import React from 'react';

import {getCountries } from '../../../../networking/NetworkingCountries';
import {getLookUpTable} from '../../../../networking/Networking'
import {getPersonIdentifications,savePersonIdentification, deletePersonIdentification} from '../../../../networking/NetworkingPesonIdentifications'

import DynamicSelect from '../../../shared/DynamicSelect';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import DatePicker from '../../../shared/DatePicker';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

import AlertBox from '../../../shared/AlertBox';
import {getStyle} from '../../../../styles/styles';

import { formatValidSelect, formatDate } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
import LoadingBox from '../../../shared/LoadingBox';
import AppContext from '../../../../AppContext';
let globalFunctions = {};
var style = getStyle();

export default class PersonIdentificationsScreen extends React.Component {
    static contextType = AppContext;
    state ={
        identifications : [],
        pages: 1,
        totalPages: 1,
        newSearch: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',      
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        loading: false,

        personIdentificationID: 0,
        type: '',
        number: '',
        cardnumber: '',
        othername: '',
        issuedby: '',
        issueddate: '',
        issuedat: '',
        countryId: '',
        otherinformation: '',
        nameperid: '',
        expirydate: '',
        remarks: '',
        status: '',
        reservedparameter: '',
        updateToken: '',
        countries: [],
        validations: {
            type: true,
            number: true,
            issuedby: true,
            issueddate: true,
            countryId: true,
            status: true
        },
        PersonIdentificationType: [],
        IdentificationStatus: [],
        lookUpTables: [
            "PersonIdentificationType",
            "IdentificationStatus"
        ],
    }

    componentDidMount()
    {
     //   globalFunctions = this.context;
       // globalFunctions.startLoading();
        if(this.props.countries !== undefined)
        {
            this.setState({countries: this.props.countries});
        }
        if(this.props.dropdowns !== undefined)
        {
            this.setState({PersonIdentificationType: this.props.dropdowns["PersonIdentificationType"] ,
             IdentificationStatus: this.props.dropdowns["IdentificationStatus"] });
        }
        /*
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                    }
                }
            });
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ countries: countries } );
                }
            }
        )
*/
        getPersonIdentifications(this.props.PersonID, this.props.EntityTaskID).then(
            (json) => {
                if (json != null && json.identifications != null) {
                    this.setState({identifications: json.identifications});
                    if(json.identifications.length > 0)
                    {
                        this.handleRowClick(json.identifications[0]);
                    }
                }
            }
        );

       /* if(this.props.ExpiryDate != undefined)
        {
            this.setState({expirydate: this.props.ExpiryDate});
        }*/

       // this.handleUpdateGrid();
    }

    getAllPersonalIdentifications = async () => {
        var result = [];
        if(this.props.PersonID !== undefined)
        {
            var entityTaskID = '';
            if(this.props.EntityTaskID !== undefined && this.props.EntityTaskID !== '')
            {
                entityTaskID = this.props.EntityTaskID;
            }
            getPersonIdentifications(this.props.PersonID, entityTaskID)
        const json = await getPersonIdentifications(this.props.PersonID, entityTaskID);
        result = json.identifications
        }
        return result;
    }

    handleUpdateGrid = async () => {        
        const identifications = await this.getAllPersonalIdentifications();
        if (identifications !== undefined) {
            const totalCount = identifications.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ identifications, totalPages, newSearch: true });
        }
        else
        {
            this.setState({ identifications:[], 
                totalPages:0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleRowClick = (row) =>
    {
        if(row !== undefined)
        {
            this.setState({
                personIdentificationID: row.IdentificationID,
                type: row['Identification Type'],
                number: row.Number,
                cardnumber: row['Card Number'],
                othername: row['Other Name'],
                issuedby: row.IssuedBy,
                issueddate: formatDate(row.IssueDate),
                issuedat: row.IssuedAt,
                countryId: row.IssuedCountry,
                otherinformation: row.OtherInfo,
                nameperid: row.NameAsPerID,
                expirydate: row.ExpiryDate != null ? formatDate(row.ExpiryDate): '',
                remarks: row.Remarks,
                status: row.Status,
                reservedparameter: row.ReservedParameter,
                updateToken: row.UpdateToken,
            })
        }
    }

    handleDeleteClick = (row) =>
    {
        this.setState({ showDeleteAlert: true,
            personIdentificationID: row.IdentificationID
        });
    }

    changePage = (value) => {
        this.setState({
            pages: value
        });
    }

    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }
    closeAlertError = () => {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '' });
    }

    yesDeleteAlert = async () => {
        globalFunctions.startLoading();
        this.setState({ showDeleteAlert: false });
        const json = await deletePersonIdentification(this.state.personIdentificationID);
        this.setState({ showSuccessDeleteAlert: true });
        globalFunctions.finishLoading();
        this.handleUpdateGrid();
    }

    closeDeleteAlert = () =>
    {
        this.setState({ showDeleteAlert: false, personIdentificationID: 0 });
    }

    handleClearClick = () =>
    {
        var identificationID= 0;
        var updateToken = '';

        if(this.props.EntityTaskID !== undefined && this.props.EntityTaskID !== '')
        {
            identificationID = this.state.personIdentificationID;
            updateToken = this.state.updateToken;
        }

        this.setState({
            personIdentificationID: identificationID,
            type: '',
            number: '',
            cardnumber: '',
            othername: '',
            issuedby: '',
            issueddate: '',
            issuedat: '',
            countryId: '',
            otherinformation: '',
            nameperid: '',
            expirydate: '',
            remarks: '',
            status: '',
            reservedparameter: '',
            updateToken: updateToken,
        });
    }

    handleSaveClick = () =>
    {
        let userID = localStorage.getItem('UserID');
        let entityTaskID = null;

        if(this.props.EntityTaskID !== undefined && this.props.EntityTaskID !== '')
        {
            entityTaskID = this.props.EntityTaskID;
        }

        var model = {
            IdentificationID: this.state.personIdentificationID, 
            PersonID: this.props.PersonID, 
            Type: this.state.type, 
            Number: this.state.number, 
            CardNumber: this.state.cardnumber, 
            OtherName: this.state.othername, 
            IssuedBy: this.state.issuedby, 
            IssueDate: this.state.issueddate, 
            Remarks: this.state.remarks, 
            NameAsPerID: this.state.nameperid, 
            OtherInfo: this.state.otherinformation, 
            IssuedAt: this.state.issuedat, 
            ExpiryDate: this.state.expirydate, 
            IssuedCountry: this.state.countryId, 
            Status: this.state.status, 
            ReservedParameter: this.state.reservedparameter, 
            UpdatedBy: userID, 
            EntityTaskID: entityTaskID,
            UpdateToken: this.state.updateToken
        }

        if(this.validateRequiredInputs())
        {
            this.setState({ loading: true });
                savePersonIdentification(model).then((json) => {
                    console.log(json);
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        this.setState({
                            showAlert: true,
                            alertMessage: json.httpErrorMessage,
                            alertTitle: 'Error',
                            loading: false
                        });
                    } else if (json.httpStatusCode === 409){
                        this.setState({ 
                            showAlertError: true, 
                            alertMessage: json.httpErrorMessage, 
                            alertTitle: 'Error',
                            loading: false
                        });
                    } else {
                        this.setState({
                            personIdentificationID: json.personIdentificationID,
                            updateToken: json.updateToken,
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Person identification successfully saved.',
                            loading: false
                        });
                        //this.handleUpdateGrid();
                        this.props.handleBackLink(true);
                    }
                })
        }

    }

    componentDidUpdate(prevProps)
    {
        /*if(prevProps.reload !== this.props.reload)
        {
            this.getAllPersonalIdentifications();
        }*/
        /*
        if(prevProps.reload !== this.props.EntityTaskID || prevProps.PersonID !== this.props.PersonID)
        {
            this.handleClearClick();
            this.handleUpdateGrid();
        }*/
    }

    validateRequiredInputs = () => {
        
        const fields = [
            {displayName: 'Type', validateName: 'type', value: this.state.type, type: 'string'},
            {displayName: 'Number', validateName: 'number', value: this.state.number, type: 'string'},
            {displayName: 'Issued By', validateName: 'issuedby', value: this.state.issuedby, type: 'string'},
            {displayName: 'Issued Date', validateName: 'issueddate', value: this.state.issueddate, type: 'string'},
            {displayName: 'Country', validateName: 'countryId', value: this.state.countryId, type: 'string'},
            {displayName: 'Status', validateName: 'status', value: this.state.status, type: 'string'}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    getCountryValue = (value) =>
    {
        if(value !== undefined && value.value !== undefined)
        {
            this.setState({
                countryId: value.value,
                validations: { ...this.state.validations, countryId: true }
            });
        }
    }

    handleGetType = (value) =>
    {
        if (value !== undefined && value !== "select") {
            this.setState({
                validations: { ...this.state.validations, type: true },
                type: value.value 
            });
        }
    }

    handleUpdateNumber = (event) =>
    {
        this.setState({
            number: event.target.value ,
            validations: { ...this.state.validations, number: true }
        });
    }

    handleUpdateCardNumber = (event) =>
    {
        this.setState({
            cardnumber: event.target.value 
        });
    }

    handleUpdateOtherName = (event) =>
    {
        this.setState({
            othername: event.target.value 
        });
    }

    handleUpdateIssuedAt = (event) =>
    {
        this.setState({
            issuedat: event.target.value
        });
    }

    handleUpdateIssuedBy = (event) =>
    {
        this.setState({
            issuedby: event.target.value,
            validations: { ...this.state.validations, issuedby: true }
        });
    }

    handleUpdateIssuedDate = (date) =>
    {
        this.setState({issueddate: formatDate(date),
            validations: { ...this.state.validations, issueddate: true }});
    }

    handleUpdateOtherInformation = (event) =>
    {
        this.setState({
            otherinformation: event.target.value 
        });
    }

    handleUpdateNamePerID = (event) =>
    {
        this.setState({
            nameperid: event.target.value 
        });
    }

    handleUpdateExpiryDate = (date) =>
    {
        this.setState({expirydate: formatDate(date)});
    }

    handleUpdateRemarks = (event) =>
    {
        this.setState({
            remarks: event.target.value 
        });
    }

    handleGetValueStatus = (value) =>
    {
        if (value !== undefined && value !== "select") {
            this.setState({
                status: value.value ,
                validations: { ...this.state.validations, status: true }
            });
        }
    }

    handleUpdateReservedParameter = (event) =>
    {
        this.setState({
            reservedparameter: event.target.value 
        });
    }

    handleBackLink = () => {
        this.props.handleBackLink(false);
    }

    render() {
        return (<div>
            <div className="uk-width-3-4">
                <h3 className="uk-heading-divider"  style={style.titleStyle} >
                    {/* <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left back-arrow" ></i>
                    </button> */}
                    Person Identifications
                </h3>
                <div>
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task" >Type (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className={formatValidSelect(this.state.validations.type)}
                                    objValue={{value:this.state.type} }
                                    getValue={this.handleGetType} 
                                    listHasPlaceholder={true} 
                                    data={this.state.PersonIdentificationType} 
                                    id="form-perID-type" />
                            </div>
                        </div>
                        <div></div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task" >Other document name</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-other-name" placeholder="" value={this.state.othername} onChange={this.handleUpdateOtherName} />
                            </div>
                        </div>
                        <div></div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Number (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={formatValidSelect(this.state.validations.number)} type="text" id="form-perID-number" placeholder="" value={this.state.number} onChange={this.handleUpdateNumber} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Card Number</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-card-number" placeholder="" value={this.state.cardnumber} onChange={this.handleUpdateCardNumber} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Issued By (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={formatValidSelect(this.state.validations.issuedby)} type="text" id="form-perID-issued-by" placeholder="" value={this.state.issuedby} onChange={this.handleUpdateIssuedBy} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task" >Issued date (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" id="form-perID-issued-date" value={this.state.issueddate} onDayChange={this.handleUpdateIssuedDate} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Issued at</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-issued-at" placeholder="" value={this.state.issuedat} onChange={this.handleUpdateIssuedAt} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task" >Country (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect id="form-perID-country" 
                                    className={formatValidSelect(this.state.validations.countryId)} 
                                    placeholder={"Select an Item..."}  getValue={this.getCountryValue} 
                                        data={this.state.countries} 
                                        objValue={{ value: this.state.countryId}} />
                                
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Other information</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-other-information" placeholder="" value={this.state.otherinformation} onChange={this.handleUpdateOtherInformation} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Name as Per ID</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-name-per-id" placeholder="" value={this.state.nameperid} onChange={this.handleUpdateNamePerID} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Expiry date</label>
                            <div className="" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" id="form-perID-expiry-date" value={this.state.expirydate} onDayChange={this.handleUpdateExpiryDate} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Remarks</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-remarks" placeholder="" value={this.state.remarks} onChange={this.handleUpdateRemarks} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Status (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className="uk-select" 
                                    objValue={{value:this.state.status}} 
                                    getValue={this.handleGetValueStatus} 
                                    listHasPlaceholder={true} 
                                    data={this.state.IdentificationStatus} 
                                    id="form-perID-status" />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-form-stacked">
                            <label className="uk-form-label-properties-task">Reserved Parameter</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" type="text" id="form-perID-reserved-parameter" placeholder="" value={this.state.reservedparameter} onChange={this.handleUpdateReservedParameter} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                        </div>
                    </div>
                </div>
                <div>
                    <button className="uk-button uk-button-green" id="form-perID-clearbtn" type="button" onClick={this.handleClearClick} >Clear Fields</button>
                    <button className="uk-button uk-button-green" id="form-perID-savebtn" type="button" onClick={this.handleSaveClick} >Save</button>
                    <hr />
                </div>
               {/* <NewDynamicTable
                    hiddenHeaders={['IdentificationID', 'Type', 'IssuedBy', 'IssueDate', 'Remarks', 'NameAsPerID', 
                    'OtherInfo', 'IssuedAt', 'ExpiryDate', 'IssuedCountry', 'ReservedParameter', 'EntityTaskID', 'UpdateToken']}
                    data={this.state.identifications}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true}
                    filterFunction={this.getAllPersonalIdentifications} 
                    useExportButton={true}
                    tableName="limit_profiles" />
               */}
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />

                <AlertBox id="delete-limit-profile" title="Are you sure you want to delete this person identification?" message="This action can not be undone." type="Yes/No"
                    open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <LoadingBox loading={this.state.loading} />
            </div>
        </div>)
    }

}
export const BankReconciliationTypeNames = {
    Payments: "Payments",
    OutgoingFunds: "OutgoingFunds",
    TransferOut: "TransferOut",
    TransferIn: "TransferIn",
    TransactionLossCredit: "TransactionLossCredit",
    TransactionLossDebit: "TransactionLossDebit",

    WithdrawalRequests: "WithdrawalRequests",
    FxcmRollover: "FxcmRollover",
    FxcmCommission: "FxcmCommission",
    FxcmFees: "FxcmFees"
}

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dialog, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import MTypography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Breadcrumb, Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography } from 'antd';
import 'antd/dist/antd.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import '../../../css/ant.css';
import { deleteCurrency, getCurrencies, getCurrencyByID } from '../../../networking/NetworkingCurrencies';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import CurrencyForm from './CurrencyForm';
import NewCurrencyMessages from './NewCurrencyMessages';

const style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <MTypography variant="h6">{children}</MTypography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function NewCurrencyScreen() {
    const context = useContext(AppContext);
    const [form]                                = Form.useForm();
    const [currencies, setCurrencies]           = useState([]);
    const [updating, setUpdating]               = useState(false);          // Para saber cuando volver a cargar el useEffect
    const [editingKey, setEditingKey]           = useState('');
    const [isCheckedStatus, setCheckedStatus]   = useState(false);
    const [firstime, setFirsttime]              = useState(true);
    const [currentPage, setCurrentPage]         = useState();
    const [currencyObjToEdit, setCurrencyObjToEdit] = useState(null);
    const [selectedCurrencyID, setSelectedCurrencyID] = useState('');

    //Filters --------------------------------------------------------------------
    const [searchText, setSearchText]                   = useState('');
    const [searchedColumn, setSearchedColumn]           = useState('');
    const [searchArray, setSearchArray]                 = useState({});
    const searchInput                                   = useRef();
    //----------------------------------------------------------------------------

    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [viewingMessages, setViewingMessages] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        getCurrencies(0, 0).then((json) => {
            if (json != null && json.currencies != null) {
                const currencies = json.currencies.map((c) => ({...c, key: c['Currency Id']}));
                setCurrencies(currencies);
            }
        });
    }, [updating]);

    const isEditing = (record) => record['Currency Id'] === editingKey;

    const edit = async (record) => {
        const json = await getCurrencyByID(record['Currency Id']);
        if (json != null && json.currency != null && json.currency[0] != null) {
            setCurrencyObjToEdit(json.currency[0]);
            setShowDialog(true);
            setDialogTitle(`Edit Currency ${record['Currency Id']}`);
        }
    };

    const showMessagesForCurrency = (record) => {
        setViewingMessages(true);
        setSelectedCurrencyID(record['Currency Id']);
    }

    const deleteRecord = async (record) => {
        context.startLoading();
        const json = await deleteCurrency(record['Currency Id']);
        context.finishLoading();
        if (json.httpStatusCode !== 200) {
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage(json.Message);
        } else {
            setShowAlert(true);
            setAlertTitle('Success');
            setAlertMessage('Currency successfully removed.');
            setUpdating(!updating);
        }
    };

    const addNewCurrency = () => {
        setShowDialog(true);
        setDialogTitle('Add new Currency');
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const settingSearchStateValues = (value, col)=>{
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...searchArray[col] = value);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
                ref           = {node => {searchInput.current = node;}}
                placeholder   = {`Search ${dataIndex}`}
                value         = {selectedKeys[0]}
                onChange      = {(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : [])}
                }
                onPressEnter  = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                style         = {{
                    width       : 188, 
                    marginBottom: 8, 
                    display     : 'block' 
                }}
            />
            <Space>
                <Button
                    type    = "primary"
                    onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon    = {<SearchOutlined />}
                    size    = "small"
                    style   = {{ width: 90 }}
                >Search
                </Button>
                <Button 
                    onClick = {() => {
                        handleReset(clearFilters);
                    }}
                    size    = "small" 
                    style   = {{ width: 90 }}
                >Reset
                </Button>
                <Button
                    type    = "link"
                    size    = "small"
                    onClick = {() => {
                        confirm({ closeDropdown: false });
                        settingSearchStateValues(selectedKeys[0], dataIndex);
                    }}
                >Filter
                </Button>
            </Space>
          </div>
        ),
        filterIcon                      : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter                        : (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange   : visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render                          : text => searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle    = {{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords       = {[searchText]}
              autoEscape
              textToHighlight   = {text ? text.toString() : ''}
            />
          ) : (
            text
          ),
    });

    const columns = [
        {
            title: 'Currency ID',
            dataIndex: 'Currency Id',
            width: '15%',
            editable: false,
            align: 'center',
            defaultSortOrder: 'ascend',
            sorter          : (a, b) => { return a['Currency Id'].localeCompare(b['Currency Id'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Currency Id'),
        },
        {
            title: 'Currency Name',
            dataIndex: 'Currency Name',
            width: '25%',
            editable: false,
            align: 'left',
            sorter          : (a, b) => { return a['Currency Name'].localeCompare(b['Currency Name'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Currency Name'),
        },
        {
            title: 'Symbol',
            dataIndex: 'Symbol',
            width: '10%',
            editable: false,
            align: 'center',
            sorter          : (a, b) => { return a['Symbol'].localeCompare(b['Symbol'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Symbol'),
        },
        {
            title           : 'Min Denomination',
            dataIndex       : 'Min Denomination',
            width           : '10%',
            editable        : false,
            align           : 'right',
            sorter          : (a, b) => a['Min Denomination'] - b['Min Denomination'],
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title           : 'Risk Score',
            dataIndex       : 'Risk Score',
            width           : '10%',
            editable        : false,
            align           : 'center',
            sorter          : (a, b) => a['Risk Score'] - b['Risk Score'],
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title           : 'Status',
            dataIndex       : 'Status',
            width           : '15%',
            editable        : false,
            align           : 'center',
            sorter          : (a, b) => { return a.Status.localeCompare(b.Status)},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title           : 'Available Online',
            dataIndex       : 'Available Online',
            width           : '15%',
            editable        : false,
            align           : 'center',
            render          : (text, row, index) => {
                return text.toString();
            },
            //sorter          : (a, b) => { return a['Available Online'].localeCompare(b['Available Online'])},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                return (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== '' || currencies[0]['Currency Id'] === ''} onClick={() => showMessagesForCurrency(record)}>
                                Messages
                            </Typography.Link>
                            <Typography.Link disabled={editingKey !== '' || currencies[0]['Currency Id'] === ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a disabled={editingKey !== '' || currencies[0]['Currency Id'] === ''}>Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Status': {
                type = 'select';
                break;
            }
            case 'RiskScore': {
                type = 'number';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const onChange = (e) => {
            if (e.target.checked) {
                setCheckedStatus(true);
            } else {
                setCheckedStatus(false);
            }

            form.setFieldsValue({
                Status: e.target.checked
            });
        };

        if (record !== undefined && record.Country === editingKey && firstime) {
            if (record.Status === 'Authorized to deal') {
                setCheckedStatus(true);
                setFirsttime(false);
                form.setFieldsValue({
                    Status: true
                });
            } else {
                setCheckedStatus(false);
                setFirsttime(false);
                form.setFieldsValue({
                    Status: false
                });
            }
        }

        let inputNode = null;
        const statuses = [
            { value: '', name: 'Select a Type...' },
            { value: 'Active', name: 'Active' },
            { value: 'Inactive', name: 'Inactive' },
        ]

        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center' }} />;
                break;
            }
            case 'select': {
                inputNode = (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {statuses.map((s) => (
                            <Select.Option value={s.value}>{s.name}</Select.Option>
                        ))}
                    </Select>
                );
                break;
            }
            case 'check': {
                inputNode = (
                    <Checkbox onChange={onChange} checked={isCheckedStatus}>
                        Available Online
                    </Checkbox>
                );
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`
                            }
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const cancel = (page, pageSize) => {
        setEditingKey('');
        setFirsttime(true);
        if(typeof page === 'number')
        {
            setCurrentPage(page);
        }
        
        if(currencies[0]['Currency Id'] === '')
        {
            let auxCurrencies = [...currencies];
            auxCurrencies.shift();
            setCurrencies(auxCurrencies);
        }
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
          return <a>Previous</a>;
        }
        if (type === 'next') {
          return <a>Next</a>;
        }
        return originalElement;
    }

    const onSaveCurrency = () => {
        setUpdating(!updating);
    };

    const onCloseDialog = () => {
        setShowDialog(false);
        setCurrencyObjToEdit(null);
    }

    const closeAlert = () => {
        setShowAlert(false);
    };

    const handleBackLink = () => {
        setViewingMessages(false);
        setSelectedCurrencyID('');
    };

    return (
        <div>
            <div className="breadcrumb-div">
                {!viewingMessages ? (
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item-bold">Currencies</Breadcrumb.Item>
                    </Breadcrumb>
                ) : (
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={handleBackLink}>
                            Currencies
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Currency Messages</Breadcrumb.Item>
                    </Breadcrumb>
                )}
            </div>
            <Form form={form} component={false}>
                <Table
                    components={{ body: { cell: EditableCell }}}
                    bordered={true}
                    dataSource={currencies}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    size="small"
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewCurrency}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Add a Currency
                        </Button>
                    )}
                />
            </Form>
            <Dialog open={showDialog} onClose={onCloseDialog} fullWidth={true} maxWidth="md">
                <DialogTitle id="edit-currencies">
                    <div className="">
                        <h4 className="">
                            {dialogTitle}<span id="edit-currency-dialog"></span>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <CurrencyForm key="currency-form" currencyList={currencyObjToEdit} updatePage={onSaveCurrency} embedded={true} onClose={onCloseDialog} />
                </DialogContent>
            </Dialog>
            {viewingMessages && <NewCurrencyMessages selectedCurrency={selectedCurrencyID} />}
            <AlertBox id="alert-currencies" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}
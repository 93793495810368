import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export async function getAdditionalPropertiesDefs(subEntityType){
    try {
        let params = {
            subEntityType: subEntityType
        }
        let response = await genericGetWithParameters('AdditionalPropertiesDefs/GetAdditionalPropertiesDefs',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAdditionalPropertiesDefsByID(addPropertyDefID, subEntityType){
    try {
        let params = {
            'addPropertyDefID': addPropertyDefID,
            'subEntityType': subEntityType
        }
        let response = await genericGetWithParameters('AdditionalPropertiesDefs/GetAdditionalPropertiesDefsByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteAdditionalPropertyDef(addPropertyDefID, subEntityType){
    let params = {
        'addPropertyDefID': addPropertyDefID,
        'subEntityType': subEntityType
    };
    let response = await genericDeleteWithParameters('AdditionalPropertiesDefs/Delete',params);
    return response;
}

export async function saveAdditionalPropertyDef(objAddPropertyDef){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'AdditionalPropertiesDefs/Save';
    let response = await genericCallWithBody(method, apiName, objAddPropertyDef);
    return response;
}

export const getAdditionalPropertiesDefsByTypeAndCountry = async (entityType, subType, countryID) => {
    const params = {
        'EntityType': entityType,
        'SubType': subType,
        'countryID': countryID
    };
    const response = await genericGetWithParameters('AdditionalPropertiesDefs/GetAdditionalPropertiesDefsByTypeAndCountry', params);
    return response;
}
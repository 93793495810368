import React from 'react';
import DatePicker from '../../shared/DatePicker';
import DynamicSelect from '../../shared/DynamicSelect';
import { getStyle } from '../../../styles/styles';
import { getLookUpTable } from '../../../networking/Networking';
import { getCountries, searchCountries, getCountryById } from '../../../networking/NetworkingCountries';
import { getStatesByCountry } from '../../../networking/NetworkingStates';
import { formatDate, formatValidSelect, formatValidInput } from '../../../helpers/FormatHelper';
import AlertBox from '../../shared/AlertBox';
import { validate } from '../../../helpers/ValidateHelper';
import UIkit from 'uikit';
import LoadingBox from '../../shared/LoadingBox';

import { v4 as uuidv4 } from 'uuid';


var style = getStyle();

export default class AddressForm extends React.Component {

    state = {
        address: {
            Address2: '',
            AddressId: '',
            AddressType: '',
            AddressValue: '',
            City: '',
            CountryId: '',
            CountryName: '',
            CreatedBy: '',
            CreatedOn: '',
            DefaultAddress: this.props.isCustomer != null ? !this.props.isCustomer : false,
            District: '',
            OtherState: '',
            PersonID: '',
            POBox: '',
            SinceWhen: '',
            StateId: 0,
            StateName: '',
            Status: '',
            UpdateToken: '',
            UpdatedOn: '',
            ZipCode: ''
        },
        dropdowns: {
            countries: [],
            states: [],
            "Address Type": [],
            "Customer Address Status": []
        },
        lookUpTables: [
            "Address Type",
            "Customer Address Status"
        ],
        validations: {
            addressType: true,
            address1: true,
            country: true,
            state: true
        },
        addresses: [],
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        isOpen: true,
        loading: false
    }

    componentDidMount() {
        if (this.props.selectedAddress === null) {
            this.props.isValid(this.validateRequiredInputs(this.state.address));
        } else {
            if (this.props.selectedAddress.length === undefined) {
                this.props.isValid(this.validateRequiredInputs(this.state.address));
            }
        }
        this.setState({ loading: true },()=>{
            getLookUpTable(this.state.lookUpTables.toString()).then(
                (json) => {
                    for (var i = 0; i < this.state.lookUpTables.length; i++) {
                        let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                        if (index > -1) {
                            var table = json.lookUpTables[index].lookUpTableDetails;
                            for (var j = 0; j < table.length; j++) {
                                table[j].value = table[j].LookUpTableDetail;
                                table[j].name = table[j].LookUpTableDetail;
                            }
                            this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } }, () => {

                                getCountries(1, 250).then(
                                    (json) => {
                                        if (json != null && json.countries != null) {
                                            const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                                            this.setState({ dropdowns: { ...this.state.dropdowns, countries: countries }, loading: false });
                                        }
                                    }
                                )
                            });
                        }
                    }
                });
        });
        UIkit.util.on('#accordion-address', 'show', () => {
            this.setState({ isOpen: true });
        });
        UIkit.util.on('#accordion-address', 'hide', () => {
            this.setState({ isOpen: false });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAddress !== null && prevProps.selectedAddress === null) {
            const address = this.props.selectedAddress;
            if (address.CountryId !== '' && address.CountryId !== 0 && address.CountryId !== undefined) {
                // this.searchCountry(address.CountryId);
                this.searchStates(address.CountryId);
            }
            this.props.isValid(this.validateRequiredInputs(address));
            this.setState({
                address: address,
                validations: {
                    addressType: true,
                    address1: true,
                    country: true,
                    state: true
                }
            });
        } else if (this.props.selectedAddress !== null && prevProps.selectedAddress !== null && this.props.selectedAddress.UpdateToken !== prevProps.selectedAddress.UpdateToken) {
            const address = this.props.selectedAddress;
            if (address.CountryId !== '' && address.CountryId !== 0 && address.CountryId !== undefined) {
                // this.searchCountry(address.CountryId);
                this.searchStates(address.CountryId);
            }
            this.props.isValid(this.validateRequiredInputs(address));
            this.setState({
                address: address,
                validations: {
                    addressType: true,
                    address1: true,
                    country: true,
                    state: true
                }
            });
        }
        if (prevProps.validations !== this.props.validations && !this.props.validations) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.clearFields();
        }
        if (!prevProps.openCollapsible && this.props.openCollapsible && !this.state.isOpen) {
            UIkit.accordion('#accordion-address').toggle();
            this.setState({ isOpen: true });
        }
    }

    getCountry(id) {
        getCountryById(id).then(
            (json) => {
                console.log(json);
                var helper = [];
                var country = json.country[0];
                country.value = country['Country Id'];
                var name = country['Country Id'] + ' - ' + country['Country Name'];
                country.name = name;
                helper.push(country);
                this.setState({ address: { ...this.state.address, CountryId: country['Country Id'], CountryName: name }, dropdowns: { ...this.state.dropdowns, countries: helper } });
            }
        );
    }

    searchStates(query) {
        getStatesByCountry(query, 1, 1000).then(
            (json) => {
                if (json != null && query !== '' && json.states != null) {
                    const states = json.states.map(s => ({ ...s, name: s["State Name"], value: s["State Id"] }));
                    this.props.isValid(this.validateRequiredInputs(this.state.address, states));
                    this.setState({ dropdowns: { ...this.state.dropdowns, states } });
                } else {
                    this.props.isValid(this.validateRequiredInputs(this.state.address, []));
                    this.setState({ dropdowns: { ...this.state.dropdowns, states: [] } });
                }

            }
        )
    }

    searchCountry(query) {
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({ dropdowns: { ...this.state.dropdowns, countries: helper } });
                    if (helper.length === 1) {
                        var value = helper[0];
                        this.setState({
                            address: {
                                ...this.state.address, CountryId: value.CountryId, CountryName: value.CountryIdName
                            }
                        });
                    }
                }
            }
        );
        console.table(this.state.dropdowns.countries)
    }

    clearFields() {
        this.setState({
            address: {
                Address2: '',
                AddressId: '',
                AddressType: '',
                AddressValue: '',
                City: '',
                CountryId: '',
                CountryName: '',
                CreatedBy: '',
                CreatedOn: '',
                DefaultAddress: false,
                District: '',
                OtherState: '',
                PersonID: '',
                POBox: '',
                SinceWhen: '',
                StateId: 0,
                StateName: '',
                Status: '',
                UpdateToken: '',
                UpdatedOn: '',
                ZipCode: ''
            },
            dropdowns: {
                ...this.state.dropdowns, states: []
            },
            validations: {
                ...this.state.validations,
                addressType: true,
                address1: true,
                country: true,
                state: true
            }
        }, () => {
            this.props.isValid(this.validateRequiredInputs(this.state.address));
        });
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    checkValidations = () => {
        return this.state.validations.address1 && this.state.validations.addressType && this.state.validations.country && this.state.validations.state;
    }

    validateRequiredFields() {
        let fields = [
            { displayName: 'Address Type', validateName: 'addressType', value: this.state.address.AddressType, type: 'string' },
            { displayName: 'Address', validateName: 'address1', value: this.state.address.AddressValue, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: this.state.address.CountryId, type: 'string' },
        ];

        if (this.state.dropdowns.states.length > 0) {
            fields.push({ displayName: 'State', validateName: 'state', value: this.state.address.StateId, type: 'string' });
        } else {
            fields.push({ displayName: 'State', validateName: 'state', value: this.state.address.OtherState, type: 'string' });
        }

        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }

    validateRequiredInputs(address, states) {
        let fields = [
            { displayName: 'Address Type', validateName: 'addressType', value: address.AddressType, type: 'string' },
            { displayName: 'Address', validateName: 'address1', value: address.AddressValue, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: address.CountryId, type: 'string' },
        ];

        if (states != null) {
            if (states.length > 0) {
                fields.push({ displayName: 'State', validateName: 'state', value: address.StateId, type: 'string' });
            } else {
                fields.push({ displayName: 'State', validateName: 'state', value: address.OtherState, type: 'string' });
            }
        } else {
            if (this.state.dropdowns.states.length > 0) {
                fields.push({ displayName: 'State', validateName: 'state', value: address.StateId, type: 'string' });
            } else {
                fields.push({ displayName: 'State', validateName: 'state', value: address.OtherState, type: 'string' });
            }
        }

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateAddressType() {
        if (this.state.address.AddressType === "select" || this.state.address.AddressType === '' || this.state.address.AddressType === undefined) {
            return false;
        }
        return true;
    }

    validateCountry() {
        if (this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) {
            return false;
        }
        return true;
    }

    validateState() {
        if (this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) {
            return false;
        }
        return true;
    }

    validateStateName() {
        if (this.state.address.OtherState === '' || this.state.address.OtherState === 0 || this.state.address.OtherState === undefined) {
            return false;
        }
        return true;
    }

    validateAddress1() {
        if (this.state.address.AddressValue === '') {
            return false;
        }
        return true;
    }

    handleSaveAddressClick(event) {
        event.preventDefault();

    }

    handleGetValueAddressType(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                validations: { ...this.state.validations, addressType: true },
                address: { ...this.state.address, AddressType: value.value }
            },
                () => {
                    if (this.validateAddressType()) {
                        this.props.getAddressInfo(this.state.address);
                        this.props.isValid(this.validateRequiredInputs(this.state.address));
                    } else {
                        this.props.isValid(this.validateRequiredInputs(this.state.address));
                    }
                }
            );
        }
    }

    handleGetValueStatus(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                address: { ...this.state.address, Status: value.value }
            },
                () => {
                    this.props.getAddressInfo(this.state.address);
                }
            );
        }
    }

    handleGetValueCountry(value) {
        if (value != null && value !== "select" && value !== '') {
            const newAddress = { ...this.state.address, CountryId: value.value, CountryName: value.name != null ? value.name : '', StateId: 0, OtherState: '' };
            this.props.getAddressInfo(newAddress);
            if (value.value != null) {
                this.searchStates(value.value); // searchStates will execute this.props.isValid when it finishes loading
            } else {
                this.props.isValid(this.validateRequiredInputs(newAddress)); // this is for the case when value.value === 'select'
            }
            this.setState({
                validations: { ...this.state.validations, country: true, state: true },
                address: newAddress
            });
        }
    }

    handleGetValueState(value) {
        if (value != null && value !== "select" && value.value != null) {
            this.setState({
                validations: { ...this.state.validations, state: true },
                address: { ...this.state.address, StateId: value.value, StateName: value.name != null ? value.name : '' }
            },
                () => {
                    if (this.validateState()) {
                        this.props.getAddressInfo(this.state.address);
                        this.props.isValid(this.validateRequiredInputs(this.state.address));
                    } else {
                        this.props.isValid(this.validateRequiredInputs(this.state.address));
                    }
                }
            );
        }
    }

    handleUpdateState = (event) => {
        this.setState({
            validations: { ...this.state.validations, state: true },
            address: { ...this.state.address, OtherState: event.target.value }
        },
            () => {
                if (this.validateStateName()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredInputs(this.state.address));
                } else {
                    this.props.isValid(this.validateRequiredInputs(this.state.address));
                }
            }
        );
    }

    handleUpdateAddress1(event) {
        this.setState({
            validations: { ...this.state.validations, address1: true },
            address: { ...this.state.address, AddressValue: event.target.value }
        },
            () => {
                if (this.validateAddress1()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredInputs(this.state.address));
                } else {
                    this.props.isValid(this.validateRequiredInputs(this.state.address));
                }
            }
        );
    }

    handleUpdateAddress2(event) {
        this.setState({
            address: { ...this.state.address, Address2: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateCity(event) {
        this.setState({
            address: { ...this.state.address, City: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDefaultAddress(event) {
        this.setState({
            address: { ...this.state.address, DefaultAddress: event.target.checked }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDistrict(event) {
        this.setState({
            address: { ...this.state.address, District: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdatePOBox(event) {
        this.setState({
            address: { ...this.state.address, POBox: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateZipCode(event) {
        this.setState({
            address: { ...this.state.address, ZipCode: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateSinceWhen(date) {
        this.setState({
            address: { ...this.state.address, SinceWhen: formatDate(date) }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    render() {
        var countryListId = 'country-list-' + this.state.address.PersonID;
        let accordionID = 'accordion-address-' + uuidv4();
        return (
            <div>
                <ul id={accordionID} uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Address Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="address-information">
                                <form className="uk-form-horizontal uk-margin">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Address Type (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className={formatValidSelect(this.state.validations.addressType)} objValue={{ value: this.state.address.AddressType }} getValue={this.handleGetValueAddressType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Address Type"]} id="form-address-type" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Status</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.address.Status === null ? [] : this.state.address.Status }} getValue={this.handleGetValueStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Customer Address Status"]} id="form-status" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Address (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className={formatValidInput(this.state.validations.address1)} type="text" placeholder="" value={this.state.address.AddressValue} onChange={this.handleUpdateAddress1.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Address 2</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.address.Address2} onChange={this.handleUpdateAddress2.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Country (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id={countryListId} className={formatValidSelect(this.state.validations.country)} placeholder={"Select an Item..."} getValue={this.handleGetValueCountry.bind(this)} data={this.state.dropdowns.countries} objValue={{ value: this.state.address.CountryId, name: this.state.address.CountryName, CountryId: this.state.address.CountryId, CountryIdName: this.state.address.CountryName }} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Province / State (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                {
                                                    this.state.dropdowns.states.length > 0 ?
                                                        <DynamicSelect className={formatValidSelect(this.state.validations.state)} getValue={this.handleGetValueState.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.states} objValue={{ value: this.state.address.StateId, name: this.state.address.StateName, "State Id": this.state.address.StateId, "State Name": this.state.address.StateName }} id="form-provinces-states" />
                                                        :
                                                        <input className={formatValidInput(this.state.validations.state)} type="text" placeholder="" value={this.state.address.OtherState} onChange={this.handleUpdateState} />
                                                }
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >City</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.address.City} onChange={this.handleUpdateCity.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >District</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.address.District === null ? '' : this.state.address.District} onChange={this.handleUpdateDistrict.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Postal / Zip Code</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.address.ZipCode} onChange={this.handleUpdateZipCode.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >PO BOX</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.address.POBox === null ? '' : this.state.address.POBox} onChange={this.handleUpdatePOBox.bind(this)} />
                                            </div>
                                        </div>
                                        {this.props.isCustomer && (
                                            <div className="uk-width-1-2 uk-form-stacked uk-margin">
                                                <label className="uk-form-label">
                                                    <input className="uk-checkbox" value={this.state.address.DefaultAddress} checked={this.state.address.DefaultAddress} onChange={this.handleUpdateDefaultAddress.bind(this)} type="checkbox" />
                                                Default Address
                                            </label>
                                            </div>
                                        )}
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Since When</label>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <DatePicker useNew className="uk-input" value={this.state.address.SinceWhen} onDayChange={this.handleUpdateSinceWhen.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}
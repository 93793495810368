import React from 'react';

import { getCurrencyList } from '../../../networking/NetworkingCurrencies';

import { getStyle, regularInputClass, notFilledInputClass } from '../../../styles/styles'
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import { formatValidInput, formatValidSelect } from '../../../helpers/FormatHelper';
import NumberInput from '../../shared/NumberInput';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { deleteForwardPoints, getAllForwardPoints, saveForwardPoints } from '../../../networking/NetworkingForward';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
import LoadingBox from '../../shared/LoadingBox';
var style = getStyle();

export default class ForwardPointsScreen extends React.Component {

    state = {
        homeCurrency: '',
        homeCurrencyName: '',
        foreignCurrency: '',
        foreignCurrencyName: '',
        currencyList: [],
        foreignCurrencyList: [],
        BIDSpread: '',
        ASKSpread: '',
        period: '',
        periodList: [
            { value: 'Select...', name: 'Select...' },
            { value: -1, name: -1 },
            { value: 0, name: 0 },
            { value: 1, name: 1 },
            { value: 2, name: 2 },
            { value: 3, name: 3 },
            { value: 4, name: 4 },
            { value: 5, name: 5 },
            { value: 6, name: 6 },
            { value: 7, name: 7 },
            { value: 8, name: 8 },
            { value: 9, name: 9 },
            { value: 10, name: 10 },
            { value: 11, name: 11 },
            { value: 12, name: 12 },
            { value: 13, name: 13 },
            { value: 14, name: 14 },
            { value: 15, name: 15 },
            { value: 16, name: 16 },
            { value: 17, name: 17 },
            { value: 18, name: 18 },
            { value: 19, name: 19 },
            { value: 20, name: 20 },
            { value: 30, name: 30 },
            { value: 40, name: 40 },
            { value: 50, name: 50 },
            { value: 60, name: 60 },
        ],
        periodDays: '',
        periodDaysList: [
            { value: 'Day(s)', name: 'Day(s)' },
            { value: 'Week(s)', name: 'Week(s)' },
            { value: 'Month(s)', name: 'Month(s)' },
        ],
        errors: [],
        validations: {
            homeCurrency: true,
            foreignCurrency: true,
            BIDSpread: true,
            ASKSpread: true,
            period: true,
            periodDays: true,
        },
        days: '',
        readyToDeleteForwardPoints: {},
        //for grid
        allForwardPoints: [],
        totalPages: 0,
        pages: 1,
        newSearch: false,
        //for alert box
        notFirstLoad: false,
        showAlert: false,
        showDeleteAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            getCurrencyList().then(
                (json) => {
                    for (var i = 0; i < json.currencies.length; i++) {
                        json.currencies[i].value = json.currencies[i].CurrencyID;
                        json.currencies[i].name = json.currencies[i].CurrencyIDName;
                    }
                    this.setState({ currencyList: json.currencies }, () => this.updateAllForwardPoints());
                }
            );
        });
    }
    updateAllForwardPoints() {
        getAllForwardPoints(0, 500).then(
            (json) => {
                if (json != null) {
                    if (json.result != null) {
                        const totalCount = json.result.length;
                        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        this.setState({
                            allForwardPoints: json.result,
                            totalPages,
                            newSearch: true,
                            loading: false
                        })
                    } else {
                        this.setState({
                            allForwardPoints: [],
                            totalPages: 0,
                            pages: 0,
                            newSearch: false,
                            loading: false
                        })
                    }
                }
            }
        );
    }
    getAllForwardPoints = async () => {
        var json = await getAllForwardPoints(0, 500);
        return json.result;
    }
    getHomeCurrencySelect = (value) => {
        if (value.value !== undefined) {
            this.setState({
                homeCurrency: value.value,
                homeCurrencyName: value.name != null ? value.name : '',
                validations: { ...this.state.validations, homeCurrency: true }
            });
        }
    }
    getForeignCurrencySelect = (value) =>{
        if (value.value !== undefined) {
            this.setState({
                foreignCurrency: value.value,
                foreignCurrencyName: value.name != null ? value.name : '',
                validations: { ...this.state.validations, foreignCurrency: true }
            });
        }
    }

    handleClearClick(event) {
        this.setState({
            homeCurrency: '',
            homeCurrencyName: '',
            foreignCurrency: '',
            foreignCurrencyName: '',
            BIDSpread: '',
            ASKSpread: '',
            period: '',
            periodDays: '',
            errors: [],
            validations: {
                homeCurrency: true,
                foreignCurrency: true,
                BIDSpread: true,
                ASKSpread: true,
                period: true,
                periodDays: true,
            },
            days: '',
            readyToDeleteForwardPoints: {},
            //for grid
            totalPages: 0,
            pages: 1,
            newSearch: false,
            //for alert box
            notFirstLoad: false,
            showAlert: false,
            alertTitle: '',
            alertMessage: ''
        })
    }

    handleSaveClick() {
        var objFilter = {
            'CCY1': this.state.homeCurrency,
            'CCY2': this.state.foreignCurrency,
            'Period': this.state.period,
            'BID': this.state.BIDSpread,
            'ASK': this.state.ASKSpread
        }
        if (this.validateRequiredInputs()) {
            saveForwardPoints(objFilter).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.Message, errors: [] });
                    } else {
                        this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Forward Points saved successfully', errors: [], loading: true }, () => this.updateAllForwardPoints())
                    }
                }
            );
        }
    }
     //Inputs Validations
     validateRequiredInputs() {
        const fields = [
            { displayName: 'Home Currency', validateName: 'homeCurrency', value: this.state.homeCurrency, type: 'string' },
            { displayName: 'Foreign Currency', validateName: 'foreignCurrency', value: this.state.foreignCurrency, type: 'string' },
            { displayName: 'Period', validateName: 'period', value: this.state.period, type: 'number' },
            { displayName: 'BID Spread', validateName: 'BIDSpread', value: this.state.BIDSpread, type: 'number' },
            { displayName: 'ASK Spread', validateName: 'ASKSpread', value: this.state.ASKSpread, type: 'number' }
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }
    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    handleDeleteClick = (obj) => {
        console.group("Property handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, alertTitle: 'Warning', alertMessage: 'Are you sure you want to delete this forward point? This action can not be undone.', readyToDeleteForwardPoints: obj });
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, alertMessage: '', alertTitle: '', readyToDeleteForwardPoints: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert = () => {
        deleteForwardPoints(this.state.readyToDeleteForwardPoints.CCY1, this.state.readyToDeleteForwardPoints.CCY2, this.state.readyToDeleteForwardPoints.Period).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({ showDeleteAlert: false, showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to delete. Please try again..' });
                } else {
                    this.setState({ showDeleteAlert: false, showAlert: true, alertTitle: 'Success', alertMessage: 'Forward Point successfully removed.' });
                    this.updateAllForwardPoints();
                }
            }
        );
    }
    handleUpdateEfectiveDate(date) {
        this.setState({ efectiveDate: date });
    }
    handleUpdateExpiriDate(date) {
        this.setState({ expiriDate: date });
    }
    handleUpdateMessage(event) {
        this.setState({ message: event.target.value });
    }
    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }
    getPeriodSelect = (value) => {
        if (value !== undefined && value != null) {
            this.setState({ period: value.value });
        }
    }
    getPeriodDaysSelect = (value) => {
        if (value !== undefined && value != null) {
            this.setState({ periodDays: value.value });
        }
    }
    handleUpdateBIDSpread(event) {
        this.setState({
            validations: { ...this.state.validations, BIDSpread: true },
            BIDSpread: event.target.value
        });
    }
    handleUpdateASKSpread(event) {
        this.setState({
            validations: { ...this.state.validations, ASKSpread: true },
            ASKSpread: event.target.value
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }
    handleRowClick = (obj) => {
        this.setState({
            homeCurrency: obj['CCY1'],
            foreignCurrency: obj['CCY2'],
            BIDSpread: obj['BID'],
            ASKSpread: obj['ASK'],
            period: obj['Period'],
            days: obj['Period']
        });
    }
    render() {
        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >Forward Points</h3>
                <div className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>Home Currency(*)</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect className={formatValidSelect(this.state.validations.homeCurrency)} id="form-spot-hcurrency" objValue={{ value: this.state.homeCurrency}} getValue={this.getHomeCurrencySelect} listHasPlaceholder={false} placeholder={"Select a Currency..."} data={this.state.currencyList}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>BID Spread(*)</label>
                        <div className="" style={style.inputStyle}>
                            <NumberInput className={formatValidInput(this.state.validations.BIDSpread)} id="BIDSpread" type={"Currency"} scale={10} placeholder="__0.00000000000" value={this.state.BIDSpread} onChange={this.handleUpdateBIDSpread.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>Foreign Currency(*)</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect className={formatValidSelect(this.state.validations.foreignCurrency)} id="form-spot-fcurrency" objValue={{ value: this.state.foreignCurrency}} getValue={this.getForeignCurrencySelect} listHasPlaceholder={false} placeholder={"Select a Currency..."} data={this.state.currencyList}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>ASK Spread(*)</label>
                        <div className="" style={style.inputStyle}>
                            <NumberInput className={formatValidInput(this.state.validations.ASKSpread)} id="ASKSpread" type={"Currency"} scale={10} placeholder="__0.00000000000" value={this.state.ASKSpread} onChange={this.handleUpdateASKSpread.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>Set a Period(*)</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect className={formatValidSelect(this.state.validations.period)} getValue={this.getPeriodSelect} listHasPlaceholder={true} data={this.state.periodList} id="period-list" objValue={{ value: this.state.period }} />
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={{ color: 'transparent' }}>Test</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect getValue={this.getPeriodDaysSelect} listHasPlaceholder={false} placeholder="Select..." data={this.state.periodDaysList} id="period-list" objValue={{ value: this.state.periodDays }} />
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" style={style.boldLabel}>Period (Days)</label>
                        <div className="" style={style.inputStyle}>
                            <input className='uk-input' id="periodDays" type="text" value={this.state.days} disabled={true} />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <hr />
                    </div>

                    <div className="uk-width-1-1">
                        <NewDynamicTable
                            id="ndt-forward-points"
                            data={this.state.allForwardPoints}
                            newSearch={this.state.newSearch}
                            enableClick={true}
                            clickFunction={this.handleRowClick}
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteClick}
                            usePaginator={true}
                            changePage={this.changePage}
                            numberOfPages={this.state.totalPages}
                            pageNumber={this.state.pages}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            enableSortByHeader={true}
                            useFilter={true}
                            filterFunction={this.getAllForwardPoints}
                            useExportButton={true}
                            tableName="forward_ponts"
                        />
                    </div>
                    <AlertBox id="delete-forward" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert.bind(this)} />
                    <LoadingBox loading={this.state.loading}/>
                </div>
            </div>
        );
    }
}
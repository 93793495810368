import React from 'react';

import Paginator from '../../shared/Paginator';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import { formatDateString, formatDatetime, formatDateToDDMMYYYY, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';

import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';

import {getReferredDeals, saveReferredDealComment} from '../../../networking/NetworkingCustomerLimitProfile';

import {getStyle} from '../../../styles/styles'
import { getMonitorDeals } from '../../../networking/NetworkingDeals';
import DynamicSelect from '../../shared/DynamicSelect';
import { getLookUpTable } from '../../../networking/Networking';
var style = getStyle();

const initialDate = new Date();
export default class DealMointorScreen extends React.Component {
    state = {
        voidDeals: false,
        dealOriginType: '',
        dealOriginList: [],
        deals: [],
        pages: 1,
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        getLookUpTable('Deal Origin').then(
            (json) => {
                if (json != null) {
                    const dealOriginList = json.lookUpTables[0].lookUpTableDetails.map(d => ({ ...d, name: d.LookUpTableDetail, value: d.LookUpTableDetail }));
                    this.setState({ dealOriginList });
                }
            }
        );
        this.getListOfMonitoredDeals();
        var gridDeal = setInterval(this.getListOfMonitoredDeals.bind(this),5000);
        this.setState({grid: gridDeal});
    }

    componentWillUnmount(){
        clearInterval(this.state.grid);
      }

    getListOfMonitoredDeals(){
        let date = new Date();
        getMonitorDeals(formatDateString(initialDate),formatDateString(date),this.state.voidDeals, this.state.dealOriginType,0,0).then(
            (value) => {
                console.log(value);
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/16);
                console.log(pages);
                if(totalCount === 0){
                    this.setState({deals: [], totalPages: pages, newSearch: true});
                }else{
                    this.setState({deals: value.result, totalPages: pages, newSearch: true});
                }
            }
        );
    }

    changePage = (value) => {
        this.setState({pages: value});
    }

    /*updatePage(){
        let date = new Date();
        getReferredDeals(formatDate(date),formatDate(date),false,this.state.currentPage, getDefaultPagingNumberOfItems()).then(
            (value) => {
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({deals: value.result, totalPages: pages, newSearch:false});
            }
        );
    }*/

    handleAddCommentClick(){
        let userID = localStorage.getItem('UserID');
        var model = {
        }
    }

    getDealOriginSelect(value) {        
        if (value != null && value.value !== undefined) {
            if(value.value === 'select'){
                this.setState({dealOriginType: ''},()=>{this.getListOfMonitoredDeals()});
            }else{
                this.setState({dealOriginType: value.value},()=>{this.getListOfMonitoredDeals()});
            }            
        }
    }

    handleUpdateDealOriginSelect(event) {
        this.setState({
            //validations: { ...this.state.validations, percentOrPoints: true },
            dealOriginType: event.target.value
        },()=>{this.getListOfMonitoredDeals()});
    }

    handleOnCheck(event) {
        console.log(event.target.checked);
        this.setState({ voidDeals: event.target.checked },()=>{this.getListOfMonitoredDeals()});
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }

    transformDealMonitorList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({
            'DealHeader ID': list[i]['DealHeaderID'],
            'Deal Number': list[i]['Deal No'], 
            'Status': list[i]['Status'],
            'Deal Type': list[i]['Deal Type'],
            'Customer ID': list[i]['Customer ID'],
            'Customer Name': list[i]['Customer Name'],
            'Value Date': list[i]['Value Date'],
            'Maturity Date': list[i]['Maturity Date'],
            'Buy': list[i]['Buy'],
            'Buy Amount': list[i]['Buy Amount'],
            'Client Rate': list[i]['Client Rate'],
            'Market Rate': list[i]['Maturity Date'],
            'Sell': list[i]['Sell'],
            'Sell Amount': list[i]['Sell Amount'],
            'Updated By': list[i]['Maturity Date'],
            'Updated On': list[i]['Updated On'],
            'Blotter': list[i]['Blotter'],
        });
        }
        return helper;
    }

    getAllDealMonitor = async () =>{
        let date = new Date();
        var json = await getMonitorDeals(formatDatetime(initialDate),formatDatetime(date),this.state.voidDeals, this.state.dealOriginType,0,0);
        var rates = this.transformRateList(json.result);
        return rates;
    }

    render(){
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Deal Monitor</h3>
                <form className="uk-grid">
                    <div className="uk-width-1-4 uk-form-stacked">
                        <label className="uk-form-label" >Deal Origin Type</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect id="deal-origin-list" className="uk-select" getValue={this.getDealOriginSelect.bind(this)} listHasPlaceholder={true} data={this.state.dealOriginList} objValue={{ value: this.state.dealOriginType}} />
                        </div>
                    </div>
                    {/*<div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "35px"}} onClick={this.handleAddCommentClick.bind(this)} type="button">Deactivate</button>
                        </div>
                    </div>
                    <div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "35px"}} onClick={this.handleAddCommentClick.bind(this)} type="button">Refresh</button>
                        </div>
                    </div>*/}
                    <div className="uk-margin-right">
                        <div className="" style={{paddingTop: 40}}>
                            <label className="uk-form-label" htmlFor="form-void-deals"><input className="uk-checkbox" id="voidDeals" type="checkbox" checked={this.state.voidDeals} onChange={this.handleOnCheck.bind(this)} />Show Void Deals</label>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </form>
                <h3 className="uk-heading-divider" style={style.titleStyle}>List of Deals</h3>
                <NewDynamicTable
                    key="deal-monitor-screen"                    
                    hiddenHeaders={['DealHeaderID']} 
                    data={this.state.deals} 
                    formats={[
                        {header: 'Buy Amount', formatFunction: formatToCurrencyWithScale,formatCurrency:'Buy'},
                        {header: 'Sell Amount', formatFunction: formatToCurrencyWithScale,formatCurrency:'Sell'},
                        {header: 'Maturity Date', formatFunction: formatDateToDDMMYYYY},
                    ]}
                    enableClick={false} 
                    newSearch={this.state.newSearch} 
                    useDeleteButton={false}
                    enableSortByHeader={true}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={16}
                    useFilter={true} 
                    filterFunction={this.getAllDealMonitor}/>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
            </div>
        );
    }
}
import {genericGetWithParameters, genericDeleteWithParameters, genericCallWithBody} from './Networking';
import jwt from 'jsonwebtoken';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getBranchesByBankID(bankID, page, items){
    try {
        let params = {
            'BankID': bankID,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('Branch/GetBranchesByBankID_forGrid',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
 
export async function getAllBranches(){
    try {
        let params = {};
        //let response = await genericCallWithBody('GET','Branch/GetBranches',params);
        let response = await genericGetWithParameters('Branch/GetBranches',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
 
export async function getBranchByID(branchID){
    try {
        let params = {
            'BranchID': branchID
        }
        let response = await genericGetWithParameters('Branch/GetBranchInformationByBranchID',params);
        
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteBranch(id){
    try{
        let params = {
            'BranchID': id
        };
        let response = await genericDeleteWithParameters('Branch/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBranch(model){

    try{
        let response = await genericCallWithBody('POST','Branch/SaveBankBranch',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
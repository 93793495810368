import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../AppContext';
import { formatValidInput } from '../../../helpers/FormatHelper';
import NumberInput from '../NumberInput';
import { validate } from '../../../helpers/ValidateHelper';
import { sendReferralEmail } from '../../../networking/NetworkingEmails';
import AlertBox from '../AlertBox';
import CustomQuill from '../CustomQuill';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const RejectedFeeAmountDialog = ({ open, onClose, updateRejectedFeeAmount }) => {
    const context = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [fee, setFee] = useState(0);
    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const onCloseDialog = () => {
        //onClose();
        updateRejectedFeeAmount(fee, false);
    };
    
    const handleOkClick = () => {
        /*setValidations({...validations, email: true});
        setEmail(event.target.value);*/
        updateRejectedFeeAmount(fee, true);
    }

    const handleUpdateRejectedFee = (event) => {
        setFee(event.target.value);
    }

    return(
        <React.Fragment>
            <Dialog open={openDialog} onClose={onCloseDialog} fullWidth={true} maxWidth="md">
                <DialogTitle id="rejectoutgoingfee">
                    <div className="">
                        <h4 className="">
                            Reject Outgoing Fee<span id="label-reject-fee"></span>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="uk-grid uk-margin" style={{ marginTop: '0px' }}>
                        <div className="uk-width-1-1 uk-form-stacked">
                            <label className="uk-form-label">Please enter the rejected fee amount to be charged. If you do not wish to charge a fee, simply press ok below. Once you click ok, the replacement outgoing details will be shown.</label>
                        </div>
                    </div>
                    <div className="uk-grid uk-margin" style={{ marginTop: '0px' }}>
                        <div className="uk-width-1-1 uk-form-stacked">
                            <label className="uk-form-label">Rejected Fee</label>
                            <div className="">
                                <NumberInput
                                    className="uk-input" 
                                    id="rejected-fee"
                                    placeholder="0,00"
                                    value={fee}
                                    onChange={handleUpdateRejectedFee}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" onClick={handleOkClick}>Ok</button>
                    <button className="uk-button uk-button-green" onClick={onCloseDialog}>Close</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default RejectedFeeAmountDialog
import { genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters } from "./Networking";

export const getBlotters = async (page, items) => {
    try {
        const params = {
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('Blotter/GetBlotters', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getParentBlotters = async () => {
    try {
        const response = await genericGetWithParameters('Blotter/GetParentBlotters', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getTraders = async () => {
    try {
        const response = await genericGetWithParameters('Blotter/GetTraders', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getTradersWithBlotter = async () => {
    try {
        const response = await genericGetWithParameters('Blotter/GetTradersWithBlotters', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveBlotter = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Blotter/SaveBlotterDefinition', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteBlotter = async (blotterID) => {
    try {
        const params = {
            'BlotterID': blotterID
        };
        const response = await genericDeleteWithParameters('Blotter/DeleteBlotterDefinition', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAllActiveBlotters = async () => {
    try 
    {
        const response = await genericGetWithParameters('Blotter/GetAllActiveBlotters', null);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getBlotterLimits = async (blotterID, page, items) => {
  try {
      const params = {
          'BlotterID': blotterID,
          'Page': page,
          'Items': items
      };
      const response = await genericGetWithParameters('Blotter/GetBlotterLimits', params);
      return response;
  } catch (error) {
      console.error(error);
  }
}

export const saveBlotterLimit = async (model) => {
  try {
      const response = await genericCallWithBody('POST', 'Blotter/SaveBlotterLimit', model);
      return response;
  } catch (error) {
      console.error(error);
  }
}

export const deleteBlotterLimit = async (blotterID, currencyID, updateToken) => {
  let params = {
      "BlotterID"   : blotterID,
      "UpdateToken" : updateToken,
      "CurrencyID"  : currencyID
  }
  let response = await genericDeleteWithParameters('Blotter/DeleteBlotterLimit',params);
  return response;
}
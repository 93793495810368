import React from 'react';

//import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';

import {getStyle} from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import LocalAccountForm from '../../pre_trade/customers/directDebitAccounts/LocalAccountForm';
import GenericBankAccountForm from '../../pre_trade/customers/directDebitAccounts/GenericBankAccountForm';
import BankAccountPropertiesForm from '../../pre_trade/customers/directDebitAccounts/BankAccountPropertiesForm';
import NostroAccountInformationForm from './NostroAccountInformationForm';
import { getPersonBankAccounts, saveBankAccount, getBankAccountsByAccountID, saveNostroAccount, getNostroAccountsByCurrencyMin } from '../../../networking/NetworkingBankAccounts';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getGUID } from '../../../networking/NetworkingGlobalSystemParameters';
import {CustomerBankAccountType, NostroAccount} from '../../../constants/EntityTypeNames';
import {Breadcrumb} from 'antd';

var style = getStyle();



export default class NostroAccountMaintenanceScreen extends React.Component {
    state = {
        customer : {},
        activeTab: "localAccount",
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        bankAccounts: [],
        localAccount: null,
        intermediary: null,
        correspondent: null,
        loading: true,
        isValidatedLocalAccount: false,
        isValidatedIntermediaryAccount: true,
        validateLocalAccountFields: true,
        validateIntermediaryAccountFields: true,
        saveProperties: false,

        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteHoliday: {},
        totalPages: 0,
        currentPage: 1,
        bankAccountID: 0,

        allNostroAccounts: [],
        localAccountErrors: [],
        intermediaryAccountErrors: [],
        showSaveConfirmation: false,
        defaultAccountOld: false
    }

    componentDidMount() {
        getPersonBankAccounts(this.props.nostroAccountGuid/*this.props.personID*/, 0, 0).then(
            (json) => {
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                console.log(pages);
                this.setState({ allNostroAccounts: json.bankAccounts, totalPages: pages, newSearch: false });
            }
        )
        
        if (this.props.data !== null && this.props.data.BankAccountID !== undefined) {
            getBankAccountsByAccountID(this.props.data.BankAccountID).then(
                (json) => {
                    if (json !== undefined && json["root"] !== undefined) {
                        var localAccount = {
                            CountryID   : json["root"][0].CountryID,
                            ABANo: json["root"][0]["ABA No"],
                            AccountNo: json["root"][0]["Account No"],
                            BankID: json["root"][0]["Bank ID"],
                            BankName: json["root"][0]["Bank Name"],
                            BranchID: json["root"][0]["Branch ID"],
                            BranchName: json["root"][0]["Branch Name"],
                            CreatedBy: json["root"][0].CreatedBy,
                            CreatedOn: json["root"][0].CreatedOn,
                            Currency: json["root"][0].Currency,
                            DefaultAccount: json["root"][0].DefaultAccount,
                            Description: json["root"][0].Description,
                            IsSettlementAccount: json["root"][0].IsSettlementAccount,
                            PublishOnline: json["root"][0].PublishOnline,
                            //Status: json["root"][0].Status,
                            Status: json["root"][0].Active ? "Active": "Inactive",
                            Swift: json["root"][0].SWIFT,
                            TransitNo: json["root"][0]["Transit No"],
                            UpdateToken: json["root"][0].UpdateToken,
                            UpdatedBy: json["root"][0].UpdatedBy,
                            UpdatedOn: json["root"][0].UpdatedOn,
                            //ShowAs: json["root"][0].ShowAs,
                            UserID: localStorage.getItem('UserID'),
                        };

                        let defaultAccountOld =  json["root"][0].DefaultAccount;
                        var intermediary = JSON.parse(json["root"][0].Intermediate);
                        var correspondent = JSON.parse(json["root"][0].Correspondent);
                        correspondent = correspondent === null ? null : correspondent[0];
                        intermediary = intermediary === null ? null : intermediary[0];
                        if (intermediary !== null){
                            intermediary = {
                                ABANo: intermediary["ABA No"],
                                AccountNo: intermediary["Account No"],
                                BankAccountID: intermediary["BankAccount ID"],
                                BankID: intermediary["Bank ID"],
                                BankName: intermediary["Bank Name"],
                                BranchID: intermediary["Branch ID"],
                                BranchName: intermediary["Branch Name"],
                                Swift: intermediary["SWIFT"],
                                TransitNo: intermediary["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        if (correspondent !== null) {
                            correspondent = {
                                ABANo: correspondent["ABA No"],
                                AccountNo: correspondent["Account No"],
                                BankAccountID: correspondent["BankAccount ID"],
                                BankID: correspondent["Bank ID"],
                                BankName: correspondent["Bank Name"],
                                BranchID: correspondent["Branch ID"],
                                BranchName: correspondent["Branch Name"],
                                Swift: correspondent["SWIFT"],
                                TransitNo: correspondent["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        this.setState({
                            localAccount: localAccount,
                            intermediary: intermediary,
                            correspondent: correspondent,
                            bankAccountID: this.props.data.BankAccountID,
                            loading: false,
                            defaultAccountOld: defaultAccountOld
                        });
                    } else {
                        this.setState({
                            bankAccountID: 0,
                            loading: false
                        });
                    }
                }
            )
        } else {
            this.setState({
                bankAccountID: 0,
                loading: false
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.data !== prevProps.data) {
            getPersonBankAccounts(this.props.nostroAccountGuid/*this.props.personID*/, 0, 0).then(
                (json) => {
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    console.log(pages);
                    this.setState({ allNostroAccounts: json.bankAccounts, totalPages: pages, newSearch: false });
                }
            );
            
            getBankAccountsByAccountID(this.props.data.BankAccountID).then(
                (json) => {
                    if (json !== undefined && json["root"] !== undefined) {
                        var localAccount = {
                            CountryID   : json["root"][0].CountryID,
                            ABANo: json["root"][0]["ABA No"],
                            AccountNo: json["root"][0]["Account No"],
                            BankID: json["root"][0]["Bank ID"],
                            BankName: json["root"][0]["Bank Name"],
                            BranchID: json["root"][0]["Branch ID"],
                            BranchName: json["root"][0]["Branch Name"],
                            CreatedBy: json["root"][0].CreatedBy,
                            CreatedOn: json["root"][0].CreatedOn,
                            Currency: json["root"][0].Currency,
                            DefaultAccount: json["root"][0].DefaultAccount,
                            Description: json["root"][0].Description,
                            IsSettlementAccount: json["root"][0].IsSettlementAccount,
                            PublishOnline: json["root"][0].PublishOnline,
                            Status: json["root"][0].Active ? "Active": "Inactive",
                            Swift: json["root"][0].SWIFT,
                            TransitNo: json["root"][0]["Transit No"],
                            UpdateToken: json["root"][0].UpdateToken,
                            UpdatedBy: json["root"][0].UpdatedBy,
                            UpdatedOn: json["root"][0].UpdatedOn,
                            UserID: localStorage.getItem('UserID')
                        };
                        let defaultAccountOld =  json["root"][0].DefaultAccount;

                        var intermediary = JSON.parse(json["root"][0].Intermediate);
                        var correspondent = JSON.parse(json["root"][0].Correspondent);
                        correspondent = correspondent === null ? null : correspondent[0];
                        intermediary = intermediary === null ? null : intermediary[0];
                        if (intermediary !== null){
                            intermediary = {
                                ABANo: intermediary["ABA No"],
                                AccountNo: intermediary["Account No"],
                                BankAccountID: intermediary["BankAccount ID"],
                                BankID: intermediary["Bank ID"],
                                BankName: intermediary["Bank Name"],
                                BranchID: intermediary["Branch ID"],
                                BranchName: intermediary["Branch Name"],
                                Swift: intermediary["SWIFT"],
                                TransitNo: intermediary["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        if (correspondent !== null) {
                            correspondent = {
                                ABANo: correspondent["ABA No"],
                                AccountNo: correspondent["Account No"],
                                BankAccountID: correspondent["BankAccount ID"],
                                BankID: correspondent["Bank ID"],
                                BankName: correspondent["Bank Name"],
                                BranchID: correspondent["Branch ID"],
                                BranchName: correspondent["Branch Name"],
                                Swift: correspondent["SWIFT"],
                                TransitNo: correspondent["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        this.setState({
                            localAccount: localAccount,
                            intermediary: intermediary,
                            correspondent: correspondent,
                            bankAccountID: this.props.data.BankAccountID,
                            loading: false,
                            defaultAccountOld: defaultAccountOld
                        });
                    } else {
                        this.setState({
                            bankAccountID: 0,
                            loading: false
                        });
                    }
                }
            );

        }
    }

    handleBackLink = () => {
        this.setState({
            localAccount: null,
            intermediary: null,
            correspondent: null,
            loading: true
        }, () => this.props.backLink());        
    }

    handleSaveClick = async(event) => {

            if (this.state.isValidatedLocalAccount && this.state.isValidatedIntermediaryAccount) {
                
                if(this.state.localAccount.Status === 'Inactive')
                {
                    
                    let bankAccountsByCCY = await getNostroAccountsByCurrencyMin(this.state.localAccount.Currency, null, false);
                    if(bankAccountsByCCY && bankAccountsByCCY.bankAccounts)
                    {
                        let bankAccountsSameCCY = bankAccountsByCCY.bankAccounts.filter(b => b["Bank Account ID"] !== this.props.data.BankAccountID);

                        if(!bankAccountsSameCCY || bankAccountsSameCCY.length === 0)
                        {
                            this.setState({showSaveConfirmation : true});
                        }
                        else
                        {
                            if(this.state.defaultAccountOld)
                            {
                                this.setState({
                                    showAlertError: true,
                                    alertMessage: 'Inactivating this account there will be no default account for this currency. First set another account with the same currency as default before inactivating this one.',
                                    alertTitle: 'Error'});
                            }
                            else
                            {                            
                                this.Save();
                            }
                        }
                    } 
                    else
                            {                            
                                this.Save();
                            }
                }
                else
                {
                    this.Save();
                }
        } else {
            if (!this.state.isValidatedLocalAccount && !this.state.isValidatedIntermediaryAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateLocalAccountFields: false,
                    validateIntermediaryAccountFields: false
                });
            } else if (!this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateLocalAccountFields: false
                });
            } else if (!this.state.isValidatedIntermediaryAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateIntermediaryAccountFields: false
                });
            }
        }
        
    }

    Save = () =>{
        this.setState({loading: true});
        var intermediary = null;

                if (this.state.intermediary !== null && (this.state.intermediary.BankID !== "select" && this.state.intermediary.BankID !== '' && this.state.intermediary.BankID !== undefined)) {
                    intermediary = {
                        ABANo: this.state.intermediary.ABANo,
                        AccountCurrencyID: null,
                        AccountType: "Intermediate",
                        Active: true,
                        BankAccount: this.state.intermediary.AccountNo,
                        BankAccountID: this.props.data === null ? null: this.state.intermediary.BankAccountID,
                        BankAccountParentID: null,
                        BankBranchID: this.state.intermediary.BranchID,
                        BankID: this.state.intermediary.BankID,
                        CreatedOn: null,
                        DefaultAccount: false,
                        DefaultDeliveryMethod: null,
                        Description: "",
                        GLAccountID: 0,
                        IsSettlementAccount: false,
                        PersonID: this.props.nostroAccountGuid/*personID*/,
                        PublishOnline: false,
                        Swift: this.state.intermediary.Swift,
                        TransitNo: this.state.intermediary.TransitNo,
                        UpdateToken: this.state.intermediary.UpdateToken,
                        UserID: localStorage.getItem('UserID')
                    }
                }
                var model = {
                    localAccount: {
                        ABANo: this.state.localAccount.ABANo,
                        AccountCurrencyID: this.state.localAccount.Currency,
                        AccountType: "Actual / Local",
                        Active: this.state.localAccount.Status === "Active",
                        BankAccount: this.state.localAccount.AccountNo,
                        BankAccountID: this.props.data === null ? '': this.props.data.BankAccountID,
                        BankAccountParentID: null,
                        BankBranchID: this.state.localAccount.BranchID,
                        BankID: this.state.localAccount.BankID,
                        CreatedOn: this.state.localAccount.CreatedOn,
                        DefaultAccount: this.state.localAccount.Status === "Inactive"? false: this.state.localAccount.DefaultAccount,
                        DefaultDeliveryMethod: null,
                        Description: this.state.localAccount.Description,
                        GLAccountID: 0,
                        IsSettlementAccount: this.state.localAccount.IsSettlementAccount,
                        PersonID: this.props.nostroAccountGuid/*personID*/,
                        PublishOnline: this.state.localAccount.PublishOnline,
                        Swift: this.state.localAccount.Swift,
                        TransitNo: this.state.localAccount.TransitNo,
                        UpdateToken: this.state.localAccount.UpdateToken,
                        //ShowAs: this.state.localAccount.ShowAs,
                        UserID: localStorage.getItem('UserID')
                    },
                    intermediateAccount: intermediary,
                    correspondantAccount: null
                }
                console.log(model);
                saveNostroAccount(model).then(
                    (json) => {
                        if (json.httpStatusCode !== 200) {
                                var alertMsg = 'An error occurred while trying to save the bank account. Please try again.'
                                if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                                    alertMsg = json.httpErrorMessage;
                                }
                            this.setState({showAlertError: true, alertMessage: alertMsg, alertTitle: 'Error'});
                        } else {
                            this.setState({saveProperties: true},
                                    () => {
                                        this.setState({
                                            showAlert: true, alertTitle: 'Success', alertMessage: 'Nostro account successfully saved.',
                                            localAccount: {...this.state.localAccount, UpdateToken: json.updateToken}
                                        });
                                        this.props.updatePage();
                                    }
                                );
                        }
                    }
                )
        this.setState({loading: false});
            
    }

    isValidatedLocalAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedLocalAccount: true,
                validateLocalAccountFields: true,
                localAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedLocalAccount: false,
                localAccountErrors: {tab: "Main Account", errors: validator.errors}
            });
        }
    }

    isValidatedIntermediaryAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedIntermediaryAccount: true,
                validateIntermediaryAccountFields: true,
                intermediaryAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedIntermediaryAccount: false,
                intermediaryAccountErrors: {tab: "Intermediary Account", errors: validator.errors}
            });
        }
    }

    getLocalAccount = (localAccount) => {
        this.setState({localAccount: localAccount});
    }

    getIntermediaryAccount = (intermediaryAccount) => {
        this.setState({intermediary: intermediaryAccount});
    }

    getSaveProperties = (value) => {
        this.setState({saveProperties: value});
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''}, () => this.props.backLink());
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: '', validateLocalAccountFields: true, validateIntermediaryAccountFields: true});
    }

    yesSave= () =>{
        this.Save();
    }

    noSave = () => {
        this.setState({showSaveConfirmation: false});
    }

    render(){
        if (this.state.loading) {
            return (<div></div>);
        }
        var nostroTitle=(        
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    Nostro Account Maintenance
                </h3>)

        if (this.props.mainTitle != null) {
            nostroTitle = ('');            
        }

        var nostroScreen = (
            <div>
                {nostroTitle}
                <ul uk-tab="">
                    <li>
                        <a href="#">Account Information</a>
                    </li>
                    <li>
                        <a href="#">Intermediary Account</a>
                    </li>
                    <li>
                        <a href="#">Properties</a>
                    </li>
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li>
                        <NostroAccountInformationForm data={this.state.localAccount} getLocalAccount={this.getLocalAccount} isValid={this.isValidatedLocalAccount} validated={this.state.validateLocalAccountFields} />
                    </li>
                    <li>
                        <GenericBankAccountForm data={this.state.intermediary} getBankAccount={this.getIntermediaryAccount} isValid={this.isValidatedIntermediaryAccount} validated={this.state.validateIntermediaryAccountFields} name={"Intermediary Account"} />
                    </li>
                    <li>
                        <BankAccountPropertiesForm getSaveProperties={this.getSaveProperties} bankAccountID={this.state.bankAccountID} saveProperties={this.state.saveProperties} subEntityType={NostroAccount} />
                    </li>
                </ul>
                <button className="uk-button uk-button-green" onClick={this.handleSaveClick}>Save</button>
                {/*<NewDynamicTable
                id="tasks-table"
                data={this.state.allNostroAccounts}
                hiddenHeaders={['Holiday Id']}
                enableClick={true} 
                clickFunction={this.handleRowClick} 
                newSearch={this.state.newSearch} 
                useDeleteButton={true}
                deleteFunction={this.handleDeleteClick}
                useFilter={true} 
                filterFunction={this.getAllTradingHolidays}
                usePaginator={true}
                changePage={this.changePage}
                numberOfPages={this.state.totalPages}
                pageNumber = {this.state.currentPage}
                numberPerPage = {getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}/>*/}
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.localAccountErrors, this.state.intermediaryAccountErrors]} type="Ok" okClick={this.closeAlertError} />
                <AlertBox id="savee-alert"
                    type="Yes/No"
                    open={this.state.showSaveConfirmation}
                    title="Warning"
                    message="There are no more active nostros  with this currency. Do you want to continue?"
                    yesClick={this.yesSave}
                    noClick={this.noSave} />
            </div>
        )
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Nostro Account Maintenance</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                {nostroScreen}
            </div>);
    }
}
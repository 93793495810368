import React from 'react';

import {useJsonToCsv} from 'react-json-csv';

import MultipleSelect from '../shared/MultipleSelect';
import NewDynamicTable from '../shared/NewDynamicTable';

import { IgrDataGridModule, IgrTextColumn, IgrColumnGroupDescription, IgrGridColumnOptionsModule, HeaderClickAction, IgrHeader, IgrTemplateHeaderCellUpdatingEventArgs, IgrTemplateHeader   } from 'igniteui-react-grids';
import { IgrDataGrid } from 'igniteui-react-grids';
import { DataSourceSectionHeaderDisplayMode } from 'igniteui-react-core';

import 'react-widgets/dist/css/react-widgets.css';

import {getBlotters} from '../../networking/NetworkingBlotters';
import {getListOfBranch} from '../../networking/NetworkingUsers';
import {getFXPositionByBlottersID, getListofDeals, exportPosition} from '../../networking/NetworkingPosition';

import {getStyle} from '../../styles/styles';
import WizardScreen from '../trade/wizard/WizardScreen';
import PositionMonitorTable from './PositionMonitorTable';

var style = getStyle();
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class PositionMonitor extends React.Component {
    //public grid: IgrDataGrid;
    constructor(props){
        super(props);
        this.HorizontalRightHeader = new IgrTemplateHeader({});
        this.HorizontalRightHeader.cellUpdating = (s, e) => this.onHorizontalHeaderUpdating(s, e, "right");
    }
    state = {
        blotters: [],
        branches: [],
        positionData: [],
        blottersValue: [],
        branchesValue: [],
        blottersString: '',
        columns: [],
        columnsHelper: [],
        openDealsTable: false,
        dealsTableCurrency: '',
        dealsTableDateFrom: '',
        dealsTableDateTo: '',
        currentScreen: 'position',
        blotterIds: ''
    }

    componentDidMount = () => {
        getBlotters(0,0).then((value)=>{
            let blottersArray = value.blotters;
            let helperDefault = [];
            let stringHelper = [];
            blottersArray.forEach(element => {
                if(element.ParentBlotterID === null){
                    helperDefault.push(element);
                    stringHelper.push(element.BlotterID)
                }
            });
            let blottersID = stringHelper.join(',');
            this.setState({blotters: blottersArray, blottersValue: helperDefault, blottersString: blottersID}, ()=>{
                this.onBlottersChange(helperDefault);
                //this.callFxPosition();
                //if(helperDefault.count > 0){
                    var posFunc = setInterval(this.callFxPosition,5000);
                    this.setState({positionFunc: posFunc});
               //}
            });
        });
        getListOfBranch().then((value)=>{
            this.setState({branches: value.branches});
        });
    }

    onBranchesChange = (value) => {
        this.setState({branchesValue: value});
    }

    onBlottersChange = (value) => {

        let helper = [];
        value.forEach(element => {
            helper.push(element.BlotterID);
        });

        
        let blottersID = helper.join(',');
        this.setState({blottersValue: value, blottersString: blottersID});
        getFXPositionByBlottersID(blottersID).then(response => {
            console.log(response);
            if (response.data) {
                this.defineColumns(response.data);
                this.setState({positionData: response.data});
                let groupCurrency = new IgrColumnGroupDescription();
                groupCurrency.field = "Currency";
                groupCurrency.displayName = "";
                this.grid.groupDescriptions.add(groupCurrency);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
             /*  if(this.state.positionFunc){
                    clearInterval(this.state.positionFunc);
                } */             
            }
            else
            {
                this.setState({positionData: []});
            }
           /* var posFunc = setInterval(this.callFxPosition,5000);
                this.setState({positionFunc: posFunc});*/
        })
    }

    componentWillUnmount(){
            clearInterval(this.state.positionFunc);
      }

    callFxPosition = () => {
        getFXPositionByBlottersID(this.state.blottersString).then(response => {
            if (response.data) {
                //this.defineColumns(response.data);
                //this.setState({positionData: response.data});
                //let groupCurrency = new IgrColumnGroupDescription();
                //groupCurrency.field = "Currency";
                //groupCurrency.displayName = "";
                //this.grid.groupDescriptions.add(groupCurrency);
                //this.grid.isGroupCollapsable = true;
                //this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
                //this.grid.actualDataSource.queueAutoRefresh();
                let groupCurrency = new IgrColumnGroupDescription();
                groupCurrency.field = "Currency";
                groupCurrency.displayName = "";
                this.grid.groupDescriptions.add(groupCurrency);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
                let newData = response.data;
                for (let i = 0; i < this.state.positionData.length; i++)
                {
                    let item = this.state.positionData[i];
                    this.grid.notifyRemoveItem(i, item);
                    //item = newData[i];
                    this.grid.notifyInsertItem(i, newData[i]);
                }
                //this.setState({loadScreen: true});
                this.setState({positionData: response.data});
                this.grid.actualDataSource.queueAutoRefresh();
                this.grid.invalidateVisibleRows();
            }
            else
            {
                this.setState({positionData: []});

            }
        });
    }

    defineColumns = (data) => {
        let columnHelper = [];
        let keys = Object.keys(data[0]);
        keys.forEach(element => {
            if (!element.includes('$')) {
                if(element === 'Currency' || element === 'Description' || element === 'Total'){
                    if(element === 'Currency') {
                        //columnHelper.push({key: element, name: '', lock: true});
                    } else {
                        columnHelper.push({key: element, name: capitalize(element), lock: true});
                    } 
                } else {
                    columnHelper.push({key: element, name: capitalize(element), lock: false});
                }
            }
        });

        let columnDirectives = [];
        this.setState({columnsHelper: columnHelper});
        console.log(columnHelper);
        columnHelper.forEach(element => {
            //columnDirectives.push(<ColumnDirective key={element.key} field={element.key} width='200' textAlign="Left" headerText={element.name} />) 
            if (element.lock) {
                if(element.key === "Total"){
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="200" horizontalAlignment="right"/>);
                } else {
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="200"/>);
                }
                
            } else {
                columnDirectives.push(<IgrTextColumn key={element.key} field={element.key} headerText={element.name} header={this.HorizontalRightHeader} width="200" horizontalAlignment="right"/>);
            }
            
        })

        this.setState({columns: columnDirectives});
    }

    onGridRef = (grid) => {
        if (!grid) { return; }

        let groupCurrency = new IgrColumnGroupDescription();
        groupCurrency.field = "Currency";
        groupCurrency.displayName = "";
        grid.groupDescriptions.add(groupCurrency);
        grid.isGroupCollapsable = true;
        grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
        this.grid = grid;
        //this.grid.actualDataSource.isSectionExpandedDefault = true;
    }

    onHorizontalHeaderUpdating = (s, e, align) => {
        const content = e.content; //as HTMLDivElement;
        let label = null;
        if (content.childElementCount === 0) {
            content.style.lineHeight = "25px";
            label = document.createElement("div");
            label.style.background = "transparent";
            label.style.color = "rgb(24, 29, 31)";
            label.style.overflowWrap = "break-word"
            //label.style.fontSize = "13px";
            //label.style.fontFamily = "Verdana";
            label.style.verticalAlign = "center";
            label.style.textAlign = align;
            content.appendChild(label);
        } else {
            label = content.children[0] ; //as HTMLDivElement;
        }

        const info = e.cellInfo; // as IgrTemplateCellInfo;
        label.textContent = info.value;
    }

    onCellClicked = (grid, cellEvent) => {
        let cell = cellEvent.cellInfo.rowItem
        let column = this.state.columnsHelper[Math.floor(cellEvent.cellInfo.x/200)];
        let dateRange = column.name.substring(column.name.lastIndexOf('(')+1, column.name.lastIndexOf(')'));
        let date;
        if(dateRange.includes(' to ')){
            date = dateRange.split(' to ');
        } else {
            date = [dateRange, dateRange];
        }

        if(dateRange === ''){
            date = [column.name.substring(column.name.lastIndexOf('Y')+2, column.name.length), null];
        }

        if(cell && cell.Currency && cell.Currency !== ' Totals') {
            console.log(cell.Currency);
            console.log(date);

            this.setState({
                dealsTableCurrency: cell.Currency,
                dealsTableDateFrom: date[0],
                dealsTableDateTo: date[1],
                openDealsTable: true,
            })

            /*getListofDeals(cell.Currency, date[0], date[1]).then((response) => {
                console.log(response);
            })*/
        }
    }

    onCloseDealsTable = () => {
        this.setState({
            dealsTableCurrency: '',
            dealsTableDateFrom: '',
            dealsTableDateTo: '',
            openDealsTable: false,
        })
    }

    showWizardScreen = () => {
        this.setState({currentScreen: 'trade'});
    }

    returnToPosition = () => {
        this.setState({currentScreen: 'position'});
    }

    exportPosition = () => {    
        let systemDate = localStorage.getItem('SystemDate');
        let blotterID = this.state.blottersValue[0].BlotterID;
        let branchID = 1;
        if(this.state.branchesValue.length > 0) {
            branchID = this.state.branchesValue[0].BranchId;
        }

        exportPosition(branchID, blotterID, systemDate).then((response) => {
            console.log(response);
            let data = response.data;
            let fields = {...data[0]};
            let keys = Object.keys(fields);
            keys.forEach(element => {
                fields[element] = element;
            });
            
            const {saveAsCsv} = useJsonToCsv();
            
            let today = new Date();
            let yearString = today.getFullYear().toString();
            let monthString = (today.getMonth()+1).toString().padStart(2, '0');
            let dayString = today.getDate().toString().padStart(2, '0');
            let hourString = today.getHours().toString().padStart(2, '0');
            let minuteString = today.getMinutes().toString().padStart(2, '0');
            let timeStamp = yearString + monthString + dayString + hourString + minuteString;
            let filename = 'PositionMonitor_' + timeStamp;
            saveAsCsv({data, fields, filename});
        });

    }


    render = () => {
        let groupOptions = {
            columns: ['Currency'],
            showDropArea: false
        };

        

        let groupBy = ["Currency"];
        let enters = [];
        let lengthOfData = 0;
        let data = [];
        let groupedRows = [];
        let columnData = this.state.columns;
        let columnDirectives = [];
        if(this.state.positionData.length === 0){
            enters.push(<div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>)
        } else {
            lengthOfData = this.state.positionData.length;
            data = this.state.positionData;
            //////debugger
            //groupedRows = Data.Selectors.getRows({ rows: data, groupBy: groupBy });
            //////debugger
        }

        return(
            <div>
                {this.state.currentScreen === 'position' ? (
                    <div>
                        <h3 className="uk-heading-divider" style={style.titleStyle}>Position Monitor</h3>
                        <div>
                            <form className="uk-form-stacked uk-grid">
                                <div className="uk-width-4-12" >
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-bank-name">Branch Selection</label>
                                        <div className="uk-form-controls">
                                            <MultipleSelect data={this.state.branches} textField='Description' valueField='BranchId' onChange={this.onBranchesChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-12"/>
                                <div className="uk-width-4-12" >
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-bank-name">Blotter Selection</label>
                                        <div className="uk-form-controls">
                                            <MultipleSelect data={this.state.blotters} textField='Description' valueField='BlotterID' value={this.state.blottersValue} onChange={this.onBlottersChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-3-12">
                                    <div className="uk-margin">
                                        <button style={{marginTop: '10px'}} className="uk-button uk-button-green" type="button" onClick={this.showWizardScreen}>Create Cover Deal</button>
                                        <button style={{marginTop: '10px'}} className="uk-button uk-button-green" type="button" onClick={this.exportPosition}>Export</button>`
                                    </div>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <IgrDataGrid
                        height="80vh"
                        width="100%"
                        headerHeight="50"
                        dataSource={data}
                        ref={this.onGridRef}
                        autoGenerateColumns="false"
                        defaultColumnMinWidth="200"
                        isColumnOptionsEnabled="false"
                        headerClickAction={HeaderClickAction.None}
                        editMode="None"
                        //summaryScope="Root"
                        //isColumnOptionsEnabled="true"
                        //isGroupCollapsable="true"
                        //groupHeaderDisplayMode="Combined"
                        //groupSummaryDisplayMode="RowBottom"
                        //columnMovingMode="Deferred"
                        //columnMovingAnimationMode="SlideOver"
                        //columnMovingSeparatorWidth="2"
                        //columnShowingAnimationMode="slideFromRightAndFadeIn"
                        //columnHidingAnimationMode="slideToRightAndFadeOut"
                        cellClicked={this.onCellClicked}
                        selectionMode="SingleCell"
                        cornerRadiusTopLeft="0"
                        cornerRadiusTopRight="0"
                        >
                            {columnData}
                        </IgrDataGrid>
                        <PositionMonitorTable open={this.state.openDealsTable} onClose={this.onCloseDealsTable} currencyID={this.state.dealsTableCurrency} dateFrom={this.state.dealsTableDateFrom} dateTo={this.state.dealsTableDateTo}/>
                    </div>
                ) : (<WizardScreen parentScreen='Position Monitor' returnToParent={this.returnToPosition}/>)}
            </div>
        );
    }
}
import React from 'react';

import { getStyle } from '../../../styles/styles';
import DynamicScreenForm from '../../shared/DynamicScreen/DynamicScreenForm';

var style = getStyle();

export default class DynamicScreen extends React.Component {
    state = {
        objJson:[
            {label: 'Country', value:''},
            {label: 'Status', value:''},
            {label: 'Name', value:''},
            {label: 'Amount', value:''},
            {label: 'Date', value:''}
        ],
        title:'Test'
    }


    render(){

        return(
            <DynamicScreenForm title={this.state.title} objJson={this.state.objJson}/>
        );
    }
}
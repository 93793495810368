import {genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncryptedAndNoJsonConvert} from './Networking';

export async function generateReport(reportName, parameters) {
    try {
        let params = {
            reportName: reportName,
            parameter: parameters
        };

        let response = await genericCallWithBody('POST', 'Report/GenerateReport', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutstandingForwardsReport(email, customerId, fromTradeDate, toTradeDate, fromValueDate, toValueDate, separator){
    try {
        /*GET api/Report/GetOutstandingForwardsReport?CustomerId={CustomerId}&FromTradeDate={FromTradeDate}&ToTradeDate={ToTradeDate}&FromValueDate={FromValueDate}&ToValueDate={ToValueDate}&Separator={Separator}*/
        let params = {
            Email:email,
            NameLike: customerId,
            FromTradeDate: fromTradeDate,
            ToTradeDate: toTradeDate,
            FromValueDate: fromValueDate,
            ToValueDate: toValueDate,
            Separator: separator
        }

        let response = await genericGetWithParametersNoEncryptedAndNoJsonConvert('Report/GetOutstandingForwardsReport',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getVostroStatementReport(customerId, fromTradeDate, toTradeDate){
    try {
        let params = {
            CustomerID: customerId,
            FromTradeDate: fromTradeDate,
            ToTradeDate: toTradeDate,
        }

        let response = await genericGetWithParameters('Report/GetVostroStatementReport', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
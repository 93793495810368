import { Breadcrumb } from 'antd';
import React, { useState } from 'react';
import CustomerOverview from '../shared/CustomerOverview';
import WizardSearchCustomerForm from '../trade/wizard/WizardSearchCustomerForm';

const CustomerOverviewScreen = () => {
    const [customerID, setCustomerID] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [activeTab, setActiveTab] = useState(1);

    const onCustomerSelected = (objCustomer) => {
        setCustomerID(objCustomer['Customer ID']);
        setCustomerName(objCustomer['Customer Name']);
        setActiveTab(2);
    }

    const onBackClick = () => {
        setCustomerID(0);
        setCustomerName('');
        setActiveTab(1);

    }

    const handleActiveTab = (tab) => {
        return activeTab === tab ? 'uk-active' : '';
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className={activeTab === 1 ? 'breadcrumb-item-bold' : 'breadcrumb-item'} onClick={onBackClick}>Customer Overview</Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-item-bold">{customerName}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <ul className="uk-tab">
                <li className={handleActiveTab(1)}><a onClick={() => onBackClick()}>Search Customer</a></li>
                <li className={handleActiveTab(2)}><a>Overview</a></li>
            </ul>
            {activeTab === 1 && <WizardSearchCustomerForm onCustomerSelected={onCustomerSelected} />}
            {activeTab === 2 && <CustomerOverview customerID={customerID} onBackClick={onBackClick} />}
        </div>
    );
}

export default CustomerOverviewScreen;
import React from 'react';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getReferredOutgoingFunds, saveReferredDealComment } from '../../../networking/NetworkingCustomerLimitProfile';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import NewDynamicTable from '../../shared/NewDynamicTable';

var style = getStyle();

export default class OutgoingFundsForm extends React.Component {
    state = {
        outgoingFunds: [],
        referredDealId: 0,
        referredDealNo: 0,
        referredCustomer: '',
        referredValueDate: '',
        referredOutgoingValueDate: '',
        referredAmount: 0,
        referredCurrency: 0,
        referredCountry: '',
        referredTraderName: '',
        referredReason: '',
        referredOutgoingFundId:0,
        referredBeneficiary:'',
        outgoingFundComment: '',
        userId: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        getReferredOutgoingFunds(0, 0).then(
            (value) => {
                if (value != null && value.referreadDeals != null) {
                    const outgoingFunds = value.referreadDeals
                    const totalCount = value.totalCount;
                    const pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({outgoingFunds: outgoingFunds, totalPages: pages, newSearch: true});
                } else {
                    this.setState({outgoingFunds: [], newSearch: true});
                }
            }
        );
    }

    handleRowClick(obj){
        console.group("Referred Deal handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({
            referredDealId: obj["ID"],
            referredDealNo: obj["Deal No."],
            referredCustomer: obj["Customer Name"],
            referredOutgoingValueDate: obj["Outgoing Value Date"],
            referredValueDate: obj["Deal Value Date"],
            referredAmount: obj["Amount"],
            referredCurrency: obj["Currency"],
            referredCountry: obj["Country"],
            referredTraderName: obj["Trader Name"],
            referredReason: obj["Referral Reasons"],
            referredOutgoingFundId: obj["OutgoingFundsID"],
            referredBeneficiary: obj["Beneficiary"],
            outgoingFundComment: ''
        });
    }
/*
    changePage(value){
        this.setState({currentPage: value});
        getReferredOutgoingFunds(value,getDefaultPagingNumberOfItems()).then(
            (value) => {
                this.setState({outgoingFunds: value.referreadDeals});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        );
    }
*/
    updatePage(){
        getReferredOutgoingFunds(0, 0).then(
            (value) => {
                this.setState({outgoingFunds: value.referreadDeals});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:false});
            }
        );
    }

    handleAddCommentClick(){
        let userID = localStorage.getItem('UserID');
        var model = {
            customerProfileDealViolationId: this.state.referredDealId,
            comment: this.state.outgoingFundComment,
            userId: userID
        }
        if(this.state.outgoingFundComment !== '' && this.state.outgoingFundComment !== undefined && this.state.outgoingFundComment !== null){
            saveReferredDealComment(model).then((json)=>
            {
                if (json !== undefined) {
                    if (json.httpStatusCode !== 200) {
                      this.setState({alertTitle: 'Error',
                        alertMessage:json.httpErrorMessage,
                        showAlertOK: true});
                    }
                  else {
                this.setState({
                    referredDealId: 0,
                    referredDealNo: 0,
                    referredCustomer: '',
                    referredValueDate: '',
                    referredOutgoingValueDate: '',
                    referredAmount: 0,
                    referredCurrency: 0,
                    referredCountry: '',
                    referredTraderName: '',
                    referredReason: '',
                    outgoingFundComment:'',
                    referredBeneficiary:'',
                    referredOutgoingFundId:0,
                    alertTitle: 'Success',
                    alertMessage:'Comment successfully saved.',
                    showAlertOK: true,});
                this.updatePage();       
                  }        
                } 
            })
        }
    }

    handleUpdateOutgoingFundComment(value){
        this.setState({outgoingFundComment: value.target.value});
    }
    
    handleUpdateReferredDealId(value){
        this.setState({referredDealId: value.target.value});
    }

    handleUpdateReferredDealNo(value){
        this.setState({referredDealNo: value.target.value});
    }

    handleUpdateReferredCustomer(value){
        this.setState({referredCustomer: value.target.value});
    }

    handleUpdateReferredBeneficiary(value){
        this.setState({referredBeneficiary: value.target.value});
    }

    handleUpdateReferredValueDate(value){
        this.setState({referredValueDate: value.target.value});
    }

    handleUpdateReferredOutgoingValueDate(value){
        this.setState({referredOutgoingValueDate: value.target.value});
    }

    handleUpdateReferredAmount(value){
        this.setState({referredAmount: value.target.value});
    }

    handleUpdateReferredCurrency(value){
        this.setState({referredCurrency: value.target.value});
    }

    handleUpdateReferredCountry(value){
        this.setState({referredCountry: value.target.value});
    }

    handleUpdateReferredTraderName(value){
        this.setState({referredTraderName: value.target.value});
    }

    handleUpdateReferredReason(value){
        this.setState({referredReason: value.target.value});
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }

    render(){
        var section = '';
        if(this.state.referredOutgoingFundId !== 0){
            section = this.state.referredOutgoingFundId
        }
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Selected Outgoing Fund Information</h3>
                <form className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">ID :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealId" type="text" placeholder="" value={this.state.referredDealId} onChange={this.handleUpdateReferredDealId.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal No. :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealNo" type="text" placeholder="" value={this.state.referredDealNo} onChange={this.handleUpdateReferredDealNo.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.referredCustomer} onChange={this.handleUpdateReferredCustomer.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal Value Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredValueDate" type="text" placeholder="" value={this.state.referredValueDate} onChange={this.handleUpdateReferredValueDate.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Beneficiary :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredBeneficiary" type="text" placeholder="" value={this.state.referredBeneficiary} onChange={this.handleUpdateReferredBeneficiary.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Outgoing Value Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredOutgoingValueDate" type="text" placeholder="" value={this.state.referredOutgoingValueDate} onChange={this.handleUpdateReferredOutgoingValueDate.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Amount :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredAmount" type="text" placeholder="" value={this.state.referredAmount} onChange={this.handleUpdateReferredAmount.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Currency :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredBuyCurrency" type="text" placeholder="" value={this.state.referredCurrency} onChange={this.handleUpdateReferredCurrency.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Country :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredCountry" type="text" placeholder="" value={this.state.referredCountry} onChange={this.handleUpdateReferredCountry.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Trader Name :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredTraderName" type="text" placeholder="" value={this.state.referredTraderName} onChange={this.handleUpdateReferredTraderName.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Referral Reason :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.referredReason} onChange={this.handleUpdateReferredReason.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right uk-bold">
                        <label className="uk-form-label" htmlFor="form-bank-status">Add Comment for Outgoing Fund {section} :</label>
                        <textarea className="uk-textarea" style={{marginTop: "5px"}} id="outgoingFundComment" type="text" placeholder="" value={this.state.outgoingFundComment} onChange={this.handleUpdateOutgoingFundComment.bind(this)}></textarea>
                        {/*<h4>Add Comment for Outgoing Fund {section}</h4>*/}
                        </div>
                    </div>
                    {/*<div className="uk-margin-right">
                        <div className="" >
                            <input className="uk-input" style={{marginTop: "5px"}} id="outgoingFundComment" type="text" placeholder="" value={this.state.outgoingFundComment} onChange={this.handleUpdateOutgoingFundComment.bind(this)}/>                               
                        </div>
                    </div>*/}
                    <div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} disabled={JSON.parse(localStorage.getItem('HasComplianceAccess'))} onClick={this.handleAddCommentClick.bind(this)} type="button">Add</button>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </form>
                <h3 className="uk-heading-divider" style={style.titleStyle}>List of Outgoing Funds</h3>
                <NewDynamicTable 
                formats={[{header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Currency'}]} 
                hiddenHeaders={['ID','Deal Value Date','Trade Name', 'OutgoingFundsID']} 
                data={this.state.outgoingFunds} 
                enableClick={true} 
                clickFunction={this.handleRowClick.bind(this)} 
                newSearch={this.state.newSearch} useDeleteButton={false}/>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
            </div>
        );
    }
}
import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';

import {getLatest10DealsByCustomer} from '../../../networking/NetworkingDeals';
import {formatToCurrency, formatToCurrencyWithScale} from '../../../helpers/FormatHelper';

export default class SelectDealForm extends React.Component {
    state = {
        deals: [],
    }

    componentDidMount = () => {
        if(this.props.customerID !== 0){
            getLatest10DealsByCustomer(this.props.customerID).then((response)=>{
                console.log(response);
                if(response.deals){
                    this.setState({deals: response.deals});
                }
            })
        }
    }

    handleRowClick = (obj) => {
        console.log(obj);
        this.props.finishStep(this.props.customerID, obj["Deal Header ID"]);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.customerID !== prevProps.customerID) {
            getLatest10DealsByCustomer(this.props.customerID).then((response)=>{
                console.log(response);
                if(response.deals){
                    this.setState({deals: response.deals});
                } else {
                    this.setState({deals: []});
                }
            })
        }
    }

    getDeals = async () => {
        if(this.props.customerID !== 0){

            var json = await getLatest10DealsByCustomer(this.props.customerID);

            var helper = json.deals;
            return helper;
        }       
    }

    render = () => {
        return(
            <div>
                <NewDynamicTable
                    id="deals-table"
                    //hiddenHeaders={['Bank ID','CustomerID']} 
                    formats={[{ header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency' }, { header: 'Sell Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Sell Currency' }]}
                    data={this.state.deals} 
                    enableClick={true} 
                    clickFunction={this.handleRowClick} 
                    tableName = "last-deals-customer"
                    useFilter       = {true}
                    filterFunction  = {this.getDeals}
                />
            </div>
        );
    }
}
import {
    genericGetWithParameters, 
    genericCallWithBody, 
    genericDeleteWithParameters, 
    genericCallWithParametersNoEncrypted,
    genericCallWithBodyAndResponseDecoded
} from './Networking';

export async function last10Transactions(customerID)
{
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/Last10Transactions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function topBeneficiaries(customerID)
{
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/TopBeneficiaries', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDashboard(personID)
{
    try {
        let params = {
            PersonID: personID,
        }
        let response = await genericGetWithParameters('Widget/GetDashboard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveDashboard(personID, map)
{
    try {
        let request = {
            PersonID: personID,
            Dashboard: map,
        }
        let response = await genericCallWithBody('POST','Widget/SaveDashboard', request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function last12MonthsTurnover(customerID)
{
    try {
        let params = {
            CustomerID: customerID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/Last12MonthsTurnover', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function rateBoard(personID)
{
    try {
        let params = {
            PersonID: personID,
            ByPass: true
        };
        let response = await genericGetWithParameters('Widget/RateBoard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAvailableWidgets(personID)
{
    try {
        let params = {
            PersonID: personID,
            Channel: 'Backend'
        };
        let response = await genericGetWithParameters('Widget/GetAvailableWidgets', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRateBoard(buyCurrency, sellCurrency, personID)
{
    try {
        let request = {
            BuyCurrency : buyCurrency,
            SellCurrency: sellCurrency,
            PersonID    : personID
        }
        let response = await genericCallWithBodyAndResponseDecoded('POST','Widget/SaveRateBoard', request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteARateOnBoard(buyCurrency, sellCurrency, personID) {
    //DELETE api/Widget/DeleteRateBoard?BuyCurrency={BuyCurrency}&SellCurrency={SellCurrency}&PersonID={PersonID}
    try{
        let params = {
            BuyCurrency : buyCurrency,
            SellCurrency: sellCurrency,
            PersonID    : personID
        }
        let response = await genericDeleteWithParameters('Widget/DeleteRateBoard', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//DESKTOP WIDGETS
export async function salespeoplePerformance(period)
{
    try {
        let params = {
            Period: period
        };
        let response = await genericGetWithParameters('Widget/SalespeoplePerformance', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function myHistoricalPerformance(personID, period)
{
    try {
        let params = {
            PersonID: personID,
            Period  : period
        };
        let response = await genericGetWithParameters('Widget/MyHistoricalPerformance', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function myPerformance(personID, period)
{
    try {
        let params = {
            PersonID: personID,
            Period  : period
        };
        let response = await genericGetWithParameters('Widget/MyPerformance', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
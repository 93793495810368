/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getStyle } from '../../../styles/styles';
import SelectedCurrencyPairForm from './SelectedCurrencyPairForm';
import ReverseCurrencyPairForm from './ReverseCurrencyPairForm';
import { getAllCurrencyPairs } from '../../../networking/NetworkingCurrencyPairs';
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';
const style = getStyle();

export default class CurrencyPairScreen extends React.Component {

    componentDidMount() {
        getAllCurrencyPairs().then(
            (json) => {
                if (json != null) {

                }
            }
        );
        getCurrencyList().then(
            (json) => {
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    this.setState({ currencies });
                }
            }
        );
    }

    render() {
        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    Currency Pair
                </h3>
                <ul uk-tab="">
                    <li><a href="#">Selected Currecy Pair Information</a></li>
                    <li><a href="#">Reverse Currecy Pair Information</a></li>
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li><SelectedCurrencyPairForm/></li>
                    <li><ReverseCurrencyPairForm/></li>
                </ul>
            </div>
        );
    }
}
import React from 'react';
import NewDynamicTable from '../shared/NewDynamicTable';
import {formatToCurrency, formatToCurrencyWithScale} from '../../helpers/FormatHelper';
import { getDepositsByForward } from '../../networking/NetworkingDeposits';
import DepositInfoScreen from '../back_office/deposits/DepositInfoScreen';
import LoadingBox from '../shared/LoadingBox';

export default class ForwardDeposits extends React.Component {
    state = {
        deposits: [],
        loading: false,
        mode: 'list',
        readOnly: false
    }

    componentDidMount() {
        this.loadDeposits();        
        if (this.props.readOnly !== undefined) {
            this.setState({ readOnly: this.props.readOnly })
        }
    }

    loadDeposits = () => {
        this.setState({loading: true});
        getDepositsByForward(this.props.dealHeaderID).then(
            (result) => {
                var deposits = [];
                if(result.deposits !== undefined){
                    deposits = result.deposits;
                }
                this.setState({deposits: deposits,
                    loading: false,
                });
            }
        );
    }

    onAddClick = () => {
        this.setState({mode: 'form'});
    }

    onCloseForm = () => {
        this.setState({mode: 'list'});
        this.loadDeposits();
    }

    render(){

        var screen;

        switch(this.state.mode){
            case 'list':
            default:
                screen = (
                    <div>
                        <button className="uk-button uk-button-green" disabled={this.state.readOnly} onClick={this.onAddClick}>Add</button>
                        <NewDynamicTable data={this.state.deposits} 
                            enableClick={false}
                            clickFunction={this.props.handleDealClick}
                            useDeleteButton={false}
                            newSearch={true}
                            formats={[{header:'Amount', formatFunction: formatToCurrencyWithScale , formatCurrency: 'Currency' }]}/>
                    </div>
                );
                break;
            case 'form':
                screen = (<DepositInfoScreen buyCurrency={this.props.buyCurrency}
                    customerID={this.props.customerID}
                    dealHeaderID={this.props.dealHeaderID}
                    onCloseClick={this.onCloseForm}
                />);
                break;
        }

        return (
            <div>
                {screen}
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
import React from 'react';
import { getStyle, regularInputClass, regularSelectClass, notFilledInputClass, notFilledSelectClass } from '../../../styles/styles';
import DynamicSelect from '../../shared/DynamicSelect';
import { getCurrencyList, searchCurrencies } from '../../../networking/NetworkingCurrencies';
import { getListOfBanks } from '../../../networking/NetworkingBanks';
import { getAllBranches } from '../../../networking/NetworkingBankBranches';
import { validate } from '../../../helpers/ValidateHelper';
import { getCountries, searchCountries } from '../../../networking/NetworkingCountries';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import BanksForm from '../banks/BanksForm';
import LoadingBox from '../../shared/LoadingBox';
import { Link } from 'react-router-dom';
import { Space } from 'antd';

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });  

export default class NostroAccountInformationForm extends React.Component {

    state = {
        localAccount: {
            ABANo: '',
            AccountNo: '',
            AccountName: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            CountryID: '',
            CountryIDName: '',
            glAccountId: '',
            glAccountName: '',
            DefaultAccount: false,
            Description: '',
            PublishOnline: false,
            IsSettlementAccount: false,
            Status: 'Active',
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: '',
            ShowAs:''
        },
        dropdowns: {
            countryList: [],
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: [],
            glAccounts: []
        },
        validations: {
            bank: true,
            accountNo: true,
            currency: true
        },
        allBanks: [],
        allBankSwifts: [],
        allBranches: [],
        loading: false,
        showModal       : false,
        fullWidth       : true,
        maxWidth        : 'md',
        selectedBank: {}
    }

    componentDidMount() {
        getCurrencyList().then(
            (json) => {
                for (var i = 0; i < json.currencies.length; i++) {
                    json.currencies[i].value = json.currencies[i].CurrencyID;
                    json.currencies[i].name = json.currencies[i].CurrencyIDName;
                }
                this.setState({ dropdowns: { ...this.state.dropdowns, currencies: json.currencies } },
                    () => {
                        getCountries(1, 250).then(
                            (json) => {
                                if (json != null && json.countries != null) {
                                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                                    this.setState({ dropdowns: { ...this.state.dropdowns, countryList: countries } },
                                        () => {
                                            getListOfBanks(false).then( // SWIFT
                                                (json) => {
                                                    for (var i = 0; i < json.banks.length; i++) {
                                                        json.banks[i].value = json.banks[i].BankID;
                                                        json.banks[i].name = json.banks[i].Name;
                                                    }
                                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banksSwift: json.banks }, allBankSwifts: json.banks });
                                                    this.setState({ allBankSwifts: json.banks },
                                                        () => {
                                                            getListOfBanks(true).then( // BANKS
                                                                (json) => {
                                                                    for (var i = 0; i < json.banks.length; i++) {
                                                                        json.banks[i].value = json.banks[i].BankID;
                                                                        json.banks[i].name = json.banks[i].Name;
                                                                    }
                                                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banks: json.banks }, allBanks: json.banks });
                                                                    this.setState({ allBanks: json.banks },
                                                                        () => {
                                                                            getAllBranches().then(
                                                                                (json) => {
                                                                                    if (json !== undefined) {
                                                                                        if (json.branches !== undefined) {
                                                                                            for (var i = 0; i < json.branches.length; i++) {
                                                                                                json.branches[i].value = json.branches[i]["Branch ID"];
                                                                                                json.branches[i].name = json.branches[i].Branch;
                                                                                            }
                                                                                            /* this.setState({ dropdowns: { ...this.state.dropdowns, branches: json.branches }, allBranches: json.branches },
                                                                                                () => {
                                                                                                    this.props.isValid(this.validateRequiredInputs())
                                                                                                    this.updateInputs()
                                                                                                }); */
                                                                                            this.setState({ allBranches: json.branches },
                                                                                                () => {
                                                                                                    this.props.isValid(this.validateRequiredInputs())
                                                                                                    this.updateInputs()
                                                                                                });
                                                                                        }
                                                                                    }
                                                                                }
                                                                            )
                                                                        });
                                                                }
                                                            )
                                                        });
                                                }
                                            )
                                        });
                                }
                            }
                        )
                    })
            }
        );
    }

    updateInputs = () => {
        if (this.props.data !== undefined) {
            if (this.props.data !== null) {
                var propsData = this.props.data;
                this.setState({ localAccount: { ...this.state.localAccount, ...propsData } },
                    () => {
                        // this.searchCountry(propsData.CountryIdName)
                        // this.searchCurrency(propsData.Currency)                        
                        this.props.isValid(this.validateRequiredInputs());
                        this.getBranches(propsData.BankID, undefined);
                    }
                );
                this.filterBanksByCountry(propsData.CountryID);
            } else {
                this.props.isValid(this.validateRequiredInputs());
            }
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data) {
            this.updateInputs();
        }
        if (prevProps.validated !== this.props.validated && !this.props.validated) {
            this.validateRequiredFields();
        }
        if (this.props.isClearClicked !== prevProps.isClearClicked && this.props.data === null) {
            this.clearLocalAccountForm();
        }
    }

    getBranches(bankID, countryID) {
        var objBranches = this.state.allBranches
        var newListB = []
        if (bankID > 0 && countryID !== undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID) && value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else if (bankID > 0 && countryID === undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID)) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else {
            objBranches.forEach(value => {
                if (value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        }
    }
    //----FILTER BY COUNTRY
    searchCountry(query) {
        var queryHelper = query
        if (queryHelper !== undefined && queryHelper.includes(' - ')) {
            var x = queryHelper.substr(0, 3)
            queryHelper = x
        }
        searchCountries(queryHelper).then(
            (json) => {
                var helper = [];
                if (queryHelper !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(queryHelper);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({
                        dropdowns: {
                            ...this.state.dropdowns,
                            countryList: helper
                        }
                    });
                    if (helper.length !== 0) {
                        if (helper.length === 1 || helper[0].CountryId === queryHelper) {
                            var value = helper[0];
                            this.setState({
                                localAccount: {
                                    ...this.state.localAccount,
                                    CountryID: value.CountryId, CountryIDName: value.CountryIdName
                                }
                            }, () => {
                                this.filterBanksByCountry(value.CountryId)
                            });
                        }
                    }
                }
            }
        );
        console.table(this.state.countries)
    }
    getCountryValue = (value) => {
        if (value !== '' && value !== 'select') {
            if (value.value != null) {
                const newLocalAccount = { ...this.state.localAccount, CountryID: value.value, CountryIDName: value.name != null ? value.name : '' };
                this.props.getLocalAccount(newLocalAccount);
                if (value.value != null) {
                    this.filterBanksByCountry(value.value)
                    //this.getBranches('', value.CountryId)
                }
                this.setState({ localAccount: newLocalAccount });
            } else if (value.value == null) {
                /* this.setState({
                    localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                ); */
                this.setState({
                    localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: [], banksSwift: [], branches: [] },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        }
    }
    filterBanksByCountry = (countryID) => {
        var objBanks = this.state.allBanks
        var objSwift = this.state.allBankSwifts
        var newList = [], newListS = []
        if (countryID !== undefined) {
            objBanks.forEach(value => {
                if (value.CountryID === countryID) {
                    newList.push(value)
                }
            })
            objSwift.forEach(value => {
                if (value.CountryID === countryID) {
                    newListS.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, banks: newList, banksSwift: newListS } });
        }
    }
    //----END FILTER BY COUNTRY
    searchCurrency(query) {
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    if (json !== undefined) {
                        for (var i = 0; i < json.length; i++) {
                            helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                        }
                        if (json.length > 1) {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } });
                        } else {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper }, localAccount: { ...this.state.localAccount, CurrencyName: helper[0].name } });
                        }
                    } else {
                        this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } });
                    }
                }
            }
        );
    }

    validateRequiredFields() {
        var currency = true, bankID = true, accountNo = true;
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            currency = false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            bankID = false;
        }
        if (this.state.localAccount.AccountNo === '') {
            accountNo = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID,
                accountNo: accountNo,
                currency: currency
            }
        })
    }

    validateRequiredInputs() {
        const fields = [
            { displayName: 'Bank', validateName: 'bank', value: this.state.localAccount.BankID, type: 'string' },
            { displayName: 'Currency', validateName: 'currency', value: this.state.localAccount.Currency, type: 'string' },
            { displayName: 'Account Number', validateName: 'accountNo', value: this.state.localAccount.AccountNo, type: 'string' }
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateBank() {
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        return true;
    }

    validateCurrency() {
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        return true;
    }

    validateAccountNo() {
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setState({
                localAccount: { ...this.state.localAccount, Currency: value.value },
                validations: { ...this.state.validations, currency: true }
            },
                () => {
                    if (this.validateCurrency()) {
                        this.props.getLocalAccount(this.state.localAccount);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                }
            );
        }
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.getBranches(value.BankID, bank.CountryID)
                        // this.searchCountry(bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            } else {
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        // this.searchCountry(bank.CountryID)
                        this.getBranches(value.BankID, bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                localAccount: { ...this.state.localAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "" : value.SWIFT },
                dropdowns: { ...this.state.dropdowns, branches: [] },
                validations: { ...this.state.validations, bank: true }
            }, () => {
                if (this.validateBank()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
                if (value.value !== '' && value.value !== "select") {
                    // this.searchCountry(value.CountryID)
                    this.getBranches(this.state.localAccount.BankID, value.CountryID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                localAccount: { ...this.state.localAccount, BranchID: value.value, BankName: value.Bank, BankID: value['Bank ID'], Swift: value.Swift === null ? "" : value.Swift },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    // this.searchCountry(value['Country ID'])
                    this.getBranches(value['Bank ID'], value['Country ID'])
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueGLAccount(value) {
        if (value.value !== undefined) {
            this.setState({
                localAccount: { ...this.state.localAccount, glAccountId: value.value }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, AccountNo: event.target.value },
            validations: { ...this.state.validations, accountNo: true }
        },
            () => {
                if (this.validateAccountNo()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
            }
        );
    }

    handleUpdateShowAs = (event) => {
        var showAs =event.target.value.replace(/[^x!@#\$%\^\&*\)\(+=._-]/ig,'');
        this.setState({
            localAccount: { ...this.state.localAccount, ShowAs: showAs }
        },
            () => {
                    this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateAccountName = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, AccountName: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Swift: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateStatus = (event) => {
        debugger
        this.setState({
            localAccount: { ...this.state.localAccount, Status: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateDefaultAccount = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, DefaultAccount: event.target.checked }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdatePublishOnline = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, PublishOnline: event.target.checked }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateSettlementAcocunt = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, IsSettlementAccount: event.target.checked }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, ABANo: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, TransitNo: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateDescription = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Description: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }
    
    closeModal = () =>
    {
        this.setState({ showModal: false });
    }

    addBank = () => {
        this.setState({
            showModal: true
        });
    }

    updatePage = () =>{        
        this.setState({ loading: true},
            () => {
                getListOfBanks(false).then( // SWIFT
                    (json) => {
                        for (var i = 0; i < json.banks.length; i++) {
                            json.banks[i].value = json.banks[i].BankID;
                            json.banks[i].name = json.banks[i].Name;
                        }
                        this.setState({ allBankSwifts: json.banks },
                            () => {
                                getListOfBanks(true).then( // BANKS
                                    (json) => {
                                        for (var i = 0; i < json.banks.length; i++) {
                                            json.banks[i].value = json.banks[i].BankID;
                                            json.banks[i].name = json.banks[i].Name;
                                        }
                                        this.setState({ allBanks: json.banks },
                                            () => {
                                                getAllBranches().then(
                                                    (json) => {
                                                        if (json !== undefined) {
                                                            if (json.branches !== undefined) {
                                                                for (var i = 0; i < json.branches.length; i++) {
                                                                    json.branches[i].value = json.branches[i]["Branch ID"];
                                                                    json.branches[i].name = json.branches[i].Branch;
                                                                }
                                                                this.setState({ allBranches: json.branches },
                                                                    () => {
                                                                        this.props.isValid(this.validateRequiredInputs())
                                                                        this.updateInputs()
                                                                    });
                                                            }
                                                        }
                                                    }
                                                )
                                            });
                                    }
                                )
                            });
                    }
                )
            }
        );
        this.setState({loading:false});
    }

    render() {
        return (
            <div>
                <ul id="accordion-" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Main Account</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="nostro-main-acccount">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.formLabel}>Country</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.CountryID, name: this.state.localAccount.CountryIDName }} getValue={this.getCountryValue} placeholder={"Select an Item..."} data={this.state.dropdowns.countryList} id="local-account-country" />
                                                {/* <DynamicSearchSelect
                                                    className='uk-select'
                                                    id="form-country"
                                                    placeholder="Select a country"
                                                    searchFunction={this.searchCountry.bind(this)}
                                                    getValue={this.getCountryValue}
                                                    data={this.state.dropdowns.countryList}
                                                    objValue={{ value: this.state.localAccount.CountryID, name: this.state.localAccount.CountryIDName }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Bank (*) <a onClick={this.addBank}>Add Bank</a></label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className={this.formatValidSelect(this.state.validations.bank)} objValue={{ value: this.state.localAccount.BankID }} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks" />
                                                {/*<button className="uk-button uk-button-green uk-margin-right" type="button" onClick={this.addBank}>Add Bank</button>*/}
                                                {/*<label onClick={this.addBank}>Add Bank</label>*/}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Bank Branch</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BranchID }} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                                            </div>
                                        </div>
                                        
                                        <div className="uk-width-1-2">
                                        </div>
                                        
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className={this.formatValidSelect(this.state.validations.currency)} objValue={{ value: this.state.localAccount.Currency }} getValue={this.handleGetValueCurrency} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.currencies} id="form-currencies" />
                                            </div>
                                        </div>

                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Account Number / IBAN (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className={this.formatValidInput(this.state.validations.accountNo)} type="text" placeholder="" value={this.state.localAccount.AccountNo} onChange={this.handleUpdateAccountNumber} />
                                            </div>
                                        </div>
                                        {/*<div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Show As</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="xxx-xxxx-xxxx-xxxx" value={this.state.localAccount.ShowAs} onChange={this.handleUpdateShowAs} />
                                            </div>
                                            </div>*/}

                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Routing Code / Transit No</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            {/* <label className="uk-form-label">SWIFT</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BankID }} getValue={this.handleGetValueBankSwift} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banksSwift} id="form-banks-swift" />
                                            </div> */}
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">SWIFT / BIC</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.Swift} onChange={this.handleUpdateSwift} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Institution/ABA No.</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.ABANo} onChange={this.handleUpdateABANo} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Account Name</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.Description} onChange={this.handleUpdateDescription} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-margin">
                                            <label className="uk-form-label"><input className="uk-checkbox" value={this.state.localAccount.DefaultAccount} checked={this.state.localAccount.DefaultAccount} onChange={this.handleUpdateDefaultAccount} type="checkbox" /> Default Account</label>
                                        </div>
                                        <div className="uk-width-1-2 uk-margin">
                                            <label className="uk-form-label"><input className="uk-checkbox" value={this.state.localAccount.PublishOnline} checked={this.state.localAccount.PublishOnline} onChange={this.handleUpdatePublishOnline} type="checkbox" /> Publish Online</label>
                                        </div>
                                        <div className="uk-width-1-2 uk-margin">
                                            <label className="uk-form-label"><input className="uk-checkbox" value={this.state.localAccount.IsSettlementAccount} checked={this.state.localAccount.IsSettlementAccount} onChange={this.handleUpdateSettlementAcocunt} type="checkbox" /> Settlement Account</label>
                                        </div>
                                        <div className="uk-width-1-2 uk-margin">
                                            <label className="uk-form-label" style={{ width: 70 }}>Status</label>
                                            <label className="uk-margin-right"><input className="uk-radio" type="radio" id="status-active" name="radio1" value={"Active"} checked={this.state.localAccount.Status === "Active"} onChange={this.handleUpdateStatus} /> Active</label>
                                            <label className=""><input className="uk-radio" type="radio" id="status-inactive" name="radio1" value={"Inactive"} checked={this.state.localAccount.Status === "Inactive"} onChange={this.handleUpdateStatus} /> Inactive</label>
                                        </div>
                                        {/*
                                            this.state.localAccount.CreatedBy !== "" && this.state.localAccount.CreatedBy !== undefined ? 
                                            <div className="uk-width-1-2">
                                                <p>Created by {this.state.localAccount.CreatedBy} on {this.state.localAccount.CreatedOn}</p>
                                            </div>
                                            :""
                                        }
                                        <div className="uk-width-1-2">
                                        </div>
                                        {
                                            this.state.localAccount.UpdatedBy !== "" && this.state.localAccount.UpdatedBy !== undefined ?
                                            <div className="uk-width-1-2">
                                                <p>Updated by {this.state.localAccount.UpdatedBy} on {this.state.localAccount.UpdatedOn}</p>
                                            </div>
                                            :""
                                        
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">GL Account</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{value: this.state.localAccount.glAccountId}} getValue={this.handleGetValueGLAccount} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.glAccounts} id="form-banks" />
                                            </div>
                                        </div>*/}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
                <Dialog
                    open={this.state.showModal} 
                    onClose={this.closeModal}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                        Add Bank
                    </DialogTitle>
                    <DialogContent dividers>
                        <BanksForm updateFunction={this.updatePage} selectedBank={this.state.selectedBank}/>
                    </DialogContent>
                </Dialog>
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}
import { Dialog, DialogContent } from '@material-ui/core';
import { Breadcrumb } from 'antd';
import React from 'react';
import { GlobalSystemParametersNames } from '../../constants/GlobalSystemParametersNames';
import { CustomerType, PaymentType } from '../../constants/EntityTypeNames';
import { formatDate, formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrency } from '../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper';
import { getNostroBankAccountsForFunds, getVirtualBankAccountsByCurrencyID } from '../../networking/NetworkingBankAccounts';
import { getListOfBanks } from '../../networking/NetworkingBanks';
import { getBeneficiariesByCustomer } from '../../networking/NetworkingBeneficiaries';
import { getCurrencyList } from '../../networking/NetworkingCurrencies';
import { getBankAccountsByCurrency, getTradingInformationByCustomerID } from '../../networking/NetworkingCustomers';
import { getDealsForPayments, getSameCurrencyDealRefunds, voidSameCurrencyDealRefund } from '../../networking/NetworkingDeals';
import { getCustomerFundOnHoldBalanceByCurrencyJson, voidIncomingFund, getIncomingFundsByDeal, getOutgoingFundsByDealReadyToSend } from '../../networking/NetworkingFunds';
import { getParametersByName } from '../../networking/NetworkingGlobalSystemParameters';
import { getInstrumentsByCustomer } from '../../networking/NetworkingInstruments';
import { applyPayment, applyPaymentsToDeal, getListOfPaymentsForCustomer, getPayment, getPaymentsWithBalanceByCustomerCurrency, savePayment, voidPayment } from '../../networking/NetworkingPayments';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import PaymentsApplyBalanceToHeldFundsForm from '../back_office/payments/PaymentsApplyBalanceToHeldFundsForm';
import RefundForm from '../back_office/RefundForm';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';
import NumberInput from '../shared/NumberInput';
import Paginator from '../shared/Paginator';
import EntityProperties from '../shared/EntityProperties';
import OutgoingSendToInterface from '../outgoingFunds/OutgoingSendToInterface';
import { getEntityProperties } from '../../networking/NetworkingEntityProperties';
import IncomingFundVersionsByDealScreen from './IncomingFundVersionsByDealScreen';

const PaymentsApplied = ({ incomingFunds = [], rowClick = f => f, unlinkPayment = f => f, dealUpdateToken, readOnly, currencyId }) => {
    
    let controlId = 'CPaymentsApplied'

    const headersForPaymentsApplied = (
        <tr style={{ textAlignLast: 'center' }}>
            <th>Payment ID</th>
            <th>Payment Status</th>
            <th>Payment Amount Total</th>
            <th>Amount Applied</th>
            {!readOnly && <th>Actions</th>}
        </tr>
    );

    let rowsForPaymentsApplied = []
    let index = 0

    incomingFunds.forEach((element, i) => {
        index++;
        let voidAction;        

        element.CanBeVoided = 1
        element.IncomingFundsID = element['Incoming Funds ID']
        element.DealUpdateToken = dealUpdateToken

        if (Number(element['Incoming Funds ID']) > 0 && element['Incoming Status'] === 'Confirmed') {
            voidAction = (
                <i
                    className="fa fa-chain-broken"
                    onClick={() => unlinkPayment(element)}
                    style={{
                        fontSize: '1.75em',
                        verticalAlign: 'middle'
                    }}
                    title={'Unapply'}
                />
            );            
        }        

        if (element['Incoming Status'] !== 'Confirmed') {
            return;
        }
        
        rowsForPaymentsApplied.push(
            <tr key={controlId + 'row' + index} onClick={() => rowClick(element['Payment ID'])} >
                <td style={{ textAlign: 'center' }}>{element['Payment ID']}</td>
                <td style={{ textAlign: 'center' }}>{element['Payment Status']}</td>
                <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Amount'], currencyId)}</td>
                <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Amount Used'], currencyId)}</td>
                {!readOnly &&
                    <td
                        style={{
                            textAlign: "center",
                            verticalAlign: 'middle'
                        }}
                    >
                        {voidAction}
                    </td>
                }
            </tr>
        );
    });

    return <li className="uk-open">
        <h5 className={"uk-accordion-title"}>Payments Applied to Deal</h5>
        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
            <div id="payments-applied-to-deal">
                <div className="uk-width-1-1" id="payments-applied-to-deal-component">
                    <div className="uk-width-1-1 border-table">
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rowsForPaymentsApplied.length === 0}>
                            <thead>
                                {headersForPaymentsApplied}
                            </thead>
                            <tbody>
                                {rowsForPaymentsApplied}
                            </tbody>
                        </table>
                    </div>
                    <h5 style={style.alignCenter} hidden={rowsForPaymentsApplied.length > 0}>No records found.</h5>
                </div>
            </div>
        </div>
    </li>
}

const PaymentsNoApplied = ({ payments = [], rowClick = f => f, handleAmountToUse = f => f, handleNewAppy = f => f,
    dealHeaderId, selectedPaymentId, dealUpdateToken, readOnly, currencyId }) => {
    
    let controlId = 'CPaymentsNoApplied'
    
    const headersForPaymentsNoApplied = (
        <tr style={{ textAlignLast: 'center' }}>
            <th>Payment ID</th>
            <th>Payment Status</th>
            <th>Payment Amount Total</th>
            <th>Outstanding Balance</th>
            <th>Amount Applied</th>            
            {!readOnly && <th>Actions</th>}
        </tr>
    );

    let rowsForPaymentsNoApplied = []
    let index = 0

    payments.forEach((element, i) => {
        index++;
        element.IncomingFundsID = element['Incoming Funds ID']
        element.DealUpdateToken = dealUpdateToken

        let amountControl = (
            <NumberInput id={controlId + 'noapplied-amounttouse-' + index}
                type="Currency"
                value={element['AmountToUse']}
                className='uk-input'
                disabled={readOnly || selectedPaymentId !== element['Payment ID']}
                onChange={(event) => handleAmountToUse(
                    {
                        recordID: element['Payment ID'],
                        typeRecord: 'Deal',
                        pendingAmount: element['Outstanding Balance'],
                        incomingFundsID: element['IncomingFundsID'],
                        amount: element['Amount'],
                        dealUpdateToken: dealUpdateToken,
                        depositUpdateToken: element['DepositUpdateToken'],
                        source: 'listCustomerPayments',
                        status: element['Status']
                    }, event.target.value)}
            />
        );

        let applyPaymentAction = (
            <i
                className="fa fa-money"
                onClick={() => handleNewAppy({ ...element, recordID: '' + dealHeaderId }, i)}
                style={{
                    fontSize: '1.25em',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }}
                title={'Apply'}
            />
        );

        if (selectedPaymentId === element['Payment ID']) {
            rowsForPaymentsNoApplied.push(
                <tr key={controlId + 'row' + index}
                >
                    <td style={{ textAlign: 'center' }}>{element['Payment ID']}</td>
                    <td style={{ textAlign: 'center' }}>{element['Status']}</td>
                    <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Amount'], currencyId)}</td>
                    <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Outstanding Balance'], currencyId)}</td>
                    <td>
                        {amountControl}
                    </td>
                    {!readOnly &&
                        <td
                            style={{
                                textAlign: "center",
                                verticalAlign: 'middle'
                            }}
                        >
                            {applyPaymentAction}
                        </td>
                    }
                </tr>
            );
        } else {
            rowsForPaymentsNoApplied.push(
                <tr key={controlId + 'row' + index}
                    onClick={() => rowClick(element['Payment ID'])}
                >
                    <td style={{ textAlign: 'center' }}>{element['Payment ID']}</td>
                    <td style={{ textAlign: 'center' }}>{element['Status']}</td>
                    <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Amount'], currencyId)}</td>
                    <td style={{ textAlign: 'right' }}>{formatToCurrencyWithScale(element['Outstanding Balance'], currencyId)}</td>
                    <td>
                        {amountControl}
                    </td>
                    {!readOnly &&
                        <td
                            style={{
                                textAlign: "center",
                                verticalAlign: 'middle'
                            }}
                        >
                            {applyPaymentAction}
                        </td>
                    }
                </tr>
            );
        }
    });

    return <li className="uk-open">
        <h5 className="uk-accordion-title">Payments No Applied</h5>
        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
            <div id={controlId + "payments-applied-to-deal"}>
                <div className="uk-width-1-1" id="payments-applied-to-deal-component">
                    <div className="uk-width-1-1 border-table">
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rowsForPaymentsNoApplied.length === 0}>
                            <thead>
                                {headersForPaymentsNoApplied}
                            </thead>
                            <tbody>
                                {rowsForPaymentsNoApplied}
                            </tbody>
                        </table>
                    </div>
                    <h5 style={style.alignCenter} hidden={rowsForPaymentsNoApplied.length > 0}>No records found.</h5>
                </div>
            </div>
        </div>
    </li>
}

var style = getStyle();
const deliveryMethodBranch = 'At the branch';
export default class IncomingPaymentForm extends React.Component {
    
    state = {
        balanceIsZero: false,
        customerID: 0,
        firstLoad: true,
        incomingFundsElementToVoid: {},
        listActiveCurrencies: [],
        listBanks: [],
        listCustomerBankAccounts: [],
        listCustomerPayments: [],
        listDeliveryMethods: {},
        listIncomingInstruments: [],
        listNostroAccounts: [],
        listThirdPartyBenes: [],
        listToApply: [],
        listToApplyView: [],
        listIncomingFunds: [],

        currentElementToApply : null,

        //payment data
        amount: 0,
        amountUsedInDeals: 0,
        beneficiaryId: 0,
        certified: false,
        chequeBankId: 0,
        chequeDate: '',
        chequeNumber: '',
        comments: '',
        createdOn: '',
        currencyID: '',
        customerBankAccountId: 0,
        dateReceived: '',
        deliveryMethod: '',
        disbursedAmount: 0,
        entityPropertiesSaveProcess: false,
        nostroBankAccountId: 0,
        originalAmount: 0,
        paymentID: 0,
        paymentReferenceNo: '',
        paymentStatus: '',
        refunds: [],
        refundedAmount: 0,
        refundToDelete: {},
        relatedDealID: 0,
        settlementInstrument: '',
        thirdPartyBeneficiaryPayment: false,
        updatedOn: '',
        updateToken: 0,
        vostroIssueDate: '',
        selectedPayment: {},

        //paginator
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        loading: false,
        loadingRefunds: false,
        loadingPayments: false,
        saveInPendingStatus: false,
        validations: {
            currencyId              : true,
            amount                  : true,
            dateReceived            : true,
            settlementInstrument    : true,
            deliveryMethod          : true,
            customerBankAccountId   : true,
            nostroAccountId         : true,
            inputAmountList         : []
        },
        //show fields
        showETFFields: false,
        showDirectDebitFields: false,
        showVoidRefundAlert: false,

        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        showAlertSave: false,
        alertMessageSave: '',
        showApplyPaymentsAlert: false,
        showVoidIncomingAlert: false,
        showVoidPaymentAlert: false,
        messageVoid: "Are you sure you want to unapply this payment?",

        //session values
        systemDate: '',
        sessionLastClosingDate: '',

        //global parameters:
        enablePaymentConfirmationPrompt: false,
        vostroConversionDate: undefined,
        enableSavePendingPayments: false,

        //Payments filters
        /*showAppliedPayments: false,
        showAvailablePayments: false,*/
        showFullyApplied: false,
        showLinkedPayments: true,

        hideButton: false,
        hideButtonApplySection: false,
        typeRecordsApply: '',
        //Balance
        customerPaymentsBalance: 0,
        combinedBalance: 0,
        currentPaymentBal: 0,
        fundOnHoldBalance: 0,

        //Other parameters
        refundMode: false,
        applyToHeldFundsMode: false,

        //Read Only
        readOnly: false,

        //Checkbox
        selectedRows: [],
        saveAmountCombined: 0,

        //defaults
        defaultSettlementInstrument: '',
        defaultDeliveryMethod: '',
        defaultIncomingNostroBankID: 0,

        //Settlements
        fromSettlements: false,
        settlementPersonID: '',
        settlementBranchID: 0,
        endDate: new Date(),

        //Deposit
        objDeposit: undefined,

        showApplyCurrentPaymentsAlert: false,

        showOutgoingsToSend: false,
        outgoingsToSend:[],
        openVersions: false

    }

    async componentDidMount(){
        //Sessions
        let sessionSystemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
        let sessionLastClosingDate = localStorage.getItem(GlobalSystemParametersNames.LastClosingDate);

        //Load global system parameters
        var enablePaymentConfirmationPrompt = false;
        var vostroConversionDate = undefined;
        var enableSavePendingPayments = false;
         
        if (this.props.globalParameters !== undefined) {
            let globalParameters = this.props.globalParameters;
            let global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.EnablePaymentConfirmationPrompt);
            if (global.length === 1) {
                enablePaymentConfirmationPrompt = global[0].Value === 'true';
            }
            global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.VostroConversionDate);
            if (global.length === 1) {
                vostroConversionDate = global[0].Value;
            }
            global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.EnableSavePendingPayments);
            if (global.length === 1) {
                enableSavePendingPayments = global[0].Value === 'true';
            }
        }

        this.loadBeneficiaries();
        if (!this.props.paymentOpenFromBoard) {
            let customerID = this.props.customerID;
            let listActiveCurrencies = this.props.activeCurrencies;
            listActiveCurrencies.forEach(element => {
                element.value = element.CurrencyID;
                element.name = element.CurrencyIDName;
            });
            var currencyID = '';
            if (this.props.buyCurrency !== undefined) {
                currencyID = this.props.buyCurrency;
                await this.loadNostroBankAccounts(currencyID);
            }
            //alert('buyCurrency:' + currencyID);

            await this.getTradingInformationByCustomerID(customerID);

            let bankObj = null;
            this.state.listNostroAccounts.forEach(bank => {
                if(bank.BankID === this.state.defaultIncomingNostroBankID)
                {
                    bankObj = bank;
                }
            });
            if(bankObj !== null)
            {
                this.handleNostroBankAccount(bankObj);
            }else
            {
                if(this.state.listNostroAccounts.length > 0)
                {
                    this.handleNostroBankAccount(this.state.listNostroAccounts[0]);
                }
            }

            //Load instrument
            this.loadCustomerInstruments(customerID);

            //Load payments
            this.loadPaymentsForCustomer(customerID, true);

            var paymentID = 0;
            //Set state
            this.setState({
                currencyID: currencyID,
                customerID: customerID,
                dateReceived: new Date(sessionSystemDate),
                enablePaymentConfirmationPrompt: enablePaymentConfirmationPrompt,
                enableSavePendingPayments: enableSavePendingPayments,
                listActiveCurrencies: listActiveCurrencies,
                paymentID: paymentID,
                sessionLastClosingDate: sessionLastClosingDate,
                sessionSystemDate: sessionSystemDate,
                vostroConversionDate: vostroConversionDate,
            });

            //Load payment if any
            if (Number(this.props.data) > 0) {//PaymentID
                paymentID = this.props.data;
                this.loadPayment(paymentID, false, false);
            }
            else
            {    
                let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);

                var showETFFields = false;
                if (this.state.defaultSettlementInstrument === 'Electronic Funds Transfer') {
                    showETFFields = true;
                }

                var showDirectDebitFields = false;
                if (this.state.defaultSettlementInstrument === 'Direct Debit') {
                    showDirectDebitFields = true;
                }

                if (this.state.defaultSettlementInstrument === 'Cheque') {
                    this.loadListOfBanks();
                } else {
                    this.setState({ listBanks: [] });
                }

                this.setState({ 
                    amount               : this.props.totalDue,
                    dateReceived         : systemDate !== ''? systemDate: '',
                    settlementInstrument: this.state.defaultSettlementInstrument || '',
                    deliveryMethod       : this.state.defaultDeliveryMethod,
                    showETFFields        : showETFFields,
                    showDirectDebitFields: showDirectDebitFields
                });
            }
            
        }
        else {
            this.setState({
                enablePaymentConfirmationPrompt: enablePaymentConfirmationPrompt,
                enableSavePendingPayments: enableSavePendingPayments,
                sessionLastClosingDate: sessionLastClosingDate,
                sessionSystemDate: sessionSystemDate,
                vostroConversionDate: vostroConversionDate,
            });

             
            this.loadCurrencyList().then(() => {
                if (Number(this.props.data) > 0) {//PaymentID
                    this.loadPayment(this.props.data, true, true);  
                }
            });
        }

        //Read only
        var readOnly = this.state.readOnly;
        if (this.props.readOnly !== undefined) {
            readOnly = this.props.readOnly;
        }

        //Settlement info
        var fromSettlements = this.state.fromSettlements;
        var settlementPersonID = this.state.settlementPersonID;
        var settlementBranchID = this.state.settlementBranchID;
        if (this.props.fromSettlements !== undefined) {
            fromSettlements = this.props.fromSettlements;
            settlementBranchID = this.props.settlementBranchID;
            settlementPersonID = this.props.settlementPersonID;
        }

        //Show linked payments
        var showLinkedPayments = this.state.showLinkedPayments;
        /*if(this.props.dealHeaderID !== undefined ||
            this.props.objDeposit !== undefined){
            showLinkedPayments = false;
        }*/

        //Set state
        this.setState({
            fromSettlements: fromSettlements,
            objDeposit: this.props.objDeposit,
            readOnly: readOnly,
            settlementBranchID: settlementBranchID,
            settlementPersonID: settlementPersonID,
            showLinkedPayments: showLinkedPayments,
        });
    }

    async componentDidUpdate(prevProps){
        if(prevProps.dealHeaderInfo !== undefined && this.props.dealHeaderInfo !== undefined && prevProps.dealHeaderInfo.DealPaidStatus !== this.props.dealHeaderInfo.DealPaidStatus)
        {
            if(this.props.dealHeaderInfo.DealPaidStatus === 'Fully Paid')
            {
                this.setState({loading: true});
                let propertiesC = await getEntityProperties(this.props.customerID, CustomerType);
                let sendOutgoingToInterface = propertiesC.entityProperties.find(x => x.Description === 'Send Automatic Outgoings to Partner');
                if(sendOutgoingToInterface !== undefined && sendOutgoingToInterface.TextValue !== null && sendOutgoingToInterface.TextValue.toLowerCase() === 'yes')
                {
                    let outgoings = await getOutgoingFundsByDealReadyToSend(this.props.dealHeaderID)

                    if(outgoings !== undefined && outgoings.data.length > 0)
                    {
                        this.setState({outgoingsToSend: outgoings.data,showOutgoingsToSend: true,loading: false});
                    }
                    else
                    {
                        this.setState({loading: false});
                    }
                }
                else
                {
                    this.setState({loading: false});
                }
            }
        }

    }

    loadPaymentsForCustomer(customerID, newSearch) {
        this.setState({loadingPayments: true});
        var withAvailableBalance = false;
        /*if (this.props.dealHeaderID !== undefined
            || this.props.objDeposit !== undefined) {
            withAvailableBalance = true;
        }*/

        var currencyID = '';
        if (this.props.buyCurrency !== undefined) {
            currencyID = this.props.buyCurrency;
        }

        getListOfPaymentsForCustomer(customerID, currencyID, this.props.dealHeaderID, withAvailableBalance, this.state.showFullyApplied, this.state.currentPage, getDefaultPagingNumberOfItems()).then(
            response => {
                var listCustomerPayments = [];
                var pages = 1;
                if (response !== undefined) {
                    if (response.customerPayments !== undefined) {
                        listCustomerPayments = response.customerPayments;
                    }
                    var totalCount = response.totalCount;
                    pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }
                this.setState({
                    listCustomerPayments: listCustomerPayments,
                    loadingPayments: false,
                    totalPages: pages,
                    newSearch: newSearch
                });
            }
        ).then(x => {
            if (this.props.dealHeaderID > 0) {
                this.loadIncomingFunds()
            }
        });
        
    }



    loadIncomingFunds = () => {
        let dealIncomingFunds = []
        getIncomingFundsByDeal(this.props.dealHeaderID, 0, 0).then(
            (jsonResponse) => {
                if (jsonResponse !== undefined) {
                    switch (jsonResponse.httpStatusCode) {
                        case 200:
                            {
                                if (jsonResponse.totalCount !== 0) {
                                    dealIncomingFunds = jsonResponse.incomingFunds
                                }
                                break;
                            }
                        default:
                            {
                                break;
                            }
                    }
                }
                this.setState({ listIncomingFunds: dealIncomingFunds })
            }
        );
    }

    async getTradingInformationByCustomerID(customerID)
    {
        if(customerID > 0)
        {
            await getTradingInformationByCustomerID(customerID).then(
                (value) => {
                    if (value != null) 
                    {
                        let settlementInstrument = '';
                        let deliveryMethod = '';
                        let incomingNostroBankID = 0;

                        if(value.DefaultSettlementInstrument !== undefined && value.DefaultSettlementInstrument !== '')
                        {
                            settlementInstrument = value.DefaultSettlementInstrument;
                        }

                        if(value.DefaultDeliveryMethod !== undefined && value.DefaultDeliveryMethod !== '')
                        {
                            deliveryMethod = value.DefaultDeliveryMethod;
                        }

                        if(value.DefaultIncomingNostroBankID !== undefined && value.DefaultIncomingNostroBankID !== '')
                        {
                            incomingNostroBankID = value.DefaultIncomingNostroBankID;
                        }

                        this.setState({ 
                            defaultSettlementInstrument: settlementInstrument || '',
                            defaultDeliveryMethod       : deliveryMethod,
                            defaultIncomingNostroBankID : incomingNostroBankID,
                        });                 
                    }
                }
            );
        }
    }

    async loadBeneficiaries(){
        //Get beneficiaries by customer
        await getBeneficiariesByCustomer(this.props.customerID, 0).then(
            (json) => {
                var helperForSettlement = [];

                if(json.beneficiaries !== undefined){                    
                    for(var i=0; i<json.beneficiaries.length; i++){
                        json.beneficiaries[i].value = json.beneficiaries[i].BeneficiaryID;
                        json.beneficiaries[i].name = json.beneficiaries[i].Description;

                        if(json.beneficiaries[i].PaymentFlow !== undefined)
                        {
                            if(json.beneficiaries[i].PaymentFlow !== 'Pays to' && json.beneficiaries[i].PaymentFlow !== null && json.beneficiaries[i].PaymentFlow !== '')
                            {
                                helperForSettlement.push(json.beneficiaries[i]);
                            }
                        }
                    }
                }

                this.setState({
                    listThirdPartyBenes: helperForSettlement,
                });
            }
        ); 
    }

    loadBankAccountsByCurrency() {
        let loadCustomerAccount = !this.state.thirdPartyBeneficiaryPayment;
        if (loadCustomerAccount) {
            getBankAccountsByCurrency(this.state.customerID, this.state.currencyID).then(
                (response) => {
                    if (typeof response !== "undefined" && typeof response.accounts !== "undefined") {
                        let bankAccounts = response.accounts;
                        bankAccounts.forEach(element => {
                            element.value = element.BankAccountID;
                            element.name = element.Description;
                        });

                        this.setState({ listCustomerBankAccounts: bankAccounts });
                    } else {
                        this.setState({ listCustomerBankAccounts: [] });
                    }
                }
            );
        } else {
            /*getBeneficiariesByCustomerFormatedMin(this.state.customerID, this.state.currencyID, '').then((response) => {
                if (typeof response !== "undefined" && typeof response.beneficiaries !== "undefined") {
                    let beneAccounts = response.beneficiaries;
                    beneAccounts.forEach(element => {
                        element.value = element.BankAccountID;
                        element.name = element.Name;
                    });
                    this.setState({ listCustomerBankAccounts: beneAccounts });
                } else {
                    this.setState({ listCustomerBankAccounts: [] });
                }
            });*/
        }
    }

    async loadPayment(paymentID, loadPayments, loadCustomerInstruments) {
        this.setState({loading: true});
        getPayment(paymentID).then(
            (response) => {
                console.table(response);
                if (typeof response !== "undefined") {
                    this.loadRefunds(paymentID);
                    this.setState({
                        amount: response['OutstandingBalance'],
                        beneficiaryId: response['Beneficiary ID'],
                        chequeBankId: response['ChequeBank ID'],
                        chequeNumber: response.ChequeNumber,
                        currencyID: response['Currency ID'],
                        customerBankAccountId: response.CustomerBankAccount,
                        dateReceived: response['Date Received'],
                        //deliveryMethod: response.DeliveryMethod,
                        originalAmount: response.Amount,
                        paymentID: response['Payment ID'],
                        paymentReferenceNo: response['Payment Ref No'],
                        paymentStatus: response.Status,
                        settlementInstrument: response.SettlementInstrument,
                        thirdPartyBeneficiaryPayment: response.ThirdPartyBeneficiaryPayment,

                        chequeDate: response.ChequeDate,
                        nostroBankAccountId: response['NostroBankAccount ID'],
                        certified: response.Certified,
                        disbursedAmount: response.DisbursedAmount,
                        refundedAmount: response.RefundedAmount,
                        vostroIssueDate: response.VostroIssueDate,
                        createdOn: response.CreatedOn,
                        updatedOn: response.UpdatedOn,
                        comments: response.Comments,
                        updateToken: response.UpdateToken,
                        customerID: response['Customer ID'],
                        amountUsedInDeals: response['AmountUsedInDeals'],
                        relatedDealID: response.RelatedDealID,
                        selectedPayment: response
                    }, () => {
                        var showETFFields = false;
                        if (this.state.settlementInstrument === 'Electronic Funds Transfer') {
                            showETFFields = true;
                        }

                        var showDirectDebitFields = false;
                        if (this.state.settlementInstrument === 'Direct Debit') {
                            showDirectDebitFields = true;
                        }

                        if (this.state.settlementInstrument === 'Cheque') {
                            this.loadListOfBanks();
                        } else {
                            this.setState({ listBanks: [] });
                        }

                        //let instrumentObject = this.state.listIncomingInstruments.filter(x => x.name === this.state.settlementInstrument)[0];
                        //this.loadLookUpTableByFilter(instrumentObject);
                        this.loadBankAccountsByCurrency()
                        this.loadNostroBankAccounts(response['Currency ID']);
                        var deliveryMethod = response.DeliveryMethod;

                        //alert(this.state.fromSettlements);
                        if(!this.state.fromSettlements)
                        {
                            this.getDealsForPayments(response['Customer ID'], response['Currency ID'], paymentID);
                            this.getCustomerFundOnHoldBalanceByCurrencyJson(response['Customer ID'], response['Currency ID']);
                            this.getPaymentsWithBalanceByCustomerCurrency(response['Customer ID'], response['Currency ID']);
                            if (loadPayments) {
                                this.loadPaymentsForCustomer(response['Customer ID'], true);
                            }
                            if (loadCustomerInstruments) {
                                this.loadCustomerInstruments(response['Customer ID']);
                            }
                            //Do not understand why this is here
                            /*if(this.state.defaultDeliveryMethod === '' || this.state.defaultSettlementInstrument === '')
                            {
                                this.getTradingInformationByCustomerID(response['Customer ID']);
                            }*/
                        }
                        else
                        {
                            deliveryMethod = deliveryMethodBranch;
                        }
                        this.setState({ deliveryMethod: deliveryMethod,
                            settlementInstrument: response.SettlementInstrument,
                            showDirectDebitFields: showDirectDebitFields,
                            showETFFields: showETFFields,
                            loading: false,
                        });
                    });
                }
                else{
                    this.setState({ loading: false, selectedPayment: {} });
                }
            }
        );
    }

    async loadNostroBankAccounts(currencyID) {
        if(currencyID !== ''){
            //alert(currencyID);
            await getNostroBankAccountsForFunds(currencyID, this.props.customerID, false).then(
                async (response) => {
                    if (response != null && response.nostroBankAccounts != null) {
                        let nostroAccounts = response.nostroBankAccounts.map(ba => ({ ...ba, value: ba.BankAccountID, name: ba.BankName }));
                        await getVirtualBankAccountsByCurrencyID(this.props.customerID, currencyID).then(
                            (json) => {
                                if (json != null && json.virtualBankAccounts != null) {
                                    if (json.virtualBankAccounts.length > 0) {
                                        let virtualBankAccounts = json.virtualBankAccounts.map(ba => ({ ...ba, value: ba.BankAccountID, name: ba.BankName }));
                                        nostroAccounts = [...nostroAccounts, ...virtualBankAccounts];
                                    }
                                }
                            }
                        )
                        this.setState({
                            listNostroAccounts: nostroAccounts
                        });
                    } else {
                        this.setState({ listNostroAccounts: [] });
                    }
                }
            );
        }        
    }

    async loadCurrencyList() {
        getCurrencyList().then(
            (response) => {
                if (typeof response !== "undefined") {
                    let currencies = response.currencies;
                    currencies.forEach(element => {
                        element.value = element.CurrencyID;
                        element.name = element.CurrencyIDName;
                    });
                    this.setState({
                        listActiveCurrencies: currencies
                    });
                }
            }
        );
    }

    cancelVoidPayment = () => {
        this.setState({ showVoidPaymentAlert: false });
    }

    voidPaymentConfirmed = () => {
        let userID = localStorage.getItem('UserID');

        var model = {
            PaymentID: this.state.paymentID,
            UserID: userID,
            UpdateToken: this.state.updateToken,
        }

        console.table(model);
        this.setState({ loading: true, showVoidPaymentAlert: false });
        voidPayment(model).then((response) => {
            var alertTitle = '';
            var alertMessage = '';
            switch (Number(response.httpStatusCode)) {
                case 200:
                    this.handleClearClick();
                    this.loadPaymentsForCustomer(this.state.customerID, true);
                    this.loadPayment(this.state.paymentID, false, false);
                    if (this.props.reloadDealHeaderInfo !== undefined) {
                        this.props.reloadDealHeaderInfo();
                    }
                    alertTitle = 'Success';
                    alertMessage = 'Payment voided successfully';
                    break;
                case 400:
                    alertTitle = 'Error';
                    alertMessage = "An error occurred while trying to void the payment. Please try again. (Bad Request)";
                    break;
                case 409:
                    alertTitle = 'Error';
                    alertMessage = 'Payment has been updated by other user. Please reload the information and try again.';
                    if (response.httpErrorMessage !== undefined) {
                        alertMessage = response.httpErrorMessage;
                    }
                    break;
                default:
                    alertTitle = 'Error';
                    alertMessage = 'An error occurred while trying to void the payment. Please try again.'
                    if (response.Message !== undefined) {
                        alertMessage = response.Message;
                    }
                    break;
            }
            this.setState({
                showAlert: true,
                alertMessage: alertMessage,
                alertTitle: alertTitle,
                loading: false,
            });
        });
    }

    voidPayment = () => {        

        //IsValidApplicationDate?
        getParametersByName(GlobalSystemParametersNames.LastClosingDate).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.parameters !== "undefined" && response.parameters.length > 0) {

                    let currentSessionLastClosingDate = response.parameters[0].Value;

                    if (new Date(currentSessionLastClosingDate) > new Date(this.state.sessionLastClosingDate)) {
                        this.setState({ showAlert: true, alertMessage: 'You should restart the session. The application date is not correct.', alertTitle: 'Error' });
                        return false
                    }

                    if (this.state.refundedAmount > 0) {
                        this.setState({
                            showAlert: true, alertMessage: 'This payment cannot be voided as there are completed refunds linked to it. Please void the refunds first, then void this payment.',
                            alertTitle: 'Error'
                        });
                        return false
                    }

                    if (this.state.disbursedAmount > 0 && this.state.paymentStatus !== 'Pending') {
                        this.setState({
                            showAlert: true, alertMessage: 'This payment cannot be voided as it has already been partially or fully applied.',
                            alertTitle: 'Warning'
                        });
                        return false
                    }
                    this.setState({ showVoidPaymentAlert: true });                    
                }
            }
        )

    }

    loadCustomerInstruments(customerID) {
        getInstrumentsByCustomer(customerID).then(
            (json) => {
                //alert(JSON.stringify(json.instruments));
                let jsonInstruments = json.instruments;
                if (jsonInstruments !== undefined) {
                    var incomingHelper = [];
                    var listDeliveryMethods = {};
                    for (var i = 0; i < jsonInstruments.length; i++) {
                        var instrumentElement = {
                            value: jsonInstruments[i].Instrument,
                            name: jsonInstruments[i].Instrument
                        };
                        var methodsList;
                        if (jsonInstruments[i].InstrumentType === 'Incoming' && jsonInstruments[i].Instrument !== "Client's Vostro Account") {
                            var addItem = true;
                            if(this.state.fromSettlements)
                            {
                                methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                                if(!methodsList.find(method => method === deliveryMethodBranch))
                                {
                                    addItem = false;
                                }
                            }
                            if(addItem)
                            {
                                incomingHelper.push(instrumentElement);
                            }
                        }

                        //Load delivery methods
                        var deliveryMethods = [];
                        if (jsonInstruments[i].DeliveryMethods !== '') {
                            //alert(jsonInstruments[i].Instrument + '-' + JSON.stringify(jsonInstruments[i].DeliveryMethods));
                            methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                            if(this.state.fromSettlements)
                            {
                                var itemBranch = { value: deliveryMethodBranch, name: deliveryMethodBranch };
                                deliveryMethods.push(itemBranch);
                            }
                            else
                            {
                                for (var j = 0; j < methodsList.length; j++) {
                                    var item = { value: methodsList[j], name: methodsList[j] };
                                    deliveryMethods.push(item);
                                }
                            }
                            listDeliveryMethods[jsonInstruments[i].Instrument] = deliveryMethods;
                        }

                    }
                    this.setState({
                        listIncomingInstruments: incomingHelper,
                        listDeliveryMethods: listDeliveryMethods,
                    });
                }
            }
        );
    }
    
    loadListOfBanks() {
        getListOfBanks(true).then(
            (response) => {
                if (typeof response !== "undefined" && response.banks !== "undefined") {
                    let banks = response.banks;
                    banks.forEach(element => {
                        element.value = element.BankID;
                        element.name = element.Name;
                    });
                    this.setState({ listBanks: response.banks })
                } else {
                    this.setState({ listBanks: [] })
                }
            }
        )
    }

    hideSaveInPendingButton() {
        /*if (this.state.paymentID > 0 && ['Fully Applied', 'Partially Applied', 'Voided'].includes(this.state.paymentStatus)) {
            return true
        }
        else if (this.state.paymentID == 0) {
            return true
        } else {
            return false;
        }*/
        if (this.state.paymentID === 0) {
            return false
        } else if (this.state.paymentID > 0 && ['Pending'].includes(this.state.paymentStatus)){
            return false
        } /*else if (this.state.paymentID > 0 && ['Fully Applied', 'Partially Applied', 'Voided'].includes(this.state.paymentStatus)) {
            return true
        }*/else {
            return true
        }
    }

    hideConfirmButton() {
        if(this.state.paymentID > 0 && ['Fully Applied', 'Partially Applied', 'Voided'].includes(this.state.paymentStatus)){
            return true
        } else {
            return false
        }
    }

    hideVoidButton() {
        if (this.state.paymentID !== "undefined" && this.state.paymentID > 0) {
            return false
        } else {
            return true
        }
    }

    hideChequeInfo() {
        if (this.state.settlementInstrument === 'Cheque') {
            return false
        } else {
            return true
        }
    }

    showApplyToFundOnHoldButton() 
    {
        return (
            ["Not Applied", "Partially Applied"].includes(this.state.paymentStatus) && 
            this.state.settlementInstrument !== "Client's Vostro Account" && 
            this.state.settlementInstrument !== "Net settlements" && 
            this.state.paymentID !== 0
        );
    }

    //events
    getAmountValue(event) {
        this.setState({ amount: event.target.value });
    }

    handleCurrencyUpdate = (obj) => {
        if (obj !== undefined && obj.value !== undefined) {
            //alert(JSON.stringify(obj));
            this.setState({
                currencyID: obj.value
            }, () => {
                this.loadBankAccountsByCurrency();
                this.loadNostroBankAccounts(obj.value);
                this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerID, obj.value);
                this.getPaymentsWithBalanceByCustomerCurrency(this.state.customerID, obj.value);
            });
        }
    }


    handleIncomingInstrument = (value) => {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({
                settlementInstrument: value.value, showETFFields: false, showDirectDebitFields: false, thirdPartyBeneficiaryPayment: false, listBanks: []
            }, () => {
                //this.loadLookUpTableByFilter(value);
                if(this.state.fromSettlements)
                {
                    this.setState({deliveryMethod: deliveryMethodBranch})
                }
                if (this.state.settlementInstrument === 'Electronic Funds Transfer') {
                    this.setState({ showETFFields: true });
                }

                if (this.state.settlementInstrument === 'Direct Debit') {
                    this.setState({ showDirectDebitFields: true });
                }

                if (this.state.settlementInstrument === 'Cheque') {
                    this.loadListOfBanks();
                }
            });
        }
    }

    handleDeliveryMethod = (obj) => {
        if (obj !== undefined 
            && obj !== null 
            && obj.value !== undefined) {
            this.setState({deliveryMethod: obj.value});
        }
    }

    handleCustomerBankAccount(value) {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({ customerBankAccountId: value !== null ? value.value : 0 });
        }
    }

    handleDateReceived(date) {
        if (date !== undefined) {
            this.setState({
                dateReceived: formatDate(date)
            });
        } else {
            this.setState({
                dateReceived: ''
            });
        }
    }

    handleOnCheckThirdPartyBeneficiaryPayment(event) {
        let checked = event.target.checked
        this.setState({
            thirdPartyBeneficiaryPayment: checked
        }, () => {
            if (checked) {
                this.loadBankAccountsByCurrency();
            } else {
                this.setState({ beneficiaryId: 0 })
            }
        });
    }

    handleBeneficiaryId(value) {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({ beneficiaryId: value !== null ? value.value : 0 })
        }
    }

    handleChequeNumber(event) {
        this.setState({ chequeNumber: event.target.value });
    }

    handleChequeDate(date) {
        if (date !== undefined) {
            this.setState({
                chequeDate: formatDate(date)
            });
        } else {
            this.setState({ chequeDate: '' });
        }
    }

    handlePaymentReferenceNo(event) {
        this.setState({ paymentReferenceNo: event.target.value });
    }

    handleDeleteClick(obj) {
    }

    handleChequeBank(value) {
        if (typeof value !== "undefined" && value != null && typeof value.value !== "undefined") {
            this.setState({ chequeBankId: value !== null ? value.value : 0 })
        }
    }

    handleNostroBankAccount(value) 
    {
         
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") 
        {
            this.setState({ 
                nostroBankAccountId: value !== null ? value.value : 0 
            });
        }
    }

    //paginator
    changePage(value) {
        this.setState({
            currentPage: value
        }, () => {
            this.loadPaymentsForCustomer(this.state.customerID, false);
        });
    }

    //validations
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    validateRequiredFields() {
        var valid = true;

        var vCurrencyId = true,
            vAmount = true,
            vSettlementInstrument = true,
            vDeliveryMethod = true,
            vDateReceived = true,
            vCustomerBankAccountId = true,
            vNostroAccountId = true;

        if (this.state.amount == null || Number(this.state.amount) <= Number(0)) {
            vAmount = false;
        }

        if (this.state.currencyID == null || this.state.currencyID === '') {
            vCurrencyId = false;
        }

        if (this.state.dateReceived == null || this.state.dateReceived === '') {
            vDateReceived = false;
        }

        if (this.state.settlementInstrument == null || this.state.settlementInstrument === '' || this.state.settlementInstrument.includes('Select')) {
            vSettlementInstrument = false;
        }

        var validateDeliverMethod = false;
        if (this.state.listDeliveryMethods[this.state.settlementInstrument] !== undefined) {
                validateDeliverMethod = true;
        }

        if (validateDeliverMethod &&
            (this.state.deliveryMethod == null || this.state.deliveryMethod === '' || this.state.deliveryMethod.includes('Select'))) {
            vDeliveryMethod = false;
        }

        var validateCustomerBankAccountId = (this.state.settlementInstrument === 'Direct Debit');
        if (validateCustomerBankAccountId &&
            (this.state.customerBankAccountId == null || this.state.customerBankAccountId === '')) {
            vCustomerBankAccountId = false;
        }

        if (Number(this.state.nostroBankAccountId) === 0) {
            vNostroAccountId = false;
        }

        valid = vCurrencyId && vAmount && vSettlementInstrument && vDeliveryMethod && vDateReceived && vCustomerBankAccountId && vNostroAccountId;

        this.setState({
            validations: {
                ...this.state.validations,
                currencyId: vCurrencyId,
                amount: vAmount,
                settlementInstrument: vSettlementInstrument,
                deliveryMethod: vDeliveryMethod,
                dateReceived: vDateReceived,
                customerBankAccountId: vCustomerBankAccountId,
                nostroAccountId: vNostroAccountId
            }
        })
        return valid;
    }
    //actions
    handleRowClick(row) {        
        this.handleClearClick();
        this.loadPayment(row['Payment ID'], false, false);
    }

    handleAppliesPaymentRowClick = (paymentId) => {        
        if (paymentId !== null) {
            this.loadPayment(paymentId, false, false);
        }
    }

    handleNewAppy = (element, index) => {        
        if(element.AmountToUse !== undefined && element.AmountToUse !== null && element.AmountToUse !== 0)
        {
            this.setState({ 
                showApplyCurrentPaymentsAlert   : true,
                currentElementToApply           : element,
                confirmCurrentElementMessage    : "You will apply: " + formatToCurrencyWithScale(element.AmountToUse, this.state.currencyID) + ' ' + this.state.currencyID
            });
        }else
        {
            let auxValdiation       = this.state.validations.inputAmountList;
            auxValdiation[index]    = false;
            this.setState({
                validations: {...this.state.validations, inputAmountList: auxValdiation}
            });
        }
    }

    savePayment = async () => {
        this.setState({loading:true});
        let userID = localStorage.getItem('UserID');
        let paymentStatus = "Not Applied";
        if(this.state.saveInPendingStatus){ paymentStatus = "Pending"; }
        var paymentExt = null;

        if(this.state.fromSettlements)
        {
            paymentExt = {
                PaymentID: this.state.paymentID,
                SettlementBranchID: this.state.settlementBranchID,
                SettlementPersonID: this.state.settlementPersonID,
                UpdatedBy: userID
            }
        }

        var autoAplicationDepositID = 0;
        if(this.props.objDeposit !== undefined){
            autoAplicationDepositID = this.props.objDeposit.DepositID;
        }

        var model = {
            paymentID: this.state.paymentID,
            AutoAplicationDepositID: autoAplicationDepositID,
            Amount: this.state.amount,
            BeneficiaryID: this.state.beneficiaryId !== null ? this.state.beneficiaryId : 0,
            ChequeBankID: this.state.chequeBankId !== null ? this.state.chequeBankId : 0,
            ChequeDate: this.state.chequeDate,
            ChequeNumber: this.state.chequeNumber,
            CurrencyID: this.state.currencyID,
            CustomerBankAccountID: this.state.customerBankAccountId !== null ? this.state.customerBankAccountId : 0,
            CustomerID: this.state.customerID,
            DateReceived: this.state.dateReceived,
            DeliveryMethod: this.state.deliveryMethod,
            NostroBankAccountID: this.state.nostroBankAccountId !== null ? this.state.nostroBankAccountId : 0,
            PaymentReferenceNo: this.state.paymentReferenceNo,
            SettlementInstrument: this.state.settlementInstrument,
            Status: paymentStatus,
            VostroIssueDate: this.state.vostroIssueDate,
            ThirdPartyBeneficiaryPayment: this.state.thirdPartyBeneficiaryPayment,
            UserID: userID,
            UpdateToken: this.state.updateToken,
            SaveInPendingStatus: this.state.saveInPendingStatus,
            PaymentExt: paymentExt,
            
        }

        console.table(model);

        this.setState({ showAlertSave: false });

        savePayment(model).then( async (response) => {
            if (response.httpStatusCode === 400) {
                let httpStatusText = "An error occurred while trying to save the payment. Please try again. (Bad Request)";
                this.setState({
                    showAlert: true,
                    alertMessage: httpStatusText,
                    alertTitle: 'Error',
                    saveInPendingStatus: false
                })
            } else if (response.httpStatusCode !== 200) {
                var alertMessage = 'An error occurred while trying to save the payment. Please try again.'
                //alert(JSON.stringify(response));
                if (response.httpErrorMessage !== undefined && response.httpErrorMessage !== '') {
                    alertMessage = response.httpErrorMessage;
                }
                this.setState({
                    showAlert: true,
                    alertMessage: alertMessage,
                    alertTitle: 'Error',
                    saveInPendingStatus: false
                });
            } else {
                let paymentID = response.paymentId;
                
                if(this.props.dealHeaderInfo !== undefined && this.state.paymentID === 0)
                {
                    let deal = this.props.dealHeaderInfo;
                    let totalDue = deal.BuyAmount + deal.TotalFees - deal.TotalPaidByCustomer;

                    if(this.state.amount <= totalDue)
                    {
                        var modelApply = {
                            DealHeaderId: deal.DealHeaderID,
                            UserId: userID,
                            DealUpdateToken: deal.UpdateToken,
                            SaveInPendingStatus: this.state.saveInPendingStatus,
                            payments:[{PaymentId: paymentID, 
                                AmountToUse:this.state.amount}]
                        };

                        if(!this.state.saveInPendingStatus){
                        }
                        applyPaymentsToDeal(modelApply).then((json) => {
                            if (json.httpStatusCode !== 200) {
                                this.setState({
                                    showAlert: true,
                                    alertMessage: 'An error occurred while trying to apply the payments. Please try again.',
                                    alertTitle: 'Error'
                                });
                            }
                            else
                            {
                                if (this.props.reloadDealHeaderInfo !== undefined) {
                                    this.props.reloadDealHeaderInfo();
                                }
                                this.loadPayment(paymentID, false, false);
                            }                             
                        });
                    }
                }
                this.loadPaymentsForCustomer(this.state.customerID, true);
                this.setState({
                    paymentID: paymentID,
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: 'Payment successfully saved!',
                    saveInPendingStatus: false,
                    entityPropertiesSaveProcess: true,
                }, () => this.loadPayment(paymentID, true, false));
                
                //Reload deal header
                if (this.props.reloadDealHeaderInfo !== undefined) {
                    this.props.reloadDealHeaderInfo();
                }

                //Reload deposit 
                if (this.props.reloadDepositInfo !== undefined &&
                    this.props.objDeposit !== undefined){
                    let objDeposit = await this.props.reloadDepositInfo(this.props.objDeposit.DepositID);
                    this.setState({objDeposit: objDeposit});
                }
            }
        });
        this.setState({loading:false});
    }

    onFinishEntityPropertiesSaveProcess(){
        this.setState({entityPropertiesSaveProcess: false});
    }

    handleClearClick(event) 
    {
        let currencyID = '';
        let systemDate = '';
        let amountA = 0;

        if(this.props.buyCurrency !== undefined)
        {
            currencyID = this.props.buyCurrency;
        }

        if(this.props.totalDue !== undefined)
        {
            amountA = this.props.totalDue;
        }

        systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
        
        this.setState({
            paymentID: 0,
            currencyID: currencyID,
            amount: amountA,
            dateReceived: systemDate,
            paymentStatus: 'Not Applied',
            settlementInstrument: this.state.defaultSettlementInstrument || '',
            deliveryMethod: this.state.defaultDeliveryMethod,
            thirdPartyBeneficiaryPayment: false,
            beneficiaryId: 0,
            customerBankAccountId: 0,
            originalAmount: 0,
            paymentReferenceNo: '',
            chequeNumber: '',
            chequeBankId: 0,
            chequeDate: '',
            nostroBankAccountId: 0,
            certified: false,
            disbursedAmount: 0,
            refundedAmount: 0,
            createdOn: '',
            updatedOn: '',
            vostroIssueDate: '',
            comments: '',
            updateToken: 0,
            listToApplyView: [],
            listToApply: [],
            validations: {
                ...this.state.validations,
                currencyId: true,
                amount: true,
                dateReceived: true,
                settlementInstrument: true,
                deliveryMethod: true,
                customerBankAccountId: true,
                nostroAccountId: true
            },
            mainFieldsEnable: {
                currency: true,
                bankAccount: true,
                cbDeliveryMethod: true,
                cbNostroAccounts: true,
                cbSettInstrument: true,
                txtAmount: true,
                lblNostroAccount: true,
                cbChequeBank: true,
                dpChequeDate: true
            },
            relatedDealID: 0,
            showETFFields: false,
            showDirectDebitFields: false,
            selectedPayment: {}
        }, () =>{
            

            let bankObj = null;
            this.state.listNostroAccounts.forEach(bank => {
                if(bank.BankID === this.state.defaultIncomingNostroBankID)
                {
                    bankObj = bank;
                }
            });
            if(bankObj !== null)
            {
                this.handleNostroBankAccount(bankObj);
            }else
            {
                if(this.state.listNostroAccounts.length > 0)
                {
                    this.handleNostroBankAccount(this.state.listNostroAccounts[0]);
                }
            }

            var showETFFields = false;
            if (this.state.defaultSettlementInstrument === 'Electronic Funds Transfer') {
                showETFFields = true;
            }

            var showDirectDebitFields = false;
            if (this.state.defaultSettlementInstrument === 'Direct Debit') {
                showDirectDebitFields = true;
            }

            if (this.state.defaultSettlementInstrument === 'Cheque') {
                this.loadListOfBanks();
            } else {
                this.setState({ listBanks: [] });
            }

            this.setState({ 
                showETFFields        : showETFFields,
                showDirectDebitFields: showDirectDebitFields
            });
        });
    }

    handleShowFullyAppliedUpdate = (event) => {
        let checked = event.target.checked;
        this.setState({ showFullyApplied: checked },
            () =>
                this.loadPaymentsForCustomer(this.state.customerID, true));
    }

    handleShowLinkedPaymentUpdate = (event) => {
        let checked = event.target.checked;
        this.setState({showLinkedPayments: checked});
    }

    //alerts
    handleSaveClickAskConfirmation = (event, saveInPendingStatus) => {
        event.preventDefault();
        this.setState({
            saveInPendingStatus: saveInPendingStatus
        }, () => {
            if (this.validateRequiredFields()) {

                //IsValidApplicationDate?
                getParametersByName(GlobalSystemParametersNames.LastClosingDate).then(
                    (response) => {
                        if (typeof response !== "undefined" && typeof response.parameters !== "undefined" && response.parameters.length > 0) {

                            let currentSessionLastClosingDate = response.parameters[0].Value;

                            if (new Date(currentSessionLastClosingDate) > new Date(this.state.sessionLastClosingDate)) {
                                this.setState({ showAlert: true, alertMessage: 'You should restart the session. The application date is not correct.', alertTitle: 'Error' });
                                return false
                            }

                            let showChequeValidationMessage = false;
                            if (this.state.settlementInstrument === 'Cheque') {
                                if (typeof this.state.chequeDate !== "undefined" || this.state.chequeDate !== '') {
                                    let chequeDate = new Date(this.state.chequeDate);
                                    let systemDate = new Date(this.state.systemDate);
                                    if (chequeDate > systemDate) {
                                        showChequeValidationMessage = true;
                                    }
                                }
                            }

                            var paymentconfirmMessage = "Are you sure you want to save the amount of " + formatToCurrencyWithScale(this.state.amount, this.state.currencyID) + " " +
                                this.state.currencyID + " for this payment from customer " + this.state.customerID + "?";

                            //monto > deal
                            if(this.state.amount > this.props.totalDue){
                                paymentconfirmMessage = "An incoming payment of " + formatToCurrencyWithScale(this.state.amount, this.state.currencyID) + " " + this.state.currencyID +
                                " will be created to reflect the TOTAL amount sent by the customer. An amount of "+ formatToCurrencyWithScale(this.props.totalDue, this.state.currencyID) + " " + this.state.currencyID +" will be applied to this deal.";
                            }
                           
                            if(this.state.relatedDealID > 0){
                                paymentconfirmMessage += ' Please notice that this payment will be applied to deal ' + this.state.relatedDealID + '.';
                            }

                            if (showChequeValidationMessage) {
                                paymentconfirmMessage = 'Please note this is a post dated cheque. ' + paymentconfirmMessage
                            }

                            //alert(JSON.stringify(this.state.listCustomerPayments));
                            var similarPaymentMessage;

                            if(this.state.paymentID === 0){
                                var similarPayment = this.state.listCustomerPayments.find((payment) => { return Number(payment.Amount) === Number(this.state.amount);});
                                if(similarPayment !== undefined){
                                    similarPaymentMessage = ' Please notice that there is a ' + similarPayment.Status +  ' payment with the same amount.';
                                    paymentconfirmMessage += similarPaymentMessage;
                                }
                            }

                            if (saveInPendingStatus) {
                                this.savePayment();
                            }
                            else if (this.state.enablePaymentConfirmationPrompt ||
                                similarPaymentMessage !== undefined) {
                                this.setState({
                                    showAlertSave: true,
                                    alertMessageSave: paymentconfirmMessage
                                })
                            } else {
                                this.savePayment();
                            }
                        }
                    }
                );
            }
        });
    }

    closeAlert = () => {
        this.setState({
            showAlert: false,
            saveInPendingStatus: false
        });
    }

    closeAlertSave() {
        this.setState({ showAlertSave: false });
    }

    handleHideButtonClick = () => {
        if (this.state.hideButton) {
            this.setState({ hideButton: false })
        } else {
            this.setState({ hideButton: true })
        }
    }

    handleHideButtonApplySectionClick = () => {
        if (this.state.hideButtonApplySection) {
            this.setState({ hideButtonApplySection: false })
        } else {
            this.setState({ hideButtonApplySection: true })
        }
    }

    getDealsForPayments(customerID, currencyID, paymentID) {
        //Do not load records to apply until it is ready to apply
        if(this.state.paymentStatus !== 'Pending'){
            if (paymentID !== undefined && paymentID > 0) {
                getDealsForPayments(customerID, currencyID, 'P', paymentID, this.state.typeRecordsApply, 1, 1000).then(
                    (value) => {                        
                        var deals = [];
                        var list = [];
                        if (value !== undefined && value.totalCount > 0) 
                        {
                            if (this.props.dealHeaderID !== undefined) 
                            {
                                value.result.forEach(element => {
                                    if (element.TypeRecord === 'Deal' &&
                                        Number(element.RecordID) === Number(this.props.dealHeaderID)) {
                                        deals.push(element);
                                    }
                                });
                            }
                            else {
                                if(this.props.objDeposit !== undefined){
                                    value.result.forEach(element => {
                                        if (element.TypeRecord === 'Deposit' &&
                                            Number(element.RecordID) === Number(this.props.objDeposit.DepositID)) {
                                            deals.push(element);
                                        }
                                    });
                                }
                                else{
                                    deals = value.result;
                                }                            
                            }
                        }

                        deals.forEach(element => {
                            list.push(true);
                        });

                        this.setState({ 
                            listToApplyView : deals,
                            listToApply     : [],
                            validations: {...this.state.validations, inputAmountList: list}
                        });
                    }
                );
            }
        }
    }

    handleBackLink = () => {
        this.props.backLink();
    }

    //alerts
    handleApplyPayment = () => {
        this.setState({ showApplyPaymentsAlert: true });
    }

    yesApplyCurentPaymentsAlert = () =>
    {
        ////debugger
        this.setState({ showApplyCurrentPaymentsAlert: false });
        let userID = localStorage.getItem('UserID');
        let newListToApply = [];

        this.state.listToApply.forEach(element => {
            if (element.DealID > 0) { 
                if (element.RecordID === this.state.currentElementToApply["Payment ID"]) {
                    newListToApply.push({ ...element, RecordID: element.DealID });
                }
            } else {
                if (element.RecordID === this.state.currentElementToApply.RecordID) {
                    newListToApply.push(element);
                }
            }
        });

        var model = {
            PaymentID           : this.state.paymentID,
            PaymentUpdateToken  : this.state.updateToken,
            UserId              : userID,
            Records             : newListToApply
        }

        this.generalApply(model);
    }

    yesApplyPaymentsAlert() {
        this.setState({ showApplyPaymentsAlert: false });
        let userID = localStorage.getItem('UserID');
        var model = {
            PaymentID: this.state.paymentID,
            PaymentUpdateToken: this.state.updateToken,
            UserId: userID,
            Records: this.state.listToApply
        }
        this.generalApply(model);
    }

    generalApply = (model) => {
        applyPayment(model).then(async (response) => {
            //alert(JSON.stringify(response));
            switch (Number(response.httpStatusCode)) {
                case 200:
                    this.setState({
                        listPaymentsToApply: [],
                        listToApply: [],
                        showAlert: true,
                        alertTitle: 'Success',
                        alertMessage: 'Payment applied successfully.',
                    });
                    this.loadPaymentsForCustomer(this.state.customerID, true);
                    this.loadPayment(this.state.paymentID, false, false);                    
                    
                    //Update deal header
                    if (this.props.reloadDealHeaderInfo !== undefined) {
                        this.props.reloadDealHeaderInfo();
                    }

                    //Update deposit 
                    if (this.props.reloadDepositInfo !== undefined &&
                        this.props.objDeposit !== undefined){
                        let objDeposit = await this.props.reloadDepositInfo(this.props.objDeposit.DepositID);
                        this.setState({objDeposit: objDeposit});
                    }
                    break;
                case 409:
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                    });
                    break;
                default:
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: 'An error occurred while trying to apply payment. Please try again.',
                    });
                    break;
            }
        });
    }

    noApplyPaymentsAlert() {
        this.setState({ showApplyPaymentsAlert: false });
    }

    noApplyCurrentPaymentsAlert = () =>
    {
        this.setState({ 
            showApplyCurrentPaymentsAlert   : false,
            currentElementToApply           : null 
        });
    }

    handleAmountToUse(data, i, event) {
        if (data.source === 'listCustomerPayments') {
            if (data.status === 'Fully Applied') {
                return
            }
            let valid = true;
            let amountToUse = event.target.value;            
            let paymentChanged = this.state.listCustomerPayments.filter(item => item['Payment ID'] === data.recordID)[0]
            
            if (paymentChanged === undefined) {
                return
            }
            if (amountToUse < 0) {
                this.setState({ showAlert: true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error' });
                paymentChanged['AmountToUse'] = 0;
                valid = false;
            }
            if (amountToUse > data.pendingAmount) {
                this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error' });
                paymentChanged['AmountToUse'] = 0;
                valid = false;
            }

            let tempToApply = this.state.listToApply;
            
            if (tempToApply.length > 0) {
                let amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
                //if (amountTotalToApply > this.state.amount - this.state.disbursedAmount - this.state.refundedAmount) {
                if (amountTotalToApply > this.state.OutstandingBalance) {
                    this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error' });
                    paymentChanged['AmountToUse'] = 0;
                    valid = false;
                }
            }

             //remove if exist
            if (tempToApply.length > 0) {
                tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
            }

            if (valid && amountToUse > 0) {
                //add if amount > 0
                if (amountToUse > 0) {
                    tempToApply.push({
                        RecordID: data.recordID,
                        DealID: this.props.dealHeaderID,
                        AmountToUse: amountToUse,
                        TypeRecord: data.typeRecord,
                        DealUpdateToken: data.dealUpdateToken,
                        DepositUpdateToken: data.depositUpdateToken,
                    });
                }
                paymentChanged['AmountToUse'] = amountToUse;                
            }
            this.setState({ listToApply: tempToApply }, () => this.updateCombinedBalance(tempToApply));
        }
        else {            
            if (this.state.listToApplyView === undefined) {
                return
            }
            
            let tempToApply = this.state.listToApply;
            let amountToUse = event.target.value;            
            let paymentChanged = this.state.listToApplyView.filter(item => item['RecordID'] === data.recordID)[0]
            if (paymentChanged === undefined) {
                return
            }
            let valid = true;
            let auxValdiation = this.state.validations.inputAmountList;

            if (amountToUse < 0) {
                this.setState({ showAlert: true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error' });
                paymentChanged['AmountToUse'] = 0;
                valid = false;
            }

            if (amountToUse > data.pendingAmount) {
                this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error' });
                paymentChanged['AmountToUse'] = 0;
                valid = false;
            }
            //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
            if (tempToApply.length > 0) {
                let amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
                //if (amountTotalToApply > this.state.amount - this.state.disbursedAmount - this.state.refundedAmount) {
                if (amountTotalToApply > this.state.OutstandingBalance) {
                    this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error' });
                    paymentChanged['AmountToUse'] = 0;
                    valid = false;
                }
            }
            //remove if exist
            if (tempToApply.length > 0) {
                tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
            }

            if (valid && amountToUse > 0) {
                //add if amount > 0
                if (amountToUse > 0) {
                    tempToApply.push({
                        RecordID: data.recordID,
                        AmountToUse: amountToUse,
                        TypeRecord: data.typeRecord,
                        DealUpdateToken: data.dealUpdateToken,
                        DepositUpdateToken: data.depositUpdateToken,
                    });
                }
                paymentChanged['AmountToUse'] = amountToUse;
                auxValdiation[i] = true;
            }
                        
            this.setState({
                listToApply: tempToApply,
                validations: { ...this.state.validations, inputAmountList: auxValdiation }
            }, () => this.updateCombinedBalance(tempToApply));
        }
    }

    handleAmountToUse2 = (data, amountToUse) => {
        if (data.status === 'Fully Applied') {
            return
        }
        let valid = true;
        //let amountToUse = amountToUse;
        let paymentChanged = this.state.listCustomerPayments.filter(item => item['Payment ID'] === data.recordID)[0]

        if (paymentChanged === undefined) {
            return
        }

        let deal = this.props.dealHeaderInfo;
        let totalDue = deal.BuyAmount + deal.TotalFees - deal.TotalPaidByCustomer;
        totalDue = roundAmountByCurrency(totalDue, this.state.currencyID);
        let tempToApply = this.state.listToApply;

        if (amountToUse < 0) {
            this.setState({ showAlert: true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error' });
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
        else if (amountToUse > data.pendingAmount) {
            //this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error' });
            this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than OutStanding Balance.', alertTitle: 'Error' });
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
        else if (amountToUse > totalDue) {
            this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Due Amount: ' + formatToCurrencyWithScale(totalDue, this.state.currencyID) });
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
        else if (tempToApply.length > 0) {
            let amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
            //if (amountTotalToApply > this.state.amount - this.state.disbursedAmount - this.state.refundedAmount) {
            if (amountTotalToApply > data.pendingAmount) {
                this.setState({ showAlert: true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error' });
                paymentChanged['AmountToUse'] = 0;
                valid = false;
            }
        }

        //remove if exist
        if (tempToApply.length > 0) {
            tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
        }

        if (valid && amountToUse > 0) {
            //add if amount > 0
            if (amountToUse > 0) {
                tempToApply.push({
                    RecordID: data.recordID,
                    DealID: this.props.dealHeaderID,
                    AmountToUse: amountToUse,
                    TypeRecord: data.typeRecord,
                    DealUpdateToken: data.dealUpdateToken,
                    DepositUpdateToken: data.depositUpdateToken,
                });
            }
            paymentChanged['AmountToUse'] = amountToUse;
        }
        this.setState({ listToApply: tempToApply }, () => this.updateCombinedBalance(tempToApply));
    }

    updateCombinedBalance = (list) => {
        let actualCombinated = this.state.saveAmountCombined;
        var amount = 0;
        list.forEach(element => {
            if (element.AmountToUse > 0) {
                amount += element.AmountToUse
            }
        });
        this.setState({ combinedBalance: actualCombinated - amount });
    }

    getPaymentsWithBalanceByCustomerCurrency(CustomerID, CurrencyID) {
        var balance = 0;
        getPaymentsWithBalanceByCustomerCurrency(CustomerID, CurrencyID, true, true).then(
            (json) => {
                if (json !== undefined && json.payments !== undefined) {
                    for (var i = 0; i < json.payments.length; i++) {
                        balance += json.payments[i].Amount - json.payments[i].DisbursedAmount - json.payments[i].RefundedAmount;
                    }
                }
                this.setState({ customerPaymentsBalance: balance });
            }
        );
    }

    getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID) {
        getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID).then(
            (json) => {
                if (json !== undefined) {
                    if (json.Amount !== undefined) {
                        this.setState({ fundOnHoldBalance: json.Amount });
                    }
                    else {
                        this.setState({ fundOnHoldBalance: 0 });
                    }
                }
                else {
                    this.setState({ fundOnHoldBalance: 0 });
                }
                this.calculateBalance();
            }
        );
    }

    calculateBalance() {
        /*var userAmount = 0;
        var incomingAmount = 0;
        var paymentBalance = 0;
        var vostroBalance = 0;
        var combinedBalance = 0;
        var listToApplyView = this.state.listToApplyView;
        let enableFundOnHoldBalance = localStorage.getItem(GlobalSystemParametersNames.EnableFundsOnHoldInPaymentScreen);

        for(var d = 0; d< listToApplyView.length; d++)
        {
            if (listToApplyView[d].AmountToUse !== undefined )
            {
                if (listToApplyView[d].IncomingFundsID !== undefined && listToApplyView[d].IncomingFundsID > 0)
                {
                    if (listToApplyView[d].Amount !== undefined && listToApplyView[d].Amount > 0)
                    {
                        incomingAmount += listToApplyView[d].Amount;
                        userAmount += listToApplyView[d].AmountToUse - listToApplyView[d].Amount;
                    }
                    else
                    {
                        incomingAmount += listToApplyView[d].AmountToUse;
                    }                        
                }
                else
                {
                    userAmount += listToApplyView[d].AmountToUse;
                }
            }  
        }

        if (this.state.paymentStatus !== "Pending" && this.state.paymentStatus !== "NSF")
        {
            paymentBalance = this.state.amount - incomingAmount - this.state.refundedAmount;
        } 

        if(enableFundOnHoldBalance)
        {
            if (this.state.fundOnHoldBalance > 0)
            {
                vostroBalance = this.state.fundOnHoldBalance;
            }
        }
        
        if (userAmount <= vostroBalance)
        {
            vostroBalance = vostroBalance - userAmount;
        }
        else
        {
            paymentBalance = paymentBalance - (userAmount - vostroBalance);
            vostroBalance = 0;
        }

        if (this.state.fundOnHoldBalance > 0)
        {                
            combinedBalance = paymentBalance + vostroBalance;
        }*/

        //let paymentBalance = this.state.amount - this.state.disbursedAmount - this.state.refundedAmount;
        let paymentBalance = this.state.OutstandingBalance;
        let vostroBalance = this.state.fundOnHoldBalance;
        let combinedBalance = paymentBalance + vostroBalance;

        this.setState({
            saveAmountCombined: combinedBalance,
            combinedBalance: combinedBalance,
            currentPaymentBal: paymentBalance,
            fundOnHoldBalance: vostroBalance
        });
    }

    handleUnlinkPayment = (rowElement) => {
        //alert(JSON.stringify(rowElement));
        let canBeVoided = rowElement.CanBeVoided;
        let typeRecord = rowElement.TypeRecord;
        if (canBeVoided) {
            let message = "Are you sure you want to unapply this payment?"
            let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
            if(this.props.dealHeaderInfo !== undefined && this.props.dealHeaderInfo.ValueDate < systemDate)
            {
                message = "Are you sure you want to unapply the payment to the deal with Value Date "+this.props.dealHeaderInfo.ValueDate+"?"
            }

            this.setState({
                messageVoid: message,
                showVoidIncomingAlert: true,
                incomingFundsElementToVoid: rowElement,
            });
        }
        else {
            var message = '';
            switch (typeRecord) {
                case 'Deposit':
                    message = 'This payment application cannot be voided. Deposit was already used.';
                    break;
                default:
                    message = 'This payment application cannot be voided. Please contact administrator.'
                    break;
            }
            this.setState({
                alertTitle: 'Error',
                alertMessage: message,
                showAlert: true,
            })
        }
    }

    yesVoidIncomingFund = () => {
        voidIncomingFund(this.state.incomingFundsElementToVoid.IncomingFundsID, localStorage.getItem('UserID'), 
        this.state.incomingFundsElementToVoid.IncomingUpdateToken, this.state.updateToken, this.state.incomingFundsElementToVoid.DealUpdateToken, 
        this.state.incomingFundsElementToVoid.DepositUpdateToken, 0).then(
            async (response) => {
                if (Number(response.httpStatusCode) === 200) {
                    let currencyID = this.state.currencyID;
                    let paymentID = this.state.paymentID;
                    if (currencyID !== undefined &&
                        currencyID !== null &&
                        currencyID !== '' &&
                        paymentID > 0) {
                        this.loadPayment(paymentID, false, false);
                        this.getDealsForPayments(this.state.customerID, currencyID, paymentID);
                    }

                    this.loadPaymentsForCustomer(this.state.customerID, true);
                    if (this.props.reloadDealHeaderInfo !== undefined) {
                        this.props.reloadDealHeaderInfo();
                    }

                    //Update deposit 
                    if (this.props.reloadDepositInfo !== undefined &&
                        this.props.objDeposit !== undefined){
                        let objDeposit = await this.props.reloadDepositInfo(this.props.objDeposit.DepositID);
                        this.setState({objDeposit: objDeposit});
                    }

                    this.setState({
                        showVoidIncomingAlert: false,
                        incomingFundsElementToVoid: {},
                        alertTitle: 'Success',
                        alertMessage: 'Payment application voided!',
                        showAlert: true,
                    });
                }
                else {
                    var alertMessage = 'Payment application not voided! Please try again or contact administrator.'; 
                    if(response.httpErrorMessage !== undefined){
                        alertMessage = response.httpErrorMessage;
                    }
                    this.setState({
                        showVoidIncomingAlert: false,
                        incomingFundsID: 0,
                        alertTitle: 'Error',
                        alertMessage: alertMessage,
                        showAlert: true,
                    });
                }
            }
        );
    }

    closeVoidIncomingAlert = () => {
        this.setState({ showVoidIncomingAlert: false });
    }

    onRefundClick = () => {
        this.setState({ refundMode: true });
    }    

    onRefundBackClick = () => {
        this.loadPayment(this.state.paymentID, false, false);
        this.loadPaymentsForCustomer(this.state.customerID, true);
        this.setState({ refundMode: false });
    }

    onApplyToHeldFundsClick = () => {
        this.setState({ applyToHeldFundsMode: true });
    }

    onApplyToHeldFundsdBackClick = () => {
        this.loadPayment(this.state.paymentID, false, false);
        this.loadPaymentsForCustomer(this.state.customerID, true);
        this.setState({ applyToHeldFundsMode: false });
    }

    getIncomingFundForHeldFund = () => {

        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);

        let incomingFund = {}
        incomingFund.ActualDate = systemDate
        incomingFund.CurrencyID = this.state.currencyID;
        incomingFund.CustomerID = this.state.customerID;
        incomingFund.ExpectedDate = systemDate;
        incomingFund.SourceID = this.state.paymentID;
        incomingFund.SourceTable = 'P'
        return incomingFund
    }
    handleCheckBoxDynamicFunction(event){
        event.stopPropagation();
        var checked = event.target.checked;
        var list = this.state.listToApplyView != null ? this.state.listToApplyView : [];
        var helper = [];
        if (list.length > 0) {
            list.forEach((value, index) => {
                if (parseInt(event.target.id) === index + 1) {
                    helper.push(value);
                }
            });
        }
        this.handleSelected(helper[0], checked);
    }
    handleSelected = (obj, checked) =>
    {
        var selectedRows = [];
        selectedRows = this.state.selectedRows;
        var amount = this.state.combinedBalance;
        var tempToApply = this.state.listToApplyView;
        var data = {
            amount: obj.PendingAmount,
            dealUpdateToken: obj.DealUpdateToken,
            depositUpdateToken: obj.DepositUpdateToken,
            incomingFundsID: obj.IncomingFundsID,
            pendingAmount: obj.PendingAmount,
            recordID: obj.RecordID,
            typeRecord: obj.TypeRecord,
        }
        var object = {
            target: {
                id: "amounttouse-",
                name: "",
                value: 0,
            }
        }
        if (obj.PendingAmount > 0 && amount > 0) {
            if (checked) {
                amount -= obj.PendingAmount;
                selectedRows.push(obj);
                tempToApply.forEach(function (item, i) {
                    if (item.RecordID === obj.RecordID) {
                        object.target.id = object.target.id + (i + 1);
                        tempToApply[i].AmountToUse = obj.PendingAmount;
                    }
                });
            }
            else {
                amount += obj.PendingAmount;
                selectedRows = selectedRows.filter(item => item.RecordID !== obj.RecordID);
                tempToApply.forEach(function (item, i) {
                    if (item.RecordID === obj.RecordID) {
                        object.target.id = object.target.id + (i + 1);
                        tempToApply[i].AmountToUse = null;
                    }
                });
            }
        } if (selectedRows.length === 1) {
            if (!checked) {
                amount += obj.PendingAmount;
                selectedRows = selectedRows.filter(item => item.RecordID !== obj.RecordID);
                tempToApply.forEach(function (item, i) {
                    if (item.RecordID === obj.RecordID) {
                        object.target.id = object.target.id + (i + 1);
                        tempToApply[i].AmountToUse = null;
                    }
                });
            }
        }
        if (amount >= 0 && this.state.saveAmountCombined >= amount) {
            this.setState({
                selectedRows,
                //combinedBalance: amount,
                listToApplyView: tempToApply
            });
        }
        if (checked) {
            object.target.value = obj.PendingAmount;
        } else {
            object.target.value = 0;
        }
        this.handleAmountToUse(data, object);
    }

    loadRefunds = (paymentID) => {
        if(paymentID > 0){
            this.setState({loadingRefunds: true});
            getSameCurrencyDealRefunds('P', paymentID, '').then(
                (response) => {
                    var refunds = [];
                    if(response.refunds !== undefined){
                        refunds = response.refunds;
                    }
                    this.setState({loadingRefunds: false,
                        refunds: refunds,
                    });
                }
            );
            /*getRefunds('P', paymentID).then(
                (result) => {
                    var refunds = [];
                    if(result.refunds !== undefined){
                        refunds = result.refunds;
                    }
                    this.setState({refunds: refunds,
                        loadingRefunds: false,
                    });
                }
            );*/            
        }
    }

    onDeleteRefundClick = (row) => {
        //alert(JSON.stringify(row));
        this.setState({
            refundToDelete: row,
            showVoidRefundAlert: true,
        });
    }

    yesDeleteRefund = () => {
        this.setState({loading: true, showVoidRefundAlert: false});
        let refundToDelete = this.state.refundToDelete;
        voidSameCurrencyDealRefund(refundToDelete['DealHeaderID$'], localStorage.getItem('UserID'), refundToDelete['DealUpdateToken$']).then(
            (response) => {
                //alert(JSON.stringify(response));
                var alertTitle = '', alertMessage = '';
                if(Number(response.httpStatusCode) === 200){
                    this.loadRefunds(this.state.paymentID);
                    this.loadPayment(this.state.paymentID, true, false);                    
                    alertTitle = 'Success';
                    alertMessage = 'Refund voided successfully!';
                }else{
                    alertTitle = 'Error';
                    alertMessage = 'Process failed. Please try again or contact the administrator.';
                    if(response.httpErrorMessage !== undefined){
                        alertMessage = response.httpErrorMessage;
                    }
                }
                this.setState({
                    alertMessage: alertMessage,
                    alertTitle: alertTitle,
                    loading: false,
                    showAlert: true,
                });
            }
        );
        /*voidRefund(refundToDelete['Refund ID'], localStorage.getItem('UserID'), refundToDelete['UpdateToken$'], this.state.updateToken).then(
            (response) => {
                //alert(JSON.stringify(response));
                var alertTitle = '', alertMessage = '';
                switch(Number(response.httpStatusCode)){
                    case 200:
                        alertTitle = 'Success';
                        alertMessage = 'Refund voided!';
                        this.loadPayment(this.state.paymentID, true);
                        break;
                    case 409:
                        alertTitle = 'Error';
                        alertMessage = 'Another user has modified this record information. Please reload the information and try again.';
                        this.loadPayment(this.state.paymentID, true);
                        break;
                    default:
                        alertTitle = 'Error';
                        alertMessage = 'Refund not voided. Try again or contact the administrator';
                        break;
                }
                this.setState({
                    alertTitle: alertTitle,
                    alertMessage: alertMessage,
                    refundToDelete: {},
                    showAlert: true,
                });
                if(this.state.outgoingFundsID > 0){
                    this.onClearFieldsClick();
                }
            }
        );*/
    }

    noDeleteRefund = () => {
        this.setState({showVoidRefundAlert: false});
    }

    handleFocusAmountApplied = (element, i, event) => {
        /*////debugger

        let dealsList = this.state.listToApplyView;
        let listFinal = [];
        var elementToUpdate = dealsList.find((el) => { return el.RecordID === element.RecordID });
        listFinal.push(elementToUpdate);

        let auxList = this.state.validations.inputAmountList;
        auxList.forEach((element, j) => {
            if(i === j)
            {
                auxList[j] = true;
            }
        });

        this.setState({
            listToApply : listFinal,
            validations : {...this.state.validations, inputAmountList: auxList}
        });*/
    }
    
    onValidMandatory = async(properties) =>
    {
        if(properties != null)
        {
            var invalidProperties = 0;
            var invalidPropertiesWarning = 0;
            properties.map(prop =>
                {
                    if(prop.Mandatory)
                    { 
                        if((prop.Type == 'Text' && (prop.TextValue == null || prop.TextValue == ''))
                        || (prop.Type == 'Numeric' && (prop.NumValue == null || prop.NumValue == '' || prop.NumValue.value == 0))
                        || (prop.Type == 'DateTime' && (prop.DateValue === null || prop.DateValue === '') ) ) 
                        {
                            invalidProperties++;
                        }
                        if(prop.AttachmentsRequired && prop.Attachments == 0)
                            {
                                invalidProperties++;
                            }                        
                    }
                    else
                    {
                        if(prop.AmountLimit > 0 && this.state.amountInBaseCurrency > prop.AmountLimit)
                        {
                            if ((prop.Type == 'Text' && (prop.TextValue == null || prop.TextValue == ''))
                            || (prop.Type == 'Numeric' && (prop.NumValue == null || prop.NumValue == '' || prop.NumValue.value == 0))
                            || (prop.Type == 'DateTime' && (prop.DateValue === null || prop.DateValue === '') ) )
                            {
                                invalidPropertiesWarning++;
                            }
                            if(prop.AttachmentsRequired && prop.Attachments == 0)
                            {
                                invalidPropertiesWarning++;
                            }
                        }
                    }
                }
            );

            this.setState({entityPropertiesBlanks: invalidProperties,
                entityPropertiesBlanksWarning: invalidPropertiesWarning,
                loadedProperties: true
            });
        }
        else
        {
            this.setState({entityPropertiesBlanks: 0,
                entityPropertiesBlanksWarning: 0,
                loadedProperties: true
            });
        }
    }

    render() {        

        console.log(this.props)

        let dealUpdateToken = this.props.dealHeaderInfo !== undefined ? this.props.dealHeaderInfo.UpdateToken : 0
        
        let headerMessage = [];
        if (this.state.paymentID > 0) {
            headerMessage.push('Status: ' + this.state.paymentStatus)
        }
        let saveButtonText = "Confirm";//"Save";
        if (this.state.paymentStatus === "Pending") {
            saveButtonText = "Confirm"
        }

        var disableIfFullyApplied = false;
        if (this.state.paymentStatus === 'Fully Applied') {
            disableIfFullyApplied = true;
        }

        let disableIfFromSettlements = false;
        if (this.state.fromSettlements) {
            disableIfFromSettlements = true;
        }

        /*let saveInPendingButtonText = "Save"
        if (this.state.paymentID > 0 && ['Not Applied'].includes(this.state.paymentStatus)) {
            saveInPendingButtonText = "Save In Pending"
        }*/
        let saveInPendingButtonText = "Save In Pending"

        //new dynamic table with editable cell
        var headers;
        var rows = [];

        headers = (
            <tr style={{textAlignLast: 'center'}}>
                <th>Record Number</th>
                <th>Type</th>
                <th>Value Date</th>       
                <th>Buy Amount</th>
                <th>Complete Amount</th>
                <th>Pending Amount</th>
                <th>Source ID</th>
                <th>Status</th>
                <th>Amount To Use</th>                
                {!this.state.readOnly && <th>Actions</th>}
            </tr>
        );
        
        var index = 0;
        if (this.state.listToApplyView !== undefined) 
        {
            this.state.listToApplyView.forEach((element,i) => {
                index++;
                var voidAction;
                var amountControl;
                
                if(this.state.paymentStatus === 'Not Applied' || this.state.paymentStatus === 'Partially Applied') 
                {
                    voidAction = (
                        /*<input className="uk-checkbox" id={index} onClick={this.handleCheckBoxDynamicFunction.bind(this)} type="checkbox" />*/
                        <i 
                            className="fa fa-money" 
                            onClick={() => this.handleNewAppy(element, i)} 
                            style={{ 
                                fontSize        : '1.25em', 
                                verticalAlign   : 'middle', 
                                cursor          : 'pointer', 
                                paddingLeft     : '10px', 
                                paddingRight    : '10px'
                            }}
                            title={'Apply'}
                        />
                    );
                }
                if (Number(element.IncomingFundsID) > 0) 
                {
                    voidAction = (
                        <i 
                            className   = "fa fa-chain-broken" 
                            onClick     = {() => this.handleUnlinkPayment(element)} 
                            style       = {{ 
                                fontSize        : '1.75em', 
                                verticalAlign   : 'middle' 
                            }}
                            title={'Unapply'}
                        />
                    );
                    amountControl = (formatToCurrencyWithScale(element['AmountToUse'], this.state.currencyID));
                }
                else {
                    ////debugger
                    amountControl = (
                        <NumberInput id={'amounttouse-' + index}
                            type        = "Currency"
                            value       = {element['AmountToUse']}
                            //className   = "uk-input"
                            className= {this.state.validations.inputAmountList[i] ? 'uk-input' : ' uk-input uk-form-danger'}
                            disabled    = {this.state.readOnly}
                            onChange    = {this.handleAmountToUse.bind(this,
                                {
                                    recordID: element['RecordID'],
                                    typeRecord: element['TypeRecord'],
                                    pendingAmount: element['PendingAmount'],
                                    incomingFundsID: element['IncomingFundsID'],
                                    amount: element['BuyAmount'],
                                    dealUpdateToken: element['DealUpdateToken'],
                                    depositUpdateToken: element['DepositUpdateToken'],
                                }, i)}
                            onFocus={(event) => this.handleFocusAmountApplied(element,i , event)}
                        />
                    );
                }
                rows.push(
                    <tr key={index} onClick={() => this.handleAppliesPaymentRowClick(element['SourceID'])} >
                        <td style={{textAlign: 'center'}}>{element['RecordNo']}</td>
                        <td style={{textAlign: 'center'}}>{element['Description']}</td>
                        <td style={{textAlign: 'center'}}>{element['ValueDate']}</td>                             
                        <td style={{textAlign: 'right'}}>{formatToCurrencyWithScale(element['BuyAmount'], this.state.currencyID)}</td>
                        <td style={{textAlign: 'right'}}>{formatToCurrencyWithScale(element['CompleteAmount'], this.state.currencyID)}</td>
                        <td style={{textAlign: 'right'}}>{formatToCurrencyWithScale(element['PendingAmount'], this.state.currencyID)}</td>
                        <td style={{textAlign: 'center'}}>{element['SourceID']}</td>
                        <td style={{textAlign: 'center'}}>{element['Status']}</td>
                        <td>
                            {amountControl}
                        </td>                    
                        {!this.state.readOnly && 
                            <td 
                                style = {{
                                    textAlign       : "center",
                                    verticalAlign   : 'middle'
                                }}
                            >
                                {voidAction}
                            </td>
                        }
                    </tr>
                );
            });
        }
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfRecordsToApply = 0;
        this.state.listToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;
            numberOfRecordsToApply++;
        });

        var validToApply = false;
        if (this.state.paymentStatus === "Not Applied" ||
            this.state.paymentStatus === "Partially Applied") {
            if (numberOfRecordsToApply > 0) {
                validToApply = true;
            }
        }

        //Amount control
        var amountControlDisable = false;
        if ((this.state.disbursedAmount > 0 || this.state.refundedAmount > 0)
            && this.state.paymentStatus !== 'Pending') {
            amountControlDisable = true;
        }

        //Currency control
        var currencyControlDisabled = false;
        if (this.state.paymentID > 0 || this.props.buyCurrency !== undefined) {
            currencyControlDisabled = true;
        }

        //Delivery methods
        var deliveryMethods = [];
        if (this.state.listDeliveryMethods[this.state.settlementInstrument] !== undefined) {
            deliveryMethods = this.state.listDeliveryMethods[this.state.settlementInstrument];
        }

        //Show payments filters
        var hidePaymentsFilter = false;
        /*if (Number(this.props.dealHeaderID) > 0) {
            hidePaymentsFilter = true;
        }*/

        //Refund button
        var refundButton;
        if (this.state.paymentID > 0 &&
            this.state.paymentStatus !== 'Pending' &&
            //this.state.amount - this.state.disbursedAmount - this.state.refundedAmount > 0) {
            this.state.OutstandingBalance > 0) {
            refundButton = (
                <button className="uk-button uk-button-green" type="button" onClick={this.onRefundClick}>Refund</button>
            );
        }

        let applyToFundOnHoldButton = []       

        if (this.showApplyToFundOnHoldButton()) {
            applyToFundOnHoldButton.push(<button className="uk-button uk-button-green" type="button" onClick={this.onApplyToHeldFundsClick}
                hidden={false}>Apply to held funds</button>)
        }

        let listCustomerPaymentsFiltered = this.state.listCustomerPayments;
        /*if(!this.state.showLinkedPayments){
            //alert(this.state.showLinkedPayments);
            var entityType = '';
            var entityID = 0;
            if(this.props.dealHeaderID !== undefined){
                entityType = 'Deal';
                entityID = this.props.dealHeaderID;
            }
            if(this.props.objDeposit !== undefined){
                entityType = 'Deposit';
                entityID = this.props.objDeposit.DepositID;
            }

            listCustomerPaymentsFiltered = this.state.listCustomerPayments.filter((payment) => { return payment['LinkedEntities$'] === null || (payment['LinkedEntities$'] !== null && JSON.parse(payment['LinkedEntities$']).find((entity) => { return entity.EntityType === entityType && entity.EntityID === entityID }) !== undefined); });
        }*/
        
        if(this.state.showLinkedPayments){
            let entityID = 0;
            if(this.props.dealHeaderID !== undefined){
                entityID = this.props.dealHeaderID;
            }
            listCustomerPaymentsFiltered = this.state.listCustomerPayments.filter((payment) => { return (payment['LinkedEntities$'] !== null && JSON.parse(payment['LinkedEntities$']).find((entity) => { return entity.EntityType === 'Deal' && entity.EntityID === entityID }) !== undefined); });
        }

        let additionalPropertiesSection = []
        additionalPropertiesSection.push(<li className="">
        <h5 className="uk-accordion-title">Additional Properties</h5>
        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }} >
            <div id="outgoing-funds-additional-properties">
                <EntityProperties id="outgoing-form" 
                    entityType={PaymentType} 
                    entityID={this.state.paymentID} 
                    fullScreen={true} 
                    runSaveProcess={this.state.entityPropertiesSaveProcess} 
                    finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} 
                    /*forceLoad={this.state.forceLoad} outgoingFunds={false} onValidMandatory = {this.onValidMandatory}
                    amount={this.state.amountInBaseCurrency}*/ />
            </div>
        </div>
        </li>)
        let paymentsFoundingSection = []
        if (this.props.dealHeaderID > 0) {
            paymentsFoundingSection.push(<PaymentsApplied
                incomingFunds={this.state.listIncomingFunds || []}
                rowClick={this.handleAppliesPaymentRowClick}
                unlinkPayment={this.handleUnlinkPayment}
                dealUpdateToken={dealUpdateToken}
                readOnly={this.state.readOnly}
            />)
            paymentsFoundingSection.push(<PaymentsNoApplied
                payments={(this.state.listCustomerPayments || []).filter(x => ['Not Applied', 'Partially Applied'].includes(x.Status))}
                rowClick={this.handleAppliesPaymentRowClick}
                handleAmountToUse={this.handleAmountToUse2}
                handleNewAppy={this.handleNewAppy}
                dealHeaderId={this.props.dealHeaderID}
                selectedPaymentId={this.state.paymentID}
                dealUpdateToken={dealUpdateToken}
                readOnly={this.state.readOnly}
                currencyId={this.state.currencyID}
            />)
            if (listCustomerPaymentsFiltered !== undefined) {
                listCustomerPaymentsFiltered = listCustomerPaymentsFiltered.filter(x => !['Fully Applied', 'Not Applied', 'Partially Applied'].includes(x.Status))
            }
        }

        let paymentApplication = []
        let paymentApplicationSection = !(this.props.dealHeaderID > 0)
        if (paymentApplicationSection) {
            paymentApplication.push(<li>
                <h5 className="uk-accordion-title">Payment application</h5>
                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                    <div id="payment-application">
                        <div className="uk-width-1-1" id="payments-apply-component">
                            <div className="uk-grid uk-margin">
                                <div className="uk-width-1-2">
                                    <label className="uk-form-label">Customer Payments Balance: {formatToCurrencyWithScale(this.state.customerPaymentsBalance, this.state.currencyID)} {this.state.currencyID}</label>
                                </div>
                                <div className="uk-width-1-2">
                                    <label className="uk-form-label uk-align-right">Combined Balance: {(this.state.combinedBalance)}</label>
                                </div>

                                <div className="uk-width-1-1">
                                    <label className="uk-form-label uk-align-right">(Current Payment Bal: {formatToCurrencyWithScale(this.state.currentPaymentBal, this.state.currencyID)} + Fund On Hold Bal: {formatToCurrencyWithScale(this.state.fundOnHoldBalance, this.state.currencyID)})</label>
                                </div>
                            </div>
                            <div className="uk-width-1-1 border-table">
                                <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                                    <thead>
                                        {headers}
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                                </table>
                            </div>
                            <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>
                        </div>
                    </div>
                </div>
            </li>)
        }

        var additionalInfo = '';
        if(!this.state.fromSettlements)
        {
            additionalInfo = <ul id="accordion-compliance" uk-accordion="multiple: true" >
                                {paymentsFoundingSection}    

                                {/*Payment application*/}
                                {paymentApplication}

                                {/*Available payments*/}
                                <li className="uk-open">
                                    <h5 className="uk-accordion-title">Available payments</h5>
                                    <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                        <div id="available-payments">
                                            <div className="uk-width-1-1" id="payments-component">
                                                <div className="uk-grid uk-margin uk-form-controls" hidden={hidePaymentsFilter}>
                                                    <div className="uk-width-1-4">
                                                        <label>
                                                            <input className="uk-checkbox" type="checkbox" checked={this.state.showLinkedPayments} onChange={this.handleShowLinkedPaymentUpdate}/> Show linked payments
                                                        </label>
                                                    </div>
                                                </div>

                                                <NewDynamicTable id="alert-incomingpaymentform-table"
                                                    data={listCustomerPaymentsFiltered}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClick.bind(this)}
                                                    hiddenHeaders={["SettlementInstrument", "CustomerBankAccount", "Beneficiary ID", "ThirdPartyBeneficiaryPayment", "DeliveryMethod", "NeedBalanceAdjustment", "Funds Cleared On", "Funds Cleared By", "Payment Ref No","IncomingFundsID","DealUpdateToken"]}
                                                    hidden={this.props.paymentOpenFromBoard}
                                                    enableSortByHeader={true}
                                                    newSearch={this.state.newSearch}
                                                    formats={[{ header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency ID' },
                                                    { header: 'Outstanding Balance', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency ID' }]}
                                                />

                                                <Paginator id="alert-incomingpaymentform-paginator" changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch} />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {additionalPropertiesSection}
                            </ul>;
        }

        var depositInfoControls;
        if(this.state.objDeposit !== undefined){
            var objDeposit = this.state.objDeposit;
            depositInfoControls = (
                <table border="1" align="right">
                    <thead>
                        <th>Deposit ID</th>
                        <th>Status</th>
                        <th>Balance</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{objDeposit.DepositID}</td>
                            <td>{objDeposit.ReceivedStatus}</td>
                            <td>{formatToCurrencyWithScale(Number(objDeposit.Amount) - Number(objDeposit.AmountReceived), objDeposit.CurrencyID)}</td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        var refundForm = (
            <Dialog
                fullWidth={true}
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.refundMode} onClose={this.onRefundBackClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <RefundForm sourceTable="P"
                        sourceID={this.state.paymentID}
                        sourceUpdateToken={this.state.updateToken}
                        customerID={this.state.customerID}
                        //balance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                        balance={this.state.OutstandingBalance}
                        currencyID={this.state.currencyID}
                        listNostroAccounts={this.state.listNostroAccounts}
                        instrument={this.state.settlementInstrument}
                        deliveryMethod={this.state.deliveryMethod}
                        nostroBankAccountID={this.state.nostroBankAccountId}
                        onBackClick={this.onRefundBackClick} />
                </DialogContent>
            </Dialog>
        );

        let paymentsApplyBalanceToHeldFundsForm = <Dialog
            fullWidth={true}
            maxWidth='sm'
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.applyToHeldFundsMode} onClose={this.onApplyToHeldFundsdBackClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <PaymentsApplyBalanceToHeldFundsForm
                    sourceTable="P"
                    onBackClick={this.onApplyToHeldFundsdBackClick}
                    //sourceBalance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                    sourceBalance={this.state.OutstandingBalance}
                    currencyId={this.state.currencyID}
                    incomingFund={this.getIncomingFundForHeldFund()} />
            </DialogContent>
        </Dialog>

        var incomingTitle = "";
        if (this.props.mainTitle != null) {
            incomingTitle = ""
        } else if (this.props.backLink != null && this.props.paymentOpenFromBoard != null) {
            incomingTitle = (
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                        <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                            <i className="fa fa-arrow-left back-arrow-white"></i>
                        </button>
                        Payment Information
                    </h3>
            )
        }
        let incomingPaymentFormAmount = this.state.amount;
        if (this.state.selectedPayment !== undefined && this.state.selectedPayment != null
            && this.state.selectedPayment.Status === 'Fully Applied' && this.state.selectedPayment.Amount > 0) {
            incomingPaymentFormAmount = this.state.selectedPayment.Amount
        }

        var regularScreen = (
            <div>
                {incomingTitle}
                <div className="uk-grid uk-margin">
                    {/*Deposit info if any*/}
                    {/*<div className="uk-width-1-2"></div>
                    <div className="uk-width-1-2">
                        {depositInfoControls}
                    </div>*/}

                    {/*Line1*/}
                    <div className="uk-width-1-4">
                            <label className="uk-form-label">Settlement amount</label>
                        {!this.state.readOnly ?
                            <NumberInput id="form-incomingpaymentform-amount"
                                className={this.formatValidInput(this.state.validations.amount)}
                                type="Currency"
                                placeholder="Please input the TOTAL amount sent by the customer."
                                value={incomingPaymentFormAmount}
                                scale={getScaleByCurrency(this.state.currencyID )}
                                onChange={this.getAmountValue.bind(this)}
                                disabled={amountControlDisable || disableIfFullyApplied || disableIfFromSettlements} />
                            :
                            <p id="label-incomingpaymentform-amount" style={{marginTop: '5px'}}>
                                {formatToCurrencyWithScale(this.state.amount, this.state.currencyID )}
                            </p>
                        }

                    </div>
                    <div className="uk-width-1-4">
                        <label className="uk-form-label">Currency</label>
                        {!this.state.readOnly ?
                            <DynamicSelect id="form-incomingpaymentform-currency"
                            className={this.formatValidSelect(this.state.validations.currencyId)}
                            placeholder='Select a currency'
                            placeholderValue=''
                            data={this.state.listActiveCurrencies}
                            objValue={{ value: this.state.currencyID }}
                            getValue={this.handleCurrencyUpdate}
                            disabled={currencyControlDisabled || disableIfFullyApplied || disableIfFromSettlements} />
                            :
                            <p id="label-incomingpaymentform-currency" style={{marginTop: '5px'}}>
                                {this.state.currencyID !== "" && this.state.listActiveCurrencies.length > 0 ?
                                    this.state.listActiveCurrencies.find(c => c.CurrencyID === this.state.currencyID).name : "-"}
                            </p>
                        }
                    </div>

                    <div className="uk-width-1-2">
                        <div style={{ textAlign: "right" }}>
                            <h4>{headerMessage}</h4>
                        </div>
                    </div>

                    {/*Line2*/}
                    <div className="uk-width-1-4" style={{ marginTop: 7 }}>
                        <label className="uk-form-label">Settlement Instrument</label>
                        {!this.state.readOnly ?
                            <DynamicSelect id="form-incomingpaymentform-settlementintrument"
                                className={this.formatValidSelect(this.state.validations.settlementInstrument)}
                                data={this.state.listIncomingInstruments}
                                objValue={{ value: this.state.settlementInstrument }}
                                placeholder="Select an Instrument..."
                                getValue={this.handleIncomingInstrument} 
                                disabled={disableIfFullyApplied}/>
                            :
                            <p id="label-incomingpaymentform-settlementintrument" style={{marginTop: '5px'}}>
                                {this.state.settlementInstrument !== "" ?this.state.settlementInstrument : "-"}
                            </p>
                        }
                    </div>

                    <div className="uk-width-1-3 uk-background-muted uk-margin-top uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                        style={{ borderWidth: 1, borderColor: 'lightgrey', borderStyle: 'solid', borderRightWidth: 0, borderBottomWidth: 0 , marginLeft: "40px"}} >
                        <div className="uk-form-controls" style={{ paddingTop: '0.5em' }} hidden={!this.state.showETFFields/* && !this.state.showDirectDebitFields*/} >
                            <input className="uk-checkbox" type="checkbox" disabled={this.state.readOnly || disableIfFullyApplied} id="form-incomingpaymentform-thirdPartyBeneficiaryPayment" checked={this.state.thirdPartyBeneficiaryPayment}
                                onChange={this.handleOnCheckThirdPartyBeneficiaryPayment.bind(this)} />
                            <label className="uk-form-label">Third Party?</label>
                        </div>

                        <label className="uk-form-label" hidden={this.hideChequeInfo()}>Cheque number</label>
                        <div className="uk-form-controls" hidden={this.hideChequeInfo()}>
                            {!this.state.readOnly ?
                                <input className={regularInputClass} type="text" id="form-incomingpaymentform-chequeNumber" value={this.state.chequeNumber}
                                    onChange={this.handleChequeNumber.bind(this)} hidden={this.hideChequeInfo()} disabled={disableIfFullyApplied}/>
                                :
                                <p id="label-incomingpaymentform-settlementintrument" style={{marginTop: '5px'}}>
                                    {this.state.chequeNumber != null && this.state.chequeNumber !== "" ?this.state.chequeNumber : "-"}
                                </p>
                            }
                        </div>

                        <label className="uk-form-label" hidden={!this.state.showDirectDebitFields} > Customer bank account</label>
                        <div className="uk-form-controls" hidden={!this.state.showDirectDebitFields}>
                            {!this.state.readOnly ?
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.customerBankAccountId)} id="form-incomingpaymentform-customerbankaccount" placeholder='Select an Account...'
                                    data={this.state.listCustomerBankAccounts}
                                    objValue={{ value: this.state.customerBankAccountId }} getValue={this.handleCustomerBankAccount.bind(this)}
                                    disabled={disableIfFullyApplied}
                                />
                                :
                                <p id="label-incomingpaymentform-beneficiaryId" style={{marginTop: '5px'}}>
                                    {this.state.customerBankAccountId > 0 && this.state.listCustomerBankAccounts.length > 0 ?
                                        this.state.listCustomerBankAccounts.find(n => n.value === this.state.customerBankAccountId).name : "-"}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="uk-width-1-3 uk-background-muted uk-margin-top uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                        style={{ borderWidth: 1, borderColor: 'lightgrey', borderStyle: 'solid', borderLeftWidth: 0, borderBottomWidth: 0}}>                                                     

                        <label className="uk-form-label" hidden={this.hideChequeInfo()}>Cheque issue date</label>
                        <div className="uk-form-controls" hidden={this.hideChequeInfo()}>
                            {!this.state.readOnly ?
                                <DatePicker className={regularInputClass} id="form-incomingpaymentform-chequeIssueDate" value={this.state.chequeDate}
                                    onDayChange={this.handleChequeDate.bind(this)} endDate={this.state.endDate} disabled={disableIfFullyApplied}/>
                                :
                                <p id="label-incomingpaymentform-dateReceived" style={{marginTop: '5px'}}>
                                    {this.state.chequeDate !== "" ? formatDate(this.state.chequeDate) : "-"}
                                </p>
                            }
                        </div>
                    </div>

                    {/*Line3*/}

                    <div className="uk-width-1-4" style={{ marginTop: 7 }}>
                        <label className="uk-form-label">Delivery method</label>
                        {!this.state.readOnly ?
                            <DynamicSelect id="form-incomingpaymentform-deliverymethod"
                                className={this.formatValidSelect(this.state.validations.deliveryMethod)}
                                data={deliveryMethods}
                                objValue={{ value: this.state.deliveryMethod }}
                                placeholder="Select a Delivery Method..."
                                placeholderValue=""
                                getValue={this.handleDeliveryMethod} 
                                disabled={disableIfFullyApplied || disableIfFromSettlements}/>
                            :
                            <p id="label-incomingpaymentform-deliverymethod" style={{marginTop: '5px'}}>
                                {this.state.deliveryMethod != null && this.state.deliveryMethod !== "" ?this.state.deliveryMethod : "-"}
                            </p>
                        }
                    </div>

                    <div className="uk-width-2-3 uk-background-muted uk-padding-small uk-padding-remove-top"
                        style={{ borderWidth: 1, borderColor: 'lightgrey', borderStyle: 'solid', borderTopWidth: 0, marginLeft: "40px"}}>
                        <label className="uk-form-label" hidden={!this.state.thirdPartyBeneficiaryPayment}>Third party entity</label>
                        <div className="uk-form-controls" hidden={!this.state.thirdPartyBeneficiaryPayment}>
                            {!this.state.readOnly ?
                                <DynamicSelect className={regularSelectClass} id="form-incomingpaymentform-beneficiaryId" placeholder='Select a Beneficiary...'
                                    data={this.state.listThirdPartyBenes} objValue={{ value: this.state.beneficiaryId }} getValue={this.handleBeneficiaryId.bind(this)} 
                                    disabled={disableIfFullyApplied || disableIfFromSettlements}/>
                                :
                                <p id="label-incomingpaymentform-beneficiaryId" style={{marginTop: '5px'}}>
                                    {this.state.beneficiaryId > 0 && this.state.listThirdPartyBenes.length > 0 ?
                                        this.state.listThirdPartyBenes.find(n => n.value === this.state.beneficiaryId).name : "-"}
                                </p>
                            }
                        </div>

                        <label className="uk-form-label" hidden={this.hideChequeInfo()}>Bank</label>
                        <div className="uk-form-controls uk-width-4-5" hidden={this.hideChequeInfo()}>
                            {!this.state.readOnly ?
                                <DynamicSelect className={regularSelectClass} id="form-incomingpaymentform-banks" placeholder='Select a Bank...'
                                    data={this.state.listBanks} objValue={{ value: this.state.chequeBankId }} getValue={this.handleChequeBank.bind(this)} 
                                    disabled={disableIfFullyApplied || disableIfFromSettlements}/>
                                :
                                <p id="label-incomingpaymentform-beneficiaryId" style={{marginTop: '5px'}}>
                                    {this.state.chequeBankId > 0 && this.state.listBanks.length > 0 ?
                                        this.state.listBanks.find(n => n.value === this.state.chequeBankId).name : "-"}
                                </p>
                            }
                        </div>
                    </div>

                    {/*Line4*/}
                    <div className="uk-width-1-4" style={{ marginTop: 7 }}>
                        <label className="uk-form-label" >Date received</label>
                        {!this.state.readOnly ?
                            <DatePicker className={this.formatValidInput(this.state.validations.dateReceived)} id="form-incomingpaymentform-dateReceived" value={this.state.dateReceived}
                            onDayChange={this.handleDateReceived.bind(this)} endDate={this.state.endDate} disabled={disableIfFullyApplied}/>
                            :
                            <p id="label-incomingpaymentform-dateReceived" style={{marginTop: '5px'}}>
                                {this.state.dateReceived !== "" ? formatDate(this.state.dateReceived) : "-"}
                            </p>
                        }
                    </div>

                    <div className="uk-width-1-4" style={{ marginTop: 7 }}>
                        <label className="uk-form-label">Payment reference no</label>
                        {!this.state.readOnly ?
                            <input className={regularInputClass} type="text" id="form-incomingpaymentform-paymentReferenceNo" value={this.state.paymentReferenceNo} 
                            onChange={this.handlePaymentReferenceNo.bind(this)} disabled={disableIfFullyApplied || disableIfFromSettlements}/>
                            :
                            <p id="label-incomingpaymentform-paymentReferenceNo" style={{marginTop: '5px'}}>
                                {this.state.paymentReferenceNo !== "" && this.state.paymentReferenceNo != null ?this.state.paymentReferenceNo : "-"}
                            </p>
                        }
                    </div>

                    <div className="uk-width-1-4"></div>

                    <div className="uk-width-1-4"></div>


                    {/*Line5*/}
                    <div className="uk-width-1-2" style={{ marginTop: 7 }}>
                        <label className="uk-form-label">Nostro account</label>
                        <div className="uk-form-controls">
                            {!this.state.readOnly ?
                                <DynamicSelect 
                                    className={this.formatValidSelect(this.state.validations.nostroAccountId)} 
                                    id="form-incomingpaymentform-nostroaccounts" 
                                    placeholder='Select an Account...'
                                    placeholderValue={0}
                                    data={this.state.listNostroAccounts} 
                                    objValue={{ value: this.state.nostroBankAccountId }} 
                                    getValue={this.handleNostroBankAccount.bind(this)} 
                                    disabled={disableIfFullyApplied}
                                />
                                :
                                <p id="label-incomingpaymentform-nostroaccounts" style={{marginTop: '5px'}}>
                                    {this.state.nostroBankAccountId > 0 && this.state.listNostroAccounts.length > 0 ?
                                        this.state.listNostroAccounts.find(n => n.value === this.state.nostroBankAccountId).name : "-"}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="uk-width-1-2"></div>

                    {/*end*/}

                </div>

                {!this.state.readOnly && !disableIfFromSettlements && (
                    <div>
                            <button className="uk-button uk-button-green" type="button" onClick={e => this.handleSaveClickAskConfirmation(e, false)}
                                hidden={this.hideConfirmButton()}>{saveButtonText}</button>
                            
                            <button className="uk-button uk-button-green" type="button" onClick={e => this.handleSaveClickAskConfirmation(e, true)}
                                hidden={this.hideSaveInPendingButton()}>{saveInPendingButtonText}</button>
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                            <button className="uk-button uk-button-green" type="button" onClick={this.voidPayment} hidden={this.hideVoidButton()}>Void</button>
                            {refundButton}
                            {applyToFundOnHoldButton}
                            {this.props.dealHeaderID > 0 && <button className="uk-button uk-button-green" type="button" onClick={() => this.setState({openVersions: true})}> View Versions</button>}
                    </div>)
                }

                {disableIfFromSettlements && (
                    <div>
                            <button className="uk-button uk-button-green" type="button" onClick={e => this.handleSaveClickAskConfirmation(e, false)}>
                            {saveButtonText}</button>
                    </div>)
                }

                {!this.state.fromSettlements && <hr></hr>} {/* Apply */}

                {additionalInfo}

                {!this.state.fromSettlements && <hr></hr>} 

                {/** "Refunds" and "Apply Held funds" as popup*/}
                {refundForm}
                {paymentsApplyBalanceToHeldFundsForm}

                <IncomingFundVersionsByDealScreen
                    open={this.state.openVersions}
                    close={() => this.setState({openVersions: false})}
                    entityID={this.props.dealHeaderID}
                />

                <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />

                <AlertBox id="alert-void-incoming"
                    type="Yes/No"
                    open={this.state.showVoidIncomingAlert}
                    title="Confirm"
                    message={this.state.messageVoid}
                    yesClick={this.yesVoidIncomingFund}
                    noClick={this.closeVoidIncomingAlert}
                    onClose={this.closeVoidIncomingAlert} />

                <AlertBox id="alert-incomingpaymentform-save"
                    open={this.state.showAlertSave}
                    title="Confirm"
                    message={this.state.alertMessageSave}
                    type="Yes/No"
                    yesClick={this.savePayment}
                    noClick={this.closeAlertSave.bind(this)}
                    onClose={this.closeAlertSave.bind(this)} />

                <AlertBox id="apply-payments-alert"
                    open={this.state.showApplyPaymentsAlert}
                    title="Please confirm"
                    message={"You will apply: " + formatToCurrencyWithScale(amountTotalToApply, this.state.currencyID) + ' ' + this.state.currencyID}
                    type="Yes/No"
                    yesClick={this.yesApplyPaymentsAlert.bind(this)}
                    noClick={this.noApplyPaymentsAlert.bind(this)}
                />

                <AlertBox id="apply-current-payment-alert"
                    open     = {this.state.showApplyCurrentPaymentsAlert}
                    title    = "Please confirm"
                    message  = {this.state.confirmCurrentElementMessage}
                    type     = "Yes/No"
                    yesClick = {this.yesApplyCurentPaymentsAlert}
                    noClick  = {this.noApplyCurrentPaymentsAlert}
                />

                <AlertBox id="delete-alert"
                    type="Yes/No"
                    open={this.state.showVoidRefundAlert}
                    title="Confirmation"
                    message="Are you sure you want to void this refund?"
                    yesClick = {this.yesDeleteRefund}
                    noClick = {this.noDeleteRefund}/>

                <AlertBox id="void-payment-alert"
                    type="Yes/No"
                    open={this.state.showVoidPaymentAlert}
                    title="Confirmation"
                    message="Are you sure you want to void this payment?"
                    yesClick={this.voidPaymentConfirmed}
                    noClick={this.cancelVoidPayment} />

                <LoadingBox loading={this.state.loading || this.state.loadingRefunds || this.state.loadingPayments} />
            </div>
        );

        // if (this.state.applyToHeldFundsMode) {
        //     return paymentsApplyBalanceToHeldFundsForm
        // } else if (this.state.refundMode) {
        //     return refundForm;
        // }
        // else {
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Payment Information - Payment ID: {this.state.paymentID}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                {regularScreen}
                <OutgoingSendToInterface outgoings={this.state.outgoingsToSend} dealHeaderID={this.props.dealHeaderID} customerID={this.props.customerID} showModal={this.state.showOutgoingsToSend} onCloseModal={()=> this.setState({showOutgoingsToSend:false})} />

            </div>);
        // }
    }
}

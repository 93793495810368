import React from 'react';
import { useLocation, useMatch, useNavigate, useParams} from "react-router-dom";  

  // Wrapper around router hooks for Class Components
  function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      const location = useLocation();
      const navigate = useNavigate();
      const params = useParams();
      const match = useMatch();
      return (
        <Component
          {...props}
          navigate={navigate}
          params={params}
          match={match}
          location={location}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

  export default withRouter;
import React from 'react';
import { getScreenInformation, searchCustomerByIdNameMin } from '../../../../networking/NetworkingCustomers';
import { getLookUpTable } from '../../../../networking/Networking';
import {getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { validate } from '../../../../helpers/ValidateHelper';
const style = getStyle();

export default class CustomerInformationForm extends React.Component {
    state = {
        customer: {
            CommissionBase: '',
            CustomerGroupID: '',
            CustomerID: 0,
            CustomerType: '',
            DefaultBranchID: '',
            DefaultPricingID: '',
            FirstName: '',
            LastName: '',
            Name: '',
            ParentCustomerID: '',
            ParentCustomerName: '',
            PreferredLanguage: '',
            Region: '',
            Salutation: '',
            ServiceLevel: '',
            ShortName: '',
            SpreadPoints: '',
        },
        listParents: [],
        dropdowns: {
            branches: [],
            ratePricingCategories: [],
            "Commission Base": [],
            "Customer Groups": [],
            "Customer Regions": [],
            "Customer Type": [],
            "Languages": [],
            "Salutation": [],
            "Service Level": [],
            "Spread Points": [],
        },
        lookUpTables: [
            "Commission Base",
            "Customer Groups",
            "Customer Regions",
            "Customer Type",
            "Languages",
            "Salutation",
            "Service Level",
            "Spread Points"
        ],
        validations: {
            customerType: true,
            companyName: true,
            firstName: true,
            lastName: true,
            preferredLanguage: true,
        }
    }

    componentDidMount() {
        this.props.isValid(this.validateRequiredInputs())
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        });
        getScreenInformation().then(
            (json) => {
                let format = (list, property1, property2) => {
                    var helper = [];
                    list.forEach(row => helper.push({value: row[property1], name: row[property2]}));
                    return helper;
                }                
                this.setState({
                    dropdowns: {
                        ...this.state.dropdowns,
                        branches: format(json.branches, "BranchID", "Description"),
                        ratePricingCategories: format(json.ratePricingCategories, "CategoryID", "CategoryName")
                    }
                });
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validations !== this.props.validations && !this.props.validations ) {
            this.validateRequiredFields();
        }
    }

    searchParent = (query) => {
        console.group("searchParent");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for(var i=0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({listParents: response.customers});
                }
            }
        )
    }

    validateRequiredFields() {
        var customerType = true, preferredLanguage =  true,
        companyName = true, firstName = true, lastName = true;
        if (this.state.customer.CustomerType === "select" || this.state.customer.CustomerType === '' | this.state.customer.CustomerType === undefined) {
            customerType = false;
        }
        if (this.state.customer.PreferredLanguage === "select" || this.state.customer.PreferredLanguage === '' || this.state.customer.PreferredLanguage === undefined) {
            preferredLanguage = false;
        }
        if (this.state.customer.CustomerType.includes("Individual")) {
            if (this.state.customer.FirstName === ''){
                firstName = false;
            }
            if (this.state.customer.LastName === ''){
                lastName = false;
            }
        } else {
            if (this.state.customer.Name === '') {
                companyName = false;
            }
        }

        this.setState({
            validations: {
                ...this.state.validations,
                customerType: customerType,
                preferredLanguage: preferredLanguage,
                companyName: companyName,
                firstName: firstName,
                lastName: lastName
            }
        })
    }

    validateRequiredInputs() {
        let fields = [
            {displayName: 'Customer Type', validateName: 'customerType', value: this.state.customer.CustomerType, type: 'string'},
            {displayName: 'Preferred Language', validateName: 'preferredLanguage', value: this.state.customer.PreferredLanguage, type: 'string'},
        ]

        if (this.state.customer.CustomerType.includes("Individual")) {
            fields.push({displayName: 'First Name', validateName: 'firstName', value: this.state.customer.FirstName, type: 'string'});
            fields.push({displayName: 'Last Name', validateName: 'lastName', value: this.state.customer.LastName, type: 'string'});
        } else {
            fields.push({displayName: 'Company Name', validateName: 'companyName', value: this.state.customer.Name, type: 'string'})
        }

        const [valid, , errors] = validate(fields);

        return {valid, errors};
    }

    validateCustomerType() {
        if (this.state.customer.CustomerType === "select" || this.state.customer.CustomerType === '' | this.state.customer.CustomerType === undefined) {
            return false;
        }
        return true;
    }

    validatePreferredLanguage() {
        if (this.state.customer.PreferredLanguage === "select" || this.state.customer.PreferredLanguage === '' || this.state.customer.PreferredLanguage === undefined) {
            return false;
        }
         return true;
    }

    validateFirstName() {
        if (this.state.customer.FirstName === ''){
            return false;
        }
        return true;
    }

    validateLastName() {        
        if (this.state.customer.LastName === ''){
            return false;
        }
        return true;
    }

    validateCompanyName() {
        if (this.state.customer.Name === '') {
            return false;
        }
        return true;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    formatVisibilityIndividual(customerType) {
        return customerType.includes("Individual") ? "visible" : "hidden";
    }

    formatVisibilityCorporate(customerType) {
        return !customerType.includes("Individual") ? "visible" : "hidden";
    }

    handleGetValueSpreadPoints = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, SpreadPoints: value.value
                }
            }, 
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueServiceLevel = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, ServiceLevel: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueCustomerType = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {...this.state.customer, CustomerType: value.value},
                validations: {...this.state.validations, customerType: true}
            },
                () => {
                    if (this.validateCustomerType()) {
                        this.props.getCustomerInfo(this.state.customer);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: []});
                    }
                }
            );
        }
    }

    handleGetValueSalutation = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {...this.state.customer, Salutation: value.value}
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueRegion = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, Region: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueGroups = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, CustomerGroupID: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueLanguages = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {...this.state.customer, PreferredLanguage: value.value},
                validations: {...this.state.validations, preferredLanguage: true}
            },
                () => {
                    if (this.validatePreferredLanguage()) {
                        this.props.getCustomerInfo(this.state.customer);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: []});
                    }
                }
            );
        }
    }

    handleGetValueCommissionBase = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, CommissionBase: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, DefaultBranchID: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleGetValuePricing = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({
                customer: {
                    ...this.state.customer, DefaultPricingID: value.value
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }
    
    handleGetValueParent = (value) => {
        if (value !== undefined && value !== "select" && value !== '') {
            this.setState({
                customer: {
                    ...this.state.customer, ParentCustomerID: value.value, ParentCustomerName: value.name
                }
            },
                () => {
                    this.props.getCustomerInfo(this.state.customer);
                }
            );
        }
    }

    handleUpdateCustomerCode = (event) => {
        this.setState({
            customer: {
                ...this.state.customer, CustomerCode: event.target.value
            }
        },
            () => {
                this.props.getCustomerInfo(this.state.customer);
            }
        );
    }

    handleUpdateName = (event) => {
        this.setState({
            customer: {...this.state.customer, Name: event.target.value},
            validations: {...this.state.validations, companyName: true}
        },
            () => {
                if (this.validateCompanyName()) {
                    this.props.getCustomerInfo(this.state.customer);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: []});
                }
            }
        );
    }

    handleUpdateFirstName = (event) => {
        this.setState({
            customer: {...this.state.customer, FirstName: event.target.value},
            validations: {...this.state.validations, firstName: true}
        },
            () => {
                if (this.validateFirstName()) {
                    this.props.getCustomerInfo(this.state.customer);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: []});
                }
            }
        );
    }

    handleUpdateLastName = (event) => {
        this.setState({
            customer: {...this.state.customer, LastName: event.target.value},
            validations: {...this.state.validations, lastName: true}
        },
            () => {
                if (this.validateLastName()) {
                    this.props.getCustomerInfo(this.state.customer);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: []});
                }
            }
        );
    }

    handleUpdateShortName = (event) => {
        this.setState({
            customer: {
                ...this.state.customer, ShortName: event.target.value
            }
        },
            () => {
                this.props.getCustomerInfo(this.state.customer);
            }
        );
    }

    render(){
        return (
            <div>
                <div className="">
                    <form className="uk-form-horizontal uk-margin-large">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel} >Type (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect className={this.formatValidSelect(this.state.validations.customerType)} objValue={{value: this.state.customer.CustomerType}} getValue={this.handleGetValueCustomerType} listHasPlaceholder={true} data={this.state.dropdowns["Customer Type"]} id="form-customer-type" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Customer Code</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.CustomerCode} onChange={this.handleUpdateCustomerCode} />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                <label className="uk-form-label" >Salutation</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.Salutation === null ? []: this.state.customer.Salutation}} getValue={this.handleGetValueSalutation} listHasPlaceholder={true} data={this.state.dropdowns["Salutation"]} id="form-salutation" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Parent</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSearchSelect id="parent-list" placeholder="Parent" searchFunction={this.searchParent} getValue={this.handleGetValueParent} data={this.state.listParents} objValue={{value: this.state.customer.ParentCustomerID, name: this.state.customer.ParentCustomerName}}/>
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                <label className="uk-form-label" style={style.boldLabel}>First Name (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className={this.formatValidInput(this.state.validations.firstName)} type="text" placeholder="" value={this.state.customer.FirstName} onChange={this.handleUpdateFirstName} />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: "hidden"}}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input type="text" />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                <label className="uk-form-label" style={style.boldLabel}>Last Name (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className={this.formatValidInput(this.state.validations.lastName)} type="text" placeholder="" value={this.state.customer.LastName} onChange={this.handleUpdateLastName} />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: "hidden"}}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input type="text" />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: this.formatVisibilityCorporate(this.state.customer.CustomerType)}}>
                                <label className="uk-form-label" style={style.boldLabel}>Company Name (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className={this.formatValidInput(this.state.validations.companyName)}  type="text" placeholder="" value={this.state.customer.Name} onChange={this.handleUpdateName} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Short Name</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className="uk-input" type="text" placeholder="" value={this.state.customer.ShortName} onChange={this.handleUpdateShortName} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Service Level</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.ServiceLevel}} getValue={this.handleGetValueServiceLevel} listHasPlaceholder={true} data={this.state.dropdowns["Service Level"]} id="form-service-level" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Spread / Points</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.SpreadPoints}} getValue={this.handleGetValueSpreadPoints} listHasPlaceholder={true} data={this.state.dropdowns["Spread Points"]} id="form-spread-points" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Pricing</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.DefaultPricingID}} getValue={this.handleGetValuePricing} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.ratePricingCategories} id="form-pricing" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Branch</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.DefaultBranchID}} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branch" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Region</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.Region}} getValue={this.handleGetValueRegion} listHasPlaceholder={true} data={this.state.dropdowns["Customer Regions"]} id="form-regions" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Group</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.CustomerGroupID}} getValue={this.handleGetValueGroups} listHasPlaceholder={true} data={this.state.dropdowns["Customer Groups"]} id="form-groups" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Commission Base</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.customer.CommissionBase}} getValue={this.handleGetValueCommissionBase} listHasPlaceholder={true} data={this.state.dropdowns["Commission Base"]} id="form-commission-base" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}>Preferred Language (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect className={this.formatValidSelect(this.state.validations.preferredLanguage)} objValue={{value: this.state.customer.PreferredLanguage}} getValue={this.handleGetValueLanguages} listHasPlaceholder={true} data={this.state.dropdowns["Languages"]} id="form-languages" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
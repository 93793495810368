/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import AppContext from '../../../AppContext';
import { validate } from '../../../helpers/ValidateHelper';
import { updateParametersForUser } from '../../../networking/NetworkingGlobalSystemParameters';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import GlobalParameterGrid from './GlobalParameterGrid';
import useGlobalSystemParameters from './useGlobalSystemParameters';
const style = getStyle();

const GlobalSystemParametersUserScreen = () => {
    const context = useContext(AppContext);
    const [modifiedCells, setModifiedCells] = useState([[], [], [], [], []]);
    const [parameters, splittedParameters, reloadParameters, splitParametersForGrids] = useGlobalSystemParameters(context);
    const [filter, setFilter] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertErrorTitle, setAlertErrorTitle] = useState('');
    const [alertErrorMessage, setAlertErrorMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [valid, setValid] = useState(true);

    const saveGlobalSystemParameters = async () => {
        const parameterList = modifiedCells.flat();
        const model = {
            parameterList: parameterList
        };
        context.startLoading();
        const json = await updateParametersForUser(model);
        context.finishLoading();
        if (json != null) {
            switch (Number(json.httpStatusCode)) {
                case 200: {
                    if (!valid) {
                        setAlertErrorTitle('Warning');
                        setAlertErrorMessage('Some fields where saved with no value assigned.');
                        setShowAlertError(true);
                    }
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Parameters successfully saved.');
                    break;
                }
                default: {
                    setShowAlertError(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    break;
                }
            }
        }
        reloadParameters(true);
    };

    const validateMandatoryParameters = (parameterList) => {
        const mandatories = parameterList.filter((param) => param.Mandatory);
        if (mandatories.length === 0) {
            setErrors([]);
            setValid(true);
        } else {
            const fields = mandatories.map((param) => ({ displayName: param.ParameterName, value: param.ParameterValue }));
            const [valid, , errors] = validate(fields);
            setErrors(errors);
            setValid(valid);
        }

    };

    const handleChangeFilter = (event) => {
        const textValue = event.target.value.toLowerCase();
        setFilter(textValue);
        const byTextValue = (text) => (par) =>
            (par['Parameter Name'] != null && par['Parameter Name'].toLowerCase().includes(text)) ||
            (par['Parameter Value'] != null && par['Parameter Value'].toLowerCase().includes(text)) ||
            (par['Description'] != null && par['Description'].toLowerCase().includes(text));
        const filteredParameterList = parameters.filter(byTextValue(textValue));
        splitParametersForGrids(filteredParameterList);
    };

    const handleCellsModified = (cells, tab) => {
        let newModifiedCells = [...modifiedCells];
        newModifiedCells[tab - 1] = cells;
        setModifiedCells(newModifiedCells);
        validateMandatoryParameters(newModifiedCells.flat());
    };

    const closeAlert = () => setShowAlert(false);

    const closeAlertError = () => setShowAlertError(false);

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle}>
                Global System Parameters
            </h3>
            <div className="uk-grid container-filter-properties" id="parameters-filter">
                <div className="uk-width-2-12 filter-label" style={{ paddingTop: '5px' }}>
                    <form className="uk-form-horizontal">
                        <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">
                            Filter
                        </label>
                        <div className="uk-form-controls-filter">
                            <input className="uk-input" id="parameters-filter-input" type="text" placeholder="" value={filter} onChange={handleChangeFilter} />
                        </div>
                    </form>
                </div>
                <div>
                    <button class="uk-button uk-button-green" onClick={saveGlobalSystemParameters}>
                        Save
                    </button>
                </div>
            </div>
            <ul id="accordion-global-system-parameters" uk-accordion="multiple: true">
                {splittedParameters.map((params, index) => {
                    const tab = index + 1;
                    return (
                        params.length > 0 && (
                            <li className="uk-open">
                                <h5 className="uk-accordion-title">{`Tab ${tab} (${params.length} Items)`}</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id={`tab-${tab}`}>
                                        <GlobalParameterGrid key={`tab${tab}`} id="grid-tab1" tab={tab} gridContent={params} onCellsModified={handleCellsModified} />
                                    </div>
                                </div>
                            </li>
                        )
                    );
                })}
            </ul>
            <AlertBox id="AlertBox-GSP" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
            <AlertBox
                id="AlertBox-GSP-error"
                open={showAlertError}
                onClose={closeAlertError}
                title={alertErrorTitle}
                message={alertErrorMessage}
                messageBoldList={errors}
                type="Ok"
                okClick={closeAlertError}
            />
        </div>
    );
};

export default GlobalSystemParametersUserScreen;

import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import { regularSelectClass, regularInputClass, notFilledInputClass, notFilledSelectClass } from '../../../styles/styles';
import { saveHolidayCarryOver } from '../../../networking/NetworkingHolidays';
import AlertBox from '../../shared/AlertBox';

export default class CarryOverValueDateHolidaysForm extends React.Component {
    
    state = {
        notFirstLoad: false,
        showSaveAlert: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',

        
        currency: '',
        currenciesList:[],
        fromYear: '',
        toYear: '',
        yearList: [],
        allCurrencies: false,
        listMissing: [],

        validations: {
            fromYear: true,
            toYear: true,
            currency: true
        },
    }

    componentDidMount(){
      if(this.props.currenciesList !== null && this.props.currenciesList !== undefined && this.props.yearList !== undefined){
          this.setState({currenciesList: this.props.currenciesList, currency: this.props.currenciesList[0].value, yearList: this.props.yearList})
      }
    }
    
    handleSaveClick(event){
        event.preventDefault()
        let userID = localStorage.getItem('UserID');
        var model = {  
            fromYear: this.state.fromYear,
            toYear: this.state.toYear,
            currencyID: this.state.currency,
            allCurrencies: this.state.allCurrencies,
            userID: userID
        }
        if(this.validateRequiredFields()){
            saveHolidayCarryOver(model).then(()=>
            {
                this.setState({
                    fromYear: '',
                    toYear: '',
                    currency: '',
                    allCurrencies: false,
                    showSaveAlert: true, 
                    alertTitle: 'Success',
                    alertMessage: 'Carry Over successful.'});                          
            })
        }
    }

    handleExitClick(event){
        //event.preventDefault();
        this.props.backFromCarryOver();
    }

    validateRequiredFields() 
    {
        var valid = true
        var fromYear = true
        var toYear = true
        var currency = true
        var listItems =[]        

        if(this.state.fromYear === undefined || 
            this.state.fromYear === "")
         {
             valid = false;
             fromYear = false;
             listItems.push("From Year")
         }

        if(this.state.toYear === undefined || 
           this.state.toYear === "")
        {
            valid = false;
            toYear = false;
            listItems.push("To Year")
        }

        if(this.state.currency === undefined || 
            this.state.currency === "" ||
            this.state.currency === null)
         {
             valid = false;
             currency = false;
             listItems.push("Currency")
         }
        
        this.setState({listMissing: listItems,validations: {
                ...this.state.validations,
                fromYear: fromYear,
                toYear: toYear,
                currency: currency
            }});

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleUpdateCurrencies(value){
        if(value.value!==undefined){
            this.setState({currency: value.value,validations: {...this.state.validations, currency: true}});
        }        
    }

    handleOnAllCurrenciesCheck(event){
        this.setState({
            allCurrencies: event.target.checked
        })
    }
    
    handleUpdateFromYear(value){
        this.setState({fromYear: value.value,validations: {...this.state.validations, fromYear: true}});
    } 

    handleUpdateToYear(value){
        this.setState({toYear: value.value,validations: {...this.state.validations, toYear: true}});
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    closeSaveAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
        this.props.backFromCarryOver();
    }

    render() {
        var classNameSelect =  regularSelectClass;
        
        return (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1"> 
                    <div className="uk-heading">
                        <h3><button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleExitClick.bind(this)}>
                            <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                            </button> 
                        Holiday Carry Over</h3>
                    </div>
                </div>
                <div className="uk-width-1"> 
                    <div className="uk-margin">
                        <br></br>
                    </div>
                </div>
                <div className="uk-width-1"> 
                    <div className="uk-heading-divider">
                        <h4>Currency Information</h4>
                    </div>
                </div>
                <div className="uk-width-1-2">                     
                    <label className="uk-form-label">Select Currency</label>
                    <div className="uk-form-controls">
                        <DynamicSelect id="currency-list" className={classNameSelect} getValue={this.handleUpdateCurrencies.bind(this)} listHasPlaceholder={false} data={this.state.currenciesList} objValue={{value: this.state.currency}}/>
                    </div>
                </div>
                <div className="uk-width-1-2 uk-margin">
                    <label className="uk-form-label" htmlFor="form-carry-over"><input className="uk-checkbox" id="allCurrencies" type="checkbox"  checked={this.state.allCurrencies} onChange={this.handleOnAllCurrenciesCheck.bind(this)}/> All Currencies</label>                
                </div>
                <div className="uk-width-1"> 
                    <div className="uk-margin">
                        <br></br>
                    </div>
                </div>
                <div className="uk-width-1"> 
                    <div className="uk-heading-divider">
                        <h4>Period</h4>
                    </div>
                </div>
                <div className="uk-width-1-2">                     
                    <label className="uk-form-label">From Year</label>
                    <div className="uk-form-controls">
                        <DynamicSelect id="year-list" className={classNameSelect} getValue={this.handleUpdateFromYear.bind(this)} placeholder="Select a year..." data={this.state.yearList} objValue={{value: this.state.fromYear}}/>
                    </div>
                </div>
                <div className="uk-width-1-2">                     
                    <label className="uk-form-label">To Year</label>
                    <div className="uk-form-controls">
                        <DynamicSelect id="year-list" className={classNameSelect} getValue={this.handleUpdateToYear.bind(this)} placeholder="Select a year..." data={this.state.yearList} objValue={{value: this.state.toYear}}/>
                    </div>
                </div>               
                <div className="uk-width-1-2">
                    <br></br>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                    {/*<button className="uk-button uk-button-green" onClick={this.handleExitClick.bind(this)}>Exit</button>*/}
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing}/>
                <AlertBox open={this.state.showSaveAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeSaveAlert.bind(this)} messageBoldList={this.state.listMissing}/>
            </form>
        );
    }
}
import { getTraders } from "../../../../networking/NetworkingBlotters";

const { useState, useEffect } = require("react")

const useTraders = () => {
    const [traders, setTraders] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);
    
    useEffect(() => {
        let isCancelled = false;

        const loadParentBlotters = async () => {
            const json = await getTraders();
            if (!isCancelled) {
                if (json.traders !== undefined) {
                    const formattedTraders = json.traders.map(t => ({...t, name: t.Description, value: t.TraderId}));
                    setTraders(formattedTraders);
                } else {
                    setTraders([]);
                }
                setNeedsReloading(false);
            }
        }
        if (needsReloading) {
            loadParentBlotters();
        }

        return () => {
            isCancelled = true;
        }
    }, [needsReloading]);

    return [traders, setNeedsReloading];
}

export default useTraders;
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import AppContext from '../../../AppContext';
import { DealTypesNames } from '../../../constants/DealTypesNames';
import { OutgoingBeneficiaryTypeNames } from '../../../constants/OutgoingBeneficiaryTypeNames';
import { SourceTableNames } from '../../../constants/SourceTableNames';
import { formatDate, formatValidDatePicker, formatValidInput, formatValidSelect } from '../../../helpers/FormatHelper';
import { getAllBeneficiariesByDeal, getBeneficiariesByID } from '../../../networking/NetworkingBeneficiaries';
import { getSuspiciousComments, saveSuspiciousComment } from '../../../networking/NetworkingComments';
import { searchCustomerByIdNameMin } from '../../../networking/NetworkingCustomers';
import { getSuspiciousDeals } from '../../../networking/NetworkingDeals';
import { getIncomingFundsByDeal, getOutgoingFundsByDeal } from '../../../networking/NetworkingFunds';
import { getRiskScoreDetailsForDeals } from '../../../networking/NetworkingRiskScoreRange';
import { getStyle } from '../../../styles/styles';
import DealInformationScreen from '../../deal/DealInformationScreen';
import BeneficiaryMaintenanceScreen from '../../pre_trade/customers/beneficiaries/BeneficiaryMaintenanceScreen';
import CustomerScreen from '../../pre_trade/customers/updateCustomer/CustomerScreen';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import RiskScoreDetailDialog from '../../shared/Dialogs/RiskScoreDetailDialog';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  

var style = getStyle();

export default class SuspiciousDealsSearchScreen extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = { 
            deals       : [],
            customerList: [],
            customerSelected        : 0,
            trade                   : 0,
            riskScore               : 0,
            suspiciousDealsChecked  : false,
            fullcommentsChecked     : false,
            fromDate                : '',
            toDate                  : '',
            tradeDate               : true,
            valueDate               : false,
            noDateFilter            : false,
            includeBankTrade        : false,
            textNoDeals             : '',
            validations:{
                customerSelected: true,
                trade           : true,
                riskScore       : true,
                fromDate        : true,
                toDate          : true,
            },

            validationsComments: {
                comments            : true,
                beneficiarySelected : true,
                listMissing         : [],
            },

            //DealSelected
            idSelected              : 0,
            beneficiarySelected     : 0,

            suspiciousCommentsList  : [],
            beneficiariesByDealList : [],
            commentTextArea         : '',
            isSuspicious            : false,
            fintrac                 : 0,

            //dialog
            showModal               : false,
            fullWidth               : true,
            maxWidth                : 'md',

            //dialog View Deal
            showModalViewDeal       : false,

            //dialog View Customer
            customerID              : 0,
            customerName            : '',
            showModalViewCustomer   : false,

            //dialog View Beneficiary
            beneficiaryID           : 0,
            objBene                 : null,
            showModalViewBeneficiary: false,

            //dialog View RiskScoreDetails
            listRiskScoreDetails    : [],
            showModalViewRiskScoreCalculation: false,

            //pages table
            pages                   : 0,
            totalPages              : 0,
            numberPerPage           : 15,
            newSearch               : false,
            useFilter               : true,

            //Alert
            showAlert               : false,
            alertTitle              : '',
            alertMessage            : '',

            //Alert no beneficiary
            showAlertNoBeneficiary  : false,

            isCheckedARow           : true,
            objChecked              : null,

            //Customer Autocomplete
            CustomerIDName: '',
            CustomerID: ''
        };
      }

    componentDidMount()
    {
        /*
            newSearchCustomers: 
            {
                "totalCount": 47,
                "customers" : [
                    {   "Customer ID"                   : 161,
                        "Customer Name"                 : "Business Advantage SAC - 161",
                        "Contact Name"                  : "Test, Tester12",
                        "Phone"                         : "(051)123-45678 123",
                        "Status"                        : "Authorized to deal",
                        "Trader"                        : "Daniel Carranza",
                        "Customer Type"                 : "Affilliate",
                        "FeeStructureID$"               : 4,
                        "WireFeeSide$"                  : "Buy",
                        "RelatedWireFeeID$"             : 49,
                        "DefaultBuyCurrencyID$"         : "",
                        "DefaultSellCurrencyID$"        : "",
                        "DefaultDepositPercent$"        : 2334,
                        "TraderAllowSameDayVoid$"       : false,
                        "TraderAllowPreviousDayVoid$"   : true,
                        "HasPendingTasks$"              : 1
                    },{},{},{},{}
                ],
                "httpStatusCode":200
            }
        */

        // newSearchCustomers('',0,0).then(
        //     (jsonResponse) => 
        //     {
        //         console.log("newSearchCustomers: "+ JSON.stringify(jsonResponse))
        //         if(jsonResponse.httpStatusCode === 200)
        //         {
        //             if(jsonResponse.customers !== undefined)
        //             {
        //                 var tempCustomers = jsonResponse.customers
        //                 var finalListOfCustomers = []
        //                 tempCustomers.forEach(element => 
        //                 {
        //                     var el = element
        //                     el.value = element['Customer ID']
        //                     el.name = element['Customer Name']

        //                     finalListOfCustomers.push(el)
        //                 });

        //                 finalListOfCustomers.sort(this.dynamicSort("name"));

        //                 this.setState({
        //                     customerList: finalListOfCustomers
        //                 });
        //             }
        //         }
        //     }
        // );

        /*
            this.View.SearchBy = SearchByNames.IssueDate;

            DateTime sysDate = this.SessionService.SystemDate;

            this.View.FromDate = sysDate.AddDays(-7);
            this.View.ToDate = sysDate;

            this.SearchSuspiciousDeals();
        */
    }

    //Autocomplete Customer Name
    searchCustomer(query) {
        if (query === '') {
            this.setState({ customerList: [] });
        } else {
            searchCustomerByIdNameMin(query).then(
                (response) => {
                    if (response.customers !== undefined) {
                        for (var i = 0; i < response.customers.length; i++) {
                            response.customers[i].value = response.customers[i].CustomerID;
                            response.customers[i].name = response.customers[i].CustomerIDName;
                        }
                        this.setState({ customerList: response.customers });
                    }
                }
            )
        }
    }

    handleUpdateCustomerIDName(value) {
        if (value.value !== undefined) {
            this.setState({
                CustomerIDName: value.name !== 'select' ? value.name : '',
                customerSelected: value.value !== 'select' ? value.value : '',
                customerList: []
            }
            );
        }
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) 
        {
            /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
            */
            //////debugger
            var x = a[property] == null ? "":a[property];
            var y = b[property] == null ? "":b[property];

            if(typeof(x) == 'string' && typeof(y) == 'string')
            {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            var result = (x < y) ? -1 : (x > y) ? 1 : 0;
            return result * sortOrder;
        }
    }

    handleGetValueCustomer = (value) =>
    {
        if (value != null && value.value !== undefined) {
            this.setState({
                customerSelected: value.value
            });
        }
    }

    handleGetValueBeneficiary = (value) =>
    {
        console.log(JSON.stringify(this.state))
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                beneficiarySelected: value['Beneficiary Id']
            });
        }
    }

    handleUpdateTrade = (event) => {
        this.setState({trade: event.target.value});
    }

    handleUpdateFintrac = (event) => {
        this.setState({fintrac: event.target.value});
    }

    handleUpdateRiskScore = (event)=>
    {
        this.setState({riskScore: event.target.value});
    }

    handleUpdateSuspiciousDealsChanged = (event) => 
    {
        this.setState({
            suspiciousDealsChecked: event.target.checked
        });
    }

    handleUpdateFullCommentsChanged= (event) =>
    {
        this.setState({
            fullcommentsChecked: event.target.checked
        });
    }

    handleUpdateTradeDate = (event) =>
    {
        this.setState({
            tradeDate: event.target.checked,
            valueDate: !event.target.checked,
        });
    }

    handleUpdateValueDate = (event) =>
    {
        this.setState({
            valueDate: event.target.checked,
            tradeDate: !event.target.checked
        });
    }

    handleUpdateNoDateFilterChanged = (event) =>
    {
        this.setState({
            noDateFilter: event.target.checked
        });
    }

    handleUpdateIsSuspisiousChanged = (event) =>
    {
        this.setState({
            isSuspicious: event.target.checked
        });
    }

    handleUpdateIncludeBankTradeChanged = (event) =>
    {
        this.setState({
            includeBankTrade: event.target.checked
        });
    }

    handleRowClick = (obj) => {
        console.group("deals searched handleRowClick")
        console.log(obj);
        console.groupEnd();
        /*this.setState({
            updateCustomer: true,
            customerID: obj["Customer ID"],
            customerName: obj['Customer Name']
        });*/

        this.viewComments(obj);
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getDeals = async () => {
        this.context.startLoading();
        var searchBy = ''
        if(this.state.tradeDate !== undefined && this.state.valueDate !== undefined)
        {
            searchBy = this.state.tradeDate === true ? 'IssueDate' : 'ValueDate'
        }

        const value = await getSuspiciousDeals(parseInt(this.state.customerSelected), this.state.trade, searchBy, this.state.fromDate, this.state.toDate,
        this.state.riskScore, this.state.suspiciousDealsChecked, this.state.noDateFilter, this.state.includeBankTrade)
        this.context.finishLoading();
        return value.dealHeaders;
    }

    handleSearchClick(event) 
    {
        /*
            GetSuspiciousDeals(
                int customerID, 
                int dealHeaderId, 
                string searchBy, 
                DateTime? dateFrom, 
                DateTime? dateTo, 
                int risk,
                bool isSuspicious, 
                bool noDateFilter, 
                bool includeBanks, 
            out string errorMessage)
        */

        /*  VALIDATIONS
            if (this.View.FromDate.HasValue &&
                this.View.ToDate.HasValue &&
                this.View.FromDate.Value.Date > this.View.ToDate.Value.Date)
            {
                this.NavigationService.NavigateBox(this.MSG_ERROR, "From Date cannot be greater than the To Date.", this.MSG_BUTTON);
                return;
            }
        */

        var searchBy = ''
        if(this.state.tradeDate !== undefined && this.state.valueDate !== undefined)
        {
            searchBy = this.state.tradeDate === true ? 'IssueDate' : 'ValueDate'
        }
        this.context.startLoading();
        getSuspiciousDeals(parseInt(this.state.customerSelected), this.state.trade, searchBy, this.state.fromDate, this.state.toDate,
        this.state.riskScore, this.state.suspiciousDealsChecked, this.state.noDateFilter, this.state.includeBankTrade).then(
            (jsonResponse) => {
                this.context.finishLoading();
                console.log("getSuspiciousDeals: "+ JSON.stringify(jsonResponse))

                if(jsonResponse.httpStatusCode !== undefined && jsonResponse.httpStatusCode === 200)
                {
                    if(jsonResponse.dealHeaders !== undefined)
                    {
                        if(jsonResponse.dealHeaders.length > 0)
                        {   this.setState({
                                deals       : jsonResponse.dealHeaders,
                                textNoDeals : '',
                                pages       : 1,
                                totalPages  : Math.ceil(jsonResponse.dealHeaders.length / this.state.numberPerPage)
                            });
                        }
                    }else
                    {
                        //vacio la busqueda
                        this.setState({
                            deals       : [],
                            pages       : 0,
                            totalPages  : 0,
                            textNoDeals : 'No records found.'
                        });
                    }
                }else
                {
                    //vacio la busqueda
                    this.setState({
                        deals       : [],
                        pages       : 0,
                        totalPages  : 0,
                        textNoDeals : 'No records found.'
                    });
                }

                /*
                    getSuspiciousDeals: {"dealHeaders":[
                    {
                        "DealNo":10881,
                        "DealType":"Swap",
                        "CustomerId":1242,
                        "CustomerName":"Ramirez Grandez, Maria Paz - 1242",
                        "ValueDate":"2022-08-24T00:00:00",
                        "BuyCcy":"USD",
                        "BuyAmount":2000,
                        "Rate":1.34501,
                        "SellCcy":"CAD",
                        "SellAmount":2690.02,
                        "TradeDate":"2020-09-02T16:47:41",
                        "CreatedBy":"Admin, Admin",
                        "BeneficiaryType":0,
                        "BeneficiaryId":0,
                        "BeneficiaryName":"Ramirez Grandez, Maria Paz",
                        "BeneficiaryRisk":null,
                        "FintracRefNumber":null,
                        "RiskScore":11,
                        "DealStatus":"Accepted",
                        "DealUpdatedOn":"2020-09-04T16:47:41.21",
                        "Flag":false,
                        "IndustrySector":"Sector 2",
                        "SuspiciousComments":null
                    }......{},{},{},{},{},{}
                */
            }
        );
    }

    handleClearClick = (event)=>
    {
        this.setState({
            deals                               : [],
            showModal                           : false,
            showModalViewDeal                   : false,
            showModalViewCustomer               : false,
            showModalViewBeneficiary            : false,
            showModalViewRiskScoreCalculation   : false,
            listRiskScoreDetails                : [],
            idSelected                          : 0,
            beneficiarySelected                 : 0,
            suspiciousCommentsList              : [],
            isCheckedARow                       : true,
            customerSelected                    : 0,
            trade                               : 0,
            riskScore                           : 0,
            suspiciousDealsChecked              : false,
            fullcommentsChecked                 : false,
            fromDate                            : '',
            toDate                              : '',
            tradeDate                           : true,
            valueDate                           : false,
            noDateFilter                        : false,
            includeBankTrade                    : false,
            textNoDeals                         : '',
            validations:{
                customerSelected    : true,
                trade               : true,
                riskScore           : true,
                fromDate            : true,
                toDate              : true,
            },
            validationsComments: {
                comments            : true,
                beneficiarySelected : true,
                listMissing         : [],
            },
        });
    }

    handleUpdateFromDate(date){
        this.setState({
            fromDate: formatDate(date)});
    }

    handleUpdateToDate(date){
        this.setState({toDate: formatDate(date)});
    }

    closeModal = () =>
    {
        this.setState({
            showModal: false,
            beneficiariesByDealList : [],
            suspiciousCommentsList  : [],
        })
    }

    closeModalViewDeal = () =>
    {
        this.setState({
            showModalViewDeal: false,
        });
    }

    closeModalViewcustomer = () =>
    {
        this.setState({
            showModalViewCustomer: false,
        });
    }

    closeModalViewBeneficiary = () =>
    {
        this.setState({
            showModalViewBeneficiary: false,
        });
    }

    closeModalViewRiskScoreCalculation = () =>
    {
        this.setState({
            showModalViewRiskScoreCalculation: false,
        });
    }

    updateCommentText = (event) =>
    {
        console.log(JSON.stringify(this.state))
        this.setState({
            validationsComments: {...this.state.validationsComments, comments: true},
            commentTextArea: event.target.value
        });
    }

    handleClick(event){
        event.preventDefault();
        var userName = localStorage.getItem('UserName');

        var listOfBenes = this.state.beneficiariesByDealList
        var objBeneficiarySelected = null
        var sourceID = 0
        var sourceTable = ''

        if(this.validateSuspiciousComments())
        {

            listOfBenes.forEach(element => 
            {
                if(element['Beneficiary Id'] === this.state.beneficiarySelected)
                {
                    objBeneficiarySelected = element
                }
            });

            if(objBeneficiarySelected['Beneficiary Type'] === OutgoingBeneficiaryTypeNames.Customer)
            {
                sourceID = objBeneficiarySelected['Beneficiary Id'];
                sourceTable = SourceTableNames.Customer;
            }
            else
            {
                sourceID = objBeneficiarySelected['Beneficiary Id'];
                sourceTable = SourceTableNames.Beneficiary;
            }

            var model = {
                "dealId"        : this.state.idSelected,
                "customerId"    : sourceID,
                "sourceTable"   : sourceTable,
                "isSuspicious"  : this.state.isSuspicious,
                "content"       : this.state.commentTextArea,
                "commentDate"   : Date.now(),
                "user"          : userName,
                "commentType"   : "Normal"
            }

            saveSuspiciousComment(model).then(
                (jsonSComment) =>{
                    ////debugger
                    console.log("saveSuspiciousComment: " + JSON.stringify(jsonSComment));
                    /*
                    saveSuspiciousComment: {
                        "suspiciousComment":[{
                            "BeneficiaryName":"Tiendecita Blanca",
                            "Source Id":13695,
                            "Source Table":"B",
                            "Content":"test 305am",
                            "DealHeaderId":10858,
                            "Is  Suspicious":false,
                            "Comment Date":"2020-09-10T04:06:12.133",
                            "Comment Id":"C72A5BD9-C493-4144-9EAF-D711C4D03FCB",
                            "User":"Admin"
                        }]}
                    */


                    if(jsonSComment.suspiciousComment !== undefined)
                    {
                        var actualList = this.state.suspiciousCommentsList
                        actualList.unshift(jsonSComment.suspiciousComment[0]);
                        this.setState({
                            suspiciousCommentsList: actualList,
                            showAlert: true, 
                            alertTitle: 'Success',
                            alertMessage: 'Suspicious comment successfully saved.'
                        });
                    }
                    

                    /*
                        saveSuspiciousComment: {
                            "Message":"SqlDateTime overflow. Must be between 1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM.",
                            "ErrorMessage":null,
                            "httpStatusCode":503,
                            "httpErrorMessage":"An error occurred. Please try again."}
                    */
                }
            );
        }
    }

    validateSuspiciousComments() {
        var valid = true
        var beneSelected = true
        var commentTextArea = true

        var listItems = []

        if(this.state.beneficiarySelected === undefined || this.state.beneficiarySelected === 0)
        {
            valid = false;
            beneSelected = false;
            listItems.push("Beneficiary")
        }

        if(this.state.commentTextArea === undefined || this.state.commentTextArea === '')
        {
            valid = false;
            commentTextArea = false;
            listItems.push("Comment")
        }

        /*if (this.state.tradingInformation.TraderID === "select" ||
            this.state.dropdowns.traders.find(e =>
                String.toString(e.TraderID) === String.toString(this.state.tradingInformation.TraderID)) === undefined
        ) {
            valid = false;
            trader = false;
            listItems.push("Trader")
        }

        if (this.state.tradingInformation.DefaultDepositPercent === "select" ||
            this.state.tradingInformation.DefaultDepositPercent === '' ||
            this.state.tradingInformation.DefaultDepositPercent === undefined ||
            this.state.tradingInformation.DefaultDepositPercent < 0) {
            valid = false;
            depositPercentage = false;
            listItems.push("Default Deposit Percent")
        }

        if (this.state.tradingInformation.CreditLimit === undefined ||
            this.state.tradingInformation.CreditLimit === "" ||
            this.state.tradingInformation.CreditLimit < 0) {
            valid = false;
            creditLimit = false;
            listItems.push("Credit Limit")
        }*/

        this.setState({
            validationsComments: {
                ...this.state.validationsComments,
                comments            : commentTextArea,
                beneficiarySelected : beneSelected,
                listMissing         : listItems,
            }
        });

        if (!valid) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    closeAlert() {
        this.setState({
            showAlert: false, alertMessage: '', alertTitle: ''
        });
    }

    closeAlertNoBeneficiary()
    {
        this.setState({
            showAlertNoBeneficiary: false,
        });
    }

    handleSelectClick = (obj) =>{
        console.log("handleSelectClick: " + JSON.stringify(obj))
        this.setState({
            isCheckedARow   : !this.state.isCheckedARow,
            objChecked      : obj,
            idSelected      : obj['Deal No'],
            customerID      : obj['Customer Id'],
            beneficiaryID   : obj['Beneficiary Id'],
        });
    }

    viewComments(obj)
    {
        if(obj === undefined || obj === null)
        {
            obj = this.state.objChecked
        }

        if(obj['Deal No'] !== null)
        {
            var id = obj['Deal No']

            this.setState({
                idSelected : id
            })

            getSuspiciousComments(id).then(
            (jsonResponse) => {
                console.log("getSuspiciousComments: " + JSON.stringify(jsonResponse));
                /*
                    getSuspiciousComments: {
                        "suspiciousComments":[
                            {
                                "BeneficiaryName":"Tiendecita Blanca",
                                "Source Id":13695,
                                "Source Table":"B",
                                "Content":"111111111111111111",
                                "DealHeaderId":10858,
                                "Is  Suspicious":false,
                                "Comment Date":"2020-09-08T11:54:19.927",
                                "Comment Id":"D4582BB5-E1FB-483F-8AAB-6FF0DC1A6AEA",
                                "User":"hugo.bullon"
                            },{
                                "BeneficiaryName":"Tiendecita Blanca",
                                "Source Id":13695,
                                "Source Table":"B",
                                "Content":"test 0000000",
                                "DealHeaderId":10858,
                                "Is  Suspicious":true,
                                "Comment Date":"2020-09-08T11:54:07.3",
                                "Comment Id":"3883BC19-2C3B-4676-A7D7-9E291F114CA8",
                                "User":"hugo.bullon"
                            }
                        ],
                        "httpStatusCode":200}
                */
                if(jsonResponse.httpStatusCode === 200)
                {
                    var listComments = []
                    if(jsonResponse.suspiciousComments !== undefined && jsonResponse.suspiciousComments.length !== 0)
                    {
                        listComments = jsonResponse.suspiciousComments
                    }
                    this.setState({
                        suspiciousCommentsList  : listComments,
                        showModal               : true,
                    });
                }
            });

            //traer beneficiaries
            getAllBeneficiariesByDeal(id).then(
                (jsonResponse) => {
                    console.log("getAllBeneficiariesByDeal: " + JSON.stringify(jsonResponse));
                    /*
                        getAllBeneficiariesByDeal:{
                            "dealBeneficiaries":[
                                {
                                    "Beneficiary Id": 13695,
                                    "Beneficiary Type": 0,
                                    "Beneficiary Name": "Tiendecita Blanca (Pending)",
                                    "Beneficiary Amount": 1200,
                                    "Payment Currency": "CAD"
                                }
                            ],"httpStatusCode":200}
                    */
                    if(jsonResponse.dealBeneficiaries !== undefined && jsonResponse.dealBeneficiaries.length !== 0)
                    {
                        var auxList = []
                        jsonResponse.dealBeneficiaries.forEach(element => {
                            var bene = element
                            bene.value = element['Beneficiary Id']
                            bene.name  = element['Beneficiary Name']
                            auxList.push(bene)
                        });

                        this.setState({
                            beneficiariesByDealList : auxList
                        });
                    }
                }
            );
        }
    }

    clickButtonViewComments(event)
    {   
        event.preventDefault()
        var objDeal = this.state.objChecked
        this.viewComments(objDeal);
    }

    clickButtonViewRiskScoreCalculation(event)
    {
        ////debugger
        event.preventDefault();
        var objDeal = this.state.objChecked;

        let listInco = []
        let listOutg = []

        getIncomingFundsByDeal(objDeal['Deal No'],0,0).then(
            (jsonResponse)=>{
                console.log("getIncomingFundsByDeal: "+ JSON.stringify(jsonResponse));

                if(jsonResponse !== undefined)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            if(jsonResponse.totalCount !== 0)
                            {
                                listInco = jsonResponse.incomingFunds
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }
                }

                getOutgoingFundsByDeal(objDeal['Deal No'],0,0).then(
                    (jsonResponse)=>{
                        console.log("getOutgoingFundsByDeal: "+ JSON.stringify(jsonResponse));
        
                        /*
                            getOutgoingFundsByDeal: 
                            {
                                "totalCount":1,
                                "outgoingFunds":[
                                    {
                                        "Outgoing Funds ID":31665,
                                        "Deal Header ID":10961,
                                        "Beneficiary Bank Account ID":null,
                                        "Beneficiary ID":13703,
                                        "Nostro Account":null,
                                        "Version No":1,
                                        "Payment Instrument":"EFT-Direct Credit",
                                        "Value Date":"2020/09/16",
                                        "Payment Amount":3000,
                                        "Delivery Ref No":null,
                                        "Bene Ref Number":"",
                                        "Payment Reference":"",
                                        "Status":"Pending",
                                        "Beneficiary Type":0,
                                        "Comments":null,
                                        "Created By":"51947BB5-DECD-4B59-911C-0B20E2F07C72",
                                        "Created On":"2020/09/15",
                                        "Updated By":"51947BB5-DECD-4B59-911C-0B20E2F07C72",
                                        "Update On":"2020/09/15",
                                        "Source Table":null,
                                        "Source ID":null,
                                        "Funds Cleared Date":"",
                                        "Funds Cleared Comments":null,
                                        "Funds Cleared Updated By":null,
                                        "Funds Cleared Updated On":"",
                                        "Delivery Method":"Wire Transfer",
                                        "Rejected Funds Cleared Date":"",
                                        "Rejected Funds Cleared Comments":null,
                                        "Rejected Funds Cleared Updated By":null,
                                        "Rejected Funds Cleared Updated On":"",
                                        "NSF Replacement":null,
                                        "Funds Cleared Issue Date":"",
                                        "Funds UnCleared Date":"",
                                        "Rejected Funds Cleared Issue Date":"",
                                        "Rejected Funds UnCleared Date":"",
                                        "Wire Confirmation Sent On":"",
                                        "Rejected Fee Amount":null,
                                        "Approved By":null,
                                        "Approved On":"",
                                        "GL Accounting Date":"",
                                        "Rejected On":"",
                                        "GL Posting Date":"",
                                        "Wire Item Fee":0,
                                        "Wire Item Fee Currency ID":"USD",
                                        "UpdateToken":"637357688683666667"
                                    }
                                ],
                                "httpStatusCode":200
                            }
                        */
        
                        if(jsonResponse !== undefined)
                        {
                           switch (jsonResponse.httpStatusCode) 
                           {
                               case 200:
                               {
                                   if(jsonResponse.totalCount !== 0)
                                   {
                                        listOutg = jsonResponse.outgoingFunds
                                   }
                                   break;
                               }
                               default:
                               {
                                   break;
                               }
                           }
                        }

                        var modelRquest = 
                        {
                            "dealType"      : DealTypesNames[objDeal['Deal Type']],
                            "customerID"    : objDeal['Customer Id'],
                            "buyCurrency"   : objDeal['Buy Currency'],
                            "sellCurrency"  : objDeal['Sell Currency'],
                            "oOutgoingFunds": listOutg,
                            "oIncomingFunds": listInco,
                            dealID          : this.state.idSelected
                        }

                        getRiskScoreDetailsForDeals(modelRquest).then(
                            (jsonResponse)=>{
                                //////debugger
                                console.log("getRiskScoreDetailsForDeals: "+ JSON.stringify(jsonResponse));

                                var tempList = [
                                    {
                                      "Risk Factor": "Customer (Ramirez Grandez, Maria Paz)",
                                      "Risk Score": 19,
                                      "Risk Importance": 50,
                                      "Total": 950,
                                      "Entity ID": 1242,
                                      "Entity Type": "C",
                                      "Order": 1,
                                      "Risk Score Text": "19",
                                      "Score Calculated": 1,
                                      "Parent Risk Score Text": ""
                                    },
                                    {
                                      "Risk Factor": "Beneficiary",
                                      "Risk Score": 0,
                                      "Risk Importance": 50,
                                      "Total": 0,
                                      "Entity ID": null,
                                      "Entity Type": null,
                                      "Order": 2,
                                      "Risk Score Text": "0",
                                      "Score Calculated": 1,
                                      "Parent Risk Score Text": ""
                                    },
                                    {
                                      "Risk Factor": "Currency Pair (CAD - 0, USD - 30)",
                                      "Risk Score": 30,
                                      "Risk Importance": 10,
                                      "Total": 300,
                                      "Entity ID": null,
                                      "Entity Type": null,
                                      "Order": 4,
                                      "Risk Score Text": "30",
                                      "Score Calculated": 1,
                                      "Parent Risk Score Text": ""
                                    }
                                ]

                                this.setState({
                                    showModalViewRiskScoreCalculation   : true,
                                    listRiskScoreDetails                : jsonResponse.riskScoreDetails,
                                    idSelected                          : objDeal['Deal No']
                                    //listRiskScoreDetails : tempList
                                })
                            }
                        );
                    }
                );
            }
        );

        
        
        
        /*  TENGO
            {   
                Beneficiary Id: 13732
                Beneficiary Name: "Ford USD"
                Beneficiary Risk: ""
                Beneficiary Type: 0
                Buy Amount: 2123.55
                Buy Currency: "CAD"
                Created By: "Oshiro, Willy"
                Customer Id: 9679
                Customer Name: "Willy Corp 1000 (WO)  - 9679"
                Deal No: 10949
                Deal Status: "Accepted"
                Deal Type: "Spot"
                Deal Updated On: "2020-09-14T15:22:33.16"
                Fintrac Ref Number: null
                Flag: false
                IndustrySector: null
                Rate: 1.4157
                Risk Score: 20
                Sell Amount: 1500
                Sell Currency: "USD"
                Suspicious Comments: null
                Trade Date: "2020-09-14T15:21:32"
                Value Date: "2020-09-16T00:00:00"
            }
        */
        


        /*   NEED
            {
                "dealType": 1,
                "customerID": 2,
                "buyCurrency": "sample string 3",
                "sellCurrency": "sample string 4",
                "oOutgoingFunds": [
                    {
                        "OutgoingFundsID": 1,
                        "DealHeaderID": 1,
                        "BeneficiaryBankAccountID": 1,
                        "BeneficiaryID": 1,
                        "NostroAccount": 1,
                        "VersionNo": 2,
                        "PaymentInstrument": "sample string 3",
                        "ValueDate": "2020-09-14T16:08:43.1116482-04:00",
                        "PaymentAmount": 4.0,
                        "DeliveryRefNo": "sample string 5",
                        "BeneRefNumber": "sample string 6",
                        "PaymentReference": "sample string 7",
                        "Status": "sample string 8",
                        "BeneficiaryType": 9,
                        "Comments": "sample string 10",
                        "CreatedBy": "732a6b08-e1b9-42bd-b1f5-8fee31cb99a1",
                        "CreatedOn": "2020-09-14T16:08:43.1116482-04:00",
                        "UpdatedBy": "b6a1f42f-d92b-42ea-91aa-e30266d5be95",
                        "UpdateOn": "2020-09-14T16:08:43.1116482-04:00",
                        "SourceTable": "A",
                        "SourceID": 1,
                        "FundsClearedDate": "2020-09-14T16:08:43.1116482-04:00",
                        "FundsClearedComments": "sample string 15",
                        "FundsClearedUpdatedBy": "d21bd934-6e73-4aa7-a891-06cb6bbd0ebe",
                        "FundsClearedUpdatedOn": "2020-09-14T16:08:43.1116482-04:00",
                        "DeliveryMethod": "sample string 16",
                        "RejectedFundsClearedDate": "2020-09-14T16:08:43.1116482-04:00",
                        "RejectedFundsClearedComments": "sample string 17",
                        "RejectedFundsClearedUpdatedBy": "19669c73-3ce8-4a7d-a0df-d5068d291639",
                        "RejectedFundsClearedUpdatedOn": "2020-09-14T16:08:43.1116482-04:00",
                        "NSFReplacement": true,
                        "FundsClearedIssueDate": "2020-09-14T16:08:43.1116482-04:00",
                        "FundsUnClearedDate": "2020-09-14T16:08:43.1116482-04:00",
                        "RejectedFundsClearedIssueDate": "2020-09-14T16:08:43.1126989-04:00",
                        "RejectedFundsUnClearedDate": "2020-09-14T16:08:43.1126989-04:00",
                        "WireConfirmationSentOn": "2020-09-14T16:08:43.1126989-04:00",
                        "RejectedFeeAmount": 1.0,
                        "ApprovedBy": "8ff45a2f-1f03-4367-b2a8-936a6663c3c4",
                        "ApprovedOn": "2020-09-14T16:08:43.1126989-04:00",
                        "GLAccountingDate": "2020-09-14T16:08:43.1126989-04:00",
                        "RejectedOn": "2020-09-14T16:08:43.1126989-04:00",
                        "GLPostingDate": "2020-09-14T16:08:43.1126989-04:00",
                        "WireItemFee": 1.0,
                        "WireItemFeeCurrencyID": "sample string 18",
                        "DealUpdatedOn": "2020-09-14T16:08:43.1126989-04:00",
                        "CountryStatus": "sample string 19",
                        "CountryID": "sample string 20",
                        "DealPaidStatus": "sample string 21",
                        "OutgoingWasCompleted": true,
                        "ForceSaveToInterface": "sample string 23",
                        "UpdateToken": "sample string 24",
                        "EntityPropertiesJson": "sample string 25"
                    },{},{}
                ],
                "oIncomingFunds": [
                    {
                        "IncomingFundsID": 1,
                        "DealHeaderID": 1,
                        "DepositID": 1,
                        "BankAccountID": 1,
                        "NostroAccount": 1,
                        "VersionNo": 2,
                        "ExpectedDate": "2020-09-14T16:08:43.1126989-04:00",
                        "ActualDate": "2020-09-14T16:08:43.1126989-04:00",
                        "Amount": 3.0,
                        "SettlementInstrument": "sample string 4",
                        "SettlementDeliveryMethod": "sample string 5",
                        "Status": "sample string 6",
                        "CreatedBy": "8977b8d3-1ec2-4f43-aec7-d6a22905f84d",
                        "CreateOn": "2020-09-14T16:08:43.1126989-04:00",
                        "UpdatedBy": "b9cdb8b7-0e25-43ca-b085-97ef99ad5578",
                        "UpdatedOn": "2020-09-14T16:08:43.1126989-04:00",
                        "SourceTable": "A",
                        "SourceID": 1,
                        "FromTrade": true,
                        "NSFReplacement": true,
                        "BalanceAdjustment": true,
                        "BalanceAdjustmentType": "sample string 11",
                        "OldFundOnHoldBalance": 1.0,
                        "NewFundOnHoldBalance": 1.0,
                        "BalanceAdjustedAmount": 12.0,
                        "BalanceAdjustedIncomingFundID": 1,
                        "DealUpdatedOn": "2020-09-14T16:08:43.1136386-04:00",
                        "UpdatedOnSource": "2020-09-14T16:08:43.1136386-04:00",
                        "ThirdPartyBeneficiaryPayment": true,
                        "UsingFundsOnHold": true,
                        "IncomingBalanceAdjustment": 1,
                        "CustomerID": 13,
                        "CurrencyID": "sample string 14",
                        "BeneficiaryID": 15,
                        "RecordNo": "sample string 16",
                        "DateReceived": "2020-09-14T16:08:43.1136386-04:00",
                        "AmtUsed": 17.0,
                        "PaymentStatus": "sample string 18",
                        "IncomingStatus": "sample string 19",
                        "Audit": "sample string 20",
                        "FundsClearedDate": "2020-09-14T16:08:43.1136386-04:00",
                        "FundsClearedBy": "sample string 21",
                        "PaymentID": 1
                    },{},{}
                ]
            }
        */
    }

    clickButtonViewDeal(event)
    {   
        this.setState({
            showModalViewDeal : true,
        });
    }

    clickButtonViewCustomer(event)
    {
        this.setState({
            showModalViewCustomer : true,
        });
    }

    clickButtonViewBeneficiary(event)
    {
        if(this.state.beneficiaryID !== 0)
        {
            getBeneficiariesByID(this.state.beneficiaryID, 1).then(
                (json) => {
                    console.log(json.beneficiary);
                    /*
                        Address: [{…}]
                        BankAccountsInfo: [{…}]
                        BeneficiaryId: 13732
                        BeneficiaryRiskScore: 13
                        BeneficiaryType: "Corporate"
                        BypassValidation: false
                        ContactEmail: "aa@aa.com"
                        ContactName: "Ford contact"
                        ContactPhone: "+1 854 865 78712"
                        CustomerId: 9679
                        CustomerIndustrySector: null
                        CustomerRelationRisk: 1
                        DealStatus: null
                        DetailsOfPayment: ""
                        EmailIndicator: "No email"
                        ExpiryByPassDate: null
                        FirstName: ""
                        IndustrySector: ""
                        LastName: ""
                        Name: "Ford USD"
                        OnlineAccess: true
                        PaymentFlow: "Pays to"
                        PersonId: "3F875135-021E-4462-AA20-EF4A470C0C31"
                        PreferredDeliveryMethod: "Wire Transfer"
                        PreferredMethodOfPayment: "Electronic Funds Transfer"
                        ReceiveLimit: 0
                        ReferencesOfPayment: ""
                        Salutation: ""
                        ShortName: "Ford USD"
                        Status: "Authorized"
                        TaskList: (3) [{…}, {…}, {…}]
                        UpdateToken: "637349093684900000"
                    */
                    this.setState({ 
                        objBene: json.beneficiary[0],
                        showModalViewBeneficiary : true,
                    });
                }
            );
        }else
        {
            this.setState({
                showAlertNoBeneficiary:true
            })
        }
    }

    returnToUnpaidDeal = () => {
        this.setState({
            idSelected          : 0,
            showModalViewDeal   : false,
        });
    }

    render(){

        return(
            <div style={{paddingTop: 20}}>
                <h3 className="uk-heading-divider" 
                    style={style.titleStyle}
                >Suspicious Deal Search: Search Deals by Risk Score
                </h3>
                <div>
                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Customer</label>
                            <div className="" style={style.inputStyle}>
                                {/* <DynamicSelect 
                                    className           = {formatValidSelect(this.state.validations.customerSelected)} 
                                    objValue            = {{ value: this.state.customerSelected }}
                                    getValue            = {this.handleGetValueCustomer} 
                                    listHasPlaceholder  = {false} 
                                    data                = {this.state.customerList} 
                                    id                  = "select-sds-customer"
                                    placeholder         = {"Select a Customer..."}
                                /> */}
                                <DynamicSearchSelect
                                    id="CustomerIDName"
                                    placeholder="Customer"
                                    searchFunction={this.searchCustomer.bind(this)}
                                    getValue={this.handleUpdateCustomerIDName.bind(this)}
                                    data={this.state.customerList}
                                    objValue={{ value: this.state.customerSelected, name: this.state.CustomerIDName }}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Trade #</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput 
                                    className={formatValidInput(this.state.validations.trade)}
                                    placeholder="0" 
                                    id="tradeno" 
                                    type="Integer" 
                                    value={this.state.trade} 
                                    onChange={this.handleUpdateTrade.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Risk Score {">="}</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput 
                                    className={formatValidInput(this.state.validations.riskScore)}
                                    placeholder="0" 
                                    id="tradeno" 
                                    type="Integer" 
                                    value={this.state.riskScore} 
                                    onChange={this.handleUpdateRiskScore.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel],{marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox" 
                                        value={this.state.suspiciousDealsChecked} 
                                        checked={this.state.suspiciousDealsChecked} 
                                        onChange={this.handleUpdateSuspiciousDealsChanged} 
                                        type="checkbox"
                                    />Suspicious Deals
                                </label>
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel], {marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox" 
                                        value={this.state.fullcommentsChecked} 
                                        checked={this.state.fullcommentsChecked} 
                                        onChange={this.handleUpdateFullCommentsChanged} 
                                        type="checkbox"
                                    />Full Comments
                                </label>
                            </div>
                        </div>
                    </div>
                
                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >From</label>
                            <div className="" style={style.inputStyle}>
                                <DatePicker 
                                    readOnly={true} 
                                    className={formatValidDatePicker(this.state.validations.fromDate)} 
                                    value={this.state.fromDate} 
                                    onDayChange={this.handleUpdateFromDate.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >To</label>
                            <div className="" style={style.inputStyle}>
                                <DatePicker 
                                    readOnly={true} 
                                    className={formatValidDatePicker(this.state.validations.toDate)} 
                                    value={this.state.toDate} 
                                    onDayChange={this.handleUpdateToDate.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel],{marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox" 
                                        value={this.state.tradeDate} 
                                        checked={this.state.tradeDate} 
                                        onChange={this.handleUpdateTradeDate} 
                                        type="checkbox"
                                    />Trade Date
                                </label>
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel], {marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox" 
                                        value={this.state.valueDate} 
                                        checked={this.state.valueDate} 
                                        onChange={this.handleUpdateValueDate} 
                                        type="checkbox"
                                    />Value Date
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel],{marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox" 
                                        value={this.state.noDateFilter} 
                                        checked={this.state.noDateFilter} 
                                        onChange={this.handleUpdateNoDateFilterChanged} 
                                        type="checkbox"
                                    />No Date Filter
                                </label>
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked" style={{alignSelf: 'center'}}>
                            <label className="uk-form-label" style={[style.boldLabel], {marginTop: 18}}></label>
                            <div className="" style={style.inputStyle}>
                                <label>
                                    <input 
                                        className="uk-checkbox"
                                        value={this.state.includeBankTrade}
                                        checked={this.state.includeBankTrade}
                                        onChange={this.handleUpdateIncludeBankTradeChanged}
                                        type="checkbox"
                                    />Include Bank Trade
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-width-1-2">
                    <br></br>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick}>Clear Fields</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSearchClick.bind(this)}>Search</button>
                </div>

                {/*
                    <NewDynamicTable 
                        hiddenHeaders       = {['Key','Action']} 
                        checkboxFunction    = {this.handleSelectClick} 
                        useCheckBox         = {true} 
                        data                = {this.state.result}  
                        enableClick         = {true} 
                        clickFunction       = {this.handleRowClick} 
                        newSearch           = {this.state.newSearch}
                    />
                */}

                <div className="uk-grid">
                    <div class="uk-width-5-5">
                        <button class="uk-button uk-button-green  uk-float-right" type="button" disabled={this.state.isCheckedARow} onClick={this.clickButtonViewCustomer.bind(this)}>View Customer</button>
                        <button class="uk-button uk-button-green  uk-float-right" type="button" disabled={this.state.isCheckedARow} onClick={this.clickButtonViewBeneficiary.bind(this)}>View Beneficiary</button>
                        <button class="uk-button uk-button-green  uk-float-right" type="button" disabled={this.state.isCheckedARow} onClick={this.clickButtonViewDeal.bind(this)}>View Deal</button>
                        <button class="uk-button uk-button-green  uk-float-right" type="button" disabled={this.state.isCheckedARow} onClick={this.clickButtonViewComments.bind(this)}>View Comments</button>
                        <button class="uk-button uk-button-green  uk-float-right" type="button" disabled={this.state.isCheckedARow} onClick={this.clickButtonViewRiskScoreCalculation.bind(this)}>View Risk Score Calc</button>
                    </div>

                    {this.state.deals.length > 0 && <div class="uk-width-5-5">
                        <br/>
                        <NewDynamicTable
                        data                = {this.state.deals}
                        enableClick         = {true}
                        clickFunction       = {this.handleRowClick}
                        newSearch           = {this.state.newSearch}
                        useDeleteButton     = {false}
                        usePaginator        = {true}
                        changePage          = {this.changePage}
                        numberOfPages       = {this.state.totalPages}
                        pageNumber          = {this.state.pages}
                        numberPerPage       = {this.state.numberPerPage}
                        enableSortByHeader  = {true}
                        useFilter           = {this.state.useFilter}
                        filterFunction      = {this.getDeals}
                        //useExportButton     = {this.state.useFilter}
                        tableName           = "deals"
                        useCheckBox         = {true}
                        checkboxFunction    = {this.handleSelectClick}
                    /></div>}
                    {this.state.deals.length === 0 && 
                        <div className="uk-width-1-1" style={{paddingTop: 50}}>
                            <h5 style={style.alignCenter}>{this.state.textNoDeals}</h5>
                        </div>
                    }
                </div>
                {/*Register Comments*/}
                <Dialog 
                    open        = {this.state.showModal} 
                    onClose     = {this.closeModal}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {this.state.maxWidth}
                    //aria-labelledby="customized-dialog-title"
                >
                    <DialogTitle id="customized-dialog-sc-title" onClose={this.closeModal}>
                        Register Comments
                    </DialogTitle>
                    <DialogContent dividers>
                    <DialogContentText>
                        Comments Registration
                    </DialogContentText>
                    <div className="uk-margin">
                        <DynamicSelect 
                            className           = {formatValidSelect(this.state.validationsComments.beneficiarySelected)} 
                            objValue            = {{value: 'value'}} 
                            getValue            = {this.handleGetValueBeneficiary} 
                            listHasPlaceholder  = {false} 
                            data                = {this.state.beneficiariesByDealList} 
                            id                  = "select-cr-beneficiary"
                            placeholder         = "Select a Beneficiary..."
                        />

                        <div className = "uk-flex" style={{paddingTop: 15}}>
                            <div className="uk-width-1-4" style={{alignSelf: 'center'}}>
                                <label className="uk-form-label" style={[style.boldLabel],{marginTop: 18}}></label>
                                <div className="" style={style.inputStyle}>
                                    <label>
                                        <input 
                                            className="uk-checkbox" 
                                            value={this.state.isSuspicious} 
                                            checked={this.state.isSuspicious} 
                                            onChange={this.handleUpdateIsSuspisiousChanged} 
                                            type="checkbox"
                                        />Is Suspicious
                                    </label>
                                </div>
                            </div>
                            {this.state.isSuspicious === true && <div className="uk-width-2-4">
                                <label className="uk-form-label" style={style.boldLabel}>Fintrac Ref. Number</label>
                                <div className="" style={style.inputStyle}>
                                    <NumberInput 
                                        className='uk-input'
                                        placeholder="0" 
                                        id="fintrac" 
                                        type="Integer" 
                                        value={this.state.fintrac} 
                                        onChange={this.handleUpdateFintrac.bind(this)}
                                    />
                                </div>
                            </div>}
                        </div>

                        <div className="uk-flex" style={{paddingTop: 15}}>
                            <div className="uk-width-2-3">
                                <textarea 
                                    className={`uk-width-1-1 ${formatValidInput(this.state.validationsComments.comments)}`}
                                    style={{fontSize:16, height: 70}}
                                    value={this.state.commentTextArea}
                                    onChange={this.updateCommentText}
                                >{this.state.commentTextArea}</textarea>
                            </div>
                            <div 
                                className="uk-width-1-3"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <button 
                                    className="uk-button uk-button-green" 
                                    onClick={this.handleClick.bind(this)}>Add</button>
                            </div>
                        </div>
                    </div>
                    <DialogContentText>
                        Suspicious Comments
                    </DialogContentText>
                    <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                        <NewDynamicTable
                            id              = "sc-table"
                            data            = {this.state.suspiciousCommentsList} 
                            hiddenHeaders   = {['Source Id', 'Source Table', 'DealHeaderId', 'Comment Id']}
                        />
                    </div>
                    </DialogContent>
                </Dialog>

                {/*View Deal*/}
                <Dialog 
                    open        = {this.state.showModalViewDeal} 
                    onClose     = {this.closeModalViewDeal}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {'xl'}
                >
                    <DialogContent dividers>
                        <DealInformationScreen 
                            backLink    = {this.returnToUnpaidDeal} 
                            data        = {this.state.idSelected}
                            readOnly    = {true}
                        />
                    </DialogContent>
                </Dialog>

                {/*View customer*/}
                <Dialog 
                    open        = {this.state.showModalViewCustomer} 
                    onClose     = {this.closeModalViewcustomer}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {'xl'}
                >
                    <DialogContent dividers>
                        <CustomerScreen 
                            backLink     = {this.closeModalViewcustomer} 
                            customerID   = {this.state.customerID} 
                            //customerName ={this.state.customerName}
                            readOnly     = {true}
                        />
                    </DialogContent>
                </Dialog>

                {/*View Beneficiary*/}
                <Dialog 
                    open        = {this.state.showModalViewBeneficiary} 
                    onClose     = {this.closeModalViewBeneficiary}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {'xl'}
                >
                    <DialogContent dividers>
                        <BeneficiaryMaintenanceScreen 
                            beneID          = {this.state.beneficiaryID} 
                            //data = {this.props.customerName}
                            selectedBene    = {this.state.objBene}
                            type            = {this.state.beneficiarySelected.BeneficiaryType}
                            customerID      = {this.state.customerID} 
                            customerName    = {this.state.customerName}
                            backLink        = {this.closeModalViewBeneficiary} 
                            updateFunctionOrigin = {this.closeModalViewBeneficiary}
                            readOnly        = {true}
                        />
                    </DialogContent>
                </Dialog>

                {/*View Risk Score Calculation*/}
                <RiskScoreDetailDialog
                    entityType                   = {'Trader'}
                    dealId                       = {this.state.idSelected}
                    riskScoreDetails             = {this.state.listRiskScoreDetails}
                    open                         = {this.state.showModalViewRiskScoreCalculation}
                    onCloseRiskScoreDetailDialog = {this.closeModalViewRiskScoreCalculation}
                ></RiskScoreDetailDialog>


                <AlertBox
                    open    = {this.state.showAlert}
                    onClose = {this.closeAlert.bind(this)}
                    title   = {this.state.alertTitle}
                    message = {this.state.alertMessage}
                    type    = "Ok"
                    okClick = {this.closeAlert.bind(this)}
                    messageBoldList={this.state.validationsComments.listMissing}
                />

                <AlertBox
                    open    = {this.state.showAlertNoBeneficiary}
                    onClose = {this.closeAlertNoBeneficiary.bind(this)}
                    title   = {'Warning'}
                    message = {'There is no beneficiary for this deal as the funds out have been sent to the Customer. '}
                    type    = "Ok"
                    okClick = {this.closeAlertNoBeneficiary.bind(this)}
                />
            </div>
        );
    }
}
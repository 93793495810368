import React from 'react';
import { getStyle } from '../../../../styles/styles';
import AuditEntity from './AuditEntity';
var style = getStyle();
export default class AuditBankAccountScreen extends React.Component {

    handleBackLink() {
        this.props.backLink();
    }

    render() {
        return (
            <div>
                <AuditEntity entityID={this.props.bankAccountID} categoryName={"Audit - Bank Accounts"} />
            </div>
        );
    }
}

import React from 'react';
import * as mime from 'mime-types';

import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import NewDynamicTable from '../../shared/NewDynamicTable';
import {getEntityProperties} from '../../../networking/NetworkingEntityProperties';
import {getEntityTasks} from '../../../networking/NetworkingEntityTasks';
import {getAttachments, getAttachment} from '../../../networking/NetworkingAttachments';
import AlertBox from '../AlertBox';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const clone = (obj) => Object.assign({}, obj);

const renameKey = (object, key, newKey) => 
{
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;

    return clonedObj;
};

export default class AttachmentsDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            loading             :false,
            entityProperties    : [],
            tasks               : [],
            listAttachments     : [],
            fullWidth           : true,
            maxWidth            : 'md',
            
            currentFileURLList  : [],
            currentFileType     : '',
            currentExtension    : '',


            //Dialog Attachment
            fullWidthAttachment : true,
            maxWidthAttachment  : 'lg',
            openAttachment      : false,

            //Alert no preview
            showOpenAlert       : false,

            //Dowload file
            currentBlob         : null,
            isNewId             : false,
        };
    }

    componentDidMount()
    {
        //////debugger
        if(this.props.entityID !== undefined && this.props.entityID !== 0 && this.props.entityID !== "")
        {
            this.setState({
                isNewId: false,
                loading: true,
            },()=>{
                this.getEntityProperties();
                this.getTasks();
            })
        }else{
            this.setState({
                isNewId         : true,
                listAttachments : [],
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        //////debugger
        if(this.props.entityID !== undefined)
        {
            if((this.props.entityID !== prevProps.entityID) && (this.props.entityID === "" || this.props.entityID === 0))
            {
                this.setState({
                    isNewId         : true,
                    listAttachments : [],
                });
            }else
            {
                if(this.props.entityID !== prevProps.entityID)
                {
                    this.setState({
                        isNewId: false,
                        loading: true,
                    },()=>{
                        this.getEntityProperties();
                        this.getTasks();
                    })
                }
            }
        }
    }

    getEntityProperties()
    {
        getEntityProperties(this.props.entityID, this.props.entityType).then(
            (value) =>
            {
                //console.group('EntityProperties in attachments ' + value.entityProperties);

                /*
                    Attachments: 1
                    CreatedBy: "EA5D833E-5229-42F5-B8AD-81F89685F23C"
                    CreatedOn: "2018/04/16"
                    DateValue: null
                    Description: "Trading Plaftform"
                    DisplayOrder: 0
                    EntityPropertyID: "7AE4EA1C-02C1-45AD-8A8C-D4AA28870023"
                    Group By: "FXCM"
                    LookUpTableID: null
                    Mandatory: null
                    NumValue: null
                    ReadOnly: false
                    Request Customer: 0
                    TextValue: "Test 123"
                    Type: "Text"
                    UpdateToken: "637249644051633333"
                    UpdatedBy: "5C92BC61-89D6-47B9-8C47-924A98C25D6B"
                    UpdatedOn: "2020/05/13"
                    UserDefinedPropertyID: 1102
                */

                var allEP = value.entityProperties
                var finalEP = []
                var attachments = this.state.listAttachments

                if(allEP !== undefined)
                {
                    if(Array.isArray(allEP))
                    {
                        allEP.forEach(element =>
                        {
                            if(element.Attachments !== 0)
                            {
                                finalEP.push(element)
                            }
                        });
                    }
                }

                if(finalEP !== [])
                {
                    finalEP.forEach(element => 
                    {
                        getAttachments('Property', element.EntityPropertyID).then((json) => 
                        {
                            /*
                                Description: "CIX"
                                Filename: "Upload File"
                                ID: "9A02F789-63CE-4D92-97C8-CA6E6DF3980B"
                                UpdateToken: "637317851118466667"
                                UploadedOn: "2020/07/31"
                            */

                            if (json.attachments !== undefined)
                            {
                                //console.group('Attachments');
                                //console.table(json.attachments);

                                json.attachments.forEach(attachment => 
                                {
                                    attachment['Type'] = 'Property'
                                    attachment = renameKey(attachment, 'Description', 'File Description');
                                    attachment['Description'] = element.Description
                                    attachment['Expiry Date'] = element.DeadlineDate
                                    attachment = this.reorderObjAttachment(attachment)
                                    attachments.push(attachment)
                                });
                            }
                        });
                    });
                }
                
                this.setState({
                    entityProperties    : finalEP,
                    listAttachments     : attachments,
                    //loading             : false,
                });
            }
        );
    }

    getTasks()
    {
        this.setState({
            loading: true,
        },()=>
            getEntityTasks(this.props.entityID, this.props.entityType).then((value) =>
            {
                //console.group('Tasks in attachments ' + value.taskDefs);

                /*
                    Completed: true
                    CompletedBy: "Admin, Admin"
                    CompletedOn: "2020/07/07"
                    DeadlineDate: "2020/05/29"
                    ExpiryDate: ""
                    GroupBy: "Without group"
                    HasAttachment: "True"
                    LinkID: "5708B493-BA17-4F6A-A2D8-8FDA6509066B"
                    NoOfAttachments: 2
                    Request Customer: ""
                    Status: "Outstanding"
                    Title: "Contacts verified via party"
                    UpdateToken: "636596606100000000"
                    taskdefid: "1627B8F1-833F-4466-AE98-FD7DE726162C"
                */

                var allTasks = value.taskDefs
                var finalTasks = []

                var attachments = this.state.listAttachments

                if(allTasks !== undefined)
                {
                    if(Array.isArray(allTasks))
                    {
                        allTasks.forEach(element => 
                        {
                            if(element.HasAttachment === 'True')
                            {
                                finalTasks.push(element)
                            }
                        });
                    }
                }

                if(finalTasks.length !== 0)
                {
                    finalTasks.forEach(element => 
                    {
                        getAttachments('Task', element.LinkID).then((json) => 
                        {
                            //console.log("jsonTask getAttachments: " + JSON.stringify(json))

                            /*
                                Description: "123123"
                                Filename: "Upload File"
                                ID: "887CE3F4-CF2D-41BE-8272-4334498FFAAA"
                                UpdateToken: "637317848200366667"
                                UploadedOn: "2020/07/31"
                            */

                        if (json.attachments !== undefined)
                        {
                            //console.group('Attachments');
                            //console.table(json.attachments);

                            json.attachments.forEach(attachment => 
                            {
                                    attachment['Type'] = 'Task'
                                    attachment = renameKey(attachment, 'Description', 'File Description');
                                    attachment['Description'] = element.Title
                                    attachment['Expiry Date'] = element.DeadlineDate
                                    attachment = this.reorderObjAttachment(attachment)
                                    attachments.push(attachment)
                            });

                            this.setState({
                                tasks           : finalTasks,
                                listAttachments : attachments,
                                loading         : false,
                            });
                        }else{
                            this.setState({
                                loading         : false,
                            });
                        }
                        }, ()=>
                            this.setState({
                                loading         : false,
                            })
                        );
                    });
                }else
                {
                    this.setState({
                        loading         : false,
                    });
                }
            })
        )
    }

    reorderObjAttachment = (objAttachment) =>
    {
        var newdObj = clone(objAttachment)
        var newAttachment =
        {
            Type                : newdObj.Type,
            Description         : newdObj.Description,
            Filename            : newdObj.Filename,
            'File Description'  : newdObj['File Description'],
            'Expiry Date'       : newdObj['Expiry Date'],
            ID                  : newdObj.ID
        }

        return newAttachment
    }

    onAttachmentRowClick = (attachmentClicked) =>
    {
        //console.log("attachmentClicked: " + JSON.stringify(attachmentClicked))

        getAttachment(attachmentClicked.ID).then((jsonResponse)=>
        {
            //console.log(jsonResponse);
            var byteCharacters = atob(jsonResponse.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) 
            {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var bytes = new Uint8Array(byteNumbers);
            var blob = new Blob([bytes],{type: mime.lookup(attachmentClicked.Filename)});

            //var url = window.URL.createObjectURL(blob)

            var arrayName = attachmentClicked.Filename.split(".")

            let fileList = [{
                uri     : window.URL.createObjectURL(blob),
                fileType: arrayName[arrayName.length-1].toLowerCase(),
                fileName: attachmentClicked.Filename
            }]

            if(arrayName.length !== 0)
            {
                if( arrayName[arrayName.length-1] === 'xlsx' || arrayName[arrayName.length-1] === 'xls' ||
                    arrayName[arrayName.length-1] === 'docx' || arrayName[arrayName.length-1] === 'doc' ||
                    arrayName[arrayName.length-1] === 'docm')
                {
                    this.setState({
                        showOpenAlert   : true,
                        currentBlob     : blob,
                        currentFileName : attachmentClicked.Filename,
                        currentExtension: arrayName[arrayName.length-1],
                    });
                }else
                {
                    this.setState({
                        currentFileName     : attachmentClicked.Filename,
                        currentFileURLList  : fileList,
                        openAttachment      : true,
                    })
                }
            }
        });
    }

    onCloseAttachment = () =>
    {
        this.setState({
            currentFileName  : '',
            currentFileURL   : [],
            currentFileType  : '',
            openAttachment   : false,
        })
    }

    closeAlert = () =>
    {
        this.setState({
            showOpenAlert   : false,
        })
    }

    yesDowloadFile = () =>
    {

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.state.currentBlob);
        link.download = this.state.currentFileName;
        link.click();
    }

    render() 
    {
        /*if(this.state.loading)
        {
            return(
                <div
                    style={{
                        width   :'100%',
                        height  :'100%',
                        backgroundColor: 'rgba(132, 172, 144,0.5)',
                    }}
                    className = "uk-position-center" 
                >
                     <div
                        className   = "uk-position-center" 
                        uk-spinner  = "ratio: 3"
                    />
                </div>
            )
        }*/

        return (
            <div>
                <Dialog 
                    open={this.props.open} 
                    onClose={this.props.onClose}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="attachments-dialog-title" onClose={this.props.onClose}>
                        Attachments
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                            {this.state.loading === true && 
                                <h4 style={{
                                    textAlign: 'center'
                                }}>Loading...</h4>
                            }
                            {this.state.listAttachments.length === 0 && 
                             this.state.isNewId === false && 
                             this.state.loading === false && <div>
                                <h4 style={{
                                    textAlign: 'center'
                                }}>No attachments found</h4>
                            </div>}
                            {this.state.loading === false && <NewDynamicTable
                                id="Attachments-table"
                                //data={this.props.data} 
                                data = {this.state.listAttachments}
                                enableClick   = {true} 
                                clickFunction = {this.onAttachmentRowClick}
                                hiddenHeaders={['ID']} 
                            />}

                            {this.state.isNewId === true && <div>
                                <h4 style={{
                                    textAlign: 'center'
                                }}>Must create {this.props.entityType} first.
                                </h4>
                            </div>}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open        = {this.state.openAttachment} 
                    onClose     = {this.onCloseAttachment}
                    fullWidth   = {this.state.fullWidthAttachment}
                    maxWidth    = {this.state.maxWidthAttachment}
                >
                    <DialogTitle id="current-attachment-dialog-title" onClose={this.onCloseAttachment}>
                        {this.state.currentFileName}
                    </DialogTitle>
                    <DialogContent dividers>      
                        <div>
                            {/*<iframe 
                                src         = {this.state.currentFileURL}
                                scrolling   = 'yes'
                                style       = {{
                                    border      : 'none',
                                    width       : '100%',
                                    height      : '800px',
                                    textAlign   : 'center',
                                }}
                            >
                            </iframe>*/}
                            <DocViewer
                                pluginRenderers = {DocViewerRenderers} 
                                documents       = {this.state.currentFileURLList}
                                config          = {{
                                    header: {
                                        disableHeader   : true,
                                        disableFileName : true,
                                        retainURLParams : false
                                    }
                                }} 
                            />
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox 
                    id="open-attachment" 
                    open={this.state.showOpenAlert} 
                    onClose={this.closeAlert.bind(this)} 
                    title="Warning" 
                    message="The file has no preview. Do you want to download the file?" 
                    type="Yes/No" 
                    yesClick={this.yesDowloadFile.bind(this)} 
                    noClick={this.closeAlert.bind(this)}
                />
            </div>
        )
    }
}
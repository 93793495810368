import {genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters} from './Networking';

export async function getTradingAndCurrencyHolidays(dateFrom, dateTo){
    try {
        let params = {dateFrom: dateFrom,
            dateTo: dateTo,
        };
        let response = await genericGetWithParameters('Holiday/GetTradingAndCurrencyHolidays',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNextBusinessDate(currentDate, extraDays){
    try {
        let params = {CurrentDate: currentDate,
            ExtraDays: extraDays,
        };
        let response = await genericGetWithParameters('Holiday/GetNextBusinessDate',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getTradingHolidays(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        };
        let response = await genericGetWithParameters('Holiday/GetTradingHolidays',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getValueDateHolidays(currencyID, year,page, items){
    try {
        let params = {
            'currencyID': currencyID,
            'year': year,
            'Page': page,
            'Items': items
        };
        let response = await genericGetWithParameters('Holiday/GetValueDateHolidays',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function IsBusinessDate(sellCurrencyID, buyCurrencyID, date){
    try {
        let params = {
            sellCurrencyID: sellCurrencyID,
            buyCurrencyID: buyCurrencyID,
            date: date
        };
        let response = await genericGetWithParameters('Holiday/GetIsBusinessDate',params);
        return response;
    } catch (error) {
        console.error("--------------------- ERROR NETWORKING ---------------------");
        console.error(error);
    }
}

export async function saveTradingHolidays(model){

    try{
        let response = await genericCallWithBody('POST','Holiday/SaveTradingHolidays',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteTradingHolidays(date) {
    try{
        let params = {
            'date': date
        };
        let response = await genericDeleteWithParameters('Holiday/DeleteTradingHolidays',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveValueDateHolidays(model){

    try{
        let response = await genericCallWithBody('POST','Holiday/SaveValueDateHolidays',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteValueDateHolidays(currencyHolidayID) {
    try{
        let params = {
            'currencyHolidayID': currencyHolidayID
        };
        let response = await genericDeleteWithParameters('Holiday/DeleteValueDateHolidays',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveHolidayCarryOver(model){

    try{
        let response = await genericCallWithBody('POST','Holiday/SaveHolidayCarryOver',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

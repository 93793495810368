import { genericCallWithBody, genericGetWithParameters } from "./Networking";

export const getMessagesByComplaintID = async (complaintID) => {
    try {
        const params = {
            'complaintID': complaintID
        };
        const response = await genericGetWithParameters('Complaint/GetListOfMessagesByComplaint', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getComplaintByID = async (complaintID) => {
    try {
        const params = {
            'complaintID': complaintID
        };
        const response = await genericGetWithParameters('Complaint/GetComplaintByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const updateComplaint = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Complaint/UpdateComplaint', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const sendComplaintRepliedEmail = async (complaintID, contactID) => {
    try {
        const params = {
            'ComplaintID': complaintID,
            'ContactID': contactID
        }
        const response = await genericCallWithBody('POST', 'Complaint/SendComplaintRepliedEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
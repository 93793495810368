import React from 'react';

import AlertBox from '../shared/AlertBox';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';

import DynamicSelect from '../shared/DynamicSelect';
import NewDynamicTable from '../shared/NewDynamicTable';
import { formatValidSelect, formatValidInput } from '../../helpers/FormatHelper';

import {getRiskScoreRanges, saveUpdateRiskScoreRange} from '../../networking/NetworkingRiskScoreRange';
import { getLookUpTablesDescription } from '../../networking/NetworkingLookUpTables'

import {getStyle} from '../../styles/styles';
var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

export default class RiskScoreRangeScreen extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = { 
            entityTypeList          : [],
            statusList              : [],
            riskScoreRangeAllList   : [],
            entityTypeSelected      : '',
            statusSelected          : '',
            disableStatus           : true,
            actualDescription       : '',
            actualColorSelected     : '',
            actualRangeFrom         : null,
            actualRangeTo           : null,
            actualRiskScoreRangeObj : {},
            //modal
            showModal               : true,
            //validaciones
            validations: {
                entityType  : true,
                status      : true,
                description : true,
                rangeFrom   : true,
                rangeTo     : true,
                color       : true,
                listMissing : []
            },
            listToShow              : [],
            actualScoreRangeID      : 0,
            userEditable            : false,
            //Buttons
            saveUpdateTextButton    : 'Save',
            disableButtonSaveUpdate : true,
            //Alert
            showAlert: false, 
            alertTitle: '',
            alertMessage: '',
            //Tooltip
            tooltipRangeFrom        : null,
            tooltipRangeTo          : null,
            tooltipSave             : null,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(this.state.entityTypeSelected !== prevState.entityTypeSelected)
        {
            this.filterAllListByEntityType()
        }
    }

    componentDidMount()
    {
        getLookUpTablesDescription('RiskRangeEntityTypes').then(
            (jsonResponse) => {
                console.log("getLookUpTablesDescription: "+ JSON.stringify(jsonResponse.lookUpTables))
                if(jsonResponse.lookUpTables !== undefined)
                {
                    if(Array.isArray(jsonResponse.lookUpTables))
                    {
                        var objLookUpTable = jsonResponse.lookUpTables[0].lookUpTableDetails
                        if(Array.isArray(objLookUpTable))
                        {
                            var riskRangeEntityTypesList = []
                            objLookUpTable.forEach(element => {
                                var elementList = {
                                    value   :element.LookUpTableDetail,
                                    name    : element.LookUpTableDetail
                                }
                                riskRangeEntityTypesList.push(elementList)
                            });
                            console.log("riskRangeEntityTypesList: "+ JSON.stringify(riskRangeEntityTypesList))
                            this.setState({
                                entityTypeList: riskRangeEntityTypesList,
                                tooltipSave: 'Select an Entity Type'
                            })
                        }

                        {/*
                            [
                                {
                                    "LookUpTableID":51,
                                    "LookUpTableDescription":"RiskRangeEntityTypes",
                                    "lookUpTableDetails":[
                                    {
                                        "LookUpTableDetail":"Select an entity type...",
                                        "DisplayOrder":-1,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    },
                                    {
                                        "LookUpTableDetail":"Customer",
                                        "DisplayOrder":1,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    },
                                    {
                                        "LookUpTableDetail":"Beneficiary",
                                        "DisplayOrder":2,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    },
                                    {
                                        "LookUpTableDetail":"Trade",
                                        "DisplayOrder":3,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    }]
                                }
                            ]
                        */}
                    }
                }
            }
        );
        
        getRiskScoreRanges().then(
            (jsonResponse) => {
                console.log("getRiskScoreRanges: "+ JSON.stringify(jsonResponse))
                if(jsonResponse.riskScores !== undefined)
                {
                    /*{
                        "Score Range ID":10,
                        "Range From":0,
                        "Range To":25,
                        "Description":"Low risk",
                        "Color":"#FF80FF00",
                        "Status":"Authorized to deal",
                        "User Editable":false,
                        "Entity Type":"Customer"
                    }*/

                    var listRSRAll = []
                    ////debugger
                    listRSRAll = jsonResponse.riskScores
                    this.setState({
                        riskScoreRangeAllList:  listRSRAll
                    });
                }
            }
        );
    }

    updateAllRiskScore = () =>{
        getRiskScoreRanges().then(
            (jsonResponse) => {
                console.log("getRiskScoreRanges: "+ JSON.stringify(jsonResponse))
                if(jsonResponse.riskScores !== undefined)
                {
                    /*{
                        "Score Range ID":10,
                        "Range From":0,
                        "Range To":25,
                        "Description":"Low risk",
                        "Color":"#FF80FF00",
                        "Status":"Authorized to deal",
                        "User Editable":false,
                        "Entity Type":"Customer"
                    }*/

                    var listRSRAll = []
                    listRSRAll = jsonResponse.riskScores
                    this.setState({
                        riskScoreRangeAllList:  listRSRAll
                    }, () => this.filterAllListByEntityType());
                }
            }
        );
    }
    onChangeValueOfColor=(event)=>
    {
        this.setState({
            actualColorSelected : event.target.value,
            validations         : {...this.state.validations, color: true}
        })
    }

    closeModal = () =>
    {
        this.setState({
            showModal: false,
        })
    }

    handleGetValueEntityType = (entityObj) => 
    {
        if (entityObj.value !== undefined)
        {
            if(entityObj.value.indexOf("Select", 0) == 0)
            {
                this.setState({
                    entityTypeSelected  : entityObj.value,
                    disableStatus       : true,
                    statusList          : [],
                    validations         : {
                        ...this.state.validations,
                        entityType  : true,
                        status      : true,
                        description : true,
                        rangeFrom   : true,
                        rangeTo     : true,
                        color       : true,
                        listMissing : [],
                    },
                    listToShow      : [],
                    tooltipSave     : 'Select an Entity Type',
                }, () => this.filterAllListByEntityType())
                this.handleClearClick();
            }else
            {
                this.setState({
                    entityTypeSelected  : entityObj.value,
                    validations         : {...this.state.validations, entityType: true},
                    disableStatus       : false,
                    tooltipSave         : 'Select a Range from the table.',
                });

                var descriptionStatus = ''

                switch (entityObj.value)
                {
                    case 'Customer':
                        descriptionStatus = 'Customer Status'
                        break;
                    case 'Beneficiary':
                        descriptionStatus = 'Beneficiary Status'
                        break;
                    case 'Trade':
                        descriptionStatus = 'Deal Status'
                        break;
                }

                if(descriptionStatus != '')
                {
                    getLookUpTablesDescription(descriptionStatus).then(
                        (jsonResponseRiskScoreStatus) =>{
                            console.log("getLookUpTablesDescription "+ descriptionStatus + ": " + JSON.stringify(jsonResponseRiskScoreStatus))
                            if(jsonResponseRiskScoreStatus.lookUpTables !== undefined)
                            {
                                if(Array.isArray(jsonResponseRiskScoreStatus.lookUpTables))
                                {
                                    var objLookUpTable = jsonResponseRiskScoreStatus.lookUpTables[0].lookUpTableDetails
                                    if(Array.isArray(objLookUpTable))
                                    {
                                        var riskRangeEntityStatusList = []
                                        objLookUpTable.forEach(element => {
                                            var elementList = {
                                                value   :element.LookUpTableDetail,
                                                name    : element.LookUpTableDetail
                                            }
                                            riskRangeEntityStatusList.push(elementList)
                                        });
                                        console.log("riskRangeEntityStatusList: "+ JSON.stringify(riskRangeEntityStatusList))
                                        this.setState({
                                            statusList: riskRangeEntityStatusList
                                        })
                                    }
                                }
                            }
                        }
                    );
                }
            }
        }
    }

    handleGetValueStatus = (statusObj) => {
        if (statusObj.value !== undefined) {
            this.setState({
                statusSelected  : statusObj.value,
                validations         : {...this.state.validations, status: true}
            });
        }
    }

    handleUpdateDescription = (event) => {
        this.setState({ 
            actualDescription: event.target.value,
            validations         : {...this.state.validations, description: true}
        });
    }

    handleUpdateRangeFrom = (event) => {
        this.setState({ 
            actualRangeFrom: event.target.value,
            validations         : {...this.state.validations, rangeFrom: true},
            tooltipRangeFrom: null
        });
    }

    handleUpdateRangeTo = (event) => {
        if(this.state.tooltipRangeFrom == this.state.tooltipRangeTo)
        {
            this.setState({
                tooltipRangeFrom: null

            })
        }

        this.setState({ 
            actualRangeTo: event.target.value,
            validations         : {...this.state.validations, rangeTo: true},
            tooltipRangeTo: null
        });
    }

    filterAllListByEntityType()
    {
        var allList = this.state.riskScoreRangeAllList
        var entityType = this.state.entityTypeSelected
        if(allList!= null && entityType != '')
        {
            var entityTypeListFilter = []
            allList.forEach((risk, index) => {
                if(risk["Entity Type"] == entityType)
                {
                    entityTypeListFilter.push(risk)
                }
            });

            entityTypeListFilter.sort(this.dynamicSort("Range From"));

            this.setState({
                listToShow: entityTypeListFilter
            })
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onClickRow = objRisk => e => {
        console.log("objRisk: "+ JSON.stringify(objRisk))
        /*{
            "Score Range ID":10,
            "Range From":0,
            "Range To":25,
            "Description":"Low risk",
            "Color":"#FF80FF00",
            "Status":"Authorized to deal",
            "User Editable":false,
            "Entity Type":"Customer",
            "Color Hex":"#80FF00",
        }*/

        var colorRisk = objRisk["Color Hex"]

        this.setState({
            actualScoreRangeID  : objRisk["Score Range ID"],
            actualRangeFrom     : objRisk["Range From"],
            actualRangeTo       : objRisk["Range To"],
            actualDescription   : objRisk["Description"],
            actualColorSelected : colorRisk,
            statusSelected      : objRisk["Status"],
            userEditable        : objRisk["User Editable"],
            //Button Text
            saveUpdateTextButton    : 'Update',
            disableButtonSaveUpdate : false,
            validations: {
                ...this.state.validations,
                entityType  : true,
                status      : true,
                description : true,
                rangeFrom   : true,
                rangeTo     : true,
                color       : true,
                listMissing : []
            },
            tooltipSave     : 'Change the values you want.'
        })
    }

    handleClearClick = () =>{
        this.setState({
            actualScoreRangeID  : 0,
            actualRangeFrom     : '',
            actualRangeTo       : '',
            actualDescription   : '',
            actualColorSelected : '',
            statusSelected      : '',
            userEditable        : '',
            //Button Text
            saveUpdateTextButton: 'Save',
            validations: {
                entityType  : true,
                status      : true,
                description : true,
                rangeFrom   : true,
                rangeTo     : true,
                color       : true,
                listMissing : []
            },
            tooltipRangeFrom: null,
            tooltipRangeTo  : null,
            disableButtonSaveUpdate: true,
            tooltipSave         : 'Select a Range from the table.',
        })
    }

    handleSaveUpdateClick = () =>
    {

        if(this.validateRequiredFields())
        {
            var modelSaveUpdate =
            {
                "ScoreRangeID"  : this.state.actualScoreRangeID,
                "RangeFrom"     : this.state.actualRangeFrom,
                "RangeTo"       : this.state.actualRangeTo,
                "Description"   : this.state.actualDescription,
                "ColorHEX"      : this.state.actualColorSelected,
                "Status"        : this.state.statusSelected,
                "EntityType"    : this.state.entityTypeSelected
            }

            saveUpdateRiskScoreRange(modelSaveUpdate).then(
                (jsonResponse) => {
                    console.log("saveUpdateRiskScoreRange: "+ JSON.stringify(jsonResponse))
                    if( jsonResponse.Message === undefined && jsonResponse.ErrorMessage == undefined)
                    {
                        if(jsonResponse.riskScore.length == 1)
                        {
                            var message = 'The Risk Score Range has been '+ (this.state.saveUpdateTextButton === 'Save' ? 'saved' : 'updated') + ' successfully.'
                            
                            var listAux = this.state.listToShow
                            listAux.forEach((element, index) => {
                               if(element["Score Range ID"] === jsonResponse.riskScore[0]["Score Range ID"])
                                {
                                    listAux[index] = jsonResponse.riskScore[0] 
                                }
                            });

                            this.setState({
                                showAlert   : true,
                                alertTitle  : 'Successfully',
                                alertMessage: message,
                                validations: {
                                    ...this.state.validations,
                                    entityType  : true,
                                    status      : true,
                                    description : true,
                                    rangeFrom   : true,
                                    rangeTo     : true,
                                    color       : true,
                                    listMissing : []
                                }
                            }, () => this.updateAllRiskScore());
                            this.handleClearClick();
                        }
                        /*{
                            "Message":"Procedure or function 'usp_react_RiskScoreRanges_SaveRiskScoreRange' expects parameter '@Description', which was not supplied.",
                            "ErrorMessage":null,
                            "httpStatusCode":503
                        }*/
                    }else
                    {
                        this.setState({
                            showAlert   : true,
                            alertTitle  : 'Error',
                            // alertMessage: jsonResponse.Message + ' - ' + jsonResponse.ErrorMessage,
                            alertMessage: jsonResponse.httpErrorMessage
                        })

                        /*
                            {"riskScore":
                                [{
                                    "Score Range ID":10,
                                    "Range From":0,
                                    "Range To":26,
                                    "Description":"Low risk",
                                    "Color Hex":"#80FF00",
                                    "Status":"Authorized to deal",
                                    "User Editable":false,
                                    "Entity Type":"Customer"
                                }]
                            }
                        */
                    }
                }
            )
        }
    }

    validateRequiredFields() 
    {
        var valid               = true
        var riskScoreRangeIdVal = true
        var rangeFromVal        = true
        var rangeToVal          = true
        var descriptionVal      = true
        var colorHEXVal         = true
        var statusVal           = true
        var entityTypeVal       = true

        var listItems =[]

        var auxIsBetween0And100 = true
        
        if (this.state.actualScoreRangeID < 0)
        {
            valid = false;
            riskScoreRangeIdVal = false;
            listItems.push("Select a risk score range from the table")
        }

        if( this.state.entityTypeSelected   === undefined   || 
            this.state.entityTypeSelected   === ""         ||
            this.state.entityTypeSelected.indexOf("Select", 0) == 0)
        {
            valid = false;
            entityTypeVal = false;
            listItems.push("Select an Entity Type")
        }

        if( this.state.statusSelected   === undefined || 
            this.state.statusSelected   === ""          ||
            this.state.statusSelected.indexOf("Select", 0) == 0)
        {
            valid = false;
            statusVal = false;
            listItems.push("Select a Status")
        }

        if( this.state.actualDescription   === undefined || 
            this.state.actualDescription    === "")
        {
            valid = false;
            descriptionVal = false;
            listItems.push("Description")
        }

        if( this.state.actualColorSelected   === undefined || 
        this.state.actualColorSelected    === "")
        {
            valid = false;
            colorHEXVal = false;
            listItems.push("Color")
        }

        if( this.state.actualRangeFrom   === undefined   || 
            this.state.actualRangeFrom   === null        || 
            this.state.actualRangeFrom   === "")
        {
            valid = false;
            rangeFromVal = false;
            listItems.push("Range From")
        }else
        {
            if( parseInt(this.state.actualRangeFrom) <   0  ||
                parseInt(this.state.actualRangeFrom) >   100)
            {
                valid = false;
                rangeFromVal = false;
                auxIsBetween0And100 = false;
                listItems.push("Range From")
                this.setState({
                    tooltipRangeFrom  : 'Value have to be between 0-100',
                })
            }
        }
 
        if( this.state.actualRangeTo   === undefined || 
            this.state.actualRangeTo   === null      || 
            this.state.actualRangeTo   === "")
        {
            valid = false;
            rangeToVal = false;
            listItems.push("Range To")
        }else
        {
            if( parseInt(this.state.actualRangeTo) < 0  ||
                parseInt(this.state.actualRangeTo) > 100)
            {
                valid = false;
                rangeToVal = false;
                auxIsBetween0And100 = false;
                listItems.push("Range To")
                this.setState({
                    tooltipRangeTo  : 'Value have to be between 0-100',
                })
            }
        }

        if(auxIsBetween0And100)
        {
            if(parseInt(this.state.actualRangeTo) === parseInt(this.state.actualRangeFrom))
            {
                valid = false;
                rangeToVal = false;
                rangeFromVal = false;

                listItems.push("Range Froom")
                listItems.push("Range To")

                this.setState({
                    tooltipRangeTo  : 'Value cannot be equal to RangeFrom',
                    tooltipRangeFrom: 'Value cannot be equal to RangeTo',
                });
            }

            if(parseInt(this.state.actualRangeTo) < parseInt(this.state.actualRangeFrom))
            {
                valid = false;
                rangeToVal = false;
                listItems.push("Range To")

                this.setState({
                    tooltipRangeTo: 'Value cannot be less than RangeFrom',
                    tooltipRangeFrom: null
                });
            }

            ////debugger
            if(parseInt(this.state.actualRangeTo) > parseInt(this.state.actualRangeFrom))
            {
                this.setState({
                    tooltipRangeTo  : null,
                    tooltipRangeFrom: null
                });
            }
        }

        this.setState({
            validations: {
                ...this.state.validations,
                entityType  : entityTypeVal,
                status      : statusVal,
                description : descriptionVal,
                rangeFrom   : rangeFromVal,
                rangeTo     : rangeToVal,
                color       : colorHEXVal,
                listMissing : listItems
            }
        });

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    closeAlert(){
        this.setState({showAlert: false, alertTitle: '', alertMessage: ''});
    }

    render()
    {
        if(this.state.listToShow !== [] && this.state.listToShow.length !== 0)
        {
            var listRiskToShow = this.state.listToShow;
            var headerList = Object.keys(listRiskToShow[0]);
            var headers = []
            var rows = []

            headerList.forEach((header, index) => 
            {
                if(index !== 0 && index !== 4 && index !== 6 && index !== 7 && index !== 8)
                {
                    headers.push(<th style={style.alignCenter} key="actions-column">{header}</th>)
                }
            });

            listRiskToShow.forEach(risk => 
            {
                var backgroundColorRisk = risk["Color Hex"]
                rows.push(
                    <tr
                        style={{
                            backgroundColor : backgroundColorRisk,
                            textAlign       : "center",
                            cursor          : 'pointer'
                        }}
                        onClick={this.onClickRow(risk)}
                    >
                        {/*<td style={{borderLeft: 'none'}}>{risk[headerList[0]]}</td>*/}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[1]]}</td>}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[2]]}</td>}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[3]]}</td>}
                        {/*<td style={{borderLeft: 'none'}}>{risk[headerList[4]]}</td>*/}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[5]]}</td>}
                        {/*<td style={{borderLeft: 'none'}}>{risk[headerList[6]]}</td>*/}
                        {/*<td style={{borderLeft: 'none'}}>{risk[headerList[7]]}</td>*/}
                    </tr>
                );  
            });
        }
        
        return (
            <div style={{paddingTop: 20}}>
                <h3 
                    className="uk-heading-divider" 
                    style={style.titleStyle}
                >Risk Score Range</h3>

                <Dialog 
                    open        = {this.state.showModal} 
                    onClose     = {this.closeModal}
                    fullWidth   = {true}
                    maxWidth    = 'sm'
                    //aria-labelledby="customized-dialog-title"
                >
                    <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                        Risk Score Range Maintenance 
                    </DialogTitle>
                    <DialogContent dividers>
                    <DialogContentText>
                        Instructions
                    </DialogContentText>
                        <text style={{backgroundColor: '#ffffff'}}>
                            Risk scores are used to classify entities (Customers, Beneficiaries, Trades) by risk. These entities are scored for risk, and, the risk score falls within a range. This screen can be used to define those ranges. A risk score can be any value between 0 and 100. Risk score ranges must be between these numbers, and must be sequential, without any gaps or intersection. For example:  ranges can be defined in quarters: 0-25, 26-50, 51-75, 76-100. There cannot be any gaps: 0 - 25, 30-100 - this would not be accepted. Also, overlap is not accepted: 0-25, 20-80, 70-100 - is also invalid.
                            Each range can have a description, status (i.e. the status that the entity will be saved in if their score falls within the defined range), and a color - the color is used on the trade screen to provide a visual indication of the risk level.
                            To modify the ranges (the values in the Range From and Range To columns), you can double click the value you want to change in the table directly. When you are done, press the 'Save Ranges' button.
                            To edit the status, entity type, description or color, double click the row you want to edit (anywhere but in the Range columns), and use the fields at in the 'Score Range Information' section to make your changes. Press Save when you are finished.
                        </text>
                    </DialogContent>
                    <DialogActions>
                        <button 
                            className="uk-button uk-button-green" 
                            key={"Yes"} 
                            type="button" 
                            onClick={this.closeModal}>Ok</button>
                    </DialogActions>
                </Dialog>

                <div style={{padding: 20}}>
                    <h4 className="uk-h3">Score Range Information</h4>

                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Type (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect 
                                    className={formatValidSelect(this.state.validations.entityType)} 
                                    objValue={{value: 'value'}} 
                                    getValue={this.handleGetValueEntityType} 
                                    listHasPlaceholder={false} 
                                    data={this.state.entityTypeList} 
                                    id="form-entity-type"
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect 
                                    className           = {formatValidSelect(this.state.validations.status)}
                                    objValue            = {{value: this.state.statusSelected}} 
                                    getValue            = {this.handleGetValueStatus} 
                                    listHasPlaceholder  = {false} 
                                    data                = {this.state.statusList} 
                                    id                  = "form-customer-status"
                                    disabled            = {this.state.disableStatus}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Description (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input 
                                    className={formatValidInput(this.state.validations.description)}
                                    type="text"
                                    placeholder=""
                                    value={this.state.actualDescription}
                                    onChange={this.handleUpdateDescription}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Color (*)</label>
                            <div 
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                }}
                            >
                                <div 
                                    style={{
                                        //backgroundColor: 'blue',
                                        width: '100%',
                                        height: '30px',
                                        flex: 1
                                    }}
                                >
                                    <input
                                        //className={formatValidInput(this.state.validations.color)}
                                        type='color'
                                        onChange={this.onChangeValueOfColor}
                                        value={this.state.actualColorSelected}
                                    />
                                </div>

                                {/*<div 
                                    style={{
                                        backgroundColor: 'yellow',
                                        width: '100%',
                                        height: '30px',
                                        flex: 2
                                    }}
                                >
                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex'
                                        }}
                                    >
                                        <div 
                                            className="test"
                                            style={{
                                                flex: 1
                                            }}
                                        >#</div>
                                        <div 
                                            style={{
                                                position: 'relative',
                                                flex: 2
                                            }}
                                        >
                                            <input 
                                                value={this.state.actualColorSelected}
                                                className="test2">
                                            </input>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                
                    <div className="uk-grid" style={{marginTop: "0px"}}>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Range From (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input 
                                    uk-tooltip={this.state.tooltipRangeFrom}
                                    className={formatValidInput(this.state.validations.rangeFrom)}
                                    type="numeric"
                                    placeholder=""
                                    value={this.state.actualRangeFrom}
                                    onChange={this.handleUpdateRangeFrom}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Range to (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input 
                                    uk-tooltip={this.state.tooltipRangeTo}
                                    className={formatValidInput(this.state.validations.rangeTo)}
                                    type="numeric"
                                    placeholder=""
                                    value={this.state.actualRangeTo}
                                    onChange={this.handleUpdateRangeTo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="uk-width-1-1" style={{paddingLeft: "20px"}}>
                    <button 
                        className   = "uk-button uk-button-green"
                        type        = "button" 
                        onClick     = {this.handleClearClick}
                    >Clear Fields</button>
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <InfoIcon 
                                uk-tooltip={this.state.tooltipSave}
                                style={{ color: '#6E9C3A' }}
                            />
                        }
                        disabled={true}
                    >
                        <button 
                            className   = "uk-button uk-button-green" 
                            type        = "button" 
                            onClick     ={this.handleSaveUpdateClick}
                            // disabled    = {this.state.disableButtonSaveUpdate}
                        >{this.state.saveUpdateTextButton}</button>
                    </Badge>
                    <hr/>
                </div>

                <div style={{padding: 20}}>
                    {/*<h2 className="uk-h3">Results</h2>*/}
                    <table class="uk-table">
                        <thead>
                            <tr 
                                style={{
                                    border: '1px solid #e5e5e5'
                                }}
                            >
                                {headers}
                            </tr>
                        </thead>
                        <tbody
                             style={{
                                border: '1px solid #e5e5e5'
                            }}
                        >
                            {rows}
                        </tbody>
                    </table>
                </div>
                <AlertBox 
                    open={this.state.showAlert} 
                    onClose={this.closeAlert.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage}
                    type="Ok" 
                    okClick={this.closeAlert.bind(this)}
                    messageBoldList={this.state.validations.listMissing}
                />
            </div>
            );
    }
  }
import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../css/custom-quill.css';

const CustomButton = (props) => <span>{props.parameters}</span>;
const Parameters = '$Name';

function insertStar() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, "★");
    this.quill.setSelection(cursorPosition + 1);
}

function insertParameter(parameter) {
    const cursorPosition = this.quill.getSelection().index;
    if (parameter != null && parameter.includes('http')) {
      this.quill.insertText(cursorPosition, 'Here');
      this.quill.formatText(cursorPosition, cursorPosition + parameter.length, { 'link': parameter });
    } else {
      this.quill.insertText(cursorPosition, parameter);
    }
    this.quill.setSelection(cursorPosition + parameter.length);
}

const CustomToolbar = (props) => (
    <div id="toolbar">
      <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option selected />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <select className="ql-color">
        <option value="red" />
        <option value="green" />
        <option value="blue" />
        <option value="orange" />
        <option value="violet" />
        <option value="#d0d1d2" />
        <option selected />
      </select>
      <select className="ql-insertParameters tooltip">
        {props.options}
      </select>
      {/*<button className="ql-insertParameters">
        <CustomButton parameters="$Test"/>
</button>*/}
    </div>
);

export default class CustomQuill extends React.Component {
    constructor(props) {
      super(props);
      this.state = { editorHtml: "" };
      this.handleChange = this.handleChange.bind(this);
    }
  
    handleChange(html) {
      this.setState({ editorHtml: html });
    }

    componentDidMount = () => {
      const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));

        placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);

        document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
            = 'Select a parameter...' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
        document.querySelector('.ql-placeholder .ql-picker-label').classList.add('uk-margin-right');
    }

    componentDidUpdate = (prevProps) => {
      if (this.props.parameters !== prevProps.parameters) {
        const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));

        placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);

        document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
            = 'Select a parameter...' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
        document.querySelector('.ql-placeholder .ql-picker-label').classList.add('uk-margin-right');
      }
    }
  
    render() {
      var options = [];
      //options.push(<option key={"select"} value=''>Select a parameter...</option>);
      for (var i=0; i<this.props.parameters.length; i++) {
        options.push(<option key={this.props.parameters[i]} value={this.props.parameters[i]}>{this.props.parameters[i]}</option>);
      }
      
      return (
        <div className="text-editor">
          {/*<CustomToolbar options = {options}/>*/}
          {/*<div id="toolbar">
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                <option value="1" />
                <option value="2" />
                <option selected />
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" />
            <button className="ql-list" value="ordered"/>
            <button className="ql-list" value="bullet"/>
            <select className="ql-color">
                <option value="red" />
                <option value="green" />
                <option value="blue" />
                <option value="orange" />
                <option value="violet" />
                <option value="#d0d1d2" />
                <option selected />
            </select>   
            <button className="ql-clean" />
            <select className="ql-insertParameters tooltip">{options}</select> 
          </div> */}
          <ReactQuill 
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            modules={getModules(this.props.parameters)}
            formats={CustomQuill.formats}
            theme={this.props.theme} // pass false to use minimal theme
          />
        </div>
      );
    }
}

let getModules = (options) => {
  return {
    toolbar: {
      //container: "#toolbar",
      container: [
            [{ 'placeholder': options }], // my custom dropdown
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
      ],
      handlers: {
        //insertStar: insertStar
        "placeholder" : insertParameter,
        //"insertParameters" : insertParameters
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }
}

CustomQuill.modules = {
    toolbar: {
      //container: "#toolbar",
      container: [
            [{ 'placeholder': ['[GuestName]', '[HotelName]'] }], // my custom dropdown
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
      ],
      handlers: {
        //insertStar: insertStar
        "placeholder" : insertParameter,
        //"insertParameters" : insertParameters
      }
    },
    clipboard: {
      matchVisual: false,
    }
};

CustomQuill.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
];

CustomQuill.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

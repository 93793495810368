import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody, decrypt, genericDeleteWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getRiskScoreRanges(){
    try {
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreRanges',null);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveUpdateRiskScoreRange(model) {
    try {
        var method = 'POST';
        var apiName = 'RiskScoreRange/SaveUpdateRiskScoreRange'

        let responseJWT = await genericCallWithBody(method, apiName, model);
        if(responseJWT.httpStatusCode === 200)
        {
            var decoded = decrypt(responseJWT);
            console.log(decoded);
            return decoded;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getRiskScoreDetailsForDeals(model)
{
    try {
        var method = 'POST';
        var apiName = 'RiskScoreRange/GetRiskScoreDetailsForDeals'

        let responseJWT = await genericCallWithBody(method, apiName, model);
        ////debugger
        if(responseJWT.httpStatusCode === 200)
        {
            var decoded = decrypt(responseJWT);
            console.log(decoded);
            return decoded;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}

export async function deleteRiskScoreRange(scoreRangeID){    
    try{
        let params = {
            'scoreRangeID': scoreRangeID
        };
        let response = await genericDeleteWithParameters('RiskScoreRange/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
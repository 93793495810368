import React, { useState } from 'react';
import CaseActivity from './CaseActivity';
import { useEffect } from 'react';
import moment from 'moment';
import AlertBox from '../../shared/AlertBox';
import { useContext } from 'react';
import { saveNewCase, updateCase, updateCaseStatus } from '../../../networking/NetworkingTMx';
import AppContext from '../../../AppContext';
import { formatDate } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
import DatePicker from '../../shared/DatePicker';
import { getLookUpTable } from '../../../networking/Networking';
import TextArea from 'antd/lib/input/TextArea';
import EntityDetails from './EntityDetails';

let style = getStyle();

const CaseDetail = (props)=> {
    const context = useContext(AppContext);   
    const [caseID, setCaseID] = useState('');
    const [riskPosed, setRiskPosed] = useState('');
    const [externalTransactionID, setExternalTransactionID] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [senderName, setSenderName] = useState('');
    const [senderID, setSenderID] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [recipientID, setRecipientID] = useState('');
    const [rulesAssessment, setRulesAssessment] = useState('');
    const [status, setStatus] = useState('Open');
    const [newStatus, setNewStatus] = useState('Open');
    const [reported, setReported] = useState(false);
    const [reportedDate, setReportedDate] = useState('');
    const [reportedRefNo, setReportedRefNo] = useState('');
    const [showConfirmStatus, setShowConfirmStatus] = useState(false);
    const [confirmStatusMessage, setConfirmStatusMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [reloadActivity, setReloadActivity] = useState(false);
    const [transactionTypesList, setTransactionTypesList] = useState([]);
    const [senderDetails, setSenderDetails] = useState('');
    const [recipientDetails, setRecipientDetails] = useState('');
    const [transactionDetails, setTransactionDetails] = useState('');
    const [hasSenderDetails, setHasSenderDetails] = useState(false);
    const [hasRecipientDetails, setHasRecipientDetails] = useState(false);
    const [hasTransactionDetails, setHasTransactionDetails] = useState(false);
    const [showEntityDetail, setShowEntityDetail] = useState(false);
    const [entityDetailTitle, setEntityDetailTitle] = useState('');
    const [entityJsonDetail, setEntityJsonDetail] = useState({});


    const dateFormat = "YYYY/MM/DD";
    const today = new Date();
    
    useEffect(()=>{
        loadTransactionTypes();
        if(props.Case.CaseID)
        {
            setCaseID(props.Case.CaseID);
            setRiskPosed(props.Case.RiskPosed);
            setSenderName(props.Case.SenderName);
            setSenderID(props.Case.SenderID);
            setSenderDetails(props.Case.SenderDetails);
            setTransactionType(props.Case.TransactionType);
            setExternalTransactionID(props.Case.ExternalTransactionID);
            setTransactionDetails(props.Case.TransactionDetails);
            setRecipientName(props.Case.RecipientName);
            setRecipientID(props.Case.RecipientID);
            setRecipientDetails(props.Case.RecipientDetails);
            setRulesAssessment(props.Case.RulesAssessment);
            setStatus(props.Case.Status);
            setReported(props.Case.Reported);
            setReportedDate(props.Case.Reported ? formatDate(props.Case.ReportedDate):'');
            setReportedRefNo(props.Case.ReportedRefNo);
            let hasSender = (props.Case.SenderDetails && props.Case.SenderDetails.startsWith('{'));
            setHasSenderDetails(hasSender);
            let hasRecipient = (props.Case.RecipientDetails && props.Case.RecipientDetails.startsWith('{'));
            setHasRecipientDetails(hasRecipient);
            let hasTransaction = (props.Case.TransactionDetails && props.Case.TransactionDetails.startsWith('{'));
            setHasTransactionDetails(hasTransaction);
        }
    },[props.Case])

    const loadTransactionTypes = () =>{
        getLookUpTable('Transaction Case Types').then(
            (json) => {
                let table = json.lookUpTables.find((l) =>  l.LookUpTableDescription=== 'Transaction Case Types');
                        if (table) {
                            let tableTransation = table.lookUpTableDetails.map(l => ({ ...l, name: l.LookUpTableDetail, value: l.LookUpTableDetail }));
                            setTransactionTypesList(tableTransation);    
                        }        
            }
        );
    }

    
    const handleSaveClick = async() =>{

        const model = {
            json : {
                "CaseID": caseID,
                "ExternalTransactionID": externalTransactionID,
                "TransactionType": transactionType,
                "RiskPosed":riskPosed,
                "SenderID":senderID,
                "SenderName":senderName,
                "RecipientID":recipientID,
                "RecipientName":recipientName,
                "RulesAssessment":rulesAssessment,
                "Reported":reported,
                "ReportedDate": reportedDate ?moment(reportedDate, dateFormat): '',
                "ReportedRefNo": reportedRefNo,
                "UpdatedBy":localStorage.getItem('UserID')
             }
        };
        context.startLoading();
        let json = null;
        if(caseID && caseID !== ''){
            json = await updateCase(model);
        }
        else
        {
            json = await saveNewCase(model);
        }
        context.finishLoading();
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Case successfully saved.');
                if(json.CaseID)
                {
                    setCaseID(json.CaseID);
                }
            }
        }
    }

    const handleCloseClick = () =>{
        setConfirmStatusMessage('Do you want to close this case?');
        setShowConfirmStatus(true);
        setNewStatus('Closed');
    }

    const handleReopenClick = () =>{
        setConfirmStatusMessage('Do you want to reopen this case?');
        setShowConfirmStatus(true);
        setNewStatus('Reopened');
    }

    const handleClearClick = () =>{
        setCaseID('');
        setRiskPosed('');
        setSenderName('');
        setSenderID('');
        setTransactionType('');
        setExternalTransactionID('');
        setRecipientName('');
        setRecipientID('');
        setRulesAssessment('');
        setReported(false);
        setReportedDate('');
        setReportedRefNo('');

        setSenderDetails('');
        setTransactionDetails('');
        setRecipientDetails('');
        setStatus('Open');
        setHasSenderDetails(false);
        setHasRecipientDetails(false);
        setHasTransactionDetails(false);
        setReloadActivity(!reloadActivity);
    }

    const reportedClick = (e) =>{
        setReported(e.target.checked);
        setReportedDate('');
        setReportedRefNo('');
    }

    const yesConfirmStatusAlert = async() =>{
        const model = {
            json : {
                "CaseID": caseID,
                "Status": newStatus,
                "UpdatedBy":localStorage.getItem('UserID')
             }
        };
        context.startLoading();
        const json = await updateCaseStatus(model);
        
        context.finishLoading();
        setShowConfirmStatus(false);
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Case successfully saved.');
                setStatus(newStatus);
                setReloadActivity(!reloadActivity);
            }
        }
    }

    const noConfirmStatusAlert = () =>{
        setShowConfirmStatus(false);
        setNewStatus(status);
    }

    const closeAlert = () =>{
        setShowAlert(false);
    }

   const onDateChange = (date) => {
        const _date = formatDate(date);
        setReportedDate(_date);
    };

    const onShowEntityDetails = (entityType) =>{
        let detail = '';
        let title = '';
        
        switch(entityType)
        {
            case 'S': detail = senderDetails;
                        title = 'Sender Details';
                    break;
            case 'R': detail = recipientDetails;
                        title = 'Recipient Details';
                break;
            case 'T': detail = transactionDetails;
                        title = 'Transaction Details';
            break;
            default:
                break;
        }

        if(detail)
        {
            let jsonDetail = JSON.parse(detail);
            if(jsonDetail)
            {
                setEntityJsonDetail(jsonDetail);
                setEntityDetailTitle(title);
                setShowEntityDetail(true);
            }
        }
    }

        return(
            <div>
                <div className="uk-form-stacked uk-grid">
                <div className="uk-width-1-4">
                        <div className="uk-margin">
                            <label className="uk-form-label" >Transaction Type</label>
                            <div className="uk-form-controls">
                            <select id="transaction-type" className="uk-select" value={transactionType}
                            onChange={(e) => {
                                if(e.target.value !== "-1")
                                {
                                    setTransactionType(e.target.value);
                                }
                            }}>
                            {transactionTypesList.map((type) => (
                                <option
                                    key={`transaction-type-option-${type.value}`}
                                    value={type.value}>{`${type.name}`}</option>
                            ))}
                        </select>
                              </div>
                        </div>
                    </div>

                    <div className="uk-width-1-4">
                        <div className="uk-margin">
                            <div style={{display:'flex'}}>
                                <div style={{marginRight:'10px'}}>
                                    <label className="uk-form-label" >Transaction ID</label>
                                </div>
                                    {hasTransactionDetails && 
                                    <div style={{marginRight:'10px'}}>
                                    <i class="fa fa-info-circle" style={{cursor:'pointer', fontSize:'18px'}} title='View details' aria-hidden="true" onClick={() => onShowEntityDetails('T')}/>
                                    </div>}
                            </div>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="ext-transaction-id" type="text" value={externalTransactionID} onChange={(e)=> setExternalTransactionID(e.target.value)}/>
                              </div>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" >Risk Posed</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-risk-posed" type="text" value={riskPosed} onChange={(e)=> setRiskPosed(e.target.value)}/>
                              </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                        <div style={{display:'flex'}}>
                                <div style={{marginRight:'10px'}}>
                                    <label className="uk-form-label" >Sender ID</label>
                                </div>
                                    {hasSenderDetails && 
                                    <div style={{marginRight:'10px'}}>
                                    <i class="fa fa-info-circle" style={{cursor:'pointer', fontSize:'18px'}} title='View details' aria-hidden="true" onClick={() => onShowEntityDetails('S')}/>
                                    </div>}
                            </div>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="sender-id" type="text" value={senderID} onChange={(e)=> setSenderID(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" >Sender Name</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-sender-name" type="text" value={senderName} onChange={(e)=> setSenderName(e.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <div style={{display:'flex'}}>
                                <div style={{marginRight:'10px'}}>
                                    <label className="uk-form-label" >Recipient ID</label>
                                </div>
                                    {hasRecipientDetails && 
                                    <div style={{marginRight:'10px'}}>
                                    <i class="fa fa-info-circle" style={{cursor:'pointer', fontSize:'18px'}} title='View details' aria-hidden="true" onClick={() => onShowEntityDetails('R')}/>
                                    </div>}
                            </div>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-recipient-id" type="text" value={recipientID} onChange={(e)=> setRecipientID(e.target.value)}/>
                              </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label">Recipient Name</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-recipient-name" type="text" value={recipientName} onChange={(e)=> setRecipientName(e.target.value)}/>
                              </div>
                        </div>
                    </div>

                    

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" >Status</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-status" disabled={true} type="text" value={status} onChange={(e)=> setStatus(e.target.value)}/>
                              </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label">Rules Assessment</label>
                            <div className="uk-form-controls">
                            <TextArea className="uk-input" id="form-rules-assessment" type="text" value={rulesAssessment} onChange={(e)=> setRulesAssessment(e.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-8" style={{height:'40px'}}>
                        <div className="uk-margin">
                            <label className="uk-form-label">Reported</label>
                            <div className="uk-form-controls">
                            <input className="uk-checkbox" id="form-reported status" type="checkbox" checked={reported} onChange={reportedClick}/>
                            </div>
                        </div>
                    </div>
                    {reported && <div className="uk-width-1-8" style={{height:'40px'}}>
                            <div className="uk-margin">
                            <label className="uk-form-label">Reported on</label>
                            <div className="uk-form-controls">
                            <DatePicker
                            className="uk-input"
                            style={style.inputStyle}
                            value={reportedDate}
                            endDate={today}
                            onDayChange={(e) => {
                                onDateChange(e);
                            }}
                    />
                            </div>
                        </div>
                        </div>}
                    {reported && <div className="uk-width-1-4" style={{height:'40px'}}>
                        <div className="uk-margin">
                            <label className="uk-form-label">Ref. No.</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-reported status" type="text" value={reportedRefNo} onChange={(e)=> setReportedRefNo(e.target.value)}/>
                            </div>
                        </div>
                    </div>}

 
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={handleSaveClick}>Save</button>
                        <button className="uk-button uk-button-green" type="button" onClick={handleClearClick}>Clear Fields</button>
                        {caseID && status === 'Open' && <button className="uk-button uk-button-green" type="button" onClick={handleCloseClick}>Close case</button>}
                        {status === 'Closed' && <button className="uk-button uk-button-green" type="button" onClick={handleReopenClick}>Reopen case</button>}
                    </div>
                </div>
                <CaseActivity CaseID={caseID} reload={reloadActivity} />
                <EntityDetails open={showEntityDetail} 
                    title ={entityDetailTitle}
                    jsonDetail={entityJsonDetail}
                    onClose={()=> setShowEntityDetail(false)} />
                <AlertBox id="confirmation-updateStatus"
                    open={showConfirmStatus}
                    type="Yes/No"
                    title={'Warning'}
                    message={confirmStatusMessage}
                    yesClick={yesConfirmStatusAlert}
                    noClick={noConfirmStatusAlert} />
                <AlertBox open={showAlert} 
                    onClose={closeAlert} 
                    title={alertTitle} 
                    message={alertMessage} 
                    type="Ok" 
                    okClick={closeAlert} />
            </div>
        );
    
}

export default CaseDetail;
import jwt from 'jsonwebtoken';
import {genericGetWithParameters, genericCallWithBody} from './Networking';

export async function getRiskFactors(page, items){
    try {
        let params = {
            'page': page,
            'items': items
        };
        let response = await genericGetWithParameters('RiskFactor/GetRiskFactors',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateRiskFactor(model){

    try{
        let response = await genericCallWithBody('PUT','RiskFactor/UpdateRiskImportance',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getContactRiskScore = async (contactId) => {
    try {
        const params = {
            ContactID: contactId
        };
        const response = await genericGetWithParameters('RiskScoreRange/GetContactRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


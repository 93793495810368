import React from 'react';

import { getStyle } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTable } from '../../../../networking/Networking';
import { getCustomerByID } from '../../../../networking/NetworkingCustomers';
import { formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();

export default class BeneficiaryInformationForm extends React.Component {

    state = {
        beneficiaries: {
            notFirstLoad: false,
            customerName: '',
            salutation: '',
            firstName: '',
            lastName: '',
            middleName: '',
            shortName: '',
            customerType: '',
            status: '',
            companyName: '',
            preferredPaymentIns: '',
            preferredDeliveryMet: '',
            contactPhone: '',
            contactEmail: '',
            contactName: '',
            customerIndustrySect: '',
            industrySector: '',
            detailsPayments: '',
            paymentReference: '',
            limit: '',
            paymentFlow: '',
            bypassValidation: '',
            expiryByPassDate: '',
            beneficiaryRiskScore: '',
            emailIndicator: '',
            field4: '',
            beneficiaryID: '',
            updateToken: 0,
            isEmailValid: '',
            errors: []
        },
        validations: {
            customerName: true,
            companyName: true,
            shortName: true,
            firstName: true,
            lastName: true,
            customerType: true,
            status: true,
            preferredPaymentIns: true,
            preferredDeliveryMet: true,
            emailIndicator: true
        },
        isNew: false,
        showRiskCalculation: false,
        riskScoreDetails: [],
        dropdowns: {
            traders: [],
            "Beneficiary Status": [],
            "Salutation": [],
            "Beneficiary Type": [],
            "Industry Sectors": [],
            "Outgoing Instruments": [],
            "Beneficiary Payment Flow": [],
            "Beneficiary Email Indicator": [],
            "Payment Delivery Method": []
        },
        lookUpTables: [
            "Beneficiary Status",
            "Salutation",
            "Beneficiary Type",
            "Industry Sectors",
            "Outgoing Instruments",
            "Beneficiary Payment Flow",
            "Beneficiary Email Indicator"
        ],
        lookUpTablesByFilter: [
            "Payment Delivery Method"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        }
    }

    componentDidMount() {
        getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({ dropdowns: { ...this.state.dropdowns, traders: json.traders } });
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                    }
                }
            });
        getCustomerByID(this.props.objNewBeneKey.CustomerId).then(
            (value) => {
                this.setState({ beneficiaries: { ...this.state.beneficiaries, customerName: value.Name } },
                    () => {
                        this.props.objFilterBeneInfo(this.state.beneficiaries)
                        this.props.isValid(this.validateRequiredInputs())
                    });
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.clearFields();
        }
    }
    clearFields() {
        this.setState({
            beneficiaries: {
                ...this.state.beneficiaries,
                notFirstLoad: false,
                salutation: '',
                firstName: '',
                lastName: '',
                middleName: '',
                shortName: '',
                customerType: '',
                status: '',
                companyName: '',
                preferredPaymentIns: '',
                preferredDeliveryMet: '',
                contactPhone: '',
                contactEmail: '',
                contactName: '',
                customerIndustrySect: '',
                industrySector: '',
                detailsPayments: '',
                paymentReference: '',
                limit: '',
                paymentFlow: '',
                bypassValidation: '',
                expiryByPassDate: '',
                beneficiaryRiskScore: '',
                emailIndicator: '',
                field4: '',
                beneficiaryID: '',
                updateToken: 0,
                isEmailValid: '',
                errors: []
            },
            validations: {
                customerName: true,
                companyName: true,
                shortName: true,
                firstName: true,
                lastName: true,
                customerType: true,
                status: true,
                preferredPaymentIns: true,
                preferredDeliveryMet: true,
                emailIndicator: true
            },
            isNew: false,
            showRiskCalculation: false,
            riskScoreDetails: [],
        }, () => {
            this.props.isValid(this.validateRequiredInputs())
            this.props.clearForm(false)
        })
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }

    handleUpdateCustomerType(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, customerType: value.value },
                    validations: { ...this.state.validations, customerType: true },
                }, () => {
                    if (this.validateCustomerType()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, customerType: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateCustomerType() {
        if (this.state.beneficiaries.customerType === "select" || this.state.beneficiaries.customerType === '' || this.state.beneficiaries.customerType === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateCompanyName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, companyName: event.target.value },
            validations: { ...this.state.validations, companyName: true }
        },
            () => {
                if (this.validateCompanyName()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
            });
    }
    validateCompanyName() {
        if (this.state.beneficiaries.companyName === '') {
            return false;
        }
        return true;
    }
    handleUpdateShortName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, shortName: event.target.value },
            validations: { ...this.state.validations, shortName: true }
        }, () => {
            if (this.validateRequiredInputs()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateShortName() {
        if (this.state.beneficiaries.shortName === '') {
            return false;
        }
        return true;
    }
    handleUpdatePreferredDeliveryMet(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, preferredDeliveryMet: value.value },
                    validations: { ...this.state.validations, preferredDeliveryMet: true }
                }, () => {
                    if (this.validatePreferredDeliveryMet()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, preferredDeliveryMet: '' }
            }, () => { this.props.objFilterBeneInfo(this.state.beneficiaries) });
        }
    }
    validatePreferredDeliveryMet() {
        if (this.state.beneficiaries.preferredDeliveryMet === "select" || this.state.beneficiaries.preferredDeliveryMet === '' || this.state.beneficiaries.preferredDeliveryMet === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateContactPhone(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactPhone: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateContactEmail(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactEmail: event.target.value } },
            () => {
                if (this.validateEmail()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(true);
                } else {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(false);
                }
            });
    }
    isEmailValid(validator) {
        if (validator) {
            this.setState({ beneficiaries: { ...this.state.beneficiaries, isEmailValid: true } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({ beneficiaries: { ...this.state.beneficiaries, isEmailValid: false } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.beneficiaries.contactEmail.match(regex)) {
            return false;
        }
        return true;
    }
    handleUpdateContactName(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactName: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateCustomerIndustrySect(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, customerIndustrySect: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateIndustrySector(value) {
        if (value !== undefined) {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, industrySector: value.value }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, industrySector: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }

    }
    handleUpdatePaymentReference(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, paymentReference: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateEmailIndicator(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, emailIndicator: value.value },
                    validations: { ...this.state.validations, emailIndicator: true }
                }, () => {
                    if (this.validateEmailIndicator()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, emailIndicator: '' }
            }, () => { this.props.objFilterBeneInfo(this.state.beneficiaries) });
        }
    }
    validateEmailIndicator() {
        if (this.state.beneficiaries.emailIndicator === "select" || this.state.beneficiaries.emailIndicator === '' || this.state.beneficiaries.emailIndicator === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateFirstName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, firstName: event.target.value },
            validations: { ...this.state.validations, firstName: true }
        }, () => {
            if (this.validateFirstName()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateFirstName() {
        if (this.state.beneficiaries.firstName === '') {
            return false;
        }
        return true;
    }
    handleUpdateLastName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, lastName: event.target.value },
            validations: { ...this.state.validations, lastName: true }
        }, () => {
            if (this.validateLastName()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateLastName() {
        if (this.state.beneficiaries.lastName === '') {
            return false;
        }
        return true;
    }
    handleUpdateSalutation(value) {
        if (value !== undefined) {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, salutation: value.value }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, salutation: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }

    validateRequiredFields() {
        const fields = [
            { displayName: 'Customer Name', validateName: 'customerName', value: this.state.beneficiaries.customerName, type: 'string' },
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.beneficiaries.shortName, type: 'string' },

            { displayName: 'Beneficiary Type', validateName: 'customerType', value: this.state.beneficiaries.customerType, type: 'string' },
            { displayName: 'Email Indicator', validateName: 'emailIndicator', value: this.state.beneficiaries.emailIndicator, type: 'string' },
        ]

        if (this.state.beneficiaries.customerType.includes("Personal")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.beneficiaries.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.beneficiaries.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.beneficiaries.companyName, type: 'string' })
        }

        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'Customer Name', validateName: 'customerName', value: this.state.beneficiaries.customerName, type: 'string' },
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.beneficiaries.shortName, type: 'string' },

            { displayName: 'Beneficiary Type', validateName: 'customerType', value: this.state.beneficiaries.customerType, type: 'string' },
            { displayName: 'Email Indicator', validateName: 'emailIndicator', value: this.state.beneficiaries.emailIndicator, type: 'string' },
        ]

        if (this.state.beneficiaries.customerType.includes("Personal")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.beneficiaries.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.beneficiaries.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.beneficiaries.companyName, type: 'string' })
        }
        const [valid, validations, errors] = validate(fields);

        return { valid, errors };
    }
    formatVisibilityIndividual(type) {
        if (type != undefined) {
            return type.includes("Personal") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    formatVisibilityCorporate(type) {
        if (type != undefined) {
            return !type.includes("Personal") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    render() {
        var customerIndustrySect = ''
        if (this.state.beneficiaries.industrySector !== '') {
            customerIndustrySect = this.state.beneficiaries.industrySector
        } else {
            customerIndustrySect = 'No Select'
        }

        var divPersonal1 = ('');
        var divPersonal2 = ('');
        var divPersonal3 = ('');
        var divCorporate1 = ('');
        var divCorporate2 = ('');
        //--VALIDATIONS VISIBLE
        switch (this.state.beneficiaries.customerType) {
            default:
            case 'Personal':
                divPersonal1 = (
                    <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                        <div className="uk-grid">
                            <div className="uk-width-1-3">
                                <label className="uk-form-label" htmlFor="form-beneficiary-salutation">Salutation:</label>
                            </div>
                            <div className="uk-width-2-3">
                                <div className="uk-form-controls">
                                    <DynamicSelect className='uk-select' objValue={{ value: this.state.beneficiaries.salutation }} getValue={this.handleUpdateSalutation.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Salutation"]} id="form-beneficiary-salutation" />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                divPersonal2 = (
                    <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                        <div className="uk-grid">
                            <div className="uk-width-1-3">
                                <label className="uk-form-label" htmlFor="form-beneficiary-firstName" style={style.boldLabel}>First Name (*):</label>
                            </div>
                            <div className="uk-width-2-3">
                                <div className="uk-form-controls">
                                    <input className={formatValidInput(this.state.validations.firstName)} id="firstName" type="text" value={this.state.beneficiaries.firstName} onChange={this.handleUpdateFirstName.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                divPersonal3 = (
                    <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                        <div className="uk-grid">
                            <div className="uk-width-1-3">
                                <label className="uk-form-label" htmlFor="form-beneficiary-lastName" style={style.boldLabel}>Last Name (*):</label>
                            </div>
                            <div className="uk-width-2-3">
                                <div className="uk-form-controls">
                                    <input className={formatValidInput(this.state.validations.lastName)} id="lastName" type="text" value={this.state.beneficiaries.lastName} onChange={this.handleUpdateLastName.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'Corporate':
                divCorporate1 = (
                    <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                        <div className="uk-grid">
                            <div className="uk-width-1-3">
                                <label className="uk-form-label" style={style.boldLabel}>Company Name (*):</label>
                            </div>
                            <div className="uk-width-2-3">
                                <div className="uk-form-controls">
                                    <input className={formatValidInput(this.state.validations.companyName)} id="companyName" type="text" value={this.state.beneficiaries.companyName} onChange={this.handleUpdateCompanyName.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                divCorporate2 = (
                    <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                        <div className="uk-grid">
                            <div className="uk-width-1-3">
                                <label className="uk-form-label" htmlFor="form-beneficiary-contactName">Contact Name:</label>
                            </div>
                            <div className="uk-width-2-3">
                                <div className="uk-form-controls">
                                    <input className='uk-input' id="contactName" type="text" value={this.state.beneficiaries.contactName} onChange={this.handleUpdateContactName.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
        }

        return (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-customerName" style={style.boldLabel}>Customer (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <label id="customerName" style={style.boldLabel}>{this.state.beneficiaries.customerName}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-customerType" style={style.boldLabel}>Type (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.customerType)} objValue={{ value: this.state.beneficiaries.customerType }} getValue={this.handleUpdateCustomerType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Type"]} id="form-beneficiary-type" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*TYPE ==> PERSONAL / INDIVIDUAL */}
                {divPersonal2}
                {divPersonal1}
                {divPersonal3}
                {/*TYPE ==> CORPORATE */}
                {divCorporate1}
                {/*---TAMBIEN VA EN PERSONAL/INDIVIDUAL ESTE INPUT DE SHORT NAME-- */}
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-shortName" style={style.boldLabel}>Short Name (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className={formatValidInput(this.state.validations.shortName)} id="shortName" type="text" value={this.state.beneficiaries.shortName} onChange={this.handleUpdateShortName.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                {/*----- */}
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-contactPhone">Contact Phone:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className='uk-input' id="contactPhone" type="text" value={this.state.beneficiaries.contactPhone} onChange={this.handleUpdateContactPhone.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-contactEmail">Contact Email:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className='uk-input' id="contactEmail" type="text" value={this.state.beneficiaries.contactEmail} onChange={this.handleUpdateContactEmail.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                {divCorporate2}

                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-customerIndustrySect">Customer Industry Sector:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <label id="customerIndustrySect">{this.state.beneficiaries.customerIndustrySect}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-industrySector">Industry Sector:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className='uk-select' objValue={{ value: this.state.beneficiaries.industrySector }} getValue={this.handleUpdateIndustrySector.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Industry Sectors"]} id="form-beneficiary-industrySector" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-paymentReference">Payment Reference:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className='uk-input' id="paymentReference" type="text" value={this.state.beneficiaries.paymentReference} onChange={this.handleUpdatePaymentReference.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" htmlFor="form-beneficiary-emailIndicator" style={style.boldLabel}>Email Indicator (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.emailIndicator)} objValue={{ value: this.state.beneficiaries.emailIndicator }} getValue={this.handleUpdateEmailIndicator.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Email Indicator"]} id="form-beneficiary-emailIndicator" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}
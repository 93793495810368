import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../helpers/FormatHelper';
import { getLookUpTable } from '../../networking/Networking';
import { getOutgoingFundsByDealReadyToSend } from '../../networking/NetworkingFunds';
import { sendOutgoingsToInterface } from '../../networking/NetworkingPayments';
import { notFilledSelectClass, regularSelectClass } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';



export default class OutgoingSendToInterface extends React.Component {

    state={
        outgoings:[],
        outgoingsToShow:[],
        externalPaymentInterface:'',
        paymentBankInterfaces: [],
        lookUpTables: [
            "BankInterfaces"
        ],
        loading: false,
        alertMessage:'',
        alertTitle:'',
        showAlert: false
    }

    transformList(list){
        var helper = [];
        if(list){
            for(var i = 0; i<list.length; i++){
                helper.push({
                    'OutgoingFunds ID': list[i].OutgoingFundsID,
                    'Beneficiary': list[i].BeneficiaryName,
                    'Amount': list[i].PaymentAmount,
                    'Value Date': formatDate(list[i].ValueDate)
                });                
            }
        }        
        return helper;
    }

    componentDidMount() {
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                    }
                }
                                
                let bankInterfaces = json.lookUpTables.filter(x => x.LookUpTableDescription === 'BankInterfaces')[0] || {}
                let bankInterfacesDetails = bankInterfaces.lookUpTableDetails || []
                let paymentBankInterfaces = bankInterfacesDetails.filter(x => x.Field1 === 'Payments' || x.DisplayOrder === -1) || []
                this.setState({ paymentBankInterfaces: paymentBankInterfaces });
            }
        );

        if(this.props.outgoings !== undefined)
        {
            this.setState({outgoings: this.props.outgoings, outgoingsToShow: this.transformList(this.props.outgoings)});
        }
        else
        {
            this.setState({outgoings: [],outgoingsToShow:[]});
        }  
    }    

    componentDidUpdate(prevProps) {
        if(this.props.outgoings !== undefined && prevProps.outgoings !== this.props.outgoings)
        {
            this.setState({outgoings: this.props.outgoings, outgoingsToShow: this.transformList(this.props.outgoings)});
        }
    }

    handlePaymentBankInterfaces = (value) => {
        if (value !== undefined && value.value !== undefined && value.value !== 'select') {
            this.setState({ externalPaymentInterface: value.value })
        }
    }

    handleSendOutgoings = () =>{

        if(this.state.externalPaymentInterface === '')
        {
            return false;
        }
        else
        {
            this.setState({ loading: true }, () => {
                sendOutgoingsToInterface(this.state.outgoings, this.props.customerID, this.state.externalPaymentInterface ).then((response) => {
                    switch (Number(response.httpStatusCode)) {
                        case 200:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: 'Payments saved in ' + this.state.externalPaymentInterface,
                                loading: false,
                            })
                            break
                        case 409:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                                loading: false
                            })
                            break
                        default:
                            console.log(response.errorMessage)
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: response.errorMessage || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                                loading: false
                            })
                            break
                    }
                }).finally(() => { 
                    this.setState({ loading: false}) ;
                })
            })
        }
    }

    closeAlert = ()=>{
        this.setState({showAlert:false});
        this.props.onCloseModal();
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    render() {

        return (
            <div>
                <Dialog
                    open={this.props.showModal}
                    onClose={this.props.closeModal}
                    fullWidth={true}
                    maxWidth='md'
                >
                    <DialogTitle id="outgoings-dialog-gwlatr-title" onClose={this.props.onCloseModal}>
                        {'Customer is set to send outgoing funds to interface, Do you want to send it now?'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <NewDynamicTable data={this.state.outgoingsToShow} hiddenHeaders={['Key']} enableClick={false} useDeleteButton={false} useCheckBox={false} newSearch={this.state.newSearch} useExportButton={false} tableName="outgoings" />
                        </div>
                        <div className="uk-grid uk-margin">
                        <div className="uk-width-1-3">
                            <DynamicSelect listHasPlaceholder={true}  data={this.state.paymentBankInterfaces} id="form-paymentBankInterfaces" 
                                        objValue={{value: this.state.externalPaymentInterface}}
                                        className={this.formatValidSelect(this.state.externalPaymentInterface !== '')}
                                        getValue={this.handlePaymentBankInterfaces} />
                        </div>
                        <div className="uk-width-1-7">
                            <button
                            className="uk-button uk-button-green"
                            key={"Yes"}
                            type="button"
                            onClick={this.handleSendOutgoings}>Send</button>
                        </div>
                        <div className="uk-width-1-7">
                            <button
                            className="uk-button uk-button-green"
                            key={"No"}
                            type="button"
                            onClick={this.props.onCloseModal}>Close</button>
                        </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
import React from 'react';
import { addLookUpTable } from '../../../networking/NetworkingLookUpTables';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import { formatValidInput } from '../../../helpers/FormatHelper';

var style = getStyle();
export default class LookUpTableForm extends React.Component {
    
    state = {
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        //---
        detailValue:'',
        isRiskFactor:'',
        updateToken:0,
        lookUpTableID:'',
        dropdowns: [],
        validations: {
            desc: true
        }
    }

    componentDidMount() {
        /*
        Description: "Account Type"
        ID: 1
        Is Risk Factor: false */
        this.setState({
            lookUpTableID: 0,
            detailValue: '',
            isRiskFactor: false,
            updateToken: 0
        });
    }
    componentDidUpdate(prevProps) {        

        if (Object.keys(this.props.getDropdowns).length !== 0 && prevProps.getDropdowns !== this.props.getDropdowns) {
            
            var propsDdwn = this.props.getDropdowns
            if (propsDdwn.Description !== '') {
                var boolFactor = ''
                if (typeof propsDdwn['Is Risk Factor'] === 'string') {
                    if (propsDdwn['Is Risk Factor'] === 'True') {
                        boolFactor = true;
                    } else {
                        boolFactor = false;
                    }
                } else if (typeof propsDdwn['Is Risk Factor'] === 'boolean') {
                    boolFactor = propsDdwn['Is Risk Factor'];
                }
                this.setState({
                    lookUpTableID: this.props.getDropdowns.ID,
                    detailValue: propsDdwn.Description,
                    isRiskFactor: boolFactor,
                    updateToken: propsDdwn.UpdateToken,
                    validations: {...this.state.validations, desc: true}
                });
            }
        } else if (Object.keys(this.props.getDropdowns).length === 0 && prevProps.getDropdowns.ID > 0) {
            
            this.setState({
                lookUpTableID: 0,
                detailValue: '',
                isRiskFactor: false,
                updateToken: 0,
                validations: {...this.state.validations, desc: true}
            })
        }
    }


    handleClearClick(event){
        event.preventDefault();
        /*this.setState({
            lookUpTableID:0,
            detailValue:'',
            isRiskFactor:false,
            updateToken:0
        })*/
        this.props.clearLookUp();        
    }
    
    handleSaveClick(event){
        event.preventDefault();
        let userID = localStorage.getItem('UserID');
        var objFilter = {
            "LookUpTableId": this.state.lookUpTableID,
            "Description": this.state.detailValue,
            "IsRiskFactor": this.state.isRiskFactor,
            "UserId": userID,
            "UpdateToken": this.state.updateToken
        }
        let isValid = true
        
        if (this.state.detailValue === '') {
            isValid = false;
            this.setState({
                validations: {
                    ...this.state.validations, desc: false
                }
            });
        }

        if (!isValid) {
            return;
        }
        
        addLookUpTable(objFilter).then(
            (json) => {
                if (json.status !== 200) {
                    this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the Look Up Table. Please try again.', alertTitle: 'Error' });
                } else {
                    this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Look Up Table successfully saved.', updateToken: json.updateToken });
                    this.props.clearLookUp();
                    this.props.updateLookUpTables();
                }
            }
        );
    }
    handleOnCheck(event){
        this.setState({isRiskFactor: event.target.checked});
    }
    handleUpdateDetailValue(event){
        let value = event.target.value        
        this.setState({
            desc: true,
            detailValue: value
        });
        if (value !== undefined && value !== null && value !== '') {
            this.setState({...this.state.validations, desc: false});
        }
    }

    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }
    
    //Input Validation
    validateRequiredInputs(){
        if(this.state.countryID === ''){
            return false;
        }
        if(this.state.currency === undefined){
            return false;
        }
        return true;
    }
    render() {

        var classNameInputName = regularInputClass;
        var classNameSelect =  regularSelectClass;

        if(this.state.countryID === '' && this.state.notFirstLoad){
            classNameInputName=notFilledInputClass;
        }
        if(this.state.countryName === '' && this.state.notFirstLoad){
            classNameInputName=notFilledInputClass;
        }
        if(this.state.currency === undefined && this.state.notFirstLoad){
            classNameSelect=notFilledSelectClass;
        }
        
        return (
            <form >
                <div className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptabledetails-detail">Name(*)</label>
                        <div className="uk-form-controls">
                            <input className={formatValidInput(this.state.validations.desc)} id="detailValue" type="text" value={this.state.detailValue} onChange={this.handleUpdateDetailValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin">
                        <label className="uk-form-label" htmlFor="form-lookuptabledetails-isRiskFactor"><input className="uk-checkbox" id="isRiskFactor" type="checkbox" checked={this.state.isRiskFactor} onChange={this.handleOnCheck.bind(this)}/>Is Risk Factor</label>
                    </div>
                </div>
                <div className="uk-width-1-1 uk-form-stacked">
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                    <hr />
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </form>
        );
    }
}

export const EmailDummyData = {
    "$UserName": "john.doe",
    "$NewPassword": "Avu3Yv5z2je6MVX8",
    "$Password": "Avu3Yv5z2je6MVX8",
    "$BeneficiaryName": "Jane Doe",
    "$CustomerID": "1242",
    "$customerId": "1242",
    "$CustomerName": "ACME Corp.",
    "$CustomerAddress": "Address Line Example",
    "$CustomerEmail": "acme@acme.com",
    "$customerName": "John Doe",
    "$FirstName": "John",
    "$LastName": "Doe", 
    "$AddressValue": "Address First Line",
    "$Address2": "Address Second Line",
    "$City": "Los Angeles",
    "$CountryID": "US",
    "$StateID": "CA",
    "$OtherState": "California",
    "$PostalCode": "90001",
    "$PhoneNumber": "213-200-4187",
    "$MobileNumber": "202-555-0077",
    "$Email": "john.doe@acme.org",
    "$DateOfBirth": "01/06/1990",
    "$TraderName": "Jane Doe",
    "$TraderEmail": "jane.doe@doe.com",
    "$TraderPhone": "202-555-0078",
    "$AllowNewDealsOnline": "Yes",
    "$RequestUserName": "bugs.bunny",
    "$EntityType": "Beneficiary",
    "$EntityName": "Daffy Duck",
    "$RequestEmail": "bugs.bunny@acme.org",
    "$CompanyContactUsEmailAddress": "us@acme.org",
    "$CompanyFaxNumber": "213-200-4187",
    "$CompanyTelephoneNumber": "213-200-4188",
    "$CompanyName": "ACME Corp.",
    "$BeneficiaryUrl": "http://beneURL.com/bene/",
    "$url": "http://beneURL.com/bene/",
    "$DealHeaderID": "1001",
    "$DealCreditStatus": "Accepted",
    "$RequireFundsInBeforeFundsOut": "Yes",
    "$STPComments": "STP Comments",
    "$CreditApprovalBy": "John Doe",
    "$CreditApprovalOn": "01/06/2021 13:01:43",
    "$PersonName": "John Doe",
    "$IssueDate": "01/06/2021",
    "$IssueTime": "13:01:43",
    "$DealTypeId": "1",
    "$HasForwardDatesDefined": "Yes",
    "$OpenFor": "Open For",
    "$NearDate": "01/06/2021",
    "$FarDate": "01/08/2021",
    "$ExpirationDate": "01/09/2021",
    "$GoodTillCancelled": "Yes",
    "$Amount": "1,500.00",
    "$CustomerRate1": "1.5000",
    "$CustomerRate2": "1.5000",
    "$SettlementCurrencyID": "EUR",
    "$PaymentCurrencyID": "USD",
    "$PaymentBeneficiary": "1,840.00",
    "$TransferType": "Wire Transfer",
    "$TransferTypeValue": "Wire",
    "$TransferType": "Wire Transfer",
    "$TransferDay": "01/08/2021",
    "$TransferMonthlyOnDay": "01/08/2021",
    "$TransferStartingDate": "01/08/2021",
    "$TransferEndingType": "Wire",
    "$TransferEndingAfterOccurrences": "5",
    "$TransferEndingOn": "01/10/2021",
    "$Website": "https://acme.com",
};
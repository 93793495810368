import React from 'react';
import { formatToCurrency, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getDealHeaderInfo, getFullDealHeader, getCoverDealsByPair, insertCoverDeal } from '../../../networking/NetworkingDeals';
import AlertBox from '../../shared/AlertBox';
import NewDynamicTable from '../../shared/NewDynamicTable.old';

export default class CoverDealForm extends React.Component {

    state = {
        coveredDeals: [],
        dealsByPair: [],
        totalPages: 0,
        pages: 1,
        iconView: false,
        selectedRows: [],
        //for alert box
        showAlert: false,
        showDeleteAlert: false,
        alertTitle: '',
        alertMessage: '',
        dealBuyAmount: '',
        sumSellAmount: 0,
        sellCurrency: '',
    }

    componentDidMount = () => {
        let deal = this.props.deal[0];
        this.setState({ dealBuyAmount: deal.BuyAmount + ' ' + deal.BCur });
        if (this.props.deal[0].CoveredDeals) {
            let coveredDeals = [];
            if (this.props.deal[0].CoveredDeals.includes(',')) {
                coveredDeals = this.props.deal[0].CoveredDeals.split(',');
            } else {
                coveredDeals.push(this.props.deal[0].CoveredDeals);
            }
            this.getCoveredDeals(coveredDeals);
            this.getCoverDeals(deal.BCur, deal.SCur);
        }

    }

    getCoverDeals = (bcur, scur) => {
        getCoverDealsByPair(bcur, scur).then((response) => {
            console.log(response);
            if (response.data != null) {
                const totalCount = response.data.length;
                const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                this.setState({ dealsByPair: this.transformList(response.data), totalPages, iconView: true })
            }
        })
    }

    updateGrid = (dealID) => {
        let deal = this.props.deal[0];
        getDealHeaderInfo(dealID).then(response => {
            if (response.dealHeaderInfo[0].CoveredDeals) {
                let coveredDeals = [];
                if (response.dealHeaderInfo[0].CoveredDeals.includes(',')) {
                    coveredDeals = response.dealHeaderInfo[0].CoveredDeals.split(',');
                } else {
                    coveredDeals.push(response.dealHeaderInfo[0].CoveredDeals);
                }
                this.getCoveredDeals(coveredDeals);
                this.getCoverDeals(deal.BCur, deal.SCur);
            }
        })
    }
    
    getCoveredDeals = async (coveredDeals) => {
        let covDealsHelper = [];
        for (let index = 0; index < coveredDeals.length; index++) {
            const element = coveredDeals[index];
            let response = await getDealHeaderInfo(element);
            let dealInfo = response.dealHeaderInfo[0];
            covDealsHelper.push(dealInfo);
        }

        this.setState({ coveredDeals: covDealsHelper });
    }

    getCoverDealsByPair = async () => {
        let json = {}
        let deal = this.props.deal[0];
        json = await getCoverDealsByPair(deal.BCur, deal.SCur)
        if (json != null && json.data != null) {
            return this.transformList(json.data);
        } else {
            return [];
        }
    }

    transformList = (list) => {
        var helper = [];
        if (list) {
            for (var i = 0; i < list.length; i++) {
                helper.push({
                    'Deal No': list[i].DealNo,
                    'Customer Name': list[i].CustomerName,
                    'Value Date': list[i].ValueDate,
                    'Buy Amount': list[i].BuyAmount,
                    'Buy Currency': list[i].BuyCurrency,
                    'Sell Amount': list[i].SellAmount,
                    'Sell Currency': list[i].SellCurrency,
                    'Status': list[i].Status,
                    'Customer Rate': list[i].dt[0].CustomerRate,
                    'Market Rate': list[i].dt[0].MarketRate,
                });
            }
        }
        return helper;
    }

    checkboxFunction = (obj, checked) => {
        var selectedRows = [];
        selectedRows = this.state.selectedRows;
        var sum = this.state.sumSellAmount;
        if (checked) {
            sum += obj['Sell Amount'];
            selectedRows.push(obj);
        }
        else {
            sum -= obj['Sell Amount'];
            selectedRows = selectedRows.filter(item => item["Deal No"] !== obj["Deal No"]);
        }
        this.setState({ selectedRows, sumSellAmount: sum, sellCurrency: obj['Sell Currency'] });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    CoverDealClick = () => {
        let deal = this.props.deal[0];
        var helper = []
        if (this.state.selectedRows.length > 0) {
            this.state.selectedRows.forEach(value => {
                helper.push(value['Deal No']);
            });
            var objFilter = {
                "CoverDealId": deal.DealHeaderID,
                "CreatedBy": localStorage.getItem('UserID'),
                "DealHeaderId": helper,
                "UpdatedBy": localStorage.getItem('UserID'),
            }
            if (parseInt(this.state.dealBuyAmount) >= this.state.sumSellAmount) {
                insertCoverDeal(objFilter).then((response) => {
                    switch (response.httpStatusCode) {
                        case 200:
                            if (response.result) {
                                this.setState({
                                    showDeleteAlert: false,
                                    showAlert: true,
                                    alertTitle: 'Success',
                                    alertMessage: 'Deals were linked successfully!'
                                }, ()=>this.updateGrid(deal.DealHeaderID));
                            }
                            break;

                        default:
                            break;
                    }
                });
            } else {
                this.setState({
                    showDeleteAlert: false,
                    showAlert: true,
                    alertTitle: 'Error',
                    alertMessage: 'Bank deal does not have enough balance (' + this.state.dealBuyAmount + ') to cover the selected deals (' + this.state.sumSellAmount + ' ' + this.state.sellCurrency + ').'
                });
            }
        } else {
            this.setState({
                showDeleteAlert: false,
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'You must select at least one deal!'
            });
        }
    }
    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    handleCoverDealClick = () => {
        this.setState({ showDeleteAlert: true, alertTitle: 'Confirm', alertMessage: 'Are you sure you want to cover selected customer deal(s) with the bank deal?' });
    }

    closeCoverDealAlert = () => {
        this.setState({ showDeleteAlert: false, alertMessage: '', alertTitle: '' });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }
    render = () => {
        let deal = this.props.deal[0];
        let buyAmount = `${formatToCurrencyWithScale(deal.BuyAmount, deal.BCur)} ${deal.BCur}`;
        let sellAmount = `${formatToCurrencyWithScale(deal.SellAmount, deal.SCur)} ${deal.SCur}`;
        let covDealsRender = [];

        if (this.state.coveredDeals.length > 0) {
            this.state.coveredDeals.forEach(element => {
                covDealsRender.push(<input className="uk-input" id="form-deal-covered-deals"
                    value={`${element.DealHeaderID} - ${element.ValueDate} - Buy Amount: ${formatToCurrencyWithScale(element.BuyAmount, element.BCur)} ${element.BCur} - Sell Amount: ${formatToCurrencyWithScale(element.SellAmount, element.SCur)} ${element.SCur}`}
                    type="text" disabled />);
            })
        }
        return (
            <div>
                <form className="uk-form-horizontal uk-grid">
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-id">Deal ID</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-id" value={deal.DealHeaderID} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-status">Status</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-status" value={deal.Status} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-customer">Customer Name</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-customer" value={deal.Name} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-type">Deal Type</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-type" value={deal.dealTypeName} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-issue-date">Issue Date</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-issue-date" value={deal.IssueDate} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-value-date">Value Date</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-value-date" value={deal.ValueDate} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-maturity-date">Maturity Date</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-maturity-date" value={deal.MaturityDate} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-customer-rate">Customer Rate</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-customer-rate" value={deal.CustomerRate} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-market-rate">Market Rate</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-market-rate" value={deal.MarketRate} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-buy-amount">Buy Amount</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-buy-amount" value={buyAmount} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-sell-amount">Sell Amount</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-deal-sell-amount" value={sellAmount} type="text" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1"><div className="uk-margin"><br /></div></div>
                    <div className="uk-width-1-1" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-deal-covered-deals">Covered Deals</label>
                            <div className="uk-form-controls">
                                {covDealsRender}
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleCoverDealClick} disabled={!this.state.iconView}>Cover Deal</button>
                        <hr />
                    </div>
                    <div className="uk-width-1-1" >
                        <NewDynamicTable
                            id="ndt-cover-deal"
                            data={this.state.dealsByPair}
                            newSearch={false}
                            hiddenHeaders={["dt"]}
                            enableClick={false}
                            useDeleteButton={false}
                            usePaginator={true}
                            changePage={this.changePage}
                            numberOfPages={this.state.totalPages}
                            pageNumber={this.state.pages}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            enableSortByHeader={this.state.iconView}
                            useFilter={this.state.iconView}
                            filterFunction={this.getCoverDealsByPair}
                            useExportButton={this.state.iconView}
                            useCheckBox={true}
                            checkboxFunction={this.checkboxFunction}
                            selectedRows={this.state.selectedRows}
                            tableName="cover_deal"
                        />
                    </div>
                </form>
                <AlertBox id="cover-deal" open={this.state.showDeleteAlert} onClose={this.closeCoverDealAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Yes/No" yesClick={this.CoverDealClick} noClick={this.closeCoverDealAlert} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}
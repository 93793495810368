import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericGetWithParametersNoEncrypted, genericCallWithBody, decrypt } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getSearchByEntity(EntityType, EntityId){
    try {
        let params = {
            'EntityType': EntityType,
            'EntityId': EntityId
        }
        let response = await genericGetWithParameters('GlobalWatchListSearch/GetSearchByEntity', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetResultsByWatchListsSearchId(Id){
    try {
        let params = {
            'Id': Id
        }
        let response = await genericGetWithParameters('GlobalWatchListSearch/GetResultsByWatchListsSearchId', params);
        
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const searchInGlobalWatchLists = async (searchText, entityType, entityID) => {
    try {
        const params = {
            'searchText': searchText,
            'entityType': entityType,
            'entityId': entityID,
            'performedBy': localStorage.getItem('UserID')
        };
        const response = await genericGetWithParametersNoEncrypted('GlobalWatchListSearch/OfacSearch', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetSearchResultsByEntityAndDateRange(model){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Watchlist/SearchResultsByEntityAndDateRange';
    let response = await genericCallWithBody(method, apiName, model);
    let response_decrypt = decrypt(response);
    return response_decrypt;
}

export async function SearchEntities(customerID,entityType,fomDate,toDate){
    const model = {
        CustomerID: customerID,
        EntityType: entityType,
        FromDate: fomDate,
        ToDate: toDate
    };
    var method = 'POST';
    var apiName = 'Watchlist/SearchEntities';
    let response = await genericCallWithBody(method, apiName, model);
    let response_decrypt = decrypt(response);
    return response_decrypt;
}

export async function GetSearchResultsByScanID(watchscanid){
    const model = {
        watchscanid: watchscanid
    };
    var method = 'POST';
    var apiName = 'Watchlist/GetSearchResultsByScanID';
    let response = await genericCallWithBody(method, apiName, model);
    let response_decrypt = decrypt(response);
    return response_decrypt;
}
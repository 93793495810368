import React from 'react';
import {getStyle} from '../../styles/styles';
var style = getStyle();

class Row extends React.Component {
   
    handleRowClick(){
        var objClicked = this.props.objData;
        this.props.onClick(objClicked);
    }
    
    render(){ 
        var obj = this.props.objData;
        var headers = this.props.objHeaders;
        var enableClick = this.props.enableClick;
        //var keys = Object.keys(obj);
        var cells = [];

        for (var i=0; i<headers.length;i++) {
            cells.push(<td key={i}>{obj[headers[i].KeyName]}</td>);
        }

        if(enableClick) {
            return (
                <tr onClick={this.handleRowClick.bind(this)}>
                    {cells}
                </tr>
            );
        } else {
            return (
                <tr>
                    {cells}
                </tr>
            );
        }
    }
}

//HOW TO USE:
//Need two arrays. headers Array must be an array of strings.
//data Array must an array of objects. The number of headers must be equal to number of keys.
//If enableClick is false, you dont need to add a clickFunction.
//The function to use will be -> functionName(obj){};
//The obj will be the object of data array.
//headers=[{Header:"Header 1", KeyName: "Header1"}]
//<DynamicTable headers={['Header 1', 'Header 2']} data={[{header1: test1, header2: test2},{header1: test3, header2: test4}]} enableClick={true} clickFunction={this.handleRowClick.bind(this)} />

export default class DynamicTable extends React.Component {
    
    handleRowClick(obj) {
        this.props.clickFunction(obj);
    }
    
    render() {
        var headers = [];
        if (this.props.headers !== null &&
            this.props.data !== undefined) {
            for (var i=0; i<this.props.headers.length; i++) {
                headers.push(<th key={this.props.headers[i].KeyName}>{this.props.headers[i].Header}</th>);
            }
        }

        var rows = [];
        if (this.props.data !== null &&
            this.props.data !== undefined) {
            var enableClick = this.props.enableClick;
            for (var i=0; i<this.props.data.length; i++) {
                if (enableClick) {
                    rows.push(<Row key={i*10} objHeaders={this.props.headers} objData={this.props.data[i]} onClick={this.handleRowClick.bind(this)} enableClick={true}></Row>);
                } else {
                    rows.push(<Row key={i*10} objHeaders={this.props.headers} objData={this.props.data[i]} enableClick={false}></Row>)
                }
                
            }
        }

        if (rows.length === 0)
        {
            return(<h5 style={style.alignCenter}>No records found.</h5>);
        }

        return(
            <div className="uk-overflow-auto">
                <table className="uk-table uk-table-hover uk-table-divider">
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}
export const Customer = 'C';
export const User = 'U';
export const Beneficiary = 'B';
export const CustomerContact = 'k';
export const Trade = 'T';
export const Owner = 'W';

export const CustomerType = 'Customer';
export const CustomerContactType = 'Contact';
export const BeneficiaryType = 'Beneficiary';
export const CountryType = 'Country';
export const UserType = 'User';
export const TraderType = 'Trader';
export const OwnerType = 'Owner';

export const AddressType = 'Address';
export const BankAccountType = 'BankAccount';
export const BankBranchType = 'BankBranch';
export const BankType = 'Bank';
export const BeneficiaryBankAccountType = 'Beneficiary Bank Account';
export const ContactBankAccountType = 'Contact Bank Account';
export const CustomerBankAccountType = 'Customer Bank Account';
export const DealType = 'Deal';
export const FxcmTransactionType = 'FxcmTransaction';
export const OnlineApplicationFormURL = 'Online Application Form URL';
export const OutgoingFundType = 'OutgoingFund';
export const PaymentType = 'Payment';
export const WithdrawalRequestType = 'Withdrawal Request';
export const PaymentOrder = 'Payment Order';
export const PaymentOrderDetailType = 'Payment Order Detail';
export const NostroAccount = 'Nostro Account';
export const EntityProperty = 'EntityProperties';
export const EntityTask = 'EntityTasks';

export const getSubtypeFromEntityType = (entityType, customerType = null) => {
    switch (entityType) {
        case CustomerType: return `${entityType} - ${customerType}`;
        case CustomerContactType: return `${entityType} - ${customerType}`;
        default: return '';
    }
}
import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle} from '../../../styles/styles';
import {BoardName} from '../../../constants/BoardNames';

var style = getStyle();

export default class TransactionMonitoringBoardScreen extends React.Component {

    state = {
        showTitle: true
    }
    componentDidMount()
    {
        
    }
  
    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }
    render(){
        console.log('TransactionMonitoringBoardScreen render')

        return(
            <div>
               {this.state.showTitle&& <h3 className="uk-heading-divider" style={style.titleStyle}>Transaction Monitoring Boards</h3>}
                <GenericBoard board={BoardName.TransactionMonitoringBoard} mainTitle={"Transaction Monitoring Boards"} isShowTitle={this.isShowTitle} selectSection/>
            </div>
        );
    }
}
import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';
import {formatToCurrencyWithScale} from '../../../helpers/FormatHelper';
import {getDrawdownsByForward} from '../../../networking/NetworkingDeals'
export default class WizardDrawdownsView extends React.Component {
    state = {
        drawdowns: [],
    }

    componentDidMount(){
        getDrawdownsByForward(this.props.forwardID, 0, 0).then(
            (json) => {
                var helper = [];
                if(json.deals !== undefined){
                    helper = json.deals;
                }
                this.setState({drawdowns:helper});
            }
        )
    }

    render(){
        return(
            <div className="uk-form-stacked">
                <h4>
                    <i onClick={this.props.handleCloseDrawdownsClick} className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    &nbsp;Drawdowns
                </h4>
                <NewDynamicTable data={this.state.drawdowns} 
                        enableClick={true} 
                        clickFunction={this.props.handleDealClick}
                        newSearch={true}
                        useDeleteButton={false}
                        formats={[{header:'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency'},
                            {header:'Sell Amount', formatFunction:formatToCurrencyWithScale, formatCurrency: 'Sell Currency'}]}/>
            </div>
        );
    }
}
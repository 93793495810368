import { ResponsivePhaseDescription } from 'igniteui-react-core';
import React from 'react';
import AppContext from '../../AppContext';
import { generateTokenCodeSecurity, sendForgotPasswordEmail, validateTokenCodeSecurity } from '../../networking/NetworkingUsers';
import AlertBox from '../shared/AlertBox';


let globalFunctions = {};


export default class AdditionalValidation extends React.Component {
    static contextType = AppContext;

    state = {
        smsSent: false,
        token: '',
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        email: localStorage.getItem('UserEmail'),
        mobile: localStorage.getItem('MobileNumber'),
        hiddenEmail: '',
        hiddenMobile:''
    }

    componentDidMount = () => {
        globalFunctions = this.context;
        this.setState({hiddenEmail: this.hideEmail(), hiddenMobile: this.hideMobile()})
    }

    hideWord = (str, charsBegin, charsEnd) => {
      if(str.length > 0)
      {
      if(str.length > charsBegin+charsEnd)
      {
        let charRepeat = str.length-charsBegin-charsEnd;
        let charSlice = charsEnd*-1;
        return str.slice(0,charsBegin)+ "*".repeat(charRepeat) + str.slice(charSlice);
      }
      else
      {
        let charRepeat = str.length/2;
        let charSlice = (str.length-1)/2*-1;
        return str.slice(0,(str.length-1)/2)+ "*".repeat(charRepeat) + str.slice(charSlice);
      }
      }
      else
      return str;
   }
   
   hideMobile =  () =>{
    return this.hideWord(this.state.mobile,0,4);
}

   hideEmail =  () =>{
        return this.hideWord(this.state.email,4,6);
   }

    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '', forgotPassword: false, username: '', password: '' });
        globalFunctions.finishLoading()
    }

    onSendEmailToken = (e) =>{
        let request = {
            numberMobile: '',
            email: this.state.email
          }

          generateTokenCodeSecurity(request).then((response) => {
            if (response !== undefined) {
              if (response.ErrorMessage === null && response.ErrorMessage !== '') {
                this.setState({ smsSent: false, showAlert: true, alertMessage: 'Code sent.', alertTitle: 'Success' });
              }
              else {
                this.setState({ smsSent: false, showAlert: true, alertMessage: response.ErrorMessage, alertTitle: 'Error' });
              }
            }
            else {
              this.setState({ smsSent: false, showAlert: true, alertMessage: 'Please try again or contact the administrator.', alertTitle: 'Error' });
            }
      
          });
    }

    onSendSMSToken = (e) =>{
        let request = {
            numberMobile: this.state.mobile,
            email: ''
          }

          generateTokenCodeSecurity(request).then((response) => {
            if (response !== undefined) {
              if (response.ErrorMessage === null && response.ErrorMessage !== '' && response.Message === "OK") {
                this.setState({ smsSent: true, showAlert: true, alertMessage: 'Code sent.', alertTitle: 'Success' });
              }
              else {
                this.setState({ smsSent: true, showAlert: true, alertMessage: 'We cannot send you our validation code at this time. Please contact your supervisor or the system admin.', alertTitle: 'Error' });
              }
            }
            else {
              this.setState({ smsSent: true, showAlert: true, alertMessage: 'Please try again or contact the administrator.', alertTitle: 'Error' });
            }
      
          });
    }

    validateToken = (event) => {
        event.preventDefault();
        
        let request = {
            numberMobile: this.state.smsSent ? this.state.mobile : '',
            email: this.state.smsSent ? '' : this.state.email,
            token: this.state.token
          }
      
          validateTokenCodeSecurity(request).then((response) => {
            if (response !== undefined) {
              if (response.validation) {
                this.props.loginSuccess();
              }
              else {
                this.setState({ showAlert: true, alertMessage: response.msg, alertTitle: 'Error' });
              }
            }
            else {
              this.setState({ showAlert: true, alertMessage: 'Please try again or contact the administrator.', alertTitle: 'Error' });
            }
      
          })
    }

    onChangeToken = (e) => {
        this.setState({ token: e.target.value });
    }
    
    render() {

        return (
            <div>

                <div >
                    <div style={{ borderBottom: '1px solid #f0f0f0', marginTop: '10px', marginBottom: '10px' }}>
                        <div >
                            <p className="uk-form-label">Please select where do you want the verification code sent to.</p>
                        </div>
                        <div >
                            <div style={{ paddingLeft: '10px' }}>Email:                              
                                <a style={{ paddingLeft: '5px' }} onClick={this.onSendEmailToken}>{this.state.hiddenEmail}</a>
                            </div>
                            {this.state.mobile !== '' &&
                            <div style={{ paddingLeft: '10px' }}>Mobile:
                                <a style={{ paddingLeft: '5px' }} onClick={this.onSendSMSToken}>{this.state.hiddenMobile}</a>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col-xs-12">
                            <div className="row">
                                <span className="uk-form-label">Enter code we sent</span>
                            </div>
                            <div className="row">
                                <input className="uk-input" type="text" placeholder="Enter code" onChange={this.onChangeToken} value={this.state.token} />
                            </div>
                        </div>
                        <div className="uk-width-1-1 uk-margin">
                            <button type="button" className="uk-button uk-button-green" onClick={this.validateToken}>Validate</button>
                            <button type="button" className="uk-button uk-button-green" onClick={this.props.onClose}>Exit</button>
                        </div>
                    </div>
                </div>

                

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
            </div>
        );
    }
}
import { genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters } from './Networking';

export const getBusinessHours = async (page, items) => {
    try {
        const params = {
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('AfterHours/GetBusinessHours', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const updateBusinessHours = async (model) => {
    try {
        const response = await genericCallWithBody('PUT', 'AfterHours/UpdateBusinessHours', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAllAfterHoursPlan = async () => {
    try {
        const response = await genericGetWithParameters('AfterHours/GetAllAfterHoursPlan', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const savePlan = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'AfterHours/SavePlan', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deletePlan = async (planID) => {
    try {
        const params = {
            'PlanId': planID
        };
        const response = await genericDeleteWithParameters('AfterHours/DeletePlan', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getPlanDetailsByPlanID = async (planID, page, items) => {
    try {
        const params = {
            'planID': planID,
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('AfterHours/GetAfterHoursPaddingPlanDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getPlanDetailsById = async (planID, homeCurrency, foreignCurrency) => {
    try {
        const params = {
            'AfterHoursPlanID': planID,
            'HomeCurrency': homeCurrency,
            'ForeignCurrency': foreignCurrency
        }
        const response = await genericGetWithParameters('AfterHours/GetPlanDetailsById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const savePlanDetails = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'AfterHours/SavePlanDetails', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deletePlanDetails = async (planID, homeCurrency, foreignCurrency) => {
    try {
        const params = {
            'PlanId': planID,
            'HomeCurrency': homeCurrency,
            'ForeignCurrency': foreignCurrency
        };
        const response = await genericDeleteWithParameters('AfterHours/DeletePlanDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAfterHoursExceptionsByCustomerID = async (customerID, page, items) => {
    try {
        const params = {
            'CustID': customerID,
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('AfterHours/GetAfterHoursPaddingExceptions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveAfterHoursExceptions = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'AfterHours/SaveAfterHoursExceptions', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteAfterHoursExceptions = async (customerID, homeCurrency, foreignCurrency) => {
    try {
        const params = {
            'CustomerID': customerID,
            'HomeCurrency': homeCurrency,
            'ForeignCurrency': foreignCurrency
        };
        const response = await genericDeleteWithParameters('AfterHours/DeleteAfterHourExceptions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import { DataSourceSectionHeaderDisplayMode } from 'igniteui-react-core';
import { HeaderClickAction, IgrColumnGroupDescription, IgrDataGrid, IgrDataGridModule, IgrGridColumnOptionsModule, IgrTemplateHeader, IgrTextColumn } from 'igniteui-react-grids';
import React from 'react';
import {useJsonToCsv} from 'react-json-csv';
import 'react-widgets/dist/css/react-widgets.css';
import { getPLByRevalType, exportPAndL } from '../../networking/NetworkingPosition';
import { getStyle } from '../../styles/styles';
import DynamicSelect from '../shared/DynamicSelect';

var style = getStyle();
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class PandLPivotScreen extends React.Component {
    //public grid: IgrDataGrid; 
    constructor(props){
        super(props);
        this.HorizontalRightHeader = new IgrTemplateHeader({});
        this.HorizontalRightHeader.cellUpdating = (s, e) => this.onHorizontalHeaderUpdating(s, e, "right");
    }
    state = {
        blotters: [],
        branches: [],
        positionData: [],
        blottersValue: [],
        revaluationType: 'MTM',
        columns: [],
        firstLoad: true,
        timeTick: 5000,
    }

    componentDidMount = () => {
        if(window.REACT_APP_PROFIT_AND_LOSS_TICK !==undefined){
            this.setState({timeTick: window.REACT_APP_PROFIT_AND_LOSS_TICK});
        }
    }

    handleRefleshClick = (objValue) =>{
        let revaluationType = this.state.revaluationType;
        getPLByRevalType(revaluationType).then(response => {
            console.log(response);
            if (response.data) {
                this.defineColumns(response.data);
                this.setState({positionData: response.data});
                let groupCurrency = new IgrColumnGroupDescription();
                groupCurrency.field = "Currency";
                groupCurrency.displayName = "";
                this.grid.groupDescriptions.add(groupCurrency);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
                if(this.state.positionFunc){
                    clearInterval(this.state.positionFunc);
                }
                var posFunc = setInterval(this.callFxPosition, this.state.timeTick);
                this.setState({positionFunc: posFunc, firstLoad: false});
            }
        })
    }

    onRevalChange = (objValue) => {

        this.setState({revaluationType: objValue.value});

        getPLByRevalType(objValue.value).then(response => {
            console.log(response);
            if (response.data) {
                this.defineColumns(response.data);
                this.setState({positionData: response.data});
                let groupCurrency = new IgrColumnGroupDescription();
                groupCurrency.field = "Currency";
                groupCurrency.displayName = "";
                this.grid.groupDescriptions.add(groupCurrency);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
                if(this.state.positionFunc){
                    clearInterval(this.state.positionFunc);
                }
                var posFunc = setInterval(this.callFxPosition, this.state.timeTick);
                this.setState({positionFunc: posFunc, firstLoad: false});
            }
        })
    }

    componentWillUnmount(){
        clearInterval(this.state.positionFunc);
      }

    callFxPosition = () => {
        getPLByRevalType(this.state.revaluationType).then(response => {
            console.log(response);
            if (response.data) {
                let newData = response.data;
                //this.grid.flush();
                //this.defineColumns(response.data);
                
                //this.grid.actualDataSource.queueAutoRefresh();
                //this.setState({positionData: response.data});
                
                for (let i = 0; i < this.state.positionData.length; i++)
                {
                    let item = this.state.positionData[i];
                    this.grid.notifyRemoveItem(i, item);
                    //item = newData[i];
                    this.grid.notifyInsertItem(i, newData[i]);
                }
                //this.setState({positionData: response.data});
                this.grid.actualDataSource.queueAutoRefresh();
                this.grid.invalidateVisibleRows();
                
                
            }
        });
    }

    defineColumns = (data) => {
        let columnHelper = [];
        let keys = Object.keys(data[0]);
        console.log(keys);
        keys.forEach(element => {
            if (!element.includes('$') ) {
                if(element === 'Currency' || element === 'Description' || element === 'Total' || element === 'CurrencyID' ){
                    if(element === 'Currency'  ) {
                        //columnHelper.push({key: element, name: '', lock: true});
                    } else {
                        columnHelper.push({key: element, name: capitalize(element), lock: true});
                    } 
                } else {
                    columnHelper.push({key: element, name: capitalize(element), lock: false});
                }
            }
        });

        let columnDirectives = [];

        columnHelper.forEach(element => {
            //columnDirectives.push(<ColumnDirective key={element.key} field={element.key} width='200' textAlign="Left" headerText={element.name} />) 
            if (element.lock) {
                if(element.key === "Total"){
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="150" horizontalAlignment="right"/>);
                } else {
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="150"/>);
                }
                
            } else {
                columnDirectives.push(<IgrTextColumn key={element.key} field={element.key} headerText={element.name} header={this.HorizontalRightHeader} width="150" horizontalAlignment="right"/>);
            }
            
        });

        this.setState({columns: columnDirectives});
    }

    onGridRef = (grid) => {
        if (!grid) { return; }

        this.grid = grid;
        //this.grid.actualDataSource.isSectionExpandedDefault = true;
    }

    onHorizontalHeaderUpdating = (s, e, align) => {
        const content = e.content; //as HTMLDivElement;
        let label = null;
        if (content.childElementCount === 0) {
            content.style.lineHeight = "25px";
            label = document.createElement("div");
            label.style.background = "transparent";
            label.style.color = "rgb(24, 29, 31)";
            label.style.overflowWrap = "break-word"
            //label.style.fontSize = "13px";
            //label.style.fontFamily = "Verdana";
            label.style.verticalAlign = "center";
            label.style.textAlign = align;
            content.appendChild(label);
        } else {
            label = content.children[0] ; //as HTMLDivElement;
        }

        const info = e.cellInfo; // as IgrTemplateCellInfo;
        label.textContent = info.value;
    }

    exportPAndL = () => {
        let systemDate = localStorage.getItem('SystemDate');
        exportPAndL(this.state.revaluationType, systemDate).then((response) => {
            console.log(response);
            let data = response.data;
            let fields = {...data[0]};
            let keys = Object.keys(fields);
            keys.forEach(element => {
                fields[element] = element;
            });
            
            const {saveAsCsv} = useJsonToCsv();
            
            let today = new Date();
            let yearString = today.getFullYear().toString();
            let monthString = (today.getMonth()+1).toString().padStart(2, '0');
            let dayString = today.getDate().toString().padStart(2, '0');
            let hourString = today.getHours().toString().padStart(2, '0');
            let minuteString = today.getMinutes().toString().padStart(2, '0');
            let timeStamp = yearString + monthString + dayString + hourString + minuteString;
            let filename = 'PAndL_' + timeStamp;
            saveAsCsv({data, fields, filename});
        })
    }

    render = () => {
        let groupBy = ["Currency"];
        let enters = [];
        let lengthOfData = 0;
        let data = [];
        let columnData = this.state.columns;
        if(this.state.positionData.length === 0){
            enters.push(<div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>)
        } else {
            lengthOfData = this.state.positionData.length;
            data = this.state.positionData;
        }
        return(
            <div className="uk-margin">
                <h3 className="uk-heading-divider" style={style.titleStyle}>Profit & Loss</h3>
                <div>
                    <form className="uk-form-stacked uk-grid">                        
                        <div className="uk-width-4-12" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-bank-name">Revaluation Type</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect className="uk-select" getValue={this.onRevalChange} listHasPlaceholder={false} data={[{value: 'MTM', name: 'MTM'},{value: 'PNL', name: 'Profit & Loss'}]} id="form-bank-name" objValue={{value: this.state.revaluationType}} />                                    
                                </div>                                
                            </div>                            
                        </div>                       
                        <div className="uk-width-6-12" />  
                        <div className="uk-width-2-12">
                            <div className="uk-margin">
                                <div className="uk-form-controls" style={{
                                    paddingTop: 16,  
                                }}>
                                    <button className="uk-button uk-button-green uk-align-right" onClick={this.handleRefleshClick.bind(this)} type="button">Refresh</button>
                                    <button className="uk-button uk-button-green" type="button" onClick={this.exportPAndL}>Export</button>`
                                </div> 
                            </div>                            
                        </div>
                    </form>                    
                </div>
                <br/>
                
                {/*lengthOfData!==0*/ true && 
                <IgrDataGrid
                height="100%"
                width="100%"
                headerHeight="50"
                dataSource={this.state.positionData}
                ref={this.onGridRef}
                autoGenerateColumns="false"
                defaultColumnMinWidth="200"
                isColumnOptionsEnabled="false"
                headerClickAction={HeaderClickAction.None}
                selectionMode="SingleRow"
                cornerRadiusTopLeft="0"
                cornerRadiusTopRight="0"
                editMode="None"
                >
                    {columnData}
                </IgrDataGrid>
               }
            </div>
        );
    }
}
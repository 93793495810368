import React from 'react';
import { getSubtypeFromEntityType } from '../../../../constants/EntityTypeNames';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import { getAdditionalPropertiesDefsByTypeAndCountry } from '../../../../networking/NetworkingAdditionalProperties';
import { getMainAddressByPersonID } from '../../../../networking/NetworkingAddresses';
import { deleteEntityOnlineProperties, getEntityOnlineProperties, saveAdditionalPropertyInfo } from '../../../../networking/NetworkingEntityProperties';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import ExtendedOnlinePropertiesForm from '../extendedOnlineProperties/ExtendedOnlinePropertiesForm';

export default class ExtendedOnlinePropertiesScreen extends React.Component {
    state = {
        entityOnlineProperties: [],
        objExtendedForm:null,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        currentPage: 1,
        contactID: 0,
        totalPages: 0,
        newSearch: false,
        subType: '',

        showAlertError: false,
        errors: [],
        notFirstLoad: true,
        loading: false,
    }
    componentDidMount() {
        this.loadEntityOnlineProperties()
    }
    loadEntityOnlineProperties = async () => {
        this.setState({ loading: true });
        const mainAddress = await getMainAddressByPersonID(this.props.personID);
        if (mainAddress != null) {
            const countryID = mainAddress.CountryId;
            const subType = getSubtypeFromEntityType(this.props.entityType, this.props.customerType);
            const allExtended = await getAdditionalPropertiesDefsByTypeAndCountry(this.props.entityType, subType, countryID);
            const userExtended = await getEntityOnlineProperties(this.props.entityID, this.props.entityType);
            if (allExtended != null && allExtended.extended != null) {
                let _allExtended = allExtended.extended.map((ext) => ({ ...ext, value: '' }));
                const totalCount = _allExtended.length;
                const pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                console.log(allExtended)
                console.log(userExtended)
                if (userExtended != null && userExtended.result != null) {
                    if (userExtended.result.length > 0) {
                        const _formattedUExt = userExtended.result.map(uExt => {
                            let _uExt = { ...uExt, Mandatory: uExt.Mandatory ? 'True' : 'False' };
                            if (_uExt.DataType === 'Numeric' && _uExt.noOfDecimals >= 0) {
                                return ({ ..._uExt, value: parseFloat(_uExt.value).toFixed(_uExt.noOfDecimals) });
                            }
                            return _uExt;
                        });
                        // Add values from User Extended into All Extended Properties
                        _allExtended = _allExtended.map(ext => {
                            const _uExtProperty = _formattedUExt.find(uExt => ext.Description === uExt.Description && ext.EntityType === uExt.EntityType && ext.SubEntityType === uExt.SubEntityType);
                            if (_uExtProperty != null) {
                                return ({ ...ext, value: _uExtProperty.value });
                            }
                            return ext;
                        });
                        console.log(_allExtended)
                    }
                }
                this.setState({ entityOnlineProperties: _allExtended, subType: subType, totalPages: pages, newSearch: false })
            }
        }
        this.setState({ loading: false });
    }
    getAllEntityProperties = async () => {
        var json = await getEntityOnlineProperties(this.props.entityID, this.props.entityType);
        var helper = json.result;
        return helper;
    }
    handleRowClick(obj) {
        console.log(obj);   
        this.setState({ showExtendedForm: true, objExtendedForm: obj })
    }
    
    handleDeleteClick(obj) {
        console.group("Extended Online Properties handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteEntityProperties: obj })
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }
    closeAlertError = () => {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoad: true });
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteEntityProperties: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() {
        this.setState({ showDeleteAlert: false });
        deleteEntityOnlineProperties(this.state.readyToDeleteEntityProperties.EntityAdditionalPropertyID).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: json.result,
                        email: ''
                    });
                } else {
                    this.setState({
                        showSuccessDeleteAlert: true,
                        alertTitle: 'Success',
                        alertMessage: json.result
                    }, () => this.loadEntityOnlineProperties());
                }
            });
    }
    changePage(value) {
        this.setState({ currentPage: value })
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }
    toggleShowExtendedForm = () => {
        this.setState({ showExtendedForm: !this.state.showExtendedForm });
    }
    SaveClick = (obj, valid, errors) => {
        let model = {
            "EntityID": this.props.entityID,
            "EntityType": this.props.entityType,
            "Description": obj.Description,
            "value": obj.value,
            "userID": localStorage.getItem('UserID'),
            "SubType": this.state.subType
        }
        if (valid) {
            saveAdditionalPropertyInfo(model).then(
                (response) => {
                    switch (response.httpStatusCode) {
                        case 200:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: 'Extended Online Property saved successfully',
                                showExtendedForm: false
                            }, async () => await this.loadEntityOnlineProperties())
                            break;
                        case 409:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Current Extended Online Property has been updated by other user. Please refresh the information and try again.'
                            })
                            break
                        default:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Error saving Extended Online Property'
                            })
                            break;
                    }
                })
        } else {
            this.setState({
                showAlertError: true,
                alertTitle: 'Error',
                alertMessage: 'Please fix the following errors:',
                errors,
                notFirstLoad: false
            });
        }
    }
    render() {
        return (
            <div>
                {this.props.noTitle == null && <h3 className="uk-heading-divider component-title">Extended Online Properties</h3>}
            
                <NewDynamicTable key="extended-online-properties-screen"
                    hiddenHeaders={[
                            'EntityAdditionalPropertyID',
                            'rangeValidate',
                            'minDate',
                            'maxDate',
                            'minNumber',
                            'maxNumber',
                            'noOfDecimals',
                            'Page Number',
                            'Mandatory',
                            'DataType',
                            'AddPropertyDefID',
                            'EntityType',
                            'SubEntityType'
                        ]}
                    data={this.state.entityOnlineProperties}
                    enableClick={true}
                    clickFunction={this.handleRowClick.bind(this)}
                    newSearch={true}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick.bind(this)}
                    enableSortByHeader={true}
                    usePaginator={true}
                    changePage={this.changePage.bind(this)}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.currentPage}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    useFilter={true}
                    filterFunction={this.getAllEntityProperties}
                    useExportButton={true}
                    tableName="extended_online_properties" />
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Extended Online Property?"
                    message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />
                
                <LoadingBox loading={this.state.loading} />                
                <ExtendedOnlinePropertiesForm data={this.state.objExtendedForm} SaveClick={this.SaveClick} notFirstLoad={this.state.notFirstLoad} open={this.state.showExtendedForm} close={this.toggleShowExtendedForm.bind(this)} />
            </div>
        );
    }
}
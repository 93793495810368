import React from 'react';
import AppContext from '../../../AppContext';
import { formatDate } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { deleteAdditionalPropertyDef, getAdditionalPropertiesDefs, getAdditionalPropertiesDefsByID, saveAdditionalPropertyDef } from '../../../networking/NetworkingAdditionalProperties';
import { getCountries } from '../../../networking/NetworkingCountries';
import { getAllLookUpTableMin } from '../../../networking/NetworkingLookUpTables';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import TranslationMaintenanceDialog from '../../shared/Dialogs/TranslationMaintenanceDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';
import PickList from '../../shared/PickList'

var style = getStyle();
let globalFunctions = {};

export default class ExtendedPropertiesMaintenance extends React.Component {
    static contextType = AppContext;
    
    state = {
        addPropertyId: 0,
        properties: [],
        allProperties: [],
        description: '',
        instructions:'',
        dataType: '',
        displayOrder: 0,
        rangeValidate: false,
        mandatory: false,
        minNumber:0,
        maxNumber: 0,
        minDate:'',
        maxDate:'',
        groupBy: '',
        noOfDecimals: 0,
        pageNumber: 0,
        subEntityType:'',
        lookUpTableId: 0,
        listLookUpTables: [],
        "Data Type": [],
        "Sub Entity Types": [],
        lookUpTables: [
            "Data Type",
            "Sub Entity Types"
        ],
        updateToken: 0,
        //
        hideButton: false,
        showDateRange: false,
        showNumberRange: false,
        showLookUpTable: false,
        showNoOfDecimals: false,
        showBlank: true,
        hasEntitiesDisabled: '',
        disableSubEntityType: '',

        //For tables
        selectIDLeft: '',
        selectIDRight: '',
        listInitialHave: [],
        listHave: [],//originLists
        listWant: [],//originLists
        listMissing: [],

        //Translation
        showTranslationDialog:false,
        objTranslation: null,

        validations: {
            description: true,
            dataType: true,
            displayOrder: true,
            mandatory: true,
            pageNumber: true,
            minDate: true,
            maxDate: true,
            minNumber: true,
            maxNumber: true,
            subEntityType: true
        },

        alertMessage: '',
        showAlert: false,
        showAlertGlobal: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteProperty: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        loading: false
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteProperty: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert = () => {
        deleteAdditionalPropertyDef(this.state.addPropertyId, this.state.subEntityType).then(
            (deleteResponse) => {
                /*
                    hasEntities: 0
                    httpStatusCode: 200
                */
                if(deleteResponse !== undefined)
                {
                    if(deleteResponse.hasEntities > 0)
                    {
                        this.setState({showDeleteAlert: false, showAlertGlobal: true, alertTitle: 'Message', alertMessage: 'This Extended property cannot be deleted as it is linked to one or more entities in the system.'});
                        
                    }
                    else
                    {
                        this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });                    
                        this.handleUpdateGrid();
                        this.handleClearClick();
                    }
                }
            });
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount() {

        globalFunctions = this.context;
        globalFunctions.startLoading();

        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                            table[j].field1 = table[j].Field1;
                        }
                        if(this.state.lookUpTables[i] === "Sub Entity Types")
                        {
                            table = table.filter(item => item.field1 == '1');
                        }
                        this.setState({ [this.state.lookUpTables[i]]: table });
                    }
                }
            })

        getCountries(0,0).then(
            (json) =>
            {
                if(json !== undefined && json.countries !== undefined)
                {
                    var countries = [];
                    for (var c = 0; c < json.countries.length; c++) {
                        var country = {};
                        country.value = json.countries[c].Country;
                        country.name = json.countries[c]["Country Name"];
                        countries.push(country);
                    }

                    this.setState({
                        listInitialHave: countries,
                        listHave: countries,
                        listWant: []
                    });

                }
            }
        );
        getAllLookUpTableMin().then(
            (value) => {
                var table = value.LookUpTable;
                for (var j = 0; j < table.length; j++) {
                    table[j].value = table[j].LookUpTableId;
                    table[j].name = table[j].Description;
                }
                this.setState({ listLookUpTables: table });
            }
        )

        this.handleUpdateGrid();
    }

    getAllAdditionalPropertiesDefs = async () => {
        const json = await getAdditionalPropertiesDefs(this.state.subEntityType);
        return json.properties;
    };

    handleUpdateGrid = async () => {        
        const properties = await this.getAllAdditionalPropertiesDefs();
        if (properties !== undefined) {
            const totalCount = properties.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({allProperties: properties, totalPages, newSearch: true });
        }
        else
        {
            this.setState({ allProperties:[], 
                totalPages:0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleRowClick = (obj) => {
        var hasEntities = '';
        if(obj.EntityAdditionalProperties !== undefined)
        {
            if(obj.EntityAdditionalProperties > 0)
            {
                hasEntities = 'disabled';
            }
        }
            getAdditionalPropertiesDefsByID(obj["AddPropertyDefID"], this.state.subEntityType).then(
                    (value) => {
                        this.UpdateDataType(value.DataType);
                        this.UpdateRangeValidate(value.RangeValidate);
                        this.setState({
                            addPropertyId: obj["AddPropertyDefID"],
                            description: value.Description,
                            //subEntityType: value.SubEntityType,
                            dataType: value.DataType,
                            displayOrder: value.DisplayOrder,
                            pageNumber: value.PageNumber,
                            mandatory: value.Mandatory,
                            lookUpTableId: value.LookUpTableID,
                            listWant: value.Countries,
                            updateToken: value.UpdateToken,
                            rangeValidate: value.RangeValidate,
                            minNumber: value.MinNumber,
                            maxNumber: value.MaxNumber,
                            minDate: formatDate(value.MinDate),
                            maxDate: formatDate(value.MaxDate),
                            groupBy: value.GroupBy,
                            hasEntitiesDisabled: hasEntities,
                            disableSubEntityType: 'disabled',
                            instructions: value.Instructions
                        },
                            () => {
                                let Countries1 = value.Countries
                                var havelist = [...this.state.listInitialHave]
                                if (Countries1) {
                                    for (var i = havelist.length - 1; i >= 0; i--) {
                                        for (var j = 0; j < Countries1.length; j++) {
                                            if (Countries1[j] && (Countries1[j].CountryID === havelist[i].value)) {
                                                havelist.splice(i, 1);
                                                i = --i
                                            }
                                        }
                                    }
                                }
                                this.setState({ listHave: havelist });                                
                            });
                    }
                );
        
    }

    handleDeleteClick = (obj) => {
        if(obj.EntityAdditionalProperties === 0)
        {
            this.setState({ showDeleteAlert: true, addPropertyId: obj.AddPropertyDefID });
        }
        else
        {
            this.setState({ showAlertGlobal: true, alertTitle: 'Message', alertMessage: 'This Extended property cannot be deleted as it is linked to one or more entities in the system.'});
        }
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    handleSaveClick = (event) => {
        let userID = localStorage.getItem('UserID');
        var noOfDecimals = this.state.noOfDecimals;
        var rangeValidate = this.state.rangeValidate;
        if(this.state.dataType !== 'Numeric')
        {
            noOfDecimals = 0;
        }
        if(this.state.dataType === 'Text')
        {
            rangeValidate = false;
        }

        var model = {
            AddPropertyDefID: this.state.addPropertyId,
            Description: this.state.description,
            DataType: this.state.dataType,
            Mandatory: this.state.mandatory,
            LookUpTableID: this.state.lookUpTableId,
            RangeValidate: rangeValidate,
            MinDate: this.state.minDate,
            MaxDate: this.state.maxDate,
            MinNumber: this.state.minNumber,
            MaxNumber: this.state.maxNumber,
            NoOfDecimals: noOfDecimals,
            DisplayOrder: this.state.displayOrder,
            PageNumber: this.state.pageNumber,
            SubEntityType: this.state.subEntityType,
            AdditionalPropertyDefByCountry:  JSON.stringify(this.transformWantList(this.state.listWant)),
            GroupBy: this.state.groupBy,
            UpdatedBy: userID,
            UpdateToken: this.state.updateToken,
            Instructions: this.state.instructions
        }
        if (this.validateRequiredFields()) {
            this.setState({loading: true});
            saveAdditionalPropertyDef(model).then(
                (json) => {
                    if (json.httpStatusCode !== 200){
                        var alertMsg = 'An error occurred while trying to save the bank. Please try again.'
                        if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                            alertMsg = json.httpErrorMessage;
                        }
                        this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error'});
                    } else {
                        this.setState({updateToken: json.updateToken//, addPropertyId: json.addPropertyDefID
                            , showAlert: true, alertTitle: 'Success', alertMessage: 'Extended property successfully saved.'
                        });
                        this.handleUpdateGrid();
                        this.handleClearClick();
                    }
                    this.setState({loading: false});
            });
            
        }
    }

    transformWantList = list => {
        var helper = [];
        var i = 0;
        for (i; i < list.length; i++) {
            helper.push({
                'CountryID': list[i].value});
        }
        return helper;
    }

    validateRequiredFields() {
        var valid = true;
        var description = true;
        var dataType = true;
        var displayOrder = true;
        var pageNumber = true;
        var minNumber = true;
        var maxNumber = true;
        var minDate = true;
        var maxDate = true;
        var pageNumber = true;
        var subEntityType = true;
        var listItems = []

        if (this.state.subEntityType === undefined ||
            this.state.subEntityType === "") {
            valid = false;
            subEntityType = false;
            listItems.push("Sub Entity Type");
        }

        if (this.state.description === undefined ||
            this.state.description === "") {
            valid = false;
            description = false;
            listItems.push("Description")
        }

        if (this.state.dataType === "select" ||
            this.state.dataType === '' ||
            this.state.dataType === undefined) {
            valid = false;
            dataType = false;
            listItems.push("Data Type")
        }
        else
        {
            if(this.state.dataType === "Numeric" && this.state.rangeValidate)
            {
                if(this.state.minNumber === undefined || this.state.minNumber === "")
                {
                    valid = false;
                    minNumber = false;
                    listItems.push("Lower limit");
                }
                if(this.state.maxNumber === undefined || this.state.maxNumber === "")
                {
                    valid = false;
                    maxNumber = false;
                    listItems.push("Upper limit");
                }
                if(this.state.minNumber >=  this.state.maxNumber)
                {
                    valid = false;
                    minNumber = false;
                    maxNumber = false;
                    listItems.push("Upper limit");
                    listItems.push("Lower limit");
                }
            }
            if(this.state.dataType === "DateTime" && this.state.rangeValidate)
            {
                if(this.state.minDate === undefined || this.state.minDate === "")
                {
                    valid = false;
                    minDate = false;
                    listItems.push("Lower limit");
                }
                if(this.state.maxDate === undefined || this.state.maxDate === "")
                {
                    valid = false;
                    maxDate = false;
                    listItems.push("Upper limit");
                }
                if(this.state.minDate >=  this.state.maxDate)
                {
                    valid = false;
                    minDate = false;
                    maxDate = false;
                    listItems.push("Upper limit");
                    listItems.push("Lower limit");
                }
            }
        }

        if (this.state.displayOrder === undefined ||
            this.state.displayOrder === "") {
            valid = false;
            displayOrder = false;
            listItems.push("Display Order")
        }

        if (this.state.pageNumber === undefined ||
            this.state.pageNumber === "") {
            valid = false;
            pageNumber = false;
            listItems.push("Page Number")
        }

        this.setState({
            validations: {
                ...this.state.validations,
                description: description,
                dataType: dataType,
                displayOrder: displayOrder,
                pageNumber: pageNumber,
                minDate: minDate,
                maxDate: maxDate,
                minNumber: minNumber,
                maxNumber: maxNumber,
                subEntityType: subEntityType
            },
            listMissing: listItems
        });

        if (!valid) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    validateRequiredInputs() {
        if (this.state.description === '') {
            return false;
        }
        if (this.state.dataType === undefined) {
            return false;
        }
        if (this.state.displayOrder === '') {
            return false;
        }
        return true;
    }

    handleClearClick = () => {
        this.setState({
            addPropertyId: 0,
            description: '',
            instructions:'',
            dataType: '',
            displayOrder: 0,
            rangeValidate: false,
            mandatory: false,
            groupBy: '',
            lookUpTableId: 0,
            updateToken: 0,
            selectIDLeft: '',
            selectIDRight: '',
            pageNumber: 0,
            listHave: this.state.listInitialHave,
            listWant: [],
            disableSubEntityType:''
        }, () => {
            this.UpdateDataType('');
            this.UpdateRangeValidate(false);
        }
        );
    }

    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
            this.setState({ selectIDLeft });
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
            this.setState({ selectIDRight });
        }
    };

    handleAddClick(event) {
        event.preventDefault();
        var listLeft = [...this.state.listHave];
        var listRight = [...this.state.listWant];
        var idSelected = this.state.selectIDLeft;
        var add = true
        if (idSelected !== '') {
            if (listRight.length === 0) {
                const newListLeft = listLeft.filter(c => c.value !== idSelected);
                const newListRight = [...listRight, ...listLeft.filter(c => c.value === idSelected)]
                    .sort((a, b) => a.name.localeCompare(b.name));
                this.setState({
                    listWant: newListRight,
                    listHave: newListLeft,
                    selectIDLeft: ''
                });
            } else {
                listRight.forEach(rightRole => {
                    if (rightRole.value === idSelected) {
                        add = false
                    }
                });
                if (add) {
                    const newListLeft = listLeft.filter(c => c.value !== idSelected);
                    const newListRight = [...listRight, ...listLeft.filter(c => c.value === idSelected)]
                        .sort((a, b) => a.name.localeCompare(b.name));
                    this.setState({
                        listWant: newListRight,
                        listHave: newListLeft,
                        selectIDLeft: ''
                    });
                }
            }
        }
    }

    handleRemoveClick(event) {
        event.preventDefault();
        var listLeft = [...this.state.listHave];
        var listRight = [...this.state.listWant];
        var idSelected = this.state.selectIDRight;

        if (idSelected !== '') {
            const newListRight = listRight.filter(c => c.value !== idSelected);
            const newListLeft = [...listLeft, ...listRight.filter(c => c.value === idSelected)]
                .sort((a, b) => a.name.localeCompare(b.name));
            this.setState({
                listWant: newListRight,
                listHave: newListLeft,
                selectIDLeft: ''
            });
        }
    }

    handleUpdateDescription = (value) => {
        this.setState({ description: value.target.value, validations: { ...this.state.validations, description: true } });
    }

    handleUpdateInstructions = (value) => {
        this.setState({ instructions: value.target.value });
    }

    handleUpdateDataType = (value) => {
        this.UpdateDataType(value.value);
    }

    UpdateDataType = (dataType) =>
    {
        var lookUpTableId = this.state.lookUpTableId;
        var showNoOfDecimals = false;
        var showLookUpTable = true;
        var showBlank = false;

        if(dataType != 'Text')
        {
            lookUpTableId = 0;
            showLookUpTable = false;
            
            if(dataType === 'Numeric')
            {
                showNoOfDecimals = true;
            }
            else
            {
                showBlank = true;
            }
        }

        this.setState({ dataType: dataType, 
            lookUpTableId: lookUpTableId,
            rangeValidate: false,
            showDateRange: false,
            showNumberRange: false,
            minDate: '',
            minNumber: 0,
            maxDate: '',
            maxNumber: 0,
            showLookUpTable: showLookUpTable,
            showNoOfDecimals: showNoOfDecimals,
            showBlank: showBlank,
            validations: { ...this.state.validations, dataType: true } 
        });
    }

    handleUpdateDisplayOrder = (value) =>  {
        this.setState({ displayOrder: value.target.value, validations: { ...this.state.validations, displayOrder: true } });
    }
    
    handleUpdateSubEntityType = (value) =>  {
        if (value != undefined && value !== "select") {
        this.setState({ subEntityType: value.value, validations: { ...this.state.validations, subEntityType: true } }
            , () => this.handleUpdateGrid());
        }
    }

    handleUpdatePageNumber = (value) =>  {
        this.setState({ pageNumber: value.target.value, validations: { ...this.state.validations, pageNumber: true } });
    }

    handleUpdateMinNumber = (value) =>  {
        this.setState({ minNumber: value.target.value, validations: { ...this.state.validations, minNumber: true } });
    }

    handleUpdateMaxNumber = (value) =>  {
        this.setState({ maxNumber: value.target.value, validations: { ...this.state.validations, maxNumber: true } });
    }

    handleOnCheckRangeValidate = (event)  =>  {
        this.UpdateRangeValidate(event.target.checked);
    }

    UpdateRangeValidate = (checked) => {
        var showNumberRange = false;
        var showDateRange = false;
        if(checked)
        {
            if(this.state.dataType == 'Numeric')
            {
                showNumberRange = true;
            }

            if(this.state.dataType == 'DateTime')
            {
                showDateRange = true;
            }
        }
        this.setState({ rangeValidate: checked, 
            showDateRange: showDateRange,
            showNumberRange: showNumberRange,
            validations: { ...this.state.validations, rangeValidate: true } });
    }

    handleOnCheckMandatory = (event) =>  {
        this.setState({ mandatory: event.target.checked, validations: { ...this.state.validations, mandatory: true } });
    }

    handleUpdateLookUpTable = (value) => {
        if(value != undefined)
        {
            this.setState({ lookUpTableId: value.LookUpTableId });
        }
    }

    handleUpdateMinDate = (date) =>
    {
        this.setState({minDate: formatDate(date), validations: { ...this.state.validations, minDate: true }});
    }

    handleUpdateMaxDate = (date) =>
    {
        this.setState({maxDate: formatDate(date), validations: { ...this.state.validations, maxDate: true }});
    }

    handleUpdateGroupBy = (event) => {
        this.setState({ groupBy: event.target.value });
    };

    handleHideButtonClick = () => {
        if (this.state.hideButton) {
            this.setState({ hideButton: false })
        } else {
            this.setState({ hideButton: true })
        }
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    closeAlertGlobal = () => {
        this.setState({ showAlertGlobal: false, alertMessage: '', alertTitle: '' });
    }

    handleOpenTranslation = (obj) => {
        console.log("handleOpenTranslation: " + JSON.stringify(obj))
        this.setState({
            showTranslationDialog   : true,
            objTranslation          : obj
        });
   }

   closeTranslationAlert = () =>
   {
       this.setState({
            showTranslationDialog   : false,
            objTranslation          : null
       })
   }

    updateSelectedCountries = (selectedCountries) => {
        this.setState({ listWant: selectedCountries })
    }

    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass;
        var listLeft = this.state.listHave;
        var listRight = [];
        if (this.state.listWant === null || this.state.listWant === undefined) {
            this.setState({ listWant: [] }, () => listRight = this.state.listWant)
        } else {
            listRight = this.state.listWant
        }

        var LowerField = '';
        var UpperField = '';

        var noOfDecimals = '';
        if(this.state.showNoOfDecimals)
        {
            noOfDecimals= <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>N° of decimals</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput className={classNameInput} type="Integer"  id="noOfDecimals"  placeholder="" value={this.state.noOfDecimals} onChange={this.handleUpdateNoOfDecimals} disabled={this.state.hasEntitiesDisabled} />
                            </div>
                        </div>
        }
        var lookUpTable = '';
        if(this.state.showLookUpTable)
        {
            lookUpTable = <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Look up Table</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect id="lookuptable-list" className={classNameSelect} getValue={this.handleUpdateLookUpTable} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.listLookUpTables} objValue={{ value: this.state.lookUpTableId}} disabled={true}/>
                            </div>
                        </div>
        }

        var blank = '';
        if(this.state.showBlank)
        {
            blank = <div className="uk-width-1-2 uk-form-stacked">
                        </div>
        }

        if(this.state.showNumberRange)
        {
            LowerField = <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Lower Limit</label>
                                <div className=""style={style.inputStyle}>
                                    <NumberInput className={this.formatValidInput(this.state.validations.minNumber)} id="minNumber" type="Rate" scale={this.state.noOfDecimals} placeholder="" value={this.state.minNumber} onChange={this.handleUpdateMinNumber} disabled={this.state.hasEntitiesDisabled} />
                                </div>
                            </div> 
            UpperField = <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Upper Limit</label>
                                <div className=""style={style.inputStyle}>
                                    <NumberInput className={this.formatValidInput(this.state.validations.maxNumber)} id="maxNumber" type="Rate" scale={this.state.noOfDecimals} placeholder="" value={this.state.maxNumber} onChange={this.handleUpdateMaxNumber} disabled={this.state.hasEntitiesDisabled}/>
                                </div>
                            </div>
        }

        if(this.state.showDateRange)
        {
            LowerField = <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Lower Limit</label>
                                <div className=""style={style.inputStyle}>
                                    <DatePicker className={this.formatValidInput(this.state.validations.minDate)} id="min-date" value={this.state.minDate} onDayChange={this.handleUpdateMinDate} disabled={this.state.hasEntitiesDisabled}/>
                                </div>
                            </div> 
            UpperField = <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Upper Limit</label>
                                <div className=""style={style.inputStyle}>
                                    <DatePicker className={this.formatValidInput(this.state.validations.maxDate)} id="max-date" value={this.state.maxDate} onDayChange={this.handleUpdateMaxDate} disabled={this.state.hasEntitiesDisabled}/>
                                </div>
                            </div>
          
        }

        return (
            <div>
                <div className="uk-width-1">
                    <div>
                        <h3 className="uk-heading-divider" style={style.titleStyle}>Extended Properties</h3>
                    </div>
                </div>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Sub Entity Type (*)</label>
                        <div className=""style={style.inputStyle}>
                            <DynamicSelect id="data-type" placeholder={"Select an Item..."} className={this.formatValidSelect(this.state.validations.subEntityType)} getValue={this.handleUpdateSubEntityType} listHasPlaceholder={true} data={this.state["Sub Entity Types"]} objValue={{ value: this.state.subEntityType }} disabled={this.state.disableSubEntityType} />
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked">
                    </div>

                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Description (*)</label>
                        <div className=""style={style.inputStyle}>
                            <input disabled= {true} className={this.formatValidInput(this.state.validations.description)} id="description" type="text" placeholder="" value={this.state.description} onChange={this.handleUpdateDescription} />
                        </div>
                    </div>       
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Instructions </label>
                        <div className=""style={style.inputStyle}>
                            <input className={regularInputClass} maxLength={500} id="instructions" type="text" placeholder="" value={this.state.instructions} onChange={this.handleUpdateInstructions} />
                        </div>
                    </div>  
                   
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Display Order (*)</label>
                        <div className=""style={style.inputStyle}>
                            <NumberInput className={this.formatValidInput(this.state.validations.displayOrder)} type="Integer"  id="displayOrder"  placeholder="" value={this.state.displayOrder} onChange={this.handleUpdateDisplayOrder} />
                        </div>
                    </div>   
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Page Number</label>
                        <div className=""style={style.inputStyle}>
                            <NumberInput className={this.formatValidInput(this.state.validations.pageNumber)} id="pageNumber" type="Integer" placeholder="" value={this.state.pageNumber} onChange={this.handleUpdatePageNumber} />
                        </div>
                    </div>

                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Data Type (*)</label>
                        <div className=""style={style.inputStyle}>
                            <DynamicSelect id="data-type" className={this.formatValidSelect(this.state.validations.dataType)} getValue={this.handleUpdateDataType} listHasPlaceholder={true} data={this.state["Data Type"]} objValue={{ value: this.state.dataType }} disabled={true} />
                        </div>
                    </div>              
                    {lookUpTable}
                    {noOfDecimals}
                    {blank}
                    
                    <div className="uk-width-1-4 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.rangeValidate} value={this.state.rangeValidate} onChange={this.handleOnCheckRangeValidate} /> Range Validate</label>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.mandatory} value={this.state.mandatory} onChange={this.handleOnCheckMandatory} /> Mandatory</label>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked uk-margin">
                        <label className="uk-form-label">Group By</label>
                        <div className=""style={style.inputStyle}>
                            <input className="uk-input" id="group-by" type="text" placeholder="" value={this.state.groupBy} onChange={this.handleUpdateGroupBy} />
                        </div>
                    </div>
                     
                    {LowerField}
                    {UpperField}
                </div>
                {/* //------Countries----------- */}
                {/* Remove after PickList is approved */}
                {/* <ul id="accordion-online-access" uk-accordion="multiple: true">
                    <li className="">
                        <h5 className="uk-accordion-title">Available Countries</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="general-information">
                                <div className="uk-grid" style={{ marginTop: "0px" }}>
                                    <div className="uk-width-1-1" id="subtype-component">
                                        <div
                                            className="uk-margin"
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                border: '1px solid #9DA99E'
                                            }}
                                        >
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listLeft.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.value} onClick={this.roleChangeColorLeft(item.value)}
                                                                    style={
                                                                        this.state.selectIDLeft === item.value ?
                                                                            { backgroundColor: '#8bc34a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDLeft === item.value ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.name}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                className="uk-card-body uk-width-1-3"
                                                style={{
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    borderRight: '1px solid #9DA99E',
                                                    borderLeft: '1px solid #9DA99E',
                                                    height: 500
                                                }}
                                            >
                                                
                                                <div
                                                    style={{
                                                        justifyContent: 'center',
                                                        marginTop: '50%'
                                                    }}>
                                                    <button
                                                        className="uk-button uk-button-green uk-margin-right" 
                                                        onClick={this.handleAddClick.bind(this)}>
                                                        Add
                                                    </button>
                                                    <br></br>
                                                    <button
                                                    className="uk-button uk-button-green uk-margin-right uk-margin" 
                                                    onClick={this.handleRemoveClick.bind(this)}>
                                                    Remove
                                                </button>
                                              
                                               </div>
                                            </div>
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listRight.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.value} onClick={this.roleChangeColorRight(item.value)}
                                                                    style={
                                                                        this.state.selectIDRight === item.value ?
                                                                            { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDRight === item.value ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.name}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul> */}
                <PickList title="Available Countries" pickList={this.state.listInitialHave} picked={this.state.listWant}
                    updateSelectedItems={(updateSelectedCountries) => this.updateSelectedCountries(updateSelectedCountries)} />
                {/* //------Available Subtypes----------- */}
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" disabled={this.state.addPropertyId === 0} onClick={this.handleSaveClick} type="button">Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick} type="button">Clear Fields</button>
                    <hr/>
                </div>
                <div className="uk-width-1 uk-margin">
                    {/* <h3 className="uk-heading-divider" style={style.titleStyle}>Existing Properties</h3> */}
                    <NewDynamicTable
                        id="properties-table"
                        data={this.state.allProperties}
                        hiddenHeaders={['AddPropertyDefID','EntityAdditionalProperties1']} 
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        useFilter={true}
                        filterFunction={this.getAllAdditionalPropertiesDefs}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        dynamicActions={[{name: 'Open Translation', function: this.handleOpenTranslation, isIcon: true}]}
                    />
                    {/*<Paginator changePage={this.changePage} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                </div>
                <AlertBox id="delete-property" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Warning" message="Are you sure you want to delete this property? This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox id="confirmation-delete-property" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Success" message="Extended property successfully removed." type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing} />
                <AlertBox open={this.state.showAlertGlobal} onClose={this.closeAlertGlobal} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertGlobal}  />
                <LoadingBox loading={this.state.loading} />
                <TranslationMaintenanceDialog
                    open    = {this.state.showTranslationDialog}
                    data    = {this.state.objTranslation}
                    type    = {'Property'}
                    onclose = {this.closeTranslationAlert}
                />
            </div>
        );
    }

}
import {genericGetWithParameters, genericCallWithBody} from './Networking';


export async function getDepositsByCustomer(customerID, showZero, page, items){
    try {
        let params = {
            'CustomerID': customerID,
            'ShowZero': showZero,
            'Page': page,
            'Items': items
        }
        
        let response = await genericGetWithParameters('Deposit/GetDepositsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function saveDeposit(model){

    try{
        let response = await genericCallWithBody('POST','Deposit/SaveDeposit',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDepositByID(depositID){
    try {
        let params = {
            'DepositID': depositID
        }
        let response = await genericGetWithParameters('Deposit/GetDepositByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getIncomingFundsForDeposit(depositID, page, items){
    try {
        let params = {
            'DepositID': depositID,
            'Page': page,
            'Items': items
        }
        
        let response = await genericGetWithParameters('Deposit/GetIncomingFundsForDeposit',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidDeposit(model) {
    var method = 'POST';
    var apiName = 'Deposit/VoidDeposit'
    let response = await genericCallWithBody(method, apiName, model);
    console.log(response);
    return response;
}

export async function getDepositApplicationList(depositID, page, items){
    try {
        let params = {
            'DepositID': depositID,
            'Page': page,
            'Items': items
        }
        
        let response = await genericGetWithParameters('Deposit/GetDepositApplicationList',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDepositsByForward(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
        }
        
        let response = await genericGetWithParameters('Deposit/GetDepositsByForward', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
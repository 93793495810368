import React, { useContext, useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography, DatePicker } from 'antd';
import { getStyle } from "../../../styles/styles";
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';
import { SearchOutlined } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import { deleteActivity, getCaseActivityByCaseID, saveActivity } from '../../../networking/NetworkingTMx';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import CaseActivityAttachment from './CaseActivityAttachment';

const dateFormat = 'YYYY/MM/DD';
const style = getStyle();
const { Option } = Select;
const CaseActivity = (props) => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [caseActivityList, setCaseActivityList] = useState([]);
    const [reloadCaseActivityList, setReloadCaseActivityList] = useState(false);
    
    const [editingKey, setEditingKey] = useState('');
    const [currentPage, setCurrentPage] = useState();
    
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [openAttachmentList, setOpenAttachmentList] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    
    const [currentCaseActivityID, setCurrentCaseActivityID] = useState('');
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    const activityStatusList = [
        {label: 'Pending', value:'Pending'},
        {label: 'Finished', value:'Finished'},
        {label: 'Void', value:'Void'}
    ];

    const caseStatus = ['open','closed','reopened'];

    
    useEffect(() =>{
        const loadCaseActivityList = async () => {
            if(props.CaseID === '')
            {
                setCaseActivityList([])
            }
            else
            {
                context.current.startLoading();
                
                const json = await getCaseActivityByCaseID(props.CaseID);
                context.current.finishLoading();
                if(json !== null && json !== undefined && json.totalCount > 0){
                    setCaseActivityList(json.result);
                } else {
                    setCaseActivityList([])
                }
            }
        };
        loadCaseActivityList();
    },[reloadCaseActivityList, props.CaseID, props.reload]);

    const isEditing = (record) => record.ID === editingKey;

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        if (value != null) {
            setSearchText(value);
            setSearchedColumn(col);
            setSearchArray(...(searchArray[col] = value));
            setFilteringSomething(true);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            const newData = [...caseActivityList];
            const index = newData.findIndex((item) => record['ID'] === item['ID']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    json : {
                        ActivityID: currentCaseActivityID,
                        CaseID: props.CaseID,
                        ActivityDate: row['Activity Date'].format(dateFormat),
                        Action: row['Action'],
                        Comments: row['Comments'],
                        Status: row['Status'],
                        UserId: userID,
                        UpdateToken: currentUpdateToken
                    }
                }
                context.current.startLoading();
                const json = await saveActivity(model);

                context.current.finishLoading();
                if (json != null){
                    if (json.httpStatusCode !== 200){
                        console.log(json)
                        setShowAlert(true);
                        setAlertTitle('Error');
                        if (json.Message != null && json.Message !== '') {
                            setAlertMessage(json.Message);
                        } else {
                            setAlertMessage(json.httpErrorMessage);
                        }
                        setReloadCaseActivityList(!reloadCaseActivityList);
                        setEditingKey('');
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Activity successfully saved.');
                        setReloadCaseActivityList(!reloadCaseActivityList);
                        setEditingKey('');
                        setCurrentCaseActivityID(json.caseActivityID);
                        setCurrentUpdateToken(json.updateToken);
                        //onAddOrDeleteSpotPricing();
                    }
                }
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const deleteRecord = async (record) => {
        context.current.startLoading();

        const model = {
                json : {
                        ActivityID: record.ID
                    }
                }

        const json = await deleteActivity(model);
        context.current.finishLoading();
        if(json !== null){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setReloadCaseActivityList(!reloadCaseActivityList);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Activity successfully removed.');
                setReloadCaseActivityList(!reloadCaseActivityList);
            }
        }
    }

    const columns = [
        {
            title: 'Activity Date',
            dataIndex: 'Activity Date',
            width: '20%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Activity Date'].localeCompare(b['Activity Date']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            width: '20%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Action'].localeCompare(b['Action']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Action')
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            width: '20%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Status'].localeCompare(b['Status']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Status')
        },
        {
            title: 'Comments',
            dataIndex: 'Comments',
            editable: true,
            align: 'center'
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a href onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a href>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey || caseActivityList[0]['ID'] === ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            {caseStatus.indexOf(record.Status.toLowerCase())=== -1 &&
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a href disabled={editingKey || caseActivityList[0]['ID'] === ''}>Delete</a>
                            </Popconfirm>}
                            <Typography.Link disabled={editingKey || caseActivityList[0]['ID'] === ''} onClick={() => openAttachmentsPopup(record)}>
                                Attachments
                            </Typography.Link>
                        </Space>
                    </div>
                );
            }
        }
    ]

    const edit = (record) => {
        if (caseActivityList[0].ID === '') {
            let auxList= [...caseActivityList];
            auxList.shift();
            setCaseActivityList(auxList);
        }
        form.setFieldsValue({
            ...record,
            UpdateToken: record['$UpdateToken'],
            'Activity Date': moment(record['Activity Date'], dateFormat),
            Action: record['Action'],
            Comments: record['Comments'],
            Status: record['Status'],

        });
        setEditingKey(record.ID);
        setCurrentCaseActivityID(record.ID);
        setCurrentUpdateToken(record['$UpdateToken']);
    }

    const cancel = (page) => {
        setEditingKey('');
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (caseActivityList[0].ID === '') {
            let auxList = [...caseActivityList];
            auxList.shift();
            setCaseActivityList(auxList);
        }
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Activity Date':{
                type = 'date';
                break;
            }
            case 'Comments':{
                type = 'textarea';
                break;
            }
            case 'Status':{
                type = 'select';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const disabledDate = (current) => {
            let today = new Date();
        //today.setDate(today.getDate() + 1);
        return current > today; 
        };

        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber disabled={record && caseStatus.indexOf(record.Status.toLowerCase()) > -1} style={{ width: '100%', textAlignLast: 'center' }} precision={2} />;
                break;
            }
            case 'textarea':
            {
                inputNode = <TextArea />;
                break;
            }
            case 'date':
            {
                inputNode = (
                    <DatePicker 
                        format={dateFormat} 
                        disabled={record && caseStatus.indexOf(record.Status.toLowerCase()) > -1}  
                        disabledDate={disabledDate} />
                )
                break;
            }
            case 'select':
                {
                    inputNode = (
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an Item"
                            optionFilterProp="children"
                            disabled={record && caseStatus.indexOf(record.Status.toLowerCase()) > -1} 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {activityStatusList.map((item) => (
                                <Option 
                                    key={`status-options-${item.value}`}
                                    value={item.value}>
                                </Option>
                            ))}
                        </Select>
                    );
                    break;
                }
            default: {
                inputNode = <Input disabled={record && caseStatus.indexOf(record.Status.toLowerCase()) > -1} style={{ textAlign: 'center' }}/>;
                break;
            }
        }
        
        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

           
        return (
            <td {...restProps}>
              {editing ? (
                <Form.Item
                  name={dataIndex}
                  style={{
                    margin: 0,
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please input ${title}!`,
                    },
                  ]}
                  key = {record}
                >
                  {inputNode}
                </Form.Item>
              ) : (
                children
              )}
            </td>
          );
    }

    const addNewCaseActivity = () => {
        let actual = [...caseActivityList];
        if (actual[0] != null && actual[0]['ID'] === '') {
            return;
        } else {
            actual.unshift({
                'key': 'caseActivity-new',
                'ID':'',
                'Activity Date': '',
                'Action': '',
                'Status': '',
                'Comments': '',
                UpdateToken: '',
                isNew: true
            });
            setCaseActivityList(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Activity Date': '',
                'Action': '',
                'Status': '',
                'Comments': '',
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentCaseActivityID('');
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    };

    const openAttachmentsPopup = (record) =>{
        setCurrentCaseActivityID(record.ID);
        setOpenAttachmentList(true);    
    }

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a href>Previous</a>;
        }
        if (type === 'next') {
            return <a href>Next</a>;
        }
        return originalElement;
    }

    return (
        <div>
            
            <Form form={form} component={false}>
            <Table
                components={{ body: { cell: EditableCell } }} 
                dataSource={caseActivityList}
                columns={mergedColumns}
                size='small'
                pagination={{
                    onChange: cancel,
                    showQuickJumper: true,
                    itemRender: itemRender,
                    current: currentPage,
                    defaultCurrent: 1,
                    size: 'default'
                }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={addNewCaseActivity}
                        block
                        icon={<PlusOutlined />}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        disabled={!props.CaseID || editingKey !== '' || (caseActivityList[0] !== undefined && caseActivityList[0]['ID'] === '')}
                    > Add a case activity
                    </Button>
                )}
            />
                </Form>
                <CaseActivityAttachment open={openAttachmentList} activityID ={currentCaseActivityID} onClose={() =>{setOpenAttachmentList(false)}}/>
            <AlertBox id="alert-pricing" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    )
}

export default CaseActivity
import React, { useState} from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import { getPricingCategory, addSpot } from '../../../networking/NetworkingPricing';
import { getCurrencyList, searchCurrencies } from '../../../networking/NetworkingCurrencies';
import { deleteRateTreasuryPadding, getRateTreasuryPadding, saveTreasuryPadding } from '../../../networking/NetworkingRates';
import { notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass, getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import { formatValidSelect, formatValidInput, getScaleByCurrency, formatToCurrencyWithScale, roundAmountByCurrencyPair, getScaleForCurrencyPair } from '../../../helpers/FormatHelper';
import NumberInput from '../../shared/NumberInput';
import { validate } from '../../../helpers/ValidateHelper';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getParametersByName, saveRFQ } from '../../../networking/NetworkingGlobalSystemParameters';

import TreasuryPaddingTable from '../../maintenance/treasury_padding/TreasuryPaddingTable';

const style = getStyle();

export default class TreasuryPaddingMaintenanceScreen extends React.Component {

  state = {
    treasuryPaddingList: [],
    paddingId: 0,
    markUpFactor: 0,
    percentOrPoints: '',
    updateToken: 0,

    homeCurrency: '',
    homeCurrencyName: '',
    foreignCurrency: '',
    foreignCurrencyName: '',
    currencyList: [],
    foreignCurrencyList: [],
    readyToDeleteRate: {},
    showAlert: false,
    showDeleteAlert: false,
    alertTitle: '',
    alertMessage: '',
    errors: [],
    validations: {
      homeCurrency: true,
      foreignCurrency: true,
      markUpFactor: true,
      percentPoints: true
    },
    totalPages: 0,
    newSearch: true,
    pages: 1,
  }

  componentDidMount() {
    getRateTreasuryPadding(0, 0).then(
      (value) => {
        var totalCount = value.totalCount;
        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
        this.setState({ treasuryPaddingList: value.result, totalPages: pages, newSearch: false });
      }
    );

    getCurrencyList().then(
      (json) => {
        if (json != null && json.currencies != null) {
          const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
          this.setState({ currencyList: currencies });
        }
      }
    );
  }

  searchHomeCurrency(query) {
    searchCurrencies(query, 'active').then(
      (json) => {
        var helper = [];
        if (query !== '') {
          if (json !== undefined) {
            for (var i = 0; i < json.length; i++) {
              helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
            }
            if (json.length > 1) {
              this.setState({ currencyList: helper });
            } else {
              this.setState({ currencyList: helper, homeCurrencyName: helper[0].name });
            }
          } else {
            this.setState({ currencyList: helper });
          }
        }
      }
    );
  }

  searchForeignCurrency(query) {
    searchCurrencies(query, 'active').then(
      (json) => {
        var helper = [];
        if (query !== '') {
          if (json !== undefined) {
            for (var i = 0; i < json.length; i++) {
              helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
            }
            if (json.length > 1) {
              this.setState({ foreignCurrencyList: helper });
            } else {
              this.setState({ foreignCurrencyList: helper, foreignCurrencyName: helper[0].name });
            }
          } else {
            this.setState({ foreignCurrencyList: helper });
          }
        }
      }
    );
  }

  handleRowClick(obj) {
    console.group("Rate handleRowClick")
    console.log(obj);
    console.groupEnd();
    //this.searchHomeCurrency(obj["Home Currency"]);
    //this.searchForeignCurrency(obj["Foreign Currency"]);
    this.setState({ paddingId: obj["ID"], homeCurrency: obj["Home Currency"], foreignCurrency: obj["Foreign Currency"], markUpFactor: obj["Markup Factor"], percentOrPoints: obj["Percent or Points"], updateToken: obj["UpdateToken"] })
  }

  getHomeCurrencySelect(value) {
    if (value != null && value !== 'select' && value.value !== undefined) {
      this.setState({
        validations: { ...this.state.validations, homeCurrency: true },
        homeCurrency: value.value !== "select" ? value.value : "",
        homeCurrencyName: value.name != null ? value.name : ""
      });
    }
  }
  getForeignCurrencySelect(value) {
    if (value != null && value !== 'select' && value.value !== undefined) {
      this.setState({
        validations: { ...this.state.validations, foreignCurrency: true },
        foreignCurrency: value.value !== "select" ? value.value : "",
        foreignCurrencyName: value.name != null ? value.name : ""
      });
    }
  }

  handleClearClick = () => {
    this.setState({
      paddingId: 0,
      homeCurrency: '',
      homeCurrencyName: '',
      foreignCurrency: '',
      foreignCurrencyName: '',
      markUpFactor: 0,
      percentOrPoints: '',
      updateToken: 0,
      validations: {
        homeCurrency: true,
        foreignCurrency: true,
        markUpFactor: true,
        percentOrPoints: true
      }
    });
  }

  handleSaveClick(event) {
    event.preventDefault();
    let UserID = localStorage.getItem('UserID');
    var object = {
      PaddingID: this.state.paddingId,
      HomeCurrencyCode: this.state.homeCurrency,
      ForeignCurrencyCode: this.state.foreignCurrency,
      MarkupFactor: this.state.markUpFactor,
      PercentOrPoints: this.state.percentOrPoints,
      UserId: UserID,
      updateToken: this.state.updateToken
    }
    if (this.validateRequiredInputs()) {
      console.log(object);
      saveTreasuryPadding(object).then(
        (json) => {
          if (json.httpStatusCode !== 200) {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage });
          } else {
            this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Rate Successfully saved.', updateToken: json.updateToken });
            this.updateTable();
          }
        }
      );
    }
  }

  updateTable() {
    getRateTreasuryPadding(0, 0).then(
      (value) => {
        var totalCount = value.totalCount;
        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
        this.setState({ treasuryPaddingList: value.result, totalPages: pages, newSearch: false });
      }
    );
  }

  changePage = (value) => {
    this.setState({ pages: value });
  }

  //Alert --------------------------------------------------------------
  closeAlert() {
    this.setState({ 
      showAlert: false, 
      alertTitle: '',
      alertMessage: '',
      errors: [],
    });
  }

  yesDeleteAlert() 
  {
    /*
      Foreign Currency  : "ANY"
      Home Currency     : "CAD"
      ID                : 98
      Markup Factor     : 1.5
      Percent or Points : "POINTS"
      UpdateToken       : "637604919496100000"
    */
    let model = {
      "PaddingID"           : this.state.readyToDeleteRate['ID'],
      "UserId"              : localStorage.getItem('UserID'),
      "UpdateToken"         : this.state.readyToDeleteRate['UpdateToken']
    }

    deleteRateTreasuryPadding(model).then(
      (deleteRateResponse) => {
        /*
          ErrorMessage: null
          Message: "The Rates has been successfully delete."
          httpStatusCode: 200
        */
        let titleAux = 'Error';
        let messageAux = 'Something went wrong, Please try again later.';

        if (deleteRateResponse !== undefined)
        {
          if (deleteRateResponse.httpStatusCode === 200) 
          {
            titleAux = 'Success';
            messageAux = 'The Rates has been successfully delete.';
          } else {
            if(deleteRateResponse.Message !== undefined && deleteRateResponse.Message !== null && deleteRateResponse.Message !== '')
            {
              messageAux = deleteRateResponse.Message;
            }
          }
        }

        if (titleAux === 'Success') 
        {
          this.updateTable();
          /*let indexToDrop = null;
          let auxList = this.state.treasuryPaddingList;
          auxList.forEach((rate, index) => {
            if(rate.ID === this.state.readyToDeleteRate['ID'])
            {
              indexToDrop = index;
            }
          });

          if(indexToDrop !== null)
          {
            auxList.splice(indexToDrop,1);
            this.setState({treasuryPaddingList : auxList});
          }*/
        }

        this.setState({
          alertTitle      : titleAux,
          alertMessage    : messageAux,
          showAlert       : true,
          showDeleteAlert : false
        });
      }
    );
  }

  closeDeleteAlert() {
    this.setState({
      showDeleteAlert: false,
    });
  }
  // --------------------------------------------------------------------

  handleUpdateMarkUpFactor = (event) => {
    this.setState({
      validations: { ...this.state.validations, markUpFactor: true },
      markUpFactor: event.target.value
    });
  }
  handleUpdatePercentOrPoints(event) {
    this.setState({
      validations: { ...this.state.validations, percentOrPoints: true },
      percentOrPoints: event.target.value
    });
  }

  //Inputs Validations
  validateRequiredInputs() {
    const fields = [
      { displayName: 'Mark Up Factor', validateName: 'markUpFactor', value: this.state.markUpFactor, type: 'wholeNumber' },
      { displayName: 'Percent or Points', validateName: 'percentOrPoints', value: this.state.percentOrPoints, type: 'string' },
      { displayName: 'Home Currency', validateName: 'homeCurrency', value: this.state.homeCurrency, type: 'string' },
      { displayName: 'Foreign Currency', validateName: 'foreignCurrency', value: this.state.foreignCurrency, type: 'string' }
    ]

    const [valid, validations, errors] = validate(fields);

    this.setState({ validations: { ...this.state.validations, ...validations } });

    if (!valid) {
      this.setState({
        showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
      });
    }

    return valid;
  }

  handleDeleteClick(obj) {
    console.group("handleDeleteClick")
    console.log(obj);
    console.groupEnd();
    this.setState({ 
      showDeleteAlert   : true, 
      readyToDeleteRate : obj 
    });
  }

  getAllTreasuryPadding = async () => {
    var json = await getRateTreasuryPadding(0, 0);
    return json.result;
  }

  render() {
    return (
      <div>
        <form className="uk-form-stacked">
          <h3 className="uk-heading-divider" style={style.titleStyle}>Treasury Padding</h3>
          {/*<div className="uk-width-1 uk-grid">
            <div className="uk-width-1-4" style={{ marginTop: 10 }}>
              <label className="uk-form-label" htmlFor="form-home-ccy" style={style.boldLabel}>Home Currency (*)</label>
              <div className="uk-form-controls">
                <DynamicSelect className={formatValidSelect(this.state.validations.homeCurrency)} id="form-forward-hcurrency" getValue={this.getHomeCurrencySelect.bind(this)} placeholder="Select your item" data={this.state.currencyList} objValue={{ value: this.state.homeCurrency, name: this.state.homeCurrencyName }} />
              </div>
            </div>
            <div className="uk-width-1-4" style={{ marginTop: 10 }}>
              <label className="uk-form-label" htmlFor="form-foreign-ccy" style={style.boldLabel}>Foreign Currency (*)</label>
              <div className="uk-form-controls">
                <DynamicSelect className={formatValidSelect(this.state.validations.foreignCurrency)} id="form-forward-fcurrency" getValue={this.getForeignCurrencySelect.bind(this)} placeholder="Select your item" data={this.state.currencyList} objValue={{ value: this.state.foreignCurrency, name: this.state.foreignCurrencyName }} />
              </div>
            </div>
            <div className="uk-width-1-4" style={{ marginTop: 10 }}>
              <label className="uk-form-label" htmlFor="form-spot-afrom" style={style.boldLabel}>Mark Up Factor (*)</label>
              <div className="uk-form-controls">
                <NumberInput id="markup"
                  className={formatValidInput(this.state.validations.markUpFactor)}
                  type="Currency"
                  scale={getScaleForCurrencyPair(this.state.homeCurrency, this.state.foreignCurrency)}
                  value={this.state.markUpFactor}
                  onChange={this.handleUpdateMarkUpFactor} />
              </div>
            </div>
            <div className="uk-width-1-4" style={{ marginTop: 10 }}>
              <label className="uk-form-label" htmlFor="form-spot-afrom" style={style.boldLabel}>Percent or Points (*)</label>
              <div className="uk-controls" style={style.inputStyle}>
                <select className="uk-select" value={this.state.percentOrPoints} onChange={this.handleUpdatePercentOrPoints.bind(this)}>
                  <option value="" >Select an item...</option>
                  <option value="PERCENT">PERCENT</option>
                  <option value="POINTS">POINTS</option>
                </select>
              </div>
            </div>
    </div>*/}
        </form>
        {/*<div>
          <br></br>
          <button className="uk-button uk-button-green" onClick={this.handleClearClick}>Clear Fields</button>
          <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
        </div>
        <NewDynamicTable key="rates-screen"
          data={this.state.treasuryPaddingList}
          enableClick={true}
          formats={[{ header: 'Markup Factor', formatFunction: roundAmountByCurrencyPair, formatCurrencyRate: ['Home Currency', 'Foreign Currency'] }]}
          hiddenHeaders={['ID', 'UpdateToken']}
          clickFunction={this.handleRowClick.bind(this)}
          newSearch={this.state.newSearch}
          useDeleteButton={true}
          deleteFunction={this.handleDeleteClick.bind(this)}
          enableSortByHeader={true}
          usePaginator={true}
          changePage={this.changePage}
          numberOfPages={this.state.totalPages}
          pageNumber={this.state.pages}
          numberPerPage={getDefaultPagingNumberOfItems()}
          useFilter={true}
          filterFunction={this.getAllTreasuryPadding}
        />
        <AlertBox
          open={this.state.showAlert}
          onClose={this.closeAlert.bind(this)}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          messageBoldList={this.state.errors}
          type="Ok"
          okClick={this.closeAlert.bind(this)}
        />
        <AlertBox
          open={this.state.showDeleteAlert}
          onClose={this.closeDeleteAlert.bind(this)}
          title="Warning"
          message="Are you sure you want to delete this rate? This action can not be undone."
          type="Yes/No"
          yesClick={this.yesDeleteAlert.bind(this)}
          noClick={this.closeDeleteAlert.bind(this)}
  />*/}

        <div>
          <TreasuryPaddingTable />
        </div>
        
      </div>
    );
  }
}
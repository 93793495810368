
import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';


export async function GetGlobalSystemParameters(page, items){
    try{
        let params = {
            "NameLike":"",
            "Page":page,
            "Items" : items
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetList', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getParametersByName(parameterNames){
    try{
        let params = {
            "parameterNames":parameterNames,
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetParametersByName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getGUID(parameterName){
    try{
        let params = {
            "parameterName":parameterName,
        };

        let response = await genericGetWithParametersNoEncrypted('GlobalSystemParameter/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRFQ(objRFQ){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'GlobalSystemParameter/Post';
    let response = await genericCallWithBody(method, apiName, objRFQ);
    return response;
}

export async function updateParameter(objParameter){
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'GlobalSystemParameter/Put';
    let response = await genericCallWithBody(method, apiName, objParameter);
    return response;
}

export const updateParametersForUser = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'GlobalSystemParameter/UpdateParameters', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateIntegrations = async (objParameter) => {
    try
    {
        const response = await genericCallWithBody('POST',  'GlobalSystemParameter/UpdateIntegrations', objParameter);
        return response;
    } catch (error) {
        console.error(error);
    }
}
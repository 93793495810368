import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as mime from 'mime-types';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { deleteE2EAttachment, getAttachment, getAttachments } from '../../networking/NetworkingAttachments';
import { getStyle } from '../../styles/styles';
import NewDynamicTable from '../shared/NewDynamicTable';
import AlertBox from './AlertBox';
import AttachmentsForm from './AttachmentsForm';




var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

//props
//linkType
//linkID
export default class AttachmentsTable extends React.Component {
    
    state = {
        listAttachments         : [],
        openForm                : false,
        showDeleteAlert         : false,
        readyToDeleteAttachment : {},
        //view
        currentFileName         : '',
        currentFileURLList      : [],
        //case no
        showOpenAlert           : false,
        currentBlob             : null,
        currentExtension        : '',

        openAttachment          : false,
        fullWidthAttachment     : true,
        maxWidthAttachment      : 'md',
    }

    onCloseAttachment = () =>
    {
        this.setState({
            currentFileName     : '',
            currentFileURLList  : [],
            openAttachment      : false,
        })
    }

    deleteAttachmentClick(obj){
        this.setState({showDeleteAlert: true, readyToDeleteAttachment: obj});
    }

    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDeleteAttachment: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert(){
        deleteE2EAttachment(this.state.readyToDeleteAttachment.ID).then((value)=>{
            this.setState({showDeleteAlert: false,});
            this.loadAttachments();
            this.props.onNewAttachment();
            if(this.props.onDelete)
            {
                this.props.onDelete();
            }
        });
    }

    componentDidMount(){
        if(this.props.attachmentsDetails != null && this.props.linkID != null)
            {
                var attachments = JSON.parse(this.props.attachmentsDetails);
                var newAttachments = this.transformAttachmentsList(attachments);
                this.setState({listAttachments: newAttachments});
            }
            else
            {
                this.setState({listAttachments: []});
            }
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.linkID !== this.props.linkID && this.props.linkID && this.props.linkType
            && this.props.linkID != null){
            if(this.props.attachmentsDetails != null)
            {
                var attachments = JSON.parse(this.props.attachmentsDetails);
                var newAttachments = this.transformAttachmentsList(attachments);
                this.setState({listAttachments: newAttachments});
            }
            else
            {
                this.setState({listAttachments: []});
            }
        }

        if(prevProps.linkType !== this.props.linkType && this.props.linkID && this.props.linkType && this.props.linkID != null){
            if(this.props.attachmentsDetails != null)
            {
                var attachments = JSON.parse(this.props.attachmentsDetails);
                var newAttachments = this.transformAttachmentsList(attachments);
                this.setState({listAttachments: newAttachments});
            }
            else
            {
                this.setState({listAttachments: []});
            }
        }

        if(prevProps.forceLoad !== this.props.forceLoad && this.props.linkID != 0){
            this.loadAttachments();
        }
    }
    
    loadAttachments(){
        if(this.props.linkID !== undefined && this.props.linkID !== null && this.props.linkID != null){
            getAttachments(this.props.linkType, this.props.linkID).then(
                (json) => {
                    if (json.attachments !== undefined){
                        //console.group('Attachments');
                        //console.table(json.attachments);
                        var newAttachments = this.transformAttachmentsList(json.attachments);
                        this.setState({listAttachments: newAttachments});
                        //console.groupEnd();
                    } else {
                        this.setState({listAttachments: []});
                    }
                }
            );
        } else {
            this.setState({listAttachments: []});
        }
    }

    transformAttachmentsList(attachments){
        for(var i=0; i<attachments.length; i++){
            Object.defineProperty(attachments[i], "Uploaded On", Object.getOwnPropertyDescriptor(attachments[i], "UploadedOn"));
            delete attachments[i]["UploadedOn"];
        }
        return attachments;
    }

    handleAttachmentClick(obj){
        getAttachment(obj.ID).then((value)=>{
            //console.log(value);
            var byteCharacters = atob(value.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var bytes = new Uint8Array(byteNumbers);
            var blob = new Blob([bytes],{type: mime.lookup(obj.Filename)});
            /*var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = obj.Filename;
            link.click();*/

            var arrayName = obj.Filename.split(".")

            let fileList = [{
                uri     : window.URL.createObjectURL(blob),
                fileType: arrayName[arrayName.length-1].toLowerCase(),
                fileName: obj.Filename
            }]

            if(arrayName.length !== 0)
            {
                if( arrayName[arrayName.length-1] === 'xlsx' || arrayName[arrayName.length-1] === 'xls' ||
                    arrayName[arrayName.length-1] === 'docx' || arrayName[arrayName.length-1] === 'doc' ||
                    arrayName[arrayName.length-1] === 'docm')
                {
                    this.setState({
                        showOpenAlert   : true,
                        currentBlob     : blob,
                        currentFileName : obj.Filename,
                        currentExtension: arrayName[arrayName.length-1],
                    });
                }else
                {
                    this.setState({
                        currentFileName     : obj.Filename,
                        currentFileURLList  : fileList,
                        openAttachment      : true
                    });
                }
            }
        });
    }

    onAddAttachmentButtonClick(){
        this.setState({openForm: true});
    }

    onCloseForm(){
        this.setState({openForm: false});
        this.loadAttachments();
        this.props.onNewAttachment();
    }

    closeAlert = () =>
    {
        this.setState({
            showOpenAlert   : false,
            currentBlob     : null,
            currentExtension: '',
            currentFileName : ''
        })
    }

    yesDowloadFile = () =>
    {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.state.currentBlob);
        link.download = this.state.currentFileName;
        link.click();
    }

    onSaveAttachmentWithoutLink = (id, fileName, fileDescription) =>
    {
        var attachments = this.state.listAttachments;
        attachments.push({ID:id, FileName: fileName});

        this.setState({listAttachments: attachments});
        if(this.props.onSaveAttachmentWithoutLink)
        {
            this.props.onSaveAttachmentWithoutLink(id, fileName, fileDescription);
        }
    }

    render(){
        if(this.props.linkID !== undefined && this.props.linkID !== null || this.props.onSaveAttachmentWithoutLink != null){
            return(
                <div style={{paddingRight: '40px'}}>
                    <h6 className="uk-heading-divider">Attachments</h6>
                    <button className="uk-button uk-button-green" type="button" onClick={this.onAddAttachmentButtonClick.bind(this)}>Add Attachment</button>
                    <NewDynamicTable hiddenHeaders={['ID','UpdateToken']} data={this.state.listAttachments} enableClick={true} clickFunction={this.handleAttachmentClick.bind(this)}  newSearch={true} useDeleteButton={true} deleteFunction={this.deleteAttachmentClick.bind(this)}/>
                    <AttachmentsForm linkType={this.props.linkType} linkID={this.props.linkID} open={this.state.openForm} onClose={this.onCloseForm.bind(this)} onSaveAttachmentWithoutLink={this.onSaveAttachmentWithoutLink}/>
                    <Dialog 
                        open        = {this.state.openAttachment} 
                        onClose     = {this.onCloseAttachment}
                        fullWidth   = {this.state.fullWidthAttachment}
                        maxWidth    = {this.state.maxWidthAttachment}
                    >
                        <DialogTitle id="current-attachment-dialog-title" onClose={this.onCloseAttachment}>
                            {this.state.currentFileName}
                        </DialogTitle>
                        <DialogContent dividers style={{padding: 0}}>      
                           
                                {/*<iframe 
                                    src         = {this.state.currentFileURL}
                                    scrolling   = 'yes'
                                    style       = {{
                                        border      : 'none',
                                        width       : '100%',
                                        height      : '400px',
                                        textAlign   : 'center',
                                    }}
                                >
                                </iframe>*/}
                            <DocViewer
                                pluginRenderers = {DocViewerRenderers} 
                                documents       = {this.state.currentFileURLList}
                                config          = {{
                                    header: {
                                        disableHeader   : true,
                                        disableFileName : true,
                                        retainURLParams : false
                                    }
                                }} 
                            />
                            
                        </DialogContent>
                    </Dialog>
                    <AlertBox 
                        id="delete-attachment" 
                        open={this.state.showDeleteAlert} 
                        onClose={this.closeDeleteAlert.bind(this)} 
                        title="Are you sure you want to delete this attachment?" 
                        message="This action can not be undone." 
                        type="Yes/No" 
                        yesClick={this.yesDeleteAlert.bind(this)} 
                        noClick={this.closeDeleteAlert.bind(this)}
                    />
                    <AlertBox 
                        id="open-attachment" 
                        open={this.state.showOpenAlert} 
                        onClose={this.closeAlert.bind(this)} 
                        title="Warning" 
                        message="The file has no preview. Do you want to download the file?" 
                        type="Yes/No" 
                        yesClick={this.yesDowloadFile.bind(this)} 
                        noClick={this.closeAlert.bind(this)}
                    />
                </div>);
        } else {
            return(
                <div>
                    <h5 style={style.alignCenter}>Please, first save the {this.props.linkType}.</h5>
                </div>
            );
        }
    }
}
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

const { useState, useEffect } = require('react');
const { getAllAfterHoursPlan } = require('../../../../networking/NetworkingAfterHours');

const usePlans = () => {
    const [plans, setPlans] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(()=> {
        let isCancelled = false;

        const getPlans = async () => {
            const json = await getAllAfterHoursPlan();
            if (!isCancelled) {
                const formattedPlans = json.afterHoursPlans.map(p => ({...p, name: p.PlanName, value: p.AfterHoursPlan_ID}));
                const totalCount = formattedPlans.length;
                const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                setPlans(formattedPlans);
                setTotalPages(totalPages);
                setNeedsReloading(false);
            }
        }
        if (needsReloading) {
            getPlans();
        }

        return () => {
            isCancelled = true;
        }
    }, [needsReloading]);

    return [plans, totalPages, setNeedsReloading];
}

export default usePlans;
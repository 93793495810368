import React from 'react';
import { getStyle} from '../../styles/styles';
import {GlobalSystemParametersNames} from '../../constants/GlobalSystemParametersNames'
import {getNextBusinessDate} from '../../networking/NetworkingHolidays'
import {getEODParameters} from '../../helpers/PackageJsonHelper'
import { formatDate } from '../../helpers/FormatHelper';
import {runEODProcess} from '../../networking/NetworkingEOD'
import AlertBox from '../shared/AlertBox';
import LoadingBox from '../shared/LoadingBox';
import AppContext from '../../AppContext';
let globalFunctions = {};

var style = getStyle();
const fontSize = {
    fontSize: '20px'
    }

export default class EndOfDayProcessScreen extends React.Component {
    static contextType = AppContext;

    state = {
        lastClosedDate : '',
        currentBusinessDay: '',
        nextBusinessDay :'',
        sendNotificationsCustomers : '',
        sendNotificationsCustomersSpots : '',
        sendNotificationsCustomersDrawDowns : '',
        sendNotificationsTraders : '',
        sendNotificationsTradersSpots : '',
        sendNotificationsTradersDrawDowns : '',
        sendProfitTraders : '',
        sendMarginCalls : '',
        backupDatabase : '',
        eodLog : '',
        numberOfDaysToCreateBaseDDForEOD : 0,
        numberOfDaysToCreateNoneBaseDDForEOD : 0,
        applicationName : '',
        showConfirm : false,
        loading : false,
        showAlert: false
    }

    componentDidMount()
    {
        globalFunctions = this.context;
        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
        this.setState({loading : true});
        this.setState({
            numberOfDaysToCreateBaseDDForEOD : localStorage.getItem(GlobalSystemParametersNames.NumberOfDaysToCreateBaseDDForEOD),
            numberOfDaysToCreateNoneBaseDDForEOD : localStorage.getItem(GlobalSystemParametersNames.NumberOfDaysToCreateNoneBaseDDForEOD),
            currentBusinessDay : systemDate,
            lastClosedDate : localStorage.getItem(GlobalSystemParametersNames.LastClosingDate),
        });
                    
        this.GetNextBusinessDate(systemDate);

        var EODParameters = getEODParameters();
        if(EODParameters !== undefined)
        {
                var EODSpotCustomerEmails = EODParameters.EODSpotCustomerEmails;
                var EODDrawdownCustomerEmails = EODParameters.EODDrawdownCustomerEmails;
                var EODSpotTraderEmails = EODParameters.EODSpotTraderEmails;
                var EODDrawdownTraderEmails = EODParameters.EODDrawdownTraderEmails;
                var appName = EODParameters.ApplicationName;

            this.setState({
                sendNotificationsCustomers : EODSpotCustomerEmails || EODDrawdownCustomerEmails,
                sendNotificationsCustomersSpots : EODSpotCustomerEmails,
                sendNotificationsCustomersDrawDowns : EODDrawdownCustomerEmails,
                sendNotificationsTraders : EODSpotTraderEmails || EODDrawdownTraderEmails,
                sendNotificationsTradersSpots : EODSpotTraderEmails,
                sendNotificationsTradersDrawDowns : EODDrawdownTraderEmails,
                sendProfitTraders : true,
                sendMarginCalls : true,
                backupDatabase : true,
                applicationName : appName
            });
        }

            
    }

    GetNextBusinessDate(systemDate)
    {
        if(systemDate !== undefined)
        {
            getNextBusinessDate(systemDate,0).then(
                (json) =>
                {
                    if(json !== undefined && json.nextDate !== undefined)
                    {
                        this.setState({nextBusinessDay : json.nextDate});
                    }
                    this.setState({loading : false});
                }                
            );
        }
    }

    handleUpdateSendNotificationCustomers(event){
        this.setState({
            sendNotificationsCustomers: event.target.checked,
            sendNotificationsCustomersSpots: event.target.checked,
            sendNotificationsCustomersDrawDowns: event.target.checked
        })
    }

    handleUpdateSendNotificationCustomersSpots(event){
        this.setState({sendNotificationsCustomersSpots: event.target.checked})
    }

    handleUpdateSendNotificationCustomersDrawDowns(event){
        this.setState({sendNotificationsCustomersDrawDowns: event.target.checked})
    }

    handleUpdateSendNotificationTraders(event){
        this.setState({
            sendNotificationsTraders: event.target.checked,
            sendNotificationsTradersSpots: event.target.checked,
            sendNotificationsTradersDrawDowns: event.target.checked})
    }

    handleUpdateSendNotificationTradersSpots(event){
        this.setState({sendNotificationsTradersSpots: event.target.checked})
    }

    handleUpdateSendNotificationTradersDrawDowns(event){
        this.setState({sendNotificationsTradersDrawDowns: event.target.checked})
    }

    handleUpdateSendProfitTraders(event){
        this.setState({sendProfitTraders: event.target.checked})
    }

    handleUpdateSendMarginCalls(event){
        this.setState({sendMarginCalls: event.target.checked})
    }

    handleUpdateBackupDatabase(event){
        this.setState({backupDatabase: event.target.checked})
    }

    handleProcessClick()
    {
        
        this.openConfirm();

    }

    closeConfirm(){
        this.setState({showConfirm: false, readyToDeleteBank: {}});
    }

    openConfirm(){
        this.setState({showConfirm: true});
    }

    yesConfirm(){

        var objParameters = {
            valueDate: formatDate(this.state.currentBusinessDay),
            SendNotificationToCustomers: this.state.sendNotificationsCustomers,
            SendNotificationToCustomersSpot: this.state.sendNotificationsCustomersSpots,
            SendNotificationToCustomersDrawDowns: this.state.sendNotificationsCustomersDrawDowns,
            SendNotificationToTraders: this.state.sendNotificationsTraders,
            SendNotificationToTradersSpot: this.state.sendNotificationsTradersSpots,
            SendNotificationToTradersDrawDowns: this.state.sendNotificationsTradersDrawDowns,
            SendProfitNotificationToTraders: this.state.sendProfitTraders,
            SendMarginCallNotifications: this.state.sendMarginCalls,
            BackupDatabase: this.state.backupDatabase,
            UserID: localStorage.getItem('UserID'),
            ApplicationName: this.state.applicationName
        }
        this.setState({showConfirm: false, loading: true});

        localStorage.setItem('StopGettingSession', true);
        runEODProcess(objParameters).then(
            (value)=>{ 
                if(value !== undefined)
                { 
                    if(value.errorMessage !== undefined && value.errorMessage === "" && value.eodID !== "")
                    {
                        
                        if(value.eodLog !== undefined)
                        {
                             this.setState({eodLog : value.eodLog});
                        } 
                        if(value.lastClosingDate !== undefined)
                        {
                            this.setState({lastClosedDate : value.lastClosingDate});
                        }    
                        if(value.systemDate !== undefined)
                        {
                            this.setState({currentBusinessDay : value.systemDate});
                            this.GetNextBusinessDate(value.systemDate);
                        }   
                        this.setState({showAlert : true});                    
                    }
                    else
                    {
                        if(value.errorMessage !== undefined)
                        {
                            this.setState({eodLog : value.errorMessage});
                        }
                    }
                }
                this.setState({loading: false});
            }).finally(() => { 
                localStorage.setItem('StopGettingSession', false);
            });
        
    }

    closeAlert = () =>
    {
        this.setState({showAlert: false});
        globalFunctions.forceLogout();
    }

    render(){

        

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>End of day process</h3>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label className="uk-form-label" style={fontSize} >Last closed date {this.state.lastClosedDate}</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label className="uk-form-label" style={fontSize} > Current business date {this.state.currentBusinessDay}</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label className="uk-form-label" style={fontSize} > Next business date {this.state.nextBusinessDay}</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-top">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsCustomers} checked={this.state.sendNotificationsCustomers} onChange={this.handleUpdateSendNotificationCustomers.bind(this)} 
                                type="checkbox" /> Do you want to send notifications to Customers with a value date of {this.state.nextBusinessDay}?</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-left">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsCustomersSpots} checked={this.state.sendNotificationsCustomersSpots} onChange={this.handleUpdateSendNotificationCustomersSpots.bind(this)} 
                                type="checkbox" /> Spots</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-left">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsCustomersDrawDowns} checked={this.state.sendNotificationsCustomersDrawDowns} onChange={this.handleUpdateSendNotificationCustomersDrawDowns.bind(this)} 
                                type="checkbox" /> Draw Downs</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsTraders} checked={this.state.sendNotificationsTraders} onChange={this.handleUpdateSendNotificationTraders.bind(this)} 
                                type="checkbox" /> Do you want to send notifications to Traders with a value date of {this.state.nextBusinessDay}?</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-left">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsTradersSpots} checked={this.state.sendNotificationsTradersSpots} onChange={this.handleUpdateSendNotificationTradersSpots.bind(this)} 
                                type="checkbox" /> Spots</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-left">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendNotificationsTradersDrawDowns} checked={this.state.sendNotificationsTradersDrawDowns} onChange={this.handleUpdateSendNotificationTradersDrawDowns.bind(this)} 
                                type="checkbox" /> Draw Downs</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendProfitTraders} checked={this.state.sendProfitTraders} onChange={this.handleUpdateSendProfitTraders.bind(this)} 
                                type="checkbox" /> Do you want to send profit notifications to Traders?</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.sendMarginCalls} checked={this.state.sendMarginCalls} onChange={this.handleUpdateSendMarginCalls.bind(this)} 
                                type="checkbox" /> Do you want to send margin calls notifications?</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <label style={fontSize}><input className="uk-checkbox" value={this.state.backupDatabase} checked={this.state.backupDatabase} onChange={this.handleUpdateBackupDatabase.bind(this)} 
                                type="checkbox" /> Do you want to backup the database before the EOD process?</label>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <button className="uk-button uk-button-green uk-float-right" type="button" onClick={this.handleProcessClick.bind(this)}>End Of Day</button>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-margin-top">
                            <textarea className="uk-width-1-1" readOnly="true" placeholder="" rows="10" value={this.state.eodLog}/>
                        </div>
                    </div>
                </form>
                <AlertBox open={this.state.showConfirm} onClose={this.closeConfirm.bind(this)} title="Are you sure you want to continue?" message="This action can not be undone." type="Yes/No" yesClick={this.yesConfirm.bind(this)} noClick={this.closeConfirm.bind(this)}/>
                <LoadingBox loading={this.state.loading}/>
                <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title="Message"
                    message="EOD process finished successfully. You will be redirected to the login screen."
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />
            </div>
        );
    }
}
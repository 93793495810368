/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getStyle } from '../../../styles/styles';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';
import {getScaleForCurrencyPair} from '../../../helpers/FormatHelper'
const style = getStyle();

export default class SelectedCurrencyPairForm extends React.Component {

    state = {
        rateFeedTerms: [],
        rateFeedTerm: '',
        rateFeedTermName: '',
        rateTerms: [],
        rateTerm: '',
        rateTermName: '',
        ratePrecision: 0,
        marginError: 0,
        lowerLimit: 0,
        upperLimit: 0,
        spotDays: 0,
        fromRateFeed: false,
        allowOnlineFwd: false,
        onlineFixVariance: 0.0,
        primaryCurrency: 'EUR',
        primaryCurrencyName: 'EUR',
        primaryCurrencies: [],
        secondaryCurrency: 'AUD',
        secondaryCurrencyName: 'AUD',
        secondaryCurrencies: []
    }

    handleGetValueRateFeedTerms = () => {

    }

    handleGetValueRateTerms = () => {

    }

    handleUpdateRatePrecision = () => {
        
    }

    handleUpdateMarginError = () => {

    }

    handleUpdateLowerLimit = () => {

    }

    handleUpdateUpperLimit = () => {

    }

    handleUpdateSpotDays = () => {

    }

    handleUpdateFromRateFeed = () => {

    }

    handleUpdateAllowOnlineFwd = () => {

    }

    handleGetValuePrimaryCurrency = () => {
        
    }

    handleGetValueSecondaryCurrency = () => {
        
    }

    render() {
        return (
            <div className="">
                <ul id="accordion-trading-information" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Selected Currency Pair Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="selected-currency-pair">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Buy Currency</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <p style={{marginBottom: '0px', marginTop: '2px'}}>EUR</p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Rate Feed Terms</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect id="scp-rate-feeds" className="uk-select" objValue={{ value: this.state.rateFeedTerm, name: this.state.rateFeedTermName }} getValue={this.handleGetValueRateFeedTerms} placeholder={"Select an Item..."} data={this.state.rateFeedTerms} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Sell Currency</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <p style={{marginBottom: '0px', marginTop: '2px'}}>EUR</p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Rate Precision</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="scp-rate-precision" type="Rate" scale={2} value={this.state.ratePrecision} onChange={this.handleUpdateRatePrecision} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Rate Terms</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect id="scp-rate-feeds" className="uk-select" objValue={{ value: this.state.rateTerm, name: this.state.rateTermName }} getValue={this.handleGetValueRateTerms} placeholder={"Select an Item..."} data={this.state.rateTerms} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Margin error</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="scp-margin-error" type="Rate" scale={2} value={this.state.marginError} onChange={this.handleUpdateMarginError} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Lower Limit</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="scp-lower-limit" type="Rate" 
                                                scale={getScaleForCurrencyPair(this.props.buyCurrency, this.props.sellCurrency)}
                                                value={this.state.lowerLimit} onChange={this.handleUpdateLowerLimit} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Upper Limit</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="scp-upper-limit" type="Rate" 
                                                scale={getScaleForCurrencyPair(this.props.buyCurrency, this.props.sellCurrency)}
                                                value={this.state.upperLimit} onChange={this.handleUpdateUpperLimit} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Spot Days</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" id="scp-spot-days" placeholder="0.00" type="Rate" scale={2} value={this.state.spotDays} onChange={this.handleUpdateSpotDays} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                                            <label className="uk-form-label">
                                                <input className="uk-checkbox" id="scp-from-rate-feed" value={this.state.fromRateFeed} checked={this.state.fromRateFeed} onChange={this.handleUpdateFromRateFeed} type="checkbox" />
                                            From Rate Feed
                                            </label>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                                            <label className="uk-form-label">
                                                <input className="uk-checkbox" id="scp-allow-online-fwd" value={this.state.allowOnlineFwd} checked={this.state.allowOnlineFwd} onChange={this.handleUpdateAllowOnlineFwd} type="checkbox" />
                                            Allow Online Fwd
                                            </label>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Online Fix Variance</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="scp-online-fix-variance" type="Rate" scale={2} value={this.state.spotDays} onChange={this.handleUpdateSpotDays} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Primary Currency</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect id="scp-primary-currency" className="uk-select" objValue={{ value: this.state.primaryCurrency, name: this.state.primaryCurrencyName }} getValue={this.handleGetValuePrimaryCurrency} placeholder={"Select an Item..."} data={this.state.primaryCurrencies} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Secondary Currency</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect id="scp-secondary-currency" className="uk-select" objValue={{ value: this.state.secondaryCurrency, name: this.state.secondaryCurrencyName }} getValue={this.handleGetValueSecondaryCurrency} placeholder={"Select an Item..."} data={this.state.secondaryCurrencies} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}
import React from 'react';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import AlertBox from '../../../shared/AlertBox';
import { getOwnerByID, deleteOwner } from '../../../../networking/NetworkingOwners';
import OwnerMaintenanceScreen from '../owners/OwnerMaintenanceScreen';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import AddOwnerScreen from '../addOwner/AddOwnerScreen';
import LoadingBox from '../../../shared/LoadingBox';
import { getOwnersByCustomer } from '../../../../networking/NetworkingOwners';

const itemsPerPage = getDefaultPagingNumberOfItems();
export default class OwnersScreen extends React.Component {

    state = {
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        updateOwner: false,
        addOwner: false,
        newOwner: false,
        customerID: '',
        ownerID: '',
        selectedCustomer: [],
        owners: [],
        ownerList: {},
        originList: {},
        type: '',
        totalpages: 0,
        currentPage: 1,
        newSearch: false,
        readyToDeleteOwner: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        email: '',
        isEmailValidated: false,
        isClearOwner: false,
        isClearAdd: false,
        loading: false
    }

    componentDidMount() {
        this.updatePage()
    }

    handleAddClick = () => {
        this.setState({ addOwner: true, updateOwner: false, type: '', ownerID: '', ownerList: {}, isClearOwner: false, isClearAdd: false });
        this.props.createOwnerHandler();
    }
    handleAddClearClick = () => {
        this.setState({ addOwner: true, updateOwner: false, type: '', ownerID: '', ownerList: {}, isClearOwner: true, isClearAdd: true });
    }
    objAddOwner = (objAddOwner) => {
        this.setState({ addOwner: false, updateOwner: true, type: objAddOwner.OwnerType, ownerID: objAddOwner.OwnerID, ownerList: objAddOwner, isClearOwner: false, isClearAdd: false })
    }

    handleRowClick(obj) {
        let title = `Owner - ${obj['Owner Name']} - ${obj['OwnerID']}`;
        this.props.updateOwnerHandler(title)
        getOwnerByID(obj['OwnerID'], 1).then(
            (json) => {
                console.log(json.owner);
                this.setState({ ownerList: json.owner[0], type: json.owner[0]['Owner Type'], ownerID: json.owner[0]['OwnerID'], updateOwner: true, addOwner: false });
            }
        );
    }
    handleDeleteClick(obj) {
        this.setState({ showDeleteAlert: true, readyToDeleteOwner: obj });
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteOwner: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() 
    {
        this.setState({ showDeleteAlert: false, loading: true });

        deleteOwner(this.state.readyToDeleteOwner['OwnerID']).then(
            (deleteResponse) => {
                let auxTitle = 'Error';
                let auxMessage = 'Something went wrong. Please try again later.';
                let deleted = false;

                if(deleteResponse !== undefined)
                {
                    switch (deleteResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            deleted = true;
                            break;
                        }
                        case 412:
                        case 503:
                        {
                            if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                            {
                                switch (deleteResponse.Message) 
                                {
                                    case 'cannot be removed':
                                    {
                                        auxMessage = 'This owner cannot be deleted as it is linked to one or more entities in the system.';
                                        break;
                                    }
                                    default:
                                    {
                                        auxMessage = deleteResponse.Message;
                                        break;
                                    }
                                }
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }

                    if(deleted){
                        this.setState({ 
                            showSuccessDeleteAlert  : true, 
                            loading                 : false
                        }, () =>{
                            this.updatePage();
                        });
                    }else{
                        this.setState({
                            showAlert   : true,
                            alertTitle  : auxTitle,
                            alertMessage: auxMessage,
                            loading     : false
                        });
                    }
                }else
                {
                    this.setState({
                        showAlert   : true,
                        alertTitle  : auxTitle,
                        alertMessage: auxMessage,
                        loading     : false
                    });
                }
            }
        )
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }
    handleBackLink() {
        this.setState({ addOwner: false, updateOwner: false, newOwner: false,isClearOwner: false, isClearAdd: false });
    }
    changePage(value) {
        this.setState({ currentPage: value })
    }
    updatePage() {
        this.setState({loading: true},()=>{
            getOwnersByCustomer(this.props.id).then(
            (json) => {
                if (json.owners !== undefined) {
                    var helper = json.owners;
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount / itemsPerPage);
                    //owner
                    this.setState({
                        totalpages: pages, 
                        newSearch: true, 
                        owners: helper,
                        loading: false
                    });
                } else {
                    this.setState({ 
                        totalpages: 0,
                        owners: [],
                        newSearch: true,
                        loading: false 
                    })
                }
            }
        )
        });
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }
    getAllOwners = async () => {
        var json = await getOwnersByCustomer(this.props.id, 0);
        var helper = json.owners;
        return helper;
    }

    render() {
        if (this.state.addOwner) {
            return (
                <AddOwnerScreen
                    updateFunctionOrigin={this.updatePage.bind(this)}
                    //backLink={this.props.backLink}
                    backLink={this.handleBackLink.bind(this)}
                    selectedOwner={this.objAddOwner}
                    customerID={this.props.id}
                    customerPersonID={this.props.customerPersonID}
                    customerName={this.props.data}
                    isNewOwner={this.handleAddClick}
                    isNewClearOwner={this.handleAddClearClick}
                    isClearOwner={this.state.isClearOwner}
                    isClearAdd={this.state.isClearAdd}
                    updateOwnerHandler={this.props.updateOwnerHandler}
                    createOwnerHandler={this.props.createOwnerHandler}
                />
            )
        }
        if (this.state.updateOwner && !this.state.newOwner) {
            return (
                <OwnerMaintenanceScreen
                    updateFunctionOrigin={this.updatePage.bind(this)}
                    //backLink={this.props.backLink}
                    backLink={this.handleBackLink.bind(this)}
                    ownerID={this.state.ownerID}
                    selectedOwner={this.state.ownerList}
                    type={this.state.type}
                    customerID={this.props.id}
                    customerPersonID={this.props.customerPersonID}
                    customerName={this.props.data}
                    isNewOwner={this.handleAddClick}
                    isNewClearOwner={this.handleAddClearClick}
                    isClearOwner={this.state.isClearOwner}
                    isClearAdd={this.state.isClearAdd}
                    updateOwnerHandler={this.props.updateOwnerHandler}
                    createOwnerHandler={this.props.createOwnerHandler}
                />
            )
        }

        return (
            <div>
                <div className="uk-grid">
                    <div className="uk-form-controls uk-width-3-5">
                        <h3 className="component-title">Owners&nbsp;
                            <button className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>
                        </h3>
                    </div>
                </div>
                <hr style={{ marginBottom: '5px', marginTop: '0px' }} />
                <div className="uk-grid">
                    <div style={{ width: '5%' }}></div>
                    <div className="uk-width-4-5">
                        <NewDynamicTable
                            key="owner-screen"
                            hiddenHeaders={['OwnerID','PersonID']}
                            data={this.state.owners}
                            enableClick={true}
                            clickFunction={this.handleRowClick.bind(this)}
                            newSearch={this.state.newSearch}
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteClick.bind(this)}
                            enableSortByHeader={true}
                            usePaginator={true}
                            changePage={this.changePage.bind(this)}
                            numberOfPages={this.state.totalpages}
                            pageNumber={this.state.currentPage}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            useFilter={true}
                            filterFunction={this.getAllOwners}
                            useExportButton={true}
                            tableName="owners"
                        />
                    </div>
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Warning"
                    message='Are you sure you want to delete this Owner? This action can not be undone.' type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Success" message="Owner successfully removed." type="Ok" okClick={this.okSuccessAlert.bind(this)} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <LoadingBox loading={this.state.loading} />

            </div>
        );
    }
}
import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import TestConfirmation from './TestConfirmation.js';
import './PDFScreen.scss';

export default class PDFScreen extends React.Component {
    render = () => {
        
        return(
            <PDFViewer className="viewer">
                <TestConfirmation text1="Hola Mundo" text2="Another Test Text 2"/>
            </PDFViewer>
        );
    }
}
import React from 'react';

import CardBoard from './CardBoard';

import {getScreen} from '../../../shared/ScreenHelper';
import {getStyle} from '../../../../styles/styles';

const style = getStyle();

export default class DealCardBoardScreen extends React.Component {
    
    state = {
        screen: 'board',
        activeTab: '',
        elementID: 0,

    }

    handleBackClick = () => {
        this.setState({screen: 'board'});
    }

    onClickElementBoard = (element) => {
        console.log(element)
        //this.setState({screen: element['Action'], elementID: element['Deal No']});
        //change to use the same column name of the store procedure
        this.setState({screen: element['Action{No line}'], elementID: element['Deal No']});
    } 

    onChangeScreen = (screen, id, tab) => {
        this.setState({screen: screen, elementID: id, activeTab: tab});
    } 
    
    render = () => {
        let screenRender;
        
        let mainTitle = ''
        let beneObj = {}
        let isBoardView = true;

        if (this.state.screen === 'ShowDealInfoScreen') 
        {
            mainTitle = 'Outstanding Deals'
        }

        if (this.state.screen === 'board'){
            screenRender = (<CardBoard onClickElementBoard={this.onClickElementBoard} onChangeScreen={this.onChangeScreen}/>);            
        } else {
            //////debugger
            screenRender = getScreen(this.state.screen, this.state.elementID, this.handleBackClick, null, this.state.activeTab, beneObj, mainTitle);
            isBoardView = false;
        }
        return(
            <div>
                {isBoardView && <h3 className="uk-heading-divider" style={style.titleStyle}>Outstanding Deals</h3>}
            	{screenRender}
            </div>
        );
    }
}
import React from 'react';
import {getEntityProperties} from '../../networking/NetworkingEntityProperties';
import NumberInput from './NumberInput';
import DatePicker from './DatePicker';
import {formatDate, formatToCurrency, formatToCurrencyWithScale, getScaleByCurrency} from '../../helpers/FormatHelper';
import {getStyle} from '../../styles/styles';
import {GlobalSystemParametersNames} from '../../constants/GlobalSystemParametersNames'

export default class EntityPropertiesContainer extends React.Component {
    state = {
        entityProperties: [],
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency)
    }

    componentDidMount(){
        if(this.props.entityProperties !== undefined){
            this.setState({entityProperties: this.props.entityProperties});
        }
        else{
            getEntityProperties(this.props.entityID, this.props.entityType).then(
                (response) => {
                    //alert(JSON.stringify(response));
                    this.setState({entityProperties: response.entityProperties});
                }
            );
        }        
    }
    
    handleTextControlUpdate = (event) => {
        var entityProperties = this.state.entityProperties;
        let controlID = event.target.id;
        let ids = controlID.split('-');
        entityProperties.forEach(element => {
            if(Number(element.UserDefinedPropertyID) === Number(ids[0])){
                element.TextValue = event.target.value;
            }
        });
        this.props.onRefreshProperties(entityProperties);
        this.setState({entityProperties: entityProperties});
    }

    handleNumberControlUpdate = (event) => {
        var entityProperties = this.state.entityProperties;
        let controlID = event.target.id;
        let ids = controlID.split('-');
        entityProperties.forEach(element => {
            if(Number(element.UserDefinedPropertyID) === Number(ids[0])){
                element.NumValue = event.target.value;
            }
        });
        this.props.onRefreshProperties(entityProperties);
        this.setState({entityProperties: entityProperties});
    }

    handleDateControlUpdate = (date, modifiers, dayPickerInput) => {
        var entityProperties = this.state.entityProperties;
        //alert(dayPickerInput.getInput().id);
        let controlID = dayPickerInput.getInput().id;
        let ids = controlID.split('-');
        entityProperties.forEach(element => {
            if(Number(element.UserDefinedPropertyID) === Number(ids[0])){
                element.DateValue = formatDate(date);
            }
        });
        this.props.onRefreshProperties(entityProperties);
        this.setState({entityProperties: entityProperties});
    }

    render(){
        var rows = [];
        if(this.state.entityProperties !== undefined){
            this.state.entityProperties.sort(function (a, b) {
                if (a['Group By']+a.Description > b['Group By']+b.Description ) {
                  return 1;
                }
                if (a['Group By']+a.Description  < b['Group By']+b.Description ) {
                  return -1;
                }
                return 0;
              });
            this.state.entityProperties.forEach(element => {
                var inputControl;
                if(this.props.readOnly){
                    switch(element.Type){
                        case 'Text':
                        default:
                            inputControl = element.TextValue;
                            break;                        
                        case 'Numeric':
                            inputControl = formatToCurrencyWithScale(element.NumValue);
                            break;
                        case 'DateTime':
                            inputControl = element.DateValue;
                            break;
                    }                
                }
                else{
                    switch(element.Type){
                        case 'Text':
                        default:
                            inputControl = (<input id={element.UserDefinedPropertyID + '-' + this.props.entityID + '-' + this.props.entityType} type="text" className="uk-input" value={element.TextValue} onChange={this.handleTextControlUpdate}/>);
                            break;
                        case 'Numeric':
                            inputControl = (<NumberInput id={element.UserDefinedPropertyID + '-' + this.props.entityID + '-' + this.props.entityType} 
                                type="Currency" 
                                scale={getScaleByCurrency(this.state.baseCurrency)}  
                                className="uk-input" 
                                value={element.NumValue} 
                                onChange={this.handleNumberControlUpdate}/>);
                            break;
                        case 'DateTime':
                            inputControl = (<DatePicker id={element.UserDefinedPropertyID + '-' + this.props.entityID + '-' + this.props.entityType}
                                disabledDays={[0,6]} 
                                className="uk-input" 
                                onDayChange={this.handleDateControlUpdate}
                                value={element.DateValue}
                                readOnly={true}/>
                            );
                            break;
                    }
                }            
    
                rows.push(<tr key={'row-' + element.UserDefinedPropertyID}>
                    <td>{element['Group By']}</td>
                    <td>{element.Description}</td>
                    <td>{inputControl}</td>
                </tr>);
            });
        }
        
        return (
            <div>
                {this.props.showLikeCollapsable === true &&
                <ul id="accordion-compliance" uk-accordion="multiple: true" >
                    {/*Entity Properties*/}
                    <li className={this.props.isConfirmationFather === true ? "uk-open":""}>
                        <button 
                            className="uk-accordion-title" 
                            >Entity Properties</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            {rows.length !== 0 && <div className="uk-margin">
                                <table>
                                    <tbody>
                                        {rows}
                                    </tbody>
                                </table>
                                {rows.length === 0 && <label style={{textAlign:'center'}}>No entity properties</label>}
                            </div>}
                            {rows.length === 0 && <div className="uk-margin" style={{textAlign:'center'}}>
                                 <label>No entity properties</label>
                            </div>}
                        </div>
                    </li>
                </ul>}

                {(this.props.showLikeCollapsable === false || this.props.showLikeCollapsable === undefined) &&
                <div>
                    <label className="uk-form-label">Entity Properties</label>
                    <table>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>}
            </div>
        );
    }
} 
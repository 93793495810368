import { genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getOutgoingInformationByID(outgoingID){
    try {
        let params = {
            OutgoingFundsID: outgoingID
        }
        let response = await genericGetWithParameters('Funds/GetOutgoingInformationByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateOutgoingFund2(model){
    try{
        var request = {
            OutgoingFundsID: model.OutgoingFundsID,
            DealHeaderID: model.DealHeaderID,
            BeneficiaryBankAccountID: model.BeneficiaryBankAccountID,
            BeneficiaryID: model.BeneficiaryID,
            NostroAccount: model.NostroAccountID,
            VersionNo: model.VersionNo,
            PaymentInstrument: model.PaymentInstrument,
            ValueDate: model.ValueDate,
            PaymentAmount: model.PaymentAmount,
            DeliveryRefNo: model.DeliveryRefNo,
            BeneRefNumber: model.DetailsOfPayment,
            Status: model.Status,
            WireItemFee: model.WireItemFee,
            WireItemFeeCurrencyID: model.WireItemFeeCurrencyID,
            CreatedBy: localStorage.getItem('UserID'),
            BeneficiaryType: model.BeneficiaryType,
            PaymentReference: model.PaymentReference,
            Comments: model.Comments,
            DeliveryMethod: model.DeliveryMethod,
            UpdateToken: model.UpdateToken,
        }
        console.group("UpdateRequest");
        console.log(model);
        console.groupEnd();
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Funds/Put', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(request),
        });
        let responseJson = await response.json();
        console.group("updateOutgoingFund", model.OutgoingFundsID);
        console.log(responseJson);
        console.groupEnd();
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function updateOutgoingFund(model){
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'Funds/Put'
    var request = {
        OutgoingFundsID: model.OutgoingFundsID,
        DealHeaderID: model.DealHeaderID,
        BeneficiaryBankAccountID: model.BeneficiaryBankAccountID,
        BeneficiaryID: model.BeneficiaryID,
        NostroAccount: model.NostroAccountID,
        VersionNo: model.VersionNo,
        PaymentInstrument: model.PaymentInstrument,
        ValueDate: model.ValueDate,
        PaymentAmount: model.PaymentAmount,
        DeliveryRefNo: model.DeliveryRefNo,
        BeneRefNumber: model.DetailsOfPayment,
        Status: model.Status,
        WireItemFee: model.WireItemFee,
        WireItemFeeCurrencyID: model.WireItemFeeCurrencyID,
        CreatedBy: localStorage.getItem('UserID'),
        BeneficiaryType: model.BeneficiaryType,
        PaymentReference: model.PaymentReference,
        Comments: model.Comments,
        DeliveryMethod: model.DeliveryMethod,
        UpdateToken: model.UpdateToken,
        OutgoingFundExt: model.OutgoingFundExt,
        IsRefund: model.IsRefund,
        UpdatedBy: localStorage.getItem('UserID'),
        ComplianceSoftRulesAcceptedByUser: model.ComplianceSoftRulesAcceptedByUser
    }
    
    let response = await genericCallWithBody(method,apiName,request);
    return response;
}

export async function completedIncomingOutgoingStatus(model) {

    let method = 'POST';
    let apiName = 'Funds/CompletedIncomingOutgoingStatus';
    
    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoings(dealHeaderID, page, items){
    try {
        let params = {
            'DealHeaderID': dealHeaderID,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Funds/GetList',params);
        response.outgoings.forEach((outgoing) => {
            outgoing.EntityProperties = JSON.parse(outgoing.EntityProperties);
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getIncomingFundsByDeal(dealID, page, items){
    try {
        let params = {
            'DealID': dealID,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Funds/GetIncomingFundsByDeal',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoingFundsByDeal(dealID, page, items){
    try {
        let params = {
            'DealID': dealID,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Funds/GetOutgoingFundsByDeal',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPurposeOfPayments(interfaceSource, customerId, currencyId, bankAccountId){
    try {
        let params = {
            'interfaceSource': interfaceSource,
            'customerId': customerId,
            'currencyId': currencyId,
            'bankAccountId': bankAccountId
        }

        let response = await genericGetWithParameters('Funds/GetPurposeOfPayments',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateRejectedFeeAmount(model) {
    let method = 'PUT';
    let apiName = 'Funds/UpdateRejectedFeeAmount';

    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAmountInBaseCurrency(dealID, paymentAmount){
    try{
        let params = {
            DealID: dealID,
            PaymentAmount: paymentAmount
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/GetAmountInBaseCurrency',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getBalanceDueByDealID(dealID){
    try{
        let params = {
            DealID: dealID
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/GetBalanceDueByDealID',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID){
    try {
        let params = {
            'CustomerID': CustomerID,
            'CurrencyID': CurrencyID
        }

        let response = await genericGetWithParameters('Funds/GetCustomerFundOnHoldBalanceByCurrencyJson',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createIncomingFund(model) {
    let method = 'POST';
    let apiName = 'Funds/CreateIncomingFund';

    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidIncomingFund(incomingFundsID, userID, incomingUpdateToken, paymentUpdateToken, dealUpdateToken, depositUpdateToken, fundsOnHoldBalancesUpdateToken){
    try {
        let params = {
            incomingFundsID: incomingFundsID,
            userID: userID,
            incomingUpdateToken: incomingUpdateToken,
            paymentUpdateToken: paymentUpdateToken,
            dealUpdateToken: dealUpdateToken,
            depositUpdateToken: depositUpdateToken,
            fundsOnHoldBalancesUpdateToken: fundsOnHoldBalancesUpdateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Funds/VoidIncomingFund',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createRefund(objOutgoingFund) {
    let method = 'POST';
    let apiName = 'Funds/CreateRefund';
    try {
        let response = await genericCallWithBody(method, apiName, objOutgoingFund);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRefunds(sourceTable, sourceID){
    try {
        let params = {
            'sourceTable': sourceTable,
            'sourceID': sourceID,
        }
        let response = await genericGetWithParameters('Funds/GetRefunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidRefund(outgoingFundsID, userID, updateToken, sourceUpdateToken){
    try {
        let params = {
            outgoingFundsID: outgoingFundsID,
            sourceUpdateToken: sourceUpdateToken,
            userID: userID,
            updateToken: updateToken,
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/VoidRefund',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function applyHeldFunds(incomingFund, userId) {

    let method = 'POST';
    let apiName = 'Funds/ApplyHeldFunds';

    let model = {
        IncomingFund: incomingFund,
        UserId: userId
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function saveOutgoingToBankInterface(objOutgoingFund) {

    let method = 'POST';
    let apiName = 'Funds/SaveOutgoingToBankInterface';

    let model = objOutgoingFund

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function confirmDealDisbursements(dealHeaderId, userId) {

    try {
        let model =
        {
            DealHeaderID: dealHeaderId,
            UserID: userId
        }

        var method = 'POST';
        var apiName = 'Funds/ConfirmDealDisbursements'
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPendingRefundsByCustomer(customerID){
    try {
        let params = {
            'customerID': customerID
        }

        let response = await genericGetWithParameters('Funds/GetPendingRefundsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCompleteEntityFunding(customerID, currencyID, entityType, entityID){
    try {
        let params = {
            'customerID': customerID,
            'currencyID': currencyID,
            'entityType': entityType,
            'entityID': entityID,
        }

        let response = await genericGetWithParameters('Funds/GetCompleteEntityFunding',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoingFundsRiskScoreDetails(customerID, outgoingID, customerAsBeneficiary, checkCustomerBankScore) {
    try {
        let params = {
            'customerID': customerID,
            'outgoingID': outgoingID,
            'customerAsBeneficiary': customerAsBeneficiary,
            'checkCustomerBankScore': checkCustomerBankScore,
        }

        let response = await genericGetWithParameters('Funds/GetOutgoingFundsRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveOutgoingFundsEntityProperties = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Funds/SaveOutgoingFundsEntityProperties', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const createTransfer = async (modelTransfer) => {

    let method = 'POST';
    let apiName = 'Funds/Transfer';

    let model = modelTransfer

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function rejectOutgoingFund(model, rejectedFee, createRejectedFee){
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'Funds/RejectOutgoing'
    var request = {
        OutgoingFundsID: model.OutgoingFundsID,
        DealHeaderID: model.DealHeaderID,
        BeneficiaryBankAccountID: model.BeneficiaryBankAccountID,
        BeneficiaryID: model.BeneficiaryID,
        NostroAccount: model.NostroAccountID,
        VersionNo: model.VersionNo,
        PaymentInstrument: model.PaymentInstrument,
        ValueDate: model.ValueDate,
        PaymentAmount: model.PaymentAmount,
        DeliveryRefNo: model.DeliveryRefNo,
        BeneRefNumber: model.DetailsOfPayment,
        Status: model.Status,
        WireItemFee: model.WireItemFee,
        WireItemFeeCurrencyID: model.WireItemFeeCurrencyID,
        CreatedBy: localStorage.getItem('UserID'),
        BeneficiaryType: model.BeneficiaryType,
        PaymentReference: model.PaymentReference,
        Comments: model.Comments,
        DeliveryMethod: model.DeliveryMethod,
        UpdateToken: model.UpdateToken,
        OutgoingFundExt: model.OutgoingFundExt,
        IsRefund: model.IsRefund,
        RejectedFeeAmount: rejectedFee,
        CreateRejectedFee: createRejectedFee,
        UpdatedBy: localStorage.getItem('UserID')
    }
    
    let response = await genericCallWithBodyAndResponseDecoded(method,apiName,request);
    return response;
}

export async function sendInstantPaymentToAFEX(userId, payments) {

    let method = 'POST';
    let apiName = 'Funds/SendInstantPaymentToAFEX';

    let model = {
        UserId: userId,
        Payments: payments
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function swiftPaymentVerification(model) {

    let method = 'POST';
    let apiName = 'SwiftApi/PaymentVerification';
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function swiftCreateCustomerCreditTransfer(model) {

    let method = 'POST';
    let apiName = 'SwiftApi/CreateCustomerCreditTransfer';
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}


export async function swiftGetOutgoingPaymentStatus(model) {

    let method = 'POST';
    let apiName = 'SwiftApi/ReadSwiftOutgoingPaymentStatus';
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function swiftCreateCustomerCreditTransferBatch(paymentOutgoingId, userId, paymentOutgoingBatchList) {

    let model = {
        "PaymentOutgoingID": paymentOutgoingId,
        "UserID": userId,
        "PaymentOutgoingIDBatchList": paymentOutgoingBatchList
    }

    let method = 'POST';
    let apiName = 'SwiftApi/CreateCustomerCreditTransferBatch';
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getHistoryAuditFundsOnHoldBalances(customerID, currencyID, fromDate, toDate) {
    try {
        let params = {
            'customerID': customerID,
            'currencyID': currencyID,
            'fromDate': fromDate,
            'toDate': toDate,
        }
      let response = await genericGetWithParameters('Funds/GetHistoryAuditFundsOnHoldBalances', params);
      return response;
  } catch (error) {
      console.error(error);
  }
}

export async function getFundsOnHoldBalancesAdditionalHistory(customerID, currencyID, fromDate, toDate) {
    try {
        let params = {
            'CustomerID': customerID,
            'CurrencyID': currencyID,
            'FromDate': fromDate,
            'ToDate': toDate,
        }
      let response = await genericGetWithParameters('Funds/GetFundsOnHoldBalancesAdditionalHistory', params);
      return response;
  } catch (error) {
      console.error(error);
  }
}

export async function updateOutgoingFundStatus(OutgoingFundID, NewStatus, UpdatedBy){
    try {
        let params = {
            'OutgoingFundID': OutgoingFundID,
            'NewStatus': NewStatus,
            'UpdatedBy': localStorage.getItem('UserID')
        }

        let response = await genericGetWithParametersNoEncrypted('Funds/UpdateOutgoingFundStatus',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function submitACHPayments(userId, payments, exportType) {

    let method = 'POST';
    let apiName = 'Funds/SubmitACHPayments';

    let model = {
        paymentsToSubmit: payments,
        userId: userId,
        exportType: exportType
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function exportPayments(exportType, groupId, noOfPaySubmited, filePath, emailDestination) {
    try {
        let model =
        {
            ExportType: exportType,
            GroupId: groupId,
            NoOfPaySubmited: noOfPaySubmited,
            FilePath: filePath,
            EmailDestination: emailDestination
        }

        var method = 'POST';
        var apiName = 'Funds/ExportPayments'
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getOutgoingFundsByDealReadyToSend = async (dealHeaderID) => {
    try {
        const params = {
            'dealHeaderID': dealHeaderID
        };
        const response = await genericGetWithParameters('Funds/GetOutgoingFundsByDealReadyToSend', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function processPayments(paymentsToProcess, userId) {
    try {
        let model =
        {
            paymentsToProcess: paymentsToProcess,
            userId: userId
        }

        var method = 'POST';
        var apiName = 'Funds/ProcessPayments'
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getACHBatches(processDate){
    try{
        let params = {
            ProcessDate: processDate
        }
        let response = await genericGetWithParameters('Funds/GetACHBatches',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getTransferHistory(sendingCustomerID, receivingCustomerID, currencyID, fromDate, toDate) {
    try{
        const params = {
            sendingCustomerID: sendingCustomerID,
            receivingCustomerID: receivingCustomerID,
            currencyID: currencyID,
            fromDate: fromDate,
            toDate: toDate
        };
        const response = await genericGetWithParameters('Funds/GetTransferHistory',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getTransferStatement(customerID, currencyID, fromDate, toDate) {
    try{
        const params = {
            customerID: customerID,
            currencyID: currencyID,
            fromDate: fromDate,
            toDate: toDate
        };
        const response = await genericGetWithParameters('Funds/GetTransferStatement',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getApayloBalance(){
    try{
        const params = {}
        const response = await genericGetWithParameters('Funds/GetApayloBalance', params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export const getOutgoingFundsVersionComparer = async (outgoingFundsID, versionNumber1, versionNumber2) => {
    try {
        const params = {
            'outgoingFundsID': outgoingFundsID,
            'versionNumber1': versionNumber1,
            'versionNumber2': versionNumber2
        };
        const response = await genericGetWithParameters('Funds/GetOutgoingFundsVersionComparer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoingFundsVersions(outgoingFundsID) {
    try {
        let params = {
            'outgoingFundsID': outgoingFundsID
        }

        let response = await genericGetWithParameters('Funds/GetOutgoingFundsVersions', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getIncomingFundsVersionsByDeal(dealHeaderID) {
    try {
        let params = {
            'dealHeaderID': dealHeaderID
        }

        let response = await genericGetWithParameters('Funds/GetIncomingFundsVersionsbyDeal', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getIncomingFundsVersionComparer = async (dealHeaderID, versionNumber1, versionNumber2) => {
    try {
        const params = {
            'dealHeaderID': dealHeaderID,
            'versionNumber1': versionNumber1,
            'versionNumber2': versionNumber2
        };
        const response = await genericGetWithParameters('Funds/GetIncomingFundsVersionComparer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAutoCoverPartners(){
    try{
        let params = {}
        let response = await genericGetWithParameters('Partner/GetAutoCoverPartners',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByPartner(partnerId){
    try{
        let params = {
            'PartnerId': partnerId,
        }
        let response = await genericGetWithParameters('Partner/GetCurrenciesByPartner',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function createInterfacePaymentForCoverBank(InterfaceName, customerId, contactId, userId, dealIds, paymentCurrency, paymentAmount, paymentDate) {
    try {
        let model =
        {
            InterfaceName: InterfaceName,
            CustomerId: customerId,
            ContactId: contactId,
            UserId: userId,
            DealIds: dealIds,
            PaymentCurrency: paymentCurrency,
            PaymentAmount: paymentAmount,
            PaymentDate: paymentDate
        }

        var method = 'POST';
        var apiName = 'Funds/CreateInterfacePaymentForCoverBank'
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
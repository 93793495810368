import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Colors } from '../../constants/Colors';

const PickListControl = ({ title = "", pickList = [], selectedItemsList = [], idSelectedLeft, idSelectedRight, updateSelectedItems, updateIdSelectedLeft, updateIdSelectedRight }) => {
    let listLeft = pickList.filter(x => !selectedItemsList.map(y => y.value).includes(x.value));
    let listRight = selectedItemsList
    return <ul id="accordion-picklist" uk-accordion="multiple: true">
        <li className="">
            <h5 className="uk-accordion-title">{title}</h5>
            <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                <div id="general-information">
                    <div className="uk-grid" style={{ marginTop: "0px" }}>
                        <div className="uk-width-1-1" id="subtype-component">
                            <div
                                className="uk-margin"
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    border: '1px solid #9DA99E'
                                }}
                            >
                                <div
                                    className="uk-width-1-3"
                                    style={{
                                        overflowY: 'scroll',
                                        height: 500,
                                    }}
                                >
                                    <table
                                        className="uk-table uk-table-hover uk-table-divider"
                                        style={{
                                            marginBottom: 0
                                        }}
                                    >
                                        <tbody>
                                            {listLeft.map((item, i) => {
                                                return (
                                                    <tr
                                                        key={item.value} onClick={x => {
                                                            updateIdSelectedLeft(item.value)
                                                        }}
                                                        style={
                                                            idSelectedLeft === item.value ?
                                                                { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                        }
                                                    >
                                                        <td
                                                            style={
                                                                idSelectedLeft === item.value ?
                                                                    { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                            }
                                                        >{item.name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    className="uk-card-body uk-width-1-3"
                                    style={{
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        borderRight: '1px solid #9DA99E',
                                        borderLeft: '1px solid #9DA99E',
                                        height: 500
                                    }}
                                >

                                    <div className="uk-grid"
                                        style={{
                                            justifyContent: 'center'
                                        }}>
                                        <div className="uk-width-1-1 uk-margin-bottom">
                                            <button
                                                className="uk-button uk-button-green"
                                                onClick={x => {
                                                    if (idSelectedLeft !== undefined && idSelectedLeft !== null && listLeft.filter(x => x.value === idSelectedLeft).length === 1) {
                                                        let tmpItem = listLeft.filter(x => x.value === idSelectedLeft)[0]
                                                        listRight.push(tmpItem);
                                                        updateSelectedItems(listRight);
                                                    }
                                                }}>
                                                Add
                                            </button>
                                        </div>
                                        <div className="uk-width-1-1 uk-margin-bottom">
                                            <button
                                                className="uk-button uk-button-green"
                                                onClick={x => { updateSelectedItems(pickList); }}>
                                                Add All
                                            </button>
                                        </div>
                                        <div className="uk-width-1-1 uk-margin-bottom">
                                            <button
                                                className="uk-button uk-button-green"
                                                onClick={x => {
                                                    if (idSelectedRight !== undefined && idSelectedRight !== null && listRight.filter(x => x.value === idSelectedRight).length === 1) {
                                                        let tmpItem = listRight.filter(x => x.value === idSelectedRight)[0]
                                                        listRight = listRight.filter(x => x.value !== tmpItem.value)
                                                        updateSelectedItems(listRight);
                                                    }
                                                }}>
                                                Remove
                                            </button>
                                        </div>
                                        <div className="uk-width-1-1 uk-margin-bottom">
                                            <button
                                                className="uk-button uk-button-green"
                                                onClick={x => { updateSelectedItems([]); }}>
                                                Remove All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="uk-width-1-3"
                                    style={{
                                        overflowY: 'scroll',
                                        height: 500,
                                        paddingLeft: 0,
                                    }}
                                >
                                    <table
                                        className="uk-table uk-table-hover uk-table-divider"
                                        style={{
                                            marginBottom: 0
                                        }}
                                    >
                                        <tbody>
                                            {listRight.map((item, i) => {
                                                return (
                                                    <tr
                                                        key={item.value} onClick={x => {
                                                            updateIdSelectedRight(item.value)
                                                        }}
                                                        style={
                                                            idSelectedRight === item.value ?
                                                                { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                        }
                                                    >
                                                        <td
                                                            style={
                                                                idSelectedRight === item.value ?
                                                                    { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                            }
                                                        >{item.name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
}

PickListControl.propTypes = {
    title: PropTypes.string,
    pickList: PropTypes.array,
    selectedItemsList: PropTypes.array,
    idSelectedLeft: PropTypes.any,
    idSelectedRight: PropTypes.any,
    updateSelectedItems: PropTypes.func,
    updateIdSelectedLeft: PropTypes.func
};

const PickList = (props) => {

    const [idSelectedLeft, setIdSelectedLeft] = useState("");
    const [idSelectedRight, setIdSelectedRight] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [reloadControl, setReloadControl] = useState(true);

    useEffect(() => {
        setReloadControl(false);
    }, []);

    useEffect(() => {
        if (props.picked != null && props.picked.length >= 0) {
            let picked = props.picked.sort((x, y) => x.value > y.value);
            setSelectedItems(picked);
        }
    }, [props.picked]);

    const updateSelectedItems = (selectedItems) => {
        setSelectedItems([])
        selectedItems = selectedItems.sort((x, y) => x.value > y.value)
        setSelectedItems(selectedItems)
        setReloadControl(true)
        props.updateSelectedItems(selectedItems)
    }

    const updateIdSelectedLeft = (idSelectedLeft) => {
        setIdSelectedLeft(idSelectedLeft)
    }

    const updateIdSelectedRight = (idSelectedRight) => {
        setIdSelectedRight(idSelectedRight)
    }

    return <PickListControl title={props.title} pickList={props.pickList}
        selectedItemsList={selectedItems}
        idSelectedLeft={idSelectedLeft}
        idSelectedRight={idSelectedRight}
        updateSelectedItems={updateSelectedItems}
        updateIdSelectedLeft={updateIdSelectedLeft}
        updateIdSelectedRight={updateIdSelectedRight}
        reloadControl={reloadControl}
    />
}

export default PickList
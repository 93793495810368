import React, { useEffect, useLayoutEffect, useState } from 'react';
import { getStyle } from '../../../styles/styles';
import { getAllRatesMonitor, getPopUpRateMonitor } from '../../../networking/NetworkingRates';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { formatDateString } from '../../../helpers/FormatHelper';
import RateMonitorForm from './RateMonitorForm';
import { getLookUpTable } from '../../../networking/Networking';
import DynamicSelect from '../../shared/DynamicSelect';
import { Button, Input, Space, Table } from 'antd';
import { useRef } from 'react';
import { SearchOutlined } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import TheCSVButton from '../../shared/TheCSVButton';
import NewEditableGrid from '../../shared/NewEditableGrid';
const style = getStyle();

const  RateMonitoringScreen  = ()=> {

        const[objAllRates, setObjAllRates] = useState([])
        const [showRateMonitor, setShowRateMonitor]=useState(false)
        //Rate PopUP
        const [objRatePopUp, setObjRatePopUp] = useState(null)
        const [customer, setCustomer] = useState('All customers')
        const [status, setStatus] = useState('Pending')
        const lookUpTables = [
                                        "Rate Watch Customer Filters",
                                        "Rate Activity Status"
                                    ];
        const [rateWatchCustomerFilters, setRateWatchCustomerFilters]=useState([])
        const [rateActivityStatus, setRateActivityStatus]=useState([])
        

        const [columnDetail, setColumnDetail] = useState(null);

        let timerID = 0;

  const getAllRatesMonitorScreen = () => {
    let date = new Date();
    const traderUserID = customer === "My customers" ? localStorage.getItem('UserID'): '';
    const statusQuery = status === "All"? '': status; 
    
    getAllRatesMonitor(formatDateString(date), traderUserID, statusQuery).then(
        (json) => {
            if (json != null) {
                if (json.result != null) {
                    setObjAllRates(json.result);
                    if (json.columnDetail != null) {
                        const _columnDetail = JSON.parse(json.columnDetail);
                        setColumnDetail(_columnDetail);
                    }
                } else {
                    setObjAllRates([]);
                }
            }
        }
    );
}

    useEffect(()=> {
        getLookUpTable(lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(lookUpTables[i]);
                    if (index > -1){
                        let tableResult = json.lookUpTables[index].lookUpTableDetails;
                        let table = tableResult.filter(l => !l.LookUpTableDetail.includes('Select '));
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        if(lookUpTables[i] === "Rate Watch Customer Filters")
                        {
                            setRateWatchCustomerFilters(table);
                        }
                        else
                        {
                            setRateActivityStatus(table)
                        }
                    }
                }
        });

        getAllRatesMonitorScreen()
        timerID = setInterval(async () => { getPopUpRateMonitorScreen()}, 5000);
    },[])

    useEffect(() => {
        return () => {
            clearInterval(timerID);
        }
      }, [])

    const getPopUpRateMonitorScreen = () => {
        let username = localStorage.getItem('UserName');
        getPopUpRateMonitor(username).then(
            (json) => {
                if (json.httpStatusCode === 200) {
                    if (json.RateActivityRate != null) {
                        clearInterval(timerID);
                        setObjRatePopUp(json);
                        setShowRateMonitor(true);
                    }
                }
            }
        );
    }

    const onRefresh = () => {
        getAllRatesMonitorScreen();
    }

    const toggleShowRateMonitor = () => {
        timerID = setInterval( async () => {getPopUpRateMonitorScreen()}, 5000);

        setShowRateMonitor(!showRateMonitor);       

        getAllRatesMonitorScreen();
    }

    const getCustomerFilterSelect =(value) =>{
        if(value)
        {
            if(value.LookUpTableDetail)
            {
                setCustomer(value.LookUpTableDetail);
            }
            else
            {
                setCustomer(value);
            }
        }
    }

    const getStatusFilterSelect =(value) =>{
        if(value)
        {
            if(value.LookUpTableDetail)
            {
                setStatus(value.LookUpTableDetail);
            }
            else
            {
                setStatus(value);
            }
        }
    }
       

        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >Rate Monitoring</h3>
                <div className="uk-grid" style={{ marginTop: '20px' }}>
                    <div className="uk-width-1-4 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer Filter</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" 
                                getValue={getCustomerFilterSelect} 
                                data={rateWatchCustomerFilters} 
                                id="customer-filter" 
                                objValue={{value: customer}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Status</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" 
                                getValue={getStatusFilterSelect} 
                                data={rateActivityStatus} 
                                id="status-filter" 
                                objValue={{value: status}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin" style={{ marginTop: '10px' }}>
                            <button Name="btnRefresh" className="uk-button uk-button-default uk-button-green" onClick={onRefresh}>Refresh</button>
                        </div>
                    </div>
                </div>
                <hr />
                <NewEditableGrid
                    data={objAllRates}
                    columnDetails={columnDetail}
                    isEditable={false}
                    useIcons={true}
                    useExportButton = {true}
                    tableName = "rate_monitor"  />
                
                <RateMonitorForm objRatePopUp = {objRatePopUp} open={showRateMonitor} close={toggleShowRateMonitor} />
            </div>
        );
    
                }

export default RateMonitoringScreen;
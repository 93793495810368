import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getStyle } from '../../../../styles/styles';
import { formatValidInput } from '../../../../helpers/FormatHelper';
import AlertBox from '../../../shared/AlertBox';
import { validate } from '../../../../helpers/ValidateHelper';
import { getNewBeneficiaryEmail } from '../../../../networking/NetworkingBeneficiaries';

var style = getStyle();
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class DialogBeneficiaryInvitation extends React.Component {
    state = {
        email: '',
        beneficiaryName: '',
        validations: {
            email: true,
            beneficiaryName: true
        },
        showAlert: false,
        showAlertResponse: false,
        alertMessage: '',
        alertTitle: ''
    }
    componentDidMount() {
        this.validateRequiredInputs();
    }
    handleUpdateBeneficiaryName = (event) => {
        this.setState({
            beneficiaryName: event.target.value,
            validations: { ...this.state.validations, beneficiaryName: true }
        }, () => this.validateRequiredInputs());
    }
    handleUpdateEmail = (event) => {
        this.setState({
            email: event.target.value,
            validations: { ...this.state.validations, email: true }
        }, () => this.validateRequiredInputs());
    }
    validateRequiredInputs = () => {
        let fields = [
            { displayName: 'Beneficiary Name', validateName: 'beneficiaryName', value: this.state.beneficiaryName, type: 'string' },
            { displayName: 'Email', validateName: 'email', value: this.state.email, type: 'email' }
        ];
        const [valid, validations, errors] = validate(fields);
        this.setState({ valid, errors })
        return { valid, errors };
    }
    validateRequiredFields = () => {
        let fields = [
            { displayName: 'Beneficiary Name', validateName: 'beneficiaryName', value: this.state.beneficiaryName, type: 'string' },
            { displayName: 'Email', validateName: 'email', value: this.state.email, type: 'email' }
        ];
        const [valid, validations, errors] = validate(fields);
        this.setState({ validations, errors })
        return { validations, errors };
    }
    handleSendMailClick = () => {
        if (this.state.valid) {
            getNewBeneficiaryEmail(this.props.id, this.props.PersonID, this.state.beneficiaryName, this.state.email).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        this.setState({ showAlertResponse: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to send email. Please try again..' })
                    } else {
                        this.setState({ showAlertResponse: true, alertTitle: 'Success', alertMessage: 'Send Beneficiary invitation successfully.' });
                    }
                }
            );
        } else {
            this.setState({ showAlertResponse: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:' }, () => { this.validateRequiredFields() })
        }
    }
    clearData = () => {
        this.setState({
            beneficiaryName: '',
            email: '',
            validations: {
                email: true,
                beneficiaryName: true
            },
            showAlert: false,
            alertMessage: '',
            alertTitle: ''
        });
    }
    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' })
    }
    closeAlertResponse = () => {
        this.setState({ showAlertResponse: false, alertMessage: '', alertTitle: '' }, this.closeDialog())
    }
    closeDialog = () => {
        this.clearData();
        this.props.close();
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.closeDialog} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle id="attachments-dialog-title" onClose={this.closeDialog}>
                    Beneficiary Invitation
                </DialogTitle>
                <DialogContent dividers>
                    <div className="">
                        <div className="uk-form-stacked">
                            <label className="uk-form-label">Beneficiary Name (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={formatValidInput(this.state.validations.beneficiaryName)} id="beneficiaryName" type="text" value={this.state.beneficiaryName} onChange={this.handleUpdateBeneficiaryName} />
                            </div>
                        </div>
                        <div className="uk-form-stacked">
                            <label className="uk-form-label">Email (*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={formatValidInput(this.state.validations.email)} id="email" type="text" value={this.state.email} onChange={this.handleUpdateEmail} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendMailClick} type="button">Send</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.closeDialog} type="button">Cancel</button>
                    </div>
                </DialogContent>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} messageBoldList={this.state.errors} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertResponse} onClose={this.closeAlertResponse} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertResponse} />
            </Dialog>
        );
    }
}

import React from 'react';
import CsvDownload from 'react-json-to-csv';
import { getStyle, regularInputClass } from '../../../../src/styles/styles';
import NewDynamicTable from '../../../components/shared/NewDynamicTable';
import DatePicker from '../../../components/shared/DatePicker';
import { formatDate, formatToCurrency } from '../../../../src/helpers/FormatHelper'
import { getHistoryAuditFundsOnHoldBalances } from '../../../../src/networking/NetworkingFunds';
var style = getStyle();

export default class HistoryBalanceScreen extends React.Component {

    state = {
        currency: '',
        customerID: 0,
        historyBalanceList: [],
        newSearch: false,
        filter: false,
        fromDate: '', 
        toDate: '', 
        beginningBalance: '',
        currentBalance: ''
    }
    
    componentDidMount() {
        let customerID=this.props.customerID;
        let currency=this.props.currency;
        let currentBalance=this.props.currentBalance;
        let fromDate=formatDate(new Date().setMonth(new Date().getMonth() - 6));
        let toDate=formatDate(new Date());

        this.setState({
            customerID: customerID,
            currentBalance: currentBalance,
            currency: currency,
            fromDate: fromDate,
            toDate: toDate,
            newSearch: true,
            //loading: false,
            filter: true,
            /*heldFundsBalances,
            undisbursedDeals,
            undisbursedForwards,
            unpaidDeals,
            totalPagesUnpaidDeals,
            totalPagesUndisbursedDeals,
            totalPagesUndisbursedForwards,
            totalPagesHeldFundBalances,*/
        });
        
        this.GetHistoryBalance(customerID, currency, fromDate, toDate);
    }
    
    GetHistoryBalance = (customerID, currencyID, fromDate, toDate) => {
        getHistoryAuditFundsOnHoldBalances(customerID, currencyID, fromDate, toDate).then((response) => {
            if (response != null && response.httpStatusCode === 200 && response.historyFunds != null) {
              let begBalance = response.historyFunds[0]['Balance'] - response.historyFunds[0]['TransactionAmount'];
              let list = response.historyFunds;
              var helper = [];
              for (var i = 0; i < list.length; i++) {
                helper.push({ 
                    "Date" : formatDate(list[i]["UpdatedOn"]),
                    "Transaction Amount" : list[i]["TransactionAmount"],
                    "Balance" : list[i]["Balance"],
                    "Source" : list[i]["Source"],
                    "Destination" : list[i]["Destination"],
                    "Transaction ID" : list[i]["AuditFundsOnHOldBalancesID"],
                    "User Name" : list[i]["User Name"],
                });
              }

                this.setState({
                    historyBalanceList: helper,
                    beginningBalance: begBalance,
                });
            }else{
                this.setState({
                    beginningBalance: '0',
                });
            }
          });
    }
    
    changePageHistoryBalances = (value) => {
        this.setState({ pagesHistoryBalances: value });
    }

    handleUpdateFromDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fromDate: value
        });
    }

    handleUpdateToDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            toDate: value
        });
    }
    
    handleResetClick = () => {    
        let fromDate=formatDate(new Date().setMonth(new Date().getMonth() - 6));
        let toDate=formatDate(new Date());   
        this.setState({ 
            fromDate: fromDate,
            toDate: toDate
        });
    }
    
    handleApplyFilterClick = () => {
        let customerID=this.state.customerID;
        let currency=this.state.currency;
        let fromDate=this.state.fromDate;
        let toDate=this.state.toDate;
        this.GetHistoryBalance(customerID, currency, fromDate, toDate);
    }

    render() {
        return(
            <div className="uk-margin-xlarge-bottom">
                <div className="uk-grid" style={{ marginTop: "0px" }}>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 10 }}>From Date</label>
                        <DatePicker
                            className={regularInputClass}
                            readOnly={true}
                            value={this.state.fromDate}
                            onDayChange={this.handleUpdateFromDate}
                        />
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 10 }}>To Date</label>
                        <DatePicker
                            className={regularInputClass}
                            readOnly={true}
                            value={this.state.toDate}
                            onDayChange={this.handleUpdateToDate}
                        />
                    </div>   
                </div>             
                <div className="uk-margin-top">
                        <CsvDownload 
                        data={this.state.historyBalanceList}
                        filename="HistoryBalance.csv"
                        className="uk-button uk-button-green uk-float-right">Export</CsvDownload>
                    <button className="uk-button uk-button-green" onClick={this.handleResetClick}>Reset</button>
                    <button className="uk-button uk-button-green" onClick={this.handleApplyFilterClick}>Apply Filter</button>
                </div>
                <div className="uk-margin-top">
                    {/* <label className='uk-form-label' style={{ marginRight: '50px' }}>{'Initial Balance ' + formatToCurrency(this.state.beginningBalance, this.state.currency) + ' ' + this.state.currency}</label> */}
                    <label className='uk-form-label'>{'Current Balance ' + this.state.currentBalance + ' ' + this.state.currency}</label>
                </div>
                <NewDynamicTable id="held-history-balances"
                    //hiddenHeaders={['Date','Transaction Amount','Balance','Source','Destination','Transaction ID','User Name']} 
                    data={this.state.historyBalanceList}
                    newSearch={this.state.newSearch}
                    useDeleteButton={false}
                    usePaginator={false}
                    //changePage={this.changePageHistoryBalances}
                    //numberOfPages={this.state.totalPagesHeldFundBalances}
                    //pageNumber={this.state.pagesHeldFundBalances}
                    //numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={this.state.filter}
                    //filterFunction={() => this.getCustomerData('Held Funds Balances')}
                />
            </div>
        );
    }
}
import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody, decrypt } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getCommentById(id, type){
    try {
        let params = {
            'Id': id,
            'Type': type
        }
        let response = await genericGetWithParameters('Comment/GetCommentById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveComment(model) {
    try {
        var method = 'POST';
        var apiName = 'Comment/SaveComment'

        let responseJWT = await genericCallWithBody(method, apiName, model);
        if(responseJWT.httpStatusCode === 200)
        {
            let response = await decrypt(responseJWT);
            return response;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getSuspiciousComments(dealId){
    try{
        let params = {
            dealId: dealId
        }

        let response = await genericGetWithParameters('Comment/GetSuspiciousComments',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function saveSuspiciousComment(model) {
    try {
        var method = 'POST';
        var apiName = 'Comment/SaveSuspiciousComment'

        let responseJWT = await genericCallWithBody(method, apiName, model);
        if(responseJWT.httpStatusCode === 200)
        {
            let response = await decrypt(responseJWT);
            return response;
        }else
        {
            return responseJWT;
        }
    } catch (error) {
        console.error(error);
    }
}
export const GetAmounts = (buyCurrency, sellCurrency, rate, sellAmountIn, buyAmountIn, calculateSellAmount) => {
    
    let currencyPairs = JSON.parse(localStorage.getItem('CurrencyPairs'));
    let pair = currencyPairs.find(c => c.CurrencyCode === buyCurrency && c.OutCurrencyCode === sellCurrency);
    let rateTerms = (pair !== undefined ? pair.RateTerms : '')

    if (pair === '' || rate === undefined) return 0

    var amountOut = 0;
    if (rateTerms == 'D') {
        if (calculateSellAmount) {
            if (rate == 0) {
                amountOut = 0;
            }
            else {
                amountOut = buyAmountIn / rate;
            }
        }
        else {
            amountOut = sellAmountIn * rate;
        }
    }
    else
        if (rateTerms == 'I') {
            if (calculateSellAmount) {
                amountOut = buyAmountIn * rate;
            }
            else {
                if (rate != 0)
                    amountOut = sellAmountIn / rate;
                else
                    amountOut = 0;
            }
        }

    return amountOut
}

export const getCurrencyPairLabel = (cc1, cc2, currencyPairs) =>
{
    if(cc1 && cc2)
    {
        let ccyPairs = currencyPairs;
        let pair = ccyPairs.find(p => p.CurrencyCode === cc1 && p.OutCurrencyCode === cc2);

        if(pair && pair.PrimaryCurrency && pair.SecondaryCurrency)
        {
            return `${pair.PrimaryCurrency} / ${pair.SecondaryCurrency}`
        }
        else
        {
            return `${cc1} / ${cc2}`
        }
    }
    else
    {
        return ''
    }
}
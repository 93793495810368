/* eslint-disable default-case */
import React from 'react';


import { getStyle } from '../../../../styles/styles';

import { getLookUpTable } from '../../../../networking/Networking';
import { getCurrenciesByCountry, saveCurrenciesByCountry, getCurrencyList } from '../../../../networking/NetworkingCurrencies';

import DynamicSelect from '../../../shared/DynamicSelect';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import { getProductsByCountry, saveProductsByCountry } from '../../../../networking/NetworkingCountries';
import {Colors} from '../../../../constants/Colors'

const textCanPayUs = 'Currencies the customer can pay us';
const textCanPurchaseFromUs = 'Currencies the customer can purchase from us';

var style = getStyle();
export default class ProductsScreen extends React.Component {
    state = {
        loading: false,
        errorMessage: '',
        showErrorAlert: false,

         //Products
         selectedIDRowLeftProduct   : '',   //Seleccionado de lista Izquierda  
         selectedIDRowRightProduct  : '',   //Seleccionado de lista Derecha
         currencyListHaveShow        : [],   
         productListHaveProduct  : [],
         productListWantShow        : [],
         productListWantProduct  : [],
         
        dropdowns: { 'Products': [] },
        lookUpTables: ['Products'],
    }

    componentDidMount = () => {
        getLookUpTable(this.state.lookUpTables.toString()).then((json) => 
        {
            for (var i = 0; i < this.state.lookUpTables.length; i++)
            {
                let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                if (index > -1)
                {
                    var table = json.lookUpTables[index].lookUpTableDetails;
                    table.splice(0, 1);//Elimina el (-1) Select...
                    for (var j = 0; j < table.length; j++)
                    {
                        table[j].ProductID = table[j].LookUpTableDetail;
                        table[j].ProductIDName = table[j].LookUpTableDetail;
                    }
                    this.setState({
                        //[this.state.lookUpTables[i]]: table,
                        productListHaveProduct: table,
                    });
                    
                }
            }
        });
        
        getProductsByCountry(this.props.countryID).then(
            (jsonResponse)=>{
                if (jsonResponse.products != null) {
                    
                    let products = jsonResponse.products
                    //{ "ProductID": 1, "ProductIDName": "Open Forwards" }
                    var wantlistProduct = this.state.productListWantProduct
                    var havelistProduct = this.state.productListHaveProduct

                    for (var i = products.length - 1; i >= 0; i--) {
                        for (var j = 0; j < wantlistProduct.length; j++) {
                            if (products[i] && (products[i].ProductID === wantlistProduct[j].ProductID)) {
                                products.splice(i, 1);
                            }
                        }
                    }
                    for (var k = havelistProduct.length - 1; k >= 0; k--) {
                        for (var l = 0; l < products.length; l++) {
                            if (havelistProduct[k] && (havelistProduct[k].ProductID === products[l].ProductID)) {
                                havelistProduct.splice(k, 1);
                            }
                        }
                    }
                    this.setState({
                        productListWantProduct: products
                    });
                }
            }
        )

    }


    // product
    productChangeColorLeft = selectedIDRowLeftProduct => e => {
        if (selectedIDRowLeftProduct !== undefined) {
          this.setState({ selectedIDRowLeftProduct  });
        }
    };
    
    productChangeColorRight = selectedIDRowRightProduct => e => {
        if (selectedIDRowRightProduct !== undefined) {
          this.setState({ selectedIDRowRightProduct  });
        }
    };

    //PRODUCT - BUTTONS -------------------------------------------------------------
    productAddItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        listLeft = this.state.productListHaveProduct
        listRight = this.state.productListWantProduct

        var idSelected = this.state.selectedIDRowLeftProduct;
        var add = true
        
        if(idSelected !== '')
        {
            if(listRight.length === 0)
            {
                listLeft.forEach((product, index) => {
                    if(product.ProductID === idSelected)
                    {
                        listRight.push(product)
                        listLeft.splice(index,1)
                    }

                    this.setState({
                        productListWantProduct:  listRight,
                        productListHaveProduct: listLeft,
                        selectedIDRowLeftProduct: ''
                    });
                });
                
            }else
            {
                listRight.forEach(rightProduct => {
                    if(rightProduct.ProductID === idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftProduct, index) => {
                            if(leftProduct.ProductID === idSelected)
                            {
                                listRight.push(leftProduct)
                                listLeft.splice(index,1)

                                this.setState({
                                    productListWantProduct:  listRight,
                                    productListHaveProduct: listLeft,
                                    selectedIDRowLeftProduct: ''
                                });
                            }
                        });
                    }
            }
        }
    }

    productRemoveItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        
        listLeft = this.state.productListHaveProduct
        listRight = this.state.productListWantProduct

        var idSelected = this.state.selectedIDRowRightProduct
        var add = true
        if(idSelected !== '')
        {
            listRight.forEach((rightProduct, index) => {
                if(rightProduct.ProductID === idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightProduct)
                }
            });

            this.setState({
                productListWantProduct:  listRight,
                productListHaveProduct: listLeft,
                selectedIDRowRightProduct: ''
            });
        }
    }

    productAddAllItems = () =>
    {
        var listLeft = [];
        var listRight = [];

        
        listLeft = this.state.productListHaveProduct
        listRight = this.state.productListWantProduct
        
        Array.prototype.push.apply(listRight,listLeft)

        
        this.setState({
            productListWantProduct:  listRight,
            productListHaveProduct: [],
            selectedIDRowLeftProduct: '',
            selectedIDRowRightProduct: '',
        });
    }
    
    productRemoveAll = () =>
    {
        var listLeft = [];
        var listRight = [];

        
        listLeft = this.state.productListHaveProduct
        listRight = this.state.productListWantProduct
        
        Array.prototype.push.apply(listLeft,listRight)

        this.setState({
            productListWantProduct:  [],
            productListHaveProduct: listLeft,
            selectedIDRowLeftProduct: '',
            selectedIDRowRightProduct: '',
        });
    }

    handleSaveClick = (event) => {
        event.preventDefault();
        var productsList = this.state.productListWantProduct;
        var ProductsJson = [];

        for (var i = 0; i < productsList.length; i++) {
            ProductsJson.push({
                'CountryID': this.props.countryID,
                'Type': productsList[i].ProductID});
        }
        var objFilter={
            'CountryID': this.props.countryID,
            'ProductsJson': ProductsJson,
            'UserID': localStorage.getItem('UserID'),
        }
        saveProductsByCountry(objFilter).then(
            (json)=>{
                console.log(json);
                if(json.httpStatusCode!==200){
                    this.setState({showAlert: true, alertTitle: 'An error occurred while trying to save the Country Products.'})
                }else{
                    this.setState({showAlert: true,alertTitle:'Successfully', alertMessage: 'Country Products successfully saved.'});
                }
            }
        );
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    render(){
        var losQueHayAhora  = []
        var losQueQuiero    = [];
        
        losQueHayAhora = this.state.productListHaveProduct
        losQueHayAhora.sort(this.dynamicSort("ProductID"));
        losQueQuiero = this.state.productListWantProduct
        losQueQuiero.sort(this.dynamicSort("ProductID"));

        return(
            <div>
                <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <h3 class="uk-width-1-3" style={{marginLeft: 20}}>Available Products</h3>
                        <h3 class="uk-width-1-3"></h3>
                        <h3 class="uk-width-1-3">Assigned Products</h3>
                    </div>
                    <div
                        className=""
                        style={{
                            //backgroundColor: 'red',
                            alignItems: 'center',
                            display: 'flex',
                            border:'1px solid #9DA99E'
                        }}
                    >
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                <tbody>
                                    {losQueHayAhora.map((item, i) => {
                                        return (
                                        <tr 
                                            key={item.ProductID} 
                                            onClick={this.productChangeColorLeft(item.ProductID)}
                                            onDoubleClick={this.productAddItemSelected.bind(this)}
                                            style={
                                                this.state.selectedIDRowLeftProduct === item.ProductID ? 
                                                {backgroundColor: Colors.primaryColor, cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'} 
                                            }
                                        >
                                            <td
                                                style={
                                                    this.state.selectedIDRowLeftProduct === item.ProductID ? 
                                                    {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                }
                                            >{item.ProductIDName}</td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div 
                            className="uk-card-body uk-width-1-3"
                            style={{
                                //backgroundColor: 'blue',
                                textAlign:'center',
                                //width: '33%',
                                borderRight:'1px solid #9DA99E',
                                borderLeft:'1px solid #9DA99E'
                            }}
                        >
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.productAddItemSelected.bind(this)}
                            >
                                Add
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.productAddAllItems.bind(this)}
                            >
                                Add all
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.productRemoveItemSelected.bind(this)}
                            >
                                Remove
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2" /*onClick={this.handleLoginClick.bind(this)}*/
                                style={{
                                    
                                }}
                                onClick={this.productRemoveAll.bind(this)}
                            >
                                Remove all
                            </button>
                        </div>
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                paddingLeft: 0,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                    <tbody>
                                        {losQueQuiero.map((item, i) => {
                                            return (
                                            <tr 
                                                key={item.ProductID} 
                                                onClick={this.productChangeColorRight(item.ProductID)} 
                                                onDoubleClick={this.productRemoveItemSelected.bind(this)}
                                                style={
                                                    this.state.selectedIDRowRightProduct === item.ProductID ? 
                                                    {backgroundColor: '#c3824a', cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'}
                                                }
                                            >
                                                <td
                                                    style={
                                                        this.state.selectedIDRowRightProduct === item.ProductID ? 
                                                        {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                    }
                                                >{item.ProductIDName}</td>
                                            </tr>
                                            );
                                        })}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                <div>
                    <button className="uk-button uk-button-green"style={{marginTop: 20}} onClick={this.handleSaveClick} >Save</button>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }
}
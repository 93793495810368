import React from 'react';
import EntityTasks from '../../../shared/EntityTasks';
import {BeneficiaryType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class BeneficiaryTasks extends React.Component {
    state = {
        beneficiaryID: 0,
        entityTasksSaveProcess: false,
    }

    onFinishEntityTasksSaveProcess(){
        this.props.getSaveTasks(false);
    }

    render(){
        var section = (
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityTasks fullScreen={true} entityType={BeneficiaryType} entityID={this.props.beneficiaryID} personID={this.props.personID} runSaveProcess={this.props.saveTasks} finishSaveProcess={this.onFinishEntityTasksSaveProcess.bind(this)} />
                    </div>
                </form>
            )
        if(this.props.beneficiaryID === ''){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create beneficiary first.</h5>
                </div>
            )
        }
        return(
            <div>
            {section}
            </div>
        );
    }
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DynamicSelect from '../../shared/DynamicSelect';
import { DialogActions } from '@material-ui/core';

const EmailDetailDialog = ({ showModal, closeModal, emailDetail }) => {

    const onAttachmentClick = (attachment) => {
        /*
        Key: "Deal Confirmation - 12169.pdf"
        Value
        */
        let arrayBuffer = new Uint8Array(attachment.Value);
        let blob = new Blob([arrayBuffer], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = attachment.Key;
        link.download = fileName;
        link.click();
    }

    // const getStatusEmail = (value) => {
    //     if (value != null && value !== 'select' && value.value !== undefined) {
    //         setEmailDetail({ ...this.state.emailDetail, Status: value.value })
    //     }
    // }

    return (
        <Dialog
                    open={showModal}
                    onClose={closeModal}
                    fullWidth={true}
                    maxWidth='sm'
                >
                    <DialogTitle id="emailscreen-dialog-gwlatr-title" onClose={closeModal}>
                        {emailDetail.EmailSubject}
                    </DialogTitle>
                    <DialogContent dividers>
                        <form className="uk-form-stacked uk-grid">
                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email ID</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" disabled type="text" value={emailDetail.EmailId} />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" >Status</label>
                                <div className="uk-form-controls">
                                    {/* <DynamicSelect className="uk-select" getValue={getStatusEmail} objValue={{ value: emailDetail.Status }} data={statusList} id="form-email-status" /> */}
                                    <input className="uk-input" disabled type="text" value={emailDetail.Status} />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" >Created On</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" disabled type="text" value={emailDetail.CreatedOn} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email Receiver</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" disabled type="text" value={emailDetail.EmailReceiver} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email Cc</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" disabled type="text" value={emailDetail.EmailCc} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email BCc</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" disabled type="text" value={emailDetail.EmailBCc} />
                                </div>
                            </div>

                            {emailDetail != null && emailDetail.Attachments != null && emailDetail.Attachments.length > 0 &&
                                (<div className="uk-width-2-2 uk-heading-divider">
                                    <label className="uk-form-label" >Attachments</label>
                                        <div className="uk-form-controls">
                                            {emailDetail.Attachments.map((attachment) => (
                                                <a onClick={() => onAttachmentClick(attachment)} className="uk-form-label">
                                                    <i class="fa fa-paperclip" aria-hidden="true">
                                                    </i>{attachment.Key}
                                                </a>
                                            ))}
                                        </div>
                                </div>)
                            }

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Content</label>
                                <div className="uk-form-controls">
                                    <text style={{ backgroundColor: '#ffffff' }}>
                                        {emailDetail.Body}
                                    </text>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {/* <button
                        className="uk-button uk-button-green"
                        key={"Yes"}
                        type="button"
                        onClick={this.handleUpdateEmail}>Update</button> */}
                        <button
                            className="uk-button uk-button-green"
                            key={"Yes"}
                            type="button"
                            onClick={closeModal}>Ok</button>
                    </DialogActions>
                </Dialog>
    );
}

export default EmailDetailDialog;

export const GlobalSystemParametersNames = {
    SystemDate: "SystemDate",
    HomeCurrency: "HomeCurrency",
    BaseCurrency: "BaseCurrency",
    BaseCurrencyName: "BaseCurrencyName",
    DefaultSellCurrencyID: "DefaultSellCurrencyID",
    DefaultBuyCurrencyID: "DefaultBuyCurrencyID",
    MaxSpotDays: "MaxSpotDays",
    NoneMaxSpotDays: "NoneMaxSpotDays",
    WorkSunday: "WorkSunday",
    WorkSaturday: "WorkSaturday",
    NumberOfDaysForDealAverages: "NumberOfDaysForDealAverages",
    DefaultOptionsCoverBank: "DefaultOptionsCoverBank",
    QuoteRetentionPeriod: "QuoteRetentionPeriod",
    AllowSwapDealsInTraderBlotter: "AllowSwapDealsInTraderBlotter",
    AmountFormat: "AmountFormat",
    PercentageFormat: "PercentageFormat",
    RateFormat: "RateFormat",
    RateExpirationPeriod: "RateExpirationPeriod",
    OnlineQuotesRetrieval: "OnlineQuotesRetrieval",
    DesktopRateFeed: "DesktopRateFeed",
    DefaultPaymentInstrument: "DefaultPaymentInstrument",
    DefaultOutgoingInstrument: "DefaultOutgoingInstrument",
    DefaultOutgoingDeliveryMethod: "DefaultOutgoingDeliveryMethod",
    DealCostAmount: "DealCostAmount",
    DealCostCurrency: "DealCostCurrency",
    LastClosingDate: "LastClosingDate",
    NumberOfDaysForDealSearch: "NumberOfDaysForDealSearch",
    AllowCashForSameCCYDeal: "AllowCashForSameCCYDeal",
    NumberOfDaysToCreateBaseDDForEOD: "NumberOfDaysToCreateBaseDDForEOD",
    NumberOfDaysToCreateNoneBaseDDForEOD: "NumberOfDaysToCreateNoneBaseDDForEOD",
    ProfitRoundingMargin: "ProfitRoundingMargin",
    DatabaseBackupType: "DatabaseBackupType",
    DatabaseBackupPath: "DatabaseBackupPath",
    DefaultPandLRate: "DefaultPandLRate",
    RateWatchExpirationMonths: "RateWatchExpirationMonths",
    RateWatchExpirationLimitMonths: "RateWatchExpirationLimitMonths",
    NotifyWhenEmailHasFailed: "NotifyWhenEmailHasFailed",
    B2BTab: "B2BTab",
    B2BDrawdownAutoUpdate: "B2BDrawdownAutoUpdate",
    OptionsTab: "OptionsTab",
    HolidayTypeNameForEOD: "HolidayTypeNameForEOD",
    HolidayTypeNameForCcy: "HolidayTypeNameForCcy",
    MarginCallBreakPeriod: "MarginCallBreakPeriod",
    MarginCallPeriodPercentage: "MarginCallPeriodPercentage",
    MarginCallEmails: "MarginCallEmails",
    TradingCustomerContactMandatory: "TradingCustomerContactMandatory",
    ConvenientOpenForwardRate: "ConvenientOpenForwardRate",
    OnlineDefaultPassword: "OnlineDefaultPassword",
    DeliveryMethodsLinkedToInstrumentLiveDate: "DeliveryMethodsLinkedToInstrumentLiveDate",
    EnableSTP: "EnableSTP",
    STPLowerLimit: "STPLowerLimit",
    STPUpperLimit: "STPUpperLimit",
    RiskScoreNoticeEmail: "RiskScoreNoticeEmail",
    VostroConversionDate: "VostroConversionDate",
    EnablePaymentConfirmationPrompt: "EnablePaymentConfirmationPrompt",
    EnableSavePendingPayments: "EnableSavePendingPayments",
    PLPositionPeriods:'PLPositionPeriods',
    EnableFundsOnHoldInPaymentScreen:'EnableFundsOnHoldInPaymentScreen',
    EnforceCreditLimits: 'EnforceCreditLimits',
    TradeScreenAllowAdditionalSettlements: 'TradeScreenAllowAdditionalSettlements',
    EnableButtonBeneficiarySaveInAfex: 'EnableButtonBeneficiarySaveInAfex',
    NostroAccountGuid: 'NostroAccountGuid',
    ForwardPointsScale: 'ForwardPointsScale',
    MaximumOpenForwardWindow: 'MaximumOpenForwardWindow',
    EnableButtonBeneficiarySaveInInterface: 'EnableButtonBeneficiarySaveInInterface',
    EnableButtonBeneficiarySaveInOpenPayd: 'EnableButtonBeneficiarySaveInOpenPayd',
    EnableContactVerificationInterface: 'EnableContactVerificationInterface',
    ContactVerificationInterface: 'ContactVerificationInterface',
    BeneficiaryHandlingMethodBackend: 'BeneficiaryHandlingMethodBackend'
}
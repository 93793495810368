import React from 'react';
import { GetEmails, DeleteEmail, GetEmailById, UpdateEmail } from '../../../networking/NetworkingEmails'
import AlertBox from '../../shared/AlertBox'
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
import { getStyle, regularInputClass, regularSelectClass } from '../../../styles/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions } from '@material-ui/core';
import DynamicSelect from '../../shared/DynamicSelect';
import AppContext from '../../../AppContext';

let globalFunctions = {};
var style = getStyle();
const itemsPerPage = getDefaultPagingNumberOfItems();

export default class EmailsScreen extends React.Component {
    static contextType = AppContext;
    state = {
        emailsList: [],
        totalPages: 0,
        pages: 1,
        newSearch: false,
        filter: false,
        showDeleteAlert: false,
        showErrorAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteEmail: {},
        showModal: false,
        emailDetail: {},
        statusList: [{ value: 'ERROR', name: 'ERROR' }, { value: 'UNSEND', name: 'UNSEND' }, { value: 'SEND', name: 'SEND' }],
        emailstatus: '',
        attachmentName: ''
    }

    componentDidMount() {
        globalFunctions = this.context;
        GetEmails().then(
            (json) => {
                if (json !== undefined && json.totalCount > 0) {
                    const totalCount = json.totalCount;
                    const totalPages = Math.ceil(totalCount / itemsPerPage);
                    this.setState({ emailsList: json.result, totalPages, newSearch: true, filter: true });
                } else {
                    this.setState({ newSearch: true, filter: false });
                }
            }
        );
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    updatePage = async () => {
        const emails = await this.getEmailList();
        const totalCount = emails.length;
        const totalPages = Math.ceil(totalCount / itemsPerPage);
        this.setState({ emailsList: emails, totalPages, newSearch: true, filter: true });
    }

    getEmailList = async () => {
        const json = await GetEmails();
        if (json !== undefined && json.result !== undefined) {
            return json.result;
        } else {
            return [];
        }
    }

    handleUpdateEmail = (obj) => {
        var request = {
            EmailId: this.state.emailDetail.EmailId,
            Status: this.state.emailDetail.Status
        }
        UpdateEmail(request).then(
            (json) => {
                if (json !== undefined && json.httpStatusCode === 200) {
                    this.setState({ showSuccessUpdateEmailAlert: true });
                    this.updatePage();
                }
            }
        );
    }

    getStatusEmail = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ emailDetail: { ...this.state.emailDetail, Status: obj.value } });
        }
    }

    handleRowClick = async (obj) => {
        globalFunctions = this.context;
        globalFunctions.startLoading();
        GetEmailById(obj['EmailId']).then(
            (json) => {
                if (json !== undefined && json.httpStatusCode === 200) {
                    this.setState({ emailDetail: json, showModal: true });
                } else {
                    this.setState({ emailDetail: {}, showModal: false });
                }
                globalFunctions.finishLoading();
            }
        );
    }

    handleDeleteClick = (obj) => {
        this.setState({ showDeleteAlert: true, readyToDeleteEmail: obj });
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteEmail: {} });
    }

    closeUpdateEmailAlert = () => {
        this.setState({ showSuccessUpdateEmailAlert: false });
    }

    yesDeleteAlert = () => {
        DeleteEmail(this.state.readyToDeleteEmail['EmailId']).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({ showDeleteAlert: false, showErrorAlert: true, errorMessage: json.Message });
                } else {
                    this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                    this.updatePage();
                }
            }
        )
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false, showSuccessUpdateEmailAlert: false });
    }

    closeErrorAlert = () => {
        this.setState({ showErrorAlert: false });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        })
    }
    AttachmentClick = (obj, event) => {
        /*
        Key: "Deal Confirmation - 12169.pdf"
        Value
        */
        var arrayBuffer = new Uint8Array(obj.Value);
        var blob = new Blob([arrayBuffer], { type: "application/pdf" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = obj.Key;
        link.download = fileName;
        link.click();
    }
    render() {
        var classNameInputName = regularInputClass;
        var classNameType = regularSelectClass;

        //Attachments
        var attachmentList = '';
        if (this.state.emailDetail.Attachments != null && this.state.emailDetail.Attachments.length > 0) {
            this.state.emailDetail.Attachments.forEach(element => {
                attachmentList = (
                    <a onClick={this.AttachmentClick.bind(this, element)} className="uk-form-label">
                        <i class="fa fa-paperclip" aria-hidden="true">
                        </i>{element.Key}
                    </a>
                )
            })
        }

        return (
            <div>
                <Dialog
                    open={this.state.showModal}
                    onClose={this.closeModal}
                    fullWidth={true}
                    maxWidth='sm'
                >
                    <DialogTitle id="emailscreen-dialog-gwlatr-title" onClose={this.closeModal}>
                        {this.state.emailDetail.EmailSubject}
                    </DialogTitle>
                    <DialogContent dividers>
                        <form className="uk-form-stacked uk-grid">
                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email ID</label>
                                <div className="uk-form-controls">
                                    <input className={classNameInputName} disabled type="text" value={this.state.emailDetail.EmailId} />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" >Status</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect className={classNameType} getValue={this.getStatusEmail.bind(this)} objValue={{ value: this.state.emailDetail.Status }} data={this.state.statusList} id="form-email-status" />
                                    {/*<input className={classNameInputName} disabled type="text" value={this.state.emailDetail.Status} />*/}
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" >Created On</label>
                                <div className="uk-form-controls">
                                    <input className={classNameInputName} disabled type="text" value={this.state.emailDetail.CreatedOn} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email Receiver</label>
                                <div className="uk-form-controls">
                                    <input className={classNameInputName} disabled type="text" value={this.state.emailDetail.EmailReceiver} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email Cc</label>
                                <div className="uk-form-controls">
                                    <input className={classNameInputName} disabled type="text" value={this.state.emailDetail.EmailCc} />
                                </div>
                            </div>

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Email BCc</label>
                                <div className="uk-form-controls">
                                    <input className={classNameInputName} disabled type="text" value={this.state.emailDetail.EmailBCc} />
                                </div>
                            </div>

                            {this.attachmentList !== '' &&
                                <div className="uk-width-2-2 uk-heading-divider">
                                    <label className="uk-form-label" >Attachments</label>
                                    <div className="uk-form-controls">
                                        {attachmentList}
                                    </div>
                                </div>
                            }

                            <div className="uk-width-2-2">
                                <label className="uk-form-label" >Content</label>
                                <div className="uk-form-controls">
                                    <text style={{ backgroundColor: '#ffffff' }}>
                                        {this.state.emailDetail.Body}
                                    </text>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions><button
                        className="uk-button uk-button-green"
                        key={"Yes"}
                        type="button"
                        onClick={this.handleUpdateEmail}>Update</button>
                        <button
                            className="uk-button uk-button-green"
                            key={"Yes"}
                            type="button"
                            onClick={this.closeModal}>Ok</button>
                    </DialogActions>
                </Dialog>

                <div className="uk-width-1-1 uk-margin">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Emails</h3>
                </div>
                <div className="uk-width-1-1">
                    <hr />
                    <NewDynamicTable
                        //hiddenHeaders={['ID', 'CostPerUnit', 'Unit']}
                        data={this.state.emailsList}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={itemsPerPage}
                        enableSortByHeader={true}
                        useFilter={this.state.filter}
                        filterFunction={this.getEmailList}
                    />
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this Email?"
                    message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox id="success-update-email" open={this.state.showSuccessUpdateEmailAlert} onClose={this.closeUpdateEmailAlert} title="Email successfully update." message="" type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox id="success-delete-email" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Email successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox id="error-message-email" open={this.state.showErrorAlert} onClose={this.closeErrorAlert} title="Error" message={this.state.errorMessage} type="Ok" okClick={this.closeErrorAlert} />
            </div>
        );
    }
}

import React from 'react';
import { getStyle } from '../../../styles/styles';
import { Dialog, DialogContent } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { formatDateString, getHoursDate, getScaleByCurrency, getScaleForCurrencyPair } from '../../../helpers/FormatHelper';
import { approvePendingRateActivity, rejectPendingRateActivity } from '../../../networking/NetworkingRates';
import AlertBox from '../../shared/AlertBox';
import NumberInput from '../../shared/NumberInput';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { Lock } from '@material-ui/icons';
var style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class RateMonitorForm extends React.Component {

    state = {
        riskMonitor: {
            amount: 0,
            amountCurrency: '',
            customerName: '',
            date: '',
            fardate:'',
            dealType: '',
            id: '',
            timeOfRequest: getHoursDate(Date()),
            expiresOn: getHoursDate(Date()),
            userName: '',
            validTimeMinute: 0,
            weBuy: '',
            weSell: '',
        },
        riskGridMonitor: {
            Amount: 0,
            'Customer Rate': 0,
            Group: 0,
            Id: '',
            IsReverse: false,
            MarginError: 0,
            'Market Rate': 0,
            ValueDate: '',
            'We Buy': '',
            'We Sell': '',
        },
        currencyWeSell: '',
        currencyWeBuy: '',
        objRatePopUp: [],
        newSearch: false,
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        profit:0,
        profitCurrency :'',
        baseCurrency:localStorage.getItem(GlobalSystemParametersNames.BaseCurrency),
        profitBaseCCy:0,
        showRejectAlert: false,
        showAcceptConfirm: false
    }
    componentDidMount() {
        /*
Amount: 1550

COMPARAR: si son diferentes es WE BUY sino WE SELL
AmountCurrency: "USD"
CurrencyForBeneficiary: "USD"

CurrencyForSettlement: "CAD"
CustomerId: 9658
CustomerName: "Soto, Daniela"
DealType: "Spot"
Id: "C5E8F058-12BF-4748-A26E-17C2AC02A3D7"
RateActivityRate: (5) [{…}, {…}, {…}, {…}, {…}]
UserName: "98549281-9d11-47c5-96a5-6f1d8705e41a"
httpStatusCode: 200
 */
    }
    componentDidUpdate(prevProps) {        
        let objRatePopUp = this.props.objRatePopUp;

        if (objRatePopUp != null && objRatePopUp !== prevProps.objRatePopUp) {
            var rateActivityRate = objRatePopUp.RateActivityRate;
            (rateActivityRate).forEach(rate => {
                if(!(objRatePopUp.CurrencyForSettlement === rate['We Buy']
                    && objRatePopUp.CurrencyForBeneficiary === rate['We Sell'])){
                    rate['Customer Rate'] = rate['Market Rate'];
                }
            });
         
            this.setState({
                riskMonitor: {
                    ...this.state.riskMonitor,
                    amount: objRatePopUp.Amount,
                    amountCurrency: objRatePopUp.AmountCurrency,
                    customerName: objRatePopUp.CustomerName,
                    date: formatDateString(objRatePopUp.RateActivityRate[0]['ValueDate']),
                    fardate: formatDateString(objRatePopUp.RateActivityRate[0]['ForwardNearDate']),
                    dealType: objRatePopUp.DealType,
                    id: objRatePopUp.Id,
                    userName: objRatePopUp.UserName,
                    validTimeMinute: 1,
                    weBuy: objRatePopUp.AmountCurrency !== objRatePopUp.CurrencyForBeneficiary ? this.props.objRatePopUp.Amount : 0,
                    weSell: objRatePopUp.AmountCurrency === objRatePopUp.CurrencyForBeneficiary ? this.props.objRatePopUp.Amount : 0,
                    timeOfRequest: getHoursDate(objRatePopUp.CreatedDate),
                    expiresOn: getHoursDate(objRatePopUp.ExpireDate)
                },
                currencyWeSell: objRatePopUp.CurrencyForBeneficiary,
                currencyWeBuy: objRatePopUp.CurrencyForSettlement,
                objRatePopUp: rateActivityRate,
                profitCurrency: objRatePopUp.AmountCurrency !== objRatePopUp.CurrencyForBeneficiary ? objRatePopUp.CurrencyForBeneficiary : objRatePopUp.CurrencyForSettlement,
                profit: 0,
                profitBaseCCy:0,
                showAlert: false,
                showRejectAlert: false
            });
        }
    }

    Clear = () => {
        let date = new Date();
        this.setState({
            riskMonitor: {
                id: '',
                userName: '',
                customerName: '',
                dealType: '',
                date: '',
                weSell: '',
                weBuy: '',
                timeOfRequest: getHoursDate(date),
                expiresOn: getHoursDate(date),
                validTimeMinute: 0,
            },
            riskGridMonitor: {
                Amount: 0,
                'Customer Rate': 0,
                Group: 0,
                Id: '',
                IsReverse: false,
                MarginError: 0,
                'Market Rate': 0,
                ValueDate: '',
                'We Buy': '',
                'We Sell': '',
            },
            currencyWeSell: '',
            currencyWeBuy: '',
            objRatePopUp: [],
            newSearch: false,
            showAlert: false,
            profitCurrency: '',
            profit: 0,
            profitBaseCCy:0,
            alertMessage: '',
            alertTitle: '',
        })
    }

    transformList = (list) => {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            if (list[i] != null) {
                helper.push({
                    'Id': list[i].Id,
                    'Group': list[i]['Group'],
                    'IsReverse': list[i]['IsReverse'],
                    'MarginError': list[i]['MarginError'],
                    'MarketRate': list[i]['Market Rate'],
                    'CustomerRate': list[i]['Customer Rate'],
                    'ValueDate': list[i]['ValueDate'],
                });
            }
        }
        return helper;
    }

    Accept = () =>{
        this.setState({showAcceptConfirm: false});

        var objApprove = {
            Id: this.state.riskMonitor.id,
            ValidTime: this.state.riskMonitor.validTimeMinute * 60,
            RateActivityRates: this.transformList(this.state.objRatePopUp)
        }
        approvePendingRateActivity(objApprove).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    if(json.Message && json.Message !== '')
                    {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.Message});
                    }
                    else
                    {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to accept. Please try again.' });
                    }
                } else {
                    this.Clear()
                    this.props.close()
                }
            });
    }

    AcceptClick = () => {
        let rates = this.state.objRatePopUp;
        let objRateDeal = rates.find((r) => { return r['We Buy'] === this.state.currencyWeBuy && r['We Sell'] === this.state.currencyWeSell });
        
        if(objRateDeal)
        {
        let customerRate = objRateDeal['Customer Rate'];

        if(customerRate <= 0)
        {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'Customer rate must be greather than zero.' });
        }
        else
        {
            if(this.state.profit <= 0)
            {
                this.setState({ showAcceptConfirm: true});
            }
            else
            {
                this.Accept();
            }
        }
    }
    }

    RejectClick = () => {
        this.setState({showRejectAlert: true});
    }

    Reject = () => {
        this.setState({showRejectAlert: false});

        var objRate = {
            'ID': this.state.riskMonitor.id
        }
        rejectPendingRateActivity(objRate).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to reject. Please try again..' })
                } else {
                    this.Clear()
                    this.props.close()
                }
            }
        );
    }
    handleUpdateUserName = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, userName: event.target.value } })
    }
    handleUpdateCustomerName = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, customerName: event.target.value } })
    }
    handleUpdateWeSell = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, weSell: event.target.value } })
    }
    handleUpdateWeBuy = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, weBuy: event.target.value } })
    }
    handleUpdateTimeOfRequest = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, timeOfRequest: event.target.value } })
    }
    handleUpdateExpiresOn = (event) => {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, expiresOn: event.target.value } })
    }
    handleUpdateValidTimeMinute(event) {
        this.setState({ riskMonitor: { ...this.state.riskMonitor, validTimeMinute: event.target.value } })
    }
    handleUpdateMarketRate(event) {
        this.setState({ riskGridMonitor: { ...this.state.riskGridMonitor, 'Market Rate': event.target.value } }, () => this.isRateGrid(this.state.riskGridMonitor))
    }
    handleUpdateCustomerRate(event) {
        this.setState({ riskGridMonitor: { ...this.state.riskGridMonitor, 'Customer Rate': event.target.value } }, () => this.isRateGrid(this.state.riskGridMonitor))
    }
    handleRowClick = (obj) => {
        this.setState({ riskGridMonitor: obj }, () => this.isRateGrid(obj));
    }

    isRateGrid = (objList) => {
        if (this.state.objRatePopUp != null) {
            var list = this.state.objRatePopUp;
            var helper = [];
            list.unshift(objList);
            helper = list.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t['Id'] === thing['Id']
                ))
            )
            this.setState({ objRatePopUp: helper })
        }
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    closeRejectAlert = () => {
        this.setState({ showRejectAlert: false });
    }

    closeAcceptConfirm = () => {
        this.setState({ showAcceptConfirm: false });
    }


    onClose = ()=>{
        this.RejectClick();
    }

    convertCcy = (amount, rateTerms, rate) =>{
        let convertAmount = 0;
                 if(rateTerms === 'D'){
                        convertAmount = amount * rate;
                    }
                    else{
                        if(rate > 0){
                            convertAmount = amount / rate;
                        }
                    }        
    
        return convertAmount;
    }

    getProfit = (amount, objRate, customerRate, marketRate) =>
    {
        let marketAmount = this.convertCcy(amount,objRate.RateTerms,marketRate);
        let customerAmount = this.convertCcy(amount,objRate.RateTerms,customerRate);

        if(objRate.RateTerms === 'D'){
            return customerAmount - marketAmount;
        }
        else{
            return marketAmount - customerAmount;
        } 

    }


    handleGridMarketRateUpdate = (rate, event) => {
        //Update rates
        var rates = this.state.objRatePopUp;
        var marketRate = Number(event.target.value);
        let objRate = rates.find((r) => { return r.Id === rate.Id; });
        if(objRate !== undefined){
            objRate['Market Rate'] = marketRate;
        }
        let baseCCy = this.state.baseCurrency;
        let profit = 0;
        let profitBaseCCy = 0;
        let profitScale = getScaleByCurrency(this.state.profitCurrency);

        if(objRate['We Buy'] !== baseCCy && objRate['We Sell'] !== baseCCy)
        {
            let objRate1 = rates.find((rate) => { return (
                (rate['We Buy'] === baseCCy)
              ); });
            
            let objRate2 = rates.find((rate) => { return (
                (rate['We Sell'] === baseCCy)
              ); });
            
            let rateFar = objRate2['Market Rate'];

                let rateNear = marketRate/rateFar

                if(objRate1 !== undefined){
                    objRate1['Market Rate'] = rateNear;
                }
            profit= this.getProfit(this.state.riskMonitor.amount, objRate, objRate['Customer Rate'], marketRate);

            
            if(objRate['We Buy'] !== baseCCy && objRate['We Sell'] !== baseCCy)
        {
            let objRateBase = rates.find((rate) => { return (
                ((this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForBeneficiary && rate['We Sell'] === this.state.baseCurrency)
                ||(this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForSettlement && rate['We Buy'] === this.state.baseCurrency))
              ); });
            if(objRateBase)
            {
                let rateTerms =  objRateBase.RateTerms;
                //XXX/USD
                let marketRateBase = objRateBase['Market Rate'] ;
                if(objRateBase['We Sell'] === this.state.baseCurrency)
                {                                      
                    rateTerms = rateTerms === 'D'?'I':'D';

                    if(marketRateBase < 1)
                    {
                        marketRateBase = 1/marketRateBase;
                    }
                }
                let baseScale = getScaleByCurrency(this.state.baseCurrency);
                profitBaseCCy = this.convertCcy(profit, rateTerms, marketRateBase).toFixed(baseScale);
            }
        }
        else
        {
            profitBaseCCy = profit.toFixed(profitScale);
        }
    }
        this.setState({objRatePopUp: rates, profit: profit.toFixed(profitScale), profitBaseCCy:profitBaseCCy});
    }

    handleGridCustomerRateUpdate = (rate, event) => {
    //Update rates
        var rates = this.state.objRatePopUp;
        var customerRate = Number(event.target.value);
        let objRate = rates.find((r) => { return r.Id === rate.Id; });
        if(objRate !== undefined){
            objRate['Customer Rate'] = customerRate;
        }

        //Update amounts
        var riskMonitor = this.state.riskMonitor;
        let profit = 0;
        let profitBaseCCy = 0;
        let profitScale = getScaleByCurrency(this.state.profitCurrency);
        if(this.props.CurrencyForSettlement === this.props.AmountCurrency ? 
          (rate['We Sell'] === this.state.currencyWeSell && rate['We Buy'] === this.state.currencyWeBuy) : 
          (rate['We Sell'] === this.state.currencyWeBuy && rate['We Buy'] === this.state.currencyWeSell))
        {
                var weSell = 0, weBuy = 0;
                if(this.state.currencyWeSell === riskMonitor.amountCurrency){
                    weSell = riskMonitor.amount;
                    if(objRate.RateTerms === 'D'){
                        weBuy = weSell * customerRate;
                    }
                    else{
                        if(customerRate > 0){
                            weBuy = weSell / customerRate;
                        }
                    }                    
                }
        
                if(this.state.currencyWeBuy === riskMonitor.amountCurrency){
                    weBuy = riskMonitor.amount;
                    if(objRate.RateTerms === 'I'){
                        weSell = weBuy * customerRate;
                    }
                    else{
                        if(customerRate > 0){
                            weSell = weBuy / customerRate;
                        }
                    }
                }
                
                riskMonitor.weBuy = weBuy;
                riskMonitor.weSell = weSell;

                

            profit= this.getProfit(this.state.riskMonitor.amount, objRate, customerRate, objRate['Market Rate']);

            if(objRate['We Buy'] !== this.state.baseCurrency && objRate['We Sell'] !== this.state.baseCurrency)
        {
            let objRateBase = rates.find((rate) => { return (
                ((this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForBeneficiary && rate['We Sell'] === this.state.baseCurrency)
                ||(this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForSettlement && rate['We Buy'] === this.state.baseCurrency))
              ); });

            if(objRateBase)
            {
                let rateTerms =  objRateBase.RateTerms;
                //XXX/USD
                let marketRateBase = objRateBase['Market Rate'] ;
                if(objRateBase['We Sell'] === this.state.baseCurrency)
                {                                      
                    rateTerms = rateTerms === 'D'?'I':'D';

                    if(marketRateBase < 1)
                    {
                        marketRateBase = 1/marketRateBase;
                    }
                }
                let baseScale = getScaleByCurrency(this.state.baseCurrency);
                profitBaseCCy = this.convertCcy(profit, rateTerms, marketRateBase).toFixed(baseScale);
            }
        }
        else
        {
            profitBaseCCy=profit.toFixed(profitScale);
        }

        }                

        this.setState({riskMonitor: riskMonitor, objRatePopUp: rates, profit:profit.toFixed(profitScale), profitBaseCCy: profitBaseCCy});
    }

    handleGridMarketRateSuppUpdate1 = (rate, event) => {
            let rates = this.state.objRatePopUp;
            let customerRate1 = Number(event.target.value);
            let objRate = rates.find((r) => { return r.Id === rate.Id; });

            if(objRate !== undefined){
                objRate['Market Rate'] = customerRate1;
            }
    
            const baseCCy = this.state.baseCurrency;

            if((rate['We Sell'] === this.state.currencyWeSell && rate['We Buy'] === baseCCy))
            {
                let objRate2 = rates.find((rate) => { return (
                    (rate['We Sell'] === baseCCy)
                  ); });

                let objRateDeal = rates.find((r) => { return r['We Buy'] === this.state.currencyWeBuy && r['We Sell'] === this.state.currencyWeSell });
                let marketRate = objRateDeal['Market Rate'];

                let newRate2 = marketRate/customerRate1

                if(objRate2 !== undefined){
                    objRate2['Market Rate'] = newRate2;
                }
                
            }                
    
            this.setState({objRatePopUp: rates});
        }

        handleGridMarketRateSuppUpdate2 = (rate, event) => {
                let rates = this.state.objRatePopUp;
                let customerRate1 = Number(event.target.value);
                let objRate = rates.find((r) => { return r.Id === rate.Id; });
    
                if(objRate !== undefined){
                    objRate['Market Rate'] = customerRate1;
                }
        
                const baseCCy = this.state.baseCurrency;

                if((rate['We Sell'] === baseCCy && rate['We Buy'] === this.state.currencyWeBuy ))
                {
                    let objRate2 = rates.find((rate) => { return (
                        (rate['We Buy'] === baseCCy)
                      ); });
    
                    let objRateDeal = rates.find((r) => { return r['We Buy'] === this.state.currencyWeBuy && r['We Sell'] === this.state.currencyWeSell });
                    let marketRate = objRateDeal['Market Rate'];
    
                    let newRate2 = marketRate/customerRate1
    
                    if(objRate2 !== undefined){
                        objRate2['Market Rate'] = newRate2;
                    }
                }                
        
                this.setState({objRatePopUp: rates});
            }

    render() {
        var rates = this.state.objRatePopUp.filter((rate) => { return (
          rate['We Sell'] !== rate['We Buy']
        ); });
        var rateRows = [];
        var index = 0;

        const baseCCy = this.state.baseCurrency;

        var ratesDeal = rates.filter((rate)=>{return (
            rate['We Sell'] === this.state.currencyWeSell && rate['We Buy'] === this.state.currencyWeBuy
        );});


        let ratesSupporting1 = this.state.currencyWeSell !== baseCCy && this.state.currencyWeBuy !== baseCCy
                ? rates.filter((rate) => { return ((rate['We Buy'] === baseCCy)      ); }) : [] ;

        let ratesSupporting2 = this.state.currencyWeSell !== baseCCy && this.state.currencyWeBuy !== baseCCy
          ? rates.filter((rate) => { return (  (rate['We Sell'] === baseCCy)   ); }) : [] ;

        ratesDeal.forEach(rate => {
            rateRows.push(
                <tr key={'rate' + index}>
                    <td>{rate['We Sell']}</td>
                    <td>{rate['We Buy']}</td>
                    <td>
                        <NumberInput id={'market-rate-' + index} 
                            type="Rate"
                            scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)}
                            className="uk-input"
                            value={rate['Market Rate']}
                            onChange={(event) => this.handleGridMarketRateUpdate(rate, event)}
                        />
                    </td>
                    <td>
                        <NumberInput id={'customer-rate-' + index} 
                            type="Rate"
                            scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)}
                            className="uk-input"
                            value={rate['Customer Rate']}
                            onChange={(event) => this.handleGridCustomerRateUpdate(rate, event)}
                        />
                    </td>
                </tr>
            );
            index++;
        });

        ratesSupporting1.forEach(rate => {
            rateRows.push(
                <tr key={'rate-supp-1-' + index}>
                    <td>{rate['We Sell']}</td>
                    <td>{rate['We Buy']}</td>
                    <td>
                        <NumberInput id={'market-rate-supp-1-' + index} 
                            type="Rate"
                            scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)}
                            className="uk-input"
                            value={rate['Market Rate']}
                            onChange={(event) => this.handleGridMarketRateSuppUpdate1(rate, event)}
                        />
                    </td>
                    <td>
                    </td>
                </tr>
            );
            index++;
        });

        ratesSupporting2.forEach(rate => {
            rateRows.push(
                <tr key={'rate-supp-2-' + index}>
                    <td>{rate['We Sell']}</td>
                    <td>{rate['We Buy']}</td>
                    <td>
                        <NumberInput id={'market-rate-supp-2-' + index} 
                            type="Rate"
                            scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)}
                            className="uk-input"
                            value={rate['Market Rate']}
                            onChange={(event) => this.handleGridMarketRateSuppUpdate2(rate, event)}
                        />
                    </td>
                    <td>
                    </td>
                </tr>
            );
            index++;
        });

        return (
            <Dialog open={this.props.open} onClose={this.onClose} 
            maxWidth={"sm"} fullWidth={true}>
                <DialogTitle id="attachments-dialog-title" onClose={this.onClose} style={{padding:'8px 24px'}}>
                    Rate Monitor
                    </DialogTitle>
                <DialogContent dividers style={{padding:'4px 24px'}}>
                    <div className="uk-grid">
                        <div className="uk-width-1-1 uk-form-stacked">
                            <label className="uk-form-label">User</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="user" type="text" value={this.state.riskMonitor.userName} onChange={this.handleUpdateUserName.bind(this)} disabled={true} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Customer</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="customer" type="text" value={this.state.riskMonitor.customerName} onChange={this.handleUpdateCustomerName.bind(this)} disabled={true} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Deal Type</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="dealType" type="text" value={this.state.riskMonitor.dealType} disabled={true} />
                            </div>
                        </div>

                        {this.state.riskMonitor.dealType === 'Forward' && this.state.riskMonitor.date === this.state.riskMonitor.fardate && 
                        <div className="uk-width-1-2 uk-form-stacked"></div>}

                        {this.state.riskMonitor.dealType !== 'Forward'  &&
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Value Date</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="valueDate" type="text" value={this.state.riskMonitor.date} disabled={true} />
                            </div>
                        </div>}

                        {this.state.riskMonitor.dealType === 'Forward' && this.state.riskMonitor.date !== this.state.riskMonitor.fardate &&                        
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Near Date</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="valueDate" type="text" value={this.state.riskMonitor.fardate} disabled={true} />
                            </div>
                        </div>
                        }

                        {this.state.riskMonitor.dealType === 'Spot' && <div className="uk-width-1-2 uk-form-stacked"></div>}
                        {this.state.riskMonitor.dealType !== 'Spot' && 
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Far Date</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="valueDate" type="text" value={this.state.riskMonitor.date} disabled={true} />
                            </div>
                        </div>}

                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">We Sell 
                            {(this.props.objRatePopUp && this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForBeneficiary) &&
                                <Lock style={{margin:'2px', fontSize:'14px'}}/>
                            }
                            </label>
                            <div className="input-icon input-icon-right" style={style.inputStyle}>
                                <NumberInput className="uk-input" id="weSell" type="Currency" value={this.state.riskMonitor.weSell} 
                                scale={getScaleByCurrency(this.state.currencyWeSell)}  
                                mask={[this.state.currencyWeSell]} disabled={true}/>
                                <i>{this.state.currencyWeSell}</i>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">We Buy
                            {(this.props.objRatePopUp && this.props.objRatePopUp.AmountCurrency !== this.props.objRatePopUp.CurrencyForBeneficiary) &&
                                <Lock style={{margin:'2px', fontSize:'14px'}}/>
                            }
                            </label>
                            <div className="input-icon input-icon-right" style={style.inputStyle}>
                                <NumberInput className="uk-input" id="weBuy" type="Currency" value={this.state.riskMonitor.weBuy} 
                                scale={getScaleByCurrency(this.state.currencyWeBuy)}  negative={true}
                                mask={[this.state.currencyWeBuy]} disabled={true}/>
                                <i>{this.state.currencyWeBuy}</i>
                            </div>
                        </div>

                        {(this.props.objRatePopUp && this.props.objRatePopUp.AmountCurrency === this.props.objRatePopUp.CurrencyForBeneficiary) &&
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">{`Profit (${this.state.baseCurrency})`}</label>
                            <div className="input-icon input-icon-right" style={style.inputStyle}>
                                <NumberInput className="uk-input" id="profit" type="Currency" value={this.state.profitBaseCCy} 
                                scale={getScaleByCurrency(this.state.baseCurrency)} negative={true}
                                mask={[this.state.baseCurrency]} disabled={true}/>
                                <i>{this.state.baseCurrency}</i>
                            </div>
                        </div>
                        }
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">{`Profit (${this.state.profitCurrency})`}</label>
                            <div className="input-icon input-icon-right" style={style.inputStyle}>
                                <NumberInput className="uk-input" id="profit" type="Currency" value={this.state.profit} 
                                scale={getScaleByCurrency(this.state.profitCurrency)} negative={true}
                                mask={[this.state.profitCurrency]} disabled={true}/>
                                <i>{this.state.profitCurrency}</i>
                            </div>
                        </div>
                        {(this.props.objRatePopUp && this.props.objRatePopUp.AmountCurrency !== this.props.objRatePopUp.CurrencyForBeneficiary) &&
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">{`Profit (${this.state.baseCurrency})`}</label>
                            <div className="input-icon input-icon-right" style={style.inputStyle}>
                                <NumberInput className="uk-input" id="profit" type="Currency" value={this.state.profitBaseCCy} 
                                scale={getScaleByCurrency(this.state.baseCurrency)} negative={true}
                                mask={[this.state.baseCurrency]} disabled={true}/>
                                <i>{this.state.baseCurrency}</i>
                            </div>
                        </div>
                        }

                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label">Requested On</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="timeOfRequest" type="text" value={this.state.riskMonitor.timeOfRequest} onChange={this.handleUpdateTimeOfRequest.bind(this)} disabled={true} />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label">Expires On</label>
                            <div className="" style={style.inputStyle}>
                                <input className='uk-input' id="expiresOn" type="text" value={this.state.riskMonitor.expiresOn} onChange={this.handleUpdateExpiresOn.bind(this)} disabled={true} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Valid Time in Minutes</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput className='uk-input' id="validTimeMinute" placeholder="0" type="Integer" value={this.state.riskMonitor.validTimeMinute} onChange={this.handleUpdateValidTimeMinute.bind(this)} />
                        </div>
                        </div>
                        {/*<div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Market Rate</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput id="marketRate" type="Rate" scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)} className='uk-input' placeholder="___0,00" value={this.state.riskGridMonitor['Market Rate']} onChange={this.handleUpdateMarketRate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Customer Rate</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput id="customerRate" type="Rate" scale={getScaleForCurrencyPair(this.state.currencyWeBuy, this.state.currencyWeSell)} className='uk-input' placeholder="___0,00" value={this.state.riskGridMonitor['Customer Rate']} onChange={this.handleUpdateCustomerRate.bind(this)}/>
                            </div>
                        </div>*/}
                    </div>
                    <div style={{marginTop:'10px'}}>
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider">
                            <thead>
                                <th style={{padding:'5px', width:'80px', textAlign:'center'}}>We Sell</th>
                                <th style={{padding:'5px',width:'80px', textAlign:'center'}}>We Buy</th>
                                <th style={{padding:'5px', textAlign:'center'}}>Market Rate</th>
                                <th style={{padding:'5px', textAlign:'center'}}>Customer Rate</th>
                            </thead>
                            <tbody>
                                {rateRows}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {/*<NewDynamicTable
                            hiddenHeaders={["Id", "Amount", "IsReverse", "MarginError", "ValueDate"]}
                            data={rates}
                            enableClick={true}
                            clickFunction={this.handleRowClick.bind(this)}
                            useDeleteButton={false}
                            useCheckBox={false}
                            newSearch={this.state.newSearch}
                        />*/}
                        <div >
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.AcceptClick} type="button">Accept</button>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.RejectClick} type="button">Reject</button>
                        </div>
                    </div>
                </DialogContent>
                <AlertBox id="save-alert" open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox id="reject-confirm" open={this.state.showRejectAlert} onClose={this.closeRejectAlert} title="Confirmation" message={"Do you want to reject this rate?"} type="Yes/No" yesClick={this.Reject} noClick={this.closeRejectAlert} />
                <AlertBox id="accept-confirm" open={this.state.showAcceptConfirm} onClose={this.closeAcceptConfirm} title="Confirmation" message={"Calculated profit is zero or negative. Do you want to continue?"} type="Yes/No" yesClick={this.Accept} noClick={this.closeAcceptConfirm} />
            </Dialog>
        );
    }
}
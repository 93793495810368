import React from 'react';
import CsvDownload from 'react-json-to-csv';

import AlertBox from '../../shared/AlertBox'
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AppContext from '../../../AppContext';

import {validateImportDealsFields, importDeals} from '../../../networking/NetworkingDeals';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';

import { getStyle } from '../../../styles/styles';
var style = getStyle();
let globalFunctions = {};

export default class ImportDealsForm extends React.Component {
    static contextType = AppContext;
    
    constructor(props){
        super(props);
        this.fileInput = React.createRef();
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleValidateFile = this.handleValidateFile.bind(this);
    }

    state = {
        alertTitle: '',
        alertMessage: '',
        csvFileName: '',
        currentPage: 1,
        dealsImported: [],
        disableImportButton: true,
        csvResult: [],
        loading: true,
        selectedRows: [],
        showAlert: false,
        temp: '',
        totalPages: 0
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }

    componentDidMount(){
        globalFunctions = this.context;
        let today = new Date();        
        let yearString = today.getFullYear().toString();
        let monthString = (today.getMonth()+1).toString().padStart(2, '0');
        let dayString = today.getDate().toString().padStart(2, '0');
        let hourString = today.getHours().toString().padStart(2, '0');
        let minuteString = today.getMinutes().toString().padStart(2, '0');
        let timeStamp = yearString + monthString + dayString + hourString + minuteString;
        
        let valueDate = yearString + '-' + monthString + '-' + (today.getDate() + 1).toString();
        const header = [
            {RefId: '11111',CustomerId: 983324,BuyCurrency: 'AUD',BuyAmount: 1406.87,SellCurrency: 'USD',SellAmount: 1000,
            MarketRate: 0.7180000000,CustomerRate: 0.7180000000,ValueDate: valueDate,ContactId: 7450}
        ];

        let csvArray = JSON.parse(JSON.stringify(header));

        this.setState({ loading: false, csvFileName: 'ImportDealsTemplate_' + timeStamp + '.csv', csvResult: csvArray});
    }
    
    componentDidUpdate(prevProps) {}
    
    handleUploadFile(event) {
        event.preventDefault();
        if (this.fileInput !== undefined && this.fileInput.current.files.length > 0){
            var file = this.fileInput.current.files[0];
            this.readFileAsBase64(file).then((resolve, reject)=>{
            console.log(resolve);
            var fileContent = resolve.replace(/^data:.+;base64,/, '');
            this.setState({temp: fileContent});
            var ValidateImportDealsFieldsRequest = {
                csvFile: fileContent,
                UpdatedBy: localStorage.getItem('UserID')
            }
            importDeals(ValidateImportDealsFieldsRequest).then((json)=>{
                    console.log(json);
                    if(json !== undefined && json.httpStatusCode !== undefined && json.httpStatusCode === 200){
                        const totalCount = json.data.length;
                        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        this.setState({showAlert: true, alertTitle: "Deals has been imported.", alertMessage: "Please check the log to review the results of the import.", disableImportButton: true, totalPages, loading: false});
                        this.changeHeaders(json.data);
                    } else {
                        this.setState({ showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false });
                    }
                    //this.props.onClose();
                })
            });
        } else {
            this.setState({ showAlert: true, alertMessage: "You have to choose a file", alertTitle: 'Warning', loading: false });
        }
    }

    handleValidateFile(event) {
        event.preventDefault();
        if (this.fileInput !== undefined && this.fileInput.current.files.length > 0){
            var file = this.fileInput.current.files[0];
            this.readFileAsBase64(file).then((resolve, reject)=>{
            console.log(resolve);
            var fileContent = resolve.replace(/^data:.+;base64,/, '');
            this.setState({temp: fileContent});
            var ValidateImportDealsFieldsRequest = { 
                csvFile: fileContent,
                UpdatedBy: localStorage.getItem('UserID')
            }
            validateImportDealsFields(ValidateImportDealsFieldsRequest).then((json)=>{
                    console.log(json);
                    if(json !== undefined && json.httpStatusCode !== undefined && json.httpStatusCode === 200){
                        const totalCount = json.data.length;
                        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        this.setState({showAlert: true, alertTitle: "Success", alertMessage: "File has been validated", totalPages, loading: false});
                        this.changeHeaders(json.data);
                    } else {
                        this.setState({ showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false });
                    }
                    //this.props.onClose();
                })
            });
        } else {
            this.setState({ showAlert: true, alertMessage: "You have to choose a file", alertTitle: 'Warning', loading: false });
        }
    }

    changeHeaders(data){
        const list = data.map((d) => ({
            "Reference ID": d.ReferenceID,
            "Deal ID": d.DealHeaderID,
            "Customer ID": d.CustomerID,
            "Contact ID": d.ContactID,
            "Buy Currency": d.BuyCurrency,
            "Buy Amount": d.BuyAmount,
            "Sell Currency": d.SellCurrency,
            "Sell Amount": d.SellAmount,
            "Market Rate": d.MarketRate,
            "Customer Rate": d.CustomerRate,
            "Value Date": d.ValueDate,
            "Failed Entities": d.FailedEntities
        }))
        let hasFailed = false;
        for (var i = 0; i < data.length; i++) {
            if(data[i].FailedEntities !== ''){
                hasFailed = true;
            }
        }
        this.setState({dealsImported: list, disableImportButton: hasFailed});
    }
    
    readFileAsBase64(file){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    }

    changePage(value) {
        this.setState({ currentPage: value });
    }

    handleCheckboxClick = (obj) => {

    }

    render(){
        return(
            <div>
                {/*<form className="uk-form-stacked uk-grid">*/}
                <div>
                    <div className="uk-width-1-1">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Please upload the file you want to import</label>
                            <div className="uk-form-controls"  style={style.inputStyle}>
                                <input type="file" ref={this.fileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <CsvDownload data={this.state.csvResult} filename={this.state.csvFileName} className="uk-button uk-button-green uk-float-right">Download template</CsvDownload>
                        <button className="uk-button uk-button-green" key={"validate"} type="button" onClick={this.handleValidateFile}>Validate</button>
                        <button className="uk-button uk-button-green" key={"import"} type="button" onClick={this.handleUploadFile} disabled={this.state.disableImportButton}>Import deals</button>
                    </div>
                    <div>
                        <NewDynamicTable
                            id="deals-imported"
                            checkboxFunction={this.handleCheckboxClick}
                            data={this.state.dealsImported}
                            enableClick={false}
                            enableSortByHeader={true}
                            newSearch={this.state.newSearch}
                            numberOfPages={this.state.totalPages}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            pageNumber={this.state.currentPage}
                            selectedRows={this.state.selectedRows}
                            useCheckBox={false}
                            useDeleteButton={false}
                            useExportButton={true}
                            useFilter={true}
                            usePaginator={true}
                            useMultiCheckBox={true} />
                        <LoadingBox loading={this.state.loading} />
                    </div>
                {/*</form>*/}
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}
import { genericDeleteWithParameters, genericGetWithParameters } from "./Networking";

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getOwnersByCustomer(customerID){
    try {
        let params = {
            "customerID":customerID
        };

        let response = await genericGetWithParameters('Owner/SearchOwnersByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRiskScoreOwnerDetails(ownerID){
    try {
        let params = {
            EntityType: "Owner",
            EntityID: ownerID,
            checkBankScore: true
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOwnerRiskScore(ownerID){
    try {
        let params = {
            OwnerID: ownerID
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetOwnerRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteOwner(ownerID) {
    try{
        let params = {
            'ownerID': ownerID
        };
        let response = await genericDeleteWithParameters('Owner/DeleteOwner',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveOwner(objOwner) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Owner/SaveOwner',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(objOwner),
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = '';
        switch(response.status)
        {
            case 200:
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function getOwnerByID(id){
    try {
        let params = {"ownerID":id,};

        let response = await genericGetWithParameters('Owner/GetOwnerByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
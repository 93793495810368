import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import AlertBox from '../../../shared/AlertBox';
import {CustomerContactType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class ContactPropertiesForm extends React.Component {
    state = {
        contactID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
        errors: [],
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
        this.props.getSaveProperties(false);
        this.props.showFinalAlert();
    }

    onFinishEntityPropertiesSaveProcess = (status,message,pendingMandatoryList) => {
        if(status){
            this.props.getSaveProperties(false);
            this.props.showFinalAlert();
            //this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Success.', alertMessage: 'Customer Properties saved.', errors: pendingMandatoryList});
        }else{
            this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Warning', alertMessage: message, errors: pendingMandatoryList});
        }
        //this.props.getSaveProperties(false);
    }

    componentDidUpdate (prevProps) {
        if(this.props.saveProperties !== prevProps.saveProperties){
            if(this.props.contactID === 0){
                this.props.getSaveProperties(false);
                this.props.showFinalAlert();
            }
        }
    }

    /*handleButtonSaveClick(){
        this.setState({entityPropertiesSaveProcess: true});
    }*/

    handleShowHistoryClick = () => {
        this.props.showHistoryScreen();
    }

    render(){
        var propertyId = 'contact-property-'+this.props.contactID;
        var section = (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <EntityProperties id={propertyId} fullScreen={true} entityType={CustomerContactType} entityID={this.props.contactID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess}>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleShowHistoryClick} type="button">View History</button>                        
                    </EntityProperties>
                </div>
            </form>
        )
        if(this.props.contactID === 0){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create contact first.</h5>
                </div>
            )
        }
        return(
            <div>
                {section}
                <AlertBox 
                open={this.state.showAlert} 
                onClose={this.closeAlert} 
                title={this.state.alertTitle} 
                message={this.state.alertMessage} 
                type="Ok" 
                messageBoldList={this.state.errors}
                okClick={this.closeAlert}/>
            </div>
        );
    }
}
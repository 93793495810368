import React from 'react';
import MarginCallsForm from './MarginCallsForm';
import {getStyle} from '../../../styles/styles';
import MarginCallsDetails from './MarginCallsDetails';
import {getCurrencyList} from '../../../networking/NetworkingCurrencies';

export default class MarginCallsScreen extends React.Component {
    state = {
        step: 1,
        marginCall: {},
        marginCallList: [],
        listActiveCurrencies: []
    }

    onClickStepTab = (value) => {
        if (value === 1) {
            this.setState({ step: value });
        }
    }

    onClickRow = (obj, marginCallList) => 
    {
        this.setState({marginCall: obj,
            marginCallList: marginCallList,
            step: 2});
    }

    handleBackClick = (marginCallList) =>
    {
     /*   var marginCall = this.state.marginCall;
        marginCall['Deals'] = deals;*/
        this.setState({
            marginCallList: marginCallList,
            step: 1}
        );
    }


    render()
    {
        var stepScreen = []
        var classNameStep1 = '', classNameStep2 = '';
        
         switch(this.state.step){
            case 1:
            default:
                classNameStep1 = 'uk-active';
                stepScreen.push(<MarginCallsForm onClickRow ={this.onClickRow}
                    marginCallList={this.state.marginCallList}
                    />);
                break;
            case 2:
                classNameStep2 = 'uk-active';
                stepScreen.push(<MarginCallsDetails marginCall={this.state.marginCall}
                    marginCallList={this.state.marginCallList}
                    handleBackClick={this.handleBackClick}/>);

                break;
         }

        return(
            <div>
                <h3 className="uk-heading-divider" style={getStyle().titleStyle}>Margin Calls</h3>
                <ul className="uk-tab">
                    <li key="step-1" className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Margin Calls</a></li>
                    <li key="step-2" className={classNameStep2}><a >Margin Calls Details</a></li>
                    
                </ul>
                {stepScreen}

            </div>
        );

    }

}
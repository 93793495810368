import { Breadcrumb, Button, Space } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getListOfMessages, markMessageAsRead } from '../../../networking/NetworkingMessages';
import AlertBox from '../../shared/AlertBox';
import ReplyMessageDialog from '../../shared/Dialogs/ReplyMessageDialog';
import NewEditableGrid from '../../shared/NewEditableGrid';

const MessagesScreen = () => {
    // const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [showReplyDialog, setShowReplyDialog] = useState(false);
    const [currentSenderID, setCurrentSenderID] = useState('');
    const [currentSubject, setCurrentSubject] = useState('');
    const [currentMessage, setCurrentMessage] = useState({});
    const [reloadMessages, setReloadMessages] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [columnDetail, setColumnDetail] = useState(null);

    useEffect(() => {
        const loadMessages = async () => {
            const json = await getListOfMessages();
            if (json != null && json.messages != null) {
                const _formattedMessages = json.messages.map((m, i) => ({ ...m, key: `messages-${i}` }));
                setMessages(_formattedMessages);
                if (json.columnDetail != null) {
                    const _columnDetail = JSON.parse(json.columnDetail);
                    setColumnDetail(_columnDetail);
                    console.log(_columnDetail);
                }
            }
        };
        loadMessages();
    }, [reloadMessages]);

    const updateMessageAsRead = async (messageId) => {
        const json = await markMessageAsRead(messageId);
        if (json != null) {
            if (json.httpStatusCode === 200) {

            } else {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage('There was an error marking the message as read. Please try again later.');
            }
            setReloadMessages(!reloadMessages);
        }
    }

    const showDialog = (record) => {
        setCurrentSenderID(record.SenderId);
        setCurrentSubject(record.Subject);
        setCurrentMessage(record);
        setShowReplyDialog(true);
    }

    const closeDialog = () => {
        setCurrentSenderID('');
        setCurrentSubject('');
        setCurrentMessage({});
        setShowReplyDialog(false);
        setReloadMessages(!reloadMessages);
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'SentOn',
            width: '10%',
            editable: true,
            defaultSortOrder: 'descend',
            align: 'center',
            sorter: (a, b) => {
                return a['SentOn'].localeCompare(b['SentOn']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'From',
            dataIndex: 'Sender',
            width: '20%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Sender'].localeCompare(b['Sender']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'To',
            dataIndex: 'Receiver',
            width: '20%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Receiver'].localeCompare(b['Receiver']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Subject',
            dataIndex: 'Subject',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Subject'].localeCompare(b['Subject']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        {record.Status !== 'Read'&&
                        <Button type="link" onClick={ async () =>  await updateMessageAsRead(record.MessageId)}>Mark as read</Button>}
                        <Button type="link" onClick={() => showDialog(record)}>Reply</Button>
                    </Space>
                );
            }
        }
    ];

    const closeAlert = () => {
        setShowAlert(false);
    }

    const onMarkAsRead = async (record) => {
        await updateMessageAsRead(record.MessageId);
    };

    const onReply = async (record) => {
        showDialog(record);
    };

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Messages</Breadcrumb.Item>
                </Breadcrumb>
            </div>
           {/* <Table
                dataSource={messages}
                columns={columns}
                rowClassName={(record) => record.Status !== 'Read' ? 'message-row-not-read' : ''}
            />
            */} 
            <NewEditableGrid
                    data={messages}
                    columnDetails={columnDetail}
                    isEditable={false}
                    useIcons={false}
                    rowClassName={(record) => record.Status !== 'Read' ? 'message-row-not-read' : ''}
                    dynamicActions={[{name: 'Mark as read', function: onMarkAsRead, isIcon: false},
                        {name: 'Reply', function: onReply, isIcon: false}]} />

            <ReplyMessageDialog complaintID={null} contactID={null} open={showReplyDialog} onClose={closeDialog} subject={currentSubject} personID={currentSenderID} messageObj={currentMessage} />
            <AlertBox key="Message-alertbox" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default MessagesScreen;

import React from 'react';

import BusinessHoursForm from './BusinessHoursForm';

import NewDynamicTable from '../../../shared/NewDynamicTable';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';

import {getBusinessHours} from '../../../../networking/NetworkingBusinessHoursByCountry';

export default class BusinessHoursScreen extends React.Component {
    state = {
        businessHours: [],
        selectedHour: {},
        loading: false,
        errorMessage: '',
        showErrorAlert: false,
    }

    componentDidMount = () => {
        this.setState({loading: true});

        getBusinessHours(this.props.countryID).then(
            (value) => {
                this.setState({
                    businessHours: value.businessHours,
                    loading: false,
                })
            }
        );
    }

    handleRowClick = (obj) =>{
        this.setState({
            selectedHour: obj,
        })
    }

    updatePage = () => {
        this.setState({loading: true});

        getBusinessHours(this.props.countryID).then(
            (value) => {
                ////debugger
                this.setState({
                    businessHours: value.businessHours,
                    loading: false,
                })
            }
        );
    }

    render(){
        return(
            <div>
                <BusinessHoursForm updateFunction={this.updatePage} selectedBusinessHour={this.state.selectedHour}/>
                <NewDynamicTable
                    id="business-hours-country-table"
                    hiddenHeaders={['BusinessHoursByCountryId','CountryId', 'CreatedBy', 'CreatedOn', 'UpdatedBy', 'UpdatedOn', 'UpdateToken']} 
                    data={this.state.businessHours} 
                    enableClick={true} 
                    clickFunction={this.handleRowClick} 
                    useDeleteButton={false}  
                    useFilter={false} 
                    usePaginator={false}
                    enableSortByHeader = {false}
                /> 
            </div>
        );
    }

}
import { useEffect, useState } from "react";
import { getBusinessHours } from "../../../../networking/NetworkingAfterHours";
import { getDefaultPagingNumberOfItems } from "../../../../helpers/PackageJsonHelper";

const useBusinessHours = () => {
    const [businessHours, setBusinessHours] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        let isCancelled = false;

        const loadBusinessHours = async () => {
            const json = await getBusinessHours(0, 0);
            if (!isCancelled) {
                const totalCount = json.businessHours.length;
                const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                const _businessHours = json.businessHours.map((b, index) => ({ ...b, key:  `business-hours-${index}`}));
                setBusinessHours(_businessHours);
                setTotalPages(totalPages);
                setNeedsReloading(false);
            }
        }
        if (needsReloading) {
            loadBusinessHours();
        }

        return () => {
            isCancelled = true;
        }
    }, [needsReloading]);

    return [businessHours, totalPages, setNeedsReloading];
}

export default useBusinessHours;
import React from 'react';

import CardBoard from './CardBoard';

import {getScreen} from '../../../../shared/ScreenHelper';
import {getStyle} from '../../../../../styles/styles';

import { getBeneficiariesByID} from '../../../../../networking/NetworkingBeneficiaries';

const style = getStyle();

export default class BeneficiariesCardViewScreen extends React.Component {
    
    state = {
        screen: 'board',
        activeTab: '',
        elementID: 0,
        beneSelected: null,
        customerInfo: null
    }

    handleBackClick = () => {
        this.setState({screen: 'board'});
    }

    onClickElementBoard = (element) => {
        console.log(element);
        /*
            Beneficiary ID  : 13703
            Created on      : "2020/08/26"
            Customer        : "IKEA (ML) (9645)"
            Name            : "YKR Ltd"
            Type            : "Corporate"
        */

        let bene = {};

        getBeneficiariesByID(element['Beneficiary ID{No line}'], 1).then(
            (jsonResponse) => {

                console.log(jsonResponse.beneficiary);
                ////debugger

                bene.selectedBene       = jsonResponse.beneficiary[0];
                bene.type               = jsonResponse.beneficiary[0].BeneficiaryType;
                bene.customerID         = jsonResponse.beneficiary[0].CustomerId;
                bene.customerPersonID   = jsonResponse.beneficiary[0].PersonIdCustomer;
                bene.customerName       = jsonResponse.beneficiary[0].NameCustomer;

                this.setState({
                    screen       : 'ShowBeneficiaryInfo', 
                    elementID    : element['Beneficiary ID{No line}'],
                    beneSelected : bene
                });
            }
        );
    } 

    changeScreen = (name) =>
    {
        this.setState({
            screen: name
        });
    }
    
    render = () => {
        let screenRender;
        let isBoardView = true;
        if (this.state.screen === 'board'){
            screenRender = (<CardBoard onClickElementBoard={this.onClickElementBoard}/>);
        } else {
            isBoardView = false;
            screenRender = getScreen(
                this.state.screen, 
                this.state.elementID, 
                this.handleBackClick,
                null,
                null, 
                this.state.beneSelected,
                'Beneficiary Card View'
            );
        }
        return(
            <div>
                {isBoardView && <h3 className="uk-heading-divider" style={style.titleStyle}>Beneficiary Card View</h3>}
            	{screenRender}
            </div>
        );
    }
}
import React, { useState } from 'react';
import { Breadcrumb } from 'antd';
import { useEffect } from 'react';
import { getAllCases } from '../../../networking/NetworkingTMx';
import NewEditableGrid from '../../shared/NewEditableGrid';
import CaseDetail from './CaseDetail';
import AppContext from '../../../AppContext';
import { useContext } from 'react';
import DatePicker from '../../shared/DatePicker';
import { formatDate } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
import moment from 'moment';

const CasesScreen = ()=> {
    const context = useContext(AppContext);   
    const [cases, setCases] = useState([]);
    const [columnDetail, setColumnDetail] = useState([]);
    const [reloadCases, setReloadCases] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [currentCase, setCurrentCase] = useState({});
    const dateFormat = "YYYY/MM/DD";
    const today = new Date();
    const [toDate, setToDate] = useState(formatDate(today));
    let initialDate = new Date();
    initialDate.setDate(today.getDate() -7);    
    const [fromDate, setFromDate] = useState(formatDate(initialDate));
    const [status, setStatus] = useState('Open');
    const statusList = [
        {name:'All', value:''},
        {name:'Closed', value:'Closed'},
        {name:'Open', value:'Open'},
        {name:'Reopen', value:'Reopen'},
    ]
    

    let style = getStyle();

    useEffect(() => {
        const loadCases = async () => {
            context.startLoading();
            const model = {
                json : {
                    "FromDate": fromDate ?moment(fromDate, dateFormat): '',
                    "ToDate": toDate ?moment(toDate, dateFormat): '',
                    "Status":status
                 }
            };
            const json = await getAllCases(model);
  
            if (json != null && json.result != null) {
                const _formattedCases = json.result.map((m, i) => ({ ...m, key: `case-${i}` }));
                setCases(_formattedCases);
                if (json.columnDetail != null) {
                    const _columnDetail = JSON.parse(json.columnDetail);
                    setColumnDetail(_columnDetail);
                }
            }
            else
            {
                setCases([]);
                setColumnDetail([]);
            }
            context.finishLoading();
        };
        loadCases();
    }, [reloadCases]);

    const onViewDetail = (record) =>{
        setCurrentCase(record);
        setShowDetail(true);
    }

    const handleNewClick = () =>{
        setCurrentCase({});
        setShowDetail(true);
    }

    const returnToList = () =>{
        setCurrentCase({});
        setShowDetail(false);
        setReloadCases(!reloadCases);
    }

    const onFromDateChange = (date) => {
        const _date = formatDate(date);
        setFromDate(_date);
    };

    const onToDateChange = (date) => {
        const _date = formatDate(date);
        setToDate(_date);
    };

        return(
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item-bold" onClick={returnToList}>Cases</Breadcrumb.Item>

                {showDetail &&
                        <Breadcrumb.Item className="breadcrumb-item-bold">{`Case Activity - ${currentCase.CaseID ? currentCase.CaseID : 'New'}`}</Breadcrumb.Item>
                 }       
                    </Breadcrumb>
                
                </div>
                {!showDetail && 
                <div className="uk-grid">
                    <div className="uk-width-1-8">
                        <div className="uk-margin">
                            <label className="uk-form-label">From</label>
                            <div className="uk-form-controls">
                                <DatePicker
                                key={'dp-fromDate'}
                                className="uk-input"
                                style={style.inputStyle}
                                value={fromDate}
                                endDate={today}
                                onDayChange={(e) => {
                                    onFromDateChange(e);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-8">
                        <div className="uk-margin">
                            <label className="uk-form-label">To</label>
                            <div className="uk-form-controls">
                                <DatePicker
                                key={'dp-toDate'}
                                className="uk-input"
                                style={style.inputStyle}
                                value={toDate}
                                endDate={today}
                                onDayChange={(e) => {
                                    onToDateChange(e);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4">
                        <div className="uk-margin">
                            <label className="uk-form-label" >Status</label>
                            <div className="uk-form-controls">
                            <select id="status-filter" className="uk-select" value={status}
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}>
                            {statusList.map((type) => (
                                <option
                                    key={`status-option-${type.value}`}
                                    value={type.value}>{`${type.name}`}</option>
                            ))}
                        </select>
                              </div>
                        </div>
                    </div>
                    <div className="uk-width-1-8 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={() => setReloadCases(!reloadCases)}>Refresh</button>                        
                    </div>
                    <div className="uk-width-1-8 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={handleNewClick}>New Case</button>                        
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                    <NewEditableGrid
                            data={cases}
                            columnDetails={columnDetail}
                            isEditable={false}
                            useIcons={false}
                            useExportButton={true}
                            tableName={'Cases'}
                            pageRowsSize={15}
                            dynamicActions={[{name: 'Edit', function: onViewDetail, isIcon: false}]} 
                    />
                    </div>
                </div>
                }
            {showDetail && 
                <CaseDetail Case={currentCase}/>
            }
            </div>
        );
}

export default CasesScreen;
import React from 'react';
import { getStyle } from '../../../../styles/styles';
import AuditEntity from './AuditEntity';
var style = getStyle();
export default class AuditOutgoingFundScreen extends React.Component {

    render() {
        return (
            <div>                
                <AuditEntity entityID={this.props.outgoingFundID} categoryName={"Audit - Outgoing Funds"} />
            </div>
        );
    }
}
import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody, genericCallWithOutBody, genericDeleteWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getCustomReports(){
    try{
        let params = {}

        let response = await genericGetWithParameters('CustomReport/GetCustomReports',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function getReportSources(){
    try{
        let params = {}

        let response = await genericGetWithParameters('CustomReport/GetReportSources',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function getReporFields(){
    try{
        let params = {}

        let response = await genericGetWithParameters('CustomReport/GetReporFields',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function saveCustomReport(objCustomReport){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'CustomReport/SaveCustomReport';
    let response = await genericCallWithBody(method, apiName, objCustomReport);
    return response;
}

export async function saveFieldsByCustomReports(FieldsByCustomReportsJson){
    var apiName = '';
    apiName = 'CustomReport/SaveFieldsByCustomReports';
    let response = await genericCallWithBody('POST',apiName, FieldsByCustomReportsJson);
    return response;
}

export async function getCustomReportByReportId(reportId){
    try{
        let params = {
            ReportId: reportId
        }

        let response = await genericGetWithParameters('CustomReport/GetCustomReportByReportId',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function getCustomReportByReportIdAndWhereClause(reportId, whereClause){
    try{
        let params = {
            ReportId: reportId,
            WhereClause: whereClause,
        }

        let response = await genericGetWithParameters('CustomReport/GetCustomReportByReportIdAndWhereClause',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function getFieldsByCustomReportId(reportId){
    try{
        let params = {
            ReportId: reportId
        }

        let response = await genericGetWithParameters('CustomReport/GetFieldsByCustomReportId',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function deleteCustomReport(ReportId){
    try{
        let params = {
            'ReportId': ReportId
        };
        let response = await genericDeleteWithParameters('CustomReport/DeleteCustomReport',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import Card from './Card';

//import AlertBox from '../../../shared/AlertBox'
//import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';

import { getCustomersCardView } from '../../../../../networking/NetworkingCustomers';


//import {formatToCurrency} from '../../../../helpers/FormatHelper';

import './CardBoard.scss';
//import { ActionName } from '../../../../constants/ActionNames';



export default class CardBoard extends React.Component {
    state = {
        data:[],
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        confirmationAlertTitle: '',
        confirmationAlertMessage: '',
        showConfirmAlert: false,
        customerIDFilter: '',
        customerNameFilter: '',
        customerList: [],
        //
        changeScreen: false,
        nextScreen: '',
        tab: '',
        id: 0
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        getCustomersCardView(this.state.customerIDFilter, this.state.customerNameFilter).then((response) => {
            if(response && response.httpStatusCode === 200){
                this.setState({data: response.data});
            }
        })
    }

    getCustomerIDFilter = (event) => {
        this.setState({customerIDFilter: event.target.value});
    }

    getCustomerNameFilter = (event) => {
        this.setState({customerNameFilter: event.target.value});
    }
    /*
    handleUpdateCustomerIDName = (value) => {
        if(value){
            if (value.value) {
                this.setState({ customerIDName: value.name !== 'select' ? value.name : '', customerIDFilter: value.value !== 'select' ? value.value : '' });
            }
        }
    }

    searchCustomer = (query) =>  {
        console.group("searchCustomer");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for (var i = 0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({customerList: response.customers});
                }
            }
        )
    }
    */

    clearFilter = () => {
        this.setState({customerIDFilter: '', customerNameFilter: ''}, ()=>{
            this.getData();
        });
    }

    render = () => {
        let columns = [];
        let columnTitle = "";
        if(this.state.data){
            this.state.data.forEach((element, i) => {
                let elementCards = [];
                if (element.customers){
                    if(element.customers[0]['Customer{No label}']){
                        element.customers.forEach((elementChild, index) => {
                            elementCards.push(<Card key={1000 + index} element={elementChild} onClickElement={this.props.onClickElementBoard}/>);
                        });
                    }
                }
                if(elementCards.length > 0){
                    columnTitle = `${element["Status"]} (${elementCards.length})`;
                } else {
                    columnTitle = element["Status"];
                }
                let elementColumn = (
                    <div className="uk-width-1-5 board-column" key={10 + i}>
                        <div className="board-column-title"><h4>{columnTitle}</h4></div>
                        <div className="cards-column">{elementCards}</div>
                    </div>
                );
                columns.push(elementColumn);
            });
        }

        

        return(
            <div>
                <div>
                    <form className="uk-form-stacked uk-grid">
                        <div className="uk-width-2-5" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-customer">Customer</label>
                                <div className="uk-form-controls">
                                <input className="uk-input" id="form-board-deal" value={this.state.customerIDFilter} type="text" placeholder="Customer ID or Name" onChange={this.getCustomerIDFilter}/>
                                {/*<DynamicSearchSelect id="CustomerIDName" placeholder="Customer" searchFunction={this.searchCustomer} getValue={this.handleUpdateCustomerIDName} data={this.state.customerList} objValue={{ value: this.state.customerIDFilter, name: this.state.customerIDName }} />*/}
                                </div>
                            </div>
                        </div>
                        {/*<div className="uk-width-2-5">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-deal">Customer Name</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-board-deal" value={this.state.customerNameFilter} type="text" placeholder="Customer Name" onChange={this.getCustomerNameFilter}/>
                                </div>
                            </div>
                        </div>*/}
                        <div className="uk-width-1-5">
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.getData()}>Apply Filter</button>
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.clearFilter()}>Clear Filter</button>
                        </div>
                    </form>
                </div>
                <div className="board">
                    {columns}
                </div>
            </div>
        );
    }
}
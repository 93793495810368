
import jwt from 'jsonwebtoken';
import { decrypt } from './Networking';

const apiURL = window.REACT_APP_API_URL_TMX;
const apiKey = window.REACT_APP_API_KEY;

export async function genericGetWithParameters(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = ''

        if(params)
        {
            query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        }
        
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var responseJSON = {};
        if(responseJWT.data !== undefined){
            responseJSON = await decrypt(responseJWT);
        } 
        if(responseJWT.Message !== undefined){
            httpErrorMessage = responseJWT.Message;
        }
        responseJSON.httpStatusCode = response.status;
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithBody(apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + apiName, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var responseJSON = {};
        if(responseJWT.data !== undefined){
            responseJSON = await decrypt(responseJWT);
        } 
        if(responseJWT.Message !== undefined){
            httpErrorMessage = responseJWT.Message;
        }
        responseJSON.httpStatusCode = response.status;
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBody(method, apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
        /*if(response.status === 200 ||
            response.status === 400){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            return response;
        }*/
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithBody(apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + apiName , {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'SessionID': sessionID,
              'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
  }

/**************************************/

export async function getAllCases(model){
    try {
        let response = await genericGetWithBody('Cases/GetCases',model);
        
        return response;
    } catch (error) {
        console.error(error);
    }
}
 
export async function getCaseActivityByCaseID(caseID){
    try {
        let params = {
            caseID: caseID
        };
        
        let response = await genericGetWithParameters('Cases/GetInfoByCaseID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveNewCase(model){
    try{
        let response = await genericCallWithBody('POST','Cases/SaveNewCase',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCase(model){
    try{
        let response = await genericCallWithBody('POST','Cases/UpdateCases',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCaseStatus(model){
    try{
        let response = await genericCallWithBody('POST','Cases/UpdateCaseStatus',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveActivity(model){
    try{
        let response = await genericCallWithBody('POST','Cases/CaseActivitySave',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteActivity(model){
    try{
        let response = await genericCallWithBody('POST','Cases/CaseActivityDelete',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachmentsList(activityID){
    try {
        let params = {
            ActivityID: activityID
        };
        
        let response = await genericGetWithParameters('Cases/GetAttachmentsList', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function addAttachment(model){
    try{
        let response = await genericCallWithBody('POST','Cases/AddAttachment',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteAttachment(model){
    try{
        let response = await genericCallWithBody('DELETE','Cases/DeleteAttachment',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachment(id){
    try {
        let params = {
            ID: id
        };
        
        let response = await genericGetWithParameters('Cases/GetAttachment', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
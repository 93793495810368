import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {getStyle} from '../../../styles/styles';
import { 
    formatValidSelect, 
    formatValidInput, 
    formatValidDatePicker, 
    formatDate } from '../../../helpers/FormatHelper';
import DatePicker from '../../shared/DatePicker';
import NewDynamicTable from '../../shared/NewDynamicTable';

import {getGlobalSearch} from '../../../networking/NetworkingTrader'


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  

const DefaultList = [{'Deal Transfer ID': '', 'Description': '', 'Issue Date': '', 'Value Date': '', 'Blotter': '', 'Trader Name': '', 'Currency ID': '', 'Amount': '', 'From Account': '', 'To Account': '', 'Status': ''}]

var style = getStyle();

export default class SearchDealTransferScreen extends React.Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            showModal : this.props.open,
            fullWidth : true,
            maxWidth  : 'lg',
            valueSearchable: '',
            fromDate : '',
            dealTransfersList: DefaultList,
            validationsFromDate : true
        };
    }
    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.open !== prevProps.open)
        {
            this.setState({
                showModal: this.props.open
            })
        }
    }

    componentDidMount()
    {
        /*
            GET api/Trader/GetGlobalSearch?strSearch={strSearch}&strSearchTable={strSearchTable}&fromDate={fromDate}

            //Get System Date
            this.View.EntryDate = this.ApplicationDate.Date;
            this.View.ValueDate = this.ApplicationDate.Date;

            //Load Traders
            this.LoadTraders();

            //Get Blotters
            this.LoadBlotters();

            //Get Currencies
            this.LoadCurrencies();

            //Set default values
            this.View.SelectedCurrencyID = this.DefaultBuyCurrencyID;

            this.OnCurrencyChange(this.View.SelectedCurrencyID);

            int dealTransferID = this.SessionService.GetValue<int>(Constants.SessionNames.DealTransferID);

            if(dealTransferID > 0)
            {
                this.OnSelectedDealTransfer(dealTransferID);
            }

        */
    }

    /*
        private void LoadTraders()
        {
            List<Trader> oTraders;
            if (this.TraderService.GetTraders(false, out oTraders))
            {
                this.View.Traders = oTraders;
                this.View.TraderId = this.SessionService.CurrentTrader.TraderId;
            }
            else
            {
                throw new Exception("Traders not found.");
            }
        }

        private void LoadBlotters()
        {
            List<BlotterDefinition> oBlotters;
            if (!this.BlotterService.GetPositionBlotters(out oBlotters))
            {
                throw new Exception("Blotters not found.");
            }
            else
            {
                BlotterDefinition defaultValue = new BlotterDefinition();
                defaultValue.Description = "Select a Blotter...";
                defaultValue.BlotterID = 0;
                oBlotters.Insert(0, defaultValue);

                this.View.Blotters = oBlotters;

                //assign default blotter to the trader
                BlotterUser objBlotterUser;
                if (!this.BlotterService.GetBlotterByUserId(this.GetUserId, out objBlotterUser))
                {
                    throw new Exception("Error in GetBlotterByUserId method.");
                }

                if (objBlotterUser != null)
                {
                    this.View.SelectedBlotterID = objBlotterUser.BlotterId;
                }
                else
                {
                    this.View.SelectedBlotterID = 0;
                }
            }
        }

        private void LoadCurrencies()
        {
            List<Currency> oCurrencies;
            if (!this.CurrencyBusinessService.GetNostroAccountCurrencies(this.NostroAccountGuid, out oCurrencies))
            {
                throw new Exception("Currencies not found.");
            }
            else
            {
                View.Currencies = oCurrencies;
            }
        }
    */

    closeModal = () =>
    {
        this.setState({
            showModal           : false,
            valueSearchable     : '',
            fromDate            : '',
            dealTransfersList   : DefaultList
        },()=>{
            this.props.onClose();
        });
    }

    handleUpdateFromDate(date){
        this.setState({
            fromDate: formatDate(date)});
    }

    handleUpdateSearchString(event){
        this.setState({
            valueSearchable: event.target.value
        });
    }

    handleSearchClick()
    {
        //GetGlobalSearch(this.state.valueSearchable, 'DealTransfers', this.state.fromDate).then(
        getGlobalSearch(this.state.valueSearchable, 'DealTransfers', this.state.fromDate).then(
            (jsonResponse) => {
                console.log("getGlobalSearch: "+JSON.stringify(jsonResponse));
                /*getGlobalSearch: 
                {
                    "DealTransfers":
                    [
                        {
                            "DealTransferID":18,
                            "Description":"Deal Transfer",
                            "IssueDate":"2020-09-30T05:00:00",
                            "ValueDate":"2020-11-25T05:00:00",
                            "Blotter":"Main Blotter",
                            "TraderName":"o.lopez",
                            "CurrencyID":"USD",
                            "Amount":999.00,
                            "Status":"Completed"
                        },
                        {
                            "DealTransferID":17,
                            "Description":"Deal Transfer",
                            "IssueDate":"2020-09-30T05:00:00",
                            "ValueDate":"2020-10-10T05:00:00",
                            "Blotter":"Main Blotter",
                            "TraderName":"Admin, Admin",
                            "CurrencyID":"USD",
                            "Amount":1010.00,
                            "Status":"Completed"
                        },...
                    ],
                    "httpStatusCode":200
                }
                */
               if(jsonResponse !== undefined)
               {
                   switch (jsonResponse.httpStatusCode)
                   {
                        case 200:
                        {
                            if(jsonResponse.DealTransfers !== undefined && Array.isArray(jsonResponse.DealTransfers))
                            {
                                this.setState({
                                    dealTransfersList: jsonResponse.DealTransfers
                                });
                            }else
                            {
                                this.setState({
                                    dealTransfersList: DefaultList
                                });
                            }
                           break;
                        }
                        default:
                        {
                            this.setState({
                                dealTransfersList: DefaultList
                            });
                        }
                   }
               }
            }
        );
    }

    handleExportClick()
    {

    }

    handleRowClick = (dealTransferObj) =>
    {
        if(dealTransferObj['Deal Transfer ID'] !== "")
        {
            this.props.onClickRow(dealTransferObj); 
        }
    }
  
    render() 
    {
        return (
            <div>
                {/*Search Deal Transfer Dialog*/}
                <Dialog 
                    open        = {this.state.showModal} 
                    onClose     = {this.closeModal}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {this.state.maxWidth}
                >
                    <DialogTitle id="customized-dialog-sdt-title" onClose={this.closeModal}>
                        Search Deal Transfers
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-margin uk-grid">
                            <div className="uk-width-1-3">
                                <div className="uk-margin">
                                    <label className="uk-form-label" style={style.boldLabel} >Search</label>
                                    <input 
                                        className="uk-input" 
                                        type="text" 
                                        placeholder="" 
                                        onChange={this.handleUpdateSearchString.bind(this)}
                                        value={this.state.valueSearchable}
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-3">
                                    <label className="uk-form-label" style={style.boldLabel} >From</label>
                                    <div className="" style={style.inputStyle}>
                                        <DatePicker 
                                            readOnly    = {true} 
                                            className   = {formatValidDatePicker(this.state.validationsFromDate)} 
                                            value       = {this.state.fromDate} 
                                            onDayChange = {this.handleUpdateFromDate.bind(this)}
                                        />
                                    </div>
                                </div>
                            <div
                                className="uk-width-1-3" 
                                //style={{marginTop: '-5px'}}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <button className="uk-button uk-button-green" onClick={this.handleSearchClick.bind(this)}>Search</button>
                                {/*<button className="uk-button uk-button-green" onClick={this.handleExportClick.bind(this)}>Export</button>*/}
                            </div>
                        </div>
                        <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                            <NewDynamicTable
                                id              = "sc-table"
                                data            = {this.state.dealTransfersList} 
                                hiddenHeaders   = {['Description', 'Source Blotter', 'TraderID', 'UpdateToken', 'BranchID']}
                                enableClick     = {true} 
                                clickFunction   = {this.handleRowClick}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle} from '../../../styles/styles';
import {BoardName} from '../../../constants/BoardNames';

var style = getStyle();

export default class DealBoardScreen extends React.Component {

    state = {
        showTitle: true
    }
    componentDidMount() {

    }
    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }
    render() {



        return (
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Deal Boards</h3>}
                <GenericBoard board={BoardName.DealsGenericBoard} mainTitle={"Deal Boards"} isShowTitle={this.isShowTitle} selectSection/>
            </div>
        );
    }
}
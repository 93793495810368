import {genericCallWithBody, genericGetWithParameters} from './Networking';

export async function saveQuote(objQuote){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Quote/Post';
    let response = await genericCallWithBody(method, apiName, objQuote);
    return response;
}

export async function getQuotesByStatus(customerID, status, page, items){
    try {
        let params = {
            'customerID': customerID,
            'status': status,
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('Quote/GetQuotesByStatus',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getQuoteByID(quoteNo){
    try {
        let params = {
            'quoteNo': quoteNo,
        }
        let response = await genericGetWithParameters('Quote/GetQuoteByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

export default class BroadcastRateSelectionDialog extends React.Component{
    componentDidMount(){
    }

    onSelectRate = (objRate) => {
        objRate.IsBroadcastedRate = true;
        this.props.onSelectBroadcastedRate(objRate);
    }

    render(){
        var rows = [];
        var broadcastRateOptions = [];
        if(this.props.broadcastRateOptions !== undefined){
            broadcastRateOptions = this.props.broadcastRateOptions;
        }
        broadcastRateOptions.forEach(element => {
            let spread = ((Number(element.CustomerRate) - Number(element.MarketRate)) * 10000).toFixed(2);
            rows.push(
                <tr onClick={() => this.onSelectRate(element)} key={element.RateQuoteID}>
                    <td>{element.Source}</td>
                    <td>{element.MarketRate}</td>
                    <td>{element.CustomerRate}</td>
                    <td>{spread}</td>
                </tr>
            );
        });

        return(
            <div>
                <Dialog open={true}
                    onClose={this.props.onClose}>
                    <DialogTitle id="attachments-dialog-title" onClose={this.props.onClose}>
                        Broadcast rate selection
                    </DialogTitle>
                    <DialogContent dividers>
                        <table className="uk-table uk-table-hover uk-table-divider">
                            <thead>
                                <th>Source</th>
                                <th>Market Rate</th>
                                <th>Customer Rate</th>
                                <th>Spread</th>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
import React from 'react';
import AuditEntity from './AuditEntity';
export default class AuditContactScreen extends React.Component {

    handleBackLink() {
        this.props.backLink();
    }

    render() {
        var auditTitle = (
            <h3 className="uk-heading-divider component-title" >
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                    <i className="fa fa-arrow-left back-arrow" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                </button>
                Audit Contact
            </h3>
        )
        if (this.props.isTitle != null) {
            auditTitle = ''
        }
        return (
            <div>
                {auditTitle}
                <AuditEntity entityID={this.props.contactID} categoryName={"Audit - Customer Contacts"} />
            </div>
        );
    }
}
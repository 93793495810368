import React, {useState, useEffect, useRef} from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, DatePicker, Select, Tag, Space, 
    Badge, Checkbox, Modal, Result, Button, Switch, Calendar, Alert, Radio, Col, Row } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.css';
import '../../../../css/ant.css';
import moment from 'moment';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';

import { formatDate, formatToCurrency, formatToCurrencyWithScale, formatValidInput, formatValidSelect } from '../../../../helpers/FormatHelper';

import { getCurrentCustomerCommissionTableByCustomer, saveCustomerCommission, updateCustomerCommission, addNewRangeCustomerCommissionTable } from '../../../../networking/NetworkingCommission';
import { getTradersWithBlotter } from '../../../../networking/NetworkingBlotters';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTablesDescription } from '../../../../networking/NetworkingLookUpTables';

const { Option } = Select;

export default function NewCommissionDetailsScreen(props)
{
    const [updating, setUpdating]                                   = useState(false);
    const [currentCustomerCommission, setCurrentCustomerCommission] = useState([]);
    const [customerCommissionsTable, setCustomerCommissionsTable]   = useState([]);
    const [showDeleted, setShowDeleted]                             = useState(false);

    //Para editar -------------------------------------------------------------------------------------------------
    const [editingKey, setEditingKey]   = useState(0);
    const isEditing                     = (record) => record.CustomerCommissionTableId === editingKey;
    const [form]                        = Form.useForm();

    const edit = (record) => {
        form.setFieldsValue({
            'TraderId'          : 0,
            'Effective Date'    : '',
            'End Date'          : '',
            'Commission'        : 0,
            'Status'            : '',
            'Bonus Indicator'   : false,
        ...record,
        });
        setEditingKey(record.CustomerCommissionTableId);
        setIsCheckedBonusIndicator(record['Bonus Indicator']);
    };

    const [traders, setTraders]     = useState([]);
    const [statuses, setStatuses]   = useState([]);
    const [isCheckedBonusIndicator, setIsCheckedBonusIndicator] = useState(false);
    //------------------------------------------------------------------------------------------------------------
    const [modalObject, setModalObject] = useState({show: false, status:'', title: '', message:''});
    //------------------------------------------------------------------------------------------------------------
    const [typeAlert, setTypeAlert]         = useState('');
    const [loading, setLoading]             = useState(false);

    const [showAlert, setShowAlert]         = useState(false);
    const [alertTitle, setAlertTitle]       = useState('');
    const [alertMessage, setAlertMessage]   = useState('');
    const [errorList, setErrorList]         = useState([]);
    //------------------------------------------------------------------------------------------------------------
    const [newRangeDateSelected, setNewRangeDateSelected] = useState(moment().format('YYYY/MM/DD'));
    const [newTraderSelected, setNewTraderSelected] = useState(null);
    const [validateStatusTrader, setValidateStatusTrader] = useState('');

    useEffect(()=>{
        getCurrentCustomerCommissionTableByCustomer(props.customerId, '').then(
            (responseCCT)=>{

                console.log("getCustomerCommissionTableByCustomer")
                console.log(JSON.stringify(responseCCT));
                /*
                    {
                        "AllCommissions":[
                            {
                                "CustomerCommission":{
                                    "Description":"Trader",
                                    "Name":"Test Trader",
                                    "Commission Rate":20
                                },
                                "CustomerCommissionsTable":[
                                    {
                                        "CustomerCommissionTableId":9637,
                                        "Description":"Trader",
                                        "TraderId":1027,
                                        "Trader Name":"Test Trader",
                                        "Effective Date":"2020-08-24T14:36:27.993",
                                        "End Date":"3000-01-01T00:00:00",
                                        "Commission":20,
                                        "Status":"Active",
                                        "Bonus Indicator":false,
                                        "Audit":"Created by paolo_kelopana on Aug 24 2020  2:36PM - Updated by paolo_kelopana on Mar 18 2021  4:13PM",
                                        "UpdateToken":"637516807922966667"
                                    }
                                ]
                            }
                        ],
                        "httpStatusCode":200
                    }
                */

                if(responseCCT !== undefined)
                {
                    if(responseCCT.AllCommissions !== undefined)
                    {
                        if(responseCCT.AllCommissions[0].CustomerCommission !== undefined)
                        {
                            let auxList =  [];
                            auxList.push(responseCCT.AllCommissions[0].CustomerCommission);
                            setCurrentCustomerCommission(auxList);
                        }

                        if(responseCCT.AllCommissions[0].CustomerCommissionsTable !== undefined &&
                           responseCCT.AllCommissions[0].CustomerCommissionsTable.length > 0)
                        {
                            responseCCT.AllCommissions[0].CustomerCommissionsTable.forEach(cctone => {
                                cctone['Effective Date'] = moment(cctone['Effective Date']);
                                cctone['End Date'] = moment(cctone['End Date']);
                                if(moment(cctone['Effective Date']).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD'))
                                {
                                    setNewRangeDateSelected(moment().add(1, 'days').format('YYYY/MM/DD'));
                                }
                            });
                            setCustomerCommissionsTable(responseCCT.AllCommissions[0].CustomerCommissionsTable);
                        }
                    }
                }
        });

        /*getActiveTradersMin().then(
            (responseGetActiveTradersMin) =>{
                console.log("getActiveTradersMin")
                console.log(responseGetActiveTradersMin);

                
                //    BranchId: 1
                //    PersonId: "EA5D833E-5229-42F5-B8AD-81F89685F23C"
                //    Trader: "Admin Admin"
                //   TraderID: 15
                //   User Name: "Admin"
                

                if(responseGetActiveTradersMin !== undefined)
                {
                    getTraders().then( // List of traders without assigned Blotter
                        (responseGetTraders) => {
                            if (responseGetTraders.traders !== undefined && responseGetTraders.traders !== null)
                            {
                                let activeTradersWithBlotter = responseGetActiveTradersMin.traders
                                .filter(({ TraderID }) => !responseGetTraders.traders.some((traderWithoutBlotter) => TraderID === traderWithoutBlotter.TraderId))
                                .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
                
                                setTraders(activeTradersWithBlotter);
                            }
                        }
                    );
                }
            }
        );*/

        getTradersWithBlotter().then( // List of traders without assigned Blotter
            (responseGetTraders) => {
                if (responseGetTraders.traders !== undefined && responseGetTraders.traders !== null)
                {
                    let activeTradersWithBlotter = responseGetTraders.traders
                    .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
    
                    setTraders(activeTradersWithBlotter);
                }
            }
        );

        getLookUpTablesDescription('Customer Commission Table Status').then(
            (responseL)=>{
                console.log("getLookUpTablesDescription")
                console.log(JSON.stringify(responseL));

                /*
                    {
                        "lookUpTables":[
                            {
                                "LookUpTableID":60,
                                "LookUpTableDescription":"Customer Commission Table Status",
                                "lookUpTableDetails":[
                                    {
                                        "LookUpTableDetail":"Select a Status...",
                                        "DisplayOrder":-1,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    },
                                    {
                                        "LookUpTableDetail":"Active",
                                        "DisplayOrder":0,
                                        "Editable":false,
                                        "Field1":"",
                                        "Field2":"",
                                        "Field3":"",
                                        "Field4":"",
                                        "Field5":"",
                                        "Field6":"",
                                        "MappingId":null
                                    }...
                                ]
                            }
                        ],
                        "httpStatusCode":200
                    }
                */

                if(responseL !== undefined && responseL !== null)
                {
                    switch (responseL.httpStatusCode) 
                    {
                        case 200:
                        {
                            let deletedIndex = null;
                            responseL.lookUpTables[0].lookUpTableDetails.forEach((lookUpTableDetail, index) => {
                                /*lookUpTableDetail.name = lookUpTableDetail.LookUpTableDetail;
                                lookUpTableDetail.value = lookUpTableDetail.LookUpTableDetail;*/
    
                                if(lookUpTableDetail.LookUpTableDetail === 'Deleted')
                                {
                                    deletedIndex = index; 
                                }
                            });
    
                            if(deletedIndex !== null)
                            {
                                responseL.lookUpTables[0].lookUpTableDetails.splice( deletedIndex, 1 );
                            }
    
                            setStatuses(responseL.lookUpTables[0].lookUpTableDetails);
    
                            break;
                        }
                        default:
                            break;
                    }
                }
            }
        );
    },[updating]);

    const cols = [
        { 
            title       : 'Trader Name', 
            dataIndex   : 'TraderId', 
            key         : 'traderName',
            width       : '15%',
            editable    : true,
            render      : (value ,obj, index) =>(
                <span>{obj['Trader Name']}</span>
            ),
        },
        {   
            title       : 'Effective Date', 
            dataIndex   : 'Effective Date', 
            key         : 'effectiveDate',
            render      : (value ,obj, index) =>(
                <span>{formatDate(value)}</span>
            ),
            align       : 'center',
            width       : '15%',
            editable    : false,
        },
        {   
            title       : 'End Date', 
            dataIndex   : 'End Date', 
            key         : 'endDate',
            render      : (value ,obj, index) =>(
                <span>{formatDate(value)}</span>
            ),
            align       : 'center',
            width       : '15%',
            editable    : false,
        },
        { 
            title       : 'Commission', 
            dataIndex   : 'Commission', 
            key         : 'commission',
            align       : 'center',
            width       : '10%',
            editable    : true,
            render      : (value ,obj, index) =>(
                <span>{formatToCurrencyWithScale(value,props.customer.CommissionCurrency)}</span>
            ),
        },
        {
            title       : 'Status',
            dataIndex   : 'Status', 
            key         : 'status',
            align       : 'center',
            render      : (value, object, index) => (
                <span>
                    <Badge status = { value === 'Active' ? 'success': value === 'Active' ? 'Warning': 'red'} />
                    {value}
                </span>
            ),
            width       : '10%',
            editable    : true,
        },
        {
            title       : 'Bonus Indicator',
            dataIndex   : 'Bonus Indicator', 
            key         : 'bonusIndicator',
            align       : 'center',
            render      : (value, object, index) => (
                <Tag 
                    color = {value === true ? "green" : 'red'}
                >{value === true ? "Yes" : 'No'}</Tag>
            ),
            width       : '15%',
            editable    : true,
        },
        {
            title       : 'Action',
            dataIndex   : 'operation',
            key         : 'operation',
            align       : 'center',
            render      : (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <a
                        href    = "javascript:;"
                        onClick = {() => {
                            saveCustomerCommissionEdited(record);
                        }}
                        style   = {{
                            marginRight: 8,
                        }}
                    >Save</a>
                    <Popconfirm 
                        title       = "Sure to cancel?" 
                        onConfirm   = {() => {
                            console.log('Cancel yes Clicked...');
                            setEditingKey(0);
                        }}
                    ><a>Cancel</a>
                    </Popconfirm>
                </span>
                ) : ( record.Status === 'Deleted' ? (<div></div>) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link 
                                disabled    = {editingKey !== 0 ? true : false} 
                                onClick     = {() => {
                                    console.log('Edit clicked...');
                                    console.log(record);
                                    console.log('...');
                                    edit(record);
                                }}
                            >Edit</Typography.Link>
                            <Popconfirm 
                                title       = "Sure to delete?"
                                onConfirm   = {() => {
                                    console.log('accept Delete clicked...');
                                    deleteCustomerCommissionClickedYes(record);
                                }}
                            ><a disabled    = {editingKey !== 0 ? true : false} >Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>)
                );
            },
            width       : '20%',
        },
    ];

    const mergedColumns = cols.map((col) => {
        if (!col.editable) 
        {
            return col;
        }

        let type = '';

        switch (col.dataIndex) 
        {
            case 'Commission':
            {
                type = 'number';
                break;
            }
            case 'Effective Date':
            case 'End Date':
            {
                type = 'date';
                break;
            }
            case 'Status':
            case 'TraderId':
            {
                type = 'select';
                break;
            }
            case 'Bonus Indicator':
            {
                type = 'check';
                break;
            }
            default:
            {
                type = 'text'
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType   : type,
                dataIndex   : col.dataIndex,
                title       : col.title,
                editing     : isEditing(record),
            }),
        };
    });

    function disabledDate(current) {

        let datesDisabled = false;
        customerCommissionsTable.filter((value, index, array) =>  value.Status !== 'Deleted').forEach(cctObject => {
            if(moment(current).format('YYYY-MM-DD') === moment(cctObject['Effective Date']).format('YYYY-MM-DD'))
            {
                datesDisabled = true
            }
        });

        return current && (
            (moment(current) < moment(props.customer.CreatedOn))    || 
            (moment(current) > moment('3000-01-01 00:00:00'))       ||
            datesDisabled
        );
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      }) => {

        let inputNode = null;

        const onChangeBonusIndicator = (event) =>{
            setIsCheckedBonusIndicator(event.target.checked);
            form.setFieldsValue({
                'Bonus Indicator' : event.target.checked
            });
        }

        switch (inputType) 
        {
            case 'number':
            {
                inputNode = <InputNumber 
                    style           = {{ 
                        width: '100%', 
                        textAlignLast: 'center'
                    }}
                    min             = {0.00}
                    step            = {0.01}
                    defaultValue    = {0.00}
                    
                />;
                break;
            }
            case 'select':
            {
                if(dataIndex == 'TraderId')
                {
                    let allOptionsOfTraders = [];
                    traders.forEach(trader => {
                        allOptionsOfTraders.push(
                            <Option value = {trader['TraderID']}>{trader['Trader']}</Option>
                        );
                    });

                    inputNode = (
                        <Select
                            showSearch
                            style               = {{ width: '100%' }}
                            placeholder         = "Select a Trader"
                            optionFilterProp    = "children"
                            filterOption        = {
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allOptionsOfTraders}
                        </Select>
                    );
                }else
                {
                    let allOptionsOfStatues = [];
                    statuses.forEach(status => {
                        allOptionsOfStatues.push(
                            <Option value = {status['LookUpTableDetail']}>{status['LookUpTableDetail']}</Option>
                        );
                    });
                    inputNode = (
                        <Select
                            showSearch
                            style               = {{ width: '100%' }}
                            placeholder         = "Select a Status"
                            optionFilterProp    = "children"
                            filterOption        = {
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange            = {(value,obj)=>{
                                if(value === 'Select a Status...')
                                {
                                    form.setFieldsValue({
                                        'Status' : null
                                    });
                                    form.setFields([
                                        {
                                          name  : 'Status',
                                          errors: ['Please Input Status!'],
                                        },
                                    ]);
                                }
                            }}
                        >
                            {allOptionsOfStatues}
                        </Select>
                    );
                }
                break;
            }
            case 'date':
            {
                inputNode = <DatePicker
                    format          = "YYYY/MM/DD HH:mm:ss"
                    disabledDate    = {disabledDate}
                    showTime        = {{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    onChange        = {(momentDate, dateString) => {
                        /*console.log('Puedo saber el tipo?...');
                        console.log(momentDate.toISOString());
                        console.log(dateString);*/
                        if(momentDate !== null)
                        {
                            if(dataIndex === 'Effective Date')
                            {
                                //console.log(form.getFieldValue('Effective Date'))
                                form.setFieldsValue({
                                    'Effective Date' : moment(momentDate.toISOString())
                                });
                            }else
                            {
                                if(dataIndex === 'End Date')
                                {
                                    form.setFieldsValue({
                                        'End Date' : moment(momentDate.toISOString())
                                    });
                                }
                            }
                        }
                    }}
                    //value           = {dataIndex === 'Effective Date' ? effectiveDateSelected : endDateSelected}
                    style           = {{ 
                        width: '100%', 
                        textAlignLast: 'center'
                    }}
                />
                break;
            }
            case 'check':
            {
                inputNode = <Checkbox 
                    onChange = {onChangeBonusIndicator} 
                    checked  = {isCheckedBonusIndicator}
                />;
                break;
            }
            default:
            {
                inputNode = <Input style={{textAlign: 'center'}}/>;
                break;
            }
        }

        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]}
                key = {record}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
    };

    const saveCustomerCommissionEdited = async (customerCommissionToEdit) => {
        try 
        {
            setLoading(true);
            console.log('Save Clicked...');
            console.log(customerCommissionToEdit);

            /*
                Audit: "Created by daniel.carranza on Dec 12 2012  3:42PM - Updated by paolo_kelopana on Mar 19 2021  3:42PM"
                Bonus Indicator: false
                Commission: 22
                CustomerCommissionTableId: 9634
                Description: "Trader"
                Effective Date: Moment {_isAMomentObject: true, _i: "2012-12-12T15:42:00.133", _f: "YYYY-MM-DDTHH:mm:ss.SSSS", _isUTC: false, _pf: {…}, …}
                End Date: Moment {_isAMomentObject: true, _i: "2021-03-18T00:00:00", _f: "YYYY-MM-DDTHH:mm:ss", _isUTC: false, _pf: {…}, …}
                Status: "Active"
                Trader Name: "Pedro  Alonzo"
                TraderId: 1061
                UpdateToken: "637517653446733333"
            */

            const row = await form.validateFields();

            console.log('form.validateFields...');
            console.log(row);

            let auxPersonIdOfTraderSelected = '';

            traders.forEach(trader => {
                /*
                    BranchId: 1
                    PersonId: "EA5D833E-5229-42F5-B8AD-81F89685F23C"
                    Trader: "Admin Admin"
                    TraderID: 15
                    User Name: "Admin"
                */
                if(trader.TraderID === row['TraderId'])
                {
                    auxPersonIdOfTraderSelected = trader.PersonId
                }
            });

            let objToUpdate = {
                CustomerCommissionTableId   : customerCommissionToEdit.CustomerCommissionTableId,
                CommissionPercent           : row['Commission'],
                BonusIndicator              : row['Bonus Indicator'],
                Status                      : row['Status'],
                UpdateToken                 : customerCommissionToEdit.UpdateToken,
                UpdatedBy                   : localStorage.getItem('UserID'),
                NewPersonIdOfTrader         : auxPersonIdOfTraderSelected
            }

            console.log("objToUpdate");
            console.log(objToUpdate);

            /*
                Bonus Indicator : false
                Commission      : 33
                Status          : "Active"
                TraderId        : 15
            */

            // Para response success o no
            let auxMessage = 'Something went wrong. Please try again later.';
            let saved = false;
            
            await saveCustomerCommission(objToUpdate).then(
                (response) =>{

                    console.log(JSON.stringify(response))
                    /*
                        ErrorMessage: null
                        Message: "Error converting data type nvarchar to int."
                        httpErrorMessage: "An error occurred. Please try again."
                        httpStatusCode: 503
                    */

                    switch (response.httpStatusCode) 
                    {
                        case 503:
                        {
                            if(response.Message !== undefined && response.Message !== null && response.Message !== '')
                            {
                                auxMessage = response.Message
                            }
                            break;
                        }
                        case 200:
                        {
                            /*
                                {
                                    "result":true,
                                    "newUpdateToken":"637521927397300000",
                                    "httpStatusCode":200
                                }
                            */
                            saved = true;
                        }
                        default:
                        {
                            break;
                        }
                    }

                    if(saved)
                    {
                        /*setModalObject({...modalObject, show: true, status:'success', title:'Success', message:'Customer Commission successfully saved.'})
                        setUpdating(!updating);
                        setEditingKey(0);*/

                        setEditingKey(0);
                        setLoading(false);
                        setTypeAlert('SuccessUpdate')
                        setAlertTitle('Success');
                        setAlertMessage('Customer Commission successfully saved.');
                        setShowAlert(true);
                        setUpdating(!updating);
                    }else
                    {
                        /*setModalObject({...modalObject, show: true, status:'error', title:'Error', message: auxMessage})
                        setEditingKey(0);*/

                        setEditingKey(0);
                        setLoading(false);
                        setAlertTitle('Error');
                        setAlertMessage(auxMessage);
                        setShowAlert(true);
                    }
                }
            );

        } catch (errInfo)
        {
            console.log('Validate Failed:', errInfo);
        }
    }

    const handleShowDeletedSwitch = (enable) => {
        setShowDeleted(enable);
        /*if(enable === true)
        {
            let auxList = customerCommissionsTable.filter((value, index, array) =>  value.Status !== 'Deleted');
            setCustomerCommissionsTable(auxList);
        }*/
    };

    const deleteCustomerCommissionClickedYes = (customerCommission) => {

        console.log("deleteCustomerCommissionClickedYes");
        console.log(customerCommission);

        /*
            Audit                       : "Created by daniel.carranza on Dec 12 2012  3:42PM - Updated by Admin on Mar 26 2021 11:54AM"
            Bonus Indicator             : false
            Commission                  : 22
            CustomerCommissionTableId   : 9634
            Description                 : "Trader"
            Effective Date              : Moment {_isAMomentObject: true, _i: "2012-12-12T15:42:00.133", _f: "YYYY-MM-DDTHH:mm:ss.SSSS", _isUTC: false, _pf: {…}, …}
            End Date                    : Moment {_isAMomentObject: true, _i: "2021-03-18T00:00:00", _f: "YYYY-MM-DDTHH:mm:ss", _isUTC: false, _pf: {…}, …}
            Status                      : "Active"
            Trader Name                 : "Pedro  Alonzo"
            TraderId                    : 1061
            UpdateToken                 : "637523564891100000"
        */

        if(customerCommissionsTable.filter((value, index, array) =>  value.Status !== 'Deleted').length === 1)
        {
            /*setModalObject({
                ...modalObject, 
                show    : true, 
                status  :'error', 
                title   :'Error', 
                message : 'This range cannot be deleted.'
            });*/
            setAlertTitle('Error');
            setAlertMessage('This range cannot be deleted.');
            setShowAlert(true);
        }else
        {
            setLoading(true);
            updateCustomerCommission(customerCommission.CustomerCommissionTableId, customerCommission.UpdateToken, localStorage.getItem('UserID')).then(
                (value) => {
                    console.log("updateCustomerCommission")
                    console.log(value);
                    if(value !== undefined)
                    {
                        let errorMessageDefault = 'Something went wrong. Please try again later.';
                        let twoHundred = false;

                        switch (value.httpStatusCode)
                        {
                            case 200:
                            {
                                twoHundred = true;
                                break;
                            }
                            case 409:
                            {
                                errorMessageDefault = 'These Commission have been updated by someone else. Please reload the information and try again.';
                                break;
                            }
                            case 503:
                            {
                                if(value.Message !== undefined)
                                {
                                    errorMessageDefault = value.Message;
                                }
                                break;
                            }
                            default:
                            {
                                break;
                            }
                        }

                        if(twoHundred)
                        {
                            /*setModalObject({...modalObject, show: true, status:'success', title:'Success', message:'Commission Table Deleted.'});
                            setUpdating(!updating);*/

                            setLoading(false);
                            setTypeAlert('Delete');
                            setAlertTitle('Success');
                            setAlertMessage('Commission Table Deleted.')
                            setShowAlert(true);
                            setUpdating(!updating);
                        }else
                        {
                            /*setModalObject({...modalObject, show: true, status:'error', title:'Error', message: errorMessageDefault});*/
                            setLoading(false);
                            setTypeAlert('Delete');
                            setAlertTitle('Error');
                            setAlertMessage(errorMessageDefault);
                            setShowAlert(true);
                        }
                    }else{
                        setLoading(false);
                    }
                }
            );
        }
    }

    //Old Alert
    const closeAlert = () => {
        setTypeAlert('');
        setAlertTitle('');
        setAlertMessage('');
        setErrorList([]);
        setShowAlert(false);
    }

    const closeSuccessUpdate = () =>{
        setShowAlert(false);
        setTypeAlert('');
        if(props.updateTraderInfo !== undefined)
        {
            props.updateTraderInfo();
        }
    }

    const closeNewRange = () => {
        setShowAlert(false);
        //setShowDayPicker(false);
        //setNewRangeDate('');
        setTypeAlert('');
    }

    const handleCloseModal = () => {
        ////debugger
        props.closeModalTradersCommission();
    }

    const handleCloseModalNewRange = () => {
        setModalObject({...modalObject, show: false});
        setNewRangeDateSelected(moment().format('YYYY/MM/DD'));
        setNewTraderSelected(null);
        setValidateStatusTrader('');
    }

    const onSelectDateOfCalendar = (value,b,c,d) => {
        ////debugger
        setNewRangeDateSelected(value.format('YYYY/MM/DD'));
    };

    const getSelectTradersOptions = () =>
    {
        let allOptionsOfTraders = [];

        traders.forEach(trader => {
            allOptionsOfTraders.push(
                <Option value = {trader['TraderID']}>{trader['Trader']}</Option>
            );
        });

        return(
            <Select
                showSearch
                style               = {{ width: '100%', borderColor: newTraderSelected === null ? 'red': ''}}
                placeholder         = "Select a Trader"
                optionFilterProp    = "children"
                filterOption        = {
                    (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSelect	        = {(value, option) =>{setNewTraderSelected(value); setValidateStatusTrader('');}}
                value               = {newTraderSelected}
            >
                {allOptionsOfTraders}
            </Select>
        );
    }

    const addNewRangeClickedConfirm = () =>
    {
        console.log('Add New Range yes Clicked...');
        console.log(newRangeDateSelected);
        console.log(newTraderSelected);
        setNewRangeDateSelected(moment().format('YYYY/MM/DD'));
        setModalObject({...modalObject, show: false});
        setNewTraderSelected(null);

        setLoading(true);
        addNewRangeCustomerCommissionTable(props.customerId, newRangeDateSelected, localStorage.getItem('UserID'), newTraderSelected).then(
            (response) =>{

                let auxMessage = 'Something went wrong. Please try again later.';
                let auxSuccess = false;

                switch (response.httpStatusCode) 
                {
                    case 503:
                    {
                        if(response.Message !== undefined && response.Message !== null && response.Message !== '')
                        {
                            auxMessage = response.Message
                        }
                        break;
                    }
                    case 200:
                    {
                        auxSuccess = true;
                        break;
                    }
                    default:
                    {
                        break;
                    }
                }

                if(auxSuccess)
                {
                    setLoading(false);
                    setTypeAlert('NewRangeSuccess')
                    setAlertTitle('Success');
                    setAlertMessage('New range created.');
                    setShowAlert(true);
                    setUpdating(!updating);
                }else
                {
                    setLoading(false);
                    setAlertTitle('Error');
                    setAlertMessage(auxMessage);
                    setShowAlert(true);
                }
            }
        );
    }

    return(
        <div>
            <Modal
                title    = {<h3 className="component-title" style={{marginBottom:0, paddingBottom: 0}}>Traders and Commissions</h3>}
                centered
                visible  = {props.show}
                footer   = {null}
                onCancel = {handleCloseModal}
                width    = {'60%'}
            >
                <Form form={form} component={false}>
                    <Table 
                        columns         = {mergedColumns} 
                        dataSource      = {
                            !showDeleted ? (customerCommissionsTable.filter((value, index, array) =>  value.Status !== 'Deleted')) : customerCommissionsTable} 
                        pagination      = {false}
                        components      = {{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowClassName    = 'editable-row'
                        size            = 'small'
                        title           = {() => 
                            <Form
                                layout      = "inline"
                            >
                                <Form.Item label="Show Deleted">
                                    <Switch 
                                        checked  = {showDeleted} 
                                        onChange = {handleShowDeletedSwitch}
                                    />
                                </Form.Item>
                            </Form>
                        }
                        footer          = {() => {
                            return(
                                <Button 
                                    type        = "dashed" 
                                    onClick     = {() => {
                                        console.log('add new range clicked...');
                                        setModalObject({...modalObject, show: true, title:'New Range'});

                                    }}
                                    block
                                    icon        = {<PlusOutlined />}
                                    style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    disabled    = {
                                        editingKey !== 0 ? true: false                                        
                                    }
                                >Add New Range
                                </Button>
                            );
                        }}
                    />
                </Form>
            </Modal>

            <Modal
                title    = {<h3 className="component-title" style={{marginBottom:0, paddingBottom: 0}}>Add New Range</h3>}
                centered
                visible  = {modalObject.show}
                footer   = {null}
                onCancel = {handleCloseModalNewRange}
                width    = {'20%'}
                zIndex   = {1021}
                bodyStyle= {{
                    paddingTop: 15,
                    paddingBottom: 15,
                    marginTop: 0
                }}
            >
                {/* <DatePicker 
                    open         = {true}
                    defaultValue = {moment('00:00:00', 'HH:mm:ss')} 
                    format       = {'YYYY/MM/DD'} 
                    disabledDate = {disabledDate}
                    onChange     = {(momentDate, dateString) => {
                        if(momentDate !== null)
                        {
                            console.log(moment(momentDate.toISOString()));
                        }
                    }}
                    style           = {{ 
                        width: '100%', 
                        textAlignLast: 'center'
                    }}
                /> */}

                <Form>
                    <Form.Item
                        label           = ""
                        validateStatus  = {validateStatusTrader}
                        style           = {{marginBottom: 0}}
                    >
                        {getSelectTradersOptions()}
                    </Form.Item>
                </Form>
                {/*<Alert
                    message={`You selected date: ${newRangeDateSelected && newRangeDateSelected.format('YYYY/MM/DD')}`}
                />*/}
                <Calendar 
                    fullscreen   = {false} 
                    onSelect     = {onSelectDateOfCalendar}
                    disabledDate = {disabledDate}
                    headerRender = {({ value, type, onChange, onTypeChange }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];
                
                        const current = value.clone();
                        const localeData = value.localeData();
                        const months = [];
                        for (let i = 0; i < 12; i++) {
                          current.month(i);
                          months.push(localeData.monthsShort(current));
                        }
                
                        for (let index = start; index < end; index++) {
                          monthOptions.push(
                            <Select.Option className="month-item" key={`${index}`}>
                              {months[index]}
                            </Select.Option>,
                          );
                        }
                        const month = value.month();
                
                        const year = value.year();
                        const options = [];

                        for (let i = moment(props.customer.CreatedOn,"YYYY-MM-DDTHH:mm:ss").year(); i < year + 10; i += 1) 
                        {
                            options.push(
                                <Select.Option key={i} value={i} className="year-item">{i}</Select.Option>,
                            );
                        }

                        return (
                            <div style={{ padding: 8, display: 'flex', justifyContent: 'center' }}>
                                <Row gutter={8}>
                                    <Col>
                                        <Radio.Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                                            <Radio.Button value="month">Month</Radio.Button>
                                            <Radio.Button value="year">Year</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <Col>
                                        <Select
                                            //showSearch
                                            size                     = "small"
                                            dropdownMatchSelectWidth = {false}
                                            className                = "my-year-select"
                                            onChange                 = {newYear => {
                                                const now = value.clone().year(newYear);
                                                onChange(now);
                                            }}
                                            value               = {String(year)}
                                            /*optionFilterProp    = "key"
                                            filterOption        = {
                                                (input, option) => 
                                                
                                                    //children: 2011
                                                    //className: "year-item"
                                                    //key: "2011"
                                                    //value: 2011
                                                
                                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }*/
                                        >
                                        {options}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <Select
                                            dropdownMatchSelectWidth = {false}
                                            size     ="small"
                                            value    ={String(month)}
                                            onChange ={selectedMonth => {
                                                const newValue = value.clone();
                                                newValue.month(parseInt(selectedMonth, 10));
                                                onChange(newValue);
                                            }}
                                        >
                                        {monthOptions}
                                        </Select>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }}
                    value        = {moment(newRangeDateSelected)}
                />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Popconfirm 
                        title       = {"Sure to add new Range from "+ moment(newRangeDateSelected).format('YYYY/MM/DD') +"?"} 
                        onConfirm   = {() =>{
                            ////debugger
                            if(newRangeDateSelected !== '' && newTraderSelected !== null)
                            {
                                addNewRangeClickedConfirm();
                            }else
                            {
                                setTypeAlert('Error')
                                setAlertTitle('Error');
                                setAlertMessage('Please fill all mandatory fields.');
                                setErrorList(['Select a Trader'])
                                setShowAlert(true);
                                setValidateStatusTrader('error');
                            }
                        }}
                        placement   = "right" 
                    >
                        <Button className='uk-button uk-button-green'>Save</Button>
                    </Popconfirm>
                </div>
            </Modal>

            <AlertBox 
                open            = {showAlert}
                onClose         = {closeAlert}
                title           = {alertTitle}
                message         = {alertMessage}
                type            = "Ok"
                messageBoldList = {errorList}
                okClick         = {typeAlert === 'SuccessUpdate' ? closeSuccessUpdate : (typeAlert === 'NewRangeSuccess' ? closeNewRange : closeAlert)}
            />
            <LoadingBox loading={loading}/>
        </div>
    );
}
import React from 'react';

import AlertBox from '../../shared/AlertBox'
import DatePicker from '../../shared/DatePicker'
import DynamicSearchSelect from '../../shared/DynamicSearchSelect'
import DynamicSelect from '../../shared/DynamicSelect'
import LoadingBox from '../../shared/LoadingBox'
import NumberInput from '../../shared/NumberInput'

import { getAllCurrencyPairs } from '../../../networking/NetworkingCurrencyPairs'
import { getDealsForRollOver, rollOverDeals } from '../../../networking/NetworkingDeals'
import { getNextBusinessDate } from '../../../networking/NetworkingHolidays'
import { getTradingAndCurrencyHolidays } from '../../../networking/NetworkingHolidays'
import { searchCustomerByIdNameMin } from '../../../networking/NetworkingCustomers'

import { formatDate, roundAmountByCurrencyPair, getTimehhmm, formatToCurrencyWithScale } from '../../../helpers/FormatHelper'
import { getStyle, regularInputClass, notFilledInputClass } from '../../../styles/styles'

const calculateDealAmount = (rate, sellAmountIn, buyAmountIn, calculateSellAmount, rateTerms, ratePrecision) => {
    if (rate === 0) {
        return 0
    }
    let amount = 0
    if (rateTerms === "D") {
        amount = calculateSellAmount ? buyAmountIn / rate : sellAmountIn * rate
    } else if (rateTerms === "I") {
        amount = calculateSellAmount ? buyAmountIn * rate : sellAmountIn / rate
    }
    return parseFloat(amount).toFixed(ratePrecision)
}

const getFwdRate = (spotRate, forwardPoints, numberOfDecimals) => {
    return parseFloat(parseFloat(spotRate) + parseFloat(forwardPoints)).toFixed(numberOfDecimals);
}

const RollOverDealTable = ({ deals = [], dealTypeId = 1, updateDeals, startDate, calculateEndDate, verifyHoliday}) => {
    
    let headers = []
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 1}></th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-expand" key={1000 * dealTypeId + 2}>Amount to update</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-expand" key={1000 * dealTypeId + 3}>New Value Date</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 4}>Deal No.</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-expand" key={1000 * dealTypeId + 5}>Customer Name</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 6}>Customer Rate</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-expand" key={1000 * dealTypeId + 7}>Addit. Points</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 8}>New Customer Rate</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 9}>Market Rate</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-expand" key={1000 * dealTypeId + 10}>Addit. Cost Points</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 11}>New Market Rate</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 12}>Deal Type</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 13}>Far/Value date</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 14}>We buy</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={1000 * dealTypeId + 15}>We sell</th>)


    let rows = deals.filter(x => x.DealTypeID === dealTypeId).map((x, i) => {
        return <tr key={i}  >
                <td>
                    <input type="checkbox"
                        checked={x.Checked}
                        onChange={() => {
                            x.Checked = !x.Checked
                            updateDeals(deals)
                        }} 
                    />
                </td>
                <td>
                    <DynamicSelect key={'ds' + x.DealNo} className="uk-select"
                        data={[{ value: 'We Buy', name: 'We Buy' }, { value: 'We Sell', name: 'We Sell' }]}
                        getValue={(obj) => { 
                            x.AmountToUpdate = obj.value
                            updateDeals(deals)
                        }} />
                </td>
                <td>
                    <DatePicker key={'dp' + x.DealNo}
                        className={x.InvalidValueDate ? notFilledInputClass : regularInputClass}
                        value={''}
                        onDayChange={(date) => {
                            x.NewValueDate = date
                            updateDeals(deals)
                        }} 
                        placeholder={'...'} 
                        disabledDays={[0,6]}
                        startDate={startDate}
                        endDate={calculateEndDate(startDate, x.BuyCurrency, x.SellCurrency, x.DealTypeID)}
                        isHoliday={verifyHoliday}
                        readOnly={true}
                        />
                </td>
                <td>{x.DealNo}</td>
                <td className="uk-preserve-width">{x.CustomerName}</td>
                <td>{roundAmountByCurrencyPair(x.CustomerRate, x.BuyCurrency, x.SellCurrency)}</td>
                <td>
                    <NumberInput key={'ni1' + x.DealNo} type="Currency" className="uk-input" scale={x.RatePrecision} value={x.AdditPoints !== undefined ? x.AdditPoints : 0}
                        negative={true}
                        onChange={(event) => { 

                            if (x.CustomerRate !== x.NewCustomerRate) {
                                let calculateSellAmount = x.AmountToUpdate === "We Sell" ? true : false
                                let newAmount = calculateDealAmount(x.NewCustomerRate, x.OriginalSellAmount, x.OriginalBuyAmount, calculateSellAmount, x.RateTerms, x.RatePrecision)
                                if (calculateSellAmount) {
                                    x.SellAmount = formatToCurrencyWithScale(newAmount, x.SellCurrency)
                                    x.WeSell = x.SellAmount + ' ' + x.SellCurrency
                                } else {
                                    x.BuyAmount = formatToCurrencyWithScale(newAmount, x.BuyCurrency)
                                    x.WeBuy = x.BuyAmount + ' ' + x.BuyCurrency
                                }
                            }
                            else {
                                x.SellAmount = formatToCurrencyWithScale(x.OriginalSellAmount, x.SellCurrency)
                                x.WeSell = x.SellAmount + ' ' + x.SellCurrency
                                x.BuyAmount = formatToCurrencyWithScale(x.OriginalBuyAmount, x.BuyCurrency)
                                x.WeBuy = x.BuyAmount + ' ' + x.BuyCurrency
                            }

                            x.AdditPoints = event.target.value
                            x.NewCustomerRate = getFwdRate(x.CustomerRate, x.AdditPoints, x.RatePrecision)
                            updateDeals(deals)                          
                        }} />
                </td>
                <td>{x.NewCustomerRate !== undefined ? roundAmountByCurrencyPair(x.NewCustomerRate, x.BuyCurrency, x.SellCurrency) : roundAmountByCurrencyPair(x.CustomerRate, x.BuyCurrency, x.SellCurrency)}</td>
                <td>{roundAmountByCurrencyPair(x.MarketRate, x.BuyCurrency, x.SellCurrency)}</td>
                <td>
                    <NumberInput key={'ni2' + x.DealNo} type="Currency" className="uk-input" scale={x.RatePrecision} value={x.AdditCostPoints !== undefined ? x.AdditCostPoints : 0}
                        negative={true}
                        onChange={(event) => {
                            x.AdditCostPoints = event.target.value 
                            x.NewMarketRate = getFwdRate(x.MarketRate, x.AdditCostPoints, x.RatePrecision)
                            updateDeals(deals)
                         }} />
                </td>
                <td>{x.NewMarketRate !== undefined ? roundAmountByCurrencyPair(x.NewMarketRate, x.BuyCurrency, x.SellCurrency) : roundAmountByCurrencyPair(x.MarketRate, x.BuyCurrency, x.SellCurrency)}</td>
                <td>{x.DealType}</td>
                <td>{x.OriginalValueDate}</td>
                <td>{x.WeBuy}</td>
                <td>{x.WeSell}</td>
            </tr>
    })


    let table =
        <div className="table-container">
            <div className="border-table"  style={{minHeight:'220px'}}>
                {/* <table key={'dealTable-' + dealTypeId} className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped" */}
                <table key={'dealTable-' + dealTypeId} className="uk-table uk-table-small uk-table-hover uk-table-divider border-table uk-table-striped">
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        </div>
    return table
}

export default class RollOverScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.initialState
      }

    initialState = {
        date: '',
        applicationDate: '',
        customer: {
            id: 0,
            name: ''
        },
        deals: [],
        customerSearchList: [],
        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        showConfirm: false,
        confirmationMessage: '',
        nextBusinessDate: '',
        currencyPairs: [],
        holidays: [],
        cutoffTime: '',
        //
        loading: false
    }

    componentDidMount() {
        let applicationDate = localStorage.getItem('SystemDate')
        let cutoffTime = localStorage.getItem('CutoffTime');
        if (cutoffTime === null) {
            cutoffTime = '12:00';
        }
        this.setState({ date: applicationDate, applicationDate: applicationDate, cutoffTime: cutoffTime }, () => {
            this.loadDeals()
            this.loadNextBusinessDate()
            this.loadCurrencyPairs()
            this.loadHolidays()
        })

    }

    //networking

    loadDeals() {
        getDealsForRollOver(0, this.state.customer.id, formatDate(this.state.date), 0).then((response) => {
            if (response !== undefined && response !== null && response.deals !== undefined && response.deals !== null) {
                this.setState({ deals: response.deals })
            }
            else {
                this.setState({ deals: [] })
            }
            console.log(response)
        })
    }

    searchCustomer = (query) => {
        searchCustomerByIdNameMin(query).then(
            (response) => {
                console.log(response)
                if (response !== undefined && response.customers !== undefined) {
                    let customers = response.customers
                    customers.forEach(x => {
                        x.value = x.CustomerID;
                        x.name = x.CustomerIDName;
                    })
                    this.setState({ customerSearchList: customers });
                }
            }
        )
    }

    rollOverSelectedDeals = () => {
        let selectedDeals = this.state.deals.filter(x => x.Checked === true)
        let applicationDate = localStorage.getItem('SystemDate');
        let userId = localStorage.getItem('UserID')
        this.setState({ loading: true }, () => {
            rollOverDeals(selectedDeals, userId, applicationDate).then((response) => {
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'All selected deals were saved successfully',
                            loading: false
                        }, this.loadDeals())
                        break
                    case 409:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                            loading: false
                        })
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Error saving deal(s). Please try again.',
                            loading: false
                        })
                        break
                }
            })
        })
    }

    loadNextBusinessDate = () => {
        getNextBusinessDate(this.state.date, 0).then(
            (response) => {
                console.log(response)
                if (response !== undefined && response.nextDate !== undefined) {
                    this.setState({ nextBusinessDate: response.nextDate })
                }
            }
        )
    }

    loadCurrencyPairs = () => {
        getAllCurrencyPairs().then(
            (response) => {
                if (response !== undefined && response.currencyPairs !== undefined) {
                    let currencyPairs = response.currencyPairs
                    this.setState({ currencyPairs: currencyPairs })
                }
            }
        )
    }

    loadHolidays = () => {
        let startDate = new Date();
        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 180);
        getTradingAndCurrencyHolidays(formatDate(startDate), formatDate(endDate)).then(
            (response) => {
                if (response !== undefined && response.holidays !== undefined) {
                    let holidays = response.holidays;
                    this.setState({ holidays: holidays });
                }
            }
        )
    }

    //helpers
    calculateEndDate = (startDate, buyCurrency, sellCurrency, dealType) => {
        if (dealType === 4) { 
            return 
        }
        //Get spot days
        let spotDays = 2;
        let currencyPairs = this.state.currencyPairs.filter(x => x.CurrencyCode === buyCurrency && x.OutCurrencyCode === sellCurrency)
        if (currencyPairs.length > 0) {
            spotDays = currencyPairs[0].SpotDays
        }
        let holidays = this.state.holidays.filter(x => x.CurrencyID === sellCurrency || x.CurrencyID === buyCurrency || x.CurrencyID === 'ANY')

        //Calculate end date
        let endDate = new Date(startDate)
        var daysToAdd = spotDays
        while (daysToAdd > 0) {
            endDate.setDate(endDate.getDate() + 1)
            if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidays.includes(formatDate(endDate))) {
                //Nothing at all
            }
            else {
                daysToAdd--;
            }
        }
        return endDate;
    }

    verifyHoliday = (day, buyCurrency, sellCurrency) => {
        let holidays = this.state.holidays.filter(x => x.CurrencyID === sellCurrency || x.CurrencyID === buyCurrency || x.CurrencyID === 'ANY')
        return holidays.includes(formatDate(day));
    }

    //events
    updateDeals = (deals) => {
        this.setState({ deals: deals })
    }

    handleCustomerSelected = (value) => {
        this.setState({ customer: { ...this.state.customer, id: value.value, name: value.name } },
            () => this.loadDeals())
    }

    handleOnDayChange = (date) => {
        this.setState({ date: formatDate(date) }, () => {
            this.loadDeals()
            this.loadNextBusinessDate()
        }
        )
    }

    handleCheckUncheckAll = (check) => {
        let dealsTmp = this.state.deals
        dealsTmp.forEach(x => {
            x.Checked = check
        })
        this.setState({ deals: dealsTmp })
    }

    closeAlert = () => {
        this.setState({ showAlert: false })
    }

    closeConfirm = () => {
        this.setState({ showConfirm: false })
    }

    yesToRollOverToNextBusinessDay = () => {
        this.setState({ showConfirm: false })
        this.state.deals.filter(x => x.Checked === true).forEach(x => { x.NewValueDate = this.state.nextBusinessDate })
        this.rollOverSelectedDeals()
    }

    onRollOverToNextBusinessDay = () => {
        if (this.validateDealSelectedForRollover()) {
            let confirmationMessage = "Are you sure you want to roll forward  the selected "
                + "deal(s) on the screen (spots, forwards not fully drawdown and drawdowns with value date higher than the forward maturity date) to: " + this.state.nextBusinessDate
            this.setState({ showConfirm: true, confirmationMessage: confirmationMessage}
            )
        } else {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Must select a deal to roll over.'
            })
        }
    }

    onSaveRolloverDeals = () => {
        if (this.validateDealSelectedForRollover()) {
            let selectedDeals = this.state.deals.filter(x => x.Checked === true)
            let dealsWithoutNewDate = selectedDeals.filter(x => x.NewValueDate === undefined)
            if (dealsWithoutNewDate.length > 0) {
                let dealsTmp = this.state.deals
                dealsTmp.forEach(x => {
                    if (x.Checked === true && x.NewValueDate === undefined) {
                        x.InvalidValueDate = true
                    }
                })
                this.setState({
                    showAlert: true,
                    alertTitle: 'Error',
                    alertMessage: 'Verify that all deals selected have a new value date',
                    deals: dealsTmp
                })
            }
            else {
                this.rollOverSelectedDeals()
            }
        }
        else {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Must select a deal to roll over.'
            })
        }
    }

    validateDealSelectedForRollover() {
        let selectedDeals = this.state.deals.filter(x => x.Checked === true)
        return selectedDeals.length > 0 ? true : false
    }

    onClear = () => {
        let applicationDate = localStorage.getItem('SystemDate')
        this.setState({
            ...this.initialState,
            date: applicationDate,
            currencyPairs: this.state.currencyPairs,
            holidays: this.state.holidays,
            cutoffTime: this.state.cutoffTime,
            applicationDate: applicationDate
        }, () => {
            this.loadDeals()
            this.loadNextBusinessDate()
        })
    }
    //render

    render() {

        let currentDate = new Date();
        let startDate = new Date(this.state.applicationDate)

        startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0)

        if (getTimehhmm(startDate) > this.state.cutoffTime) {
            startDate.setDate(startDate.getDate() + 1);
        }

        let spotCount = this.state.deals.filter(x => x.DealTypeID === 1).length
        let drawdownCount = this.state.deals.filter(x => x.DealTypeID === 2).length
        let forwardCount = this.state.deals.filter(x => x.DealTypeID === 4).length        
        
        let dealSection =
            <div >
                <button key="btSelect" className="uk-button uk-button-default uk-button-green" type="button"
                    onClick={() => this.handleCheckUncheckAll(true)}>Select All</button>
                
                <button key="btUnselect" className="uk-button uk-button-default uk-button-green" type="button"
                    onClick={() => this.handleCheckUncheckAll(false)}>Unselect All</button>
                
                <h5 className="uk-heading-divider"
                    uk-toggle="target : #spotSection;"
                    style={{ ...getStyle().subTitleStyle, cursor: 'pointer' }}>Spot Deals ({spotCount})
                    <i id="spotSection" className="fa fa-fw fa-sort-desc" style={{ verticalAlign: 'top' }} />
                    <i id="spotSection" className="fa fa-fw fa-sort-asc" style={{ verticalAlign: 'bottom' }} hidden />
                </h5>
                <div id="spotSection" hidden>
                    <RollOverDealTable deals={this.state.deals} dealTypeId={1} updateDeals={this.updateDeals} 
                        startDate={startDate} calculateEndDate={this.calculateEndDate} verifyHoliday={this.verifyHoliday}/>
                </div>

                <h5 className="uk-heading-divider"
                    uk-toggle="target : #forwardSection;"
                    style={{ ...getStyle().subTitleStyle, cursor: 'pointer' }}>Forward Deals ({forwardCount})
                    <i id="forwardSection" className="fa fa-fw fa-sort-desc" style={{ verticalAlign: 'top' }} />
                    <i id="forwardSection" className="fa fa-fw fa-sort-asc" style={{ verticalAlign: 'bottom' }} hidden />
                </h5>
                <div id="forwardSection" hidden>
                    <RollOverDealTable deals={this.state.deals} dealTypeId={4} updateDeals={this.updateDeals} 
                        startDate={startDate} calculateEndDate={this.calculateEndDate} verifyHoliday={this.verifyHoliday}/>
                </div>

                <h5 className="uk-heading-divider"
                    uk-toggle="target : #drawdownSection;"
                    style={{ ...getStyle().subTitleStyle, cursor: 'pointer'}}>Drawdown Deals ({drawdownCount})
                    <i id="drawdownSection" className="fa fa-fw fa-sort-desc" style={{ verticalAlign: 'top' }} />
                    <i id="drawdownSection" className="fa fa-fw fa-sort-asc" style={{ verticalAlign: 'bottom' }} hidden />
                </h5>
                <div id="drawdownSection" hidden>
                    <RollOverDealTable deals={this.state.deals} dealTypeId={2} updateDeals={this.updateDeals} 
                        startDate={startDate} calculateEndDate={this.calculateEndDate} verifyHoliday={this.verifyHoliday}/>
                </div>

            </div>


        return (
            <div className="uk-margin">
                <h3 className="uk-heading-divider" style={{ ...getStyle().titleStyle}}>Roll Over</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2">
                        <div className="uk-grid uk-margin">                           
                            <div className="uk-width-1-3" style={{ marginTop: '15px'}}>
                                Customer
                            </div>
                            <div className="uk-width-2-3" style={{ marginTop: '15px'}}>
                                <DynamicSearchSelect id="CustomerIDName"
                                    placeholder="Customer"
                                    searchFunction={this.searchCustomer}
                                    getValue={this.handleCustomerSelected}
                                    data={this.state.customerSearchList}
                                    objValue={{ value: this.state.customer.id, name: this.state.customer.name }} />
                            </div>
                            <div className="uk-width-1-3" style={{ marginTop: '15px'}}>
                                Date
                            </div>
                            <div className="uk-width-1-3" style={{ marginTop: '15px'}}>
                                <DatePicker className="uk-input" value={this.state.date} onDayChange={this.handleOnDayChange}/>
                            </div>
                            <div className="uk-width-1-3" style={{ marginTop: '15px'}}>                                
                            </div>
                        </div>
                        <div className="uk-width-2-2">
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-2">
                            </div>
                            <div className="uk-width-1-2">
                            </div>
                        </div>

                    </div>
                </div>
                <hr/>
                {dealSection}
                <hr/>
                <button key="btSave" className="uk-button uk-button-default uk-button-green" type="button" onClick={this.onSaveRolloverDeals}>Save</button>
                <button key="btRolloverToNextBD" className="uk-button uk-button-default uk-button-green" type="button" onClick={this.onRollOverToNextBusinessDay}>Roll Over to Next Business Date</button>
                <button key="btClear" className="uk-button uk-button-default uk-button-green" type="button" onClick={this.onClear}>Clear</button>
                <LoadingBox loading={this.state.loading}/>
                <AlertBox id="rolloverAlert" type="Ok" open={this.state.showAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                    okClick={this.closeAlert} onClose={this.closeAlert} />
                <AlertBox type="Yes/No" id="rolloverConfirm" open={this.state.showConfirm} onClose={this.closeConfirm} noClick={this.closeConfirm}
                    title="Confirm" message={this.state.confirmationMessage} yesClick={this.yesToRollOverToNextBusinessDay}/>

            </div>
        )
    }
}
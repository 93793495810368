import React from 'react';

import { getLookUpTable } from '../../../networking/Networking';
import {addFee} from '../../../networking/NetworkingFees';
import {notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass} from '../../../styles/styles';
import NumberInput from '../../shared/NumberInput';
import DynamicSelect from '../../shared/DynamicSelect';
import AlertBox from '../../shared/AlertBox';
import {getStyle} from '../../../styles/styles';
import { getScaleByCurrency } from '../../../helpers/FormatHelper';
import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'

var style = getStyle();

export default class FeeMaintenanceForm extends React.Component {

    state = {
        searchedfees: [],
        feeId: 0,
        feeDescription: '',
        costPerUnit: 0,
        unit: '',
        chargeTo: '',
        defaultAmount: '',
        lossIndicator: false,
        comissionIndicator: false,
        allowNegative: false,
        displayIn: '',
        readOnly: false,
        updateToken: 0,  
        alertMessage: '',
        showAlert: false,
        alertTitle: '',
        notFirstLoad: false,
        "ChargeTo": [],
        "Display In": [],
        lookUpTables:[
            "ChargeTo",
            "Display In",
        ],
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency)
    }
    
    componentDidMount() {
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        console.group(this.state.lookUpTables[i]);
                        console.table(table);
                        console.groupEnd();
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        })
        /*getLookUpTable("ChargeTo,Display In").then(
            (value) => {
                this.selectLookUpTable(value.lookUpTables, "ChargeTo");
                this.selectLookUpTable(value.lookUpTables, "Display In");
            }
        )*/
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedFee !== {} && prevProps.selectedFee !== this.props.selectedFee){
            var fee = this.props.selectedFee;
            console.log(fee);       
            this.setState({
                feeId: fee.FeeStructureId,
                feeDescription: fee.Description,
                costPerUnit: fee.CostPerUnit,
                unit: fee.Unit,
                chargeTo: fee.ChargeTo,
                defaultAmount: fee.DefaultAmount,
                lossIndicator: fee.LossIndicator,
                comissionIndicator: fee.CommissionIndicator,
                allowNegative: fee.AllowNegative,
                displayIn: fee.DisplayIn,
                readOnly: fee.ReadOnly,
                updateToken: fee.UpdateToken
            });
        };
    }

    handleClearClick(){
        this.setState({
            feeId: 0,
            feeDescription: '',
            costPerUnit: 0,
            unit: '',
            chargeTo: '',
            defaultAmount: '',
            lossIndicator: false,
            comissionIndicator: false,
            allowNegative: false,
            displayIn: '',
            readOnly: false,
            updateToken: 0, 
        });
    }
    
    
    handleSaveFeeClick() {
        //event.preventDefault();
        if(this.validateRequiredInputs()){
            let userID = localStorage.getItem('UserID');
            const model = {
                FeeStructureId: this.state.feeId,
                Description: this.state.feeDescription,
                CostPerUnit: this.state.costPerUnit,
                Unit: this.state.unit,
                ChargeTo: this.state.chargeTo,
                DefaultAmount: this.state.defaultAmount,
                LossIndicator: this.state.lossIndicator,
                CommissionIndicator: this.state.comissionIndicator,
                AllowNegative: this.state.allowNegative,
                DisplayIn: this.state.displayIn,
                ReadOnly: this.state.readOnly,
                UserId: userID,
                UpdateToken: this.state.updateToken,
                AgentCustomerID: 0
            }
            addFee(model).then((json) => {
                if (json.httpStatusCode !== 200) {
                    
                    var alertMsg = 'An error occurred while trying to save the fee. Please try again.'
                    if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                        alertMsg = json.httpErrorMessage;
                    }
                    this.setState({ showAlert: true, alertMessage: alertMsg, alertTitle: 'Error' });
                } else {
                    this.setState({ updateToken: json.updateToken, feeId: json.feeStructureId, showAlert: true, alertTitle: 'Fee successfully saved.' });
                    this.props.updateFunction();
                }
            });
        } else {
            this.setState({notFirstLoad: true});
        }
    }

    validateRequiredInputs(){
        if(this.state.feeDescription === ''){
            return false;
        }
        if(this.state.chargeTo === undefined || this.state.chargeTo === ''){
            return false;
        }
        if(this.state.displayIn === undefined || this.state.displayIn === ''){
            return false;
        }
        if(this.state.lossIndicator === undefined || this.state.lossIndicator === ''){
            return false;
        }
        if(this.state.comissionIndicator === undefined || this.state.comissionIndicator === ''){
            return false;
        }
        if(this.state.readOnly === undefined || this.state.readOnly === ''){
            return false;
        }
        if(this.state.allowNegative === undefined || this.state.allowNegative === ''){
            return false;
        }
        return true;
    }

    getChargeToSelect(value){
        this.setState({chargeTo: value.LookUpTableDetail});
    }

    getDisplayInSelect(value){
        this.setState({displayIn: value.LookUpTableDetail});
    }

    handleOnCheckLossIndicator(event){
        this.setState({lossIndicator: event.target.checked});
    }

    handleOnCheckComissionIndicator(event){
        this.setState({comissionIndicator: event.target.checked});
    }

    handleOnCheckReadOnly(event){
        this.setState({readOnly: event.target.checked});
    }

    handleOnCheckAllowNegative(event){
        this.setState({allowNegative: event.target.checked});
    }

    handleUpdateFeeDescription(event){
        this.setState({feeDescription: event.target.value});
    }

    handleUpdateDefaultAmount(event){
        this.setState({defaultAmount: event.target.value});
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    render() {
        var classNameInputDescription = regularInputClass;
        var classNameInputChargeTo = regularSelectClass; 
        var classNameInputDisplayIn = regularSelectClass;
        var classNameInputLoss = regularInputClass;
        var classNameInputComission = regularInputClass;
        var classNameInputReadOnly = regularInputClass;
        var classNameInputAllowNegative = regularInputClass;
        if(this.state.feeDescription === '' && this.state.notFirstLoad){
            classNameInputDescription=notFilledInputClass;
        }
        if(this.state.chargeTo === undefined && this.state.notFirstLoad){
            classNameInputChargeTo = notFilledSelectClass;
        }
        if(this.state.displayIn === undefined && this.state.notFirstLoad){
            classNameInputDisplayIn = notFilledInputClass;
        }
        if(this.state.lossIndicator === '' && this.state.notFirstLoad){
            classNameInputLoss = notFilledInputClass;
        }
        if(this.state.comissionIndicator === '' && this.state.notFirstLoad){
            classNameInputComission = notFilledInputClass;
        }
        if(this.state.readOnly === '' && this.state.notFirstLoad){
            classNameInputReadOnly = notFilledInputClass;
        }
        if(this.state.allowNegative === '' && this.state.notFirstLoad){
            classNameInputAllowNegative = notFilledInputClass;
        }
        
        return (
            <div>
                <form className="uk-grid"> 
                    <div className="uk-width-1-2 uk-form-stacked">  
                        <label className="uk-form-label" htmlFor="form-fee-info" style={style.boldLabel}>Description (*)</label>
                        <div className="" style={style.inputStyle}>                                
                            <input className={classNameInputDescription} id="feeDescription" type="text" placeholder="" value={this.state.feeDescription} onChange={this.handleUpdateFeeDescription.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-fee-chargeTo" style={style.boldLabel}>Charge To (*)</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameInputChargeTo}  id="chargeTo-list" getValue={this.getChargeToSelect.bind(this)} listHasPlaceholder={true} data={this.state["ChargeTo"]} objValue={{value: this.state.chargeTo, LookUpTableDetail: this.state.chargeTo}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-fee-defaultAmount">Default Amount</label>
                        <div className="" style={style.inputStyle}>
                            <NumberInput className="uk-input" placeholder="0" id="defaultAmount" type="Currency"
                            scale={getScaleByCurrency(this.state.baseCurrency)}
                            value={this.state.defaultAmount} onChange={this.handleUpdateDefaultAmount.bind(this)}/>
                            {/*<input className="uk-input" id="defaultAmount" type="numeric" placeholder="0.00" value={this.state.defaultAmount} onChange={this.handleUpdateDefaultAmount.bind(this)}/>*/}                               
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}> 
                        <label className="uk-form-label" htmlFor="form-fee-lossIndicator" style={style.boldLabel}>
                            <input className="uk-checkbox" type="checkbox" checked={this.state.lossIndicator} value={this.state.lossIndicator} onChange={this.handleOnCheckLossIndicator.bind(this)}/> Loss Indicator (*)
                        </label>                        
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked ">
                        <label className="uk-form-label" htmlFor="form-fee-displayIn" style={style.boldLabel}>Display In (*)</label>
                        <div className="uk-form-controls ">
                            <DynamicSelect className={classNameInputDisplayIn} id="displayIn-list" getValue={this.getDisplayInSelect.bind(this)} listHasPlaceholder={true} data={this.state["Display In"]} objValue={{value: this.state.displayIn, LookUpTableDetail: this.state.displayIn}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}> 
                        <label className="uk-form-label" htmlFor="form-fee-comissionIndicator" style={style.boldLabel}>
                            <input className="uk-checkbox" type="checkbox" checked={this.state.comissionIndicator} value={this.state.comissionIndicator} onChange={this.handleOnCheckComissionIndicator.bind(this)}/> Comission Indicator (*)
                        </label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}> 
                        <label className="uk-form-label" htmlFor="form-fee-readOnly" style={style.boldLabel}>
                            <input className="uk-checkbox" type="checkbox" checked={this.state.readOnly} value={this.state.readOnly} onChange={this.handleOnCheckReadOnly.bind(this)}/> Read Only (*)
                        </label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}> 
                        <label className="uk-form-label" htmlFor="form-fee-allowNegative" style={style.boldLabel}>
                            <input className="uk-checkbox" type="checkbox" checked={this.state.allowNegative} value={this.state.allowNegative} onChange={this.handleOnCheckAllowNegative.bind(this)}/> Allow Negative (*)
                        </label>
                    </div>
                    <div className="uk-margin-top uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)} type="button">Clear Fields</button>
                        </div>
                    </div>
                    <div className="uk-margin-top uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green" onClick={this.handleSaveFeeClick.bind(this)} type="button">Save</button>
                        </div>
                    </div>                
                </form>
                <AlertBox id="save-bank" open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </div>
        );
    }

}
import React, { useState } from 'react';
import { createCustomer } from '../../../../networking/NetworkingCustomers';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import CustomerInformationForm from './CustomerInformationForm';
import TradingInformationForm from './TradingInformationForm';
const style = getStyle();

const AddCustomerScreen = (props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertDetails, setAlertDetails] = useState({alertTitle: "", alertMessage: ""});
    const [customer, setCustomer] = useState({});
    const [validatedCustomerInfo, setValidatedCustomerInfo] = useState(false);
    const [validatedTradingInfo, setValidatedTradingInfo] = useState(false);
    const [validationsCustomerInfoFields, setValidationsCustomerInfoFields] = useState(true);
    const [validationsTradingInfoFields, setValidationsTradingInfoFields] = useState(true);
    const [customerInfoErrors, setCustomerInfoErrors] = useState([]);
    const [tradingInfoErrors, setTradingInfoErrors] = useState([]);
    const [newCustomerID, setNewCustomerID] = useState('');
    const [newCustomerName, setNewCustomerName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlertSaveSuspicious, setShowAlertSaveSuspicious] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const handleBackLink = () => {
        props.backLink();
    }

    const handleAddCustomer = async () => {
        setDisableSaveButton(true);
        const textToSearch = customer.CustomerType === "Individual" ?
            `${customer.FirstName} ${customer.LastName}` : customer.Name;
            const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Customer', 0);
        if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
            await insertCustomer(false)
        } else {
            setShowAlertSaveSuspicious(true);
            setAlertDetails({ alertTitle: 'Warning', alertMessage: 'Found Customer in Watch Lists. The Customer will be saved with status "Suspicious"' });
        }
    }

    const insertCustomer = async (isSuspicious) => {
        if (validatedCustomerInfo && validatedTradingInfo) {
            const model = {
                CommissionBase: customer.CommissionBase,
                CountryID: "CAN",
                CreatedBy: localStorage.getItem('UserID'),
                CustomerGroupID: customer.CustomerGroupID,
                CustomerType: customer.CustomerType,
                DefaultBranchID: customer.DefaultBranchID,
                DefaultPricingID: customer.DefaultPricingID,
                FirstName: customer.FirstName,
                LastName: customer.LastName,
                Name: customer.CustomerType === "Individual" ? `${customer.LastName}, ${customer.FirstName}` : customer.Name,
                ParentCustomerID: customer.ParentCustomerID,
                PreferredLanguage: customer.PreferredLanguage,
                Region: customer.Region,
                Salutation: customer.Salutation,
                ServiceLevel: customer.ServiceLevel,
                ShortName: customer.ShortName,
                SpreadPoints: customer.SpreadPoints,
                Status: isSuspicious ? 'Suspicious' : 'Pending',
                AverageTradeSize: customer.AverageTradeSize,
                ConfirmationLimit: customer.ConfirmationLimit,
                ConfirmationType: customer.ConfirmationType,
                MonthlyTradingVolume: customer.MonthlyTradingVolume,
                TraderID: customer.TraderID
            }
            setLoading(true);
            const json = await createCustomer(model);
            console.log(json)
            if (json.httpStatusCode !== 200) {
                setDisableSaveButton(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "An error occurred while trying to save the customer. Please try again.", alertTitle: "Error"});
                setLoading(false);
            } else {
                setShowAlert(true);
                setAlertDetails({alertMessage: "Customer successfully saved.", alertTitle: "Success"});
                setNewCustomerID(json.customerID);
                setNewCustomerName(json.name);
                setLoading(false);
            }
        } else {
            setDisableSaveButton(false);
            if (validatedTradingInfo && !validatedCustomerInfo) {
                setValidationsCustomerInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error", });
            } else if (validatedCustomerInfo && !validatedTradingInfo) {
                setValidationsTradingInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error", });
            } else {
                setValidationsCustomerInfoFields(false);
                setValidationsTradingInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error" });
            }
        }
    }

    const getCustomerInfo = (customerInfo) => {
        setCustomer({...customer, ...customerInfo})
    }

    const getTradingInfo = (tradingInfo) => {
        setCustomer({
            ...customer,
            TraderID: tradingInfo.trader,
            AverageTradeSize: tradingInfo.tradeAverageSize,
            ConfirmationType: tradingInfo.confirmationType,
            MonthlyTradingVolume: tradingInfo.monthlyAverageVolume,
            ConfirmationLimit: tradingInfo.confirmationLimit
        })
    }

    const isValidatedCustomerInfo = (validator) => {
        if (validator.valid) {
            setValidatedCustomerInfo(true);
            setValidationsCustomerInfoFields(true);
            setCustomerInfoErrors([])
        } else {
            setValidatedCustomerInfo(false);
            setCustomerInfoErrors({tab: "Customer Information", errors: validator.errors});
        }
    }

    const isValidatedTradingInfo = (validator) => {
        if (validator.valid) {
            setValidatedTradingInfo(true);
            setValidationsTradingInfoFields(true);
            setTradingInfoErrors([]);
        } else{
            setValidatedTradingInfo(false);
            setTradingInfoErrors({tab: "Trading Information", errors: validator.errors});
        }
    }

    const closeAlertOK = () => {
        setShowAlert(false);
        props.customerCreatedBackLink(newCustomerID, newCustomerName, customer.CustomerType);
    }

    const closeAlertError = () => {
        setValidationsCustomerInfoFields(true);
        setValidationsTradingInfoFields(true);
        setShowAlertError(false);
    }

    const closeAlertSaveSuspicious = async () => {
        setShowAlertSaveSuspicious(false);
        await insertCustomer(true);
    }

    return (
        <div>
            {/*<h3 className="uk-heading-divider" style={style.titleStyle} >
                <button className="uk-button uk-button-text uk-margin-right  min-width-0" onClick={handleBackLink}>
                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                </button> 
                Customer
            </h3>*/}
            <ul uk-tab="">
                <li><a href="#">Customer Information</a></li>
                <li><a href="#">Trading Information</a></li>
                {/* <li><a href="#">Comments</a></li> */}
            </ul>
            <ul className="uk-switcher uk-margin">
                <li>
                    <CustomerInformationForm getCustomerInfo={getCustomerInfo} isValid={isValidatedCustomerInfo} validations={validationsCustomerInfoFields}/>
                </li>
                <li>
                    <TradingInformationForm getTradingInfo={getTradingInfo} isValid={isValidatedTradingInfo} validations={validationsTradingInfoFields}/>
                </li>
                {/* <li><CommentsForm/></li> */}
            </ul>
            <button className="uk-button uk-button-green" disabled={disableSaveButton} onClick={handleAddCustomer}>Save</button>
            <AlertBox open={showAlert} onClose={closeAlertOK} title={alertDetails.alertTitle} message={alertDetails.alertMessage} type="Ok" okClick={closeAlertOK}/>
            <AlertBox open={showAlertSaveSuspicious} onClose={closeAlertSaveSuspicious} title={alertDetails.alertTitle} message={alertDetails.alertMessage} type="Ok" okClick={closeAlertSaveSuspicious}/>
            <AlertBox open={showAlertError} onClose={closeAlertError} title={alertDetails.alertTitle} message={alertDetails.alertMessage} messageBoldList={[customerInfoErrors, tradingInfoErrors]} type="Ok" okClick={closeAlertError}/>
            <LoadingBox loading={loading} />
        </div>
    )
}

export default AddCustomerScreen;

import React from 'react';
import { useState, useEffect } from 'react';
import CsvDownload from 'react-json-to-csv';
import { formatDate, formatToCurrencyWithScale } from '../../helpers/FormatHelper';
import DatePicker from '../../components/shared/DatePicker';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper';
import { getCustomerOverview } from '../../networking/NetworkingCustomers';
import { getDepositsByCustomer } from '../../networking/NetworkingDeposits';
import {getFundsOnHoldBalancesAdditionalHistory} from '../../networking/NetworkingFunds'
import DepositInfoScreen from '../back_office/deposits/DepositInfoScreen';
import HistoryBalanceScreen from '../back_office/historyBalance/HistoryBalanceScreen';
import DealInformationScreen from '../deal/DealInformationScreen';
import LoadingBox from './LoadingBox';
import NewDynamicTable from './NewDynamicTable';
import { regularInputClass } from '../../../src/styles/styles';
import { Dialog, DialogContent } from '@material-ui/core';

const DirectDebit = ({ data, loadData = f => f }) => {

    const [showInterfaceInfo, setShowInterfaceInfo] = useState(false)
    const [interfaceLog, setInterfaceLog] = useState('')
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    
    const InterfaceInfoDialog = (showInterfaceInfo = false) => {

        return <Dialog open={showInterfaceInfo} onClose={closeInterfaceDialog} maxWidth={'lg'} fullWidth={true}>
            <DialogContent dividers>
                <div className="">
                    <h3 className="uk-heading-divider">Interface Info</h3>
                    <div>
                        {interfaceLog}
                    </div>
                </div>
                <div className="uk-margin uk-width-1-1">
                    <button className="uk-button uk-button-green" onClick={closeInterfaceDialog}>Close</button>
                </div>
            </DialogContent>
        </Dialog>
    }

    const openInterfaceDialog = (obj) => {        
        setInterfaceLog(obj['External Info'])
        setShowInterfaceInfo(true)
    }

    const closeInterfaceDialog = () => {
        setShowInterfaceInfo(false)
    }

    const reLoadData = () => {
        loadData(formatDate(fromDate), formatDate(toDate))
    }

    const resetData = () => {
        setFromDate(formatDate(new Date().setMonth(new Date().getMonth() - 6)))
        setToDate(formatDate(new Date()))
        loadData(formatDate(fromDate), formatDate(toDate))
    }

    let content = <>
        <div className="uk-grid" style={{ marginTop: "0px" }}>
            <div className="uk-width-1-2 uk-form-stacked">
                <label className="uk-form-label">From Date</label>
                <DatePicker
                    className={regularInputClass}
                    readOnly={true}
                    value={fromDate}
                    onDayChange={(date) => {
                        setFromDate(date)
                    }}
                />
            </div>
            <div className="uk-width-1-2 uk-form-stacked">
                <label className="uk-form-label">To Date</label>
                <DatePicker
                    className={regularInputClass}
                    readOnly={true}
                    value={toDate}
                    onDayChange={(date) => {
                        setToDate(date)
                    }}
                />
            </div>
        </div>
        <div className="uk-margin-top">
            <CsvDownload
                data={data}
                filename="HistoryBalance.csv"
                className="uk-button uk-button-green uk-float-right">Export</CsvDownload>
            <button className="uk-button uk-button-green" onClick={resetData}>Reset</button>
            <button className="uk-button uk-button-green" onClick={reLoadData}>Apply Filter</button>
        </div>
        <NewDynamicTable id="direct-debit"
            data={data}
            hiddenHeaders={['External Info', 'External Source', 'PayeeAccountNumber', 'PayeeRouting', 'PayorAccountNumber', 'PayorRouting']}            
            useDeleteButton={false}
            dynamicActions={[{ name: 'Interface Log', function: openInterfaceDialog, isIcon: false }]}
            usePaginator={true}            
            enableSortByHeader={true}
        />

    </>

    if (showInterfaceInfo) {
        return <InterfaceInfoDialog showInterfaceInfo={showInterfaceInfo} closeDialog={closeInterfaceDialog} />
    }
    else {
        return content
    }
}

export default class CustomerOverview extends React.Component {
    state = {
        heldFundsBalances: [],
        undisbursedDeals: [],
        undisbursedForwards: [],
        unpaidDeals: [],
        unpaidDeposits: [],
        fundOnHoldBalancesExtHistory: [],
        loading: true,
        newSearch: false,
        filter: false,
        pagesUnpaidDeals: 1,
        totalPagesUnpaidDeals: 0,
        pagesUndisbursedDeals: 1,
        totalPagesUndisbursedDeals: 0,
        pagesUndisbursedForwards: 1,
        totalPagesUndisbursedForwards: 0,
        pagesHeldFundBalances: 1,
        totalPagesHeldFundBalances: 0,
        pagesUnpaidDeposits: 1,
        totalPagesUnpaidDeposits: 0,
        showUnpaidDealInfo: false,
        showUndisbursedDealInfo: false,
        showUndisbursedForwardInfo: false,        
        showHistoryBalance: false,
        objID: 0,
        activeTabUnpaidDeals: 1,
        activeTabUndisbursedDeals: 1,
        activeTabUndisbursedForwards: 1,
        activeTabUnpaidDeposits: 1,
        activeTabHeldFundBalance: 1,
        activeTabDirectDebit: 1,
        currency: '',
        currentBalance: ''
    }

    componentDidMount() {
        getCustomerOverview(this.props.customerID).then(
            (response) => {
                let unpaidDeals = [], undisbursedDeals = [],
                    undisbursedForwards = [], heldFundsBalances = [];
                let totalPagesUnpaidDeals = this.state.totalPagesUnpaidDeals;
                let totalPagesUndisbursedDeals = this.state.totalPagesUndisbursedDeals;
                let totalPagesUndisbursedForwards = this.state.totalPagesUndisbursedForwards;
                let totalPagesHeldFundBalances = this.state.totalPagesHeldFundBalances;

                if (response.overview.UnpaidDeals !== null) {
                    unpaidDeals = response.overview.UnpaidDeals;
                    const totalCount = unpaidDeals.length;
                    totalPagesUnpaidDeals = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.UndisbursedDeals !== null) {
                    undisbursedDeals = response.overview.UndisbursedDeals;
                    const totalCount = undisbursedDeals.length;
                    totalPagesUndisbursedDeals = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.UndisbursedForwards) {
                    undisbursedForwards = response.overview.UndisbursedForwards;
                    const totalCount = undisbursedForwards.length;
                    totalPagesUndisbursedForwards = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.HeldFundsBalances) {
                    heldFundsBalances = response.overview.HeldFundsBalances;
                    const totalCount = heldFundsBalances.length;
                    totalPagesHeldFundBalances = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                this.setState({
                    loading: false,
                    newSearch: true,
                    filter: true,
                    heldFundsBalances,
                    undisbursedDeals,
                    undisbursedForwards,
                    unpaidDeals,
                    totalPagesUnpaidDeals,
                    totalPagesUndisbursedDeals,
                    totalPagesUndisbursedForwards,
                    totalPagesHeldFundBalances,
                });
            }
        );
        getDepositsByCustomer(this.props.customerID, true, 0, 0).then(
            (json) => {
                if (json != null && json.result != null) {
                    const unpaidDeposits = json.result.map(d => ({ DepositID: d.DepositID, Amount: d.Amount, Currency: d.CurrencyID }))
                    const totalCount = json.totalCount;
                    const totalPagesUnpaidDeposits = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({ unpaidDeposits, totalPagesUnpaidDeposits })
                }
            }
        )
        this.loadFundsOnHoldBalancesAdditionalHistory()
    }

    loadFundsOnHoldBalancesAdditionalHistory = (fromDate, toDate) => {
        if (fromDate === undefined) {
            fromDate = formatDate(new Date().setMonth(new Date().getMonth() - 6));
            toDate = formatDate(new Date());
        }

        getFundsOnHoldBalancesAdditionalHistory(this.props.customerID, this.state.currency, fromDate, toDate).then((response) => {
            if (response != null && response.FundOnHoldBalancesExtHistory !== undefined && response.FundOnHoldBalancesExtHistory != null) {
                this.setState({
                    fundOnHoldBalancesExtHistory: response.FundOnHoldBalancesExtHistory
                });
            } else {
                this.setState({
                    fundOnHoldBalancesExtHistory: []
                });
            }
        });
    }

    onBackClick = () => {
        if (this.props.onBackClick !== undefined) {
            this.props.onBackClick();
        }
    }

    returnToUnpaidDeal = () => {
        this.setState({
            objID: 0,
            showUnpaidDealInfo: false,
            activeTabUnpaidDeals: 1
        });
    }

    handleUnpaidDealClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUnpaidDealInfo: true,
            activeTabUnpaidDeals: 2
        });
    }

    handleUndisbursedDealClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUndisbursedDealInfo: true,
            activeTabUndisbursedDeals: 2
        })
    }


    handleHeldFundBalanceClick = (obj) => {
        this.setState({
            currency: obj['Currency'],
            currentBalance: obj['Amount'],
            showHistoryBalance: true,
            activeTabHeldFundBalance: 2
        }, () => {
            this.loadFundsOnHoldBalancesAdditionalHistory()
        });
    }

    returnToUndisbursedDeal = () => {
        this.setState({
            objID: 0,
            showUndisbursedDealInfo: false,
            activeTabUndisbursedDeals: 1
        });
    }

    handleUndisbursedForwardClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUndisbursedForwardInfo: true,
            activeTabUndisbursedForwards: 2
        })
    }

    returnToUndisbursedForward = () => {
        this.setState({
            objID: 0,
            showUndisbursedForwardInfo: false,
            activeTabUndisbursedForwards: 1
        });
    }

    handleUnpaidDepositClick = (obj) => {
        this.setState({
            objID: obj['DepositID'],
            showUnpaidDepositInfo: true,
            activeTabUnpaidDeposits: 2
        })
    }

    returnToUnpaidDeposit = () => {
        this.setState({
            objID: 0,
            showUnpaidDepositInfo: false,
            activeTabUnpaidDeposits: 1
        });
    }
    
    returnToHeldFundBalance = () => {
        this.setState({
            currency: '',
            showHistoryBalance: false,
            activeTabHeldFundBalance: 1
        });
    }

    showHoldOnFundDirectDebit = (tabId) => {
        this.setState({
            activeTabHeldFundBalance: tabId
        });
    }

    changePageUnpaidDeals = (value) => {
        this.setState({ pagesUnpaidDeals: value });
    }

    changePageUndisbursedDeals = (value) => {
        this.setState({ pagesUndisbursedDeals: value });
    }

    changePageUndisbursedForwards = (value) => {
        this.setState({ pagesUndisbursedForwards: value });
    }

    changePageHeldFundsBalances = (value) => {
        this.setState({ pagesHeldFundsBalances: value });
    }

    changePageUnpaidDeposits = (value) => {
        this.setState({ pagesUnpaidDeposits: value });
    }

    getCustomerData = async (value) => {
        const response = await getCustomerOverview(this.props.customerID);
        switch (value) {
            case 'Unpaid Deals': {
                if (response.overview.UnpaidDeals !== null) {
                    return response.overview.UnpaidDeals;
                }
                break;
            }
            case 'Undisbursed Deals': {
                if (response.overview.UndisbursedDeals !== null) {
                    return response.overview.UndisbursedDeals;
                }
                break;
            }
            case 'Undisbursed Forwards': {
                if (response.overview.UndisbursedForwards) {
                    return response.overview.UndisbursedForwards;
                }
                break;
            }
            case 'Held Funds Balances': {
                if (response.overview.HeldFundsBalances) {
                    return response.overview.HeldFundsBalances;
                }
                break;
            }
            case 'Unpaid Deposits': {
                if (response.overview.UnpaidDeposits) {
                    return response.overview.UnpaidDeposits;
                }
                break;
            }
            default: return [];
        }
    }

    handleActiveTab = (activeTab, tab) => activeTab === tab ? 'uk-active' : '';

    render() {

        let debitTd = []     

        let fundsBalance = []
        if (this.state.showHistoryBalance) {
            //fundsBalance.push(<div>)
            if (this.state.activeTabHeldFundBalance === 3) {
                fundsBalance.push(<DirectDebit
                    data={this.state.fundOnHoldBalancesExtHistory || []}
                    loadData={this.loadFundsOnHoldBalancesAdditionalHistory}></DirectDebit>)
            }
            else {
                fundsBalance.push(<HistoryBalanceScreen currency={this.state.currency} customerID={this.props.customerID} currentBalance={this.state.currentBalance} />)
            }
        } else {
            fundsBalance.push(<NewDynamicTable id="held-funds-balances"
                data={this.state.heldFundsBalances}
                newSearch={this.state.newSearch}
                useDeleteButton={false}
                dynamicActions={[{ name: 'History', function: this.handleHeldFundBalanceClick, isIcon: true }]}
                usePaginator={true}
                changePage={this.changePageHeldFundsBalances}
                numberOfPages={this.state.totalPagesHeldFundBalances}
                pageNumber={this.state.pagesHeldFundBalances}
                numberPerPage={getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}
                useFilter={this.state.filter}
                filterFunction={() => this.getCustomerData('Held Funds Balances')}
            />)
        }

        return (
            <div>
                <ul id="accordion-customer-overview" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Unpaid Deals {this.state.loading ? '' : `(${this.state.unpaidDeals.length} Items)`}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8', paddingBottom: '0px', paddingTop: '0px' }}>
                            <div id="unpaid-deals">
                                {
                                    this.state.showUnpaidDealInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTab(this.state.activeTabUnpaidDeals, 1)}><a onClick={() => this.returnToUnpaidDeal()}>Unpaid Deals</a></li>
                                        <li className={this.handleActiveTab(this.state.activeTabUnpaidDeals, 2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUnpaidDealInfo ?
                                    <DealInformationScreen backLink={this.returnToUnpaidDeal} data={this.state.objID} noBreadcrumb={true} />
                                    :
                                    <NewDynamicTable id="table-unpaid-deals"
                                        data={this.state.unpaidDeals}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        dynamicActions={[{ name: 'Info', function: this.handleUnpaidDealClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUnpaidDeals}
                                        numberOfPages={this.state.totalPagesUnpaidDeals}
                                        pageNumber={this.state.pagesUnpaidDeals}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Unpaid Deals')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Undisbursed Deals {this.state.loading ? '' : `(${this.state.undisbursedDeals.length} Items)`}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="undisbursed-deals">
                                {
                                    this.state.showUndisbursedDealInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTab(this.state.activeTabUndisbursedDeals, 1)}><a onClick={() => this.returnToUndisbursedDeal()}>Undisbursed Deals</a></li>
                                        <li className={this.handleActiveTab(this.state.activeTabUndisbursedDeals, 2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUndisbursedDealInfo ?
                                    <DealInformationScreen backLink={this.returnToUndisbursedDeal} data={this.state.objID} noBreadcrumb={true} />
                                    :
                                    <NewDynamicTable id="table-undisbursed-deals"
                                        data={this.state.undisbursedDeals}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        hasChildren={true}
                                        dynamicActions={[{ name: 'Info', function: this.handleUndisbursedDealClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUndisbursedDeals}
                                        numberOfPages={this.state.totalPagesUndisbursedDeals}
                                        pageNumber={this.state.pagesUndisbursedDeals}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Undisbursed Deals')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Undisbursed Forwards {this.state.loading ? '' : `(${this.state.undisbursedForwards.length} Items)`}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="undisbursed-forwards">
                                {
                                    this.state.showUndisbursedForwardInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTab(this.state.activeTabUndisbursedForwards, 1)}><a onClick={() => this.returnToUndisbursedForward()}>Undisbursed Forwards</a></li>
                                        <li className={this.handleActiveTab(this.state.activeTabUndisbursedForwards, 2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUndisbursedForwardInfo ?
                                    <DealInformationScreen backLink={this.returnToUndisbursedForward} data={this.state.objID} noBreadcrumb={true} />
                                    :
                                    <NewDynamicTable id="table-undisbursed-forwards"
                                        data={this.state.undisbursedForwards}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        dynamicActions={[{ name: 'Info', function: this.handleUndisbursedForwardClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUndisbursedForwards}
                                        numberOfPages={this.state.totalPagesUndisbursedForwards}
                                        pageNumber={this.state.pagesUndisbursedForwards}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Undisbursed Forwards')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Held Funds Balances {this.state.loading ? '' : `(${this.state.heldFundsBalances.length} Items)`}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="funds-balances">
                                {
                                    this.state.showHistoryBalance && (
                                        <ul className="uk-tab">
                                            <li className={this.handleActiveTab(this.state.activeTabHeldFundBalance, 1)}><a onClick={() => this.returnToHeldFundBalance()}>Held Funds Balances</a></li>
                                            <li className={this.handleActiveTab(this.state.activeTabHeldFundBalance, 2)}><a onClick={() => { this.showHoldOnFundDirectDebit(2) }}>History Balance</a></li>
                                            <li className={this.handleActiveTab(this.state.activeTabHeldFundBalance, 3)}><a onClick={() => { this.showHoldOnFundDirectDebit(3) }}>Direct Debits</a></li>
                                        </ul>)
                                }
                                {
                                    fundsBalance
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Unpaid Deposits {this.state.loading ? '' : `(${this.state.unpaidDeposits.length} Items)`}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="unpaid-deposits">
                                {
                                    this.state.showUnpaidDepositInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTab(this.state.activeTabUnpaidDeposits, 1)}><a onClick={() => this.returnToUnpaidDeposit()}>Unpaid Deposits</a></li>
                                        <li className={this.handleActiveTab(this.state.activeTabUnpaidDeposits, 2)}><a>Deposit Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUnpaidDepositInfo ?
                                    <DepositInfoScreen customerID={this.props.customerID}
                                        data={this.state.objID}
                                        backLink={this.returnToUnpaidDeposit}  />
                                    :
                                    <NewDynamicTable id="unpaid-deposits"
                                        data={this.state.unpaidDeposits}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        dynamicActions={[{ name: 'Info', function: this.handleUnpaidDepositClick, isIcon: true }]}
                                        hiddenHeaders={['DepositID']}
                                        formats={[{header: 'Amount', formatFunction: formatToCurrencyWithScale}]}
                                        usePaginator={true}
                                        changePage={this.changePageUnpaidDeposits}
                                        numberOfPages={this.state.totalPagesUnpaidDeposits}
                                        pageNumber={this.state.pagesUnpaidDeposits}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Unpaid Deposits')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                </ul>
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}
import React from 'react';
import AlertBox from './AlertBox';
import { exportPayments } from '../../networking/NetworkingFunds';
import { CSVLink, CSVDownload } from "react-csv";

export default class ExportDialog extends React.Component{
    csvLink = React.createRef()
    csvLink2 = React.createRef()
    state = {
        emailDestination: '',
        showAlert: false,
        alertMessage: '',
        alerTitle: '',
        groupId: 0,
        noOfPaySubmited: 0,
        typeOfFile: 0,
        exportType: '',
        fileName: 'empty.csv',
        showExportButton: false,
        array: [],
        boardTittle: '',
        printSupplementalReport: false,
        disableOkButton: false,
    }

    componentDidMount () {
        this.setState({groupId: this.props.groupId, noOfPaySubmited: this.props.noOfPaySubmited,
            typeOfFile: this.props.typeOfFile, exportType: this.props.exportType})
        
        if(this.props.exportType === 'Beneficiary' || this.props.exportType === 'OutgoingFund'){
            if(this.props.typeOfFile === 1) {
                //ExportSVBPayments
                this.setState({exportType: 'SVBPayments', fileName: 'BoardSVB_', boardTittle: 'SVB File'});
            } else {
                //ExportSVBWire
                this.setState({exportType: 'SVBWire', fileName: 'BoardSVBWire_', boardTittle: 'SVB Wire'});
            }
        } else {
            if(this.props.typeOfFile === 1) {
                // ExportACHPayments, ExportCFSBPayments
                this.setState({exportType: 'ACHPayments', fileName: 'BoardACH_', boardTittle: 'ACH Incoming Payments and Supplemental File',
                printSupplementalReport: true});
            } else {
                //ExportACHOutgoingPayments, ExportCFSBOutgoingPayments
                this.setState({exportType: 'ACHOutgoingPayments', fileName: 'BoardACHOutgoingPayments_', boardTittle: 'ACH Incoming Payments and Supplemental File',
                printSupplementalReport: true});
            }
        }
    }

    componentDidUpdate (prevProps) {
        if(prevProps.groupId !== this.props.groupId){
            this.setState({groupId: this.props.groupId, noOfPaySubmited: this.props.noOfPaySubmited,
                typeOfFile: this.props.typeOfFile, exportType: this.props.exportType})
                if(this.props.exportType === 'Beneficiary' || this.props.exportType === 'OutgoingFund'){
                    if(this.props.typeOfFile === 1) {
                        this.setState({fileName: ''});
                        //ExportSVBPayments
                        this.setState({exportType: 'SVBPayments', fileName: 'BoardSVB_', boardTittle: 'SVB File'});
                    } else {
                        //ExportSVBWire
                        this.setState({exportType: 'SVBWire', fileName: 'BoardSVBWire_', boardTittle: 'SVB Wire'});
                    }
                } else {
                    if(this.props.typeOfFile === 1) {
                        // ExportACHPayments, ExportCFSBPayments
                        this.setState({exportType: 'ACHPayments', fileName: 'BoardACH_', boardTittle: 'ACH Incoming Payments and Supplemental File',
                                        printSupplementalReport: true});
                    } else {
                        //ExportACHOutgoingPayments, ExportCFSBOutgoingPayments
                        this.setState({exportType: 'ACHOutgoingPayments', fileName: 'BoardACHOutgoingPayments_', boardTittle: 'ACH Incoming Payments and Supplemental File',
                                        printSupplementalReport: true});
                    }
                }
        }
    }

    onChangeEmailDestination = (event) => {
        this.setState({emailDestination: event.target.value})
    }

    closeAlert = () =>
    {
        this.setState({showAlert: false, alertTitle: '', alertMessage: ''});
    }

    handleExportClick = async (event) => {
        event.preventDefault();
        this.setState({showExportButton: true});
        this.printReport();
    }

    printReport = async () => {
        const json = await exportPayments(this.state.exportType, this.state.groupId, this.state.noOfPaySubmited, '', this.state.emailDestination);
        if (json != null && json !== undefined) {
                let newData = json.response;
                if(newData){
                    if(newData.length > 0){
                        let keys = [...Object.keys(newData[0])];
                        for(let i = 0; i<newData.length; i++){
                            delete newData[i].Action;
                            delete newData[i].Key;
                
                            for(let j = 0; j<keys.length; j++){
                                if(keys[j].includes('$')){
                                    delete newData[i][keys[j]];
                                }
                            }
                        }
                        let today = new Date();
                        let yearString = today.getFullYear().toString();
                        let monthString = (today.getMonth()+1).toString().padStart(2, '0');
                        let dayString = today.getDate().toString().padStart(2, '0');
                        let hourString = today.getHours().toString().padStart(2, '0');
                        let minuteString = today.getMinutes().toString().padStart(2, '0');
                        let timeStamp = yearString + monthString + dayString + hourString + minuteString;
                        this.setState({fileName: this.state.fileName + timeStamp + '.csv', array: newData, disableOkButton: true, showExportButton: true}, () => {
                            this.csvLink.current.link.click()
                        });
                        debugger;
                        if(this.state.printSupplementalReport){
                            if(this.state.exportType === 'ACHPayments') {
                                this.setState({exportType: 'CFSBPayments', fileName: 'SupplementalFile_',
                                        printSupplementalReport: false, showExportSupplementalButton: true});
                            } else {
                                this.setState({exportType: 'CFSBOutgoingPayments', fileName: 'BoardSuplementalOutgoingPayment_',
                                        printSupplementalReport: false, showExportSupplementalButton: true});
                            }
                            this.printSupplementalReport();
                        }
                        else{
                            this.props.onRefresh();
                        }
                    }                    
                }
        }
    }
    
    printSupplementalReport = async () => {
        debugger;
        const json = await exportPayments(this.state.exportType, this.state.groupId, this.state.noOfPaySubmited, '', this.state.emailDestination);
        if (json != null && json !== undefined) {
            debugger;
                let newData = json.response;
                if(newData){
                    if(newData.length > 0){
                        let keys = [...Object.keys(newData[0])];
                        for(let i = 0; i<newData.length; i++){
                            delete newData[i].Action;
                            delete newData[i].Key;
                
                            for(let j = 0; j<keys.length; j++){
                                if(keys[j].includes('$')){
                                    delete newData[i][keys[j]];
                                }
                            }
                        }
                        let today = new Date();
                        let yearString = today.getFullYear().toString();
                        let monthString = (today.getMonth()+1).toString().padStart(2, '0');
                        let dayString = today.getDate().toString().padStart(2, '0');
                        let hourString = today.getHours().toString().padStart(2, '0');
                        let minuteString = today.getMinutes().toString().padStart(2, '0');
                        let timeStamp = yearString + monthString + dayString + hourString + minuteString;
                        this.setState({fileName: this.state.fileName + timeStamp + '.csv', disableOkButton: true, array: newData, showExportButton: true}, () => {
                            this.csvLink.current.link.click()
                        });
                        this.props.onRefresh();
                    }                    
                }
        }
    }

    render (){
        return(
            <div>
                <h5>{this.state.boardTittle}</h5>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label">Email destination</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" value={this.state.emailDestination} onChange={this.onChangeEmailDestination} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <button className="uk-button uk-button-green" disabled={this.state.disableOkButton} onClick={this.handleExportClick}>Ok</button>
                        </div>
                    </div>
                </form>
                <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title={this.state.alerTitle}
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />
                {this.state.showExportButton && 
                    <CSVLink
                        className="hidden" 
                        data={this.state.array}
                        filename={this.state.fileName}
                        ref={this.csvLink}
                    />
                }
            </div>
        );
    }
}
import { ValueBrushScaleDescription } from 'igniteui-react-core';
import React from 'react';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getReferredDeals, saveReferredDealComment } from '../../../networking/NetworkingCustomerLimitProfile';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import NewDynamicTable from '../../shared/NewDynamicTable';




var style = getStyle();

export default class DealsForm extends React.Component {
    state = {
        deals: [],
        referredDealId: 0,
        referredDealNo: 0,
        referredCustomer: '',
        referredIssueDate: '',
        referredValueDate: '',
        referredDealType: '',
        referredBuyAmount: 0,
        referredBuyCurrency: 0,
        referredSellAmount: 0,
        referredSellCurrency: 0,
        referredTraderName: '',
        referredReason: '',
        dealComment: '',
        userId: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        getReferredDeals(0, 0).then(
            (value) => {
                let listDeals=[];
                let pages=0;
                if(value.referreadDeals!==undefined){
                    listDeals = value.referreadDeals;
                    var totalCount = value.totalCount;
                    pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                }
                this.setState({totalPages: pages, newSearch: true, deals: listDeals});
            }
        );
    }

    handleRowClick(obj){
        console.group("Referred Deal handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({
            referredDealId: obj["ID"],
            referredDealNo: obj["Deal No."],
            referredCustomer: obj["Customer"],
            referredIssueDate: obj["Issue Date"],
            referredValueDate: obj["Value Date"],
            referredDealType: obj["Deal Type"],
            referredBuyAmount: obj["Buy Amount"],
            referredBuyCurrency: obj["Buy Currency"],
            referredSellAmount: obj["Sell Amount"],
            referredSellCurrency: obj["Sell Currency"],
            referredTraderName: obj["Trader Name"],
            referredReason: obj["Referral Reason"],
            dealComment: ''
        });
    }
/*
    changePage(value){
        this.setState({currentPage: value});
        getReferredDeals(value,getDefaultPagingNumberOfItems()).then(
            (value) => {                
                let listDeals=[];
                let pages=0;
                if(value.referreadDeals!==undefined){
                    listDeals = value.referreadDeals;
                    var totalCount = value.totalCount;
                    pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                }
                this.setState({totalPages: pages, newSearch: false, deals: listDeals});
            }
        );
    }
*/
    updatePage(){
        getReferredDeals(0, 0).then(
            (value) => {                
                let listDeals=[];
                let pages=0;
                if(value.referreadDeals!==undefined){
                    listDeals = value.referreadDeals;
                    var totalCount = value.totalCount;
                    pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                }                
                this.setState({totalPages: pages, newSearch: false, deals: listDeals});
            }
        );
    }

    handleAddCommentClick(){
        let userID = localStorage.getItem('UserID');
        var model = {
            customerProfileDealViolationId: this.state.referredDealId,
            comment: this.state.dealComment,
            userId: userID
        }
        if(this.state.dealComment !== '' && this.state.dealComment !== undefined && this.state.dealComment !== null){
            saveReferredDealComment(model).then((json)=>
            {
                if (json !== undefined) {
                    if (json.httpStatusCode !== 200) {
                      this.setState({alertTitle: 'Error',
                        alertMessage:json.httpErrorMessage,
                        showAlertOK: true});
                    }
                  else {
                this.setState({
                    referredDealId: 0,
                    referredDealNo: 0,
                    referredCustomer: '',
                    referredIssueDate: '',
                    referredValueDate: '',
                    referredDealType: '',
                    referredBuyAmount: 0,
                    referredBuyCurrency: 0,
                    referredSellAmount: 0,
                    referredSellCurrency: 0,
                    referredTraderName: '',
                    referredReason: '',
                    dealComment:'',
                    alertTitle: 'Success',
                    alertMessage: 'Comment successfully saved.',
                    showAlertOK: true, 
                });
                this.updatePage(); 
                  }
                }
            })
        }
    }

    handleUpdateReferredDealId(value){
        this.setState({referredDealId: value.target.value});
    }

    handleUpdateReferredDealNo(value){
        this.setState({referredDealNo: value.target.value});
    }

    handleUpdateReferredCustomer(value){
        this.setState({referredCustomer: value.target.value});
    }

    handleUpdateReferredIssueDate(value){
        this.setState({referredIssueDate: value.target.value});
    }

    handleUpdateReferredValueDate(value){
        this.setState({referredValueDate: value.target.value});
    }

    handleUpdateReferredDealType(value){
        this.setState({referredDealType: value.target.value});
    }

    handleUpdateReferredBuyAmount(value){
        this.setState({referredBuyAmount: value.target.value});
    }

    handleUpdateReferredBuyCurrency(value){
        this.setState({referredBuyCurrency: value.target.value});
    }

    handleUpdateReferredSellAmount(value){
        this.setState({referredSellAmount: value.target.value});
    }

    handleUpdateReferredSellCurrency(value){
        this.setState({referredSellCurrency: value.target.value});
    }

    handleUpdateReferredTraderName(value){
        this.setState({referredTraderName: value.target.value});
    }

    handleUpdateReferredReason(value){
        this.setState({referredReason: value.target.value});
    }

    handleUpdateDealComment(value){
        this.setState({dealComment: value.target.value});
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }

    render(){
        var section = '';
        if(this.state.referredDealNo !== 0){
            section = this.state.referredDealNo
        }
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Selected Deal Information</h3>
                <form className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">ID :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealId" type="text" placeholder="" value={this.state.referredDealId} onChange={this.handleUpdateReferredDealId.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal No. :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealNo" type="text" placeholder="" value={this.state.referredDealNo} onChange={this.handleUpdateReferredDealNo.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.referredCustomer} onChange={this.handleUpdateReferredCustomer.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Issue Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredIssueDate" type="text" placeholder="" value={this.state.referredIssueDate} onChange={this.handleUpdateReferredIssueDate.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Value Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredValueDate" type="text" placeholder="" value={this.state.referredValueDate} onChange={this.handleUpdateReferredValueDate.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal Type :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.referredDealType} onChange={this.handleUpdateReferredDealType.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Buy Amount :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredBuyAmount" type="text" placeholder="" value={this.state.referredBuyAmount} onChange={this.handleUpdateReferredBuyAmount.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Buy Currency :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredBuyCurrency" type="text" placeholder="" value={this.state.referredBuyCurrency} onChange={this.handleUpdateReferredBuyCurrency.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Sell Amount :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredSellAmount" type="text" placeholder="" value={this.state.referredSellAmount} onChange={this.handleUpdateReferredSellAmount.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Sell Currency :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredSellCurrency" type="text" placeholder="" value={this.state.referredSellCurrency} onChange={this.handleUpdateReferredSellCurrency.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Trader Name :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredTraderName" type="text" placeholder="" value={this.state.referredTraderName} onChange={this.handleUpdateReferredTraderName.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Referral Reason :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.referredReason} onChange={this.handleUpdateReferredReason.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                        <label className="uk-form-label" htmlFor="form-bank-status">Add Comment for Deal {section} :</label>
                        <textarea className="uk-textarea" style={{marginTop: "5px"}} id="dealComment" type="text" placeholder="" value={this.state.dealComment} onChange={this.handleUpdateDealComment.bind(this)}></textarea>
                        {/*<h4>Add Comment for Deal {section}</h4>*/}
                        </div>
                    </div>
                    {/*<div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <textarea className="uk-textarea" style={{marginTop: "5px"}} id="dealComment" type="text" placeholder="" value={this.state.dealComment} onChange={this.handleUpdateDealComment.bind(this)}></textarea>                               
                        </div>
                    </div>*/}
                    <div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} disabled={JSON.parse(localStorage.getItem('HasComplianceAccess'))} onClick={this.handleAddCommentClick.bind(this)} type="button">Add</button>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </form>
                <h3 className="uk-heading-divider" style={style.titleStyle}>List of Deals</h3>
                <NewDynamicTable 
                        formats={[{header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency'},{header: 'Sell Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Sell Currency'}]} 
                        hiddenHeaders={['ID','Issue Date','Deal Type','Trade Name']} 
                        data={this.state.deals} 
                        enableClick={true} 
                        clickFunction={this.handleRowClick.bind(this)} 
                        newSearch={this.state.newSearch} 
                        useDeleteButton={false}/>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
            </div>
        );
    }
}
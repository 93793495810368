import { useState, useEffect } from "react";
import { getParentBlotters } from "../../../../networking/NetworkingBlotters";

const useParentBlotters = () => {
    const [parentBlotters, setParentBlotters] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);

    useEffect(() => {
        let isCancelled = false;

        const loadParentBlotters = async () => {
            const json = await getParentBlotters();
            if (!isCancelled) {
                const formattedJson = json.blotters.map(b => ({...b, name: b.Description, value: b.BlotterID}));
                setParentBlotters(formattedJson);
                setNeedsReloading(false);
            }
        }
        if (needsReloading) {
            loadParentBlotters();
        }

        return () => {
            isCancelled = true;
        }
    }, [needsReloading]);

    return [parentBlotters, setNeedsReloading];
}

export default useParentBlotters;
import {genericGetWithParameters, genericCallWithBody,genericCallWithBodyAndResponseDecoded} from './Networking';

export async function getAllCurrencyPairs(){
    try {
        let params = {};
        let response = await genericGetWithParameters('CurrencyPair/GetAll',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCurrencyPairInfo = async (buyCurrency, sellCurrency) => {
    try {
        const params = {
            BuyCurrency: buyCurrency,
            SellCurrency: sellCurrency
        };
        const response = await genericGetWithParameters('CurrencyPair/GetCurrencyPairInfo', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyPairDefs(filter){
    try {
        let params = { 'filter': filter};
        let response = await genericGetWithParameters('CurrencyPair/GetCurrencyPairDefs',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function modifyCurrencyPair(currencyPair, reverseCurrencyPair) {
    var method = 'PUT';
    var apiName = 'CurrencyPair/ModifyCurrencyPair';
    var request = {
        'currencyPair': currencyPair,
        'reverseCurrencyPair': reverseCurrencyPair
    }
    try {
        let response = await genericCallWithBody(method, apiName, request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateCustomerPairs(model) {
    var method = 'POST';
    var apiName = 'CurrencyPair/ValidateCustomerPairs';

    try {
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import AlertBox from '../../shared/AlertBox';
import { getLookUpTable } from '../../../networking/Networking';
import { getFeesMin,saveFeeStructure } from '../../../networking/NetworkingFeeStructure';
import { validate } from '../../../helpers/ValidateHelper';
import { formatValidInput, formatValidSelect } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
const style = getStyle();
export default class FeeStructureForm extends React.Component {

    state = {
        notFirstLoad:false,
        feeStructureId:'',
        description:'',
        default:false,
        cumulativeByTier:false,
        relatedFeeID:'',        
        buySell:'',
        updateToken: '',
        relatedFeeName:'',
        forUserOnline:false,
        relatedFeeList:[],
        buySellList:[],
        showAlert:false,
        alertTitle: '',
        alertMessage:'',
        showAlertError: false,
        errors:[],
        validations: {
            description: true,
            relatedFeeID: true,
            buySell: true,
        }
    }

    
    componentDidMount(){     
        getLookUpTable('Type of transacction').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                }
                this.setState({buySellList: helper});
            }
        );  
        getFeesMin().then(
            (value) => {
                var feesMin = this.transformRelatedFeeList(value.fees);
                var helper = [];
                for(var i=0;i<feesMin.length;i++){
                    feesMin[i].value = feesMin[i]['FeeStructureId'];
                    feesMin[i].name = feesMin[i]['Description'];
                    helper.push(feesMin[i]);
                }
                console.log(helper);
                this.setState({relatedFeeList: helper},()=>{
                    if(this.props.selectedFeeStructure.FeeStructureID!==undefined){
                        this.updateInputs()
                    } 
                });
            }
        );
    }

    updateInputs = () =>{
        var feestructure = this.props.selectedFeeStructure;
        var feestructureID = 0;
        if(feestructure['FeeStructureID'] !== null){
            feestructureID = feestructure['FeeStructureID'];
        }

        console.log(feestructureID);
        this.setState({
            feeStructureId: feestructureID,
            description: feestructure['Description'],
            default: feestructure['Default'],
            cumulativeByTier: feestructure['CumulativeByTier'],
            relatedFeeID: feestructure['RelatedFeeID'],
            buySell: feestructure['BuySell'],
            updateToken: feestructure['UpdateToken']
        });
    }
    
    componentDidUpdate(prevProps){
        if(this.props.selectedFeeStructure!== {} && prevProps.selectedFeeStructure !== this.props.selectedFeeStructure){
            this.updateInputs()  
        };
        if(this.props.clearForm !== {} && this.props.clearForm === true){
            this.setState({
                feeStructureId: '',
                description: '',
                default: false,
                cumulativeByTier: false,
                relatedFeeID: '',
                buySell: '',
                updateToken: ''          
            });
            this.props.outClearForm(false);
        }
    }

    transformRelatedFeeList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'FeeStructureId': list[i]['FeeStructureId'],
            'Description': list[i]['Description']});
        }
        return helper;
    }
    //#region dropdown
    getRelatedFeeSelect(value){
        console.log(value.value);
        if(value.value == undefined||value.value == "select"){
            this.setState({relatedFeeID: ''});
        }
        else{
            this.setState({validations: {...this.state.validations, relatedFeeID: true},relatedFeeID: value.value});
        }
    }
    getBuySellSelect(value){
        console.log(value);
        if(value.value == undefined||value.value == "select"){
            this.setState({buySell: ''});
        }
        else{
            this.setState({validations: {...this.state.validations, buySell: true},buySell: value.LookUpTableDetail});
        }
    }
    //#endregion

    handleUpdateDescription(event){
        this.setState({validations: {...this.state.validations, description: true},description: event.target.value});
    }
    
    handleUpdateBuySell(event){
        this.setState({validations: {...this.state.validations, buySell: true},buySell: event.target.value});
    }

    //#region checkbox
    handleUpdateCumulativeByTier(event){
        this.setState({
            cumulativeByTier: event.target.checked
        })
    }

    handleUpdateDefault(event){
        this.setState({
            default: event.target.checked
        })
    }    
    //#endregion

    handleClearClick = (event) =>{
        event.preventDefault();
        this.setState({
            feeStructureId:'',
            description:'',
            default:false,
            cumulativeByTier:false,
            relatedFeeID:'',
            buySell:'',
            updateToken: '',
            validations: {
                description: true,
                relatedFeeID: true,
                buySell: true,
            }
        },()=>this.props.isClearForm());
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }
    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }

    handleSaveClick(event){
        event.preventDefault();
        let UserID = localStorage.getItem('UserID');        
        //var feeList = this.props.selectedFeeStructure;
        var feeStructureId = this.state.feeStructureId;
        if(feeStructureId ==''){
            feeStructureId = 0;
        }
        var objectFilter = {                
            feeStructureId: feeStructureId,
            description: this.state.description,
            default: this.state.default,
            cumulativeByTier: this.state.cumulativeByTier,
            relatedFeeID: this.state.relatedFeeID,
            buySell: this.state.buySell,
            updateToken: this.state.updateToken,
            userID: UserID
        }
        
        if(this.validateRequiredInputs()){
            console.log(objectFilter);
            saveFeeStructure(objectFilter).then(                
            (json)=>{
                if (json.status !== 200){
                    var alertMsg = 'An error occurred while trying to save the Fee Structure. Please try again.'
                    if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                        alertMsg = json.httpErrorMessage;
                    }
                    this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error'});
                } else {
                    this.setState({
                        feeStructureId:'',
                        description:'',
                        default:false,
                        cumulativeByTier:false,
                        relatedFeeID:'',
                        buySell:'',
                        updateToken: '',
                        showAlert: true, alertTitle: 'Fee Structure successfully saved.'});
                    this.props.updateCategoryTable();
                }
            }
            );
        }
    }

    //Inputs Validations
    validateRequiredInputs(){
        const fields = [
            {displayName:'Description', validateName: 'description', value:this.state.description, type: 'string'},
            {displayName:'Related Fee', validateName: 'relatedFeeID', value:this.state.relatedFeeID, type: 'string'},
            {displayName:'Buy/Sell', validateName: 'buySell', value: this.state.buySell, type: 'string'}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }
    
    render(){
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-description" style={style.boldLabel}>Fee Description (*)</label>
                            <div className="uk-form-controls">
                                <input className={formatValidInput(this.state.validations.description)} id="form-fee-structure-description" type="text" placeholder="Fee Description" value={this.state.description}  onChange={this.handleUpdateDescription.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-default"><label><input className="uk-checkbox" type="checkbox" checked={this.state.default} onChange={this.handleUpdateDefault.bind(this)}/> Default Fee</label>  Default Fee</label>                            
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-cumulativebytier"><label><input className="uk-checkbox" type="checkbox" checked={this.state.cumulativeByTier} onChange={this.handleUpdateCumulativeByTier.bind(this)}/> Cumulative by tier</label> Cumulative by tier</label>
                            <div className="uk-form-controls">                                        
                                                      
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-relatedfee" style={style.boldLabel}>Related Fee (*)</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.relatedFeeID)} id="form-fee-structure-relatedfee" getValue={this.getRelatedFeeSelect.bind(this)} placeholder="Select a Fee..." data={this.state.relatedFeeList} 
                                objValue={{value: this.state.relatedFeeID, name: this.state.relatedFeeName}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-buysell" style={style.boldLabel}>Buy / Sell (*)</label>
                            <div className="uk-form-controls">
                            <DynamicSelect className={formatValidSelect(this.state.validations.buySell)} id="form-fee-structure-buysell" getValue={this.getBuySellSelect.bind(this)} placeholder="Select a Buy/Sell..." data={this.state.buySellList} 
                                objValue={{value: this.state.buySell, name: this.state.buySell}}/>
                            </div>
                        </div>
                    </div>            
                    <div className="uk-width-1-2 uk-form-stacked"></div>
                    <div className="uk-width-2-2 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <hr/>
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>
                </form>
            </div>
        );
    }
}
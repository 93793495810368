import React, { useState } from 'react';
import { getStyle } from '../../../styles/styles';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { updateBusinessHours, getBusinessHours } from '../../../networking/NetworkingAfterHours';
import useBusinessHours from './hooks/useBusinessHours';
import AlertBox from '../../shared/AlertBox';
import { formatValidInput } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { TextField } from '@material-ui/core';
var style = getStyle();

const BusinessHoursForm = (props) => {
    const [businessHours, setBusinessHours] = useState({ day: '', closed: false, openHour: '', closeHour: '', plan: '' });
    const [dayDisabled] = useState(true);
    const [validations, setValidations] = useState({day: true, openHour: true, closeHour: true});
    const plans = props.plans;
    const [businessHoursGrid, totalPages, businessHoursGridNeedsReloading] = useBusinessHours();
    const [pages, setPages] = useState(1);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [disableHours, setDisableHours] = useState(false);

    const validateInputColor = (valid) => {
        return valid ? '#666' : '#f0506e';
    }

    const handleRowClick = (obj) => {
        setValidations({day: true, openHour: true, closeHour: true});
        setBusinessHours({
            ...businessHours,
            day: obj.Day,
            closed: obj['Is Closed'],
            openHour: obj['Open Hour'],
            closeHour: obj['Close Hour'],
            plan: obj.PlanID === null ? '': obj.PlanID
        });
        setDisableHours(obj['Is Closed']);
    }

    const handleSaveClick = async (e) => {
        e.preventDefault();
        if (validateFields()) {
            const model = {
                'Day': businessHours.day,
                'OpenHour': businessHours.openHour,
                'CloseHour': businessHours.closeHour,
                'PlanID': businessHours.plan === '' ? 0 : parseInt(businessHours.plan),
                'Closed': businessHours.closed
            };
            const json = await updateBusinessHours(model);
            if (json.httpStatusCode !== 200) {
                setShowAlertError(true);
                setAlertTitle('Error');
                setAlertMessage('An error occurred while trying to update the business hour. Please try again.');
                console.log('error');
            } else {
                setShowAlert(true);
                setAlertTitle('Business hour saved successfully.');
            }
            clearFields();
            businessHoursGridNeedsReloading(true);
        }
    }

    const clearFields = () => {
        setBusinessHours({ day: '', closed: false, openHour: '', closeHour: '', plan: '' });
    }

    const validateFields = () => {
        const fields = [
            { displayName: 'Day', validateName: 'day', value: businessHours.day, type: 'string' }
        ];
        if (!businessHours.closed) {
            fields.push({ displayName: 'Open Hour', validateName: 'openHour', value: businessHours.openHour, type: 'string' },
                { displayName: 'Close Hour', validateName: 'closeHour', value: businessHours.closeHour, type: 'string' });
        }
        const [valid, _validations, _errors] = validate(fields);

        setValidations(_validations);
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid;
    }

    const changePage = (value) => {
        setPages(value);
    }

    const getAllBusinessHours = async () => {
        const json = await getBusinessHours(0, 0);
        return json.businessHours;
    }

    const handleUpdateDay = (event) => {
        setValidations({...validations, day: true});
        setBusinessHours({...businessHours, day: event.target.value});
    }

    const handleUpdateClosed = (event) => {
        setBusinessHours({...businessHours, closed: event.target.checked, openHour: '', closeHour: '' });
        setDisableHours(event.target.checked);
    }

    const handleUpdateOpenHour = (event) => {
        setValidations({...validations, openHour: true});
        setBusinessHours({...businessHours, openHour: event.target.value});
    }

    const handleUpdateCloseHour = (event) => {
        setValidations({...validations, closeHour: true});
        setBusinessHours({...businessHours, closeHour: event.target.value});
    }

    const handleGetValuePlan = (value) => {
        if (value !== undefined && value !== 'select') {
            value.value = value.value === 'select' ? '' : value.value;
            setBusinessHours({...businessHours, plan: value.value});
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    const closeAlertError = () => {
        setShowAlertError(false)
        setAlertTitle('');
        setAlertMessage('');
    }

    return (
        <div>
            <form className="uk-form-horizontal uk-margin">
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Day (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <input className={formatValidInput(validations.day)} disabled={dayDisabled} type="text" placeholder="" value={businessHours.day} onChange={handleUpdateDay} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}>
                        <label className="uk-form-label"><input className="uk-checkbox" value={businessHours.closed} checked={businessHours.closed} onChange={handleUpdateClosed} type="checkbox" /> Closed</label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Open Hour</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <TextField id="time-open" type="time" defaultValue="00:00" InputLabelProps={{ shrink: true }} 
                                InputProps={{ style: { color: validateInputColor(validations.openHour) }}}
                                inputProps={{ step: 60 }}
                                value={businessHours.openHour}
                                onChange={handleUpdateOpenHour}
                                disabled={disableHours}
                            />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Close Hour</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <TextField id="time-close" type="time" defaultValue="00:00" InputLabelProps={{ shrink: true }}
                                InputProps={{ style: { color: validateInputColor(validations.closeHour) } }}
                                inputProps={{ step: 60 }}
                                value={businessHours.closeHour}
                                onChange={handleUpdateCloseHour}
                                disabled={disableHours}
                            />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Plan</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect className="uk-select" data={plans} objValue={{value: businessHours.plan}} getValue={handleGetValuePlan} listHasPlaceholder={false} placeholder="Select an Item..." id="form-plans" />
                        </div>
                    </div>
                </div>
                <div>
                    <button className="uk-button uk-button-green" onClick={handleSaveClick}>Save</button>
                    <hr/>
                </div>
            </form>
            <NewDynamicTable
                id="ndt-business-hours"
                data={businessHoursGrid}
                hiddenHeaders={["PlanID", "Is Closed"]}
                enableClick={true}
                clickFunction={handleRowClick}
                newSearch={true}
                useDeleteButton={false}
                usePaginator={true}
                changePage={changePage}
                numberOfPages={totalPages}
                pageNumber={pages}
                numberPerPage={getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}
                useFilter={true}
                filterFunction={getAllBusinessHours}/>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            <AlertBox open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
        </div>
    );
}

export default BusinessHoursForm;
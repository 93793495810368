import { Breadcrumb } from 'antd';
import React from 'react';
import { useState } from 'react';
import { getStyle } from '../../../styles/styles';
import HeldFundsBalanceTransferForm from './HeldFundsBalanceTransferForm';
import HeldFundsBalanceTransferSearchScreen from './HeldFundsBalanceTransferSearchScreen';
import HeldFundsBalanceTransferStatementScreen from './HeldFundsBalanceTransferStatementScreen';

const style = getStyle();

const HeldFundsBalanceTransferScreen = () => {
    const [activeTab, setActiveTab] = useState('new-transfer');

    const handleActiveTab = (tab) => {
        return activeTab === tab ? 'uk-active': '';
    }

    const changeTab = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Held Funds Balance Transfer</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <ul uk-tab="">
                <li className={handleActiveTab('blotter')}><a href="#" onClick={() => changeTab("new-transfer")}>New Transfers</a></li>
                <li className={handleActiveTab('blotter-limits')}><a onClick={() => changeTab("search")}>Search</a></li>
                <li className={handleActiveTab('blotter-limits')}><a onClick={() => changeTab("statement")}>Statement</a></li>
            </ul>
            <ul className="uk-switcher uk-margin">
                <li className={handleActiveTab('new-transfer')}>
                    <HeldFundsBalanceTransferForm />
                </li>
                <li className={handleActiveTab('search')}>
                    <HeldFundsBalanceTransferSearchScreen />
                </li>
                <li className={handleActiveTab('statement')}>
                    <HeldFundsBalanceTransferStatementScreen />
                </li>
            </ul>
        </div>
    );
}

export default HeldFundsBalanceTransferScreen;

import React from 'react';

import Paginator from '../shared/Paginator';
import NewDynamicTable from '../shared/NewDynamicTable';
import AlertBox from '../shared/AlertBox';

import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';

import {getReferredDeals, saveReferredDealComment} from '../../networking/NetworkingCustomerLimitProfile';

import {getStyle} from '../../styles/styles'
var style = getStyle();

export default class DealVersionInformationScreen extends React.Component {
    state = {
        objVersion:{},
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        if(this.props.objVersion != undefined)
        {
            //debugger;
            this.setState({objVersion:this.props.objVersion});
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.objVersion != this.props.objVersion && this.props.objVersion != undefined )
        {
            this.setState({objVersion:this.props.objVersion});
        }
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }

    render(){
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{"Deal "+this.state.objVersion["Deal Number"] + " - Version " + this.state.objVersion["Version Number"]}</h3>
                <div className="uk-grid">

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Trader :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="dealVersionTrader" type="text" placeholder="" value={this.state.objVersion.Trader} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Branch :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.objVersion.Branch} />
                            </div>
                        </div>
                    </div>                    
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal Origin :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.objVersion.DealOrigin} />
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-3-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.objVersion.Name}/>
                            </div>
                        </div>
                    </div>  

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deal Type :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.objVersion.DealType} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Forward Type :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ForwardType} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Status :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.Status} />
                            </div>
                        </div>
                    </div>
                                      
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Issue Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredIssueDate" type="text" placeholder="" value={this.state.objVersion["Issue Date"]} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Value Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredValueDate" type="text" placeholder="" value={this.state.objVersion["Value Date"]} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Maturity Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.objVersion["MaturityDate"]} />
                            </div>
                        </div>
                    </div>

                    <hr className="uk-width-3-3 uk-margin-top uk-margin-left uk-margin-bottom"></hr>

                    <div className="uk-width-2-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">We Buy :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredBuyAmount" type="text" placeholder="" value={this.state.objVersion.BuyAmount + " " + this.state.objVersion.BuyCurrency} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked"></div>

                    <div className="uk-width-2-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">We Sell :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredSellAmount" type="text" placeholder="" value={this.state.objVersion.SellAmount + " " + this.state.objVersion.SellCurrency} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked"></div>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Original Market Rate :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.objVersion.OriginalMarketSpotRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Market Spot Rate :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.objVersion.MarketRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked"></div>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Original Customer Rate :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredTraderName" type="text" placeholder="" value={this.state.objVersion.OriginalCustomerRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer Rate :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.CustomerRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Forward Points :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ForwardPoints} />
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Total Fee Amount :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.objVersion.TotalFeesAmount} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Deposit Percent :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.DepositPercent} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Profit Amount :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ProfitAmount} />
                            </div>
                        </div>
                    </div>

                    <hr className="uk-width-3-3 uk-margin-top uk-margin-left uk-margin-bottom"></hr>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">{"Spot Market Rate USD/"+this.state.objVersion.SellCurrency+ ":"}</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.objVersion.BuyBaseCCYSpotMarketRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">{"Forward Market Rate USD/"+this.state.objVersion.SellCurrency+ ":"}</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BuyBaseCCYFwdMarketRate} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">{"Fwd. Points USD/"+this.state.objVersion.SellCurrency+ ":"}</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BuyBaseCCYFwdPoints} />
                            </div>
                        </div>
                    </div>

                    <hr className="uk-width-3-3 uk-margin-top uk-margin-left uk-margin-bottom"></hr>

                    <div className="uk-width-3-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer Ref No :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.CustomerRefNo} />
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Voided By :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.VoidBy} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Void Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.VoidTS} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked"></div>

                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Approved By :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ApprovedBy} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Approved Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ApprovedTS} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3 uk-form-stacked"></div>

                    <div className="uk-width-2-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">External Comments :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ExternalComments} />
                            </div>
                        </div>
                    </div>                    
                    <div className="uk-width-1-3 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Internal Comments :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.InternalComments}/>
                            </div>
                        </div>
                    </div>

                    {/*
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Delivery Method :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.objVersion.SettlementDeliveryMethods} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Total Disbursed :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.TotalDisbursed} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Input By :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.InputBy}/>
                            </div>
                        </div>
                    </div>
                                                                                
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Batch Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BatchDate} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Link Quote No :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.LinkQuoteNo} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Batch Id :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BatchId} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Notification Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.NotificationTS} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Settlement Account :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BankAccount} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Notification Method :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.NotificationMethod} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Confirmed By Client :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ConfirmedByClient} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Require Deposits :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.RequireDeposits} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Paid Status :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.DealPaidStatus} />
                            </div>
                        </div>
                    </div><div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Confirmed Date :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.ConfirmedTS} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Original Forward Points :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.OriginalForwardPoints} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Disbursement Status :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.DealDisbursementStatus} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Cash indicator :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.CashIndicator} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Is Rate Direct :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.IsRateDirect == 'D' ?true: false} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Bank :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.BankName} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Online Settlement :</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="fwd-market-rate" type="text" placeholder="" value={this.state.objVersion.OnlineSettlement} />
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </div>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
            </div>
        );
    }
}
import React from 'react';
import NewDynamicTable from '../../../shared/NewDynamicTable.old';
import { getStyle } from '../../../../styles/styles';
import { getContactsAvailableForMessaging, sendMessage } from '../../../../networking/NetworkingCustomers';
import LoadingBox from '../../../shared/LoadingBox';
import AlertBox from '../../../shared/AlertBox';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import { Dialog, DialogContent } from '@material-ui/core';
import DynamicSelect from '../../../shared/DynamicSelect';
const style = getStyle();

export default class SendMessageScreen extends React.Component {

    state = {
        subject: '',
        message: '',
        contacts: [],
        contactID: '',
        contactName: '',
        recipients: [],
        loading: true,
        newSearch: false,
        showGrid: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        totalPages: 0,
        currentPage: 1
    };

    componentDidMount() {
        this.loadContacts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.loadContacts();
        }
    }
    handleBackLink = () => {
        this.props.backLink();
    }

    handleCheckboxClick = (contact, checked) => {
        let recipients = this.state.recipients;
        if (checked) {
            recipients.push(contact);
        } else {
            recipients = recipients.filter(c => c.PersonID !== contact.PersonID);
        }
        this.setState({ recipients });
    }/*
    handleSelectContact = (value) => {
        let recipients = this.state.recipients;
        if (value.value != null) {
            recipients = recipients.filter(c => c.PersonID !== value.value);
            recipients.push({ PersonID: value.value, Contacts: value.name });
            this.setState({ contactID: value.value, contactName: value.name, recipients });
        }
    } */

    handleClearClick = async () => {
        this.setState({
            subject: '',
            message: '',
            showGrid: false,
            recipients: []
        }, () => this.setState({ showGrid: true }));
    }

    loadContacts = async () => {
        const contacts = await this.getAllMessages();
        if (contacts.length > 0) {
            /* this.setState({ contacts }) */
            const totalCount = contacts.length;
            const totalPages = Math.ceil(totalCount / 5);
            this.setState({ contacts, totalPages, loading: false, newSearch: true });
        } else {
            this.setState({ loading: false, newSearch: true });
        }
    }

    handleSendMessageClick = async () => {
        if (this.state.recipients.length > 0) {
            const receiverIds = this.state.recipients.map(r => r.PersonID);
            const model = {
                messageContent: this.state.message,
                senderId: localStorage.getItem('UserID'),
                messageSubject: this.state.subject,
                receiverIds: receiverIds
            };
            this.setState({ loading: true });
            const json = await sendMessage(model);
            if (json != null) {
                switch (Number(json.httpStatusCode)) {
                    case 200: {
                        this.setState({
                            showAlert: true, alertTitle: 'Success', alertMessage: 'Message successfully sent.', loading: false
                        });
                        break;
                    }
                    default: {
                        this.setState({
                            showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false
                        });
                        break;
                    }
                }
            }
        } else {
            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'Please select a contact from the list' });
        }
    }

    handleExitClick = () => {
        this.handleBackLink();
    }

    handleUpdateSubject = (event) => {
        this.setState({ subject: event.target.value });
    }

    handleUpdateMessage = (event) => {
        this.setState({ message: event.target.value });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    getAllMessages = async () => {
        const json = await getContactsAvailableForMessaging(this.props.customerID);
        if (json != null && json.contacts != null) {
            const contacts = json.contacts.map(contact => ({ Contacts: contact.NamePosition, PersonID: contact.PersonId }))
            //const contacts = json.contacts.map(contact => ({ name: contact.NamePosition, value: contact.PersonId }))
            return contacts;
        } else {
            return []
        }
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleExitClick} maxWidth={"md"} fullWidth={true}>
                <DialogContent>
                    <h3 style={{ fontWeight: "bold" }}>Send Message</h3>
                    <div className="">
                       {
                            this.state.showGrid &&
                            <NewDynamicTable
                                id="ndt-send-message"
                                data={this.state.contacts}
                                newSearch={this.state.newSearch}
                                hiddenHeaders={['PersonID']}
                                checkboxFunction={this.handleCheckboxClick}
                                useCheckBox={true}
                                selectedRows={this.state.recipients}
                                usePaginator={true}
                                changePage={this.changePage}
                                numberOfPages={this.state.totalPages}
                                pageNumber={this.state.currentPage}
                                numberPerPage={5}
                                enableSortByHeader={true}
                                useFilter={true}
                                filterFunction={this.getAllMessages} />
                        }
                        <form className="uk-form-horizontal">
                            <div className="uk-grid">
                                {/* <div className="uk-width-1-1 uk-form-stacked">
                                    <label className="uk-form-label">To</label>
                                    <div className="uk-margin" style={style.inputStyle}>
                                        <DynamicSelect id="select-beneficiary"
                                            //className={this.formatValidSelect(this.state.validations.beneficiary)}
                                            placeholder="Select a Contact..."
                                            getValue={this.handleSelectContact}
                                            objValue={{ value: this.state.contactID, name: this.state.contactName }}
                                            data={this.state.contacts}
                                            />
                                    </div>
                                </div> */}
                                <div className="uk-width-1-1 uk-form-stacked">
                                    <label className="uk-form-label">Subject</label>
                                    <div className="uk-margin" style={style.inputStyle}>
                                        <input id="send-message-subject" className="uk-input" type="text" placeholder="" value={this.state.subject} onChange={this.handleUpdateSubject} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1 uk-form-stacked">
                                    <label className="uk-form-label" >Message</label>
                                    <div className="uk-margin" style={style.inputStyle}>
                                        <textarea className="uk-textarea" style={{ marginTop: "5px" }} id="send-message-message" type="text" placeholder="" value={this.state.message} onChange={this.handleUpdateMessage}></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick}>Clear Fields</button>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendMessageClick}>Send</button>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleExitClick}>Exit</button>
                        </div>
                        <LoadingBox loading={this.state.loading} />
                        <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                        <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} />

                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}


import React from 'react';

import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {CustomerType, BeneficiaryType, CustomerContactType,TraderType} from '../../../constants/EntityTypeNames'
import AlertBox from '../AlertBox';

import {getStyle} from '../../../styles/styles';
var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" style={{fontWeight: 'bold'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

export default class RiskScoreDetailDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {
            fullWidth           : true,
            maxWidth            : 'md',
            titleRSDD           : '',

            //Alert no preview
            showOpenAlert       : false,
        };
    }

    componentDidMount()
    {

    }

    setTitle()
    {
        let newTitle = ''
        
        switch (this.props.entityType)
        {
            case CustomerType:
            {  
                newTitle = 'Customer Risk Score Calculation'
                break;
            }
            case BeneficiaryType:
            {  
                newTitle = 'Beneficiary Risk Score Calculation'
                break;
            }
            case CustomerContactType:
            {  
                newTitle = 'Contact Risk Score Calculation'
                break;
            }
            case TraderType:
            {  
                newTitle = 'Deal Risk Score Calculation (Deal No: ' + this.props.dealId.toString() + ')';
                break;
            }
            default:
            {
                break;
            }
        }

        this.setState({
            titleRSDD: newTitle
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(this.props.dealId !== prevProps.dealId)
        {
            this.setTitle()
        }
    }

    closeAlert = () =>
    {
        this.setState({
            showOpenAlert   : false,
        })
    }

    render() 
    {
        let headers     = [];
        let rows        = [];

        let sumImportance = 0;
        let sumTotal = 0;
        if(this.props.riskScoreDetails !== undefined && this.props.riskScoreDetails.length !== 0)
        {
            let headerList  = Object.keys(this.props.riskScoreDetails[0]);

            headerList.forEach((header, index) => 
            {
                if(index < 4)
                {
                    headers.push(<th style={style.alignCenter} key="actions-column">{header}</th>)
                }
            });

            this.props.riskScoreDetails.forEach((risk, index) => {
                /*{
                    "Risk Factor": "Customer (Ramirez Grandez, Maria Paz)",
                    "Risk Score": 19,
                    "Risk Importance": 50,
                    "Total": 950,
                    "Entity ID": 1242,
                    "Entity Type": "C",
                    "Order": 1,
                    "Risk Score Text": "19",
                    "Score Calculated": 1,
                    "Parent Risk Score Text": ""
                }*/
                rows.push(
                    <tr
                        style={{
                            textAlign : "center",
                            cursor    : 'pointer'
                        }}
                        //onClick={this.onClickRow(risk)}
                    >
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[0]]}</td>}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[1]]}</td>}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[2]]}</td>}
                        {<td style={{borderLeft: 'none'}}>{risk[headerList[3]]}</td>}
                    </tr>
                );
                sumImportance += risk[headerList[2]]
                sumTotal += risk[headerList[3]]
            });

            rows.push(
                <tr
                    style={{
                        textAlign : "center",
                        cursor    : 'pointer'
                    }}
                    //onClick={this.onClickRow(risk)}
                >
                    {<td style={{borderLeft: 'none'}}>{}</td>}
                    {<td style={{borderLeft: 'none', fontWeight: 'bold'}}>{'Sub totals'}</td>}
                    {<td style={{borderLeft: 'none'}}>{sumImportance}</td>}
                    {<td style={{borderLeft: 'none'}}>{sumTotal}</td>}
                </tr>
            );
            rows.push(
                <tr
                    style={{
                        textAlign : "center",
                        cursor    : 'pointer'
                    }}
                    //onClick={this.onClickRow(risk)}
                >
                    {<td style={{borderLeft: 'none'}}>{}</td>}
                    {<td style={{borderLeft: 'none', fontWeight: 'bold'}}><font size="3">{'Total Risk Score'}</font><br></br><font size="1" style={{paddingTop: 0}}>{'(Total/Importance)'}</font></td>}
                    {<td colspan="2" style={{borderLeft: 'none', fontWeight: 'bold'}}>{Math.round(sumTotal/sumImportance)}</td>}
                </tr>
            );  
        }

        return (
            <div>
                <Dialog 
                    open        = {this.props.open} 
                    onClose     = {this.props.onCloseRiskScoreDetailDialog}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {this.state.maxWidth}
                >
                    <DialogTitle 
                        id="attachments-dialog-title" 
                        onClose={this.props.onCloseRiskScoreDetailDialog}
                    >
                        {this.state.titleRSDD}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                            <div style={{padding: 20}}>
                                <table class="uk-table">
                                    <thead>
                                        <tr 
                                            style={{
                                                border: '1px solid #e5e5e5'
                                            }}
                                        >
                                            {headers}
                                        </tr>
                                    </thead>
                                    <tbody
                                        style={{
                                            border: '1px solid #e5e5e5'
                                        }}
                                    >
                                        {rows}
                                    </tbody>
                                </table>
                                <div className="" style={{ lineHeight: "1" }}>
                                    <label className="uk-form-label" style={{ fontSize: "10px", marginRight: "240px", color: "red" }}>* Please note, total risk score values are rounded up.</label>
                                </div>
                                <div className="" style={{ lineHeight: "1" }}>
                                    <label className="uk-form-label" style={{ fontSize: "10px", marginRight: "240px", color: "red" }}>** When there are multiple items within a Risk Factor (i.e. A customer could have addresses in multiple countries, province/states). the risk scores of the group are added together and then averaged to come up with one overall risk score for the risk factor.</label>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox 
                    id       = "alert-riskScoreDetailDialog" 
                    open     = {this.state.showOpenAlert} 
                    onClose  = {this.closeAlert.bind(this)} 
                    title    = "title" 
                    message  = "message" 
                    type     = "Ok" 
                    okClick  = {this.closeAlert.bind(this)}
                />
            </div>
        )
    }
}
import { genericCallWithBody, genericGetWithParameters } from "./Networking";

export const getListOfMessages = async () => {
    const userID = localStorage.getItem('UserID');
    try {
      const params = {
        UserId: userID
      };
      const response = await genericGetWithParameters('Message/Get', params);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

export const markMessageAsRead = async (messageId) => {
  try {
    const params = {
      MessageId: messageId
    };
    const response = await genericCallWithBody('POST', 'Message/DismissMessage', params);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getReplyHistoryByMessageID = async (messageID) => {
  try {
    const params = {
      'MessageID': messageID
    }
    const response = await genericGetWithParameters('Message/GetReplyHistoryByMessageID', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import { formatDate, formatStringToBool, formatValidInput, formatValidSelect, getScaleByCurrency } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
import { getLookUpTable } from '../../../../networking/Networking';
import { getRiskScoreDetailsByEntity, getRiskScoreRanges } from '../../../../networking/NetworkingCustomers';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { getContactRiskScore } from '../../../../networking/NetworkingRiskFactors';
import { getStyle, notFilledInputClass, regularInputClass } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import DatePicker from '../../../shared/DatePicker';
import GlobalWatchListsResultDialog from '../../../shared/Dialogs/GlobalWatchListsResultDialog';
import DynamicSelect from '../../../shared/DynamicSelect';
import LoadingBox from '../../../shared/LoadingBox';
import NumberInput from '../../../shared/NumberInput';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';

const style = getStyle();


export default class ContactInformationForm extends React.Component {

    state = {
        contact: {
            contactID: 0,
            contactType: '',
            status: '',
            salutation: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNumber: '',
            extension: '',
            cellNumber: '',
            position1: '',
            tradeLimit: 0.0,
            tLWE: null,
            dailyTradeLimit: 0.0,
            dTLWE: null,
            dateOfBirth: '',
            preferredName: '',
            language: '',
            employerName: '',
            isMainContact: false,
            PEP: false,
            pepPosition: '',
            institutionType: '',
            appointmentDate: null,
            isAuthorized: false,
            bypassValidation: false,
            expiryDate: null,
            riskScore: 0,
            updateToken: 0,
            errors: []
        },
        disableInputs: false,
        referredBySelect: [],
        "Contact Type": [],
        "Contact Status ": [],
        "Salutation": [],
        "Languages": [],
        lookUpTables: [
            "Contact Type",
            "Contact Status ",
            "Salutation",
            "Languages"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        },
        watchListResults: [],
        nameToSearch: '',
        showAlertWatchList: false,
        showWatchListsResult: false,
        showContactRiskCalculation: false,
        riskScoreDetails: [],
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        validations: {
            contactType: true,
            firstName: true,
            lastName: true,
            status: true,
            language: true,
        },
        temp: '',
        loading: false,
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency),

        fontWeight                          : 'bolder', //riskscore
        backgroundColorRiskScore            : '#1d2b1d'
    }

    handleClearClick() {
        this.setState({
            contact: {
                contactID: '',
                contactType: '',
                status: '',
                salutation: '',
                firstName: '',
                middleName: '',
                lastName: '',
                phoneNumber: '',
                extension: '',
                cellNumber: '',
                position1: '',
                tradeLimit: '',
                tLWE: '',
                dailyTradeLimit: '',
                dTLWE: '',
                dateOfBirth: '',
                preferredName: '',
                language: '',
                employerName: '',
                isMainContact: false,
                PEP: false,
                pepPosition: '',
                institutionType: '',
                appointmentDate: '',
                isAuthorized: false,
                bypassValidation: false,
                expiryDate: '',
                riskScore: 0,
                updateToken: 0,
            }
        });
    }

    componentDidMount() {
        
        this.setState({ loading: true });

        var backgroundColorRiskScore =  localStorage.getItem('BackgroundColorRiskScore');
        if(backgroundColorRiskScore != null && backgroundColorRiskScore != '')
        {
            this.setState({backgroundColorRiskScore: backgroundColorRiskScore});
        }

        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ [this.state.lookUpTables[i]]: table });
                    }
                }
            })
        /*getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreDetails(this.props.customerID).then(
                    (details) => {
                        if (details !== undefined) {
                            getCustomerRiskScore(this.props.customerID).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        this.setState({
                                            riskScore: riskScore,
                                            riskScoreDetails: details.riskScoreDetails,
                                            loading: false
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );*/
        this.props.isValid(this.validateRequiredInputs());
    }

    loadContactRisks = (contactId) => {
        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreDetailsByEntity(contactId, "Contact").then(
                    (details) => {
                        if (details !== undefined) {
                            getContactRiskScore(contactId).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        this.setState({
                                            riskScore: riskScore || 0,
                                            riskScoreDetails: details.riskScoreDetails,
                                            loading: false
                                        });
                                    }
                                    else
                                    {
                                        this.setState({
                                            loading: false
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedContact !== null && prevProps.selectedContact === null) {
            const contact = this.props.selectedContact;
            this.setState({
                contact: {
                    contactID: contact["Contact ID"],
                    contactType: contact["Contact Type"],
                    status: contact["Contact Status"],
                    salutation: contact["Salutation"],
                    firstName: contact["First Name"],
                    middleName: contact["Name"],
                    lastName: contact["Last Name"],
                    cellNumber: contact["Mobile Number"],
                    phoneNumber: contact["Phone Number"],
                    extension: contact["Phone Extension"],
                    position1: contact["Position"],
                    tradeLimit: contact["Trade Limit"],
                    tLWE: contact["Trade Limit Warning or Enforce"],
                    dailyTradeLimit: contact["Daily Trade Limit"],
                    dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                    dateOfBirth: contact["Date of Birth"],
                    preferredName: contact["Preferred Name"],
                    language: contact["Language"],
                    employerName: contact["Employer Name"],
                    isMainContact: contact["Main Contact"],
                    PEP: contact["Politically Exposed Person"],
                    pepPosition: contact["PEP Position"],
                    institutionType: contact["Institution Type"],
                    appointmentDate: contact["Appointment Date"],
                    isAuthorized: contact["Authorized Signing Officer"],
                    bypassValidation: contact["ByPass Validation"],
                    expiryDate: contact["Expiry ByPass Date"],
                    updateToken: contact["UpdateToken"]
                },
                validations: {
                    contactType: true,
                    firstName: true,
                    lastName: true,
                    status: true,
                    language: true,
                }
            }, () => {
                this.props.isValid(this.validateRequiredInputs());
                this.loadContactRisks(contact["Contact ID"])
            })
        } else if (this.props.fromAudit ||(this.props.selectedContact !== null && prevProps.selectedContact !== null && this.props.selectedContact.UpdateToken !== prevProps.selectedContact.UpdateToken  )) {
            const contact = this.props.selectedContact;
            this.setState({
                contact: {
                    contactID: contact["Contact ID"],
                    contactType: contact["Contact Type"],
                    status: contact["Contact Status"],
                    salutation: contact["Salutation"],
                    firstName: contact["First Name"],
                    middleName: contact["Name"],
                    lastName: contact["Last Name"],
                    cellNumber: contact["Mobile Number"],
                    phoneNumber: contact["Phone Number"],
                    extension: contact["Phone Extension"],
                    position1: contact["Position"],
                    tradeLimit: contact["Trade Limit"],
                    tLWE: contact["Trade Limit Warning or Enforce"],
                    dailyTradeLimit: contact["Daily Trade Limit"],
                    dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                    dateOfBirth: contact["Date of Birth"],
                    preferredName: contact["Preferred Name"],
                    language: contact["Language"],
                    employerName: contact["Employer Name"],
                    isMainContact: contact["Main Contact"],
                    PEP: contact["Politically Exposed Person"],
                    pepPosition: contact["PEP Position"],
                    institutionType: contact["Institution Type"],
                    appointmentDate: contact["Appointment Date"],
                    isAuthorized: contact["Authorized Signing Officer"],
                    bypassValidation: contact["ByPass Validation"],
                    expiryDate: contact["Expiry ByPass Date"],
                    updateToken: contact["UpdateToken"]
                },
                loading: false,
                validations: {
                    contactType: true,
                    firstName: true,
                    lastName: true,
                    status: true,
                    language: true,
                }
            }, () => {
                this.props.isValid(this.validateRequiredInputs());
                if(this.props.updateFromAudit)
                {
                    this.props.updateFromAudit();
                }
                //this.loadContactRisks(contact["Contact ID"])
            })
        }
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.handleClearClick();
        }
        /*console.log(this.props.saveButton);
        if(this.props.saveButton !== {} && prevProps.saveButton !== this.props.saveButton){
            console.log(this.props.saveButton);
            if(this.props.saveButton){
                this.handleSaveClick();
            }
        }*/
    }

    /*handleSaveClick(){
        var date = new Date();
        let userID = localStorage.getItem('UserID');
        if(this.validateRequiredInputs()){
            this.props.isValid();
            var model = {
                ContactID: this.state.contact.contactID,
                CustomerID: this.props.customerID,
                MainContact: this.state.contact.isMainContact,
                Position: this.state.contact.position1,
                TradeLimit: this.state.contact.tradeLimit,
                DailyTradeLimit: this.state.contact.dailyTradeLimit,
                //PersonID: this.props.selectedContact.PersonID,
                BirthdayDate: this.state.contact.dateOfBirth,
                PoliticallyExposedPerson: this.state.contact.PEP,
                AuthorizedSigningOfficer: this.state.contact.isAuthorized,
                PreferredName: this.state.contact.preferredName,
                CreatedBy: userID,
                CreatedOn: date.toISOString(),
                ContactType: this.state.contact.contactType,
                OnlineLanguage: this.state.contact.language,
                PEPPosition: this.state.contact.pepPosition,
                EmployerName: this.state.contact.employerName,
                AppointmentDate: this.state.contact.appointmentDate,
                InstitutionType: this.state.contact.institutionType,
                Status: this.state.contact.status,
                ExpiryByPassDate: this.state.contact.expiryDate,
                ByPassValidation: this.state.contact.bypassValidation,
                TradeLimitWarningOrEnforce: this.state.contact.tLWE,
                DailyTradeLimitWarningOrEnforce: this.state.contact.dTLWE,
                UpdateToken: this.state.contact.updateToken,
                Name: this.state.contact.middleName,
                FirstName: this.state.contact.firstName,
                LastName: this.state.contact.lastName,
                Salutation: this.state.contact.salutation
            }
            this.props.getContactInfo(model);
            this.props.returnButtonState();
        }
    }*/

    toggleShowContactRiskCalculation() {
        this.setState({ showContactRiskCalculation: !this.state.showContactRiskCalculation });
    }

    handleOnCheckMainContact(event) {
        /*let mainContact = event.target.checked;
        let selectedBeneficiaryID = this.state.selectedBeneficiaryID;
        if(!mainContact){
            selectedBeneficiaryID = 0;            
        }*/
        this.setState({
            contact: { ...this.state.contact, isMainContact: event.target.checked }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleOnCheckAuthorized(event) {
        this.setState({
            contact: { ...this.state.contact, isAuthorized: event.target.checked }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleOnCheckBypassValidation(event) {
        this.setState({
            contact: { ...this.state.contact, bypassValidation: event.target.checked, expiryDate: '' }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleRadioPEPChange(event) 
    {
        const newContact = { ...this.state.contact };

        switch (event.target.value) 
        {
            default:
            case 'true':
            {
                newContact.PEP = formatStringToBool(event.target.value);
                break;
            }
            case 'false':
            {
                newContact.PEP = formatStringToBool(event.target.value);
                newContact.pepPosition = '';
                newContact.institutionType = '';
                newContact.appointmentDate = '';
                
                break;
            }
        }

        this.setState({
            contact: newContact
        }, () => this.props.getContactInfo(this.state.contact));
        
    }

    getContactTypeSelect(value) {
        if (value.value !== undefined) {
            this.setState({
                contact: { ...this.state.contact, contactType: value.LookUpTableDetail },
                validations: { ...this.state.validations, contactType: true }
            },
                () => {
                    if (this.validateRequiredInputs()) {
                        this.props.getContactInfo(this.state.contact);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                });
        } else {
            this.setState({ contact: { ...this.state.contact, contactType: '' } },
                () => this.props.getContactInfo(this.state.contact));
        }
    }

    getStatusSelect(value) {
        if (value.value !== undefined) {
            this.setState({
                contact: { ...this.state.contact, status: value.LookUpTableDetail },
                validations: { ...this.state.validations, status: true }
            },
                () => {
                    if (this.validateRequiredInputs()) {
                        this.props.getContactInfo(this.state.contact);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                });
        } else {
            this.setState({ contact: { ...this.state.contact, status: '' } },
                () => this.props.getContactInfo(this.state.contact));
        }
    }

    getSalutationSelect(value) {
        this.setState({ contact: { ...this.state.contact, salutation: value.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    getLanguageSelect(value) {
        if (value.value !== undefined) {
            this.setState({
                contact: { ...this.state.contact, language: value.LookUpTableDetail },
                validations: { ...this.state.validations, language: true }
            }, () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
        } else {
            this.setState({ contact: { ...this.state.contact, language: '' }, },
                () => this.props.getContactInfo(this.state.contact));
        }
    }

    handleUpdateFirstName(value) {
        this.setState({
            contact: { ...this.state.contact, firstName: value.target.value },
            validations: { ...this.state.validations, firstName: true }
        },
            () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
    }

    handleUpdateMiddleName(value) {
        this.setState({ contact: { ...this.state.contact, middleName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateLastName(value) {
        this.setState({
            contact: { ...this.state.contact, lastName: value.target.value },
            validations: { ...this.state.validations, lastName: true }
        },
            () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
    }

    handleUpdatePhoneNumber = (phone) => {
        this.setState({ contact: { ...this.state.contact, phoneNumber: phone } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateExtension(value) {
        this.setState({ contact: { ...this.state.contact, extension: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateCellNumber = (phone) => {
        this.setState({ contact: { ...this.state.contact, cellNumber: phone } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePosition1(value) {
        this.setState({ contact: { ...this.state.contact, position1: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateTradeLimit(value) {
        this.setState({ contact: { ...this.state.contact, tradeLimit: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateTLWE(value) {
        if (value !== undefined && value !== 'select' && value.target.value !== '') {
            this.setState({ contact: { ...this.state.contact, tLWE: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
        }
        else{
            this.setState({ contact: { ...this.state.contact, tLWE: null } }, () => this.props.getContactInfo(this.state.contact));
        }
    }

    handleUpdateDailyTradeLimit(value) {
        this.setState({ contact: { ...this.state.contact, dailyTradeLimit: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateDTLWE(value) {
        if (value !== undefined && value !== 'select' && value.target.value !== '') {
            this.setState({ contact: { ...this.state.contact, dTLWE: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
        }
        else{
            this.setState({ contact: { ...this.state.contact, dTLWE: null } }, () => this.props.getContactInfo(this.state.contact));
        }
    }

    handleUpdateDateOfBirth(date) {
        this.setState({ contact: { ...this.state.contact, dateOfBirth: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePreferredName(value) {
        this.setState({ contact: { ...this.state.contact, preferredName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateEmployerName(value) {
        this.setState({ contact: { ...this.state.contact, employerName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePEPPosition(value) {
        this.setState({ contact: { ...this.state.contact, pepPosition: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateInstitutionType(value) {
        this.setState({ contact: { ...this.state.contact, institutionType: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateAppointmentDate(date) {
        this.setState({ contact: { ...this.state.contact, appointmentDate: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleExpiryBypassDate(date) {
        this.setState({ contact: { ...this.state.contact, expiryDate: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    formatValidDatePicker(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }
    validateRequiredFields() {
        const fields = [
            { displayName: 'Contact Type', validateName: 'contactType', value: this.state.contact.contactType, type: 'string' },
            { displayName: 'First Name', validateName: 'firstName', value: this.state.contact.firstName, type: 'string' },
            { displayName: 'Last Name', validateName: 'lastName', value: this.state.contact.lastName, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.contact.status, type: 'string' },
            { displayName: 'Language', validateName: 'language', value: this.state.contact.language, type: 'string' }
        ]
        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'Contact Type', validateName: 'contactType', value: this.state.contact.contactType, type: 'string' },
            { displayName: 'First Name', validateName: 'firstName', value: this.state.contact.firstName, type: 'string' },
            { displayName: 'Last Name', validateName: 'lastName', value: this.state.contact.lastName, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.contact.status, type: 'string' },
            { displayName: 'Language', validateName: 'language', value: this.state.contact.language, type: 'string' }
        ]

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }
    
    searchContactInGlobalWatchLists = async (event) => {
        event.preventDefault()
        const textToSearch = `${this.state.contact.firstName} ${this.state.contact.lastName}`;
        const json = await searchInGlobalWatchLists(textToSearch, 'Contact', this.state.contact.contactID);
        if (json != null && json.MatchedWords.length === 0 && json.SourceNames.length === 0) {
            this.setState({ showAlertWatchList: true, alertTitle: 'Info', alertMessage: 'No results found.', nameToSearch: this.state.contact.lastName + ', ' + this.state.contact.firstName, watchListResults: [] });
        } else {
            this.setState({ showAlertWatchList: true, alertTitle: 'Warning', alertMessage: 'Found Contact in Watch Lists', nameToSearch: this.state.contact.lastName + ', ' + this.state.contact.firstName, watchListResults: json.SourceNames });
        }
    }
    
    closeAlertWatchlist = () => {
        this.setState({ showAlertWatchList: false, showWatchListsResult: true });
    }

    closeWatchListResult = () => {
        this.setState({ showWatchListsResult: false });
        if (this.props.refreshContact != null) {
            this.props.refreshContact();
        }
    }

    render() 
    {
        return (
            <div className="">
                <ul id="accordion-contacts" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">General Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="contact-general-information">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Contact Type (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id="contactType-list" className={formatValidSelect(this.state.validations.contactType)} getValue={this.getContactTypeSelect.bind(this)} listHasPlaceholder={true} data={this.state["Contact Type"]} objValue={{ value: this.state.contact.contactType, LookUpTableDetail: this.state.contact.contactType }} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id="status-list" className={formatValidSelect(this.state.validations.status)} getValue={this.getStatusSelect.bind(this)} listHasPlaceholder={true} data={this.state["Contact Status "]} objValue={{ value: this.state.contact.status, LookUpTableDetail: this.state.contact.status }} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Salutation</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id="salutation-list" className="uk-select" getValue={this.getSalutationSelect.bind(this)} listHasPlaceholder={true} data={this.state["Salutation"]} objValue={{ value: this.state.contact.salutation, LookUpTableDetail: this.state.contact.salutation }} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>First Name (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className={formatValidInput(this.state.validations.firstName)} id="firstName" type="text" placeholder="" value={this.state.contact.firstName} onChange={this.handleUpdateFirstName.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-margin" style={{ marginBottom: '0px', paddingLeft: '20px' }}>
                                            <div className="uk-margin" style={{ paddingBottom: "5px", marginTop: '5px' }}>
                                                <button className="uk-button-green" onClick={this.searchContactInGlobalWatchLists.bind(this)} style={{ borderRadius: '10px' }}>✓</button>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Last Name (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className={formatValidInput(this.state.validations.lastName)} id="lastName" type="text" placeholder="" value={this.state.contact.lastName} onChange={this.handleUpdateLastName.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Preferred Name</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" id="preferredName" type="text" placeholder="" value={this.state.contact.preferredName} onChange={this.handleUpdatePreferredName.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Date of Birth</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DatePicker useNew className="uk-input" value={this.state.contact.dateOfBirth} onDayChange={this.handleUpdateDateOfBirth.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Phone Number</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                {/* <input className="uk-input" id="phoneNumber" type="text" placeholder="" value={this.state.contact.phoneNumber} onChange={this.handleUpdatePhoneNumber.bind(this)} /> */}
                                                <PhoneInput
                                                    key="phoneNumber"
                                                    inputProps  = {{
                                                        name        : 'phone',
                                                        required    : true,
                                                        autoFocus   : true
                                                    }}
                                                    enableSearch        = {true}
                                                    disableSearchIcon   = {true}
                                                    containerStyle      = {{
                                                        height: 30
                                                    }}
                                                    inputClass  = {'uk-input'}
                                                    inputStyle  = {{
                                                        height: 30,
                                                        borderRadius: 0,
                                                        width: '100%'
                                                    }}
                                                    buttonStyle = {{
                                                        borderColor: '#CACACA'
                                                    }}
                                                    placeholder = 'Enter a Phone Number'
                                                    autoFormat
                                                    copyNumbersOnly     = {true}
                                                    countryCodeEditable = {true}
                                                    value               = {this.state.contact.phoneNumber}
                                                    onChange            = {this.handleUpdatePhoneNumber}
                                                    preferredCountries  = {['gb']}
                                                    searchPlaceholder   = 'Search a country'
                                                    autocompleteSearch/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Extension</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" id="extension" type="text" placeholder="" value={this.state.contact.extension} onChange={this.handleUpdateExtension.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Cell Number</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <PhoneInput
                                                    id="cellNumber"
                                                    key="phoneNumber"
                                                    inputProps  = {{
                                                        name        : 'cellNumber',
                                                        required    : true,
                                                        autoFocus   : true
                                                    }}
                                                    enableSearch        = {true}
                                                    disableSearchIcon   = {true}
                                                    containerStyle      = {{
                                                        height: 30
                                                    }}
                                                    inputClass  = {'uk-input'}
                                                    inputStyle  = {{
                                                        height: 30,
                                                        borderRadius: 0,
                                                        width: '100%'
                                                    }}
                                                    buttonStyle = {{
                                                        borderColor: '#CACACA'
                                                    }}
                                                    placeholder = 'Enter a Cell Number'
                                                    autoFormat
                                                    copyNumbersOnly     = {true}
                                                    countryCodeEditable = {true}
                                                    value               = {this.state.contact.cellNumber}
                                                    onChange            = {this.handleUpdateCellNumber}
                                                    preferredCountries  = {['gb']}
                                                    searchPlaceholder   = 'Search a country'
                                                    autocompleteSearch/>
                                                {/* <input className="uk-input" id="cellNumber" type="text" placeholder="" value={this.state.contact.cellNumber} onChange={this.handleUpdateCellNumber.bind(this)} /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Position</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" id="position1" type="text" placeholder="" value={this.state.contact.position1} onChange={this.handleUpdatePosition1.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Language (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id="language-list" className={formatValidSelect(this.state.validations.language)} getValue={this.getLanguageSelect.bind(this)} listHasPlaceholder={true} data={this.state["Languages"]} objValue={{ value: this.state.contact.language, LookUpTableDetail: this.state.contact.language }} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Risk Score (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                            <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Risk Calculation Contact'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        height          : 30,
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : this.state.backgroundColorRiskScore
                                    }}
                                >
                                    <label 
                                        onClick     = { this.toggleShowContactRiskCalculation.bind(this)}
                                        className   =""
                                        style       = {{ color: this.state.riskScore['Color Hex'], fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                        id          = "ContactRiskScore"
                                    >{this.state.riskScore.Description}
                                    </label>
                                </div>
                            </Badge>
                                               </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                                            <label className="uk-form-label" >
                                                <input className="uk-checkbox" type="checkbox" checked={this.state.contact.isMainContact} value={this.state.contact.isMainContact} onChange={this.handleOnCheckMainContact.bind(this)} />
                                                Main Contact
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Compliance</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="contact-compliance">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                                            <label className="uk-form-label" >
                                                <input className="uk-checkbox" type="checkbox" checked={this.state.contact.bypassValidation} value={this.state.contact.bypassValidation} onChange={this.handleOnCheckBypassValidation.bind(this)} />
                                                Bypass Validation
                                            </label>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Expiry Bypass Date</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DatePicker useNew className="uk-input" disabled={!this.state.contact.bypassValidation} value={this.state.contact.expiryDate} onDayChange={this.handleExpiryBypassDate.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                                            <label className="uk-form-label" style={{ width: "300px" }}>
                                                <input className="uk-checkbox" type="checkbox" checked={this.state.contact.isAuthorized} value={this.state.contact.isAuthorized} onChange={this.handleOnCheckAuthorized.bind(this)} />
                                            Authorized Signing Officer Contact
                                            </label>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                                            <label className="uk-form-label">Politically Exposed Person</label>
                                            <label className="uk-margin-right"><input value={false} className="uk-radio" type="radio" name="radioPEP" onChange={this.handleRadioPEPChange.bind(this)} checked={this.state.contact.PEP === false} /> No  </label>
                                            <label className=""><input value={true} className="uk-radio" type="radio" name="radioPEP" onChange={this.handleRadioPEPChange.bind(this)} checked={this.state.contact.PEP === true} /> Yes</label>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Employer Name</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" id="employerName" type="text" placeholder="" value={this.state.contact.employerName} onChange={this.handleUpdateEmployerName.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked" style={{paddingLeft: 60, paddingRight: 20}}>
                                            <label className="uk-form-label" >Position</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input disabled={this.state.contact.PEP === true? false: true} className="uk-input" id="pepPosition" type="text" placeholder="" value={this.state.contact.pepPosition} onChange={this.handleUpdatePEPPosition.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked"></div>
                                        <div className="uk-width-1-2 uk-form-stacked" style={{paddingLeft: 60, paddingRight: 20}}>
                                            <label className="uk-form-label" >Institution Type</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input disabled={this.state.contact.PEP === true? false: true} className="uk-input" id="institutionType" type="text" placeholder="" value={this.state.contact.institutionType} onChange={this.handleUpdateInstitutionType.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked"></div>
                                        <div className="uk-width-1-2 uk-form-stacked" style={{paddingLeft: 60, paddingRight: 20}}>
                                            <label className="uk-form-label" >Appointment Date</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DatePicker useNew disabled={this.state.contact.PEP === true? false: true} className="uk-input" value={this.state.contact.appointmentDate} onDayChange={this.handleUpdateAppointmentDate.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Trade Limit</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="contact-trade-limit">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Trade Limit</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className="uk-input" placeholder="0" id="tradeLimit" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.contact.tradeLimit} onChange={this.handleUpdateTradeLimit.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" > </label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <select className="uk-select" value={this.state.contact.tLWE} onChange={this.handleUpdateTLWE.bind(this)}>
                                                    <option value="">Select an item...</option>
                                                    <option value="Warning">Warning</option>
                                                    <option value="Enforce">Enforce</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" >Daily Trade Limit</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className="uk-input" placeholder="0" id="dailyTradeLimit" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.contact.dailyTradeLimit} onChange={this.handleUpdateDailyTradeLimit.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" > </label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <select className="uk-select" value={this.state.contact.dTLWE} onChange={this.handleUpdateDTLWE.bind(this)}>
                                                    <option value="" >Select an item...</option>
                                                    <option value="Warning">Warning</option>
                                                    <option value="Enforce">Enforce</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    {/*<li className="">
                        <h5 className="uk-accordion-title">Employer Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="contact-employer">
                                <form className="uk-form-horizontal">
                                    
                                </form>
                            </div>
                        </div>
                    </li>*/}
                </ul>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox
                    open={this.state.showAlertWatchList}
                    onClose={this.closeAlertWatchlist}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage} type="Ok" okClick={this.closeAlertWatchlist} />
                <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails} open={this.state.showContactRiskCalculation} close={this.toggleShowContactRiskCalculation.bind(this)}
                    entityType={"Contact"} />
                {this.state.showWatchListsResult && <GlobalWatchListsResultDialog
                    open={this.state.showWatchListsResult}
                    onClose={this.closeWatchListResult}
                    nameToSearch={this.state.nameToSearch}
                    resultList={this.state.watchListResults}
                    entityID={this.state.contact.contactID}
                    entityType='Contact'
                />}
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }

}
import jwt from 'jsonwebtoken';
import {genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted, genericDeleteWithParameters} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getAllFees(description,page,items){
    try {
        let params = {
            'Description': description,
            'Page': page,
            'Items': items,
        }

        let response = await genericGetWithParameters('Fees/GetFeesByDescription', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeeById(feeStructureId){
    try {
        let params = {
            'FeeStructureId': feeStructureId
        }

        let response = await genericGetWithParameters('Fees/GetFeesByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function addFee(model) {
    try {
        let response = await genericCallWithBody('POST', 'Fees/SaveFee', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function calculateWireItemFee(customerID, settlementCurrency, foreignCurrency, amount, customerRate, instrument, deliveryMethod){
    try {
        let params = {customerID: customerID,
            settlementCurrency: settlementCurrency,
            foreignCurrency: foreignCurrency,
            amount: amount,
            customerRate: customerRate,
            instrument: instrument,
            deliveryMethod: deliveryMethod,
        };
        let response = await genericGetWithParameters('Fees/CalculateWireItemFee', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteFee(feeStructureId){    
    try{
        let params = {
            'feeStructureId': feeStructureId
        };
        let response = await genericDeleteWithParameters('Fees/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
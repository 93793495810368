import { IgrButton } from 'igniteui-react-inputs';
import React from 'react';
import DynamicSelect from '../shared/DynamicSelect'

export default class BoardParameters extends React.Component {


    state = {
        value: 'R',
        query: '',
        items: [],
        parameters: []
    }

    
    componentDidMount() {
        if(this.props.value !== null)
        {
            this.loadItems(this.props.value);
        }
    }

    componentDidUpdate(prevProps, prevState) 
    {
        if(this.props.value !== prevProps.value)
        {
            this.loadItems(this.props.value);
        }
    }

    Selected = (obj) => {
        if (obj !== undefined && obj.controlID !== undefined) {
            let index = obj.controlID.indexOf("-");
            let name = obj.controlID.substring(index + 1);
            let parameters = []
            let parameter = {name: name, value: obj.value}
            if(this.state.parameters !== undefined && this.state.parameters.length > 0){
                if(this.state.parameters.length > 1){
                    let tempParameter = {}
                    for(var i=0; i< this.state.parameters.length; i++){
                        if( this.state.parameters[i].name === name){
                            tempParameter = {name: this.state.parameters[i].name, value: obj.value}
                        } else {
                            tempParameter = {name: this.state.parameters[i].name, value: this.state.parameters[i].value}
                        }
                        parameters.push(tempParameter);
                    }
                } else {
                    parameters.push({name: this.state.parameters[0].name, value: this.state.parameters[0].value});
                    parameters.push(parameter);
                }
                
            }
            else{
                parameters.push({name: name, value: obj.value})
            }
            this.setState({ parameters: parameters });
        }
    }

    onSearchClick = (event) => {
        if (this.props.onSearchClick) {
            this.props.onSearchClick(this.state.parameters);
        }
    }

    loadItems(json){
        let items = []; 
        if (json !== undefined && json !== null && json !=='') {
            let json2 = JSON.parse(json);
            let parameters = json2.parameters;
            for (var c = 0; c < parameters.length; c++) {
                if(parameters[c].type==='dropdown'){            
                    let data = [];
                    for(var i = 0; i< parameters[c].values.length; i++) {                        
                        data.push({value: parameters[c].values[i], name: parameters[c].values[i]});
                    }
                    /*data.forEach(x => {
                        x.value = x
                        x.name = x
                    });*/
                    
                    //let data = [{ value: 'ERROR', name: 'ERROR' }, { value: 'UNSEND', name: 'UNSEND' }, { value: 'SEND', name: 'SEND' }]
                    
                    items.push(<label className="uk-form-label" htmlFor={'select-'+ parameters[c].name}>{parameters[c].name}</label>)
                    items.push(
                    <DynamicSelect id={'select-'+ parameters[c].name}
                                placeholder='Select an option...'
                                data={data}
                                //objValue={{ value: 'Select an option...' }}
                                getValue={this.Selected}/>)
                }
            }
        }
        items.push(<button className="uk-button uk-button-green" onClick={this.onSearchClick}>Search</button>)
        this.setState({items: items})
    }

    render(){
        return(
            <div>{this.state.items}</div>
        );
    }
}
import React from 'react';

import { BoardName } from '../../../constants/BoardNames';

import GenericBoard from '../../shared/GenericBoard';

import { getStyle } from '../../../styles/styles';

var style = getStyle();

export default class HeldFundsBoardScreen extends React.Component {

    state = {
        showTitle: true
    }
    componentDidMount() {

    }

    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }
    //render
    render() {
        return (
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Held Funds Board</h3>}
                <GenericBoard board={BoardName.HeldFundsBoard} mainTitle={"Held Funds Board"} isShowTitle={this.isShowTitle} selectSection/>
            </div>
        )
    }

}
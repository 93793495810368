/* eslint-disable default-case */
import React from 'react';

import BusinessHoursForm from './BusinessHoursForm';

import { getLookUpTable } from '../../../../networking/Networking';
import { getInstrumentsByCountry, saveInstrumentsByCountry } from '../../../../networking/NetworkingInstruments';

import DynamicSelect from '../../../shared/DynamicSelect';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import {Colors} from '../../../../constants/Colors'

export default class InstrumentsScreen extends React.Component {
    state = {
        loading: false,
        errorMessage: '',
        showErrorAlert: false,

        //Instrumens
        listOfInstrumentType        : [
            {value:'Incoming' , name:'Incoming'},
            {value:'Outgoing' , name: 'Outgoing'}
          ],
        selectedIDRowLeftInstrument : '',   //Seleccionado de lista Izquierd2
        selectedIDRowRightInstrument: '',   //Seleccionado de lista Derecha2
        instrumentListHaveShow      : [],   
        instrumentListHaveIncoming  : [],
        instrumentListHaveOutgoing  : [],
        instrumentListWantShow      : [],
        instrumentListWantIncoming  : [],
        instrumentListWantOutgoing  : [],
    }

    componentDidMount = () => {

        this.setState({
            instrumentTypeSelected: 'Incoming'
        })

        var hasInstruments = false

        //Para obtener los [Instruments] del tipo [Incoming] y [Outgoing]
        getInstrumentsByCountry(this.props.countryID).then(
            (jsonResponseGetInstrumentByCustomer) =>{

                if(jsonResponseGetInstrumentByCustomer.instruments!== undefined){

                    hasInstruments = true

                    this.setState({
                        instrumentListByCustomer: jsonResponseGetInstrumentByCustomer.instruments
                    });

                    var listInstrumentsByCustomer = jsonResponseGetInstrumentByCustomer.instruments

                    var listOfIncoming =  listInstrumentsByCustomer.filter(function(instrument){
                        return instrument.InstrumentType == "Incoming";
                    });

                    var listOfOutcoming =  listInstrumentsByCustomer.filter(function(instrument){
                        return instrument.InstrumentType == "Outgoing";
                    });

                    if(hasInstruments === true)
                    {
                        this.getInOutINstruments('Incoming Instruments', listOfIncoming);
                        this.getInOutINstruments('Outgoing Instruments', listOfOutcoming);
                    }

                    this.setState({
                        instrumentListWantIncoming : listOfIncoming,
                        instrumentListWantOutgoing : listOfOutcoming,
                    });
                    
                }
            }
        );

        if(hasInstruments == false)
        {
            this.getInOutINstruments('Incoming Instruments', []);
            this.getInOutINstruments('Outgoing Instruments', []);
        }
    }

    getInOutINstruments = async (description, wantlist) => {
        getLookUpTable(description).then(
         (json) => {
             var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
             var helper = [];
             for(var i=1; i<lookUpTableDetails.length; i++){
                 lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                 lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                 helper.push(lookUpTableDetails[i]);
             }
             // eslint-disable-next-line default-case
             switch(description){
                 case 'Incoming Instruments':
                 {
                     //var wantlistIncoming = this.state.instrumentListWantIncoming
                     for( var i = helper.length - 1; i >= 0; i--)
                     {
                         for( var j = 0; j<wantlist.length; j++)
                         {
                             if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                             {
                                 helper.splice(i, 1);
                             }
                         }
                     }
                     this.setState({
                         instrumentListHaveIncoming: helper,
                     });
                     break;
                 }
                 case 'Outgoing Instruments':
                 {
                     //var wantlistOutgoing = this.state.instrumentListWantOutgoing
                     for( var i = helper.length - 1; i >= 0; i--)
                     {
                         for( var j = 0; j<wantlist.length; j++)
                         {
                             if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                             {
                                 helper.splice(i, 1);
                             }
                         }
                     }
                     this.setState({
                         instrumentListHaveOutgoing: helper
                     });
                     break;
                 }
             }
         }
         );
     }

    onchangeValueOfInstrumentType = (typeSelected) =>
    {
        if(typeSelected != undefined)
        {
            this.setState({
                instrumentTypeSelected: typeSelected.value
            });            
        }
    }

    // Instruments
    instrumentChangeColorLeft = selectedIDRowLeftInstrument => e => {
        if (selectedIDRowLeftInstrument !== undefined) {
          this.setState({ selectedIDRowLeftInstrument  });
        }
    };

    instrumentChangeColorRight = selectedIDRowRightInstrument => e => {
        if (selectedIDRowRightInstrument !== undefined) {
          this.setState({ selectedIDRowRightInstrument  });
        }
    };

    instrumentAddItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }
        
        var idSelected = this.state.selectedIDRowLeftInstrument;
        var add = true

        //{LookUpTableDetail: "Transfer in", Field4: "", value: "Transfer in", name: "Transfer in"}
        //{CustomerInstrumentId: 82, InstrumentType: "Incoming", Instrument: "Transfer in"}

        var newIncomingToAdd = {
            CustomerInstrumentId: 0, 
            InstrumentType      : this.state.selectedIDRowLeftInstrument, 
            Instrument          : ""
        }
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((instrument, index) => {
                    if(instrument.name == idSelected)
                    {
                        newIncomingToAdd['Instrument'] = instrument.name
                        listRight.push(newIncomingToAdd)
                        listLeft.splice(index,1)
                    }

                    switch(this.state.instrumentTypeSelected)
                    {
                        case 'Incoming':
                        {
                            this.setState({
                                instrumentListWantIncoming: listRight,
                                instrumentListHaveIncoming: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;
                        }
                        case 'Outgoing': 
                        {
                            this.setState({
                                instrumentListWantOutgoing:  listRight,
                                instrumentListHaveOutgoing: listLeft,
                                selectedIDRowLeftInstrument: ''
                            });
                            break;
                        }
                    }
                });
                
            }else
            {
                listRight.forEach(rightInstrument => {
                    if(rightInstrument.Instrument == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((lefInstrument, index) => {
                            if(lefInstrument.name == idSelected)
                            {
                                newIncomingToAdd['Instrument'] = lefInstrument.name
                                listRight.push(newIncomingToAdd)
                                listLeft.splice(index,1)

                                switch(this.state.instrumentTypeSelected)
                                {
                                    case 'Incoming':
                                    {
                                        this.setState({
                                            instrumentListWantIncoming: listRight,
                                            instrumentListHaveIncoming: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                    case 'Outgoing': 
                                    {
                                        this.setState({
                                            instrumentListWantOutgoing:  listRight,
                                            instrumentListHaveOutgoing: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }

    instrumentRemoveItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        var idSelected = this.state.selectedIDRowRightInstrument
        var add = true

        //{LookUpTableDetail: "Transfer in", Field4: "", value: "Transfer in", name: "Transfer in"}
        //{CustomerInstrumentId: 82, InstrumentType: "Incoming", Instrument: "Transfer in"}

        var oldIncomingToAdd = {
            LookUpTableDetail   : "", 
            Field4              : "Incoming", 
            value               : "",
            name                : ""
        }

        if(idSelected != '')
        {
            listRight.forEach((rightInstrument, index) => {
                if(rightInstrument.Instrument == idSelected)
                {
                    oldIncomingToAdd.LookUpTableDetail = rightInstrument.Instrument
                    oldIncomingToAdd.value = rightInstrument.Instrument
                    oldIncomingToAdd.name = rightInstrument.Instrument
                    listRight.splice(index,1)
                    listLeft.push(oldIncomingToAdd)
                }
            });

            switch(this.state.instrumentTypeSelected)
            {
                case 'Incoming':
                    {this.setState({
                        instrumentListWantIncoming: listRight,
                        instrumentListHaveIncoming: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
                case 'Outgoing': 
                    {this.setState({
                        instrumentListWantOutgoing:  listRight,
                        instrumentListHaveOutgoing: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
            }
        }
    }

    instrumentAddAllItems = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        var newListLeft = []
        listLeft.forEach((lefInstrument, index) => {
            var newIncomingToAdd = {
                CustomerInstrumentId: 0, 
                InstrumentType      : this.state.instrumentTypeSelected, 
                Instrument          : lefInstrument.name
            }
            newListLeft.push(newIncomingToAdd)
        })
        
        Array.prototype.push.apply(listRight,newListLeft)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming  : listRight,
                    instrumentListHaveIncoming  : []
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  listRight,
                    instrumentListHaveOutgoing: []
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: ''
        })
    }
    
    instrumentRemoveAll = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }

        var newListRight = []
        listRight.forEach((rightInstrument, index) => {
            var oldInstrumentToAdd = {
                LookUpTableDetail   : rightInstrument.Instrument, 
                Field4              : this.state.instrumentTypeSelected, 
                value               : rightInstrument.Instrument,
                name                : rightInstrument.Instrument
            }
            newListRight.push(oldInstrumentToAdd)
        })

        Array.prototype.push.apply(listLeft,newListRight)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming:  [],
                    instrumentListHaveIncoming: listLeft,
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  [],
                    instrumentListHaveOutgoing: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: '',
        })
    }
    // --------------

    handleSaveClick = (event) => {
        event.preventDefault();
        var insList             = this.state.instrumentListWantIncoming;
        var outList             = this.state.instrumentListWantOutgoing;
        var InstrumentsJson     = [];

        for (var i = 0; i < insList.length; i++) {
            InstrumentsJson.push({
                'Instrument': insList[i].Instrument,
                'Type': 'Incoming'});
        }
        for (var i = 0; i < outList.length; i++) {
            InstrumentsJson.push({
                'Instrument': outList[i].Instrument,
                'Type': 'Outgoing'});
        }
        var objFilter={
            'CountryID': this.props.countryID,
            'InstrumentsJson': InstrumentsJson,
            'UserID': localStorage.getItem('UserID'),
        }
        saveInstrumentsByCountry(objFilter).then(
            (json)=>{
                console.log(json);
                if(json.httpStatusCode!==200){
                    this.setState({showAlert: true, alertTitle: 'An error occurred while trying to save the Country Instruments'})
                }else{
                    this.setState({showAlert: true, alertTitle:'Successfully', alertMessage: 'Country Instruments successfully saved.'});
                }
            }
        );
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    render(){
        var losQueHayAhoraIns  = []
        var losQueQuieroIns    = []

        // eslint-disable-next-line default-case
        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                //{LookUpTableDetail: "Transfer in", Field4: "", value: "Transfer in", name: "Transfer in"}
                losQueHayAhoraIns = this.state.instrumentListHaveIncoming
                losQueHayAhoraIns.sort(this.dynamicSort("name"));
                //{CustomerInstrumentId: 82, InstrumentType: "Incoming", Instrument: "Transfer in"}
                losQueQuieroIns = this.state.instrumentListWantIncoming
                losQueQuieroIns.sort(this.dynamicSort("Instrument"));
                break;
            }
            case 'Outgoing': 
            {
                losQueHayAhoraIns = this.state.instrumentListHaveOutgoing
                losQueHayAhoraIns.sort(this.dynamicSort("name"));
                losQueQuieroIns = this.state.instrumentListWantOutgoing
                losQueQuieroIns.sort(this.dynamicSort("Instrument"));
                break;
            }
        }

        return(
            <div>
                <div style={{ display: 'flex',}}>
                        <div className="uk-width-1-3">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-bank-status">Instrument Type</label>
                                <div className="uk-form-controls">
                                    {/*Falta aqui el DynamicSelect*/}
                                    <DynamicSelect 
                                        className       = "uk-select"
                                        objValue        = {'value'}
                                        getValue        = {this.onchangeValueOfInstrumentType} 
                                        listHasPlaceholder  = {false} 
                                        data            = {this.state.listOfInstrumentType}
                                        id              = 'select-instrument-type'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <h3 class="uk-width-1-3" style={{marginLeft: 20}}>Available Instruments</h3>
                        <h3 class="uk-width-1-3"></h3>
                        <h3 class="uk-width-1-3">Assigned Instruments</h3>
                    </div>
                    <div
                        className=""
                        style={{
                            //backgroundColor: 'red',
                            alignItems: 'center',
                            display: 'flex',
                            border:'1px solid #9DA99E'
                        }}
                    >
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                <tbody>
                                    {losQueHayAhoraIns.map((item, i) => {
                                        return (
                                        <tr 
                                            key={item.value} 
                                            onClick={this.instrumentChangeColorLeft(item.value)} 
                                            onDoubleClick={this.instrumentAddItemSelected.bind(this)}
                                            style={
                                                this.state.selectedIDRowLeftInstrument === item.value ? 
                                                {backgroundColor: Colors.primaryColor, cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'} 
                                            }
                                        >
                                            <td
                                                style={
                                                    this.state.selectedIDRowLeftInstrument === item.value ? 
                                                    {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                }
                                            >{item.LookUpTableDetail}</td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div 
                            className="uk-card-body uk-width-1-3"
                            style={{
                                //backgroundColor: 'blue',
                                textAlign:'center',
                                //width: '33%',
                                borderRight:'1px solid #9DA99E',
                                borderLeft:'1px solid #9DA99E'
                            }}
                        >
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.instrumentAddItemSelected.bind(this)}
                            >
                                Add
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.instrumentAddAllItems.bind(this)}
                            >
                                Add all
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.instrumentRemoveItemSelected.bind(this)}
                            >
                                Remove
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2" /*onClick={this.handleLoginClick.bind(this)}*/
                                style={{
                                    
                                }}
                                onClick={this.instrumentRemoveAll.bind(this)}
                            >
                                Remove all
                            </button>
                        </div>
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                paddingLeft: 0,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                    <tbody>
                                        {losQueQuieroIns.map((item, i) => {
                                                return (
                                                <tr 
                                                    key={item.Instrument} 
                                                    onClick={this.instrumentChangeColorRight(item.Instrument)} 
                                                    onDoubleClick={this.instrumentRemoveItemSelected.bind(this)}
    
                                                    style={
                                                        this.state.selectedIDRowRightInstrument === item.Instrument ? 
                                                        {backgroundColor: '#c3824a', cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'}
                                                }
                                                >
                                                    <td
                                                        style={
                                                            this.state.selectedIDRowRightInstrument === item.Instrument ? 
                                                            {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                    }
                                                    >{item.Instrument}</td>
                                                </tr>
                                                );
                                        })}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <button className="uk-button uk-button-green"style={{marginTop: 20}} onClick={this.handleSaveClick} >Save</button>
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }

}
import { genericGetWithParameters } from './Networking';

export const getUserDefinedPropertiesBySubEntityType = async (subEntityType, page, items) => {
    try {
        const params = {
            subEntityType: subEntityType,
            page: page,
            items: items
        };

        const response = await genericGetWithParameters('UserDefinedProperties/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
};

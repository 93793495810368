import React from 'react';

import NewDynamicTable from '../shared/NewDynamicTable';

import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { withStyles, responsiveFontSizes } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {getStyle} from '../../styles/styles';

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  

export default class DealEmailLogForm extends React.Component {

    state = {
        customerName: "",
        sentBy: "",
        sentDate: "",
        emailType: "",
        mainReceipt: "",
        ccRecipient: "",
        bccRecipient: "",
        listEmailLogs: [{
            "Sent On": "2020/05/08",
            "Sent By": "Admin",
            Type: "Confirmation"
        }]
    }

    handleBackLink = () => {
        this.setState({}, () => this.props.backLink());
    }

    render() { 
        return(
            <Dialog 
                open        ={this.props.open} 
                onClose     ={this.handleBackLink}
                fullWidth   ={true}
                maxWidth    ={"md"}
            >
                <DialogTitle 
                    id      = "customized-dialog-gwlatr-title"
                    onClose = {this.handleBackLink}
                >
                    Deal Email Log
                </DialogTitle>
                <DialogContent dividers style={{paddingLeft:24}} >
                    <div className="uk-grid">
                        {/*Line1*/}
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">Customer Name</label>
                            <input className="uk-input" type="text" value={this.state.customerName} readOnly/>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">Email type</label>
                            <input className="uk-input" type="text" value={this.state.emailType} readOnly/>
                        </div>
                        <div className="uk-width-1-3"> </div>
                        
                        {/*Line2*/}
                        <div className="uk-width-1-3">                        
                            <label className="uk-form-label">Sent By</label>
                            <input className="uk-input" type="text" value={this.state.sentBy} readOnly/>
                        </div>
                        <div className="uk-width-2-3"> </div>

                        {/*Line3*/}
                        <div className="uk-width-1-3">                        
                            <label className="uk-form-label">Sent Date</label>
                            <input className="uk-input" type="text" value={this.state.sentDate} readOnly/>
                        </div>
                        <div className="uk-width-2-3"> </div>

                        {/*Line4*/}
                        <div className="uk-width-1-3">       
                            <NewDynamicTable data={[{"Main Recipient":""}]}/>                 
                            </div>

                        <div className="uk-width-1-3">
                            <NewDynamicTable data={[{"C.C. Recipient":""}]}/>
                        </div>
                        <div className="uk-width-1-3">
                            <NewDynamicTable data={[{"B.C.C Recipient":""}]}/>
                        </div>

                        {/*Line5*/}
                        <div className="uk-width-1-1">
                            <h4>Email Logs</h4>
                            <NewDynamicTable data={this.state.listEmailLogs}/>
                        </div>

                        <div className="uk-width-1-1" style={{paddingTop:10}}>
                            <button 
                                className="uk-button uk-button-green uk-float-right" 
                                type="button" 
                                onClick={this.handleBackLink}
                            >Close</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );        
    }
}
import { Breadcrumb, Checkbox, Form, Input, Popconfirm, Select, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import AlertBox from '../../shared/AlertBox';
import useBusinessHours from './hooks/useBusinessHours';
import Text from 'antd/lib/typography/Text';
import { TextField } from '@material-ui/core';
import { updateBusinessHours } from '../../../networking/NetworkingAfterHours';

const NewBusinessHoursForm = (props) => {
    const [form] = Form.useForm();
    const plans = props.plans;
    const [businessHoursGrid, totalPages, businessHoursGridNeedsReloading] = useBusinessHours();

    const [currentPage, setCurrentPage] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [editingKey, setEditingKey] = useState('');
    const [currentEditingDay, setCurrentEditingDay] = useState('');
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');
    const [currentOpenHour, setCurrentOpenHour] = useState('');
    const [currentCloseHour, setCurrentCloseHour] = useState('');
    const [currentIsClosed, setCurrentIsClosed] = useState(false);
    const [currentPlanID, setCurrentPlanID] = useState('');

    const isEditing = (record) => record.Day === editingKey;

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log('Model');
            console.log(row);
            const model = {
                'Day': row['Day'],
                'OpenHour': row['Open Hour'],
                'CloseHour': row['Close Hour'],
                'PlanID': row['PlanID'],
                'Closed': row['Is Closed']
            };
            const json = await updateBusinessHours(model);
            if (json.httpStatusCode === 200) {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Business hour saved successfully.')
                setEditingKey('');
                setCurrentEditingDay('');
                setCurrentIsClosed(false);
                setCurrentOpenHour('');
                setCurrentCloseHour('');
                form.resetFields();
                businessHoursGridNeedsReloading(true);
            } else {
                setShowAlertError(true);
                setAlertTitle('Error');
                setAlertMessage('An error occurred while trying to update the business hour. Please try again.');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.Day);
        setCurrentEditingDay(record.Day);
        setCurrentUpdateToken(record['UpdateToken']);
        setCurrentOpenHour(record['Open Hour']);
        setCurrentCloseHour(record['Close Hour']);
        setCurrentIsClosed(record['Is Closed']);
        setCurrentPlanID(record['PlanID']);
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const onChangeHour = (event) => {
            if (dataIndex === 'Open Hour') {
                setCurrentOpenHour(event.target.value);
            } else {
                setCurrentCloseHour(event.target.value);
            }
            form.setFieldValue(dataIndex, event.target.value);
        };

        const onChangeIsClosed = (event) => {
            setCurrentIsClosed(event.target.checked);
            form.setFieldsValue({ 'Is Closed': event.target.checked });
        }

        const onChangePlan = (value) => {
            setCurrentPlanID(value);
            form.setFieldsValue({ 'PlanID': value });
        }

        let inputNode = null;
        switch (inputType) {
            case 'select': {
                inputNode = <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Plan..."
                                optionFilterProp="children"
                                value={currentPlanID}
                                onChange={onChangePlan}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Select.Option key={`BusinessHours-option-select`} value=''>Select a Plan...</Select.Option>
                                {plans.map((plan) => (
                                    <Select.Option key={`BusinessHours-option-${plan.value}`} value={plan.value}>{plan.name}</Select.Option>
                                ))}
                            </Select>
                break;
            }
            case 'check': {
                inputNode = <Checkbox onChange={(event) => onChangeIsClosed(event)} checked={currentIsClosed} />;
                break;
            }
            case 'time': {
                let value = form.getFieldValue(dataIndex);
                inputNode = <TextField id={record.Key} type="time" InputLabelProps={{ shrink: true }} 
                                InputProps={{ style: { color: '#666' }}}
                                inputProps={{ step: 60 }}
                                value={value}
                                onChange={((event) => onChangeHour(event))}
                                disabled={currentIsClosed}
                            />;
                break;
            }
            default: {
                inputNode = <Input disabled={editingKey !== ''} style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };
        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
            }
        };
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules} valuePropName={inputType === 'text' ? 'value' : 'checked'}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        {
            title: 'Day',
            dataIndex: 'Day',
            width: '10%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Day'].localeCompare(b['Day'])},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Closed',
            dataIndex: 'Is Closed',
            width: '10%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Is Closed'].toString().localeCompare(b['Is Closed'])},
            sortDirections  : ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Is Closed'] ? 'Yes' : 'No'}</Text>
        },
        {
            title: 'Open Hour',
            dataIndex: 'Open Hour',
            width: '10%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Open Hour'].localeCompare(b['Open Hour'])},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Close Hour',
            dataIndex: 'Close Hour',
            width: '10%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Close Hour'].localeCompare(b['Close Hour'])},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Plan',
            dataIndex: 'PlanID',
            width: '20%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => {
                return a['Plan']?.localeCompare(b['Plan']) ?? -100;
            },
            sortDirections  : ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Plan'] != null ? record['Plan'] : ''}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '30%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== '' || businessHoursGrid[0].Day === ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                        </Space>
                    </div>
                );
            }
        }
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        let type = 'text';
        switch (col.dataIndex) {
            case 'Is Closed': {
                type = 'check';
                break;
            }
            case 'Open Hour':
            case 'Close Hour': {
                type = 'time';
                break;
            }
            case 'PlanID': {
                type = 'select';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const cancel = (page) => {
        setEditingKey('');
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        form.resetFields();
        setCurrentEditingDay('');
        setCurrentUpdateToken('');
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    return (
        <div>
            <Form form={form}>
                <Table dataSource={businessHoursGrid} columns={columns} size="small" components={{ body: { cell: EditableCell } }} pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a>Previous</a>;
                            }
                            if (type === 'next') {
                                return <a>Next</a>;
                            }
                            return originalElement;
                        },
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    ></Table>
            </Form>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default NewBusinessHoursForm;

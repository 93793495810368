import React, { useEffect, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Radio, Select, Tree } from 'antd';

import { getArchivedCustomers, getArchivedDeals, searchCustomerArchivedData } from '../../../networking/NetworkingArchive';
import DynamicSelect from '../../shared/DynamicSelect';

const dataSample = '{"Deal Header ID":1,"Value Date":"2023-07-17 00:00:00.000","Buy Currency":"CAD","Sell Currency":"USD","Buy Amount":953.22,"Sell Amount":953.22,"Payments":[{"Payment ID":1,"Amount":500}]}'

const isObject = (val) => {
    return (typeof val === 'object');
}

const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const isArray = (str) => {
    let isArray = str !== undefined && Array.isArray(str)
    return isArray;
}

const GetAntdTree = (jsonObject, isBranch) => {

    let idxKey = 1
    let headerAdded = false;

    let antdTree = []
    let antBranch = {}

    for (const [key, value] of Object.entries(jsonObject)) {

        let workingValue = value
        if (value === undefined || value === null) {
            workingValue = ''
        }

        idxKey = Math.floor(100000 + Math.random() * 900000)

        if (isArray(workingValue)) {
            let customizedTitle = <div key={idxKey}> <label style={{ fontWeight: 'bold' }}>{key}</label></div>
            antBranch.children.push({ title: customizedTitle, key: idxKey, "children": [] })
            let newBranch = antBranch.children.slice(-1)[0]
            workingValue.forEach(x => {
                newBranch.children.push(GetAntdTree(x, true))
            })
        } else if (isObject(workingValue)) {
            console.log('processing key: ' + key)
            console.log('processing value: ' + workingValue)
        }
        else {
            if (!headerAdded) {
                let customizedTitle = <div key={idxKey}> <label style={{ fontWeight: 'bold' }}>{key}: {workingValue}</label></div>
                antBranch = { title: customizedTitle, key: idxKey, "children": [] }
                antdTree.push(antBranch)
                headerAdded = true
            }
            else {
                let customizedTitle = <div key={idxKey}> <label style={{ minWidth: '220px', display: 'inline-block' }}>{key}:</label>{workingValue}</div>
                antBranch.children.push({ title: customizedTitle, key: idxKey })
            }
        }
    }
    return isBranch ? antBranch : antdTree
}

const SearchInput = (props) => {

    const { placeholder, style, updateTextToSearch, data, updateDataToParse, updateCustomerId, searchBy } = props

    const [value] = useState();
    const handleSearch = (newValue) => {        
        updateTextToSearch(newValue);
    };
    const handleChange = (newValue) => {
        var selectedData = data.filter(x => x.value === newValue)
        if (selectedData && isArray(selectedData) && selectedData.length > 0) {
            if (searchBy === 'Deal') {
                updateDataToParse(selectedData[0].JSON)
            }
            else {
                updateDataToParse(selectedData[0].JSONData)
            }
            updateCustomerId(newValue)
        } else {
            updateCustomerId(0)
        }
    };

    const onClear = () => {
        updateCustomerId(0)
        updateDataToParse('{}')
    }

    return (
        <Select
            showSearch
            value={value}
            placeholder={placeholder}
            style={style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.text,
            }))}
            allowClear={true}
            onClear={onClear}
        />
    );
};

const HistoryData = () => {

    const [dataToParse, setDataToParse] = useState(dataSample)
    const [textToSearch, setTextToSearch] = useState('')    
    const [nodeSelected, setNodeSelected] = useState([])

    const [archivedCustomers, setArchivedCustomers] = useState([])
    const [customerId, setCustomerId] = useState(0)    
    const [customerBeneficiaries, setCustomerBeneficiaries] = useState([])
    const [customerDeals, setCustomerDeals] = useState([])

    const [showJsonData, setShowJsonData] = useState(false)
    const [showCustomerBenesAndDeals, setShowCustomerBenesAndDeals] = useState(false)

    const [searchBy, setSearchBy] = useState('Customer')    

    useEffect(() => {
        let searchDeals = searchBy === 'Deal'

        if (textToSearch !== undefined && textToSearch !== null && textToSearch.length > 2) {
            if (searchDeals) {
                getArchivedDeals(textToSearch).then(response => {                    
                    if (response !== undefined && response.data !== undefined && isArray(response.data)) {
                        let deals = response.data
                        deals.forEach(x => {
                            x.value = x.DealHeaderID
                            x.text = x.DealHeaderID
                        })
                        setNodeSelected([])
                        setArchivedCustomers(deals)
                    } else {
                        setArchivedCustomers([])
                    }
                }).catch(x => {                    
                    setArchivedCustomers([])
                })
            } else {
                getArchivedCustomers(textToSearch).then(response => {
                    if (response !== undefined && response.customers !== undefined && isArray(response.customers)) {
                        let customers = response.customers
                        customers.forEach(x => {
                            x.value = x.CustomerID
                            x.text = x.CustomerID + '-' + x.CustomerName
                        })                        
                        setNodeSelected([])
                        setArchivedCustomers(customers)
                    } else {
                        setArchivedCustomers([])
                    }
                }
                ).catch(x => {                    
                    setArchivedCustomers([])
                })
            }
        }
    }, [textToSearch])

    useEffect(() => {
        if (customerId > 0) {
            searchCustomerArchivedData(customerId).then(response => {                
                let data = response.data
                if (data && isArray(data)) {                    
                    data.forEach(x => {
                        x.value = x.ID
                        x.name = x.Name
                    })
                    setCustomerBeneficiaries(data.filter(x => x.Source === 'Beneficiaries'))
                    setCustomerDeals(data.filter(x => x.Source === 'Deals'))
                }
                else {                    
                    setCustomerBeneficiaries([])
                    setCustomerDeals([])
                }
            }
            )
        }
        else {            
            setCustomerBeneficiaries([])
            setCustomerDeals([])
        }
    }, [customerId])

    const updateTextToSearch = (value) => {        
        setTextToSearch(value)
    }

    const updateDataToParse = (value) => {
        if (isJson(value)) {
            setDataToParse(value)
        }
        else {
            setDataToParse('{}')
        }
    }

    const onSelectNode = (selectedKeys, e) => {        
    }

    const onRightClick = (event) => {        
        let selectedNode = []        
        selectedNode.push(event.node)
        setNodeSelected(selectedNode)
    }

    let jsonObject = {}
    try {
        jsonObject = JSON.parse(dataToParse);        
    } catch (e) {
        //console.error(e);
    }

    const changeDataToParse = (event) => {
        setDataToParse(event.target.value)
    }

    const selectCustomerArchivedInfo = (event) => {        
        let json = event.JSON
        if (isJson(json)) {
            setDataToParse(json)
        }
        else {
            setDataToParse('{}')
        }
    }

    const onShowBenesAndDeals = (event) => {
        setShowCustomerBenesAndDeals(event.target.checked)        
    }

    const onCheckShowJson = (event) => {
        setShowJsonData(event.target.checked)
    }

    const onChangeRadioGroup = (e) => {
        setSearchBy(e.target.value);
        clearView();
    };

    const clearView = () => {
        setDataToParse('{}')
        setShowCustomerBenesAndDeals(false)
        setTextToSearch('')
        setDataToParse('')
        setArchivedCustomers([])
        setNodeSelected([])
        setCustomerId(0)
    }

    console.log(jsonObject)
    let antdTreeJson = GetAntdTree(jsonObject)    
    console.log(antdTreeJson)    

    return (
        <div>
            <h3>
                History Data
            </h3>
            <hr />
            <div>                
                <label className="uk-form-label">Search: </label><SearchInput
                    placeholder={searchBy === 'Customer' ? "customer search text" : "deal search number"}
                    style={{
                        width: 240, padding: '10px'
                    }}
                    data={archivedCustomers}
                    updateTextToSearch={updateTextToSearch}
                    updateDataToParse={updateDataToParse}
                    updateCustomerId={setCustomerId}
                    searchBy={searchBy} />
                <Radio.Group onChange={onChangeRadioGroup} value={searchBy}>
                    <Radio value={'Customer'}>Customer</Radio>
                    <Radio value={'Deal'}>Deal</Radio>
                </Radio.Group>
                <div>
                    <label className="uk-form-label" hidden={customerId === 0 || searchBy !== 'Customer'}>
                        <input className="uk-checkbox" id="showBenesAndDealId" type="checkbox" checked={showCustomerBenesAndDeals} onChange={onShowBenesAndDeals} />
                        Show Archived Beneficiaries and Deals</label>
                </div>
                <div uk-grid="true" hidden={!showCustomerBenesAndDeals || searchBy !== 'Customer'}>
                    <div className="uk-width-1-2 uk-form-controls">
                        <label className="uk-form-label">Beneficiary: </label>
                        <DynamicSelect placeholder="Select a beneficiary..."
                            data={customerBeneficiaries}
                            getValue={selectCustomerArchivedInfo} hidden={!showCustomerBenesAndDeals} />
                    </div>
                    <div className="uk-width-1-2 uk-form-controls"></div>
                    <div className="uk-width-1-2 uk-form-controls">
                        <label className="uk-form-label">Deal: </label>
                        <DynamicSelect placeholder="Select a deal..."
                            data={customerDeals}
                            getValue={selectCustomerArchivedInfo} hidden={!showCustomerBenesAndDeals} />
                    </div>
                </div>
            </div>
            <hr hidden={customerId === 0} />
            <form className="uk-grid">
                <div className="uk-width-1-2">
                    <Tree
                        showLine={true}
                        treeData={antdTreeJson}
                        selectable
                        onSelect={onSelectNode}
                        onRightClick={onRightClick}
                        switcherIcon={<DownOutlined />}
                    />
                </div>
                <div className="uk-width-1-3">
                    <Tree key={'tree-selected-1-3'}
                        showLine={true}
                        treeData={nodeSelected}
                        switcherIcon={<DownOutlined />}
                    />
                </div>
            </form>
            <hr />
            <label className="uk-form-label">
                <input className="uk-checkbox" id="showJsonDataId" type="checkbox" checked={showJsonData} onChange={onCheckShowJson} />
                Show Json Data</label>
            <textarea value={dataToParse} style={{ width: '100%' }} onChange={changeDataToParse} hidden={!showJsonData}>
            </textarea>            

        </div>
        )
}

export default HistoryData;
import jwt from 'jsonwebtoken';
import { genericCallWithBody, genericCallWithOutBody, genericGetWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getCustomerCommissionByEntityId(entityType, entityId)
{
    try {
        let params = {
            'EntityType': entityType,
            'EntityId': entityId
        }
        let response = await genericGetWithParameters('Commission/GetCustomerCommissionByEntityId', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrentCustomerCommissionTableByCustomer(customerId, noStatus)
{
    try {
        let params = {
            'CustomerID': customerId,
            'NoStatus': noStatus
        }
        let response = await genericGetWithParameters('Commission/GetCurrentCustomerCommissionTableByCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveCustomerCommission = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Commission/SaveCustomerCommission', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const addNewRangeCustomerCommissionTable = async (customerId, newRangeStartDate, createdBy, newTraderId) => {
    try {
        let params = {
            'CustomerID'        : customerId,
            'NewRangeStartDate' : newRangeStartDate,
            'CreatedBy'         : createdBy,
            'NewTraderID'       : newTraderId
        }
        const response = await genericCallWithOutBody('Commission/AddNewRangeCustomerCommissionTable', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const updateCustomerCommission = async (customerCommissionTableId, updateToken, updatedBy) => {
    try {
        let params = {
            'CustomerCommissionTableId' : customerCommissionTableId,
            'UpdateToken'               : updateToken,
            'UpdatedBy'                 : updatedBy
        }
        const response = await genericCallWithOutBody('Commission/UpdateCustomerCommission', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import AlertBox from '../../../shared/AlertBox';
import {OwnerType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class OwnerAdditionalProperties extends React.Component {
    state = {
        ownerID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
        errors: [],
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
        this.props.getSaveProperties(false);
        this.props.showFinalAlert();
    }

    onFinishEntityPropertiesSaveProcess(status,message,pendingMandatoryList){
        if(status){
            this.props.getSaveProperties(false);
            this.props.showFinalAlert();
        }else{
            this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Warning', alertMessage: message, errors: pendingMandatoryList});
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.saveProperties !== prevProps.saveProperties){
            if(this.props.ownerID === ''){
                this.props.getSaveProperties(false);
                this.props.showFinalAlert();
            }
        }
    }

    render(){
        let componentID = 'entity-properties-owner'+ this.props.ownerID;
        var section = (
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties id={componentID} fullScreen={true} entityType={OwnerType} entityID={this.props.ownerID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                    </div>
                </form>
            )
        if(this.props.ownerID === ''){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create owner first.</h5>
                </div>
            )
        }
        return(
            <div>
            {section}
            <AlertBox 
                open={this.state.showAlert} 
                onClose={this.closeAlert} 
                title={this.state.alertTitle} 
                message={this.state.alertMessage} 
                type="Ok" 
                messageBoldList={this.state.errors}
                okClick={this.closeAlert}/>
            </div>
        );
    }
}
import React from 'react';

import SearchDealTransferScreen from './SearchDealTransferScreen';

import DynamicSelect from '../../shared/DynamicSelect';
import DatePicker from '../../shared/DatePicker';
import NumberInput from '../../shared/NumberInput';
import AlertBox from '../../shared/AlertBox';

import {getActiveTradersMin} from '../../../networking/NetworkingTrader';
import {getAllActiveBlotters} from '../../../networking/NetworkingBlotters';
import {getParametersByName} from '../../../networking/NetworkingGlobalSystemParameters';
import {getNostroAccountCurrencies} from '../../../networking/NetworkingCurrencies';
import {getBankAccountsByPerson} from '../../../networking/NetworkingBankAccounts';
import { getBankAccountsForDealTransfer, saveDealTransfer } from '../../../networking/NetworkingDealTransfers';

import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames';
import {DealTypesNames} from '../../../constants/DealTypesNames';
import {getScaleByCurrency} from '../../../helpers/FormatHelper'

import { 
    formatValidSelect, 
    formatValidInput, 
    formatValidDatePicker, 
    formatDate,
    formatDatetime } from '../../../helpers/FormatHelper';
import {getStyle} from '../../../styles/styles';

var style = getStyle();

export default class CreateTransfersScreen extends React.Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            updateOne               : false,
            dealTransferID          : 0,
            updateToken             : 0,
            entryDate               : formatDate(new Date(Date.now())),
            traderList              : [],
            traderSelected          : '',
            traderBranchID          : 0,
            transferNumber          : '',
            blotterList             : [],
            blotterSelected         : '',
            totalCurrencyList       : [], //Lista total de Currencies
            currencyList            : [],
            currencySelected        : '',
            valueDateSelected       : '',
            amount                  : 0,
            comments                : '',
            totalListAccounts       : [], //Lista Total de Accounts
            currencyListQuantity    : [], //Lista de Currencies con sus cantidades
            fromAccountList         : [],
            fromAccountSelected     : '',
            accountSelectedFrom     : null,
            accountSelectedTo       : null,
            toAccountList           : [],
            toAccountSelected       : '',
            //valuesForGettings
            nostroAccountGuid       : '',
            defaultBuyCurrencyID    : '', //Currency por default a considerar
            //Validations   
            validationsTrader       : true,
            validationsBlotter      : true,        // Si agregan algun valor en el state tambien agregarlo
            validationsCurrency     : true,        // en el handleClearFieldsClick()
            validationsValueDate    : true,
            validationsAmount       : true,
            validationsComments     : true,
            validationsFromAccount  : true,
            validationsToAccount    : true,
            //Alert
            showAlert               : false,
            alertTitle              : '',
            alertMessage            : '',
            listMissing             : [],
            //AlertSave
            showAlertSave           : false,
            alertTitleSave          : '',
            alertMessageSave        : '',
            bankAccountsForDealTransfer: []
        };
    }

    componentDidMount()
    {
        //Traer todas las listas y valores por default 
        getActiveTradersMin().then(
            (jsonR) => {
                console.log("json: " + JSON.stringify(jsonR))
                /*{
                    "traders":[
                        {"TraderID":15,"Trader":"Admin Admin"},
                        {"TraderID":1062,"Trader":"Arturo Barragan"},...
                */
               if(jsonR !== undefined)
               {
                   if(jsonR.traders !== undefined && Array.isArray(jsonR.traders))
                   {
                       var allTradersActive = jsonR.traders;
                       var userSelected = '';
                       var branchId = 0;
                       allTradersActive.forEach(trader => {
                            trader.name = trader.Trader;
                            trader.value = trader.TraderID;
                            if(localStorage.getItem('UserName').toLowerCase() === trader['User Name'].toLowerCase())
                            {
                                userSelected = trader.TraderID;
                                branchId = trader.BranchId
                            }
                       });

                        this.setState({
                            traderList      : allTradersActive,
                            traderSelected  : userSelected,
                            traderBranchID  : branchId,
                        });
                   }
               }
            }
        );

        /*
            {"blotters":[
                {
                    "Description":"Main Blotter",
                    "Base Currency":"USD",
                    "Position Limit":1500.00,
                    "Parent Blotter ID":null,
                    "Status":"Active",
                    "Allow Profit":false
                },
                {
                    "Description":"Daniel Blotter",
                    "Base Currency":"USD",
                    "Position Limit":100000.00,
                    "Parent Blotter ID":22,
                    "Status":"Active",
                    "Allow Profit":false
                },....
            ]}
        */

        getAllActiveBlotters().then(
            (jsonResponse) => {
                if(jsonResponse !== undefined && jsonResponse.httpStatusCode !== undefined)
                {
                    switch (jsonResponse.httpStatusCode)
                    {
                        case 200:
                        {
                            if(jsonResponse.blotters !== undefined && Array.isArray(jsonResponse.blotters))
                            {
                                var allActiveBlotters = jsonResponse.blotters
                                allActiveBlotters.forEach(blotter => {
                                    blotter.name = blotter.Description
                                    blotter.value = blotter['Blotter ID']
                                });
         
                                this.setState({
                                    blotterList: allActiveBlotters
                                });
                            }
                            break;
                        }
                    }
                }
            }
        );

        var accountGuid = '';
        var currencyID = '';

        getParametersByName('NostroAccountGuid,DefaultBuyCurrencyID').then(
            (jsonResponse) => {
                console.log("jsonResponse: "+ JSON.stringify(jsonResponse));
                if(jsonResponse != undefined)
                {
                    jsonResponse.parameters.forEach(element => {
                        switch (element.Name) {
                            case 'NostroAccountGuid':
                            { 
                                accountGuid = element.Value;
                                break;
                            }
                            case 'DefaultBuyCurrencyID':
                            { 
                                currencyID = element.Value;
                                break;
                            }
                        }
                    });

                    this.setState({
                        nostroAccountGuid   : accountGuid,
                        defaultBuyCurrencyID: currencyID,
                    });

                    getNostroAccountCurrencies(accountGuid).then(
                        (jsonR)=>{
                            console.log("jsogetNostroAccountCurrenciesnR: "+ JSON.stringify(jsonR));
                            /*
                                jsogetNostroAccountCurrenciesnR: 
                                {
                                    "currencies":[
                                        {
                                            "Currency Id":"AUD",
                                            "Currency Name":"AUSTRALIAN DOLLAR"
                                        },
                                        {
                                            "Currency Id":"CAD",
                                            "Currency Name":"CANADIAN DOLLAR"
                                        },...
                                    ],
                                    "httpStatusCode":200
                                }
                            */
                           if(jsonR !== undefined)
                           {
                               if(jsonR.currencies !== undefined && Array.isArray(jsonR.currencies))
                               {
                                    var currencies =  jsonR.currencies;
                                    currencies.forEach((currency,index) => {
                                        currency.value = currency['Currency Id'];
                                        currency.name = currency['Currency Name'];
                                    });

                                    this.setState({
                                        totalCurrencyList: currencies
                                    });
                               }
                           }
                        }
                    );

                    getBankAccountsByPerson(accountGuid).then(
                        (jsonResponse) => {
                            console.log('getBankAccountsByPerson: ' + JSON.stringify(jsonResponse));
                            /*{
                                "bankAccounts":[
                                    {
                                        "Account ID":1005444,
                                        "Account Number":"",
                                        "Currency":null,
                                        "Bank":"Interbank",
                                        "Branch":"Plaza San Miguel",
                                        "Account Name":"",
                                        "Country":"Peru",
                                        "AccountType":"Intermediate"
                                    },
                                    {
                                        "Account ID":1011912,
                                        "Account Number":"",
                                        "Currency":null,
                                        "Bank":"10 Bank 10",
                                        "Branch":null,
                                        "Account Name":"",
                                        "Country":"Peru",
                                        "AccountType":"Intermediate"
                                    },.....................
                                ],
                                "httpStatusCode":200
                            }*/

                            if(jsonResponse !== undefined)
                            {
                                if(jsonResponse.bankAccounts !== undefined && Array.isArray(jsonResponse.bankAccounts))
                                {
                                    var bankAccountsList = jsonResponse.bankAccounts;
                                    var auxCurrencyCount = [];

                                    bankAccountsList.forEach(bankAI => {
                                        bankAI.value = bankAI['Account ID'];
                                        bankAI.name = bankAI.Bank + ' - ' + bankAI['Account Number'] + ' - ' + bankAI['Currency']

                                        if(auxCurrencyCount.length === 0)
                                        {
                                            var first = {
                                                Currency: bankAI['Currency'],
                                                Quantity: 1,
                                            }
                                            auxCurrencyCount.push(first)
                                        }else
                                        {
                                            var encontrarSiEstaEnListaActual = false;
                                            var indiceDondeEstaEncontrado = 0;

                                            auxCurrencyCount.forEach((element, index) => {
                                                if(element.Currency === bankAI['Currency'])
                                                {
                                                    encontrarSiEstaEnListaActual = true;
                                                    indiceDondeEstaEncontrado = index
                                                }
                                            });

                                            if(encontrarSiEstaEnListaActual)
                                            {
                                                auxCurrencyCount[indiceDondeEstaEncontrado].Quantity = auxCurrencyCount[indiceDondeEstaEncontrado].Quantity + 1
                                            }else
                                            {
                                                var more = {
                                                    Currency: bankAI['Currency'],
                                                    Quantity: 1,
                                                }
                                                auxCurrencyCount.push(more)
                                            }
                                        }
                                    });

                                    this.setState({
                                        totalListAccounts    : bankAccountsList,
                                        currencyListQuantity : auxCurrencyCount,
                                    });
                                }
                            }
                        }
                    );

                    getBankAccountsForDealTransfer().then(
                        (jsonResponse) => {
                            console.log(jsonResponse)
                            if (jsonResponse !== undefined && jsonResponse !== null) {

                                let accounts = jsonResponse.accounts || []
                                accounts.forEach(x => {
                                    x.value = x.BankAccountID;
                                    x.name = x.BankName + ' - ' + x.BankAccount + ' - ' + x.AccountCurrencyID
                                    if (x.VirtualAccount) {
                                        x.name = x.name + ' (Virtual Account - Customer ID :' + x.CustomerId + ' )'
                                    }
                                })

                                this.setState({
                                    bankAccountsForDealTransfer: accounts
                                });
                            }
                            else
                            {
                                this.setState({
                                    bankAccountsForDealTransfer: []
                                });
                            }
                        }
                    )
                }
            }
        );

        this.setState({
            valueDateSelected: formatDate(new Date(Date.now()))
        });
    }

    handleGetValueTrader = (traderObj) => {
        if (traderObj.value !== undefined) 
        {
            this.setState({
                traderSelected      : traderObj.value,
                validationsTrader   : true,
                traderBranchID      : traderObj.BranchId,
            });
        }
    }

    handleGetValueBlotter = (blotterObj) => {
        if (blotterObj.value !== undefined) 
        {
            this.setState({
                blotterSelected      : blotterObj.value,
                validationsBlotter   : true,
            });
        }
    }

    handleGetValueCurrency = (currencyObj) => {
        if (currencyObj.value !== undefined) 
        {
            this.setState({
                currencySelected      : currencyObj.value,
                validationsCurrency   : true,
            });
        }
    }

    handleUpdateValueDate = (date) => {
        this.setState({
            valueDateSelected   : formatDate(date),
            validationsValueDate: true,
        });
    }

    handleUpdateAmount = (event) => {
        this.setState({
            amount              : event.target.value,
            validationsAmount   : true,
        });
    }

    updateComments = (event) =>{
        this.setState({
            validationsComments : true,
            comments            : event.target.value
        });
    }

    handleGetValuefromAccount = (accountObj) => {
        if (accountObj.value !== undefined) 
        {
            this.setState({
                fromAccountSelected      : accountObj.value,
                validationsFromAccount   : true,
                accountSelectedFrom      : accountObj,
            });
        }

        if(accountObj.value === this.state.toAccountSelected)
        {
            this.setState({
                toAccountSelected       : '',
                validationsToAccount    : false,
                accountSelectedTo       : null,
            });
        }

        if(accountObj.Currency !== undefined || accountObj.Currency !== "")
        {
            if(this.state.currencySelected === undefined || this.state.currencySelected === "")
            {
                this.setState({
                    currencySelected        : accountObj.Currency,
                    validationsCurrency     : true,
                });
            }
        }
    }

    handleGetValuetoAccount = (accountObj) => {
        if (accountObj.value !== undefined) 
        {
            this.setState({
                toAccountSelected      : accountObj.value,
                validationsToAccount   : true,
                accountSelectedTo      : accountObj
            });
        }
    }

    handleSearchClick = () =>
    {
        this.setState({
            showSearchDialog: true,
        });
    }

    closeSearchDialog = () =>
    {
        this.setState({
            showSearchDialog: false,
        });
    }

    handleSaveClick = () => {
        var localStorageSystemDate = formatDate(localStorage.getItem(GlobalSystemParametersNames.SystemDate));
        var localStorageUserID = localStorage.getItem('UserID')
        var systemDate = new Date(localStorageSystemDate).toISOString();

        var systemDate = new Date(formatDate(localStorage.getItem(GlobalSystemParametersNames.SystemDate))).toISOString();
        if (this.validateRequiredFields())
        {
            var model ={
                "dealTransfers": {
                  "DealTransferID": this.state.dealTransferID,
                  "BranchID": this.state.traderBranchID,
                  "DealTypeID": DealTypesNames['Deal Transfer'],
                  "IssueDate": systemDate,
                  "ValueDate": new Date(this.state.valueDateSelected).toISOString(),
                  "SourceBlotter": parseInt(this.state.blotterSelected),
                  "TraderID": parseInt(this.state.traderSelected),
                  "CurrencyID": this.state.currencySelected,
                  "Amount": this.state.amount,
                  "FromSettlementBankAccount": parseInt(this.state.fromAccountSelected),
                  "ToSettlementBankAccount": parseInt(this.state.toAccountSelected),
                  "Status": "Completed",
                  "Comments": this.state.comments,
                  "UpdatedBy": localStorageUserID,
                  "UpdatedOn": new Date(Date.now()).toISOString(),
                  "CreatedBy": localStorageUserID,
                  "CreatedOn": new Date(Date.now()).toISOString(),
                  "FromClearedDate": null,
                  "FromClearedComments": this.state.comments,
                  "FromClearedUpdatedBy": null,
                  "FromClearedUpdatedOn": null,
                  "ToClearedDate": null,
                  "ToClearedComments": this.state.comments,
                  "ToClearedUpdatedBy": null,
                  "ToClearedUpdatedOn": null,
                  "ToClearedIssueDate": null,
                  "ToUnClearedDate": null,
                  "FromClearedIssueDate": null,
                  "FromUnClearedDate": null,
                  "ApprovedBy": null,
                  "ApprovedOn": null,
                  "GLAccountingDate": null,
                  "VoidDate": null,
                  "GLPostingDate": null,
                  "UpdateToken": this.state.updateToken
                },
                "reactSystemDate": new Date(Date.now()).toISOString(),
                "syncComments": false
              }

            saveDealTransfer(model).then(
                (jsonResponse) => {
                    console.log('saveDealTransfer: '+ JSON.stringify(jsonResponse));
                    /*
                        saveDealTransfer: 
                        {
                            "Result":true,
                            "UpdateToken":"637375007612200000",
                            "httpStatusCode":200
                        }
                    */
                    /*saveDealTransfer: 
                    {
                        "Message":"concurrency",
                        "ErrorMessage":null,
                        "httpStatusCode":503,
                        "httpErrorMessage":"An error occurred. Please try again."
                    }
                    */
                   if(jsonResponse !== undefined)
                   {
                       switch (jsonResponse.httpStatusCode) 
                       {
                            case 200:
                            { 
                                if(jsonResponse.Result !== undefined)
                                {
                                     switch (jsonResponse.Result) 
                                     {
                                         case true:
                                         {  
                                             this.setState({
                                                 showAlertSave       : true,
                                                 alertTitleSave      : 'Success',
                                                 alertMessageSave    : 'Transfer successfully saved.',
                                             });
                                             
                                             break;
                                         }
                                         case false:
                                         {
                                             break;
                                         }
                                    }
                                }
                               break;
                            }
                            case 503:
                            { 
                                var message = ''
                                if(jsonResponse.Message !== undefined)
                                {

                                    switch (jsonResponse.Message) {
                                        case 'concurrency':
                                        {
                                            message = 'The record has been updated by another user. Please reload the information and try again.'
                                            break;
                                        }
                                        default:
                                        {
                                            message = 'Something went wrong. Please try again later'
                                            break;
                                        }
                                    }
                                }
                                this.setState({
                                    showAlert: true,
                                    alertTitle: 'Error',
                                    alertMessage: message
                                });
                                break;
                            }
                       }
                       
                   }
                }
            );
        }
    }

    validateRequiredFields = () => {
        var valid = true;
        var valTrader       = true;
        var valBlotter      = true;
        var valCurrency     = true;
        var valValueDate    = true;
        var valAmount       = true;
        var valComments     = true;
        var valFromAccount  = true;
        var valToAccount    = true;
        var listReq = []

        switch (typeof this.state.traderSelected) {
            case 'string':
            {   
                if(this.state.traderSelected === undefined || this.state.traderSelected === '' || this.state.traderSelected.includes('select'))
                {
                    valid = false;
                    valTrader = false;
                    listReq.push('Trader');
                }
                break;
            }
        }

        switch (typeof this.state.blotterSelected) {
            case 'string':
            {   
                if(this.state.blotterSelected === undefined || this.state.blotterSelected === '' || this.state.blotterSelected.includes('select'))
                {
                    valid = false;
                    valBlotter = false;
                    listReq.push('Blotter');
                }
                break;
            }
        }

        switch (typeof this.state.currencySelected) {
            case 'string':
            {   
                if(this.state.currencySelected === undefined || this.state.currencySelected === '' || this.state.currencySelected.includes('select'))
                {
                    valid = false;
                    valCurrency = false;
                    listReq.push('Currency');
                }
                break;
            }
        }


        if(this.state.valueDateSelected === undefined || this.state.valueDateSelected === '' || this.state.valueDateSelected.includes('select'))
        {
            valid = false;
            valValueDate = false;
            listReq.push('Value Date');
        }

        if(this.state.amount === undefined || this.state.amount <= 0 || this.state.amount === '')
        {
            valid = false;
            valAmount = false;
            listReq.push('Ammount');
        }

        if(this.state.comments === undefined || this.state.comments === '')
        {
            valid = false;
            valComments = false;
            listReq.push('Comments');
        }

        switch (typeof this.state.fromAccountSelected) {
            case 'string':
            {   
                if(this.state.fromAccountSelected === undefined || this.state.fromAccountSelected === '' || this.state.fromAccountSelected.includes('select'))
                {
                    valid = false;
                    valFromAccount = false;
                    listReq.push('From Account');
                }
                break;
            }
        }

        switch (typeof this.state.toAccountSelected) {
            case 'string':
            {   
                if(this.state.toAccountSelected === undefined || this.state.toAccountSelected === '' || this.state.toAccountSelected.includes('select'))
                {
                    valid = false;
                    valToAccount = false;
                    listReq.push('To Account');
                }
                break;
            }
        }

        if(this.state.accountSelectedFrom !== undefined && this.state.accountSelectedFrom !== null)
        {
            if(this.state.accountSelectedFrom.value !== "" && this.state.currencySelected !== "")
            {
                if(this.state.accountSelectedFrom.AccountCurrencyID/*Currency*/ !== this.state.currencySelected)
                {
                    valid = false;
                    valFromAccount = false;
                    valCurrency = false;
                    listReq.push('From Account currency must be the same as the selected Currency.');
                }
            }
        }

        if(this.state.accountSelectedTo !== undefined && this.state.accountSelectedTo !== null)
        {
            if(this.state.accountSelectedTo.value !== "" && this.state.currencySelected !== "")
            {
                if(this.state.accountSelectedTo.AccountCurrencyID/*Currency*/ !== this.state.currencySelected)
                {
                    valid = false;
                    valToAccount = false;
                    valCurrency = false;
                    listReq.push('To Account currency must be the same as the selected Currency.');
                }
            }
        }

        this.setState({
            validationsTrader       : valTrader,
            validationsBlotter      : valBlotter,
            validationsCurrency     : valCurrency,
            validationsValueDate    : valValueDate,
            validationsAmount       : valAmount,
            validationsComments     : valComments,
            validationsFromAccount  : valFromAccount,
            validationsToAccount    : valToAccount,
            listMissing             : listReq,
        });

        if(!valid)
        {
            this.setState({
                showAlert   : true,
                alertTitle  : 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    closeAlert = () => {
        this.setState({
            showAlert   : false, 
            alertMessage: '', 
            alertTitle  : '',
            listMissing : [],
        });
    }

    closeAlertSave = () =>
    {
        this.setState({
            showAlertSave   : false, 
            alertMessageSave: '', 
            alertTitleSave  : '',
        },()=>{
            this.handleClearFieldsClick();
        });
    }

    handleClearFieldsClick = () => {
        this.setState({
            updateOne               : false,
            transferNumber          : '',
            dealTransferID          : 0,
            updateToken             : 0,
            entryDate               : formatDate(new Date(Date.now())),
            traderSelected          : '',
            transferNumber          : '',
            blotterSelected         : '',
            currencySelected        : '',
            valueDateSelected       : '',
            amount                  : '',
            comments                : '',
            fromAccountList         : [],
            fromAccountSelected     : '',
            toAccountList           : [],
            toAccountSelected       : '',
            //Validations   
            validationsTrader       : true,
            validationsBlotter      : true,
            validationsCurrency     : true,
            validationsValueDate    : true,
            validationsAmount       : true,
            validationsComments     : true,
            validationsFromAccount  : true,
            validationsToAccount    : true,
            //Alert
            showAlert               : false,
            alertTitle              : '',
            alertMessage            : '',
            listMissing             : [],
        });
    }

    aDealTransferWasclicked = (objDT)=>
    {
        console.log("se clickeo: " + JSON.stringify(objDT));
        /*
        {
            "DealTransferID":15,
            "Description":"Deal Transfer",
            "IssueDate":"2020-09-30T05:00:00",
            "ValueDate":"2020-10-21T05:00:00",
            "SourceBlotter":22,"Blotter":"Main Blotter",
            "TraderID":1037,
            "TraderName":"Cruzado, Javier",
            "CurrencyID":"USD","Amount":4545,
            "FromSettlementBankAccount":1006480,
            "ToSettlementBankAccount":1005443,
            "Status":"Completed",
            "UpdateToken":637375028633500000
        }
        */

        if(objDT.DealTransferID !== 0)
        {
            this.setState({
                updateOne               : true,
                transferNumber          : objDT['Deal Transfer ID'],
                dealTransferID          : objDT['Deal Transfer ID'],
                updateToken             : objDT.UpdateToken,
                traderSelected          : objDT.TraderID,
                traderBranchID          : objDT.BranchID,
                blotterSelected         : objDT['Source Blotter'],
                currencySelected        : objDT['Currency ID'],
                valueDateSelected       : formatDate(objDT['Value Date']),
                amount                  : objDT.Amount,
                comments                : '',
                fromAccountSelected     : objDT['From Account'],
                toAccountSelected       : objDT['To Account'],
                validationsTrader       : true,
                validationsBlotter      : true,        // Si agregan algun valor en el state tambien agregarlo
                validationsCurrency     : true,        // en el handleClearFieldsClick()
                validationsValueDate    : true,
                validationsAmount       : true,
                validationsComments     : false,
                validationsFromAccount  : true,
                validationsToAccount    : true,
            });
        }
    }

    render() 
    {
        var totalCurrencies = this.state.totalCurrencyList;
        var listCurrencyQuantity = this.state.currencyListQuantity;
        var totalAccounts = this.state.totalListAccounts;

        var currencyListToShow = [];
        var accountListToShow = [];

        listCurrencyQuantity.forEach((element, index) => {
            if(element.Quantity > 1 && element.Currency !== null)
            {
                currencyListToShow.push(element);
            }
        });

        currencyListToShow.forEach(currency => {
            totalCurrencies.forEach(currencyTotal => {
                if(currency.Currency === currencyTotal['Currency Id'])
                {
                    currency.value = currency.Currency
                    currency.name = currencyTotal['Currency Name']
                }
            });
        });

        if(currencyListToShow.length === 1 && this.state.currencySelected === '')
        {
            this.handleGetValueCurrency(currencyListToShow[0]);
        }

        totalAccounts.forEach((account, index) => {
            currencyListToShow.forEach(element => {
                if(account.Currency === element.value)
                {
                    accountListToShow.push(account);
                }
            });
        });

        var newAccountListToShow = [];
        if(this.state.currencySelected !== undefined && this.state.currencySelected !== "")
        {
            accountListToShow.forEach(element => {
                console.log(element);
                if(element.Currency.toString() === this.state.currencySelected.toString())
                {
                    newAccountListToShow.push(element);
                }
            });
        }

        if(newAccountListToShow.length !== 0)
        {
            accountListToShow = newAccountListToShow;
        }

        var fromAccountSelected = this.state.fromAccountSelected;
        var toAccountToShow = [];

        if(fromAccountSelected !== '' && fromAccountSelected !== "select")
        {
            accountListToShow.forEach(element => {
                if(element['Account ID'].toString() !== fromAccountSelected)
                {
                    toAccountToShow.push(element);
                }
            });
        }

        return (
            <div>
                <h3 
                    className="uk-heading-divider" 
                    style={style.titleStyle}
                >Create Transfers</h3>
                <div style={{paddingLeft: 20, paddingRight: 20, paddingBottom: 20}}>
                    <ul id="accordion-compliance" uk-accordion="multiple: true" >
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">General Information</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >Entry Date</label>
                                        <div className="" style={style.inputStyle}>
                                            <input 
                                                className   = 'uk-input'
                                                type        = 'text'
                                                value       = {this.state.entryDate}
                                                disabled    = {true}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Trader</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id                  = "form-transfer-trader"
                                                className           = {formatValidSelect(this.state.validationsTrader)}
                                                objValue            = {{value: this.state.traderSelected}} 
                                                getValue            = {this.handleGetValueTrader} 
                                                placeholder         = 'Select a Trader...'
                                                listHasPlaceholder  = {false}
                                                data                = {this.state.traderList}
                                                disabled            = {this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Transaction Information</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >Transfer N°</label>
                                        <div className="" style={style.inputStyle}>
                                            <input 
                                                className   = 'uk-input'
                                                type        = 'text'
                                                value       = {this.state.transferNumber}
                                                disabled    = {true}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Blotter</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id                  = "form-transfer-blotter"
                                                className           = {formatValidSelect(this.state.validationsBlotter)}
                                                objValue            = {{value: this.state.blotterSelected}} 
                                                getValue            = {this.handleGetValueBlotter} 
                                                placeholder         = 'Select a Blotter...'
                                                listHasPlaceholder  = {false}
                                                data                = {this.state.blotterList} 
                                                disabled            = {this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >Currency</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id                  = "form-transfer-currency"
                                                className           = {formatValidSelect(this.state.validationsCurrency)}
                                                objValue            = {{value: this.state.currencySelected}} 
                                                getValue            = {this.handleGetValueCurrency} 
                                                placeholder         = 'Select a Currency...'
                                                listHasPlaceholder  = {false}
                                                data                = {currencyListToShow} 
                                                disabled            = {this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Value Date</label>
                                        <div className="" style={style.inputStyle}>
                                            <DatePicker
                                                className   = {formatValidDatePicker(this.state.validationsValueDate)} 
                                                value       = {this.state.valueDateSelected} 
                                                onDayChange = {this.handleUpdateValueDate}
                                                disabled    = {this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >Amount</label>
                                        <div className="" style={style.inputStyle}>
                                            <NumberInput 
                                                className   = {formatValidInput(this.state.validationsAmount)}
                                                
                                                id          = "form-transfer-amount" 
                                                type        = "Currency" 
                                                value       = {this.state.amount} 
                                                onChange    = {this.handleUpdateAmount}
                                                disabled    = {this.state.updateOne}
                                                scale = {getScaleByCurrency(this.state.currencySelected)}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Comments</label>
                                        <div className="" style={style.inputStyle}>
                                            <textarea 
                                                className   = {`uk-width-1-1 ${formatValidInput(this.state.validationsComments)}`}
                                                style       = {{fontSize:16, height: 70}}
                                                value       = {this.state.comments}
                                                onChange    = {this.updateComments}
                                            >{this.state.comments}</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Settlement Information</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >From Account</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id="form-transfer-fromAccount"
                                                className={formatValidSelect(this.state.validationsFromAccount)}
                                                objValue={{ value: this.state.fromAccountSelected }}
                                                getValue={this.handleGetValuefromAccount}
                                                placeholder='Select an Account...'
                                                listHasPlaceholder={false}
                                                //data                = {accountListToShow}
                                                data={this.state.bankAccountsForDealTransfer}
                                                disabled={this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>To Account</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id                  = "form-transfer-toAccount"
                                                className           = {formatValidSelect(this.state.validationsToAccount)}
                                                objValue            = {{value: this.state.toAccountSelected}} 
                                                getValue            = {this.handleGetValuetoAccount} 
                                                placeholder         = 'Select an Account...'
                                                listHasPlaceholder  = {false}
                                                //data                = {toAccountToShow} 
                                                data={this.state.bankAccountsForDealTransfer}
                                                disabled            = {this.state.updateOne}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div>
                        <button 
                            className="uk-button uk-button-green uk-margin-right" 
                            onClick={this.handleSearchClick}
                        >Search</button>
                        <button 
                            className="uk-button uk-button-green uk-margin-right" 
                            onClick={this.handleClearFieldsClick}
                        >ClearFields</button>
                        <button 
                            className="uk-button uk-button-green uk-margin-right" 
                            onClick={this.handleSaveClick}
                        >Save</button>
                    </div>
                    </div>
                <SearchDealTransferScreen
                    open        = {this.state.showSearchDialog}
                    onClose     = {this.closeSearchDialog}
                    onClickRow  = {this.aDealTransferWasclicked}
                />
                <AlertBox
                    open    = {this.state.showAlert}
                    onClose = {this.closeAlert}
                    title   = {this.state.alertTitle}
                    message = {this.state.alertMessage}
                    type    = "Ok"
                    okClick = {this.closeAlert}
                    messageBoldList = {this.state.listMissing}
                />
                <AlertBox
                    open    = {this.state.showAlertSave}
                    onClose = {this.closeAlertSave}
                    title   = {this.state.alertTitleSave}
                    message = {this.state.alertMessageSave}
                    type    = "Ok"
                    okClick = {this.closeAlertSave}
                />
            </div>
        )
    }
}
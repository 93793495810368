import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle } from '../../../styles/styles';
import { BoardName } from '../../../constants/BoardNames';
import { approvalBoard } from '../../../networking/NetworkingDeals';
import AlertBox from '../../shared/AlertBox';
import { getOutgoingInformationByID } from '../../../networking/NetworkingFunds';
import { formatDateToDDMMYYYY, formatToMT103 } from '../../../helpers/FormatHelper';

var style = getStyle();
export default class ApprovalBoardScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            boardName: '',
            ids: [],
            showAlert: false,
            alertMessage: '',
            alertTitle: '',
            isApproval: false,
            selectedRows: [],
            showApproveAlert: false,
            showTitle: true
        }
    }
    
    componentDidMount() {

    }

    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }

    handleApprovalCheckboxClick = (obj, checked, all, listSelects) => {
        let actualBoardName = this.state.boardName;
        var listOfIds = this.state.ids;
        var key = obj['Key'];
        var outgoingID = key.length > 5 ? key.substr(0, 5) : key;
        var selected = listSelects;

        if(actualBoardName !== "")
        {
            if (obj['Action'] !== actualBoardName)
            {
                selected = [];
                listOfIds = [];
            }
        }

        if(all === true)
        {
            selected = [];
            listOfIds = [];
        }else
        {
            if (checked) 
            {
                selected.push(obj);
                listOfIds.push(parseInt(outgoingID));
            }else
            {
                selected = selected.filter(item => item["Key"] !== obj["Key"]);
                listOfIds = listOfIds.filter(item => item !== parseInt(outgoingID));
            }
        }

        this.setState({
            selectedRows: selected,
            boardName   : obj['Action'],
            ids         : listOfIds
        }, () =>{
            //console.log ("Estas aqui");
            //console.log("rows: " + JSON.stringify(this.state.selectedRows));
        });
    }
    
    clickApproval = () => {
        let idsFinales = this.state.ids;
        idsFinales = [...new Set(idsFinales)];
        let model = {
            boardName   : this.state.boardName,
            userID      : localStorage.getItem('UserID'),
            ids         : idsFinales
        }
        this.setState({ showApproveAlert: true, readyToApproveFunds: model });
    }

    handleGenerateMT103Click = async () => {
        let finalText = '';
        // this.state.ids.forEach(async (outgoingID, index) => {
        for (const [index, outgoingID] of this.state.ids.entries()){
            const outgoing = await getOutgoingInformationByID(outgoingID);
            const dealID = this.state.selectedRows[index]['Deal No'];
            if (outgoing != null) {
                if (outgoing.DeliveryMethod === 'Wire Transfer' && outgoing.BeneficiaryBankAccountID != null && outgoing.NostroAccountID != null && outgoing.BeneficiaryBankAccountID > 0 && outgoing.NostroAccountID > 0) {
                    const model = {
                        dealID: dealID,
                        outgoingFund: outgoing.OutgoingFundsID,
                        currencyID: outgoing.CurrencyID,
                        paymentAmount: outgoing.PaymentAmount,
                        nostroAccountName: outgoing.NostroAccountName,
                        beneficiaryName: outgoing.BeneficiaryName,
                        beneficiaryAddress: outgoing.BeneAddress,
                        countryID: outgoing.CountryId,
                        beneficiaryState: outgoing.BeneState,
                        beneficiaryBankAccountID: outgoing.BeneficiaryBankAccountID,
                        interBank: outgoing.InterBank
                    };

                    const text = await formatToMT103(model);
                    finalText += text;
                    if (index !== this.state.ids.length) {
                        finalText += '\n';
                    }
                }
            }
        }
        finalText = finalText.substring(0, finalText.length - 1);
        const date = new Date();
        const [month, day] = formatDateToDDMMYYYY(date);
        const file = new Blob([finalText], { type: 'text/plain' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = `MT103-${month}${day}${date.getFullYear()}-deals.txt`;
        document.body.appendChild(element);
        element.click();
    }

    handleOnRefresh = () => {
        this.setState({ selectedRows: [], ids: [] });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    yesApproveAlert = async () => {
        this.setState({ showApproveAlert: false });
        approvalBoard(this.state.readyToApproveFunds).then(
            (json) => {
                if (json != null) {
                    switch (json.httpStatusCode) {
                        case 200:
                            if (json.Result) {
                                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Approval.', isApproval: true }, () => {
                                    this.setState({ isApproval: false })
                                })
                            }
                            break;
                        case 503:
                            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'Version Number not defined for Vostro' })
                            break;
                        default:
                            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to approval. Please try again..' })
                            break;
                    }
                }
            }
        )
    }

    closeApproveAlert = () => {
        this.setState({ showApproveAlert: false });
    }

    resetIdsAndSelectedRows = () =>
    {
        this.setState({
            ids         : [],
            selectedRows: []
        });
    }

    render() {
        return (
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Approval Board</h3>}
                <GenericBoard
                    board={BoardName.ApprovalBoard}
                    approvalFunction={this.handleApprovalCheckboxClick}
                    clickApproval={this.clickApproval}
                    clickGenerateMT103={this.handleGenerateMT103Click}
                    isApproval={this.state.isApproval}
                    selectedRows={this.state.selectedRows}
                    multiselect={true}
                    onRefresh={this.handleOnRefresh}
                    activeSelectAll={true}
                    mainTitle={"Approval Board"}
                    isShowTitle={this.isShowTitle}
                />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showApproveAlert} onClose={this.closeApproveAlert} title="Are you sure to approve the selected payments?" message="This action can not be undone." type="Yes/No" yesClick={this.yesApproveAlert} noClick={this.closeApproveAlert} />
            </div>
        );
    }
}
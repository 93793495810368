import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF'
  },
  section: {
    backgroundColor: 'lightblue',
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

export default class TestConfirmation extends React.Component {

  render = () => {

    return(
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>Página 1</Text>
            <Text>{this.props.text1}</Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>{this.props.text2}</Text>
          </View>
        </Page>
      </Document>
    );

  }
}
import { useState, useEffect } from "react";
import { getActiveTradersMin } from "../../../../../networking/NetworkingTrader";
import { getTraders, getTradersWithBlotter } from "../../../../../networking/NetworkingBlotters";

const useActiveTraders = () => {
    const [traders, setTraders] = useState([]);
    
    useEffect(() => {
        const getActiveTraders = async () => {
            /*const json = await getActiveTradersMin();
            if (json != null) {
                const json2 = await getTraders(); // List of traders without assigned Blotter
                if (json2.traders != null) {
                    const activeTradersWithBlotter = json.traders
                        .filter(({ TraderID }) => json2.traders.some((traderWithoutBlotter) => TraderID === traderWithoutBlotter.TraderId))
                        .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
                    setTraders(activeTradersWithBlotter);
                }
            }*/
            getTradersWithBlotter().then( // List of traders without assigned Blotter
            (responseGetTraders) => {
                if (responseGetTraders.traders !== undefined && responseGetTraders.traders !== null)
                {
                    let activeTradersWithBlotter = responseGetTraders.traders
                    .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
    
                    setTraders(activeTradersWithBlotter);
                }
            }
        );
        }
        getActiveTraders();
    }, []);
    return traders
}




export default useActiveTraders;

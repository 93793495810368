import React from 'react';

//import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';

import {getStyle} from '../../../styles/styles';
import TradingHolidaysScreen from './TradingHolidaysScreen';
import ValueDateHolidaysScreen from './ValueDateHolidaysScreen';
import CarryOverValueDateHolidaysForm from './CarryOverValueDateHolidaysForm';
var style = getStyle();



export default class HolidaysMaintenanceScreen extends React.Component {
    state = {
        
    }

    render(){
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Holidays</h3>
                <ul uk-tab="">
                    <li>
                        <a href="#" id="trading-holidays">Trading Holidays</a>
                    </li>
                    <li>
                        <a href="#" id="value-date-holidays">Value Date Holidays</a>
                    </li>
                    {/*<li>
                        <a href="#" id="holiday-carry-over">Holiday Carry Over</a>
                    </li>*/}
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li>
                        <TradingHolidaysScreen/>
                    </li>
                    <li>
                        <ValueDateHolidaysScreen/>
                    </li>
                    {/*<li>
                        <CarryOverValueDateHolidaysForm/>
                    </li>*/}
                </ul>
            </div>
        );
    }
}
import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericDeleteWithParameters, genericCallWithBody } from './Networking';


//Pricing/SavePricingCategories
export async function addCategory(addcategory) {
    try{
        let response = await genericCallWithBody('POST','Pricing/SavePricingCategories',addcategory);
        return response;
    } catch (error) {
        console.error(error);
    }
    /*try {        
        console.log(addcategory);
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Pricing/SavePricingCategories',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(addcategory),
        });
        let responseJson = await response.json();
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }*/
}

//Pricing/SaveSpotPricingDetails
export async function addSpot(addSpotDetail) {
    try{
        let response = await genericCallWithBody('POST','Pricing/SaveSpotPricingDetails',addSpotDetail);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Pricing/SaveForwardPricingDetails
export async function addForward(addForwardDetail) {
    try{
        let response = await genericCallWithBody('POST','Pricing/SaveForwardPricingDetails',addForwardDetail);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Pricing/DeletePricingCategories -- DELETE with BODY
export async function deleteCategory(putCategory, userID) {
    try{
        let params = {
            'CategoryID': putCategory,
            'UserId': userID
        };
        let response = await genericCallWithBody('DELETE','Pricing/DeletePricingCategories',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Pricing/DeleteSpotPricingDetails
export async function deleteSpot(putSpot) {
    try{
        let params = {
            'pricingCategoryDetailID': putSpot
        };
        let response = await genericDeleteWithParameters('Pricing/DeleteSpotPricingDetails',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Pricing/DeleteForwardPricingDetails?pricingCategoryDetailID={pricingCategoryDetailID}
export async function deleteForward(putForward) {
    try{
        let params = {
            'pricingCategoryDetailID': putForward
        };
        let response = await genericDeleteWithParameters('Pricing/DeleteForwardPricingDetails',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPricingCategory(){
    try {
        let params = {}
        let response = await genericGetWithParameters('Pricing/GetPricingCategories',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
//Get Category
export async function getPricingCategoryByID(id){
    try {
        let params = {
            'categoryId': id
        }
        let response = await genericGetWithParameters('Pricing/GetPricingCategoriesById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Get Spot
export async function getPricingSpotByID(id){
    try {
        let params = {
            'pricingCategoryDetailID': id
        }
        let response = await genericGetWithParameters('Pricing/GetSpotPricingDetailsById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Get Forward
export async function getPricingForwardByID(id){
    try {
        let params = {
            'pricingCategoryDetailID': id
        }
        let response = await genericGetWithParameters('Pricing/GetForwardPricingDetailsById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Get SpotList
export async function getSpotbyCategoryID(categoryID){
    try {
        let params = {
            'categoryId' : categoryID
        }
        let response = await genericGetWithParameters('Pricing/GetSpotPricingDetails',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Get ForwardList
export async function getForwardbyCategoryID(categoryID){
    try {
        let params = {
            'categoryId' : categoryID
        }
        let response = await genericGetWithParameters('Pricing/GetForwardPricingDetails',params);
        console.log(response);
        return response;
    } catch (error) {
        console.error(error);
    }
}
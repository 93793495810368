import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import AlertBox from '../../../shared/AlertBox';
import {BeneficiaryType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
import { Breadcrumb } from 'antd';
import AuditEntityPropertyScreen from '../auditEntity/AuditEntityPropertyScreen';
var style = getStyle();

export default class BeneficiaryAdditionalProperties extends React.Component {
    state = {
        beneficiaryID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
        errors: [],

    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
        this.props.getSaveProperties(false);
        this.props.showFinalAlert();
    }

    onFinishEntityPropertiesSaveProcess(status,message,pendingMandatoryList){
        if(status){
            this.props.getSaveProperties(false);
            this.props.showFinalAlert();
            //this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Success.', alertMessage: 'Customer Properties saved.', errors: pendingMandatoryList});
        }else{
            this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Warning', alertMessage: message, errors: pendingMandatoryList});
        }
        //this.props.getSaveProperties(false);
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.saveProperties !== prevProps.saveProperties){
            if(this.props.beneficiaryID === ''){
                this.props.getSaveProperties(false);
                this.props.showFinalAlert();
            }
        }
    }

    handleShowHistoryClick = () => {
        this.setState({ showBreadCrumb: true });
    }

    handleBackLink = () => {
        this.setState({ showBreadCrumb: false });
    }

    render(){
        let componentID = 'entity-properties-beneficiary'+ this.props.beneficiaryID;
        var section = (
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties id={componentID} fullScreen={true} entityType={BeneficiaryType} entityID={this.props.beneficiaryID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)}>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleShowHistoryClick} type="button">View History</button>
                        </EntityProperties>
                    </div>
                </form>
            )
        if(this.props.beneficiaryID === ''){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create beneficiary first.</h5>
                </div>
            )
        }
        if (this.props.beneficiaryID && this.state.showBreadCrumb) {
            section = (
                <div>
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink}>Additional Properties</Breadcrumb.Item>
                            <Breadcrumb.Item className="breadcrumb-item-bold">Audit History</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <AuditEntityPropertyScreen entityID={this.props.beneficiaryID} entityType="Beneficiary" isTitle />;
                </div>
            );
        }
        return(
            <div>
            {section}
            <AlertBox 
                open={this.state.showAlert} 
                onClose={this.closeAlert} 
                title={this.state.alertTitle} 
                message={this.state.alertMessage} 
                type="Ok" 
                messageBoldList={this.state.errors}
                okClick={this.closeAlert}/>
            </div>
        );
    }
}
import React from 'react';
import Lottie from "lottie-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoadingBox from './../LoadingBox';
import AlertBox from './../../shared/AlertBox';

import uploadAnimation from './../../../animations/upload-files.json';

import './../../../css/attachments-form.scss';

export default class GeneralDragAndDropFileDialog extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.fileInput = React.createRef();
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    state = {
        loading     : false,
        showAlert   : false,
        validated   : false,
        showAlertG  : false,
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps){

    }

    handleUploadFile(event) 
    {
        if(this.state.validated)
        {
            this.setState({
                loading: true
            }, () =>{
                event.preventDefault();
                var file = this.fileInput.current.files[0];

                this.readFileAsBase64(file).then((resolve, reject)=>{
                    console.log(resolve);
                    var fileContent = resolve.replace(/^data:.+;base64,/, '');

                    this.props.onUploadFile(file.name, fileContent);

                    this.setState({
                        loading     : false
                    }, () =>{
                        this.props.onClose();
                    });
                });
            });
        }else
        {
            this.setState({
                showAlertG: true
            })
        }
    }

    readFileAsBase64(file){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    }

    validateFile = (event) =>{
        if(!event || !event.target || !event.target.files || event.target.files.length === 0) 
        {
            this.setState({
                validated: false
            });
        }
        
        let name = event.target.files[0].name;
        let lastDot = name.lastIndexOf('.');
    
        let fileName = name.substring(0, lastDot);
        let ext = name.substring(lastDot + 1);

        console.log(this.props.validExtensions.indexOf(ext))

        if(this.props.validExtensions.indexOf(ext) < 0)
        {
            this.fileInput.current.value ='';
            this.setState({
                showAlert: true,
                validated: false
            });
        }else
        {
            this.setState({
                validated: true
            });
        }
    }

    onChangeInputShowwingName = () =>{

    }

    render() {
        return(
            <div>
                <Dialog open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle>Upload File</DialogTitle>
                    <DialogContent>
                        <div uk-form-custom="target: true" className="uk-margin file-box">
                            <Lottie animationData={uploadAnimation} style={{height: '200px'}}/>
                            <h5>Click or Drag&Drop the file you want to save</h5>
                            <input
                                type        = "file"
                                ref         = {this.fileInput}
                                className   = "file_upload"
                                onChange    = {this.validateFile}
                            />
                            <input 
                                className   = "uk-input uk-form-width-large" 
                                type        = "text" 
                                placeholder = "Select a file..." 
                                disabled    = {true}
                                onChange    = {this.onChangeInputShowwingName}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={this.props.onClose}>Cancel</button>
                        <button className="uk-button uk-button-green" key={"upload"} type="button" onClick={this.handleUploadFile}>Upload</button>
                    </DialogActions>
                </Dialog>
                <LoadingBox loading={this.state.loading}/>
                <AlertBox 
                    id      = "validation-file-type" 
                    type    = "Ok" 
                    open    = {this.state.showAlert} 
                    title   = 'Error'
                    message = {'The uploaded file is not valid. Please enter a new one (' + this.props.validExtensions + ').'}
                    okClick = {() =>{this.setState({showAlert: false})}}
                    onClose = {() =>{this.setState({showAlert: false})}}
                />
                <AlertBox 
                    id      = "general-alert-fileDialog" 
                    type    = "Ok" 
                    open    = {this.state.showAlertG} 
                    title   = 'Error'
                    message = {'Select a file of type (' + this.props.validExtensions + ') first.'}
                    okClick = {() =>{this.setState({showAlertG: false})}}
                    onClose = {() =>{this.setState({showAlertG: false})}}
                />
            </div>
        );
    }

}
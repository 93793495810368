import { genericCallWithBody, genericGetWithParameters, genericDeleteWithParameters } from '../networking/Networking';

export const searchTransactionUpload = async (customerId, fromDate, toDate) => {
    try {
        const params = { customerIdMSB: customerId, fromDate: fromDate , toDate: toDate};
        const response = await genericGetWithParameters('TransactionUpload/SearchTransactionUpload', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getTransactionUploadDetails = async (transactionUploadId) => {
    try {
        const params = { transactionUploadId: transactionUploadId };
        const response = await genericGetWithParameters('TransactionUpload/TransactionUpload_GetDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteFileUploaded(transactionUploadId) {
    try {
        let params = {
            'transactionUploadId': transactionUploadId
        };
        let response = await genericDeleteWithParameters('TransactionUpload/TransactionUpload_Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateTransactionDetailComment(transactionUploadId, detailId, comment, updatedBy, updateToken) {
    var method = 'PUT';
    var apiName = 'TransactionUpload/TransactionUpload_UpdateComment';
    var request = {
        TransactionUploadId: transactionUploadId,
        DetailId: detailId,
        Comment: comment,
        UpdatedBy: updatedBy,
        UpdateToken: updateToken
    }
    try {
        let response = await genericCallWithBody(method, apiName, request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateFiletransactionUpload(transactionUploadId, status) {
    var method = 'POST';
    var apiName = 'TransactionUpload/TransactionUploadUpdate';
    var request = {
        TransactionUploadId: transactionUploadId,
        Status: status
    }
    try {
        let response = await genericCallWithBody(method, apiName, request);
        return response;
    } catch (error) {
        console.error(error);
    }
}


import {
    genericGetWithParameters, 
    genericCallWithBody, 
    genericDeleteWithParameters, 
    genericCallWithParametersNoEncrypted
} from './Networking';

export async function getTranslationsByTypeAndLabelID(labelType, labelID)
{
    try {
        let params = {
            LabelType   : labelType,
            LabelID     : labelID
        };
        let response = await genericGetWithParameters('Translations/GetTranslationsByTypeAndLabelID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveTranslation(model)
{
    try {
        let response = await genericCallWithBody('POST','Translations/SaveTranslation', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteATranslation(translationID){    
    try{
        let params = {
            'TranslationID': translationID
        };
        let response = await genericDeleteWithParameters('Translations/DeleteATranslation', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
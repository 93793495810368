import { useState, useEffect } from "react";
import { getCurrencyList } from "../../../../networking/NetworkingCurrencies";

const useCurrenciesList = () => {
    const [currencies, setCurrencies] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);

    useEffect(()=>{
        let isCancelled = false;
        const getBaseCurrencyList = async () =>{
            const json = await getCurrencyList();
            if(!isCancelled){
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    setCurrencies( currencies );
                    setNeedsReloading(false);
                }
            }
        }
        if(needsReloading){        
            getBaseCurrencyList();
        }
        return () => {isCancelled = true};
    }, [needsReloading]);

    return [currencies, setNeedsReloading];
}

export default useCurrenciesList;
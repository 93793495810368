import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Space, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Tag, Table, Typography, Switch } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import AlertBox from '../../shared/AlertBox';
import { getStyle } from '../../../styles/styles';
import AppContext from '../../../AppContext';
import { GetGlobalSystemParameters, saveRFQ } from '../../../networking/NetworkingGlobalSystemParameters';
import NewEditableGrid from '../../shared/NewEditableGrid';

const { Option } = Select;
const { TextArea } = Input;

const style = getStyle();

const parameterTypes = [
  {label: 'Date', value: 'D'},
  {label: 'Text', value: 'T'},
  {label: 'Numeric', value: 'N'},
  {label: 'Boolean', value: 'B'},
  {label: 'Integer', value: 'I'},
  {label: 'Guid', value: 'G'},
  {label: 'Character', value: 'C'},
]

const getStringOfLookupdetails = (list) =>{
  let helper = [];
  if (Array.isArray(list))
  {
    list.forEach((el) => {
      helper.push(el.Description);
    });
  }
  return helper.join();
}

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}

const NewGlobalSystemParametersScreen = () => {
  const context = useRef(useContext(AppContext));

  const [globals, setGlobals] = useState([]);
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);          // Para saber cuando volver a cargar el useEffect
  const [currentPage, setCurrentPage] = useState();

  //Edit
  const [editingKey, setEditingKey] = useState('');
  const [currentType, setcurrentType] = useState('');
  const isEditing = (record) => record['Parameter Name'] === editingKey;
  const [checkedMandatory, setCheckedMandatory] = useState(false);
  const [checkedCaseValueBoolean, setCheckedCaseValueBoolean] = useState(null);
  //

  //Add
  const [isEditableName, setIsEditableName] = useState(false);
  const [newGlobalParameterObj, setNewGlobalParameterObj] = useState(null);
  //

  //Search
  const [filteringSomething, setFilteringSomething] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchArray, setSearchArray] = useState({});
  const searchInput = useRef();

  //Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  //

  useEffect(()=>{
    const loadGlobalSystemParameters = async () => {
      context.current.startLoading();
      const json = await GetGlobalSystemParameters(0, 0);

      if (json != null && json.globalParameters != null) 
      {
        let auxGP = json.globalParameters;
        auxGP.forEach((globalParameter, index) => {
          if(globalParameter.Description === null)
          {
            globalParameter.Description = '';
          }
        });
        /*
          {
            "response": {
              "totalCount": 225,
              "globalParameters": [
                {
                  "Parameter Name": "AAA",
                  "Parameter Value": "1994/09/23",
                  "Data Type": "D",
                  "Description": "date of A",
                  "Tab": 1,
                  "Display Order": null,
                  "LookUpTableID": null,
                  "Mandatory": null,
                  "lookupdetails": null
                },
                {},{},{},{},{},{},{},{},{},{},{},{},{},{},...{}
              ]
            },
            exp": 1624477523
          }
        */
        setGlobals(auxGP);
      }

      context.current.finishLoading();
    }
    loadGlobalSystemParameters();
  },[updating]);

  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setFilteringSomething(true);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilteringSomething(false);
  };

  const settingSearchStateValues = (value, col) => {
    setSearchText(value);
    setSearchedColumn(col);
    setSearchArray(...(searchArray[col] = value));
    setFilteringSomething(true);
  };
  //

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const onChangeMandatory = (e) => {
      setCheckedMandatory(e.target.checked);
      form.setFieldsValue({
        'Mandatory' : e.target.checked,
      });
    }

    const onChangeDataType = (value) => {
      setcurrentType(value);
      let actualValue = null;
      switch (value) 
      {
        case 'D':
        case 'T':
        case 'N':
        case 'I':
        case 'G':
        case 'C':
        {
          actualValue = '';
          break;
        }
        case 'B':
        {
          actualValue = false;
          break;
        }
      }

      setCheckedCaseValueBoolean(false);

      form.setFieldsValue({
        'Parameter Value' : actualValue,
        'Data Type'       : value
      });
    }
  
    let inputNode = null;
  
    if(editing)
    {
      switch (inputType) 
      {
          case 'number':
          {
            inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
            break;
          }
          case 'select':
          {
            let allOptions = [];
    
            if(Array.isArray(record.lookupdetails))
            {
              record.lookupdetails.forEach((lookupdetail, index) => {
                allOptions.push(
                  <Option 
                    key   = {index + lookupdetail['Description']} 
                    value = {lookupdetail['Description']}
                  >{lookupdetail['Description']}
                  </Option>
                );
              });
            }
    
            inputNode = (
              <Select
                  showSearch
                  style               = {{ width: '100%' }}
                  placeholder         = "Select..."
                  //optionFilterProp    = "children"
                  /*filterOption        = {
                      (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }*/
              >
                {allOptions}
              </Select>
            );
            break;
          }
          case 'select-datatype':
          {
            let allOptions = [];

            parameterTypes.forEach((parameterType, index) => {
              allOptions.push(
                <Option 
                  key   = {index + parameterType.value} 
                  value = {parameterType.value}
                >{parameterType.label}
                </Option>
              );
            });

            inputNode = (
              <Select
                  showSearch
                  style       = {{ width: '100%' }}
                  placeholder = "Select a Type..."
                  onChange    = {onChangeDataType}
                  //optionFilterProp    = "children"
                  /*filterOption        = {
                      (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }*/
              >
                {allOptions}
              </Select>
            );
            break;
          }
          case 'check':
          {
            inputNode = <Checkbox onChange={onChangeMandatory} checked={checkedMandatory}></Checkbox>;
            break;
          }
          case 'textarea':
          {
            inputNode = <TextArea />;
            break;
          }
          case 'adaptable':
          {
            if(record.LookUpTableID !== undefined && record.LookUpTableID !== null && record.LookUpTableID !== '') 
            {
              let allOptions = [];
      
              if(Array.isArray(record.lookupdetails))
              {
                record.lookupdetails.forEach((lookupdetail, index) => {
                  allOptions.push(
                    <Option 
                      key   = {index + lookupdetail['Description']} 
                      value = {lookupdetail['Description']}
                    >{lookupdetail['Description']}
                    </Option>
                  );
                });
              }
      
              inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                  {allOptions}
                </Select>
              );
            }else
            {
              let whatSwitch = '';
              whatSwitch = currentType === '' ? record['Data Type'] : currentType
              switch (whatSwitch) 
              {
                case 'D':
                {
                  inputNode = <Input style={{textAlign: 'center'}}/>;
                  break;
                }
                case 'I':
                case 'G':
                case 'C':
                case 'T':
                {
                  inputNode = <Input style={{textAlign: 'center'}}/>;
                  break;
                }
                case 'N':
                {
                  inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                  break;
                }
                case 'B':
                {
                  if(checkedCaseValueBoolean === null)
                  {
                    if(record['Parameter Value'] === 'true')
                    {
                      form.setFieldsValue({
                        'Parameter Value' : true,
                      });
                      setCheckedCaseValueBoolean(true);
                    }else
                    {
                      setCheckedCaseValueBoolean(false);
                    }
                  }

                  inputNode = <Checkbox
                    checked   = {checkedCaseValueBoolean}
                    onChange  = {(e)=>{
                      form.setFieldsValue({
                        'Parameter Value' : e.target.checked,
                      });
                      setCheckedCaseValueBoolean(e.target.checked);
                    }}
                    ></Checkbox>;
                  break;
                }
              }
            }
            break;
          }
          default:
          {
            inputNode = <Input style={{textAlign: 'center'}}/>;
            break;
          }
      }
    }

    let auxRules = [];

    if(dataIndex !== 'LookUpTableID')
    {
      auxRules = [
        {
          required: true,
          message : `Please Input ${title}!`,
        },
      ]
    }
  
    return (
      <td 
        key = {inputType + dataIndex} 
        id  = {inputType + dataIndex}
        {...restProps}
      >
        {editing ? (
          <Form.Item
            name  = {dataIndex}
            rules = {auxRules}
            style = {{
              margin: 0,
            }}
          >{inputNode}
          </Form.Item>
        ) : (dataIndex === 'lookupdetails' ? (getStringOfLookupdetails(record.lookupdetails)) : (Array.isArray(children[1]) ? getStringOfLookupdetails(children[1]) : children))
        }
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      'Parameter Value': record['Data Type'] === 'B' ? (record['Parameter Name'] === 'true' ? true: false) : '',
      'Data Type': '',
      'Description': '',
      'Tab': 1,
      'Display Order': 1,
      'Mandatory' : record['Mandatory'],
      'LookUpTableID': '',
      ...record,
    });

    setEditingKey(record['Parameter Name']);
    setCheckedMandatory(record['Mandatory']);
  };

  const cancelEdit = () => {
    setEditingKey('');
    setCheckedCaseValueBoolean(null);
    if(globals[0]['isNew'] === true)
    {
      let auxGlobals = [...globals];
      auxGlobals.shift();
      setGlobals(auxGlobals);
      setIsEditableName(false);
    }
  };

  const onChangePagination = (page, pageSize) => {
    setEditingKey('');
    if(typeof page === 'number')
    {
      setCurrentPage(page);
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      //console.log("save - validateFields -> ", row);

      /*{
        Data Type: "T"
        Description: "Used by Trade screen drop down and Disbursement screen. If the user selects a bene from the dropdown from the trade screen it will put that value as the instrument in the outgoing or if the user selects a bene and press the disburse button , it will show that instrument in the Instrumnet Dropdown in the Disbursement screen."
        Display Order: 1
        LookUpTableID: "3"
        Mandatory: false
        Parameter Value: "Electronic Funds Transfer"
        Tab: 1
      }*/

      let model = {
        ParameterName         : editingKey === '' ? row['Parameter Name'] : editingKey,
        ParameterValue        : row['Parameter Value'],
        ParameterValueDataType: row['Data Type'],
        ParameterDescription  : row['Description'],
        Tab                   : row['Tab'],
        DisplayOrder          : row['Display Order'],
        Mandatory             : row['Mandatory'],
        LookUpTableID         : row['LookUpTableID'],
      }
      //console.log("save - model -> ", model);

      context.current.startLoading();
      const saveRFQResponse = await saveRFQ(model);
      //console.log('saveRFQ ->', saveRFQResponse);
      /*
        Result: true
        httpErrorMessage: undefined
        httpStatusCode: 200
      */
      context.current.finishLoading();

      let title = 'Error';
      let message = 'Something went wrong saving Global Parameter. Please try again later.';

      if(saveRFQResponse !== undefined)
      {
        if(saveRFQResponse.httpStatusCode === 200 && saveRFQResponse.Result)
        {
          title = 'Success';
          message = 'Global Parameter successfully saved.';
        }else
        {
          if(saveRFQResponse.Message !== undefined && saveRFQResponse.Message !== null && saveRFQResponse.Message !== '')
          {
            message = saveRFQResponse.Message;
          }
        }

        setAlertTitle(title);
        setAlertMessage(message);
        setShowAlert(true);

        if(title === 'Success')
        {
          setUpdating(!updating);
          setEditingKey('');
          setCheckedCaseValueBoolean(null);
        }
      }else
      {
        setAlertTitle('Error');
        setAlertMessage('Something went wrong. Please try again later.');
        setShowAlert(true);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref         = {(node) => {
            searchInput.current = node;
          }}
          placeholder = {`Search ${dataIndex}`}
          value       = {selectedKeys[0]}
          onChange    = {(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width       : 188,
            marginBottom: 8,
            display     : 'block'
          }}
        />
        <Space>
          <Button 
            type    = "primary" 
            onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)} 
            icon    = {<SearchOutlined />} 
            size    = "small" 
            style   = {{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
                handleReset(clearFilters);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type    = "link"
            size    = "small"
            onClick = {() => {
              confirm({ closeDropdown: false });
              settingSearchStateValues(selectedKeys[0], dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : ( text )
    )
  });

  const columns = [
    {
      title     : 'Parameter Name',
      dataIndex : 'Parameter Name',
      width     : '20%',
      editable  : isEditableName,
      sorter    : (a, b) => {
        return a['Parameter Name'].localeCompare(b['Parameter Name']);
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Parameter Name')
    },
    {
      title     : 'Parameter Value',
      dataIndex : 'Parameter Value',
      width     : '10%',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => {
        return a['Parameter Value'].localeCompare(b['Parameter Value']);
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Parameter Value')
    },
    {
      title     : 'Data Type',
      dataIndex : 'Data Type',
      width     : '6%',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => {
        return a['Data Type'].localeCompare(b['Data Type']);
      },
      sortDirections: ['descend', 'ascend'],
      render        : (value, obj, index) => (
        <Tag key={index + value}>
          {parameterTypes.find(element => element.value === value).label}
        </Tag>
      )
    },
    {
      title     : 'Description',
      dataIndex : 'Description',
      width     : '25%',
      editable  : true,
      sorter    : (a, b) => {
        return a['Description'].localeCompare(b['Description']);
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Description')
    },
    {
      title     : 'Tab',
      dataIndex : 'Tab',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => a.Tab - b.Tab,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title     : 'Display Order',
      dataIndex : 'Display Order',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => a['Display Order'] - b['Display Order'],
      sortDirections: ['descend', 'ascend'],
    },
    {
      title     : 'Mandatory',
      dataIndex : 'Mandatory',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => {
        return a['Mandatory'].localeCompare(b['Mandatory']);
      },
      sortDirections: ['descend', 'ascend'],
      render        : (value, obj, index) => (
        <Tag color={value? 'blue' : 'red'} key={value + index}>
          {value? 'Yes' : 'No'}
        </Tag>
      )
    },
    {
      title     : 'LookUp Table ID',
      dataIndex : 'LookUpTableID',
      editable  : true,
      align     : 'center',
      sorter    : (a, b) => a['LookUpTableID'] - b['LookUpTableID'],
      sortDirections: ['descend', 'ascend'],
    },
    {
      title     : 'LookUp Details',
      dataIndex : 'lookupdetails',
      editable  : false,
      sorter    : (a, b) => {
        let aValue = a['lookupdetails'];
        let bValue = b['lookupdetails'];
        if(!aValue){
            aValue='';
        }
        if(!bValue){
            bValue='';
        }
        return aValue.toString().localeCompare(bValue.toString());
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title     : 'Actions',
      dataIndex : 'Actions',
      align     : 'center',
      width     : '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              onClick = {() => {save(record['Parameter Name'])}}
              style   = {{
                marginRight: 8,
              }}
            >Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancelEdit}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link 
            disabled = {editingKey !== '' ? true: (globals[0]['Parameter Name'] === '') ? true: false}
            onClick  = {() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    const getType = (record) => {
      let type = '';
      switch (col.dataIndex) 
      {
        case 'Tab':
        case 'Display Order':
        {
          type = 'number';
          break;
        }
        case 'Mandatory':
        {
          type = 'check';
          break;
        }
        case 'Data Type':
        {
          type = 'select-datatype';
          break;
        }
        case 'Description':
        case 'LookUp Details':
        {
          type = 'textarea';
          break;
        }
        case 'Parameter Value':
        {
          type = 'adaptable'
          break;
        }
        default:
        {
            type = 'text'
            break;
        }
      }
      return type;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: getType(record),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return(
      <div>
        <h3 className="uk-heading-divider" style={style.titleStyle} >Global System Parameters</h3>
        {/* <NewEditableGrid
          data={globals}
        /> */}

        <Form form={form} component={false}>
          <Table
            components   = {{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource   = {globals}
            columns      = {mergedColumns}
            rowClassName = "editable-row"
            size         = 'small'
            pagination   = {{
              onChange        : onChangePagination,
              showQuickJumper : true,
              itemRender      : itemRender,
              current         : currentPage,
              defaultCurrent  : 1,
              defaultPageSize : 15,
              pageSizeOptions : [10, 15, 20, 30, 40, 50],
              size            : 'default'
            }}
            footer          = {() => {
              return(
                <Button 
                  type        = "dashed" 
                  onClick     = {() => {
                      let actual = [...globals];
                      if(actual[0]['Parameter Name'] === '')
                      {
                        return;
                      }else{
                        setIsEditableName(true);
                        actual.unshift({
                          'key'             : 'globalsystemparameter-new',
                          "Parameter Name"  : "",
                          "Parameter Value" : "",
                          "Data Type"       : "T",
                          "Description"     : "",
                          "Tab"             : 1,
                          "Display Order"   : 1,
                          "LookUpTableID"   : null,
                          "Mandatory"       : false,
                          "lookupdetails"   : null,
                          'isNew'           : true,
                        });
                        setCheckedMandatory(false);
                        setCheckedCaseValueBoolean(false);
                        setGlobals(actual);
                        setCurrentPage(1);
                        form.setFieldsValue({
                          "Parameter Name"  : "",
                          'Parameter Value': '',
                          'Data Type': '',
                          'Description': '',
                          'Tab': 1,
                          'Display Order': 1,
                          'Mandatory' : false,
                          'LookUpTableID': '',
                          ...actual
                        });
                      }
                  }}
                  block
                  icon        = {<PlusOutlined />}
                  style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  disabled    = {
                    editingKey !== '' || (globals[0] !== undefined && globals[0]['Parameter Name'] === '') || filteringSomething                                
                  }
                >Add a Global System Parameter
                </Button>
              );
            }}
          />
        </Form>

        <AlertBox 
          id      = "alert-new-globals-system-parameters" 
          open    = {showAlert} 
          onClose = {()=>setShowAlert(false)} 
          title   = {alertTitle} 
          message = {alertMessage} 
          type    = "Ok" 
          okClick = {()=>setShowAlert(false)} 
        />
      </div>
  );
}

export default NewGlobalSystemParametersScreen;
import React from 'react';
import { Multiselect } from 'react-widgets'

export default class MultipleSelect extends React.Component {
    state = {
    }

    render = () => {
        return(
            <div>
                <Multiselect data={this.props.data} textField={this.props.textField} valueField={this.props.valueField}  value={this.props.value} onChange={this.props.onChange} />
            </div>
        );
    }
}
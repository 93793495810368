import React from 'react';

import {salespeoplePerformance} from '../../../networking/NetworkingWidget';

import { Pie } from '@ant-design/charts';

import {formatToCurrency} from '../../../helpers/FormatHelper';
export default class SalespeoplePerformancePie extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = { 
            salesVolumesList    : [],
            //Checks
            ytd                 : true,
            last12months        : false
        };
    }

    componentDidMount()
    {
        this.getSalesVolumes('YTD');
    }

    getSalesVolumes(period)
    {
        salespeoplePerformance(period).then(
            (response) => {
                if(response !== undefined)
                {
                    console.log("salespeoplePerformance: " + JSON.stringify(response));

                    /*
                        salespeoplePerformance: {
                            "SalesVolumes":[
                                {
                                    "Salesperson":"Admin Admin",
                                    "Period Total":66453.8,
                                    "Period Profit":1500.11,
                                    "Monthly Total":0,
                                    "Base Currency":"USD"
                                },
                                {
                                    "Salesperson":"Daniel Carranza",
                                    "Period Total":2207992.65,
                                    "Period Profit":2922.78,
                                    "Monthly Total":0,
                                    "Base Currency":"USD"
                                },
                                {
                                    "Salesperson":"Test Trader",
                                    "Period Total":120794.23,
                                    "Period Profit":2164.38,
                                    "Monthly Total":0,
                                    "Base Currency":"USD"
                                }
                            ],
                            "httpStatusCode":200
                        }
                    */

                    /*
                        "Last12MonthsTurnover":[
                            {
                                "year":2020,
                                "month":1,
                                "MonthText":"Jan",
                                "MonthlyTotal":0,
                                "baseccy":"USD",
                                "yyyyMM":"Jan/20"
                            },
                            {}
                        ]
                    */

                    if(response.SalesVolumes !== undefined)
                    {
                        response.SalesVolumes.forEach(element => {
                            element.id    = element.Salesperson + ' ' + element['Base Currency'];
                            element.label = element.Salesperson
                            element.value = element['Period Total'];
                        });

                        this.setState({
                            salesVolumesList: response.SalesVolumes
                        });
                    }
                }
            }
        );
    }

    getDefaultColor = (value) => 
    {
        var color = '#8ac43f';
        return color;
    }

    handleUpdateYTD = (event) => {
        this.setState({
            ytd         : event.target.checked,
            last12months: !event.target.checked
        }, () =>{
            if(this.state.ytd === true)
            {
                this.getSalesVolumes("YTD");
            }else
            {
                this.getSalesVolumes("Last 12 months");
            }
        });
    }

    handleUpdateLast12Months = (event) => {
        this.setState({
            last12months: event.target.checked,
            ytd         : !event.target.checked
        }, () =>{
            if(this.state.last12months === true)
            {
                this.getSalesVolumes("Last 12 months");
            }else
            {
                this.getSalesVolumes("YTD");
            }
        });
    }

    render = () => {

        var config = {
            appendPadding: 10,
            data: this.state.salesVolumesList,
            angleField: 'Period Total',
            colorField: 'Salesperson',
            radius: 1,
            innerRadius: 0.64,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{percentage}',
            },
            /*meta: {
                value: {
                  formatter: function formatter(v) {
                    return v;
                  },
                },
              },*/
            interactions: [
   
                { type: 'element-active' },
            ],
            statistic: {
                title: false,
                content: {
                  style: {
                    fontSize: 20,
                  },
                  formatter: function formatter(objSelected, totalList) 
                  {
                        /* ----------------------------- objSelected
                            Base Currency: "USD"
                            Monthly Total: 68.833955
                            Period Profit: 0
                            Period Total: 68.833955
                            Salesperson: "Willy Oshiro"
                            Scale: 10000
                            id: "Willy Oshiro USD"
                            label: "Willy Oshiro"
                            value: 68.833955
                        */
                        /* ----------------------------- totalList
                            0: {Salesperson: "Daniel Carranza", Period Total: 13.862832, Period Profit: 43442.96, Monthly Total: 13.862832, Base Currency: "USD", …}
                            1: {Salesperson: "Test Trader", Period Total: 0.223321, Period Profit: 0.16, Monthly Total: 0.223321, Base Currency: "USD", …}
                            2: {Salesperson: "Willy Oshiro", Period Total: 68.833955, Period Profit: 0, Monthly Total: 68.833955, Base Currency: "USD", …}
                        */
                        if(objSelected === undefined)
                        {
                            let sumtotal = 0;
                            totalList.forEach(element => {
                                sumtotal += element.value;
                            });
                            return (
                                "<div style='display: flex;flex-direction: column;'><label style='font-size: 12px'>Total</label><label>" + sumtotal + "</label></div>"
                            );
                        }else
                        {
                            return (objSelected.value);
                        }
                  }
                }
            }
        };

        return(
            <div
                style={{
                    width           : this.props.width,
                    height          : this.props.height,
                    //overflow        : 'auto',
                    flexDirection   : 'column',
                    paddingTop      : 10,
                    display         : 'flex',
                    alignItems      : 'stretch',
                    margin          :'0 auto',
                }}
            >
                <span 
                    className ='title-widget'
                >Salespeople Performance for {this.state.ytd === true? 'YTD':'last 12 months'}
                </span>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%', // 30, 70, 110, 150, 
                    margin          : 0,
                    overflow        : 'hidden',
                    //backgroundColor : 'yellow'
                    display         : 'flex',
                    flexDirection   : 'column',
                    textAlign       : 'center'
                }}
            >
                <div 
                    style={{
                        width           : '100%', 
                        height          : '100%',
                        margin          : 0,
                        overflow        : 'hidden',
                        paddingTop      : 10,
                        paddingBottom   : 5
                    }}
                >
                    <Pie 
                        {...config} 
                        style={{
                            height  : '100%', 
                            width   :'100%'
                        }}
                        loading={this.state.loadingData}
                    />
                </div>

                <div style={{display: 'flex'}}>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.ytd} 
                                onChange    = {this.handleUpdateYTD} 
                                type        = "checkbox"
                            /> YTD
                        </label>
                    </div>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.last12months} 
                                onChange    = {this.handleUpdateLast12Months} 
                                type        = "checkbox"
                            /> Last 12 months
                        </label>
                    </div>
                </div>
            </div>
          </div>
        );
        
        /*return(
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    overflow: 'auto',
                    //backgroundColor: 'yellow',
                    flexDirection: 'column',
                    paddingTop: 10,
                    //
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    //borderRadius: '10px',
                    //border: '2px solid #E7E7E7',
                }}
            >
            <span 
                className ='title-widget'
            >Salespeople Performance for {this.state.ytd === true? 'YTD':'last 12 months'}
            </span>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%', // 30, 70, 110, 150, 
                    margin          : 0,
                    overflow        : 'hidden',
                    //backgroundColor : 'yellow'
                    display         : 'flex',
                    flexDirection   : 'column',
                    textAlign       : 'center'
                }}
            >
                <div style={{display: 'flex', height: '90%'}}>
                <ResponsivePie
                    data                = {this.state.salesVolumesList}
                    //onClick           =  {(item) =>{this.onClickABeneficiary(item)}}
                    margin              = {{ top: 40, right: 20, bottom: 20, left: 20 }}
                    startAngle          = {360}
                    endAngle            = {-360}
                    sortByValue         = {true}
                    borderWidth         = {1}
                    enableSlicesLabels  = {true}
                    enableRadialLabels  = {true}

                    innerRadius         = {0.6}
                    padAngle            = {1}
                    cornerRadius        = {5}
                    colors              = {{ scheme: 'nivo' }}
                    borderColor         = {{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                    radialLabelsTextColor            = {{ from: 'color', modifiers: [] }}
                    radialLabelsLinkOffset           = {-3}
                    radialLabelsLinkDiagonalLength   = {18}
                    radialLabelsLinkHorizontalLength = {36}
                    radialLabelsLinkStrokeWidth      = {3}
                    radialLabelsLinkColor            = {{ from: 'color', modifiers: [] }}
                    sliceLabelsTextColor             = "#333333"

                    tooltip             = {(element) => {
                        //console.log(element)
                        return (
                        <div 
                            style={{
                                color       : 'white',
                                fontSize    : 12,
                            }}
                        >
                            {element.id} {formatToCurrencyWithScale(element.value, element['Base Currency'])}
                        </div>
                        )
                    }}
                    theme               = {{
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        },
                    }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'ruby'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'c'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'go'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'python'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'scala'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'lisp'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'elixir'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'javascript'
                            },
                            id: 'lines'
                        }
                    ]}
                    legends={[]}
                /></div>
                <div style={{display: 'flex'}}>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.ytd} 
                                onChange    = {this.handleUpdateYTD} 
                                type        = "checkbox"
                            /> YTD
                        </label>
                    </div>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.last12months} 
                                onChange    = {this.handleUpdateLast12Months} 
                                type        = "checkbox"
                            /> Last 12 months
                        </label>
                    </div>
                </div>
            </div>
          </div>
        );*/
    }
}
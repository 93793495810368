import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const ShowDynamicInfo = (props) =>{

    const getControl = (id, control,value) =>{
        switch(control){
            case 'textarea':
                return <textarea className="uk-textarea" disabled={true} id={"textarea-"+id} type="text" value={value}></textarea>;
            default:
                return <input className="uk-input" disabled={true} id={"input-"+id} type="text" placeholder="" value={value} />
                
        }
    }

    const getInfo =() =>{
        let objJson = props.jsonDetail;

        let information = [];
        
        if(objJson)
        {
            let data = Object.keys(objJson).map(function (key) {
                let colunmName = key;
                let colunmValue = objJson[key];
                let hide = false;
                let control = 'input'

                if (props.columnDetails != null) {
                    const _columnDetail = props.columnDetails[key];
                    if (_columnDetail != null) {
                        const options = _columnDetail.split(',');
                        options.forEach(option => {
                            const [_optionName, _optionValue] = option.split(':');
                            if (_optionName != null && _optionValue != null) {
                                switch (_optionName) {
                                    case 'title': {
                                        colunmName = _optionValue;
                                        break;
                                    }
                                    case 'hideDetail': {
                                        hide = true;
                                        break;
                                    }
                                    case 'controlDetail':{
                                        control = _optionValue;
                                        break;
                                    }
                                    default: break;
                                }
                            }
                        });
                    }
                }                
                return [colunmName, colunmValue, hide, control];
            });

            if(data && data.length > 0)
            {
                let id=0;
            data.filter(d => !d[2] && d[0] !== 'key').map(i =>{
                id++;
                information.push(
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" id={"label-"+id}>{i[0]}:</label>
                            <div className="uk-margin-right">
                                {getControl(id,i[3],i[1])}                             
                            </div>
                        </div>
                    </div> )
                    } 
            )
            }
        
        }

        return information
    }
    return(
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <div className="uk-grid">
                        {getInfo()}
                    </div>                
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={props.onClose}>Close</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ShowDynamicInfo
import React from 'react';
import { getStyle, regularInputClass, regularSelectClass, notFilledInputClass, notFilledSelectClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getCurrencyList, searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import { getListOfBanks, getBanks } from '../../../../networking/NetworkingBanks';
import { getBranchesByBankID, getAllBranches } from '../../../../networking/NetworkingBankBranches';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { searchCountries } from '../../../../networking/NetworkingCountries';
var style = getStyle();

export default class LocalAccountForm extends React.Component {

    state = {
        localAccount: {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            countryList: [],
            countryId: '',
            countryIdName: '',
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: ''
        },
        dropdowns: {
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: []
        },
        validations: {
            bank: true,
            accountNo: true,
            currency: true
        },
        allBanks: [],
        allBankSwifts: [],
        allBranches: []
    }

    componentDidMount() {
        getListOfBanks(false).then( // SWIFT
            (json) => {
                for (var i = 0; i < json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                //this.setState({ dropdowns: { ...this.state.dropdowns, banksSwift: json.banks }, allBankSwifts: json.banks });
                this.setState({ allBankSwifts: json.banks });
            }
        )

        getListOfBanks(true).then( // BANKS
            (json) => {
                for (var i = 0; i < json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                //this.setState({ dropdowns: { ...this.state.dropdowns, banks: json.banks }, allBanks: json.banks });
                this.setState({ allBanks: json.banks });
            }
        )
        getAllBranches().then(
            (json) => {
                if (json !== undefined) {
                    if (json.branches !== undefined) {
                        for (var i = 0; i < json.branches.length; i++) {
                            json.branches[i].value = json.branches[i]["Branch ID"];
                            json.branches[i].name = json.branches[i].Branch;
                        }
                        /* this.setState({ dropdowns: { ...this.state.dropdowns, branches: json.branches }, allBranches: json.branches },
                            () => {
                                this.props.isValid(this.validateRequiredInputs())
                                this.updateInputs()
                            }); */
                        this.setState({ allBranches: json.branches },
                            () => {
                                this.props.isValid(this.validateRequiredInputs())
                            });
                    }
                }
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validated !== this.props.validated && !this.props.validated) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.clearLocalAccountForm();
        }
    }
    clearLocalAccountForm() {
        var localAccount = {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            countryId: '',
            countryIdName: '',
            DefaultAccount: false,
            Description: '',
            Active: "Active",
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: ''
        }
        var dropdowns = {
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: []
        }

        var validations = {
            bank: true,
            accountNo: true,
            currency: true
        }

        this.setState({
            localAccount: localAccount,
            dropdowns: dropdowns,
            validations: validations
        })
    }
    //----FILTER BY COUNTRY
    searchCountry(query) {
        var queryHelper = query
        if (queryHelper !== undefined && queryHelper.includes(' - ')) {
            var x = queryHelper.substr(0, 3)
            queryHelper = x
        }
        searchCountries(queryHelper).then(
            (json) => {
                var helper = [];
                if (queryHelper !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(queryHelper);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({
                        localAccount: {
                            ...this.state.localAccount,
                            countryList: helper
                        }
                    });
                    if (helper.length !== 0) {
                        if (helper.length === 1 || helper[0].CountryId === queryHelper) {
                            var value = helper[0];
                            this.setState({
                                localAccount: {
                                    ...this.state.localAccount,
                                    countryId: value.CountryId, countryIdName: value.CountryIdName
                                }
                            }, () => {
                                this.filterBanksByCountry(value.CountryId)
                            });
                        }
                    }
                }
            }
        );
        console.table(this.state.countries)
    }
    getCountryValue(value) {
        if (value !== '') {
            if (value.value !== undefined) {
                this.setState({
                    localAccount: {
                        ...this.state.localAccount,
                        countryId: value.CountryId, countryIdName: value.CountryIdName
                    }
                });
                if (value.CountryId !== undefined) {
                    this.filterBanksByCountry(value.CountryId)
                    //this.getBranches('', value.CountryId)
                }
            } else if (value.value === undefined) {
                /* this.setState({
                    localAccount: { ...this.state.localAccount, countryId: '', countryIdName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                ); */
                this.setState({
                    localAccount: { ...this.state.localAccount, countryId: '', countryIdName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: [], banksSwift: [], branches: [] },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        }
    }
    filterBanksByCountry = (countryID) => {
        var objBanks = this.state.allBanks
        var objSwift = this.state.allBankSwifts
        var newList = [], newListS = []
        objBanks.forEach(value => {
            if (value.CountryID === countryID) {
                newList.push(value)
            }
        })
        objSwift.forEach(value => {
            if (value.CountryID === countryID) {
                newListS.push(value)
            }
        })
        this.setState({ dropdowns: { ...this.state.dropdowns, banks: newList, banksSwift: newListS } });
    }
    //----END FILTER BY COUNTRY
    getBranches(bankID, countryID) {
        var objBranches = this.state.allBranches
        var newListB = []
        if (bankID > 0 && countryID !== undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID) && value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else if (bankID > 0 && countryID === undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID)) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else {
            objBranches.forEach(value => {
                if (value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        }
    }
    validateRequiredFields() {
        var currency = true, bankID = true, accountNo = true;
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            currency = false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            bankID = false;
        }
        if (this.state.localAccount.AccountNo === '') {
            accountNo = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID,
                accountNo: accountNo,
                currency: currency
            }
        })
    }

    validateRequiredInputs() {
        if (this.state.localAccount.Swift === "select" || this.state.localAccount.Swift === '' || this.state.localAccount.Swift === undefined) {
            return false;
        }
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    validateBank() {
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        return true;
    }

    validateCurrency() {
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        return true;
    }

    validateAccountNo() {
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setState({
                localAccount: { ...this.state.localAccount, Currency: value.value, CurrencyName: value.name },
                validations: { ...this.state.validations, currency: true }
            },
                () => {
                    if (this.validateCurrency()) {
                        this.props.getLocalAccount(this.state.localAccount);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(false);
                    }
                }
            );
        }
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.getBranches(value.BankID, bank.CountryID)
                        this.searchCountry(bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            } else {
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.searchCountry(bank.CountryID)
                        this.getBranches(value.BankID, bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, countryID: '', countryIdName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                localAccount: { ...this.state.localAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "" : value.SWIFT },
                dropdowns: { ...this.state.dropdowns, branches: [] },
                validations: { ...this.state.validations, bank: true }
            }, () => {
                if (this.validateBank()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
                if (value.value !== '' && value.value !== "select") {
                    this.searchCountry(value.CountryID)
                    this.getBranches(this.state.localAccount.BankID, value.CountryID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, countryID: '', countryIdName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                localAccount: { ...this.state.localAccount, BranchID: value.value, BankName: value.Bank, BankID: value['Bank ID'], Swift: value.Swift === null ? "" : value.Swift },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.searchCountry(value['Country ID'])
                    this.getBranches(value['Bank ID'], value['Country ID'])
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, countryID: '', countryIdName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, AccountNo: event.target.value },
            validations: { ...this.state.validations, accountNo: true }
        },
            () => {
                if (this.validateAccountNo()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(false);
                }
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Swift: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, ABANo: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, TransitNo: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }
    search = (query) => {
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    if (json !== undefined) {
                        for (var i = 0; i < json.length; i++) {
                            helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                        }
                        if (json.length > 1) {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } },
                                () => {
                                    this.props.getLocalAccount(this.state.localAccount);
                                }
                            );
                        } else {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper }, localAccount: { ...this.state.localAccount, Currency: helper[0].name } },
                                () => {
                                    this.props.getLocalAccount(this.state.localAccount);
                                }
                            );
                        }
                    }
                }
            }
        );
    }

    render() {
        return (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.formLabel}>Country:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSearchSelect
                                    className='uk-select'
                                    id="form-country"
                                    placeholder="Select a country"
                                    searchFunction={this.searchCountry.bind(this)}
                                    getValue={this.getCountryValue.bind(this)}
                                    data={this.state.localAccount.countryList}
                                    objValue={{ value: this.state.localAccount.countryId, name: this.state.localAccount.countryIdName }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel}>Bank (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.bank)} objValue={{ value: this.state.localAccount.BankID }} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks" />
                            </div>
                            <div className="uk-form-controls">
                                <button className="uk-button uk-button-green uk-margin-right" type="button">Add Bank</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">SWIFT:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BankID }} getValue={this.handleGetValueBankSwift} listHasPlaceholder={false} placeholder={" "} data={this.state.dropdowns.banksSwift} id="form-banks-swift" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">Bank Branch:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BranchID }} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="uk-width-1-2 uk-form-stacked uk-form-stacked" style={{ visibility: "hidden" }}>
                                <label className="uk-form-label">:</label>
                                <div className="uk-form-controls">
                                </div>
                            </div> */}
                <div className="uk-width-1-1 uk-form-stacked uk-margin-small-top uk-margin-small-bottom">
                    <hr></hr>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel}>Account Number / IBAN (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.accountNo)} type="text" placeholder="" value={this.state.localAccount.AccountNo} onChange={this.handleUpdateAccountNumber} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel}>Currency (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSearchSelect className={this.formatValidSelect(this.state.validations.currency)} id="form-currencies" objValue={{ value: this.state.localAccount.Currency, name: this.state.localAccount.CurrencyName }} placeholder={"Select an Item..."} searchFunction={this.search.bind(this)} data={this.state.dropdowns.currencies} getValue={this.handleGetValueCurrency} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">SWIFT / BIC:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.localAccount.Swift} onChange={this.handleUpdateSwift} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">Institution/ABA No.:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.ABANo} onChange={this.handleUpdateABANo} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label">Routing Code / Transit No:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.localAccount.CreatedBy !== "" && this.state.localAccount.CreatedBy !== undefined ?
                        <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                            <p>Created by {this.state.localAccount.CreatedBy} on {this.state.localAccount.CreatedOn}</p>
                        </div>
                        : ""
                }
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                </div>
                {
                    this.state.localAccount.UpdatedBy !== "" && this.state.localAccount.UpdatedBy !== undefined ?
                        <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                            <p>Updated by {this.state.localAccount.UpdatedBy} on {this.state.localAccount.UpdatedOn}</p>
                        </div>
                        : ""
                }
            </form>
        );
    }
}
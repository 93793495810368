import React from 'react';

 
import BankBranchForm from './BankBranchForm';

//import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';

import {getStyle} from '../../../styles/styles';
import BanksScreen from '../banks/BanksScreen';
import AppContext from '../../../AppContext';

import {Breadcrumb} from 'antd';

var globalFunctions = {};
var style = getStyle();



export default class BankBranchScreen extends React.Component {
    static contextType = AppContext;

    state = {
        bankBranches: [],
        originalBankBranches: [],
        selectedBank: {},
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        selectedBankBranch: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteBankBranch: {},
        actualPage: 'banks',
    }

    componentDidMount = () => {
        globalFunctions = this.context;
    }

    getSelectedBank = (model) =>{
        this.setState({selectedBank: model});
    }

    changeToBranch = (model) => {
        this.setState({selectedBank: model, actualPage: 'branches'});
    }

    returnToBanks = () => {
        this.setState({actualPage: 'banks'});
    }

    render(){
        let breadcrumb = [];
        let screen = [];

        if(this.state.actualPage === 'banks'){
            breadcrumb.push(<Breadcrumb.Item className="breadcrumb-item-bold">Banks</Breadcrumb.Item>);
            screen.push(<BanksScreen getSelectedBank={this.getSelectedBank} changeToBranch={this.changeToBranch} />);
        } else if (this.state.actualPage === 'branches'){
            breadcrumb.push(<Breadcrumb.Item className="breadcrumb-item" onClick={this.returnToBanks}>Banks</Breadcrumb.Item>);
            breadcrumb.push(<Breadcrumb.Item className="breadcrumb-item-bold">{`${this.state.selectedBank.BankName} - Branches`}</Breadcrumb.Item>);
            screen.push(<BankBranchForm selectedBank={this.state.selectedBank}/>);
        }

        return(
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>
                <div className="uk-margin">
                    {screen}
                </div>
            </div>
        );
    }
}
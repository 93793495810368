import React from 'react';
import AddOrderBoardForm from './AddOrderBoardForm';
import RateWatchesView from './RateWatchesView';
import {getStyle} from '../../../styles/styles';
import WizardSearchCustomerForm from '../wizard/WizardSearchCustomerForm';
import WizardSelectContactForm from '../wizard/WizardSelectContactForm';
import {getRateWatchByID} from '../../../networking/NetworkingRateWatch';
import {getAllCurrencyPairs} from '../../../networking/NetworkingCurrencyPairs';

var style = getStyle();
export default class OrderBoardScreen extends React.Component {
    state = {
        currentTab: 1,
        objContact: {},
        objCustomer: {},
        objRateWatch: {},
        currencyPairs: []
    }

    componentDidMount(){
        getAllCurrencyPairs().then(
            (json) => {
                if(json !== undefined){
                    let currencyPairs = json.currencyPairs;
                    var helper = {};
                    for(var j=0; j<currencyPairs.length; j++){
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode] = {};
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode].RateTerms = currencyPairs[j].RateTerms;
                    }
                    this.setState({currencyPairs: helper});
                }
            }
        );
    }
    
    onClickTab = (tab) => {
        this.setState({currentTab: tab});
    }

    handleCustomerSelection = (obj) => {
        this.setState({objCustomer: obj,
            currentTab: 3,            
        });
    }

    handleContactSelection = (obj) => {
        this.setState({objContact: obj,
            objRateWatch: {},
            currentTab: 4
        });
    }

    handleRateWatchSelect = (obj) => {
        //alert(JSON.stringify(obj));
        getRateWatchByID(obj['Order No']).then(
            (json) => {
                //alert(JSON.stringify(json));
                if(json.RateWatchID !== undefined){
                    let objRateWatch = json;

                    let objCustomer = {
                        'Customer ID': objRateWatch.CustomerID,
                        'Customer Name': objRateWatch.CustomerName,
                    };

                    let objContact = {
                        'Contact ID': objRateWatch.CustomerContactID,
                        'Name': objRateWatch.ContactName,
                    }

                    this.setState({objCustomer: objCustomer,
                        objContact: objContact,
                        objRateWatch: objRateWatch,
                        currentTab: 4,
                    });
                }
                
                
                
                
            }
        );
    }

    render(){
        var classNameRateWatchesTab = '', classNameCustomerSelectionTab = '',
        classContactSelectionTab = '', classNameRateWatchDetails = '';

        var currentScreen;

        switch(this.state.currentTab){
            case 1:
            default:
                classNameRateWatchesTab = 'uk-active';
                currentScreen = (<RateWatchesView 
                    onAddRateWatch={() => this.onClickTab(2)}
                    handleRowClick={this.handleRateWatchSelect}
                />);
            break;
            case 2:
                classNameCustomerSelectionTab = 'uk-active';
                currentScreen = (<WizardSearchCustomerForm
                    onCustomerSelected={this.handleCustomerSelection}
                />);
            break;
            case 3:
                classContactSelectionTab = 'uk-active';
                currentScreen = (<WizardSelectContactForm 
                    objCustomer={this.state.objCustomer}
                    onContactSelected={this.handleContactSelection}
                />);
            break;
            case 4:
                classNameRateWatchDetails = 'uk-active';
                currentScreen = (<AddOrderBoardForm
                    objCustomer={this.state.objCustomer}
                    objContact={this.state.objContact}
                    objRateWatch={this.state.objRateWatch}
                    currencyPairs={this.state.currencyPairs}
                />);
            break;
        }

        return (
            <div className="uk-form-stacked">
                <h3 className="uk-heading-divider" style={style.titleStyle}>Order Board</h3>

                <ul className="uk-tab">
                    <li className={classNameRateWatchesTab}><a onClick={() => this.onClickTab(1)}>Rate Watches</a></li>
                    <li className={classNameCustomerSelectionTab}><a onClick={() => this.onClickTab(2)}>Search Customer</a></li>
                    <li className={classContactSelectionTab}><a onClick={() => this.onClickTab(3)}>Select Contact</a></li>
                    <li className={classNameRateWatchDetails}><a onClick={() => this.onClickTab(4)}>Rate Watch Details</a></li>
                </ul>

                {currentScreen}
            </div>            
        );
    }
}
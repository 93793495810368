import {genericCallWithBody, genericDeleteWithParameters, genericGetWithParametersNoEncrypted} from './Networking';

export async function saveRateWatchSound(rateWatchSoundRequest)
{
    /*
        RateWatchSoundRequest
    
        int     RateWatchSoundID
        byte[]  SoundData
        string  SoundType
        string  FileName
        Guid    UserId
        long    UpdateToken

    */
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'RateWatchSounds/SaveRateWatchSound';
    let response = await genericCallWithBody(method, apiName, rateWatchSoundRequest);
    return response;
}

export async function deleteRateWatchSound(rateWatchSoundID)
{
    //  int rateWatchSoundID
    let params = {
        "rateWatchSoundID": rateWatchSoundID
    }
    let response = await genericDeleteWithParameters('RateWatchSounds/DeleteRateWatchSound',params);
    return response;
}

export async function getRateWatchSoundByType(soundType)
{
    try
    {
        let params = {
            'soundType': soundType,
        }
        let response = await genericGetWithParametersNoEncrypted('RateWatchSounds/GetRateWatchSoundByType',params);
        return response;
    }catch(error) 
    {
        console.error(error);
    }
}
import React from 'react';

import NewDynamicTable from '../../shared/NewDynamicTable';
import DynamicSelect from '../../shared/DynamicSelect';
import GeneralDragAndDropFileDialog from '../../shared/Dialogs/GeneralDragAndDropFileDialog';
import {getLookUpTable} from '../../../networking/Networking';
import {getActiveTradersMin} from '../../../networking/NetworkingTrader';
import {formatToCurrency, roundAmountByCurrencyPair, formatToCurrencyWithScale} from '../../../helpers/FormatHelper';
import {getCurrencyPairForTabs, getAllRateWatches} from '../../../networking/NetworkingRateWatch';
import { getRateWatchSoundByType } from '../../../networking/NetworkingRateWatchSounds';
import {getStyle} from '../../../styles/styles';
var style = getStyle();

export default class RateWatchesView extends React.Component {
    state = {
        currencyPairs   : {},
        orderStatus     : 'Outstanding',
        orderStatusList : [],
        pairsCount      : 0,
        rateWatches     : [],
        selectedTab     : -1,
        traderID        : 0,
        traderList      : [],
        
     
        customer        : '',
        customerList    : [],      
        rateType        : '',
        rateTypeList    : [],
        soundAlarm      : '',
        "Rate Watch Customer Filters"   : [],
        "Rate Watch Status Names"       : [],
        "Rate type" : [],
        lookUpTables: [
            "Rate Watch Customer Filters",
            "Rate Watch Status Names",
            "Rate type",
        ],

        showFileDialog              : false,
        rateWatchSoundID            : 0,
        updateTokenRateWatchSound   : 0
    }

    componentDidMount ()
    {
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        console.group(this.state.lookUpTables[i]);
                        console.table(table);
                        console.groupEnd();
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        });

        getActiveTradersMin().then(
            (value)=>{
                for (var i = 0; i< value.traders.length; i++) {
                    value.traders[i].value = value.traders[i].TraderID;
                    value.traders[i].name = value.traders[i].Trader;
                }
                value.traders.unshift({value:0, name:'Select a Trader...'});
                this.setState({traderList: value.traders});
            }
        );
        this.loadCurrencyPairForTabs('outstanding');

        getRateWatchSoundByType('OrderType').then(
            (jsonResponseGetRateWatchSoundByType) => {
                ////debugger
                /*
                    data: "UklGRtJpAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZm"
                    httpErrorMessage: undefined
                    httpStatusCode: 200
                */
                switch (jsonResponseGetRateWatchSoundByType.httpStatusCode) {
                    case 200:
                        {

                            break;
                        }
                    default:
                        break;
                }
            }
        );
    }

    loadCurrencyPairForTabs = (orderStatus) => {
        getCurrencyPairForTabs(orderStatus, 0).then(
            (json) => {
                ////debugger
                let pairs = json.pairs;
                var currencyPairs = {};
                var pairsCount = 0;
                var selectedTab = -1;
                if (pairs !== undefined) {
                    pairsCount = pairs.length;
                    for (var i = 0; i < pairs.length; i++) {
                        currencyPairs[i] = pairs[i].Pair;
                    }
                }
                if (pairsCount > 0) {
                    selectedTab = 0;
                }
                this.setState({
                    currencyPairs: currencyPairs,
                    pairsCount: pairsCount,
                    selectedTab: selectedTab,
                }, () => {
                    if (pairsCount > 0) {
                        this.loadRateWatches();
                    }
                });

            }
        );
    }

    loadRateWatches = () => {
        let selectedPair = this.state.currencyPairs[this.state.selectedTab] != null ? this.state.currencyPairs[this.state.selectedTab] : '';
        if (selectedPair !== '') {
            let pairs = selectedPair.split('/');
            getAllRateWatches(0, this.state.traderID, this.state.orderStatus, null, pairs[0], pairs[1]).then(
                (json) => {
                    var helper = [];
                    if (json.rateWatches !== undefined) {
                        helper = json.rateWatches;
                    }
                    this.setState({ rateWatches: helper });
                }
            );
        }
    }

    onClickTab = (tab) => {
        this.setState({selectedTab: tab}, 
        () => {
         this.loadRateWatches();
        });
    }

    getCustomerFilterSelect(value){
        console.log(value);
        this.setState({customer: value.LookUpTableDetail});
    }

    getOrderStatusSelect = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ orderStatus: obj.value }, () => { this.loadCurrencyPairForTabs(obj.value) });
        }
    }

    getTradersSelect = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ traderID: obj.value }, () => { this.loadRateWatches() });
        }
    }

    getRateTypeSelect(value){
        console.log(value);
        this.setState({rateType: value.LookUpTableDetail});
    }

    handleUpdateSoundAlarm(value){
        console.log(value);
        this.setState({soundAlarm: value.target.value});
    }

    handleBrowseSoundAlarmClick(){
        this.setState({
            showFileDialog: true,
        });
    }

    handleReloadClick(){

    }

    handleAddClick = () => {
        this.props.onAddRateWatch();
    }

    handleRowClick = (obj) => {
        this.props.handleRowClick(obj);
    }

    onUploadFile = (fileName, fileContent) =>{

        if( fileName !== undefined && fileName !== null && fileName !== '' &&
            fileContent !== undefined && fileContent !== null && fileContent !== '')
        {
            /*let rateWatchSoundRequest = {
                RateWatchSoundID    : this.state.rateWatchSoundID,
                SoundData           : fileContent,
                SoundType           : 'OrderType',
                FileName            : fileName,
                UserId              : localStorage.getItem('UserID'),
                UpdateToken         : this.state.updateTokenRateWatchSound,
            }
            
            saveRateWatchSound(rateWatchSoundRequest).then(
                (jsonResponse) => {
                    
                    //    httpErrorMessage: undefined
                    //    httpStatusCode: 200
                    //    rateWatchSoundID: 1
                    //    updateToken: "637478269728000000"
                    
                    if(jsonResponse !== undefined)
                    {
                        switch (jsonResponse.httpStatusCode) 
                        {
                            case 200:
                            {
                                this.setState({
                                    rateWatchSoundID            : jsonResponse.rateWatchSoundID,
                                    updateTokenRateWatchSound   : jsonResponse.updateToken,
                                    soundAlarm                  : fileName
                                });
                                break;
                            }
                            default:
                            {
                                break;
                            }
                        }
                    }
                }
            );*/
         }
    }

    getRateWatches = async () => {

        let selectedPair = this.state.currencyPairs[this.state.selectedTab] != null ? this.state.currencyPairs[this.state.selectedTab] : '';
        if (selectedPair !== '') {
            let pairs = selectedPair.split('/');
            
            var json = await getAllRateWatches(0, this.state.traderID, this.state.orderStatus, null, pairs[0], pairs[1]);

            var helper = json.rateWatches;
            return helper;
        }

        
    }

    render(){
        var tabs = [];
        for(var i=0; i<this.state.pairsCount; i++){
            var className = '';
            if(i === this.state.selectedTab){
                className = 'uk-active';
            }
            tabs.push(<li key={"tab" + i} className={className}><a href onClick={this.onClickTab.bind(this, i)}>{this.state.currencyPairs[i]}</a></li>);
        }                

        return(
            <div className="uk-form-stacked">
                <h4 className="uk-heading-divider" style={style.titleStyle}>Filter</h4>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Customer Filter</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" getValue={this.getCustomerFilterSelect.bind(this)} listHasPlaceholder={true} data={this.state["Rate Watch Customer Filters"]} id="customer-filter" objValue={{value: this.state.customer, LookUpTableDetail: this.state.customer}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Order Board Status</label>
                            <div className="uk-form-controls">
                                <DynamicSelect id="order-status" 
                                    className="uk-select" 
                                    getValue={this.getOrderStatusSelect.bind(this)} 
                                    listHasPlaceholder={false} 
                                    data={this.state["Rate Watch Status Names"]} 
                                    objValue={{value: this.state.orderStatus}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Traders</label>
                            <div className="uk-form-controls">
                                <DynamicSelect id="traders"
                                    className="uk-select"
                                    listHasPlaceholder={false}
                                    getValue={this.getTradersSelect} 
                                    data={this.state.traderList}  
                                    objValue={{value: this.state.traderID}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Rate Type</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" getValue={this.getRateTypeSelect.bind(this)} listHasPlaceholder={true} data={this.state["Rate type"]} id="rate-type" objValue={{value: this.state.rateType, LookUpTableDetail: this.state.rateType}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-right">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">Sound Alarm</label>
                        </div>
                    </div>
                    <div className=" uk-width-1-3 uk-margin-right">
                        <div className="uk-margin" style={style.inputStyle}>
                            <input 
                                className="uk-input" 
                                style={{marginTop: "5px"}} 
                                id="soundAlarm" 
                                type="text" 
                                placeholder="" 
                                value={this.state.soundAlarm} 
                                disabled={true} 
                                /*onChange={this.handleUpdateSoundAlarm.bind(this)}*/
                            />
                        </div>
                    </div>
                    <div className="uk-margin-right">
                        <div className="uk-margin" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleBrowseSoundAlarmClick.bind(this)} type="button">Browse</button>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <button className="uk-button uk-button-green uk-align-right" onClick={this.handleAddClick} type="button">Add</button>
                        <button className="uk-button uk-button-green uk-align-right" onClick={this.handleReloadClick.bind(this)} type="button">Reload</button>
                    </div>
                </div>

                <div>
                    <ul className="uk-tab">
                        {tabs}
                    </ul>
                    <NewDynamicTable id="table-rate-watches"
                        data={this.state.rateWatches}
                        formats={
                            [
                                {header: 'Customer Rate', formatFunction: roundAmountByCurrencyPair, formatBuyCurrency: 'Buy Currency', formatSellCurrency: 'Sell Currency'},
                                {header: 'Spot Market Rate', formatFunction: roundAmountByCurrencyPair, formatBuyCurrency: 'Buy Currency', formatSellCurrency: 'Sell Currency'},
                                {header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Currency'},
                                {header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency'},
                                {header: 'Sell Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Sell Currency'},
                                {header: 'ASK Rate', formatFunction: roundAmountByCurrencyPair, formatBuyCurrency: 'Buy Currency', formatSellCurrency: 'Sell Currency'},
                                {header: 'BID Rate', formatFunction: roundAmountByCurrencyPair, formatBuyCurrency: 'Buy Currency', formatSellCurrency: 'Sell Currency'},
                            ]
                        }
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        useDeleteButton={false}
                        newSearch={true}
                        useFilter       = {true}
                        filterFunction  = {this.getRateWatches}
                        />
                </div>
                <GeneralDragAndDropFileDialog
                    open            = {this.state.showFileDialog}
                    onUploadFile    = {this.onUploadFile}
                    onClose         = {() =>{ this.setState({showFileDialog: false})}}
                    validExtensions = 'wav'
                />
            </div>
        );
    }
}
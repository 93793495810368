import { Colors } from "../constants/Colors";

export function getStyle(){
    return defaultStyle;
} 

const defaultStyle = {
    titleStyle: {
        paddingLeft: "5px",
        paddingTop: "5px",
        paddingRight: "25px",
        backgroundImage: 'linear-gradient(to right , #89b4cc 30%, #b2cede 100%, #cee0ea 100%)',
        marginTop: '5px',
        color: '#fff',
        fontSize: 'medium',
        fontWeight: '500'
    },
    titleStyle2: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px",
        marginTop: '11%'
    },
    inputStyle: {
        paddingBottom:"5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    semiboldLabel: {
        fontWeight: 700
    },
    boldLabel: {
        fontWeight: 800
    },
    boldLabelRight: {
        fontWeight  : 800,
        textAlign   :'right',
        color       : 'gray'
    },
    boldTitle: {
        fontWeight: 500
    },
    tasktitleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    titleStyleMargin: {
        paddingLeft: "5px",
        paddingTop: "5px",
        paddingRight: "25px",
        backgroundImage: 'linear-gradient(to right , #89b4cc 30%, #b2cede 100%, #cee0ea 100%)',
        marginTop: '5px',
        color: '#fff',
        display: 'flex',
        flexDirection   : 'row',
        justifyContent  : 'center'
    },
    subTitleStyle: {
        paddingLeft: "5px",
        paddingTop: "5px",
        paddingRight: "25px",
        //backgroundImage: 'linear-gradient(to right , #89b4cc 30%, #b2cede 100%, #cee0ea 100%)',
        marginTop: '5px',
        color: '#fff',
        fontSize: 'medium',
        fontWeight: '500',
        backgroundColor: Colors.primaryColor
    }
}

export const notFilledInputClass = "uk-input uk-form-danger";
export const regularInputClass = "uk-input";
export const notFilledSelectClass = "uk-select uk-form-danger";
export const regularSelectClass = "uk-select";
export const notFilledTextAreaClass = "uk-textarea uk-form-danger";
export const regularTextAreaClass = "uk-textarea";
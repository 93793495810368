/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from 'antd';
import React from 'react';
import AppContext from '../../../../AppContext';
import { CustomerBankAccountType } from '../../../../constants/EntityTypeNames';
import { getCustomerByID } from '../../../../networking/NetworkingCustomers';
import AddressScreen from './AddressesScreen';
import BankAccountsScreen from './BankAccountsScreen';
import BeneficiariesScreen from './BeneficiariesScreen';
import CommentsScreen from './CommentsScreen';
import ComplianceScreen from './ComplianceScreen';
import ContactsScreen from './ContactsScreen';
import CustomerInformationScreen from './CustomerInformationScreen';
import LimitProfilesScreen from './LimitProfilesScreen';
import OnlineAccessInformationScreen from './OnlineAccessInformationScreen';
import PropertiesScreen from './PropertiesScreen';
import TasksListScreen from './TasksListScreen';
import TradingInformationScreen from './TradingInformationScreen';
import OwnersScreen from './OwnersScreen'

let globalFunctions = {};

export default class CustomerScreen extends React.Component {
    static contextType = AppContext;

    state = {
        loading: true,
        viewHistory: false,
        id: 0,
        isReadOnly: false,
        refreshOnlineCurrencies: false,
        //----------------------------------------------------------------
        activateCustomer: true,
        activateAddress: false,
        activateOnlineAccess: false,
        activateTradingInfo: false,
        activateContacts: false,
        activateProperties: false,
        activateTasksLists: false,
        activateCompliance: false,
        activateBankAccounts: false,
        activateBeneficiaries: false,
        activateLimitProfiles: false,
        activateExtendedProperties: false,
        activateOwners: false,

        //----------------------------------------------------------------
        selectedEntityTaskID      :'',
        customer: {
            Name: 'Loading...'
        },
        showTabs: true,
        toggleReloadGlobalSearch: false,
        activeTab: 'customer-information',
        refresh: true,
        forceLoad:false,
        hideCustomerDetail: true,
        hideContactDetail: true,
        hideBeneficiaryDetail: true,
        hideBankAccountDetail: true,
    }

    handleBackLink() {
        this.props.backLink();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.backToCustomer !== prevProps.backToCustomer){
            if(this.props.backToCustomer){
                //this.activateTab('activateCustomer');
                this.setState({showTabs: true, activateCustomer: true});
            }
        }
        if(this.props.hideDetail !== prevProps.hideDetail){
            this.setState({hideCustomerDetail: this.props.hideDetail });            
        }
        if(this.props.hideContactDetail !== prevProps.hideContactDetail){
            this.setState({hideContactDetail: this.props.hideContactDetail });            
        }
        if(this.props.hideBeneficiaryDetail !== prevProps.hideBeneficiaryDetail){
            this.setState({hideBeneficiaryDetail: this.props.hideBeneficiaryDetail });            
        }
        if(this.props.hideBankAccountDetail !== prevProps.hideBankAccountDetail){
            this.setState({hideBankAccountDetail: this.props.hideBankAccountDetail });            
        }
        if(this.props.backTotabContact !== prevProps.backTotabContact && this.props.backTotabContact){
            this.handleactiveTabContact();
        }        
        if(this.props.backTotabBank !== prevProps.backTotabBank && this.props.backTotabBank){
            this.handleactiveTabBank();
        }        
        if(this.props.backTotabBeneficiary !== prevProps.backTotabBeneficiary && this.props.backTotabBeneficiary){
            this.handleactiveTabBeneficiary();
        }
        if(this.props.backTotabOwner !== prevProps.backTotabOwner && this.props.backTotabOwner){
            this.handleactiveTabOwner();
        }
    }

    componentDidMount(){
        globalFunctions = this.context;
        if(this.props.readOnly !== undefined) {
            this.setState({
                isReadOnly: this.props.readOnly,
                //activateCustomer: true,
            });
        }
        this.refreshCustomer();
    }

    refreshCustomer = async () => {
        globalFunctions.startLoading();
        getCustomerByID(this.props.customerID).then(
            (json) => {
                if (json != null) {
                    this.setState({
                        customer: json,
                        id: json.CustomerID,
                        loading: false,
                        toggleReloadGlobalSearch: !this.state.toggleReloadGlobalSearch,
                        forceLoad: !this.state.forceLoad // reload properties
                    });
                }
                globalFunctions.finishLoading();
            }
        );
    }

    refreshOnlineCurrencies = () => {
        this.setState({ refreshOnlineCurrencies: true }, () => this.setState({ refreshOnlineCurrencies: false }));
    }

    activateTab = (tab) => {
        this.setState({ activatePersonIdentifications:false,
            [tab]: true,selectedEntityTaskID:''});
        if(tab !== 'activateContacts') {
            this.setState({activateContacts: false});
        }
        if(tab !== 'activateBeneficiaries') {
            this.setState({activateBeneficiaries: false});
        }
        if(tab !== 'activateBankAccounts') {
            this.setState({activateBankAccounts: false});
        }
        if(tab !== 'activateOwners') {
            this.setState({activateOwners: false});
        }
    }

    handleActiveTab = (tab) => {
        return this.state.activeTab === tab ? 'uk-active': '';
    }

    handleactiveTabContact=()=>{
        this.setState({activateContacts:false, activeTab: 'contacts'},()=>this.setState({activateContacts:true}))
    }

    handleactiveTabBank=()=>{
        this.setState({activateBankAccounts:false, activeTab: 'direct-debit'},()=>this.setState({activateBankAccounts:true}))
    }    
    
    handleactiveTabBeneficiary=()=>{
        this.setState({activateBeneficiaries:false, activeTab: 'beneficiaries'},()=>this.setState({activateBeneficiaries:true}))
    }

    handleactiveTabOwner=()=>{
        this.setState({activateOwners:false, activeTab: 'owners'},()=>this.setState({activateOwners:true}))
    }

    createContactHandler = () => {
        this.setState({showTabs: false});
        this.props.createContactHandler();
    }

    updateContactHandler = (name) => {
        this.setState({showTabs: false});
        this.props.updateContactHandler(name);
    }

    createBeneficiaryHandler = () => {
        this.setState({showTabs: false});
        this.props.createBeneficiaryHandler();
    }

    updateBeneficiaryHandler = (name) => {
        this.setState({showTabs: false});
        this.props.updateBeneficiaryHandler(name);
    }
    
    createBeneficiaryBankAccountHandler = () => {
        this.setState({showTabs: false});
        this.props.createBeneficiaryBankAccountHandler();
    }

    updateBeneficiaryBankAccountHandler = (name) => {
        this.setState({showTabs: false});
        this.props.updateBeneficiaryBankAccountHandler(name);
    }

    createBankAccountHandler = () => {
        this.setState({showTabs: false});
        this.props.createBankAccount();
    }

    updateBankAccountHandler = (name) => {
        this.setState({showTabs: false});
        this.props.updateBankAccount(name);
    }
    createOwnerHandler = () => {
        this.setState({showTabs: false});
        this.props.createOwnerHandler();
    }

    updateOwnerHandler = (name) => {
        this.setState({showTabs: false});
        this.props.updateOwnerHandler(name);
    }

    activateGeneralInformation = () => {
        this.activateTab('activateCustomer') 
        if(this.props.removeBreadcrumb)
        {this.props.removeBreadcrumb()}
        this.setState({hideCustomerDetail: false});
    }

    activateContactGeneralInformation = () => {
        this.activateTab('activateContacts') 
        if(this.props.removeBreadcrumb)
        {this.props.removeBreadcrumb()}
        this.setState({hideContactDetail: false});
    }
    
    activateBeneficiariesGeneralInformation = () => {
        this.activateTab('activateBeneficiaries') 
        if(this.props.removeBreadcrumb)
        {this.props.removeBreadcrumb()}
        this.setState({hideBeneficiaryDetail: false});
    }

    activateBankAccountInformation = () => {
        this.activateTab('activateBankAccounts') 
        if(this.props.removeBreadcrumb)
        {this.props.removeBreadcrumb()}
        this.setState({hideBankAccountDetail: false});
    }

    render() {

        let breadcrumbCustomerItem = this.props.customerName != null ? this.props.customerName : ''

        if (breadcrumbCustomerItem === '' && this.state.customer !== undefined && this.state.customer !== null && this.state.customer.Name !== 'Loading...') {
            breadcrumbCustomerItem = this.state.customer.Name + ' - ' + this.props.customerID
        } else if (breadcrumbCustomerItem === '' && this.props.customerID !== undefined && this.props.customerID !== null) {
            breadcrumbCustomerItem = 'Customer ID ' + this.props.customerID
        }

        return (
            <div>
                {/*this.props.mainTitle != null && <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">{breadcrumbCustomerItem}</Breadcrumb.Item>
                    </Breadcrumb>
        </div>*/}
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                <fieldset disabled={this.state.isReadOnly}>
                    <div>
                        {/*<h3 className="uk-heading-divider" style={style.titleStyle} >
                            <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.handleBackLink.bind(this)} style={{paddingRight: 10}}>
                                <i className="fa fa-arrow-left back-arrow-white"> </i>
                            </button>
                            Customer - {this.props.customerName ? this.props.customerName : this.state.customer.Name}
                        </h3>*/}
                        {this.state.showTabs && <ul uk-tab="">
                            <li className={this.handleActiveTab('customer-information')}><a onClick={this.activateGeneralInformation}>Customer Information</a></li>
                            <li className={this.handleActiveTab('addresses')}><a onClick={() => this.activateTab('activateAddress')}>Addresses</a></li>
                            {<li className={this.handleActiveTab('comments')}><a href="#">Comments</a></li>}
                            <li className={this.handleActiveTab('payments-and-settlements')}><a onClick={() => this.activateTab('activateOnlineAccess')}>Payments and Settlements </a></li>
                            <li className={this.handleActiveTab('trading-information')}><a onClick={() => this.activateTab('activateTradingInfo')}>Trading Information</a></li>
                            <li className={this.handleActiveTab('contacts')}><a onClick={this.activateContactGeneralInformation}>Contacts</a></li>
                            {/* <li><a href="#">Commissions</a></li> */}
                            <li className={this.handleActiveTab('properties')}><a onClick={() => this.activateTab('activateProperties')}>Properties</a></li>
                            <li className={this.handleActiveTab('tasks-lists')}><a onClick={() => this.activateTab('activateTasksLists')}>Tasks Lists</a></li>
                            <li className={this.handleActiveTab('compliance')}><a onClick={() => this.activateTab('activateCompliance')}>Compliance</a></li>
                            <li className={this.handleActiveTab('direct-debit')}><a onClick={this.activateBankAccountInformation}>Direct Debit / Subaccounts</a></li>
                            <li className={this.handleActiveTab('beneficiaries')}><a onClick={this.activateBeneficiariesGeneralInformation}>Beneficiaries</a></li>
                            {/* <li><a href="#">Default Outgoing Payments</a></li> */}
                            {/* <li><a href="#">Commission Details</a></li> */}
                            <li className={this.handleActiveTab('limit-profiles')}><a onClick={() => this.activateTab('activateLimitProfiles')}>Limit Profiles</a></li>
                            {this.props.customerType === 'Corporate' && <li className={this.handleActiveTab('owners')}><a onClick={() => this.activateTab('activateOwners')}>Owners</a></li>}

                            {/* <li><a href="#">Commercial Information</a></li> */}
                            {/* <li><a onClick={() => this.activateTab('activateExtendedProperties')}>Extended online properties</a></li> */}
                        </ul>}
                        <ul className="uk-switcher uk-margin">
                            <li className={this.handleActiveTab('customer-information')}>{this.state.activateCustomer && <CustomerInformationScreen id={this.state.id} data={this.state.customer} refreshCustomer={this.refreshCustomer} readOnly={this.state.isReadOnly} canUpdateCustomerProcessStatus={this.props.canUpdateCustomerProcessStatus} addBreadcrumb={this.props.addBreadcrumb} removeBreadcrumb ={this.props.removeBreadcrumb} hideDetail={this.state.hideCustomerDetail} />}</li>
                            <li className={this.handleActiveTab('addresses')}>{this.state.activateAddress && <AddressScreen data={this.state.customer.PersonID} refreshOnlineCurrencies={this.refreshOnlineCurrencies} />}</li>
                            {<li className={this.handleActiveTab('comments')}><CommentsScreen customer={this.state.customer} /></li>}
                            <li className={this.handleActiveTab('payments-and-settlements')}>{this.state.activateOnlineAccess && <OnlineAccessInformationScreen id={this.state.id} customer={this.state.customer} refreshCustomer={this.refreshCustomer} refreshOnlineCurrencies={this.state.refreshOnlineCurrencies} />}</li>
                            <li className={this.handleActiveTab('trading-information')}>{this.state.activateTradingInfo && <TradingInformationScreen id={this.state.id} data={this.state.customer} refreshCustomer={this.refreshCustomer} />}</li>
                            <li className={this.handleActiveTab('contacts')}>{this.state.activateContacts && <ContactsScreen data={this.state.id} createContactHandler={this.createContactHandler} updateContactHandler={this.updateContactHandler} customerType={this.state.customer.CustomerType}  addBreadcrumb={this.props.addBreadcrumb} removeBreadcrumb ={this.props.removeBreadcrumb} hideDetail={this.state.hideContactDetail}  />}</li>
                            {/* <li><CommissionsScreen/></li> */}
                            {/* <li>{this.state.activateProperties && <AdditionalPropertiesScreen customerID={this.state.customer.CustomerID} />}</li> */}
                            <li className={this.handleActiveTab('properties')}>{this.state.activateProperties && <PropertiesScreen customerID={this.state.customer.CustomerID} personID={this.state.customer.PersonID} customerType={this.state.customer.CustomerType} refreshCustomer={this.refreshCustomer} forceLoad={this.state.forceLoad}/>}</li>
                            <li className={this.handleActiveTab('tasks-lists')}>{this.state.activateTasksLists && <TasksListScreen customerID={this.state.customer.CustomerID} personID={this.state.customer.PersonID} />}</li>
                            <li className={this.handleActiveTab('compliance')}>{this.state.activateCompliance && <ComplianceScreen customer={this.state.customer} refreshCustomer={this.refreshCustomer} reloadGlobalSearch={this.state.toggleReloadGlobalSearch} fatherScreen='Customer' />}</li>
                            <li className={this.handleActiveTab('direct-debit')}>{this.state.activateBankAccounts && <BankAccountsScreen data={this.state.customer.PersonID} title={"Direct Debit"} customerID={this.state.customer.CustomerID}subEntityType={CustomerBankAccountType} updateBankAccount={this.updateBankAccountHandler} createBankAccount={this.createBankAccountHandler} isBeneficiary={false} addBreadcrumb={this.props.addBreadcrumb} removeBreadcrumb ={this.props.removeBreadcrumb} hideDetail={this.state.hideBankAccountDetail}  />}</li>
                            <li className={this.handleActiveTab('beneficiaries')}>{this.state.activateBeneficiaries && <BeneficiariesScreen id={this.state.id} customerPersonID={this.state.customer.PersonID} customer={this.state.customer} data={this.state.customer.Name + " - " + this.state.customer.CustomerID} createBeneficiaryHandler={this.createBeneficiaryHandler} updateBeneficiaryHandler={this.updateBeneficiaryHandler} createBeneficiaryBankAccountHandler={this.createBeneficiaryBankAccountHandler} 
                                show_list_bankAccount={this.props.show_list_bankAccount} updateBeneficiaryBankAccountHandler={this.updateBeneficiaryBankAccountHandler} backLink={this.props.backToUpdateCustomer} updateBankAccount={this.updateBankAccountHandler} createBankAccount={this.createBankAccountHandler} addBreadcrumb={this.props.addBreadcrumb} removeBreadcrumb ={this.props.removeBreadcrumb} hideDetail={this.state.hideBeneficiaryDetail}  />}</li>
                            {/* <li><DefaultOutgoingPaymentsScreen/></li> */}
                            {/* <li><CommissionDetailsScreen/></li> */}
                            <li className={this.handleActiveTab('limit-profiles')}>{this.state.activateLimitProfiles && <LimitProfilesScreen id={this.state.id} requiresEdd={this.state.customer.RequiresEdd}/>}</li>
                            {this.props.customerType === 'Corporate' && <li className={this.handleActiveTab('owners')}>{this.state.activateOwners && <OwnersScreen id={this.state.id} customerPersonID={this.state.customer.PersonID} customer={this.state.customer} data={this.state.customer.Name + " - " + this.state.customer.CustomerID} createOwnerHandler={this.createOwnerHandler} updateOwnerHandler={this.updateOwnerHandler} backLink={this.props.backToUpdateCustomer}  />}</li>}
                            
                            {/* <li><CommercialInformationScreen/></li> */}
                            {/* <li>{this.state.activateExtendedProperties && <ExtendedOnlinePropertiesScreen entityID={this.state.id} entityType="Customer" />}</li> */}
                        </ul>
                    </div>
                </fieldset>
            </div>
        );
    }
}
import React from 'react';

import AlertBox from '../../shared/AlertBox'
import DatePicker from '../../shared/DatePicker'
import LoadingBox from '../../shared/LoadingBox'

import { getAFEXBeneficiaryInstantPayments } from '../../../networking/NetworkingBeneficiaries'
import { sendInstantPaymentToAFEX } from '../../../networking/NetworkingFunds'

import { formatDate, formatToCurrency, formatToCurrencyWithScale } from '../../../helpers/FormatHelper'

import { getStyle } from '../../../styles/styles' 

const InstantPaymentTable = ({ results = [], updateData = f => f}) => {
    
    let headers = []
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h1'}></th>)    
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h2'}>DealHeader ID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h3'}>BuyAmount</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h4'}>CustomerID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h5'}>ValueDate</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h6'}>CoverDealHeader ID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h7'}>CoverSellCurrency</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h8'}>CoverSellAmount</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h9'}>CustomerName</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h10'}>VendorID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h11'}>CustomerRefNo</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h12'}>AFEXAccountNumber</th>)
    
    //let filteredResults = results.filter(x => (Number(x.Credits) > 0 && showCredit) || (Number(x.Debits) > 0 && showDebit));

    console.log(results)
    let rows = results.map((x, i) => {
        
        let checkbox = <input 
            className   = 'uk-checkbox'
            style       = {{marginRight: 0}}
            type        = "checkbox" 
            checked     = {x.Checked} 
        />
        return <tr
            key={i}
        >
            <td
                style={{ textAlign: 'center', verticalAlign: 'middle', cursor: 'pointer' }}
                onClick={() => {
                    x.Checked = !x.Checked;
                    updateData(results);
                }}
            >
                {checkbox}
            </td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.DealHeaderID}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{formatToCurrencyWithScale(x.BuyAmount, x.BuyCurrency)}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'right', verticalAlign: 'middle' }}>{x.CustomerID}</td>
            {/* <td className="uk-preserve-width" style={{ textAlign: 'right', verticalAlign: 'middle' }}>{formatDate(x.ValueDate)}</td> */}
            <td>
                <DatePicker key={'dp' + x.DealHeaderID}
                    className="uk-input"
                    value={formatDate(x.ValueDate)}
                    onDayChange={(date) => {
                        x.ValueDate = date
                        x.PaymentDate = date
                        updateData(results);
                    }}
                    placeholder={'...'}
                    readOnly={true}
                />
            </td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.CoverDealHeaderID}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.CoverSellCurrency}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{formatToCurrencyWithScale(x.BuyAmount, x.BuyCurrency)}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.CustomerName}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.VendorID}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.CustomerRefNo}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.AFEXAccountNumber}</td>
        </tr>
    })

    let table =
        <div className="">
            <table key={'instantPayments'} className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped"
                >
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    return table
}

export default class InstantBeneficiaryPaymentsAFEX extends React.Component {

    state = {
        fromDate: '',
        instantPayments: [],
        
        showAlert: false,
        loading: false
    }

    componentDidMount() {
        let systemDate = localStorage.getItem('SystemDate')
        this.setState({ fromDate: systemDate }, () => {                        
            this.loadAFEXBeneficiaryInstantPayments()
        })
    }

    //networking
    loadAFEXBeneficiaryInstantPayments = () => {

        this.setState({ loading: true }, () => {
            getAFEXBeneficiaryInstantPayments(0, this.state.fromDate).then(response => {
                console.log(response)
                if (response !== undefined && response.beneficiaryInstantPayments !== undefined && response.beneficiaryInstantPayments !== null
                    && response.beneficiaryInstantPayments.length > 0) {
                    let data = response.beneficiaryInstantPayments || [];
                    console.log(data)
                    data.forEach(x => {
                        x.Checked = false
                        x.PaymentDate = x.ValueDate
                    });
                    this.setState({
                        instantPayments: data
                    });
                }
                else if (response !== undefined && response !== null) {
                    this.setState({
                        instantPayments: [],
                        loading: false
                    });
                }
                else {
                    this.setState({
                        instantPayments: [],
                        loading: false
                    });
                }
            }).finally(() => this.setState({ loading: false }));
        })
    }

    onSendPayments = () => {
        let applicationDate = localStorage.getItem('SystemDate');
        let userId = localStorage.getItem('UserID')

        let processedRecords = this.state.instantPayments.filter(x => x.Checked === true)

        if (processedRecords.length === 0) {
            this.setState({ showAlert: true, alertTitle: 'Warning', alertMessage: 'There are no selected records' })
            return
        }        
        
        this.setState({ loading: true }, () => {
            sendInstantPaymentToAFEX(userId, processedRecords)
                //userId, applicationDate, reconciliatedRecords)
                .then((response) => {
                    console.log(response)
                    switch (Number(response.httpStatusCode)) {
                        case 200:
                            let message = response.logMessage
                            if (message != undefined && message != null)
                                message = message.split("\n").map(function (item, idx) {
                                    return (
                                        <span key={idx}>
                                            {item}
                                            <br />
                                        </span>
                                    )
                                })
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: message,
                                loading: false,
                            }, this.onRefresh())
                            break                            
                        default:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Error sending payments : ' + response.errorMessage,
                                loading: false
                            })
                            break
                    }
                })
        })

    }
    updateData = (instantPaymentsUpdated) => {
        this.setState({ instantPayments: instantPaymentsUpdated })
    }

    closeAlert = () => {
        this.setState({ showAlert: false })
    }

    onRefresh = () => {
        this.setState({ loading: true }, () => this.loadAFEXBeneficiaryInstantPayments())
    }

    render() {
        return <div>
            <h3 className="uk-heading-divider" style={{ ...getStyle().titleStyle, width: '100%' }}>
                AFEX Instant Beneficiary Payments
            </h3>
            <h5 className="uk-heading-divider" style={{ ...getStyle().subTitleStyle, width: '100%' }}>
                Actions
            </h5>
            <div className="uk-width-1-1" style={{ marginTop: '20px' }}>
                <button name="btnSendPayments" className="uk-button uk-button-default uk-button-green" onClick={this.onSendPayments}>Sent Payments to AFEx</button>
                <button name="btnRefresh" className="uk-button uk-button-default uk-button-green" onClick={this.loadAFEXBeneficiaryInstantPayments}>Refresh</button>
            </div>
            <h5 className="uk-heading-divider" style={{ ...getStyle().subTitleStyle, width: '100%' }}>
                Available Payments
            </h5>
            <InstantPaymentTable results={this.state.instantPayments}
                updateData={this.updateData} />
            <LoadingBox loading={this.state.loading} />
            <AlertBox id="instantPaymentsAlert" type="Ok" open={this.state.showAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                okClick={this.closeAlert} onClose={this.closeAlert} />
        </div>
    }
}
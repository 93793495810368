import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import VersionInformationScreen from './VersionInformationScreen';
import NewEditableGrid from '../NewEditableGrid';
import { getDealVersionComparer } from '../../../networking/NetworkingDeals';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../AlertBox';
import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';

var style = getStyle();

const initialDate = new Date();
export default class VersionsScreen extends React.Component {
    state = {        
        versions: [],
        columnDetail:[],
        columnDetailDifferences:[],
        differences: [],
        selectedVersions: [],
        selectedVersion1: 0,
        selectedVersion2: 0,
        viewMode: false,

        newSearch: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
        openDetail: false,
        objVersion:{},
        differencesDisabled:true,
        hiddenVersions: true,
        hiddenDetails: true
    }

    componentDidMount(){
        if(this.props.versions)
        {
            this.setState({versions: this.props.versions, columnDetail: this.props.columnDetail});
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.versions !== this.props.versions)
        {
            this.setState({versions: this.props.versions, columnDetail: this.props.columnDetail});
        }
        if(prevProps.differences !== this.props.differences)
        {
            this.setState({differences: this.props.differences, hiddenVersions: false, hiddenDetails: true});
        }
    }

    handleDifferencesClick = async()=> {

        if(this.props.onClickVersionComparer)
        {
            await this.props.onClickVersionComparer(this.state.selectedVersions);            
        }
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }
    handleExitClick(event){
        //event.preventDefault();
        this.props.backFromDealVersions();
    }

    handleExitClick = () =>
    {
        this.setState({openDetail: false});
    }

    onHiddenDetails = () =>
    {
        this.setState({hiddenDetails:true});
    }

    onShowDetails = () =>
    {
        this.setState({hiddenDetails:false});
    }

    onHiddenVersions = () =>
    {
        this.setState({hiddenVersions: true});
    }

    onShowVersions = () =>
    {
        this.setState({hiddenVersions:false});
    }

    onView = (record) =>{

            if(record)
            {
                this.setState({objVersion: record, openDetail: true});
            }
        
    }

    onSelect = (record) =>{
        let selectedItems = this.state.selectedVersions;
        let exists = selectedItems.findIndex(v => v === record.ID);        

        if(exists === -1)
        {            
            selectedItems.push(record.ID);            
        }
        else
        {
            selectedItems = selectedItems.filter(v => v !== record.ID);
        }

        let differencesDisabled = !(selectedItems.length === 2);

        this.setState({selectedVersions: selectedItems, differencesDisabled: differencesDisabled});
    }

    getAction = (record) =>{
        let selectedItems = this.state.selectedVersions;
        let exists = selectedItems.findIndex(v => v === record.ID);

        return (exists === -1) ? 'Select':'Unselect'
    }

    render(){

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    {this.props.EntityType} Versions</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >To view the differences between 2 versions, select the desired two versions and click on "Differences". The differences will be displayed in the '{this.props.EntityType} Version Differences' section below.</label>
                    </div>
                    <div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "35px"}} disabled={this.state.differencesDisabled} onClick={this.handleDifferencesClick.bind(this)} type="button">Differences</button>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </div>
                <h5 className="uk-heading-divider" 
                                uk-toggle="target : #dealsVersionSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>List of {this.props.EntityType} Versions<i id="dealsVersionSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}} hidden={!this.state.hiddenVersions}  onClick={this.onShowVersions}/><i id="dealsVersionSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden={this.state.hiddenVersions} onClick={this.onHiddenVersions}/>
                            </h5>
                <div id="dealsVersionSection" hidden={!this.state.hiddenVersions}>

                    <NewEditableGrid
                        key="deal-version"   
                        data={this.state.versions}
                        columnDetails={this.state.columnDetail}
                        isEditable={false}
                        useIcons={false}
                        dynamicActions={[{name: 'View', function: this.onView, isIcon: false}
                        ,{getName: ((record) => this.getAction(record)), function: this.onSelect, isIcon: false}]} 
                    />
                </div>
                <h5 className="uk-heading-divider" 
                                uk-toggle="target : #differencesSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>List of Differences<i id="differencesSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}} hidden={!this.state.hiddenDetails} onClick={this.onShowDetails}/><i id="differencesSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} onClick={this.onHiddenDetails} hidden={this.state.hiddenDetails}/>
                            </h5>
                <div id="differencesSection" hidden={!this.state.hiddenDetails}>
                    <NewEditableGrid
                        key="deal-version-differences"   
                        data={this.state.differences}
                        isEditable={false}
                        useIcons={false}
                    />
                </div>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
                
                <Dialog open={this.state.openDetail} onClose={this.handleExitClick} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>{this.props.EntityType} Versions</DialogTitle>
                <DialogContent dividers>
                    <VersionInformationScreen EntityType={this.props.EntityType} objVersion = {this.state.objVersion}/>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"exit"} onClick={this.handleExitClick}>Exit</button>
                </DialogActions>
                </Dialog>
            </div>
        );
    }
}
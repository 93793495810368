import React from 'react';
import { getLookUpTable } from '../../../networking/Networking';
import { getCurrencyType, saveCurrency } from '../../../networking/NetworkingCurrencies';
import { notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';

export default class CurrencyForm extends React.Component {

    state = {
        currencyID: '',
        symbol: '',
        currencyName: '',
        displayOrder: 99999,
        currencyType: '',
        //boolean
        availableOnline: '',
        minDenomination: 0,
        status: '',
        riskScore: 0,
        rateTimeTolerance: '',
        //boolean
        allowSpots: '',
        //boolean
        allowForwards: '',
        cutOffTime: '',
        listStatus: [],
        listCurrencyType: [],
        updateToken: 0,
        //for alert box
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        saved: false
    }

    componentDidMount() {
        getLookUpTable('Currency Type').then(
            (json) => {
                if (json != null && json.lookUpTables != null && json.lookUpTables[0] != null) {
                    const lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails
                        .map((lookUp) => ({...lookUp, value: lookUp.LookUpTableDetail, name: lookUp.LookUpTableDetail}));
                    this.setState({ listStatus: lookUpTableDetails });
                } else {
                    this.setState({ listStatus: [] });
                }
            }
        );
        getCurrencyType().then(
            (json) => {
                if (json != null) {
                    const currencies = json.map((currency) => ({...currency, value: currency.CurrencyGroupId, name: currency.Description}));
                    this.setState({ listCurrencyType: currencies });
                } else {
                    this.setState({ listCurrencyType: [] });
                }
            }
        );
        if (this.props.currencyList !== null && this.props.currencyList != null) {
            var currency = this.props.currencyList;
            var currencyID = 0;
            var avail, all, allf = '';
            if (currency['Currency Id'] !== null) {
                currencyID = currency['Currency Id'];
            }
            if (currency['Available Online'] !== '') { avail = currency['Available Online'] } else { avail = false }
            if (currency['Allow Spots'] !== '') { all = currency['Allow Spots'] } else { all = false }
            if (currency['Allow Forwards'] !== '') { allf = currency['Allow Forwards'] } else { allf = false }
            if (currencyID != null) {
                this.setState({
                    currencyID: currencyID,
                    symbol: currency['Symbol'],
                    currencyName: currency['Currency Name'],
                    displayOrder: currency['Display Order'],
                    availableOnline: avail,
                    minDenomination: currency['Min Denomination'],
                    status: currency['Status'],
                    riskScore: currency['Risk Score'],
                    rateTimeTolerance: currency['Rate Time Tolerance'],
                    allowSpots: all,
                    allowForwards: allf,
                    updateToken: currency['UpdateToken'],
                    currencyType: currency['Currency Group']
                });
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.currencyList !== null && prevProps.currencyList !== this.props.currencyList) {
            var currency = this.props.currencyList;
            var currencyID = 0;
            var avail, all, allf = '';
            if (currency['Currency Id'] !== null) {
                currencyID = currency['Currency Id'];
            }
            if (currency['Available Online'] !== '') { avail = currency['Available Online'] } else { avail = false }
            if (currency['Allow Spots'] !== '') { all = currency['Allow Spots'] } else { all = false }
            if (currency['Allow Forwards'] !== '') { allf = currency['Allow Forwards'] } else { allf = false }
            this.setState({
                currencyID: currencyID,
                symbol: currency['Symbol'],
                currencyName: currency['Currency Name'],
                displayOrder: currency['Display Order'],
                availableOnline: avail,
                minDenomination: currency['Min Denomination'],
                status: currency['Status'],
                riskScore: currency['Risk Score'],
                rateTimeTolerance: currency['Rate Time Tolerance'],
                allowSpots: all,
                allowForwards: allf,
                updateToken: currency['UpdateToken'],
                currencyType: currency['Currency Group']
            });
        };
    }

    getStatusSelect(value) {
        if (value != null && value !== "select") {
            this.setState({ status: value.value })
        }
    }

    getCurrencyType1(value) {
        if (value != null && value !== "select") {
            this.setState({ currencyType: value.value })
        }
    }
    handleClearClick(event) {
        event.preventDefault();
        this.setState({
            currencyID: '',
            symbol: '',
            currencyName: '',
            displayOrder: 0,
            availableOnline: '',
            minDenomination: 0,
            riskScore: 0,
            rateTimeTolerance: '',
            allowSpots: '',
            allowForwards: '',
            cutOffTime: '',
            updateToken: 0,
            currencyType: '',
            status: '',
            notFirstLoad: false
        });
    }

    closeAlert() {
        this.setState({ showAlert: false });
        if (this.state.saved && this.props.onClose != null) {
            this.props.onClose();
        }
    }

    handleSaveClick(event) {
        event.preventDefault();
        let userID = localStorage.getItem('UserID');
        var utok, avail, all, allf = '';
        if (this.state.updateToken !== null && this.state.updateToken !== '') { utok = this.state.updateToken } else { utok = 0 }
        if (this.state.availableOnline !== '') { avail = this.state.availableOnline } else { avail = false }
        if (this.state.allowSpots !== '') { all = this.state.allowSpots } else { all = false }
        if (this.state.allowForwards !== '') { allf = this.state.allowForwards } else { allf = false }
        var objectFilter = {
            CurrencyId: this.state.currencyID,
            Symbol: this.state.symbol,
            CurrencyName: this.state.currencyName,
            DisplayOrder: this.state.displayOrder,
            AvailableOnline: avail,
            MimDenomination: this.state.minDenomination,
            RiskScore: this.state.riskScore,
            RateTimeTolerance: this.state.rateTimeTolerance,
            AllowSpots: all,
            AllowForwards: allf,
            Status: this.state.status,
            UserId: userID,
            CurrencyGroup: this.state.currencyType,
            UpdateToken: utok
        }
        if (this.validateRequiredFields()) {
            if (this.state.riskScore >= 0 && this.state.riskScore <= 100) {
                saveCurrency(objectFilter).then(
                    (json) => {
                        if (json.httpStatusCode !== 200) {
                            var alertMsg = 'An error occurred while trying to save message. Please try again.'
                            if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                                alertMsg = json.httpErrorMessage;
                            }
                            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: alertMsg, saved: false });
                        } else {
                            this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Currency successfully saved.', updateToken: json.updateToken, currencyID: json.currencyId, saved: true })
                            this.props.updatePage();
                        }
                    }
                );
            } else {
                this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'Risk Score value must be less or equal than 100. Please, try again.' });
            }
        } else {
            this.setState({ notFirstLoad: true });
        }
    }
    validateRequiredFields() {
        if ((this.state.currencyID === '')) {
            return false;
        }
        if ((this.state.symbol === '')) {
            return false;
        }
        if ((this.state.currencyName === '')) {
            return false;
        }
        if ((this.state.displayOrder === '' || this.state.displayOrder === "0")) {
            return false;
        }
        if ((this.state.minDenomination === '')) {
            return false;
        }
        if ((this.state.riskScore === '')) {
            return false;
        }
        if ((this.state.currencyType === '' || this.state.currencyType === "select" || this.state.currencyType === undefined)) {
            return false;
        }
        if ((this.state.status === '' || this.state.status === "select" || this.state.status === undefined)) {
            return false;
        }
        return true;
    }
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleUpdateCurrencyID(event) {
        this.setState({ currencyID: event.target.value });
    }
    handleUpdateSymbol(event) {
        this.setState({ symbol: event.target.value });
    }
    handleUpdateCurrencyName(event) {
        this.setState({ currencyName: event.target.value });
    }
    handleUpdateDisplayOrder(event) {
        this.setState({ displayOrder: event.target.value });
    }
    handleUpdateAvailableOnline(event) {
        this.setState({ availableOnline: event.target.checked });
    }
    handleUpdateMinDenomination(event) {
        this.setState({ minDenomination: event.target.value });
    }
    handleUpdateStatus(event) {
        this.setState({ status: event.target.value });
    }
    handleUpdateRiskScore(event) {
        this.setState({ riskScore: event.target.value });
    }
    handleUpdateRateTimeTolerance(event) {
        this.setState({ rateTimeTolerance: event.target.value });
    }
    handleUpdateAllowSpots(event) {
        this.setState({ allowSpots: event.target.checked });
    }
    handleUpdateAllowForwards(event) {
        this.setState({ allowForwards: event.target.checked });
    }
    handleUpdateCutOffTime(event) {
        this.setState({ cutOffTime: event.target.value });
    }

    render() {
        var classNameInputCurrencyID = regularInputClass;
        var classNameInputSymbol = regularInputClass;
        var classNameInputName = regularInputClass;
        var classNameInputNameDisplayOrder = regularInputClass;
        var classNameInputMinDenomination = regularInputClass;
        var classNameRikScore = regularInputClass;
        var classNameType = regularSelectClass;
        var classNameStatus = regularSelectClass;

        if ((this.state.currencyID === '') && this.state.notFirstLoad) {
            classNameInputCurrencyID = notFilledInputClass;
        }
        if ((this.state.symbol === '') && this.state.notFirstLoad) {
            classNameInputSymbol = notFilledInputClass;
        }
        if ((this.state.currencyName === '') && this.state.notFirstLoad) {
            classNameInputName = notFilledInputClass;
        }
        if ((this.state.displayOrder === '' || this.state.displayOrder === "0") && this.state.notFirstLoad) {
            classNameInputNameDisplayOrder = notFilledInputClass;
        }
        if ((this.state.minDenomination === '') && this.state.notFirstLoad) {
            classNameInputMinDenomination = notFilledInputClass;
        }
        if ((this.state.riskScore === '') && this.state.notFirstLoad) {
            classNameRikScore = notFilledInputClass;
        }
        if ((this.state.currencyType === '' || this.state.currencyType === "select" || this.state.currencyType === undefined) && this.state.notFirstLoad) {
            classNameType = notFilledSelectClass;
        }
        if ((this.state.status === '' || this.state.status === "select" || this.state.status === undefined) && this.state.notFirstLoad) {
            classNameStatus = notFilledSelectClass;
        }
        return (
            <div className="uk-form-stacked uk-grid">
                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-id">Currency ID(*)</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputCurrencyID} id="currencyID" placeholder="Country ID" type="text" onChange={this.handleUpdateCurrencyID.bind(this)} value={this.state.currencyID} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-symbol">Symbol(*)</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputSymbol} placeholder="Symbol" id="symbol" type="text" value={this.state.symbol} onChange={this.handleUpdateSymbol.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-name">Currency Name(*)</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputName} id="currencyName" placeholder="Currency Name" type="text" value={this.state.currencyName} onChange={this.handleUpdateCurrencyName.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-displayOrder">Display Order(*)</label>
                    <div className="uk-form-controls">
                        <NumberInput id="displayOrder" type="Integer" className={classNameInputNameDisplayOrder} placeholder="99999" value={this.state.displayOrder} onChange={this.handleUpdateDisplayOrder.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-type">Currency type(*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className={classNameType} getValue={this.getCurrencyType1.bind(this)} placeholder="Select an Item" objValue={{ value: this.state.currencyType }} data={this.state.listCurrencyType} id="form-currency-status" />
                    </div>
                </div>

                <div className="uk-width-1-2 uk-margin" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-availableOnline"><input className="uk-checkbox" id="availableOnline" type="checkbox" checked={this.state.availableOnline} onChange={this.handleUpdateAvailableOnline.bind(this)} /> Available Online</label>

                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-minDenomination">Min Denomination(*)</label>
                    <div className="uk-form-controls">
                        <NumberInput id="minDenomination" type={"Currency"} scale={18} placeholder="0.00" className={classNameInputMinDenomination} value={this.state.minDenomination} onChange={this.handleUpdateMinDenomination.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-status">Status(*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className={classNameStatus} getValue={this.getStatusSelect.bind(this)} listHasPlaceholder={true} objValue={{ value: this.state.status }} data={this.state.listStatus} id="form-currency-status" />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-riskScore">Risk Score(*)</label>
                    <div className="uk-form-controls">
                        <NumberInput className={classNameRikScore} id="riskScore" type="Integer" placeholder="0" value={this.state.riskScore} onChange={this.handleUpdateRiskScore.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-rateTimeTolerance">Rate Time Tolerance</label>
                    <div className="uk-form-controls">
                        <NumberInput id="rateTimeTolerance" type="Integer" placeholder="0" className="uk-input" value={this.state.rateTimeTolerance} onChange={this.handleUpdateRateTimeTolerance.bind(this)} />
                    </div>
                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10, marginTop: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-allowSpots"><input className="uk-checkbox" id="allowSpots" type="checkbox" checked={this.state.allowSpots} onChange={this.handleUpdateAllowSpots.bind(this)} /> Allow Spots</label>

                </div>

                <div className="uk-width-1-2" style={{ marginBottom: 10, marginTop: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-allowForwards"><input className="uk-checkbox" id="allowForwards" type="checkbox" checked={this.state.allowForwards} onChange={this.handleUpdateAllowForwards.bind(this)} /> Allow Forwards</label>

                </div>
{/* 
                <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                    <label className="uk-form-label" htmlFor="form-currency-cutOffTime">Cut Off Time</label>
                    <div className="uk-form-controls">
                        <input className="uk-input" id="cutOffTime" type="text" placeholder="00:00" value={this.state.cutOffTime} onChange={this.handleUpdateCutOffTime.bind(this)} disabled={true} />
                    </div>
                </div> 

                <div className="uk-width-1-2">
                    <br></br>
                </div>
*/}
                {this.props.embedded != null && this.props.embedded ?
                    (<div className="uk-width-1-1">
                        <br></br>
                        <button className="uk-button uk-button-green uk-align-right uk-margin-remove-bottom" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <button className="uk-button uk-button-green uk-align-right" onClick={() => this.props.onClose()}>Close</button>
                    </div>)
                    :
                    (<div className="uk-width-1-2">
                        <br></br>
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                    </div>)}
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}
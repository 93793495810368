import React from 'react';
import { createIncomingFund, getCompleteEntityFunding, voidIncomingFund } from '../../networking/NetworkingFunds';
import { applyPayment } from '../../networking/NetworkingPayments';
import NumberInput from './NumberInput';
import { formatToCurrency, formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrency } from '../../helpers/FormatHelper';
import AlertBox from './AlertBox';

export default class EntityFunding extends React.Component {
    state = {
        alertMessage: '',
        alertTitle: '',
        elementToProcess: {},
        enableAmountUpdates: true,
        processName: '',
        records: [],
        showAlert: false,
        showYesNoAlert: false,
        ynAlertMessage: '',
        validations : []
    }

    componentDidMount (){
        this.loadRecords();
    }

    componentDidUpdate(prevProps){
        if(prevProps.currencyID !== this.props.currencyID ||
            prevProps.entityID !== this.props.entityID){
            this.loadRecords();
        }

        if(this.props.balance !== prevProps.balance)
        {
            this.loadRecords();
        }
    }

    loadRecords(){
        let entityID = Number(this.props.entityID);
        if(entityID > 0){
            getCompleteEntityFunding(this.props.customerID, this.props.currencyID, this.props.entityType, entityID).then(
                (response) => {
                    //alert(JSON.stringify(response));
                    //console.log('response', response);
                    var records = [];
                    var validations = [];

                    if(response.records !== undefined)
                    {
                        records = response.records;

                        if(Number(this.props.balance) === 0)
                        {
                            records = records.filter((record) => { return !record.ForFunding; });
                        }

                        records = records.filter((record) => { return (record.Source !== "Deposit" || (record.Source === "Deposit" && record.CanBeUsed === 1)); });

                        records.forEach(element => {
                            validations.push(true);
                        });
                    }

                    this.setState({
                        enableAmountUpdates : false,
                        records             : records,
                        validations         : validations
                    }, ()=>{
                        this.setState({enableAmountUpdates: true});
                    });
                }
            );
        }
    }

    handleVoidIncomingClick = (element) => {

        if(this.props.noVoidAllowedMessage !== undefined){
            this.setState({alertMessage: this.props.noVoidAllowedMessage,
                alertTitle: 'Error',
                showAlert: true,
            });
        }
        else{
            var ynAlertMessage = 'Are you sure you want to void this source application?';
            if(this.props.additionalWarningVoidMessage !== undefined){
                ynAlertMessage += this.props.additionalWarningVoidMessage;
            }
            this.setState({elementToProcess: element,
                processName: 'Void',
                showYesNoAlert: true,
                ynAlertMessage: ynAlertMessage,
            });
        }        
    }

    handleSettleEntity = (element, index) => {
        var sourceText = element.Source;
        if(element.SourceID > 0){
            sourceText = sourceText + ' ' + element.SourceID;
        }

        if(element.AmountApplied !== undefined && element.AmountApplied !== '' && element.AmountApplied !== 0)
        {
            this.setState({
                elementToProcess    : element,
                processName         : 'Apply',
                showYesNoAlert      : true,
                ynAlertMessage      : 'Are you sure you want to apply ' + formatToCurrencyWithScale(element.AmountApplied,this.props.currencyID) + ' from ' + sourceText + '?',
            });
        }else
        {
            let auxValdiation       = this.state.validations;
            auxValdiation[index]    = false;
            this.setState({
                validations: auxValdiation
            });
        }
    }

    handleFocusAmountApplied = (element, i, event) => {
        //alert('handleFocusAmountApplied:' + JSON.stringify(element));
        if(Number(element.AmountApplied) === 0)
        {
            let records = this.state.records;
            var elementToUpdate = records.find((el) => { return el.SourceID === element.SourceID && Number(el.ForFunding) === 1 });
            let balance = this.props.balance;
            let amountUsed = 0;
            records.forEach(record => {
                if(Number(record.ForFunding) === 1){
                    amountUsed += record.AmountApplied;
                }                
            });
            let realBalance = balance - amountUsed;
            var amountApplied = 0;
            if(element.Amount >= realBalance){
                amountApplied = realBalance;
            }
            else{
                amountApplied = element.Amount;
            }
            elementToUpdate.AmountApplied = roundAmountByCurrency(amountApplied, this.props.currencyID);

            let auxList = this.state.validations;
            auxList.forEach((element, j) => {
                if(i === j)
                {
                    auxList[j] = true;
                }
            });

            this.setState({
                records     : records,
                validations : auxList
            });
        }
    }

    handleUpdateAmountApplied = (element, i, event) => {
        if(this.state.enableAmountUpdates){
            var amountApplied = Number(event.target.value);
            if(amountApplied > Number(element.AvailableBalance)){
                amountApplied = Number(element.AvailableBalance);
            }
            if(amountApplied > Number(this.props.balance)){
                amountApplied = Number(this.props.balance);
            }
            let records = this.state.records;
            var elementToUpdate = records.find((el) => { return el.SourceID === element.SourceID && Number(el.ForFunding) === 1 });
            elementToUpdate.AmountApplied = roundAmountByCurrency(amountApplied, this.props.currencyID);

            let auxList = this.state.validations;
            auxList.forEach((element, j) => {
                if(i === j)
                {
                    auxList[j] = true;
                }
            });

            this.setState({
                enableAmountUpdates : false,
                records             : records,
                validations         : auxList
            }, () => {
                this.setState({
                    enableAmountUpdates: true
                });
            });
        }
    }

    closeGlobalAlert = () => {
        this.setState({showAlert: false});
    }

    yesClickConfirmation = () => {
        this.setState({showYesNoAlert: false});
        //alert('handleSettleEntity:' + JSON.stringify(element));
        let element = this.state.elementToProcess;
        switch(this.state.processName){
            case 'Apply':                
                var amountToApply = roundAmountByCurrency(Number(element.AmountApplied), this.props.currencyID);
                var dealUpdateToken = 0;
                var depositUpdateToken = 0;
                switch(this.props.entityType){
                    case 'Deal':
                        dealUpdateToken = this.props.entityUpdateToken;
                        break;
                    case 'Deposit':
                        depositUpdateToken = this.props.entityUpdateToken;
                        break;
                    default:
                        break;
                }
                var records = [];
                switch(element.Source){
                    case 'Payment':
                        records.push({
                            RecordID: this.props.entityID,
                            AmountToUse: amountToApply,
                            TypeRecord: this.props.entityType,
                            DealUpdateToken: dealUpdateToken,
                            DepositUpdateToken: depositUpdateToken,
                        });
                        var applyPaymentRequest = {
                            PaymentID: element.SourceID,
                            PaymentUpdateToken: element.UpdateToken,
                            UserId: localStorage.getItem('UserID'),
                            Records: records,
                        }

                        applyPayment(applyPaymentRequest).then(async (response) => {
                            //alert(JSON.stringify(response));
                            switch (Number(response.httpStatusCode)) {
                                case 200:
                                    this.setState({showAlert: true,
                                        alertTitle: 'Success',
                                        alertMessage: 'Payment applied successfully!',
                                    });
                                    //this.loadRecords();
                                    if(this.props.reloadEntity !== undefined){
                                        this.props.reloadEntity(this.props.entityID);
                                    }
                                    break
                                case 409:
                                    this.setState({
                                        showAlert: true,
                                        alertTitle: 'Error',
                                        alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                                    });
                                    break;
                                default:
                                    this.setState({
                                        showAlert: true,
                                        alertTitle: 'Error',
                                        alertMessage: 'An error occurred while trying to apply payment. Please try again.',
                                    });
                                    break;
                            }
                        });//applyPayment(applyPaymentRequest).then(
                        break;//case 'Payment':
                    case 'Held Funds':
                    case 'Deposit':
                        //Defaults for Held Funds
                        var sourceID = 0;
                        var sourceTable = 'H';
                        if(element.Source === 'Deposit'){
                            sourceID = element.SourceID;
                            sourceTable = 'D';
                        }
                        records.push({
                            EntityTYpe: this.props.entityType,
                            RecordID: this.props.entityID,
                            AmountToUse: amountToApply,
                            UpdateToken: this.props.entityUpdateToken,
                        });
                        var model = {
                            SourceID: sourceID,
                            SourceTable: sourceTable,
                            UserID: localStorage.getItem('UserID'),
                            SourceUpdateToken: element.UpdateToken,
                            Records: records,
                        }
                        createIncomingFund(model).then(
                            (response) => {
                                //alert(JSON.stringify(response));
                                switch (Number(response.httpStatusCode)) {
                                    case 200:
                                        var alertMessage = 'Application completed successfully!'
                                        switch(element.Source){
                                            case 'Held Funds':
                                                alertMessage = 'Held funds applied successfully!';
                                                break;
                                            case 'Deposit':
                                                alertMessage = 'Deposit applied successfully!';
                                                break;
                                            default:
                                                break;
                                        }
                                        this.setState({showAlert: true,
                                            alertTitle: 'Success',
                                            alertMessage: alertMessage,
                                        });
                                        //this.loadRecords();
                                        if(this.props.reloadEntity !== undefined){
                                            this.props.reloadEntity(this.props.entityID);
                                        }
                                        break;
                                    case 409:
                                        this.setState({
                                            showAlert: true,
                                            alertTitle: 'Error',
                                            alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                                        });
                                        break;
                                    default:
                                        this.setState({
                                            showAlert: true,
                                            alertTitle: 'Error',
                                            alertMessage: 'An error occurred while trying to apply payment. Please try again.',
                                        });
                                        break;
                                }
                        });//createIncomingFund(model).then(
                        break;//case 'Held Funds':
                    default:
                        break;
                }//switch(element.Source){
                break;//case 'Apply':
            case 'Void':
                //alert('handleVoidIncomingClick:' + element.IncomingFundsID);
                voidIncomingFund(element.IncomingFundsID, 
                    localStorage.getItem('UserID'), 
                    element.UpdateToken, 0, 0, 0, 0).then(
                    (response) => {
                        if (Number(response.httpStatusCode) === 200)
                        {
                            this.setState({
                                showAlert   : true,
                                alertTitle  : 'Success',
                                alertMessage: 'Funds application voided!',
                            });

                            //this.loadRecords();

                            if(this.props.reloadEntity !== undefined)
                            {
                                this.props.reloadEntity(this.props.entityID);
                            }
                        }
                        else{
                            var alertMessage = 'Funds application not voided! Please try again or contact administrator.'; 
                            if(response.httpErrorMessage !== undefined){
                                alertMessage = response.httpErrorMessage;
                            }
                            this.setState({
                                alertTitle: 'Error',
                                alertMessage: alertMessage,
                                showAlert: true,
                            });
                        }                
                    }
                );
                break;//case 'Void':
            default:
                break;//default:
        }//switch(this.state.processName){
    }

    noClickConfirmation = () => {
        this.setState({
            elementToProcess    : false,
            processName         : '',
            showYesNoAlert      : false,
        });
    }

    render(){
 
        var rows = [];
        var records = this.state.records;

        var index = 0;
        records.forEach((element, i) => {
            var action;
            if(element.ForFunding){
                action = (<i className="fa fa-money" onClick={() => this.handleSettleEntity(element, i)} style={{ fontSize: '1.25em', verticalAlign: 'middle', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px'  }}title={'Apply'}/>);
            }
            else{
                action = (<i className="fa fa-chain-broken" onClick={() => this.handleVoidIncomingClick(element)} style={{ fontSize: '1.25em', verticalAlign: 'middle', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px'  }}title={'Unapply'}/>);
            }
            var amountControl;
            if(Number(element.IncomingFundsID) > 0)
            {
                amountControl = formatToCurrencyWithScale(element.AmountApplied,this.props.currencyID);
            }
            else{
                amountControl = (<NumberInput id={'amountApplied-' + index}
                    type="Currency"
                    className= {this.state.validations[index] ? 'uk-input' : ' uk-input uk-form-danger'}
                    value={element.AmountApplied}
                    scale={getScaleByCurrency(this.props.currencyID)}
                    onFocus={(event) => this.handleFocusAmountApplied(element,i , event)}
                    onChange={(event) => this.handleUpdateAmountApplied(element, i, event)}
                />);
            }

            var sourceIDcontrol;
            if(Number(element.SourceID) > 0)
            {
                sourceIDcontrol = element.SourceID;
            }

            rows.push(
                <tr key={'funding-' + index}>
                    <td style={{textAlign: 'center'}}>{element.Source}</td>
                    <td style={{textAlign: 'center'}}>{sourceIDcontrol}</td>
                    <td className="text-align-right">{formatToCurrencyWithScale(element.Amount,this.props.currencyID)}</td>
                    <td className="text-align-right">{formatToCurrencyWithScale(element.AvailableBalance,this.props.currencyID)}</td>
                    <td className="text-align-right">{amountControl}</td>
                    <td
                        style = {{
                            textAlign       : "center",
                            verticalAlign   : 'middle'
                        }}
                    >{action}</td>
                </tr>
            );
            index++;
        });

        return (
            <div>
                <table className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped">
                    <thead style={{textAlignLast: 'center'}}>
                        <th>Source</th>
                        <th>Source ID</th>
                        <th>Amount</th>
                        <th>Available Balance</th>
                        <th>Amount Applied</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                
                <AlertBox id="global-alert" 
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    onClose={this.closeGlobalAlert}
                    okClick={this.closeGlobalAlert}/>

                <AlertBox id="yes-no-alert"
                    open={this.state.showYesNoAlert} 
                    title="Confirm" 
                    message = {this.state.ynAlertMessage}
                    type = "Yes/No"
                    yesClick = {this.yesClickConfirmation}
                    noClick = {this.noClickConfirmation}/>
            </div>
        );
    }
}
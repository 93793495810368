

export const ActionName = {
    EditCustomerInfo : "EditCustomerInfo",
    ShowDealInfo : "ShowDealInfo",
    ShowDealInfoScreen : "ShowDealInfoScreen",
    ShowDepositInfo : 'ShowDepositInfo',
    ShowCustomerInfo : 'ShowCustomerInfo',
    DealVersions : "Deal Versions",
    BeneficiaryInfo : "Beneficiary Info",
    AddPayment : "AddPayment",
    AddDeposit : "AddDeposit",
    AddRequest : "AddRequest",
    ShowPaymentInfo : "ShowPaymentInfo",
    ShowCustomerPaymentScreen: "ShowCustomerPaymentScreen",
    ShowCountryAdditionalSettings: "ShowCountryAdditionalSettings",
    ShowOtherSettlementFunds: "ShowOtherSettlementFunds",
    ShowOutgoingFundsScreen: "ShowOutgoingFundsScreen",
    ShowBeneficiaryInfo: 'ShowBeneficiaryInfo',
    EditBeneficiaryInfo: 'EditBeneficiaryInfo',
    ShowComplaintInfo: 'ShowComplaintInfo'
}

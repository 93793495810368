import { Dialog, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { deleteUserPropertyExt, saveUserPropertiesExt } from '../../../networking/NetworkingUserProperties';
import AlertBox from '../../shared/AlertBox';
import { DialogTitle } from '../../shared/Dialogs/AttachmentsDialog';
import NumberInput from '../../shared/NumberInput';
import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'
import {getScaleByCurrency} from '../../../helpers/FormatHelper'

const AdditionalPropertiesDialog = ({ open, onClose, propertiesID, propertyName, properties: { _amountLimit, _mandatory, _attachmentsRequired, _printable, _updateToken } }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [amountLimit, setAmountLimit] = useState(null);
    const [mandatory, setMandatory] = useState(false);
    const [attachmentsRequired, setAttachmentsRequired] = useState(false);
    const [printable, setPrintable] = useState(false);
    const [updateToken, setUpdateToken] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [baseCurrency, setBaseCurrency]= useState(localStorage.getItem(GlobalSystemParametersNames.BaseCurrency));

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    useEffect(() => {
        setAmountLimit(_amountLimit != null ? _amountLimit : null);
    }, [_amountLimit]);

    useEffect(() => {
        setMandatory(_mandatory);
    }, [_mandatory]);

    useEffect(() => {
        setAttachmentsRequired(_attachmentsRequired);
    }, [_attachmentsRequired]);

    useEffect(() => {
        setPrintable(_printable);
    }, [_printable]);

    useEffect(() => {
        setUpdateToken(_updateToken);
    }, [_updateToken]);

    const handleSaveClick = async () => {
        const model = {
            UserPropertyID: propertiesID,
            AmountLimit: amountLimit,
            Mandatory: mandatory,
            AttachmentsRequired: attachmentsRequired,
            Printable: printable,
            UserID: localStorage.getItem('UserID'),
            UpdateToken: updateToken
        };
        console.log(model)
        const json = await saveUserPropertiesExt(model);
        if (json != null) {
            switch (Number(json.httpStatusCode)) {
                case 200: {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Additional Fields successfully saved.');
                    setUpdateToken(json.updateToken);
                    break;
                }
                default: {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    break;
                }
            }
        }
    };

    const handleDeleteClick = async () => {
        
        const json = await deleteUserPropertyExt(propertiesID);
        if (json != null) {
            switch (Number(json.httpStatusCode)) {
                case 200: {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Additional Fields successfully deleted.');
                    setUpdateToken(json.updateToken);
                    break;
                }
                default: {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    break;
                }
            }
        }
    }

    const exit = () => {
        setOpenDialog(false);
        onClose(updateToken);
    };

    const closeAlert = () => {
        setShowAlert(false);
        exit();
    }

    return (
        <Dialog open={openDialog} onClose={exit} fullWidth={true} maxWidth="xs">
            <DialogTitle id="searchgloballists">
                <div>
                    <h4>
                        Additional Fields<span id="label-watch-list"></span>
                    </h4>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <div className="uk-grid uk-margin" style={{ marginTop: '0px' }}>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <h5>{propertyName}</h5>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Amount Limit</label>
                        <div className="">
                            <NumberInput
                                className="uk-input"
                                id="amount-limit"
                                type="Currency"
                                placeholder=""
                                scale={getScaleByCurrency(baseCurrency)}
                               // min={0}
                                value={amountLimit}
                                onChange={(e) => setAmountLimit(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Mandatory</label>
                        <div className="">
                            <input
                                className="uk-checkbox"
                                id="mandatory"
                                type="checkbox"
                                checked={mandatory}
                                value={mandatory}
                                onChange={(e) => setMandatory(e.target.checked)}
                            />
                        </div>
                    </div> */}
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Attachments Required</label>
                        <div className="">
                            <input
                                className="uk-checkbox"
                                id="attachments-required"
                                type="checkbox"
                                checked={attachmentsRequired}
                                value={attachmentsRequired}
                                onChange={(e) => setAttachmentsRequired(e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Printable</label>
                        <div className="">
                            <input
                                className="uk-checkbox"
                                id="printable"
                                type="checkbox"
                                checked={printable}
                                value={printable}
                                onChange={(e) => setPrintable(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
                <div className="uk-text-right">
                    <button className="uk-button uk-button-green uk-margin-right" onClick={handleDeleteClick} type="button">
                        Delete
                    </button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={handleSaveClick} type="button">
                        Save
                    </button>
                    <button className="uk-button uk-button-green" onClick={exit} type="button">
                        Exit
                    </button>
                </div>
                <AlertBox
                    id="alertbox-additional-properties"
                    open={showAlert}
                    onClose={closeAlert}
                    title={alertTitle}
                    message={alertMessage}
                    type="Ok"
                    okClick={closeAlert}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AdditionalPropertiesDialog;

import React from 'react';

import {myPerformance} from '../../../networking/NetworkingWidget';

import {formatToCurrencyWithScale} from '../../../helpers/FormatHelper';

import { Gauge } from '@ant-design/charts';

export default class MyPerformance extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = { 
            periodTotal     : 0,
            periodProfit    : 0,
            baseCurrency    : '',
            traderName      : '',
            nodata          : false,
            //Checks
            ytd           : false,
            last12months  : true
        };
    }

    componentDidMount()
    {
        this.getMyPerformance('Last 12 months');
    }

    getMyPerformance(period)
    {
        myPerformance(localStorage.getItem('UserID'), period).then(
            (response) => {
                if(response !== undefined)
                {
                    console.log("myPerformance: " + JSON.stringify(response));
                    console.log(response);

                    /*
                        myPerformance: 
                        {
                            "SalesVolumes":[
                                {
                                    "Period Total"  :66453.8,
                                    "Period Profit" :1500.11,
                                    "Base Currency" :"USD",
                                    "Trader name"   :"Admin"
                                }
                            ],
                            "httpStatusCode":200
                        }
                    */

                    if(response.SalesVolumes !== undefined && response.SalesVolumes.lengh > 0)
                    {
                        this.setState({
                            periodTotal     : response.SalesVolumes[0]['Period Total'],
                            periodProfit    : response.SalesVolumes[0]['Period Profit'],
                            baseCurrency    : response.SalesVolumes[0]['Base Currency'],
                            traderName      : response.SalesVolumes[0]['Trader name'],
                        });
                    }else
                    {
                        this.setState({
                            nodata          : true
                        });
                    }
                }
            }
        );
    }

    getColorByMonth = (month) => 
    {
        var color = '#8ac43f';
        return color;
    }

    handleUpdateYTD = (event) => {
        this.setState({
            ytd         : event.target.checked,
            last12months: !event.target.checked
        }, () =>{
            if(this.state.ytd === true)
            {
                this.getMyPerformance("YTD");
            }else
            {
                this.getMyPerformance("Last 12 months");
            }
        });
    }

    handleUpdateLast12Months = (event) => {
        this.setState({
            last12months: event.target.checked,
            ytd         : !event.target.checked
        }, () =>{
            if(this.state.last12months === true)
            {
                this.getMyPerformance("Last 12 months");
            }else
            {
                this.getMyPerformance("YTD");
            }
        });
    }

    render = () => {
        ////debugger
        var config = {
            percent: this.state.periodTotal === 0 ? 0: this.state.periodProfit/this.state.periodTotal,
            range:{
                ticks: [0, 1 / 3, 2 / 3, 1],
                color: ['#F4664A', '#FAAD14', '#30BF78'],
            },
            indicator: {
                pointer : { style: { stroke: '#D0D0D0' } },
                pin     : { style: { stroke: '#D0D0D0' } },
            },
            statistic: {
                content: {
                    style: {
                        fontSize    : '36px',
                        lineHeight  : '36px',
                    },
                },
            },
        };

        return(
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    //overflow: 'auto',
                    //backgroundColor: 'yellow',
                    flexDirection: 'column',
                    paddingTop: 10,
                    //
                    display: 'flex',
                    alignItems: 'stretch',
                    Margin:'0 auto',
                    //borderRadius: '10px',
                    //border: '2px solid #E7E7E7',
                }}
            >
                <div
                    className ='title-widget'
                    style={{
                        display         : 'block',
                        textAlign       : 'center'
                    }}
                >
                    <span>My Performance for {this.state.ytd === true? 'YTD':'last 12 months'}</span>
                </div>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%',
                    margin          : 0,
                    display         : 'flex',
                    flexDirection   : 'column',
                    textAlign       : 'center'
                }}
            >
                <Gauge {...config} />
                {/*
                <div style={{display: 'flex', height: '90%'}}>
                    <GaugeChart 
                        id          = "gauge-MyPerformance" 
                        nrOfLevels  = {10} 
                        percent     = {this.state.periodProfit/this.state.periodTotal}
                        textColor   = "black" 
                        needleColor = "#959e99" 
                        needleBaseColor = "#959e99"
                        style       = {{
                            width       : '100%',
                            height      : '100%',
                            paddingTop  : 10
                        }}
                    />
                </div>
                */}
                <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 10}}>
                    <div className="uk-width-1-2" style={{display: 'flex', flexDirection: 'column'}}>
                        <label className ='text-like-title-widget'>Period Total:</label>
                        <label>{formatToCurrencyWithScale(this.state.periodTotal, this.state.baseCurrency) + ' ' + this.state.baseCurrency}</label>
                    </div>
                    <div className="uk-width-1-2" style={{display: 'flex', flexDirection: 'column'}}>
                        <label className ='text-like-title-widget'>Period Profit:</label>
                        <label>{formatToCurrencyWithScale(this.state.periodProfit, this.state.baseCurrency) + ' ' + this.state.baseCurrency}</label>
                    </div>
                </div>
                <div style={{display: 'inline-flex'}}>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.ytd} 
                                onChange    = {this.handleUpdateYTD} 
                                type        = "checkbox"
                            /> YTD
                        </label>
                    </div>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.last12months} 
                                onChange    = {this.handleUpdateLast12Months} 
                                type        = "checkbox"
                            /> Last 12 months
                        </label>
                    </div>
                </div>
            </div>
          </div>
        );
    }
}
import {
    genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericGetWithParameters
} from './Networking';

export async function applyPaymentsToDeal(model) {
    var method = 'POST';
    var apiName = 'Payment/ApplyPaymentsToDeal'
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getListOfPaymentsForCustomer(customerID, currencyID, dealHeaderID, withAvailableBalance, showFullyApplied, page, items){
    try {
        let params = {
            'CustomerID': customerID,
            'CurrencyID': currencyID,
            'DealHeaderID': dealHeaderID,
            'WithAvailableBalance': withAvailableBalance,
            'ShowFullyApplied': showFullyApplied,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Payment/GetListOfPaymentsForCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPendingPaymentsByCustomer(customerID){
    try {
        let params = {
            'customerID': customerID
        }

        let response = await genericGetWithParameters('Payment/GetPendingPaymentsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function savePayment(model){
    var method = '';
    var apiName = '';
    if (model.paymentID === 0){
        method = 'POST';
        apiName = 'Payment/Post'

    } else {
        method = 'PUT';
        apiName = 'Payment/Put'
    }
    
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function getPayment(id){
    try {
        let params = {
            'paymentId': id
        }

        let response = await genericGetWithParameters('Payment/Get',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidPayment(model) {
    var method = 'POST';
    var apiName = 'Payment/VoidPayment'
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function applyPayment(model) {
    var method = 'POST';
    var apiName = 'Payment/ApplyPayment'
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getPaymentsWithBalanceByCustomerCurrency(CustomerID, CurrencyID, IncludeClientVostroPayments, IncludeNotForAutomaticBalanceAdjustment){
    try {
        let params = {
            'CustomerID': CustomerID,
            'CurrencyID': CurrencyID,
            'IncludeClientVostroPayments': IncludeClientVostroPayments,
            'IncludeNotForAutomaticBalanceAdjustment': IncludeNotForAutomaticBalanceAdjustment
        }
        
        let response = await genericGetWithParameters('Payment/GetPaymentsWithBalanceByCustomerCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function confirmDealSettlements(dealHeaderId, userId, createNewSettlement) {

    try {
        let model =
        {
            DealHeaderID: dealHeaderId,
            UserID: userId, 
            CreateNewSettlement: createNewSettlement
        }

        var method = 'POST';
        var apiName = 'Payment/ConfirmDealSettlements'
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendOutgoingsToInterface(outgoingFunds, customerID, interfaceName) {

    let method = 'POST';
    let apiName = 'Payment/SendOutgoingsToInterface';

    let model = {
        OutgoingFunds: outgoingFunds,
        CustomerID: customerID,
        InterfaceName: interfaceName
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}
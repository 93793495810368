export const validate = (fields) => {
    let valid = true;
    let errorList = [];
    let validations = {};
    fields.forEach(
        (field) => {
            if(field.validateName != null){
                validations[field.validateName] = true;
            }
    
            if (field.validation !== undefined) {
                if (!field.validation()) {
                    valid = false;
                    errorList.push(field.displayName);
                    if(field.validateName != null){
                        validations[field.validateName] = false;
                    }
                }
            } else {
                switch(field.type) {
                    default:
                    case 'string': {
                        if (field.value === undefined || field.value === null ||field.value === 'select' || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            if(field.validateName != null){
                                validations[field.validateName] = false;
                            }
                        }
                        break;
                    }
                    case 'number': {
                        if (field.value === undefined || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            if(field.validateName != null){
                                validations[field.validateName] = false;
                            }
                        }
                        break;
                    }
                    case 'wholeNumber': {
                        if (field.value === undefined || field.value === '') {
                            valid = false;
                            errorList.push(field.displayName);
                            if(field.validateName != null){
                                validations[field.validateName] = false;
                            }
                        }
                        break;
                    }
                    case 'email': {
                        if (!validateEmail(field.value)) {
                            valid = false;
                            errorList.push(field.displayName);
                            if(field.validateName != null){
                                validations[field.validateName] = false;
                            }
                        }
                        break;
                    }
                }
            }
        }
    )

    return [valid, validations, errorList];
}

const validateEmail = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email != null && email != '') {
        if (email.includes(';')) {
            const emails = email.split(';');
            if (emails.some(e => !e.match(regex))) {
                return false;
            }
        } else {
            if (!email.match(regex)) {
                return false;
            }
        }
    }
    else
    {
        return false;
    }
    return true;
}

export const validateEmail2 = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validateDateString = (string) => {
    if (string === null) {
        return false;
    }
    if (string.includes('/')) {
        return string.split('').length === 3;
    } else if (string.includes('-')) {
        return string.split('-').length === 3;
    } else {
        return false;
    }
};
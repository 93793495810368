import React from 'react';
import { getStyle } from '../../../../styles/styles';
import OwnerComplianceForm from './OwnerComplianceForm';
var style = getStyle();

export default class OwnerCompliance extends React.Component {
    state = {
        toggleReloadGlobalSearch: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.reloadGlobalSearch != null && this.props.reloadGlobalSearch != null && prevProps.reloadGlobalSearch !== this.props.reloadGlobalSearch) {
            this.setState({ toggleReloadGlobalSearch: this.props.reloadGlobalSearch });
        }
    }

    render() {
        let section = (
            <div className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <OwnerComplianceForm customer={this.props.customer} reloadGlobalSearch={this.state.toggleReloadGlobalSearch}
                         objOwnerInfo={this.props.objOwnerInfo} objFilterOwnerInfo={this.props.objFilterOwnerInfo} />
                </div>
            </div>
        );
        if (this.props.customer.CustomerID === undefined) {
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create beneficiary first.</h5>
                </div>
            );
        }
        return <div>{section}</div>;
    }
}

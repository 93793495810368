import { Breadcrumb } from 'antd';
import React from 'react';
import { formatToCurrencyWithScale, formatValidInput, formatValidSelect,getScaleByCurrency } from '../../../helpers/FormatHelper';
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';
import { saveFundsOnHoldBalanceTransfers, searchCustomerByIdNameMin } from '../../../networking/NetworkingCustomers';
import { getCustomerFundOnHoldBalanceByCurrencyJson } from '../../../networking/NetworkingFunds';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';



var style = getStyle();
export default class HeldFundsBalanceTransferForm extends React.Component{

    state = {
        customerList: [],
        customerListReceiving: [],
        //Sending Customer
        customerSendingSelected : {value:0, name:''},
        sendingStatus    : '-',
        sendingBalance   : 0,
        sendingAmount    : 0,
        currencies       : [],
        currencySelected : {value:'', name:''},
        //Receiving Customer
        customerReceivingSelected   : {value:0, name:''},
        receivingStatus             : '-',
        receivingBalance            : 0,
        //Extra Information
        commentTextArea     : '',
        //validation
        validations         : {
            customerSendingSelected     : true,
            amountSending               : true,
            currencySelected            : true,
            customerReceivingSelected   : true,
            comment                     : true,
        },
        //Alert
        showAlert       : false,
        alertTitle      : '',
        alertMessage    : '',
        messageList     : [],
    }

    componentDidMount()
    {
        getCurrencyList().then(
            (json) => {
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    this.setState({ currencies });
                }
            }
        );

        // newSearchCustomers('', 0, 0).then(
        //     (jsonResponse) => {
        //         console.log("newSearchCustomers: " + JSON.stringify(jsonResponse))
        //         if (jsonResponse.httpStatusCode === 200) {
        //             if (jsonResponse.customers !== undefined) 
        //             {
        //                 let tempCustomers = jsonResponse.customers;
        //                 let finalListOfCustomers = [];
        //                 tempCustomers.forEach(element => {
        //                     let el = element;
        //                     el.value = element['Customer ID'];
        //                     el.name = element['Customer Name'];

        //                     finalListOfCustomers.push(el);
        //                 });

        //                 finalListOfCustomers.sort(this.dynamicSort("name"));

        //                 this.setState({
        //                     customerList: finalListOfCustomers
        //                 });
        //             }
        //         }
        //     }
        // );
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) 
        {
            /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
            */
            //////debugger
            var x = a[property] == null ? "":a[property];
            var y = b[property] == null ? "":b[property];

            if(typeof(x) == 'string' && typeof(y) == 'string')
            {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            var result = (x < y) ? -1 : (x > y) ? 1 : 0;
            return result * sortOrder;
        }
    }
    
    handleGetValueCurrency = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) 
        {
            this.setState({
                currencySelected: value,
                validations     : {...this.state.validations, currencySelected: true}
            }, () =>{
                if(this.state.customerSendingSelected.value !== 0)
                {
                    this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerSendingSelected.value, value.value, 'sending');
                }
                if(this.state.customerReceivingSelected.value !== 0)
                {
                    this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerReceivingSelected.value, value.value, 'receiving');
                }
            });
        }
    }

    getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID, from)
    {
        getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID).then(
            (json) => {
                if (json !== undefined) {
                    if (json.Amount !== undefined) 
                    {
                        switch (from) {
                            case 'sending':
                                this.setState({ sendingBalance: json.Amount });
                                break;
                        
                            case 'receiving':
                                this.setState({ receivingBalance: json.Amount });
                                break;
                        }
                        
                    }
                    else {
                        switch (from) {
                            case 'sending':
                                this.setState({ sendingBalance: 0 });
                                break;
                        
                            case 'receiving':
                                this.setState({ receivingBalance: 0 });
                                break;
                        }
                    }
                }
                else {
                    switch (from) {
                        case 'sending':
                            this.setState({ sendingBalance: 0 });
                            break;
                    
                        case 'receiving':
                            this.setState({ receivingBalance: 0 });
                            break;
                    }
                }
            }
        );
    }

    handleUpdateSendingCustomer(value)
    {
        if (value.value !== undefined) 
        {
            this.setState({
                customerSendingSelected     : value.value !== 'select' ? value : 0,
                sendingStatus               : value.Status,
                validations                 : {...this.state.validations, customerSendingSelected: true},
                customerList: []
            }, () =>{
                if(this.state.currencySelected.value !== '')
                {
                    this.getCustomerFundOnHoldBalanceByCurrencyJson(value.value, this.state.currencySelected.value, 'sending');
                }
            });
        }
    }

    handleUpdateReceivingCustomer(value)
    {
        if (value.value !== undefined) 
        {
            this.setState({
                customerReceivingSelected   : value.value !== 'select' ? value : 0,
                receivingStatus             : value.Status,
                validations                 : {...this.state.validations, customerReceivingSelected: true},
                customerListReceiving: []
            }, () => {
                if(this.state.currencySelected.value !== '')
                {
                    this.getCustomerFundOnHoldBalanceByCurrencyJson(value.value, this.state.currencySelected.value, 'receiving');
                }
            });
        }
    }

    //Autocomplete Customer Name
    searchCustomer = (query, dropdown) => {
        if (query === '') {
            if (dropdown === 'Sending') {
                this.setState({ customerList: [] });
            } else {
                this.setState({ customerListReceiving: [] });
            }
        } else {
            searchCustomerByIdNameMin(query).then(
                (response) => {
                    if (response.customers !== undefined) {
                        for (var i = 0; i < response.customers.length; i++) {
                            response.customers[i].value = response.customers[i].CustomerID;
                            response.customers[i].name = response.customers[i].CustomerIDName;
                        }
                        if (dropdown === 'Sending') {
                            this.setState({ customerList: response.customers });
                        } else {
                            this.setState({ customerListReceiving: response.customers });
                        }
                    }
                }
            )
        }
    }

    handleUpdateSendingAmount = (event) =>
    {
        if (event != null && event.target.value !== undefined) 
        {
            this.setState({
                sendingAmount   : event.target.value,
                validations     : { ...this.state.validations, amountSending: true }
            });
        }
    }

    updateCommentText = (event) =>
    {
        this.setState({
            commentTextArea : event.target.value,
            validations     : { ...this.state.validations, comment: true }
        });
    }

    onClearFields = (event) => {
        this.setState({
            //Sending Customer
            customerSendingSelected : {value:0, name:''},
            sendingStatus           : '-',
            sendingBalance          : 0,
            sendingAmount           : 0,
            currencySelected        : {value:'', name:''},
            //Receiving Customer
            customerReceivingSelected   : {value:0, name:''},
            receivingStatus             : '-',
            receivingBalance            : 0,
            //Extra Information
            commentTextArea     : '',
            //validation
            validations         : {
                customerSendingSelected     : true,
                amountSending               : true,
                currencySelected            : true,
                customerReceivingSelected   : true,
                comment                     : true
            },
            //Alert
            showAlert       : false,
            alertTitle      : '',
            alertMessage    : '',
            messageList     : [],
        });
    }

    onClearFieldsAfterTransfer = () => {
        this.setState({
            //Sending Customer
            customerSendingSelected: { value: 0, name: '' },
            sendingStatus: '-',
            sendingBalance: 0,
            sendingAmount: 0,
            currencySelected: { value: '', name: '' },
            //Receiving Customer
            customerReceivingSelected: { value: 0, name: '' },
            receivingStatus: '-',
            receivingBalance: 0,
            //Extra Information
            commentTextArea: '',
            //validation
            validations: {
                customerSendingSelected: true,
                amountSending: true,
                currencySelected: true,
                customerReceivingSelected: true,
                comment: true
            }
        });
    }

    onTransfer = (event) => {
        let valuesOfValidate = this.validateFields();
        console.log(this.state)
        if (valuesOfValidate[0] && valuesOfValidate[1].length === 0) {
            this.setState({ loading: true }, () => {
                saveFundsOnHoldBalanceTransfers(
                    localStorage.getItem('UserID'),
                    this.state.customerSendingSelected.CustomerID,
                    this.state.customerReceivingSelected.CustomerID,
                    this.state.sendingAmount,
                    this.state.currencySelected.CurrencyID,
                    localStorage.getItem(GlobalSystemParametersNames.SystemDate),
                    //new Date(),//localStorage.getItem(GlobalSystemParametersNames.SystemDate),
                    this.state.commentTextArea
                ).then(
                    (response) => {
                        console.log(response)
                        let title = ''
                        let message = ''

                        if (response.transferId > 0) {
                            title = "Success"
                            message = "Transfer created successfully with ID " + response.transferId
                        }
                        else {
                            title = "Error"
                            message = response.errorMessage !== undefined ? response.errorMessage : "Something went wrong, Please try again later."
                        }

                        switch (response.httpStatusCode) {
                            case 200:
                                this.setState({
                                    showAlert: true,
                                    alertTitle: title,
                                    alertMessage: message,
                                    loading: false
                                }, () => this.onClearFieldsAfterTransfer());
                                break;
                            default:
                                let errorMessage = response !== undefined ? response.errorMessage : "Something went wrong, Please try again later."
                                this.setState({
                                    showAlert: true,
                                    alertTitle: "Error",
                                    alertMessage: errorMessage,
                                    loading: false
                                }, () => this.onClearFieldsAfterTransfer());
                        }
                    }
                ).finally(x => { this.setState({ loading: false }); })

            })
        }
        else {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:',
                messageList: valuesOfValidate[1]
            });
        }
    }

    validateFields = () =>{
        let customerSendingSelectedValid    = true;
        let sendingStatusValid              = true;
        let sendingAmountValid              = true;
        let currencySelectedValid           = true;
        let customerReceivingSelectedValid  = true;
        let receivingStatusValid            = true;
        let commentTextAreaValid            = true;

        let listErrors = [];

        if(this.state.customerSendingSelected.value === 0)
        {
            customerSendingSelectedValid = false; 
            listErrors.push('Select a Sending Customer.');
        }
        /*if(this.state.sendingStatus === '-' || this.state.sendingStatus === '' || this.state.sendingStatus !== 'Authorized to deal')
        {
            sendingStatusValid = false;
            if(this.state.sendingStatus !== '-' && this.state.sendingStatus !== '' && this.state.sendingStatus !== 'Authorized to deal')
            {
                listErrors.push('Status of Sending Customer is not correct.');
            }
        }*/
        if(this.state.sendingAmount === 0)
        {
            sendingAmountValid = false;
            listErrors.push('Amount to send is required.');
        }
        if(this.state.currencySelected.value === '')
        {
            currencySelectedValid = false;
            listErrors.push('Select a Currency.');
        }
        if(this.state.customerReceivingSelected.value === 0)
        {
            customerReceivingSelectedValid = false;
            listErrors.push('Select a Receiving Customer.');
        }
        /*if(this.state.receivingStatus === '-' || this.state.receivingStatus === '' || this.state.receivingStatus !== 'Authorized to deal')
        {
            receivingStatusValid = false;
        }*/
        if(this.state.commentTextArea === '')
        {
            commentTextAreaValid = false;
            listErrors.push('A Comment is required.');
        }

        let valid = customerSendingSelectedValid    &&
        sendingStatusValid              &&
        sendingAmountValid              &&
        currencySelectedValid           &&
        customerReceivingSelectedValid  &&
        receivingStatusValid            &&
        commentTextAreaValid;

        this.setState({
            validations     : { ...this.state.validations, 
                customerSendingSelected     : customerSendingSelectedValid,
                amountSending               : sendingAmountValid,
                currencySelected            : currencySelectedValid,
                customerReceivingSelected   : customerReceivingSelectedValid,
                comment                     : commentTextAreaValid
            }
        });

        return[valid, listErrors];
    }
    
    render() 
    {
        return (
            <div>
                {/* <div className="uk-width-1-1">
                    <h3 className="uk-heading-divider component-title" >Held Funds Balance Transfer</h3>
                </div> */}
                {/* <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item-bold">Held Funds Balance Transfer</Breadcrumb.Item>
                    </Breadcrumb>
                </div> */}
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-margin">
                        {/*Sending Customer*/}
                        <ul id="accordion-sending-customer" uk-accordion="multiple: true" >
                            <li className="uk-open">
                                <h5 className="uk-accordion-title accordion-new-style accordion-new-style green-background">Sending Customer</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div className='Sending Customer'>
                                        <form className="uk-form-horizontal">
                                            <div className="uk-grid">
                                                {/*Customer Sending*/}
                                                <div className="uk-width-1-2 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Customer (*)</label>
                                                    <div className="" style={{ paddingBottom: "5px" }}>
                                                        <DynamicSearchSelect
                                                            className={formatValidInput(this.state.validations.customerSendingSelected)}
                                                            id="select-sending-customer"
                                                            placeholder="Select a Customer..."
                                                            searchFunction={(query) => this.searchCustomer(query, 'Sending')}
                                                            getValue={(event) => this.handleUpdateSendingCustomer(event)}
                                                            data={this.state.customerList}
                                                            objValue={{ value: this.state.customerSendingSelected.value, name: this.state.customerSendingSelected.name }}
                                                        />
                                                    </div>
                                                </div>
                                                {/*Status*/}
                                                <div className="uk-width-1-2 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Status</label>
                                                    <div className="uk-margin" style={style.inputStyle}>
                                                        <label className="">{this.state.sendingStatus}</label>
                                                    </div>
                                                </div>
                                                {/*Balance*/}
                                                <div className="uk-width-1-2 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Balance</label>
                                                    <div className="uk-margin" style={{ paddingBottom: 10, paddingTop: 2 }}>
                                                        <label className="">{formatToCurrencyWithScale(this.state.sendingBalance, this.state.currencySelected.value) + ' ' + this.state.currencySelected.value}</label>
                                                    </div>
                                                </div>
                                                {/*Amount*/}
                                                <div className="uk-width-1-5 uk-form-stacked" style={{marginRight: '10px'}}>
                                                    <label className="uk-form-label" style={style.boldLabel} htmlFor="number-input-amount">Amount & Currency (*)</label>
                                                    <div className="" style={style.inputStyle}>
                                                        <NumberInput
                                                            className={formatValidInput(this.state.validations.amountSending)}
                                                            id='number-input-amount'
                                                            value={this.state.sendingAmount}
                                                            onChange={this.handleUpdateSendingAmount}
                                                            type="Currency"
                                                            scale={getScaleByCurrency(this.state.currencySelected.value)}
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                {/*Currency*/}
                                                <div className="uk-width-28 uk-form-stacked" style={{ padding: 0 }}>
                                                    <label className="uk-form-label" style={style.boldLabel}><br></br></label>
                                                    <div className="" style={style.inputStyle}>
                                                        <DynamicSelect
                                                            className={formatValidSelect(this.state.validations.currencySelected)}
                                                            objValue={{ value: this.state.currencySelected.value, name: this.state.currencySelected.name }}
                                                            getValue={this.handleGetValueCurrency}
                                                            listHasPlaceholder={false}
                                                            placeholder={"Select a Currency..."}
                                                            data={this.state.currencies}
                                                            id="form-commission-currencies"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-width-1-2">
                        {/*Receiving Customer*/}
                        <ul id="accordion-receiving-customer" uk-accordion="multiple: true" >
                            <li className="uk-open">
                                <h5 className="uk-accordion-title accordion-new-style green-background">Receiving Customer</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div className='Receiving Customer'>
                                        <form className="uk-form-horizontal">
                                            <div className="uk-grid">
                                                {/*Customer Receiving*/}
                                                <div className="uk-width-1-2 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Customer (*)</label>
                                                    <div className="" style={{ paddingBottom: "5px" }}>
                                                        <DynamicSearchSelect
                                                            className={formatValidInput(this.state.validations.customerReceivingSelected)}
                                                            id="select-receiving-customer"
                                                            placeholder="Select a Customer..."
                                                            searchFunction={(query) => this.searchCustomer(query, 'Receiving')}
                                                            getValue={(event) => this.handleUpdateReceivingCustomer(event)}
                                                            data={this.state.customerListReceiving}
                                                            objValue={{ value: this.state.customerReceivingSelected.value, name: this.state.customerReceivingSelected.name }}
                                                        />
                                                    </div>
                                                </div>
                                                {/*Status*/}
                                                <div className="uk-width-1-2 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Status</label>
                                                    <div className="uk-margin" style={style.inputStyle}>
                                                        <label className="">{this.state.receivingStatus}</label>
                                                    </div>
                                                </div>
                                                {/*Balance*/}
                                                <div className="uk-width-1-3 uk-form-stacked">
                                                    <label className="uk-form-label" style={style.boldLabel}>Balance</label>
                                                    <div className="uk-margin" style={{ paddingBottom: 10, paddingTop: 2 }}>
                                                        <label className="">{formatToCurrencyWithScale(this.state.receivingBalance, this.state.currencySelected.value) + ' ' + this.state.currencySelected.value}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-width-1-1">
                        {/*Extra Information*/}
                        <ul id="hf-accordion-extrainformation" uk-accordion="multiple: true">
                            <li className="uk-open">
                                <h5 className="uk-accordion-title accordion-new-style green-background">Extra Information</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id="extra-information">
                                        <label className="uk-form-label" style={style.boldLabel}>Comment (*)</label>
                                        <div className="uk-flex" style={{ paddingBottom: 15 }}>
                                            <div className="uk-width-1-1">
                                                <textarea
                                                    className={formatValidInput(this.state.validations.comment)}
                                                    style={{ fontSize: 16, height: 70 }}
                                                    value={this.state.commentTextArea}
                                                    onChange={this.updateCommentText}
                                                >{this.state.commentTextArea}</textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button
                            className="uk-button uk-button-green uk-margin-right"
                            onClick={this.onClearFields}
                        >New Transfer</button>
                        <button
                            className="uk-button uk-button-green uk-margin-right"
                            onClick={this.onClearFields}
                        >Clear Fields</button>
                        <button
                            className="uk-button uk-button-green uk-margin-right"
                            onClick={this.onTransfer}
                        >Transfer</button>
                    </div>
                </div>
                <AlertBox
                    open    = {this.state.showAlert} 
                    onClose = {()=>{
                        this.setState({
                            showAlert    : false,
                            alertTitle   : '',
                            alertMessage : '',
                            messageList  : []
                        })
                    }}
                    type    = "Ok" 
                    title   = {this.state.alertTitle} 
                    message = {this.state.alertMessage} 
                    okClick = {()=>{
                        this.setState({
                            showAlert    : false,
                            alertTitle   : '',
                            alertMessage : '',
                            messageList  : []
                        })
                    }}
                    messageBoldList = {this.state.messageList}
                />
            </div>
        );
    }
}

import React from 'react';

export default class CustomersSearchForm extends React.Component {
    state = {
        customerName: '',
        hasComplianceAccess: JSON.parse(localStorage.getItem('HasComplianceAccess'))
    };

    componentDidMount() {
        var name = this.props.searchedString;
        this.setState({ customerName: name });
    }

    handleClearClick = () => {
        this.setState({ customerName: '' });
        this.props.clearData();
    };

    handleSearchClick = () => {
        this.props.updateTable();
    };

    handleAddCustomerClick = () => {
        this.props.addCustomer();
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSearchClick();
        }
    };

    handleUpdateSearchString = (event) => {
        this.setState({ customerName: event.target.value });
        this.props.updateSearchedCustomerName(event.target.value);
    };

    render() {
        return (
            <div className="uk-grid uk-margin">
                <div className="uk-width-1-2">
                    <div className="uk-margin">
                        <input
                            className="uk-input"
                            type="text"
                            placeholder="Customer Name"
                            onChange={this.handleUpdateSearchString}
                            value={this.state.customerName}
                            onKeyPress={this.handleKeyPress}
                            autoFocus={true}
                        />
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ marginTop: '-5px' }}>
                    <button className="uk-button uk-button-green" onClick={this.handleSearchClick}>Search</button>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick}>Clear Fields</button>
                    <button className="uk-button uk-button-green" disabled={this.state.hasComplianceAccess} onClick={this.handleAddCustomerClick}>Add</button>
                </div>
            </div>
        );
    }
}

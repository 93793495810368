import { Breadcrumb } from 'antd';
import React from 'react';
import { getStyle } from '../../../styles/styles';
import NewBankingStandardScreen from './countryAddtionalSettings/NewBankingStandardScreen';
import BusinessHoursScreen from './countryAddtionalSettings/BusinessHoursScreen';
import CountryPropertiesForm from './countryAddtionalSettings/CountryPropertiesForm';
import CurrenciesScrenn from './countryAddtionalSettings/CurrenciesScreen';
import InstrumentsScreen from './countryAddtionalSettings/InstrumentsScreen';
import ProductsScreen from './countryAddtionalSettings/ProductsScreen';
const style = getStyle();

const CountryAdditionalSettingsScreen = (props) => {
    const handleBackLink = () => {
        props.backLink();
    };

    return (
        <div>
            {props.data.showBreadcrumb ? (
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={handleBackLink}>
                            Countries
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">{`Settings for ${props.data.Country}-${props.data['Country Name']}`}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            ) : (
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={handleBackLink}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    {`${props.data.Country}-${props.data['Country Name']}`}
                </h3>
            )}
            <ul uk-tab="">
                <li><a href="#">Instruments</a></li>
                <li><a href="#">Business Hours</a></li>
                <li><a href="#">Currencies</a></li>
                <li><a href="/#">Additional Properties</a></li>
                <li><a href="/#">Products</a></li>
                <li><a href="/#">Banking Standards</a></li>
            </ul>
            <ul className="uk-switcher uk-margin">
                <li><InstrumentsScreen countryID={props.data.Country} /></li>
                <li><BusinessHoursScreen countryID={props.data.Country} /></li>
                <li><CurrenciesScrenn countryID={props.data.Country} /></li>
                <li><CountryPropertiesForm countryID={props.data['Num ID']} /></li>
                <li><ProductsScreen countryID={props.data.Country} /></li>
                <li><NewBankingStandardScreen selectedCountry={props.data.Country} /></li>
            </ul>
        </div>
    );
};

export default CountryAdditionalSettingsScreen;

import React, { useState } from 'react';
import { BoardName } from '../../../constants/BoardNames';
import { getStyle } from '../../../styles/styles';
import GenericBoard from '../../shared/GenericBoard';

const style = getStyle();

function ComplaintBoardScreen() {
    const [showTitle, setShowTitle] = useState(true);

    const isShowTitle = (value) => {
        setShowTitle(value);
    };

    return (
        <div>
            {showTitle && (
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    Complaint Boards
                </h3>
            )}
            <GenericBoard board={BoardName.ComplaintBoard} mainTitle={'Complaint Boards'} isShowTitle={isShowTitle} selectSection />
        </div>
    );
}

export default ComplaintBoardScreen;

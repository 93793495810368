import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle } from '../../../styles/styles';
import { BoardName } from '../../../constants/BoardNames';
import AlertBox from '../../shared/AlertBox';

import { swiftCreateCustomerCreditTransferBatch } from '../../../networking/NetworkingFunds';

var style = getStyle();
export default class SwiftBoardScreen extends React.Component {

    state = {
        boardName: '',
        ids: [],
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        selectedRows: [],
        showTitle: true,
        loading: false
    }
    
    componentDidMount() {
    }

    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }

    handleCheckboxClick = (obj, checked, all, listSelects) => {

        let actualBoardName = this.state.boardName;
        var listOfIds = this.state.ids;
        var key = obj['Key'];
        var outgoingID = key.length > 5 ? key.substr(0, 5) : key;
        var selected = listSelects;

        if (actualBoardName !== "") {
            if (obj['Action'] !== actualBoardName) {
                selected = [];
                listOfIds = [];
            }
        }

        if (all === true) {
            selected = [];
            listOfIds = [];
        } else {
            if (checked) {
                selected.push(obj);
                listOfIds.push(parseInt(outgoingID));
            } else {
                selected = selected.filter(item => item["Key"] !== obj["Key"]);
                listOfIds = listOfIds.filter(item => item !== parseInt(outgoingID));
            }
        }

        this.setState({
            selectedRows: selected,
            boardName: obj['Action'],
            ids: listOfIds
        }, () => {
            //console.log("rows: " + JSON.stringify(this.state.selectedRows));
        });
    }

    handleSendPayments = () => {
        let idsFinales = this.state.ids;
        console.log(idsFinales)
        //There are no records to update.
        if (idsFinales.length == 0) {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'Please select some outgoings first' })
        }
        else {
            this.handleSwiftSendPayment()
        }

    }

    handleSwiftSendPayment = async () => {
        let idsFinales = this.state.ids;
        let userId = localStorage.getItem('UserID');
        this.setState({ loading: true }, () => {
            swiftCreateCustomerCreditTransferBatch(0, userId, idsFinales).then((response) => { 
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        if (response.interfaceProviderMessage != undefined && response.interfaceProviderMessage != null && response.interfaceProviderMessage != '') {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Warning',
                                alertMessage: response.interfaceProviderMessage,
                                loading: false,
                            })
                        } else {
                            let message = response.result.split("\n").map(function (item, idx) {
                                return (
                                    <span key={idx}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            })
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: message,
                                loading: false,
                            })
                        }
                        break
                    default:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: response.Message || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                            loading: false
                        })
                        break
                }
            }).finally(() => { this.setState({ loading: false }) ;})
        })
    }

    handleOnRefresh = () => {
        this.setState({ selectedRows: [], ids: [] });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    render() {
        return (
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Swift Payments Board</h3>}
                <GenericBoard
                    board={BoardName.SwiftPaymentsBoard}
                    mainTitle={"Swift Payments Board"}
                    activeSelectAll={true}
                    isShowTitle={this.isShowTitle}
                    multiselect={true}
                    handleCheckboxClick={this.handleCheckboxClick}
                    selectedRows={this.state.selectedRows}
                    sendSwiftPayments={this.handleSendPayments}
                    onRefresh={this.handleOnRefresh}
                />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
            </div>
        );
    }
}
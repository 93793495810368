import React from 'react';

import {formatToCurrency} from '../../../../../helpers/FormatHelper';

import './CardBoard.scss';


export default class Card extends React.Component {
    
    componentDidMount = () => {
    }

    onCardClick = () => {
        this.props.onClickElement(this.props.element);
    }

    /*
    handleCustomActions = (e, action, id, screen, tab, element) => {
        e.stopPropagation()
        this.props.customActions(action, id, screen, tab, element)
    }*/

    render = () => {

        /*let customActions = []
        if (["Unpaid", "Partially Paid"].includes(this.props.dealStatus)) {
            customActions.push(<button key={this.props.element['Deal No']} className="card-button uk-button-green" type="button" 
                onClick={(e) => this.handleCustomActions(e, 'confirmSettlements', this.props.element['Deal No'], this.props.element['Action'], 'incomings', this.props.element)}>
                    Confirm Settlements</button>)
        }
        if (["Not Disbursed", "Partially Disbursed"].includes(this.props.dealStatus)) {
            customActions.push(<button key={this.props.element['Deal No']} className="card-button uk-button-green" type="button"
                onClick={(e) => this.handleCustomActions(e, 'confirmDisbursements', this.props.element['Deal No'], this.props.element['Action'], 'outgoings', this.props.element)}>
                Confirm Disbursements</button>)
        }*/
        let headers = Object.keys(this.props.element);
        let cardData = [];

        for(var i=0; i<headers.length; i++){
            if (i===0) {
                if(!headers[i].includes('{No label}')){
                    cardData.push(<p className="card-title">{headers[i]}: {this.props.element[headers[i]]}</p>);
                } else {
                    cardData.push(<p className="card-title">{this.props.element[headers[i]]}</p>);
                }
            } else {
                if(!headers[i].includes('{No line}') && headers[i] !== 'BackgroundColour'){
                    if(!headers[i].includes('{No label}')){
                        cardData.push(<p className="card-content">{headers[i]}: {this.props.element[headers[i]]}</p>);
                    } else {
                        cardData.push(<p className="card-content">{this.props.element[headers[i]]}</p>);
                    }
                }
            }
        }
        return(
            <div>
                <div className="main-card" onClick={this.onCardClick} style={{backgroundColor: `#${this.props.element['BackgroundColour']}`}}>
                    {cardData}
                </div>
            </div>
        );
    }
}
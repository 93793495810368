import React from 'react';
import OutgoingFundsForm from '../../components/outgoingFunds/OutgoingFundsForm';
import { formatToCurrency, formatToCurrencyWithScale } from '../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper';
import { getDealHeaderInfo } from '../../networking/NetworkingDeals';
import { getOutgoings, getAmountInBaseCurrency } from '../../networking/NetworkingFunds';
import { getStyle } from '../../styles/styles';
import DisbursementPropertiesDialog from '../shared/Dialogs/DisbursmentPropertiesDialog';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';
import Paginator from '../shared/Paginator';
import {Breadcrumb} from 'antd';



var style = getStyle();

export default class OutgoingFundsScreen extends React.Component {
    state = {
        viewAuditOutGoingFundScreen: false,
        defaultOutgoingNostroBankID: 0,
        outgoings: [],
        originalOutgoings: [],
        outgoingFundsID: 0,
        dealHeaderID:0,
        customerID: 0,
        sellCcy: '',
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        selectedOutgoing: {},
        loading: false,
        //Read Only
        readOnly: false,

        //Refunds        
        fromRefunds: false,
        refundPersonID:'',
        refundBranchID: 0,

        //r2d2
        totalAmountOutgoings:0,
        // Outgoing Properties
        showDisbursementPropertiesDialog: false,
        disbursementDialogIndex: 0,
        selectedOutgoingFundsID: 0,
        forceLoad: false,
        selectedOutgoingFundsAmount: 0,
        outgoingStatus:''
    }
    
    handleBackLink() {
        this.props.backLink();
    }

    componentDidMount(){
        var outgoingID = 0;
        var dealID = 0;
        if(this.props.id != null && this.props.id > 0){
            outgoingID = this.props.id;
        }
        if(this.props.fromRefunds !== undefined)
        {
            this.setState({fromRefunds: this.props.fromRefunds, 
                refundPersonID: this.props.refundPersonID,
                refundBranchID: this.props.refundBranchID});            
        }
        if(this.props.dealID != null){
            dealID = this.props.dealID;
            getDealHeaderInfo(dealID).then(
                (value) => {
                    if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                        var dealHeaderInfo = value.dealHeaderInfo[0]
                        this.setState({customerID: dealHeaderInfo["Customer ID"], 
                            defaultOutgoingNostroBankID: dealHeaderInfo.DefaultOutgoingNostroBankID,
                            sellCcy: dealHeaderInfo.SCur,
                        });
                    }
                }
            );
        }
        this.setState({outgoingFundsID: outgoingID, dealHeaderID: dealID});
        if(!this.state.fromRefunds)
        {
        getOutgoings(dealID,1,getDefaultPagingNumberOfItems()).then(
            (value) => {
                if (value != null) {
                    console.table(value.outgoings);
                    var outgoings = value.outgoings != null ? value.outgoings : [];
                    let totalAux = 0;
                    totalAux = this.getTotalAmountOutgoings(outgoings);
                    this.setState({
                        outgoings: outgoings,
                        totalAmountOutgoings: totalAux,
                        originalOutgoings: value.outgoings
                    });
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({ totalPages: pages, newSearch: true });
                    if (totalCount > 0) {
                        //(this.props.approvalBoard != null && outgoings.length > 0) {
                        let outgoingAux = null;
                        outgoings.every(function (outgoing, index) {
                            if (outgoing.Status !== 'Void') {
                                outgoingAux = outgoing;
                                return false
                            }
                            else {
                                return true
                            }
                        });

                        if (outgoingAux == null) {
                            outgoingAux = outgoings[0];
                        }

                        this.handleRowClick(outgoingAux);
                    }
                }
            }
        );
        }
        if (this.props.readOnly !== undefined) {
            this.setState({ readOnly: this.props.readOnly })
        }
    }

    getTotalAmountOutgoings(listOutg)
    {
        let total= 0;
        if(listOutg!== undefined && listOutg.length > 0)
        {
            listOutg.forEach(outgoing => {
                if(outgoing.Status !== 'Void' && outgoing.Status !== 'Rejected')
                {
                    total = total + outgoing.Amount;
                }
            });
        }
        return total;
    }
    
    componentDidUpdate(prevProps){             
        if(prevProps.id !== this.props.id && this.props.id > 0){
            var outgoingID = this.props.id;
            this.setState({outgoingFundsID: outgoingID});            
        }  
        if(prevProps.fromRefunds !== this.props.fromRefunds){
            this.setState({fromRefunds: this.props.fromRefunds, 
                refundPersonID: this.props.refundPersonID,
                refundBranchID: this.props.refundBranchID});  
        }
        if(prevProps.dealID !== this.props.dealID){   
            if(this.props.dealID != null){
                var dealID = this.props.dealID;
                getDealHeaderInfo(dealID).then(
                    (value) => {
                        if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                            var dealHeaderInfo = value.dealHeaderInfo[0]
                            this.setState({customerID: dealHeaderInfo["Customer ID"], sellCcy: dealHeaderInfo.SCur});
                        }
                    }
                );
            }
            this.setState({outgoingFundsID: outgoingID, dealHeaderID: dealID, loading: true});
            if(!this.state.fromRefunds)
            {
            getOutgoings(dealID,1,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    console.table(value.outgoings);
                    var outgoings = value.outgoings;
                    let totalAux = 0;
                    totalAux = this.getTotalAmountOutgoings(outgoings);
                    this.setState({
                        outgoings           : outgoings, 
                        totalAmountOutgoings: totalAux,
                        originalOutgoings   : value.outgoings
                    });
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:true, loading:false});
                    if (totalCount > 0) {//(this.props.approvalBoard != null && outgoings.length > 0) {
                        this.handleRowClick(outgoings[0]);
                    }
                }
            );
            }
        }
    }

    changePage(value){
        this.setState({currentPage: value});
        getOutgoings(this.state.dealHeaderID, value, getDefaultPagingNumberOfItems()).then(
            (json) => {
                var outgoings = json.outgoings;
                let totalAux = 0;
                totalAux = this.getTotalAmountOutgoings(outgoings);
                this.setState({
                    outgoings           : outgoings, 
                    totalAmountOutgoings: totalAux,
                    originalOutgoings   : json.outgoings
                });
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:false});
            }
        );
    }
    
    handleRowClick(obj){
        console.group("Outgoing handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({outgoingFundsID: obj['OutgoingFundsID'], outgoingStatus: obj['Status']});
    }
    
    handleViewAuditOutGoingFundScreen() {
        this.setState({ viewAuditOutGoingFundScreen: true});
    }

    handleCloseViewAuditOutGoingFundScreen() {
        this.setState({ viewAuditOutGoingFundScreen: false});
    }

    handleShowPropertiesClick = (obj) => {
        const index = this.state.outgoings.findIndex(o => o.OutgoingFundsID === obj.OutgoingFundsID);
        if (index !== -1) {
            const entityProperties = this.state.outgoings[index].EntityProperties;
            const selectedDisbursementEntityProperties = entityProperties != null ? entityProperties : null;
            this.setState({
                showDisbursementPropertiesDialog: true,
                disbursementDialogIndex: index,
                selectedDisbursementEntityProperties,
                selectedOutgoingFundsID: obj.OutgoingFundsID
            },
            () =>
            getAmountInBaseCurrency(this.state.dealHeaderID, this.state.outgoings[index].PaymentAmount).then(
                (getamountInBaseCcy) => 
                {
                    this.setState({selectedOutgoingFundsAmount: getamountInBaseCcy.response});
                }
            ));
        }
    };

    closeDisbursementPropertiesDialog = (updateDisbursements = false, entityProperties, indexToUpdate) => {
        if (updateDisbursements) {
            let outgoings = [...this.state.outgoings];
            outgoings[indexToUpdate].EntityProperties = entityProperties;
            outgoings[indexToUpdate].EntityPropertiesJson = JSON.stringify(entityProperties);
            outgoings[indexToUpdate].UpdatedBy = localStorage.getItem('UserID');
            this.setState({
                outgoings: outgoings,
                showDisbursementPropertiesDialog: false,
                // Refresh EntityProperties
                // This variable is sent to OutgoingFundsForm and then to EntityProperties
                forceLoad: !this.state.forceLoad });
        } else {
            this.setState({ showDisbursementPropertiesDialog: false });
        }
    };
    
    onSaveSuccess = () => {
        //Update outgoing list
        getOutgoings(this.state.dealHeaderID, this.state.currentPage, getDefaultPagingNumberOfItems()).then(
            (json) => {
                var outgoings = json.outgoings;
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                let totalAux = 0;
                totalAux = this.getTotalAmountOutgoings(outgoings);
                this.setState({newSearch:false,
                    originalOutgoings   : json.outgoings,
                    outgoings           : outgoings,
                    totalAmountOutgoings: totalAux,
                    totalPages          : pages,
                });
            }
        );
        //Refresh deal header info
        if (this.props.reloadDealHeaderInfo !== undefined) {
            this.props.reloadDealHeaderInfo();
        }
    }
    onUpdateID = (id)=>{
        this.setState({outgoingFundsID:id});
    }

    onUpdateStatus = (status)=>{
        this.setState({outgoingStatus:status});
    }

    render(){
        var msg = '';
        var className1 = '';
        if(this.state.viewAuditOutGoingFundScreen){
            className1 = 'breadcrumb-item';
        }else{
            className1 = 'breadcrumb-item-bold';
        }
        if(this.state.outgoingFundsID > 0){
            msg = 'Create / Update Outgoing Fund -  ID - ' + this.state.outgoingFundsID + ' - '+ this.state.outgoingStatus;
        } else {
            msg = 'Create / Update Outgoing Fund';
        }

        var outgoingFundsForm;
        if(this.state.sellCcy !== ''){
            outgoingFundsForm = (
                <OutgoingFundsForm 
                    id                   = {this.state.outgoingFundsID}
                    onSaveSuccess        = {this.onSaveSuccess}
                    customerID           = {this.state.customerID}
                    defaultOutgoingNostroBankID = {this.state.defaultOutgoingNostroBankID}
                    dealId               = {this.state.dealHeaderID}
                    sellCcy              = {this.state.sellCcy}
                    readOnly             = {this.state.readOnly}
                    fromRefunds          = {this.state.fromRefunds}
                    refundPersonID       = {this.state.refundPersonID}
                    refundBranchID       = {this.state.refundBranchID} 
                    totalAmountOutgoings = {this.state.totalAmountOutgoings}
                    sellAmount           = {this.props.sellAmount}
                    forceLoad            = {this.state.forceLoad}
                    onUpdateID           = {this.onUpdateID}
                    onUpdateStatus           = {this.onUpdateStatus}
                    CloseAuditOutGoingFundScreen = {this.state.viewAuditOutGoingFundScreen}
                    ViewAuditOutGoingFundScreen = {this.handleViewAuditOutGoingFundScreen.bind(this)}
                />
            );
        }
        var outgoingTitle = "";
        if (this.props.mainTitle != null) {
            outgoingTitle = ""
        } else if (this.props.backLink != null && this.props.approvalBoard != null) {
            outgoingTitle = (
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left back-arrow-white"></i>
                    </button>
                    {msg}
                </h3>
            )
        }
        var outgoingScreen = (
            <div>
                {outgoingTitle}
                {outgoingFundsForm}
                {!this.state.fromRefunds && <ul id="accordion-previous-outgoing-funds" uk-accordion="multiple: true" style={{marginTop: "0px"}}>
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Outgoing Funds</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="previous-outgoing-funds">
                                <NewDynamicTable 
                                    formats         = {[{header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency'}]} 
                                    hiddenHeaders   = {['OutgoingFundsID', 'DealHeaderID', 'EntityProperties', 'EntityPropertiesJson', 'UpdatedBy']}
                                    data            = {this.state.outgoings} 
                                    enableClick     = {true} 
                                    clickFunction   = {this.handleRowClick.bind(this)} 
                                    newSearch       = {this.state.newSearch} 
                                    dynamicActions={[{name: 'Show Properties', function: this.handleShowPropertiesClick, isIcon: true, icon:'fa fa-search'}]}
                                />
                            </div>
                        </div>
                    </li>
                </ul>}
                {!this.state.fromRefunds && <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>
                }
                <LoadingBox loading={this.state.loading}/>
                <DisbursementPropertiesDialog
                    open={this.state.showDisbursementPropertiesDialog}
                    onClose={this.closeDisbursementPropertiesDialog}
                    index={this.state.disbursementDialogIndex}
                    entityProperties={this.state.selectedDisbursementEntityProperties}
                    disabled={false}
                    saveMode={true}
                    outgoingFundsID={this.state.selectedOutgoingFundsID}
                    amount= {this.state.selectedOutgoingFundsAmount}
                />
            </div>
       )
        return( 
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className={className1}  onClick={this.handleCloseViewAuditOutGoingFundScreen.bind(this)}>{msg}</Breadcrumb.Item>
                        {this.state.viewAuditOutGoingFundScreen && <Breadcrumb.Item className="breadcrumb-item-bold">Audit Outgoing Funds</Breadcrumb.Item>}
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                {outgoingScreen}

            </div>);
    }
}
import {getListOfUserActions} from '../networking/NetworkingUsers';

/**
 * 
 * @param {[string]} arrayOfActions  String Array of Actions. 
 * 
 * @return {Promise<[{action: string, userHasAction: boolean}]>} Array of actions and if the user has it. 
 */

export const userHasActions = async (arrayOfActions) => {
    let finalResponse = {};
    let response = await getListOfUserActions();
    if(response != undefined)
    {
    let userActions = response.userActions;
    
    

    if(userActions !== undefined)
    {
        arrayOfActions.forEach(actionSearched => {
            let find = false;
            for(let i = 0; i<userActions.length; i++){
                if(userActions[i].Right === actionSearched){
                    find = true;
                    break;
                }
            }
            finalResponse[actionSearched] = find;
            /*finalResponse.push({
                action: actionSearched,
                userHasAction: find,
            });*/
        });
    }
}
    return finalResponse;
}


import {genericGetWithParameters} from './Networking';

export async function getFXPositionByBlottersID(ids){
    try {
        let params = {
            'blottersID': ids,
        }
        let response = await genericGetWithParameters('Position/GetFXPosition',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPLByRevalType(revalType){
    try {
        let params = {
            'revalType': revalType,
        }
        let response = await genericGetWithParameters('Position/GetPLTable',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListofDeals(currencyID, dateFrom, dateTo) {
    try {
        let params = {
            'currencyID': currencyID,
            'valueDateFrom': dateFrom,
            'valueDateTo': dateTo,
        }

        let response = await genericGetWithParameters('Position/GetListOfDeals', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//export async function exportPosition(branchID, blotterID, targetDate) {
export async function exportPosition(branchID, blotterID, targetDate) {
    try {
        let params = {
            'branchID': branchID,
            'blotterID': blotterID,
            'targetDate': targetDate,
        };
        let response = await genericGetWithParameters('Position/ExportPosition', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function exportPAndL(revaluationType, targetDate) {
    try {
        let params = {
            'revaluationType': revaluationType,
            'targetDate': targetDate,
        };
        let response = await genericGetWithParameters('Position/ExportPAndL', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import {genericGetWithParameters, genericCallWithBody} from './Networking';

export async function getInstrumentsByCustomer(customerID){
    try {
        let params = {customerID: customerID};
        let response = await genericGetWithParameters('Instrument/GetInstrumentsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getInstrumentsByCountry(countryID){
    try {
        let params = {countryID: countryID};
        let response = await genericGetWithParameters('Instrument/GetInstrumentsByCountry',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveInstrumentsByCountry(model){
    let response = await genericCallWithBody('POST','Instrument/SaveInstrumentsByCountry',model);
    return response;
}



import { TrendingUpRounded } from '@material-ui/icons';
import React from 'react';
import { formatDate, formatValidSelect } from '../../../helpers/FormatHelper';
import { newSearchCustomers } from '../../../networking/Networking';
import { getAllBranches } from '../../../networking/NetworkingBankBranches';
import { getListOfBanks } from '../../../networking/NetworkingBanks';
import { getCountries } from '../../../networking/NetworkingCountries';
import { searchCustomerByIdNameMin } from '../../../networking/NetworkingCustomers';
import { getParametersByName } from '../../../networking/NetworkingGlobalSystemParameters';
import { getAllUsers } from '../../../networking/NetworkingUsers';
import { getStyle, notFilledInputClass, regularInputClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';


var style = getStyle();

export default class ReportForm extends React.Component {

    state = {
        customerList: [],
        countryList     : [],
        bankList        : [],
        banksFiltered   : [],
        disableBank     : true,
        branchList      : [],
        branchFiltered  : [],
        disableBranch   : true,
        fields: {
            Email: '',
            "From Trade Date": null,
            "To Trade Date": null,
            "From Value Date": null,
            "To Value Date": null,
            "Value Date": null,
            "From Risk Score": 0,
            "To Risk Score": 0,
            "From Date": null,
            "To Date": null,
            "Process Date": null,
            Customer: -1,
            "Exclude Forwards": false,
            selection: "Banks",
            Separator: ";",
            Country: 0,
            Bank: 0,
            Branch: 0,
            "Hide Voided Transactions": true,
            "Show Comments": false,
            Format: "EXCEL",
            UserId: localStorage.getItem('UserID')
        },
        showFields: {
            Email: false,
            "From Trade Date": false,
            "To Trade Date": false,
            "From Value Date": false,
            "To Value Date": false,
            "From Risk Score": false,
            "To Risk Score": false,
            "From Date": false,
            "To Date": false,
            "Process Date": false,
            "Value Date": false,
            Separator: false,
            Customer: false,
            "Hide Voided Transactions": false,
            "Branch Id": false,
            "Show Comments": false,
            Format: false,
        },
        validations: {
            email: true,
            fromTradeDate: true,
            toTradeDate: true,
            fromValueDate: true,
            toValueDate: true,
            fromRiskScore: true,
            toRiskScore: true,
            fromDate: true,
            toDate: true,
            processDate: true,
            valueDate: true,
            separator: true,
            customerSelected: true,
            countrySelected  : true,
            bankSelected     : true,
            branchSelected   : true,
            errorMessage: '',
        },
        //Alert Box
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
        userEmail: '',
        systemDate: null
    }

    componentDidMount() {
        let userName = localStorage.getItem('UserName');
        getAllUsers('ForeignExchange').then(
            (json) => {
                let helper = json.filter(item => item.UserName === userName);
                this.setState({ userEmail: helper[0] != null ? helper[0].Email : '' })
            }
        );
        getParametersByName('SystemDate').then(
            (json) => {
                this.setState({ systemDate: json.parameters[0].Value });
            }
        );
    }

    componentDidUpdate(prevProps) 
    {
        if (!this.compare(prevProps.fields, this.props.fields)) 
        {
            var showFields = this.state.showFields;
            for (var key in showFields) 
            {
                showFields[key] = false;
            }
            for(var i = 0; i < this.props.fields.length; i++) 
            {
                let formattedString = this.props.fields[i];
                showFields[formattedString] = true;

                if (formattedString === 'Customer') {
                    newSearchCustomers('', 0, 0).then(
                        (jsonResponse) => {
                            console.log("newSearchCustomers: " + JSON.stringify(jsonResponse))
                            if (jsonResponse.httpStatusCode === 200) {
                                if (jsonResponse.customers !== undefined) {
                                    var tempCustomers = jsonResponse.customers
                                    var finalListOfCustomers = []
                                    tempCustomers.forEach(element => {
                                        var el = element
                                        el.value = element['Customer ID']
                                        el.name = element['Customer Name']

                                        finalListOfCustomers.push(el)
                                    });

                                    finalListOfCustomers.sort(this.dynamicSort("name"));
                                    finalListOfCustomers.splice(0,0,{value: 0, name:"All Customer Excluding Banks"});
                                    finalListOfCustomers.splice(0,0,{value: -2, name:"All Customer"});

                                    this.setState({
                                        customerList: finalListOfCustomers
                                    });
                                }
                            }
                        }
                    );
                }

                if(formattedString === 'Branch Id')
                {
                    getCountries(0,0).then(
                        (json) =>{
                            if (json != null && json.countries != null)
                            {
                                const countries = json.countries.map(c => (
                                    { ...c, name: c["Country Name"], value: c.Country }));

                                this.setState({ 
                                    countryList: countries,
                                    banksFiltered: countries
                                }, () => {
                                        getListOfBanks(true).then( // SWIFT
                                            (json2) =>{
                                                console.log("getListOfBanks: " + json2)
                                                if(json2 !== undefined)
                                                {
                                                    ////debugger
                                                    for (var i = 0; i < json2.banks.length; i++)
                                                    {
                                                        json2.banks[i].value = json2.banks[i].BankID;
                                                        json2.banks[i].name  = json2.banks[i].Name;
                                                    }

                                                    this.setState({ 
                                                        bankList: json2.banks 
                                                    }, () => {
                                                        getAllBranches().then(
                                                            (json) =>{
                                                                if (json !== undefined) 
                                                                {
                                                                    if (json.branches !== undefined) 
                                                                    {
                                                                        for (var i = 0; i < json.branches.length; i++) 
                                                                        {
                                                                            json.branches[i].value = json.branches[i]["Branch ID"];
                                                                            json.branches[i].name = json.branches[i].Branch;
                                                                        }

                                                                        this.setState({ 
                                                                            branchList: json.branches,
                                                                            branchFiltered: json.branches
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                        );
                                                    });
                                                }
                                            }
                                        );
                                    }
                                );
                            }
                        }
                    );
                }
            }
            this.resetFields()
            this.setState({ showFields }, () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            });
        }
        if (prevProps.validated !== this.props.validated && !this.props.validated ) {
            this.validateRequiredFields();
        }
        if (prevProps.forceReload !== this.props.forceReload) {
            this.resetFields();
        }
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) 
        {
            /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
            */
            //////debugger
            var x = a[property] == null ? "":a[property];
            var y = b[property] == null ? "":b[property];

            if(typeof(x) == 'string' && typeof(y) == 'string')
            {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            var result = (x < y) ? -1 : (x > y) ? 1 : 0;
            return result * sortOrder;
        }
    }

    compare(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }
        array1.sort();
        array2.sort();
        for (var i = 0; i< array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    }

    resetFields() {
        const fields = {
            Email: this.state.userEmail,
            "From Trade Date": '',
            "To Trade Date": '',
            "From Value Date": '',
            "To Value Date": '',
            "From Risk Score": 0,
            "To Risk Score": 0,
            "From Date": this.state.systemDate,
            "To Date": this.state.systemDate,
            "Process Date": this.state.systemDate,
            "Value Date": this.state.systemDate,
            Customer: -1,
            "Exclude Forwards": false,
            selection: "Banks",
            Separator: ";",
            "Hide Voided Transactions": true,
            "Show Comments": false,
            Format: "EXCEL",
            UserId: localStorage.getItem('UserID')
        }
        
        this.props.getFields(fields);
        this.setState({ fields: fields });
    }

    validateRequiredFields() {
        var email = true, 
            fromTradeDate       = true, 
            toTradeDate         = true, 
            fromValueDate       = true, 
            toValueDate         = true, 
            fromRiskScore       = true, 
            toRiskScore         = true, 
            fromDate            = true, 
            toDate              = true, 
            valueDate           = true, 
            separator           = true, 
            customerSelected    = true,
            countrySelected     = true,
            bankSelected        = true,
            branchSelected      = true,
            processDate         = true;

        var errorMessage = "";
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(this.state.showFields.Email)
        {
            if (!this.state.fields.Email.match(regex)) {
                email = false;
            }
        }

        if(this.state.showFields.Customer)
        {
            if(this.state.fields.Customer === -1)
            {
                customerSelected = false;
            }
        }

        if(this.state.showFields["From Risk Score"])
        {
            if(this.state.fields["From Risk Score"] === 0)
            {
                fromRiskScore = false;
            }
        }
        if(this.state.showFields["To Risk Score"])
        {
            if(this.state.fields["To Risk Score"] === 0)
            {
                toRiskScore = false;
            }
        }
        if(this.state.showFields.Separator)
        {
            if(this.state.fields.Separator === "" || this.state.fields.Separator.length !== 1)
            {
                separator = false;
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Country === 0 || this.state.fields.Country === null)
            {
                countrySelected = false;
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Bank === 0 || this.state.fields.Bank === null)
            {
                bankSelected = false;
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Branch === 0 || this.state.fields.Branch === null)
            {
                branchSelected = false;
            }
        }
        if (this.state.fields["Process Date"] === null) {
            processDate = false;
        }
        if (this.state.fields["From Trade Date"] !== null || this.state.fields["To Trade Date"] !== null) {
            if (this.state.fields["From Trade Date"] === null) {
                fromTradeDate = false;
            }
            if (this.state.fields["To Trade Date"] === null) {
                toTradeDate = false;
            }
            if (this.state.fields["From Trade Date"] !== null && this.state.fields["To Trade Date"] !== null) {
                let fromArray = this.state.fields["From Trade Date"].split("/");
                let toArray = this.state.fields["To Trade Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    fromTradeDate = false;
                    errorMessage = "From Trade Date cannot be greater than To Value Date"
                }
            }
        }
        if (this.state.fields["From Value Date"] !== null || this.state.fields["To Value Date"] !== null) {
            if (this.state.fields["From Value Date"] === null) {
                fromValueDate = false;
            }
            if (this.state.fields["To Value Date"] === null) {
                toValueDate = false;
            }
            if (this.state.fields["From Value Date"] !== null && this.state.fields["To Value Date"] !== null) {
                let fromArray = this.state.fields["From Value Date"].split("/");
                let toArray = this.state.fields["To Value Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    fromValueDate = false;
                    errorMessage = "From Value Date cannot be greater than To Value Date"
                }
            }
        }
        if (this.state.fields["From Date"] !== null || this.state.fields["To Date"] !== null) {
            if (this.state.fields["From Date"] === null) {
                fromDate = false;
            }
            if (this.state.fields["To Date"] === null) {
                toDate = false;
            }
            if (this.state.fields["From Date"] !== null && this.state.fields["To Date"] !== null) {
                let fromArray = this.state.fields["From Date"].split("/");
                let toArray = this.state.fields["To Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    fromDate = false;
                    errorMessage = "From Date cannot be greater than To Value Date"
                }
            }
        }

        this.setState({
            validations: {
                ...this.state.validations,
                email,
                fromTradeDate,
                toTradeDate,
                fromValueDate,
                toValueDate,
                fromDate,
                toDate,
                valueDate,
                separator,
                customerSelected,
                countrySelected,
                bankSelected,
                branchSelected,
                fromRiskScore,
                toRiskScore,
                processDate
            }
        });
    }

    validateRequiredInputs() {
        var errors = [];
        var valid = true;
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(this.state.showFields.Email)
        {
            if (!this.state.fields.Email.match(regex)) {
                valid = false;
                errors.push("Type a valid email.");
            }
        }

        if(this.state.showFields.Customer)
        {
            if(this.state.fields.Customer === undefined || this.state.fields.Customer ===-1)
            {
                valid = false;
                errors.push("Select a Customer.");
            }
        }
/*
        if(this.state.showFields["From Risk Score"])
        {
            if(this.state.fields["From Risk Score"] === undefined || this.state.fields["From Risk Score"] === 0)
            {
                valid = false;
                errors.push("From Risk Score cannot be empty.");
            } else if (this.state.fields["From Risk Score"] === undefined || this.state.fields["From Risk Score"] > 100) 
            {
                valid = false;
                errors.push("From Risk Score value must be less than or equal to 100.");
            }
        }
        if(this.state.showFields["To Risk Score"])
        {
            if(this.state.fields["To Risk Score"] === undefined || this.state.fields["To Risk Score"] === 0)
            {
                valid = false;
                errors.push("To Risk Score cannot be empty.");
            } else if (this.state.fields["To Risk Score"] === undefined || this.state.fields["To Risk Score"] > 100) 
            {
                valid = false;
                errors.push("To Risk Score value must be less than or equal to 100.");
            }
        }*/

        if(this.state.showFields.Separator)
        {
            if(this.state.fields.Separator === undefined || this.state.fields.Separator === "")
            {
                valid = false;
                errors.push("Type a Separator for CSV Report.");
            }else
            {
                if(this.state.fields.Separator.length !== 1)
                {
                    valid = false;
                    errors.push("Separator can only be one character.");
                }
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Country === undefined || this.state.fields.Country === 0)
            {
                valid = false;
                errors.push("Select a Country.");
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Bank === undefined || this.state.fields.Bank === 0)
            {
                valid = false;
                errors.push("Select a Bank.");
            }
        }

        if(this.state.showFields['Branch Id'])
        {
            if(this.state.fields.Branch === undefined || this.state.fields.Branch === 0)
            {
                valid = false;
                errors.push("Select a Branch.");
            }
        }

        if (this.state.fields["From Trade Date"] !== null || this.state.fields["To Trade Date"] !== null) {
            if (this.state.fields["From Trade Date"] === null && this.state.fields["To Trade Date"] !== null) {
                valid = false;
                errors.push("To Trade Date cannot be empty.");
            }
            else if (this.state.fields["To Trade Date"] === null && this.state.fields["From Trade Date"] !== null) {
                valid = false;
                errors.push("From Trade Date cannot be empty.");
            }
            if (this.state.fields["From Trade Date"] !== null && this.state.fields["To Trade Date"] !== null) {
                let fromArray = this.state.fields["From Trade Date"].split("/");
                let toArray = this.state.fields["To Trade Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    valid = false;
                    errors.push("From Trade Date cannot be greater than To Trade Date.");
                }
            }
        }
        if (this.state.fields["From Value Date"] !== null || this.state.fields["To Value Date"] !== null) {
            if (this.state.fields["From Value Date"] === null && this.state.fields["To Value Date"] !== null) {
                valid = false;
                errors.push("To Value Date cannot be empty.");
            }
            else if (this.state.fields["To Value Date"] === null && this.state.fields["From Value Date"] !== null) {
                valid = false;
                errors.push("From Value Date cannot be empty.");
            }
            if (this.state.fields["From Value Date"] !== null && this.state.fields["To Value Date"] !== null) {
                let fromArray = this.state.fields["From Value Date"].split("/");
                let toArray = this.state.fields["To Value Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    valid = false;
                    errors.push("From Value Date cannot be greater than To Value Date.");
                }
            }
        }
        if (this.state.fields["From Date"] !== null || this.state.fields["To Date"] !== null) 
        {
            if (this.state.fields["From Date"] === null && this.state.fields["To Value Date"] !== null) {
                valid = false;
                errors.push("To Date cannot be empty.");
            }
            else if (this.state.fields["To Date"] === null && this.state.fields["From Date"] !== null) {
                valid = false;
                errors.push("From Date cannot be empty.");
            }
            if (this.state.fields["From Date"] !== null && this.state.fields["To Date"] !== null) {
                let fromArray = this.state.fields["From Date"].split("/");
                let toArray = this.state.fields["To Date"].split("/");
                //let from = new Date(fromArray[2], fromArray[1], fromArray[0]);
                //let to = new Date(toArray[2], toArray[1], toArray[0]);
                //if (from > to) {
                if (Date.parse(fromArray) > Date.parse(toArray)) {
                    valid = false;
                    errors.push("From Date cannot be greater than To Date.");
                }
            }
        }
        if (this.state.fields["Process Date"] === null) {
            valid = false;
            errors.push("Process Date cannot be empty.");
        }
        return { valid, errors };
    }

    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.fields.Email.match(regex)) {
            return false;
        }
        return true;
    }
    
    formatVisibility(field) {
        return field ? "inline" : "none";
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    searchCustomer = async (query) => {
        if (query === '') {
            const fields = {...this.state.fields, Customer: 0 };
            this.setState({ customerList: [], fields: fields });
            this.props.getFields(fields);
        } else {
            const json = await searchCustomerByIdNameMin(query);
            if (json != null && json.customers != null) {
                const customers = json.customers.map((c) => ({ ...c, value: c.CustomerID, name: c.CustomerIDName }));
                this.setState({ customerList: customers });
            }
        }
    };

    handleUpdateEmail = (event) => {
        this.setState({
            fields: {...this.state.fields, Email: event.target.value},
            validations: { ...this.state.validations, email: true }
        },
            () => {
                if (this.validateEmail()) {
                    this.props.getFields(this.state.fields);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            }
        );
    }

    handleUpdateSeparator = (event) =>
    {
        this.setState({
            fields: {...this.state.fields, Separator: event.target.value},
            validations: {...this.state.validations, separator: true}
        },
        () => {
            this.props.isValid(this.validateRequiredInputs());
            this.props.getFields(this.state.fields);
        });
    }

    handleUpdateFromTradeDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "From Trade Date": value},
            validations: {...this.state.validations, fromTradeDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateToTradeDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "To Trade Date": value},
            validations: {...this.state.validations, toTradeDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateFromValueDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "From Value Date": value},
            validations: {...this.state.validations, fromValueDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateToValueDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "To Value Date": value},
            validations: {...this.state.validations, toValueDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateFromDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "From Date": value},
            validations: {...this.state.validations, fromDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }
    
    handleUpdateToDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "To Date": value},
            validations: {...this.state.validations, toDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateValueDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "Value Date": value},
            validations: {...this.state.validations, valueDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }

    handleUpdateProcessDate = (date) => {
        let value = date !== undefined ? formatDate(date) : '';
        this.setState({
            fields: {...this.state.fields, "Process Date": value},
            validations: {...this.state.validations, processDate: true}
        },
            () => {
                this.props.isValid(this.validateRequiredInputs());
                this.props.getFields(this.state.fields);
            }
        );
    }
    handleGetValueCustomer = (value) =>
    {
        if (value != null && value.value !== undefined) 
        {
            this.setState({
                fields: {...this.state.fields, Customer: value.value},
                validations: {...this.state.validations, customerSelected: true}
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }
    }

    handleGetValueFromRiskScore = (event) =>
    {
        if (event != null && event.target.value !== undefined) 
        {
            if (event.target.value <= 100) {
                this.setState({
                    fields: { ...this.state.fields, "From Risk Score": event.target.value },
                    validations: { ...this.state.validations, fromRiskScore: true }
                }, () => {
                    this.props.getFields(this.state.fields);
                    this.props.isValid(this.validateRequiredInputs());
                });
            } else {
                this.setState({
                    fields: { ...this.state.fields, "From Risk Score": event.target.value },
                    validations: { ...this.state.validations, fromRiskScore: TrendingUpRounded },

                    showAlertOK: true,
                    alertTitle: 'Error',
                    alertMessage: 'Value must be less than or equal to 100.'
                }, () => {
                    this.props.getFields(this.state.fields);
                    this.props.isValid(this.validateRequiredInputs());
                });
            }
        }
    }
    handleGetValueToRiskScore = (event) => {
        if (event != null && event.target.value !== undefined) {
            if (event.target.value <= 100) {
                this.setState({
                    fields: { ...this.state.fields, "To Risk Score": event.target.value },
                    validations: { ...this.state.validations, toRiskScore: true }
                }, () => {
                    this.props.getFields(this.state.fields);
                    this.props.isValid(this.validateRequiredInputs());
                });
            } else {
                this.setState({
                    fields: { ...this.state.fields, "To Risk Score": event.target.value },
                    validations: { ...this.state.validations, toRiskScore: TrendingUpRounded },

                    showAlertOK: true,
                    alertTitle: 'Error',
                    alertMessage: 'Value must be less than or equal to 100.'
                }, () => {
                    this.props.getFields(this.state.fields);
                    this.props.isValid(this.validateRequiredInputs());
                });
            }
        }
    }

    handleGetValueCountry = (value) =>
    {
        if (value != null && value.value !== undefined && value.value !== "") 
        {
            var allBanks = this.state.bankList;
            var banksToShow = [];
            
            allBanks.forEach(element => {
                if(element.CountryID === value.value)
                {
                    banksToShow.push(element)
                }
                /*
                    BankID: 1005167
                    CountryID: "PER"
                    Name: "00BANK OF PERU (Peru) SW123456BP"
                    SWIFT: "SW123456BP"
                */
            });

            this.setState({
                fields          : {...this.state.fields, Country: value.value},
                validations     : {...this.state.validations, countrySelected: true},
                disableBank     : false,
                banksFiltered   : banksToShow
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }else
        {
            this.setState({
                fields       : {...this.state.fields, Bank: 0},
                disableBank  : true,
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }
    }

    handleGetValueBank = (value) =>
    {
        if (value != null && value.value !== undefined && value.value !== "") 
        {
            var allBranches  = this.state.branchList;
            var branchToShow = [];
            var disable = true;

            allBranches.forEach(element => {
                if(parseInt(element['Bank ID']) === parseInt(value.value))
                {
                    branchToShow.push(element)
                }
            });
            
            if(branchToShow.length !== 0)
            {
                disable = false;
            }

            this.setState({
                fields          : {...this.state.fields, Bank: value.value},
                validations     : {...this.state.validations, bankSelected: true},
                branchFiltered  : branchToShow,
                disableBranch   : disable,
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }else
        {
            this.setState({
                fields          : {...this.state.fields, Branch: 0},
                disableBranch   : true,
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }
    }

    handleGetValueBranch = (value) =>
    {
        if (value != null && value.value !== undefined) 
        {
            this.setState({
                fields: {...this.state.fields, Branch: value.value},
                validations: {...this.state.validations, branchSelected: true}
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }
    }
    
    handleUpdateFormat(value) {
        if (value !== null && value.target.value !== '') {
            this.setState({
                fields: {...this.state.fields, Format: value.target.value}
            }, () => {
                this.props.getFields(this.state.fields);
                this.props.isValid(this.validateRequiredInputs());
            });
        }
    }
    
    handleOnCheckShowComments(event) {
        this.setState({
            contact: { ...this.state.fields, "Show Comments": event.target.checked }
        }, () => {
            this.props.getFields(this.state.fields);
            this.props.isValid(this.validateRequiredInputs());
        });
    }
    
    handleOnCheckHideVoidedTransaction(event) {
        this.setState({
            contact: { ...this.state.fields, "Hide Voided Transactions": event.target.checked }
        }, () => {
            this.props.getFields(this.state.fields);
            this.props.isValid(this.validateRequiredInputs());
        });
    }

    closeAlertOK = () => {
        this.setState({ showAlertOK: false, alertMessage: '', alertTitle: '' });
    }

    render() {
        return (
            <div className="uk-margin">
                <div className="uk-width-1-1" style={{ display: this.formatVisibility(this.state.showFields.Email) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Email</label>
                    <input className={this.formatValidInput(this.state.validations.email)} type="email" placeholder="" value={this.state.fields.Email} onChange={this.handleUpdateEmail} />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["Format"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Export Format</label>
                    <select className="uk-select" value={this.state.fields.Format} onChange={this.handleUpdateFormat.bind(this)}>
                        <option value="EXCEL">EXCEL</option>
                        <option value="PDF">PDF</option>
                    </select>
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["Show Comments"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Show Comments</label>
                    <input className="uk-checkbox" type="checkbox" checked={this.state.fields["Show Comments"]} value={this.state.fields["Show Comments"]} onChange={this.handleOnCheckShowComments.bind(this)} />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["Hide Voided Transactions"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Hide Voided Transactions</label>
                    <input className="uk-checkbox" type="checkbox" checked={this.state.fields["Hide Voided Transactions"]} value={this.state.fields["Hide Voided Transactions"]} onChange={this.handleOnCheckHideVoidedTransaction.bind(this)} />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["From Trade Date"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>From Trade Date</label>
                    <DatePicker
                        //className={this.formatValidInput(this.state.validations.fromTradeDate)}
                        className={this.formatValidInput(true)}
                        readOnly={true}
                        value={this.state.fields["From Trade Date"]}
                        onDayChange={this.handleUpdateFromTradeDate}
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["To Trade Date"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>To Trade Date</label>
                    <DatePicker
                        //className={this.formatValidInput(this.state.validations.toTradeDate)}
                        className={this.formatValidInput(true)}
                        readOnly={true}
                        value={this.state.fields["To Trade Date"]}
                        onDayChange={this.handleUpdateToTradeDate}
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["From Value Date"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>From Value Date</label>
                    <DatePicker
                        //className={this.formatValidInput(this.state.validations.fromValueDate)}
                        className={this.formatValidInput(true)}
                        readOnly={true}
                        value={this.state.fields["From Value Date"]}
                        onDayChange={this.handleUpdateFromValueDate}
                    />
                </div>
                <div
                    className="uk-width-1-1 uk-form-stacked"
                    style={{
                        display: this.formatVisibility(this.state.showFields["To Value Date"]),
                    }}
                >
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>To Value Date</label>
                    <DatePicker
                        //className={this.formatValidInput(this.state.validations.toValueDate)}
                        className={this.formatValidInput(true)}
                        readOnly={true}
                        value={this.state.fields["To Value Date"]}
                        onDayChange={this.handleUpdateToValueDate}
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["From Risk Score"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>From Risk Score</label>
                    <NumberInput
                        //className={this.formatValidInput(this.state.validations.fromRiskScore)}
                        className={this.formatValidInput(true)}
                        type="Integer"
                        readOnly={true}
                        value={this.state.fields["From Risk Score"]}
                        onChange={this.handleGetValueFromRiskScore}
                        placeholder="0.00"
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["To Risk Score"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>To Risk Score</label>
                    <NumberInput
                        //className={this.formatValidInput(this.state.validations.toRiskScore)}
                        className={this.formatValidInput(true)}
                        type="Integer"
                        readOnly={true}
                        value={this.state.fields["To Risk Score"]}
                        onChange={this.handleGetValueToRiskScore}
                        placeholder="0.00"
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["From Date"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>From Date</label>
                    <DatePicker
                        className={this.formatValidInput(this.state.validations.fromDate)}
                        readOnly={true}
                        value={this.state.fields["From Date"]}
                        onDayChange={this.handleUpdateFromDate}
                    />
                </div>
                <div className="uk-width-1-1 uk-form-stacked" style={{ display: this.formatVisibility(this.state.showFields["To Date"]) }}>
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>To Date</label>
                    <DatePicker
                        className={this.formatValidInput(this.state.validations.toDate)}
                        readOnly={true}
                        value={this.state.fields["To Date"]}
                        onDayChange={this.handleUpdateToDate}
                    />
                </div>
                <div
                    className="uk-width-1-1 uk-form-stacked"
                    style={{
                        display: this.formatVisibility(this.state.showFields["Value Date"]),
                        paddingBottom: 20,
                    }}
                >
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Value Date</label>
                    <DatePicker
                        className={this.formatValidInput(this.state.validations.valueDate)}
                        readOnly={true}
                        value={this.state.fields["Value Date"]}
                        onDayChange={this.handleUpdateValueDate}
                    />
                </div>
                <div
                    className="uk-width-1-1 uk-form-stacked"
                    style={{
                        display: this.formatVisibility(this.state.showFields["Process Date"]),
                        paddingBottom: 20,
                    }}
                >
                    <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Process Date</label>
                    <DatePicker
                        className={this.formatValidInput(this.state.validations.processDate)}
                        readOnly={true}
                        value={this.state.fields["Process Date"]}
                        onDayChange={this.handleUpdateProcessDate}
                    />
                </div>

                {this.state.showFields.Customer && <div className="uk-grid">
                    <div
                        className="uk-width-1-2 uk-form-stacked"
                        style={{ display: this.formatVisibility(this.state.showFields.Customer), }}
                    >
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }} >Customer</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSearchSelect
                                className={this.formatValidInput(this.state.validations.customerSelected)}
                                objValue={{ value: this.state.fields.Customer }}
                                getValue={this.handleGetValueCustomer}
                                searchFunction={this.searchCustomer}
                                listHasPlaceholder={false}
                                data={this.state.customerList}
                                id="select-sds-customer"
                                placeholder={"Select a Customer..."}
                            />
                        </div>
                    </div>

                    <div
                        className="uk-width-1-2 uk-form-stacked"
                        style={{
                            display: this.formatVisibility(this.state.showFields.Separator),
                            marginBottom: '0px'
                        }}
                    >
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Separator</label>
                        <input
                            className={this.formatValidInput(this.state.validations.separator)}
                            value={this.state.fields.Separator}
                            onChange={this.handleUpdateSeparator}
                            maxLength={1}
                        />
                    </div>
                </div>}

                {this.state.showFields['Branch Id'] && <div className="uk-grid">
                    <div
                        className="uk-width-1-3 uk-form-stacked"
                        style={{ display: this.formatVisibility(this.state.showFields['Branch Id']) }}
                    >
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }} >Country</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect
                                className={formatValidSelect(this.state.validations.countrySelected)}
                                objValue={{ value: this.state.fields.Country }}
                                getValue={this.handleGetValueCountry}
                                listHasPlaceholder={false}
                                data={this.state.countryList}
                                id="select-sds-country"
                                placeholder={"Select a Country..."}
                            />
                        </div>
                    </div>

                    <div
                        className="uk-width-1-3 uk-form-stacked"
                        style={{
                            display: this.formatVisibility(this.state.showFields['Branch Id']),
                            marginBottom: '0px'
                        }}
                    >
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Bank</label>
                        <DynamicSelect
                            className={formatValidSelect(this.state.validations.bankSelected)}
                            objValue={{ value: this.state.fields.Bank }}
                            getValue={this.handleGetValueBank}
                            listHasPlaceholder={false}
                            data={this.state.banksFiltered}
                            id="select-sds-bank"
                            placeholder={"Select a Bank..."}
                            disabled={this.state.disableBank}
                        />
                    </div>

                    <div
                        className="uk-width-1-3 uk-form-stacked"
                        style={{
                            display: this.formatVisibility(this.state.showFields['Branch Id']),
                            marginBottom: '0px'
                        }}
                    >
                        <label className="uk-form-label" style={[style.boldLabel], { marginTop: 18 }}>Branch</label>
                        <DynamicSelect
                            className={formatValidSelect(this.state.validations.branchSelected)}
                            objValue={{ value: this.state.fields.Branch }}
                            getValue={this.handleGetValueBranch}
                            listHasPlaceholder={false}
                            data={this.state.branchFiltered}
                            id="select-sds-branch"
                            placeholder={"Select a Branch..."}
                            disabled={this.state.disableBranch}
                        />
                    </div>
                </div>}
                
                <AlertBox
                    open={this.state.showAlertOK}
                    onClose={this.closeAlertOK}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeAlertOK}
                />
            </div>
        );
    }
}
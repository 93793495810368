import React from 'react';
import { formatDate, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getDealsInquiries } from '../../../networking/NetworkingCustomerLimitProfile';
import { getStyle } from '../../../styles/styles';
import DatePicker from '../../shared/DatePicker';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NewEditableGrid from '../../shared/NewEditableGrid';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';

var style = getStyle();

export default class DealsInquiriesForm extends React.Component {
    state = {
        dealInquiries: [],
        columnDetail: {},
        referredDealId: 0,
        referredCustomer: '',
        referredIssueDate: '',
        referredValueDate: '',
        referredDealType: '',
        referredBuyAmount: 0,
        referredBuyCurrency: 0,
        referredSellAmount: 0,
        referredSellCurrency: 0,
        referredTraderName: '',
        referredReason: '',
        referredReviewedBy: '',
        dealComment: '',
        fromDate: '',
        toDate: '',
        showModal: false,
        dealInquiriesDetail: {}
    }

    /*
    newHandleTableData(){
        newSearchCustomers(this.state.searchedCustomerName,1,getDefaultPagingNumberOfItems()).then(
            (value) => {
                console.log(value);
                this.setState({customers: value.customers});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                console.log(pages);
                this.setState({totalPages: pages, newSearch: true});
            }
        );
    }*/

    handleRowClick(obj){        
        this.setState({
            showModal: true,
            dealInquiriesDetail: obj
        });
        /*this.setState({
            referredDealId: obj["DealHeaderID"],
            referredCustomer: obj["CustomerName"],
            referredIssueDate: obj["IssueDate"],
            referredValueDate: obj["ValueDate"],
            referredDealType: obj["Description"],
            referredBuyAmount: obj["BuyAmount"],
            referredBuyCurrency: obj["BuyCurrency"],
            referredSellAmount: obj["SellAmount"],
            referredSellCurrency: obj["SellCurrency"],
            referredTraderName: obj["TraderName"],
            referredReason: obj["Reasons"],            
            referredReviewedBy: obj["Name"],
            dealComment: obj["Content"],
            showModal: true,
            dealInquiriesDetail: obj
        });*/
    }

    changePage(value){
        getDealsInquiries(value,getDefaultPagingNumberOfItems(),this.state.fromDate,this.state.toDate).then(
            (value) => {
                this.setState({dealInquiries: value.result, columnDetail:JSON.parse(value.columnDetail)});
                /*var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({totalPages: pages, newSearch: false});*/
            }
        );
    }

    handleUpdateFromDate(date){
        this.setState({fromDate: formatDate(date)});
    }

    handleUpdateToDate(date){
        this.setState({toDate: formatDate(date)});
    }

    handleSearchClick(){
        if(this.state.fromDate !== '' && this.state.fromDate !== undefined && this.state.fromDate !== null && this.state.toDate !== '' && this.state.toDate !== undefined && this.state.toDate !== null){
            getDealsInquiries(1,getDefaultPagingNumberOfItems(),this.state.fromDate,this.state.toDate).then(
                (value) => {
                    if (value != null && value.result != null) {
                        this.setState({dealInquiries: value.result, columnDetail:JSON.parse(value.columnDetail) });
                        /*this.setState({ dealInquiries: value.referreadDeals });
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        // console.log(pages);
                        this.setState({ totalPages: pages, newSearch: false });*/
                    }
                }
            );
        }        
    }

    handleUpdateReferredDealId(value){
        this.setState({referredDealId: value.target.value});
    }

    handleUpdateReferredCustomer(value){
        this.setState({referredCustomer: value.target.value});
    }

    handleUpdateReferredIssueDate(value){
        this.setState({referredIssueDate: value.target.value});
    }

    handleUpdateReferredValueDate(value){
        this.setState({referredValueDate: value.target.value});
    }

    handleUpdateReferredDealType(value){
        this.setState({referredDealType: value.target.value});
    }

    handleUpdateReferredBuyAmount(value){
        this.setState({referredBuyAmount: value.target.value});
    }

    handleUpdateReferredBuyCurrency(value){
        this.setState({referredBuyCurrency: value.target.value});
    }

    handleUpdateReferredSellAmount(value){
        this.setState({referredSellAmount: value.target.value});
    }

    handleUpdateReferredSellCurrency(value){
        this.setState({referredSellCurrency: value.target.value});
    }

    handleUpdateReferredTraderName(value){
        this.setState({referredTraderName: value.target.value});
    }

    handleUpdateReferredReason(value){
        this.setState({referredReason: value.target.value});
    }

    handleUpdateDealComment(value){
        this.setState({dealComment: value.target.value});
    }

    handleUpdateReferredReviewedBy(value){
        this.setState({referredReviewedBy: value.target.value});
    }

    onCloseDetail(value){
        this.setState({showModal: false});
    }

    render(){

        return(
            <div>
                <div className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1 uk-margin">                      
                            <div className="uk-heading-divider" style={style.titleStyle}>
                                <h4>Search Criteria</h4>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >From (Value) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" value={this.state.fromDate} onDayChange={this.handleUpdateFromDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >To (Value) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" value={this.state.toDate} onDayChange={this.handleUpdateToDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" > </label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSearchClick.bind(this)} type="button">Search</button>
                            </div>
                        </div>
                    </div>

                    <NewEditableGrid
                        data={this.state.dealInquiries}
                        columnDetails={this.state.columnDetail}
                        isEditable={false}
                        enableClick={true} 
                        clickFunction={this.handleRowClick.bind(this)}
                        useExportButton={true}
                        tableName="DealsInquiries"
                        useIcons={true} />

                </div>

                <ShowDynamicInfo open={this.state.showModal} jsonDetail={this.state.dealInquiriesDetail} columnDetails={this.state.columnDetail} title='Deal Inquiries Information' onClose={this.onCloseDetail.bind(this)}/>
                
                {/*
                <form className="uk-form-horizontal uk-margin-large">                    
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Selected Deal Information</h3>
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Deal ID :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredDealId" type="text" placeholder="" value={this.state.referredDealId} onChange={this.handleUpdateReferredDealId.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Customer Name :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.referredCustomer} onChange={this.handleUpdateReferredCustomer.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Issue Date :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredIssueDate" type="text" placeholder="" value={this.state.referredIssueDate} onChange={this.handleUpdateReferredIssueDate.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Value Date :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredValueDate" type="text" placeholder="" value={this.state.referredValueDate} onChange={this.handleUpdateReferredValueDate.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Deal Type :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredDealType" type="text" placeholder="" value={this.state.referredDealType} onChange={this.handleUpdateReferredDealType.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Buy Amount :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredBuyAmount" type="text" placeholder="" value={this.state.referredBuyAmount} onChange={this.handleUpdateReferredBuyAmount.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Buy Currency :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredBuyCurrency" type="text" placeholder="" value={this.state.referredBuyCurrency} onChange={this.handleUpdateReferredBuyCurrency.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Sell Amount :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredSellAmount" type="text" placeholder="" value={this.state.referredSellAmount} onChange={this.handleUpdateReferredSellAmount.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Sell Currency :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredSellCurrency" type="text" placeholder="" value={this.state.referredSellCurrency} onChange={this.handleUpdateReferredSellCurrency.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Trader Name :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredTraderName" type="text" placeholder="" value={this.state.referredTraderName} onChange={this.handleUpdateReferredTraderName.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Referral Reason :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.referredReason} onChange={this.handleUpdateReferredReason.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Comment :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="dealComment" type="text" placeholder="" value={this.state.dealComment} onChange={this.handleUpdateDealComment.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Reviewed By :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredReviewedBy" type="text" placeholder="" value={this.state.referredReviewedBy} onChange={this.handleUpdateReferredReviewedBy.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        
                    </div>
                </form>
                */}               

                {/*<NewDynamicTable formats={[{header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency'}]} 
                hiddenHeaders={['Deal ID','Issue Date','Deal Type','Trader Name']} 
                data={this.state.dealInquiries} enableClick={true} clickFunction={this.handleRowClick.bind(this)} 
        newSearch={this.state.newSearch} useDeleteButton={false}/>*/}
            </div>
        );
    }
}
import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import {getStyle} from '../../../../styles/styles'
import AlertBox from '../../../shared/AlertBox';
var style = getStyle();

export default class BankAccountPropertiesForm extends React.Component {
    state = {
        bankAccountID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        
    }

    onFinishEntityPropertiesSaveProcess(status, message, pendingMandatoryList){
        if (status) {

        } else {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: message, errors: pendingMandatoryList });
        }
        this.props.getSaveProperties(false);
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    render(){
        var section = (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <EntityProperties fullScreen={true} entityType={this.props.subEntityType} entityID={this.props.bankAccountID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                </div>
            </form>
        )
        if(this.props.bankAccountID === 0){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create bank account first.</h5>
                </div>
            )
        }
        return(
            <div>
                {section}
                <AlertBox
                    open={this.state.showAlert}
                    onClose={this.closeAlert.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    messageBoldList={this.state.errors}
                    okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}
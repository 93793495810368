import {genericCallWithBody} from './Networking';

export async function runEODProcess(objParameters){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'EOD/Run';
    let response = await genericCallWithBody(method, apiName, objParameters);
    return response;
}

export async function ValidateEOD(objParameters){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'EOD/ValidateEOD';
    let response = await genericCallWithBody(method, apiName, objParameters);
    return response;
}
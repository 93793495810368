import React from 'react';
import { Colors } from '../../constants/Colors';

export default class DynamicList extends React.Component {

    state = {
        value: '',
        data: [],
        selectFirst : false
    }

    componentDidMount(){
        var propData = this.props.data;
        this.setState({data: propData});
        var propValue = '';
        if(this.props.objValue !== undefined && this.props.objValue.value !== ''){
            propValue = this.props.objValue.value;
            this.setState({value: propValue}, ()=>{
                this.props.getValue(this.state.value);
            });
        }
        else
        {
            if(this.props.selectFirst)
            {
                if(this.props.data.length > 0)
                {        
                   this.change(this.props.data[0]);                     
                }
            }
        }

    }

    componentDidUpdate(prevProps) {
        if(prevProps.objValue !== undefined && this.props.objValue !== undefined){
            if (prevProps.objValue.value !== this.props.objValue.value){
                this.setState({value: this.props.objValue.value});
            }
        }
    }

    change(obj) {
        var helper = {};        
        helper = obj;

        helper.controlID = this.props.id;
        helper.parent = this.props.parent;

        this.setState({value: obj.value});
        //helper.value = event.target.value;
        console.log(this.state.value);
        this.props.getValue(helper);
        
    }
    
    render() {
        var className = "uk-list";
        if(this.props.className !== undefined){
            className = this.props.className;
        }

        var list = [];
        var initCount = 0;

        for (var i=initCount; i<this.props.data.length; i++) {
            
            list.push(<li style={
                
                this.state.value === this.props.data[i].value ? 
                {backgroundColor: Colors.primaryColor, cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'} 
            }
             key={this.props.data[i].value} onClick ={this.change.bind(this, this.props.data[i])}  value={this.props.data[i].value} >
                {this.props.data[i].name}
            </li>);
        }
        
        return(
            
                <lu className={className} >
                     {list}
                </lu>
        );
    }
}
import React from 'react';
import NumberInput from './NumberInput';

export default class Paginator extends React.Component {

    constructor(props) {
        super(props);
        
        var current = 1
        if(this.props.pageNumber !== undefined)
        {
            current = this.props.pageNumber
        }

        this.state = { 
            selectedPage: current,
        };
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.pageNumber !== prevProps.pageNumber)
        {
            this.setState({
                selectedPage: this.props.pageNumber
            })
        }
    }

    /*change = (event) => {
        console.log('CLICKED!');
        console.log(event.target.id);
        var newPage = event.target.id;
        this.setState({selectedPage: newPage}, () => {
            this.props.changePage(newPage);
        });
    }*/

    change = (event) => {
        console.log('Change To!');
        console.log(event.target.value);
        var newPage = parseInt(event.target.value);
        this.setState({selectedPage: newPage}, () => {
            this.props.changePage(newPage);
        });
    }

    changePrevious = () => {
        if (this.state.selectedPage !== 1){
            var newValue = this.state.selectedPage - 1;
            this.setState({ selectedPage: newValue }, ()=>{
                this.props.changePage(newValue);
            });
        }
    }

    changeNext = () => {
        if (this.state.selectedPage !== this.props.numberOfPages){
            var newValue = parseInt(this.state.selectedPage) + 1;
            console.log(newValue);
            this.setState({ selectedPage: newValue }, ()=>{
                this.props.changePage(newValue);
            });
        }
    }

    changeToFirst = () => {
        this.setState({selectedPage: 1}, () => {
            this.props.changePage(1);
        })
    }

    changeToLast = () => {
        this.setState({selectedPage: this.props.numberOfPages}, () => {
            this.props.changePage(this.props.numberOfPages);
        })
    }
    
    render() {
        
        /*var pagesButtons = [];
        for(var i = 1; i<=this.props.numberOfPages; i++){
            if (this.props.newSearch && i === 1){
                pagesButtons.push(<li className="uk-active" key={i}><a>{i}</a></li>);
                
            } else {
                if (i == this.state.selectedPage) {
                    console.log("IT ENTER");
                    pagesButtons.push(<li className="uk-active" key={i}><a>{i}</a></li>);
                } else {
                    pagesButtons.push(<li key={i}><a onClick={this.change} id={i}>{i}</a></li>);
                }
            }
        }*/

        if (this.props.numberOfPages > 0){
            return(
                <div>
                    <ul className="uk-pagination uk-flex-center" uk-margin="true" style={{margin: 0}}>
                        <li><a href onClick={this.changeToFirst}><p><span uk-pagination-previous="true"/><span uk-pagination-previous="true"/></p></a></li>
                        <li><a href onClick={this.changePrevious}><span uk-pagination-previous="true"></span></a></li>
                        <li><NumberInput className="uk-paginator-input" id="paginator-page" type="Integer" value={this.state.selectedPage} onChange={this.change}/></li>
                        <li><p href className="uk-pagintation-number">/</p></li>
                        <li><p href className="uk-pagintation-number">{this.props.numberOfPages}</p></li>
                        <li><a href onClick={this.changeNext}><span uk-pagination-next="true"></span></a></li>
                        <li><a href onClick={this.changeToLast}><p><span uk-pagination-next="true"/><span uk-pagination-next="true"/></p></a></li>
                    </ul>
                </div>
            );
        } else {
            return(<div></div>);
        }
    }
}
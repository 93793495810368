import React from 'react';
import WizardSearchCustomerForm from '../../trade/wizard/WizardSearchCustomerForm';
import WizardSelectContactForm from '../../trade/wizard/WizardSelectContactForm';
import SettlementsListScreen from './SettlementsListScreen'
import RefundsListScreen from './RefundsListScreen'
import {getEODParameters} from '../../../helpers/PackageJsonHelper';
import {getAdditionalInfoByUserID} from '../../../networking/NetworkingUsers'

import LoadingBox from '../../shared/LoadingBox'
import AlertBox from '../../shared/AlertBox'
import {getStyle} from '../../../styles/styles';

import {Breadcrumb} from 'antd';

export default class SettlementsScreen extends React.Component {

    state = {
        alertMessage: '',
        alertTitle: '',
        loading: false,
        objCustomer: {},
        objContact: {},
        showAlert: false,
        step: 1,    
        branchID: 0
    }

    componentDidMount()
    {
        getAdditionalInfoByUserID(getEODParameters().ApplicationName, localStorage.getItem('UserID')).then(
            (json)=>{
                console.log(json);
                if(json != undefined)
                {
                    this.setState({branchID: json.TellerBranchID})
                }
                else
                {
                    this.setState({branchID: 0})
                }
            }
        );
    }

    onClickStepTab = (value) => {
        if(this.state.step === 1 && value === 2){
            //Nothing at all
        }
        else{
            this.setState({step: value});
        }
    }

    ///Customer screen
    handleCustomerSelection = async (obj, nextStep) => {
        //alert(JSON.stringify(obj));
        //Show status warning
        if(obj.Status !== 'Authorized to deal'){
            this.setState({
                alertMessage: 'Customer is in ' + obj.Status + ' status. Deals cannot be created or edited under this customer.',
                alertTitle: 'Warning',
                showAlert: true,
            });
        }        

        if(nextStep === 2){
            this.setState({step: nextStep, 
                objCustomer: obj
            });
        }        
    }

        //Contacts screen
    handleContactSelection = (obj) =>{
        this.setState({step: 3, 
            objContact: obj
        });
    }

    render(){
        var stepScreen;
        let breadcrumb = [];

        var classNameStep1 = '', classNameStep2 = '', classNameStep3 = 'disabled'
        , classNameStep4 = 'disabled', hidden = {display:''};
    
        switch(this.state.step){
            case 1:
            default:
                classNameStep1 = 'uk-active';
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item">Settlements</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Select Customer</Breadcrumb.Item>
                );
                hidden = {display:'none'}
                stepScreen = (<WizardSearchCustomerForm id="comp_customer_form"
                    onCustomerSelected={this.handleCustomerSelection}/>);
                break;
            case 2:
                classNameStep2 = 'uk-active';
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Settlements</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Select Customer</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">{this.state.objCustomer['Customer Name']} - Select Contact</Breadcrumb.Item>
                );
                hidden = {display:'none'}
                stepScreen = (<WizardSelectContactForm id="comp_contact_form"
                    onContactSelected={this.handleContactSelection}
                    objCustomer={this.state.objCustomer}/>);
                break;
            case 3:
                classNameStep3 = 'uk-active';
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Settlements</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Select Customer</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(2)}}>{this.state.objCustomer['Customer Name']} - Select Contact</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Contact - {this.state.objContact['Name']} - {this.state.objContact['Contact ID']}</Breadcrumb.Item>
                );
                stepScreen = (<SettlementsListScreen customerID={this.state.objCustomer["Customer ID"]} 
                            settlementPersonID={this.state.objContact["PersonID$"]}
                            settlementBranchID={this.state.branchID} />);
                break;
            case 4:
                classNameStep4 = 'uk-active';
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Settlements</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(1)}}>Select Customer</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.onClickStepTab(2)}}>{this.state.objCustomer['Customer Name']} - Select Contact</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Contact - {this.state.objContact['Name']} - {this.state.objContact['Contact ID']} </Breadcrumb.Item>
                );
                stepScreen = (<RefundsListScreen customerID={this.state.objCustomer["Customer ID"]} 
                            refundPersonID={this.state.objContact["PersonID$"]}
                            refundBranchID={this.state.branchID} />);
                break;
        }

        return(
            <div>
                {/*<div uk-grid="true">
                        <div className="uk-width-1-2">
                            <h3 className="uk-heading-divider" style={getStyle().titleStyle}>Settlements</h3>
                        </div>
                </div>*/}
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>

                {(this.state.step === 3 || this.state.step === 4) && <ul className="uk-tab">
                    {/*<li key="step-1" className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>
                    <li key="step-2" className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Select Contact</a></li>*/}
                    <li key="step-3" className={classNameStep3} style={hidden}><a onClick={() => this.onClickStepTab(3)}>Settlements</a></li>
                    <li key="step-4" className={classNameStep4} style={hidden} ><a onClick={() => this.onClickStepTab(4)}>Refunds</a></li>
                </ul>}
                {stepScreen}

                <LoadingBox loading={this.state.loading}/>

                <AlertBox id="validation-alert" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okValidationAlert}/>
            </div>
        );
    }
}
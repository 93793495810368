import React from 'react';

import { getStyle } from '../../../styles/styles';

var style = getStyle();

export default class VersionInformationScreen extends React.Component {
    state = {
        objVersion:{},
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        if(this.props.objVersion != undefined)
        {
            this.setState({objVersion:this.props.objVersion});
            console.log(this.props.objVersion.array);
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.objVersion != this.props.objVersion && this.props.objVersion != undefined )
        {
            this.setState({objVersion:this.props.objVersion});
        }
    }


    render(){
        let objVersion = this.state.objVersion;
        let information = [];
        if(objVersion)
        {
        let data = Object.keys(objVersion).map(function (key) {
            return [key, objVersion[key]];
        });

        if(data && data.length > 0)
        {
        data.map(i => 
            information.push(
                <div className="uk-width-1-2 uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" htmlFor="form-bank-status">{i[0]}:</label>
                        <div className="uk-margin-right">
                            <input className="uk-input" disabled={true} id="dealVersionTrader" type="text" placeholder="" value={i[1]} />
                        </div>
                    </div>
                </div> )
        )
        }
    }

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{this.props.EntityType + " - Version " + this.state.objVersion.ID}</h3>
                <div className="uk-grid">
                    {information}
                </div>
            </div>
        );
    }
}
import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import SelectDealForm from './SelectDealForm';

import {getBanks} from '../../../networking/NetworkingCustomers';

export default class SelectBankForm extends React.Component {
    state = {
        banks: [],
        selectedBank: 0,
    }

    componentDidMount = () => {
        getBanks().then((response) => {
            if(response.customers){
                console.log(response.customers);
                let banks = response.customers;
                let helper = [];
                banks.forEach((element) => {
                    let newElement = element;
                    newElement.value = element.CustomerId;
                    newElement.name = element.CustomerIDName;
                    helper.push(newElement);
                });
                this.setState({banks: helper});
            }
        });
    }

    getBankSelect = (value) => {
        this.setState({selectedBank: value.value});
    }

    nextClick = () => {
        if(this.state.selectedBank !== '' && this.state.selectedBank !== 'select' && this.state.selectedBank){
            this.props.finishStep(parseInt(this.state.selectedBank));
        }
    }

    render = () => {
        return(
            <div>
                <form className="uk-form-horizontal uk-grid">
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-select">Select a Bank</label>
                            <div className="uk-form-controls">
                            <DynamicSelect className="uk-select" getValue={this.getBankSelect} listHasPlaceholder={false} placeholder="Select a Bank" data={this.state.banks} id="form-bank-select" objValue={{value: this.state.selectedBank, LookUpTableDetail: this.state.selectedBank}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2"></div>
                    <SelectDealForm customerID={parseInt(this.state.selectedBank)} finishStep={this.props.finishStep2}/>
                    {/*<div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.nextClick}>Next</button>
                    </div>*/}
                </form>
            </div>
        );
    }
}
import {genericCallWithBody, genericGetWithParameters} from './Networking';

export async function saveRateWatch(objRateWatch){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'RateWatch/Post';
    let response = await genericCallWithBody(method, apiName, objRateWatch);
    return response;
}

export async function getCurrencyPairForTabs(status, traderID){
    try {
        let params = {
            'status': status,
            'traderID': traderID,
        };
        let response = await genericGetWithParameters('RateWatch/GetCurrencyPairForTabs', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllRateWatches(customerID, traderID, rateWatchStatus, applicationDate, ccy1, ccy2){
    try {
        let params = {
            'customerID': customerID,
            'traderID': traderID,
            'rateWatchStatus': rateWatchStatus,
            'applicationDate': applicationDate,
            'ccy1': ccy1,
            'ccy2': ccy2,
        }
        let response = await genericGetWithParameters('RateWatch/GetRateWatches',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRateWatchByID(rateWatchID){
    try {
        let params = {
            'RateWatchID': rateWatchID
        }
        let response = await genericGetWithParameters('RateWatch/GetRateWatchByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateRateWatchStatus(objRateWatch){
    try {
        let method = 'POST';
        let apiName = 'RateWatch/UpdateRateWatchStatus';
        let response = await genericCallWithBody(method, apiName, objRateWatch);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import LoadingBox from '../../shared/LoadingBox';
import {getStyle} from '../../../styles/styles';
import {getDealHeaderInfo, getFullDealHeader} from '../../../networking/NetworkingDeals';

import SelectBankForm from './SelectBankForm';
import SelectDealForm from './SelectDealForm';
import CoverDealForm from './CoverDealForm';

export default class CoverDealApplicationScreen extends React.Component {
    state = {
        step: 1,
        loading: false,
        selectedBankID: 0,
        selectedDealID: 0,
    }

    resetScreen = () => {
        this.setState({
            step: 1,
            selectedBankID: 0,
        });
    }

    finishStep1 = (customerID) => {
        this.setState({step: 2, selectedBankID: customerID});
    }

    finishStep2 = (customerID, dealID) => {
        getDealHeaderInfo(dealID).then(response=>{
            console.log(response);
            this.setState({step: 2, selectedBankID: customerID, selectedDealID: dealID, selectedDeal: response.dealHeaderInfo});
        })
        
        //this.setState({step: 3, selectedBankID: customerID, selectedDealID: dealID});
    }

    render = () => {
        var stepScreen = [];

        var classNameStep1 = '', classNameStep2 = '', classNameStep3 = '';

        switch(this.state.step){
            case 1:
            default:
                classNameStep1 = 'uk-active';
                stepScreen.push(<SelectBankForm finishStep={this.finishStep1} finishStep2={this.finishStep2}/>);
            break;
            /*case 2:
                classNameStep2 = 'uk-active';
                stepScreen.push(<SelectDealForm customerID={this.state.selectedBankID} finishStep={this.finishStep2}/>);
            break;*/
            case 2:
                classNameStep2 = 'uk-active';
                stepScreen.push(<CoverDealForm deal={this.state.selectedDeal}/>);
            break;
        }

        return (
            <div>
                <h3 className="uk-heading-divider" style={getStyle().titleStyle}>Cover Deal Application</h3>
                <ul className="uk-tab">
                    <li key="step-1" className={classNameStep1}><a onClick={this.resetScreen}>Select Bank</a></li>
                    {/*<li key="step-2" className={classNameStep2}><a>Select Deal</a></li>*/}
                    <li key="step-2" className={classNameStep2}><a>Cover Deal Application</a></li>
                </ul>
                {stepScreen}
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
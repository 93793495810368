import React from 'react';

import DynamicSelect from '../../shared/DynamicSelect';
import { regularSelectClass, regularInputClass, notFilledInputClass, notFilledSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import NumberInput from '../../shared/NumberInput';

import {getStyle} from '../../../styles/styles'
import NewDynamicTable from '../../shared/NewDynamicTable';
var style = getStyle();
export default class LookUpDeliveryMethod extends React.Component {
    
    state = {
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        isDisable: true,
        readyToDeleteLookUpDetail:{},
        isBack: false,
        //---
        name:'',
        nameDetails:'',
        dataTemporal:[],
        methods:[
            {name:'ACH',value:'ACH'},
            {name:'BMO Settlements',value:'BMO Settlements'},
            {name:'Customer to Drop-off',value:'Customer to Drop-off'},
            {name:'EFT',value:'EFT'},
            {name:'Mail',value:'Mail'},
            {name:'Telpay',value:'Telpay'},
            {name:'We Pick-up',value:'We Pick-up'},
            {name:'Wire Transfer',value:'Wire Transfer'},
            {name:'At the branch', value:'At the branch'}
        ],
        dataTable:[],
        lookUpTableId:'',
        updateToken:0,
    }
    componentDidUpdate(prevProps){
        if(prevProps.field4 !== this.props.field4){
            this.setState({dataTemporal:this.transformField4(this.props.field4)})
            var table = this.transformField4(this.props.field4)
            this.setState({dataTable: table, newSearch: false,isDisable:this.props.isDisable},()=>this.props.objMethod(table))
        }
        if(this.props.name!==prevProps.name&&this.props.name.Description!==undefined){
            this.setState({nameDetails: this.props.name.Description, newSearch: true})           
        }
    }

    componentDidMount = () => {
        this.setState({dataTemporal:this.transformField4(this.props.field4)});
        var table = this.transformField4(this.props.field4);
        this.setState({dataTable: table, newSearch: false,isDisable:this.props.isDisable},()=>this.props.objMethod(table));
        if(this.props.name.Description!==undefined){
            this.setState({nameDetails: this.props.name.Description, newSearch: true})           
        }
    }

    handleAddItemClick(event){
        event.preventDefault();//§
        var newData = this.state.dataTable
        if(this.state.name!==undefined){
            if(!this.state.name.includes('select')){
                newData.push({name: this.state.name})
                this.setState({dataTable: newData, newSearch: false},
                    ()=>{
                            this.props.objMethod(newData)
                            this.props.isBack(false)
                        }
                    );
            }
        }
    }
    handleSaveAllClick = (event) =>{
        event.preventDefault();
        this.props.save();
    }

    handleBackLink = (event) =>{
        event.preventDefault();
        this.props.isBack(true)
    }
    transformField4 = textField =>{
        var helper = []
        if(textField!==undefined&&textField!==''){
            var srcText = textField.split('§')
            for(var i=0; i<srcText.length;i++){
                helper.push({name : srcText[i]})
            }
            return helper
        }else{
            return helper
        }
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }
    handleRowClick = obj =>{
        console.group("Look Up Delivery Method handleClick")
        console.log(obj);
        console.groupEnd();
    }
    handleDeleteClick = obj =>{
        console.group("Look Up Table Details handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        var table = this.state.dataTable
        var ddwn = this.state.methods
        table.forEach((tabList, index)=>{
            if(obj.name === tabList.name){
                table.splice(index,1)
                ddwn.push(obj)
                this.setState({dataTable: table, methods:ddwn},()=>this.props.objMethod(table))
            }
        });
    }

    //---
    handleUpdateName = (value) =>{
        this.setState({name: value.value});
    }
    render() {    
        var classNameSelect =  regularSelectClass;  
        var table = this.state.dataTable;
        var dropdwn = [
            {name:'ACH',value:'ACH'},
            {name:'BMO Settlements',value:'BMO Settlements'},
            {name:'Customer to Drop-off',value:'Customer to Drop-off'},
            {name:'EFT',value:'EFT'},
            {name:'Mail',value:'Mail'},
            {name:'Telpay',value:'Telpay'},
            {name:'We Pick-up',value:'We Pick-up'},
            {name:'Wire Transfer',value:'Wire Transfer'},
            {name:'At the branch',value:'At the branch'}
        ];
        var isDisable = true
        if(table.length>0){
            //Remove to Ddwn
            table.forEach((tabIndex) =>{
                dropdwn.forEach((ddwnIndex, index) =>{
                    if(tabIndex.name === ddwnIndex.name){
                        dropdwn.splice(index,1)
                    }
                });
            });
        }
        switch (this.state.nameDetails) {
            case 'Incoming Instruments':
                isDisable = false;
                break;
        
            case 'Outgoing Instruments':
                isDisable = false;
                break;
            default:
                isDisable = true;
                break;
        }
        
        return (
            <div className="" style={{width:'50%',marginLeft:'auto', marginRight:'auto'}}>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    Delivery Method - {this.state.nameDetails}
                </h3>*/}
            <form >
                    <div className="uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-id">Name(*)</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="name" objValue={{value: this.state.name}} getValue={this.handleUpdateName} listHasPlaceholder={false} placeholder={"Select a Method..."} data={dropdwn} />
                        </div> 
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                
                    <br></br>
                    {this.state.dataTable.length > 0  &&<NewDynamicTable data={table} enableClick={true} clickFunction={this.handleRowClick} newSearch={this.state.newSearch} useDeleteButton={true} deleteFunction={this.handleDeleteClick} useFilter={false} />}
            
                    <div className="uk-form-stacked">
                        <br></br>
                        <button className="uk-button uk-button-green" onClick={this.handleAddItemClick.bind(this)} disabled={isDisable}>Add Item</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveAllClick} disabled={isDisable}>Save</button>
                    </div>
            </form>
        </div>
        );
    }
}
import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;


export async function searchCurrencies(currency, status) {
    if (currency !== '') {
        try {
            let params = {
                'currency': currency,
                'status': status
            }
            let response = await genericGetWithParameters('Currency/SearchCurrenciesByStatusMin', params);
            
            return response.currencies;
        } catch (error) {
            console.error(error);
        }
    }
}

export async function getCurrencyType() {
    try {
        
        let params = {}
        let response = await genericGetWithParameters('Currency/GetCurrencyType', params);
        return response.CurrencyType;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyList() {
    try {
        let params = {}
        let response = await genericGetWithParameters('Currency/GetCurrenciesMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByStatus(status) {
    try {
        let params = {
            'status': status,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByStatus', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByCustomerType(customerID, customerType) {
    try {
        let params = {
            'CustomerID': customerID,
            'Type': customerType
        }
        let response = await genericGetWithParameters('Currency/GetAvailableCurrenciesByCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByCustomer(customerID) {
    try {
        let params = {
            'customerID': customerID,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByCountry(countryID) {
    try {
        let params = {
            'countryID': countryID,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByCountry', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveCurrenciesByCountry(model) {
    let response = await genericCallWithBody('POST', 'Currency/SaveCurrenciesByCountry', model);
    return response;
}

export async function getCurrencies(page, items) {
    try {
        let params = {
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('Currency/GetCurrencies', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyMessages(page, items) {
    try {
        let params = {
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyMessage', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCurrencyMessageForCurrencyID = async (currencyID, page, items) => {
    try {
        const params = {
            'currencyID': currencyID,
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('Currency/GetCurrencyMessageForCurrencyID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyByID(id) {
    try {
        let params = {
            'currencyId': id
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyMessageByID(id) {
    try {
        let params = {
            'currencyMessageId': id
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyMessageById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function saveCurrency(objCurrency) {
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Currency/SaveCurrency';
    let response = await genericCallWithBody(method, apiName, objCurrency);
    return response;
}

export const saveCurrencyMessage = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Currency/SaveCurrencyMessage', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteCurrency(putCurrency) {
    try {
        let params = {
            'currencyId': putCurrency
        };
        let response = await genericDeleteWithParameters('Currency/DeleteCurrency', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteCurrencyMessage = async (currencyId) => {
    try {
        const params = {
            'currencyId': currencyId
        };
        const response = await genericDeleteWithParameters('Currency/DeleteCurrencyMessage', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNostroAccountCurrencies(personId)
{
    try {
        let params = {
            'PersonId': personId
        }
        let response = await genericGetWithParameters('Currency/GetNostroAccountCurrencies', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAmountInBaseCCy(currencyID, amount){
    try{
        let params = {
            CurrencyID: currencyID,
            Amount: amount
        }
        let response = await genericGetWithParametersNoEncrypted('Currency/GetAmountInBaseCCy',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}
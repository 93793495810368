import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import {CustomerType} from '../../../../constants/EntityTypeNames';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import AuditEntityPropertyScreen from '../auditEntity/AuditEntityPropertyScreen';

export default class AdditionalPropertiesScreen extends React.Component {
    state = {
        customerID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
        errors: [],
    }

    onFinishEntityPropertiesSaveProcess = (status,message,pendingMandatoryList) => {
        if(status){
            this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Success.', alertMessage: 'Customer Properties saved.', errors: pendingMandatoryList});
        }else{
            this.setState({loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Error', alertMessage: message, errors: pendingMandatoryList});
        }
        this.props.refreshCustomer()
    }

    handleButtonSaveClick = () => {
        this.setState({entityPropertiesSaveProcess: true, loading: true});
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    handleViewHistory = () => {
        this.props.showHistoryScreen();
    }

    render(){

        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties fullScreen={true} entityType={CustomerType} entityID={this.props.customerID} runSaveProcess={this.state.entityPropertiesSaveProcess} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} forceLoad={this.props.forceLoad}>
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistory} type="button">View History</button>
                        </EntityProperties>
                    </div>
                    <div>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick} type="button">Save</button>
                    </div>
                </form>
                <AlertBox 
                open={this.state.showAlert} 
                onClose={this.closeAlert.bind(this)} 
                title={this.state.alertTitle} 
                message={this.state.alertMessage} 
                type="Ok" 
                messageBoldList={this.state.errors}
                okClick={this.closeAlert.bind(this)}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
import React from 'react';
import EntityTasks from '../../../shared/EntityTasks';
import {CustomerContactType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class ContactTasksForm extends React.Component {
    state = {
        contactID: 0,
    }

    onFinishEntityTasksSaveProcess(){
        this.props.getSaveTasks(false);
    }

    /*handleButtonSaveClick(){
        this.setState({entityPropertiesSaveProcess: true});
    }*/

    render(){
        var section = (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <EntityTasks fullScreen={true} entityType={CustomerContactType} entityID={this.props.contactID} personID={this.props.personID}  runSaveProcess={this.props.saveTasks} finishSaveProcess={this.onFinishEntityTasksSaveProcess.bind(this)}/>
                </div>
                {/*<div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>                        
                </div>*/}
            </form>
        )
        if(this.props.contactID === 0){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create contact first.</h5>
                </div>
            )
        }
        return(
            <div>
                {section}
            </div>
        );
    }
}
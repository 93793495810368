import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle } from '../../../styles/styles';
import { BoardName } from '../../../constants/BoardNames';

var style = getStyle();

export default class WorkAssignmentBoardScreen extends React.Component {

    state = {
        showTitle: true,
        selectedItem: null
    }
    componentDidMount() {

    }

    isShowTitle = (value, item) => {
        this.setState({ showTitle: value, selectedItem: item })
    }
    render() {
        const { showTitle, selectedItem } = this.state;

        return (
            <div>
                {showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Work Assignment Boards</h3>}
                <GenericBoard board={BoardName.WorkAssignmentBoard} mainTitle={"Work Assignment Board"} isShowTitle={this.isShowTitle} selectSection={false}
                    canUpdateCustomerProcessStatus={true} />
                {!showTitle && selectedItem && (
                    <div>
                        <button onClick={() => this.setState({ showTitle: true, selectedItem: null })}>Back</button>
                        <h3>{selectedItem.title}</h3>
                        <p>{selectedItem.description}</p>
                    </div>
                )}
            </div>
        );
    }
}
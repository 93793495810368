import React from 'react';
import DayPicker from 'react-day-picker';

export const currentYear = new Date().getFullYear();
export const fromMonth = new Date(window.REACT_APP_DATE_OF_BIRTH_MIN ?? 1935, 0);
export const toMonth = new Date(currentYear + 20, 11);

export const YearMonthForm = ({ date, localeUtils, onChange }) => {
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i++) {
        years.push(i);
    }

    const handleChange = (e) => {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="uk-grid-collapse DayPicker-Caption">
            <div className="uk-width-auto">
                <div className="uk-grid-collapse uk-flex">
                    <div className="uk-width-1-2">
                        <select className="uk-select uk-form-width-small uk-form-small" name="month" onChange={handleChange} value={date.getMonth()}>
                            {months.map((month, i) => (
                                <option key={month} value={i}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="uk-width-1-3">
                        <select className="uk-select uk-form-width-small uk-form-small" name="year" onChange={handleChange} value={date.getFullYear()}>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </form>
    );
};

export class Calendar extends React.Component {
    state = {
        month: this.props.month
    };

    componentDidUpdate(prevProps) {
        if (prevProps.month === '' && this.props.month !== '') {
            this.setState({ month: this.props.month });
        } else if (this.props.month !== '' && prevProps.month.getTime() !== this.props.month.getTime()) {
            this.setState({ month: this.props.month });
        }
    }

    handleYearMonthChange = (month) => {
        this.setState({ month });
    }

    render() {
        return (
            <DayPicker
                value={this.props.value}
                onDayClick={this.props.onDayClick}
                selectedDays={this.props.selectedDays}
                formatDay={this.props.formatDay}
                fromMonth={this.props.fromMonth}
                toMonth={toMonth}
                month={this.state.month}
                captionElement={ ({date, localeUtils }) => (
                    <YearMonthForm
                        date={date}
                        localeUtils={localeUtils}
                        onChange={this.handleYearMonthChange}
                    />)}
            />
        );
    }
}
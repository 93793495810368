import React from 'react';

//USAGE
//<DynamicSelect getValue={this.getCountriesSelectValue.bind(this)} placeholder="Select your item" data={[value: "value", name: "name"]}/>

export default class DynamicSelect extends React.Component {
    state = {
        value: 'select',
        data: []
    }

    componentDidMount(){
        var propData = this.props.data;
        this.setState({data: propData});
        var propValue = 'select';
        if(this.props.objValue !== undefined && this.props.objValue.value !== ''){
            propValue = this.props.objValue.value;
        }
        this.setState({value: propValue}, ()=>{
            this.props.getValue(this.state.value);
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.objValue !== undefined && this.props.objValue !== undefined){
            if (prevProps.objValue.value !== this.props.objValue.value){
                this.setState({value: this.props.objValue.value});
            }
        }
    }

    change(event) {
        this.setState({value: event.target.value});
        var helper = {};
        for(var i=0; i<this.props.data.length; i++){
            if (this.props.data[i].value == event.target.value){
                helper = this.props.data[i];
            }
        }
        helper.controlID = this.props.id;
        helper.value = event.target.value;
        this.props.getValue(helper);
    }
    
    render() {
        var className = "uk-select";
        if(this.props.className !== undefined){
            className = this.props.className;
        }
        var disabled = false;
        if(this.props.disabled !== undefined){
          disabled = this.props.disabled;
        }
        var options = [];
        var placeholder = '';
        var initCount = 0;

        if (this.props.listHasPlaceholder){
            if(this.props.data.length>0){
                placeholder = this.props.data[0].name;
                initCount = 1;
            } else {
                placeholder = 'Loading...';
            }
        } else {
            placeholder = this.props.placeholder;
        }

        if(placeholder !== '' && placeholder !== undefined)
        {
            var placeholderValue = '';
            if(this.props.placeholderValue !== undefined){
                placeholderValue = this.props.placeholderValue;
            }
            options.push(<option key={"select"} value={placeholderValue}>{placeholder}</option>)
        }

        if(disabled)
        {
            for (var i=initCount; i<this.props.data.length; i++) {
                if(this.state.value === this.props.data[i].value)
                {
                    options.push(<option key={this.props.data[i].value} value={this.props.data[i].value}>{this.props.data[i].name}</option>);
                }
            }
        }else{
            for (var i=initCount; i<this.props.data.length; i++) {
                options.push(<option key={this.props.data[i].value} value={this.props.data[i].value}>{this.props.data[i].name}</option>);
            }
        }
        
        return(
            <div>
                <select 
                    className={className} 
                    onChange={this.change.bind(this)} 
                    value={this.state.value} 
                    id={this.props.id}
                    disabled={disabled}
                >
                    {options}
                </select>
            </div>
        );
    }
}
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../../AppContext';
import { getBankingStandardsByCountry, deleteBankingStandards, getValidations, saveBankingStandards } from '../../../../networking/NetworkingCountries';
import { getCurrencies } from '../../../../networking/NetworkingCurrencies';
import { getCountries } from '../../../../networking/NetworkingCountries';
import AlertBox from '../../../shared/AlertBox';
import { getStyle } from '../../../../styles/styles';
import NewValidatioinsScreen from './NewValidationsScreen';

var style = getStyle();
const { Option } = Select;

const NewBankingStandardScreen = ({selectedCountry}) => {
    const context = useContext(AppContext);
    const [form] = Form.useForm();
    const searchInput = useRef();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchArray, setSearchArray] = useState({});
    const [editingKey, setEditingKey] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const [firstime, setFirsttime] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [selectedCountryID, setSelectedCountryID] = useState(selectedCountry);
    const [updating, setUpdating] = useState(false);
    const [currentBankingStandardID, setCurrentBankingStandardID] = useState(0);
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');
    const [currencies, setCurrencies]           = useState([]);
    const [bankingStandards, setBankingStandards] = useState([]);
    const [labels, setLabels] = useState([]);
    const [countries, setCountries] = useState([]);
    const [validations, setValidations] = useState([]);

    const loadLabels = async () => {
        let labels = ['Account number','Institution','Routing Code'];
        setLabels(labels);
    }
    const loadBankingStandards = async (country) =>{        
        let currency = '';
        if(country!==undefined && country!==''){
            let json = await getBankingStandardsByCountry(country, currency);
            if (json != null && json.data != null) {
                let bankingStandards = json.data;
                setBankingStandards(bankingStandards);
            }
        }
    }
    const loadCurrencies = async () =>{
        let json = await getCurrencies(0,0);
        if(json !== undefined && json.currencies != null) {
            let currencies = json.currencies.map((c) => ({...c, key: c['Currency Id']}));
            setCurrencies(currencies);
        }
    }
    const loadCountries = async () => {
        const json = await getCountries(0, 0);
        if (json != null && json.countries != null) {
            let countries = json.countries.map((c) => ({...c, key: `StateScreen-${c.Country}`}));
            setCountries(countries);
        }
    };
    const loadValidations = async () => {
        const json = await getValidations();
        if (json != null && json.data != null) {
            let validations = json.data;
            setValidations(validations);
        }
    };

    useEffect(() => {
        context.startLoading();
        loadLabels();
        loadBankingStandards(selectedCountry);
        loadCurrencies(); 
        loadCountries();
        loadValidations();
        context.finishLoading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadBankingStandards(selectedCountryID)
        loadValidations();
    }, [updating, selectedCountryID]);

    useEffect(() => {
        setSelectedCountryID(selectedCountry);
    }, [selectedCountry]);
    
    const isEditing = (record) => record['Id'] === editingKey;
    
    const edit = (record) => {
        if (bankingStandards[0]['Id'] === 0) {
            let auxStates = [...bankingStandards];
            auxStates.shift();
            setBankingStandards(auxStates);
        }
        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record['Id']);
        setCurrentBankingStandardID(record['Id']);
        setCurrentUpdateToken(record['UpdateToken']);
    };
    
    const deleteRecord = async (record) => {
        context.startLoading();
        const deleteResponse = await deleteBankingStandards(record['Id']);
        context.finishLoading();
        if (deleteResponse != null) {
            if (deleteResponse.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(deleteResponse.httpErrorMessage);
                setUpdating(!updating);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Banking standard successfully removed.');
                setUpdating(!updating);
            }
        }
    };
    
    const cancel = (page, pageSize) => {
        setEditingKey(0);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }
        if (bankingStandards[0]['Id'] === 0) {
            let auxStates = [...bankingStandards];
            auxStates.shift();
            setBankingStandards(auxStates);
        }
        setCurrentBankingStandardID(0);
        setCurrentUpdateToken('');
    };
    
    const save = async (record) => {
        try {
            const row = await form.validateFields();
            const newData = [...bankingStandards];
            const index = newData.findIndex((item) => record === item['Id']);
            if (index > -1) {
                //const userID = localStorage.getItem('UserID');
                const model = {
                    Id: bankingStandards[index]['Id'] !== 0 ? currentBankingStandardID : 0,
                    Label: row['Label'],
                    Description: row['Label for country'],
                    CountryId: row['Country'],
                    CurrencyId: row['Currency'],
                    ValidationName: row['Validation Name']
                };
                context.startLoading();
                const json = await saveBankingStandards(model);
                context.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setUpdating(!updating);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Banking standard successfully saved.');
                        setUpdating(!updating);
                        setEditingKey(0);
                        setCurrentBankingStandardID(0);
                        setCurrentUpdateToken('');
                    }
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    
    let currencyFilters = []    
    currencies.forEach(currency => {
        currencyFilters.push({
            text    : currency['Currency Name'],
            value   : currency['Currency Id'],
        });
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {         
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const settingSearchStateValues = (value, col) => {        
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...searchArray[col] = value);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                ref           = {node => {searchInput.current = node;}}
                placeholder   = {`Search ${dataIndex}`}
                value         = {selectedKeys[0]}
                onChange      = {(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : [])}
                }
                onPressEnter  = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                style         = {{
                    width       : 188, 
                    marginBottom: 8, 
                    display     : 'block' 
                }}
            />
            <Space>
                <Button
                    type    = "primary"
                    onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon    = {<SearchOutlined />}
                    size    = "small"
                    style   = {{ width: 90 }}
                >Search
                </Button>
                <Button 
                    onClick = {() => {
                        handleReset(clearFilters);
                    }}
                    size    = "small" 
                    style   = {{ width: 90 }}
                >Reset
                </Button>
                {/*<Button
                    type    = "link"
                    size    = "small"
                    onClick = {() => {
                        confirm({ closeDropdown: false });
                        settingSearchStateValues(selectedKeys[0], dataIndex);
                    }}
                >Filter
                </Button>*/}
            </Space>
          </div>
        ),
        filterIcon                      : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter                        : (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange   : visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render                          : text => searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle    = {{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords       = {[searchText]}
              autoEscape
              textToHighlight   = {text ? text.toString() : ''}
            />
          ) : (
            text
          )
    });

    const columns = [
        {
            title           : 'Label',
            dataIndex       : 'Label',
            width           : '10%',
            editable        : true,
            align           : 'center',
            defaultSortOrder: 'ascend',
            sorter          : (a, b) => { return a.Label.localeCompare(b.Label)},
            sortDirections  : ['descend', 'ascend'],
            filterMultiple  : false,
            onFilter        : (value, record) => {  
                if(record.Label.indexOf(value) === 0)
                {
                    return true;
                }
            },
            ...getColumnSearchProps('Label'),
        },
        {
            title           : 'Label for country',
            dataIndex       : 'Label for country',
            width           : '20%',
            editable        : true,
            align           : 'left',
            sorter          : (a, b) => { return a['Label for country'].localeCompare(b['Label for country'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Label for country'),
        },
        {
            title           : 'Country',
            dataIndex       : 'Country',
            width           : '10%',
            editable        : true,
            align           : 'left',
            sorter          : (a, b) => { return a['Country'].localeCompare(b['Country'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Country'),
        },
        {
            title           : 'Currency',
            dataIndex       : 'Currency',
            width           : '10%',
            editable        : true,
            align           : 'left',
            sorter          : (a, b) => { return a['Currency'].localeCompare(b['Currency'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Currency'),
        },
        {
            title           : 'Validation Name',
            dataIndex       : 'Validation Name',
            width           : '20%',
            editable        : true,
            align           : 'left',
            sorter          : (a, b) => { return a['Validation Name'].localeCompare(b['Validation Name'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Validation Name'),
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <a
                        onClick = {() => save(record.Id)}
                        style   = {{
                            marginRight: 8,
                        }}
                    >Save</a>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <a>Cancel</a>
                    </Popconfirm>
                </span>
                ) : (
                <div>
                    <Space size="middle">                        
                        <Typography.Link disabled={editingKey !== 0 ? true: (bankingStandards[0]['Id'] === 0) ? true: false} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Popconfirm title = "Sure to delete?" onConfirm = {() => {deleteRecord(record)}}>
                            <a disabled = {editingKey !== 0 ? true: (bankingStandards[0]['Id'] === 0) ? true: false} >Delete</a>
                        </Popconfirm>
                    </Space>
                </div>
                );
            },
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        let type = '';
        switch (col.dataIndex) {
            case 'Label': {
                type = 'select-label';
                break;
            }
            case 'Currency': {
                type = 'select-currency';
                break;
            }
            case 'Country': {
                type = 'select-country';
                break;
            }
            case 'Validation Name': {
                type = 'select-validation';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = null;
        let allOptionsOfCurrencies = [];
        currencies.forEach(currency => {            
            allOptionsOfCurrencies.push(<Option key={currency['Currency Id']} value={currency['Currency Id']}>{currency['Currency Id'] + ' - ' + currency['Currency Name']}</Option>)
        })
        let allOptionsOfLabels = [];
        labels.forEach(label => {
            allOptionsOfLabels.push(<Option key={label} value={label}>{label}</Option>)
        })
        let allOptionsOfValidations = [];
        allOptionsOfValidations.push(<Option value={'No validation'}>{'No validation'}</Option>)
        validations.forEach(validation => {
            allOptionsOfValidations.push(<Option value={validation.Name}>{validation.Name}</Option>)
        })
        let allOptionsOfCountries = [];
        countries.forEach(country => {
            allOptionsOfCountries.push(<Option
                key={`cell-${country.Country}-${country['Country Name']}`}
                value={country.Country}>{`${country['Country Name']} - ${country.Country}`}</Option>)
        })
        
        switch (inputType) 
        {
            case 'number':
            {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                break;
            }
            case 'select-country':
            {
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select a Country"
                        optionFilterProp    = "children"
                        filterOption        = {
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={true}
                    >
                        {allOptionsOfCountries}
                    </Select>
                );
                break;
            }
            case 'select-label':
            {
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select a Label"
                        optionFilterProp    = "children"
                        filterOption        = {
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allOptionsOfLabels}
                    </Select>
                );
                break;
            }
            case 'select-currency':
            {
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select a Currency"
                        optionFilterProp    = "children"
                        filterOption        = {
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allOptionsOfCurrencies}
                    </Select>
                );
                break;
            }
            case 'select-validation':
            {
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select a Validation"
                        optionFilterProp    = "children"
                        filterOption        = {
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allOptionsOfValidations}
                    </Select>
                );
                break;
            }
            /*case 'check':
            {
                inputNode = <Checkbox onChange={onChange} checked={isCheckedStatus}>Authorized Deal</Checkbox>;
                break;
            }*/
            default:
            {
                inputNode = <Input style={{textAlign: 'center'}}/>;
                break;
            }
        }
        let styleEdit = {margin: 0};

        if((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing)
        {
            styleEdit = {
                paddingBottom   : 10, 
                paddingTop      : 10,
                margin          : 0,
            }
        }

        return (
            <td {...restProps}>
              {editing ? (
                <Form.Item
                  name={dataIndex}
                  style={styleEdit}
                  rules={[
                    {
                      required: true,
                      message : `Please Input ${title}!`,
                    },
                  ]}
                >
                  {inputNode}
                </Form.Item>
              ) : (
                children
              )}
            </td>
          );
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
          return <a>Previous</a>;
        }
        if (type === 'next') {
          return <a>Next</a>;
        }
        return originalElement;
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    const addNewBankingStandard = () => {
        let actual = [...bankingStandards];
        if (actual[0] != null && actual[0]['Id'] === 0) {
            return;
        } else {
            actual.unshift({
                'Id': 0,
                'Label': '',
                'Label for country': '',
                'Country': selectedCountryID,
                'Currency': '',
                'Validation Name': '',
                UpdateToken: '',
                isNew: true
            });
            setBankingStandards(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Label': '',
                'Label for country': '',
                'Country': selectedCountryID,
                'Currency': '',
                'Validation Name': '',
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentBankingStandardID(0);
        }
    };

    return(
        <div>
            <form className="uk-form-stacked uk-grid uk-margin">
                <div className="uk-width-1-2">
                    <label className="uk-form-label" >
                        Country
                    </label>
                    <div className="uk-form-controls">
                        <select id="bankingstandard-countries" className="uk-select" value={selectedCountryID}
                            onChange={(e) => {
                                setSelectedCountryID(e.target.value)
                            }}>
                            <option key='bankingstandard-option-placeholder' value=''>Select a Country...</option>
                            {countries.map((country) => (
                                <option
                                    key={`bankingstandard-option-${country.Country}`}
                                    value={country.Country}>{`${country['Country Name']} - ${country.Country}`}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </form>
            <Form form={form} component={false}>                
                <Table
                    components      = {{ body: { cell: EditableCell } }}
                    //bordered        = {true}
                    dataSource      = { bankingStandards }
                    columns         = { mergedColumns }
                    //rowClassName    = "editable-row"
                    pagination      = {{
                        onChange        : cancel,
                        showQuickJumper : true,
                        itemRender      : itemRender,
                        current         : currentPage,
                        defaultCurrent  : 1,
                        size            : 'default'
                    }}
                    size            = 'small'
                    footer          = {() => {
                        return(
                            <Button 
                                type        = "dashed" 
                                onClick     = {addNewBankingStandard}
                                block
                                icon        = {<PlusOutlined />}
                                style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                disabled    = {selectedCountryID === '' || editingKey !== 0 || filteringSomething || (bankingStandards[0] !== undefined && bankingStandards[0]['Id'] === 0)}
                            >Add a Banking Standard
                            </Button>
                        );
                    }}
                />
            </Form>
            <NewValidatioinsScreen />
            <AlertBox id="alert-new-bankingStandard" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );

};

export default NewBankingStandardScreen;
  
import { ContourValueResolverDescription } from 'igniteui-react-core';
import React from 'react';
import { TaskLinkType } from '../../constants/LinkTypeNames';
import MinusIcon from '../../css/custom-icons/minus.svg';
import PlusIcon from '../../css/custom-icons/plus.svg';
import { formatDate, titleCase } from '../../helpers/FormatHelper';
import { getLookUpTable } from '../../networking/Networking';
import { getCountries } from '../../networking/NetworkingCountries';
import { completeEntityTask, getEntityTasks, updateEntityTask } from '../../networking/NetworkingEntityTasks';
import { getStyle } from '../../styles/styles';
import PersonIdentificationsScreen from '../pre_trade/customers/updateCustomer/PersonIdentificationsScreen';
import AttachmentsTable from './AttachmentsTable';
import DatePicker from './DatePicker';
import DynamicSelect from './DynamicSelect';
import LoadingBox from './LoadingBox';




var style = getStyle();
var counter = 1;
var helperCounter = 1;
var lengthListOfTasks = 0;

class TaskRow extends React.Component {
    state = {
        completed: false,
        completedBy: '',
        completedOn: '',
        deadlineDate: '',
        expiryDate: '',
        numberOfAttachments: 0,
        requestCustomer: false,
        title: '',
        linkID: '',
        taskDefID: '',
        attachmentsID: '',
        toggle: '',
        tooltip: '',
        forceLoadAttachments: false,
        showExtendedInfo: false,
        personID : '',
        hasPersonIdentification: false,
        reloadPersonIdentification: false
    }

    componentDidMount(){
        var entityTask = this.props.entityTask;
        var attachmentsID = 'attachments-' + entityTask.LinkID;
        var toggle = 'target: #'+ attachmentsID + '; animation: uk-animation-slide-top-small';
        var attachmentsText = 'No Attachments'
        if(entityTask.NoOfAttachments > 0){
            attachmentsText = entityTask.NoOfAttachments + " Attachment";
            if(entityTask.NoOfAttachments > 1){
                attachmentsText = entityTask.NoOfAttachments + " Attachments";
            }
        }
        var requestCustomer = false;
        if(entityTask['Status'] === 'RequestCustomer'){
            requestCustomer = true;
        }

        var personID = '';
        if(this.props.personID != undefined)
        {
            personID = this.props.personID;
        }
        
        this.setState({
            completed: entityTask.Completed,
            completedBy: entityTask.CompletedBy,
            completedOn: entityTask.CompletedOn,
            deadlineDate: entityTask.DeadlineDate !== '' ? entityTask.DeadlineDate : entityTask.PlaceholderDate,
            expiryDate: entityTask.ExpiryDate,
            numberOfAttachments: entityTask.NoOfAttachments,
            requestCustomer: requestCustomer,
            title: entityTask.Title,
            linkID: entityTask.LinkID,
            taskDefID: entityTask.taskdefid,
            attachmentsID: attachmentsID,
            toggle: toggle,
            tooltip: attachmentsText,
            personID: personID,
            hasPersonIdentification: entityTask.IsPersonIdentification
        }, ()=> this.updateTask());
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityTask !== this.props.entityTask){
            var entityTask = this.props.entityTask;
            var attachmentsID = 'attachments-' + TaskLinkType + '-' + entityTask.LinkID;
            var toggle = 'target: #'+ attachmentsID + '; animation: uk-animation-slide-top-small';
            var attachmentsText = 'No Attachments'
            if(entityTask.NoOfAttachments > 0){
                attachmentsText = entityTask.NoOfAttachments + " Attachment";
                if(entityTask.NoOfAttachments > 1){
                    attachmentsText = entityTask.NoOfAttachments + " Attachments";
                }
            }
            var requestCustomer = false;
            if(entityTask['Status'] === 'RequestCustomer'){
                requestCustomer = true;
            }
            var completedBy = '';
            if(entityTask.Completed){
                completedBy = entityTask.CompletedBy;
            }

            var personID = '';
            if(this.props.personID != undefined)
            {
                personID = this.props.personID;
            }
            this.setState({
                completed: entityTask.Completed,
                completedBy: completedBy,
                completedOn: entityTask.CompletedOn,
                deadlineDate: entityTask.DeadlineDate !== '' ? entityTask.DeadlineDate : entityTask.PlaceholderDate,
                expiryDate: entityTask.ExpiryDate,
                numberOfAttachments: entityTask.NoOfAttachments,
                requestCustomer: requestCustomer,
                title: entityTask.Title,
                linkID: entityTask.LinkID,
                taskDefID: entityTask.taskdefid,
                attachmentsID: attachmentsID,
                toggle: toggle,
                tooltip: attachmentsText,
                personID: personID,
                hasPersonIdentification: entityTask.IsPersonIdentification
            }, ()=> this.updateTask());
        }
    }

    handleUpdateDeadlineDate(date){
        this.setState({deadlineDate: formatDate(date)},() => this.updateTask());
    }

    handleUpdateExpiryDate(date){
        this.setState({expiryDate: formatDate(date)},() => this.updateTask());
    }

    handleCompleteTask(event){
        this.setState({completed: event.target.checked}, () => this.updateTask());
    }

    handleRequestCustomer=(event)=>{
        this.setState({requestCustomer: event.target.checked}, () => this.updateTask());
    }

    updateTask(){
        let userID = localStorage.getItem('UserID');
        var entityTask = this.props.entityTask;
        var objectToUpdate = {};
        objectToUpdate.Completed = this.state.completed;
        objectToUpdate.Status = entityTask.Status;
        if (objectToUpdate.Completed) {
            objectToUpdate.CompletedBy = userID;
            objectToUpdate.Status = 'Completed';
        }
        if(this.state.requestCustomer){
            objectToUpdate.Status = 'RequestCustomer';
        }
        if(entityTask.Status === 'RequestCustomer' && !this.state.requestCustomer){
            objectToUpdate.Status = 'Outstanding';
        }
        objectToUpdate.UpdatedBy = userID;
        
        objectToUpdate.DeadlineDate = this.state.deadlineDate;
        objectToUpdate.ExpiryDate = this.state.expiryDate;
        objectToUpdate.EntityTaskId = entityTask.LinkID;
        objectToUpdate.LinkID = this.state.linkID;
        objectToUpdate.UpdateToken = entityTask.UpdateToken;
        objectToUpdate['GroupBy'] = entityTask['GroupBy'];
        objectToUpdate["Request Customer"] = entityTask["Request Customer"]

        objectToUpdate.DeadlineDateOriginal = entityTask.DeadlineDateOriginal
        objectToUpdate.ExpiryDateOriginal = entityTask.ExpiryDateOriginal
        objectToUpdate.StatusOriginal = entityTask.StatusOriginal
        objectToUpdate["Request Customer Original"] = entityTask["Request Customer Original"]
        objectToUpdate.CompletedOriginal = entityTask.CompletedOriginal

        this.props.getTask(objectToUpdate);
    }

    onAttachmentClick = () => {
        let value = this.state.forceLoadAttachments;
        this.setState({forceLoadAttachments: !value});
    }

    onAdditionalInfoClick = () => {
        var show = !this.state.showExtendedInfo;
        this.setState({showExtendedInfo: show});
    }

    handleBackLinkPersonIdentification = (fromSave) =>
    {
        var hasPersonIdentification = fromSave ? true: this.state.hasPersonIdentification;
       this.setState({showExtendedInfo: false, hasPersonIdentification: hasPersonIdentification}); 
    }

    showSquareIcon = () => {
        return (!this.state.showExtendedInfo ? 'fa-plus-square' : 'fa-minus-square' );
    }

    onDeleteAttach = () => {
        let reload = this.state.reloadPersonIdentification;
        this.setState({showExtendedInfo: false});
    }

    render() {
        var notificationAttachmentsBadge = (<span></span>);
        if(this.props.entityTask.NoOfAttachments > 0){
            notificationAttachmentsBadge = (<span className="badge">{this.props.entityTask.NoOfAttachments}</span>);
        }
        let newCompleted = (this.state.completedBy != null && this.state.completedBy !== '') ? this.state.completedBy + ' on ' + this.state.completedOn : '';

        return (
            <div className="uk-grid task-row">
                <div className="uk-width-7-7"><p style={{fontSize: '14px'}}>{this.state.title}</p></div>
                    <div className="uk-width-1-7">
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-deadline">Deadline Date</label>
                            <div className="uk-form-controls">
                                <DatePicker useNew onDayChange={this.handleUpdateDeadlineDate.bind(this)} id="form-entity-task-deadline" key="form-entity-task-deadline" className="uk-input" value={this.state.deadlineDate}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-7">
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-expiry">Expiry Date</label>
                            <div className="uk-form-controls">
                                <DatePicker useNew onDayChange={this.handleUpdateExpiryDate.bind(this)} id="form-entity-task-expiry" key="form-entity-task-expiry" className="uk-input" value={this.state.expiryDate}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-7 uk-margin"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-complete" ><input id="form-entity-task-complete" className="uk-checkbox" type="checkbox" checked={this.state.completed} value={this.state.completed} onChange={this.handleCompleteTask.bind(this)}/> Complete</label>                            
                        </div>
                    </div>
                    <div className="uk-width-1-7 uk-margin"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-request" >
                                <input id="form-entity-task-request" className="uk-checkbox" type="checkbox" checked={this.state.requestCustomer} value={this.state.requestCustomer}  onChange={this.handleRequestCustomer}/>
                                    Request Customer
                            </label>
                        </div>
                    </div>
                    <div className="uk-width-1-7"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-completed-by" >Completed</label>
                            <div className="uk-form-controls">                                
                                <input key="form-entity-task-completed-by" className="uk-input" id="form-entity-task-completed-by" type="text" placeholder="" value={newCompleted} readOnly/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-7" style={{paddingTop: '25px'}}> 
                        <i className="fa fa-fw fa-paperclip" onClick={this.onAttachmentClick} uk-toggle={this.state.toggle} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer' }}>{notificationAttachmentsBadge}</i>
                    {this.props.entityTask.LinkID != null && this.props.entityTask.LinkID !== '00000000-0000-0000-0000-000000000000' &&
                        this.props.entityTask.ExtendedInfoType === 'PersonIdentification' && (//.IsPersonIdentification === true )
                        <i className={`fa fa-fw ${this.showSquareIcon()}`} onClick={this.onAdditionalInfoClick} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', color: this.state.hasPersonIdentification ? 'blue' : 'gray' }}></i>
                    )}
                    </div>
                    <div className="uk-width-1-1 uk-margin" id={this.state.attachmentsID} hidden>
                        <AttachmentsTable linkType={TaskLinkType} linkID={this.state.linkID} onNewAttachment={this.props.onNewAttachment} forceLoad={this.state.forceLoadAttachments} onDelete ={this.onDeleteAttach}/>
                    </div>
                    {this.state.showExtendedInfo && (
                        <PersonIdentificationsScreen countries={this.props.countries} 
                            dropdowns={this.props.dropdowns}
                            ExpiryDate={this.state.expiryDate}
                            handleBackLink={this.handleBackLinkPersonIdentification}
                            PersonID={this.state.personID}
                            EntityTaskID={this.props.entityTask.LinkID}/>
                    )}
            </div>
        );
    }
}

export default class EntityTasks extends React.Component {
    state ={
        listCategories: [],
        filteredCategories: [],
        originalTasks: [],
        finalListEntityTasks: [],
        listEntityTasks: [],
        filteredListEntityTasks: [],
        filterScreen: false,
        loading: false,
        filtering: false,
        nameFilter: '',
        completionFilter: '', 
        completedStatusFilter: [],
        selectedStatusFilter: {name: 'All', value: 'All'},
        personID:'',
        lookUpTables: [
            "Completed Task Filter",
            "PersonIdentificationType",
            "IdentificationStatus"
        ],
        countries: [],
        dropdowns: {            
            "Completed Task Filter":[],
            "PersonIdentificationType": [],
            "IdentificationStatus": []
        }
    }
    
    componentDidMount(){
        getLookUpTable(this.state.lookUpTables.toString()).then((json)=>{
            for (var i = 0; i < this.state.lookUpTables.length; i++) {
                let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                if (index > -1) {
                    var table = json.lookUpTables[index].lookUpTableDetails;
                    for (var j = 0; j < table.length; j++) {
                        table[j].value = table[j].LookUpTableDetail;
                        table[j].name = table[j].LookUpTableDetail;
                    }
                    var completedStatusFilter = [];
                    ////debugger
                    if(this.state.lookUpTables[i] == 'Completed Task Filter')
                    {
                        completedStatusFilter = table;
                        this.setState({completedStatusFilter: completedStatusFilter});
                    }
                    this.setState({dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                }
            }
        });
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ countries: countries } );
                }
            }
        )

        this.getTasks();
        if(this.props.personID !== undefined)
        {
            this.setState({personID: this.props.personID});
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityID !== this.props.entityID){            
            this.getTasks();
        }

        if(prevProps.runSaveProcess !== this.props.runSaveProcess && this.props.runSaveProcess){
            this.saveTasks();
        }

        if(this.props.personID !== undefined && prevProps.personID !== this.props.personID)
        {
            this.setState({personID: this.props.personID});
        }
    }

    onNewAttachment = () => {
        this.saveTasks();
        this.getTasks();
    }

    getTasks(){
        this.setState({loading: true});
        getEntityTasks(this.props.entityID, this.props.entityType).then(
            (value) => {
                if(value.taskDefs){
                    //console.group('EntityTasks');
                    //console.table(value.taskDefs);
                    //console.groupEnd();
                    value.taskDefs.forEach(x => {
                        x.CompletedOriginal = x.Completed
                        x.DeadlineDateOriginal = x.DeadlineDate
                        x.ExpiryDateOriginal = x.ExpiryDate
                        x.StatusOriginal = x.Status
                        x["Request Customer Original"] = x["Request Customer"]
                        x.GroupBy = (x.GroupBy === undefined || x.GroupBy === null || x.GroupBy === '') ? 'Without group' : x.GroupBy
                    }) 
                    this.setState({loading: false, originalTasks: value.taskDefs, finalListEntityTasks: value.taskDefs, listEntityTasks: value.taskDefs, filteredListEntityTasks: value.taskDefs});
                    this.getCategories(value.taskDefs);
                } else {
                    this.setState({loading: false});
                }
            }
        );
    }

    getCategories(json) {
        var helper = [];
        for (var i = 0; i < json.length; i++) {
            if (!helper.includes(json[i]['GroupBy'])) {
                helper.push(json[i]['GroupBy']);
            }
        }        
        this.setState({ listCategories: helper.reverse() });
    }

    getTask(value){
        //var listOfTasks = [...this.state.finalListEntityTasks];
        let listOfTasks = [];
        for(var i=0; i<this.state.finalListEntityTasks.length; i++){
            if(this.state.finalListEntityTasks[i].LinkID === value.LinkID){
                listOfTasks.push(value);
            } else {
                listOfTasks.push(this.state.finalListEntityTasks[i]);
            }
            
        };
        this.setState({finalListEntityTasks: listOfTasks});
    }

    saveTasks = async () => {
        var listOfTasks = this.state.finalListEntityTasks;
        //var originalTasks = this.state.originalTasks;
        listOfTasks = listOfTasks.filter(x => {
            let valueHasChange = false
            valueHasChange = x.DeadlineDateOriginal !== x.DeadlineDate
            valueHasChange = valueHasChange || x.CompletedOriginal !== x.Completed
            valueHasChange = valueHasChange || x.ExpiryDateOriginal !== x.ExpiryDate
            valueHasChange = valueHasChange || x.StatusOriginal !== x.Status
            valueHasChange = valueHasChange || x["Request Customer Original"] !== x["Request Customer"]

            return valueHasChange
        }) || [];

        //counter = 1;
        //helperCounter = 1;
        ///lengthListOfTasks = listOfTasks.length;
        let concurrencyError = false;
        let arrayOfConcurrencyTasks = [];
            //let userID = localStorage.getItem('UserID');
            for(var i = 0; i<listOfTasks.length; i++){
                // eslint-disable-next-line no-loop-func
                let taskToProcess = listOfTasks[i]

                let updateResponse = await updateEntityTask(taskToProcess);
                if (updateResponse.httpStatusCode === 409) {
                    concurrencyError = true;
                    arrayOfConcurrencyTasks.push(taskToProcess.Title)
                } else if (taskToProcess.Completed === true || (taskToProcess.Completed === false && taskToProcess.CompletedOriginal === 1)) {
                    let completeResponse = await completeEntityTask(taskToProcess);
                    if (completeResponse.httpStatusCode === 409) {
                        concurrencyError = true;
                        arrayOfConcurrencyTasks.push(taskToProcess.Title)
                    }
                }
                //this.props.finishSaveProcess();
            }

            if(concurrencyError){
                let message = `These tasks have been updated by someone else: ${arrayOfConcurrencyTasks.join(', ')}. Please reload the information and try again.`
                this.props.finishSaveProcess(false,message);
            } else {
                this.props.finishSaveProcess(true,"Success.");
            }
            this.getTasks();
    }

    onChangeNameFilter = (event) => {
        this.setState({nameFilter: event.target.value},()=>{
            this.runFilter();
        });
    }

    onChangeCompletedStatusFilter = (objValue) => {
        let helper = '';
        switch (objValue.value) {
            case 'All':
                helper = '';
                break;
            case 'Completed':
                helper = true;
                break;
            case 'Not Completed':
                helper = false;
                break;
            default:
                break;
        }
        
        this.setState({completionFilter: helper},()=>{
            this.runFilter();
        });
    }

    runFilter = () => {
        let newList = [];
        let filterList = this.state.listEntityTasks;
        let name = this.state.nameFilter;
        let complete = this.state.completionFilter;

        if (name === '' && complete === ''){
            this.setState({filteredListEntityTasks: this.state.listEntityTasks});
            this.getCategories(this.state.listEntityTasks);
            return;
        }

        filterList.forEach(element => {
            if(element.Title.toLowerCase().includes(name.toLowerCase()) && (element.Completed === complete || complete === '') ) {
                newList.push(element);
            }
        });
        this.setState({filteredListEntityTasks: newList});
        this.getCategories(newList);
    }

    onFilterButtonClick = () => {
        if(this.state.filtering){
            this.setState({filtering: false, nameFilter: '', contentFilter: ''},()=>{this.runFilter()});
        } else {
            this.setState({filtering: true});
            this.runFilter();
        }
    }

    render(){
        var fullScreen = false;

        if(this.props.fullScreen !== undefined){
            fullScreen = this.props.fullScreen;
        }

        var filterIcon = "fa fa-fw fa-filter uk-align-right";
        if (this.state.filtering){
            filterIcon = "fa fa-fw fa-ban uk-align-right";
        }

        var id = "";
        if(this.props.id !== undefined){
            id = "-"+this.props.id;
        }

        var idForFilter = "entity-tasks-filter"+this.props.entityID;

        var tasks = [];
        
        for(var j=0; j<this.state.listCategories.length; j++){
            var idForGroup = 'task-group-' + j;
            var toogleForGroup = "target: #"+idForGroup+";"
            tasks.push(<h5 key={j} uk-toggle={toogleForGroup} className="uk-heading-divider uk-margin propoerties-tasks-category-title" style={{...style.tasktitleStyle, cursor: 'pointer'}}>{titleCase(this.state.listCategories[j])} 
                <img 
                        id={idForGroup}
                        className="uk-align-right"
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{position: 'sticky',
                        paddingTop: '5px',
                        marginBottom: '0px',
                        right: '0px', color: 'black'}}
                    />
                    <img 
                        id={idForGroup} 
                        className="uk-align-right"
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{position: 'sticky',
                        paddingTop: '5px',
                        marginBottom: '0px',
                        right: '0px', color: 'black'}} 
                        hidden
                    />
            
               </h5>);
            var listTask = [];
            for (var k=0; k<this.state.filteredListEntityTasks.length; k++){
                if(this.state.filteredListEntityTasks[k]['GroupBy'] === this.state.listCategories[j]){
                    listTask.push(<TaskRow key={this.state.filteredListEntityTasks[k].LinkID} entityTask={this.state.filteredListEntityTasks[k]} getTask={this.getTask.bind(this)} 
                                onNewAttachment={this.onNewAttachment}
                                personID={this.state.personID}
                                countries= {this.state.countries}
                                dropdowns={this.state.dropdowns}/>);
                }
            }
            tasks.push(<div id={idForGroup} className="task-group" hidden>{listTask}</div>);
        }


        if(this.state.filteredListEntityTasks === undefined || this.state.filteredListEntityTasks.length === 0){
            tasks.push(<h5 style={style.alignCenter}>No Tasks Available.</h5>)
        }

        if(fullScreen){
            return(
                <div>
                    <div className="uk-grid container-filter-properties" id={idForFilter} >
                        <div className="uk-width-2-12 filter-label">
                            <form className="uk-form-horizontal">
                                <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                <div className="uk-form-controls-filter"><input className="uk-input" id="property-name-filter-input" type="text" placeholder="Task Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                            </form>
                        </div>
                        <div className="uk-width-1-12">
                            <form className="uk-form-horizontal">
                                <label htmlFor="form-filter-completed-task">
                                    Status
                                </label>
                            </form>
                        </div>
                        <div className="uk-width-2-12">
                            <DynamicSelect id="form-filter-completed-task" className="uk-select" objValue={this.state.selectedStatusFilter} listHasPlaceholder={true} getValue={this.onChangeCompletedStatusFilter} placeholder="Select your item" data={this.state.completedStatusFilter}/>
                        </div>
                    </div>
                    <div id="entity-tasks" className="uk-margin task-div">
                        {tasks}  
                    </div>
                    <LoadingBox loading={this.state.loading} />
                </div>
            );
        } else {
            return(
                <div>
                    <button className="uk-button uk-button-default" type="button" uk-toggle="target: #entity-tasks; animation: uk-animation-slide-top-medium">Task List</button>
                    <div className="uk-margin" id="entity-tasks" hidden>
                        <div className="uk-grid container-filter-properties" id={idForFilter} >
                            <div className="uk-width-2-12 filter-label">
                                <form className="uk-form-horizontal">
                                    <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                    <div className="uk-form-controls-filter"><input className="uk-input" id="property-name-filter-input" type="text" placeholder="Task Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                                </form>
                            </div>
                            <div className="uk-width-1-12">
                                <label htmlFor="form-filter-completed-task">
                                     Completed
                                </label>
                            </div>
                            <div className="uk-width-2-12">
                                <DynamicSelect id="form-filter-completed-task" className="uk-select" objValue={this.state.selectedStatusFilter} listHasPlaceholder={true} getValue={this.onChangeCompletedStatusFilter} placeholder="Select your item" data={this.state.completedStatusFilter}/>
                            </div>
                        </div>
                        <div>
                            {tasks}  
                        </div>
                    </div>
                    <LoadingBox loading={this.state.loading} />
                </div>
            );
        }
    }
}
import { Breadcrumb } from 'antd';
import React from 'react';
import AppContext from '../../../../AppContext';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import { saveOwner, getOwnerByID } from '../../../../networking/NetworkingOwners';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import AddressForm from '../../addresses/AddressForm';
import AuditOwnerScreen from '../auditEntity/AuditOwnerScreen';
import OwnersScreen from '../updateCustomer/OwnersScreen';
import OwnerAdditionalProperties from '../owners/OwnerAdditionalProperties';
import OwnerCompliance from '../owners/OwnerCompliance';
import OwnerInformationForm from '../owners/OwnerInformationForm';
import OwnerTasks from '../owners/OwnerTasks';

export default class OwnerMaintenanceScreen extends React.Component {
    static contextType = AppContext;
    state = {
        ownerID: 0,
        updateToken: 0,
        isValidated: true,
        isValidatedAddress: true,
        ownerInfoErrors: [],
        addressErrors: [],
        notFirstLoad: true,
        notFirstLoadOwner: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        updateOwner: false,
        pressedButton: false,
        saveProperties: false,
        saveTasks: false,
        selectOwner: {},
        viewHistory: false,
        isClearAdd: false,
        isClearOwner: false,
        activeTab: "owner-information",
        enableButtonSaveInAfex: false,
        //OBJ FILTERS
        objOwnerInfo: {},
        objAddress: {},
        objBankAccount: {},

        //WatchList
        showAlertSaveSuspicious: false,
        toggleReloadGlobalSearch: false,
        enableButtonSaveToInterface: false,

        ownerBankAccounts: []
    }

    componentDidMount() {
        console.log(this.props.selectedOwner);
        this.setState({ selectOwner: this.props.selectedOwner })
    
        if (this.props.selectedOwner.Address != null) {
            var objAdd = this.props.selectedOwner.Address[0];
            this.setState({ objAddress: objAdd });
        }
        if (this.props.selectedOwner.BankAccountsInfo != null){
            this.setState({ ownerBankAccounts: this.props.selectedOwner.BankAccountsInfo});
        }

        if (this.props.readOnly !== undefined) {
            this.setState({
                isReadOnly: this.props.readOnly
            })
        }
        getParametersByName(GlobalSystemParametersNames.EnableButtonOwnerSaveInAfex).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveInAfex: response.parameters[0].Value });
                }
            }
        )
        getParametersByName(GlobalSystemParametersNames.EnableButtonOwnerSaveInInterface).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveToInterface: response.parameters[0].Value });
                }
            }
        )
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedOwner && prevProps.updateToken !== this.props.updateToken) {
            this.setState({ selectOwner: this.props.selectedOwner, updateToken: this.props.updateToken });
        }
    }

    changePage(value) {
    }

    updatePage(ownerID, updateToken, updateTokenAddress, newOwner) {
        if (newOwner) {
            this.setState({ showAlert: true });
        }
        var objOwnerInfo = this.state.objOwnerInfo
        var objAdd = this.state.objAddress
        getOwnerByID(ownerID, 1).then(
            (json) => {
                objOwnerInfo.ownerID = ownerID
                objOwnerInfo.updateToken = updateToken
                objAdd.AddressID = json.owner[0].Address[0].AddressID
                objAdd.UpdateToken = updateTokenAddress
                this.setState({ objOwnerInfo: objOwnerInfo, objAddress: objAdd })
            }
        );
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            notFirstLoad: true,
            notFirstLoadOwner: true,
            showAlert: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            updateOwner: false,
            pressedButton: false,
            saveProperties: false,
            saveTasks: false,
            selectOwner: null,
            viewHistory: false,
            objOwnerInfo: {},
            objAddress: null,
            objBankAccount: {}
        }, () => this.props.isNewClearOwner())
    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick = async () => {
        if (this.state.isValidated && this.state.isValidatedAddress) {
            if(this.state.objOwnerInfo.BankAccountsInfo != null){
                this.setState({ ownerBankAccounts: this.state.objOwnerInfo.BankAccountsInfo});
            }
            if (this.state.objOwnerInfo.bypassValidation) {
                this.saveAll(false);
            } else {
                this.setState({ loading: true });
                const textToSearch = this.state.objOwnerInfo.customerType === "Personal" ?
                `${this.state.objOwnerInfo.firstName} ${this.state.objOwnerInfo.lastName}` : this.state.objOwnerInfo.companyName;
                const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Owner', this.state.objOwnerInfo.ownerID);
                this.setState({ loading: false });
                if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
                    this.saveAll(false);
                } else {
                    this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Owner in Watch Lists. The Owner will be saved with status "Suspicious"' });
                }
            }
        }
        else {
            if (this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadOwner: false,
                    notFirstLoad: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadOwner: false
                });
            }
        }
    }

    showAlertAfterPropertiesValidation = () => {
        this.setState({ loading: false });
    }

    saveAll = async (isSuspicious) => {
        let UserID = localStorage.getItem('UserID');
        var objFilter = {
            OwnerID: this.state.objOwnerInfo.ownerID === '' ? 0 : this.state.objOwnerInfo.ownerID,
            CustomerId: this.props.customerID,
            //StatusOwner: this.state.objOwnerInfo.status,
            StatusOwner: isSuspicious ? "Suspicious" : this.state.objOwnerInfo.status,
            OwnerType: this.state.objOwnerInfo.ownerType,
            BypassValidation: this.state.objOwnerInfo.bypassValidation,
            Email: this.state.objOwnerInfo.contactEmail != null ? this.state.objOwnerInfo.contactEmail : '',
            Phone: this.state.objOwnerInfo.contactPhone != null ? this.state.objOwnerInfo.contactPhone : '',
            EmailIndicator: this.state.objOwnerInfo.emailIndicator,
            DetailsOfPayment: this.state.objOwnerInfo.detailsPayments != null ? this.state.objOwnerInfo.detailsPayments : '',
            ExpiryByPassDate: this.state.objOwnerInfo.expiryByPassDate != null ? this.state.objOwnerInfo.expiryByPassDate : '',
            IndustrySector: this.state.objOwnerInfo.industrySector != null ? this.state.objOwnerInfo.industrySector : '',
            ReferencesOfPayment: this.state.objOwnerInfo.paymentReference != null ? this.state.objOwnerInfo.paymentReference : '',
            ReceiveLimit: this.state.objOwnerInfo.limit,
            PreferredMethodOfPayment: this.state.objOwnerInfo.preferredPaymentIns,
            PreferredDeliveryMethod: this.state.objOwnerInfo.preferredDeliveryMet,
            PaymentFlow: this.state.objOwnerInfo.paymentFlow,
            Salutation: this.state.objOwnerInfo.salutation !== null ? this.state.objOwnerInfo.salutation : '',
            Name: this.state.objOwnerInfo.companyName,
            ContactName: this.state.objOwnerInfo.contactName,
            FirstName: this.state.objOwnerInfo.firstName !== null ? this.state.objOwnerInfo.firstName : '',
            LastName: this.state.objOwnerInfo.lastName !== null ? this.state.objOwnerInfo.lastName : '',
            ShortName: this.state.objOwnerInfo.shortName !== null ? this.state.objOwnerInfo.shortName : '',
            UpdateToken: this.state.objOwnerInfo.updateToken,
            UserId: UserID,
            CustomerRelationRisk: this.state.objOwnerInfo.customerRelationRisk != null ? this.state.objOwnerInfo.customerRelationRisk : 0,
            PersonID: this.state.selectOwner.PersonID,

            Address: this.state.objAddress !== null ? {
                ...this.state.objAddress,
                Address2: this.state.objAddress.Address2 !== null ? this.state.objAddress.Address2 : '',
                POBox: this.state.objAddress.POBox !== null ? this.state.objAddress.POBox : '',
                SinceWhen: this.state.objAddress.SinceWhen !== null ? this.state.objAddress.SinceWhen : '',
            } : {},
            BankAccounts: null
        }
        if (this.state.selectOwner != null) {
            if (this.state.selectOwner.Address != null) {
                objFilter.Address.AddressID = this.state.selectOwner.Address[0] != null ? this.state.selectOwner.Address[0].AddressID : (this.state.selectOwner.Address?.AddressID ?? 0)
            } else {
                objFilter.Address.AddressId = 0
            }
        } else {
            objFilter.Address.AddressId = 0
        }
        objFilter.Address.UpdateToken = this.state.objAddress != null ? this.state.objAddress.UpdateToken : undefined;
        objFilter.Address.UpdatedBy = UserID;
        objFilter.Address.PersonID = this.state.selectOwner.PersonID;
        this.setState({ selectOwner: objFilter });
        if ((this.state.objOwnerInfo.isEmailValid && this.state.objOwnerInfo.contactEmail !== '') || (!this.state.objOwnerInfo.isEmailValid && this.state.objOwnerInfo.contactEmail === '')) {
            this.setState({ loading: true });
            saveOwner(objFilter).then(
                (json) => {
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to save the owner. Please try again and complete the required fields(*).', loading: false });
                    } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409) {
                        this.setState({ showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false });
                    } else {
                        let newOwner = true;
                        if (objFilter.OwnerID !== 0) {
                            newOwner = false;
                        }
                        this.state.selectOwner.OwnerID = json.ownerID;
                        this.state.selectOwner.UpdateToken = json.updateToken;
                        if (newOwner) {
                            this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Owner successfully saved.', loading: false }, () => this.updatePage(json.ownerID, json.updateToken, json.updateTokenAddress, true))
                        } else {
                            this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Owner successfully saved.' }, () => this.updatePage(json.ownerID, json.updateToken, json.updateTokenAddress, false))
                        }

                    }
                }
            );
            this.setState({ pressedButton: true });
        } else {
            this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadOwner: false });
        }
    }
    isValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadOwner: true,
                ownerInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                ownerInfoErrors: { tab: "Owner Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true });
    }

    handleReturnToOwner() {
        this.setState({ viewHistory: false });
    }

    handleResetAnswersClick() {

    }

    handleSendOAEmailClick() {

    }

    closeAlert() {
        this.setState({ showAlert: false });
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadOwner: true, notFirstLoad: true });
    }
    formatVisibilityNew(ownerID) {
        if (ownerID === '' || ownerID === null) { return "visible"; }
        else { return "hidden"; }
    }
    formatVisibilityUpdate(ownerID) {
        if (ownerID === '' || ownerID === null) { return "hidden"; }
        else { return "visible"; }
    }

    //OBJ FILTERS
    objFilterOwnerInfo(objFilter) {
        this.setState({ objOwnerInfo: objFilter });
    }

    objFilterOwnerAddress(objFilterA) {
        this.setState({ objAddress: objFilterA });
    }

    objFilterOwnerAddProperties(value) {
        this.setState({ saveProperties: value });
    }

    objFilterOwnerAddTasks(value) {
        this.setState({ saveTasks: value });
    }

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    formatVisibilityButton() {
        if (this.state.activeTab === "account-information") { return "hidden"; }
        else { return "visible"; }
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false, objOwnerInfo: {...this.state.objOwnerInfo, status: 'Suspicious'}, selectOwner: {...this.state.selectOwner, status: 'Suspicious'} });
        await this.saveAll(true);
    }

    refreshOwner = async () => {
        const json = await getOwnerByID(this.props.ownerID);
        if (json != null) {
            this.setState({ selectOwner: json.owner[0] })
            if (this.props.selectedOwner.Address != null) {
                var objAdd = this.props.selectedOwner.Address[0];
                this.setState({ objAddress: objAdd });
            }    
            if (this.props.readOnly !== undefined) {
                this.setState({
                    isReadOnly: this.props.readOnly
                })
            }
        }
    };

    refreshContact = async () => {
        this.setState({ toggleReloadGlobalSearch: !this.state.toggleReloadGlobalSearch });
    };

    //ownerID={this.props.ownerID} selectedOwner={this.state.ownerList} type={this.state.type}
    render() {
        var selectOwner = {}
        selectOwner.PersonID = this.props.selectedOwner.PersonID
        selectOwner.CustomerID = this.props.selectedOwner.OwnerID
        selectOwner.DealStatus = null
        selectOwner.OwnerID = this.props.ownerID;

        var section = (
            <h3 className="uk-heading-divider component-title" style={{ visibility: this.formatVisibilityUpdate(this.props.ownerID) }} >
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                    <i className="fa fa-arrow-left back-arrow"></i>
                </button>
                Owner - {this.props.selectedOwner.Name} - {this.props.ownerID}
            </h3>
        );
        if (this.formatVisibilityNew(this.props.ownerID) == "visible") {
            section = (
                <h3 className="uk-heading-divider component-title" style={{ visibility: this.formatVisibilityNew(this.props.ownerID) }} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left back-arrow"></i>
                    </button>
                    New Owner
                </h3>
            );
        }
        
        var ownerTitle = '';
        if (this.props.mainTitle != null) {
            ownerTitle = (
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>);
        }
        if (this.state.updateOwner) {
            return <OwnersScreen id={this.props.customerID} data={this.props.customerName} />
        }
        if (this.state.viewHistory) {
            return <AuditOwnerScreen backLink={this.handleReturnToOwner.bind(this)} ownerID={this.props.ownerID} />
        }
        return (
            <fieldset disabled={this.state.isReadOnly}>
                <div>
                    {ownerTitle}
                    <div>
                        {/*section*/}
                        <ul uk-tab="">
                            <li>
                                <a href="#" id="owner-information" onClick={() => this.onClickTab('owner-information')}>Owner Information</a>
                            </li>
                            <li>
                                <a href="#" id="address-information" onClick={() => this.onClickTab('address-information')}>Address</a>
                            </li>
                            <li>{/*class="uk-disabled"*/}
                                <a href="#" id="additional-properties" onClick={() => this.onClickTab('additional-properties')}>Additional Properties</a>
                            </li>
                            <li>
                                <a href="#" id="compliance" onClick={() => this.onClickTab('compliance')}>Compliance</a>
                            </li>
                            <li>
                                <a href="#" id="tasks-list" onClick={() => this.onClickTab('tasks-list')}>Tasks List</a>
                            </li>
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            <li>
                                <OwnerInformationForm clearFields={this.props.isClearOwner} newOrUpdate={this.formatVisibilityNew(this.props.ownerID)} isValid={this.isValidated.bind(this)} updateFunction={this.updatePage.bind(this)} selectedOwner={this.state.selectOwner} ownerID={this.props.ownerID} customerName={this.props.customerName} objFilterOwnerInfo={this.objFilterOwnerInfo.bind(this)} notFirstLoad={this.state.notFirstLoadOwner} refreshContact={this.refreshContact} />
                            </li>
                            <li>
                                <AddressForm clearFields={this.props.isClearAdd} selectedAddress={this.state.objAddress} getAddressInfo={this.objFilterOwnerAddress.bind(this)} isValid={this.isValidatedAddress.bind(this)} validations={this.state.notFirstLoad} isCustomer={false} />
                            </li>
                            <li>
                                <OwnerAdditionalProperties getSaveProperties={this.objFilterOwnerAddProperties.bind(this)} ownerID={this.props.ownerID} saveProperties={this.state.saveProperties} showFinalAlert={this.showAlertAfterPropertiesValidation} />
                            </li>
                            <li>
                                <OwnerCompliance customer={selectOwner} reloadGlobalSearch={this.state.toggleReloadGlobalSearch} objOwnerInfo={this.state.objOwnerInfo} objFilterOwnerInfo={this.objFilterOwnerInfo.bind(this)} />
                            </li>
                            <li>
                                <OwnerTasks getSaveTasks={this.objFilterOwnerAddTasks.bind(this)} ownerID={this.props.ownerID} personID={this.state.selectOwner.PersonID} saveTasks={this.state.saveTasks} />
                            </li>
                        </ul>
                    </div>

                    <div style={{ visibility: this.formatVisibilityButton() }}>
                        {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>*/}
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick} type="button">Save</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">View History</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleBackLink.bind(this)} type="button">Exit</button>
                    </div>
                    <AlertBox
                        open={this.state.showAlert}
                        onClose={this.closeAlert.bind(this)}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        type="Ok"
                        okClick={this.closeAlert.bind(this)}
                    />
                    <AlertBox
                        open={this.state.showAlertError}
                        onClose={this.closeAlertError.bind(this)}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        messageBoldList={[this.state.ownerInfoErrors, this.state.addressErrors]}
                        type="Ok"
                        okClick={this.closeAlertError.bind(this)}
                    />
                    <AlertBox
                        open={this.state.showAlertSaveSuspicious}
                        onClose={this.closeAlertSaveSuspicious}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />

                    <LoadingBox loading={this.state.loading} />
                </div>
            </fieldset>
        );
    }
}
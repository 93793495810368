import React from 'react';
import { saveOwner, getOwnerByID } from '../../../../networking/NetworkingOwners';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import AddressForm from '../../addresses/AddressForm';
import AddOwnerInformationForm from './AddOwnerInformationForm';


var style = getStyle();

export default class AddOwnerScreen extends React.Component {
    state = {
        ownerID       : 0,
        updateToken         : 0,
        isValidated         : true,
        isValidatedAddress  : true,
        ownerInfoErrors      : [],
        addressErrors       : [],
        notFirstLoad        : true,
        notFirstLoadOwner    : true,
        showAlert           : false,
        showAlertError      : false,
        showAlertSaveSuspicious: false,
        alertTitle          : '',
        alertMessage        : '',
        loading             : false,
        updateOwner   : false,
        pressedButton       : false,
        saveProperties      : false,
        saveTasks           : false,
        selectOwner          : {},
        viewHistory         : false,
        isClearAdd          : false,
        isClearOwner         : false,        
        activeTab: "owner-information",
        //OBJ FILTERS
        objOwnerInfo         : {},
        objAddress          : {},
        objBankAccount      : {},

        objJson             : [],
        //Attachments
        showAttachmentsDialog : false
    }

    componentDidMount() {
    }

    updatePage(ownerID, updateToken, updateTokenAddress, newOwner) {
        if(newOwner){
            this.setState({showAlert: newOwner});
        }
        var objOwnerInfo = this.state.objOwnerInfo
        var objAdd = this.state.objAddress
        getOwnerByID(ownerID, 1).then(
            (json) => {
                console.log(json)
                objOwnerInfo.ownerID = ownerID
                objOwnerInfo.updateToken = updateToken
                objAdd.AddressID = json.owner[0].Address[0].AddressID
                objAdd.UpdateToken = updateTokenAddress
                this.setState({ objOwnerInfo: objOwnerInfo, objAddress: objAdd, objJson:json.owner })
            }
        );
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            notFirstLoad: true,
            notFirstLoadOwner: true,
            showAlert: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            updateOwner: false,
            pressedButton: false,
            saveProperties: false,
            saveTasks: false,
            selectOwner: null,
            viewHistory: false,
            objOwnerInfo: {},
            objAddress: null,
            objBankAccount: {}
        }, () => this.props.isNewClearOwner())
    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick = async () => {
        if (this.state.isValidated && this.state.isValidatedAddress) {
            if (this.state.objOwnerInfo.bypassValidation) {
                this.saveAll(false);
            } else {
                this.setState({ loading: true });
                const textToSearch = this.state.objOwnerInfo.ownerType === "Individual" ?
                    `${this.state.objOwnerInfo.firstName} ${this.state.objOwnerInfo.lastName}` : this.state.objOwnerInfo.companyName;
                const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Owner', 0);
                this.setState({ loading: false });
                if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
                    this.saveAll(false);
                } else {
                    this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Owner in Watch Lists. The Owner will be saved with status "Suspicious"' });
                }
            }
        } else {
            if (this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadOwner: false,
                    notFirstLoad: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadOwner: false
                });
            }
        }
    }

    showAlertAfterPropertiesValidation = () => {
        this.setState({ showAlert: true});
    }

    saveAll(isSuspicious) {
        let UserID = localStorage.getItem('UserID');
        var objFilter = {
            OwnerID: 0,
            CustomerID: this.props.customerID,
            StatusOwner: isSuspicious ? 'Suspicious' : this.state.objOwnerInfo.status,
            OwnerType: this.state.objOwnerInfo.ownerType,
            BypassValidation: this.state.objOwnerInfo.bypassValidation,
            ExpiryByPassDate: this.state.objOwnerInfo.expiryByPassDate,
            Salutation: this.state.objOwnerInfo.salutation!==undefined?this.state.objOwnerInfo.salutation:'',
            Name: this.state.objOwnerInfo.companyName,
            FirstName: this.state.objOwnerInfo.firstName,
            LastName: this.state.objOwnerInfo.lastName,
            ShortName: this.state.objOwnerInfo.shortName,
            Email:this.state.objOwnerInfo.contactEmail,
            Phone: this.state.objOwnerInfo.contactPhone,
            ProcessStatus: '',
            UpdateToken: this.state.objOwnerInfo.updateToken,
            UserID: UserID,
            PersonID: this.state.objOwnerInfo.personID,

            Address: this.state.objAddress!==null?this.state.objAddress:{},
            BankAccounts: null
        }
        if (this.state.selectOwner != null) {
            if (this.state.selectOwner.Address != null) {
                objFilter.Address.AddressID = this.state.selectOwner.Address[0] != null ? this.state.selectOwner.Address[0].AddressID : (this.state.selectOwner.Address?.AddressID ?? 0)
            } else {
                objFilter.Address.AddressId = 0
            }
        } else {
            objFilter.Address.AddressId = 0
        }
        objFilter.Address.UpdateToken = this.state.objAddress != null ? this.state.objAddress.UpdateToken : undefined;
        objFilter.Address.UpdatedBy = UserID;
        objFilter.Address.PersonID = this.state.objOwnerInfo.personID;
        this.setState({ selectOwner: objFilter });
        if (this.state.objOwnerInfo.isEmailValid && this.state.objOwnerInfo.contactEmail !== '' || !this.state.objOwnerInfo.isEmailValid && this.state.objOwnerInfo.contactEmail === '') {
            this.setState({ loading: true });
            saveOwner(objFilter).then(
                (json) => {
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to save the owner. Please try again and complete the required fields(*).', loading: false });
                    } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409){
                        this.setState({ showAlertError: true, alertTitle: 'Error' , alertMessage: json.httpErrorMessage, loading: false });
                    } else {
                        this.state.selectOwner.OwnerID = json.ownerID;
                        this.state.selectOwner.UpdateToken = json.updateToken;
                        this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Owner successfully saved.', loading: false }, () => this.updatePage(json.ownerID, json.updateToken, json.updateTokenAddress, true))
                    }
                }
            );
            this.setState({ pressedButton: true });
        } else {
            this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadOwner: false });
        }
    }
    isValidated = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadOwner: true,
                ownerInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                ownerInfoErrors: { tab: "Owner Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true });
    }

    handleReturnToOwner() {
        this.setState({ viewHistory: false });
    }

    handleAttachmentsClick() {
        this.setState({
            showAttachmentsDialog: true
        });  
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' }, () => this.props.selectedOwner(this.state.objJson[0]));
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadOwner: true, notFirstLoad: true });
    }
    formatVisibilityNew(ownerID) {
        if (ownerID === '' || ownerID === null) { return "visible"; }
        else { return "hidden"; }
    }
    formatVisibilityUpdate(ownerID) {
        if (ownerID === '' || ownerID === null) { return "hidden"; }
        else { return "visible"; }
    }

    //OBJ FILTERS
    objFilterOwnerInfo(objFilter) {
        this.setState({ objOwnerInfo: objFilter });
    }

    objFilterOwnerAddress(objFilterA) {
        this.setState({ objAddress: objFilterA });
    }

    objFilterOwnerAddProperties(value) {
        this.setState({ saveProperties: value });
    }

    objFilterOwnerAddTasks(value) {
        this.setState({ saveTasks: value });
    }

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    formatVisibilityButton() {
        if (this.state.activeTab==="account-information") { return "hidden"; }
        else { return "visible"; }
    }
    
    onCloseAttachmentsDialog = ()=>
    {
        this.setState({
            showAttachmentsDialog: false,
        })
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false });
        await this.saveAll(true);
    }

    render() {
        return (
            <div>
                <div>
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="owner-information" onClick={() => this.onClickTab('owner-information')}>Owner Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information" onClick={() => this.onClickTab('address-information')}>Address</a>
                        </li>
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <AddOwnerInformationForm isValid={this.isValidated.bind(this)} updateFunction={this.updatePage.bind(this)} customerName={this.props.customerName} objFilterOwnerInfo={this.objFilterOwnerInfo.bind(this)} notFirstLoad={this.state.notFirstLoadOwner} />
                        </li>
                        <li>
                            <AddressForm clearFields = {this.props.isClearAdd} selectedAddress={this.state.objAddress} getAddressInfo={this.objFilterOwnerAddress.bind(this)} isValid={this.isValidatedAddress.bind(this)} validations={this.state.notFirstLoad} isCustomer = {false}/>
                            {/*<OwnerAddressForm updateFunction={this.updatePage.bind(this)} selectedOwnerAddress={this.props.selectedOwner.Address}  ownerID={this.props.ownerID} objFilterOwnerAddress={this.objFilterOwnerAddress.bind(this)}/>*/}
                        </li>
                    </ul>
                </div>
                
                <div style={{visibility:this.formatVisibilityButton()}}>
                    {/* <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button> */}
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleBackLink.bind(this)} type="button">Exit</button>
                </div>
                <AlertBox 
                    open={this.state.showAlert} 
                    onClose={this.closeAlert.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    type="Ok" 
                    okClick={this.closeAlert.bind(this)}
                />
                <AlertBox
                    open={this.state.showAlertSaveSuspicious}
                    onClose={this.closeAlertSaveSuspicious}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
                <AlertBox 
                    open={this.state.showAlertError} 
                    onClose={this.closeAlertError.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    type="Ok" 
                    messageBoldList={[this.state.ownerInfoErrors, this.state.addressErrors]}
                    okClick={this.closeAlertError.bind(this)}
                />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}
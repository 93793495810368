import React from 'react';
import { ActionName } from '../../constants/ActionNames';
//import DepositInfoScreen from '../back_office/deposits/DepositInfoScreen';
import DepositsScreen from '../back_office/deposits/DepositsScreen';
import DealInformationScreen from '../deal/DealInformationScreen';
import IncomingPaymentForm from '../deal/IncomingPaymentForm';
import OtherSettlementFunds from '../deal/OtherSettlementFunds';
import PaymentScreen from '../deal/PaymentScreen';
import ComplaintScreen from '../maintenance/complaints/ComplaintScreen';
import CountryAdditionalSettingsScreen from '../maintenance/country/CountryAdditionalSettingsScreen';
import OutgoingFundsScreen from '../outgoingFunds/OutgoingFundsScreen';
import BeneficiaryMaintenanceScreen from '../pre_trade/customers/beneficiaries/BeneficiaryMaintenanceScreen';
import CustomersScreen from '../pre_trade/customers/CustomersScreen';

export function getScreen(action, id, handleBack, name, activeTab, beneObj, mainTitle, canUpdateCustomerProcessStatus=false) {    

    switch (action) {
        case ActionName.EditCustomerInfo: return (<CustomersScreen mainTitle={mainTitle} backLink={handleBack} customerID={id} customerName={beneObj} canUpdateCustomerProcessStatus={canUpdateCustomerProcessStatus} updateCustomer={true} fromBoard={true} />);
        case ActionName.ShowCustomerInfo: return (<CustomersScreen mainTitle={mainTitle} backLink={handleBack} customerID={id} customerName={beneObj} canUpdateCustomerProcessStatus={canUpdateCustomerProcessStatus} updateCustomer={true} fromBoard={true}/>);
        case ActionName.ShowDealInfo: return (<DealInformationScreen mainTitle={mainTitle} backLink={handleBack} data={id} />);
        case ActionName.ShowDealInfoScreen: return (<DealInformationScreen mainTitle={mainTitle} backLink={handleBack} data={id} activeTab={activeTab} />);
        case ActionName.ShowPaymentInfo: return (<IncomingPaymentForm mainTitle={mainTitle} backLink={handleBack} data={id} paymentOpenFromBoard={true} />);
        case ActionName.ShowCustomerPaymentScreen: return (<PaymentScreen mainTitle={mainTitle} backLink={handleBack} data={0} />);
        case ActionName.ShowDepositInfo: return (<DepositsScreen mainTitle={mainTitle} backLink={handleBack} data={id} />);
        case ActionName.AddDeposit: return (<DepositsScreen mainTitle={mainTitle} backLink={handleBack} data={0} />);
        case ActionName.ShowOtherSettlementFunds: return (<OtherSettlementFunds mainTitle={mainTitle} backLink={handleBack} customerID={id} customerName={name} openFromHeldfundBoard={true} />)
        case ActionName.ShowCountryAdditionalSettings: return (<CountryAdditionalSettingsScreen mainTitle={mainTitle} backLink={handleBack} data={id} />)
        case ActionName.ShowOutgoingFundsScreen: return (<OutgoingFundsScreen mainTitle={mainTitle} dealID={id !== undefined?id.split('-')[1]:0} backLink={handleBack} readOnly={false} approvalBoard={true} />);
        case ActionName.ShowComplaintInfo: return (<ComplaintScreen mainTitle={mainTitle} data={id} backLink={handleBack} />)
        case ActionName.ShowBeneficiaryInfo:
            ////debugger
            return(
                <BeneficiaryMaintenanceScreen 
                    mainTitle            = {mainTitle}
                    beneID               = {id} 
                    selectedBene         = {beneObj.selectedBene}
                    type                 = {beneObj.type}
                    customerID           = {beneObj.customerID}
                    customerName         = {beneObj.customerName}
                    backLink             = {handleBack}
                    updateFunctionOrigin = {handleBack}
                />
            );
        case ActionName.EditBeneficiaryInfo:

            return (
                <BeneficiaryMaintenanceScreen
                    mainTitle={mainTitle}
                    beneID={id}
                    selectedBene={beneObj.selectedBene}
                    type={beneObj.type}
                    customerID={beneObj.customerID}
                    customerName={beneObj.customerName}
                    backLink={handleBack}
                    updateFunctionOrigin={handleBack}
                />
            );
        default: return (undefined);
    }

}
import React from 'react';
import AttachmentsDefinitionMaintenance from '../attachments_definition/AttachmentsDefinitionMaintenance';
import ExtendedPropertiesMaintenance from '../extended_properties/ExtendedPropertiesMaintenance';

export default class RegistrationSettingsScreen extends React.Component {
    state = {
        activeTab: 'extendedProperties'
    };

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    };

    render() {
        var classNameExtendedProperties = '',
            classNameAttachmentDefinition = '';

        var currentScreen;
        switch (this.state.activeTab) {
            case 'extendedProperties':
            default:
                classNameExtendedProperties = 'uk-active';
                currentScreen = <ExtendedPropertiesMaintenance />;
                break;
            case 'attachmentsDefinition':
                classNameAttachmentDefinition = 'uk-active';
                currentScreen = <AttachmentsDefinitionMaintenance />;
                break;
        }

        return (
            <div>
                <ul className="uk-tab">
                    <li className={classNameExtendedProperties}>
                        <a onClick={() => this.onClickTab('extendedProperties')}>Extended Properties</a>
                    </li>
                    <li className={classNameAttachmentDefinition}>
                        <a onClick={() => this.onClickTab('attachmentsDefinition')}>Attachments Definition</a>
                    </li>
                </ul>
                {currentScreen}
            </div>
        );
    }
}

import React from 'react';
import AuditEntity from './AuditEntity';

const AuditEntityPropertyScreen = ({ entityID, entityType}) => {

    return (
        <div>
            <AuditEntity entityID={entityID} entityType={entityType} categoryName={"Audit - Entity Properties"} />
        </div>
    );
}

export default AuditEntityPropertyScreen;

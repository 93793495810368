import React from 'react';
import CardBeneficiary from './CardBeneficiary';
import { getBeneficiariesCardView } from '../../../../../networking/NetworkingBeneficiaries';
import './CardBoardBeneficiaries.scss';

export default class CardBoard extends React.Component {
    state = {
        data:[],
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        confirmationAlertTitle: '',
        confirmationAlertMessage: '',
        showConfirmAlert: false,
        customerIDFilter: '',        
        beneficiaryIDFilter: '',
        beneficiaryNameFilter: '',
        customerList: [],
        //
        changeScreen: false,
        nextScreen: '',
        tab: '',
        id: 0
    }

    componentDidMount = () => {
        this.getData();
        /*
            Status: "Disabled",
            beneficiaries: Array(1)
                0:
                    Beneficiary ID: 13703
                    BeneficiaryType: "Corporate"
                    Created on: "Created on: 2020/08/26"
                    Customer: "IKEA (ML)(9645)"
                    Name: "YKR Ltd"
        */
    }

    getData = () => {
        getBeneficiariesCardView(this.state.customerIDFilter, this.state.beneficiaryIDFilter, this.state.beneficiaryNameFilter).then((response) => {
            if(response && response.httpStatusCode === 200){
                this.setState({data: response.data});
            }
        })
    }

    getCustomerIDFilter = (event) => {
        this.setState({customerIDFilter: event.target.value});
    }

    getBeneficiaryIDFilter = (event) => {
        this.setState({beneficiaryIDFilter: event.target.value});
    }

    getBeneficiaryNameFilter = (event) => {
        this.setState({beneficiaryNameFilter: event.target.value});
    }

    clearFilter = () => {
        this.setState({customerIDFilter: '', beneficiaryIDFilter: '', beneficiaryNameFilter: ''}, ()=>{
            this.getData();
        });
    }

    render = () => {
        ////debugger
        let columns = [];
        let columnTitle = "";
        if(this.state.data){
            this.state.data.forEach((element, i) => {
                let elementCards = [];
                if (element.beneficiaries){
                    element.beneficiaries.forEach((elementChild, index) => {
                        elementCards.push(<CardBeneficiary key={1000 + index} element={elementChild} onClickElement={this.props.onClickElementBoard}/>);
                    });
                }
                if(elementCards.length > 0){
                    columnTitle = `${element["Status"]} (${elementCards.length})`;
                } else {
                    columnTitle = element["Status"];
                }
                let elementColumn = (
                    <div className="uk-width-1-5 board-column" key={10 + i}>
                        <div className="board-column-title"><h4>{columnTitle}</h4></div>
                        <div className="cards-column">{elementCards}</div>
                    </div>
                );
                columns.push(elementColumn);
            });
        }

        return(
            <div>
                <div>
                    <form className="uk-form-stacked uk-grid">
                        <div className="uk-width-1-5" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-customer">Customer</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-board-deal" value={this.state.customerIDFilter} type="text" placeholder="Customer ID or Name" onChange={this.getCustomerIDFilter}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-5" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-customer">Beneficiary</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-board-deal" value={this.state.beneficiaryIDFilter} type="text" placeholder="Beneficiary ID or Name" onChange={this.getBeneficiaryIDFilter}/>
                                </div>
                            </div>
                        </div>
                        {/*<div className="uk-width-1-5">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-deal">Beneficiary Name</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-board-deal" value={this.state.beneficiaryNameFilter} type="text" placeholder="Beneficiary Name" onChange={this.getBeneficiaryNameFilter}/>
                                </div>
                            </div>
                        </div>*/}
                        <div className="uk-width-1-5">
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.getData()}>Apply Filter</button>
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.clearFilter()}>Clear Filter</button>
                        </div>
                    </form>
                </div>
                <div className="board">
                    {columns}
                </div>
            </div>
        );
    }
}

import {genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody, genericGetWithParametersNoEncrypted} from './Networking';

export async function searchBankCustomers(query){
    try{
        let params = {
            'TextToSearch': query,
            'CustomerType': 'Bank'
        };
        let response = await genericGetWithParameters('CustomerSearch/SearchByMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBanks(page, items){
    try {
        let params = {
            'NameLike': '',
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Bank/GetList',params,true);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBank(id){
    try {
        let params = {
            'bankId': id
        }

        let response = await genericGetWithParameters('Bank/Get',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteBank(id){
    try{
        let params = {
            'bankId': id
        };
        let response = await genericDeleteWithParameters('Bank/Delete',params);
        ////debugger
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBank(model){
    var method = '';
    var apiName = '';
    if (model.BankID === 0){
        method = 'POST';
        apiName = 'Bank/Post'

    } else {
        method = 'PUT';
        apiName = 'Bank/Put'
    }
    
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function searchBanks(query){
    try {
        let params = {
            'bank': query
        }

        let response = await genericGetWithParameters('Bank/SearchBankByNameMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListOfBanks(isBank){
    try {
        let params = {
            'isBank': isBank
        }

        let response = await genericGetWithParameters('Bank/GetListOfBanks_min', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateIban(ibanCode){
    try {
        let method = 'GET';
        let apiName = 'Bank/ValidateIBAN'
        let params = {
            'ibanCode': ibanCode
        }

        let response = await genericGetWithParametersNoEncrypted(apiName, params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

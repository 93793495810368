import React from 'react';
import Card from './Card';

import AlertBox from '../../../shared/AlertBox'
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import DatePicker from '../../../shared/DatePicker';


import { searchCustomerByIdNameMin } from '../../../../networking/NetworkingCustomers';
import {getOutstandingDealsByStatus} from '../../../../networking/NetworkingDeals';
import {confirmDealSettlements} from '../../../../networking/NetworkingPayments';
import {confirmDealDisbursements} from '../../../../networking/NetworkingFunds';

import {formatToCurrencyWithScale, formatDate} from '../../../../helpers/FormatHelper';

import './CardBoard.scss';
//import { ActionName } from '../../../../constants/ActionNames';



export default class CardBoard extends React.Component {
    state = {
        data:[],
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        confirmationAlertTitle: '',
        confirmationAlertMessage: '',
        showConfirmAlert: false,
        customerIDFilter: 0,
        dealNumberFilter: '',
        //sadfsdf
        valueDateFilter : '',
        customerList: [],
        //
        changeScreen: false,
        nextScreen: '',
        tab: '',
        id: 0
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        let dealNumber = 0;
        let customerID = 0;
        if(this.state.dealNumberFilter !== ''){
            dealNumber = parseInt(this.state.dealNumberFilter);
        }
        if(this.state.customerIDFilter !== ''){
            customerID = parseInt(this.state.customerIDFilter);
        }
        getOutstandingDealsByStatus(dealNumber,customerID, this.state.valueDateFilter).then((response) => {
            if(response && response.httpStatusCode === 200){
                this.setState({data: response.data});
            }
        })
    }

    customActions = (action, id, screen, tab, element) => {
        switch (action) {
            case 'confirmSettlements':
                this.handleConfirmDealSettlements(id, screen, tab, element)
                break;
            case 'confirmDisbursements':
                this.handleConfirmDealDisbursements(id, screen, tab)
                break;
            default:
        }
    }

    handleConfirmDealSettlements = (id, screen, tab, element, createNewSettlement) => {
        console.log(element)
        let userId = localStorage.getItem('UserID')

        confirmDealSettlements(id, userId, createNewSettlement).then((response) => {
            console.log(response)
            let changeScreen = false
            if (response.httpStatusCode !== 200) {
                if (Number(response.settlementsCount) === 0) {
                    let confirmationMessage = []
                    console.log(response.errorMessage)
                    confirmationMessage.push('No settlement information found for this deal. Settlement for an amount of '
                        + formatToCurrencyWithScale(element['Buy Amount'], element['Buy CCY']) + ' ' + element['Buy CCY'] + ' will be created. Do you want to proceed?')
                    this.setState({
                        showConfirmAlert: true,
                        confirmationAlertTitle: 'Warning',
                        confirmationAlertMessage: confirmationMessage,
                        nextScreen: screen,
                        tab: tab,
                        id: id
                    })
                } else {
                    changeScreen = true
                    this.setState({
                        showAlert: true,
                        alertMessage: response.errorMessage || 'An error occurred while trying to confirm the settlements. Please try again.',
                        alertTitle: 'Error',
                        changeScreen: changeScreen,
                        nextScreen: screen,
                        tab: tab,
                        id: id
                    })
                }
            } else {
                let message = 'Deal Settlements Confirmed Successfully.'
                let alertTitle = 'Success'
                if (response.dealConfirmationMessage !== null && response.dealConfirmationMessage !== "") {
                    alertTitle = 'Warning'
                    message = response.dealConfirmationMessage
                    message = message.split("\n").map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        )
                    })
                    changeScreen = true
                }

                this.setState({
                    showAlert: true,
                    alertMessage: message,
                    alertTitle: alertTitle,
                    changeScreen: changeScreen,
                    nextScreen: screen,
                    tab: tab,
                    id: id
                }, () => this.getData())
            }
        })
    }

    handleConfirmDealDisbursements = (id, screen, tab) => {
        let userId = localStorage.getItem('UserID')

        confirmDealDisbursements(id, userId).then((response) => {
            console.log(response)
            let changeScreen = false
            if (response.httpStatusCode !== 200) {
                changeScreen = true
                this.setState({
                    showAlert: true,
                    alertMessage: response.errorMessage || 'An error occurred while trying to confirm the disbursements. Please try again.',
                    alertTitle: 'Error',
                    changeScreen: changeScreen,
                    nextScreen: screen,
                    tab: tab,
                    id: id
                })
            } else {
                let message = 'Deal Disbursement Confirmed Successfully.'
                let alertTitle = 'Success'
                if (response.dealConfirmationMessage !== null && response.dealConfirmationMessage !== "") {
                    alertTitle = 'Warning'
                    message = response.dealConfirmationMessage
                    message = message.split("\n").map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        )
                    })
                    changeScreen = true
                }

                this.setState({
                    showAlert: true,
                    alertMessage: message,
                    alertTitle: alertTitle,
                    changeScreen: changeScreen,
                    nextScreen: screen,
                    tab: tab,
                    id: id
                }, () => this.getData())
            }
        })
    }

    closeAlert = () => {
        if (this.state.changeScreen) {
            this.props.onChangeScreen(this.state.nextScreen, this.state.id, this.state.tab)
        }
        this.setState({
            showAlert: false,
            saveInPendingStatus: false,
            changeScreen: false,
            id: 0,
            tab: ''
        });
    }

    noConfirmAlert = () => {
        this.setState({ showConfirmAlert: false });
    }

    yesConfirmAlert = () => {
        this.setState({ showConfirmAlert: false }, 
            () => this.handleConfirmDealSettlements(this.state.id, this.state.nextScreen, this.state.tab, {}, true))
    }

    getCustomerIDFilter = (event) => {
        this.setState({customerIDFilter: event.target.value});
    }

    getDealNumberFilter = (event) => {
        this.setState({dealNumberFilter: event.target.value});
    }

    handleUpdateCustomerIDName = (value) => {
        if(value){
            if (value.value) {
                this.setState({ customerIDName: value.name !== 'select' ? value.name : '', customerIDFilter: value.value !== 'select' ? value.value : '' });
            }
        }
    }

    searchCustomer = (query) =>  {
        console.group("searchCustomer");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for (var i = 0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({customerList: response.customers});
                }
            }
        )
    }

    clearFilter = () => {
        this.setState({customerIDFilter: '', dealNumberFilter: '', customerIDName: '', valueDateFilter: ''}, ()=>{
            this.getData();
        });
    }

    handleUpdateValueDateFilter(date){
        this.setState({valueDateFilter: formatDate(date)});
    }

    render = () => {
        let columns = [];
        let columnTitle = "";
        if(this.state.data){
            this.state.data.forEach((element, i) => {
                let elementCards = [];
                if(element.dh[0]['Deal No']){
                    element.dh.forEach((elementChild, index) => {
                        elementCards.push(<Card key={1000 + index} element={elementChild} onClickElement={this.props.onClickElementBoard} dealStatus={element["Deal Status"]}
                            customActions={this.customActions} />);
                    });
                }
                if(elementCards.length > 0){
                    columnTitle = `${element["Deal Status"]} (${elementCards.length})`;
                } else {
                    columnTitle = element["Deal Status"];
                }
                let elementColumn = (
                    <div className="uk-width-1-5 board-column" key={10 + i}>
                        <div className="board-column-title"><h4>{columnTitle}</h4></div>
                        <div className="cards-column">{elementCards}</div>
                    </div>
                );
                columns.push(elementColumn);
            });
        }

        

        return(
            <div>
                <div>
                    <form className="uk-form-stacked uk-grid">
                        <div className="uk-width-1-5" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-customer">Customer</label>
                                <div className="uk-form-controls">
                                <DynamicSearchSelect id="CustomerIDName" placeholder="Customer" searchFunction={this.searchCustomer} getValue={this.handleUpdateCustomerIDName} data={this.state.customerList} objValue={{ value: this.state.customerIDFilter, name: this.state.customerIDName }} />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-5">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-deal">Deal ID</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-board-deal" value={this.state.dealNumberFilter} type="text" placeholder="Deal ID" onChange={this.getDealNumberFilter}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-5">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-board-deal">Value Date</label>
                                <div className="uk-form-controls">
                                    <DatePicker 
                                        readOnly            = {true} 
                                        className           = 'uk-input' 
                                        value               = {this.state.valueDateFilter} 
                                        onDayChange         = {this.handleUpdateValueDateFilter.bind(this)}
                                        modeCustomArturo    = {true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-2-5">
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.getData()}>Apply Filter</button>
                            <button className="uk-button uk-button-green board-filter-button" type="button" onClick={()=>this.clearFilter()}>Clear Filter</button>
                        </div>
                    </form>
                </div>
                <div className="board">
                    {columns}
                    <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />
                    <AlertBox id="confirmation-alert"
                    open={this.state.showConfirmAlert}
                    type="Yes/No"
                    title={this.state.confirmationAlertTitle}
                    message={this.state.confirmationAlertMessage}
                    yesClick={this.yesConfirmAlert}
                    noClick={this.noConfirmAlert} />
                </div>
            </div>
        );
    }
}
import React, { useState, useEffect } from 'react';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import NumberInput from '../../shared/NumberInput';
import DynamicSelect from '../../shared/DynamicSelect';
import { getStyle } from '../../../styles/styles';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import { formatValidInput, formatValidSelect, formatToCurrency, getScaleByCurrency, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { searchCurrencies } from '../../../networking/NetworkingCurrencies';
import { getBlotterLimits, saveBlotterLimit, deleteBlotterLimit } from '../../../networking/NetworkingBlotters';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
import useCurrenciesList from './hooks/useCurrenciesList';
const style = getStyle();

const BlotterLimitsForm = (props) => {
    const blotter = props.selectedBlotterID;
    const [currency, setCurrency] = useState('');
    const [currencyName, setCurrencyName] = useState('');
    const [currencies] = useCurrenciesList();
    const [email, setEmail] = useState('');
    const [lowerLimit, setLowerLimit] = useState(0);
    const [upperLimit, setUpperLimit] = useState(0);
    const [updateToken, setUpdateToken] = useState('');
    const [validations, setValidations] = useState({blotter: true, currency: true, email:true, lowerLimit: true, upperLimit: true});
    const [pages, setPages] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const blotters = props.parentBlotters;

    const [blotterLimits, setBlotterLimits] = useState([]);
    const [blotterLimitReadyToDelete, setBlotterLimitReadyToDelete] = useState(null);

    const handleButtonSaveClick = async () => {
        if (validateFields()) {
            const model = {
                'BlotterID': blotter,
                'ForeignCurrency': currency,
                'LowerLimit': lowerLimit,
                'UpperLimit': upperLimit,
                'Email': email,
                'UserID': localStorage.getItem('UserID'),
                'UpdateToken': updateToken
            };
            const json = await saveBlotterLimit(model);
            if (json.httpStatusCode !== 200) {
                var alertMsg = 'An error occurred while trying to save the blotter limit. Please try again.'
                if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                    alertMsg = json.httpErrorMessage;
                }
                setShowAlertError(true);
                setAlertTitle('Error');
                setAlertMessage(alertMsg);
            } else {
                setShowAlert(true);
                setAlertMessage('Blotter limit saved successfully.');
                setAlertTitle('Success');
            }
            loadBlotterLimits(blotter)
        }
    }

    const yesDeleteAlert = async () => {
      console.log("yesDeleteAlert ->", blotterLimitReadyToDelete);
      const deleteBlotterLimitResponse = await deleteBlotterLimit(blotterLimitReadyToDelete.BlotterID, blotterLimitReadyToDelete.Currency, blotterLimitReadyToDelete.UpdateToken);
      
      let titleAux = 'Error';
      let messageAux = 'Something went wrong, Please try again later.';

      if(deleteBlotterLimitResponse !== undefined)
      {
        if (deleteBlotterLimitResponse.httpStatusCode === 200) 
        {
          titleAux = 'Success';
          messageAux = 'The Blotter Limit has been successfully deleted.';
        } else {
          if(deleteBlotterLimitResponse.Message !== undefined && deleteBlotterLimitResponse.Message !== null && deleteBlotterLimitResponse.Message !== '')
          {
            messageAux = deleteBlotterLimitResponse.Message;
          }
        }
      }

      if(titleAux == 'Success')
      {
        loadBlotterLimits(blotter);
      }

      setAlertTitle(titleAux);
      setAlertMessage(messageAux);
      setShowDeleteAlert(false);
      setShowAlert(true);
    }

    const loadBlotterLimits = async (blotterID) => {
        const json = await getBlotterLimits(blotterID, 0, 0);
        if (json.blotters !== undefined) {
            const totalCount = json.blotters.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            setBlotterLimits(json.blotters);
            setTotalPages(totalPages);
        } else {
            setBlotterLimits([]);
        }
    }

    const validateFields = () => {
        const fields = [
            {displayName: 'Blotter', validateName: 'blotter', value: blotter, type: 'string'},
            {displayName: 'Currency', validateName: 'currency', value: currency, type: 'string'},
            {displayName: 'Email', validateName: 'email', value: email, type: 'email'},
            {displayName: 'Lower limit', validateName: 'lowerLimit', value: lowerLimit, type: 'number'},
            {displayName: 'Upper limit', validateName: 'upperLimit', value: upperLimit, type: 'number'}
        ];

        const [valid, _validations, _errors] = validate(fields);
        setValidations(_validations);
        
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid;
    }

    const handleRowClick = (obj) => {
        setValidations({blotter: true, currency: true, email:true, lowerLimit: true, upperLimit: true});
        // setBlotter(obj.BlotterID);
        setCurrency(obj.Currency);
        setCurrencyName(obj.Currency);
        setEmail(obj.Email);
        setLowerLimit(obj['Lower Limit']);
        setUpperLimit(obj['Upper Limit']);
        setUpdateToken(obj.UpdateToken);
    }

    const handleClearFieldsClick = () => {
        setValidations({blotter: true, currency: true, email:true, lowerLimit: true, upperLimit: true});
        // setBlotter('');
        setCurrency('');
        setCurrencyName('');
        setEmail('');
        setLowerLimit(0);
        setUpperLimit(0);
        setUpdateToken('');
    }

    const changePage = (value) => {
        setPages(value);
    }

    const handleGetValueBlotter = (value) => {
        loadBlotterLimits(value);
        setValidations({...validations, blotter: true});
    }

    const handleGetValueCurrency = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            setValidations({...validations, currency: true});
            setCurrency(value.value);
            setCurrencyName(value.name);
        }
    }

    const handleUpdateEmail = (event) => {
        setValidations({...validations, email: true});
        setEmail(event.target.value);
    }

    const handleUpdateLowerLimit = (event) => {
        setValidations({...validations, lowerLimit: true});
        setLowerLimit(event.target.value);
    }

    const handleUpdateUpperLimit = (event) => {
        setValidations({...validations, upperLimit: true});
        setUpperLimit(event.target.value);
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    const closeAlertError = () => {
        setShowAlertError(false)
        setAlertTitle('');
        setAlertMessage('');
    }

    const handleDeleteClick = (obj) => {
      setBlotterLimitReadyToDelete(obj);
      setShowDeleteAlert(true)
  }

    return (
        <div>
            <form className="uk-form-horizontal uk-margin">
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Blotter (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect id="bl-blotter" disabled={true} className={formatValidSelect(validations.blotter)} data={blotters} objValue={{value: blotter}} getValue={handleGetValueBlotter} listHasPlaceholder={false} placeholder={"Select a Blotter..."} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            {/*<DynamicSearchSelect id="bl-currency" className={formatValidSelect(validations.currency)} data={currencies} objValue={{value: currency, name: currencyName}} getValue={handleGetValueCurrency} searchFunction={searchCurrency} placeholder="Select a Currency..." />*/}
                            <DynamicSelect className="uk-select" id="b-base-currency" getValue={handleGetValueCurrency} placeholder="Select a Currency..." data={currencies} objValue={{value: currency, name: currencyName}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Email (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <input className={formatValidInput(validations.email)} type="email" placeholder="" value={email} onChange={handleUpdateEmail}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked"></div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Lower Limit (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <NumberInput id="bl-lower-limit" className={formatValidInput(validations.lowerLimit)} type="Currency" 
                            scale={getScaleByCurrency(currency)}
                            placeholder="" value={lowerLimit} onChange={handleUpdateLowerLimit} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Upper Limit (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <NumberInput id="bl-upper-limit" className={formatValidInput(validations.upperLimit)} type="Currency" 
                            scale={getScaleByCurrency(currency)}
                            placeholder="" value={upperLimit} onChange={handleUpdateUpperLimit} />
                        </div>
                    </div>
                </div>
            </form>
            <div>
                <button className="uk-button uk-button-green uk-margin-right" onClick={handleClearFieldsClick} type="button">Clear Fields</button>
                <button className="uk-button uk-button-green uk-margin-right" onClick={handleButtonSaveClick} type="button">Save</button>
                <hr/>
            </div>
            <NewDynamicTable
                id="blotter-limits"
                data={blotterLimits}
                hiddenHeaders={['BlotterID', 'UpdateToken']}
                formats={[{ header: 'Lower Limit', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Currency' }, { header: 'Upper Limit', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Currency' }]}
                enableClick={true}
                clickFunction={handleRowClick}
                newSearch={true}
                useDeleteButton={true}
                deleteFunction={handleDeleteClick}
                usePaginator={true}
                changePage={changePage}
                numberOfPages={totalPages}
                pageNumber={pages}
                numberPerPage={getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}
            />
            <AlertBox id="alert-blotter-limits" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            <AlertBox id="alert-error-blotter-limits" open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
            <AlertBox id="alert-delete-blotter-limits" open={showDeleteAlert} onClose={()=>{setShowDeleteAlert(false)}} title="Warning" message="Are you sure you want to delete these Blotter Limit? This action can not be undone." type="Yes/No" yesClick={yesDeleteAlert} noClick={()=>{setShowDeleteAlert(false)}}/>
        </div>
    );
}

export default BlotterLimitsForm;
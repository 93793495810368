import React from 'react';
import NumberInput from '../../shared/NumberInput';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';

import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames'

import { formatToCurrency,formatToCurrencyWithScale,getScaleByCurrency } from '../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { getOutstandingForwardsMin, getSameCurrencyDealRefunds, voidSameCurrencyDealRefund } from '../../../networking/NetworkingDeals';
import { getCurrenciesByStatus } from '../../../networking/NetworkingCurrencies';
import { getDepositsByCustomer, saveDeposit, getDepositByID, getIncomingFundsForDeposit, voidDeposit, getDepositApplicationList } from '../../../networking/NetworkingDeposits';
import { getParametersByName } from '../../../networking/NetworkingGlobalSystemParameters'
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getCustomerByID } from '../../../networking/NetworkingCustomers'
import { voidIncomingFund } from '../../../networking/NetworkingFunds'

import { getStyle, notFilledInputClass, regularInputClass, regularSelectClass, notFilledSelectClass } from '../../../styles/styles';

import RefundForm from '../RefundForm';
import PaymentsApplyBalanceToHeldFundsForm from '../../back_office/payments/PaymentsApplyBalanceToHeldFundsForm'
import EntityFunding from '../../shared/EntityFunding';
import { Dialog, DialogContent } from '@material-ui/core';

var style = getStyle();

/*
                	
*/
const FundingTable = ({ data = [], unlinkPayment = f => f }) => {

    let headers = []
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h1'}>PAYMENT ID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h2'}>FORWARD DEAL ID</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h3'}>INSTRUMENT</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h4'}>INSTRUMENT	DATE</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h5'}>AMOUNT</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h6'}>AUDIT</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center' }} className="uk-table-shrink" key={'rt-h7'}>ACTIONS</th>)

    let rows = data.map((x, i) => {

        let unlink = []
        console.log(Number(x.CanBeVoided))
        if (Number(x.CanBeVoided) === 1) {
            console.log(Number(x.CanBeVoided))
            unlink.push(<i className="fa fa-chain-broken" onClick={() => unlinkPayment(x)} style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>)
        }

        return <tr key={i}  >
            <td className="uk-preserve-width">{x.PaymentID}</td>
            <td className="uk-preserve-width">{x.ForwardDealID}</td>
            <td className="uk-preserve-width">{x.Instrument}</td>
            <td className="uk-preserve-width">{x.Date}</td>
            <td className="uk-preserve-width">{x.Amount}</td>
            <td className="uk-preserve-width">{x.Audit}</td>
            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'center' }}>
                {unlink}
            </td>
        </tr>
    })

    let table =
        <div className="">
            <table key={'reconciliationTable'} className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped"
            >
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    return table
}

export default class DepositInfoScreen extends React.Component {

    state = {
        amount: 0,
        amountReceived: 0,
        createdOn: '',
        currencies: [],
        currencyID: '',
        customerID: 0,
        customerName: '',
        dealHeaderID: 0,
        depositID: 0,
        depositType: 'MarginCall',
        disbursedAmount: 0,
        disbursedStatus: '',
        forwardList: [],
        issuedOn: '',
        listCustomers: [],
        listDeposits: [],
        listFunding: [],
        percentage: 0,
        receivedStatus: '',
        storedDealHeaderID: 0,

        listDepositApplication: [],
        newSearch: false,
        newSearchFunding: false,
        notFirstLoad: false,
        showAlert: false,
        updateToken: '',
        showZero: false,
        loading: false,
        currentPage: 1,
        currentPageFunding: 1,
        totalPages: 0,
        totalPagesFunding: 0,
        listReceivedStatus: [],
        listDisbursedStatus: [],
        "Deposit Received Status": [],
        "Deposit Disbursed Status": [],
        lookUpTables: [
            "Deposit Received Status",
            "Deposit Disbursed Status"],
        refundMode: false,
        refundedAmount: 0,
        refunds: [],
        refundToVoid: {},
        showCustomerIDandName: false,
    }

    componentDidMount() {
        var customerID = 0;
        if (this.props.customerID !== undefined) {
            customerID = this.props.customerID;
            this.getDepositsByCustomerID(customerID, false);
        }

        var percentage = 0;
        if (this.props.objCustomer !== undefined) {
            if (this.props.objCustomer.DefaultDepositPercent$ !== undefined) {
                percentage = this.props.objCustomer['DefaultDepositPercent$'];
            }
        }

        if (this.props.currencies === undefined) {
            getCurrenciesByStatus("Active").then(
                (json) => {
                    if (json.currencies !== undefined) {
                        json.currencies.forEach(element => {
                            element.value = element.CurrencyID;
                            element.name = element.Description;
                        });
                        this.setState({
                            currencies: json.currencies
                        });
                    }
                }
            );
        }
        else {
            this.setState({ currencies: this.props.currencies });
        }

        getLookUpTable(this.state.lookUpTables.toString()).then((json) => {
            for (var i = 0; i < this.state.lookUpTables.length; i++) {
                let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                if (index > -1) {
                    var table = json.lookUpTables[index].lookUpTableDetails;
                    for (var j = 0; j < table.length; j++) {
                        table[j].value = table[j].LookUpTableDetail;
                        table[j].name = table[j].LookUpTableDetail;
                    }
                    this.setState({ [this.state.lookUpTables[i]]: table });
                }
            }
            this.setState({
                receivedStatus: 'Outstanding',
                disbursedStatus: 'Outstanding'
            });

            if (this.props.data !== undefined) {
                this.getDepositByID(this.props.data);
            }
        });

        //Buy currency
        var currencyID = '';
        if (this.props.buyCurrency !== undefined) {
            currencyID = this.props.buyCurrency;
            this.loadForwards(0, customerID, currencyID);
        }

        //Deal header
        var dealHeaderID = 0;
        if (this.props.dealHeaderID !== undefined) {
            dealHeaderID = this.props.dealHeaderID;
        }

        //Set state 
        this.setState({
            currencyID: currencyID,
            customerID: customerID,
            dealHeaderID: dealHeaderID,
            percentage: percentage,
        });
    }

    getDepositsByCustomerID(customerID, showZero) {
        //alert('getDepositsByCustomerID');
        if (customerID !== undefined && customerID > 0) {
            this.setState({ loading: true });
            getDepositsByCustomer(customerID, showZero, this.state.currentPage, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if (value !== undefined && value.totalCount > 0) {
                        var deposits = this.transformDepositList(value.result);
                        this.setState({ listDeposits: deposits });
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        this.setState({ totalPages: pages, newSearch: true, loading: false });
                    }
                    else {
                        this.setState({ listDeposits: [], newSearch: true, loading: false });
                    }
                }
            );
        }
    }

    transformDepositList(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'Deposit ID': list[i]['DepositID'],
                'Amount': list[i]['Amount'],
                'Amount Received': list[i]['AmountReceived'],
                'Refunded Amount': list[i]['RefundedAmount'],
                'Currency': list[i]['CurrencyID'],
                'Outgoing Balance': list[i]['OutgoingBalance'],
                'Received Status': list[i]['ReceivedStatus'],
                'Disbursed Status': list[i]['DisbursedStatus'],
            });
        }
        return helper;
    }

    getDepositByID = (depositID) => {
        this.setState({
            loading: true,
            currentPageDeals: 1
        });
        if (depositID !== undefined && depositID > 0) {
            getDepositByID(depositID).then(
                (json) => {
                    if (json !== undefined) {
                        this.setState({
                            depositID: json.DepositID,
                            customerID: json.CustomerID,
                            dealHeaderID: json.DealReferenceID,
                            storedDealHeaderID: json.DealReferenceID,
                            amount: json.Amount,
                            amountReceived: json.AmountReceived,
                            disbursedAmount: json.DisbursedAmount,
                            refundedAmount: json.RefundedAmount,
                            currencyID: json.CurrencyID,
                            receivedStatus: json.ReceivedStatus,
                            disbursedStatus: json.DisbursedStatus,
                            depositType: json.DepositType,
                            createdOn: json.CreatedOn,
                            issuedOn: json.IssueDate,
                            updateToken: json.UpdateToken
                        });

                        this.loadForwards(json.DealReferenceID || 0, json.CustomerID, json.CurrencyID);
                        this.getIncomingFundsForDeposit(depositID);
                        this.loadDepositApplications(depositID)
                        this.getDepositsByCustomerID(json.CustomerID, this.state.showZero)
                        this.loadRefunds(depositID);
                        if (this.props.setDepositCurrency !== undefined) {
                            this.props.setDepositCurrency(json.CurrencyID);
                        }
                        if (this.props.setCustomer !== undefined) {
                            this.props.setCustomer(json.CustomerID);
                        }

                        if(this.props.reloadDepositInfo !== undefined)
                        {
                            this.props.reloadDepositInfo(json.DepositID);
                        }

                        if (this.props.data !== undefined) {
                            getCustomerByID(json.CustomerID).then(
                                (c) => {
                                    if (c !== undefined && c.CustomerID !== undefined) {
                                        this.setState({
                                            customerName: c.Name,
                                            percentage: c.DefaultDepositPercent,
                                        });

                                        if (this.props.setCustomerName !== undefined) {
                                            this.props.setCustomerName(c.Name);
                                        }else{
                                            this.setState({showCustomerIDandName: true});
                                        }
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }
        this.setState({ loading: false });
    }

    handleRowClick = (obj) => {
        if (obj !== undefined) {
            this.getDepositByID(obj["Deposit ID"]);
            if (this.props.onDepositSelected !== undefined) {
                this.props.onDepositSelected(obj, 4);
            }
        }
    }

    changePage = (value) => {
        this.setState({ currentPage: value }, () =>
            this.getDepositsByCustomerID(this.state.customerID, this.state.showZero));
    }

    getIncomingFundsForDeposit(depositID) {
        if (depositID !== undefined && depositID > 0) {
            getIncomingFundsForDeposit(depositID, this.state.currentPageFunding, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    console.log(value)
                    if (value !== undefined && value.totalCount > 0) {
                        this.setState({ listFunding: value.result });
                        //var deals = this.transformFundList(value.result);
                        //this.setState({listFunding: deals});
                        //var totalCount = value.totalCount;
                        //var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                        //this.setState({totalPagesFunding: pages, newSearchFunding:true});
                    }
                    else {
                        this.setState({ listFunding: [], newSearchFunding: true });
                    }
                }
            );
        }
    }

    loadDepositApplications(depositId) {
        getDepositApplicationList(depositId, 0, 0).then(
            (json) => {
                console.log(json)
                if (json !== undefined && json.list !== undefined) {
                    this.setState({ listDepositApplication: json.list });
                }
                else {
                    this.setState({ listDepositApplication: [] });
                }
            }
        )
    }

    transformFundList(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'Payment ID': list[i]['PaymentID'],
                'Forward Deal ID': list[i]['ForwardDealID'],
                'Instrument': list[i]['Instrument'],
                'Date': list[i]['Date'],
                'Amount': list[i]['Amount'],
                'Audit': list[i]['Audit']
            });
        }
        return helper;
    }

    changePageFunding = (value) => {
        this.setState({ currentPageFunding: value });
    }

    handleUpdateshowZero = (event) => {
        this.setState({ showZero: event.target.checked })
        this.getDepositsByCustomerID(this.state.customerID, event.target.checked);
    }

    handleBackLink = () => {
        this.props.backLink();
    }

    handleUpdatecurrencyID = (value) => {
        if (value !== undefined && value.CurrencyID !== undefined) {
            this.setState({ currencyID: value.CurrencyID });
            this.loadForwards(0, this.state.customerID, value.CurrencyID)
        }
    }

    handleUpdateFordward = (obj) => {
        if (obj !== undefined
            && obj.value !== undefined
            && obj !== null) {
            this.setState({ dealHeaderID: obj.value });
        }
    }

    getAmount = (event) => {
        this.setState({ amount: event.target.value });
    }

    getAmountReceived = (event) => {
        this.setState({ amountReceived: event.target.value });
    }

    getReceivedStatusSelect = (value) => {
        if (value !== undefined && value != null) {
            this.setState({ receivedStatus: value.LookUpTableDetail });
        }
    }

    getDisbursedStatusSelect = (value) => {
        if (value !== undefined && value != null) {
            this.setState({ disbursedStatus: value.LookUpTableDetail });
        }
    }

    handleSaveClick = () => {
        this.setState({ loading: true });
        if (this.validateRequiredInputs()) {
            var valid = false;
            getParametersByName(GlobalSystemParametersNames.SystemDate).then((value) => {
                if (value.parameters !== undefined) {

                    let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
                    if (value.parameters[0].Value <= systemDate) {
                        valid = true;
                    }
                }

                if (valid) {
                    let userID = localStorage.getItem('UserID');
                    var model = {
                        DepositID: this.state.depositID,
                        CustomerID: this.state.customerID,
                        DealHeaderID: this.state.dealHeaderID,
                        Amount: this.state.amount,
                        AmountReceived: this.state.amountReceived,
                        DisbursedAmount: this.state.disbursedAmount,
                        RefundedAmount: this.state.refundedAmount,
                        CurrencyID: this.state.currencyID,
                        ReceivedStatus: this.state.receivedStatus,
                        DisbursedStatus: this.state.disbursedStatus,
                        DepositType: this.state.depositType,
                        UpdatedBy: userID,
                        UpdateToken: this.state.updateToken
                    }

                    saveDeposit(model).then((json) => {
                        switch (Number(json.httpStatusCode)) {
                            case 200:
                                this.setState({
                                    updateToken: json.updateToken,
                                    depositID: json.depositID,
                                    showAlert: true,
                                    alertTitle: 'Success',
                                    alertMessage: 'Deposit successfully saved.',
                                    storedDealHeaderID: this.state.dealHeaderID,
                                });
                                //Update deposit currency from container component
                                if (this.props.setDepositCurrency !== undefined) {
                                    this.props.setDepositCurrency(this.state.currencyID);
                                }
                                //Update deposit object from container component
                                if (this.props.reloadDepositInfo !== undefined) {
                                    this.props.reloadDepositInfo(json.depositID);
                                }
                                this.changePage(this.state.currentPage);
                                break;
                            default:
                                var alertMessage = 'An error occurred while trying to save the deposit. Please try again.'
                                if (json.httpErrorMessage !== undefined) {
                                    alertMessage = json.httpErrorMessage;
                                }
                                this.setState({
                                    showAlert: true,
                                    alertMessage: alertMessage,
                                    alertTitle: 'Error'
                                });
                                break;
                        }
                    });
                }
            });
        }
        else {
            this.setState({ notFirstLoad: true });
        }
        this.setState({ loading: false });
    }

    validateRequiredInputs() {
        if (this.state.currencyID === undefined) {
            return false;
        }
        if (this.state.amount === '' || this.state.amount === 0) {
            return false;
        }
        if (this.state.amountReceived === '') {
            return false;
        }

        return true;
    }

    handleClearClick = () => {
        this.setState({
            depositID: 0,
            dealHeaderID: 0,
            storedDealHeaderID: 0,
            amount: 0,
            amountReceived: 0,
            disbursedAmount: 0,
            refundedAmount: 0,
            currencyID: '',
            receivedStatus: 'Outstanding',
            disbursedStatus: 'Outstanding',
            depositType: 'MarginCall',
            updateToken: '',
            createdOn: '',
            issuedOn: '',
            showUnlinkAlert: false,
            showVoidAlert: false,
            unlinkElement: {},
            forwardList: [],
            listDeals: [],
            listDepositApplication: []
        });
    }

    handleRefundClick = () => {
        let balance = this.state.amountReceived - this.state.refundedAmount - this.state.disbursedAmount;
        var showAlert = false;
        var alertTitle, alertMessage;
        if (balance > 0) {
            if (this.state.storedDealHeaderID > 0) {
                showAlert = true;
                alertTitle = 'Error';
                alertMessage = 'Deposit is linked to a Forward. Please unlink the forward first before refund it.';
            }
            else {
                this.setState({ refundMode: true });
            }
        }
        else {
            showAlert = true;
            alertTitle = 'Error';
            alertMessage = 'Deposit does not have balance to refund!';
        }

        if (showAlert) {
            this.setState({
                alertMessage: alertMessage,
                alertTitle: alertTitle,
                showAlert: showAlert,
            });
        }
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    loadForwards(dealHeaderID, customerID, currencyID) {
        if (currencyID !== undefined) {
            getOutstandingForwardsMin(dealHeaderID, customerID).then(
                (json) => {
                    console.log(json)
                    if (json !== undefined && json.forwards !== undefined) {
                        let helper = json.forwards.filter(x => x.BuyCurrency === currencyID)
                        helper.forEach(x => {
                            x.value = x.DealHeaderID
                            x.name = x.Description
                        })
                        this.setState({ forwardList: helper });
                    }
                    else {
                        this.setState({ forwardList: [] });
                    }
                }
            );
        }
    }

    onRefundBackClick = () => {
        this.getDepositByID(this.state.depositID);
        this.setState({ refundMode: false });
    }

    showApplyToFundOnHoldButton = () => {
        return (["Fully paid"].includes(this.state.receivedStatus) && Number(this.state.amount - this.state.disbursedAmount - this.state.refundedAmount) > 0)
    }

    onApplyToHeldFundsClick = () => {
        this.setState({ applyToHeldFundsMode: true });
    }

    onApplyToHeldFundsdBackClick = () => {
        this.setState({ applyToHeldFundsMode: false });
        this.getDepositByID(this.state.depositID);
    }

    getIncomingFundForHeldFund = () => {

        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);

        let incomingFund = {}
        incomingFund.ActualDate = systemDate
        incomingFund.CurrencyID = this.state.currencyID;
        incomingFund.CustomerID = this.state.customerID;
        incomingFund.ExpectedDate = systemDate;
        incomingFund.SourceID = this.state.depositID;
        incomingFund.SourceTable = 'D'
        return incomingFund
    }

    handleVoidClick = () => {
        let userID = localStorage.getItem('UserID');

        var model = {
            DepositID: this.state.depositID,
            UserID: userID,
            UpdateToken: this.state.updateToken,
        }

        //IsValidApplicationDate?
        getParametersByName(GlobalSystemParametersNames.LastClosingDate).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.parameters !== "undefined" && response.parameters.length > 0) {

                    let currentSessionLastClosingDate = response.parameters[0].Value;

                    if (new Date(currentSessionLastClosingDate) > new Date(this.state.sessionLastClosingDate)) {
                        this.setState({ showAlert: true, alertMessage: 'You should restart the session. The application date is not correct.', alertTitle: 'Error' });
                        return false
                    }

                    if (this.state.amountReceived > 0) {
                        this.setState({
                            showAlert: true, alertMessage: 'This deposit has been fully or partially paid. Please first unapply the settlement to the deposit on the Deposit Funding section.',
                            alertTitle: 'Error'
                        });
                        return false
                    }

                    if (this.state.refundedAmount > 0) {
                        this.setState({
                            showAlert: true, alertMessage: 'This deposit cannot be voided as there are completed refunds linked to it.',
                            alertTitle: 'Error'
                        });
                        return false
                    }

                    this.setState({ loading: true });
                    voidDeposit(model).then((response) => {
                        var alertTitle = '';
                        var alertMessage = '';
                        var clearScreen = false;
                        switch (Number(response.httpStatusCode)) {
                            case 200:
                                alertTitle = 'Success';
                                alertMessage = 'Deposit voided successfully';
                                clearScreen = true;
                                break;
                            case 400:
                                alertTitle = 'Error';
                                alertMessage = "An error occurred while trying to void the payment. Please try again. (Bad Request)";
                                break;
                            case 409:
                                alertTitle = 'Error';
                                alertMessage = 'Payment has been updated by other user. Please reload the information and try again.';
                                if (response.httpErrorMessage !== undefined) {
                                    alertMessage = response.httpErrorMessage;
                                }
                                break;
                            default:
                                alertTitle = 'Error';
                                alertMessage = 'An error occurred while trying to void the payment. Please try again.'
                                if (response.Message !== undefined) {
                                    alertMessage = response.Message;
                                }
                                break;
                        }
                        this.setState({
                            showAlert: true,
                            alertMessage: alertMessage,
                            alertTitle: alertTitle,
                            loading: false,
                        }, () => {
                            if (clearScreen) {
                                this.handleClearClick();
                            }
                        });
                    });
                }
            }
        )

    }

    handleUnlinkPayment = (unlinkElement) => {
        this.setState({
            showUnlinkAlert: true, unlinkElement: unlinkElement,
        })
    }

    onDeleteRefundClick = (row) => {
        //alert(JSON.stringify(row));
        this.setState({
            refundToVoid: row,
            showVoidAlert: true,
        });
    }

    closeVoidIncomingAlert = () => {
        this.setState({ showUnlinkAlert: false });
    }


    yesVoidIncomingFund = () => {
        let userId = localStorage.getItem('UserID')
        console.log(this.state.unlinkElement)
        voidIncomingFund(this.state.unlinkElement.IncomingFundsID, userId, this.state.unlinkElement.IncomingUpdateToken,
            this.state.unlinkElement.PaymentUpdateToken, 0, this.state.unlinkElement.DepositUpdateToken, 0).then(
                (response) => {
                    console.log(response)
                    if (Number(response.httpStatusCode) === 200) {
                        this.getDepositByID(this.state.depositID)
                        this.setState({
                            showUnlinkAlert: false,
                            unlinkElement: {},
                            alertTitle: 'Success',
                            alertMessage: 'Payment application voided!',
                            showAlert: true,
                        });
                        if (this.props.reloadDepositInfo !== undefined) {
                            this.props.reloadDepositInfo(this.state.depositID);
                        }
                    }
                    else {
                        let alertMessage = 'Payment application not voided! Please try again or contact administrator.';
                        if (response.httpErrorMessage !== undefined) {
                            alertMessage = response.httpErrorMessage
                        }
                        this.setState({
                            showUnlinkAlert: false,
                            unlinkElement: {},
                            alertTitle: 'Error',
                            alertMessage: alertMessage,
                            showAlert: true,
                        })
                    }
                }
            );
    }

    loadRefunds = (depositID) => {
        if (depositID > 0) {
            this.setState({ loadingRefunds: true });
            getSameCurrencyDealRefunds('D', depositID, '').then(
                (response) => {
                    var refunds = [];
                    if (response.refunds !== undefined) {
                        refunds = response.refunds;
                    }
                    this.setState({
                        loadingRefunds: false,
                        refunds: refunds,
                    });
                }
            );
        }
    }

    yesDeleteRefund = () => {
        this.setState({ showVoidAlert: false });
        let recordToDelete = this.state.refundToVoid;
        voidSameCurrencyDealRefund(recordToDelete['DealHeaderID$'], localStorage.getItem('UserID'), recordToDelete['DealUpdateToken$']).then(
            (response) => {
                //alert(JSON.stringify(response));
                var alertTitle = '', alertMessage = '';
                if (Number(response.httpStatusCode) === 200) {
                    this.loadRefunds();
                    this.getDepositByID(this.state.depositID);
                    alertTitle = 'Success';
                    alertMessage = 'Refund voided successfully!';
                    recordToDelete = {};
                } else {
                    alertTitle = 'Error';
                    alertMessage = 'Process failed. Please try again or contact the administrator.';
                    if (response.httpErrorMessage !== undefined) {
                        alertMessage = response.httpErrorMessage;
                    }
                }
                this.setState({
                    alertMessage: alertMessage,
                    alertTitle: alertTitle,
                    loading: false,
                    showAlert: true,
                    recordToDelete: recordToDelete,
                });
            }
        );
    }

    noDeleteRefund = () => {
        this.setState({ showVoidAlert: false });
    }

    render() {
        var backButton;
        var customer = "";
        if (this.props.data !== undefined && this.props.data > 0 && this.state.showCustomerIDandName) {
            /*  backButton = (<button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                              <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                          </button> );*/
            customer = <h4 className="text-align-left">Customer: {this.state.customerID} - {this.state.customerName}</h4>
        }

        var depositIDLabel;
        {/*if (this.state.depositID > 0) {
            depositIDLabel = (<h4 className="text-align-right">Deposit ID: {this.state.depositID}</h4>);
        }*/}

        var classNameAmount = regularInputClass;
        var classNameAmountReceived = regularInputClass;
        var classNameCurrency = regularSelectClass;

        if ((this.state.currencyID === undefined || this.state.currencyID === '') && this.state.notFirstLoad) {
            classNameCurrency = notFilledSelectClass;
        }

        if ((this.state.amount === '' || this.state.amount === 0) && this.state.notFirstLoad) {
            classNameAmount = notFilledInputClass;
        }
        if (this.state.amountReceived === '' && this.state.notFirstLoad) {
            classNameAmountReceived = notFilledInputClass;
        }

        //Refund button
        var refundButton;
        if (this.state.amountReceived > 0) {
            refundButton = (<button className="uk-button uk-button-green" type="button" onClick={this.handleRefundClick}>Refunds</button>);
        }

        //Disabled controls
        var amountControlDisabled = false, currencyControlDisabled = false,
            forwardControlDisabled = false;
        if (this.state.amountReceived > 0) {
            amountControlDisabled = currencyControlDisabled = true;
        }

        if (this.props.buyCurrency !== undefined) {
            currencyControlDisabled = true;
        }

        if (this.props.dealHeaderID !== undefined) {
            forwardControlDisabled = true;
        }

        //Void button
        var voidButton;
        if (this.state.depositID > 0) {
            voidButton = (<button className="uk-button uk-button-green" type="button" onClick={this.handleVoidClick}>Void</button>);
        }

        //Exit button
        var exitButton;
        if (this.props.onCloseClick !== undefined) {
            exitButton = (<button className="uk-button uk-button-green" onClick={this.props.onCloseClick}>Exit</button>)
        }

        //held funds
        let applyToFundOnHoldButton = []

        if (this.showApplyToFundOnHoldButton()) {
            applyToFundOnHoldButton.push(<button className="uk-button uk-button-green" type="button" onClick={this.onApplyToHeldFundsClick}
                hidden={false}>Apply to held funds</button>)
        }

        //no void allowed message
        var noVoidAllowedMessage;
        if (this.state.refundedAmount > 0 || this.state.disbursedAmount > 0) {
            noVoidAllowedMessage = 'This record cannot be voided. Please first void deposit applications.';
        }

        let paymentsApplyBalanceToHeldFundsForm = <Dialog
            fullWidth={true}
            maxWidth='md'
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.applyToHeldFundsMode} onClose={this.onRefundBackClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <PaymentsApplyBalanceToHeldFundsForm
                    sourceTable="D"
                    onBackClick={this.onApplyToHeldFundsdBackClick}
                    sourceBalance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                    currencyId={this.state.currencyID}
                    incomingFund={this.getIncomingFundForHeldFund()} />
            </DialogContent>
        </Dialog>

        let refundForm = (
            <Dialog
                fullWidth={true}
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.refundMode} onClose={this.onRefundBackClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <RefundForm sourceTable="D"
                        sourceID={this.state.depositID}
                        sourceUpdateToken={this.state.updateToken}
                        customerID={this.state.customerID}
                        balance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                        currencyID={this.state.currencyID}
                        onBackClick={this.onRefundBackClick} />
                </DialogContent>
            </Dialog>
        );

        var currentScreen =
            (
                <div>
                    <div>
                        <div>
                            <div className="uk-grid">
                                {(this.state.showCustomerIDandName) && <div className="uk-width-1-2">{customer}</div>}
                                <div className="uk-width-1-2">{depositIDLabel}</div>
                            </div>
                        </div>
                        <form className="uk-form-stacked uk-grid">
                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-amount">Amount *</label>
                                    <div className="uk-form-controls">
                                        <NumberInput className={classNameAmount} placeholder="Amount" id="form-amount" type="Currency" value={this.state.amount} 
                                        scale={getScaleByCurrency(this.state.currencyID)}
                                        onChange={this.getAmount} disabled={amountControlDisabled} />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked uk-form-controls">
                                <label className="uk-form-label" htmlFor="form-deposit-currency">Currency</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect
                                        className={classNameCurrency}
                                        placeholder="Select a currency..."
                                        placeholderValue=''
                                        data={this.state.currencies}
                                        objValue={{ value: this.state.currencyID }}
                                        getValue={this.handleUpdatecurrencyID}
                                        disabled={currencyControlDisabled} />
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-amount-received">Amount Received </label>
                                    <div className="uk-form-controls">
                                        <NumberInput className={classNameAmountReceived} disabled={true} placeholder="Amount Received" id="form-amount-received" type="Currency" value={this.state.amountReceived} 
                                        scale={getScaleByCurrency(this.state.currencyID)}
                                        onChange={this.getAmountReceived} />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked"></div>

                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-total-refunded">Total Refunded </label>
                                    <div className="uk-form-controls">
                                        <NumberInput className="uk-input" disabled={true} placeholder="Total Refunded" id="form-total-refunded" type="Currency" 
                                        scale={getScaleByCurrency(this.state.currencyID)}
                                        value={this.state.refundedAmount} />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-available-balance">Available Balance</label>
                                    <div className="uk-form-controls">
                                        <NumberInput className="uk-input" disabled={true} placeholder="Available Balance" id="form-available-balance" type="Currency" 
                                        scale={getScaleByCurrency(this.state.currencyID)}
                                        value={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount} />
                                    </div>
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-form-stacked" >
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-received-status">Received Status</label>
                                    <div className="uk-form-controls">
                                        <DynamicSelect getValue={this.getReceivedStatusSelect} disabled={true} listHasPlaceholder={true} data={this.state["Deposit Received Status"]} id="form-received-status" objValue={{ value: this.state.receivedStatus, LookUpTableDetail: this.state.receivedStatus }} />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked" >
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-disbursed-status">Disbursed Status</label>
                                    <div className="uk-form-controls">
                                        <DynamicSelect getValue={this.getDisbursedStatusSelect} disabled={true} listHasPlaceholder={true} data={this.state["Deposit Disbursed Status"]} id="form-disbursed-status" objValue={{ value: this.state.disbursedStatus, LookUpTableDetail: this.state.disbursedStatus }} />
                                    </div>
                                </div>
                            </div>

                            <div className="uk-width-1-1 uk-form-controls">
                                <label className="uk-form-label" htmlFor="form-forward">Forward</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect
                                        placeholder="Select a Forward..."
                                        placeholderValue={0}
                                        data={this.state.forwardList}
                                        objValue={{ value: this.state.dealHeaderID }}
                                        getValue={this.handleUpdateFordward}
                                        disabled={forwardControlDisabled} />
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-form-stacked" >
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-created-on">Created On  {this.state.createdOn}</label>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked" >
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-disbursed-status">Customer Margin Percentage  {this.state.percentage} %</label>
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-form-stacked" >
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-issued-on">Issued On  {this.state.issuedOn}</label>
                                </div>
                            </div>

                            <div className="uk-width-1-1 uk-margin">
                                <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick}>Save</button>
                                <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick}>Clear Fields</button>
                                {refundButton}
                                {voidButton}
                                {applyToFundOnHoldButton}
                                {exitButton}
                            </div>

                            <div className="uk-width-1-1">

                                <ul id="accordion-grids" uk-accordion="multiple: true">
                                    <li>
                                        <h5 className="uk-accordion-title">Deposit Funding</h5>
                                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                            <EntityFunding
                                                balance={this.state.amount - this.state.amountReceived}
                                                currencyID={this.state.currencyID}
                                                customerID={this.state.customerID}
                                                entityID={this.state.depositID}
                                                entityType='Deposit'
                                                entityUpdateToken={this.state.updateToken}
                                                noVoidAllowedMessage={noVoidAllowedMessage}
                                                reloadEntity={this.getDepositByID} />
                                        </div>
                                    </li>
                                    <li>
                                        <h5 className="uk-accordion-title">Deposit Application</h5>
                                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                            <NewDynamicTable
                                                id="ndt-deposit-application"
                                                data={this.state.listDepositApplication}
                                                formats={[{ header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: this.state.currencyID }]}
                                                enableClick={false} />
                                        </div>
                                    </li>
                                    <li>
                                        <h5 className="uk-accordion-title">Refunds</h5>
                                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                            <NewDynamicTable data={this.state.refunds}
                                                enableClick={false}
                                                newSearch={true}
                                                useDeleteButton={true}
                                                deleteFunction={this.onDeleteRefundClick}
                                                formats={[{ header: 'Refund Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: this.state.currencyID }]} />
                                        </div>
                                    </li>
                                </ul>

                                {/** "Refunds" and "Apply Held funds" as popup*/}
                                {refundForm}
                                {paymentsApplyBalanceToHeldFundsForm}
                                
                                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                                <AlertBox id="alert-void-incoming"
                                    type="Yes/No"
                                    open={this.state.showUnlinkAlert}
                                    title="Confirm"
                                    message="Are you sure you want to unapply this payment?"
                                    yesClick={this.yesVoidIncomingFund}
                                    noClick={this.closeVoidIncomingAlert}
                                    onClose={this.closeVoidIncomingAlert} />
                                <AlertBox id="delete-alert"
                                    type="Yes/No"
                                    open={this.state.showVoidAlert}
                                    title="Confirmation"
                                    message="Are you sure you want to void this refund?"
                                    yesClick={this.yesDeleteRefund}
                                    noClick={this.noDeleteRefund}
                                    onClose={this.noDeleteRefund} />

                                <LoadingBox loading={this.state.loading} />
                            </div>
                        </form>
                    </div>
                </div>
            );

        // if (this.state.applyToHeldFundsMode) {
        //     return paymentsApplyBalanceToHeldFundsForm
        // }
        // else if (this.state.refundMode) {
        //     currentScreen = (<RefundForm sourceTable="D"
        //         sourceID={this.state.depositID}
        //         sourceUpdateToken={this.state.updateToken}
        //         customerID={this.state.customerID}
        //         balance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
        //         currencyID={this.state.currencyID}
        //         onBackClick={this.onRefundBackClick} />);
        // }

        return currentScreen;
    }
}
import { genericGetWithParameters, genericDeleteWithParameters, genericCallWithBody, genericGetWithParametersNoEncrypted } from "./Networking";
import jwt from 'jsonwebtoken';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getAllLookUpTable(page, items){
    try {
        let params = {
            "page": page,
            "items":items
        };

        let response = await genericGetWithParameters('LookUpTable/GetAllLookUpTables',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllLookUpTableMin(){
    try {
        let params = {
            /*"page": page,
            "items":items*/
        };

        let response = await genericGetWithParameters('LookUpTable/GetAllLookUpTablesMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTableByID(id){
    try {
        let params = {
            "LookUpTableId": id
        };

        let response = await genericGetWithParameters('LookUpTable/GetLookUpTableById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteLookUpTableDetail(lookUpTableId,description) {
    try{
        let params = {
            'lookUpTableId': lookUpTableId,
            'description':description
        };
        let response = await genericDeleteWithParameters('LookUpTable/DeleteDetail',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function addLookUpTable(ojbLookUp) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'LookUpTable/SaveLookUpTable',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(ojbLookUp),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function addLookUpTableDetails(ojbLookUp)
{
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'LookUpTable/SaveDetail',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(ojbLookUp),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTablesDescription(LookUpTablesDescription)
{
    try
    {
        let params = {
            "LookUpTablesDescription": LookUpTablesDescription
        };

        let responseJWT = await genericGetWithParameters('LookUpTable/Get', params);
        if(responseJWT.httpStatusCode === 200)
        {
            console.log(responseJWT);
            return responseJWT;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getProcessStatusesByRole(){
    try {
        let params = {
            userId: localStorage.getItem('UserID')
        };

        let responseJWT = await genericGetWithParameters('LookUpTable/GetProcessStatusesByRole',params);
        if(responseJWT.httpStatusCode === 200)
        {
            console.log(responseJWT);
            return responseJWT;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getStatusesForEntity(entityType, subEntityType, entityId, amount, currency, entityStatus){
    try{
        let params = {
            EntityType: entityType,
            SubEntityType: subEntityType,
            EntityId: entityId,
            Amount: amount,
            Currency: currency,
            EntityStatus: entityStatus,
            UserId: localStorage.getItem('UserID')
        }
        let responseJWT = await genericGetWithParameters('LookUpTable/GetStatusesForEntity', params);
        if(responseJWT.httpStatusCode === 200)
        {
            console.log(responseJWT);
            return responseJWT;
        }else
        {
            return responseJWT
        }
    }catch(error) {
        console.error(error)
    }
}

export async function validateRecordInFilter(tableId, lookupTableToFind, descriptionToCompare)
{
    try
    {
        let params = {
            tableID: tableId,
            lookupTableToFind: lookupTableToFind,
            descriptionToCompare: descriptionToCompare
        }
        
        let response = await genericGetWithParametersNoEncrypted('LookUpTable/ValidateRecordInFilter',params);
        return response;
    } catch(error) {
        console.error(error)
    }
}

export async function getUsersByRole(roleName){
    try {
        let params = {
            RoleName: roleName
        };

        let responseJWT = await genericGetWithParameters('LookUpTable/GetUsersByRole',params);
        if(responseJWT.httpStatusCode === 200)
        {
            console.log(responseJWT);
            return responseJWT;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}
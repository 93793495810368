import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const EntityDetails = (props) =>{

    const getInfo =() =>{
        let objJson = props.jsonDetail;

        let information = [];
        
        if(objJson)
        {
            let data = Object.keys(objJson).map(function (key) {
                return [key, objJson[key]];
            });

            if(data && data.length > 0)
            {
            data.map(i => 
                information.push(
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">{i[0]}:</label>
                            <div className="uk-margin-right">
                                <input className="uk-input" disabled={true} id="dealVersionTrader" type="text" placeholder="" value={i[1]} />
                            </div>
                        </div>
                    </div> )
            )
            }
        
        }

        return information
    }
    return(
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <div className="uk-grid">
                        {getInfo()}
                    </div>                
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={props.onClose}>Close</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EntityDetails
import React from 'react';
import LoadingBox from '../../../shared/LoadingBox';
import AlertBox from '../../../shared/AlertBox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default class AdditionalInformationScreen extends React.Component {

    state = {
        bankReference: '',
        internalComments: '',
        clientComments: ''
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    handleSaveDealHeader = () => {
        this.handleClearClick();
        this.props.returnObj(this.state, true)
    }

    handleClearClick = async () => {
        this.setState({
            bankReference: '',
            internalComments: '',
            clientComments: '',
        })
    }

    handleExitClick = (value) => {
        this.handleClearClick();
        this.props.close(value);
    }

    handleUpdateBankReference = (event) => {
        this.setState({ bankReference: event.target.value});
    }

    handleUpdateInternalComments = (event) => {
        this.setState({ internalComments: event.target.value });
    }
    handleUpdateClientComments = (event) => {
        this.setState({ clientComments: event.target.value });
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleExitClick.bind(this,false)} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>Additional Information</DialogTitle>
                <DialogContent dividers>
                    <div uk-grid="true">
                        <div className="uk-width-1-1 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-attachment-description">Customer/Bank Reference</label>
                            <div className="">
                                <input
                                    className="uk-input"
                                    id="form-attachment-bankReference"
                                    type="text"
                                    placeholder="Bank Reference"
                                    value={this.state.bankReference}
                                    onChange={this.handleUpdateBankReference} />
                            </div>
                        </div>
                        <div className="uk-width-1-1 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-attachment-description">Internal Comments</label>
                            <div className="">
                                <textarea class="uk-textarea"
                                    id="form-attachment-internalComments"
                                    type="textarea"
                                    placeholder="Internal Comments"
                                    value={this.state.internalComments}
                                    onChange={this.handleUpdateInternalComments} />
                            </div>
                        </div>
                        <div className="uk-width-1-1 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-attachment-description">Client Comments</label>
                            <div className="">
                                <textarea class="uk-textarea"
                                    id="form-attachment-internalComments"
                                    type="textarea"
                                    placeholder="Client Comments"
                                    value={this.state.clientComments}
                                    onChange={this.handleUpdateClientComments} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"clear"} onClick={this.handleClearClick}>Clear Fields</button>
                    <button className="uk-button uk-button-green" key={"save"} onClick={this.handleSaveDealHeader}>Save</button>
                    <button className="uk-button uk-button-green" key={"exit"} onClick={this.handleExitClick.bind(this,false)}>Exit</button>
                </DialogActions>
            </Dialog>
        )
    }
}

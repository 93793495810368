import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export async function requestFacematchVerification(model){

    try{
        let response = await genericCallWithBody('POST','LemVerify/RequestFacematchVerification',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import { SourceTableNames } from '../../constants/SourceTableNames';
import { formatDate, formatToCurrencyWithScale, getScaleByCurrency } from '../../helpers/FormatHelper';
import { getNostroBankAccountsForFunds } from '../../networking/NetworkingBankAccounts';
import { getBeneficiaryForDisbursementsScreen } from '../../networking/NetworkingBeneficiaries';
import { getSameCurrencyDealRefunds, sameCurrencyDealRefund, voidSameCurrencyDealRefund } from '../../networking/NetworkingDeals';
import { createRefund, getRefunds } from '../../networking/NetworkingFunds';
import { getInstrumentsByCustomer } from '../../networking/NetworkingInstruments';
import { getBankAccountsByPerson } from '../../networking/NetworkingBankAccounts';
import { getCustomerByID } from '../../networking/NetworkingCustomers';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NumberInput from '../shared/NumberInput';

var style = getStyle();
export default class RefundForm extends React.Component {
    state = {
        alertMessage: '',
        alertTitle: '',
        amount: 0,
        balance: 0,
        beneficiaries: '',
        beneficiaryID: 0,
        bankAccountID: 0,
        deliveryMethod: '',
        deliveryMethods: {},
        instrument: '',
        listNostroAccounts: [],
        loading: false,
        loadingRefunds: false,
        nostroBankAccountID: 0,
        outgoingFundsID: 0,
        outgoingInstruments: [],
        recordToDelete: {},
        refunds: [],
        showAlert: false,
        showVoidAlert: false,
        validations: {
            amount: true,
            nostroAccount: true,
            beneficiary: true,
            valueDate: true,
            instrument: true
        },
        valueDate: new Date(),
    }

    componentDidMount() {
        var prop_instrument='';
        var prop_deliveryMethod='';
        var prop_nostroBankAccountID= 0;
        if(this.props.instrument !== undefined){
            prop_instrument = this.props.instrument;
        }
        if(this.props.deliveryMethod !== undefined){
            prop_deliveryMethod = this.props.deliveryMethod;
        }
        if(this.props.nostroBankAccountID !== undefined){
            prop_nostroBankAccountID = this.props.nostroBankAccountID;
        }
        //alert(this.props.sourceTable);
        //Get customer instruments
        getInstrumentsByCustomer(this.props.customerID).then(
            (json) => {
                //alert(JSON.stringify(json));
                let jsonInstruments = json.instruments;
                if (jsonInstruments !== undefined) {
                    var outgoingHelper = [];
                    var deliveryMethods = {};
                    for (var i = 0; i < jsonInstruments.length; i++) {
                        var instrumentElement = {
                            value: jsonInstruments[i].Instrument,
                            name: jsonInstruments[i].Instrument
                        };
                        if (jsonInstruments[i].InstrumentType === 'Outgoing') {
                            instrumentElement.deliveryMethods = jsonInstruments[i].DeliveryMethods;
                            instrumentElement.accountsOnly = jsonInstruments[i].AccountsOnly;
                            outgoingHelper.push(instrumentElement);

                            var list = [];
                            if (jsonInstruments[i].DeliveryMethods !== '') {
                                var methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                                for (var j = 0; j < methodsList.length; j++) {
                                    var item = { value: methodsList[j], name: methodsList[j] };
                                    list.push(item);
                                }
                            }
                            deliveryMethods[jsonInstruments[i].Instrument] = list;
                        }
                    }
                    this.setState({
                        outgoingInstruments: outgoingHelper,
                        deliveryMethods: deliveryMethods,
                        instrument: prop_instrument,
                        deliveryMethod: prop_deliveryMethod
                    });
                }
            }
        );

        //Get beneficiaries
        getBeneficiaryForDisbursementsScreen(this.props.customerID, this.props.currencyID).then(
            (response) => {
                if (response != null && response.beneficiaries != null) {
                    response.beneficiaries.forEach(element => {
                        element.value = element.Value;
                        element.name = element.Description;
                    });
                    if (this.props.sourceTable === SourceTableNames["FundOnHold"] || this.props.sourceTable === SourceTableNames["Deposit"]
                    || this.props.sourceTable === SourceTableNames["Payment"] 
                    ) {

                        var helper = response.beneficiaries;

                        this.setState({ beneficiaries: helper },
                            () => this.handleUpdateBeneficiary(this.state.beneficiaries[0]) );
                                 
                           /* getCustomerByID(this.props.customerID).then(
                            (customer) =>
                            {
                                if(customer != undefined)
                                {
                                    var PersonID = customer.PersonID;
                                    var CustomerName = customer.Name;
                                    getBankAccountsByPerson(PersonID).then(
                                    (json) => {
                                            if (json !== undefined && json.bankAccounts !== undefined) 
                                            {
                                                var helper = response.beneficiaries;
                                                const bankAccounts = json.bankAccounts.filter(bankAccount => bankAccount.Currency == this.props.currencyID && bankAccount.AccountType === "Actual / Local" && bankAccount['Active$']);

                                                if(bankAccounts.length > 0)
                                                {
                                                    bankAccounts.forEach(ba =>
                                                        {
                                                            helper.push({value: -1+'|'+ba['Account ID'], name: CustomerName + ' - ' +  this.props.currencyID + ' - ' + ba['Account Number'] });
                                                        }
                                                    );
                                                    helper = helper.sort((a, b) => a.value > b.value ? 1 : -1);
                                                    this.setState({ beneficiaries: helper },
                                                       () => this.handleUpdateBeneficiary(this.state.beneficiaries[0]) );
                                                }
                                                else{
                                                    var helper = response.beneficiaries;
                                                    helper.push({value: -1+'|0', name: CustomerName });
                                                    helper = helper.sort((a, b) => a.value > b.value ? 1 : -1);

                                                    this.setState({ beneficiaries: helper },
                                                        () => this.handleUpdateBeneficiary(this.state.beneficiaries[0]) );
                                                }
                                            }
                                            else{
                                                var helper = response.beneficiaries;
                                                helper.push({value: -1+'|0', name: CustomerName });
                                                helper = helper.sort((a, b) => a.value > b.value ? 1 : -1);

                                                this.setState({ beneficiaries: helper },
                                                    () => this.handleUpdateBeneficiary(this.state.beneficiaries[0]) );
                                            }
                                        }
                                    );
                                }
                            }
                            );*/
                        }
                        else
                     {
                        this.setState({ beneficiaries: response.beneficiaries });
                    }
                }
            }
        );

        //Load nostro accounts
        if (this.props.listNostroAccounts !== undefined) {
            this.setState({ listNostroAccounts: this.props.listNostroAccounts , nostroBankAccountID: prop_nostroBankAccountID});
        }
        else {
            this.loadNostroAccounts();
        }

        //Get refunds
        this.loadRefunds();

        //Load state
        this.setState({ balance: this.props.balance, amount: this.props.balance});
    }


    loadNostroAccounts = () => {
        getNostroBankAccountsForFunds(this.props.currencyID, this.props.customerID, false).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.nostroBankAccounts !== "undefined") {
                    let nostroAcounts = response.nostroBankAccounts;
                    nostroAcounts.forEach(element => {
                        element.value = element.BankAccountID;
                        element.name = element.BankName;
                    });
                    this.setState({
                        listNostroAccounts: nostroAcounts
                    });
                } else {
                    this.setState({ listNostroAccounts: [] });
                }
            }
        );
    }

    loadRefunds = () => {
        this.setState({ loadingRefunds: true });

        if (['P', 'H', 'D'].includes(this.props.sourceTable)) {
            var currencyID = '';
            if (this.props.currencyID !== undefined) {
                currencyID = this.props.currencyID;
            }
            getSameCurrencyDealRefunds(this.props.sourceTable, this.props.sourceID, currencyID).then(
                (response) => {
                    var refunds = [];
                    if (response.refunds !== undefined) {
                        refunds = response.refunds;
                    }
                    this.setState({
                        loadingRefunds: false,
                        refunds: refunds,
                    });
                }
            );
        }
        else {
            getRefunds(this.props.sourceTable, this.props.sourceID).then(
                (result) => {
                    var refunds = [];
                    if (result.refunds !== undefined) {
                        refunds = result.refunds;
                    }
                    this.setState({
                        loadingRefunds: false,
                        refunds: refunds
                    });
                }
            );
        }
    }

    handleAmountUpdate = (event) => {
        this.setState({ amount: event.target.value });
    }

    handleUpdateValueDate = (date) => {
        this.setState({ valueDate: date });
    }

    handleNostroBankAccount = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ nostroBankAccountID: obj.value });
        }
    }

    handleInstrumentUpdate = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ instrument: obj.value });
        }
    }

    handleUpdateBeneficiary = (obj) => {
        if (obj != null && obj.value != null) {
            let ids = obj.value.split('|');
            this.setState({
                beneficiaryID: ids[0],
                bankAccountID: ids[1],
            });
        }
    }

    handleDeliveryMethodUpdate = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ deliveryMethod: obj.value });
        }
    }

    onBackClick = (event) => {
        if (this.props.onBackClick) {
            this.props.onBackClick();
        }
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    validateSave = () => {
        var result = true;
        var validations = this.state.validations;

        validations.amount =
            validations.nostroAccount =
            validations.beneficiary =
            validations.valueDate =
            validations.instrument = true;

        if (this.state.amount === 0) {
            validations.amount = false;
            result = false;
        }
        else {
            if (this.state.amount > this.state.balance) {
                validations.amount = false;
                result = false;
            }
        }

        if (!(this.state.nostroBankAccountID > 0)) {
            validations.nostroAccount = false;
            result = false;
        }

        /*if (!(this.state.beneficiaryID > 0 || (Number(this.state.beneficiaryID) === -1 && this.props.sourceTable === SourceTableNames["FundOnHold"]))) {
            validations.beneficiary = false;
            result = false;
        }*/

        if (this.state.valueDate === undefined) {
            validations.valueDate = false;
            result = false;
        }

        if (this.state.instrument === undefined ||
            this.state.instrument === '' ||
            this.state.instrument === 'select') {
            validations.instrument = false;
            result = false;
        }

        this.setState({ validations: validations });
        return result;
    }

    onSaveClick = (event) => {
        if (this.validateSave()) {
            this.setState({ loading: true });
            if (false) {
                var objOutgoingFund = {
                    BeneficiaryBankAccountID: (this.state.bankAccountID == 0? null:this.state.bankAccountID),
                    BeneficiaryType: (this.state.beneficiaryID == -1)?1:0, //valida si es customer
                    BeneficiaryID: (this.state.beneficiaryID == -1)?null:this.state.beneficiaryID,
                    CreatedBy: localStorage.getItem('UserID'),
                    CreatedOn: formatDate(new Date()),
                    DeliveryMethod: this.state.deliveryMethod,
                    NostroAccount: this.state.nostroBankAccountID,
                    PaymentAmount: this.state.amount,
                    PaymentInstrument: this.state.instrument,
                    SourceID: this.props.sourceID,
                    SourceTable: this.props.sourceTable,
                    SourceUpdateToken: this.props.sourceUpdateToken,
                    Status: 'Completed',
                    UpdatedBy: localStorage.getItem('UserID'),
                    UpdateOn: formatDate(new Date()),
                    ValueDate: formatDate(this.state.valueDate),
                    VersionNo: 1,
                };

                createRefund(objOutgoingFund).then(
                    (response) => {
                        //alert(JSON.stringify(response));
                        if (Number(response.httpStatusCode) === 200) {
                            this.loadRefunds();
                            var balance = this.state.balance;
                            balance -= this.state.amount;
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: 'Refund created successfully!',
                                outgoingFundsID: response.OutgoingFundsID,
                                balance: balance,
                                loading: false,
                            });
                        }
                        else {
                            var alertMessage = 'Refund not created. Please try again or contact administrator';
                            if (response.httpErrorMessage !== undefined) {
                                alertMessage = response.httpErrorMessage;
                            }
                            this.setState({
                                alertMessage: alertMessage,
                                alertTitle: 'Error',
                                showAlert: true,
                                loading: false,
                            });
                        }
                    }
                );
            }
            else {
                let request = {
                    Amount: this.state.amount,
                    BeneficiaryID: this.state.beneficiaryID,
                    BeneficiaryBankAccountID: this.state.bankAccountID,
                    ContactID: null,
                    Currency: this.props.currencyID,
                    CustomerID: this.props.customerID,
                    DeliveryMethod: this.state.deliveryMethod,
                    Instrument: this.state.instrument,
                    NostroAccountID: this.state.nostroBankAccountID,
                    SourceTable: this.props.sourceTable,
                    SourceID: this.props.sourceID,
                    SourceUpdateToken: this.props.sourceUpdateToken,
                    UserID: localStorage.getItem('UserID'),
                    ValueDate: formatDate(this.state.valueDate),
                };
                sameCurrencyDealRefund(request).then(
                    (response) => {
                        var balance = this.state.balance;
                        var alertMessage = '', alertTitle = '';
                        if (Number(response.httpStatusCode) === 200) {
                            this.loadRefunds();
                            alertMessage = 'Refund created successfully!';
                            alertTitle = 'Success';
                            balance -= this.state.amount;
                        }
                        else {
                            alertMessage = 'Refund failed! Please try again or contact the administrator.';
                            if (response.httpErrorMessage !== undefined) {
                                alertMessage = response.httpErrorMessage;
                            }
                            alertTitle = 'Error'
                        }
                        this.setState({
                            alertMessage: alertMessage,
                            alertTitle: alertTitle,
                            balance,
                            showAlert: true,
                            loading: false,
                        });
                    }
                );
            }
        }
    }

    onClearFieldsClick = () => {
        this.setState({
            amount: 0,
            beneficiaryID: 0,
            bankAccountID: 0,
            deliveryMethod: '',
            instrument: '',
            nostroBankAccountID: 0,
            outgoingFundsID: 0,
            valueDate: undefined,
        });
    }

    closeAlert = () => {
        this.setState({ showAlert: false }, () =>{
            if(this.state.alertTitle === 'Success')
            {
                if (this.props.onBackClick) {
                    this.props.onBackClick();
                }
            }
        });
    }

    onRefundClick = (row) => {
        this.setState({
            amount: row['Refund Amount'],
            nostroBankAccountID: row['NostroAccount$'],
            valueDate: new Date(row['Value Date']),
            instrument: row['Instrument'],
            deliveryMethod: row['Delivery Method'],
            outgoingFundsID: row['Refund ID'],
            beneficiaryID: row['BeneficiaryID$'],
            bankAccountID: row['BeneficiaryBankAccountID$'],
        });
    }

    onDeleteRefundClick = (row) => {
        //alert(JSON.stringify(row));
        this.setState({
            recordToDelete: row,
            showVoidAlert: true,
        });
    }

    yesDeleteRefund = () => {
        this.setState({ showVoidAlert: false, loading: true });
        let recordToDelete = this.state.recordToDelete;
        voidSameCurrencyDealRefund(recordToDelete['DealHeaderID$'], localStorage.getItem('UserID'), recordToDelete['DealUpdateToken$']).then(
            (response) => {
                //alert(JSON.stringify(response));
                var alertTitle = '', alertMessage = '';
                var balance = this.state.balance;
                if (Number(response.httpStatusCode) === 200) {
                    this.loadRefunds();
                    alertTitle = 'Success';
                    alertMessage = 'Refund voided successfully!';
                    balance += Number(recordToDelete['Refund Amount']);
                    recordToDelete = {};
                } else {
                    alertTitle = 'Error';
                    alertMessage = 'Process failed. Please try again or contact the administrator.';
                    if (response.httpErrorMessage !== undefined) {
                        alertMessage = response.httpErrorMessage;
                    }
                }
                this.setState({
                    alertMessage: alertMessage,
                    alertTitle: alertTitle,
                    balance: balance,
                    loading: false,
                    recordToDelete: recordToDelete,
                    showAlert: true,
                });
            }
        );
    }

    noDeleteRefund = () => {
        this.setState({ showVoidAlert: false });
    }

    render() {
        //Get delivery methods
        var deliveryMethods = [];
        if (this.state.instrument !== undefined &&this.state.instrument !== '' && this.state.instrument !== 'select')
        {
            if(this.state.deliveryMethods[this.state.instrument] !== undefined)
            {
                deliveryMethods = this.state.deliveryMethods[this.state.instrument];
            }
        }

        //Save button
        var saveButton;
        if (this.state.outgoingFundsID === 0) {
            saveButton = (<button className="uk-button uk-button-green" onClick={this.onSaveClick}>Save</button>);
        }

        //Disable fields
        var disableFields = false;
        if (this.state.outgoingFundsID > 0) {
            disableFields = true;
        }

        var titleText = '';
        switch (this.props.sourceTable) {
            case SourceTableNames['Payment']:
                titleText = 'Payment ' + this.props.sourceID;
                break;
            case SourceTableNames['Deposit']:
                titleText = 'Deposit ' + this.props.sourceID;
                break;
            case SourceTableNames['FundOnHold']:
                titleText = 'Funds on Hold';
                break;
            default:
                titleText = 'Entity ' + this.props.sourceID;
                break;
        }

        let title = 'Refund for ' + titleText;

        return (
            <div className="uk-form-stacked">
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    {this.props.sourceTable === undefined && <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.onBackClick}>
                        <i className="fa fa-arrow-left back-arrow-white"></i>
                    </button>
                    }
                    {title}
                    
                    {/*To Right */}
                    <label className="uk-margin-small-left" style={{float:'right'}}>Balance {formatToCurrencyWithScale(this.state.balance, this.props.currencyID)}&nbsp;{this.props.currencyID}</label>
                </h3>
                <div uk-grid="true">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Amount*</label>
                        <NumberInput id="txt-amount"
                            className={this.formatValidInput(this.state.validations.amount)}
                            type="Currency"
                            scale={getScaleByCurrency(this.props.currencyID)}
                            value={this.state.amount}
                            onChange={this.handleAmountUpdate}
                            disabled={disableFields} />
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Beneficiary*</label>
                        <DynamicSelect id="select-beneficiary"
                            className={this.formatValidSelect(this.state.validations.beneficiary)}
                            placeholder="Select a Beneficiary..."
                            getValue={this.handleUpdateBeneficiary}
                            objValue={{ value: this.state.beneficiaryID + '|' + this.state.bankAccountID }}
                            data={this.state.beneficiaries}
                            disabled={false} />
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Value Date*</label>
                        <DatePicker id="dp-value-date"
                            className={this.formatValidInput(this.state.validations.valueDate)}
                            disabledDays={[0, 6]}
                            startDate={new Date()}
                            onDayChange={this.handleUpdateValueDate}
                            value={this.state.valueDate}
                            readOnly={true}
                            disabled={disableFields} />
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Instrument*</label>
                        <DynamicSelect id="select-instrument"
                            className={this.formatValidSelect(this.state.validations.instrument)}
                            placeholder="Select an Instrument..."
                            getValue={this.handleInstrumentUpdate}
                            objValue={{ value: this.state.instrument }}
                            data={this.state.outgoingInstruments}
                            disabled={disableFields} />
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Delivery Method</label>
                        <DynamicSelect id="select-delivery-method"
                            className="uk-select"
                            placeholder="Select a Delivery Method..."
                            getValue={this.handleDeliveryMethodUpdate}
                            objValue={{ value: this.state.deliveryMethod }}
                            data={deliveryMethods}
                            disabled={disableFields} />
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Nostro Account*</label>
                        <DynamicSelect id="select-nostro-accounts"
                            className={this.formatValidSelect(this.state.validations.nostroAccount)}
                            placeholder='Select an Account...'
                            data={this.state.listNostroAccounts}
                            objValue={{ value: this.state.nostroBankAccountID }}
                            getValue={this.handleNostroBankAccount}
                            disabled={disableFields} />
                    </div>
                </div>
                <div className="uk-grid uk-margin">
                    <div className="uk-margin-small-top" >
                        {saveButton}
                        <button className="uk-button uk-button-green" onClick={this.onClearFieldsClick}>Clear Fields</button>
                        {(this.props.sourceTable != null) &&
                            <button className="uk-button uk-button-green" onClick={this.onBackClick}>Close</button>
                        }
                    </div>
                </div>

                {/* <NewDynamicTable data={this.state.refunds}
                    enableClick={true}
                    clickFunction={this.onRefundClick}
                    newSearch={true}
                    useDeleteButton={true}
                    deleteFunction={this.onDeleteRefundClick}
                    formats={[{header:'Refund Amount', formatFunction: formatToCurrency}]}/> */}

                <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />

                <AlertBox id="delete-alert"
                    type="Yes/No"
                    open={this.state.showVoidAlert}
                    title="Confirmation"
                    message="Are you sure you want to void this refund?"
                    yesClick={this.yesDeleteRefund}
                    noClick={this.noDeleteRefund} />

                <LoadingBox loading={this.state.loading || this.state.loadingRefunds} />
            </div>
        );
    }
}
import React from 'react';
import BeneficiaryInformationForm from "./BeneficiaryInformation"
import { getStyle } from '../../../../styles/styles'
import AddressForm from './AddressForm';
import { getNewBeneficiaryKey, addBeneficiary, getBeneficiariesByID } from '../../../../networking/NetworkingBeneficiaries';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import LocalAccountForm from './LocalAccountForm';
import { saveBankAccount } from '../../../../networking/NetworkingBankAccounts';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
import logoLarge from '../../../../img/logo-large.png';
import logo from '../../../../img/logo.png';

var style = getStyle();

const version = "version 0.0.1"
const titleWebApp = "e2eFX Online"
export default class NewBeneficiaryMaintenance extends React.Component {

    state = {
        beneficiaryID: 0,
        updateToken: 0,
        isValidated: true,
        isValidatedAddress: true,
        isValidatedLocalAccount: true,
        beneInfoErrors: [],
        addressErrors: [],
        isClear: false,
        notFirstLoad: true,
        notFirstLoadBene: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        updateBeneficiary: false,
        pressedButton: false,
        saveProperties: false,
        saveTasks: false,
        viewHistory: false,
        loading: true,
        //OBJ FILTERS
        objNewBene: {},
        objNewAdd: null,
        objBankAccount: {}
    }
    componentDidMount() {
        var params = this.props.history.location.search;
        params = params.substr(5)
        console.log(params);
        getNewBeneficiaryKey(params).then(
            (json) => {
                if (json.httpStatusCode === 200) {
                    localStorage.setItem('SessionID', json.SessionID);
                    this.setState({ loading: false, objNewBeneKey: json },
                        () =>
                            this.getStatus()
                    )
                }
                console.log(json)
            }
        );
    }
    getStatus() {
        getParametersByName('BeneficiaryStandardMethodStatus').then(
            (json) => {
                this.setState({ beneStatus: json })
            }
        );
    }
    objFilterBeneInfo(objNewBene) {
        this.setState({ objNewBene: objNewBene })
    }
    objFilterAdd(objNewAdd) {
        this.setState({ objNewAdd: objNewAdd })
    }
    isValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadBene: true,
                beneInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                beneInfoErrors: { tab: "Beneficiary Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }
    isValidatedLocalAccount = (validator) => {
        if (validator) {
            this.setState({
                isValidatedLocalAccount: true,
                validateLocalAccountFields: true
            });
        } else {
            this.setState({ isValidatedLocalAccount: false });
        }
    }
    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '', updateBeneficiary: true });
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadBene: true, notFirstLoad: true, validateLocalAccountFields: true });
    }

    //Buttons
    handleClearFieldsClick = () => {
        this.setState({ isClear: true })
    }
    isClearForm = (value) => {
        this.setState({ isClear: value })
    }
    handleButtonSaveClick() {
        this.saveAll();
    }
    saveAll() {
        var beneID = 0, uptokBene = 0
        if (this.state.objNewBene.beneficiaryID !== '' && this.state.objNewBene.beneficiaryID > 0) {
            beneID = this.state.objNewBene.beneficiaryID
            uptokBene = this.state.objNewBene.updateToken
        }
        var objFilter = {
            BeneficiaryId: beneID,
            CustomerId: this.state.objNewBeneKey.CustomerId,
            StatusBeneficiary: this.state.beneStatus.parameters[0].Value,
            BeneficiaryType: this.state.objNewBene.customerType,
            BypassValidation: false,
            ContactEmail: this.state.objNewBene.contactEmail != null ? this.state.objNewBene.contactEmail : '',
            ContactPhone: this.state.objNewBene.contactPhone != null ? this.state.objNewBene.contactPhone : '',
            EmailIndicator: this.state.objNewBene.emailIndicator,
            DetailsOfPayment: '',
            ExpiryByPassDate: '',
            IndustrySector: this.state.objNewBene.industrySector !== undefined ? this.state.objNewBene.industrySector : '',
            ReferencesOfPayment: this.state.objNewBene.paymentReference != null ? this.state.objNewBene.paymentReference : '',
            ReceiveLimit: '',
            PreferredMethodOfPayment: 'Electronic Funds Transfer',
            PreferredDeliveryMethod: 'Wire Transfer',
            PaymentFlow: '',
            Salutation: this.state.objNewBene.salutation !== undefined ? this.state.objNewBene.salutation : '',
            Name: this.state.objNewBene.companyName,
            ContactName: this.state.objNewBene.contactName,
            FirstName: this.state.objNewBene.firstName !== null ? this.state.objNewBene.firstName : '',
            LastName: this.state.objNewBene.lastName !== null ? this.state.objNewBene.lastName : '',
            ShortName: this.state.objNewBene.shortName !== null ? this.state.objNewBene.shortName : '',
            Email: '',
            Phone: '',
            PhoneExt: '',
            OfficeFax: '',
            MobilePhone: '',
            UpdateToken: uptokBene,
            UserId: '',

            Address: this.state.objNewAdd !== undefined ? this.state.objNewAdd !== null ? {
                ...this.state.objNewAdd,
                Address2: this.state.objNewAdd.Address2 !== null ? this.state.objNewAdd.Address2 : '',
                POBox: this.state.objNewAdd.POBox !== null ? this.state.objNewAdd.POBox : '',
                SinceWhen: this.state.objNewAdd.SinceWhen !== null ? this.state.objNewAdd.SinceWhen : '',
            } : {} : {},
            BankAccounts: null
        }
        if (this.state.objNewAdd !== null) {
            if (this.state.objNewAdd.Address !== undefined) {
                objFilter.Address.AddressID = this.state.objNewAdd.Address[0] !== undefined ? this.state.objNewAdd.Address[0].AddressID : this.state.objNewAdd.Address.AddressID
            } else {
                objFilter.Address.AddressId = 0
            }
        } else {
            objFilter.Address.AddressId = 0
        }
        objFilter.Address.UpdateToken = this.state.objNewAdd != null ? this.state.objNewAdd.UpdateToken : undefined;
        if (this.state.isValidated && this.state.isValidatedAddress && this.state.isValidatedLocalAccount) {
            if ((this.state.objNewBene.isEmailValid && this.state.objNewBene.contactEmail !== '') || (!this.state.objNewBene.isEmailValid && this.state.objNewBene.contactEmail === '')) {
                addBeneficiary(objFilter).then(
                    (json) => {
                        if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to save the beneficiary. Please try again and complete the required fields(*).', alertTitle: 'Error' });
                        } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409) {
                            this.setState({ showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error' });
                        } else {
                            this.saveBank(json.beneficiaryId)
                        }
                    }
                );
                this.setState({ pressedButton: true });
            } else {
                this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadBene: false });
            }
        } else {
            if (this.state.isValidated && !this.state.isValidatedAddress && !this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false,
                    validateLocalAccountFields: false
                });
            } else if (this.state.isValidated && this.state.isValidatedAddress && !this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateLocalAccountFields: false
                });
            } else if (!this.state.isValidated && this.state.isValidatedAddress && this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress && this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false,
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress && !this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false,
                    notFirstLoad: false,
                    validateLocalAccountFields: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false
                });
            }
        }
    }

    saveBank(beneficiaryId) {
        getBeneficiariesByID(beneficiaryId).then(
            (json) => {
                var objBene = this.state.objNewBene
                var objAdd = this.state.objNewAdd
                //---Address ID + PersonID
                objAdd.AddressId = json.beneficiary[0].Address[0].AddressID
                objAdd.UpdateToken = json.beneficiary[0].Address[0].UpdateToken
                //---Bene ID + UpdateToken
                objBene.beneficiaryID = json.beneficiary[0].BeneficiaryId
                objBene.updateToken = json.beneficiary[0].UpdateToken
                console.log(json);
                this.setState({ objNewBene: objBene, objNewAdd: objAdd })
                if (this.state.isValidatedLocalAccount) {
                    var model = {
                        localAccount: {
                            ABANo: this.state.localAccount.ABANo,
                            AccountCurrencyID: this.state.localAccount.Currency,
                            AccountType: "Actual / Local",
                            Active: true,
                            BankAccount: this.state.localAccount.AccountNo,
                            BankAccountID: '',
                            BankAccountParentID: null,
                            BankBranchID: this.state.localAccount.BranchID,
                            BankID: this.state.localAccount.BankID,
                            CreatedOn: this.state.localAccount.CreatedOn,
                            DefaultAccount: this.state.localAccount.DefaultAccount,
                            DefaultDeliveryMethod: null,
                            Description: this.state.localAccount.Description,
                            GLAccountID: 0,
                            IsSettlementAccount: false,
                            PersonID: json.beneficiary[0].PersonId,
                            PublishOnline: false,
                            Swift: this.state.localAccount.Swift,
                            TransitNo: this.state.localAccount.TransitNo,
                            UpdateToken: this.state.localAccount.UpdateToken,
                            UserID: localStorage.getItem('UserID')
                        },
                        intermediateAccount: null,
                        correspondantAccount: null
                    }
                    saveBankAccount(model).then(
                        (json) => {
                            if (json.httpStatusCode !== 200) {
                                this.setState({ showAlertError: true, alertMessage: 'An error occurred while trying to save the bank account. Please try again.', alertTitle: 'Error' });
                            } else {
                                this.setState({ saveProperties: true, saveTasks: true }, () => this.redireccionar())
                            }
                        }
                    )
                } else {
                    this.setState({ showAlertError: true, alertMessage: 'Please, add required Bank Account data', alertTitle: 'Error' });
                }
            }
        );
    }
    redireccionar = () => {
        this.props.history.push('/SuccessBeneficiary');
    }

    getLocalAccount = (localAccount) => {
        this.setState({ localAccount: localAccount });
    }
    render() {
        if (this.state.loading) {
            return (<LoadingBox loading={this.state.loading} />);
        }
        return (
            <div className="uk-card uk-card-default uk-card-body" style={{ padding: '10px 0px 0px 0px' }}>
                <div className="header-container clearfix">
                    <header className="wrapper">
                        <a href="/#">
                            <img id="logo-large" title={titleWebApp} alt={titleWebApp} src={logoLarge} />
                        </a>
                        <h2>{version}</h2>
                    </header>
                </div>

                <div className="uk-margin-medium-bottom uk-margin-medium-right uk-margin-medium-left">
                    <div className="uk-grid">
                        <div className="uk-width-1-2 main-container">
                            <header class="clearfix">
                                <h3>
                                    Beneficiary and Banking Information
                                <span class="fa fa-info-circle fnt-size-1 success badge-pointer" title="Guide Help" id="page_manage_beneficiaries_guide_help"
                                        hidden={!this.state.activeHelpGuide}></span>
                                </h3>
                            </header>
                        </div>
                        <div className="uk-flex uk-flex-center@m uk-flex-right@l uk-width-1-2">
                            <div className="uk-card uk-card-default uk-card-small uk-card-body" style={{ width: '90%', fontWeight: 'bold', fontSize: '0.750rem', color: '#fff', background: '#00548d' }}>
                                <p>
                                    Please make sure that your banking information is accurate as it will be used to send the funds to you
                                <br></br>
                                Your information will be forwarded to your customer after you save it for their review
                            </p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <ul className="ul-options" uk-tab="">
                            <li className="li-options">
                                <a className="li-a-options" href="#" id="beneficiary-information">Beneficiary Information</a>
                            </li>
                            <li className="li-options">
                                <a className="li-a-options" href="#" id="address-information">Address</a>
                            </li>
                            <li className="li-options">
                                <a className="li-a-options" href="#" id="account-information">Bank Account Info</a>
                            </li>
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            <li>
                                <BeneficiaryInformationForm clearFields={this.state.isClear} clearForm={this.isClearForm} objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} isValid={this.isValidated} notFirstLoad={this.state.notFirstLoadBene} objNewBeneKey={this.state.objNewBeneKey} />
                            </li>
                            <li>
                                <AddressForm clearFields={this.state.isClear} selectedAddress={this.state.objNewAdd} getAddressInfo={this.objFilterAdd.bind(this)} isValid={this.isValidatedAddress} validations={this.state.notFirstLoad} />
                            </li>
                            <li>
                                <LocalAccountForm clearFields={this.state.isClear} getLocalAccount={this.getLocalAccount} isValid={this.isValidatedLocalAccount} validated={this.state.validateLocalAccountFields} />
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button id="btn-add-new-beneficiary" class="button_style_smaller bck-info mrg-b-0" onClick={this.handleClearFieldsClick}>Clear Fields</button>
                        <button id="btn-add-new-beneficiary" class="button_style_smaller bck-info mrg-b-0" onClick={this.handleButtonSaveClick.bind(this)}>Submit</button>
                    </div>
                </div>

                <div className="footer-container" role="complementary">
                    <footer className="wrapper">
                        <section className="section group">
                            <div className="col span_1_of_1 txt-a-c pad-t-20p pad-b-40p">
                                <img src={logo} id="id-logo-large" title="Home" alt="Home" onClick={this.props.handleHomeClick} />
                            </div>
                        </section>
                        <section className="section group">
                            <div className="col span_1_of_3 txt-a-c">
                            </div>
                            <div className="col span_1_of_3 txt-a-c">
                                <p>
                                    <a href="#" className="footer-link" title="Terms of Use" target="_blank">Terms of Use</a>
                                </p>
                            </div>
                            <div className="col span_1_of_3 txt-a-c">
                                <p>
                                    <a href="#" className="footer-link" title="Technical Information" target="_blank">Technical Information</a>
                                </p>
                            </div>
                        </section>
                    </footer>
                </div>
                {/* <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />*/}
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.beneInfoErrors, this.state.addressErrors]} type="Ok" okClick={this.closeAlertError.bind(this)} />
            </div>
        );
    }
}
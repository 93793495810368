import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import AlertBox from '../../shared/AlertBox';
import { saveDetails, getListOfOnlineUserProfile } from '../../../networking/NetworkingOnlineUserProfile';
import { getAllRoles } from '../../../networking/NetworkingRoles';
import { validate } from '../../../helpers/ValidateHelper';
import { formatValidSelect } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
import { ThreeSixtyOutlined } from '@material-ui/icons';
import {Colors} from '../../../constants/Colors'

import AppContext from '../../../AppContext';
let globalFunctions = {};


const style = getStyle();

export default class OnlineUserProfilesDetailForm extends React.Component{
    static contextType = AppContext;

    state = {
        alertTitle: '',
        alertMessage: '',
        listHave: [],
        listWant: [],
        profileNames: [],
        profileSelectName: '',
        profileSelectValue: 0,
        roles: [],
        selectIDLeft:'',
        selectIDRight:'',
        showAlert: false,
        showAlertError: false,
        temp: [],
        validations: {
            profileSelectValue: true,
        }
    }
    
    componentDidMount(){
        globalFunctions = this.context;
        globalFunctions.startLoading();
        getListOfOnlineUserProfile('').then(
            (json) => {
                var profileNamesMin = json.onlineUserProfiles;
                var helper = [];
                for(var i=0;i<profileNamesMin.length;i++){
                    profileNamesMin[i].name = profileNamesMin[i]['OnlineUserProfileName'];
                    profileNamesMin[i].value = profileNamesMin[i]['OnlineUserProfileID'];
                    helper.push(profileNamesMin[i]);
                }
                this.setState({profileNames: helper});
                globalFunctions.finishLoading();
            }
        );
        
        getAllRoles("ForeignExchange", 1, 100).then(
            (value) => {
                this.setState({ listHave: value.Roles, roles: value.Roles });
                globalFunctions.finishLoading();
            }
        );

        if(this.props.selectedOnlineUserProfile !== {}){
            let profileName = this.props.selectedOnlineUserProfile['Profile Name'];
            let profileID = this.props.selectedOnlineUserProfile.OnlineUserProfileID;

            this.getProfileNameSelect({value: String(profileID), name: profileName});

        }
    }
    
    componentDidUpdate(prevProps){}
    
    
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }
    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }

    //Inputs Validations
    validateRequiredInputs(){
        const fields = [
            {displayName:'Profile', validateName: 'profileSelectValue', value:this.state.profileSelectValue, type: 'string'},
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    handleSaveClick(event){
        event.preventDefault();

        var userProfileDetails = {
            'OnlineUserProfileID': this.state.profileSelectValue,
            'UpdatedBy': localStorage.getItem('UserID'),
            'Roles': this.transformListWant(this.state.listWant)
        }
        
        if(this.validateRequiredInputs()){
            console.log(userProfileDetails);
            saveDetails(userProfileDetails).then(
                (json) =>{
                    if (json.httpStatusCode != undefined && json.httpStatusCode !== 200){
                        var alertMsg = 'An error occurred while trying to save the Fee Structure. Please try again.'
                        if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                            alertMsg = json.httpErrorMessage;
                        }
                        this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error', updateToken: json.updateToken});
                    } else {
                        this.setState({
                            profileSelectValue: 0,
                            profileSelectName: '',
                            listWant: [],
                            listHave: this.state.roles,
                            showAlert: true, alertTitle: 'Online user profile detail successfully saved.'});
                    }                                   
                }
            );
        }
    }
    
    transformListWant(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push(list[i]['Role Name']);
        }
        return helper;
    }
    
    transformList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push(list[i]['RoleName']);
        }
        return helper;
    }

    getProfileNameSelect(value){
        globalFunctions.startLoading();
        console.log(value);
        if(value === 0) {
            value = {value: String(this.state.profileSelectValue), name: this.state.profileSelectName};
        }    
        this.setState({listWant: []});
        if(value.value === '' || value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({profileSelectValue: ''});
            this.props.profileSelectValue(undefined);
        }
        else{   
            //this.setState({listWant: []});
            getListOfOnlineUserProfile(value.name).then(
                (json) => {
                    if(json !== undefined && json.onlineUserProfiles !== undefined && json.onlineUserProfiles.length >0){
                        var profileNamesMin = json.onlineUserProfiles;
                        var helper = [];
                        if(profileNamesMin[0]['onlineUserProfilesDetails'] !== undefined && profileNamesMin[0]['onlineUserProfilesDetails'] !== null){
                            for(var i=0;i<profileNamesMin.length;i++){
                                helper.push(profileNamesMin[i]['onlineUserProfilesDetails']);
                            }
                            
                            let tempList = this.transformList(helper[0]);
                            for(var i=0;i<tempList.length;i++){
                                this.fillWantList(tempList[i]);
                            }
                            //this.setState({listWant: this.transformList(helper[0])});
                        }
                    }
                    globalFunctions.finishLoading();
                }
            );
            this.setState({validations: {...this.state.validations, profileSelectValue: true},profileSelectValue: parseInt(value.value), profileSelectName: value.name});
            //this.props.profileSelectValue(value.value);
        }        
    }

    fillWantList(idSelected){
        //var listLeft = this.state.listHave;
        var listLeft = [...this.state.roles];
        var listRight = this.state.listWant;
        var add = true
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((role, index) => {
                    if(role['Role Name'] == idSelected)
                    {
                        listRight.push(role)
                        listLeft.splice(index,1)
                        this.setState({
                            listWant:  listRight,
                            listHave: listLeft,
                            selectIDLeft: ''}
                        );
                    }
                });
                
            }else
            {
                listRight.forEach(rightRole => {
                    if(rightRole['Role Name'] == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftRole, index) => {
                            if(leftRole['Role Name'] == idSelected)
                            {
                                listRight.push(leftRole)
                                listLeft.splice(index,1)
                                this.setState({
                                    listWant:  listRight,
                                    listHave: listLeft,
                                    selectIDLeft: ''}
                                );
                            }
                        });
                    }
            }
        }
    }
    
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
          this.setState({selectIDLeft});
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
          this.setState({selectIDRight});
        }
    };

    handleAddClick= (event) =>{
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDLeft;
        var add = true
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((role, index) => {
                    if(role['Role Name'] == idSelected)
                    {
                        listRight.push(role)
                        listLeft.splice(index,1)
                        this.setState({
                            listWant:  listRight,
                            listHave: listLeft,
                            selectIDLeft: ''}
                        );
                    }
                });
                
            }else
            {
                listRight.forEach(rightRole => {
                    if(rightRole['Role Name'] == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftRole, index) => {
                            if(leftRole['Role Name'] == idSelected)
                            {
                                listRight.push(leftRole)
                                listLeft.splice(index,1)
                                this.setState({
                                    listWant:  listRight,
                                    listHave: listLeft,
                                    selectIDLeft: ''}
                                );
                            }
                        });
                    }
            }
        }
    }
    
    handleRemoveClick= (event) =>{
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDRight;
        var add = true

        if(idSelected != '')
        {
            listRight.forEach((rightRole, index) => {
                if(rightRole['Role Name'] == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightRole)
                }
                this.setState({
                        listWant:  listRight,
                        listHave: listLeft,
                        selectIDRight: ''}
                    );
            });
        }
    }
    
    render(){
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        return(
            <div>
                <div className="uk-width-1-2 uk-form-stacked">
                    <div className="uk-margin" style={{marginBottom: 10}}>
                        <label className="uk-form-label" htmlFor="form-online-user-profiles-detail-profile" style={style.boldLabel}>Profile (*)</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={formatValidSelect(this.state.validations.profileSelectValue)} id="form-online-user-profiles-detail-profile" getValue={this.getProfileNameSelect.bind(this)} placeholder="Select a Profile Name..." data={this.state.profileNames} 
                            objValue={{value: this.state.profileSelectValue, name: this.state.profileSelectName}}/>  
                        </div>
                    </div>
                    {/*<div className="uk-width-1-2 uk-form-stacked"></div>*/}
                    <div className="uk-width-2-2 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <hr/>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <div
                            className="uk-margin"
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                border:'1px solid #9DA99E'
                            }}
                        >
                            <div
                                className="uk-width-1-3"
                                style={{
                                    overflowY: 'scroll',
                                    height: 500,
                                }}
                            >
                                <table 
                                    className="uk-table uk-table-hover uk-table-divider"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <tbody>
                                        {listLeft.map((item, i) => {
                                            return (
                                            <tr 
                                                key={item['Role Name']} onClick={this.roleChangeColorLeft(item['Role Name'])}
                                                style={
                                                    this.state.selectIDLeft === item['Role Name'] ?
                                                    {backgroundColor: Colors.primaryColor, cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'} 
                                                }
                                            >
                                                <td
                                                    style={
                                                        this.state.selectIDLeft === item['Role Name'] ?
                                                        {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                    }
                                                >{item['Role Name']}</td>
                                            </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div 
                                className="uk-card-body uk-width-1-3"
                                style={{
                                    textAlign:'center',
                                    borderRight:'1px solid #9DA99E',
                                    borderLeft:'1px solid #9DA99E'
                                }}
                            >
                                <button 
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleAddClick.bind(this)}
                                >
                                    Add
                                </button>
                                <button 
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleRemoveClick.bind(this)}
                                >
                                    Remove
                                </button>
                            </div>
                            <div
                                className="uk-width-1-3"
                                style={{
                                    overflowY: 'scroll',
                                    height: 500,
                                    paddingLeft: 0,
                                }}
                            >
                                <table 
                                    className="uk-table uk-table-hover uk-table-divider"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                        <tbody>
                                            {listRight.map((item, i) => {
                                                return (
                                                <tr 
                                                    key={item['Role Name']} onClick={this.roleChangeColorRight(item['Role Name'])} 
                                                    style={
                                                        this.state.selectIDRight === item['Role Name'] ?
                                                        {backgroundColor: '#c3824a', cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'}
                                                    }
                                                >
                                                    <td
                                                        style={
                                                            this.state.selectIDRight === item['Role Name'] ?
                                                            {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                        }
                                                    >{item['Role Name']}</td>
                                                </tr>
                                                );
                                            })}
                                        </tbody>
                                </table>
                            </div>
                        </div>
                    
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>
                </div>
            </div>
        );
    }
}

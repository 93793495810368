import React from 'react';
import NumberInput from '../../shared/NumberInput';
import DynamicSelect from '../../shared/DynamicSelect';
import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'
import {getRatePricingManager} from '../../../networking/NetworkingRates'
import {getCurrenciesByCustomer} from '../../../networking/NetworkingCurrencies'
import AlertBox from '../../shared/AlertBox'
import {getScaleByCurrency, roundAmountByCurrency, formatToCurrencyWithScale, roundAmountByCurrencyPair} from '../../../helpers/FormatHelper'
export default class MarginCallsDetails extends React.Component {

    state={
        marginCall: {},
        listActiveCurrencies: [],
        listDeals: [],
        showAlert:false,
        alertMessage: ''
    }

    componentDidMount()
    {
        var listDeals = [];
       /* if(this.props.listActiveCurrencies)
        {
            listActiveCurrencies = this.props.listActiveCurrencies;
        }*/
        if(this.props.marginCall != undefined)
        {
            if(this.props.marginCall['Deals'] != undefined)
            {
                listDeals = JSON.parse(this.props.marginCall['Deals']);                
            }

            this.loadCurrencies(this.props.marginCall.CustomerID);
        }

        this.setState({
            listDeals: listDeals});
    }

    componentDidUpdate(prevProps)
    {
        if(prevProps.marginCall != this.props.marginCall &&  this.props.marginCall != undefined)
        {
            //debugger;
            this.loadCurrencies(this.props.marginCall.CustomerID);
        }
    }

    loadCurrencies(customerID)
    {        
        getCurrenciesByCustomer(customerID).then(
            (json) => {
                let jsonCurrencies = json.currencies;
                var settlementHelper = [];
                if (jsonCurrencies !== undefined) {
                    for (var i = 0; i < jsonCurrencies.length; i++) {
                        var currencyElement = {
                            value: jsonCurrencies[i].CurrencyID,
                            name: jsonCurrencies[i].CurrencyIDName
                        };
                        switch (jsonCurrencies[i].Type) {
                            case 'Settlement':
                                settlementHelper.push(currencyElement);
                                break;
                            default:
                                break;
                        }
                    }
                }

                this.setState({
                    listActiveCurrencies: settlementHelper
                });
            }
        );
    }

    handleNewMarginCallAmount(data, event)
    {
        let tempDeals = this.state.listDeals;
        let newMarginCallAmount = event.target.value;        
        var dealChangedIndex = tempDeals.findIndex(item => item.DealHeaderID === data.recordID);
        var dealChanged = tempDeals[dealChangedIndex];
        this.OnCalulateRatio(tempDeals, newMarginCallAmount, dealChangedIndex, dealChanged);
    }

    OnCalulateRatio(tempDeals, newMarginCallAmount, dealChangedIndex, dealChanged)
    {
        let baseCurrency = localStorage.getItem(GlobalSystemParametersNames.BaseCurrency);
        var amountOut = this.GetAmounts(dealChanged.NewMarginCallRate,newMarginCallAmount,0,false,dealChanged.NewMarginCallRateTerms);
        var marginCallEquivalentAmount = amountOut.toFixed(dealChanged.MarginCallNroOfDecimal);

        var newExposureRatio = 0; 
        var newExposure = 0;
        var isCrossCCY = false;
        
        if(marginCallEquivalentAmount < 0)
        {
            return false;
        }
        else
        {
            
            if (dealChanged.BuyCurrency != baseCurrency &&
                dealChanged.SellCurrency != baseCurrency)
            {
                isCrossCCY = true;
            }

            var marginCall = parseFloat(marginCallEquivalentAmount);
            var deposit = parseFloat(dealChanged.DepositAmountAssigned);
            
            if(isCrossCCY)
            {
                console.log('iscross');
                marginCall = this.GetAmounts (dealChanged.FwdMarketRateXCCY, 0, marginCallEquivalentAmount, 1, dealChanged.RateTermsXCCY) 		
                deposit = this.GetAmounts (dealChanged.FwdMarketRateXCCY, 0, dealChanged.DepositAmountAssigned, 1, dealChanged.RateTermsXCCY) 
            }

            newExposure = ((deposit + parseFloat(dealChanged.GrossExposure) + marginCall)).toFixed(dealChanged.M2MNroOfDecimals);
           
            newExposureRatio = this.GetNewNetExposureRatio(newExposure, dealChanged.BuyCurrency, 
                dealChanged.BuyAmount, dealChanged.SellCurrency, dealChanged.SellAmount, baseCurrency);

        }
        
        tempDeals[dealChangedIndex]['NewNetExposure'] = newExposure;
        tempDeals[dealChangedIndex]['NewNetExposureRatio'] = newExposureRatio;
        tempDeals[dealChangedIndex]['MarginCallEquivalentAmount'] = marginCallEquivalentAmount;
        tempDeals[dealChangedIndex]['NewMarginCallAmount'] = newMarginCallAmount;

        this.setState({
            listDeals: tempDeals
        });

        return true;
    }

    GetNewNetExposureRatio(netExposure, buyCurrency, buyAmount, sellCurrency, sellAmount, baseCurrency)
    {
        var result = 0;
        var amount = 0;
        var ccy = buyAmount;

        if(buyCurrency != baseCurrency && sellCurrency == baseCurrency)
	    {
            amount = sellAmount;
            ccy = sellAmount;
        }
        else
        {    if ((buyCurrency == baseCurrency && sellCurrency != baseCurrency) || 
				(buyCurrency != baseCurrency && sellCurrency != baseCurrency))	
            {
                amount = buyAmount;
            }
        }
	
	
        if(amount != 0)
        {
            result = netExposure / amount * 100;
        }
        else
        {
            result = 0
        }


        return roundAmountByCurrency(result,ccy);
    }

    GetAmounts(rate, sellAmountIn, buyAmountIn, calculateSellAmount, rateTerms)
    {
            
        var amountOut = 0;
        if (rateTerms == 'D')
        {
            if (calculateSellAmount)
            {
                 if (rate == 0)
                {
                    amountOut = 0;
                }
                else
                {
                    amountOut = buyAmountIn / rate;
                }
            }
            else
            {
                amountOut = sellAmountIn * rate;
            }
        }
        else 
            if (rateTerms == 'I')
            {
                if (calculateSellAmount)
                {
                    amountOut = buyAmountIn * rate;
                }
                else
                {
                    if (rate != 0)
                        amountOut = sellAmountIn / rate;
                    else
                        amountOut = 0;
                }
            }
       
        return amountOut
     }
            

    handleNewNetExposureRatio(data, event)
    {
        let baseCurrency = localStorage.getItem(GlobalSystemParametersNames.BaseCurrency);
        let tempDeals = this.state.listDeals;
        let newNetExposureRatio = event.target.value;        
        var dealChangedIndex = tempDeals.findIndex(item => item.DealHeaderID === data.recordID);
        var dealChanged = tempDeals[dealChangedIndex];

        var newMarginCall = parseFloat(0);
        var depositAmount = parseFloat(dealChanged.DepositAmountAssigned);
        var amount = parseFloat(0);

        if (dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency != baseCurrency)
		{
		    depositAmount = this.GetAmounts(parseFloat(dealChanged.RateXCCY), 0, parseFloat(dealChanged.DepositAmountAssigned), 1, dealChanged.RateTermsXCCY) 
	    }
	
        /*
            1:  Buy Currency <> Base Currency And Sell Currency = Base Currency === SellAmount 
            2:  (Buy Currency = Base Currency And Sell Currency <> Base Currency) Or XCCY === BuyAmount
        */
        if(dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency == baseCurrency)
        {
            amount = parseFloat(dealChanged.SellAmount)
        }
        else 
        {   
            if ((dealChanged.BuyCurrency == baseCurrency && dealChanged.SellCurrency != baseCurrency	) 
                || (dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency != baseCurrency	))
            {
                amount = parseFloat(dealChanged.BuyAmount)
            }
        }
        if(dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency == baseCurrency)
        {
            newMarginCall = (((newNetExposureRatio / 100) * amount) - depositAmount - (amount - parseFloat(dealChanged.M2MAmount))).toFixed(dealChanged.M2MNroOfDecimals);
            
        }
        else 
            if ((dealChanged.BuyCurrency == baseCurrency && dealChanged.SellCurrency != baseCurrency	) 
            || (dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency != baseCurrency	))
            {
                newMarginCall = (((newNetExposureRatio / 100) * amount) - depositAmount - (parseFloat(dealChanged.M2MAmount) - amount)).toFixed(dealChanged.M2MNroOfDecimals);
            }
        
        //Convert to base amount
        if (dealChanged.BuyCurrency != baseCurrency && dealChanged.SellCurrency != baseCurrency)
        {	
            newMarginCall = this.GetAmounts(dealChanged.RateXCCY, newMarginCall, 0, 0, dealChanged.RateTermsXCCY); 
        }
        
        if(newMarginCall < 0)
        {
            return false;
        }
        else
        {
            var amountOut = this.GetAmounts(parseFloat(dealChanged.NewMarginCallRate),0,newMarginCall,true,dealChanged.NewMarginCallRateTerms);
            
            tempDeals[dealChangedIndex]['NewNetExposureRatio'] = newNetExposureRatio;
            tempDeals[dealChangedIndex]['MarginCallEquivalentAmount'] = parseFloat(newMarginCall).toFixed(dealChanged.MarginCallNroOfDecimal);
            tempDeals[dealChangedIndex]['NewMarginCallAmount'] = parseFloat(amountOut).toFixed(dealChanged.MarginCallNroOfDecimal);

            this.setState({
                listDeals: tempDeals
            });
        }
    }

    handleActiveCurrencies(data, value)
    {
        if(value != undefined && value.value != undefined)
        {
            let baseCurrency = localStorage.getItem(GlobalSystemParametersNames.BaseCurrency);
            let tempDeals = this.state.listDeals;
            var currency = value.value;   //NewMarginCallCurrency     
            var dealChangedIndex = tempDeals.findIndex(item => item.DealHeaderID === data.recordID);
            var dealChanged = tempDeals[dealChangedIndex];
            var newMarginCallAmount = dealChanged.NewMarginCallAmount; 
            
            if(currency != dealChanged.NewMarginCallCurrency)
            {
                getRatePricingManager(dealChanged.CustomerID,
                    dealChanged.DefaultPricingID,
                    0,
                    0,
                    baseCurrency,
                    currency,
                    dealChanged.NewMarginCallAmount,
                    false,
                    false,
                    null, 
                    null,
                    null,
                    null
                    ).then(
                (json) => {
                    if(json != undefined && json.MarketRate != undefined)
                    {
                        if(json.MarketRate > 0)
                        {   
                            if(json.RateTerms !== '\0')
                            {
                                let marketRate = json.MarketRate;
                                let rateTerms = json.RateTerms;

                                //nroDecimals

                                tempDeals[dealChangedIndex]['NewMarginCallRate'] = marketRate;
                                tempDeals[dealChangedIndex]['NewMarginCallRateTerms'] = rateTerms;
                                tempDeals[dealChangedIndex]['NewMarginCallCurrency'] = currency;
                                
                                this.setState({
                                    listDeals: tempDeals
                                },
                                    () => {this.OnCalulateRatio(tempDeals, newMarginCallAmount, dealChangedIndex, dealChanged);}
                                );
                            }
                            else
                            {
                                this.setState({alertMessage: 'Cannot calculate margin call amount. Rate terms not defined for currency pair (' + baseCurrency+ "/" + currency + ")",
                                showAlert: true});
                            }
                        }
                        else
                        {
                            this.setState({alertMessage: 'Market Rate not defined for currency pair (' + baseCurrency+ "/" + currency + ")",
                            showAlert: true});
                        }
                    }
                    else
                    {
                        this.setState({alertMessage: 'Rate not found for currency pair (' + baseCurrency+ "/" + currency + ")",
                            showAlert: true});
                    }
                });

                
            }
        }
    }

    handleBackClick = () =>
    {
        var deals = JSON.stringify(this.state.listDeals);
        if(this.props.marginCallList !== undefined)
        {
            var tempMargin = this.props.marginCallList;
            var changedIndex = tempMargin.findIndex(item => item.CustomerID === this.props.marginCall.CustomerID); 
            //console.log(tempMargin);
            tempMargin[changedIndex]['Deals'] = deals;
                
           /*     this.setState({
                    marginCallsAux: tempMargin,
                    marginCalls: this.transformMarginCallList(tempMargin)
                });
            */
            this.props.handleBackClick(tempMargin);
        }
    }

    okGlobalAlert = () =>
    {
        this.setState({showAlert: false});
    }

    render()
    {
        var customerName ='';
        var exposureRatio = 0;
        var totalDeposits =0;
        var currency = '';
        var headersDeals= (<tr>
                    <th>Deal No.</th>
                    <th>Far Date</th>
                    <th>Buy Balance</th>
                    <th>Buy Currency</th>
                    <th>Sell Ballance</th>
                    <th>Sell Currency</th>
                    <th>Margin Call</th>
                    <th>Currency</th>
                    <th>Exposure Ratio</th>
                </tr>);
        var rowsDeals= [];
        var headersDeposits= (<tr>
                    <th>Created On</th>
                    <th>Deposit Received Status</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Base Amount</th>
                    <th>Base Currency</th>
                    <th>Rate</th>
                </tr>);
        var rowsDeposits= [];

        if(this.props.marginCall != undefined)
        {
            customerName = this.props.marginCall['Customer Name'];
            exposureRatio = this.props.marginCall['Total Exposure'];
            totalDeposits = this.props.marginCall['Total Deposits'];
            currency = this.props.marginCall['MarginCallCurrency'];
            
            //Deals
            if(this.state.listDeals != undefined)
            {
                var listDeals = this.state.listDeals;

                listDeals.forEach(element => {
                    rowsDeals.push(<tr key={element['DealHeaderID']}>
                        <td>{element['DealHeaderID']}</td>
                        <td>{element['MaturityDate']}</td>
                        <td>{formatToCurrencyWithScale(element['BuyAmount'], element['BuyCurrency'])}</td>
                        <td>{element['BuyCurrency']}</td>
                        <td>{formatToCurrencyWithScale(element['SellAmount'], element['SellCurrency'])}</td>
                        <td>{element['SellCurrency']}</td>
                        <td>
                            <NumberInput id={'newMarginCallAmount-' + element['DealHeaderID']}
                                type="Currency"
                                negative={true}
                                value={element['NewMarginCallAmount']}
                                className="uk-input"
                                scale={getScaleByCurrency(element['NewMarginCallCurrency'])}
                                onChange={this.handleNewMarginCallAmount.bind(this,
                                    {
                                        recordID: element['DealHeaderID']
                                    })}
                            />
                        </td>
                        <td>{}
                            <DynamicSelect id="form-incomingpaymentform-currency"
                                className="regularSelectClass"
                                placeholder='Select a currency'
                                data={this.state.listActiveCurrencies}
                                objValue={{ value: element['NewMarginCallCurrency']}}
                                getValue={this.handleActiveCurrencies.bind(this, {recordID: element['DealHeaderID']})} />
                        </td>
                        <td>
                            <NumberInput id={'amounttouse-' + element['DealHeaderID']}
                                type="Currency"
                                negative={true}
                                value={element['NewNetExposureRatio']}
                                className="uk-input"
                                scale={2}//getScaleByCurrency(element['NewMarginCallCurrency'])}
                                onChange={this.handleNewNetExposureRatio.bind(this,
                                    {
                                        recordID: element['DealHeaderID']
                                    })}
                            />
                        </td>
                    </tr>);
                });
            }

            //Deposits
            if(this.props.marginCall['Deposits'] != undefined)
            {
                var listDeposits = JSON.parse(this.props.marginCall['Deposits']);

                listDeposits.forEach(element => {
                    rowsDeposits.push(<tr key={element['DepositID']}>
                        <td>{element['CreatedOn']}</td>
                        <td>{element['DepositReceivedStatus']}</td>
                        <td>{formatToCurrencyWithScale(element['Amount'],element['CurrencyID'])}</td>
                        <td>{element['CurrencyID']}</td>
                        <td>{formatToCurrencyWithScale(element['BaseAmount'],element['BaseCurrency'])}</td>
                        <td>{element['BaseCurrency']}</td>
                        <td>{roundAmountByCurrencyPair(element['SpotRate'],element['CurrencyID'],element['BaseCurrency'])}</td>
                    </tr>);
                });
            }
        }
        return(

            <div>
                <ul id="accordion-marginCalls" uk-accordion="multiple: true" >
                    <li>
                        <h5 className="uk-accordion-title">Customer Information</h5>
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-1 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" id="lblName">Customer Name: {customerName}</label>                            
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" id="lblExposureRatio">Total Exposure Ratio: {exposureRatio}</label>                            
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked">
                                <div className="uk-margin">
                                    <label className="uk-form-label" id="lblTotalDeposits">Total Deposits: {totalDeposits} {currency}</label>                            
                                </div>
                            </div>
                        </div>
                    </li>
                
                    <li>
                        
                        <h5 className="uk-accordion-title">Deals</h5>
                        <table id="tableDeals" className="uk-table uk-table-striped uk-table-hover uk-table-divider">
                            <thead>
                                {headersDeals}
                            </thead>
                            <tbody>
                                {rowsDeals}
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <h5 className="uk-accordion-title">Deposits</h5>
                        <table id="tableDeposits" className="uk-table uk-table-striped uk-table-hover uk-table-divider">
                            <thead>
                                {headersDeposits}
                            </thead>
                            <tbody>
                                {rowsDeposits}
                            </tbody>
                        </table>
                    </li>
                </ul>                
                <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green   uk-float-right" type="button" onClick={this.handleBackClick}>Back</button>  
                </div>
                <AlertBox id="calulate-alert" 
                    open={this.state.showAlert} 
                    title="Message"
                    message={this.state.alertMessage} 
                    type="Ok"
                    okClick={this.okGlobalAlert}/>
            </div>

        );
    }

}
import React from 'react';
import BlotterLimitsForm from './BlotterLimitsForm';
import BlotterForm from './BlotterForm';
import { getStyle } from '../../../styles/styles';
import useParentBlotters from './hooks/useParentBlotters';
import { useState } from 'react';
const style = getStyle();

const BlotterScreen = () => {
    const [parentBlotters, parentBlottersNeedReloading] = useParentBlotters();
    const [activeTab, setActiveTab] = useState('blotter');
    const [selectedBlotterID, setSelectedBlotterID] = useState(0);
    const [showBlotterLimits, setShowBlotterLimits] = useState(false);

    const getSelectedBlotterID = (blotterID) => {
        setSelectedBlotterID(blotterID);
        setShowBlotterLimits(true);
        setActiveTab('blotter-limits')
    }

    const getParentBlotters = () => {
        parentBlottersNeedReloading(true);
    }

    const handleActiveTab = (tab) => {
        if (tab === 'blotter-limits' && activeTab !== 'blotter-limits') {
            return 'uk-disabled';
        }
        return activeTab === tab ? 'uk-active': '';
    }

    const changeTab = (tab) => {
        if (tab !== 'blotter-limits') {
            setShowBlotterLimits(false);
        }
        setActiveTab(tab);
    }

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >
                Blotter
            </h3>
            <ul uk-tab="">
                <li className={handleActiveTab('blotter')}><a href="#" onClick={() => changeTab("blotter")}>Blotter Creation</a></li>
                <li className={handleActiveTab('blotter-limits')}><a onClick={() => changeTab("blotter-limits")}>Blotter Limits</a></li>
            </ul>
            <ul className="uk-switcher uk-margin">
                <li className={handleActiveTab('blotter')}>
                    <BlotterForm parentBlotters={parentBlotters} parentBlottersNeedReloading={getParentBlotters} getSelectedBlotterID={getSelectedBlotterID}/>
                </li>
                <li className={handleActiveTab('blotter-limits')}>
                    {
                        showBlotterLimits && 
                        <BlotterLimitsForm parentBlotters={parentBlotters} parentBlottersNeedReloading={getParentBlotters} selectedBlotterID={selectedBlotterID}/>
                    }
                </li>
            </ul>
        </div>
    );
}

export default BlotterScreen;
import { genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters } from './Networking';

export async function getAllForwardPoints(page, items) {
    try {
        let params = {
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('ForwardPoints/GetAllForwardPoints', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveForwardPoints(objForwardPoints) {
    let method = 'POST';
    let apiName = 'ForwardPoints/SaveForwardPoints';
    try {
        let response = await genericCallWithBody(method, apiName, objForwardPoints);
        return response;
    } catch (error) {
        console.error(error);
    }
}
//ForwardPoints/DeleteForwardPoints?CCY1={CCY1}&CCY2={CCY2}&Period={Period}
export async function deleteForwardPoints(CCY1, CCY2, period) {
    try {
        let params = {
            'CCY1': CCY1,
            'CCY2': CCY2,
            'period': period
        }
        let response = await genericDeleteWithParameters('ForwardPoints/DeleteForwardPoints', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export const getCustomerByID = async (customerID) => {
    try {
        const params = {
            'customerID': customerID
        };
        const response = await genericGetWithParameters('Customer/GetCustomerById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const searchUserReferredByPerson = async (name) => {
    try {
        const params = {
            'Name': name
        };
        const response = await genericGetWithParameters('User/SearchUserReferredByPersonMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const updateCustomer = async (customer) => {
    try {
        const response = await genericCallWithBody('POST', "Customer/UpdateCustomer", customer);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const createCustomer = async (customer) => {
    try {
        const response = await genericCallWithBody('POST', "Customer/CreateCustomer", customer);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getRiskScoreRanges = async () => {
    try {
        const response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreRanges', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getRiskScoreDetails = async (customerID) => {
    try {
        const params = {
            EntityType: "Customer",
            EntityID: customerID,
            checkBankScore: true
        };
        const response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getRiskScoreDetailsByEntity = async (customerID, entityType) => {
    try {
        const params = {
            EntityType: entityType,
            EntityID: customerID,
            checkBankScore: true
        };
        const response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCustomerRiskScore = async (customerID) => {
    try {
        const params = {
            CustomerID: customerID
        };
        const response = await genericGetWithParameters('RiskScoreRange/GetCustomerRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getScreenInformation = async () => {
    try {
        const response = await genericGetWithParameters('Customer/GetScreenInformation', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const searchCustomerByIdNameMin = async (customer) => {
    try {
        const params = {
            'customer': customer
        };
        const response = await genericGetWithParameters('Customer/SearchCustomerByIdNameMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getBankAccountsByCurrency = async (customerID, currencyID) => {
    try {
        const params = {
            "customerID": customerID,
            "currencyID": currencyID,
        };
        const response = await genericGetWithParameters('Customer/GetBankAccountsByCurrency', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAllLimitProfilesByCustomerID = async (customerID, page, items) => {
    try {
        const params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items
        };
        const response = await genericGetWithParameters('Customer/GetAllLimitProfilesByCustomerID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getLimitProfile = async (id) => {
    try {
        const params = {
            'CustomerLimitProfileID': id
        };
        const response = await genericGetWithParameters('Customer/GetCustomerLimitProfileByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveLimitProfile = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Customer/SaveCustomerLimitProfiles', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteCustomerLimitProfile = async (id) => {
    try {
        const params = {
            'CustomerLimitProfileID': id
        };
        const response = await genericDeleteWithParameters('Customer/DeleteCustomerLimitProfileByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getTradingInformationByCustomerID = async (customerID) => {
    try {
        const params = {
            "customerID": customerID
        };
        const response = await genericGetWithParameters('Customer/GetTradingInformationByCustomerId', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const updateCustomerTradingInformation = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Customer/UpdateTradingInformation', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getFundOnHoldByCustomerAndCurrency = async (customerID, currencyID) => {
    try {
        const params = {
            "customerID": customerID,
            "currencyID": currencyID,
        };
        const response = await genericGetWithParameters('Customer/GetFundOnHoldByCustomerAndCurrency', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveOnlineAccess = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Customer/SaveOnlineAccessInformation', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteApprovalTier = async (model) => {
    try {
        const params = {
            'CustomerID': model.CustomerID,
            'EntityType': model['Entity Type'],
            'FromTier': model['From Tier'],
            'ToTier': model['To Tier'],
            'RequiredApprovals': model['Required Approvals'],
            'UpdatedBy': model.UpdatedBy,
            'UpdateToken': model.UpdateToken
        };
        const response = await genericCallWithBody('Delete', 'ApprovalTier/Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getApprovalTiersByCustomerID = async (customerID, page, items) => {
    try {
        const params = {
            "CustomerID": customerID,
            "Page": page,
            "Items": items
        };
        const response = await genericGetWithParameters('ApprovalTier/GetApprovalTiersByCustomerID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getApprovalTiersByEntityType = async (customerID, entityType, page, items) => {
    try {
        const params = {
            "CustomerID": customerID,
            "EntityType": entityType,
            "Page": page,
            "Items": items
        };
        const response = await genericGetWithParameters('ApprovalTier/GetApprovalTiersByEntityType', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveApprovalTier = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'ApprovalTier/SaveApprovalTiersByCustomerID', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getApprovalTiersInfo = async (customerID, entityType, toTier, fromTier) => {
    try {
        const params = {
            "CustomerID": customerID,
            "EntityType": entityType,
            "ToTier": toTier,
            "FromTier": fromTier
        };
        const response = await genericGetWithParameters('ApprovalTier/GetApprovalTierInfo', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getOtherSettlementFunds = async (customerID, dealHeaderID, page, items) => {
    try {
        const params = {
            'CustomerID': customerID,
            'DealHeaderID': dealHeaderID,
            'Page': page,
            'Items': items
        };
        const response = await genericGetWithParameters('Customer/GetOtherSettlementFunds', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCustomerOverview = async (customerID) => {
    try {
        const params = {
            'customerID': customerID,
        };
        const response = await genericGetWithParameters('Customer/GetOverview', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCustomersAscendantsAndDescendants = async (customerID) => {
    try {
        const params = {
            'CustomerID': customerID,
        };
        const response = await genericGetWithParameters('Customer/GetCustomersAscendantsAndDescendants_min', params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getContactsAvailableForMessaging = async (customerID) => {
    try {
        const params = {
            'CustomerID': customerID
        }
        const response = await genericGetWithParameters('Message/GetContactsAvailableForMessaging', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const sendMessage = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Message/SendMessage', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAllDynamicPricingByCustomer = async (customerID) => {
    try {
        const params = {
            'CustomerID': customerID
        }
        const response = await genericGetWithParameters('DynamicPricingDefault/GetAllDynamicPricingCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
//--------
export const getAllDynamicPricingDefault = async () => {
    try {
        const params = {}
        const response = await genericGetWithParameters('DynamicPricingDefault/GetAllDynamicPricingDefault', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveDynamicPricingByCustomer = async (model) => {
    try {
        const response = await genericCallWithBody('PUT', 'DynamicPricingDefault/SaveDynamicPricingByCustomer', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export const saveDynamicPricingDefault = async (model) => {
    try {
        const response = await genericCallWithBody('PUT', 'DynamicPricingDefault/SaveDynamicPricingDefault', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteDynamicPricingByCustomer = async (model) => {
    try {
        const response = await genericDeleteWithParameters('DynamicPricingDefault/DeleteDynamicPricingByCustomer', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const deleteDynamicPricingDefault = async (model) => {
    try {
        const response = await genericDeleteWithParameters('DynamicPricingDefault/DeleteDynamicPricingDefault', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getMarginCalls = async (customerID, applicationDate, page, items) => {
    try {
        const params = {
            'customerID': customerID,
            'applicationDate': applicationDate,
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('Customer/GetMarginCalls', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getBanksForOneClickCover = async () => {
    try {
        const params = { };
        const response = await genericGetWithParameters('Customer/GetBanksForOneClickCover', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getBanks = async () => {
    try {
        const params = { };
        const response = await genericGetWithParameters('Customer/GetBanks', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const onboardCustomerToCurrencyCloud = async (customerId, userId) => {
    try {
        let model = {
            CustomerID: customerId,
            UserID: userId
          }
        const response = await genericCallWithBody('POST', 'Customer/OnboardCustomerToCurrencyCloud', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCustomersCardView = async (customerId, customerName) => {
    try {
        const params = {
            CustomerID: (customerId === '' ? '' : customerId),
            CustomerName: (customerName === '' ? '' : customerName)
        }
        const response = await genericGetWithParameters('Customer/GetCustomersCardView', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const AFEXSaveCustomer = async (customerId, userId) => {
    try {
        let model = {
            customerId: customerId,
            userId: userId
          }
        const response = await genericCallWithBody('POST', 'Customer/AFEXSaveCustomer', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const AFEXCreateInstantBeneficiary = async (customerId, userId, currencies) => {
    try {
        let model = {
            customerId: customerId,
            userId: userId,
            currencies: currencies
          }
        const response = await genericCallWithBody('POST', 'Customer/AFEXCreateInstantBeneficiary', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerCommissionTableByCustomer(customerId, showDelete)
{
    try {
        let params = {
            'CustomerID'    : customerId,
            'ShowDelete'    : showDelete
        }
        let response = await genericGetWithParameters('Customer/GetCustomerCommissionTableByCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const AFEXCreateFundingBalance = async (customerId, userId, currencies) => {
    try {
        let model = {
            customerId: customerId,
            userId: userId,
            currencies: currencies
          }
        const response = await genericCallWithBody('POST', 'Customer/AFEXCreateFundingBalance', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const GetFundingBalances = async (customerId, userId, currencies) => {
    try {
        let model = {
            customerId: customerId,
            userId: userId,
            currencies: currencies
          }
        const response = await genericCallWithBody('POST', 'Customer/GetFundingBalance', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const GetSubAccountID = async (customerId) => {
    try {
        let model = {
            CustomerID: customerId
          }
        const response = await genericCallWithBody('POST', 'Customer/GetSubAccountID', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const searchCustomerMSB = async (textToSearch) => {
    try {
        const params = { TextToSearch: textToSearch };
        const response = await genericGetWithParameters('CustomerSearch/SearchCustomerMSB', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const sendAuthorizedCustomerEmail = async (customerID) => {
    try {
        const params = {
            'CustomerID': customerID
        };
        const response = await genericCallWithBody('POST', 'Customer/SendAuthorizedCustomerEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveFundsOnHoldBalanceTransfers(userId, fromCustomerId, toCustomerId, transferAmount, currencyId, transactionDate, comments) {

    let method = 'POST';
    let apiName = 'Customer/SaveFundsOnHoldBalanceTransfers';

    let model = {
        UserId: userId,
        BalanceTransfer: {
            'FromCustomerID': fromCustomerId,
            'ToCustomerID': toCustomerId,
            'TransferAmount': transferAmount,
            'CurrencyID': currencyId,
            'TransactionDate': transactionDate,
            'Comments': comments
        }
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}
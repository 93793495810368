import React, { useState } from 'react';
import { addAttachment } from "../../../networking/NetworkingTMx";
import AlertBox from '../../shared/AlertBox';
import Lottie from "lottie-react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import uploadAnimation from '../../../animations/upload-files.json';
import { useEffect } from 'react';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const NewAttachment = (props) =>{
    
    const fileInput = React.createRef();
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');

    useEffect(()=>{
        setDescription('');
    },[props.open])

    const closeAlert = () =>{
        setShowAlert(false);
    }
    
    const readFileAsBase64 = (file) =>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    }

    const handleUploadFile = async() => {
        let file = fileInput.current.files[0];
        if (file) {
            readFileAsBase64(file).then((resolve, reject)=>{
                let fileContent = resolve.replace(/^data:.+;base64,/, '');
                if(props.linkID !== undefined && props.linkID !== null)
                {
                    const model = {
                        json : {                                
                                Description: description,
                                Filename: file.name,
                                LinkID: props.linkID,
                                LinkType: 'Activity',
                                UploadedBy: localStorage.getItem('UserID')
                            },
                        FileContent: fileContent
                        }
        
                    addAttachment(model).then(
                    (json) =>{
                        if(json !== null){
                            if (json.httpStatusCode !== 200){
                                setShowAlert(true);
                                setAlertTitle('Error');
                                if (json.Message != null && json.Message !== '') {
                                    setAlertMessage(json.Message);
                                } else {
                                    setAlertMessage(json.httpErrorMessage);
                                }
                                
                            } else {
                                setShowAlert(true);
                                setAlertTitle('Success');
                                setAlertMessage('File has successfully saved.');
                                props.onClose();
                            }
                        }
                    });
                }
                
             });
        }
        
    }

    return(
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>Upload Attachment</DialogTitle>
                <DialogContent>
                <div uk-form-custom="target: true" className="uk-margin file-box">
                    <Lottie animationData={uploadAnimation} style={{height: '200px'}}/>
                    <h5>Click or Drag&Drop the file you want to attach</h5>
                    <input type="file" ref={fileInput} className="file_upload"/>
                    <input className="uk-input uk-form-width-large" type="text" placeholder="Select a file..." disabled/>
                </div>
                
                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="form-attachment-description">Description</label>
                    <div className="uk-form-controls">
                        <input className="uk-input uk-form-width-large" id="form-attachment-description" type="text" placeholder="Description" value={description} onChange={(e)=> setDescription(e.target.value)}/>
                    </div>
                </div>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={props.onClose}>Cancel</button>
                    <button className="uk-button uk-button-green" key={"upload"} type="button" onClick={handleUploadFile}>Upload</button>
                </DialogActions>
            </Dialog>
            <AlertBox
                id="disbursement-dialog-alert"
                open={showAlert}
                title={alertTitle}
                message={alertMessage}
                type="Ok"
                okClick={closeAlert}
            />
        </div>
    );
}

export default NewAttachment;
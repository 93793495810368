
import {genericGetWithParameters} from './Networking';


export async function GetToolItemsByGenericBoardID(genericBoardID){
    try{
        let params = {"GenericBoardID":genericBoardID};

        let response = await genericGetWithParameters('GenericBoard/GetToolItemsByGenericBoardID_min', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetCategoriesByGenericBoardID(genericBoardID){
    try{
        let params = {"GenericBoardID":genericBoardID};

        let response = await genericGetWithParameters('GenericBoard/GetCategoriesByGenericBoardID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetBoardByName(genericBoard){
    try{
        let params = {"GenericBoard":genericBoard};

        let response = await genericGetWithParameters('GenericBoard/GetBoardByName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetQueriesByCategory(categoryID){
    try{
        let params = {"CategoryId":categoryID};

        let response = await genericGetWithParameters('GenericBoard/GetQueriesByCategory_min', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function ExecuteGQuery(query, personID, name, traderId, countryId, groupId, status, page, items, interfacePartnerId, currency) {
    try {
        let params = {
            'query': query,
            'PersonID': personID,
            'Name': name,
            'TraderID': traderId,
            'CountryID': countryId,
            'GroupId': groupId,
            'Status': status,
            'Page': page,
            'Items': items,
            'InterfacePartnerId': interfacePartnerId,
            'Currency': currency
        }
        let response = await genericGetWithParameters('GenericBoard/ExecuteGQuery', params);
        return response;

    } catch (error) {
        console.error(error);
    }
}
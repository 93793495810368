import jwt from 'jsonwebtoken';
import {genericCallWithBody, 
        genericGetWithParameters, genericDeleteWithParameters} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export const getAdminActions = async (ApplicationName, page, items) => {
    try {
        const params = {
            'ApplicationName': ApplicationName,
            'page': page,
            'items': items
        };
        const response = await genericGetWithParameters('AdminActions/GetSecurityRules', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveAdminActions(objAction){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'AdminActions/SaveSecurityRules';
    let response = await genericCallWithBody(method, apiName, objAction);
    return response;
}

export async function deleteAdminActions(ApplicationName, ActionName) {
    try{
        let params = {
            'ApplicationName': ApplicationName,
            'ActionName': ActionName
        };
        let response = await genericDeleteWithParameters('AdminActions/DeleteSecurityRules',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import DayPicker from 'react-day-picker';
import { formatDateString } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';
import { deleteValueDateHolidays, getValueDateHolidays, saveValueDateHolidays } from '../../../networking/NetworkingHolidays';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import CarryOverValueDateHolidaysForm from './CarryOverValueDateHolidaysForm';
var style = getStyle();
const itemsPerPage = 0;

const inputStyle= {
    paddingBottom:"15px"
}

export default class ValueDateHolidaysScreen extends React.Component {
    state = {
        valueDateHolidayId: 0,
        valueDateHoliday: '',
        selecteddate: '',
        currency: '',
        year: 0,
        description: '',
        updateToken: 0,
        
        years: [],
        currenciesList: [],
        allValueDateHolidays: [],
        
        backFromCarryOver: false,
        hideButton: false,
        available:'',
        listMissing: [],

        validations: {
            valueDateHoliday: true,
            description: true,
            currency: true,
            year: true
        },

        showCarryOver: false,
        alertMessage: '',
        showAlert: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteValueDate: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false
    }

    closeDeleteAlert = () =>{
        this.setState({showDeleteAlert: false, readyToDeleteValueDate: {}});
    }

    openDeleteAlert = () => {
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert = () => {
        deleteValueDateHolidays(this.state.readyToDeleteValueDate['Holiday Id']).then(
            (value)=>{
                console.log(value);
                if(value.httpStatusCode !== 200){
                    this.setState({showDeleteAlert: false, showErrorAlert: true, errorMessage: value.Message});
                } else {
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                    this.updatePage();
                }
        });
    }

    okSuccessAlert = () => {
        this.setState({showSuccessDeleteAlert: false});
    }
    
    componentDidMount(){
        this.loadCurrencyList();
        this.getYearList();
    }

    getListOfValueDateHolidays(){
        if(this.state.currency !== undefined && this.state.currency !== '' && this.state.year !== undefined && this.state.year !== 0){
            getValueDateHolidays(this.state.currency, this.state.year, 0, itemsPerPage).then(
                (value)=>{ 
                    if(value.holidays !== undefined){              
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount/itemsPerPage);
                        console.log(pages);
                        this.setState({allValueDateHolidays: value.holidays, totalPages: pages, newSearch: false});
                    }else{
                        this.setState({allValueDateHolidays: [], totalPages: 0, newSearch: false});
                    }
                }
            );
        }
    }

    getYearList (){
        var helper = new Date().getFullYear();
        var helperList = [...Array(10).keys()].map((_, i) => ({name: helper + i, value: helper + i}))
        this.setState({years: helperList})
    }

    loadCurrencyList() {
        getCurrencyList().then(
            (response) => {
                if (typeof response !== "undefined") {
                    let currencies = response.currencies;
                    currencies.forEach(element => {
                        element.value = element.CurrencyID;
                        element.name = element.CurrencyIDName;
                    });
                    this.setState({
                        currenciesList: currencies
                    });
                }
            }
        );
    }    

    transformHolidaysList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Holiday Id': list[i]['Holiday Id'],
            'Description': list[i]['Description'],
            'Date': list[i]['Date']});
        }
        return helper;
    }

    handleRowClick = (obj) =>{
        console.group("Holiday handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({valueDateHoliday: obj["Date"],
                        valueDateHolidayId: obj["Holiday Id"],
                        description: obj["Description"]})
    }

    handleDeleteClick = (obj) => {
        console.group("Holiday handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteValueDate: obj});        
    }

    changePage = (value) => {
        this.setState({currentPage: value});        
    }

    updatePage = () => {
        getValueDateHolidays(this.state.currency, this.state.year, 0, 0).then(
            (value)=>{               
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({allValueDateHolidays: value.holidays, totalPages: pages, newSearch: false});
            }
        )
    }

    handleSaveClick(){
        let userID = localStorage.getItem('UserID');
        var model = {  
            currencyHolidayID: this.state.valueDateHolidayId,
            description: this.state.description,
            date: this.state.valueDateHoliday,
            currencyID: this.state.currency,
            UserId: userID,
            UpdateToken: this.state.updateToken
        }
        if(this.validateRequiredFields()){
            saveValueDateHolidays(model).then(()=>
            {
                this.setState({
                    valueDateHolidayId: 0,
                    description: '',
                    valueDateHoliday: '',
                    updateToken: 0,
                    showAlert: true, 
                    alertTitle: 'Holiday successfully saved.'});
                this.updatePage(); 
            })
        }
    }

    validateRequiredFields() 
    {
        var valid = true
        var description = true
        var valueDateHoliday = true
        var year = true
        var currency = true
        var listItems =[]        

        if(this.state.valueDateHoliday === undefined || 
            this.state.valueDateHoliday === "")
         {
             valid = false;
             valueDateHoliday = false;
             listItems.push("Value Date Holiday")
         }

        if(this.state.description === undefined || 
           this.state.description === "")
        {
            valid = false;
            description = false;
            listItems.push("Description")
        }        

        if (this.state.year === '' || 
            this.state.year === undefined ||
            this.state.year === 0) 
        {
            valid = false;
            year = false;
            listItems.push("Year")
        }

        if(this.state.currency === undefined || 
            this.state.currency === "" ||
            this.state.currency === null)
         {
             valid = false;
             currency = false;
             listItems.push("Currency")
         }
        
        this.setState({listMissing: listItems,validations: {
                ...this.state.validations,
                description: description,
                valueDateHoliday: valueDateHoliday,
                year: year,
                currency: currency
            }});

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleBackFromCarryOver() {
        this.setState({backFromCarryOver: false});
    }

    handleCarryOver(){
        this.setState({backFromCarryOver: true})
    }

    handleUpdateCurrencies(value){
        if (value.value !== undefined) {
            this.setState({currency: value.value,validations: {...this.state.validations, currency: true}}, ()=>this.getListOfValueDateHolidays());
        }
    }

    handleUpdateYears(value){
        if (value.value !== undefined) {
            this.setState({year: value.value,validations: {...this.state.validations, year: true}}, ()=>this.getListOfValueDateHolidays());
        }
    }    

    handleValueDateHoliday(value){
        this.setState({valueDateHoliday: formatDateString(value), selecteddate: value, validations: {...this.state.validations, valueDateHoliday: true}});
    }

    handleUpdateDescription(value){
        this.setState({description: value.target.value,validations: {...this.state.validations, description: true}});
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    getAllValueDateHolidays = async () => {
        var json = await getValueDateHolidays(0,0);
        var holidays = this.transformHolidaysList(json.holidays);
        return holidays;
    }

    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass;

        if (this.state.backFromCarryOver) {
            return <CarryOverValueDateHolidaysForm currenciesList={this.state.currenciesList} yearList={this.state.years} backFromCarryOver={this.handleBackFromCarryOver.bind(this)}/>
            }

        return (
            <div>
            <form className="uk-grid">
                <div className="uk-width-1-2">
                    <div className="uk-width-1" style={{marginBottom: 10}}>
                        <label className="uk-form-label" htmlFor="form-holiday-description">Description</label>
                        <div className="uk-form-controls">
                             <input className={this.formatValidInput(this.state.validations.description)} id="form-holiday-description" value={this.state.description} type="text" placeholder=" " onChange={this.handleUpdateDescription.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1" style={{marginBottom: 10}}>                     
                        <label className="uk-form-label">Select Currency</label>
                        <div className="uk-form-controls">
                            <DynamicSelect id="currency-list" className={this.formatValidSelect(this.state.validations.currency)} getValue={this.handleUpdateCurrencies.bind(this)} placeholder="Select a currency..." listHasPlaceholder={false} data={this.state.currenciesList} objValue={{value: this.state.currency}}/>
                        </div>
                    </div>
                    <div className="uk-width-1" style={{marginBottom: 10}}>                     
                        <label className="uk-form-label">Select Year</label>
                        <div className="uk-form-controls">
                            <DynamicSelect id="year-list" className={this.formatValidSelect(this.state.validations.year)} getValue={this.handleUpdateYears.bind(this)} placeholder="Select a year..." data={this.state.years} objValue={{value: this.state.year}}/>
                        </div>
                    </div>
                    <div className="uk-width-1"> 
                    <div className="uk-margin" style={inputStyle}>
                        <br></br>
                    </div>
                    </div>
                    {/*<div className="uk-width-1"> 
                        <div className="uk-heading-divider">
                            <h4>Options</h4>
                        </div>
                    </div>*/}
                    <div className="uk-grid">
                        <div className="uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={this.handleCarryOver.bind(this)} type="button">Carry Over</button>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="uk-margin-right"> 
                    <div className="">                           
                        <DayPicker value={this.state.valueDateHoliday} onDayClick={this.handleValueDateHoliday.bind(this)} selectedDays={this.state.selecteddate}/>                        
                    </div>                    
                                       
                </div>
                <div className="uk-margin-right"> 
                    <div className="uk-margin" style={style.inputStyle}>
                        <div className="uk-margin-right"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-margin-right"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={this.handleSaveClick.bind(this)} type="button">Add</button>
                            </div>
                        </div>
                        {/*<div className="uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={this.handleDeleteClick.bind(this)} type="button">Delete</button>
                            </div>
                        </div>*/}
                    </div>
                </div>    
                
                <div className="uk-width-1"> 
                    <div className="uk-margin" style={style.inputStyle}>
                        <br></br>
                    </div>
                </div>
            </form>
            <div className="uk-width-1">
            <h3 className="uk-heading-divider"> </h3>
            <NewDynamicTable
                id="tasks-table"
                data={this.state.allValueDateHolidays}
                hiddenHeaders={['Holiday Id']} 
                enableClick={true} 
                clickFunction={this.handleRowClick} 
                newSearch={this.state.newSearch} 
                useDeleteButton={true}
                deleteFunction={this.handleDeleteClick}
                useFilter={false} 
                filterFunction={this.getAllValueDateHolidays}
                usePaginator={true}
                changePage={this.changePage}
                numberOfPages={this.state.totalPages}
                pageNumber = {this.state.currentPage}
                numberPerPage = {getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}/>
            </div>
            <AlertBox id="delete-valueDate" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this holiday?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
            <AlertBox id="confirmation-delete-valueDate" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Holiday successfully removed." message="" type="Ok" okClick={this.okSuccessAlert}/>
            <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing}/>
            </div>
        );
    }

}
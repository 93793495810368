import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {getTranslationsByTypeAndLabelID, saveTranslation, deleteATranslation} from '../../../networking/NetworkingTranslation'

import {CustomerType, BeneficiaryType, CustomerContactType,TraderType} from '../../../constants/EntityTypeNames'
import AlertBox from '../AlertBox';

import {formatDate} from '../../../helpers/FormatHelper'
import {getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../../styles/styles';

import NewDynamicTable from '../NewDynamicTable';
import DynamicSelect from '../../shared/DynamicSelect';

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

export default class TranslationMaintenanceDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {
            fullWidth           : true,
            maxWidth            : 'md',
            //Form
            translationID       : 0,
            updateToken         : '',
            description         : '',
            lenguageList        : [{name:'English', value: 'English'}, {name:'Chinese', value: 'Chinese'}],
            languageSelected    : '',
            newDescription      : '',
            translationList     : [],

            validationLanguage   : true,
            validationDescription: true,

            //Alert
            alertTitle          : '',
            alertMessage        : '',
            showOpenAlert       : false,
            listMissing         : [],

            //Delete Alert
            showDeleteAlert     : false
        };
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    componentDidMount()
    {

    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if((this.props.data !== prevProps.data) && (this.props.data !== null))
        {
            /*
                handleOpenTranslation: 
                {
                    "AddPropertyDefID":9,
                    "Description":"Test 6",
                    "Data Type":"DateTime",
                    "Display Order":2,
                    "Page Number":3,
                    "EntityAdditionalProperties":0
                }
            */
            this.setState({
                description: this.props.data.Description
            }, () =>{
                let id = 0;
                switch (this.props.type) {
                    case 'Property':
                        id = this.props.data.AddPropertyDefID;
                        break;
                    case 'Attachment':
                        id= this.props.data.AttachmentBySubEntityTypeID;
                    default:
                        break;
                }
                this.getTranslations(id, this.props.type);
            });
        }
    }

    getTranslations = (labelID, labelType) =>
    {
        getTranslationsByTypeAndLabelID(labelType, labelID).then(
            (jsonResponse) => {
                console.log("getTranslationsByTypeAndLabelID "+JSON.stringify(jsonResponse));
                /*
                    getTranslationsByTypeAndLabelID 
                    {
                        "Translations":[
                            {
                                "TranslationID":2,
                                "LabelType":"Property",
                                "Languaje":"English",
                                "Label":"Label Test 2",
                                "Name":"Admin",
                                "UpdatedOn":"2020-12-10T10:39:36.48",
                                "UpdateToken":"637431935764800000"
                            }
                        ],"httpStatusCode":200}
                */
                if(jsonResponse !== undefined)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            let listFix = jsonResponse.Translations;
                            listFix.forEach(element => {
                                element['Updated On'] = formatDate(element['Updated On'])
                            });
                            this.setState({
                                translationList: listFix
                            });
                            break;
                        }
                    }
                }
            }
        );
    }

    handleUpdateLanguage = (value) =>  {
        if (value != undefined && value !== "select") 
        {
            this.setState({ 
                languageSelected    : value.value, 
                validationLanguage  : true,
                translationID       : 0,
                updateToken         : 0,
            });
        }
    }

    handleUpdateDescription = (value) => {
        this.setState({ 
            newDescription          : value.target.value, 
            validationDescription   : true 
        });
    }

    handleAddClick = () =>{
        if(this.validateForm())
        {
            /*{
                "AddPropertyDefID":9,
                "Description":"Test 6",
                "Data Type":"DateTime",
                "Display Order":2,
                "Page Number":3,
                "EntityAdditionalProperties":0
            }*/

            let id = 0;

            switch (this.props.type) {
                case 'Property':
                    id = this.props.data.AddPropertyDefID;
                    break;
                case 'Attachment':
                    id= this.props.data.AttachmentBySubEntityTypeID;
                default:
                    break;
            }

            let model = {
                TranslationID   : this.state.translationID,
                LabelType       : this.props.type,
                LabelID         : id,
                Languaje        : this.state.languageSelected,
                Label           : this.state.newDescription,
                UserID          : localStorage.getItem('UserID'),
                UpdateToken     : this.state.updateToken,
            }
            
            saveTranslation(model).then(
                (jsonResponse) => {
                    console.log("saveTranslation "+JSON.stringify(jsonResponse));

                    if(jsonResponse !== undefined)
                    {
                        /*
                            saveTranslation 
                            {
                                "Message":"concurrency",
                                "ErrorMessage":null,
                                "httpStatusCode":409,
                                "httpErrorMessage":"This record has been updated by someone else. Please reload the information and try again."
                            }
                        */
                        switch (jsonResponse.httpStatusCode) 
                        {
                            case 200:
                            {
                                this.setState({
                                    showOpenAlert   : true,
                                    alertTitle      : 'Success',
                                    alertMessage    : 'Translation successfully saved.',
                                    languageSelected: '',
                                    newDescription  : '',
                                    translationID   : 0,
                                    updateToken     : ''
                                });

                                let id = 0;
                                switch (this.props.type) {
                                    case 'Property':
                                        id = this.props.data.AddPropertyDefID;
                                        break;
                                    case 'Attachment':
                                        id= this.props.data.AttachmentBySubEntityTypeID;
                                    default:
                                        break;
                                }
                                this.getTranslations(id, this.props.type);
                                break;
                            }
                            case 409:
                            {
                                this.setState({
                                    showOpenAlert   : true,
                                    alertTitle      : 'Error',
                                    alertMessage    : 'This record has been updated by someone else. Please reload the information and try again.',
                                });
                                break;
                            }
                            case 503:
                            {
                                /*
                                    saveTranslation 
                                    {
                                        "Message":"There is already a translation with this language.",
                                        "ErrorMessage":null,
                                        "httpStatusCode":503,
                                        "httpErrorMessage":"An error occurred. Please try again."
                                    }
                                */
                                this.setState({
                                    showOpenAlert   : true,
                                    alertTitle      : 'Error',
                                    alertMessage    : jsonResponse.Message,
                                });
                                break;
                            }
                            default:
                            {
                                this.setState({
                                    showOpenAlert   : true,
                                    alertTitle      : 'Error',
                                    alertMessage    : 'An error occurred. Please try again.',
                                });
                                break;
                            }
                        }
                    }
                }
            );
        }else
        {
            this.setState({
                showOpenAlert   : true,
                alertTitle      : 'Error',
                alertMessage    : 'Please fill all mandatory fields:',
            })
        }
    }

    validateForm()
    {
        let result = true;
        let validationLanguageAux = true;
        let validationDescriptionAux = true;
        let listErrors = [];

        if(this.state.languageSelected === undefined || this.state.languageSelected === null || this.state.languageSelected === '')
        {
            result = false;
            validationLanguageAux = false;
            listErrors.push('Select a language');
        }

        if(this.state.newDescription === undefined || this.state.newDescription === null || this.state.newDescription === '')
        {
            result = false;
            validationDescriptionAux = false;
            listErrors.push('Description');
        }

        this.setState({
            validationLanguage      : validationLanguageAux,
            validationDescription   : validationDescriptionAux,
            listMissing             : listErrors
        });

        return result;
    }

    closeAlert = () =>
    {
        this.setState({
            showOpenAlert: false
        });
    }

    oncloseDialog = () =>
    {
        this.setState({
            translationID    : 0,
            updateToken      : '',
            translationList  : [],
            languageSelected : '',
            newDescription   : '',
            alertTitle       : '',
            alertMessage     : '',
            listMissing      : [],
            validationLanguage      : true,
            validationDescription   : true
        },() =>{
            this.props.onclose();
        });
    }

    onTranslateRowClick = (translation) =>
    {
        console.log("onTranslateRowClick: "+ JSON.stringify(translation));
        /*
            onTranslateRowClick: 
            {
                "TranslationID":2,
                "Type":"Property",
                "Language":"English",
                "Text":"Label Test 2",
                "Created By":"Admin",
                "Updated On":"2020/12/10",
                "UpdateToken":"637431935764800000"
            }
        */
        this.setState({
            translationID           : translation.TranslationID,
            languageSelected        : translation.Language,
            newDescription          : translation.Text,
            updateToken             : translation.UpdateToken,
            validationLanguage      : true,
            validationDescription   : true

        });
    }

    handleDeleteClick = (obj) => {
        console.log("handleDeleteClick: " + JSON.stringify(obj));
        /*
            handleDeleteClick: 
            {
                "TranslationID" :4,
                "Type"          :"Property",
                "Language"      :"English",
                "Text"          :"Label Test One",
                "Created By"    :"daniela.soto",
                "Updated On"    :"2020/12/11",
                "UpdateToken"   :"637432794915100000"
            }
        */
        this.setState({
            showDeleteAlert     : true,
            translationID       : obj.TranslationID
        });
    }

    closeDeleteAlert = () =>{
        this.setState({
            showDeleteAlert         : false,
            translationID           : 0,
            languageSelected        : '',
            newDescription          : '',
            updateToken             : 0,
            validationLanguage      : true,
            validationDescription   : true
        });
    }

    yesDeleteAlert = () => {
        console.log("delete ---------------------------------------------------------------");
        deleteATranslation(this.state.translationID).then(
            (jsonResponse)=>{
                console.log("deleteATranslation: " + JSON.stringify(jsonResponse));

                /*deleteATranslation: 
                    {
                        "Message"       : "The translation has been deleted successfully.",
                        "ErrorMessage"  : null,
                        "httpStatusCode": 200
                    }
                */

                switch (jsonResponse.httpStatusCode) 
                {
                    case 200:
                    {
                        this.setState({
                            showDeleteAlert         : false,
                            showOpenAlert           : true,
                            alertTitle              : 'Success',
                            alertMessage            : 'The translation has been deleted successfully.',
                            listMissing             : [],
                            translationID           : 0,
                            languageSelected        : '',
                            newDescription          : '',
                            updateToken             : 0,
                            validationLanguage      : true,
                            validationDescription   : true
                        }, () =>{
                            let id = 0;

                            switch (this.props.type) 
                            {
                                case 'Property':
                                    id = this.props.data.AddPropertyDefID;
                                    break;
                                case 'Attachment':
                                    id= this.props.data.AttachmentBySubEntityTypeID;
                                default:
                                    break;
                            }
                            this.getTranslations(id, this.props.type);
                        });
                        
                        break;
                    }
                    default:
                    {
                        this.setState({
                            showDeleteAlert : false,
                            showOpenAlert   : true,
                            alertTitle      : 'Error',
                            alertMessage    : 'Something went wrong. Please try again later',
                            listMissing     : [],
                        });
                        break;
                    }
                }
        });
    }

    render() 
    {
        return (
            <div>
                <Dialog 
                    open        = {this.props.open} 
                    onClose     = {this.oncloseDialog}
                    fullWidth   = {this.state.fullWidth}
                    maxWidth    = {this.state.maxWidth}
                >
                    <DialogTitle 
                        id="translation-dialog-title" 
                        onClose={this.oncloseDialog}
                    >
                        Save translations for - <span style={{fontWeight: 'bold'}}>{this.state.description}</span>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className = 'uk-grid'>
                            <div className="uk-width-1-3 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Language (*)</label>
                                <div className=""style={style.inputStyle}>
                                    <DynamicSelect 
                                        id                  = "data-lenguages" 
                                        className           = {this.formatValidSelect(this.state.validationLanguage)} 
                                        getValue            = {this.handleUpdateLanguage} 
                                        listHasPlaceholder  = {false}
                                        placeholder         = 'Select a language...'
                                        data                = {this.state.lenguageList} 
                                        objValue            = {{ 
                                            value: this.state.languageSelected 
                                        }}
                                    />
                                </div>
                            </div> 
                            <div className="uk-width-1-3 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Description (*)</label>
                                <div className=""style={style.inputStyle}>
                                    <input 
                                        className   = {this.formatValidInput(this.state.validationDescription)} 
                                        id          = "description" 
                                        type        = "text" 
                                        placeholder = "" 
                                        value       = {this.state.newDescription} 
                                        onChange    = {this.handleUpdateDescription}
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-3 uk-form-stacked" style={{margin: 'auto'}}>
                                <button
                                    className="uk-button uk-button-green uk-margin-right" 
                                    onClick={this.handleAddClick}
                                >
                                    Save
                                </button>
                            </div>
                            <div className="uk-width-1-1 uk-form-stacked">
                                <NewDynamicTable
                                    id              = "attachments-table"
                                    data            = {this.state.translationList}
                                    enableClick     = {true} 
                                    clickFunction   = {this.onTranslateRowClick}
                                    hiddenHeaders   = {['TranslationID', 'UpdateToken']}
                                    useDeleteButton = {true} 
                                    deleteFunction  = {this.handleDeleteClick} 
                                />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox 
                    id              = "alert-translation-validation" 
                    open            = {this.state.showOpenAlert} 
                    onClose         = {this.closeAlert} 
                    title           = {this.state.alertTitle}
                    message         = {this.state.alertMessage}
                    type            = "Ok"
                    okClick         = {this.closeAlert}
                    messageBoldList = {this.state.listMissing}
                />
                <AlertBox 
                    id          = "delete-translation" 
                    open        = {this.state.showDeleteAlert} 
                    onClose     = {this.closeDeleteAlert} 
                    title       = "Are you sure you want to delete this translation?" 
                    message     = "This action can not be undone." 
                    type        = "Yes/No" 
                    yesClick    = {this.yesDeleteAlert} 
                    noClick     = {this.closeDeleteAlert}
                />
            </div>
        )
    }
}
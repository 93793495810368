import { decrypt, genericCallWithBody, genericGetWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getAddressByPersonID(personID){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let request = {
            PersonID: personID,
            Page: 1,
            Items: 10
        }
        
        let response = await fetch(apiURL + 'Address/GetAddressByPersonID', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(request),
        });
        
        let responseJWT = await response.json();
        let responseJSON = await decrypt(responseJWT);
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function getAddressByID(addressID){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let params = {
            'AddressID': addressID,
        }
        let response = await genericGetWithParameters('Address/GetAddressByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveAddress = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Address/SaveAddress', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteAddress(addressID){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let params = {
            'AddressID': addressID,
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'Address/Delete?' + query, {
            method: 'DELETE',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        console.group("deleteAddress", addressID);
        console.log(responseJson);
        console.groupEnd();
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export const getMainAddressByPersonID = async (personID) => {
    const params = {
        'personID': personID
    };
    const response = await genericGetWithParameters('Address/GetMainAddressByPersonID', params);
    return response;
}
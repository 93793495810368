import React from 'react';

import AlertBox from '../../shared/AlertBox';
import { saveOnlineUserProfile } from '../../../networking/NetworkingOnlineUserProfile';
import { validate } from '../../../helpers/ValidateHelper';
import { formatValidInput } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';

import AppContext from '../../../AppContext';
let globalFunctions = {};
var style = getStyle();

export default class OnlineUserProfilesForm extends React.Component{
    static contextType = AppContext;
    state = {
        alertMessage:'',
        alertTitle: '',
        onlineUserProfileId: 0,
        onlineUserProfiles: [],
        profileName: '',
        showAlert: false,
        showAlertError: false,
        updateToken: '',
        validations: {
            profileName: true
        }
    }

    componentDidMount(){
        globalFunctions = this.context;
    }

    updateInputs = () =>{
        var onlineUserProfile = this.props.onlineUserProfile;
        var onlineUserId = 0
        var profileName = ''
        if(onlineUserProfile['OnlineUserProfileID'] !== null){
            onlineUserId = onlineUserProfile['OnlineUserProfileID'];
            profileName = onlineUserProfile['OnlineUserProfileName'];
        }

        console.log(onlineUserId);
        this.setState({onlineUserProfileId: onlineUserId, profileName: profileName});
    }
    
    componentDidUpdate(prevProps){
        if(this.props.selectedOnlineUserProfile!== {} && prevProps.selectedOnlineUserProfile !== this.props.selectedOnlineUserProfile){
            this.updateInputs()  
        };
        if(this.props.clearForm !== {} && this.props.clearForm === true){
            this.setState({
                onlineUserProfileId: 0,
                profileName: '',
                updateToken: '',        
            });
            this.props.outClearForm(false);
        }
    }

    handleClearClick = (event) =>{
        event.preventDefault();
        this.setState({
            onlineUserProfileId: 0,
            profileName: '',
            updateToken: '',
            validations: {
                profileName: true
            }
        },()=>this.props.isClearForm());
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }
    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }

    handleSaveClick(event){
        globalFunctions.startLoading();
        event.preventDefault();

        var onlineUserProfile = {
            OnlineUserProfileID: this.state.onlineUserProfileId,
            OnlineUserProfileName: this.state.profileName,
            UpdatedBy: localStorage.getItem('UserID'),
            UpdateToken: this.state.updateToken
        }
        
        if(this.validateRequiredInputs()){
            console.log(onlineUserProfile);
            saveOnlineUserProfile(onlineUserProfile).then(
                (json) =>{
                    if (json.httpStatusCode != undefined && json.httpStatusCode !== 200){
                        var alertMsg = 'An error occurred while trying to save the Fee Structure. Please try again.'
                        if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                            alertMsg = json.httpErrorMessage;
                        }
                        this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error', updateToken: json.updateToken});
                    } else {
                        this.setState({
                            onlineUserProfileId: 0,
                            profileName: '',
                            updateToken: '',
                            showAlert: true, alertTitle: 'Online user profile successfully saved.'});
                        this.props.updateTable();
                    } 
                    globalFunctions.finishLoading();                   
                }
            );
        }
    }

    //Inputs Validations
    validateRequiredInputs(){
        const fields = [
            {displayName:'ProfileName', validateName: 'profileName', value:this.state.profileName, type: 'string'}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    handleUpdateProfileName(event){
        this.setState({validations: {...this.state.validations, profileName: true}, profileName: event.target.value});
    }

    render(){
        return(
            <div>
                <div className="uk-width-1-2 uk-form-stacked">
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-online-user-profile-name" style={style.boldLabel}>Profile Name (*)</label>
                        <div className="uk-form-controls">
                            <input className={formatValidInput(this.state.validations.profileName)} id="form-online-user-profile-name" type="text" placeholder="Profile Name" value={this.state.profileName}  onChange={this.handleUpdateProfileName.bind(this)}/>
                        </div>
                    </div>
                    {/*<div className="uk-width-1-2 uk-form-stacked"></div>*/}
                    <div className="uk-width-2-2 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <hr/>
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>                
                </div>
            </div>
        );
    }
}
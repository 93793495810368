import React from 'react'

const AppContext = React.createContext({
    startLoading: () => {},
    finishLoading: () => {},
    forceLogout: () => {},
    toggleShowChangePassword: () => {}
});

export const AppProvider = AppContext.Provider
export const AppConsumer = AppContext.Consumer

export default AppContext
import React from 'react';

import {useJsonToCsv} from 'react-json-csv';

import MultipleSelect from '../shared/MultipleSelect';

import { IgrDataGridModule, IgrTextColumn, IgrColumnGroupDescription, IgrGridColumnOptionsModule, HeaderClickAction, IgrTemplateHeader } from 'igniteui-react-grids';
import { IgrDataGrid } from 'igniteui-react-grids';
import { DataSourceSectionHeaderDisplayMode } from 'igniteui-react-core';

import 'react-widgets/dist/css/react-widgets.css';

import { getNostroAccounts } from '../../networking/NetworkingBankAccounts';
import { getCashProjection } from '../../networking/NetworkingDeals';

import {getStyle} from '../../styles/styles';
import WizardScreen from '../trade/wizard/WizardScreen';
import CashProjectionTable from './CashProjectionTable';

var style = getStyle();
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class CashProjection extends React.Component{
    constructor(props){
        super(props);
        this.HorizontalRightHeader = new IgrTemplateHeader({});
        this.HorizontalRightHeader.cellUpdating = (s, e) => this.onHorizontalHeaderUpdating(s, e, "right");
    }

    state = {
        nostroAccounts: [],
        projectionData: [],
        nostroAccountsValue: [],
        nostroAccountsString: '',
        columns: [],
        columnsHelper: [],
        openDealsTable: false,
        dealsTableCurrency: '',
        dealsTableDateFrom: '',
        dealsTableDateTo: '',
    }
    
    componentDidMount = () =>{
        getNostroAccounts(0,0).then((value)=>{
            let nostroAccountsArray = value.nostroAccounts;
            let helperDefault = [];
            let stringHelper = [];   
            nostroAccountsArray.forEach(element => {
                helperDefault.push(element);
                stringHelper.push(element.BankAccountID)
            });    
            let nostrosID = stringHelper.join(',');
            this.setState({nostroAccounts: nostroAccountsArray, nostroAccountsValue: helperDefault, nostroAccountsString: nostrosID}
            /*, ()=>{
                this.onNostrosChange(helperDefault);
            }*/);
        });
        getCashProjection().then(response => {
            if (response.data) {
                this.defineColumns(response.data);
                let cashProjection =  response.data[0].balances;
                //cashProjection.add(response.data[0].Currency);
                //this.setState({projectionData: response.data});
                //debugger;
                this.setState({projectionData: cashProjection});
                let groupCurrency = new IgrColumnGroupDescription();
                groupCurrency.field = "Currency";
                groupCurrency.displayName = "";
                this.grid.groupDescriptions.add(groupCurrency);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
                if(this.state.positionFunc){
                    clearInterval(this.state.positionFunc);
                }
                var posFunc = setInterval(this.callFXCashProjections,5000);
                this.setState({positionFunc: posFunc});
            }
        });
    }
    
    componentWillUnmount(){
        clearInterval(this.state.positionFunc);
      }

    /*onNostrosChange = (value) => {
        let helper = [];
        value.forEach(element => {
            helper.push(element.BankAccountID);
        });

        let nostrosID = helper.join(',');
        this.setState({nostroAccountsValue: value, nostroAccountsString: nostrosID});
    }*/

    defineColumns = (data) => {
        let columnHelper = [];
        //let keys = Object.keys(data[0]);
        let keys = Object.keys(data[0].balances[0]);
        columnHelper.push({key: "Description", name: capitalize("Description"), lock: true});
        keys.forEach(element => {
            if (!element.includes('$')) {
                if(element === 'Currency' || element === 'Description' || element === 'Total'){
                    if(element === 'Currency') {
                        //columnHelper.push({key: element, name: '', lock: true});
                    } else {
                        columnHelper.push({key: element, name: capitalize(element), lock: true});
                    } 
                } else {
                    columnHelper.push({key: element, name: capitalize(element), lock: false});
                }
            }
        });

        let columnDirectives = [];
        this.setState({columnsHelper: columnHelper});
        console.log(columnHelper);
        columnHelper.forEach(element => {
            if (element.lock) {
                if(element.key === "Description"){
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="200" horizontalAlignment="right"/>);
                } else {
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} width="200"/>);
                }
                
            } else {
                columnDirectives.push(<IgrTextColumn key={element.key} field={element.key} headerText={element.name} header={this.HorizontalRightHeader} width="200" horizontalAlignment="right"/>);
            }
            
        })

        this.setState({columns: columnDirectives});
    }

    onGridRef = (grid) => {
        if (!grid) { return; }
        this.grid = grid;
    }

    onHorizontalHeaderUpdating = (s, e, align) => {
        const content = e.content; //as HTMLDivElement;
        let label = null;
        if (content.childElementCount === 0) {
            content.style.lineHeight = "25px";
            label = document.createElement("div");
            label.style.background = "transparent";
            label.style.color = "rgb(24, 29, 31)";
            label.style.overflowWrap = "break-word"
            label.style.verticalAlign = "center";
            label.style.textAlign = align;
            content.appendChild(label);
        } else {
            label = content.children[0] ; //as HTMLDivElement;
        }

        const info = e.cellInfo; // as IgrTemplateCellInfo;
        label.textContent = info.value;
    }

    onCellClicked = (grid, cellEvent) => {
        //debugger;
        let cell = cellEvent.cellInfo.rowItem
        let column = this.state.columnsHelper[Math.floor(cellEvent.cellInfo.x/200)];
        
        if(cell && cell.Currency && cell.Currency !== ' Totals') {
            console.log(cell.Currency);

            this.setState({
                dealsTableCurrency: cell.Currency,
                openDealsTable: true,
            })
        }
    }

    //exportNostros = () => {}

    callFXCashProjections = () => {
        getCashProjection().then(response => {
            if (response.data) {
                let newData = response.data[0].balances;
                for (let i = 0; i < this.state.projectionData.length; i++)
                {
                    let item = this.state.projectionData[i];
                    let balances = item.balances;
                    this.grid.notifyRemoveItem(i, item);
                    this.grid.notifyInsertItem(i, newData[i]);
                }
                this.grid.actualDataSource.queueAutoRefresh();
                this.grid.invalidateVisibleRows();
            }
        });
    }

    onCloseDealsTable = () => {
        this.setState({
            dealsTableCurrency: '',
            dealsTableDateFrom: '',
            dealsTableDateTo: '',
            openDealsTable: false,
        })
    }

    render = () =>{
        let groupOptions = {
            columns: ['Currency'],
            showDropArea: false
        };

        let enters = [];
        let lengthOfData = 0;
        let data = [];
        let columnData = this.state.columns;

        if(this.state.projectionData.length === 0){
            enters.push(<div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>)
        } else {
            lengthOfData = this.state.projectionData.length;
            data = this.state.projectionData;
        }

        return(
            <div>
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Cash Projection</h3>
                    <div>
                        <form className="uk-form-stacked uk-grid">
                            <div className="uk-width-4-12" >
                                <div className="uk-margin">
                                    {/*<label className="uk-form-label" htmlFor="form-bank-name">Nostro Account Selection</label>
                                    <div className="uk-form-controls">
                                        <MultipleSelect data={this.state.nostroAccounts} textField='BankAccountName' valueField='BankAccountID' onChange={this.onNostrosChange}/>
        </div>*/}
                                </div>
                            </div>
                            {/*<div className="uk-width-3-12">
                                <div className="uk-margin">
                                        <button style={{marginTop: '10px'}} className="uk-button uk-button-green" type="button" onClick={this.exportNostros}>Export</button>`
                                </div>
    </div>*/}
                        </form>
                    </div>
                    <br/>
                    <IgrDataGrid
                        height="80vh"
                        width="100%"
                        headerHeight="50"
                        dataSource={data}
                        ref={this.onGridRef}
                        autoGenerateColumns="false"
                        defaultColumnMinWidth="200"
                        isColumnOptionsEnabled="false"
                        headerClickAction={HeaderClickAction.None}
                        editMode="None"
                        cellClicked={this.onCellClicked}
                        selectionMode="SingleCell"
                        cornerRadiusTopLeft="0"
                        cornerRadiusTopRight="0"
                        >
                            {columnData}
                    </IgrDataGrid>
                        <CashProjectionTable open={this.state.openDealsTable} onClose={this.onCloseDealsTable} currencyID={this.state.dealsTableCurrency}/>
                </div>
            </div>
        );
    }
}
import React from 'react';

import AlertBox from '../../../shared/AlertBox';
import NewDynamicTable from '../../../../components/shared/NewDynamicTable';

import {
    getCustomReports,
    getCustomReportByReportId,
    getCustomReportByReportIdAndWhereClause,
    deleteCustomReport
} from '../../../../networking/NetworkingCustomReport';

import QueryBuilder from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { getStyle } from '../../../../styles/styles';

var style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const queryBuilderCSS = {
    combinators: 'uk-select uk-width-2-12',
    //rule: 'uk-grid',
    fields: 'uk-select uk-width-5-12',
    operators: 'uk-select uk-width-2-12',
    value: 'uk-input uk-width-4-12',
    //removeRule: 'uk-width-1-12'
};

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class ListOfReportsScreen extends React.Component {

    state = {
        loading: false,
        customReportsList: [],
        dataCustomReportList: [],
        parametersDialogOpen: false,

        jsonQuery: {},
        sqlQuery: '',
        parametersFieldsQuery: [],
    }

    componentDidMount = () => {
        getCustomReports().then(
            (json) => {
                if (json !== undefined) {
                    switch (json.httpStatusCode) {
                        case 200:
                            {
                                if (json.CustomReports !== undefined) {
                                    var list = json.CustomReports;
                                    this.setState({
                                        customReportsList: list
                                    });
                                }
                                break;
                            }
                        default:
                            {
                                this.setState({
                                    showAlert: true,
                                    alertMessage: 'Something went wrong loading the data. Please try again later',
                                    alertTitle: 'Error',
                                });
                                break;
                            }
                    }
                }
            }
        );
    }

    closeDeleteAlert = () => {
        this.setState({
            showDeleteAlert: false,
            customReportId: 0,
        });
    }

    logQuery = (query) => {
        this.setState({ jsonQuery: query, sqlQuery: formatQuery(query, 'sql') })
    }

    handleDeleteCustomReportClick = (obj) => {
        this.setState({
            showDeleteAlert: true,
            customReportId: obj['Report Id']
        });

    }

    handleExecuteCustomReportClick = (obj) => {        
        console.log(obj)
        //Open Dialog
        if (obj.SQLQuery.includes('?')) {
            let cleanJsonQuery = JSON.parse(obj.JSONQuery);
            //clear ? from rules as it causes issues
            if (cleanJsonQuery !== undefined && cleanJsonQuery != null && cleanJsonQuery.rules != null
                && Array.isArray(cleanJsonQuery.rules)) {
                cleanJsonQuery.rules.forEach(x => {
                    x.value = ''
                })
            }

            this.setState({
                jsonQuery: cleanJsonQuery,
                parametersFieldsQuery: JSON.parse(obj.FieldsQuery),
                parametersDialogOpen: true,
                customReportName: obj['Report Name'],
                reportId: obj['Report Id'],
                reportOrderByColumns: obj.OrderByColumns || ''
            })
        } else {
            this.setState({
                loading: true,
                open: true,
                customReportName: obj['Report Name'],
                reportOrderByColumns: obj.OrderByColumns || '',
                listMissing: []
            }, () => {
                getCustomReportByReportId(obj['Report Id']).then(
                    (json) => {

                        if (json !== undefined) {
                            if (json.DataCustomReport !== undefined) {
                                switch (json.httpStatusCode) {
                                    case 200:
                                        {
                                            if(json.DataCustomReport.length > 0){
                                                var pages = 0;
                                                var total = json.DataCustomReport.length;
                                                pages = Math.ceil(total / this.state.numberPerPage);
        
                                                var size = this.state.maxWidth
                                                var keysCount = Object.keys(json.DataCustomReport[0]);
        
                                                if (keysCount.length > 7) {
                                                    size = 'xl'
                                                }
        
                                                this.setState({
                                                    loading: false,
                                                    dataCustomReportList: json.DataCustomReport,
                                                    totalPages: pages,
                                                    maxWidth: size
                                                });
                                            } else {
                                                this.setState({
                                                    loading: false,
                                                    showAlert: true,
                                                    alertTitle: 'Error',
                                                    alertMessage: 'There is no data for this request. Please try again.',
                                                });
                                            }
                                            break;
                                        }
                                    default:
                                        {
                                            break;
                                        }
                                }
                            } else {
                                switch (json.httpStatusCode) {
                                    case 503:
                                    {
                                        if (json.httpErrorMessage !== undefined) {
                                            this.setState({
                                                loading: false,
                                                showAlert: true,
                                                alertTitle: 'Error',
                                                alertMessage: json.httpErrorMessage,
                                            });
                                        }
                                        break;
                                    }
                                default:
                                    {
                                        this.setState({
                                            loading: false,
                                            open: false,
                                            showAlert: true,
                                            alertTitle: 'Error',
                                            alertMessage: 'There is no data for this request. Please try again.',
                                        });
                                        break;
                                    }
                                }
                            }
                        }
                    }
                );
            });
        }
    }

    closeAlert = () => {
        this.setState({
            showAlert: false,
            alertMessage: '',
            alertTitle: '',
        });
    }

    handleRowClick = (obj) => {
        this.props.reportClick(obj);
    }

    onCloseDialog = () => {
        this.setState({
            open: false,
            dataCustomReportList: [],
            customReportName: '',
            reportOrderByColumns: '',
            maxWidth: 'md',
            currentPage: 1,
            totalPages: 0
        });
    }

    onCloseParametersDialog = () => {
        this.setState({
            parametersDialogOpen: false,
        });
    }

    newReportClick = () => {
        this.props.newReportClick();
    }

    executeCustomReportWithParameters = () => {
        this.setState({
            loading: true,
            open: true,
            parametersDialogOpen: false,
            listMissing: []
        }, () => {
            getCustomReportByReportIdAndWhereClause(this.state.reportId, this.state.sqlQuery).then(
                (json) => {
                    if (json !== undefined) {
                        if (json.DataCustomReport !== undefined) {
                            switch (json.httpStatusCode) {
                                case 200:
                                    {
                                        if(json.DataCustomReport.length > 0){
                                            var pages = 0;
                                            var total = json.DataCustomReport.length;
                                            pages = Math.ceil(total / this.state.numberPerPage);
    
                                            var size = this.state.maxWidth
                                            var keysCount = Object.keys(json.DataCustomReport[0]);
    
                                            if (keysCount.length > 7) {
                                                size = 'xl'
                                            }
    
                                            this.setState({
                                                loading: false,
                                                dataCustomReportList: json.DataCustomReport,
                                                totalPages: pages,
                                                maxWidth: size
                                            });
                                        } else {
                                            this.setState({
                                                loading: false,
                                                showAlert: true,
                                                alertTitle: 'Error',
                                                alertMessage: 'There is no data for this request. Please try again.',
                                            });
                                        }
                                        break;
                                    }
                                default:
                                    {
                                        break;
                                    }
                            }
                        } else {
                            switch (json.httpStatusCode) {
                                case 503:
                                    {
                                        if (json.httpErrorMessage !== undefined) {
                                            this.setState({
                                                loading: false,
                                                showAlert: true,
                                                alertTitle: 'Error',
                                                alertMessage: json.httpErrorMessage,
                                            });
                                        }
                                        break;
                                    }
                                default:
                                    {
                                        this.setState({
                                            loading: false,
                                            open: false,
                                            showAlert: true,
                                            alertTitle: 'Error',
                                            alertMessage: 'There is no data for this request. Please try again.',
                                        });
                                        break;
                                    }
                            }
                        }
                    } else {
                        this.setState({
                            loading: false,
                            open: false,
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'There is no data for this request. Please try again.',
                        });
                    }
                }
            );
        });
    }

    updateCustomReportList = () => {
        getCustomReports().then(
            (json) => {
                if (json !== undefined) {
                    switch (json.httpStatusCode) {
                        case 200:
                            {
                                if (json.CustomReports !== undefined) {
                                    var list = json.CustomReports;
                                    this.setState({
                                        customReportsList: list
                                    });
                                }
                                break;
                            }
                        default:
                            {
                                this.setState({
                                    showAlert: true,
                                    alertMessage: 'Something went wrong loading the data. Please try again later',
                                    alertTitle: 'Error',
                                });
                                break;
                            }
                    }
                }
            }
        );
    }

    yesDeleteAlert = () => {
        deleteCustomReport(this.state.customReportId).then(
            (jsonResponse) => {
                ////debugger
                if (jsonResponse !== undefined) {
                    switch (jsonResponse.httpStatusCode) {
                        case 200:
                            {
                                if (jsonResponse.Result === true) {
                                    this.setState({
                                        showDeleteAlert: false,
                                        showAlert: true,
                                        alertTitle: 'Success',
                                        alertMessage: 'Custom Report successfully removed.',
                                        customReportId: 0,
                                    }, () => {
                                        this.updateCustomReportList();
                                    });
                                }
                                break;
                            }
                        default:
                            {
                                this.setState({
                                    customReportId: 0,
                                });
                                break;
                            }
                    }
                }
            }
        );
    }

    getallcustomreports = async () => {
        const json = await getCustomReports();
        if (json != null && json.CustomReports != null) {
            return json.CustomReports;
        }else{
            return [];
        }
    }

    render = () => { 
        
        return (
            <div>
                <div className="uk-margin">
                    <button className="uk-button uk-button-green" style={{ marginBottom: 20 }} onClick={this.newReportClick} >New Report</button>
                </div>
                <div className="uk-margin">
                    <div className="uk-width-1-1 uk-form-stacked">
                        <NewDynamicTable
                            id="table-customReports"
                            hiddenHeaders={['Report Id', 'Created By', 'Report Source Id', 'SQLQuery', 'JSONQuery', 'FieldsQuery']}
                            data={this.state.customReportsList}
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteCustomReportClick}
                            dynamicActions={[{ name: 'Execute', function: this.handleExecuteCustomReportClick, isIcon: true }]}
                            enableClick={true}
                            clickFunction={this.handleRowClick}
                            useFilter={true}
                            filterFunction={this.getallcustomreports}
                        />
                    </div>
                </div>
                <AlertBox
                    open={this.state.showAlert}
                    onClose={this.closeAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeAlert}
                    messageBoldList={this.state.listMissing}
                />
                <Dialog
                    open={this.state.open}
                    onClose={this.onCloseDialog}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="attachments-dialog-title" onClose={this.onCloseDialog}>
                        {this.state.customReportName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-margin" style={{ backgroundColor: '#ffffff' }}>
                            {
                                this.state.loading === true &&
                                <h4 style={{
                                    textAlign: 'center'
                                }}>Loading...</h4>
                            }
                            {
                                this.state.dataCustomReportList.length === 0 &&
                                this.state.loading === false && <div>
                                    <h4 style={{
                                        textAlign: 'center'
                                    }}>No data</h4>
                                </div>
                            }
                            {
                                this.state.loading === false &&
                                <NewDynamicTable
                                    id="dataCustomReport-table"
                                    data={this.state.dataCustomReportList}
                                    enableClick={false}
                                    usePaginator={true}
                                    changePage={this.changePage}
                                    numberOfPages={this.state.totalPages}
                                    pageNumber={this.state.currentPage}
                                    numberPerPage={this.state.numberPerPage}
                                    useExportButton={true}
                                    tableName={this.state.customReportName}
                                />
                            }
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.parametersDialogOpen}
                    onClose={this.onCloseParametersDialog}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="attachments-dialog-title" onClose={this.onCloseParametersDialog}>
                        {this.state.customReportName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-margin" style={{ backgroundColor: '#ffffff' }}>
                            {/* fields={this.state.rightList}*/}
                            <QueryBuilder fields={this.state.parametersFieldsQuery} onQueryChange={this.logQuery} query={this.state.jsonQuery} controlClassnames={queryBuilderCSS} />
                            <div hidden={this.state.reportOrderByColumns === ''} style={{ padding: '10px' }}>
                                <label style={{ backgroundColor: '#f1f1f1' }}>Order by {this.state.reportOrderByColumns}</label>
                            </div>
                            <button className="uk-button uk-button-green uk-margin"
                                onClick={this.executeCustomReportWithParameters}>Execute</button>
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox
                    id="delete-customReport-alert"
                    open={this.state.showDeleteAlert}
                    onClose={this.closeDeleteAlert}
                    title="Warning"
                    message="Are you sure you want to delete this Custom Report? This action can not be undone."
                    type="Yes/No"
                    yesClick={this.yesDeleteAlert}
                    noClick={this.closeDeleteAlert}
                />
            </div>
        );
    }
}
import React from 'react';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import { getGUID, updateParameter } from '../../../networking/NetworkingGlobalSystemParameters';
import VolatilityPaddingTable from '../../../components/maintenance/volatility_padding/VolatilityPaddingTable';
import {Colors} from '../../../constants/Colors'

const style = getStyle();

export default class VolatilityPaddingMaintenanceScreen extends React.Component {

    state = {
        treasuryPaddingList: [],
        paddingId: 0,
        markUpFactor: 0,
        percentOrPoints: '',
        updateToken: 0,

        homeCurrency: '',
        homeCurrencyName: '',
        foreignCurrency: '',
        foreignCurrencyName: '',
        currencyList: [],
        foreignCurrencyList: [],
        readyToDeleteRate: {},
        showAlert: false,
        showAlertError: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        validations: {
            homeCurrency: true,
            foreignCurrency: true,
            markUpFactor: true,
            percentPoints: true
        },
        totalPages: 0,
        newSearch: true,
        pages: 1,
        //ENABLE OR DISABLED
        parameterName: '',
        buttonName: '',
        colorName: '',
        jsonResponse: null
    }

    componentDidMount() {
        this.updateEnableDisabled();
    }

    updateEnableDisabled = () => {
        getGUID('EnableVolatilityPadding').then(
            (jsonResponse) => {
                if (jsonResponse.ParameterValue === 'true') {
                    this.setState({ parameterName: 'ENABLED   ', colorName: Colors.primaryColor, buttonName: 'DISABLE', jsonResponse })
                } else {
                    this.setState({ parameterName: 'DISABLED   ', colorName: 'red', buttonName: 'ENABLE', jsonResponse })
                }
            }
        );
    }

    handleEnableDisabledCLick = (event) => {        
        event.preventDefault();
        let objFilter = {
            'ParameterName': this.state.jsonResponse.ParameterName,
            'ParameterValue': this.state.jsonResponse.ParameterValue === 'true' ? 'false' : 'true',
            'ParameterValueDataType': this.state.jsonResponse.ParameterValueDataType,
            'ParameterDescription': this.state.jsonResponse.ParameterDescription
        }
        updateParameter(objFilter).then(
            (json) => {
                switch (json.httpStatusCode) {
                    case 200:
                        this.updateEnableDisabled()
                        break;
                    default:
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage });
                        break;
                }
            }
        );
    }

    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }

    render() {
        return (
            <div>
                <form className="uk-form-stacked">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Volatility Padding</h3>
                    <p>
                        Use this screen to define and add additional spread to all rates issued by the system in times of market uncertainty. You can enable/disable the additional spreads at any time.
                    </p>
                    <p>
                        Volatility padding is currently: <h5 style={{ color: this.state.colorName, display: 'contents' }}><b>{this.state.parameterName}</b>
                            <button className="uk-button uk-button-green" onClick={this.handleEnableDisabledCLick.bind(this)}>{this.state.buttonName}</button></h5>
                    </p>                    
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />

                <VolatilityPaddingTable/>
            </div>
        );
    }
}
import { Dialog, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { getStyle } from '../../../../styles/styles';
import NumberInput from '../../../shared/NumberInput';
import DatePicker from '../../../shared/DatePicker'
import { formatDate, formatValidInput } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';

var style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
export default class ExtendedOnlinePropertiesForm extends React.Component {
    state = {
        data: null,
        validations: {
            Description: true
        }
    }
    componentDidMount() {
        /*
        DataType: "DateTime"
        Description: "Test 5 (Date between 2019/12/01 and 2020/12/01)"
        EntityAdditionalPropertyID: 797
        Mandatory: "True"
        Page Number: 1
        maxDate: "2020-12-01T00:00:00"
        maxNumber: 0
        minDate: "2019-12-01T00:00:00"
        minNumber: 0
        noOfDecimals: 0
        rangeValidate: true
        value: "2020/09/07"
        */
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (this.props.data != null && this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data }, () => {
                this.validateRequiredInputs();
            })
        }
    }
    onClose = () => {
        this.props.close()
    }
    SaveClick = () => {
        this.props.SaveClick(this.state.data, this.state.valid, this.state.errors)
    }
    handleUpdateValueTypeText = (event) => {
        this.setState({
            data: { ...this.state.data, value: event.target.value },
            validations: { ...this.state.validations, Description: true }
        }, () => {
            this.validateRequiredInputs();
        })
    }
    handleUpdateValueTypeNumber = (event) => {
        this.setState({
            data: { ...this.state.data, value: event.target.value },
            validations: { ...this.state.validations, Description: true }
        }, () => {
            this.validateRequiredInputs();
        })
    }
    handleUpdateValueTypeDate = (date) => {
        this.setState({
            data: { ...this.state.data, value: formatDate(date) },
            validations: { ...this.state.validations, Description: true }
        }, () => {
            this.validateRequiredInputs();
        })
    }
    validateRequiredInputs = () => {
        let element = this.state.data;
        var type = '';
        var obj = null;
        //Determinar el tipo de datos
        switch (element.DataType) {
            case 'Text':
                type = 'string';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value of ' + element.value + ' must not be more than ' + element.maxNumber + ' characters.',
                            validateName: 'Description',
                            validation: () => this.textLengthVerification(element.minNumber, element.maxNumber, element.value) //No se puede mandar un method con parametros
                        }
                    }
                }
                break;
            case 'Numeric':
                type = 'wholeNumber';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value must be between ' + element.minNumber + ' and ' + element.maxNumber + '.',
                            validateName: 'Description',
                            validation: () => this.numericVerification(element.minNumber, element.maxNumber, element.value) //asi que lo guardo en otra lista para luego juntarlo
                        }
                    }
                }
                break;
            case 'DateTime':
                type = 'string';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value must be between ' + formatDate(element.minDate) + ' and ' + formatDate(element.maxDate) + '.',
                            validateName: 'Description',
                            validation: () => this.dateTimeRangeVerification(element.minDate, element.maxDate, element.value) //asi para todos
                        }
                    }
                }
                break;
            default:
                type = 'string';
                break;
        }
        if (obj == null) {
            obj = { displayName: 'value', validateName: 'Description', value: element.value, type: type };
        }
        const fields = [obj];

        const [valid, errors] = validate(fields);
        this.setState({ valid, errors })
        return { valid, errors };
    }
    validateRequiredFields = () => {
        let element = this.state.data;
        var type = '';
        var obj = null;
        //Determinar el tipo de datos
        //Determinar el tipo de datos
        switch (element.DataType) {
            case 'Text':
                type = 'string';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value of ' + element.value + ' must not be more than ' + element.maxNumber + ' characters.',
                            validateName: 'Description',
                            validation: () => this.textLengthVerification(element.minNumber, element.maxNumber, element.value) //No se puede mandar un method con parametros
                        }
                    }
                }
                break;
            case 'Numeric':
                type = 'wholeNumber';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value must be between ' + element.minNumber + ' and ' + element.maxNumber + '.',
                            validateName: 'Description',
                            validation: () => this.numericVerification(element.minNumber, element.maxNumber, element.value) //asi que lo guardo en otra lista para luego juntarlo
                        }
                    }
                }
                break;
            case 'DateTime':
                type = 'string';
                if (element.rangeValidate) {
                    if (element.value != null && element.value !== '') {
                        obj = {
                            displayName: 'The value must be between ' + formatDate(element.minDate) + ' and ' + formatDate(element.maxDate) + '.',
                            validateName: 'Description',
                            validation: () => this.dateTimeRangeVerification(element.minDate, element.maxDate, element.value) //asi para todos
                        }
                    }
                }
                break;
            default:
                type = 'string';
                break;
        }
        if (obj == null) {
            obj = { displayName: 'value', validateName: 'Description', value: element.value, type: type };
        }
        const fields = [obj];

        const [valid, validations, errors] = validate(fields);
        this.setState({ valid, validations, errors })
        return { valid, validations, errors };
    }

    //verifica los rangos
    textLengthVerification = (min, max, value) => {
        const newMin = parseInt(min);
        const newMax = parseInt(max);
        const lengthString = value.length;
        return (newMin <= lengthString && lengthString <= newMax);
    }

    numericVerification = (min, max, value) => {
        return (min <= value && value <= max);
    }

    dateTimeRangeVerification = (minDate, maxDate, valueDate) => {
        //const currentDate = formatStringToDate(valueDate);
        const miniDate = new Date(minDate);
        const maxiDate = new Date(maxDate);
        const currDate = new Date(valueDate);

        return (miniDate <= currDate && currDate <= maxiDate);
    }
    render() {
        var rows = (<div></div>)
        if (this.state.data != null) {
            switch (this.state.data.DataType) {
                default:
                case 'Text':
                    {
                        rows = (
                            <input
                                type="text"
                                className={formatValidInput(this.state.validations.Description)}
                                id="data_value"
                                name="data_value"
                                maxLength="100"
                                value={this.state.data.value}
                                onChange={this.handleUpdateValueTypeText}
                            />
                        );
                        break;
                    }
                case 'Numeric':
                    {
                        rows = (
                            <NumberInput
                                id="data_value"
                                name="data_value"
                                type="Integer"
                                className={formatValidInput(this.state.validations.Description)}
                                value={parseInt(this.state.data.value)}
                                onChange={this.handleUpdateValueTypeNumber}
                                scale={this.state.data.noOfDecimals}
                            />
                        );
                        break;
                    }
                case 'DateTime':
                    {
                        rows = (
                            <DatePicker
                                useNew
                                id="data_value"
                                name="data_value"
                                type="Integer"
                                className={formatValidInput(this.state.validations.Description)}
                                value={this.state.data.value}
                                onDayChange={this.handleUpdateValueTypeDate}
                            />
                        );
                        break;
                    }
            }
            return (
                <Dialog open={this.props.open} onClose={this.onClose} maxWidth={"sm"} fullWidth={true}>
                    <DialogTitle id="attachments-dialog-title" onClose={this.onClose}>
                        {this.state.data.Description}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-grid" style={{ marginTop: '75px', marginBottom: '75px' }}>
                            <div className="uk-width-1-1 uk-form-stacked">
                                <label className="uk-form-label">Value</label>
                                <div className="" style={style.inputStyle}>
                                    {rows}
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label">Page Number</label>
                                <div className="" style={style.inputStyle}>
                                    <input className='uk-input' id="user" type="text" value={this.state.data['Page Number']} disabled={true} />
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label">Mandatory</label>
                                <div className="" style={style.inputStyle}>
                                    <input className='uk-input' id="user" type="text" value={this.state.data.Mandatory} disabled={true} />
                                </div>
                            </div>
                            <div className="uk-margin">
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.SaveClick} type="button">Save</button>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.onClose} type="button">Cancel</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {getStyle} from '../../styles/styles'
import VersionsScreen from '../shared/Versions/VersionsScreen';
import { getOutgoingFundsVersionComparer, getOutgoingFundsVersions } from '../../networking/NetworkingFunds';

var style = getStyle();

const initialDate = new Date();
export default class OutgoingFundVersionsScreen extends React.Component {
    state = {        
        outgoingVersions: [],
        columnDetail: []
    }

    componentDidMount(){
        if(this.props.open)
        {
        this.loadVersions(this.props.entityID);
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityID !== this.props.entityID || (prevProps.open !== this.props.open && this.props.open))
        {
            this.loadVersions(this.props.entityID);
        }
    }

    loadVersions = (outgoingFundID) =>{

        if(outgoingFundID > 0)
        {
            getOutgoingFundsVersions(outgoingFundID).then(
                (json)=>{
                    if (json !== null && json !== undefined) {
                        if (json.result !== undefined) {
                            this.setState({outgoingVersions: json.result})
                        }
                        if (json.columnDetail != null) {
                            const _columnDetail = JSON.parse(json.columnDetail);
                            this.setState({columnDetail: _columnDetail})
                        }
                    }
                }
            )
        }
        else
        {
            this.setState({outgoingVersions: [],
                columnDetail: []})
        }
    }

    handleExitClick = () => {
        this.props.close();
    }

    getVersionComparer = (selectedVersions) =>{
        if(selectedVersions.length === 2)
        {
            getOutgoingFundsVersionComparer(this.props.entityID, selectedVersions[0], selectedVersions[1]).then(
                (json) =>{
                    if(json === undefined){
                        this.setState({differences: []});
                    }else{
                        this.setState({differences: json.data});
                    }
                }
            )
        }
    }

    render(){

        return(
            <Dialog open={this.props.open} onClose={this.handleExitClick} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Outgoing Versions</DialogTitle>
                <DialogContent dividers>
                    <VersionsScreen versions ={this.state.outgoingVersions} columnDetail={this.state.columnDetail} EntityType={'Outgoing'}
                                differences = {this.state.differences} onClickVersionComparer={this.getVersionComparer}/>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"exit"} onClick={this.handleExitClick}>Exit</button>
                </DialogActions>
            </Dialog>
            
        );
    }
}
import React from 'react';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getTraders } from '../../../networking/NetworkingBlotters';
import { getUncoveredDeals } from '../../../networking/NetworkingDeals';
import { getActiveTradersMin } from '../../../networking/NetworkingTrader';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable.old';
const style = getStyle();

export default class WizardUncoveredDeals extends React.Component {
    
    state = {
        deals: [],
        traders: [],
        selectedDeals: [],
        newSearch: false,
        showAlertError: false,
        alertMessage: '',
        TraderID: 0,
        Trader: '',
        totalPages: 0,
        currentPage: 1
    }

    componentDidMount() {
        this.refreshUncoveredDeals(this.state.TraderID);
        getActiveTradersMin().then(
            (json) => {
                if (json != null && json.traders != null) {
                    getTraders().then( // List of traders without assigned Blotter
                        (json2) => {
                            if (json2.traders != null) {
                                let activeTradersWithBlotter = json.traders
                                    .filter(({ TraderID }) => !json2.traders.some((traderWithoutBlotter) => TraderID === traderWithoutBlotter.TraderId))
                                    .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
                                activeTradersWithBlotter.unshift({name: 'All traders', value: 0});
                                this.setState({ traders: activeTradersWithBlotter });
                            }
                        }
                    )
                }
            }
        );
    }

    handleContinueClick = () => {
        if (this.state.selectedDeals.length > 0) {
            const currencyPairs = this.state.selectedDeals.map(d => d['CCYPair$']);
            const dealTypes = this.state.selectedDeals.map(d => d.DealType);
            const valueDates = this.state.selectedDeals.map(d => d['Value Date']);
            if ((new Set(currencyPairs).size * new Set(dealTypes).size * new Set(valueDates).size) === 1) {
                const dealNos = this.state.selectedDeals.map(d => d.DealNo);
                const sellAmount = this.state.selectedDeals.map(d => d['Sell Amount']).reduce((accumulator, next) => accumulator + next);
                const buyAmount = this.state.selectedDeals.map(d => d['Buy Amount']).reduce((accumulator, next) => accumulator + next);
                // alert(`Data to send to Covers tab
                //       DealIDs: ${dealNos}
                //       CurrencyPair:  ${currencyPairs[0]}
                //       DealType: ${dealTypes[0]}
                //       ValueDate: ${valueDates[0]}
                //       SellAmount: ${sellAmount}`);
                this.props.onSelectUncoveredDeals(dealNos, currencyPairs[0], sellAmount, buyAmount, dealTypes[0], valueDates[0]);
            } else {
                this.setState({ showAlertError: true, alertMessage: 'Please only select Deals with the same Currency Pair, Value Date and Deal Type.' });
            }
        } else {
            this.setState({ showAlertError: true, alertMessage: 'Please select a Deal.' });
        }
    }

    onExitClick = () => {
        this.props.onExitClick();
    }

    handleCheckboxClick = (deal, checked) => {
        console.log(deal)
        let selectedDeals = this.state.selectedDeals;
        if (checked) {
            selectedDeals.push(deal);
        } else {
            const dealsDifferentThan = (dealNo) => (deal) => deal.DealNo !== dealNo;
            selectedDeals = selectedDeals.filter(dealsDifferentThan(deal.DealNo));
        }
        this.setState({ selectedDeals });
    }

    refreshUncoveredDeals = async (traderID) => {
        const json = await getUncoveredDeals(3404, traderID, 0, 0);
        if (json != null && json.deals != null) {
            const totalCount = json.deals.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ deals: json.deals, totalPages, newSearch: true });
        } else {
            this.setState({ deals: [], newSearch: true });
        }
    }

    getAllUncoveredDeals = async () => {
        const json = await getUncoveredDeals(3404, this.state.TraderID, 0, 0)
        if (json != null && json.deals != null) {
            return json.deals;
        } else {
            return [];
        }
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    handleGetValueTrader = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            if (value.value !== 'select') {
                this.refreshUncoveredDeals(value.value);
            }
            this.setState({
                TraderID: value.value !== "select" ? value.value : 0,
                Trader: value.name != null ? value.name : ''
            });
        }
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    }

    render() {
        var uncoveredTitle = (
            <h3 className="uk-heading-divider" style={style.titleStyle}>
                <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.onExitClick}>
                    <i className="fa fa-arrow-left back-arrow-white"></i>
                </button>
                Uncovered Deals
            </h3>);
        if (this.props.isBreadcrumb != null && this.props.isBreadcrumb) {
            uncoveredTitle = ''
        }
        return (
            <div>
                {uncoveredTitle}
                <form className="uk-form-horizontal uk-margin">
                    <div className="uk-grid">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel} >Trader (*)</label>
                            <div className="" style={{ paddingBottom: "5px" }}>
                                <DynamicSelect className="uk-select" objValue={{ value: this.state.TraderID, name: this.state.Trader }} getValue={this.handleGetValueTrader} placeholder={"Select an Item..."} data={this.state.traders} id="ud-traders" />
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleContinueClick}>Continue</button>
                    <button className="uk-button uk-button-green uk-margin-right" style={{ float: 'right' }} onClick={this.onExitClick} >Exit</button>
                    <hr />
                </div>
                <NewDynamicTable
                    id="ud-ndt"
                    useCheckBox={true}
                    checkboxFunction={this.handleCheckboxClick}
                    data={this.state.deals}
                    newSearch={this.state.newSearch}
                    formats={[{header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy CCY'}, {header: 'Sell Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Sell CCY'}]}
                    selectedRows={this.state.selectedDeals}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.currentPage}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true}
                    filterFunction={this.getAllUncoveredDeals}
                />
                <AlertBox
                        open={this.state.showAlertError}
                        onClose={this.closeAlertError}
                        title='Error'
                        message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} />
            </div>
        );
    }
}
import React from 'react';

import {formatToCurrency} from '../../../../../helpers/FormatHelper';

import './CardBoardBeneficiaries.scss';


export default class CardBeneficiary extends React.Component {
    
    componentDidMount = () => {
    }

    onCardClick = () => {
        this.props.onClickElement(this.props.element);
    }

    render = () => {
        let headers = Object.keys(this.props.element);
        let cardData = [];

        for (var i=0; i<headers.length; i++) {
            if (i===0) {
                if(!headers[i].includes('{No label}')){
                    cardData.push(<p className="card-title">{headers[i]}: {this.props.element[headers[i]]}</p>);
                } else {
                    cardData.push(<p className="card-title">{this.props.element[headers[i]]}</p>);
                }
            } else {
                if(!headers[i].includes('{No line}') && headers[i] !== 'BackgroundColour'){
                    if(!headers[i].includes('{No label}')){
                        cardData.push(<p className="card-content">{headers[i]}: {this.props.element[headers[i]]}</p>);
                    } else {
                        cardData.push(<p className="card-content">{this.props.element[headers[i]]}</p>);
                    }
                }
            }
        }

        return(
            <div>
                <div className="main-card" onClick={this.onCardClick}>
                    {cardData}
                </div>
            </div>
        );
    }
}
import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export const getListOfOnlineUserProfile = async (nameLike) => {
    try {
        const params = {
            'nameLike': nameLike
        }

        const response = await genericGetWithParameters('OnlineUserProfile/GetListOfOnlineUserProfilesMin', params)

        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteOnlineUserProfile(onlineUserProfileID){
    try{
        let params = {
            'onlineUserProfileID': onlineUserProfileID
        };
        let response = await genericDeleteWithParameters('OnlineUserProfile/DeleteOnlineUserProfile',params);
        //let response = await genericCallWithBody('Delete', 'OnlineUserProfile/DeleteOnlineUserProfile', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveOnlineUserProfile = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'OnlineUserProfile/SaveOnlineUserProfile', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveDetails = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'OnlineUserProfile/SaveDetails', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import { genericCallWithBody, genericGetWithParameters } from './Networking';

export async function GetTemplateNames() {
    try {
        let params = {}
        let response = await genericGetWithParameters('EmailTemplates/GetTemplateNames', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function CreateEmailTemplates(emailTemplate, body, subject, location,includeFooter){
    var method = 'PUT';
    var apiName = 'EmailTemplates/CreateEmailTemplates';
    var request = {
        EmailTemplateName: emailTemplate,
        Body: body,
        Subject: subject,
        Location: location,
        IncludeFooter: includeFooter,
        UpdatedBy: localStorage.getItem('UserID')
    }
    try {
        let response = await genericCallWithBody(method,apiName,request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetParametersByTemplateName(templateName) {
    try {
        let params = {
            'TemplateName': templateName
        }
        let response = await genericGetWithParameters('EmailTemplates/GetParametersByTemplateName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable'
import AlertBox from '../../shared/AlertBox'
import LoadingBox from '../../shared/LoadingBox'
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'
import {getMarginCalls} from '../../../networking/NetworkingCustomers'
import {createMarginCalls} from '../../../networking/NetworkingDeals'
import {getEODParameters} from '../../../helpers/PackageJsonHelper'
import {formatToCurrencyWithScale} from '../../../helpers/FormatHelper'

export default class MarginCallsForm extends React.Component {
    state = {
        marginCalls: [],
        marginCallsAux: [],
        newSearch: true,
        totalPages: 0,
        page: 1,
        marginCall: {},
        showAlert: false,
        showCommitConfirmation: false,
        titleAlert: '',
        messageAlert: '',
        loading: false,
        baseCurrency: localStorage.getItem(GlobalSystemParametersNames.BaseCurrency)
    }

    componentDidMount()
    {
        if (this.props.marginCallList !== undefined && this.props.marginCallList.length > 0) {
            var pages = Math.ceil(this.props.marginCallList.length / getDefaultPagingNumberOfItems());
            this.setState({
                totalPages: pages,
                marginCallsAux: this.props.marginCallList,
                marginCalls: this.transformMarginCallList(this.props.marginCallList)
            });

        }
        else {
            console.log(' no existe');

            this.getMarginCalls();    
        }
    }

    getMarginCalls()
    {
        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
        
        getMarginCalls(0,systemDate,this.state.page,getDefaultPagingNumberOfItems()).then(
            (json) =>
            {
                if(json !== undefined)
                {
                    if(json.marginCalls !== undefined)
                    {
                        var mcHelper = this.transformMarginCallList(json.marginCalls);
                        var totalCount = json.totalCount;
                        var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());

                        this.setState({
                            marginCalls : mcHelper,
                            marginCallsAux: json.marginCalls,
                            totalPages: pages,
                            newSearch: true
                        });
                    }   
                }
            }
        );
    }

    reloadMarginCalls = () => {
        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);

        getMarginCalls(0, systemDate, 1, getDefaultPagingNumberOfItems()).then(
            (json) => {
                if (json !== undefined) {
                    if (json.marginCalls !== undefined) {
                        var mcHelper = this.transformMarginCallList(json.marginCalls);
                        var totalCount = json.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());

                        this.setState({
                            marginCalls: mcHelper,
                            marginCallsAux: json.marginCalls,
                            page: 1,
                            totalPages: pages,
                            newSearch: true
                        });
                    }
                }
            }
        );
    }
    transformMarginCallList (list) 
    {        
        var helper = [];
        for(var i = 0; i<list.length; i++)
        {
            var deals = list[i]['Deals'];
            
            helper.push({
                'CustomerID'   : list[i]['CustomerID'], 
                'Selected': false,
                'Email Customer': false,
                'Email Trader'   : false,                 
                'Customer Name'  : list[i]['CustomerShortName'],
                'TraderName'  : list[i]['TraderShortName'],
                'Max Exposure Limit'   : list[i]['MaxExposureLimit'].toFixed(2),
                'Total Exposure'  : this.calculateTotalExposure(deals),
                'Total Forwards'   : list[i]['TotalForwards'],
                'Total Deposits'   : this.calculateTotalDeposits(list[i]['Deposits']),
                'Total Received Deposits'   : formatToCurrencyWithScale(list[i]['TotalReceivedDeposits'], this.state.baseCurrency),
                'Total Margin Call'   : this.calculateMarginCall(deals),
                'Deals'   : deals,
                'Deposits'   : list[i]['Deposits'],
                'MarginCallCurrency': list[i]['MarginCallCurrency'],
                'TraderID': list[i]['TraderID']
            });
        }
        return helper;
    }

    calculateTotalExposure(deals){

        var totalExposure = 0;
        var exposure = 0;
        var amount = 0;
        
        if(deals != undefined)
        {
            var dealsJson = JSON.parse(deals);
            for(var d = 0; d<dealsJson.length; d++)
            {
                var item = dealsJson[d];
                
                if (item.BuyCurrency != item.MarginCallCurrency &&
                    item.SellCurrency != item.MarginCallCurrency)
                {
                    var newNetExposureInBaseCurrency = 0;
                    var amountInBaseCurrency = 0;

                    if (item.RateTermsXCCY == 'D')
                    {
                        newNetExposureInBaseCurrency = parseFloat((item.NewNetExposure * item.FwdMarketRateXCCY));
                        newNetExposureInBaseCurrency = newNetExposureInBaseCurrency.toFixed(item.MarginCallNroOfDecimal);

                        amountInBaseCurrency = parseFloat(item.BuyAmount * item.FwdMarketRateXCCY);
                        amountInBaseCurrency = amountInBaseCurrency.toFixed(item.MarginCallNroOfDecimal);
                    }
                    else if (item.RateTermsXCCY == 'I')
                    {
                        if (item.FwdMarketRateXCCY != 0)
                        {
                            newNetExposureInBaseCurrency = parseFloat(item.NewNetExposure / item.FwdMarketRateXCCY);
                            newNetExposureInBaseCurrency = newNetExposureInBaseCurrency.toFixed(item.MarginCallNroOfDecimal);
                        }
                        else
                        {
                            newNetExposureInBaseCurrency = 0;
                        }

                        if (item.FwdMarketRateXCCY != 0)
                        {
                            amountInBaseCurrency = parseFloat(item.BuyAmount) / parseFloat(item.FwdMarketRateXCCY);
                            amountInBaseCurrency = amountInBaseCurrency.toFixed(item.MarginCallNroOfDecimal);
                        }
                        else
                        {
                            amountInBaseCurrency = 0;
                        }
                    }
                    exposure += parseFloat(newNetExposureInBaseCurrency);
                    amount += parseFloat(amountInBaseCurrency);
                }
                else if (item.BuyCurrency == item.MarginCallCurrency &&
                    item.SellCurrency != item.MarginCallCurrency)
                {
                    exposure += parseFloat(item.NewNetExposure);
                    amount += parseFloat(item.BuyAmount);
                }
                else if (item.BuyCurrency != item.MarginCallCurrency &&
                    item.SellCurrency == item.MarginCallCurrency)
                {
                    exposure += parseFloat(item.NewNetExposure);
                    amount += parseFloat(item.SellAmount);
                }
            }
        }

        if (amount != 0)
        {
            totalExposure = parseFloat((exposure / amount)) * 100;
            totalExposure = totalExposure.toFixed(2);
        }

        return totalExposure;
    }

    calculateTotalDeposits(deposits){

        var totalDeposits = 0;
        if(deposits != undefined)
        {
            var depositsJson = JSON.parse(deposits);
            for(var d = 0; d<depositsJson.length; d++)
            {
                totalDeposits += parseFloat(depositsJson[d].BaseAmount);
            }
        }

        return formatToCurrencyWithScale(totalDeposits,this.state.baseCurrency);
    }

    calculateMarginCall(deals){

        var totalMarginCall = 0;
        if(deals != undefined)
        {
            var dealsJson = JSON.parse(deals);
            for(var d = 0; d<dealsJson.length; d++)
            {
                if(dealsJson[d].MarginCallEquivalentAmount != undefined)
                {
                    totalMarginCall += parseFloat(dealsJson[d].MarginCallEquivalentAmount);
                }
            }
        }

        return formatToCurrencyWithScale(totalMarginCall, this.state.baseCurrency);
    }

    handleRowClick = (obj) =>
    {
        if(obj != undefined)
        {
            this.setState({marginCall: obj});
            this.props.onClickRow(obj, this.state.marginCallsAux);
        }
    } 

    changePage = (value) => {
        this.setState({page: value}, () => this.getMarginCalls());
    }

    handleCommitClick= () => 
    {
        this.setState({showCommitConfirmation: true});
    }

    handleCalculateMarginsClick = () =>
    {
        this.setState({
            marginCall : {},
            marginCalls: [],
            marginCallsAux: [],
            titleAlert: 'Message',
            messageAlert: 'Margin Call calculated successfully.'
        }, () => {this.getMarginCalls(); this.setState({
            showAlert : true});});
    }

    handleSelected = (obj, checked) =>
    {
        if(this.state.marginCalls !== undefined)
        {
            var tempMargin = this.state.marginCalls;
            var changedIndex = tempMargin.findIndex(item => item.CustomerID === obj.CustomerID); 
            //console.log(tempMargin);
            tempMargin[changedIndex]['Selected'] = checked;
        }
    }

    handleEmailCustomer = (obj, checked) =>
    {
        if(this.state.marginCalls !== undefined)
        {
            var tempMargin = this.state.marginCalls;
            var changedIndex = tempMargin.findIndex(item => item.CustomerID === obj.CustomerID); 
            //console.log(tempMargin);
            tempMargin[changedIndex]['Email Customer'] = checked;
        }
    }

    handleEmailTrader = (obj, checked) =>
    {
        if(this.state.marginCalls !== undefined)
        {
            var tempMargin = this.state.marginCalls;
            var changedIndex = tempMargin.findIndex(item => item.CustomerID === obj.CustomerID); 
            //console.log(tempMargin);
            tempMargin[changedIndex]['Email Trader'] = checked;
        }
    }

    okGlobalAlert = () =>
    {
        this.setState({showAlert: false});
    }

    closeCommitConfirmation = () =>
    {
        this.setState({showCommitConfirmation: false});
    }

    yesCommitConfirmation = () =>
    {

        if(this.validateUserSelection())
        {
            this.setState({loading: true});
            var tempMargin = this.state.marginCalls;
            var selectedItems = tempMargin.filter(element => element.Selected);
            var oTotals = [];
            var listDeals = [];
            var listDeposit = [];
            
            selectedItems.forEach(marginCall => 
                {
                    listDeals = JSON.parse(marginCall['Deals']);
                    listDeposit = JSON.parse(marginCall['Deposits']);

                    oTotals.push(
                        {
                            'CustomerID'   : marginCall.CustomerID, 
                            'TraderID': marginCall.TraderID,
                            'CustomerShortName': marginCall['Customer Name'],
                            'IsChecked': marginCall.Selected,
                            'SendToCustomerChecked': marginCall['Email Customer'],
                            'SendToTraderChecked': marginCall['Email Trader'],
                            'MarginCallCurrency': marginCall['MarginCallCurrency'],
                            'Deals'   : listDeals,
                            'Deposits'   : listDeposit
                        }
                    );
                });

                var EODParameters = getEODParameters();
                var objMarginCall = {
                    'MarginCallTotals': oTotals,
                    'UserID': localStorage.getItem('UserID'),
                    'ApplicationName': EODParameters.ApplicationName
                };

                createMarginCalls(objMarginCall).then(
                    (json) => {
                        if(json !== undefined)
                        {
                            var alertMessage = 'Margin call was created successfully';
                            if(json.errorMessage !== '')
                            {
                                alertMessage = json.errorMessage;
                            }
                        }

                        this.setState({messageAlert: alertMessage,
                        showCommitConfirmation: false,
                        showAlert: true,
                        loading: false,                        
                        page: 1}, () => this.getMarginCalls());
                    }
                );
        }
    }

    validateUserSelection()
    {
        var tempMargin = this.state.marginCalls;

        var mailItemsWithoutSelection = tempMargin.filter(element => (element['Email Customer'] || element['Email Trader']) && !element.Selected);

        if(mailItemsWithoutSelection !== undefined && mailItemsWithoutSelection.length > 0)
        {
            this.setState({messageAlert: 'Customer: '+ mailItemsWithoutSelection[0]['Customer Name']+' must be selected to send notification(s).',
                showCommitConfirmation: false,
                showAlert: true});
            return false;
        }

        var selectedItems = tempMargin.filter(element => element.Selected);
        if(selectedItems === undefined || selectedItems.length === 0)
        {
            this.setState({messageAlert: 'Must select a record.',
                showCommitConfirmation: false,
                showAlert: true});
            return false;
        }

        var selectedInvalid = selectedItems.filter(element => element['Total Margin Call'] <= 0);

        if(selectedInvalid !== undefined && selectedInvalid.length > 0)
        {
            this.setState({messageAlert: 'Customer: '+ selectedInvalid[0]['Customer Name']+' has an invalid margin call. Margin call must be a positive number.',
                showCommitConfirmation: false,
                showAlert: true});
            return false;
        }

        return true;
    }

    render()
    {
        

        return(
            <div>
                <div className="uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green   uk-float-right" type="button" onClick={this.reloadMarginCalls}>Refresh</button>
                        <button className="uk-button uk-button-green   uk-float-right" type="button" onClick={this.handleCalculateMarginsClick}>Calculate Margins</button>
                        <button className="uk-button uk-button-green   uk-float-right" type="button" onClick={this.handleCommitClick}>Commit</button>
                    </div>             
                    <NewDynamicTable
                                hiddenHeaders={['CustomerID', 'Selected','Email Customer', 'Email Trader', 'Deals','Deposits', 'MarginCallCurrency','TraderID']}
                                data={this.state.marginCalls}
                                enableClick={true}
                                clickFunction={this.handleRowClick}
                                newSearch={this.state.newSearch}
                                useDeleteButton={false}
                                usePaginator={true}
                                changePage={this.changePage}
                                numberOfPages={this.state.totalPages}
                                pageNumber={this.state.page}
                                numberPerPage={getDefaultPagingNumberOfItems()}
                                enableSortByHeader={false}
                                useFilter={false}
                                //filterFunction={this.getAllCustomers}
                                useExportButton={true}
                                tableName="marginCalls"
                                useMultiCheckBox={true}
                                dynamicCheckboxHeaders={['Selected','Email Customer', 'Email Trader']}
                                dynamicCheckboxFunction={[{function: this.handleSelected},{function: this.handleEmailCustomer},{function: this.handleEmailTrader}]}
                    />
                </div>
                <AlertBox id="calulate-alert" 
                    open={this.state.showAlert} 
                    title={this.state.titleAlert}
                    message={this.state.messageAlert}
                    type="Ok"
                    okClick={this.okGlobalAlert}/>
                <AlertBox id="commit-confirmation"
                    open={this.state.showCommitConfirmation}
                    title="Confirmation"
                    message="Are you sure you want to commit the selected margin calls?"
                    type="Yes/No"
                    onClose={this.closeCommitConfirmation}
                    yesClick={this.yesCommitConfirmation}
                    noClick={this.closeCommitConfirmation}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );

    }

}
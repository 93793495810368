import jwt from 'jsonwebtoken';
import {genericGetWithParameters, genericGetWithParametersNoEncrypted} from '../networking/Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getActiveTradersMin(){
    try {
        let response = await genericGetWithParameters('Trader/GetActiveTradersMin', null);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getGlobalSearch(strSearch, strSearchTable, fromDate)
{
    try {
        let params = {
            'strSearch': strSearch,
            'strSearchTable': strSearchTable,
            'fromDate': fromDate,
        };

        let response = await genericGetWithParameters('Trader/GetGlobalSearch', params);
        return response;
    } catch (error) 
    {
        console.error(error);
    }
}

export const getActiveBlotterByCustomerID = async (customerID) => {
    try {
        const params = {
            'customerID': customerID
        };
        const response = await genericGetWithParametersNoEncrypted('Trader/GetActiveBlotterByCustomerID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
  }
  
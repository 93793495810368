import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as mime from 'mime-types';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { LookUpTables } from '../../constants/LookUpTables';
import { getLookUpTable } from '../../networking/Networking';
import { getConfirmation, getDealHeaderInfo, getInwardConfirmation, sendDealReportByEmail } from '../../networking/NetworkingDeals';
import { getPropertiesForDealHeader } from '../../networking/NetworkingEntityProperties';
import { getContactsByCustomer } from '../../networking/NetworkingContacts';
import { getCustomerByID } from '../../networking/NetworkingCustomers';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import { validateEmail2 } from '../../helpers/ValidateHelper'
import AlertBox from '../shared/AlertBox';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CustomerType } from '../../constants/EntityTypeNames';

////debugger
//const addPasswordToReports = (window.REACT_APP_ADD_PASSWORD_TO_REPORTS) ? window.REACT_APP_ADD_PASSWORD_TO_REPORTS : false;
let addPasswordToReports = (window.REACT_APP_ADD_PASSWORD_TO_REPORTS) ? window.REACT_APP_ADD_PASSWORD_TO_REPORTS : false;
//const addPasswordToReports = false;

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  

const DocumentTypes = {
    CONFIRMATION        : 'Confirmation',
    INWARD_CONFIRMATION  : 'Confirmation-InwardConfirmation',
    /*Confirmation = "Confirmation";
    ForwardContract = "ForwardContract";
    WireTransfer = "WireTransfer";
    DesignatedAccountDetails = "DesignatedAccountDetails";
    InwardConfirmation = "InwardConfirmation";*/
}

export default class DealConfirmationFormDialog extends React.Component {

    state = {
        dealHeaderInfo: {},
        language: "",
        email: "",
        customerContactSelected: 0,
        additionalEmail: "",
        fax: "",
        additionalFax: "",
        //
        dealHeaderId: 0,
        title: 'Print/Send Deal Confirmation',
        documentType: DocumentTypes.CONFIRMATION,
        //
        emailChecked: false,
        additionalEmailChecked: false,
        faxChecked: false,        
        additionalFaxChecked: false,
        InwardsFundsConfirmationChecked: false,
        //
        emailDisabled: true,
        additionalEmailDisabled: true,
        faxDisabled: true,
        additionalFaxDisabled: true,
        //
        listLanguages: [],
        customerContactsList: [],
        //
        loading: false,
        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        messageList: [],
        //
        showAlertEmail: false,
        alertMessageEmail: '',
        alertTitleEmail: '',
        //
        //preview
        currentFileName         : '',
        currentFileURLList      : [],
        openPreviewFile         : false,
        currentInwardName         : '',
        currentInwardURLList      : [],
        openPreviewInward       : false,
        fullWidthPreviewFile    : true,
        maxWidthPreviewFile     : 'md',

        additionalMobileNumber  : '',

        validations: {
            language: true,
            email: true,
            additionalEmail: true,
            fax: true,
            additionalFax: true,
            additionalMobileNumber: true
        },

        addPwdToReports         : false,
    }

    async componentDidUpdate(prevProps)
    {
        if(this.props !== prevProps)
        {
            if (this.props.lookUpTables != null && this.props.lookUpTables !== prevProps.lookUpTables) {
                this.setState({dealHeaderId: this.props.dealHeaderId},
                    () => {
                        let lookUpTables = this.props.lookUpTables
    
                        let languages = lookUpTables.filter(x => x.LookUpTableDescription === LookUpTables.Languages)[0];
    
                        languages.lookUpTableDetails.forEach(element => {
                            element.value = element.LookUpTableDetail;
                            element.name = element.LookUpTableDetail;
                        });
                        this.setState({listLanguages: languages.lookUpTableDetails})
                    })
            }
            if (this.props.dealHeaderInfo != null && this.props.dealHeaderInfo !== prevProps.dealHeaderInfo) {
                this.setState({dealHeaderInfo: this.props.dealHeaderInfo}, () => {
                    //default values:
                    this.setState({
                        email: this.state.dealHeaderInfo.Email,
                        emailChecked: this.state.dealHeaderInfo.EmailConfirmations,
                        fax: this.state.dealHeaderInfo.OfficeFax,
                        faxChecked: this.state.dealHeaderInfo.FaxConfirmations,
                        language: this.state.dealHeaderInfo.PreferredLanguage,
                        emailDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.Email),
                        faxDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.OfficeFax)
                    }, () =>{
                        this.getCustomerContactList(this.props.dealHeaderInfo);
                    });
                })
            }
            if (this.props.dealHeaderId != null && this.props.dealHeaderId !== prevProps.dealHeaderId) {
                if(this.props.dealHeaderId > 0){
                    let propertiesC = await getPropertiesForDealHeader(this.props.dealHeaderId, CustomerType);
                    if (propertiesC != null && propertiesC.entityProperties != null) {
                        let addPwdToReports = propertiesC.entityProperties.find(x => x.Description === 'AddPasswordToReports');
                        if(addPwdToReports != null && addPwdToReports.TextValue !== null && addPwdToReports.TextValue.toLowerCase() === 'yes'){
                            addPasswordToReports = true
                            this.setState({addPwdToReports: true})
                        }
                    }
                }
                if (this.props.dealHeaderInfo == null) {
                    getDealHeaderInfo(this.props.dealHeaderId).then(
                        (value) => {
                            if (value != null && value.dealHeaderInfo != null) {
                                let dealHeaderInfo = {};
                                if (value.dealHeaderInfo != null) 
                                {
                                    dealHeaderInfo = value.dealHeaderInfo[0];
                                    this.setState({
                                        dealHeaderId: this.props.dealHeaderId,
                                        dealHeaderInfo: dealHeaderInfo,
                                        email: dealHeaderInfo.Email,
                                        emailChecked: dealHeaderInfo.EmailConfirmations,
                                        fax: dealHeaderInfo.OfficeFax,
                                        faxChecked: dealHeaderInfo.FaxConfirmations,
                                        language: dealHeaderInfo.PreferredLanguage,
                                        emailDisabled: !this.stringEmptyOrNull(dealHeaderInfo.Email),
                                        faxDisabled: !this.stringEmptyOrNull(dealHeaderInfo.OfficeFax)
                                    }, () => {
                                        this.getCustomerContactList(this.state.dealHeaderInfo);
                                    });
                                }
                            }
                        }
                    );
                } else {
                    this.setState({ dealHeaderId: this.props.dealHeaderId });
                }
            }
        }
    }

    getCustomerContactList = (dealHeaderInfo) =>
    {
        getContactsByCustomer(dealHeaderInfo['Customer ID'],0,0).then(
            (value) => {
                /*
                    httpErrorMessage: "The request is invalid."
                    httpStatusCode: 400

                    contacts: (11) [{
                        Contact ID: 9273
                        ContactType$: "Individual"
                        Email: "m.alves@nexo.com"
                        Main Contact$: false
                        MaxOpenForwardDays$: 0
                        Mobile Phone: ""
                        Name: "Alves, Mellissa"
                        PersonID$: "EA6D1B89-5F84-4324-A83D-1C5905F9151B"
                        Phone: "1234"
                        Status$: "Active"
                    }, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
                    httpErrorMessage: undefined
                    httpStatusCode: 200
                    totalCount: 11
                */
                if (value != undefined) 
                {
                    let areCustomerContacts = false;
                    let errorMessage = 'Could not bring customer contact list.';
                    switch (value.httpStatusCode) 
                    {
                        case 200:
                        {    
                            areCustomerContacts = true;
                            break;
                        }
                        case 400:
                        {
                            if(value.httpErrorMessage !== '')
                            {
                                errorMessage = value.httpErrorMessage;
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }

                    if(areCustomerContacts)
                    {
                        ////debugger
                        let newList = [];
                        let idMain = 0;
                        if(value.contacts != undefined)
                        {
                            newList = value.contacts.length > 0 ? value.contacts: [];
                            newList.forEach(contact => {
                                contact.value = contact['Contact ID'];
                                contact.name = contact.Name + ' - ' + contact['Email'] + ' (' + contact['Mobile Phone'] + ')'

                                if(contact['Main Contact$'])
                                {
                                    idMain = contact['Contact ID'];
                                }
                            });
                        }

                        this.setState({
                            customerContactsList: newList,
                            customerContactSelected: idMain
                        }, () =>{
                            getCustomerByID(dealHeaderInfo['Customer ID']).then(
                                (responseGetCustomerByID) => {
                                    /*
                                    */
                                    if(responseGetCustomerByID !== undefined)
                                    {
                                        let newContactLikeCustomer = {
                                            value           : -1,
                                            'Contact ID'    : -1,
                                            name            : responseGetCustomerByID.Name + ' - ' + responseGetCustomerByID.Email + ' (' + responseGetCustomerByID.MobilePhone + ')',
                                            'Email'         : responseGetCustomerByID.Email,
                                            'Mobile Phone'  : responseGetCustomerByID.MobilePhone,

                                        }
                                        let list = this.state.customerContactsList;
                                        list.unshift(newContactLikeCustomer);
                                        this.setState({
                                            customerContactsList: list,
                                            customerContactSelected: this.state.customerContactSelected === 0 ? -1 : this.state.customerContactSelected
                                        });
                                    }
                                }
                            );
                        });
                    }else
                    {
                        this.setState({
                            showAlert      : true,
                            alertTitle     : 'Error',
                            alertMessage   : errorMessage
                        });
                    }
                }
            }
        );
    }

    componentDidMount() {
        console.log(this.props)
        addPasswordToReports = (window.REACT_APP_ADD_PASSWORD_TO_REPORTS) ? window.REACT_APP_ADD_PASSWORD_TO_REPORTS : false;
        if (this.props.lookUpTables != null) {
            let lookUpTables = this.props.lookUpTables;
            let languages = lookUpTables.filter(x => x.LookUpTableDescription === LookUpTables.Languages)[0];

            languages.lookUpTableDetails.forEach(element => {
                element.value = element.LookUpTableDetail;
                element.name = element.LookUpTableDetail;
            });
            this.setState({listLanguages: languages.lookUpTableDetails})
        }
        if (this.props.dealHeaderInfo != null) {
            this.setState({
                dealHeaderInfo: this.props.dealHeaderInfo,
                email: this.props.dealHeaderInfo.Email,
                emailChecked: this.props.dealHeaderInfo.EmailConfirmations,
                fax: this.props.dealHeaderInfo.OfficeFax,
                faxChecked: this.props.dealHeaderInfo.FaxConfirmations,
                language: this.props.dealHeaderInfo.PreferredLanguage,
                emailDisabled: !this.stringEmptyOrNull(this.props.dealHeaderInfo.Email),
                faxDisabled: !this.stringEmptyOrNull(this.props.dealHeaderInfo.OfficeFax)
            });
        }
        if (this.props.dealHeaderId != null) {
            if (this.props.dealHeaderInfo == null) 
            {
                getDealHeaderInfo(this.props.dealHeaderId).then(
                    (value) => {
                        if (value != null && value.dealHeaderInfo != null) {
                            let dealHeaderInfo = {};
                            if (value.dealHeaderInfo != null) {
                                dealHeaderInfo = value.dealHeaderInfo[0];
                                this.setState({
                                    dealHeaderId: this.props.dealHeaderId,
                                    dealHeaderInfo: dealHeaderInfo,
                                    email: dealHeaderInfo.Email,
                                    emailChecked: dealHeaderInfo.EmailConfirmations,
                                    fax: dealHeaderInfo.OfficeFax,
                                    faxChecked: dealHeaderInfo.FaxConfirmations,
                                    language: dealHeaderInfo.PreferredLanguage,
                                    emailDisabled: !this.stringEmptyOrNull(dealHeaderInfo.Email),
                                    faxDisabled: !this.stringEmptyOrNull(dealHeaderInfo.OfficeFax)
                                }, () => {
                                    this.getCustomerContactList(this.state.dealHeaderInfo);
                                });
                            }
                        }
                    }
                );
            } else {
                this.setState({ dealHeaderId: this.props.dealHeaderId });
            }
        }
        if (this.props.lookUpTables == null) {
            getLookUpTable('Languages').then(
                (json) => {
                    if (json != null && json.lookUpTables != null) {
                        let languages = json.lookUpTables[0];
                        languages.lookUpTableDetails.forEach(element => {
                            element.value = element.LookUpTableDetail;
                            element.name = element.LookUpTableDetail;
                        });
                        this.setState({ listLanguages: languages.lookUpTableDetails });
                    }
                }
            )
        }
    }

    onCloseDialog = () => {
        this.setState({}, () => this.props.onClose());
    }

    //services
    showConfirmation = () => {
        this.setState({loading: true}, () => {
            getConfirmation(this.state.dealHeaderId).then((value) => {
                this.setState({loading: false})
                if (value !== undefined) {
                    let fileName = 'Deal Confirmation ' + this.state.dealHeaderId + '.pdf';
                    var byteCharacters = atob(value.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var bytes = new Uint8Array(byteNumbers);
                    var blob = new Blob([bytes], {
                        type: mime.lookup(fileName)
                    });

                    /*var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();*/

                    let fileList = [{
                        uri     : window.URL.createObjectURL(blob),
                        fileType: 'pdf',
                        fileName: fileName
                    }];

                    this.setState({
                        currentFileName     : fileName,
                        currentFileURLList  : fileList,
                        openPreviewFile     : true
                    });
                }
            })
        })        
    }

    
    showInwardConfirmation = () => {
        this.setState({loading: true}, () => {
            getInwardConfirmation(this.state.dealHeaderId).then((value) => {
                this.setState({loading: false})
                if (value !== undefined) {
                    if(value.Message === undefined){
                        let fileName = 'Inward Funds Confirmation ' + this.state.dealHeaderId + '.pdf';
                        var byteCharacters = atob(value.data);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var bytes = new Uint8Array(byteNumbers);
                        var blob = new Blob([bytes], {
                            type: mime.lookup(fileName)
                        });
    
                        let fileList = [{
                            uri     : window.URL.createObjectURL(blob),
                            fileType: 'pdf',
                            fileName: fileName
                        }];
    
                        this.setState({
                            currentInwardName     : fileName,
                            currentInwardURLList  : fileList,
                            openPreviewInward     : true
                        });
                    } else {
                        this.setState({
                            showAlert      : true,
                            alertTitle     : 'Error',
                            alertMessage   : value.Message
                        });
                    }
                }
            })
        })        
    }

    yesToAlertEmail = () => {
        this.sendConfirmationDirect()
    }

    sendConfirmation = () => {

        var resultValidateFields = this.validateRequiredFields();
        var r1 = resultValidateFields[0];
        var r2 = resultValidateFields[1];

        if (!r1)
        {
            if(r2.length > 0)
            {
                this.setState({
                    showAlert      : true,
                    alertTitle     : 'Error',
                    alertMessage   : 'Please fill all mandatory fields:',
                    messageList    : r2
                });
            }
            return;
        }

        if (this.validateEmailBelongsToCustomer()) 
        {
            this.sendConfirmationDirect();
        } else {
            this.setState({
                showAlertEmail: true,
                alertTitleEmail: 'Warning',
                alertMessageEmail: 'One or more emails do not belong to this customer. Do you still want to send these emails?'
            });
        }
    }

    sendConfirmationDirect = () => {
        this.setState({
            showAlertEmail: false, alertTitleEmail: '', alertMessageEmail: ''
        });

        if (!this.state.emailChecked && !this.state.additionalEmailChecked && !this.state.faxChecked && !this.state.additionalFaxChecked) 
        {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please select who to send the confirmation to.'
            });
            return;
        }

        let sendDealReportByEmailRequest = {
            DealHeaderID: this.state.dealHeaderId,
            Language: this.state.language,
            SendTrader: false,
        }

        let DealMailInfo = [];
        let confirmationValue = '';

        if(this.state.InwardsFundsConfirmationChecked)
        {
            confirmationValue = DocumentTypes.INWARD_CONFIRMATION
        }else
        {
            confirmationValue = DocumentTypes.CONFIRMATION
        }

        if (this.state.emailChecked) 
        {
            if(addPasswordToReports)
            {
                this.state.customerContactsList.forEach(customerContact => {
                    if(customerContact['Contact ID'] === this.state.customerContactSelected)
                    {
                        DealMailInfo.push({
                            MainInfo    : customerContact.Email, 
                            MobilePhone : customerContact['Mobile Phone'],
                            DocType     : confirmationValue
                        });
                    }
                });
            }else
            {
                DealMailInfo.push({MainInfo: this.state.email, DocType: confirmationValue})
            }
        }
        
        if (this.state.additionalEmailChecked) 
        {
            if(addPasswordToReports)
            {
                DealMailInfo.push({
                    MainInfo    : this.state.additionalEmail, 
                    MobilePhone : this.state.additionalMobileNumber,
                    DocType     : confirmationValue
                });
            }else
            {
                DealMailInfo.push({MainInfo: this.state.additionalEmail, DocType: confirmationValue});
            }
        }

        if (this.state.faxChecked) {
            DealMailInfo.push({MainInfo: this.state.fax, DocType: confirmationValue})
        }
        
        if (this.state.additionalFaxChecked) {
            DealMailInfo.push({MainInfo: this.state.additionalFax, DocType: confirmationValue})
        }

        sendDealReportByEmailRequest.DealMailInfo = DealMailInfo;        

        this.setState({loading: true}, () => {
            sendDealReportByEmail(sendDealReportByEmailRequest).then((response) => {
                console.log(response)
                /*
                    ErrorMessage     : null
                    Message          : "Error sending SMS. (Error en el servidor remoto: (400) Solicitud incorrecta.)"
                    httpErrorMessage : "An error occurred. Please try again."
                    httpStatusCode   : 500
                */
                this.setState({loading: false});

                if (response.httpStatusCode !== 200) 
                {
                    let errorMessageAux = 'An error occurred while sending the report. Please try again.';
                    switch (response.httpStatusCode) 
                    {
                        case 500:
                        {
                            if(response.Message !== undefined && response.Message !== '')
                            {
                                errorMessageAux = response.Message;
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }

                    this.setState({
                        showAlert    : true,
                        alertMessage : errorMessageAux,
                        alertTitle   : 'Error'
                    });
                } else {
                    /*
                        ErrorMessage: null
                        Message: "{\r\n  \"result\": \"Confirmation sent successfully.\"\r\n}"
                        httpErrorMessage: undefined
                        httpStatusCode: 200
                    */

                    this.setState({
                        showAlert: true,
                        alertTitle: ' Success',
                        alertMessage: response.Message//'Confirmation sent successfully.'
                    });
                }
            })
        })
    }

    //validations
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    stringEmptyOrNull(str){
        return (str === null || str === '')
    }

    validateEmailBelongsToCustomer() {
        if (this.props.dealHeaderInfo != null && this.props.dealHeaderInfo.EmailsForConfirmations != null && this.props.dealHeaderInfo.EmailsForConfirmations.length > 0) {
            let customerContactEmails = this.props.dealHeaderInfo.EmailsForConfirmations.map(x => x.Email)
            let emails = []

            if (!addPasswordToReports && this.state.emailChecked && !this.stringEmptyOrNull(this.state.email)) {
                emails.push(this.state.email)
            }
    
            if (this.state.additionalEmailChecked && !this.stringEmptyOrNull(this.state.additionalEmail)) {
                emails.push(this.state.additionalEmail)
            }
    
            if (this.state.faxChecked && !this.stringEmptyOrNull(this.state.fax)) {
                emails.push(this.state.fax)
            }
    
            if (this.state.additionalFaxChecked && !this.stringEmptyOrNull(this.state.additionalFax)) {
                emails.push(this.state.additionalFax)
            }
            let invalidEmails = emails.filter(x => !customerContactEmails.includes(x))
            if (invalidEmails.length > 0) {
                return false;
            }
        }
        return true
    }

    validateRequiredFields() 
    {
        let valid = true;
        let validLanguage = true, validEmail = true, validCusomterContactSelected = true, validAdditionalEmail = true, validFax = true, validAdditionalFax=true, validAdditionalMobileNumber = true;
        let messages = [];

        if (this.stringEmptyOrNull(this.state.language) || this.state.language.includes('Select')) {
            validLanguage = false;
        }
        
        if (!addPasswordToReports && this.state.emailChecked && this.stringEmptyOrNull(this.state.email)) {
            validEmail = false;
        }

        if(addPasswordToReports && this.state.emailChecked && this.state.customerContactSelected !== undefined)
        {
            if(this.state.customerContactSelected === 0)
            {
                validCusomterContactSelected = false;
            }else
            {
                /*
                    {
                        Contact ID: 9273
                        ContactType$: "Individual"
                        Email: "m.alves@nexo.com"
                        Main Contact$: false
                        MaxOpenForwardDays$: 0
                        Mobile Phone: ""
                        Name: "Alves, Mellissa"
                        PersonID$: "EA6D1B89-5F84-4324-A83D-1C5905F9151B"
                        Phone: "1234"
                        Status$: "Active"
                    }
                */
                let ccSelected = null;
                this.state.customerContactsList.forEach(cc => {
                    if(cc['Contact ID'] === this.state.customerContactSelected)
                    {
                        ccSelected = cc;
                    }
                });

                if(ccSelected !== null)
                {
                    if(this.stringEmptyOrNull(ccSelected['Email']))
                    {
                        validCusomterContactSelected = false;
                        messages.push("Contact " + ccSelected.Name + " does not have an Email. Fill in this information first or select another Contact.");
                    }

                    if(this.stringEmptyOrNull(ccSelected['Mobile Phone']))
                    {
                        validCusomterContactSelected = false;
                        messages.push("Contact " + ccSelected.Name + " does not have a Mobile Phone. Fill in this information first or select another Contact.");
                    }
                }
            }
        }

        if (this.state.additionalEmailChecked && this.stringEmptyOrNull(this.state.additionalEmail)) {
            validAdditionalEmail = false;
        }else
        {
            if(this.state.additionalEmailChecked && !validateEmail2(this.state.additionalEmail))
            {
                validAdditionalEmail = false;
                messages.push('Enter a valir Additional Email');
            }
        }

        if (addPasswordToReports && this.state.additionalEmailChecked && this.stringEmptyOrNull(this.state.additionalMobileNumber)) {
            validAdditionalMobileNumber = false;
        }

        if (this.state.faxChecked && this.stringEmptyOrNull(this.state.fax)) {
            validFax = false;
        }

        if (this.state.additionalFaxChecked && this.stringEmptyOrNull(this.state.additionalFax)) {
            validAdditionalFax = false;
        }

        valid = validLanguage && validEmail && validCusomterContactSelected &&validAdditionalEmail && validFax && validAdditionalFax && validAdditionalMobileNumber;

        this.setState({
            validations: {
                ...this.state.validations,
                language: validLanguage,
                email: addPasswordToReports? validCusomterContactSelected : validEmail,
                additionalEmail: validAdditionalEmail,
                fax: validFax,
                additionalFax: validAdditionalFax,
                additionalMobileNumber: validAdditionalMobileNumber
            }
        });

        return [valid, messages];
    }

    //events

    handleLanguage(value){
        if (typeof value !== undefined && value !== undefined) 
        {
            switch (typeof value) {
                case 'object':
                {
                    this.setState({language: value !== null ? value.value : ''});
                    break;
                }
                case 'string':
                {
                    this.setState({language: value !== null ? value : ''});
                    break;
                }
            }
        }
    }

    handleCustomerContact = (contact) =>{
        /*
            Contact ID: 9363
            ContactType$: "Individual"
            Email: "hugobullont@gmail.com"
            Main Contact$: false
            MaxOpenForwardDays$: 0
            Mobile Phone: "888888"
            Name: "Hugo Bullon"
            PersonID$: "9E6307AB-E807-4421-851B-BCFDD382158C"
            Phone: "999999"
            Status$: "Active"
            controlID: "form-dealconfirmation-customercontact"
            name: "Hugo Bullon - hugobullont@gmail.com (888888)"
            value: "9363"
        */
        if (contact !== undefined && typeof(contact) === 'object')
        {
            this.setState({
                customerContactSelected: contact['Contact ID'],
                validations: { 
                    ...this.state.validations, 
                    customerContactSelected: true,
                }
            });
        }
    }

    handleEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({emailChecked: checked})
    }

    handleEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleAdditionalEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({
            additionalEmailDisabled: !checked, 
            additionalEmail: '', 
            additionalMobileNumber: '',
            additionalEmailChecked: checked,
            validations: { 
                ...this.state.validations, 
                additionalMobileNumber: !checked? true: this.state.validations.additionalMobileNumber,
                additionalEmail: !checked? true: this.state.validations.additionalEmail
            }
        });
    }

    handleAdditionalEmail = (event) => {
        this.setState({additionalEmail: event.target.value, validations: { ...this.state.validations, additionalEmail: true }});
    }

    handleFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({faxChecked: checked})
    }

    handleFax = (event) => {
        this.setState({fax: event.target.value});
    }

    handleAdditionalFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({
            additionalFaxDisabled: !checked, 
            additionalFax: '', 
            additionalFaxChecked: checked,
            validations: { 
                ...this.state.validations, 
                additionalFax: true 
            }
        });
    }

    handleAdditionalFax = (event) => {
        this.setState({additionalFax: event.target.value});
    }

    //alerts
    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: '', messageList: []})
    }

    closeAlertEmail = () => {
        this.setState({showAlertEmail: false, alertMessageEmail: '', alertTitleEmail: ''})
    }

    handleUpdateInwardsFundsConfirmationCheckedChanged = (event) => 
    {
        this.setState({
            InwardsFundsConfirmationChecked: event.target.checked
        });
    }

    onClosePreviewFile = () =>
    {
        this.setState({
            currentFileName     : '',
            currentFileURLList  : [],
            openPreviewFile     : false,
        })
    }
    
    onClosePreviewInward = () =>
    {
        this.setState({
            currentInwardName     : '',
            currentInwardURLList  : [],
            openPreviewInward     : false,
        })
    }

    onchangeAdditionalMobileNumber = (mobilePhone, data, event, formattedValue) => {
        this.setState({
            additionalMobileNumber: mobilePhone,
            validations: { ...this.state.validations, additionalMobileNumber: true },
        });
    }

    render() { 
        return(
            <Dialog 
                    open        = {this.props.open} 
                    onClose     = {this.props.onCloseDialog}
                    fullWidth   = {true}
                    maxWidth    = {false}
                    style       = {{
                        width: '60%',
                        margin: 'auto'
                    }}
                >
                    <DialogTitle 
                        id="customized-dialog-gwlatr-title"
                        onClose={this.onCloseDialog}
                    >
                        {this.state.title}
                    </DialogTitle>
                    <DialogContent dividers style={{paddingLeft:24, overflowY: 'visible'}}>
                        <div className="uk-grid">
                            {/*Line1*/}
                            <div className="uk-width-1-3">
                                <label className="uk-form-label">Language:</label>
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.language)} id="form-dealconfirmation-language" data={this.state.listLanguages} 
                                    objValue={{value: this.state.language}} getValue={this.handleLanguage.bind(this)} />
                            </div>
                            <div className="uk-width-1-3"></div>
                            <div className="uk-width-1-3"></div>
                            
                            {/*Line2*/}
                            <div className={addPasswordToReports ? "uk-width-1-2" : "uk-width-1-2"} style={{paddingTop: 10}}>                        
                                <input className="uk-checkbox  uk-margin-right" type="checkbox" id="form-dealconfirmation-emailcheck" onChange={this.handleEmailChecked} 
                                    checked={this.state.emailChecked}/>
                                <label className="uk-form-label" >Email</label>
                                {!addPasswordToReports && <input 
                                    className   = {this.formatValidInput(this.state.validations.email)} 
                                    type        = "text" 
                                    id          = "form-dealconfirmation-email" 
                                    value       = {this.state.email} 
                                    onChange    = {this.handleEmail} 
                                    disabled    = {this.state.emailDisabled}
                                />}
                                {addPasswordToReports && <DynamicSelect 
                                    className   = {this.formatValidSelect(this.state.validations.email)} 
                                    id          = "form-dealconfirmation-customercontact" 
                                    data        = {this.state.customerContactsList} 
                                    objValue    = {{value: this.state.customerContactSelected}} 
                                    getValue    = {this.handleCustomerContact}
                                    disabled    = {!this.state.emailChecked}
                                />}
                            </div>

                            <div className={addPasswordToReports ? "uk-width-1-2" : "uk-width-1-2"} style={{paddingTop: 10}}>                        
                                <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalEmailCheck" onChange={this.handleAdditionalEmailChecked}/>
                                <label className="uk-form-label" >Additional Email</label>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{width:addPasswordToReports? '56%' : '100%', paddingRight: 10}}>
                                        <input 
                                            className={this.formatValidInput(this.state.validations.additionalEmail)} 
                                            type="text" id="form-dealconfirmation-additionalemail" 
                                            value={this.state.additionalEmail} 
                                            onChange={this.handleAdditionalEmail} 
                                            disabled={this.state.additionalEmailDisabled}
                                        />
                                    </div>
                                    {addPasswordToReports && <div style={{width:'44%'}}>
                                        <PhoneInput
                                            inputProps  = {{
                                                name        : 'phone',
                                                required    : true,
                                                autoFocus   : true
                                            }}
                                            enableSearch        = {true}
                                            disableSearchIcon   = {true}
                                            containerStyle      = {{
                                                height: 30
                                            }}
                                            inputClass  = {this.formatValidInput(this.state.validations.additionalMobileNumber)}
                                            inputStyle  = {{
                                                height: 30,
                                                borderRadius: 0
                                            }}
                                            buttonStyle = {{
                                                borderColor: this.state.validations.additionalMobileNumber ? '#CACACA': 'red'
                                            }}
                                            disabled    = {this.state.additionalEmailDisabled}
                                            placeholder = 'Enter a Mobile Number'
                                            autoFormat
                                            copyNumbersOnly     = {true}
                                            countryCodeEditable = {true}
                                            value               = {this.state.additionalMobileNumber}
                                            onChange            = {this.onchangeAdditionalMobileNumber}
                                            preferredCountries  = {['gb']}
                                            searchPlaceholder   = 'Search a country'
                                            autocompleteSearch

                                        />
                                    </div>}
                                </div>
                            </div>

                            {/*Line3*/}
                            <div className={addPasswordToReports ? "uk-width-1-2" : "uk-width-1-2"} style={{paddingTop: 10}}>                        
                                <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-faxcheck" onChange={this.handleFaxChecked}
                                    checked={this.state.faxChecked}/>
                                <label className="uk-form-label" >Fax</label>
                                <input className={this.formatValidInput(this.state.validations.fax)} type="text" id="form-dealconfirmation-fax" value={this.state.fax} 
                                onChange={this.handleFax} disabled={this.state.emailDisabled}/>
                            </div>

                            <div className={addPasswordToReports ? "uk-width-1-2" : "uk-width-1-2"} style={{paddingTop: 10}}>                     
                                <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalfaxcheck" onChange={this.handleAdditionalFaxChecked}/>
                                <label className="uk-form-label" >Additional Fax</label>
                                <input className={this.formatValidInput(this.state.validations.additionalFax)} type="text" id="form-dealconfirmation-additionalfax" value={this.state.additionalFax} 
                                onChange={this.handleAdditionalFax} disabled={this.state.additionalFaxDisabled}/>
                            </div>

                            <div 
                                className="uk-width-1-2"
                                style={{paddingTop: 10, margin:'auto'}}
                            >
                                {this.props.showIncludeInwardConfirmation && <div
                                    style={{display:'flex'}}
                                >
                                    <input
                                            style       = {{marginBlock:'auto'}}
                                            className   = "uk-checkbox" 
                                            value       = {this.state.InwardsFundsConfirmationChecked} 
                                            checked     = {this.state.InwardsFundsConfirmationChecked} 
                                            onChange    = {this.handleUpdateInwardsFundsConfirmationCheckedChanged} 
                                            type        = "checkbox"
                                    />
                                    <label style={{fontSize: 'smaller'}}>Include Inwards Funds Confirmation</label>
                                </div>}

                            </div>
                            <div className="uk-width-1-2"></div>

                            {/*Line4*/}
                            <div className="uk-width-1-1" style={{paddingTop: 20, marginBottom:0}}>
                                <button 
                                    className   = "uk-button uk-button-default uk-button-green" 
                                    type        = "button" 
                                    onClick     = {this.sendConfirmation}
                                >Send Confirmation</button>
                                <button 
                                    className   = "uk-button uk-button-green" 
                                    type        = "button" 
                                    onClick     = {this.showConfirmation}
                                >View PDF</button>
                                <button hidden={!this.state.InwardsFundsConfirmationChecked}
                                    className   = "uk-button uk-button-green" 
                                    type        = "button" 
                                    onClick     = {this.showInwardConfirmation}
                                >View Inward</button>
                                <button 
                                    className="uk-button uk-button-green uk-float-right" 
                                    type="button" 
                                    onClick={this.onCloseDialog}
                                >close</button>
                            </div>
                        </div>
                    </DialogContent>
                    <Dialog 
                        open        = {this.state.openPreviewFile} 
                        onClose     = {this.onClosePreviewFile}
                        fullWidth   = {this.state.fullWidthPreviewFile}
                        maxWidth    = {this.state.maxWidthPreviewFile}
                    >
                        <DialogTitle id="current-attachment-dialog-title" onClose={this.onClosePreviewFile}>
                            {this.state.currentFileName}
                        </DialogTitle>
                        <DialogContent dividers style={{padding: 0}}>      
                           
                                {/*<iframe 
                                    src         = {this.state.currentFileURL}
                                    scrolling   = 'yes'
                                    style       = {{
                                        border      : 'none',
                                        width       : '100%',
                                        height      : '400px',
                                        textAlign   : 'center',
                                    }}
                                >
                                </iframe>*/}
                            <DocViewer
                                pluginRenderers = {DocViewerRenderers} 
                                documents       = {this.state.currentFileURLList}
                                config          = {{
                                    header: {
                                        disableHeader   : true,
                                        disableFileName : true,
                                        retainURLParams : false
                                    }
                                }} 
                            />
                            
                        </DialogContent>
                    </Dialog>
                    <Dialog 
                        open        = {this.state.openPreviewInward} 
                        onClose     = {this.onClosePreviewInward}
                        fullWidth   = {this.state.fullWidthPreviewFile}
                        maxWidth    = {this.state.maxWidthPreviewFile}
                    >
                        <DialogTitle id="current-attachment-dialog-title" onClose={this.onClosePreviewInward}>
                            {this.state.currentInwardName}
                        </DialogTitle>
                        <DialogContent dividers style={{padding: 0}}>    
                            <DocViewer
                                pluginRenderers = {DocViewerRenderers} 
                                documents       = {this.state.currentInwardURLList}
                                config          = {{
                                    header: {
                                        disableHeader   : true,
                                        disableFileName : true,
                                        retainURLParams : false
                                    }
                                }} 
                            />
                            
                        </DialogContent>
                    </Dialog>
                    <AlertBox 
                        open    = {this.state.showAlert} 
                        onClose = {this.closeAlert} 
                        type    = "Ok" 
                        title   = {this.state.alertTitle} 
                        message = {this.state.alertMessage} 
                        okClick = {this.closeAlert}
                        messageBoldList = {this.state.messageList}
                    />

                    <AlertBox 
                        open    = {this.state.showAlertEmail} 
                        onClose = {this.closeAlertEmail} 
                        type    = "Yes/No" 
                        title   = {this.state.alertTitleEmail} 
                        message = {this.state.alertMessageEmail} 
                        noClick = {this.closeAlertEmail} 
                        yesClick= {this.sendConfirmationDirect}
                    />

                    <LoadingBox loading={this.state.loading}/>
                </Dialog>
        );        
    }
}
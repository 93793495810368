import React from 'react';

import FeeMaintenanceForm from './FeeMaintenanceForm';
import Paginator from '../../shared/Paginator';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import {getAllFees, getFeeById, deleteFee} from '../../../networking/NetworkingFees';
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper'
import {getStyle} from '../../../styles/styles'
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();

export default class FeeMaintenanceScreen extends React.Component {
    state = {
        fees: [],
        selectedFee: {},
        totalPages: 0,
        pages: 1,
        newSearch: false,
        filter: false,
        readyToDeleteFee: {},
        showDeleteAlert: false,
        showErrorAlert: false,
        showSuccessDeleteAlert: false
    }
    
    closeDeleteAlert = () =>{
        this.setState({ showDeleteAlert: false, readyToDeleteFee: {} });
    }

    closeErrorAlert = () => {
        this.setState({showErrorAlert: false});
    }

    yesDeleteAlert = () =>{   
        deleteFee(this.state.readyToDeleteFee['ID']).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({ showDeleteAlert: false, showErrorAlert: true, errorMessage: json.Message });
                } else {
                    this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                    this.updatePage();
                }
            }
        )
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount() {
        getAllFees('', 0, 0).then(
            (json) => {
                if (json !== undefined && json.totalCount > 0) {
                    const totalCount = json.totalCount;
                    const totalPages = Math.ceil(totalCount / itemsPerPage);
                    this.setState({ fees: json.fees, totalPages, newSearch: true, filter: true });
                } else {
                    this.setState({ newSearch: true, filter: false });
                }
            }
        );
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    updatePage = async () => {
        const fees = await this.getFeeList();
        const totalCount = fees.length;
        const totalPages = Math.ceil(totalCount / itemsPerPage);
        this.setState({ fees, totalPages, newSearch: true, filter: true });
    }

    handleRowClick = async (obj) => {
        const value = await getFeeById(obj['ID']);
        this.setState({ selectedFee: value });
    }
    
    handleDeleteClick = (obj) => {
        console.group("Fee handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteFee: obj});
    }

    getFeeList = async () => {
        const json = await getAllFees('', 0, 0);
        if (json !== undefined && json.fees !== undefined) {
            return json.fees;
        } else {
            return [];
        }
    }

    render() {
        return (
            <div uk-grid="true">
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Fee Maintenance</h3>
                    <FeeMaintenanceForm selectedFee={this.state.selectedFee} updateFunction={this.updatePage} />
                </div>
                <div className="uk-width-1">
                    <hr />
                    <NewDynamicTable
                        hiddenHeaders={['ID', 'CostPerUnit', 'Unit']}
                        formats={[{ header: 'Default Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: localStorage.getItem('BaseCurrency') }]}
                        data={this.state.fees}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick} 
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={itemsPerPage}
                        enableSortByHeader={true}
                        useFilter={this.state.filter}
                        filterFunction={this.getFeeList} />
                </div>
                    <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this User?"
                        message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                    <AlertBox id="success-delete-user" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Fee successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                    <AlertBox id="error-message-user" open={this.state.showErrorAlert} onClose={this.closeErrorAlert} title="Error" message={this.state.errorMessage} type="Ok" okClick={this.closeErrorAlert}/>
            </div>
        );
    }
}
import { Breadcrumb, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../AppContext';
import { formatDate, formatValidDatePicker, formatValidInput, formatValidSelect } from '../../../helpers/FormatHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { getComplaintByID, getMessagesByComplaintID, updateComplaint } from '../../../networking/NetworkingComplaints';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import ReplyMessageDialog from '../../shared/Dialogs/ReplyMessageDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import useLookUpTables from './useLookUpTables';
const style = getStyle();

function ComplaintScreen({ backLink, mainTitle, data: complaintID }) {
    const context = useRef(useContext(AppContext));
    const [contactID, setContactID] = useState(0);
    const [description, setDescription] = useState('');
    const [createdOn, setCreatedOn] = useState('');
    const [dealID, setDealID] = useState('');
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    const [subject, setSubject] = useState('');
    // const [updatedOn, setUpdatedOn] = useState('');
    const [updateToken, setUpdateToken] = useState('');
    const [messages, setMessages] = useState([]);
    const [reloadMessages, setReloadMessages] = useState(false);
    const [disableControls, setDisableControls] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [validations, setValidations] = useState({ status: true, subject: true, description: true, createdOn: true, reason: true });
    const [errors, setErrors] = useState([]);

    const [showReplyDialog, setShowReplyDialog] = useState(false);

    const lookUpTables = useLookUpTables('Complaint Status');

    useEffect(() => {
        const loadComplaint = async () => {
            context.current.startLoading();
            const json = await getComplaintByID(complaintID);
            console.log(json);
            if (json != null) {
                setContactID(json.ContactID);
                setDealID(json.DealID);
                setSubject(json.Subject);
                setDescription(json.Description);
                setStatus(json.Status);
                setReason(json.Reason);
                setCreatedOn(json.CreatedOn);
                // setUpdatedOn(json.UpdatedOn);
                setUpdateToken(json.UpdateToken);
                setDisableControls(json.Status === 'Closed');
            }
            context.current.finishLoading();
        }
        loadComplaint();
    }, [complaintID]);

    useEffect(() => {
        const loadMessages = async () => {
            context.current.startLoading();
            const json = await getMessagesByComplaintID(complaintID);
            console.log(json);
            if (json != null && json.messages != null) {
                 setMessages(json.messages);
            } else {
                setMessages([]);
            }
            context.current.finishLoading();
        }
        loadMessages();
    }, [complaintID, reloadMessages]);

    const saveComplaint = async (e) => {
        e.preventDefault();
        if (validateComplaint()) {
            const userID = localStorage.getItem('UserID');
            const request = {
                ComplaintID: complaintID,
                DealID: dealID !== '' ? dealID : null,
                Subject: subject,
                Description: description,
                Status: status,
                CreatedOn: createdOn,
                UpdatedBy: userID,
                UpdateToken: updateToken,
                Reason: reason
            };
            const json = await updateComplaint(request);
            if (json.httpStatusCode != null && json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Complaint updated successfully.');
                setUpdateToken(json.updateToken);
                setDisableControls(status === 'Closed');
            }
        }
    };

    const validateComplaint = () => {
        let fields = [
            { displayName:'Status', validateName: 'status', value: status },
            { displayName:'Subject', validateName: 'subject', value: subject },
            // { displayName:'Description', validateName: 'description', value: description },
            { displayName:'Date', validateName: 'createdOn', value: createdOn }
        ];
        if (status === 'Closed') {
            fields.push({ displayName:'Reason', validateName: 'reason', value: reason });
        }
        const [valid, _validations, _errors] = validate(fields);
        setValidations(_validations);
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid; 
    }

    const handleSelectStatus = (value)=> {
        if (value != null && value !== 'select' && value.value !== undefined) {
            setValidations({ ...validations, status: true, reason: true })
            setStatus(value.value);
            if (value.value === 'Opened') {
                setReason('');
            }
        }
    };

    const handleUpdateReason = (event) => {
        setValidations({ ...validations, reason: true })
        setReason(event.target.value);
    }

    const handleUpdateDate = (date)=> {
        setValidations({ ...validations, createdOn: true })
        setCreatedOn(formatDate(date));
    };

    const handleUpdateSubject = (event)=> {
        setValidations({ ...validations, subject: true })
        setSubject(event.target.value);
    };

    // const handleUpdateDescription = (event)=> {
    //     setValidations({ ...validations, description: true })
    //     setDescription(event.target.value);
    // };

    const openReplyDialog = () => {
        setShowReplyDialog(true);
    }

    const closeReplyDialog = () => {
        setShowReplyDialog(false);
        setReloadMessages(!reloadMessages)
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    const closeAlertError = () => {
        setShowAlertError(false)
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'SentOn',
            width: '15%',
            editable: false,
            // align: 'center',
            defaultSortOrder: 'descend',
            // sorter          : (a, b) => { return moment(a.SentOn).unix() - moment(b.SentOn).unix()},
            sorter          : (a, b) => { return a.SentOn.localeCompare(b.SentOn)},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Sender',
            dataIndex: 'Sender',
            width: '15%',
            editable: false,
            // align: 'center',
            // sorter          : (a, b) => { return moment(a.SentOn).unix() - moment(b.SentOn).unix()},
            sorter          : (a, b) => { return a.Sender.localeCompare(b.Sender)},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Content',
            dataIndex: 'Content',
            width: '70%',
            editable: false,
            // align: 'center',
            sorter          : (a, b) => { return a.Content.localeCompare(b.Content)},
            sortDirections  : ['descend', 'ascend'],
        },
    ];

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item" onClick={backLink}>{mainTitle}</Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-item-bold">Complaint{complaintID != null ? ` #${complaintID}` : ''}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <form className="uk-grid">
                <div className="uk-width-1-4@s uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" htmlFor="dealNo">Deal No.:</label>
                        <div className="uk-margin-right">
                            <input className="uk-input" id="dealNo" type="text" placeholder="" disabled value={dealID != null ? dealID : 'N/A'} />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-4@s uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" style={style.boldLabel} htmlFor="status">Status (*)</label>
                        <div className="uk-margin-right">
                            <DynamicSelect
                                className={formatValidSelect(validations.status)}
                                id='status'
                                key='status'
                                listHasPlaceholder={true}
                                disabled={disableControls}
                                getValue={handleSelectStatus}
                                objValue={{ value: status, name: status }}
                                data={lookUpTables.ComplaintStatus} />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-4@s uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" style={{ ...style.boldLabel, visibility: status === 'Closed' ? 'inherit' : 'hidden' }} htmlFor="closeReason">Reason (*)</label>
                        <div className="uk-margin-right">
                            <input className={formatValidInput(validations.reason)} id="reason" type="text" placeholder="" disabled={disableControls} style={{ visibility: status === 'Closed' ? 'inherit' : 'hidden' }} value={reason} onChange={handleUpdateReason} />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-4@s uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" style={style.boldLabel} htmlFor="date">Date (*)</label>
                        <div className="uk-margin-right">
                            <DatePicker className={formatValidDatePicker(validations.createdOn)} id="date" key='date' disabled={disableControls} readOnly={true} value={createdOn} onDayChange={handleUpdateDate} />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1@s uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" style={style.boldLabel} htmlFor="date">Subject (*)</label>
                        <div className="uk-margin-right">
                            <textarea className={formatValidInput(validations.subject)} id="subject" placeholder="" disabled={disableControls} value={subject} onChange={handleUpdateSubject} style={{ fontSize: 16, height: 70 }} maxLength={200} />
                        </div>
                    </div>
                </div>
                <div className="uk-width-3-4 uk-form-stacked">
                </div>
                {/* <div className="uk-width-1-1 uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" style={style.boldLabel} htmlFor="date">Description (*)</label>
                        <div className="uk-margin-right">
                            <textarea className={formatValidInput(validations.description)} id="subject" placeholder="" disabled={status === 'Closed'} value={description} onChange={handleUpdateDescription} style={{ fontSize: 16, height: 70 }} maxLength={200} />
                        </div>
                    </div>
                </div> */}
            </form>
            <div className='uk-margin-top'>
                <button className="uk-button uk-button-green uk-margin-right" disabled={disableControls} onClick={saveComplaint}>Save</button>
                <button className="uk-button uk-button-green uk-margin-right" disabled={disableControls} onClick={openReplyDialog}>Reply</button>
            </div>
            <div className='uk-margin' />
            <h3 className="uk-heading-divider" style={style.titleStyle}>List of Messages</h3>
            <ReplyMessageDialog complaintID={complaintID} contactID={contactID} subject={subject} open={showReplyDialog} onClose={closeReplyDialog} />
            <Table dataSource={messages} columns={columns} />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            <AlertBox id="alert-error-reply-dialog" open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
        </div>
    );
}

export default ComplaintScreen;

import { PDFViewer } from '@react-pdf/renderer';
import { Breadcrumb } from 'antd';
import React from 'react';
import AppContext from '../../../AppContext';
import { getLookUpTable } from '../../../networking/Networking';
import { getParametersByName } from '../../../networking/NetworkingGlobalSystemParameters';
import { generateReport, getOutstandingForwardsReport, getVostroStatementReport } from '../../../networking/NetworkingReports';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import './PDFScreen.scss';
import ReportForm from './ReportForm';
import VostroStatementPDF from './vostroStatementReport/VostroStatementPDF';

let globalFunctions = {};

var style = getStyle();

export default class ReportsScreen extends React.Component {
    static contextType = AppContext;

    state= {
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        reportType: '',
        reportTypeDropdown: [],
        showFields: [],
        fields: null,
        isValidated: false,
        validateFields: true,
        errors: [],
        showPDF: false,
        companyAddress: '',
        companyTollFree: '',
        forceReload: false
    }

    componentDidMount() {
        globalFunctions = this.context;
        getLookUpTable("Report Types").then(
            (json) => {
                if (json !== undefined) {
                    var table = json.lookUpTables[0].lookUpTableDetails;
                    for (var i = 0; i < table.length; i++) {
                        table[i].value = table[i].LookUpTableDetail;
                        table[i].name = table[i].LookUpTableDetail;
                    }
                    this.setState({ reportTypeDropdown: table });
                }
        });
        getParametersByName('CompanyAddress,CompanyTollFree').then(value => {
            this.setState({companyAddress: value.parameters[0].Value, companyTollFree: value.parameters[1].Value})
        })
    }

    cleanFields = () => {
        this.setState({
            showAlert: false,
            alertTitle: '',
            alertMessage: '',
            reportType: '',
            showFields: [],
            fields: null,
            isValidated: false,
            validateFields: true,
            errors: []
        });
    }

    handleGetReportsClick = (event) => {
        if (this.state.reportType === '') {
            this.setState({
                showAlertError: true, 
                alertMessage: 'Please select a report type', 
                alertTitle: 'Error'
            });
        } else {
            if (this.state.isValidated)
            {
                if(this.state.reportType === 'Outstanding Forwards')
                {
                    getOutstandingForwardsReport(
                        this.state.fields.Email,
                        this.state.fields.Customer, 
                        this.state.fields['From Trade Date'],
                        this.state.fields['To Trade Date'],
                        this.state.fields['From Value Date'],
                        this.state.fields['To Trade Date'],
                        this.state.fields.Separator).then(
                            (response)=>{
                                response.text().then(
                                    (data) => {
                                        //console.log(data)
                                        var a = document.createElement('a');
                                        a.textContent   = 'download';
                                        a.download      = "ReportOutstandingForwards-" + this.state.fields.Customer + ".csv";
                                        a.href          = 'data:text/csv;charset=utf-8,' + escape(data);
                                        a.id            = 'report-file-generated'
                                        a.click();
                                    }
                                );
                            }
                        );
                }else if (this.state.reportType === 'Vostro Statements') {
                    globalFunctions.startLoading();
                    getVostroStatementReport(this.state.fields.Customer, this.state.fields['From Trade Date'], this.state.fields['To Trade Date']).then((value) => {
                        globalFunctions.finishLoading();
                        this.setState({showPDF: true, vostroStatementData: value});
                        //console.log(value);
                    })
                }
                else
                {
                    generateReport(this.state.reportType, this.state.fields).then(
                        (json) => {
                            if (json !== undefined && json !== null) {
                                if (json.httpStatusCode !== 200) {
                                    this.setState({ showAlertError: true, alertMessage: 'An error occurred while trying to generate the report. Please try again.', alertTitle: 'Error' });
                                } else {
                                    this.setState({ showAlert: true, alertTitle: 'Report successfully generated. Please check your email.' });
                                }
                            } else {
                                this.setState({ showAlertError: true, alertMessage: 'An error occurred while trying to generate the report. Please try again.', alertTitle: 'Error' });
                            }
                        }
                    );
                }
                
            } else {
                this.setState({
                    showAlertError: true, 
                    alertMessage: 'Please fix the following errors:', 
                    alertTitle: 'Error', 
                    validateFields: false
                });
            }
        }
    }

    handleResetFieldsClick = () => {
        this.setState({ forceReload: !this.state.forceReload });
    }

    handleGetFields = (fields) => {
        this.setState({ fields , showPDF: false});
    }

    isValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidated: true,
                validateFields: true,
                errors: []
            });
        } else{
            this.setState({isValidated: false, errors: validator.errors});
        }
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: '', validateFields: true},
        () => {
            this.cleanFields();
        });
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: '', validateFields: true});
    }

    handleGetValueReportType = (value) => {
        if (value.value !== undefined) {
            var showFields = []
            if (value.value !== "select" && value.value !== "") {
                showFields = value.Field4.split(",");
            }
            this.setState({
                reportType: value.value,
                showFields
            });
        }
    }

    render() {
        return (
            <div className="uk-margin-xlarge-bottom">
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item-bold">Reports</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <DynamicSelect 
                    data                = {this.state.reportTypeDropdown}  
                    objValue            = {{value: this.state.reportType, name: this.state.reportType}} 
                    getValue            = {this.handleGetValueReportType} 
                    listHasPlaceholder  = {true} 
                    placeholder         = {"Select an Item..."} 
                    id                  = "report-type"
                />
                <ReportForm 
                    fields      = {this.state.showFields} 
                    getFields   = {this.handleGetFields} 
                    isValid     = {this.isValidated} 
                    validated   = {this.state.validateFields}
                    forceReload = {this.state.forceReload}
                />
                <div>
                    {this.state.reportType !== '' && <button className="uk-button uk-button-green" onClick={this.handleResetFieldsClick}>Reset Fields</button>}
                    <button className="uk-button uk-button-green" onClick={this.handleGetReportsClick}>Get Reports</button>
                </div>
                {this.state.showPDF &&
                <PDFViewer className="viewer">
                    <VostroStatementPDF vostroStatementData={this.state.vostroStatementData} fromTradeDate={this.state.fields['From Trade Date']} toTradeDate={this.state.fields['To Trade Date']} companyAddress={this.state.companyAddress} companyTollFree={this.state.companyTollFree}/>
                </PDFViewer>
                }
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} messageBoldList={this.state.errors} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError}/>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }
}
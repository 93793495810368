import React from 'react';
import {getLookUpTable} from '../../../networking/Networking';
import { getTasksDef, getTasksDefById, saveTaskDef, deleteTaskDef } from '../../../networking/NetworkingTasksDefinition';
import { getSubEntityTypes } from '../../../networking/NetworkingUserProperties';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import Paginator from '../../shared/Paginator';
import AlertBox from '../../shared/AlertBox';
import { getStyle, regularInputClass, regularSelectClass, regularTextAreaClass, notFilledSelectClass, notFilledInputClass, notFilledTextAreaClass } from '../../../styles/styles';
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper'
import NumberInput from '../../shared/NumberInput';
import {Colors} from '../../../constants/Colors';
import NewEditableGrid from '../../shared/NewEditableGrid';

var style = getStyle();
const itemsPerPage = getDefaultPagingNumberOfItems();

const inputStyle= {
    paddingBottom:"15px"
}

export default class TasksMaintenanceScreen extends React.Component {
    state = {
        taskDefId: '',
        title: '',
        description: '',
        deadlineDays: '',
        mandatory: false,
        groupBy: '',
        customerCanAttach: false,
        dealStatus: '',
        updateToken: 0,
        extendedInfoType: '',
        
        tasks: [],
        allTasks: [],     
        columnDetail: [],
        lookUpTableId: 0,
        lookUpTableName: '',
        "Sub Entity Types": [],
        "Deal Status": [],
        "Type of extended task":[],
        lookUpTables: [
            "Sub Entity Types",
            "Deal Status",
            "Type of extended task"
        ],
        //
        hideButton: false,
        
        //For tables
        selectIDLeft:'',
        selectIDRight:'',
        listInitialHave: [],
        listHave: [],//originLists
        listWant: [],//originLists
        available:'',
        listMissing: [],

        validations: {
            description: true,
            title: true,
            deadlineDays: true,
            mandatory: true,
            customerCanAttach: true
        },

        alertMessage: '',
        showAlert: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteTask: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,

        subEntityTypeFilter: [],
        subEntityType:''
    }

    closeDeleteAlert = () =>{
        this.setState({showDeleteAlert: false, readyToDeleteTask: {}});
    }

    openDeleteAlert = () => {
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert = () => {
        deleteTaskDef(this.state.readyToDeleteTask['TaskDefId']).then(
            (value)=>{
                if(value.ErrorMessage == null)
                {
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                    this.updatePage();
                }
                else
                {
                    let alertMessage='';
                    if(value.ErrorMessage !='' ){ alertMessage=value.ErrorMessage; }
                    else if (value.Message !='' ){ alertMessage=value.Message; }
                    this.setState({showDeleteAlert: false, alertTitle:'Error', alertMessage: alertMessage, showAlert: true});
                }
        });
    }

    okSuccessAlert = () => {
        this.setState({showSuccessDeleteAlert: false});
    }

    componentDidMount(){
        this.getSubEntityTypes();
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                this.state.lookUpTables.forEach(
                    (lookUpTable) => {
                        let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(lookUpTable);
                        if (index > -1) {
                            const table = json.lookUpTables[index].lookUpTableDetails.map(l => ({ ...l, name: l.LookUpTableDetail, value: l.LookUpTableDetail }));
                            this.setState({ [lookUpTable]: table }, 
                                () => {
                                    let helper = [...this.state["Sub Entity Types"]]; //creando copia de la lista original
                                    if (helper != null) {
                                        helper = this.transformBoolean(helper);
                                        this.setState({
                                            listInitialHave: [...helper], //copia de la lista original
                                            listHave: [...helper]
                                            // listWant: []
                                        })
                                    }
                                }
                            );
                        }
                    }
                );
            }
        );

        /*getTasksDef(1,itemsPerPage).then(
            (value)=>{
                var helper = [];
                var helper1 = [];
                helper = this.changeAvailableLabelsMandatory(value.tasks);
                helper1 = this.changeAvailableLabelsCanAttach(helper);
                this.setState({tasks: helper1});                
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        )*/
        
        this.getTasksDef();
        
    }

    getTasksDef () {
        getTasksDef(this.state.subEntityType, 0,0).then(
            (value)=>{
                if (value != null && value.result != null) {
                    let _columnDetail = [];
                    if (value.columnDetail != null) {
                        _columnDetail = JSON.parse(value.columnDetail);
                    }
                    this.setState({allTasks: value.result, columnDetail: _columnDetail});
                }
                else {
                    this.setState({allTasks: []});
                }

                /*var helper = [];
                var helper1 = [];
                //debugger;
                if(value != undefined && value.tasks != undefined)
                {
                helper = this.changeAvailableLabelsMandatory(value.tasks);
                helper1 = this.changeAvailableLabelsCanAttach(helper);
                }    
                var totalCount = (value != undefined) ?value.totalCount:0;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);

                this.setState({allTasks: helper1, totalPages: pages, newSearch: false});*/
            }
        );
    }

    changeAvailableLabelsMandatory(collection){
        var helper = collection;
        for (var i = 0; i<helper.length; i++){
            if(helper[i]['Mandatory']){
                helper[i]['Mandatory'] = 'Yes';
            } else {
                helper[i]['Mandatory'] = 'No';
            };
        }
        return helper;
    }

    changeAvailableLabelsCanAttach(collection){
        var helper = collection;
        for (var i = 0; i<helper.length; i++){
            if(helper[i]['Can Attach']){
                helper[i]['Can Attach'] = 'Yes';
            } else {
                helper[i]['Can Attach'] = 'No';
            };
        }
        return helper;
    }

    transformWantList = list =>{
        var helper = [];
        var i = 0;
        for(i; i<list.length;i++){
            helper.push(list[i]['SubEntityType']);
        }
        return helper;
    }

    transformBoolean = list =>{
        var helper = [];
        var i, trueFalse = '';
        if(list[0].name.includes('Select')){i=1}else{i=0}
        for(i; i<list.length;i++){
            if(list[i]['Editable']){
                trueFalse = 'True';
            }else{
                trueFalse = 'False';
            }
            helper.push({
                'LookUpTableDetail': list[i]['LookUpTableDetail'],
                'DisplayOrder': list[i]['DisplayOrder'],
                'Field1': list[i]['Field1'],
                'Field2': list[i]['Field2'],
                'Field3': list[i]['Field3'],
                'Field4': list[i]['Field4'],
                'Field5': list[i]['Field5'],
                'Field6': list[i]['Field6'],
                'MappingId': list[i]['MappingId'],
                'name': list[i]['name'],
                'value': list[i]['value'],
                'Editable': trueFalse
            });
        }
        return helper.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    transformTasksList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'ID': list[i]['ID'],
            'Group By': list[i]['Group By'],
            'Title': list[i]['Title'], 
            'Description': list[i]['Description'], 
            'Mandatory': list[i]['Mandatory'],
            'Can Attach': list[i]['Can Attach'],
            'Deadline Days': list[i]['Mandatory'],
            'Deal Status': list[i]['Deal Status']});
        }
        return helper;
    }

    handleRowClick = (obj) =>{
        console.group("Task handleRowClick")
        console.log(obj);
        console.groupEnd();

        getTasksDefById(obj["TaskDefId"]).then(
        (value)=>{
            let value_groupBy = (value.GroupBy!=null)?value.GroupBy:'';
            this.setState({
                taskDefId: value.TaskDefId,
                title: value.Title,
                description: value.Description,
                deadlineDays: value.DeadlineDays,
                mandatory: value.Mandatory,
                groupBy: value_groupBy,
                customerCanAttach: value.CustomerCanAttach,
                dealStatus: value.DealStatus,
                listWant: value.subEntityTypes,
                updateToken: value.UpdateToken,
                extendedInfoType: value.ExtendedInfoType}, 
                ()=>{
                    let subTypes1 = value.subEntityTypes
                    var havelist = [...this.state.listHave]
                    if(subTypes1 !== null){
                        for( var j = 0; j<subTypes1.length; j++)
                        {
                            for( var i = havelist.length - 1; i >= 0; i--)
                            {
                                if(subTypes1[j] && (subTypes1[j].SubEntityType === havelist[i].LookUpTableDetail))
                                {
                                    havelist.splice(i, 1);
                                }
                            }
                        }
                    }
                    this.setState({listHave: havelist});
                });
        });
    }

    handleDeleteClick = (obj) => {
        console.group("Task handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteTask: obj});        
    }

    changePage = (value) => {
        this.setState({currentPage: value});
        /*getTasksDef(this.state.currentPage, itemsPerPage).then(
            (value) => {
                var helper = [];
                var helper1 = [];
                helper = this.changeAvailableLabelsMandatory(value.tasks);
                helper1 = this.changeAvailableLabelsCanAttach(helper);
                this.setState({tasks: helper1});                
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        );*/
    }

    updatePage = () => {
        this.getTasksDef();
    }

    handleSaveClick(){
        let userID = localStorage.getItem('UserID');
        var model = {  
            TaskDefId: this.state.taskDefId,
            Title: this.state.title,
            Description: this.state.description,
            Mandatory: this.state.mandatory,
            DeadlineDays: this.state.deadlineDays,
            DealStatus: this.state.dealStatus,
            CustomerCanAttach: this.state.customerCanAttach,
            GroupBy: this.state.groupBy,
            UserId: userID,
            SubEntityTypes: this.transformWantList(this.state.listWant),
            UpdateToken: this.state.updateToken,
            ExtendedInfoType: this.state.extendedInfoType
        }
        if(this.validateRequiredFields()){
            saveTaskDef(model).then(()=>
            {
                this.setState({
                    title: '',
                    description: '',
                    deadlineDays: 0,
                    mandatory: false,
                    groupBy: '',
                    customerCanAttach: false,
                    updateToken: 0,
                    taskDefId: 0,
                    
                    lookUpTableId: 0,
                    lookUpTableName: '',
                    selectIDLeft:'',
                    selectIDRight:'',
                    listHave: this.state.listInitialHave.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                    listWant: [],
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: 'Task successfully saved.'});
                this.updatePage(); 
            })
        }
    }

    validateRequiredFields() 
    {
        var valid = true
        var description = true
        var title = true
        var deadlineDays = true
        var customerCanAttach = true
        var mandatory = true
        var listItems =[]        

        if(this.state.title === undefined || 
            this.state.title === "")
         {
             valid = false;
             title = false;
             listItems.push("Title")
         }

        if(this.state.description === undefined || 
           this.state.description === "")
        {
            valid = false;
            description = false;
            listItems.push("Description")
        }        

        if (this.state.deadlineDays === '' || 
            this.state.deadlineDays === undefined ||
            this.state.deadlineDays < 0) 
        {
            valid = false;
            deadlineDays = false;
            listItems.push("Deadline Days")
        }

        if(this.state.mandatory === undefined || 
            this.state.mandatory === "" ||
            this.state.mandatory === null)
         {
             valid = false;
             mandatory = false;
             listItems.push("Mandatory")
         }

        if(this.state.customerCanAttach === undefined || 
             this.state.customerCanAttach === "" ||
             this.state.customerCanAttach === null)
          {
              valid = false;
              customerCanAttach = false;
              listItems.push("Customer Can Attach")
          }
        
        this.setState({listMissing: listItems,validations: {
                ...this.state.validations,
                description: description,
                title: title,
                deadlineDays: deadlineDays,
                mandatory: mandatory,
                customerCanAttach: customerCanAttach
            }});

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    validateRequiredInputs(){
        if(this.state.description === ''){
            return false;
        }
        if(this.state.title === ''){
            return false;
        }
        if(this.state.deadlineDays === '' || this.state.deadlineDays === undefined){
            return false;
        }
        if(this.state.customerCanAttach === undefined){
            return false;
        }
        if(this.state.mandatory === undefined){
            return false;
        }
        return true;
    }

    handleClearClick(){
        this.setState({
            taskDefId: 0,
            title: '',
            description: '',
            deadlineDays: 0,
            mandatory: false,
            groupBy: '',
            customerCanAttach: false,
            updateToken: 0,
            selectIDLeft:'',
            selectIDRight:'',
            listHave: this.state.listInitialHave.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
            listWant: [],
            extendedInfoType: ''
            });
    }
    
    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
          this.setState({selectIDLeft});
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
          this.setState({selectIDRight});
        }
    };
    
    handleAddClick(event){
        console.log("handleAddClick event")
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDLeft;
        var add = true

        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((subType, index) => {
                    if(subType.LookUpTableDetail == idSelected)
                    {
                        listRight.push({SubEntityType: subType.LookUpTableDetail})//not sure why the object is diferent from the left objects
                        listLeft.splice(index,1)
                        this.setState({
                            listWant:  listRight,
                            listHave: listLeft.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                            selectIDLeft: ''}
                        );
                    }
                });
                
            }else
            {
                listRight.forEach(rightRole => {
                    if(rightRole.LookUpTableDetail == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftRole, index) => {
                            if(leftRole.LookUpTableDetail == idSelected)
                            {
                                listRight.push({SubEntityType: leftRole.LookUpTableDetail})
                                listLeft.splice(index,1)
                                this.setState({
                                    listWant:  listRight.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                                    listHave: listLeft.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                                    selectIDLeft: ''}
                                );
                            }
                        });
                    }
            }
        }
    }

    handleRemoveClick(event){
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDRight;
        var add = true

        if(idSelected != '')
        {
            listRight.forEach((rightRole, index) => {
                if(rightRole.SubEntityType == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push({LookUpTableDetail: rightRole.SubEntityType, name: rightRole.SubEntityType})
                    listLeft = listLeft.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                }
                this.setState({
                        listWant:  listRight.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                        listHave: listLeft.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                        selectIDRight: ''}
                    );
            });
        }
    }

    handleUpdateTitle(value){
        this.setState({title: value.target.value,validations: {...this.state.validations, title: true}});
    }

    handleUpdateDescription(value){
        this.setState({description: value.target.value,validations: {...this.state.validations, description: true}});
    }    

    handleUpdateDeadlineDays(value){
        this.setState({deadlineDays: value.target.value,validations: {...this.state.validations, deadlineDays: true}});
    }

    handleOnCheckMandatory(event){
        this.setState({mandatory: event.target.checked,validations: {...this.state.validations, mandatory: true}});
    }

    handleUpdateGroupBy(value){
        this.setState({groupBy: value.target.value});
    }

    handleOnCheckCustomerCanAttach(event){
        this.setState({customerCanAttach: event.target.checked,validations: {...this.state.validations, customerCanAttach: true}});
    }

    handleUpdateDealStatus(value){
        this.setState({dealStatus: value.value});
    }

    handleHideButtonClick = () =>{
        if(this.state.hideButton){
            this.setState({hideButton: false})
        }else{
            this.setState({hideButton: true})
        }        
    }

    formatValidTextArea(valid) {
        return valid ? regularTextAreaClass : notFilledTextAreaClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    getAllTasks = async () => {
        var json = await getTasksDef(this.state.subEntityType,0,0);
        var helper = [];
        var helper1 = [];
        helper = this.changeAvailableLabelsMandatory(json.tasks);
        helper1 = this.changeAvailableLabelsCanAttach(helper);
        var tasks = this.transformTasksList(helper1);
        return tasks;
    }

    handleUpdateTypeExtended = (value) =>
    {
        if (value !== undefined && value.value !== undefined) 
        {
            this.setState({extendedInfoType: value.value});
        }
    }

    getSubEntityTypes()
    {
        getSubEntityTypes().then(
            (value) => {
                var table = value.SubEntityTypes;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].SubEntityType;
                            table[j].name = table[j].SubEntityType;
                        }
                this.setState({subEntityTypeFilter: table, subtEntityType: ''});
            }
        )
    }

    
    handleUpdateSubEntityType = (value) =>
    {
        if(value != undefined)
        {
        if(value == 'select' || value.value == '')
        {
            value='';
        }
        else
        {
            value = value.SubEntityType;
        }
        this.setState({subEntityType: value, currentPage: 1}, ()=> this.getTasksDef());
    }
    }


    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass; 
        var listLeft = this.state.listHave.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        console.log(listLeft)
        var listRight = [];    
        if(this.state.listWant === null || this.state.listWant === undefined){
            this.setState({listWant:[]},()=>listRight = this.state.listWant)
        }else{
            listRight = this.state.listWant.sort((a, b) => (a.SubEntityType > b.SubEntityType) ? 1 : ((b.SubEntityType > a.SubEntityType) ? -1 : 0))
        }
        console.log(listRight)

        var available = this.state.available;

        var sectionDealStatus = (
            <div className="uk-width-1-2 uk-form-stacked" hidden>                     
                <label className="uk-form-label">Deal Status</label>
                <div className="uk-form-controls">
                    <DynamicSelect id="deal-status" className={classNameSelect} getValue={this.handleUpdateDealStatus.bind(this)} listHasPlaceholder={true} data={this.state["Deal Status"]} objValue={{value: this.state.dealStatus}} disabled={available}/>
                </div>
            </div>
        )
        
        if(this.state.mandatory){
            sectionDealStatus = (
                <div className="uk-width-1-2 uk-form-stacked">                     
                    <label className="uk-form-label">Deal Status</label>
                    <div className="uk-form-controls">
                        <DynamicSelect id="deal-status" className={classNameSelect} getValue={this.handleUpdateDealStatus.bind(this)} listHasPlaceholder={true} data={this.state["Deal Status"]} objValue={{value: this.state.dealStatus}} disabled={available}/>
                    </div>
                </div>
            )
        }

        var section = (
            <div className="uk-margin-right">
                <i className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'center'}} onClick={this.handleHideButtonClick} uk-toggle="target: #subtype-component; animation: uk-animation-fade"></i>
            </div>
        )

        /*<div className="uk-margin-right">
                <div className="" style={style.inputStyle}>
                    <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "15px"}} onClick={this.handleHideButtonClick} type="button" uk-toggle="target: #subtype-component; animation: uk-animation-fade">v</button>
                </div>
            </div>*/

        if(this.state.hideButton){
            section = (            
                <div className="uk-margin-right">
                    <i className="fa fa-fw fa-sort-asc" style={{paddingTop: 15, verticalAlign: 'center'}} onClick={this.handleHideButtonClick} uk-toggle="target: #subtype-component; animation: uk-animation-fade"></i>
                </div>
            );
        }
        /*<div className="uk-margin-right">
                    <div className="" style={style.inputStyle}>
                        <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "15px", fontSize: 20}} onClick={this.handleHideButtonClick} type="button" uk-toggle="target: #subtype-component; animation: uk-animation-fade">^</button>
                    </div>
                </div>*/

        return (
            <div>
                <form className="uk-grid">
                    <div className="uk-width-1" style={{ marginBottom: 10 }}>
                        <h3 className="uk-heading-divider" style={style.titleStyle}>Tasks Definition</h3>
                    </div>
                    {/*<div className="uk-width-1">
                        <div className="uk-margin" style={inputStyle}>
                            
                        </div>
                    </div>*/}
                    <br></br>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Title</label>
                        <div className="uk-form-controls">
                            <input className={this.formatValidInput(this.state.validations.title)} id="title" type="text" placeholder="" value={this.state.title} onChange={this.handleUpdateTitle.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Description</label>
                        <div className="uk-form-controls">
                            <textarea className={this.formatValidTextArea(this.state.validations.description)} id="description" type="text" placeholder="" value={this.state.description} onChange={this.handleUpdateDescription.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Deadline Days</label>
                        <div className="uk-form-controls">
                            <NumberInput className={this.formatValidInput(this.state.validations.deadlineDays)} placeholder="0" id="tradeLimit" type="Integer" value={this.state.deadlineDays} onChange={this.handleUpdateDeadlineDays.bind(this)} />
                            {/*<input className={this.formatValidInput(this.state.validations.deadlineDays)} id="deadlineDays" type="text" placeholder="" value={this.state.deadlineDays} onChange={this.handleUpdateDeadlineDays.bind(this)}/>*/}
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin" style={{ marginBottom: '0px' }}>
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.mandatory} value={this.state.mandatory} onChange={this.handleOnCheckMandatory.bind(this)} /> Mandatory</label>

                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Group By</label>
                        <div className="uk-form-controls">
                            <input className={classNameInput} id="groupBy" type="text" placeholder="" value={this.state.groupBy} onChange={this.handleUpdateGroupBy.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.customerCanAttach} value={this.state.customerCanAttach} onChange={this.handleOnCheckCustomerCanAttach.bind(this)} /> Customer Can Attach</label>

                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">                     
                        <label className="uk-form-label">Type of extended info</label>
                        <div className="uk-form-controls">
                            <DynamicSelect id="type-extended" className={classNameSelect} getValue={this.handleUpdateTypeExtended} listHasPlaceholder={true} data={this.state["Type of extended task"]} objValue={{value: this.state.extendedInfoType}} />
                        </div>
                    </div>
                    {sectionDealStatus}
                    <div className="uk-width-1 uk-margin">
                        <div className="uk-margin">
                            <ul id="accordion-trading-information" uk-accordion="multiple: true">
                                <li className="">
                                    <h5 className="uk-accordion-title">Available Subtypes</h5>
                                    <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                        <div className="task-available-selected-subtypes">
                                            <div style={{ display: 'flex' }}>
                                                <h3 class="uk-width-3-3" style={{marginLeft: "20px"}}>Available Subtypes</h3>
                                                <h3 class="uk-width-1-3 uk-form-stacked">Selected Subtypes</h3>
                                            </div>
                                            <div className="uk-width-1-1" id="subtype-component">
                                                <div
                                                    className="uk-margin"
                                                    style={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        border: '1px solid #9DA99E'
                                                    }}
                                                >
                                                    <div
                                                        className="uk-width-1-3"
                                                        style={{
                                                            overflowY: 'scroll',
                                                            height: 500,
                                                        }}
                                                    >
                                                        <table
                                                            className="uk-table uk-table-hover uk-table-divider"
                                                            style={{
                                                                marginBottom: 0
                                                            }}
                                                        >
                                                            <tbody>
                                                                {listLeft.map((item, i) => {
                                                                    return (
                                                                        <tr
                                                                            key={item.LookUpTableDetail} onClick={this.roleChangeColorLeft(item.LookUpTableDetail)}
                                                                            style={
                                                                                this.state.selectIDLeft === item.LookUpTableDetail ?
                                                                                    { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                            }
                                                                        >
                                                                            <td
                                                                                style={
                                                                                    this.state.selectIDLeft === item.LookUpTableDetail ?
                                                                                        { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                                }
                                                                            >{item.LookUpTableDetail}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div
                                                        className="uk-card-body uk-width-1-3"
                                                        style={{
                                                            textAlign: 'center',
                                                            borderRight: '1px solid #9DA99E',
                                                            borderLeft: '1px solid #9DA99E'
                                                        }}
                                                    >
                                                        <button
                                                            className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available}
                                                            style={{
                                                                marginBottom: 20,
                                                            }}
                                                            onClick={this.handleAddClick.bind(this)}
                                                        >
                                                            Add
                                            </button>
                                                        <button
                                                            className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available}
                                                            style={{
                                                                marginBottom: 20,
                                                            }}
                                                            onClick={this.handleRemoveClick.bind(this)}
                                                        >
                                                            Remove
                                            </button>
                                                    </div>
                                                    <div
                                                        className="uk-width-1-3"
                                                        style={{
                                                            overflowY: 'scroll',
                                                            height: 500,
                                                            paddingLeft: 0,
                                                        }}
                                                    >
                                                        <table
                                                            className="uk-table uk-table-hover uk-table-divider"
                                                            style={{
                                                                marginBottom: 0
                                                            }}
                                                        >
                                                            <tbody>
                                                                {listRight.map((item, i) => {
                                                                    return (
                                                                        <tr
                                                                            key={item.SubEntityType} onClick={this.roleChangeColorRight(item.SubEntityType)}
                                                                            style={
                                                                                this.state.selectIDRight === item.SubEntityType ?
                                                                                    { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                            }
                                                                        >
                                                                            <td
                                                                                style={
                                                                                    this.state.selectIDRight === item.SubEntityType ?
                                                                                        { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                                }
                                                                            >{item.SubEntityType}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1">
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveClick.bind(this)} type="button">Save</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick.bind(this)} type="button">Clear Fields</button>
                        <hr/>
                    </div>
                </form>
                <div className="uk-width-1">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Existing Tasks</h3>
                    <div className="uk-width-1-4 uk-form-stacked">
                        <label className="uk-form-label">SubEntityType</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect id="subEntityType-list" className={classNameSelect} getValue={this.handleUpdateSubEntityType} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.subEntityTypeFilter} objValue={{ value: this.state.subtEntityType, name: this.state.subtEntityType }} />
                        </div>
                    </div>
                    <NewEditableGrid
                        data={this.state.allTasks}
                        columnDetails={this.state.columnDetail}
                        isEditable={false}
                        useDeleteButton={true}
                        enableClick={true} 
                        clickFunction={this.handleRowClick.bind(this)}
                        deleteFunction={this.handleDeleteClick.bind(this)}
                        useExportButton={true}
                        tableName="Tasks"
                        useIcons={true} />

                    {/*<NewDynamicTable
                        id="tasks-table"
                        data={this.state.allTasks}
                        hiddenHeaders={['ID']}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        useFilter={true}
                        filterFunction={this.getAllTasks}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                                                            enableSortByHeader={true} />*/}
                    {/*<Paginator changePage={this.changePage} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                </div>
                <AlertBox id="delete-task" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this task?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox id="confirmation-delete-task" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Task successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing} />
            </div>
        );
    }

}
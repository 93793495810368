import { getDefaultPagingNumberOfItems } from "../../../../helpers/PackageJsonHelper";

const { useState, useEffect } = require("react");
const { getBlotters } = require("../../../../networking/NetworkingBlotters");

const useBlotters = () => {
    const [blotters, setBlotters] = useState([]);
    const [needsReloading, setNeedsReloading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        let isCancelled = false;
        const loadBloatters = async () => {
            const json = await getBlotters(0, 0);
            if (!isCancelled) {
                const totalCount = json.blotters.length;
                const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                setBlotters(json.blotters.sort((a, b) => a.BlotterID - b.BlotterID));
                setTotalPages(totalPages);
                setNeedsReloading(false);
            }
        }

        if (needsReloading) {
            loadBloatters();
        }

        return () => {
            isCancelled = true;
          };
    }, [needsReloading]);

    return [blotters, totalPages, setNeedsReloading];
}

export default useBlotters;
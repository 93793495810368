import {genericGetWithParameters, genericCallWithBody} from './Networking';

export async function getEntityTasks(entityID, entityType) {
    try{
        let params = {
            'entityType': entityType,
            'entityId': entityID,
        };
        let response = await genericGetWithParameters('TaskDefinition/GetEntityTasksByEntityType', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateEntityTask(model){
    var method = 'PUT';
    var apiName = 'TaskDefinition/UpdateEntityTaskFields?';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function completeEntityTask(model){
    var method = 'PUT';
    var apiName = 'TaskDefinition/CompleteEntityTaskFields';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}
import React from 'react';

import { getStyle } from '../../../styles/styles';

var style = getStyle();

export default class DynamicScreenForm extends React.Component {
    state = {
        objJson:{},
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount(){
        if(this.props.objJson != undefined)
        {
            this.setState({objJson:this.props.objJson});
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.objJson != this.props.objJson && this.props.objJson != undefined )
        {
            this.setState({objJson:this.props.objJson});
        }
    }


    render(){
        let objJson = this.state.objJson;
        let information = [];
        
        if(objJson && objJson.length > 0)
        {
            objJson.map(i => 
            information.push(
                <div className="uk-width-1-2 uk-form-stacked">
                    <div className="uk-margin-right">
                        <label className="uk-form-label" htmlFor="form-bank-status">{i.label}:</label>
                        <div className="uk-margin-right">
                            <input className="uk-input" id={"value-"+i} type="text" placeholder="" value={i.value} />
                        </div>
                    </div>
                </div> )
        )
        }
    

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{this.props.Title}</h3>
                <div className="uk-grid">
                    {information}
                </div>
            </div>
        );
    }
}
import React from 'react';
import AuditEntity from './AuditEntity';
export default class AuditOwnerScreen extends React.Component {

    handleBackLink() {
        this.props.backLink();
    }

    render() {
        return (
            <div>
                <h3 className="uk-heading-divider component-title" >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left back-arrow" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    Audit Owner
                </h3>
                <AuditEntity entityID={this.props.ownerID} categoryName={"Audit - Owners"} />
            </div>
        );
    }
}
import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export const Monoova_Register = async (customerID, userID) => {
    try {
        let model = {
            customerID: customerID,
            userID: userID
          }
        const response = await genericCallWithBody('POST', 'Monoova/Register', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const Zepto_Register = async (customerID, userID, bankAccountId, updateToken, entityType) => {
    try {
        let model = {
            customerID: customerID,
            userID: userID,
            bankAccountId: bankAccountId,
            updateToken: updateToken,
            entityType: entityType
          }
        const response = await genericCallWithBody('POST', 'Zepto/Register', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const OpenPayd_CreateBeneficiaryBankAccount = async (bankAccountId) => {
    try {
        let model = {
            BankAccountId: bankAccountId
          }
        const response = await genericCallWithBody('POST', 'OpenPayd/CreateBeneficiaryBankAccount', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import React from 'react';
import './CurrencyPairTable.css'

export default class CurrencyPairTable extends React.Component {

    onClickCell = (event) => {
        console.group('ClickCell');
        console.log(event.target.dataset.firstcurrency);
        console.log(event.target.dataset.secondcurrency);
        console.groupEnd();
        this.props.handleModifyCurrencyPair(event.target.dataset.secondcurrency, event.target.dataset.firstcurrency)
    }
    
    render = () => {
        
        let headers = [];
        let rows = [];
        if(this.props.data.length > 0){
            let objTest = this.props.data[0];
            let finalData = this.props.data;
            let keys = Object.keys(objTest);
            keys.forEach(element => {
                if(element === 'rowdata') {
                    headers.push(<th className="pair-table pair-table-header-blank"></th>);
                } else if (element !== 'Type'){
                    headers.push(<th className="pair-table pair-table-header" style={{padding: '10px', paddingLeft: '20px', paddingRight: '20px'}}>{element}</th>);
                }
                
            });

            for (let i = 0; i < finalData.length; i++) {
                const element = finalData[i];
                let rowElement = []; 
                let backgroundColor = ''
                switch (element.Type) {
                    case 10:
                        backgroundColor = 'lightgreen'
                        break;
                    case 20:
                        backgroundColor = '#dee50f'
                        break;
                    case 30:
                        backgroundColor = 'red'
                        break;
                    default:
                        break;
                }

                for (let index = 0; index < keys.length; index++) {
                    let key = keys[index];
                    if(key === 'rowdata'){
                        rowElement.push(<td className="pair-table pair-table-header-left"
                            style={{ 'backgroundColor': backgroundColor}}>{element[key]}</td>)
                    } else if (key !== 'Type'){                        
                        rowElement.push(<td className="pair-table pair-table-space" data-firstcurrency={element['rowdata']} data-secondcurrency={key} onClick={this.onClickCell}
                            style={{ 'backgroundColor': backgroundColor, 'color': "black"}}>{element[key]}</td>)
                    }
                }
                rows.push(<tr key={100 + i}>{rowElement}</tr>);
            }

        }
        return(
            <div>
                <div style={{textAlign: 'center'}}>Buying Currencies</div>
                <div className="pair-table-container">
                    <table className="pair-table" >
                        <thead>
                            <tr>
                                {headers}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
            
        ); 
    }

}
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import AppContext from '../../../AppContext';
import { formatValidInput, getEmailFooter, replaceWithDummyData } from '../../../helpers/FormatHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { CreateEmailTemplates, GetTemplateNames } from '../../../networking/NetworkingEmailTemplates';
import { getStyle, notFilledSelectClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import CustomQuill from '../../shared/CustomQuill';
import DynamicSelect from '../../shared/DynamicSelect';
const style = getStyle();

export default class EmailTemplatesScreen extends React.Component{
    static contextType = AppContext;
    state = {
        emailBody: '',
        readOnly: false,
        parameters: [],
        subject: '',
        template: '',
        templatesList: [],
        includeFooter: false,
        validations: {
            locations: true,
            subjects: true,
            templates: true,
        },
        "Emails Templates": [],
        lookUpTables: [
            "Emails Templates"
        ],
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        showAlertError: false,
        visibility: "hidden",
        alertHTML: false,
        errors: []
    }
    
    componentDidMount(){
        this.loadEmailTemplateNames();
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {                
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        });
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    };

    loadEmailTemplateNames() {
        GetTemplateNames().then(
            (response) => {
                if (typeof response !== "undefined") {
                    let templates = response.templateNames;
                    templates.forEach(element => {
                        element.value = element.Name;
                        element.name = element.Name;
                    });
                    this.setState({
                        templatesList: templates
                    });
                }
            }
        );
    }
    
    validateRequiredFields() {
        const fields = [
            { displayName: 'Email Template', validateName: 'templates', value: this.state.template, type: 'string' },
            { displayName: 'Location', validateName: 'locations', value: this.state.location, type: 'string' },
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    handleGetEmailTemplate(value){
        if (value !== null && value !== undefined && value !== 'select' && value !== '') {
            var parameters = [];
            if(value.Parameters !== undefined){
                parameters = value.Parameters.split(",");
            }
            var visibility = 'visible'
            if(value.Location === ''){
                visibility = 'hidden'
            }
            this.setState({
                template: value.value,
                emailBody: value.Body, 
                subject: value.Subject,
                parameters: parameters, 
                location: value.Location,
                includeFooter: value.includeFooter,
                controlsVisibility: visibility,
                validations: { ...this.state.validations, templates: true, }
            });
        }
    }
    
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    handleGetBody(value){
        if (value !== null && value !== undefined && value !== 'select' && value !== '') {
            this.setState({ emailBody: value});
        }
    }
    
    handleGetSubject = (event) => {
        this.setState({subject:  event.target.value});
    }

    handleGetLocation = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                location: value.value,
                validations: {...this.state.validations, locations: true}
            });
        }
    }

    handleCreateEmailTemplateClick(event){
        event.preventDefault();
        this.context.startLoading();
        CreateEmailTemplates(this.state.template, this.state.emailBody, this.state.subject, this.state.location, this.state.includeFooter).then(
            (json) => {
                this.context.finishLoading();
                if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                    this.setState({ showAlert: true, alertMessage: json.Message, alertTitle: 'Error', alertHTML: false, });
                } else {
                    this.setState({ showAlert: true, alertMessage: 'The template has been updated.', alertTitle: 'Success', template: '', emailBody: '', subject: '', location: '', parameters: [], alertHTML: false, includeFooter: false });
                    this.loadEmailTemplateNames();
                }
            }
        );
    }

    handlePreviewEmail = () => {
        this.context.startLoading();
        getEmailFooter().then((footer) => {
            this.context.finishLoading();
            let emailBodyWithDummyData = this.state.includeFooter ? replaceWithDummyData(this.state.emailBody) + footer : replaceWithDummyData(this.state.emailBody);
            let subject = replaceWithDummyData(this.state.subject);
            this.setState({ showAlert: true, alertMessage: emailBodyWithDummyData, alertTitle: 'Subject: ' + subject, alertHTML: true });
        })
    }
    handleUpdateIncludeFooter = (event) => {
        this.setState({ includeFooter: event.target.checked })
    }
    render() {
        return(
            <div>
                <div className="uk-width-1-1 uk-margin">
                    <h3 className="uk-heading-divider" style={style.titleStyle}> 
                        Email Templates</h3>   
                </div>         
                <div className="uk-width-1-1">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Email Template (*)</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={this.formatValidSelect(this.state.validations.templates)} objValue={{ value: this.state.template }} getValue={this.handleGetEmailTemplate.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.templatesList} id="dynamic-select-template" />
                        </div>
                    </div>
                </div>       
                <div className="uk-width-1-1">
                    <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: this.state.controlsVisibility }}>
                        <label className="uk-form-label" style={style.boldLabel}>Location (*)</label>
                        <div className="uk-form-controls" >
                            <DynamicSelect className={this.formatValidSelect(this.state.validations.locations)} objValue={{ value: this.state.location }} getValue={this.handleGetLocation.bind(this)} listHasPlaceholder={true} data={this.state["Emails Templates"]} id="dynamic-select-location" />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <div className="uk-margin-top">
                        <label className="uk-form-label" style={style.boldLabel}>
                            <input
                                className="uk-checkbox"
                                value={this.state.includeFooter}
                                checked={this.state.includeFooter}
                                onChange={this.handleUpdateIncludeFooter}
                                type="checkbox" />
                                Include Footer
                        </label>
                    </div>
                </div>
                <div className="uk-width-1-1 uk-margin">
                    <button className="uk-button uk-button-green" type="button" onClick={this.handleCreateEmailTemplateClick.bind(this)}>Save Template</button>
                </div>
                <div className="uk-width-1-1">
                    {/*<ReactQuill theme="snow" value={this.state.emailBody} onChange={this.handleGetBody.bind(this)}/>*/}
                    <label className="uk-form-label" style={style.boldLabel}>Subject (*)</label>
                    <div className="uk-width-6-12 uk-form-stacked" style={style.inputStyle}>
                        <input className={formatValidInput(this.state.validations.subjects) + " uk-width-9-12"}  type="text" placeholder="" value={this.state.subject} onChange={this.handleGetSubject} />
                        <button className="uk-button uk-button-green" type="button" onClick={this.handlePreviewEmail}>Preview Email</button>
                    </div>
                    <CustomQuill placeholder={"Write text for the template."} theme="snow" value={this.state.emailBody} onChange={this.handleGetBody.bind(this)} parameters={this.state.parameters}/>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageIsHTML={this.state.alertHTML}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title='Error' message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} messageBoldList={this.state.errors}/>
            </div>
        );
    }
}
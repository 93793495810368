import React from 'react';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getBankAccountsByPerson, deleteBankAccount, getPersonBankAccounts } from '../../../networking/NetworkingBankAccounts';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import NostroAccountMaintenanceScreen from './NostroAccountMaintenanceScreen';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames'
import NewEditableGrid from '../../shared/NewEditableGrid'
var style = getStyle();
var defaultPagingNumberOfItems = getDefaultPagingNumberOfItems()*2

export default class NostroAccountsScreen extends React.Component {
    state = {
        personID: localStorage.getItem('UserID'),
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        bankAccounts: [],
        selectedBankAccount: null,
        showBankAccount: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteBankAccount: {},
        pages: 1,
        totalPages: 0,
        nostroAccountGuid: '',
        columnDetail: {}
    }

    componentDidMount() {
        let nostroAccountGuid = localStorage.getItem(GlobalSystemParametersNames.NostroAccountGuid);
        console.log(nostroAccountGuid)
        this.setState({ nostroAccountGuid: nostroAccountGuid }, () => {
            this.updatePage();
        })
    }

    handleBackLink = () =>
        this.setState({
            showBankAccount: false
        });

    handleAddClick = () =>
        this.setState({
            selectedBankAccount: null,
            showBankAccount: true
        });

    handleDeleteClick = (obj) =>
        this.setState({
            showDeleteAlert: true,
            readyToDeleteBankAccount: obj});

    handleRowClick = (row) =>
        this.setState({
            selectedBankAccount: {
                BankAccountID: row["ID"],
                Currency: row["Currency"],
                AccountNumber: row["Account Number"],
                Country: row["Country"]
            },
            showBankAccount: true
        });

    closeAlert = () =>
        this.setState({
            showAlert: false,
            alertMessage: '',
            alertTitle: ''
        });

    closeDeleteAlert = () =>
        this.setState({
            showDeleteAlert: false,
            readyToDeleteBankAccount: {}
        });

    openDeleteAlert = () =>
        this.setState({
            showDeleteAlert: true
        });

    okSuccessAlert = () =>
        this.setState({
            showSuccessDeleteAlert: false
        });

    yesDeleteAlert = async () => {

        const json = await deleteBankAccount(this.state.readyToDeleteBankAccount["ID"]);

        /*
            ErrorMessage: null
            Message: "This item cannot be deleted as it is linked to one or more entities in the system."
            httpStatusCode: 412
        */

        if (json !== undefined) 
        {
            let success = false;
            let auxTittle = 'Error';
            let auxMessage = 'An error occurred while trying to delete the bank account. Please try again.';

            switch (json.httpStatusCode)
            {
                case 200:
                {
                    success = true;
                    break;
                }
                case 412:
                case 503:
                {
                    if(json.Message !== undefined && json.Message !== null && json.Message !== '')
                    {
                        auxMessage = json.Message;
                    }
                    break;
                }
                default:
                {
                    break;
                }
            }

            if(success)
            {
                this.setState({
                    showDeleteAlert         : false,
                    showSuccessDeleteAlert  : true
                }, () => {
                    this.updatePage();
                });
            }else
            {
                this.setState({
                    showDeleteAlert : false,
                    alertMessage    : auxMessage,
                    alertTitle      : auxTittle,
                    showAlert       : true
                });
            }
        }
    }

    changePage = (value) => {
        this.setState({
            pages: value,
            newSearch: false
        });
    }

    updatePage = async () => {
        //////debugger
        const json = await getPersonBankAccounts(this.state.nostroAccountGuid,0,0);
        if (json !== undefined && json.bankAccounts !== undefined) 
        {
            const bankAccounts = json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null);
            const totalCount = bankAccounts.length;
            const totalPages = Math.ceil(totalCount / defaultPagingNumberOfItems);
            this.setState({
                bankAccounts,
                totalPages,
                newSearch: false,
                columnDetail:JSON.parse(json.columnDetail)
            });
        } else {
            this.setState({
                bankAccounts: [],
                totalPages: 0,
                newSearch: true
            });
        }
    }

    getBankAccounts = async () => {
        const json = await getPersonBankAccounts(this.state.nostroAccountGuid,0,0);
        if (json !== undefined && json.bankAccounts !== undefined) {            
            return json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null);
        } else {
            return [];
        }
    }

    render() {
        if (this.state.showBankAccount) {
            return (
                <NostroAccountMaintenanceScreen updatePage={this.updatePage} backLink={this.handleBackLink} data={this.state.selectedBankAccount}
                    personID={this.state.personID} nostroAccountGuid={this.state.nostroAccountGuid} mainTitle={this.props.title !== undefined ? this.props.title : "Nostro accounts"} />
            );
        }

        return (
            <div>
                {/* <h3 className="uk-heading-divider"style={style.titleStyle}>
                    {this.props.title !== undefined ? this.props.title : "Nostro accounts"} &nbsp;
                    <button className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>
                </h3> */}
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    {this.props.title !== undefined ? this.props.title : "Nostro accounts"}
                </h3>
                {/*<NewDynamicTable
                    hiddenHeaders = {['ID']}
                    data={this.state.bankAccounts}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={defaultPagingNumberOfItems}
                    enableSortByHeader={true}
                    useFilter={true} 
                    filterFunction={this.getBankAccounts}
                    useExportButton={true}
                    tableName="nostro_accounts"/>*/}
                <NewEditableGrid
                    data={this.state.bankAccounts}
                    columnDetails={this.state.columnDetail}
                    isEditable={false}
                    useDeleteButton={true} 
                    deleteFunction={this.handleDeleteClick}
                    enableClick={true} 
                    clickFunction={this.handleRowClick}
                    useExportButton={true}
                    tableName="NostroAccounts"
                    useIcons={true} />
                <button className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Warning" message="Are you sure you want to delete this bank account? This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Success" message="Bank account successfully removed." type="Ok" okClick={this.okSuccessAlert}/>
            </div>
        );
    }
}
import { useState, useEffect } from "react";
import { removeWhiteSpace } from "../../../../../helpers/FormatHelper";
import { getLookUpTable } from "../../../../../networking/Networking";

const useLookUpTables = (lookUpTablesDescriptionsArray) => {
    const [lookUpTables, setLookUpTables] = useState(lookUpTablesDescriptionsArray.map(t => ({[removeWhiteSpace(t)]: []}))[0]);
    const [needsReloading, setNeedsReloading] = useState(true);

    useEffect(() => {
        const getLookUpTables = async (tables) => {
            const json = await getLookUpTable(tables.toString());
            const formattedLookUpTables = json.lookUpTables.map(t =>
                    ({[removeWhiteSpace(t.LookUpTableDescription)]: t.lookUpTableDetails.map(d => ({...d, name: d.LookUpTableDetail, value: d.LookUpTableDetail}))})
                )[0];
            setLookUpTables(formattedLookUpTables);
            setNeedsReloading(false);
        }
        if (needsReloading) {
            getLookUpTables(lookUpTablesDescriptionsArray);
        }
    }, [needsReloading, lookUpTablesDescriptionsArray]);

    return lookUpTables;
}

export default useLookUpTables;

import React from 'react';

import AlertBox from '../../shared/AlertBox'
import LoadingBox from '../../shared/LoadingBox'
import NumberInput from '../../shared/NumberInput'

import { applyHeldFunds } from '../../../networking/NetworkingFunds'

import { regularInputClass, notFilledInputClass } from '../../../styles/styles'
import { formatDate, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';

import { getStyle } from '../../../styles/styles';
var style = getStyle();



export default class PaymentsApplyBalanceToHeldFundsForm extends React.Component {

    state = {
        amount: 0,
        validations: {
            amount: true
        },
        loading: false,
        backToPayments: false,
        showAlert: false,
        alertMessage: '',
        alertTitle: ''
    }

    componentDidMount() {
        this.setState({ amount: this.props.sourceBalance })
    }

    //networking
    applyBalance = () => {

        let userId = localStorage.getItem('UserID');

        let incomingFund = this.props.incomingFund

        incomingFund.Amount = this.state.amount
        incomingFund.BalanceAdjustment = true
        incomingFund.BalanceAdjustmentType = 'Direct Funding'
        incomingFund.CreatedBy = userId;
        incomingFund.CreateOn = formatDate(new Date());
        incomingFund.FromTrade = false;
        incomingFund.SettlementDeliveryMethod = '';
        incomingFund.SettlementInstrument = "Client's Vostro Account";
        incomingFund.Status = 'Confirmed';
        incomingFund.UpdatedBy = userId;
        incomingFund.UpdatedOn = formatDate(new Date());
        incomingFund.VersionNo = 1

        this.setState({ loading: true }, () => {
            applyHeldFunds(incomingFund, userId).then((response) => {
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Funds applied correctly',
                            loading: false,
                            backToPayments: true
                        })
                        break
                    case 409:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                            loading: false
                        })
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Error applying fund(s). Please try again.',
                            loading: false
                        })
                        break
                }
            })
        })

    }

    //helper
    formatValidInput = (valid) => {
        return valid ? regularInputClass : notFilledInputClass;
    }

    //events
    onApplyBalance = () => {

        let amountToApply = this.state.amount
        if (amountToApply <= 0) {
            this.setState({ validations: { ...this.state.validations, amount: false } })
            return
        }

        let sourceBalance = this.props.sourceBalance
        if (amountToApply > this.props.sourceBalance) {
            let alertMessage = "Payment does not have enough funds (" + formatToCurrencyWithScale(sourceBalance, this.props.currencyId) + " " + this.props.currencyId + "). Please update the amount and try again.";
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: alertMessage, validations: { ...this.state.validations, amount: true } })
            return
        }

        this.applyBalance()
    }

    setAmount = (event) => {
        this.setState({ amount: event.target.value });
    }

    closeAlert = () => {
        this.setState({ showAlert: false })
        if (this.state.backToPayments) {
            this.props.onBackClick()
        }
    }

    //render
    render() {
        return (
            <div className="uk-form-stacked">
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    {this.props.sourceTable === undefined && <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.props.onBackClick}>
                        <i className="fa fa-arrow-left back-arrow-white"></i>
                    </button>
                    }
                    Apply Source Balance to Held Funds
                </h3>
                <div>
                    <div class="uk-text-break">Please enter the amount you want to apply.
                    The amount will be reduced from the source balance and increase the customer's held funds.</div>
                </div>
                <div className="uk-grid uk-margin">
                    <label className='uk-width-1-3' style={{textAlign:'left'}}>Amount to apply</label>
                    <div className='uk-width-1-3'>
                        <NumberInput 
                            id          = "form-applytoheldfunds-amount"
                            className   = {this.formatValidInput(this.state.validations.amount)}
                            type        = "Currency"
                            placeholder = "0"
                            value       = {this.state.amount}
                            onChange    = {this.setAmount}
                        />
                    </div>
                    <div className='uk-width-1-3' style={{textAlign:'left', paddingLeft: 10, margin: 'auto'}}>
                        <label>{this.props.currencyId}</label>
                    </div>
                </div>
                <div className="uk-grid uk-margin">
                    <div className="uk-margin-small-top" >
                        <button className="uk-button uk-button-green" type="button" onClick={this.onApplyBalance}>Save</button>
                        {(this.props.sourceTable != null) &&
                            <button className="uk-button uk-button-green" onClick={this.props.onBackClick}>Close</button>
                        }
                    </div>
                </div>
                <LoadingBox loading={this.state.loading} />
                <AlertBox id="rollApplyFundsrAlert" type="Ok" open={this.state.showAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                    okClick={this.closeAlert} onClose={this.closeAlert} />
            </div>
        )
    }
}
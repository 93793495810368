import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';
import {getPendingPaymentsByCustomer} from '../../../networking/NetworkingPayments'
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import LoadingBox from '../../shared/LoadingBox'
import AppContext from '../../../AppContext';
import DealInformationScreen from '../../deal/DealInformationScreen'

let globalFunctions = {};
export default class SettlementsListScreen extends React.Component {
    static contextType = AppContext;
    state = {
        loading: false,
        payments: [],
        totalPages: 0,
        currentPage: 1,
        customerID: 0,
        newSearch: false,
        showDetail: false,
        paymentID: 0,
        dealHeaderID: 0,
        settlementPersonID:'',
        settlementBranchID: 0
    }

    componentDidMount() {

        globalFunctions = this.context;
        globalFunctions.startLoading();
        if(this.props.customerID !== undefined)
        {
            this.setState({customerID: this.props.customerID,
                    settlementPersonID: this.props.settlementPersonID,
                    settlementBranchID: this.props.settlementBranchID}
                ,() => this.handleUpdateGrid());
        }
        
    }

    getPendingPaymentsByCustomer = async () => {
        var result = [];
        
        getPendingPaymentsByCustomer(this.state.customerID, '', 0, true, false, 0, 0);
        const json = await getPendingPaymentsByCustomer(this.state.customerID, '', 0, true, false, 0, 0);
        result = json.customerPayments;
        
        return result;
    }

    handleUpdateGrid = async () => {        
        const customerPayments = await this.getPendingPaymentsByCustomer();
        if (customerPayments !== undefined) {
            const totalCount = customerPayments.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({payments: customerPayments, totalPages, newSearch: true });
        }
        else
        {
            this.setState({ payments:[], 
                totalPages:0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleRowClick = (obj) => {
        this.setState({dealHeaderID: obj["Deal ID"], 
            paymentID: obj["Payment ID"],
            showDetail: true});
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    handleBackLink = () => {
        this.setState({paymentID: 0, dealHeaderID: 0, showDetail: false});
        this.handleUpdateGrid();
    }

    render(){
        var view = <NewDynamicTable
                        id="properties-table"
                        data={this.state.payments}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={false}
                        useFilter={true}
                        filterFunction={this.getPendingPaymentsByCustomer}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        formats={[{ header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency ID' }]}
                    />
    
        if(this.state.showDetail)
        {
            view = <DealInformationScreen backLink={this.handleBackLink} data={this.state.dealHeaderID}
                    paymentID={this.state.paymentID} fromSettlements={true} 
                    settlementPersonID={this.state.settlementPersonID} settlementBranchID={this.state.settlementBranchID}/>
        }

        return(<div>
                <div>
                    {view}
                </div>
                <LoadingBox loading={this.state.loading} />
        </div>);
    }
}
import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';
import {getPendingRefundsByCustomer} from '../../../networking/NetworkingFunds'
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
import { formatToCurrency, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import LoadingBox from '../../shared/LoadingBox'
import AppContext from '../../../AppContext';
import DealInformationScreen from '../../deal/DealInformationScreen'

let globalFunctions = {};
export default class RefundsListScreen extends React.Component {
    static contextType = AppContext;
    state = {
        loading: false,
        refunds: [],
        totalPages: 0,
        currentPage: 1,
        customerID: 0,
        newSearch: false,
        showDetail: false,
        refundID: 0,
        dealHeaderID: 0,
        refundPersonID:'',
        refundBranchID: 0
    }

    componentDidMount() {

        globalFunctions = this.context;
        globalFunctions.startLoading();
        if(this.props.customerID !== undefined)
        {
            this.setState({customerID: this.props.customerID,
                    refundBranchID: this.props.refundBranchID,
                    refundPersonID: this.props.refundPersonID}
                ,() => this.handleUpdateGrid());
        }
        
    }

    getPendingRefundsByCustomer = async () => {
        var result = [];
        
        getPendingRefundsByCustomer(this.state.customerID, '', 0, true, false, 0, 0);
        const json = await getPendingRefundsByCustomer(this.state.customerID, '', 0, true, false, 0, 0);
        result = json.refunds;
        
        return result;
    }

    handleUpdateGrid = async () => {        
        const refunds = await this.getPendingRefundsByCustomer();
        if (refunds !== undefined) {
            const totalCount = refunds.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({refunds: refunds, totalPages, newSearch: true });
        }
        else
        {
            this.setState({ refunds:[], 
                totalPages:0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleRowClick = (obj) => {
        this.setState({dealHeaderID: obj["Deal ID"], 
            refundID: obj["Outgoing Fund ID"],
            showDetail: true});
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    handleBackLink = () => {
        this.setState({refundID: 0, dealHeaderID: 0, showDetail: false});
        this.handleUpdateGrid();
    }

    render(){
        var view = <NewDynamicTable
                        id="properties-table"
                        data={this.state.refunds}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={false}
                        useFilter={true}
                        filterFunction={this.getPendingRefundsByCustomer}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        formats={[{ header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency ID' }]}
                    />
    
        if(this.state.showDetail)
        {
            view = <DealInformationScreen backLink={this.handleBackLink} data={this.state.dealHeaderID}
                    refundID={this.state.refundID} fromRefunds={true} 
                    refundPersonID={this.state.refundPersonID} refundBranchID={this.state.refundBranchID}/>
        }

        return(<div>
                <div>
                    {view}
                </div>
                <LoadingBox loading={this.state.loading} />
        </div>);
    }
}
import { genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters } from "./Networking";

export async function getTasksDef(subEntityType, page, items){
    try {
        //debugger;
        let params = {
            "SubEntityType":subEntityType,
            "page": page,
            "items":items
        };

        let response = await genericGetWithParameters('TaskDefinition/GetTasksDef',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getTasksDefById(taskDefId){
    try {
        let params = {
            "TaskDefId": taskDefId
        };

        let response = await genericGetWithParameters('TaskDefinition/GetTasksDefById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveTaskDef(model){

    try{
        let response = await genericCallWithBody('POST','TaskDefinition/SaveTasksDef',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteTaskDef(id){
    let params = {
        'TaskDefId': id,
    };
    let response = await genericDeleteWithParameters('TaskDefinition/DeleteTaskDef',params);
    return response;
}

export async function getPendingEntityTasks(entityType, entityID, applicationDate, overDeadline){
    try {
        let params = {
            "entityType": entityType,
            "entityID": entityID,
            "applicationDate": applicationDate,
            "overDeadline": overDeadline,
        };

        let response = await genericGetWithParameters('TaskDefinition/GetPendingEntityTasks',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getTasksDefBySubEntityType(subEntityType){
    try {
        let params = {
            "subEntityType": subEntityType
        };

        let response = await genericGetWithParameters('TaskDefinition/GetTasksDefBySubEntityType',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
//Beneficiary successfully saved.
import React from 'react';

import { getStyle } from '../../../../styles/styles';
var style = getStyle();

export default class SuccessBeneficiary extends React.Component {
    render() {
        return (
            <div className="uk-card uk-card-default uk-card-body" style={{ textAlign: 'center' }}>
                <h3 style={style.titleStyle}>Thanks for providing your information.</h3>
            </div>
        );
    }
}
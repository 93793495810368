import { Table } from '@material-ui/core';
import React from 'react';
import { getLookUpTable } from '../../../networking/Networking';
import { getCurrenciesByCustomer, getCurrencyList } from '../../../networking/NetworkingCurrencies';
import { saveRateWatch, updateRateWatchStatus } from '../../../networking/NetworkingRateWatch';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NumberInput from '../../shared/NumberInput';
import { getScaleByCurrency, getScaleForCurrencyPair, roundAmountByCurrency, roundAmountByCurrencyPair } from '../../../helpers/FormatHelper';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';

var style = getStyle();

export default class AddOrderBoardForm extends React.Component {
    state = {
        alertMessage: '',
        alertTitle: '',
        amount: 0,
        branchID: 0,
        buyCurrency: '',
        buysOrSells1: 'Buys',
        buysOrSells2: 'Sells',
        checkSingleRate: true,
        currency1: '',
        currency2: '',
        currencyList: [],
        customerSettlementCurrencies: [],
        customerPaymentCurrencies: [],
        customerContactID: 0,
        customerID: 0,
        customerList: [],
        customerName: '',
        customerRate1: 0,
        customerRate2: 0,
        dealHeaderID: 0,
        dealType: 1,
        equivalent: 0,
        equivalent2:0,
        executionDate: undefined,
        expirationDate: undefined,
        farDate: undefined,
        forwardType: 'Days',
        goodTillCancelled: false,
        loading: false,
        nearDate: undefined,
        notificationMethod: '',
        notifyCustomer: false,
        openFor: 0,
        rateInformation: [{CustomerRate:0, SpotMarketRate: 0, SpotMarketRateWithPadding: 0, ForwardPoints: 0, FwdMarketRate: 0},
                            {CustomerRate:0, SpotMarketRate: 0, SpotMarketRateWithPadding: 0, ForwardPoints: 0, FwdMarketRate: 0}],
        rateReached: false,
        rateWatchID: 0, 
        rateWatchOrigin: 'Retail',
        rateWatchStatus: 'Outstanding',
        rateWatchType: 'Order',
        showAlert: false,
        updateToken:'',
        validations:{
            notificationMethod: true,
            expirationDate: true,
            amount: true,
            currency1: true,
            currency2: true,
            nearDate: true,
            farDate: true,
            customerRate1: true,
            customerRate2: true
        },
        weAction: 'We Sell', 
        
        
        
        notificationList: [],
        "Rate Watch Customer Filters": [],
        "Rate Watch Status Names": [],
        "Rate Watch Type": [],
        "Notification Method": [],
        lookUpTables: [
            "Rate Watch Customer Filters",
            "Rate Watch Status Names",
            "Rate Watch Type",
            "Notification Method"
        ],
    }

    componentDidMount(){
        //Get look up tables
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        console.group(this.state.lookUpTables[i]);
                        console.table(table);
                        console.groupEnd();
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        });

        //Get currencies
        getCurrencyList().then(
            (value) => {
                let jsonCurrency = value.currencies;
                for(var i=0;i<jsonCurrency.length;i++){
                    jsonCurrency[i].value = jsonCurrency[i].CurrencyID;
                    jsonCurrency[i].name = jsonCurrency[i].CurrencyIDName;
                }
                //console.log(helper);
                //currencies
                this.setState({currencyList: jsonCurrency});
            }
        );

        let customerId = this.props.objCustomer['Customer ID']
        getCurrenciesByCustomer(customerId).then(
            (json) => {
                if (json !== undefined & json != null) {
                    console.log(json)
                    let currencies = json.currencies
                    let settlementCurrencies = currencies.filter(x => x.Type === 'Settlement') || []
                    let paymentCurrencies = currencies.filter(x => x.Type === 'Payment') || []

                    settlementCurrencies.forEach(x => {
                        x.value = x.CurrencyID
                        x.name = x.CurrencyIDName
                    })

                    paymentCurrencies.forEach(x => {
                        x.value = x.CurrencyID
                        x.name = x.CurrencyIDName
                    })

                    this.setState({ customerSettlementCurrencies: settlementCurrencies, customerPaymentCurrencies: paymentCurrencies })
                    /*let jsonCurrency = value.currencies;
                    for(var i=0;i<jsonCurrency.length;i++){
                        jsonCurrency[i].value = jsonCurrency[i].CurrencyID;
                        jsonCurrency[i].name = jsonCurrency[i].CurrencyIDName;
                    }
                    //console.log(helper);
                    //currencies
                    this.setState({currencyList: jsonCurrency});*/
                }
            }
        );

        //Load rate watch details
        var customerName = '', rateWatchStatus = 'Outstanding', rateWatchType = 'Order',
            notificationMethod = '', weAction = 'We Sell', currency1 = '',
            currency2 = '', updateToken = '', buysOrSells1 = 'Buys',
            buysOrSells2 = 'Sells';
        var rateWatchID = 0,
            amount = 0, dealType = 1, dealHeaderID = 0,
            openFor = 0, customerRate1 = 0, customerRate2 = 0;
        var executionDate = null, expirationDate = null, nearDate = null,
            farDate = null;
        var checkSingleRate = true, goodTillCancelled = false, notifyCustomer = false;
        if(this.props.objRateWatch.RateWatchID != null){
            let objRateWatch = this.props.objRateWatch;
            checkSingleRate = objRateWatch.CheckSingleRate;
            customerName = objRateWatch.CustomerName;
            customerRate1 = objRateWatch.CustomerRate1;
            customerRate2 = objRateWatch.CustomerRate2;
            dealType = objRateWatch.DealTypeID;
            if(objRateWatch.ExecutionDate != null){
                executionDate = new Date(objRateWatch.ExecutionDate);
            }
            if(objRateWatch.ExpirationDate != null){
                expirationDate = new Date(objRateWatch.ExpirationDate);
            }   
            if(objRateWatch.FarDate != null){
                farDate = new Date(objRateWatch.FarDate);
            }         
            goodTillCancelled = objRateWatch.GoodTillCancelled;
            if(objRateWatch.NearDate != null){
                nearDate = new Date(objRateWatch.NearDate);
            }
            if(objRateWatch.DealHeaderID != null)
            {
                dealHeaderID = objRateWatch.DealHeaderID;
            }
            notificationMethod = objRateWatch.NotificationMethod;
            notifyCustomer = objRateWatch.NotifyCustomer;
            openFor = objRateWatch.OpenFor;
            rateWatchID = objRateWatch.RateWatchID;
            rateWatchStatus = objRateWatch.RateWatchStatus;
            rateWatchType = objRateWatch.RateWatchType;
            updateToken = objRateWatch.UpdateToken;
            weAction = objRateWatch.WeAction;

            if(weAction === 'We Sell'){
                currency2 = objRateWatch.BuyCurrency;
                currency1 = objRateWatch.SellCurrency;
                amount = objRateWatch.SellAmount1;
            }
            else{
                currency1 = objRateWatch.BuyCurrency;
                currency2 = objRateWatch.SellCurrency;
                amount = objRateWatch.BuyAmount1;
            }

            if(weAction === 'We Buy'){
                buysOrSells1 = 'Sells';
                buysOrSells2 = 'Buys';   
            }
        }

        this.setState({amount: amount,
            buysOrSells1: buysOrSells1,
            buysOrSells2: buysOrSells2,
            checkSingleRate: checkSingleRate,
            currency1: currency1,
            currency2: currency2,
            customerID: this.props.objCustomer['Customer ID'],
            customerContactID: this.props.objContact['Contact ID'],
            customerName: customerName,
            customerRate1: customerRate1,
            customerRate2: customerRate2,
            dealHeaderID: dealHeaderID,
            dealType: dealType,
            executionDate: executionDate,
            expirationDate: expirationDate,
            farDate: farDate,
            goodTillCancelled: goodTillCancelled,
            nearDate: nearDate,
            notificationMethod: notificationMethod,
            notifyCustomer: notifyCustomer,
            openFor: openFor,
            rateWatchID: rateWatchID,
            rateWatchStatus: rateWatchStatus,
            rateWatchType: rateWatchType,
            updateToken: updateToken,
            weAction: weAction,
        },() =>
        {
            if(this.props.objRateWatch.RateWatchID !== undefined){
            var equivalent = 0;
            var equivalent2 = 0;
            
            equivalent = this.calculateAmounts(this.state.amount, this.state.customerRate1).SellAmount;

            if(!this.state.checkSingleRate)
            {
                equivalent2 = this.calculateAmounts(this.state.amount, this.state.customerRate2).SellAmount;
            }
            this.setState({equivalent: equivalent, equivalent2: equivalent2});
        }
        });
    }

    handleNewClick = () =>{
        this.setState({
            amount: 0,
            branchID: 0,
            buyCurrency: '',
            buysOrSells1: 'Buys',
            buysOrSells2: 'Sells',
            checkSingleRate: true,
            currency1: '',
            currency2: '',
            customerContactID: 0,
            customerID: 0,
            customerName: '',
            customerRate1: 0,
            customerRate2: 0,
            dealHeaderID: 0,
            dealType: 1,
            executionDate: null,
            expirationDate: null,
            farDate: null,
            forwardType: 'Days',
            goodTillCancelled: false,
            openFor: 0,
            nearDate: null,
            notificationMethod: '',
            notifyCustomer: false,
            rateInformation: [{CustomerRate:0, SpotMarketRate: 0, SpotMarketRateWithPadding: 0, ForwardPoints: 0, FwdMarketRate: 0},
                                {CustomerRate:0, SpotMarketRate: 0, SpotMarketRateWithPadding: 0, ForwardPoints: 0, FwdMarketRate: 0}],
            rateReached: false,
            rateWatchID: 0, 
            rateWatchOrigin: 'Retail',
            rateWatchStatus: 'Outstanding',
            rateWatchType: 'Order',
            updateToken:'',
            validations:{
                notificationMethod: true,
                expirationDate: true,
                amount: true,
                currency1: true,
                currency2: true,
                nearDate: true,
                farDate: true,
                customerRate1: true,
                customerRate2: true
            },
            weAction: 'We Sell',
        });
    }

    validateSave = () => {
        var result = true;
        var validations = {
            notificationMethod: true,
            expirationDate: true,
            amount: true,
            currency1: true,
            currency2: true,
            nearDate: true,
            farDate: true,
            customerRate1: true,
            customerRate2: true
        }

        if(this.state.notificationMethod === '' || 
            this.state.notificationMethod === 'select' || 
            this.state.notificationMethod === undefined){
            validations.notificationMethod = false;
            result = false;
        }

        if(!this.state.goodTillCancelled &&
            this.state.expirationDate === undefined){
            validations.expirationDate = false;
            result = false;
        }
        
        if(this.state.amount === 0){
            validations.amount = false;
            result = false;
        }

        if(this.state.currency1 === '' || 
            this.state.currency1 === 'select' || 
            this.state.currency1 === undefined){
            validations.currency1 = false;
            result = false;
        }

        if(this.state.currency2 === '' || 
            this.state.currency2 === 'select' ||
            this.state.currency2 === undefined){
            validations.currency2 = false;
            result = false;
        }
        
        if(this.state.dealType === 4){
            if(this.state.forwardType === 'Dates'){
                if(this.state.nearDate === undefined){
                    validations.nearDate = false;
                    result = false;
                }
                
                if(this.state.farDate === undefined){
                    validations.farDate = false;
                    result = false;
                }
            }
        }

        if(this.state.customerRate1 === 0){
            validations.customerRate1 = false;
            result = false;
        }

        if(!this.state.checkSingleRate &&
            this.state.customerRate2 === 0){
            validations.customerRate2 = false;
            result = false;
        }

        this.setState({validations: validations});
        return result;
    }

    handleSaveClick = () =>{
        if(this.validateSave()){
            this.setState({loading: true});
            let rateInformation = this.state.rateInformation;
            let weAction = this.state.weAction;
            let customerRate1 = this.state.customerRate1;
            var customerRate2 = this.state.customerRate2; 
            if(this.state.checkSingleRate){
                customerRate2 = null; 
            }

            var buyCurrency = '', sellCurrency = '';
            var buyAmount1 = 0, sellAmount1 = 0, buyAmount2 = 0, sellAmount2 = 0;
            if(weAction === 'We Sell'){
                buyCurrency = this.state.currency2;
                sellCurrency = this.state.currency1;
                sellAmount1 = sellAmount2 = this.state.amount;
            }
            else{
                buyCurrency = this.state.currency1;
                sellCurrency = this.state.currency2;
                buyAmount1 = buyAmount2 = this.state.amount;
            }
            
            let padding1 = ((rateInformation[0].SpotMarketRateWithPadding - rateInformation[0].SpotMarketRate) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));
            let padding2 = ((rateInformation[1].SpotMarketRateWithPadding - rateInformation[1].SpotMarketRate) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

            let trueFwdMarketRate1 = (rateInformation[0].FwdMarketRate - (padding1 / 10000)).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));
            let trueFwdMarketRate2 = (rateInformation[1].FwdMarketRate - (padding2 / 10000)).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

            var objRateWatch = {
                BranchID: this.state.branchID,
                BuyAmount1: buyAmount1,//
                //BuyAmount2: buyAmount2,//
                BuyCurrency: buyCurrency,
                CheckSingleRate: this.state.checkSingleRate,
                CreatedBy: localStorage.getItem('UserID'),
                CustomerContactID: this.state.customerContactID,
                CustomerID: this.state.customerID,
                CustomerRate1: customerRate1,//
                CustomerRate2: customerRate2,//
                DealHeaderID: this.state.dealHeaderID,
                DealTypeID: this.state.dealType,
                ExecutionDate: this.state.executionDate != null ? this.state.executionDate : null,
                ExpirationDate: this.state.expirationDate != null ? this.state.expirationDate.toISOString() : null,
                FarDate: this.state.farDate != null ? this.state.farDate.toISOString() : null,
                ForwardPoints1: rateInformation[0].ForwardPoints,//
                //ForwardPoints2: rateInformation[1].ForwardPoints,//
                FwdMarketRate1: rateInformation[0].FwdMarketRate,//
                //FwdMarketRate2: rateInformation[1].FwdMarketRate,//
                GoodTillCancelled: this.state.goodTillCancelled,
                NearDate: this.state.nearDate != null ? this.state.nearDate.toISOString() : null,
                NotificationMethod: this.state.notificationMethod,
                NotifyCustomer: this.state.notifyCustomer,
                OpenFor: this.state.openFor,
                Padding1: padding1,//
                //Padding2: padding2,//
                Profit1: rateInformation[0].Profit,
                //Profit2: rateInformation[1].Profit,
                RateReached: this.state.rateReached ? 1 : 0,
                RateWatchID: this.state.rateWatchID,
                RateWatchOrigin: this.state.rateWatchOrigin,
                RateWatchStatus: this.state.rateWatchStatus, 
                RateWatchType: this.state.rateWatchType,
                SellAmount1: sellAmount1,//
                //SellAmount2: sellAmount2,//
                SellCurrency: sellCurrency,
                SpotMarketRate1: rateInformation[0].SpotMarketRate,//
                //SpotMarketRate2: rateInformation[1].SpotMarketRate,//
                TrueFwdMarketRate1: trueFwdMarketRate1,//
                //TrueFwdMarketRate2: trueFwdMarketRate2,//
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: this.state.updateToken,
                WeAction: weAction,
            };

            saveRateWatch(objRateWatch).then(
                (json) => {
                    //alert(JSON.stringify(json));
                    var rateWatchID = this.state.rateWatchID;
                    var updateToken = this.state.updateToken;
                    if(json.httpStatusCode === 200){
                        var message = 'Rate watch created!';
                        if(rateWatchID !== 0){
                            message = 'Rate watch updated!';
                        }
                        this.showAlert('Success', message);
                        rateWatchID = json.RateWatchID;
                        updateToken = json.UpdateToken;
                    }
                    else{
                        this.showAlert('Error', 'Rate watch not created! Try again or contact administrator.');
                    }
                    this.setState({loading: false,
                        rateWatchID: rateWatchID,
                        updateToken: updateToken,
                    });
                }
            )

            //alert('Save:' + JSON.stringify(objRateWatch));
        }//if(this.validateSave()){
    }

    handleDuplicateClick = () => {
        this.setState({rateWatchID: 0,
            rateWatchStatus: 'Outstanding',
            updateToken: '',
        });
    }

    handleVoidClick = async () => {
        let objRateWatch = {
            RateWatchID: this.state.rateWatchID,
            RateWatchStatus: 'Voided',
            UpdateToken: this.state.updateToken,
            UpdatedBy: await localStorage.getItem('UserID'),
        };
        updateRateWatchStatus(objRateWatch).then(
            (response) => {
                //alert(JSON.stringify(response));
                if(Number(response.httpStatusCode) === 200){
                    this.showAlert('Success', 'Rate watch voided!');
                    this.setState({rateWatchStatus: 'Voided',
                        updateToken: response.UpdateToken,
                    });
                }
                else{
                    this.showAlert('Error', 'Rate watch not updated! Please try again or contact the administrator.');
                }
            }
        );
    }

    handleReactivateClick = async () =>{
        let objRateWatch = {
            RateWatchID: this.state.rateWatchID,
            RateWatchStatus: 'Outstanding',
            UpdateToken: this.state.updateToken,
            UpdatedBy: await localStorage.getItem('UserID'),
        };
        updateRateWatchStatus(objRateWatch).then(
            (response) => {
                //alert(JSON.stringify(response));
                if(Number(response.httpStatusCode) === 200){
                    this.showAlert('Success', 'Rate watch reactivated!');
                    this.setState({rateWatchStatus: 'Outstanding',
                        updateToken: response.UpdateToken,
                    });
                }
                else{
                    this.showAlert('Error', 'Rate watch not updated! Please try again or contact the administrator.');
                }                
            }
        );
    }

    formatValidSelect(valid){
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid){
        return valid ? regularInputClass : notFilledInputClass;
    }

    showAlert = (title, message) => {
        this.setState({alertTitle: title,
            alertMessage: message,
            showAlert: true,
        });
    }

    closeAlert = () => {
        this.setState({showAlert: false});
    }

    //#region Handle controls updates

    handleUpdateCheckSingleRate = (value) => {
        this.setState({checkSingleRate: value});
    }

    handleUpdateDealType = (value) => {
        this.setState({dealType: value});
    }

    handleUpdateGoodTillCancelled = (value) => {
        this.setState({goodTillCancelled: value});
    }

    handleUpdateBuysOrSell1 = (event) => {
        var value = event.target.value;
        var buysOrSells2 = 'Sells';
        var weAction = 'We Sell';
        if(value === 'Sells'){
            buysOrSells2 = 'Buys';
            weAction = 'We Buy';
        }
        this.setState({buysOrSells1: value,
            buysOrSells2: buysOrSells2,
            weAction: weAction,
        },() =>
        {
            var equivalent = 0;
            var equivalent2 = 0;
            
            equivalent = this.calculateAmounts(this.state.amount, this.state.customerRate1).SellAmount;

            if(!this.state.checkSingleRate)
            {
                equivalent2 = this.calculateAmounts(this.state.amount, this.state.customerRate2).SellAmount;
            }
            this.setState({equivalent: equivalent, equivalent2: equivalent2});
        })
    }
    
    handleUpdateForwardType = (value) => {
        this.setState({forwardType: value});
    }

    getCurrency1 = (obj) => {
        if(obj.value !== undefined){            
            this.setState({currency1: obj.value},() =>
            {
                var equivalent = 0;
                var equivalent2 = 0;
                
                equivalent = this.calculateAmounts(this.state.amount, this.state.customerRate1).SellAmount;

                if(!this.state.checkSingleRate)
                {
                    equivalent2 = this.calculateAmounts(this.state.amount, this.state.customerRate2).SellAmount;
                }
                this.setState({equivalent: equivalent, equivalent2: equivalent2});
            });
        }        
    }

    getCurrency2 = (obj) => {
        if(obj.value !== undefined){
            this.setState({currency2: obj.value},() =>
            {
                var equivalent = 0;
                var equivalent2 = 0;
                
                equivalent = this.calculateAmounts(this.state.amount, this.state.customerRate1).SellAmount;

                if(!this.state.checkSingleRate)
                {
                    equivalent2 = this.calculateAmounts(this.state.amount, this.state.customerRate2).SellAmount;
                }
                this.setState({equivalent: equivalent, equivalent2: equivalent2});
            });
        }        
    }

    handleUpdateNearDate = (date) => {
        this.setState({nearDate: date});
    }

    handleUpdateFarDate = (date) => {
        this.setState({farDate: date});
    }

    handleExecutionDate = (date) => {
        this.setState({executionDate: date});
    }

    handleUpdateExpirationDate = (date) => {
        this.setState({expirationDate: date});
    }

    handleUpdateOpenFor = (event) => {
        this.setState({openFor: event.target.value});
    }

    handleUpdateNotificationMethod = (obj) => {
        if(obj.value !== undefined){
            this.setState({notificationMethod: obj.value});
        }        
    }

    handleUpdateStatus = (obj) => {
        if(obj.value !== undefined){
            this.setState({rateWatchStatus: obj.value});
        }        
    }

    handleUpdateType = (obj) => {
        if(obj.value !== undefined){
            this.setState({rateWatchType: obj.value});
        }        
    }

    handleOnCheckNotify = (event) => {
        this.setState({notifyCustomer: event.target.checked})
    }

    calculateAmounts(amount, customerRate){
        //alert(sellAmount + '-' + buyAmount + '-' + rateTerms);

        var rateTerms = 'D';
        var lockedSide = (this.state.buysOrSells1 == 'Buys' ? 'Buy': 'Sell');
        var equivalent = 0;

        if(this.props.currencyPairs != undefined)
        {
            var pair = this.props.currencyPairs[this.state.currency1+this.state.currency2]
            if(pair != undefined)
            {
                rateTerms = pair.RateTerms;
            }
        }
        var depositAmount = this.state.depositAmount;
        if(lockedSide === 'Buy'){
            if(rateTerms === 'D'){
                        if(customerRate !== 0){
                            equivalent = amount / customerRate;
                        }
                    }
                    else{
                        equivalent = amount * customerRate;
                    }
            
                    equivalent = roundAmountByCurrency(equivalent,this.state.currency1);
        }
        else{
                    if(rateTerms === 'D'){
                        equivalent = amount * customerRate;
                    }
                    else{
                        if(customerRate !== 0){
                            equivalent = amount / customerRate;
                        }
                    }
            
            
            equivalent = roundAmountByCurrency(equivalent,this.state.currency1);
        }

        var results = [];
        results.BuyAmount = Number(amount);
        results.SellAmount = Number(equivalent);
        return results;
    }

    handleUpdateCustomerRate1 = (event) => {
        /*let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        var rateInformation = this.state.rateInformation;
        rateInformation[index].CustomerRate = event.target.value;
        this.setState({rateInformation: rateInformation});*/
        var equivalent = 0;
        if(event != undefined)
        {
            equivalent = this.calculateAmounts(this.state.amount, event.target.value).SellAmount;
        }
        this.setState({customerRate1: event.target.value, equivalent: equivalent});
    }

    handleUpdateCustomerRate2 = (event) => {
        var equivalent2 = 0;
        if(event != undefined)
        {
            equivalent2 = this.calculateAmounts(this.state.amount, event.target.value).SellAmount;
        }
        this.setState({customerRate2: event.target.value, equivalent2: equivalent2});
    }

    handleUpdateSpotMarketRateWithPadding = (event) =>{
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        var rateInformation = this.state.rateInformation;
        rateInformation[index].SpotMarketRateWithPadding = event.target.value;
        this.setState({rateInformation: rateInformation});
    }

    handleUpdateSpotMarketRateNoPadding = (event) =>{
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        var rateInformation = this.state.rateInformation;
        rateInformation[index].SpotMarketRate = event.target.value;
        this.setState({rateInformation: rateInformation});
    }

    handleUpdateForwardPoints = (event) => {
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        var rateInformation = this.state.rateInformation;
        rateInformation[index].ForwardPoints = event.target.value;
        this.setState({rateInformation: rateInformation});
    }

    handleUpdateFwdMarketRate = (event) => {
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        var rateInformation = this.state.rateInformation;
        rateInformation[index].FwdMarketRate = event.target.value;
        this.setState({rateInformation: rateInformation});
    }

    handleUpdateAmount(event){
        var equivalent = 0;
        var equivalent2 = 0;
        
        equivalent = this.calculateAmounts(event.target.value, this.state.customerRate1).SellAmount;

        if(!this.state.checkSingleRate)
        {
            equivalent2 = this.calculateAmounts(event.target.value, this.state.customerRate2).SellAmount;
        }

        this.setState({amount: event.target.value, equivalent: equivalent, equivalent2: equivalent2});
    }    

    
    //#endregion

    render(){
        var rows = [];
        var headers = [];
        headers.push(<th key='1'></th>);
        headers.push(<th key='2'>Customer Rate</th>);
        headers.push(<th key='3'>Equivalent</th>);
        
        /*headers.push(<th key='4'>Spot Market Rate (with Padding)</th>);
        headers.push(<th key='5'>Spot Market Rate (no Padding)</th>);
        headers.push(<th key='6'>Forward Points</th>);
        headers.push(<th key='7'>Fwd. Market Rate</th>);
        headers.push(<th key='8'>Deal Cost</th>);
        headers.push(<th key='9'>Profit</th>);*/

        let currencyPairBuy = this.state.weAction === 'We Sell' ? this.state.currency2 : this.state.currency1
        let currencyPairSell = this.state.weAction === 'We Sell' ? this.state.currency1 : this.state.currency2

        console.log(currencyPairBuy)
        console.log(currencyPairSell)

        rows.push(<tr key='1'>
            <td><label className="uk-form-label" style={style.boldLabel}>Rate Information</label></td>
            <td><NumberInput className={this.formatValidInput(this.state.validations.customerRate1)} id="customerRate-0" type="Rate"
                scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1}
                onChange={this.handleUpdateCustomerRate1} /></td>
            <td><NumberInput className="uk-input" id="row-equivalent" type="Currency" scale={getScaleByCurrency(this.state.currency1)} value={this.state.equivalent} disabled={true} /></td>
        </tr>);

        /*
            <td><NumberInput className="uk-input" id="spotMarketRateWithPadding-0" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[0].SpotMarketRateWithPadding} onChange={this.handleUpdateSpotMarketRateWithPadding}/></td>
            <td><NumberInput className="uk-input" id="spotMarketRate-0" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[0].SpotMarketRate} onChange={this.handleUpdateSpotMarketRateNoPadding}/></td>
            <td><NumberInput className="uk-input" id="forwardPoints-0" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[0].ForwardPoints} onChange={this.handleUpdateForwardPoints}/></td>
            <td><NumberInput className="uk-input" id="fwdMarketRate-0" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[0].FwdMarketRate} onChange={this.handleUpdateFwdMarketRate}/></td>
            <td><NumberInput className="uk-input" id="row-dealCost" type="Currency" value={this.state.dealCost} disabled={true}/></td>
            <td><NumberInput className="uk-input" id="row-profit" type="Currency" value={this.state.profit} disabled={true}/></td>
        */

        var rows1 = [];

        if(!this.state.checkSingleRate){            
            rows1.push(<tr key='2'>
                <td><label className="uk-form-label" style={style.boldLabel}>Rate Information</label></td>
                <td><NumberInput className={this.formatValidInput(this.state.validations.customerRate2)} id="customerRate-1" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.customerRate2} onChange={this.handleUpdateCustomerRate2}/></td>
                <td><NumberInput className="uk-input" id="row-equivalent" type="Currency" scale={getScaleByCurrency(this.state.currency2)} value={this.state.equivalent2} disabled={true}/></td>
            </tr>);
        }

        /*
            <td><NumberInput className="uk-input" id="spotMarketRateWithPadding-1" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[1].SpotMarketRateWithPadding} onChange={this.handleUpdateSpotMarketRateWithPadding}/></td>
            <td><NumberInput className="uk-input" id="spotMarketRate-1" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[1].SpotMarketRate} onChange={this.handleUpdateSpotMarketRateNoPadding}/></td>
            <td><NumberInput className="uk-input" id="forwardPoints-1" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[1].ForwardPoints} onChange={this.handleUpdateForwardPoints}/></td>
            <td><NumberInput className="uk-input" id="fwdMarketRate-1" type="Rate" scale={getScaleForCurrencyPair(currencyPairBuy, currencyPairSell)} value={this.state.customerRate1} value={this.state.rateInformation[1].FwdMarketRate} onChange={this.handleUpdateFwdMarketRate}/></td>
            <td><NumberInput className="uk-input" id="row-dealCost" type="Currency" value={this.state.dealCost1} disabled={true}/></td>
            <td><NumberInput className="uk-input" id="row-profit" type="Currency" value={this.state.profit1} disabled={true}/></td>
        */

        //Disabled fields
        var expiredOnDisabled = false;
        if(this.state.goodTillCancelled){
            expiredOnDisabled = true;
        }

        var dealType = this.state.dealType;

        var saveButton;
        var duplicateButton;
        var voidButton;
        var reactivateButton;

        if(!['Executed', 'Voided'].includes(this.state.rateWatchStatus)){
            saveButton = (<button className="uk-button uk-button-green" onClick={this.handleSaveClick}>Save</button>);
        }        

        if(this.state.rateWatchID !== 0){
            duplicateButton = (<button className="uk-button uk-button-green" onClick={this.handleDuplicateClick}>Duplicate</button>);

            if(this.state.rateWatchStatus === 'Outstanding'){
                voidButton = (<button className="uk-button uk-button-green" onClick={this.handleVoidClick}>Void</button>);
            }

            if(['Expired','Voided'].includes(this.state.rateWatchStatus)){
                reactivateButton = (<button className="uk-button uk-button-green" onClick={this.handleReactivateClick}>Reactivate</button>);
            }
        }


        let currenciesListAction1 = []
        let currenciesListAction2 = []
        if (this.state.weAction === 'We Sell') {
            currenciesListAction1 = this.state.customerPaymentCurrencies || []
            currenciesListAction2 = this.state.customerSettlementCurrencies || []
        } else {
            currenciesListAction2 = this.state.customerPaymentCurrencies || []
            currenciesListAction1 = this.state.customerSettlementCurrencies || []
        }

        var leftView;
        leftView = (
            <div className="uk-width-expand@m uk-form-stacked">
                <div className="uk-width-1-1 uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-bank-status">Type</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className="uk-select" getValue={this.handleUpdateType} listHasPlaceholder={false} data={this.state["Rate Watch Type"]} id="order-type" objValue={{value: this.state.rateWatchType}}/>
                    </div>
                </div>
                <form className="uk-form-horizontal">
                    <div className="uk-grid">
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                            <label className="uk-form-label">Type of Rate</label>
                            <div className="uk-form-controls ">
                                <div className="uk-grid-small uk-child-width-auto uk-grid">
                                    <label><input value="Single Rate" className="uk-checkbox" type="radio" name="typeTrade" onChange={() => this.handleUpdateCheckSingleRate(true)} checked={this.state.checkSingleRate}/> Single Rate</label>
                                    <label><input value="OCO" className="uk-checkbox" type="radio" name="typeTrade" onChange={() => this.handleUpdateCheckSingleRate(false)} checked={!this.state.checkSingleRate}/> OCO</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <form className="uk-form-horizontal">
                    <div className="uk-grid">
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                            <label className="uk-form-label">Good Till Cancelled</label>
                            <div className="uk-form-controls">
                                <div className="uk-grid-small uk-child-width-auto uk-grid">
                                    <label><input value={true} className="uk-checkbox" type="radio" name="goodTillCancelled" onChange={() => this.handleUpdateGoodTillCancelled(true)} checked={this.state.goodTillCancelled}/> Yes</label>
                                    <label><input value={false} className="uk-checkbox" type="radio" name="goodTillCancelled" onChange={() => this.handleUpdateGoodTillCancelled(false)} checked={!this.state.goodTillCancelled}/> No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="uk-width-1-1">
                    <label className="uk-form-label" htmlFor="form-bank-status">Expires On</label>
                    <DatePicker className={this.formatValidInput(this.state.validations.expirationDate)} value={this.state.expirationDate} onDayChange={this.handleUpdateExpirationDate} disabled={expiredOnDisabled}/>
                </div>
                                
                <div>
                    <label className="uk-form-label" htmlFor="form-action">Action</label>
                    <div uk-grid="true">
                        <div className="uk-width-1-3">
                            <select className="uk-select" value={this.state.buysOrSells1} onChange={this.handleUpdateBuysOrSell1}>
                                <option value="Buys">Client Buys</option>
                                <option value="Sells">Client Sells</option>
                            </select>
                        </div>
                        <div className="uk-width-1-3">
                            <NumberInput className={this.formatValidInput(this.state.validations.amount)} placeholder="0" id="amount" type="Currency" scale={getScaleByCurrency(this.state.currency1)} value={this.state.amount} onChange={this.handleUpdateAmount.bind(this)}/>
                        </div>
                        <div className="uk-width-1-3 uk-form-controls">
                            <DynamicSelect id="form-currency-1"
                                className={this.formatValidSelect(this.state.validations.currency1)}
                                getValue={this.getCurrency1} 
                                listHasPlaceholder={false}
                                placeholder="Select a Currency..."
                                objValue={{value: this.state.currency1}} 
                                data={currenciesListAction1}/>
                        </div>
                    </div>
                </div>

                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-3">
                            <select className="uk-select" value={this.state.buysOrSells2} disabled={true}>
                                <option value="Sells">Client Sells</option>
                                <option value="Buys">Client Buys</option>
                            </select>
                        </div>
                        <div className="uk-width-1-3"> 
                        </div>
                        <div className="uk-width-1-3 uk-form-controls">
                            <DynamicSelect id="form-currency-2"
                                className={this.formatValidSelect(this.state.validations.currency2)}
                                getValue={this.getCurrency2} 
                                listHasPlaceholder={false} 
                                placeholder="Select a Currency..."
                                objValue={{value: this.state.currency2}} 
                                data={currenciesListAction2}/>
                        </div>
                    </div>
                </div>

            </div>
        );

        var rightView;
        rightView = (
            <div className="uk-width-expand@m uk-form-stacked">
                {/*<div uk-grid="true">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Order Board ID</label>
                        {this.state.rateWatchID}
                    </div>
                    <div className="uk-width-1-2">                                           
                        <div className="uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-status">Status</label>
                            <DynamicSelect className="uk-select" 
                                getValue={this.handleUpdateStatus} 
                                listHasPlaceholder={false} 
                                data={this.state["Rate Watch Status Names"]} 
                                id="order-status" 
                                objValue={{value: this.state.rateWatchStatus}}
                                disabled={true}/>
                        </div>
                    </div>
                </div>*/}

                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-bank-status">Execution Date</label>
                    <div className="" style={style.inputStyle}>
                         <DatePicker className="uk-input" value={this.state.executionDate} onDayChange={this.handleExecutionDate} disabled={true}/>
                    </div>
                </div>

                <div uk-grid="true">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-customer-contact">Notification</label>
                        <div className="uk-form-controls">                        
                            <DynamicSelect className={this.formatValidSelect(this.state.validations.notificationMethod)} getValue={this.handleUpdateNotificationMethod} listHasPlaceholder={true} data={this.state["Notification Method"]} id="notification" objValue={{value: this.state.notificationMethod}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin"> 
                        <label className="uk-form-label" ><input className="uk-checkbox" type="checkbox" checked={this.state.notifyCustomer} value={this.state.notifyCustomer} onChange={this.handleOnCheckNotify}/> Notify Customer</label>
                    </div>
                </div>

                <form className="uk-form-horizontal">
                    <div className="uk-grid">
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-top">
                            <label className="uk-form-label">Deal Type</label>
                            <div className="uk-form-controls">
                                <div className="uk-grid-small uk-child-width-auto uk-grid uk-margin">
                                    <label><input value="Spot" className="uk-checkbox" type="radio" name="dealType" onChange={() => this.handleUpdateDealType(1)} checked={this.state.dealType === 1}/> Spot</label>
                                    <label><input value="Forward" className="uk-checkbox" type="radio" name="dealType" onChange={() => this.handleUpdateDealType(4)} checked={this.state.dealType !== 1}/> Forward</label>
                                </div>
                            </div>
                        </div>     
                    </div>   
                </form> 

                <div>
                    <div uk-grid="true">
                        <div className="uk-width-1-6">
                            <input className="uk-checkbox" type="radio" name="forwardType" checked={this.state.forwardType === 'Days'} onChange={() => this.handleUpdateForwardType('Days')} disabled={dealType===1}/>
                        </div>
                        <div className="uk-width-1-6">
                            Open For
                        </div>
                        <div className="uk-width-1-6">
                            <NumberInput className="uk-input" placeholder="0" id="openFor" type="Integer" value={this.state.openFor} onChange={this.handleUpdateOpenFor} disabled={dealType===1}/>
                        </div>
                    </div>
                </div>
                
                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6">
                            <input className="uk-checkbox" type="radio" name="forwardType" checked={this.state.forwardType === 'Dates'} onChange={() => this.handleUpdateForwardType('Dates')} disabled={dealType===1}/>
                        </div>
                        <div className="uk-width-1-6">
                            Near Date
                        </div>
                        <div className="uk-width-1-6">
                            <DatePicker className={this.formatValidInput(this.state.validations.nearDate)} value={this.state.nearDate} endDate={this.state.farDate} onDayChange={this.handleUpdateNearDate} disabled={dealType===1}/>
                        </div>
                        <div className="uk-width-1-6">
                            Far Date
                        </div>
                        <div className="uk-width-1-6">
                            <DatePicker className={this.formatValidInput(this.state.validations.farDate)} value={this.state.farDate} startDate={this.state.nearDate} onDayChange={this.handleUpdateFarDate} disabled={dealType===1}/>
                        </div>
                    </div>
                </div>

            </div>
        );

        return(
            <div className="uk-form-stacked">
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-1-3">
                            <h3>Customer: {this.props.objCustomer["Customer Name"]}</h3>
                            <h3>Contact: {this.props.objContact.Name}</h3>
                        </div>
                        <div className="uk-width-1-3">
                        </div>
                        <div className="uk-width-1-3">
                            <table border="1" bordercolor="#E5DBD8"  className="uk-align-right" cellSpacing="0" cellPadding="5">
                            <thead>
                                <tr >
                                    <th align="center">Order Board ID</th>
                                    <th align="center">Status</th>
                                    {this.state.dealHeaderID > 0 && <th align="center">Executed Deal Header</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td align="middle">{this.state.rateWatchID}</td>
                                    <td align="middle">{this.state.rateWatchStatus}</td>
                                    {this.state.dealHeaderID > 0 && <td align="middle">{this.state.dealHeaderID}</td>}

                                </tr>
                            </tbody>
                            </table>                          
                        </div>                       
                    </div>
                </div>

                <h3 className="uk-heading-divider" style={style.titleStyle}>Order Board Information</h3>
                <div>
                    <div uk-grid="true">
                        {leftView}
                        <div className="uk-width-auto@m">
                            <hr className="uk-hr-vertical uk-divider-vertical"></hr>
                        </div>
                        {rightView}
                    </div>
                </div> 

                <table>
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        {rows}
                        {rows1}
                    </tbody>
                </table>
                
                <div className="uk-margin-top">
                    <button className="uk-button uk-button-green" onClick={this.handleNewClick}>New</button>
                    {saveButton}
                    {duplicateButton}
                    {voidButton}
                    {reactivateButton}
                </div>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
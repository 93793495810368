export const LookUpTables = {
    FeeTypes: "Fee Types",
    IncomingFundStatus: "Incoming Fund Status",
    IncomingInstruments: "Incoming Instruments",
    Languages: "Languages",    
    PaymentDeliveryMethod: "Payment Delivery Method",
    PaymentStatus: "Payment Status",
    OutgoingFundStatus: "Outgoing Fund Status",
    SettlementDeliveryMethod: "Settlement Delivery Method"
}

import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters, genericCallWithBodyAndResponseDecoded } from './Networking';

export async function getNostroBankAccounts(CustomerId){
    try {
        let params = {
            'CustomerId': CustomerId
        }
        let response = await genericGetWithParameters('Bank/GetNostroAccountsBanksMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPersonBankAccounts(PersonID, Page, Items){
    try {
        let params = {
            'PersonID': PersonID,
            'Page': Page,
            'Items': Items
        }
        let response = await genericGetWithParameters('BankAccount/GetPersonAccounts',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBankAccountsByPerson(personID) {
    try {
        let params = {
            PersonID: personID
        }
        let response = await genericGetWithParameters('BankAccount/GetBankAccountsByPerson_min', params)
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function getBankAccountsByAccountID(bankAccountID) {
    try {
        let params = {
            BankAccountID: bankAccountID
        }
        let response = await genericGetWithParameters('BankAccount/GetBankAccountsByAccountID', params)
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function getBeneAccountByCustomerID(customerID, currencyID){
    try {
        let params = {
            CustomerID: customerID,
            CurrencyID: currencyID
        }
        let response = await genericGetWithParameters('BankAccount/GetBeneficiaryAccountByCustomerId_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBankAccount(model) {
    var method = '';
    var apiName = '';
    if (model.localAccount.BankAccountID === 0 || model.localAccount.BankAccountID === '') {
        method = 'POST';
        apiName = 'BankAccount/Post'

    } else {
        method = 'PUT';
        apiName = 'BankAccount/Put'
    }
    
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function deleteBankAccount(id){
    try{
        let params = {
            'BankAccountID': id
        };
        let response = await genericDeleteWithParameters('BankAccount/Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNostroBankAccountsForFunds(currencyID, customerID, forTradingScreen){
    try {
        let params = {
            'CurrencyID': currencyID,
            CustomerID: customerID,
            ForTradingScreen: forTradingScreen
        }
        let response = await genericGetWithParameters('BankAccount/GetNostroAccountsByCcy_ForFunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNostroAccountsByCurrencyMin(currencyID, isCustomerUsingNostroSettlementAccounts, forTradeScreen){
    try {
        let params = {
            CurrencyID: currencyID,
            IsCustomerUsingNostroSettlementAccounts: isCustomerUsingNostroSettlementAccounts,
            ForTradeScreen: forTradeScreen
        }
        let response = await genericGetWithParameters('BankAccount/GetNostroAccountsByCurrencyMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getGLAccounts() {
    try {
        let params = {
        }
        let response = await genericGetWithParameters('BankAccount/GetGLAccountsMin', params)
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function saveNostroAccount(model){

    try{
        let response = await genericCallWithBody('POST','BankAccount/SaveNostroAccount',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBankAccountBalanceReconciliation(nostroBankAccountId, clearingDate, toClearingDate){
    try {
        let params = {
            NostroBankAccountId: nostroBankAccountId, ClearingDate: clearingDate, ToClearingDate: toClearingDate
        }

        let response = await genericGetWithParameters('BankAccount/GetBankAccountBalanceReconciliation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBankReconciliation(processDate, nostroAccountId, totalCredits, totalDebits, totalEndingBalances, expectedBalance, userId, applicationDate, reconciliationData) {

    let method = 'POST';
    let apiName = 'BankAccount/SaveBankReconciliation';

    let model = {
        ProcessDate: processDate,
        NostroAccountID: nostroAccountId,
        TotalCredits: totalCredits,
        TotalDebits: totalDebits,
        TotalEndingBalances: totalEndingBalances,
        ExpectedBalance: expectedBalance,
        UserID: userId,
        ApplicationDate: applicationDate,
        ReconciliationData: reconciliationData
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getNostroAccounts(page, items){
    try {
        const params = {
            'page': page,
            'items': items
        }
        const response = await genericGetWithParameters('BankAccount/GetNostroAccounts', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateIBAN(iban){

    let model = {
        IBAN: iban,
        UpdatedBy: localStorage.getItem('UserID')
    }
    try{
        let response = await genericCallWithBody('POST','BankAccount/ValidateIBAN',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNostroBalances(date, bankID) {
    try {
        let params = {
            Date: date,
            BankID: bankID
        }
        
        let response = await genericGetWithParameters('BankAccount/GetNostroBalances', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function UpdateApiBalances(model) {
    try {
        var method = 'POST';
        var apiName = 'BankAccount/UpdateApiBalances'        
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveActualEndingBalance(model) {
    var method = 'POST';
    var apiName = 'BankAccount/SaveActualEndingBalance'
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function SaveNostroBalances(model) {
    var method = 'POST';
    var apiName = 'BankAccount/SaveNostroBalances'
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export const getVirtualBankAccountsByCurrencyID = async (customerID, currencyID) => {
    try {
        const params = {
            'customerID': customerID,
            'currencyID': currencyID
        };
        const response = await genericGetWithParameters('BankAccount/GetVirtualBankAccountsByCurrencyID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export async function synchronizeBenesWithInterface(customerId, externalInterfaceName) {
    
    let method = 'POST';
    let apiName = 'BankAccount/SynchronizeBenesWithInterface';

    let model = {
        CustomerID: customerId,
        ExternalInterfaceName: externalInterfaceName
    }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export const sendBankAcountToInterface = async (externalInterface, bankAccountId) => {
    try {
        let model = {
            ExternalInterface: externalInterface,
            BeneficiaryID: 0,
            BankAcountID: bankAccountId
        }
        const response = await genericCallWithBody('POST', 'BankAccount/SendBankAcountToInterface', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import { searchInGlobalWatchLists } from '../../../networking/NetworkingGlobalWatchListSearch';
import AlertBox from '../AlertBox';
import NewDynamicTable from '../NewDynamicTable';
import { DialogTitle } from './AttachmentsDialog';

export default class GlobalWatchListsResultDialog extends React.Component {
    state = {
        nameToSearch: '',
        resultList: [],
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        entityID: 0,
        entityType: 'Customer'
    };

    componentDidMount() {
        this.setState({
            nameToSearch: this.props.nameToSearch,
            resultList: this.props.resultList,
            entityID: this.props.entityID,
            entityType: this.props.entityType
        });
    }

    search = async () => {
        const json = await searchInGlobalWatchLists(this.state.nameToSearch, this.state.entityType, this.state.entityID);
        console.log(json);
        if (json != null && json.MatchedWords.length === 0 && json.SourceNames.length === 0) {
            this.setState({ showAlert: true, alertTitle: 'Info', alertMessage: 'No results found.', resultList: [] });
        } else {
            this.setState({ showAlert: true, alertTitle: 'Warning', alertMessage: 'Found Customer in Watch Lists', resultList: json.SourceNames });
        }
    };

    handleChange = (event) => {
        this.setState({ nameToSearch: event.target.value });
    };

    closeAlert = () => {
        this.setState({ showAlert: false });
    };

    exit = () => {
        this.props.onClose();
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.exit} fullWidth={true} maxWidth="xl">
                <DialogTitle id="searchgloballists">
                    <div className="">
                        <h4 className="">
                            Global Watch List<span id="label-watch-list"></span>
                        </h4>
                    </div>
                    <div className="uk-text-right" style={{ marginTop: '-45px' }}>
                        <button className="uk-button uk-button-green" onClick={this.exit}>
                            {' '}
                            Exit
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="uk-grid uk-margin" style={{ marginTop: '0px' }}>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Name to Search</label>
                            <div className="">
                                <input id="name-to-search" className="uk-input" onChange={this.handleChange} value={this.state.nameToSearch} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked" style={{ marginTop: '10px' }}>
                            <button className="uk-button uk-button-green" onClick={this.search}>
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <NewDynamicTable
                            id="ndt-watchlist-results"
                            data={this.state.resultList}
                            newSearch={true}
                            usePaginator={true}
                            enableSortByHeader={true}
                            useExportButton={true}
                            tableName="watchlist"
                        />
                    </div>
                    <AlertBox
                        id="alertbox-result-watchlist"
                        open={this.state.showAlert}
                        onClose={this.closeAlert}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        type="Ok"
                        okClick={this.closeAlert}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}
import { genericGetWithParameters } from './Networking';

export const getArchivedCustomers = async (textToSearch) => {
    try {
        const params = {
            'TextToSearch': textToSearch
        };
        const response = await genericGetWithParameters('Archive/SearchCustomers', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const searchCustomerArchivedData = async (customerId) => {
    try {
        const params = {
            'CustomerID': customerId
        };
        const response = await genericGetWithParameters('Archive/SearchCustomerArchivedData', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getArchivedDeals = async (dealHeaderId) => {
    try {
        const params = {
            'DealHeaderID': dealHeaderId
        };
        const response = await genericGetWithParameters('Archive/SearchDeals', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
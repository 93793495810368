import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as mime from 'mime-types';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { LookUpTables } from '../../constants/LookUpTables';
import { generateConfirmationPDF, sendOutgoingFundReportByEmail } from '../../networking/NetworkingDeals';
import { notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';






const DocumentTypes = {
    WIRETRANSFER:'WireTransfer',
    CONFIRMATION: 'Confirmation'
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  
export default class OutgoingFundsConfirmation extends React.Component {

    state = {
        dealHeaderInfo: {},
        language: "",
        email: "",
        additionalEmail: "",
        fax: "",
        additionalFax: "",
        //
        dealHeaderId: 0,
        title: 'Payment Confirmation',
        documentType: DocumentTypes.CONFIRMATION,
        //
        emailChecked: false,
        additionalEmailChecked: false,
        faxChecked: false,        
        additionalFaxChecked: false,
        //
        emailDisabled: true,
        additionalEmailDisabled: true,
        faxDisabled: true,
        additionalFaxDisabled: true,
        //
        listLanguages: [],
        //
        loading: false,
        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        //
        showAlertEmail: false,
        alertMessageEmail: '',
        alertTitleEmail: '',
        //
        //preview
        currentFileName         : '',
        currentFileURLList      : [],
        openPreviewFile         : false,
        fullWidthPreviewFile     : true,
        maxWidthPreviewFile      : 'md',

        validations: {
            language: true,
            email: true,
            additionalEmail: true,
            fax: true,
            additionalFax: true
        }
    }

    componentDidUpdate(prevProps)
    {
        if(this.props !== prevProps)
        {
            this.getValues();
        }
    }

    getValues = () =>
    {
        if (typeof this.props.lookUpTables !== undefined) {
            this.setState({dealHeaderId: this.props.dealHeaderId},
                () => {
                    let lookUpTables = this.props.lookUpTables

                    let languages = lookUpTables.filter(x => x.LookUpTableDescription === LookUpTables.Languages)[0];

                    if(languages !== undefined)
                    {
                        languages.lookUpTableDetails.forEach(element => {
                            element.value = element.LookUpTableDetail;
                            element.name = element.LookUpTableDetail;
                        });
                        this.setState({listLanguages: languages.lookUpTableDetails});
                    }
                })
        }
        if (typeof this.props.dealHeaderInfo !== undefined) {
            this.setState({dealHeaderInfo: this.props.dealHeaderInfo}, () => {
                //default values:
                this.setState({
                    email: this.state.dealHeaderInfo.Email,
                    emailChecked: this.state.dealHeaderInfo.EmailConfirmations,
                    fax: this.state.dealHeaderInfo.OfficeFax,
                    faxChecked: this.state.dealHeaderInfo.FaxConfirmations,
                    language: this.state.dealHeaderInfo.PreferredLanguage,
                    emailDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.Email),
                    faxDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.OfficeFax)
                })
            })
        }
    }

    componentDidMount() {
        console.log(this.props);
        this.getValues();
    }

    handleBackLink() {
        this.setState({}, () => this.props.backLink());
    }

    //services
    showConfirmation = () => {
        this.setState({loading: true}, () => {
            generateConfirmationPDF(this.props.outgoingFundId, "WireTransfer", this.state.language, ).then((value) => {
                this.setState({loading: false})
                if (value !== undefined) {
                    let fileName = 'Payment Confirmation ' + this.state.dealHeaderId + '.pdf';
                    var byteCharacters = atob(value.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var bytes = new Uint8Array(byteNumbers);
                    var blob = new Blob([bytes], {
                        type: mime.lookup(fileName)
                    });
                    
                    /*var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();*/

                    let fileList = [{
                        uri     : window.URL.createObjectURL(blob),
                        fileType: 'pdf',
                        fileName: fileName
                    }];

                    this.setState({
                        currentFileName     : fileName,
                        currentFileURLList  : fileList,
                        openPreviewFile     : true
                    });
                }
            })
        })        
    }

    yesToAlertEmail = () => {
        this.sendConfirmationDirect()
    }

    sendConfirmation = () => {
 
        if (this.validateEmailBelongsToCustomer()) {
            this.sendConfirmationDirect()
        } else {
            this.setState({
                showAlertEmail: true,
                alertTitleEmail: 'Warning',
                alertMessageEmail: 'One or more emails do not belong to this customer. Do you still want to send these emails?'
            });
        }
    }

    sendConfirmationDirect = () => {
        this.setState({
            showAlertEmail: false, alertTitleEmail: '', alertMessageEmail: ''
        })

        if (!this.validateRequiredFields()) {
            return
        }

        if (this.state.emailChecked && this.state.additionalEmailChecked && this.state.faxChecked && this.state.additionalFaxChecked) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please select who to send the confirmation to.'
            });
            return
        }

        let sendOutgoingFundReportByEmailRequest = {
            DealHeaderID: this.props.outgoingFundId,
            Language: this.state.language
        }

        let DealMailInfo = []

        if (this.state.emailChecked) {
            DealMailInfo.push({MainInfo: this.state.email, DocType: DocumentTypes.WIRETRANSFER})
        }
        
        if (this.state.additionalEmailChecked) {
            DealMailInfo.push({MainInfo: this.state.additionalEmail, DocType: DocumentTypes.WIRETRANSFER})
        }

        if (this.state.faxChecked) {
            DealMailInfo.push({MainInfo: this.state.fax, DocType: DocumentTypes.WIRETRANSFER})
        }
        
        if (this.state.additionalFaxChecked) {
            DealMailInfo.push({MainInfo: this.state.additionalFax, DocType: DocumentTypes.WIRETRANSFER})
        }

        sendOutgoingFundReportByEmailRequest.DealMailInfo = DealMailInfo;        

        this.setState({loading: true}, () => {
            sendOutgoingFundReportByEmail(sendOutgoingFundReportByEmailRequest).then((response) => {
                console.log(response)
                this.setState({loading: false})
                if (response.httpStatusCode !== 200) {
                    this.setState({
                        showAlert: true,
                        alertMessage: 'An error occurred while sending the report. Please try again.',
                        alertTitle: 'Error'
                    });
                } else {
                    if (typeof response.error !== undefined && response.error !== '') {
                        this.setState({
                            showAlert: true,
                            alertMessage: response.error,
                            alertTitle: 'Error'
                        })
                    } else {
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Email confirmation sent.'
                        });
                    }                    
                }
            })
        })
    }

    //validations
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }


    stringEmptyOrNull(str){
        return (str == null || str === '')
    }

    validateEmailBelongsToCustomer() {
        if (typeof this.props.dealHeaderInfo.EmailsForConfirmations !== undefined && this.props.dealHeaderInfo.EmailsForConfirmations.length > 0) {
            let customerContactEmails = this.props.dealHeaderInfo.EmailsForConfirmations.map(x => x.Email)
            let emails = []

            if (this.state.emailChecked && !this.stringEmptyOrNull(this.state.email)) {
                emails.push(this.state.email)
            }
    
            if (this.state.additionalEmailChecked && !this.stringEmptyOrNull(this.state.additionalEmail)) {
                emails.push(this.state.additionalEmail)
            }
    
            if (this.state.faxChecked && !this.stringEmptyOrNull(this.state.fax)) {
                emails.push(this.state.fax)
            }
    
            if (this.state.additionalFaxChecked && !this.stringEmptyOrNull(this.state.additionalFax)) {
                emails.push(this.state.additionalFax)
            }
            let invalidEmails = emails.filter(x => !customerContactEmails.includes(x))
            if (invalidEmails.length > 0) {
                return false;
            }
        }
        return true
    }

    validateRequiredFields() {
        let valid = true;
        let validLanguage = true, validEmail = true, validAdditionalEmail = true, validFax = true, validAdditionalFax=true;

        if (this.stringEmptyOrNull(this.state.language) || this.state.language.includes('Select')) {
            validLanguage = false;
        }
        
        if (this.state.emailChecked && this.stringEmptyOrNull(this.state.email)) {
            validEmail = false;
        }

        if (this.state.additionalEmailChecked && this.stringEmptyOrNull(this.state.additionalEmail)) {
            validAdditionalEmail = false;
        }

        if (this.state.faxChecked && this.stringEmptyOrNull(this.state.fax)) {
            validFax = false;
        }

        if (this.state.additionalFaxChecked && this.stringEmptyOrNull(this.state.additionalFax)) {
            validAdditionalFax = false;
        }

        valid = validLanguage && validEmail && validAdditionalEmail && validFax && validAdditionalFax;

        this.setState({
            validations: {
                ...this.state.validations,
                language: validLanguage,
                email: validEmail,
                additionalEmail: validAdditionalEmail,
                fax: validFax,
                additionalFax: validAdditionalFax
            }
        })
        return valid;
    }

    //events

    handleLanguage(value){
        if (value != null & value.value != null) {
            this.setState({language: value !== null ? value.value : ''});
        }
    }

    handleEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({emailChecked: checked})
    }

    handleEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleAdditionalEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({additionalEmailDisabled: !checked, additionalEmail: '', additionalEmailChecked: checked})
    }

    handleAdditionalEmail = (event) => {
        this.setState({additionalEmail: event.target.value});
    }

    handleFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({faxChecked: checked})
    }

    handleFax = (event) => {
        this.setState({fax: event.target.value});
    }

    handleAdditionalFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({additionalFaxDisabled: !checked, additionalFax: '', additionalFaxChecked: checked})
    }

    handleAdditionalFax = (event) => {
        this.setState({additionalFax: event.target.value});
    }

    //alerts
    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''})
    }

    closeAlertEmail = () => {
        this.setState({showAlertEmail: false, alertMessageEmail: '', alertTitleEmail: ''})
    }

    onClosePreviewFile = () =>
    {
        this.setState({
            currentFileName     : '',
            currentFileURLList  : [],
            openPreviewFile     : false,
        })
    }

    render() { 
        return(
            <Dialog 
                open        ={this.props.open} 
                onClose     ={this.props.backLink}
                fullWidth   ={true}
                maxWidth    ={"sm"}
            >
                <DialogTitle 
                    id="customized-dialog-ofc-title"
                    onClose={this.props.backLink}
                >
                    {this.state.title}
                </DialogTitle>
                <DialogContent dividers style={{paddingLeft:24}} >
                    <div className="uk-grid">
                        {/*Line1*/}
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Language:</label>
                            <DynamicSelect className={this.formatValidSelect(this.state.validations.language)} id="form-dealconfirmation-language" data={this.state.listLanguages} 
                                objValue={{value: this.state.language}} getValue={this.handleLanguage.bind(this)} />
                        </div>
                        <div className="uk-width-1-2"> </div>
                        
                        {/*Line2*/}
                        <div className="uk-width-1-2">                        
                            <input className="uk-checkbox  uk-margin-right" type="checkbox" id="form-dealconfirmation-emailcheck" onChange={this.handleEmailChecked} 
                                checked={this.state.emailChecked}/>
                            <label className="uk-form-label" >Email</label>
                            <input className={this.formatValidInput(this.state.validations.email)} type="text" id="form-dealconfirmation-email" value={this.state.email} 
                            onChange={this.handleEmail} disabled={this.state.emailDisabled}/>
                        </div>

                        <div className="uk-width-1-2">                        
                            <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalEmailCheck" onChange={this.handleAdditionalEmailChecked}/>
                            <label className="uk-form-label" >Additional Email</label>
                            <input className={this.formatValidInput(this.state.validations.additionalEmail)} type="text" id="form-dealconfirmation-additionalemail" value={this.state.additionalEmail} 
                            onChange={this.handleAdditionalEmail} disabled={this.state.additionalEmailDisabled}/>
                        </div>

                        {/*Line3*/}
                        <div className="uk-width-1-2">                        
                            <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-faxcheck" onChange={this.handleFaxChecked}
                                checked={this.state.faxChecked}/>
                            <label className="uk-form-label" >Fax</label>
                            <input className={this.formatValidInput(this.state.validations.fax)} type="text" id="form-dealconfirmation-fax" value={this.state.fax} 
                            onChange={this.handleFax} disabled={this.state.emailDisabled}/>
                        </div>

                        <div className="uk-width-1-2">                     
                            <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalfaxcheck" onChange={this.handleAdditionalFaxChecked}/>
                            <label className="uk-form-label" >Additional Fax</label>
                            <input className={this.formatValidInput(this.state.validations.additionalFax)} type="text" id="form-dealconfirmation-additionalfax" value={this.state.additionalFax} 
                            onChange={this.handleAdditionalFax} disabled={this.state.additionalFaxDisabled}/>
                        </div>

                        {/*Line4*/}
                        <div className="uk-width-1-1" style={{paddingTop: 20, marginBottom:0}}>
                            <button className="uk-button uk-button-default uk-button-green" type="button" onClick={this.sendConfirmation}>Send Confirmation</button>
                            <button className="uk-button uk-button-green" type="button" onClick={this.showConfirmation}>View PDF</button>
                            <button 
                                    className="uk-button uk-button-green uk-float-right" 
                                    type="button" 
                                    onClick={this.props.backLink}
                                >close</button>
                        </div>
                    </div>
                </DialogContent>
                <Dialog 
                        open        = {this.state.openPreviewFile} 
                        onClose     = {this.onClosePreviewFile}
                        fullWidth   = {this.state.fullWidthPreviewFile}
                        maxWidth    = {this.state.maxWidthPreviewFile}
                    >
                        <DialogTitle id="current-attachment-dialog-title" onClose={this.onClosePreviewFile}>
                            {this.state.currentFileName}
                        </DialogTitle>
                        <DialogContent dividers style={{padding: 0}}>      
                           
                                {/*<iframe 
                                    src         = {this.state.currentFileURL}
                                    scrolling   = 'yes'
                                    style       = {{
                                        border      : 'none',
                                        width       : '100%',
                                        height      : '400px',
                                        textAlign   : 'center',
                                    }}
                                >
                                </iframe>*/}
                            <DocViewer
                                pluginRenderers = {DocViewerRenderers} 
                                documents       = {this.state.currentFileURLList}
                                config          = {{
                                    header: {
                                        disableHeader   : true,
                                        disableFileName : true,
                                        retainURLParams : false
                                    }
                                }} 
                            />
                            
                        </DialogContent>
                    </Dialog>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} type="Ok" title={this.state.alertTitle} 
                    message={this.state.alertMessage} okClick={this.closeAlert}/>

                <AlertBox open={this.state.showAlertEmail} onClose={this.closeAlertEmail} type="Yes/No" title={this.state.alertTitleEmail} 
                    message={this.state.alertMessageEmail} noClick={this.closeAlertEmail} yesClick={this.sendConfirmationDirect}/>

                <LoadingBox loading={this.state.loading}/>
            </Dialog>
        );        
    }
}
import React from 'react';
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import { getStyle} from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTable} from '../../../../networking/Networking';
import { getRiskScoreRanges } from '../../../../networking/NetworkingCustomers';
import { getRiskScoreOwnerDetails, getOwnerRiskScore } from '../../../../networking/NetworkingOwners';
import DatePicker from '../../../shared/DatePicker';
import { formatDate, formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';
import { validate } from '../../../../helpers/ValidateHelper';
import {GlobalSystemParametersNames} from '../../../../constants/GlobalSystemParametersNames'
import PhoneInput from 'react-phone-input-2';

var style = getStyle();

export default class AddOwnerInformationForm extends React.Component {

    state = {
        owners: {
            notFirstLoad: false,
            salutation: '',
            firstName: '',
            lastName: '',
            middleName: '',
            shortName: '',
            ownerType: '',
            status: '',
            companyName: '',
            contactEmail: '',
            contactPhone:'',
            bypassValidation: '',
            expiryByPassDate: '',
            ownerRiskScore: '',
            ownerID: '',
            updateToken: 0,
            isEmailValid: '',
            personID:'',
            errors: []
        },
        validations: {
            companyName: true,
            shortName: true,
            firstName: true,
            lastName: true,
            ownerType: true,
            status: true
        },
        showRiskCalculation: false,
        riskScoreDetails: [],
        dropdowns: {
            traders: [],
            "Owner Status": [],
            "Salutation": [],
            "Owner Type": []
        },
        lookUpTables: [
            "Owner Status",
            "Salutation",
            "Owner Type"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        },
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency)
    }

    componentDidMount() {
        getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({ dropdowns: { ...this.state.dropdowns, traders: json.traders } });
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                    }
                }
            });

        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreOwnerDetails(0).then(
                    (details) => {
                        if (details !== undefined) {
                            getOwnerRiskScore(0).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Owner"); // cambiar por Owner
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        this.setState({
                                            riskScore: riskScore,
                                            riskScoreDetails: details.riskScoreDetails
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
        this.props.isValid(this.validateRequiredInputs());
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
    }

    clearFields() {
        this.setState({
            owners: {
                ...this.state.owners,
                notFirstLoad: false,
                salutation: '',
                firstName: '',
                lastName: '',
                middleName: '',
                shortName: '',
                ownerType: '',
                status: '',
                companyName: '',
                contactEmail: '',
                contactPhone:'',
                bypassValidation: '',
                expiryByPassDate: '',
                ownerRiskScore: '',
                ownerID: '',
                updateToken: 0,
                isEmailValid: '',
                errors: []
            },
            validations: {
                companyName: true,
                shortName: true,
                firstName: true,
                lastName: true,
                ownerType: true,
                status: true,
            },
            showRiskCalculation: false,
            riskScoreDetails: [],
            riskScore: {
                Color: "inherit",
                Description: '',
                "Entity Type": '',
                "Range From": '',
                "Range To": '',
                "Score Range ID": '',
                "Status": '',
                "User Editable": false
            }
        }, () => this.props.isValid(this.validateRequiredInputs()))
    }
   
    toggleShowRiskCalculation() {
        this.setState({ showRiskCalculation: !this.state.showRiskCalculation });
    }

    handleUpdateCustomerType(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    owners: { ...this.state.owners, ownerType: value.value },
                    validations: { ...this.state.validations, ownerType: true },
                }, () => {
                    if (this.validateCustomerType()) {
                        this.props.objFilterOwnerInfo(this.state.owners);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                owners: { ...this.state.owners, ownerType: '' }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        }
    }
    validateCustomerType() {
        if (this.state.owners.ownerType === "select" || this.state.owners.ownerType === '' || this.state.owners.ownerType === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateStatus(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    owners: { ...this.state.owners, status: value.value },
                    validations: { ...this.state.validations, status: true }
                }, () => {
                    if (this.validateStatus()) {
                        this.props.objFilterOwnerInfo(this.state.owners);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                owners: { ...this.state.owners, status: '' }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        }
    }
    validateStatus() {
        if (this.state.owners.ownerType === "select" || this.state.owners.ownerType === '' || this.state.owners.ownerType === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateCompanyName(event) {
        this.setState({
            owners: { ...this.state.owners, companyName: event.target.value },
            validations: { ...this.state.validations, companyName: true }
        },
            () => {
                if (this.validateCompanyName()) {
                    this.props.objFilterOwnerInfo(this.state.owners);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
            });
    }
    validateCompanyName() {
        if (this.state.owners.companyName === '') {
            return false;
        }
        return true;
    }
    handleUpdateShortName(event) {
        this.setState({
            owners: { ...this.state.owners, shortName: event.target.value },
            validations: { ...this.state.validations, shortName: true }
        }, () => {
            if (this.validateRequiredInputs()) {
                this.props.objFilterOwnerInfo(this.state.owners);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateShortName() {
        if (this.state.owners.shortName === '') {
            return false;
        }
        return true;
    }

    handleUpdateContactPhone = (phone) =>  {
        this.setState({ owners: { ...this.state.owners, contactPhone: phone} }, () => this.props.objFilterOwnerInfo(this.state.owners));
    }
    handleUpdateContactEmail(event) {
        this.setState({ owners: { ...this.state.owners, contactEmail: event.target.value } },
            () => {
                if (this.validateEmail()) {
                    this.props.objFilterOwnerInfo(this.state.owners)
                    this.isEmailValid(true);
                } else {
                    this.props.objFilterOwnerInfo(this.state.owners)
                    this.isEmailValid(false);
                }
            });
    }

    isEmailValid(validator) {
        if (validator) {
            this.setState({ owners: { ...this.state.owners, isEmailValid: true } }, () => this.props.objFilterOwnerInfo(this.state.owners));
        } else {
            this.setState({ owners: { ...this.state.owners, isEmailValid: false } }, () => this.props.objFilterOwnerInfo(this.state.owners));
        }
    }
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.state.owners.contactEmail != null) {
            if (!this.state.owners.contactEmail.match(regex)) {
                return false;
            }
        }
        return true;
    }
    
    handleOnCheck(event) {
        console.log(event.target.checked);
        this.setState({ owners: { ...this.state.owners, bypassValidation: event.target.checked } }, () => this.props.objFilterOwnerInfo(this.state.owners));
    }
    handleUpdateOwnerRiskScore(event) {
        this.setState({ owners: { ...this.state.owners, ownerRiskScore: event.target.value } }, () => this.props.objFilterOwnerInfo(this.state.owners));
    }

    handleUpdateExpiryByPassDate(date) {
        if (date !== undefined) {
            var dateEx = formatDate(date)
            this.setState({
                owners: { ...this.state.owners, expiryByPassDate: dateEx }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        } else {
            this.setState({
                owners: { ...this.state.owners, expiryByPassDate: '' }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        }
    }
    handleUpdateFirstName(event) {
        this.setState({
            owners: { ...this.state.owners, firstName: event.target.value },
            validations: { ...this.state.validations, firstName: true }
        }, () => {
            if (this.validateFirstName()) {
                this.props.objFilterOwnerInfo(this.state.owners);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateFirstName() {
        if (this.state.owners.firstName === '') {
            return false;
        }
        return true;
    }
    handleUpdateLastName(event) {
        this.setState({
            owners: { ...this.state.owners, lastName: event.target.value },
            validations: { ...this.state.validations, lastName: true }
        }, () => {
            if (this.validateLastName()) {
                this.props.objFilterOwnerInfo(this.state.owners);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateLastName() {
        if (this.state.owners.lastName === '') {
            return false;
        }
        return true;
    }
    handleUpdateMiddleName(event) {
        this.setState({ owners: { ...this.state.owners, middleName: event.target.value } }, () => this.props.objFilterOwnerInfo(this.state.owners));
    }
    handleUpdateSalutation(value) {
        if (value != null) {
            this.setState({
                owners: { ...this.state.owners, salutation: value.value }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        } else {
            this.setState({
                owners: { ...this.state.owners, salutation: '' }
            }, () => this.props.objFilterOwnerInfo(this.state.owners));
        }
    }
    validateRequiredFields() {
        const fields = [
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.owners.shortName, type: 'string' },

            { displayName: 'Owner Type', validateName: 'ownerType', value: this.state.owners.ownerType, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.owners.status, type: 'string' },
            { displayName: 'Preferred Payment Instrument', validateName: 'preferredPaymentIns', value: this.state.owners.preferredPaymentIns, type: 'string' },
            { displayName: 'Preferred Delivery Method', validateName: 'preferredDeliveryMet', value: this.state.owners.preferredDeliveryMet, type: 'string' },
            { displayName: 'Email Indicator', validateName: 'emailIndicator', value: this.state.owners.emailIndicator, type: 'string' },
        ]

        if (this.state.owners.ownerType.includes("Individual")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.owners.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.owners.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.owners.companyName, type: 'string' })
        }

        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.owners.shortName, type: 'string' },

            { displayName: 'Owner Type', validateName: 'ownerType', value: this.state.owners.ownerType, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.owners.status, type: 'string' }
        ]

        if (this.state.owners.ownerType.includes("Individual")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.owners.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.owners.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.owners.companyName, type: 'string' })
        }
        const [valid,validations, errors] = validate(fields);

        return { valid, errors };
    }
    formatVisibilityIndividual(type) {
        if (type != undefined) {
            return type.includes("Individual") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    formatVisibilityCorporate(type) {
        if (type != undefined) {
            return !type.includes("Individual") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    render() {
        var corporate1 = ('')
        var individual1 = ('')
        var individual2 = ('')
        var individual3 = ('')
        if (this.formatVisibilityIndividual(this.state.owners.ownerType) !== 'hidden') {
            individual1 = (
                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-owner-salutation">Salutation</label>
                    <div className="" style={style.inputStyle}>
                        <DynamicSelect className='uk-select' objValue={{ value: this.state.owners.salutation }} getValue={this.handleUpdateSalutation.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Salutation"]} id="form-owner-salutation" />
                    </div>
                </div>
            )
            individual2 = (
                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-owner-firstName" style={style.boldLabel}>First Name (*)</label>
                    <div className="" style={style.inputStyle}>
                        <input className={formatValidInput(this.state.validations.firstName)} id="firstName" type="text" value={this.state.owners.firstName} onChange={this.handleUpdateFirstName.bind(this)} />
                    </div>
                </div>
            )
            individual3 = (
                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-owner-lastName" style={style.boldLabel}>Last Name (*)</label>
                    <div className="" style={style.inputStyle}>
                        <input className={formatValidInput(this.state.validations.lastName)} id="lastName" type="text" value={this.state.owners.lastName} onChange={this.handleUpdateLastName.bind(this)} />
                    </div>
                </div>
            )
        }
        if (this.formatVisibilityCorporate(this.state.owners.ownerType) !== 'hidden') {
            corporate1 = (
                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" style={style.boldLabel}>Company Name (*)</label>
                    <div className="" style={style.inputStyle}>
                        <input className={formatValidInput(this.state.validations.companyName)} id="companyName" type="text" value={this.state.owners.companyName} onChange={this.handleUpdateCompanyName.bind(this)} />
                    </div>
                </div>
            )
        }
        return (
            <div>
                <ul id="accordion-online-access" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">General Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="general-information">
                                <div className="uk-grid" style={{ marginTop: "0px" }}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-owner-customerType" style={style.boldLabel}>Type (*)</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect className={formatValidSelect(this.state.validations.ownerType)} objValue={{ value: this.state.owners.ownerType }} getValue={this.handleUpdateCustomerType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Owner Type"]} id="form-owner-type" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-owner-status" style={style.boldLabel}>Status (*)</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect className={formatValidSelect(this.state.validations.status)} objValue={{ value: this.state.owners.status }} getValue={this.handleUpdateStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Owner Status"]} id="form-owner-status" />
                                        </div>
                                    </div>
                                    {/*TYPE ==> PERSONAL / INDIVIDUAL */}
                                    {individual1}
                                    {individual2}
                                    {individual3}
                                    {/*TYPE ==> CORPORATE */}
                                    {corporate1}
                                    {/*---TAMBIEN VA EN PERSONAL/INDIVIDUAL ESTE INPUT DE SHORT NAME-- */}
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-owner-shortName" style={style.boldLabel}>Short Name (*)</label>
                                        <div className="" style={style.inputStyle}>
                                            <input className={formatValidInput(this.state.validations.shortName)} id="shortName" type="text" value={this.state.owners.shortName} onChange={this.handleUpdateShortName.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-beneficiary-contactPhone">Contact Phone</label>
                                        <div className="" style={style.inputStyle}>
                                            <PhoneInput
                                                key="contactPhone"
                                                inputProps  = {{
                                                    name        : 'phone',
                                                    required    : true,
                                                    autoFocus   : true
                                                }}
                                                enableSearch        = {true}
                                                disableSearchIcon   = {true}
                                                containerStyle      = {{
                                                    height: 30
                                                }}
                                                inputClass  = {'uk-input'}
                                                inputStyle  = {{
                                                    height: 30,
                                                    borderRadius: 0,
                                                    width: '100%'
                                                }}
                                                buttonStyle = {{
                                                    borderColor: '#CACACA'
                                                }}
                                                placeholder = 'Enter a Mobile Number'
                                                autoFormat
                                                copyNumbersOnly     = {true}
                                                countryCodeEditable = {true}
                                                value               = {this.state.owners.contactPhone}
                                                onChange            = {this.handleUpdateContactPhone}
                                                preferredCountries  = {['gb']}
                                                searchPlaceholder   = 'Search a country'
                                                autocompleteSearch/>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-beneficiary-contactEmail">Contact Email</label>
                                        <div className="" style={style.inputStyle}>
                                            <input className='uk-input' id="contactEmail" type="text" value={this.state.owners.contactEmail} onChange={this.handleUpdateContactEmail.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Compliance Related Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="compliance-related-information">
                                <div className="uk-grid" style={{ marginTop: "0px" }}>
                                    <div className="uk-width-1-2 uk-form-stacked ">
                                        <label className="uk-form-label" htmlFor="form-owner-bypassValidation"><input className="uk-checkbox" id="bypassValidation" type="checkbox" checked={this.state.owners.bypassValidation} onChange={this.handleOnCheck.bind(this)} /> Bypass Validation(*)</label>
                                        <div className="" style={style.inputStyle}>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" htmlFor="form-owner-expiryByPassDate">Expiry bypass date</label>
                                        <div className="uk-width-1-2 uk-form-stacked">{/*after="2025/01/10" before="2025/01/30" */}
                                            <DatePicker useNew className='uk-input' value={this.state.owners.expiryByPassDate} onDayChange={this.handleUpdateExpiryByPassDate.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Owner Risk Score(*)</label>
                                        <div className="" style={style.inputStyle}>
                                        <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Risk Calculation Owner'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        height          : 30,
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : this.state.backgroundColorRiskScore
                                    }}
                                >
                                    <label 
                                        onClick     = { this.toggleShowRiskCalculation.bind(this)}
                                        className   =""
                                        style       = {{ color: this.state.riskScore['Color Hex'], fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                        id          = "OwnerRiskScore"
                                    >{this.state.riskScore.Description}
                                    </label>
                                </div>
                            </Badge>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails} open={this.state.showRiskCalculation} close={this.toggleShowRiskCalculation.bind(this)} 
                    entityType={"Owner"}/>
            </div>
        );
    }
}
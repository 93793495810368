import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { validate } from '../../../../helpers/ValidateHelper';
import { getContactOnlineInfo, saveRelatedCustomerRoles } from '../../../../networking/NetworkingContacts';
import { getCustomersAscendantsAndDescendants } from '../../../../networking/NetworkingCustomers';
import { getRolesByCustomerAndContact } from '../../../../networking/NetworkingRoles';
import { notFilledInputClass, regularInputClass, regularSelectClass } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';
import NumberInput from '../../../shared/NumberInput';
import {Colors} from '../../../../constants/Colors'

const enableContactImpersonation = (window.REACT_APP_ENABLE_CONTACT_IMPERSONATION) ? window.REACT_APP_ENABLE_CONTACT_IMPERSONATION : false;

const inputStyle= {
    paddingBottom:"15px"
}

export default class AccountInformationForm extends React.Component {
    state = {
        contact:{
            isConfirmEmailValidated: false,
            isEmailValidated: false,
            emailAddress: '',
            onlineConfirmEmail: '',
            password: '',
            confirmPassword: '',
            forwardDays: 0,
            onlineAccess: '',
            showChallenge: '',
            accountLocked: '',
            resetPassword: '',
            doublePassword:'',
            doubleEmail:'',

            //
            passwordExpire:'180',
            listPasswordExpire:[
                {name:'0',value:'0'},
                {name:'15',value:'15'},
                {name:'30',value:'30'},
                {name:'45',value:'45'},
                {name:'60',value:'60'},
                {name:'75',value:'75'},
                {name:'90',value:'90'},
                {name:'180',value:'180'}
            ],
            previousPassword:'',
            listPreviousPassword:[
                {name:'0',value:'0'},
                {name:'1',value:'1'},
                {name:'2',value:'2'},
                {name:'3',value:'3'},
                {name:'4',value:'4'},
                {name:'5',value:'5'},
                {name:'6',value:'6'},
                {name:'7',value:'7'},
                {name:'8',value:'8'},
                {name:'9',value:'9'}
            ],
            selContact: {},
            
            //For tables
            listOnlineUserProfiles:[],
            onlineUserProfiles:'',
            rolesAvailableForOnlineUsers: [],
            listTestProfile:[],
            selectIDLeft:'',
            selectIDRight:'',
            listHave: [],//originLists
            listWant: [],//originLists
            available:'',
            relatedCustomerId: 0
        },
        //validations
        validations:{
            email: true,
            emailConfirmation: true,
            password: true,
            passwordConfirmation: true
        },
        //For related customers
        alertMessage: '',
        alertTitle: '',
        contactId: 0,
        customerId: 0,
        relatedCustomers: [],
        showAlert: false,
        showAlertYesNo: false,
    }

    closeAlert(){
        this.setState({showAlert: false});
    }

    closeAlertYesNo(){
        this.setState({showAlertYesNo: false});
    }

    yesAlertYesNo(){
        this.validateOnlineAccess();
        this.setState({contact:{...this.state.contact, onlineAccess: false, available: true, listWant: []}, showAlertYesNo: false, validations:{...this.state.validations, email:true, emailConfirmation: true, password: true, passwordConfirmation: true}}
            ,()=> {this.props.getAccountInfo(this.state.contact);
            });
    }

    validateOnlineAccess(){
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;

        Array.prototype.push.apply(listLeft,listRight)
        this.setState({contact:{...this.state.contact,
            listWant:  [],
            listHave: listLeft,
            selectIDLeft: '',
            selectIDRight: ''}
        },()=> this.props.getAccountInfo(this.state.contact));
        this.setState({contact:{...this.state.contact, available: false}});
    }

    componentDidUpdate(prevProps){
        if (prevProps.selectedContact === null && this.props.selectedContact != null) {
            var av, email, onlinEmail, doub = '';
            this.setState({
                selContact: this.props.selectedContact,
                contactId: this.props.selectedContact['Contact ID'],
                customerId: this.props.selectedContact['Customer ID']
            })
            var pwd = '';
            var contact = this.props.selectedContact;
            if (contact['Online Access']) { 
                av = false 
                // pwd ='*********';
            } else { 
                av = true 
                // pwd = '';
            }
            if (contact['Password'] != null && contact['Password'] !== '') {
                pwd ='*********'
            }
            if (contact['Email'] !== contact['Online Confirmation Email']) {
                doub = false;
            } else {
                doub = true;
            }
            var emailValidated = false;
            if (contact['Email'] === null) {
                email = '';
                emailValidated = false;
            } else {
                email = contact['Email'];
                emailValidated = true;
            }
            var confirmEmailValidated = false;
            if (contact['Online Confirmation Email'] === null) {
                onlinEmail = '';
                confirmEmailValidated = false;
            } else {
                onlinEmail = contact['Online Confirmation Email'];
                confirmEmailValidated = true;
            }
            var customerId = contact['Customer ID'];
            this.setState({
                contact: {
                    ...this.state.contact,
                    doubleEmail: doub,
                    doublePassword: doub,
                    emailAddress: email,
                    onlineConfirmEmail: onlinEmail,
                    password: pwd,
                    confirmPassword: pwd,
                    passwordExpire: contact.noDaysPasswordExpires !== null ? contact.noDaysPasswordExpires : '180',
                    previousPassword: contact.noPreviousPwds,
                    forwardDays: contact['Max Open Forwards Days'],
                    onlineAccess: contact['Online Access'],
                    showChallenge: contact['Show Challenge Questions'],
                    accountLocked: contact.Islock,
                    resetPassword: contact.ResetPassword,
                    available: av,
                    isEmailValidated: emailValidated,
                    isConfirmEmailValidated: confirmEmailValidated,
                    relatedCustomerId: customerId,
                }
            }, () => this.props.getAccountInfo(this.state.contact))

            if(customerId !== 'undefined' && customerId > 0){
                this.fillRelatedCustomerDropDown(customerId);
            }
            
        }
        if (prevProps.validations !== this.props.validations && !this.props.validations) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields != null && this.props.clearFields != null && prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.handleClearClick();
        }
    }
    componentDidMount(){
        var contact = this.state.contact.selContact;
        //var customerId = contact['Customer ID'];
        var customerId = this.props.customerId;
        var contactId = contact['Contact ID'];
        var list = []
        this.setState({customerId: customerId});
        if(contactId !== undefined && contactId > 0){
            this.setState({contactId: contactId});
        }
        getContactOnlineInfo(contact['Contact ID'],contact['PersonID'],contact['Email']).then(
            (json) =>{
                var helper = this.transformList(json.onlineUserProfiles);
                if(this.props.selectedContact.Roles=== null||this.props.selectedContact.Roles===undefined){list = []}else{list = this.props.selectedContact.Roles}
                this.setState({roles:json.rolesAvailableForOnlineUsers, contact:{...this.state.contact,
                    rolesAvailableForOnlineUsers: json.rolesAvailableForOnlineUsers,
                    listHave:json.rolesAvailableForOnlineUsers,
                    listWant: list,
                    listOnlineUserProfiles: helper}},()=> this.props.getAccountInfo(this.state.contact))
            }
        );
        if(customerId !== undefined && customerId > 0){
            this.fillRelatedCustomerDropDown(customerId);
        }
    }

    fillRelatedCustomerDropDown(customerID){        
        getCustomersAscendantsAndDescendants(customerID).then(
            (json) => {
                var relatedCustomerId = 0;
                var relatedCustomers = [];
                if(json.customers !== undefined){
                    for (let i = 0; i < json.customers.length; i++){
                        json.customers[i].name = json.customers[i].Customer;
                        json.customers[i].value = json.customers[i].CustomerId;
                    }
                    relatedCustomers = json.customers;
                    relatedCustomerId = json.customers[0].CustomerId;
                }
                this.setState({relatedCustomers: relatedCustomers, 
                    contact: {...this.state.contact, relatedCustomerId: relatedCustomerId}});
                this.getRolesForRelatedCustomers(customerID);
            }
        )
    }

    //listOnlineUserProfiles
    transformList(list){
        var helper = [];
        var listTest={};
        if(list){
            for(var i = 0; i<list.length; i++){
                helper.push({'name': list[i].OnlineUserProfileName,
                'value': list[i].OnlineUserProfileID});
                listTest = list[i].onlineUserProfilesDetails
            }
        }
        this.setState({contact:{...this.state.contact,listTestProfile: listTest}},()=> this.props.getAccountInfo(this.state.contact));
        return helper;
    }

    handleClearClick(){
        this.setState({
            contact: {
                ...this.state.contact,
                isConfirmEmailValidated: false,
                isEmailValidated: false,
                emailAddress: '',
                onlineConfirmEmail: '',
                password: '*********',
                confirmPassword: '*********',
                forwardDays: 0,
                onlineAccess: '',
                showChallenge: '',
                accountLocked: '',
                resetPassword: '',
                doublePassword:'',
                doubleEmail:'',
            }
        });
    }

    //Method for validations
    validateRequiredInputs() {

        let fields = [];

        if(this.state.contact.onlineAccess){      
            fields.push({ displayName: 'Passwords are different', validation: this.validateDoublePasswords});
            fields.push({ displayName: 'Email', validateName: 'email', value: this.state.contact.emailAddress, type: 'email' });
            fields.push({ displayName: 'Confirm Email', validateName: 'emailConfirmation', value: this.state.contact.onlineConfirmEmail, type: 'email' });
            fields.push({ displayName: 'Password', validateName: 'password', value: this.state.contact.password, type: 'string' });
            fields.push({ displayName: 'Confirm Password', validateName: 'passwordConfirmation', value: this.state.contact.confirmPassword, type: 'string' });
            fields.push({ displayName: 'Password must have at least 5 characters', validation: this.validatePasswordLenght });
        }

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateDoublePasswords = () => this.state.contact.password === this.state.contact.confirmPassword;

    validateDoubleEmails = () => this.state.contact.emailAddress === this.state.contact.onlineConfirmEmail;

    validatePasswordLenght = () => {
        if(this.state.contact.password.length >= 5){
            return true
        }
        return false
    }

    validateRequiredFields() {
        let fields = [];

        if(this.state.contact.onlineAccess){ 
            fields.push({ displayName: 'Passwords are different', validation: this.validateDoublePasswords });     
            fields.push({ displayName: 'Email', validateName: 'email', value: this.state.contact.emailAddress, type: 'email' });
            fields.push({ displayName: 'Confirm Email', validateName: 'emailConfirmation', value: this.state.contact.onlineConfirmEmail, type: 'email' });
            fields.push({ displayName: 'Password', validateName: 'password', value: this.state.contact.password, type: 'string' });
            fields.push({ displayName: 'Confirm Password', validateName: 'passwordConfirmation', value: this.state.contact.confirmPassword, type: 'string' });
            fields.push({ displayName: 'Password must have at least 5 characters', validation: this.validatePasswordLenght });
        }

        const [ , validations] = validate(fields);
        this.setState({ validations: validations });
    }
    
    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
          this.setState({contact:{...this.state.contact,selectIDLeft}},()=> this.props.getAccountInfo(this.state.contact));
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
          this.setState({contact:{...this.state.contact,selectIDRight}},()=> this.props.getAccountInfo(this.state.contact));
        }
    };
    
    handleAddClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var idSelected = this.state.contact.selectIDLeft;
        var add = true
        if(idSelected !== '')
        {
            if(listRight.length === 0)
            {
                listLeft.forEach((role, index) => {
                    if(role.RoleName === idSelected)
                    {
                        listRight.push(role)
                        listLeft.splice(index,1)
                        this.setState({contact:{...this.state.contact,
                            listWant:  listRight,
                            listHave: listLeft,
                            selectIDLeft: ''}
                        },()=> this.props.getAccountInfo(this.state.contact));
                    }
                });
                
            }else
            {
                listRight.forEach(rightRole => {
                    if(rightRole.RoleName === idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftRole, index) => {
                            if(leftRole.RoleName === idSelected)
                            {
                                listRight.push(leftRole)
                                listLeft.splice(index,1)
                                this.setState({contact:{...this.state.contact,
                                    listWant:  listRight,
                                    listHave: listLeft,
                                    selectIDLeft: ''}
                                },()=> this.props.getAccountInfo(this.state.contact));
                            }
                        });
                    }
            }
        }
    }

    handleAddAllClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        
        Array.prototype.push.apply(listRight,listLeft)
        this.setState({contact:{...this.state.contact,
            listWant:  listRight,
            listHave: [],
            selectIDLeft: '',
            selectIDRight: ''}
        },()=> this.props.getAccountInfo(this.state.contact));
    }

    handleRemoveClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var idSelected = this.state.contact.selectIDRight;

        if(idSelected !== '')
        {
            listRight.forEach((rightRole, index) => {
                if(rightRole.RoleName === idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightRole)
                }
                this.setState({contact:{...this.state.contact,
                        listWant:  listRight,
                        listHave: listLeft,
                        selectIDRight: ''}
                    },()=> this.props.getAccountInfo(this.state.contact));
            });
        }
    }

    handleRemoveAllClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        
        Array.prototype.push.apply(listLeft,listRight)
        this.setState({contact:{...this.state.contact,
            listWant:  [],
            listHave: listLeft,
            selectIDLeft: '',
            selectIDRight: ''}
        },()=> this.props.getAccountInfo(this.state.contact));
    }

    handleAddRolesClick(event){
        event.preventDefault();
        if(this.state.contact.onlineUserProfiles !== undefined || this.state.contact.onlineUserProfiles !== '' || this.state.contact.onlineUserProfiles !== 'select'){
            if(this.state.contact.listTestProfile!==undefined){
                this.setState({contact:{...this.state.contact,listWant: this.state.contact.listTestProfile}},()=> this.props.getAccountInfo(this.state.contact));
            }else{
                this.setState({contact:{...this.state.contact,listWant: []}},()=> this.props.getAccountInfo(this.state.contact));
            }
        }
    }
    handleUpdateEmailAddress(event){
        this.setState({contact:{...this.state.contact,emailAddress: event.target.value}, validations:{...this.state.validations, email: true}},
            ()=> {
                    this.props.getAccountInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                }
            );
    }

    handleUpdateOnlineConfirmEmail(event){
            this.setState({contact:{...this.state.contact, onlineConfirmEmail: event.target.value, doubleEmail: true}, validations:{...this.state.validations, emailConfirmation: true}},()=> 
            {
                this.props.getAccountInfo(this.state.contact);
                this.props.isValid(this.validateRequiredInputs());
            });
    }
    validateEmail() {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.contact.emailAddress.match(regex)) {
            return false;
        }
        return true;
    }
    isEmailValid(validator){
        if(validator){
            this.setState({contact:{...this.state.contact,isEmailValidated: true}},()=>this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,isEmailValidated: false}},()=>this.props.getAccountInfo(this.state.contact));
        } 
    }
    validateConfirmEmail() {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.contact.onlineConfirmEmail.match(regex)) {
            return false;
        }
        return true;
    }
    isEmailConfirmValid(validator){
        if(validator){
            this.setState({contact:{...this.state.contact,isConfirmEmailValidated: true}},()=>this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,isConfirmEmailValidated: false}},()=>this.props.getAccountInfo(this.state.contact));
        } 
    }
    handleUpdatePassword(event){
        this.setState({contact:{...this.state.contact, password: event.target.value}, validations:{...this.state.validations, password: true}},()=> 
        {
            this.props.getAccountInfo(this.state.contact);
            this.props.isValid(this.validateRequiredInputs());
        });
    }

    handleUpdateConfirmPassword(event){
            this.setState({contact:{...this.state.contact,confirmPassword: event.target.value, doublePassword:true}, validations:{...this.state.validations, passwordConfirmation: true}},()=> 
            {
                this.props.getAccountInfo(this.state.contact);
                this.props.isValid(this.validateRequiredInputs());
            });
    }

    handleUpdateForwardDays(event){
        this.setState({contact:{...this.state.contact,forwardDays: event.target.value}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleUpdatePasswordExpire(value){
        if(this.state.contact.passwordExpire !== undefined || this.state.contact.passwordExpire !== '' || this.state.contact.passwordExpire !== 'select'){
            this.setState({contact:{...this.state.contact,passwordExpire: value.value}},()=> this.props.getAccountInfo(this.state.contact));
        }        
    }
    handleUpdatePreviousPassword(value){
        this.setState({contact:{...this.state.contact,previousPassword: value.value}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleUpdateOnlineProfileName(value){
        this.setState({contact:{...this.state.contact,onlineUserProfiles: value.name}},()=> this.props.getAccountInfo(this.state.contact));
    }

    //checkboxes
    handleOnlineAccess(event){
        if(event.target.checked){            
            this.setState({contact:{...this.state.contact,onlineAccess: event.target.checked,available: false}}
                ,()=> {this.props.getAccountInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                });
        }else{
            this.setState({showAlertYesNo: true, alertMessage: 'By not allowing online access, all the selected online roles for this contact will be removed. Do you want to continue?', alertTitle: 'Warning'});
            //this.setState({contact:{...this.state.contact,onlineAccess: event.target.checked,available: true}},()=> this.props.getAccountInfo(this.state.contact));
        }
    }
    handleShowChallenge(event){
        this.setState({contact:{...this.state.contact,showChallenge: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleAccountLocked(event){
        this.setState({contact:{...this.state.contact,accountLocked: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleResetPassword(event){
        this.setState({contact:{...this.state.contact,resetPassword: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }

    //related customers
    handleGetRelatedCustomers(value){
        this.setState({contact:{...this.state.contact, relatedCustomerId: parseInt(value.value)}},()=> this.props.getAccountInfo(this.state.contact));
        if(value.value !== undefined){
            this.getRolesForRelatedCustomers(parseInt(value.value));
        }
    }

    getRolesForRelatedCustomers(relatedCustomerId){
        if(this.state.contactId > 0){
            getRolesByCustomerAndContact(relatedCustomerId, this.state.contactId).then(
                (json) => {
                    var listLeft = this.state.contact.rolesAvailableForOnlineUsers;
                    var listRight = json;
                    if(listRight === null || listRight === undefined){
                        this.setState({contact:{...this.state.contact,
                            listWant: [],
                            listHave: listLeft}
                        }); 
                    }else{
                        if(listRight.length === 0)
                        {
                            this.setState({contact:{...this.state.contact,
                                listWant: [],
                                listHave: listLeft}
                            }); 
                        }else
                        {
                            var helper = [];
                            var rolesHave = [];
                            var rolesWant = [];
                            for(var k = 0; k < listRight.length; k++){
                                helper.push(listRight[k]);
                            }
                            for (let i = helper.length - 1; i >= 0; i--)
                            {
                                for( var j = 0; j < listLeft.length; j++)
                                {
                                    if(helper[i] === listLeft[j].RoleName)
                                    {
                                        rolesWant.push(listLeft[j]);
                                    }
                                }
                            }
                            for(let i = 0; i < listLeft.length; i++)
                            {
                                var result = rolesWant.find(role => role.RoleName === listLeft[i].RoleName)
                                if(result === undefined || result === '')
                                {
                                    rolesHave.push(listLeft[i]);
                                }
                            }
                            /*Order by name*/
                            rolesHave.sort((a,b) => (a.RoleName > b.RoleName) ? 1 : -1);
                            rolesWant.sort((a,b) => (a.RoleName > b.RoleName) ? 1 : -1);

                            this.setState({contact:{...this.state.contact, 
                                listHave: rolesHave,
                                listWant: rolesWant},
                            },()=> this.props.getAccountInfo(this.state.contact));
                        }
                    }                    
                }
            );
        }
        else{
            this.setState({contact:{...this.state.contact,
                listWant: [],
                listHave: this.state.contact.rolesAvailableForOnlineUsers}
            }); 
        }
    }

    formatRelatedCustomerVisibility() {
        if(enableContactImpersonation !== undefined){
            if(enableContactImpersonation){
                return "flex";
            }
        }else{return "none";}
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleSaveRolesClick(event){
        if(this.state.contactId !== 'undefined' && this.state.contactId > 0 && this.validateRequiredInputs()){
            var roles = [];
            for(var i = 0; i < this.state.contact.listWant.length; i++){
                roles.push(this.state.contact.listWant[i].RoleName);
            }
            saveRelatedCustomerRoles(this.state.contact.relatedCustomerId, this.state.contact.emailAddress, this.state.contactId, roles).then(
                (json) => {
                    console.log(json);
                    if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                        this.setState({showAlert: true, alertMessage: json.Message, alertTitle: 'Error'}); 
                    }
                    else{
                        this.setState({showAlert: true, alertMessage: 'Roles Added!', alertTitle: 'Success'}); 
                    }
                }
            )
        }
    }

    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass;
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var available = this.state.contact.available;
        let accordionID = 'accordion-online-access-' + uuidv4();
        return (
            <div>
                <ul id={accordionID} uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">User Information</h5>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="user-information">
                                <form className="uk-grid">
                                    <div className="uk-width-1-2 uk-form-stacked" style={{marginTop: '-10px', display: "none"}}>
                                        <label className="uk-form-label">Email Address</label>
                                        <div className="uk-form-controls">
                                            <input autoComplete="off" id="emailAddress" type="text" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{marginTop: '-10px', display: "none"}}>
                                        <label className="uk-form-label">Password</label>
                                        <div className="uk-form-controls">
                                            <input autoComplete="off" id="password" type="password" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{marginTop: '-10px'}}>
                                        <label className="uk-form-label">Email Address</label>
                                        <div className="uk-form-controls">
                                            <input className={this.formatValidInput((!this.state.contact.onlineAccess?true: this.state.validations.email))} autoComplete="off" id="emailAddress" type="text" placeholder="" value={this.state.contact.emailAddress} onChange={this.handleUpdateEmailAddress.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{marginTop: '-10px'}}>
                                        <label className="uk-form-label">Online Confirmation Email</label>
                                        <div className="uk-form-controls">
                                            <input className={this.formatValidInput((!this.state.contact.onlineAccess?true: this.state.validations.emailConfirmation))} autoComplete="off"  id="onlineConfirmEmail" type="text" placeholder="" value={this.state.contact.onlineConfirmEmail} onChange={this.handleUpdateOnlineConfirmEmail.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Password</label>
                                        <div className="uk-form-controls">
                                            <input className={this.formatValidInput((!this.state.contact.onlineAccess?true:this.state.validations.password))} autoComplete="off" id="password" type="password" placeholder="" value={this.state.contact.password} onChange={this.handleUpdatePassword.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Confirm Password</label>
                                        <div className="uk-form-controls">
                                            <input className={this.formatValidInput((!this.state.contact.onlineAccess?true: this.state.validations.passwordConfirmation))} autoComplete="off" id="confirmPassword" type="password" placeholder="" value={this.state.contact.confirmPassword} onChange={this.handleUpdateConfirmPassword.bind(this)} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Permissions and Account Configuration</h5>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="permissions-account-configuration">
                                <form className="uk-grid">
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input className="uk-checkbox" id="onlineAccess" type="checkbox" checked={this.state.contact.onlineAccess} onChange={this.handleOnlineAccess.bind(this)} />Online Access
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input className="uk-checkbox" id="showChallenge" type="checkbox" checked={this.state.contact.showChallenge} onChange={this.handleShowChallenge.bind(this)} disabled={available} />Show Challenge Questions
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input className="uk-checkbox" id="accountLocked" type="checkbox" checked={this.state.contact.accountLocked} onChange={this.handleAccountLocked.bind(this)} disabled={available} /> Account Locked
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input className="uk-checkbox" id="resetPassword" type="checkbox" checked={this.state.contact.resetPassword} onChange={this.handleResetPassword.bind(this)} disabled={available} /> Reset password on next login
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">Password expire every</label>
                                        <div className="uk-form-controls">
                                            <DynamicSelect id="expire-every" className={classNameSelect} getValue={this.handleUpdatePasswordExpire.bind(this)} placeholderValue={'0'} listHasPlaceholder={true} data={this.state.contact.listPasswordExpire} objValue={{ value: this.state.contact.passwordExpire }} disabled={available} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label"># of previous password to store</label>
                                        <div className="uk-form-controls">
                                            <DynamicSelect id="previous-password" className={classNameSelect} getValue={this.handleUpdatePreviousPassword.bind(this)} listHasPlaceholder={true} data={this.state.contact.listPreviousPassword} objValue={{ value: this.state.contact.previousPassword }} disabled={available} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">Forward Days</label>
                                        <div className="uk-form-controls">
                                            <NumberInput id="forwardDays" type="Integer" className="uk-input" placeholder="__0" value={this.state.contact.forwardDays} onChange={this.handleUpdateForwardDays.bind(this)} disabled={available} />
                                        </div>
                                    </div>
                                </form>
                                <form className="uk-grid" style={{ display: this.formatRelatedCustomerVisibility() }}>
                                    <div className="uk-grid uk-width-2-3">
                                        <div className="uk-width-3-5">
                                            <label className="uk-form-label">Related Customers</label>
                                            <DynamicSelect id="related-customers" className="uk-select" getValue={this.handleGetRelatedCustomers.bind(this)} data={this.state.relatedCustomers} objValue={{ value: this.state.contact.relatedCustomerId }} />                                            
                                        </div>
                                        <div className="uk-width-2-5" style={{marginTop: '20px'}}>
                                            <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveRolesClick.bind(this)}>Save Roles</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Roles</h5>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="roles">
                                <form className="uk-grid">
                                    <div className="uk-width-1-1">
                                        <div className="uk-margin" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                            <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 500 }}>
                                                <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                    <tbody>
                                                        {listLeft.map((item) => {
                                                            return (
                                                                <tr key={item.RoleName} onClick={this.roleChangeColorLeft(item.RoleName)} style={this.state.contact.selectIDLeft === item.RoleName ? { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                    <td style={this.state.contact.selectIDLeft === item.RoleName ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                        {item.RoleName}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="uk-card-body uk-width-1-3" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                                <button className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available} style={{ marginBottom: 20 }} onClick={this.handleAddClick.bind(this)}>Add</button>
                                                <button className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available} style={{ marginBottom: 20 }} onClick={this.handleAddAllClick.bind(this)}>Add all</button>
                                                <button className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available} style={{ marginBottom: 20 }} onClick={this.handleRemoveClick.bind(this)}>Remove</button>
                                                <button className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" disabled={available} style={{ marginBottom: 20 }} onClick={this.handleRemoveAllClick.bind(this)}>Remove all</button>
                                                <DynamicSelect id="online-user" className="uk-select uk-width-1-2 uk-form-stacked" getValue={this.handleUpdateOnlineProfileName.bind(this)} listHasPlaceholder={false} placeholder="Select a Profile Name" data={this.state.contact.listOnlineUserProfiles} objValue={{ value: this.state.contact.onlineUserProfiles }} />
                                                <button className="uk-button uk-button-green uk-width-1-2 uk-form-stacked" style={{ marginBottom: 20, marginTop: 20 }} onClick={this.handleAddRolesClick.bind(this)}>Add Roles</button>
                                            </div>
                                            <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 500, paddingLeft: 0 }}>
                                                <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                    <tbody>
                                                        {listRight.map((item) => {
                                                            return (
                                                                <tr key={item.RoleName} onClick={this.roleChangeColorRight(item.RoleName)} style={this.state.contact.selectIDRight === item.RoleName ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                    <td style={this.state.contact.selectIDRight === item.RoleName ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                        {item.RoleName}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox id="yes-no-alert" open={this.state.showAlertYesNo} onClose={this.closeAlertYesNo.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} 
                            type="Yes/No" yesClick={this.yesAlertYesNo.bind(this)} noClick={this.closeAlertYesNo.bind(this)}/>
            </div>
        );
    }
}
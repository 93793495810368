import React from 'react';
import DealsForm from './DealsForm'
import OutgoingFundsForm from './OutgoingFundsForm'
import DealsInquiriesForm from './DealsInquiriesForm'
import OutgoingFundsInquiriesForm from './OutgoingFundsInquiriesForm'
import AlertBox from '../../shared/AlertBox';
import {getStyle} from '../../../styles/styles';
import NewDealsForm from './NewDealsForm';
import NewOutgoingFundsForm from './NewOutgoingFundsForm';
var style = getStyle();

export default class ReferredDealsScreen extends React.Component {

    render(){
        return(
            <div>
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}> Referred Deals</h3>
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="deals-form">Deals</a>
                        </li>
                        <li>
                            <a href="#" id="outgoing-funds">Outgoing Funds</a>
                        </li>
                        <li>
                            <a href="#" id="deals-inquiries">Deals Inquiries</a>
                        </li>
                        <li>
                            <a href="#" id="outgoing-inquiries">Outgoing Funds Inquiries</a>
                        </li>
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <NewDealsForm/>
                        </li>
                        <li>
                            <NewOutgoingFundsForm/>
                        </li>
                        <li>
                            <DealsInquiriesForm/>
                        </li>
                        <li>
                            <OutgoingFundsInquiriesForm/>
                        </li>
                    </ul>
                </div>
                <div>
                    {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Options</h3>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Save Criteria</button>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Load Criteria</button>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">Export</button>*/}             
                </div>
                {/*<AlertBox open={this.state.showAlertError} onClose={this.closeAlertError.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError.bind(this)}/>*/}
                {/*<AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>*/}
            </div>
        );
    }
}
import React from 'react';
import ImportDealsForm from './ImportDealsForm';
import AppContext from '../../../AppContext';

import { getStyle } from '../../../styles/styles';
var style = getStyle();
let globalFunctions = {};
export default class ImportDealsScreen extends React.Component{
    static contextType = AppContext;
    state = {
        openForm: false
    }
    
    componentDidMount = () => {
        globalFunctions = this.context;
    }

    handleImportDealsClick(event){
        this.setState({openForm: true});
    }

    onCloseForm(){
        this.setState({openForm: false});
    }

    render(){
        return(
            <div>
                {/*<form className="uk-form-stacked uk-grid">*/}
                    <div className="uk-width-1-1 uk-margin">
                        <div className="">
                            <h3 className="uk-heading-divider" style={style.titleStyle}>
                                    Import Deals
                            </h3>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <ImportDealsForm open={this.state.openForm} onClose={this.onCloseForm.bind(this)}/>
                    </div>
                {/*</form>*/}
            </div>
        );
    }
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DealVersionsScreen from './DealVersionsScreen';

export default class DealVersionViewer extends React.Component {

    handleExitClick = () => {
        this.props.close();
    }

    render (){
        return(
            <Dialog open={this.props.open} onClose={this.handleExitClick} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Deal Versions</DialogTitle>
                <DialogContent dividers>
                    <DealVersionsScreen dealID = {this.props.dealHeaderID} backFromDealVersions={this.handleExitClick}/>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"exit"} onClick={this.handleExitClick}>Exit</button>
                </DialogActions>
            </Dialog>
        );
    }
}
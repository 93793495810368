import React from 'react';

import ListOfReportsScreen from './ListOfReportsScreen';
import CustomReportsForm from './CustomReportsForm';

import {Breadcrumb} from 'antd';

import { getStyle } from '../../../../styles/styles';
var style = getStyle();

export default class CustomReportsScreen extends React.Component {
    state = {
        step:1,
        selectedReport: null,
    }

    //For Tabs
    onClickStepTab(value){
        this.setState({step: value});
    }

    newReportClick = () => {
        this.setState({step: 2, selectedReport: null});
    }

    returnToList = () => {
        this.setState({step: 1, selectedReport: null});
    }

    reportClick = (obj) => {
        console.log(obj);
        this.setState({selectedReport: obj, step: 2});
    }

    render = () => {
        var stepScreen = [];
        let breadcrumb = [];

        var classNameStep1 = "";
        var classNameStep2 = "uk-disabled";

        switch(this.state.step){
            case 1:
                classNameStep1 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Custom Reports</Breadcrumb.Item>
                );
                stepScreen.push(<ListOfReportsScreen newReportClick={this.newReportClick} reportClick={this.reportClick} />);
                break;
            case 2:
                stepScreen.pop();
                classNameStep2 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.returnToList}>Custom Reports</Breadcrumb.Item>
                );
                if(this.state.selectedReport){
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item-bold">Report Detail - {this.state.selectedReport['Report Name']}</Breadcrumb.Item>
                    );
                } else {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item-bold">New Report</Breadcrumb.Item>
                    );
                }
                
                stepScreen.push(<CustomReportsForm selectedRow={this.state.selectedReport} returnToList={this.returnToList} />);
                //stepScreen.push(<CreateUserForm getStep = {this.getStep} onUserCreateSelected={this.handleUserCreateSelection.bind(this)} blankUser = {this.selectedUser} selectedUser = {this.state.selectedUser} isCreate = {this.state.isCreate} isCreateClick = {this.isCreateClick}/>);
                break;
            default:
                classNameStep1 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Custom Reports</Breadcrumb.Item>
                );
                stepScreen.push(<ListOfReportsScreen newReportClick={this.newReportClick} />);
                break;
        }

        return(
            <div>
                <div className="">
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            {breadcrumb}
                        </Breadcrumb>
                    </div>
                    {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Custom Reports</h3>
                    <ul className="uk-tab">
                        <li className={classNameStep1}><a onClick={this.onClickStepTab.bind(this, 1)}>Reports</a></li>
                        <li className={classNameStep2}><a onClick={this.onClickStepTab.bind(this, 2)}>Report Details</a></li>
                    </ul>*/}
                    {stepScreen}
                </div>
            </div>
        );
    }
}
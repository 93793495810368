import React from 'react';
import DatePicker from '../../../shared/DatePicker';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getStyle } from '../../../../styles/styles';
import { getLookUpTable } from '../../../../networking/Networking';
import { searchCountries, getCountryById } from '../../../../networking/NetworkingCountries';
import { getStatesByCountry } from '../../../../networking/NetworkingStates';
import { formatDate, formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import AlertBox from '../../../shared/AlertBox';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();

export default class AddressForm extends React.Component {

    state = {
        address: {
            Address2: '',
            AddressId: '',
            AddressType: '',
            AddressValue: '',
            City: '',
            CountryId: '',
            CountryName: '',
            CreatedBy: '',
            CreatedOn: '',
            DefaultAddress: true,
            District: '',
            OtherState: '',
            PersonID: '',
            POBox: '',
            SinceWhen: '',
            StateId: '',
            StateName: '',
            Status: '',
            UpdateToken: '',
            UpdatedOn: '',
            ZipCode: ''
        },
        dropdowns: {
            countries: [],
            states: [],
            "Address Type": [],
            "Customer Address Status": []
        },
        lookUpTables: [
            "Address Type",
            "Customer Address Status"
        ],
        validations: {
            addressType: true,
            address1: true,
            country: true,
            state: true
        },
        addresses: [],
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount() {
        if (this.props.selectedAddress === null) {
            this.props.isValid(this.validateRequiredInputs());
        } else {
            if (this.props.selectedAddress.length === undefined) {
                this.props.isValid(this.validateRequiredInputs());
            }
        }
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                    }
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAddress !== undefined && prevProps.selectedAddress === undefined) {
            const address = this.props.selectedAddress;
            if (address.CountryId !== '' && address.CountryId !== 0 && address.CountryId !== undefined) {
                this.searchCountry(address.CountryId);
                this.searchStates(address.CountryId);
            }

            this.setState({
                address: address,
                validations: {
                    addressType: true,
                    address1: true,
                    country: true,
                    state: true
                }
            }, () => {
                this.props.isValid(this.validateRequiredInputs());
            })
        } if (prevProps.validations !== this.props.validations && !this.props.validations) {
            this.validateRequiredFields();
        }
        if (prevProps.clearFields !== this.props.clearFields && this.props.clearFields) {
            this.clearFields();
        }
    }

    getCountry(id) {
        getCountryById(id).then(
            (json) => {
                console.log(json);
                var helper = [];
                var country = json.country[0];
                country.value = country['Country Id'];
                var name = country['Country Id'] + ' - ' + country['Country Name'];
                country.name = name;
                helper.push(country);
                this.setState({ address: { ...this.state.address, CountryId: country['Country Id'], CountryName: name }, dropdowns: { ...this.state.dropdowns, countries: helper } });
            }
        );
    }

    searchStates(query) {
        getStatesByCountry(query, 1, 100).then(
            (json) => {
                var helper = [];
                if (json !== undefined) {
                    if (query !== '' && json.states !== undefined) {
                        var states = json.states;
                        for (var i = 0; i < states.length; i++) {
                            states[i].value = states[i]["State Id"];
                            states[i].name = states[i]["State Name"];
                            helper.push(states[i]);
                        }
                    }
                }
                this.setState({ dropdowns: { ...this.state.dropdowns, states: helper } });
            }
        )
    }

    searchCountry(query) {
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({ dropdowns: { ...this.state.dropdowns, countries: helper } });
                    if (helper.length === 1) {
                        var value = helper[0];
                        this.setState({
                            address: {
                                ...this.state.address, CountryId: value.CountryId, CountryName: value.CountryIdName
                            }
                        });
                    }
                }
            }
        );
        console.table(this.state.dropdowns.countries)
    }

    clearFields() {
        this.setState({
            address: {
                Address2: '',
                AddressId: '',
                AddressType: '',
                AddressValue: '',
                City: '',
                CountryId: '',
                CountryName: '',
                CreatedBy: '',
                CreatedOn: '',
                DefaultAddress: false,
                District: '',
                OtherState: '',
                PersonID: '',
                POBox: '',
                SinceWhen: '',
                StateId: '',
                StateName: '',
                Status: '',
                UpdateToken: '',
                UpdatedOn: '',
                ZipCode: ''
            },
            dropdowns: {
                ...this.state.dropdowns, states: []
            },
            validations: {
                ...this.state.validations,
                addressType: true,
                address1: true,
                country: true,
                state: true
            }
        }, () => {
            this.props.isValid(this.validateRequiredInputs());
        });
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    checkValidations = () => {
        return this.state.validations.address1 && this.state.validations.addressType && this.state.validations.country && this.state.validations.state;
    }

    validateRequiredFields() {
        const fields = [
            { displayName: 'Address Type', validateName: 'addressType', value: this.state.address.AddressType, type: 'string' },
            { displayName: 'Address', validateName: 'address1', value: this.state.address.AddressValue, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: this.state.address.CountryId, type: 'string' },
            { displayName: 'State', validateName: 'state', value: this.state.address.StateId, type: 'string' },
        ];

        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }

    validateRequiredInputs() {
        const fields = [
            { displayName: 'Address Type', validateName: 'addressType', value: this.state.address.AddressType, type: 'string' },
            { displayName: 'Address', validateName: 'address1', value: this.state.address.AddressValue, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: this.state.address.CountryId, type: 'string' },
            { displayName: 'State', validateName: 'state', value: this.state.address.StateId, type: 'string' },
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateAddressType() {
        if (this.state.address.AddressType === "select" || this.state.address.AddressType === '' || this.state.address.AddressType === undefined) {
            return false;
        }
        return true;
    }

    validateCountry() {
        if (this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) {
            return false;
        }
        return true;
    }

    validateState() {
        if (this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) {
            return false;
        }
        return true;
    }

    validateAddress1() {
        if (this.state.address.AddressValue === '') {
            return false;
        }
        return true;
    }

    handleSaveAddressClick(event) {
        event.preventDefault();

    }

    handleGetValueAddressType(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                validations: { ...this.state.validations, addressType: true },
                address: { ...this.state.address, AddressType: value.value }
            },
                () => {
                    if (this.validateAddressType()) {
                        this.props.getAddressInfo(this.state.address);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                }
            );
        }
    }

    handleGetValueCountry(value) {
        if (value !== undefined && value !== "select" && value !== '') {
            this.setState({
                validations: { ...this.state.validations, country: true },
                address: { ...this.state.address, CountryId: value.value, CountryName: value.name }
            },
                () => {
                    if (this.validateCountry()) {
                        this.props.getAddressInfo(this.state.address);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                }
            );
            if (value.value !== undefined) {
                this.searchStates(value.value);
            }
        }
    }

    handleGetValueState(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                validations: { ...this.state.validations, state: true },
                address: { ...this.state.address, StateId: value.value, StateName: value.name }
            },
                () => {
                    if (this.validateState()) {
                        this.props.getAddressInfo(this.state.address);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                }
            );
        }
    }

    handleUpdateAddress1(event) {
        this.setState({
            validations: { ...this.state.validations, address1: true },
            address: { ...this.state.address, AddressValue: event.target.value }
        },
            () => {
                if (this.validateAddress1()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            }
        );
    }

    handleUpdateAddress2(event) {
        this.setState({
            address: { ...this.state.address, Address2: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateCity(event) {
        this.setState({
            address: { ...this.state.address, City: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDefaultAddress(event) {
        this.setState({
            address: { ...this.state.address, DefaultAddress: event.target.checked }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDistrict(event) {
        this.setState({
            address: { ...this.state.address, District: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdatePOBox(event) {
        this.setState({
            address: { ...this.state.address, POBox: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateZipCode(event) {
        this.setState({
            address: { ...this.state.address, ZipCode: event.target.value }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateSinceWhen(date) {
        this.setState({
            address: { ...this.state.address, SinceWhen: formatDate(date) }
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }
    render() {
        var countryListId = 'country-list-' + this.state.address.PersonID;
        return (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel} >Address Type (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.addressType)} objValue={{ value: this.state.address.AddressType }} getValue={this.handleGetValueAddressType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Address Type"]} id="form-address-type" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel}>Address (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className={formatValidInput(this.state.validations.address1)} type="text" placeholder="" value={this.state.address.AddressValue} onChange={this.handleUpdateAddress1.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >Address 2:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.Address2} onChange={this.handleUpdateAddress2.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel} >Country (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSearchSelect id={countryListId} className={formatValidSelect(this.state.validations.country)} listHasPlaceholder={false} placeholder={"Select an Item..."} searchFunction={this.searchCountry.bind(this)} getValue={this.handleGetValueCountry.bind(this)} data={this.state.dropdowns.countries} objValue={{ value: this.state.address.CountryId, name: this.state.address.CountryName, CountryId: this.state.address.CountryId, CountryIdName: this.state.address.CountryName }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" style={style.boldLabel} >Province / State (*):</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.state)} getValue={this.handleGetValueState.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.states} objValue={{ value: this.state.address.StateId, name: this.state.address.StateName, "State Id": this.state.address.StateId, "State Name": this.state.address.StateName }} id="form-provinces-states" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >City:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.City} onChange={this.handleUpdateCity.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >District:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.District === null ? '' : this.state.address.District} onChange={this.handleUpdateDistrict.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >Postal / Zip Code:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.ZipCode} onChange={this.handleUpdateZipCode.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >PO BOX:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.POBox === null ? '' : this.state.address.POBox} onChange={this.handleUpdatePOBox.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <label className="uk-form-label">
                        <input className="uk-checkbox" value={this.state.address.DefaultAddress} checked={this.state.address.DefaultAddress} onChange={this.handleUpdateDefaultAddress.bind(this)} type="checkbox" />
                        Default Address
                        </label>
                </div>
                <div className="uk-width-1-2" style={{ paddingBottom: '5px' }}>
                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >Since When:</label>
                        </div>
                        <div className="uk-width-2-3">
                            <div className="uk-form-controls">
                                <DatePicker useNew className="uk-input" value={this.state.address.SinceWhen} onDayChange={this.handleUpdateSinceWhen.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </form>
        );
    }
}
import React from 'react';
import { formatDate } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getOutgoingFundsInquiries } from '../../../networking/NetworkingCustomerLimitProfile';
import { getStyle } from '../../../styles/styles';
import DatePicker from '../../shared/DatePicker';
import NewEditableGrid from '../../shared/NewEditableGrid';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';

var style = getStyle();

export default class OutgoingFundsInquiriesForm extends React.Component {
    state = {
        outgoingFundsInquiries_data_table: [],
        outgoingFundsInquiries_data_columnDetails: {},
        outgoingFundsInquiries_detail: {},
        showModal: false,
        fromDate: '',
        toDate: ''
    }

    handleSearchClick(){
        if(this.state.fromDate !== '' && this.state.fromDate !== undefined && this.state.fromDate !== null && this.state.toDate !== '' && this.state.toDate !== undefined && this.state.toDate !== null){
            getOutgoingFundsInquiries(1,getDefaultPagingNumberOfItems(),this.state.fromDate,this.state.toDate).then(
                (value) => {
                    if (value != null && value.referreadDeals != null) {
                        this.setState({ 
                            outgoingFundsInquiries_data_table: value.referreadDeals, 
                            outgoingFundsInquiries_data_columnDetails: JSON.parse(value.columnDetail)
                        });
                    }
                }
            );
        }
    }

    handleRowClick(obj){
        this.setState({
            showModal: true,
            outgoingFundsInquiries_detail: obj
        });        
    }

    onCloseDetail(value){
        this.setState({showModal: false});
    }

    handleUpdateFromDate(date){
        this.setState({fromDate: formatDate(date)});
    }

    handleUpdateToDate(date){
        this.setState({toDate: formatDate(date)});
    }

    render(){

        return(
            <div>
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1 uk-margin">                      
                            <div className="uk-heading-divider" style={style.titleStyle}>
                                <h4>Search Criteria</h4>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >From (Outgoing) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" value={this.state.fromDate} onDayChange={this.handleUpdateFromDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >To (Outgoing) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker useNew className="uk-input" value={this.state.toDate} onDayChange={this.handleUpdateToDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" > </label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSearchClick.bind(this)} type="button">Search</button>
                            </div>
                        </div>
                    </div>
                    <NewEditableGrid
                        data={this.state.outgoingFundsInquiries_data_table}
                        columnDetails={this.state.outgoingFundsInquiries_data_columnDetails}
                        isEditable={false}
                        enableClick={true} 
                        clickFunction={this.handleRowClick.bind(this)}
                        useExportButton={true}
                        tableName="OutgoingFundsInquiries"
                        useIcons={true} />
                    <ShowDynamicInfo open={this.state.showModal} jsonDetail={this.state.outgoingFundsInquiries_detail} columnDetails={this.state.outgoingFundsInquiries_data_columnDetails} 
                        title='Outgoing Funds Inquiries Information' onClose={this.onCloseDetail.bind(this)}/>
                </form>
            </div>
        );
    }
}
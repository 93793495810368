import {genericCallWithBody, 
    genericGetWithParameters, 
    genericDeleteWithParameters
    } from './Networking';

export async function savePersonIdentification(objIdentification){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'PersonIdentification/SavePersonIdentification';
    let response = await genericCallWithBody(method, apiName, objIdentification);
    return response;
}

export async function getPersonIdentifications(personID,entityTaskID){
    try {
        let params = {
            'personID': personID,
            'entityTaskID': entityTaskID
        }

        let response = await genericGetWithParameters('PersonIdentification/GetPersonIdentifications',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deletePersonIdentification(PersonIdentificationID){
    try{
        let params = {
            'PersonIdentificationID': PersonIdentificationID
        };
        let response = await genericDeleteWithParameters('PersonIdentification/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
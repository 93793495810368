import React from 'react';
import DynamicList from './DynamicList';

export default class DynamicAccordion extends React.Component {

    state = {
        value: '',
        data: [],
        selectFirst: false
    }

    componentDidMount(){
        var propData = this.props.data;
        this.setState({data: propData});
        var propValue = '';
        if(this.props.objValue !== undefined && this.props.objValue.value !== ''){
            propValue = this.props.objValue.value;

            this.setState({value: propValue}, ()=>{
                this.props.getValue(this.state.value);
            });
        }
        if(this.props.selectFirst !== undefined){
            this.setState({selectFirst: this.props.selectFirst});
        }
       
    }

    getSelectedValue(value){
        this.setState({value: value.value});
        console.log(this.state.value);
        this.props.getValue(value);
    }


    render() {
        var typecollapse = "collapsible: false";
        if(this.props.typecollapse !== undefined){
            typecollapse = this.props.typecollapse;
        }

        var classNameList = "uk-list";
        if(this.props.classNameList !== undefined){
            classNameList = this.props.classNameList;
        }

        var openLink = -1;
        if(this.props.openLink !== undefined){
            openLink = this.props.openLink;
        }

        var list = [];
        var accordion = [];
        var liClass= "";//"uk-open";

        for(var i=0; i<this.props.data.length; i++)
        {            
            list = <DynamicList className={classNameList}            
            selectFirst={i === 0 ? this.state.selectFirst : false}
            key={this.props.data[i].name + '-'+i} parent={i} data={this.props.data[i].childrens}
            getValue={this.getSelectedValue.bind(this)} objValue={{value: this.state.value, Value: this.state.value}}            ></DynamicList>;
            if(i === openLink)
            {
                liClass = "uk-open";
            }
            else
            {   
                liClass= "";
            }

            accordion.push(<li class ={liClass}>
                            <a class="uk-accordion-title" value={i} href="#">{this.props.data[i].name}</a>
                            <div class="uk-accordion-content">
                                {list}
                            </div>
                            </li>);
        }   

        
        return(
            <div className="uk-overflow-auto">
                <ul uk-accordion={typecollapse}  id={this.props.id}>
                    {accordion}
                </ul>
            </div>
        );
    }
}
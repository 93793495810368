import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import NewDynamicTable from '../../../shared/NewDynamicTable';

import { getStyle } from '../../../../styles/styles';
import { Button } from '@material-ui/core';
import { formatToCommaSeparate } from '../../../../helpers/FormatHelper';
var style = getStyle();

export default class RiskCalculationDialog extends React.Component {

    state = {
        newSearch: false,
        importance: 0,
        total: 0,
        totalRiskScore: 0,
        manualRiskText: ""
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            let reducer = (accumulator, currentValue) => accumulator + currentValue;
            let importance = this.props.riskScoreDetails.map((detail) => detail["Risk Importance"]).reduce(reducer);
            let total = this.props.riskScoreDetails.map((detail) => detail.Total).reduce(reducer);
            let totalRiskScore = total !== 0 ? Math.ceil(total / importance) : 0;

            let detail1 = this.props.riskScoreDetails[0] || {};
            
            this.setState({
                importance: importance,
                total: total,
                totalRiskScore: totalRiskScore,
                manualRiskText: detail1["Parent Risk Score Text"] || ""
            })
        }
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.close} maxWidth={"md"} fullWidth={true}>
                <DialogContent>
                    <h3 style={{ fontWeight: "bold" }}>{"Risk Calculation " + this.props.entityType}</h3>
                    <div className="">
                        <NewDynamicTable hiddenHeaders={["Entity ID", "Entity Type", "Order", "Risk Score Text", "Score Calculated", "Parent Risk Score Text"]}
                            // formats={[
                            //      { header: 'Risk Importance', formatFunction: formatToCommaSeparate },
                            //      { header: 'Risk Score', formatFunction: formatToCommaSeparate },
                            //      { header: 'Total', formatFunction: formatToCommaSeparate }
                            // ]}
                            data={this.props.riskScoreDetails} enableClick={false} useDeleteButton={false} useCheckBox={false} newSearch={this.state.newSearch} />
                        <div className="uk-grid">
                            <div className="uk-width-1-3"></div>
                            <div className="uk-width-1-3">
                                <label className="uk-margin-large-right uk-margin-right" style={{ fontWeight: "bold" }}>Sub totals</label>
                            </div>
                            <div className="uk-width-1-3 uk-margin-small" style={{ textAlign: "right" }}>
                                <label className="uk-margin-large-left uk-margin-right uk-text-right">{this.state.importance}</label>
                                <label className="uk-margin-small-right uk-margin-right uk-text-right" style={{ marginLeft: "5px" }}>{this.state.total}</label>
                            </div>
                            <div className="uk-width-1-3"></div>
                            <div className="uk-width-1-3">
                                <label className="uk-margin-large-right uk-margin-right" style={{ fontWeight: "bold", fontSize: '16px' }} >Total Risk Score</label>
                            </div>
                            <div className="uk-width-1-3 uk-margin-small-top" style={{ textAlign: "right" }}>
                                <label className="uk-margin-small-right uk-margin-right" style={{ fontWeight: "bold", marginLeft: "5px", fontSize: '16px' }}>{this.state.totalRiskScore + ' ' + this.state.manualRiskText}</label>                            
                            </div>
                            <div className="uk-width-1-3"></div>
                            <div className="uk-width-1-3">
                                <label className="uk-margin-large-right uk-margin-right" style={{ fontSize: '10px' }} >(Total / Importance)</label>
                            </div>
                        </div>                        
                        
                        <div className="" style={{ lineHeight: "1" }}>
                            <label className="uk-form-label" style={{ fontSize: "10px", marginRight: "240px", color: "red" }}>* Please note, total risk score values are rounded up.</label>
                        </div>
                        <div className="" style={{ lineHeight: "1" }}>
                            <label className="uk-form-label" style={{ fontSize: "10px", marginRight: "240px", color: "red" }}>** When there are multiple items within a Risk Factor (i.e. A customer could have addresses in multiple countries, province/states). the risk scores of the group are added together and then averaged to come up with one overall risk score for the risk factor.</label>
                        </div>
                        <div className="uk-margin">
                            <button className="uk-button uk-button-green uk-margin-right" onClick={this.props.close} type="button">Exit</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function updateProcessStatus(entityId, entityType, processStatus, assignTo){
    var method = 'PUT';
    var apiName = 'WorkAssignment/UpdateProcessStatus';
    let userId = ''
    if(assignTo !== undefined && assignTo !== ''){
        userId = assignTo;
    } else {
        userId = localStorage.getItem('UserID');
    }
    var request = {
        entityId: entityId,
        entityType: entityType,
        userId: userId,
        assignedBy: localStorage.getItem('UserID'),
        processStatus: processStatus
    }
    try {
        let response = await genericCallWithBody(method,apiName,request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteWorkAssignments(entityId, entityType){
    try{
        let params = {
            'entityId': entityId,
            'entityType': entityType,
            'userId': localStorage.getItem('UserID')
        };
        let response = await genericDeleteWithParameters('WorkAssignment/DeleteWorkAssignments',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCustomersCardViewByCountry = async (customerId, customerName) => {
    try {
        const params = {
            CustomerID: (customerId === '' ? '' : customerId),
            CustomerName: (customerName === '' ? '' : customerName)
        }
        const response = await genericGetWithParameters('Customer/GetCustomersCardViewForCompliance', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
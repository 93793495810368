import React from 'react';
import { formatDate, getScaleByCurrency, getScaleForCurrencyPair } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';
import DatePicker from '../../shared/DatePicker';
import NewDynamicTable from '../../shared/NewDynamicTable';
import WizardSearchCustomerForm from '../../../components/trade/wizard/WizardSearchCustomerForm';
import { notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import {GetSearchResultsByEntityAndDateRange, SearchEntities, GetSearchResultsByScanID} from '../../../networking/NetworkingGlobalWatchListSearch'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions } from '@material-ui/core';
import AppContext from '../../../AppContext';
import { alignBox } from '@nivo/core';
import { LeftClipper } from 'igniteui-react-core';

let globalFunctions = {};
const style = getStyle();

export default class GlobalWatchList extends React.Component {
    static contextType = AppContext;

    state = {
        listEntityType: [],
        entityType: '',
        entityID: 0,
        entity: '',
        dateFrom: '',
        dateTo: '',
        listGlobalWatch: [],
        newSearch: false,
        showModal: false,
        listModal: [],
        customerNameTitle: '',
        step: 1,
        listEntities: [],
        customerID: 0
    }
    
    componentDidMount(){
        globalFunctions = this.context;
        let list = [];
        list.push(
            {value: 'Customer',name: 'Customer'},
            {value: 'Contact',name: 'Customer Contact'},
            {value: 'Beneficiary',name: 'Beneficiary'},
            {value: 'Incoming',name: 'Incoming sender'},
            {value: 'Outgoing',name: 'Outgoing beneficiary'});

        let date_now = new Date;
        let date_later_2month = new Date;
        date_later_2month.setMonth(date_later_2month.getMonth() - 2);

        this.setState({ listEntityType: list, dateTo:formatDate(date_now), dateFrom:formatDate(date_later_2month) });
    }
    
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    getSelectEntityType  = async (value) => {
        if (value !== undefined && value != null && value !== "select" && value.value !== undefined) {
            let response = await SearchEntities(this.state.customerID, value.value, this.state.dateFrom, this.state.dateTo);
            let listEntities=[];
            if(response!==undefined && response!==null && response.results!==undefined && response.results!==null){
                response.results.forEach(element => {
                    listEntities.push({value: element.EntityID,name: element.Description});
                });
            }
            if(value.value=='Customer'){
                this.setState({ entityType: value.value, listEntities: listEntities, entityID:this.state.customerID })
            }else{
                this.setState({ entityType: value.value, listEntities: listEntities, entityID:0 })    
            }            
        }
    }

    getSelectEntity(value) {
        if (value !== undefined && value != null && value !== "select" && value.value !== undefined) {
            this.setState({ entity: value.name, entityID: value.value })
        }
    }

    handleDateFrom(date) {
        if (date !== undefined) {
            this.setState({ dateFrom: formatDate(date) });
        } else {
            this.setState({ dateFrom: '' });
        }
    }

    handleDateTo(date) {
        if (date !== undefined) {
            this.setState({ dateTo: formatDate(date) });
        } else {
            this.setState({ dateTo: '' });
        }
    }
        
    handleSearchClick = async () =>{
        globalFunctions.startLoading();
        let entityType = this.state.entityType;
        let entityID = this.state.entityID;
        let dateFrom = this.state.dateFrom;
        let dateTo = this.state.dateTo;
        const model = {
            EntityType: entityType,
            EntityID: entityID,
            FromDate: dateFrom,
            ToDate: dateTo
        };
        let response =  await GetSearchResultsByEntityAndDateRange(model);
        let listGlobalWatch=[];
        if(response!==undefined && response!==null && response.results!==undefined && response.results!==null){
            listGlobalWatch=response.results;
        }
        globalFunctions.finishLoading();
        this.setState({ listGlobalWatch:listGlobalWatch });
    }
    
    handleRowClick = async (obj) => {
        GetSearchResultsByScanID(obj['WatchListsSearchID']).then(
            (json) => {
                if (json !== undefined && json.results != null) {
                    let modal = json.results;
                    this.setState({ listModal: modal, showModal: true });
                } else {
                    this.setState({ listModal: [], showModal: false });
                }
            });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        })
    }
    
    onClickStepTab = (value) => {
        if(value==1){
            this.setState({ customerNameTitle:'' });
        }
        this.setState({ step:value });
    }

    ///Customer screen
    handleCustomerSelection = async (obj, nextStep, loadCurrenciesCallback) => {
        let customerID = obj['Customer ID'];
        let customerName = obj['Customer Name'];
        let customerNameTitle = ' - ' + customerName;
        let response = await SearchEntities(customerID,'Customer',null,null);
        let listEntities=[];
        if(response!==undefined && response!==null && response.results!==undefined && response.results!==null){
            response.results.forEach(element => {
                listEntities.push({value: element.EntityID,name: element.Description});
            });
        }
        
        let date_now = new Date;
        let date_later_2month = new Date;
        date_later_2month.setMonth(date_later_2month.getMonth() - 2);

        this.setState({ step:2, customerNameTitle:customerNameTitle, entityType:'Customer', entityID:customerID, customerID:customerID, listEntities: listEntities,
            dateTo:formatDate(date_now), dateFrom:formatDate(date_later_2month)});
    }

    render() {        
        var classNameStep1 = '', classNameStep2 = '';
        var stepScreen = null;
        switch (this.state.step) {
            case 1:
                classNameStep1 = 'uk-active';
                stepScreen=(<WizardSearchCustomerForm id="comp_customer_form" onCustomerSelected={this.handleCustomerSelection} />);
                break;
            case 2:
                stepScreen=(<div>
                    <form className="uk-form-stacked">
                        <div className="uk-form-stacked uk-grid">

                            <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                                <label className="uk-form-label" >Entity type</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect className={regularSelectClass} 
                                    getValue={this.getSelectEntityType.bind(this)} 
                                    placeholder="Select an Item" 
                                    objValue={{ value: this.state.entityType }} 
                                    data={this.state.listEntityType} id="form-entitytype" />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                                <label className="uk-form-label" htmlFor="form-currency-riskScore">Entity</label>
                                <div className="uk-form-controls">
                                <DynamicSelect className={regularSelectClass} 
                                    getValue={this.getSelectEntity.bind(this)} 
                                    placeholder="Select an Item" 
                                    objValue={{ value: this.state.entityID }} 
                                    data={this.state.listEntities} id="form-entity" />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" htmlFor="form-deal-valueDateTo">Date From</label>
                                <div className="" style={style.inputStyle}>
                                    <DatePicker className={regularInputClass} value={this.state.dateFrom} onDayChange={this.handleDateFrom.bind(this)} />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" htmlFor="form-deal-valueDateTo">Date To</label>
                                <div className="" style={style.inputStyle}>
                                    <DatePicker className={regularInputClass} value={this.state.dateTo} onDayChange={this.handleDateTo.bind(this)} />
                                </div>
                            </div>

                        </div>
                        <div className="uk-margin-top uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green" onClick={this.handleSearchClick.bind(this)} type="button">Search</button>
                            </div>
                        </div> 
                    </form>
                    <NewDynamicTable 
                        //formats         = {[{header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency'}]} 
                        //hiddenHeaders   = {['OutgoingFundsID', 'DealHeaderID', 'EntityProperties', 'EntityPropertiesJson', 'UpdatedBy']}
                        data            = {this.state.listGlobalWatch} 
                        enableClick     = {true} 
                        clickFunction   = {this.handleRowClick.bind(this)} 
                        newSearch       = {this.state.newSearch} 
                        //dynamicActions={[{name: 'Show Properties', function: this.handleShowPropertiesClick, isIcon: true, icon:'fa fa-search'}]}
                    />
                    <Dialog
                        open={this.state.showModal}
                        onClose={this.closeModal}
                        fullWidth={true}
                        maxWidth='sm'>
                        <DialogTitle id="emailscreen-dialog-gwlatr-title" onClose={this.closeModal}>
                            <div style={{float:'left'}}>SCAN</div>
                            <div style={{float:'right'}}><button onClick={this.closeModal.bind(this)} type="button">X</button></div>
                        </DialogTitle>                        
                        <DialogContent dividers>
                            <form className="uk-form-stacked">
                            <NewDynamicTable 
                                data            = {this.state.listModal} 
                                enableClick     = {true} 
                                newSearch       = {false} 
                            />
                            </form>
                        </DialogContent>
                    </Dialog>
                </div>);
                classNameStep2 = 'uk-active';
                break;
        }

        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Scan Inquiry{this.state.customerNameTitle}</h3>

                <ul className="uk-tab">
                    <li key="step-1" className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>
                    {/*<li key="step-2" className={classNameStep2}><a onClick={() => this.onClickStepTab(0)}>Watch List Search</a></li>*/}
                    <li key="step-2" className={classNameStep2}><a>Watch List Search</a></li>
                </ul>

                {stepScreen}
            </div>
        );
    }
}
import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import LoadingBox from '../../../shared/LoadingBox'
import AlertBox from '../../../shared/AlertBox';
import { CountryType } from '../../../../constants/EntityTypeNames';

export default class CountryPropertiesForm extends React.Component {

    state = {
        customerID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
        errors: [],
    }

    onFinishEntityPropertiesSaveProcess(status, message, pendingMandatoryList) {
        if (status) {
            this.setState({ loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Success.', alertMessage: 'Customer Properties saved.', errors: pendingMandatoryList });
        } else {
            this.setState({ loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Error', alertMessage: message, errors: pendingMandatoryList });
        }

    }

    handleButtonSaveClick() {
        this.setState({ entityPropertiesSaveProcess: true, loading: true });
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    render() {
        console.log(this.props)
        var propertyId = 'country-property-' + this.props.countryID;

        var section = (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <EntityProperties id={propertyId} fullScreen={true} entityType={CountryType} entityID={this.props.countryID}
                        runSaveProcess={this.state.entityPropertiesSaveProcess} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                </div>
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                </div>
            </form>
        )

        return (
            <div>
                {section}
                <AlertBox
                    open={this.state.showAlert}
                    onClose={this.closeAlert.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    messageBoldList={this.state.errors}
                    okClick={this.closeAlert.bind(this)} />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}
import React from 'react';
import { newSearchCustomers } from '../../../networking/Networking';
import { getStyle } from '../../../styles/styles';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AddCustomerScreen from './addCustomer/AddCustomerScreen';
import CustomersSearchForm from './CustomersSearchForm';
import CustomerScreen from './updateCustomer/CustomerScreen';

import { Breadcrumb } from 'antd';
import { faLinesLeaning } from '@fortawesome/free-solid-svg-icons';

const style = getStyle();

export default class CustomersScreen extends React.Component {
    state = {
        searchedCustomerName: '',
        customers: [],
        updateCustomer: false,
        addCustomer: false,
        addContact: false,
        updateContact: false,
        addBeneficiary: false,
        addOwner: false,
        updateBeneficiary: false,
        updateOwner: false,
        createBankAccount: false,
        updateBeneficiaryBankAccount: false,
        createBeneficiaryBankAccount: false,
        updateBankAccount: false,
        bankName: '',
        contactName: '',
        beneficiaryName: '',
        ownerName: '',
        customerID: 0,
        customerName: '',
        pages: 0,
        totalPages: 0,
        newSearch: false,
        useFilter: false,
        backToCustomer: false,
        hasComplianceAccess: JSON.parse(localStorage.getItem('HasComplianceAccess')),
        settabContact:false,
        settabBank:false,
        settabBeneficiary:false,
        settabOwner:false,
        customerType:'', 
        addBreadcrumb: false,
        breadcrumb: '', 
        hideCustomerDetail: true,
        hideContactDetail: true,
        hideBeneficiaryDetail: true,
        hideBankAccountDetail: true,
        show_list_bankAccount: false
    }

    componentDidMount(){
        let updateCustomer = false;
        let customerID = 0;
        let customerName = '';
        if(this.props.updateCustomer) {
            updateCustomer= this.props.updateCustomer            
        }
        if(this.props.customerID) {
            customerID= this.props.customerID            
        }
        if(this.props.customerName && this.props.customerName.Name) {
            customerName= this.props.customerName.Name           
        }
        this.setState({
            updateCustomer: updateCustomer,
            customerID: customerID,
            customerName: customerName
        });
    }

    handleUpdateSearchedCustomerName = (value) => {
        this.setState({ searchedCustomerName: value });
    }

    newHandleTableData = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, 0, 0);
        if (value !== undefined && value.totalCount > 0) {
            const totalCount = value.totalCount;
            const totalPages = Math.ceil(totalCount / 15);
            this.setState({ customers: value.customers, pages: 1, totalPages, newSearch: true, useFilter: true });
        } else {
            this.setState({ customers: [], pages: 0, totalPages: 0, newSearch: true, useFilter: false });
        }
    }

    handleAddCustomer = () => {
        this.setState({ addCustomer: true });
    }

    handleRowClick = (obj) => {
        this.setState({
            updateCustomer: true,
            customerID: obj["Customer ID"],
            customerName: obj['Customer Name'],
            customerType: obj['Customer Type']
        });
    }

    handleBackLink = () => {
        if(this.props.backLink)
        {
            this.props.backLink();
        }
        else
        {
            this.setState({
                updateCustomer: false,
                addCustomer: false,
                updateBeneficiary: false,
                updateBankAccount: false,
                updateBeneficiaryBankAccount: false,
                updateContact: false,
                updateOwner: false,
            });
        }
    }

    handleCustomerCreatedBacklink = (customerID, customerName, customerType) => {
        this.setState({
            addCustomer: false,
            updateCustomer: true,
            customerID: customerID,
            customerName: customerName,
            customerType: customerType
        });
    }

    clearData = () => {
        this.setState({
            customers: [], searchedCustomerName: '', pages: 0, totalPages: 0, newSearch: false, useFilter: false
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getAllCustomers = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, 0, 0);
        return value.customers;
    }

    backToUpdateCustomer = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true, addOwner: false, updateOwner: false, addBreadcrumb: false, hideCustomerDetail: false });
    }

    updateContact = (name) => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: true, addContact: false, updateBeneficiary: false, addBeneficiary: false, contactName: name, backToCustomer: false, createBankAccount: false, updateBankAccount: false, addOwner: false, updateOwner: false, hideContactDetail: false })
    }

    createContact = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: true, updateBeneficiary: false, addBeneficiary: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false, addOwner: false, updateOwner: false })
    }

    updateBeneficiary = (name) => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false , updateBeneficiary: true, addBeneficiary: false, beneficiaryName: name, addOwner: false, updateOwner: false, hideBeneficiaryDetail: false})
    }

    createBeneficiary = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: true, backToCustomer: false, createBankAccount: false, updateBankAccount: false, addOwner: false, updateOwner: false })
    }

    updateBeneficiaryBankAccount = (name) => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: true, updateContact: false, addContact: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false , updateBeneficiary: false, addBeneficiary: false, bankName: name, addOwner: false, updateOwner: false, hideBeneficiaryDetail: false})
    }

    createBeneficiaryBankAccount = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: true, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false, addOwner: false, updateOwner: false })
    }

    updateOwner = (name) => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false , updateBeneficiary: false, addBeneficiary: false, ownerName: name, addOwner: false, updateOwner: true})
    }

    createOwner= () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, backToCustomer: false, createBankAccount: false, updateBankAccount: false, addOwner: true, updateOwner: false })
    }

    createBankAccount = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, createBankAccount: true, updateBankAccount: false, backToCustomer: false, addOwner: false, updateOwner: false})
    }

    updateBankAccount = (name) => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, createBankAccount: false, bankName: name, updateBankAccount: true, backToCustomer: false, addOwner: false, updateOwner: false, hideBankAccountDetail:false })
    }


    backTosettabContact = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, addOwner: false, updateOwner: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true, settabContact: true }, ()=>this.setState({settabContact:false}));
    }    
    
    backTosettabBank = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, addOwner: false, updateOwner: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true, settabBank: true }, ()=>this.setState({settabBank:false}));
    }    
    
    backTosettabBeneficiary = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, addOwner: false, updateOwner: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true, settabBeneficiary: true }, ()=>this.setState({settabBeneficiary:false}));
    }

    backTosettabOwner = () => {
        this.setState({ show_list_bankAccount: false, createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, addOwner: false, updateOwner: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true, settabOwner: true }, ()=>this.setState({settabOwner:false}));
    }

    pushBreadcrumb = (breadcrumb, hideDetailName) =>{
        this.setState({addBreadcrumb: true, breadcrumb: breadcrumb, [hideDetailName]: true});
    }

    removeBreadcrumb = () =>{
        this.setState({addBreadcrumb: false, breadcrumb: '', hideCustomerDetail: false, hideContactDetail: false, hideBeneficiaryDetail: false, hideBankAccountDetail: false});
    }

    show_list_bankAccount = () =>{
        this.setState({ createBeneficiaryBankAccount: false, updateBeneficiaryBankAccount: false, updateCustomer: true, updateContact: false, addContact: false, updateBeneficiary: false, addBeneficiary: false, addOwner: false, updateOwner: false, createBankAccount: false, updateBankAccount: false , backToCustomer: true
            ,show_list_bankAccount: true, settabBeneficiary: true }, ()=>this.setState({settabBeneficiary:false}));
    }


    render() {
        var screen = [];
        var breadcrumb = [];

        let styleActiveBreadcrumb = this.state.addBreadcrumb ? "breadcrumb-item": "breadcrumb-item-bold";


        if (this.state.updateCustomer) {
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink}>{this.props.mainTitle != null ?this.props.mainTitle : "Customers"}</Breadcrumb.Item>
            );
            if (!this.state.updateBeneficiary && !this.state.addBeneficiary && !this.state.updateContact && !this.state.addContact&& !this.state.updateBankAccount && !this.state.createBankAccount && !this.state.addOwner 
                && !this.state.updateOwner && !this.state.createBeneficiaryBankAccount && !this.state.updateBeneficiaryBankAccount) {
                breadcrumb.push(
                    <Breadcrumb.Item className={styleActiveBreadcrumb} onClick={this.backToUpdateCustomer}>{this.state.customerName}</Breadcrumb.Item>
                );
            }

            if (this.state.addContact) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabContact}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Create Contact</Breadcrumb.Item>
                );
            }

            if (this.state.updateContact) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabContact}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className={styleActiveBreadcrumb} onClick={() => this.updateContact(this.state.contactName)}>{this.state.contactName}</Breadcrumb.Item>
                );
            }

            if (this.state.addBeneficiary) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBeneficiary}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Create Beneficiary</Breadcrumb.Item>
                );
            }

            if (this.state.updateBeneficiary) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBeneficiary}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className={styleActiveBreadcrumb} onClick={() => this.updateBeneficiary(this.state.beneficiaryName)}>{this.state.beneficiaryName}</Breadcrumb.Item>
                );
            }

            if (this.state.createBeneficiaryBankAccount) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBeneficiary}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.show_list_bankAccount()}>{this.state.beneficiaryName}</Breadcrumb.Item>                    
                );                
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Add / Update Bank Account</Breadcrumb.Item>
                );
            }

            if (this.state.updateBeneficiaryBankAccount) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBeneficiary}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.show_list_bankAccount()}>{this.state.beneficiaryName}</Breadcrumb.Item>
                );                
                breadcrumb.push(
                    <Breadcrumb.Item className={styleActiveBreadcrumb} onClick={() => this.updateBankAccount(this.state.bankName)}>{this.state.bankName}</Breadcrumb.Item>
                );
            }

            if (this.state.createBankAccount) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBank}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Add / Update Bank Account</Breadcrumb.Item>
                );
            }
            if (this.state.updateBankAccount) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabBank}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className={styleActiveBreadcrumb} onClick={() => this.updateBankAccount(this.state.bankName)}>{this.state.bankName}</Breadcrumb.Item>
                );
            }

            if (this.state.addOwner) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabOwner}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Create Owner</Breadcrumb.Item>
                );
            }

            if (this.state.updateOwner) {
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.backTosettabOwner}>{this.state.customerName}</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">{this.state.ownerName}</Breadcrumb.Item>
                );
            }

            if(this.state.addBreadcrumb)
            {
                breadcrumb.push(this.state.breadcrumb);
            }

            

            screen.push(<CustomerScreen
                backToUpdateCustomer={this.backToUpdateCustomer}
                updateContactHandler={this.updateContact}
                createContactHandler={this.createContact}
                updateBeneficiaryHandler={this.updateBeneficiary}
                createBeneficiaryHandler={this.createBeneficiary}
                updateBeneficiaryBankAccountHandler={this.updateBeneficiaryBankAccount}
                createBeneficiaryBankAccountHandler={this.createBeneficiaryBankAccount}                
                updateOwnerHandler={this.updateOwner}
                createOwnerHandler={this.createOwner}
                updateBankAccount={this.updateBankAccount}
                createBankAccount={this.createBankAccount}
                backToCustomer={this.state.backToCustomer}
                backLink={this.handleBackLink}
                show_list_bankAccount={this.show_list_bankAccount}
                customerID={this.state.customerID}
                customerName={this.state.customerName}
                customerType={this.state.customerType}
                readOnly={this.state.hasComplianceAccess} 
                backTotabContact={this.state.settabContact}
                backTotabBank={this.state.settabBank}
                backTotabBeneficiary={this.state.settabBeneficiary}
                backTotabOwner={this.state.settabOwner}       
                mainTitle={this.props.mainTitle}         
                canUpdateCustomerProcessStatus={this.props.canUpdateCustomerProcessStatus}
                addBreadcrumb={this.pushBreadcrumb} 
                removeBreadcrumb ={this.removeBreadcrumb}
                hideDetail={this.state.hideCustomerDetail}
                hideContactDetail={this.state.hideContactDetail}
                hideBeneficiaryDetail={this.state.hideBeneficiaryDetail}
                hideBankAccountDetail={this.state.hideBankAccountDetail}
                />);
        }

        if (this.state.addCustomer) {
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink}>Customers</Breadcrumb.Item>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item-bold">Add Customer</Breadcrumb.Item>
            );
            screen.push(<AddCustomerScreen backLink={this.handleBackLink} customerCreatedBackLink={this.handleCustomerCreatedBacklink} />);
        }

        if (!this.state.updateCustomer && !this.state.addCustomer) {
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item-bold">Customers</Breadcrumb.Item>
            );
            screen.push(
                <div>
                    <CustomersSearchForm
                        searchedString={this.state.searchedCustomerName}
                        updateSearchedCustomerName={this.handleUpdateSearchedCustomerName}
                        addCustomer={this.handleAddCustomer}
                        updateTable={this.newHandleTableData}
                        clearData={this.clearData}
                    />
                    <NewDynamicTable
                        data={this.state.customers}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={false}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={15}
                        enableSortByHeader={true}
                        useFilter={this.state.useFilter}
                        filterFunction={this.getAllCustomers}
                        useExportButton={this.state.useFilter}
                        tableName="customers"
                    />
                </div>
            );
        }

        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                {screen}

            </div>
        );
    }
}
import {genericGetWithParameters, genericCallWithBody} from './Networking';

export async function getDealFees(dealHeaderID, chargeTo, displayIn){
    try {
        let params = {"dealHeaderID":dealHeaderID,
            "chargeTo":chargeTo,
            "displayIn":displayIn,
        };

        let response = await genericGetWithParameters('DealFee/GetDealFees',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateDealFee(model) {

    let method = 'POST';
    let apiName = 'DealFee/UpdateDealFee';
    
    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
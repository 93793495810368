import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import { getCountries } from '../../../networking/NetworkingCountries';
import { addState, deleteState, getStatesByCountry } from '../../../networking/NetworkingStates';
import AlertBox from '../../shared/AlertBox';

const NewStateScreen = ({selectedCountry}) => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [selectedCountryID, setSelectedCountryID] = useState(selectedCountry);

    const [editingKey, setEditingKey] = useState(0);
    const [currentPage, setCurrentPage] = useState();

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [currentStateID, setCurrentStateID] = useState('');
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    const isEditing = (record) => record['State Id'] === editingKey;

    useEffect(() => {
        const loadCountries = async () => {
            const json = await getCountries(0, 0);
            if (json != null && json.countries != null) {
                const countries = json.countries.map((c) => ({...c, key: `StateScreen-${c.Country}`}));
                setCountries(countries);
            }
        };
        loadCountries();
    }, []);

    const handleGetStatesForCountry = async (value) => {
        if (value !== '') {
            context.current.startLoading();
            const json = await getStatesByCountry(value, 0, 0);
            context.current.finishLoading();
            if (json != null && json.states != null) {
                const states = json.states.map((s) => ({...s, key: s['State Id']}));
                setStates(states);
            }
        } else {
            setStates([]);
        }
    };

    useEffect(() => {
        const loadStates = async () => {
            if (selectedCountryID !== '') {
                await handleGetStatesForCountry(selectedCountryID, false);
            }
        }
        loadStates();
    }, [updating, selectedCountryID]);

    useEffect(() => {
        setSelectedCountryID(selectedCountry);
    }, [selectedCountry]);

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log(row);
            const newData = [...states];
            const index = newData.findIndex((item) => record['State Id'] === item['State Id']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    StateId: states[index]['State Id'] !== 0 ? currentStateID : 0,
                    CountryId: row['Country'],
                    StateCode: row['State Code'],
                    RiskScore: row['RiskScore'],
                    StateName: row['State Name'],
                    UpdateToken: currentUpdateToken,
                    UserId: userID
                };
                context.current.startLoading();
                const json = await addState(model);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setUpdating(!updating);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('State successfully saved.');
                        setUpdating(!updating);
                        setEditingKey(0);
                        setCurrentStateID(0);
                        setCurrentUpdateToken('');
                    }
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = (record) => {
        if (states[0]['State Id'] === 0) {
            let auxStates = [...states];
            auxStates.shift();
            setStates(auxStates);
        }

        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record['State Id']);
        setCurrentStateID(record['State Id']);
        setCurrentUpdateToken(record['UpdateToken']);
    };

    const deleteRecord = async (record) => {
        context.current.startLoading();
        const json = await deleteState(record['State Id']);
        context.current.finishLoading();
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
                setUpdating(!updating);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('State successfully removed.');
                setUpdating(!updating);
            }
        }
    };

    const cancel = (page) => {
        setEditingKey(0);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (states[0]['State Id'] === 0) {
            let auxStates = [...states];
            auxStates.shift();
            setStates(auxStates);
        }
        setCurrentStateID(0);
        setCurrentUpdateToken('');
    };

    const addNewState = () => {
        let actual = [...states];
        if (actual[0] != null && actual[0]['State Id'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'StateScreen-state-new',
                'State Id': 0,
                'State Code': '',
                'State Name': '',
                'Country': selectedCountryID,
                'RiskScore': 0,
                UpdateToken: '',
                isNew: true
            });
            setStates(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'State Code': '',
                'State Name': '',
                'Country': selectedCountryID,
                'RiskScore': 0,
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentStateID(0);
        }
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...(searchArray[col] = value));
        setFilteringSomething(true);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber min={0} max={100} style={{ width: '100%', textAlignLast: 'center' }} />;
                break;
            }
            case 'select': {
                inputNode = (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Country"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        disabled={true}
                    >
                        {countries.map((country) => (
                            <Select.Option
                                key={`cell-${country.Country}-${country['Country Name']}`}
                                value={country.Country}>{`${country['Country Name']} - ${country.Country}`}
                            </Select.Option>
                        ))}
                    </Select>
                );
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }}/>;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'State Code': {
                    return {
                        required: false,
                    }
                }
                case 'RiskScore': {
                    return {
                        min: 0,
                        max: 100,
                        type: 'number',
                        message: 'Please Input a valid Risk Score!'
                    }
                }
                default: return {};
            }
        }

        const rules = {
            required: true,
            message: `Please Input a ${title}!`,
            ...getCustomRules(dataIndex)
        };

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={[rules]}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        {
            title: 'State Code',
            dataIndex: 'State Code',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['State Code'].localeCompare(b['State Code']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('State Code')
        },
        {
            title: 'State Name',
            dataIndex: 'State Name',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['State Name'].localeCompare(b['State Name']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('State Name')
        },
        {
            title: 'Country',
            dataIndex: 'Country',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Country'].localeCompare(b['Country']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Country')
        },
        {
            title: 'Risk Score',
            dataIndex: 'RiskScore',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['RiskScore'] - b['RiskScore'],
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== 0 || states[0]['State Id'] === 0} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a disabled={editingKey !== 0 || states[0]['State Id'] === 0}>Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Country': {
                type = 'select';
                break;
            }
            case 'RiskScore': {
                type = 'number';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const closeAlert = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <form className="uk-form-stacked uk-grid uk-margin">
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-country-id">
                        Country
                    </label>
                    <div className="uk-form-controls">
                        <select id="state-countries" className="uk-select" value={selectedCountryID}
                            onChange={(e) => {
                                setSelectedCountryID(e.target.value)
                                if (e.target.value === '') {
                                    setStates([]);
                                }
                            }}>
                            <option key='state-option-placeholder' value=''>Select a Country...</option>
                            {countries.map((country) => (
                                <option
                                    key={`state-option-${country.Country}`}
                                    value={country.Country}>{`${country['Country Name']} - ${country.Country}`}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </form>
            <Form form={form} component={false}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    dataSource={states}
                    columns={mergedColumns}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewState}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={selectedCountryID === '' || editingKey !== 0 || filteringSomething || (states[0] !== undefined && states[0]['State Id'] === 0)}
                        >
                            Add a State
                        </Button>
                    )}
                />
            </Form>
            <AlertBox id="alert-state" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewStateScreen;

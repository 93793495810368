import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../AppContext';
import { formatValidInput } from '../../../helpers/FormatHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { sendComplaintRepliedEmail } from '../../../networking/NetworkingComplaints';
import { getContactByID } from '../../../networking/NetworkingContacts';
import { sendMessage } from '../../../networking/NetworkingCustomers';
import AlertBox from '../AlertBox';
import { getReplyHistoryByMessageID } from '../../../networking/NetworkingMessages';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ReplyMessageDialog = ({ open, onClose, complaintID, contactID, subject, personID, messageObj }) => {
    const context = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [contactPersonID, setContactPersonID] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [validations, setValidations] = useState({ message: true });
    const [errors, setErrors] = useState([]);
    const [replyContent, setReplyContent] = useState('');

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    useEffect(() => {
        if (messageObj && messageObj.Content) {
            getReplyHistory();
        }
      }, [messageObj])

      const getReplyHistory = async () => {
        const json = await getReplyHistoryByMessageID(messageObj.MessageId);
        let reply = '';
        if (messageObj && messageObj.Content) {
          reply = `From: ${messageObj.Sender} \nDate: ${messageObj.SentOnDate} \nSubject: ${messageObj.Subject}\n\n${messageObj.Content}\n\n`;
    
        }
        if (json.messages && json.messages.length > 0) {
          json.messages.forEach((m) => {
            reply = reply + `From: ${m.Sender} \nDate: ${m.SentOnDate} \nSubject: ${m.Subject}\n\n${m.Content}\n\n`;
    
          });
          setReplyContent(reply);
        }
      }
      
    useEffect(() => {
        const loadContact = async () => {
            const json = await getContactByID(contactID);
            console.log(json);
            if (json != null) {
                setContactPersonID(json.PersonID);
            }
        }
        if (contactID != null) {
            loadContact();
        }
    }, [contactID]);

    const handleReplyClick = async () => {
        if (validateReply()) {
            const userID = localStorage.getItem('UserID');
            const model = {
                ComplaintID: complaintID,
                messageSubject: `Re: ${subject}`,
                messageContent: message,
                senderId: userID,
                receiverIds: [
                    contactPersonID !== '' ? contactPersonID : personID
                ],
                replyMessageId: messageObj.MessageId
            };
            context.startLoading();
            const json = await sendMessage(model);
            context.finishLoading();
            if (json != null) {
                switch (json.httpStatusCode) {
                    case 200: {
                        if (complaintID != null && contactID != null) {
                            await sendComplaintRepliedEmail(complaintID, contactID);
                        }
                        setAlertMessage('Reply successfully sent.');
                        setShowAlertSuccess(true);
                        setAlertTitle('Success');
                        break;
                    }
                    default: {
                        setAlertMessage('Send reply failed. Please try again.');
                        setShowAlert(true);
                        setAlertTitle('Error');
                        break;
                    }
                }
            }
        }
    }

    const validateReply = () => {
        const fields = [
            { displayName:'Message', validateName: 'message', value: message }
        ];
        const [valid, _validations, _errors] = validate(fields);
        setValidations(_validations);
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid;
    }

    const closeAlert = () => {
        setShowAlert(false);
    };

    const closeAlertError = () => {
        setShowAlertError(false)
    }

    const closeAlertSuccess = () => {
        setShowAlertSuccess(false)
        onCloseDialog();
    }

    const onCloseDialog = () => {
        setMessage('');
        onClose();
    };

    return (
        <React.Fragment>
            <Dialog open={openDialog} onClose={onCloseDialog} fullWidth={true} maxWidth="md">
                <DialogTitle id="searchgloballists">
                    <div className="">
                        <h4 className="">
                            Send reply
                        </h4>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                <div className="uk-grid uk-margin" style={{ marginTop: '0px' }}>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">To</label>
                        <div className="">
                        <input className={'uk-input'} id="to" type="text" placeholder="" value={messageObj.Sender} readOnly={true} />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Subject</label>
                        <div className="">
                        <input className={'uk-input'} id="subject" type="text" placeholder="" value={`Re: ${subject}`} readOnly={true} />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label">Message</label>
                        <div className={'div-message-container'+ (validations.message ? '':'-error')}>
                            <textarea
                                className={'text-area-reply-message'}
                                id="message"
                                value={message}
                                placeholder=''
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            {replyContent &&
                            <textarea
                                className={'text-area-reply-message text-area-border-top'}
                                id="replyMessage"
                                value={replyContent}
                                placeholder=''
                                readOnly={true}
                            />
                            }
                        </div>
                    </div>
                </div>
                </DialogContent>
                <DialogActions>
                        <button className="uk-button uk-button-green" onClick={handleReplyClick}>Send</button>
                        <button className="uk-button uk-button-green" onClick={onCloseDialog}>Close</button>
                    </DialogActions>
            </Dialog>
            <AlertBox id="alertbox-reply-dialog" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
            <AlertBox id="alertbox-reply-dialog-success" open={showAlertSuccess} onClose={closeAlertSuccess} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlertSuccess} />
            <AlertBox id="alert-error-reply-dialog" open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
        </React.Fragment>
    );
};

export default ReplyMessageDialog;

import React from 'react';

import {myHistoricalPerformance} from '../../../networking/NetworkingWidget';
import { ResponsiveBar } from '@nivo/bar'

import { Column } from '@ant-design/charts';

export default class MyHistoricalPerformance extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = { 
            myPerformanceList   : [],
            //Checks
            ytd                 : false,
            last12months        : true,
            loadingData         : false
        };
    }

    componentDidMount()
    {
        this.getMyHistoricalPerformance('Last 12 months');
    }

    getMyHistoricalPerformance(period)
    {
        this.setState({
            loadingData: true
        }, () =>{
            myHistoricalPerformance(localStorage.getItem('UserID'), period).then(
                (response) => {
                    if(response !== undefined)
                    {
                        console.log("myHistoricalPerformance: " + JSON.stringify(response));
                        console.log(response);
    
                        /*
                            {
                                "SalesVolumes":[
                                    {
                                        "year"          :2020,
                                        "month"         :1,
                                        "MonthText"     :"Jan",
                                        "Period Total"  :0,
                                        "Period Profit" :0,
                                        "Monthly Total" :0,
                                        "Base Currency" :"USD",
                                        "Trader name"   :"Admin",
                                        "yyyyMM"        :"Jan/20"
                                    },
                                    {},{},{}...
                                ]
                            }
                        */
                    
                        this.setState({
                            loadingData         : false,
                            myPerformanceList   : response.SalesVolumes
                        });
                    }
                }
            );
        });
    }

    getColorByMonth = (month) => 
    {
        var color = '#8ac43f';
        return color;
    }

    handleUpdateYTD = (event) => {
        this.setState({
            ytd         : event.target.checked,
            last12months: !event.target.checked
        }, () =>{
            if(this.state.ytd === true)
            {
                this.getMyHistoricalPerformance("YTD");
            }else
            {
                this.getMyHistoricalPerformance("Last 12 months");
            }
        });
    }

    handleUpdateLast12Months = (event) => {
        this.setState({
            last12months: event.target.checked,
            ytd         : !event.target.checked
        }, () =>{
            if(this.state.last12months === true)
            {
                this.getMyHistoricalPerformance("Last 12 months");
            }else
            {
                this.getMyHistoricalPerformance("YTD");
            }
        });
    }

    render = () => {

        var config = {
            data        : this.state.myPerformanceList,
            isGroup     : false,
            xField      : 'yyyyMM',
            yField      : 'PeriodTotal',
            seriesField : 'PeriodTotal',
            label       : {
                position    : 'middle',
                content     : function content(item) {
                  return item['PeriodTotal'].toFixed(2);
                },
                style: { 
                    fill        : 'transparent',
                },
            },
            color: '#8ac43f',
            legend: false,
        };

        return(
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    //overflow: 'auto',
                    //backgroundColor: 'yellow',
                    flexDirection: 'column',
                    padding: 10,
                    //
                    display: 'flex',
                    alignItems: 'stretch',
                    Margin:'0 auto',
                    //borderRadius: '10px',
                    //border: '2px solid #E7E7E7',
                }}
            >
                <div
                    className ='title-widget'
                    style={{
                        display         : 'block',
                        textAlign       : 'center'
                    }}
                >
                    <span>My Historical Performance for {this.state.ytd === true? 'YTD':'last 12 months'}</span>
                </div>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%',
                    margin          : 0,
                    overflow        : 'hidden',
                    paddingTop      : 10,
                    paddingBottom   : 5
                }}
            >
                <Column 
                    {...config} 
                    style={{
                        height: '100%', 
                        width:'100%'
                    }}
                    loading={this.state.loadingData}
                />
            </div>
            <div style={{display: 'flex'}}>
                    <div className="uk-width-1-2" style={{textAlign: 'center'}}>
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.ytd} 
                                onChange    = {this.handleUpdateYTD} 
                                type        = "checkbox"
                            /> YTD
                        </label>
                    </div>
                    <div className="uk-width-1-2" style={{textAlign: 'center'}}>
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.last12months} 
                                onChange    = {this.handleUpdateLast12Months} 
                                type        = "checkbox"
                            /> Last 12 months
                        </label>
                    </div>
                </div>
          </div>
        );

        /*return(
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    //overflow: 'auto',
                    //backgroundColor: 'yellow',
                    flexDirection: 'column',
                    paddingTop: 10,
                    //
                    display: 'flex',
                    alignItems: 'stretch',
                    Margin:'0 auto',
                    //borderRadius: '10px',
                    //border: '2px solid #E7E7E7',
                }}
            >
                <div
                    className ='title-widget'
                    style={{
                        display         : 'block',
                        textAlign       : 'center'
                    }}
                >
                    <span>My Historical Performance for {this.state.ytd === true? 'YTD':'last 12 months'}</span>
                </div>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%',
                    margin          : 0,
                    display         : 'flex',
                    flexDirection   : 'column',
                    textAlign       : 'center'
                }}
            >
                <div style={{display: 'flex', height: '90%'}}>
                <ResponsiveBar
                    data        = {this.state.myPerformanceList}
                    keys        = {[ 'Period Total' ]}
                    indexBy     = "yyyyMM"
                    margin      = {{ top: 20, right: 20, bottom: 50, left: 70 }}
                    padding     = {0.3}
                    valueScale  = {{ type: 'linear' }}
                    indexScale  = {{ type: 'band', round: true }}
                    colors      = {this.getColorByMonth}
                    defs        = {[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#A3EFB1',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill    = {[
                        {
                            match: {
                                id: ''
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor = {{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop     = {null}
                    axisRight   = {null}
                    axisBottom  = {{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Months',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft    = {{
                        tickSize: 0,
                        tickPadding: 0,
                        tickRotation: 0,
                        legend: 'Volume',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                    enableLabel     = {false}
                    labelSkipWidth  = {12}
                    labelSkipHeight = {12}
                    labelTextColor  = {{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    animate         = {true}
                    motionStiffness = {90}
                    motionDamping   = {15}
                    isInteractive   = {true}
                    tooltip         = {(element) => {
                        console.log(element)
                        return (
                        <div 
                            style={{
                                color   : 'white',
                                fontSize: 12
                            }}
                        >
                            {formatToCurrency(element.value)}
                        </div>
                      )}}
                    theme           = {{
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        },
                    }}
                /></div>
                <div style={{display: 'flex'}}>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.ytd} 
                                onChange    = {this.handleUpdateYTD} 
                                type        = "checkbox"
                            /> YTD
                        </label>
                    </div>
                    <div className="uk-width-1-2">
                        <label style={{fontSize: '12px'}}>
                            <input 
                                className   = "uk-checkbox" 
                                checked     = {this.state.last12months} 
                                onChange    = {this.handleUpdateLast12Months} 
                                type        = "checkbox"
                            /> Last 12 months
                        </label>
                    </div>
                </div>
            </div>
          </div>
        );*/
    }
}
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { IgrDataGridModule, IgrTextColumn, IgrColumnGroupDescription, IgrGridColumnOptionsModule, HeaderClickAction, IgrHeader, IgrTemplateHeaderCellUpdatingEventArgs, IgrTemplateHeader   } from 'igniteui-react-grids';
import { IgrDataGrid } from 'igniteui-react-grids';
import { DataSourceSectionHeaderDisplayMode } from 'igniteui-react-core';
import {getListofDeals} from '../../networking/NetworkingPosition';

import LoadingBox from '../shared/LoadingBox';

import {getStyle} from '../../styles/styles';

var style = getStyle();
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class PositionMonitorTable extends React.Component {
    constructor(props){
        super(props);
        this.HorizontalRightHeader = new IgrTemplateHeader({});
        this.HorizontalRightHeader.cellUpdating = (s, e) => this.onHorizontalHeaderUpdating(s, e, "right");
    }

    state = {
        loading: false,
        open: false,
        columns: [],
        data: [],
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.open !== this.props.open) {
            if(this.props.open){
                this.setState({
                    loading: true,
                })
                this.loadDeals();
            }
        }
    }

    loadDeals = () => {
        getListofDeals(this.props.currencyID, this.props.dateFrom, this.props.dateTo).then((response) => {
            if(response.deals){
                console.log(response.deals);
                let finalData = [];
                response.deals.forEach(ele => {
                    ele.Group = ele['Value Date'] + ' Total Bought: ' + ele['Total Bought'] + ' Total Sold: ' + ele['Total Sold'];
                    finalData.push(ele);
                })
                this.defineColumns(finalData);
                this.setState({open: true, data: finalData});
                let groupDate = new IgrColumnGroupDescription();
                groupDate.field = "Group";
                groupDate.displayName = "";
                this.grid.groupDescriptions.add(groupDate);
                this.grid.isGroupCollapsable = true;
                this.grid.groupHeaderDisplayMode = DataSourceSectionHeaderDisplayMode.Combined;
            }
            this.setState({
                loading: false,
            })
        });
    }

    onGridRef = (grid) => {
        if (!grid) { return; }

        this.grid = grid;
        //this.grid.actualDataSource.isSectionExpandedDefault = true;
    }

    onHorizontalHeaderUpdating = (s, e, align) => {
        const content = e.content; //as HTMLDivElement;
        let label = null;
        if (content.childElementCount === 0) {
            content.style.lineHeight = "25px";
            label = document.createElement("div");
            label.style.background = "transparent";
            label.style.color = "rgb(24, 29, 31)";
            label.style.overflowWrap = "break-word"
            //label.style.fontSize = "13px";
            //label.style.fontFamily = "Verdana";
            label.style.verticalAlign = "center";
            label.style.textAlign = align;
            content.appendChild(label);
        } else {
            label = content.children[0] ; //as HTMLDivElement;
        }

        const info = e.cellInfo; // as IgrTemplateCellInfo;
        label.textContent = info.value;
    }

    onClose = () => {
        this.setState({
            open: false,
            loading: false,
        })
        this.props.onClose();
    }

    defineColumns = (data) => {
        let columnHelper = [];
        let keys = Object.keys(data[0]);
        keys.forEach(element => {
            if (!element.includes('$')) {
                if(element === 'Currency' || element === 'Description' || element === 'Total' || element === 'Group' || element === 'Value Date' || element === 'Total Bought' || element === 'Total Sold'){
                    if(element === 'Currency' || element === 'Group' || element === 'Value Date' || element === 'Total Bought' || element === 'Total Sold') {
                        //columnHelper.push({key: element, name: '', lock: true});
                    } else {
                        columnHelper.push({key: element, name: capitalize(element), lock: true});
                    } 
                } else {
                    columnHelper.push({key: element, name: capitalize(element), lock: false});
                }
            }
        });

        let columnDirectives = [];
        this.setState({columnsHelper: columnHelper});
        console.log(columnHelper);
        columnHelper.forEach(element => {
            //columnDirectives.push(<ColumnDirective key={element.key} field={element.key} width='200' textAlign="Left" headerText={element.name} />) 
            if (element.lock) {
                if(element.key === "Total"){
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} minWidth="100" horizontalAlignment="right"/>);
                } else {
                    columnDirectives.push(<IgrTextColumn key={element.key} pinned="left" field={element.key} headerText={element.name} minWidth="100"/>);
                }
                
            } else {
                columnDirectives.push(<IgrTextColumn key={element.key} field={element.key} headerText={element.name} header={this.HorizontalRightHeader} minWidth="100" horizontalAlignment="right"/>);
            }
            
        })

        this.setState({columns: columnDirectives});
    }

    render() {
        return(
            <div>
                <Dialog open={this.state.open} onClose={this.onClose} fullWidth={true} maxWidth='lg'>
                    <DialogTitle>List of Deals</DialogTitle>
                    <DialogContent>
                        {
                            this.state.data.length < 0 && 
                            (<h5>No deals available.</h5>)
                        }
                        <IgrDataGrid
                                height="80vh"
                                width="100%"
                                //headerHeight="50"
                                dataSource={this.state.data}
                                ref={this.onGridRef}
                                autoGenerateColumns="false"
                                defaultColumnMinWidth="100"
                                isColumnOptionsEnabled="false"
                                headerClickAction={HeaderClickAction.None}
                                editMode="None"
                                //summaryScope="Root"
                                //isColumnOptionsEnabled="true"
                                //isGroupCollapsable="true"
                                //groupHeaderDisplayMode="Combined"
                                //groupSummaryDisplayMode="RowBottom"
                                //columnMovingMode="Deferred"
                                //columnMovingAnimationMode="SlideOver"
                                //columnMovingSeparatorWidth="2"
                                //columnShowingAnimationMode="slideFromRightAndFadeIn"
                                //columnHidingAnimationMode="slideToRightAndFadeOut"
                                selectionMode="SingleCell"
                                cornerRadiusTopLeft="0"
                                cornerRadiusTopRight="0"
                                >
                                    {this.state.columns}
                                </IgrDataGrid>
                    </DialogContent>
                    <DialogActions>
                        <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={this.onClose}>Close</button>
                    </DialogActions>
                </Dialog>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}
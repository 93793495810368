// export interface LookUpTableDetail {
//     DisplayOrder: number;
//     Editable: boolean;
//     Field1: string;
//     Field2: string;
//     Field3: string;
//     Field4: string;
//     Field5: string;
//     Field6: string;
//     LookUpTableDetail: string;
//     MappingId: number;
//     name: string;
//     value: string;
//     label: string;
//     LookUpTableDetailTranslationLabel: string;
// }

// Usage Example:
// const lookUpTables = useLookUpTables('Confirmation Type,Salutations');
// console.log(lookUpTables) // Initial value
// {
//    ConfirmationType: [],
//    Salutation: []
// }
// console.log(lookUpTables) // After getting data from API
// {
//    ConfirmationType: [LookUpTableDetail],
//    Salutation: [LookUpTableDetail]
// }

import { useEffect, useMemo, useState } from 'react';
import { removeWhiteSpace } from '../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../networking/Networking';

const useLookUpTables = (descriptionString) => {
    const lookUpTablesDescriptionsArray = useMemo(() => descriptionString.split(','), [descriptionString]);
    const [lookUpTables, setLookUpTables] = useState(
        lookUpTablesDescriptionsArray.reduce((accumulator, currentValue) => ({ ...accumulator, [removeWhiteSpace(currentValue)]: [] }), {})
    );

    useEffect(() => {
        const getLookUpTables = async (tables) => {
            const getDetail = (array, description) => {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].LookUpTableDescription === description) {
                        return array[i].lookUpTableDetails.map((d) => ({ ...d, name: d.LookUpTableDetail, value: d.LookUpTableDetailTranslationLabel }));
                    }
                }
                return [];
            };
            const json = await getLookUpTable(tables.toString());
            if (json != null && json.lookUpTables != null) {
                const formattedLookUpTables = lookUpTablesDescriptionsArray.reduce(
                    (accumulator, currentValue) => ({ ...accumulator, [removeWhiteSpace(currentValue)]: getDetail(json.lookUpTables, currentValue) }),
                    {}
                );
                setLookUpTables(formattedLookUpTables);
            }
        };
        getLookUpTables(lookUpTablesDescriptionsArray);
    }, [descriptionString, lookUpTablesDescriptionsArray]);

    return lookUpTables;
};

export default useLookUpTables;

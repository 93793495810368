import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from './Networking';

export async function GetEmails() {
    try {
        let params = {}
        let response = await genericGetWithParameters('Email/GetEmails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetEmailById(emailId) {
    try {
        let params = {
            'EmailId': emailId
        }
        let response = await genericGetWithParameters('Email/GetEmailById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function DeleteEmail(emailId){    
    try{
        let params = {
            'EmailId': emailId
        };
        let response = await genericDeleteWithParameters('Email/DeleteEmail',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function UpdateEmail(model) {
    let method = 'PUT';
    let apiName = 'Email/UpdateEmail';

    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const sendReferralEmail = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'Email/SendReferralEmail', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography } from 'antd';
import 'antd/dist/antd.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import { ActionName } from '../../../constants/ActionNames';
import '../../../css/ant.css';
import { deleteCountry, getCountries, saveCountry } from '../../../networking/NetworkingCountries';
import { getCurrencies } from '../../../networking/NetworkingCurrencies';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import { getScreen } from '../../shared/ScreenHelper';
import NewStateScreen from './NewStateScreen';

var style = getStyle();
const { Option } = Select;

export default function NewCountryScreen() {
    const context = useRef(useContext(AppContext));
    const [form]                                = Form.useForm();
    const [countries, setCountries]             = useState([]);
    const [currencies, setCurrencies]           = useState([]);
    const [updating, setUpdating]               = useState(false);          // Para saber cuando volver a cargar el useEffect
    const [editingKey, setEditingKey]           = useState('');
    // const [deletingKey, setDeletingKey]         = useState('');
    const [isCheckedStatus, setCheckedStatus]   = useState(false);
    // const [firstime, setFirsttime]              = useState(true);
    const [currentPage, setCurrentPage]         = useState();
    const [selectedCountryID, setSelectedCountryID] = useState('');

    //Filters --------------------------------------------------------------------
    // const [filteringSomething, setFilteringSomething]   = useState(false);
    const [searchText, setSearchText]                   = useState('');
    const [searchedColumn, setSearchedColumn]           = useState('');
    const [searchArray, setSearchArray]                 = useState({});
    const searchInput                                   = useRef();
    //----------------------------------------------------------------------------

    const [viewSettingsScreen, setViewSettingsScreen] = useState(false);
    const [additionalSettingsObj, setAdditionalSettingsObj] = useState({});

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(()=>{
        const loadCountriesAndCurrencies = async () => {
            context.current.startLoading();
            const value = await getCountries(0,0);
            if(value !== undefined && value.countries != null) {
                const countries = value.countries.map((c) => ({...c, key: `CountryScreen-${c.Country}`}));
                setCountries(countries);
            }
            const json = await getCurrencies(0,0);
            if(json !== undefined && json.currencies != null) {
                const currencies = json.currencies.map((c) => ({...c, key: c['Currency Id']}));
                setCurrencies(currencies);
            }
            context.current.finishLoading();
        }
        loadCountriesAndCurrencies();
    },[updating]);

    const isEditing = (record) => record.Country === editingKey;

    // const isDeleting = (record) => record.Country === deletingKey;

    const edit = (record) => {

        if(countries[0].Country === '')
        {
            let auxCountries = [...countries];
            auxCountries.shift();
            setCountries(auxCountries);
        }

        form.setFieldsValue({
            'Country'       : "",
            'Country Name'  : "",
            'Currency'      : "",
            'Display Order' : 0,
            'Num ID'        : 0,
            'Risk Score'    : 0,
            'UpdateToken'   : 0,
            'Status': record.Status === 'Authorized to deal',
        ...record,
        });
        setCheckedStatus(record.Status === 'Authorized to deal');
        setEditingKey(record.Country);
    };

    const viewSettings = (record) => {
        setAdditionalSettingsObj({...record, showBreadcrumb: true, parentScreenName: 'Currencies' });
        setViewSettingsScreen(true);
    };

    const deleteRecord = async (record) => {
        context.current.startLoading();
        const deleteResponse = await deleteCountry(record.Country);
        context.current.finishLoading();
        if (deleteResponse != null) 
        {
          let message = 'Something went wrong loading the data. Please try again later.';
            if (deleteResponse.httpStatusCode !== 200) 
            {
              switch (deleteResponse.httpStatusCode) 
              {
                case 412:
                {  
                  if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                  {
                    message = deleteResponse.Message
                  }
                  break;
                }
                default:
                {
                  if(deleteResponse.ErrorMessage !== undefined && deleteResponse.ErrorMessage !== null && deleteResponse.ErrorMessage !== '')
                  {
                    message = deleteResponse.ErrorMessage
                  }
                  break;
                }
              }

              setShowAlert(true);
              setAlertTitle('Error');
              setAlertMessage(message);
            } else {
              setShowAlert(true);
              setAlertTitle('Success');
              setAlertMessage('Country successfully removed.');
              setUpdating(!updating);
            }
        }else
        {
          setShowAlert(true);
          setAlertTitle('Error');
          setAlertMessage('Something went wrong loading the data. Please try again later.');
        }
    };

    const cancel = (page, pageSize) => {
        setEditingKey('');
        // setFirsttime(true);
        if(typeof page === 'number')
        {
            setCurrentPage(page);
        }
        
        if(countries[0].Country === '')
        {
            let auxCountries = [...countries];
            auxCountries.shift();
            setCountries(auxCountries);
        }
    };

    const save = async (Country) => {
        try 
        {
            const row = await form.validateFields();
            /*
                Country         : "xxx"
                Country Name    : "Afghanistanxxx"
                Currency        : "AFNxxx"
                Display Order   : 2222
                Risk Score      : 22
                Status          : "Authorized to deal"
            */
            
            const newData = [...countries];
            const index = newData.findIndex((item) => Country === item.Country);

            if (index > -1) 
            {
                const item = newData[index];
                const token = item.UpdateToken$;
                            
                var objCountry = {
                    CountryId   : row.Country,
                    CountryName : row['Country Name'],
                    CurrencyId  : row['Currency'],
                    DisplayOrder: row['Display Order'],
                    RiskScore   : row['Risk Score'],
                    Status      : isCheckedStatus !== false ? 'Authorized to deal': '',
                    ISOCode     : '',
                    UserId      : localStorage.getItem('UserID'),
                    UpdateToken : token
                }
                context.current.startLoading();
                const json = await saveCountry(objCountry);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setUpdating(!updating);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Country successfully saved.');
                        setUpdating(!updating);
                    }
                }
                /*newData.splice(index, 1, { ...item, ...row });
                setCountries(newData);*/
                setEditingKey('');
                // setFirsttime(true);
            }else{
                newData.push(row);
                setCountries(newData);
                setEditingKey('');
                // setFirsttime(true);
            }
        } catch (errInfo)
        {
            console.log('Validate Failed:', errInfo);
        }
    };

    let currencyFilters = []
    
    currencies.forEach(currency => {
        currencyFilters.push({
            text    : currency['Currency Name'],
            value   : currency['Currency Id'],
        });
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {        
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const settingSearchStateValues = (value, col)=>{
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...searchArray[col] = value);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
                ref           = {node => {searchInput.current = node;}}
                placeholder   = {`Search ${dataIndex}`}
                value         = {selectedKeys[0]}
                onChange      = {(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : [])}
                }
                onPressEnter  = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                style         = {{
                    width       : 188, 
                    marginBottom: 8, 
                    display     : 'block' 
                }}
            />
            <Space>
                <Button
                    type    = "primary"
                    onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon    = {<SearchOutlined />}
                    size    = "small"
                    style   = {{ width: 90 }}
                >Search
                </Button>
                <Button 
                    onClick = {() => {
                        handleReset(clearFilters);
                    }}
                    size    = "small" 
                    style   = {{ width: 90 }}
                >Reset
                </Button>
                <Button
                    type    = "link"
                    size    = "small"
                    onClick = {() => {
                        confirm({ closeDropdown: false });
                        settingSearchStateValues(selectedKeys[0], dataIndex);
                    }}
                >Filter
                </Button>
            </Space>
          </div>
        ),
        filterIcon                      : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter                        : (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange   : visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render                          : text => searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle    = {{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords       = {[searchText]}
              autoEscape
              textToHighlight   = {text ? text.toString() : ''}
            />
          ) : (
            text
          ),
    });

    const columns = [
        {
            title           : 'Country',
            dataIndex       : 'Country',
            width           : '10%',
            editable        : true,
            align           : 'center',
            defaultSortOrder: 'ascend',
            sorter          : (a, b) => { return a.Country.localeCompare(b.Country)},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Country'),
        },
        {
            title           : 'Country Name',
            dataIndex       : 'Country Name',
            width           : '20%',
            editable        : true,
            align           : 'left',
            sorter          : (a, b) => { return a['Country Name'].localeCompare(b['Country Name'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Country Name'),
        },
        {
            title           : 'Currency',
            dataIndex       : 'Currency',
            width           : '20%',
            editable        : true,
            align           : 'center',
            sorter          : (a, b) => { return a.Currency.localeCompare(b.Currency)},
            sortDirections  : ['descend', 'ascend'],
            filters         : currencyFilters,
            filterMultiple  : false,
            onFilter        : (value, record) => {  
                if(record.Currency.indexOf(value) === 0)
                {
                    // setFilteringSomething(true);
                    return true;
                }
            },
            // onReset         : () =>{setFilteringSomething(false);}
        },
        {
            title           : 'Risk Score',
            dataIndex       : 'Risk Score',
            width           : '10%',
            editable        : true,
            align           : 'center',
            sorter          : (a, b) => a['Risk Score'] - b['Risk Score'],
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title           : 'Display Order',
            dataIndex       : 'Display Order',
            width           : '10%',
            editable        : true,
            align           : 'center',
            sorter          : (a, b) => a['Display Order'] - b['Display Order'],
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title           : 'Status',
            dataIndex       : 'Status',
            width           : '15%',
            editable        : true,
            align           : 'center',
            sorter          : (a, b) => { return a.Status.localeCompare(b.Status)},
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <a
                        onClick = {() => save(record.Country)}
                        style   = {{
                            marginRight: 8,
                        }}
                    >Save</a>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <a>Cancel</a>
                    </Popconfirm>
                </span>
                ) : (
                <div>
                    <Space size="middle">
                        <Typography.Link disabled={editingKey !== '' ? true: (countries[0]['Country'] === '') ? true: false} onClick={() => viewSettings(record)}>
                            Settings
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== '' ? true: (countries[0]['Country'] === '') ? true: false} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Popconfirm 
                            title       = "Sure to delete?"
                            onConfirm   = {() => {deleteRecord(record)}}
                        >
                            <a disabled    = {editingKey !== '' ? true: (countries[0]['Country'] === '') ? true: false} >Delete</a>
                        </Popconfirm>
                    </Space>
                </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) 
        {
            return col;
        }

        let type = '';

        switch (col.dataIndex) 
        {
            case 'Risk Score':
            case 'Display Order':
            {
                type = 'number';
                break;
            }
            case 'Status':
            {
                type = 'check';
                break;
            }
            case 'Currency':
            {
                type = 'select';
                break;
            }
            default:
            {
                type = 'text'
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType   : type,
                dataIndex   : col.dataIndex,
                title       : col.title,
                editing     : isEditing(record),
            }),
        };
    });

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
        }) => {
        const onChange = (e) => {
            setCheckedStatus(e.target.checked);
            form.setFieldsValue({
                'Status'        : e.target.checked,
            });
        }
    
        let inputNode = null;
        let allOptionsOfCurrencies = [];

        currencies.forEach(currency => {
            /*
                {
                    Available Online: "False"
                    Currency Id: "AED"
                    Currency Name: "United Arab Emirates dirham"
                    Min Denomination: "0.03"
                    Risk Score: 1
                    Status: "Active"
                    Symbol: "("
                    UpdateToken: "637362932020866667"
                }
            */
            allOptionsOfCurrencies.push(<Option key={currency['Currency Id']} value={currency['Currency Id']}>{currency['Currency Id'] + ' - ' + currency['Currency Name']}</Option>)
        })
        
        switch (inputType) 
        {
            case 'number':
            {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                break;
            }
            case 'select':
            {
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select a Currency"
                        optionFilterProp    = "children"
                        /*onChange            = {onChangeCurrencySelect}
                        onFocus             = {onFocusCurrencySelect}
                        onBlur              = {onBlurCurrencySelect}
                        onSearch            = {onSearchCurrencySelect}*/
                        filterOption        = {
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allOptionsOfCurrencies}
                    </Select>
                );
                break;
            }
            case 'check':
            {
                inputNode = <Checkbox onChange={onChange} checked={isCheckedStatus}>Authorized Deal</Checkbox>;
                break;
            }
            default:
            {
                inputNode = <Input style={{textAlign: 'center'}}/>;
                break;
            }
        }

        let styleEdit = {margin: 0};

        if((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing)
        {
            styleEdit = {
                paddingBottom   : 10, 
                paddingTop      : 10,
                margin          : 0,
            }
        }

        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={styleEdit}
                rules={[
                  {
                    required: true,
                    message : `Please Input ${title}!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
          return <a>Previous</a>;
        }
        if (type === 'next') {
          return <a>Next</a>;
        }
        return originalElement;
    }

    const handleBackClickOfCountryAdditionalSettings = () => {
        setViewSettingsScreen(false);
        setAdditionalSettingsObj({})
    };

    const selectCountry = (record) => {
        setSelectedCountryID(record.Country);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    if (viewSettingsScreen) {
        return (getScreen(ActionName.ShowCountryAdditionalSettings, additionalSettingsObj, handleBackClickOfCountryAdditionalSettings, null));
    }

    return(
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >Countries</h3>
            <div key="country-original-screen">
                <ul uk-tab="">
                    <li><a href="#">Country</a></li>
                    <li><a href="#">State</a></li>
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li>
                        <Form form={form} component={false}>
                            <Table
                                components      = {{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                bordered        = {true}
                                dataSource      = {countries}
                                columns         = {mergedColumns}
                                rowClassName    = "editable-row"
                                pagination      = {{
                                    onChange        : cancel,
                                    showQuickJumper : true,
                                    itemRender      : itemRender,
                                    current         : currentPage,
                                    defaultCurrent  : 1,
                                    size            : 'default'
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => selectCountry(record)
                                    }
                                }}
                                size            = 'small'
                                footer          = {() => {
                                    return(
                                        <Button 
                                            type        = "dashed" 
                                            onClick     = {() => {
                                                // console.log('editingKey' + editingKey);
                                                // console.log('searchText' + searchText);
                                                // console.log('searchedColumn' + searchedColumn);
                                                let actual = [...countries];
                                                if(actual[0].Country === '')
                                                {
                                                    return;
                                                }else{
                                                    actual.unshift({
                                                        'key'           : 'CountryScreen-new',
                                                        'Country'       : "",
                                                        'Country Name'  : "",
                                                        'Currency'      : "",
                                                        'Display Order' : 0,
                                                        'Num ID'        : 0,
                                                        'Risk Score'    : 0,
                                                        'UpdateToken'   : 0,
                                                        'isNew'         : true,
                                                    });
                                                    setCheckedStatus(false);
                                                    setCountries(actual);
                                                    setCurrentPage(1);
                                                    form.setFieldsValue({
                                                        'Country'       : "",
                                                        'Country Name'  : "",
                                                        'Currency'      : "",
                                                        'Display Order' : 0,
                                                        'Risk Score'    : 0,
                                                        ...actual
                                                    });
                                                }
                                            }}
                                            block
                                            icon        = {<PlusOutlined />}
                                            style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                            disabled    = {
                                                //(editingKey === '' && (searchText === '' || searchedColumn === '') && filteringSomething === false ) ? false: true
                                                editingKey !== '' ? true: (countries[0] !== undefined && countries[0]['Country'] === '') ? true: false                                        
                                            }
                                        >Add a Country
                                        </Button>
                                    );
                                }}
                            />
                        </Form>
                    </li>
                    <li>
                        <NewStateScreen selectedCountry={selectedCountryID} />
                    </li>
                </ul>
            </div>
            <AlertBox id="alert-new-countries" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}
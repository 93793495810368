import jwt from 'jsonwebtoken';
import {genericCallWithBody, 
        genericGetWithParameters, 
        genericGetWithParametersNoEncrypted, 
        genericCallWithBodyAndResponseDecoded} from './Networking';

export async function saveDealTransfer(objDealTransfer){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'DealTransfers/SaveDealTransfers';
    let response = await genericCallWithBody(method, apiName, objDealTransfer);
    return response;
}

export async function getBankAccountsForDealTransfer() {
    try {
        let params = {}
        let response = await genericGetWithParameters('DealTransfers/GetBankAccountsForDealTransfer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
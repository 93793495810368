import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters } from "./Networking";

export async function getUserDefinedProperties(subEntityType, page, items){
    try {
        let params = {
            "subEntityType": subEntityType,
            "page": page,
            "items":items
        };

        let response = await genericGetWithParameters('UserDefinedProperties/Get',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getUserDefinedPropertiesById(userPropertyId){
    try {
        let params = {
            "userPropertyId": userPropertyId
        };

        let response = await genericGetWithParameters('UserDefinedProperties/GetUserDefinedPropertiesById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveProperty(model){

    try{
        let response = await genericCallWithBody('POST','UserDefinedProperties/SaveUserDefinedProperties',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteUserProperty(id){
    let params = {
        'userPropertyId': id,
    };
    let response = await genericDeleteWithParameters('UserDefinedProperties/DeleteUserDefinedProperties',params);
    return response;
}

export const saveUserPropertiesExt = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'UserDefinedProperties/SaveUserDefinedPropertiesExt', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export async function getSubEntityTypes(){
    try {
        

        let response = await genericGetWithParameters('UserDefinedProperties/GetSubEntityTypes',null);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteUserPropertyExt(id){
    let params = {
        'userPropertyID': id,
    };
    let response = await genericDeleteWithParameters('UserDefinedProperties/DeleteUserDefinedPropertiesExt',params);
    return response;
}

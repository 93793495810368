import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import { getEODParameters } from '../../../helpers/PackageJsonHelper';
import { deleteAdminActions, getAdminActions, saveAdminActions } from '../../../networking/NetworkingActions';
import AlertBox from '../../shared/AlertBox';
import AppContext from '../../../AppContext';
import Highlighter from 'react-highlight-words';
import TheCSVButton from '../../shared/TheCSVButton';

const ActionsScreen = () => {
    const context = useRef(useContext(AppContext));
    const ApplicationName = useMemo(() => getEODParameters().ApplicationName, []);
    const [form] = Form.useForm();
    const [actions, setActions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [updating, setUpdating] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    //Search
  const [filteringSomething, setFilteringSomething] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchArray, setSearchArray] = useState({});
  const searchInput = useRef();

  const defaultRowsSize = window.DEFAULT_ROWS_SIZE??20;

    useEffect(() => {
        const loadActions = async () => {
            const json = await getAdminActions(ApplicationName, 0, 0);
            if (json != null && json.result != null) {
                const _actions = json.result.map((a) => ({ ...a, key: `Action-${a.ActionName}` }));
                setActions(_actions);
            }
        };
        loadActions();
    }, [ApplicationName, updating]);

    const isEditing = (record) => record.ActionName === editingKey;

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            const userID = localStorage.getItem('UserID');
            const model = {
                ApplicationName: ApplicationName,
                ActionName: row.ActionName,
                Rule: row.Rule,
                Description: row.Description,
                ShortName: row['Short Name'],
                Keywords: row.Keywords,
                IconName: row.IconName,
                UserID: userID,
                UpdateToken: currentUpdateToken
            };
            console.log('Model');
            console.log(model);
            console.log(row);
            const json = await saveAdminActions(model);
            if (json != null) {
                if (json.httpStatusCode === 200) {
                    setCurrentUpdateToken('');
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Action successfully saved.');
                    setEditingKey('');
                    setUpdating(!updating);
                    form.resetFields();
                } else {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteRecord = async (record) => {
        const json = await deleteAdminActions(ApplicationName, record.ActionName);
        if (json != null) {
            if (json.httpStatusCode === 200) {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Action successfully deleted.');
            } else {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.Message);
            }
            setUpdating(!updating);
        }
    };

    const edit = (record) => {
        if (actions[0].ActionName === '') {
            let auxActions = [...actions];
            auxActions.shift();
            setActions(auxActions);
        }

        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record.ActionName);
        setCurrentUpdateToken(record.UpdateToken);
    };

    const cancel = (page) => {
        setEditingKey('');
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (actions[0].ActionName === '') {
            let auxActions = [...actions];
            auxActions.shift();
            setActions(auxActions);
        }
        form.resetFields();
        setCurrentUpdateToken('');
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'ActionName': {
                    return [
                        {
                            required: true,
                            mesage: 'Please Input a valid action name!'
                        }
                    ];
                }
                default:
                    return [
                        {
                            required: false
                        }
                    ];
            }
        };
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules}>
                        <Input style={{ textAlign: 'center' }} />
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

      //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setFilteringSomething(true);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilteringSomething(false);
  };

  const settingSearchStateValues = (value, col) => {
    setSearchText(value);
    setSearchedColumn(col);
    setSearchArray(...(searchArray[col] = value));
    setFilteringSomething(true);
  };
  //

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref         = {(node) => {
                searchInput.current = node;
              }}
              placeholder = {`Search ${dataIndex}`}
              value       = {selectedKeys[0]}
              onChange    = {(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width       : 188,
                marginBottom: 8,
                display     : 'block'
              }}
            />
            <Space>
              <Button 
                type    = "primary" 
                onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)} 
                icon    = {<SearchOutlined />} 
                size    = "small" 
                style   = {{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                    handleReset(clearFilters);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type    = "link"
                size    = "small"
                onClick = {() => {
                  confirm({ closeDropdown: false });
                  settingSearchStateValues(selectedKeys[0], dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: (text) => (
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : ( text )
        )
      });

    const columns = [
        {
            title: 'Action Name',
            dataIndex: 'ActionName',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.ActionName.localeCompare(b.ActionName);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('ActionName')
        },
        {
            title: 'Rule',
            dataIndex: 'Rule',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.Rule.localeCompare(b.Rule);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Short Name',
            dataIndex: 'Short Name',
            width: '10%',
            editable: true,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Short Name')
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '10%',
            editable: true,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Description')
        },
        {
            title: 'Keywords',
            dataIndex: 'Keywords',
            width: '10%',
            editable: true,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Keywords')
        },
        {
            title: 'Icon Name',
            dataIndex: 'IconName',
            width: '10%',
            editable: true,
            align: 'center',
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== '' || actions[0].ActionName === ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a disabled={editingKey !== '' || actions[0].ActionName === ''}>Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const addNewAction = () => {
        let actual = [...actions];
        if (actual[0] != null && actual[0].ActionName === '') {
            return;
        } else {
            actual.unshift({
                'key': 'Actions-new',
                'ActionName': '',
                'Rule': '',
                'Short Name': '',
                'Description': '',
                'Keywords': '',
                'IconName': '',
                UpdateToken: '',
                isNew: true
            });
            setActions(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                ...actual
            });
            setCurrentUpdateToken('');
        }
    }

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Admin Actions</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Form form={form}>
            <div className="uk-overflow-auto">
                        <TheCSVButton board={'Actions'} data={actions} useIcon={true}/>
                    </div>
                <Table components={{ body: { cell: EditableCell } }} dataSource={actions} columns={columns} size="small" 
                pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default',
                        defaultPageSize: defaultRowsSize
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewAction}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={editingKey !== '' || (actions[0] !== undefined && actions[0].ActionName === '')}
                        >
                            Add an Action
                        </Button>
                    )}></Table>
            </Form>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default ActionsScreen;

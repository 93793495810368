import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import { deleteCurrencyMessage, getCurrencyMessageByID, getCurrencyMessageForCurrencyID, saveCurrencyMessage } from '../../../networking/NetworkingCurrencies';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import useCurrenciesList from '../blotter/hooks/useCurrenciesList';
const dateFormat = 'YYYY/MM/DD';
const style = getStyle();

const NewCurrencyMessages = ({ selectedCurrency }) => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [currencyMessages, setCurrencyMessages] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [selectedCurrencyID, setSelectedCurrencyID] = useState(selectedCurrency);

    const [editingKey, setEditingKey] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const [currencies] = useCurrenciesList();

    const [currentCurrencyMessageID, setCurrentCurrencyMessageID] = useState('');
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setSelectedCurrencyID(selectedCurrency);
    }, [selectedCurrency]);

    useEffect(() => {
        const loadCurrencyMessages = async () => {
            context.current.startLoading();
            console.log(selectedCurrencyID)
            const json = await getCurrencyMessageForCurrencyID(selectedCurrencyID, 0, 0);
            context.current.finishLoading();
            console.log(json);
            if (json != null && json.currencyMessage != null) {
                const currencyMessages = json.currencyMessage.map((m) => ({...m, key: m.CurrencyMessageId}));
                setCurrencyMessages(currencyMessages);
            } else {
                setCurrencyMessages([]);
            }
        };
        loadCurrencyMessages();
    }, [updating, selectedCurrencyID]);

    const isEditing = (record) => record['CurrencyMessageId'] === editingKey;

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log(row);
            const newData = [...currencyMessages];
            const index = newData.findIndex((item) => record['CurrencyMessageId'] === item['CurrencyMessageId']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    CurrencyMessageId: currencyMessages[index]['CurrencyMessageId'] !== 0 ? currentCurrencyMessageID : 0,
                    CurrencyId: row['Currency ID'],
                    EffectiveDate: row['Effective Date'].format(dateFormat),
                    ExpiryDate: row['Expiry Date'].format(dateFormat),
                    Message: row['Message'],
                    UserId: userID,
                    UpdateToken: currentUpdateToken
                };
                console.log(model)
                context.current.startLoading();
                const json = await saveCurrencyMessage(model);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setUpdating(!updating);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Currency Message successfully saved.');
                        setUpdating(!updating);
                        setEditingKey(0);
                        setCurrentCurrencyMessageID(0);
                        setCurrentUpdateToken('');
                    }
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = async (record) => {
        console.log(record)
        if (currencyMessages[0]['CurrencyMessageId'] === 0) {
            let auxCurrencyMessages = [...currencyMessages];
            auxCurrencyMessages.shift();
            setCurrencyMessages(auxCurrencyMessages);
        }

        form.setFieldsValue({
            UpdateToken: '',
            ...record,
            'Effective Date': moment(record['Effective Date'], dateFormat),
            'Expiry Date': moment(record['Expiry Date'], dateFormat)
        });
        context.current.startLoading();
        const json = await getCurrencyMessageByID(record['CurrencyMessageId']);
        context.current.finishLoading();
        setEditingKey(record['CurrencyMessageId']);
        setCurrentCurrencyMessageID(record['CurrencyMessageId']);
        setCurrentUpdateToken(json.UpdateToken);
    };

    const deleteRecord = async (record) => {
        context.current.startLoading();
        const json = await deleteCurrencyMessage(record['CurrencyMessageId']);
        context.current.finishLoading();
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
                setUpdating(!updating);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Currency Message successfully removed.');
                setUpdating(!updating);
            }
        }
    };

    const cancel = (page) => {
        setEditingKey(0);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (currencyMessages[0]['CurrencyMessageId'] === 0) {
            let auxCurrencyMessages = [...currencyMessages];
            auxCurrencyMessages.shift();
            setCurrencyMessages(auxCurrencyMessages);
        }
        setCurrentCurrencyMessageID(0);
        setCurrentUpdateToken('');
    };

    const addNewCurrencyMessage = () => {
        let actual = [...currencyMessages];
        if (actual[0] != null && actual[0]['CurrencyMessageId'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'CurrencyMessage-new',
                'CurrencyMessageId': 0,
                'Currency ID': selectedCurrencyID,
                'Effective Date': '',
                'Expiry Date': '',
                'Message': '',
                UpdateToken: '',
                isNew: true
            });
            setCurrencyMessages(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Currency ID': selectedCurrencyID,
                'Effective Date': '',
                'Expiry Date': '',
                'Message': '',
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentCurrencyMessageID(0);
        }
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...(searchArray[col] = value));
        setFilteringSomething(true);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const disabledDate = (current) => {
            return current.day() === 0 || current.day() === 6 || current < moment().startOf('day');
        };

        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center' }} />;
                break;
            }
            case 'select': {
                inputNode = (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Currency..."
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        disabled={true}
                    >
                        {currencies.map((currency) => (
                            <Select.Option key={`CurrencyMessage-option-${currency.value}`} value={currency.value}>{currency.name}</Select.Option>
                        ))}
                    </Select>
                );
                break;
            }
            case 'date': {
                inputNode = (
                    <DatePicker format={dateFormat} disabledDate={disabledDate} />
                )
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }}/>;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'Effective Date':
                case 'Expiry Date': {
                    return {
                        type: 'object'
                    }
                }
                default: return {};
            }
        }

        const rules = {
            required: true,
            message: `Please Input a ${title}!`,
            ...getCustomRules(dataIndex)
        };

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={[rules]}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        {
            title: 'Currency ID',
            dataIndex: 'Currency ID',
            width: '10%',
            editable: true,
            align: 'center',
            ...getColumnSearchProps('Currency ID')
        },
        {
            title: 'Effective Date',
            dataIndex: 'Effective Date',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Effective Date'].localeCompare(b['Effective Date']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Effective Date')
        },
        {
            title: 'Expiry Date',
            dataIndex: 'Expiry Date',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Expiry Date'].localeCompare(b['Expiry Date']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Expiry Date')
        },
        {
            title: 'Message',
            dataIndex: 'Message',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Message'].localeCompare(b['Message']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Message')
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== 0 || currencyMessages[0]['CurrencyMessageId'] === 0} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a disabled={editingKey !== 0 || currencyMessages[0]['CurrencyMessageId'] === 0}>Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Currency ID': {
                type = 'select';
                break;
            }
            case 'Effective Date':
            case 'Expiry Date': {
                type = 'date';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const closeAlert = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle}>Currency Messages</h3>
            <Form form={form}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    dataSource={currencyMessages}
                    columns={mergedColumns}
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewCurrencyMessage}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={editingKey !== 0 || filteringSomething || (currencyMessages[0] !== undefined && currencyMessages[0]['CurrencyMessageId'] === 0)}
                        >
                            Add a Currency Message
                        </Button>
                    )}
                />
            </Form>
            <AlertBox id="alert-state" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewCurrencyMessages;

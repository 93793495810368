import React from 'react';
import { getStyle, regularSelectClass, notFilledSelectClass, notFilledInputClass, regularInputClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getListOfBanks, getBanks } from '../../../../networking/NetworkingBanks';
import { getBranchesByBankID, getAllBranches } from '../../../../networking/NetworkingBankBranches';
import { validate } from '../../../../helpers/ValidateHelper';
import { searchCountries, getCountries } from '../../../../networking/NetworkingCountries';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
var style = getStyle();

export default class GenericBankAccountForm extends React.Component {

    state = {
        bankAccount: {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: 0,
            BankName: '',
            BranchID: '',
            BranchName: '',
            CountryID: '',
            CountryIDName: '',
            Swift: '',
            SwiftID: '',
            TransitNo: '',
            UpdateToken: ''
        },
        dropdowns: {
            countryList: [],
            banks: [],
            banksSwift: [],
            branches: []
        },
        validations: {
            bank: true
        },
        allBanks: [],
        allBankSwifts: [],
        allBranches: []
    }

    componentDidMount() {
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ dropdowns: { ...this.state.dropdowns, countryList: countries } },
                        () => {
                            getListOfBanks(false).then( // SWIFT
                                (json) => {
                                    for (var i = 0; i < json.banks.length; i++) {
                                        json.banks[i].value = json.banks[i].BankID;
                                        json.banks[i].name = json.banks[i].Name;
                                    }
                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banksSwift: json.banks }, allBankSwifts: json.banks });
                                    this.setState({ allBankSwifts: json.banks },
                                        () => {
                                            getListOfBanks(true).then( // BANKS
                                                (json) => {
                                                    for (var i = 0; i < json.banks.length; i++) {
                                                        json.banks[i].value = json.banks[i].BankID;
                                                        json.banks[i].name = json.banks[i].Name;
                                                    }
                                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banks: json.banks }, allBanks: json.banks });
                                                    this.setState({ allBanks: json.banks },
                                                        () => {
                                                            getAllBranches().then(
                                                                (json) => {
                                                                    if (json !== undefined) {
                                                                        if (json.branches !== undefined) {
                                                                            for (var i = 0; i < json.branches.length; i++) {
                                                                                json.branches[i].value = json.branches[i]["Branch ID"];
                                                                                json.branches[i].name = json.branches[i].Branch;
                                                                            }
                                                                            /* this.setState({ dropdowns: { ...this.state.dropdowns, branches: json.branches }, allBranches: json.branches },
                                                                                () => {
                                                                                    this.props.isValid(this.validateRequiredInputs())
                                                                                    this.updateInputs()
                                                                                }); */
                                                                            this.setState({ allBranches: json.branches },
                                                                                () => {
                                                                                    this.updateInputs()
                                                                                });
                                                                        }
                                                                    }
                                                                }
                                                            )
                                                        });
                                                }
                                            )
                                        });
                                }
                            )
                        });
                }
            }
        )
    }
    updateInputs = () => {
        if (this.props.data !== undefined) {
            if (this.props.data !== null) {
                var propsData = this.props.data;
                this.setState({ bankAccount: { ...this.state.bankAccount, ...propsData } },
                    () => {
                        this.getBranches(propsData.BankID, undefined);
                    }
                );
                this.filterBanksByCountry(propsData.CountryID);
            }
        }
    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.data != null && this.props.data != null && prevProps.data.UpdateToken !== this.props.data.UpdateToken) {
            const propsData = this.props.data;
            this.setState({ bankAccount: { ...this.state.bankAccount, UpdateToken: propsData.UpdateToken } });
        }

        if (prevProps.data !== this.props.data) {
            this.updateInputs();
        }

        if (prevProps.validated !== this.props.validated && !this.props.validated) {
            this.validateRequiredFields();
        }

        if (this.props.isClearClicked !== prevProps.isClearClicked) {
            this.clearGenericBankAccountForm();
        }
    }

    clearGenericBankAccountForm() {
        var bankAccount = {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: 0,
            BankName: '',
            BranchID: '',
            BranchName: '',
            CountryID: '',
            CountryIDName: '',
            Swift: '',
            SwiftID: '',
            TransitNo: '',
            UpdateToken: ''
        }
        /*var dropdowns = {
            banks: [],
            banksSwift: [],
            branches: []
        }*/

        var validations = {
            bank: true
        }

        this.setState({
            bankAccount: bankAccount,
            validations: validations,
        })
    }
    //----FILTER BY COUNTRY
    searchCountry(query) {
        var queryHelper = query
        if (queryHelper !== undefined && queryHelper.includes(' - ')) {
            var x = queryHelper.substr(0, 3)
            queryHelper = x
        }
        searchCountries(queryHelper).then(
            (json) => {
                var helper = [];
                if (queryHelper !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(queryHelper);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({
                        dropdowns: {
                            ...this.state.dropdowns,
                            countryList: helper
                        }
                    });
                    if (helper.length !== 0) {
                        if (helper.length === 1 || helper[0].CountryId === queryHelper) {
                            var value = helper[0];
                            this.setState({
                                bankAccount: {
                                    ...this.state.bankAccount,
                                    CountryID: value.CountryId, CountryIDName: value.CountryIdName
                                }
                            }, () => {
                                this.filterBanksByCountry(value.CountryId)
                            });
                        }
                    }
                }
            }
        );
        console.table(this.state.countries)
    }
    getCountryValue = (value) => {
        if (value !== ''  && value !== 'select') {
            if (value.value != null) {
                const newBankAccount = { ...this.state.bankAccount, CountryID: value.value, CountryIDName: value.name != null ? value.name : '' };                
                this.props.getBankAccount(newBankAccount);
                if (value.value != null) {
                    this.filterBanksByCountry(value.value)
                    //this.getBranches('', value.CountryId)
                }
                this.setState({ bankAccount: newBankAccount });
            } else if (value.value == null) {
                /* this.setState({
                    bankAccount: { ...this.state.bankAccount, countryId: '', countryIdName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                ); */
                this.setState({
                    bankAccount: { ...this.state.bankAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: [], banksSwift: [], branches: [] },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                );
            }
        }
    }
    filterBanksByCountry = (countryID) => {
        var objBanks = this.state.allBanks
        var objSwift = this.state.allBankSwifts
        var newList = [], newListS = []
        objBanks.forEach(value => {
            if (value.CountryID === countryID) {
                newList.push(value)
            }
        })
        objSwift.forEach(value => {
            if (value.CountryID === countryID) {
                newListS.push(value)
            }
        })
        this.setState({ dropdowns: { ...this.state.dropdowns, banks: newList, banksSwift: newListS } });
    }
    //----END FILTER BY COUNTRY
    getBranches(bankID, countryID) {
        var objBranches = this.state.allBranches
        var newListB = []
        if (bankID > 0 && countryID !== undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID) && value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else if (bankID > 0 && countryID === undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID)) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else {
            objBranches.forEach(value => {
                if (value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        }
    }
    validateRequiredFields() {
        var bankID = true;
        if (this.state.bankAccount.BankID === "select" || this.state.bankAccount.BankID === '' || this.state.bankAccount.BankID === 0 || this.state.bankAccount.BankID === undefined) {
            bankID = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID
            }
        })
    }


    validateRequiredInputs() {
        const fields = [
            { displayName: 'Bank', validateName: 'bank', value: this.state.bankAccount.BankID, type: 'string' }
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };

    }

    validateBank() {
        const fields = [
            { displayName: 'Bank', validateName: 'bank', value: this.state.bankAccount.BankID, type: 'string' }
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateFieldsEmpty() {
        if (this.state.bankAccount.AccountNo === "" && this.state.bankAccount.TransitNo === "" && this.state.bankAccount.ABANo === "" && (this.state.bankAccount.BranchID === "select" || this.state.bankAccount.BranchID === "")) {
            return true;
        }
        return false;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                this.setState({
                    bankAccount: { ...this.state.bankAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        // this.searchCountry(bank.CountryID)
                        this.getBranches(value.BankID, bank.CountryID)
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                );
            } else {
                this.setState({
                    bankAccount: { ...this.state.bankAccount, Swift: value.name, BankID: value.BankID },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        // this.searchCountry(bank.CountryID)
                        this.getBranches(value.BankID, bank.CountryID)
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                bankAccount: { ...this.state.bankAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                bankAccount: { ...this.state.bankAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "" : value.SWIFT },
                dropdowns: { ...this.state.dropdowns, branches: [] },
                validations: { ...this.state.validations, bank: true }
            }, () => {
                if (this.validateBank()) {
                    this.props.getBankAccount(this.state.bankAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
                if (value.value !== '' && value.value !== "select") {
                    // this.searchCountry(value.CountryID)
                    this.getBranches(this.state.bankAccount.BankID, value.CountryID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                bankAccount: { ...this.state.bankAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined) {
            this.setState({
                bankAccount: { ...this.state.bankAccount, BranchID: value.value, BankName: value.Bank, BankID: value['Bank ID'], Swift: value.Swift === null ? "" : value.Swift }
            },
                () => {
                    if (this.validateFieldsEmpty()) {
                        this.props.isValid({ valid: true, errors: [] });
                    }
                    else {
                        this.props.isValid(this.validateBank());
                    }
                    // this.searchCountry(value['Country ID'])
                    this.getBranches(value['Bank ID'],value['Country ID'])
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                bankAccount: { ...this.state.bankAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            bankAccount: { ...this.state.bankAccount, AccountNo: event.target.value }
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: [] });
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            bankAccount: { ...this.state.bankAccount, Swift: event.target.value }
        },
            () => {
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            bankAccount: { ...this.state.bankAccount, ABANo: event.target.value }
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: [] });
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            bankAccount: { ...this.state.bankAccount, TransitNo: event.target.value }
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: [] });
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    render() {
        return (
            <div>
                <ul id="accordion-" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">{this.props.name != null ? this.props.name : 'Intermediary Account'}</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="generic-bank-account">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.formLabel}>Country</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.bankAccount.CountryID, name: this.state.bankAccount.CountryIDName }} getValue={this.getCountryValue} placeholder={"Select an Item..."} data={this.state.dropdowns.countryList} id="generic-account-country" />
                                                {/* <DynamicSearchSelect
                                                    className='uk-select'
                                                    id="form-country"
                                                    placeholder="Select a country"
                                                    searchFunction={this.searchCountry.bind(this)}
                                                    getValue={this.getCountryValue}
                                                    data={this.state.dropdowns.countryList}
                                                    objValue={{ value: this.state.bankAccount.CountryID, name: this.state.bankAccount.CountryIDName }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}> Bank (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className={this.formatValidSelect(this.state.validations.bank)} objValue={{ value: this.state.bankAccount.BankID }} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">SWIFT</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.bankAccount.Swift} onChange={this.handleUpdateSwift} />
                                                {/* <DynamicSelect className="uk-select" objValue={{ value: this.state.bankAccount.BankID }} getValue={this.handleGetValueBankSwift} placeholder={"Select an Item..."} listHasPlaceholder={false} data={this.state.dropdowns.banksSwift} id="form-banks-swift" /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Bank Branch</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.bankAccount.BranchID }} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                                            </div>
                                        </div>
                                        {/* <div className="uk-width-1-2 uk-form-stacked"/> */}
                                        <div className="uk-width-1-1 uk-form-stacked uk-margin-small-top uk-margin-small-bottom">
                                            <hr></hr>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Account Number / IBAN</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.bankAccount.AccountNo} onChange={this.handleUpdateAccountNumber} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked" />
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">SWIFT / BIC</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.bankAccount.Swift} onChange={this.handleUpdateSwift} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked" />
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Institution/ABA No.</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.bankAccount.ABANo} onChange={this.handleUpdateABANo} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Routing Code / Transit No</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <input className="uk-input" type="text" placeholder="" value={this.state.bankAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}
import React from 'react';
import GridLayout from 'react-grid-layout';
import _ from "lodash";
import { Responsive, WidthProvider } from 'react-grid-layout';

import './Dashboard.scss'
import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'

//Network
import {getDashboard, saveDashboard, getAvailableWidgets} from '../../networking/NetworkingWidget';

import SalespeoplePerformancePie from '../widgets/SalespeoplePerformance/SalespeoplePerformancePie';
import MyHistoricalPerformance from '../widgets/MyHistoricalPerformance/MyHistoricalPerformance';
import MyPerformance from '../widgets/MyPerformance/MyPerformance';

import {getStyle} from '../../styles/styles';

const style = getStyle();
const ResponsiveGridLayout = WidthProvider(Responsive);


const removeStyle = {
    position: "absolute",
    right: "2px",
    paddingRight: "15px",
    fontWeight: 500,
    top: 0,
    paddingTop: '10px',
    cursor: "pointer",
    backgroundColor: "transparent"
};

export default class DashboardScreen extends React.Component {
    
    state = {
        loading: false,
        layout: [],
        availableWidgets: [],
        saveLayout: [],
        addWidgetText: "Add Widget",
        showToolbox: false,
    }

    componentDidMount = () => {
        this.getDashboardData();
        this.getListOfWidgets();
    }

    getListOfWidgets = () => {
        let userID = localStorage.getItem('UserID');
        getAvailableWidgets(userID).then(response => {
            if(response.ListOfWidgets){
                this.setState({availableWidgets: response.ListOfWidgets});
            } else {
                //this.setState({availableWidgets: [{"Description":"Desktop Test Widget","internalname":"desktop-test-widget"}]})
            }
        })
    }

    getDashboardData = () => {
        let userID = localStorage.getItem('UserID');
        getDashboard(userID).then((dashboardResponse)=>{
            console.log(dashboardResponse);
            if(dashboardResponse.data){
                
                let prevLayout = dashboardResponse.data;
                this.setState({layout: prevLayout});
            }
        });
    }

    onLayoutChange = (layout) => {
        let userID = localStorage.getItem('UserID');
        this.setState({saveLayout: layout});
        console.log(layout);
        saveDashboard(userID,JSON.stringify(layout));
    }

    onRemoveItem(key) {
        this.setState({ layout: _.reject(this.state.layout, { i: key }) });
    }

    onAddWidgetClick = () => {
        this.setState({showToolbox: !this.state.showToolbox}, () => {
            if(this.state.showToolbox){
                this.setState({addWidgetText: 'Close'});
            } else {
                this.setState({addWidgetText: 'Add Widget'});
            }
        });
    }

    onAddWidgetButtonClick(value) {
        let layoutArray = [...this.state.layout];
        let newId = value;
        let found = layoutArray.some(el => el.i === newId);
        if (!found) layoutArray.push({i: newId, x: 0, y: 0, w: 3, h: 11});
        this.setState({layout: layoutArray});
    }

    render = () => {
        // layout is an array of objects, see the demo for more complete usage
        /*const layout = [
            //{i: 'a', x: 0, y: 0, w: 4, h: 8},
            //{i: 'b', x: 0, y: 0, w: 6, h: 8},
            //{i: 'c', x: 0, y: 0, w: 10, h: 4},
            //{i: 'd', x: 0, y: 0, w: 6, h: 8},
            //{i: 'e', x: 0, y: 0, w: 6, h: 8},
            //{i: 'f', x: 0, y: 0, w: 6, h: 8},
            //{i: 'g', x: 0, y: 0, w: 6, h: 8},
        ];*/


        let ToolBoxArray = [
            { id: "desktop-test-widget", 
            comp:<div className="showcase-button">
                <button type="button" id="add-test" className="uk-button uk-button-green" onClick={this.onAddWidgetButtonClick.bind(this,'desktop-test-widget')}>Test Widget</button>
            </div>},
            { 
                id  : "SalespeoplePerformancePie-widget", 
                comp: <div className="showcase-button">
                        <button 
                            type        = "button" 
                            id          = "add-SalespeoplePerformancePie" 
                            className   = "uk-button uk-button-green"
                            onClick     = {this.onAddWidgetButtonClick.bind(this,'SalespeoplePerformancePie-widget')}
                        >Salespeople Performance Pie</button>
                     </div>
            },
            { 
                id  : "MyHistoricalPerformance-widget", 
                comp: <div className="showcase-button">
                        <button 
                            type        = "button" 
                            id          = "add-MyHistoricalPerformance" 
                            className   = "uk-button uk-button-green"
                            onClick     = {this.onAddWidgetButtonClick.bind(this,'MyHistoricalPerformance-widget')}
                        >My Historical Performance</button>
                     </div>
            }
            ,
            { 
                id  : "MyPerformance-widget", 
                comp: <div className="showcase-button">
                        <button 
                            type        = "button" 
                            id          = "add-MyPerformance" 
                            className   = "uk-button uk-button-green"
                            onClick     = {this.onAddWidgetButtonClick.bind(this,'MyPerformance-widget')}
                        >My Performance</button>
                     </div>
            }
        ];

        let originalToolBoxArray = [...ToolBoxArray];
        /*this.state.availableWidgets.forEach(element => {
            originalToolBoxArray = originalToolBoxArray.filter(x => {
                return x.id == element.internalname;
            });
        });*/
        let cardArrays = [];
        this.state.layout.forEach(element => {
            switch (element.i) {
                case 'desktop-test-widget':
                    cardArrays.push(
                        <div className="dashboard-card" key="desktop-test-widget" data-grid={{x: element.x, y: element.y, w: element.w, h: element.h}}>
                            <div className="dashboard-card-frame">
                                <h5>Test Widget</h5>
                            </div>
                            <span
                                className="remove"
                                style={removeStyle}
                                onClick={this.onRemoveItem.bind(this,"desktop-test-widget")}
                            >
                                x
                            </span>
                        </div>
                    );
                    originalToolBoxArray = originalToolBoxArray.filter(x => {
                        return x.id !== 'desktop-test-widget';
                    });
                    break;
                case 'SalespeoplePerformancePie-widget':
                            cardArrays.push(
                                <div className="dashboard-card" key="SalespeoplePerformancePie-widget" data-grid={{x: element.x, y: element.y, w: element.w, h: element.h}}>
                                    <div className="dashboard-card-frame">
                                        <SalespeoplePerformancePie
                                            width      = {'auto'}
                                            height     = {'90%'}
                                        />
                                    </div>
                                    <span
                                        className   = "remove"
                                        style       = {removeStyle}
                                        onClick     = {this.onRemoveItem.bind(this,"SalespeoplePerformancePie-widget")}
                                    >
                                        x
                                    </span>
                                </div>
                            );
                            originalToolBoxArray = originalToolBoxArray.filter(x => {
                                return x.id !== 'SalespeoplePerformancePie-widget';
                            });
                        break;
                case 'MyHistoricalPerformance-widget':
                            cardArrays.push(
                                <div className="dashboard-card" key="MyHistoricalPerformance-widget" data-grid={{x: element.x, y: element.y, w: element.w, h: element.h}}>
                                    <div className="dashboard-card-frame">
                                        <MyHistoricalPerformance
                                            width      = {'auto'}
                                            height     = {'90%'}
                                        />
                                    </div>
                                    <span
                                        className   = "remove"
                                        style       = {removeStyle}
                                        onClick     = {this.onRemoveItem.bind(this,"MyHistoricalPerformance-widget")}
                                    >
                                        x
                                    </span>
                                </div>
                            );
                            originalToolBoxArray = originalToolBoxArray.filter(x => {
                                return x.id !== 'MyHistoricalPerformance-widget';
                            });
                        break;
                case 'MyPerformance-widget':
                    cardArrays.push(
                        <div className="dashboard-card" key="MyPerformance-widget" data-grid={{x: element.x, y: element.y, w: element.w, h: element.h}}>
                            <div className="dashboard-card-frame">
                                <MyPerformance
                                    width      = {'auto'}
                                    height     = {'80%'}
                                />
                            </div>
                            <span
                                className   = "remove"
                                style       = {removeStyle}
                                onClick     = {this.onRemoveItem.bind(this,"MyPerformance-widget")}
                            >
                                x
                            </span>
                        </div>
                    );
                    originalToolBoxArray = originalToolBoxArray.filter(x => {
                        return x.id !== 'MyPerformance-widget';
                    });
                break;
                default:
                    break;
            }
        });

        let finalToolbox = [];
        for (var i=0; i < this.state.availableWidgets.length; i++) {
            for(var j=0; j < originalToolBoxArray.length; j++) {
                if (this.state.availableWidgets[i].internalname === originalToolBoxArray[j].id){
                    finalToolbox.push(originalToolBoxArray[j].comp);
                }
            }
        }
        /*originalToolBoxArray.forEach(tbElement => {
            finalToolbox.push(tbElement.comp);
        })*/
        ////debugger
        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Dashboard</h3>
                    <div>
                        <div id="add-box" style={{display: "flex"}} uk-grid="true">
                            <div className="uk-width-5-6"></div>
                            <div className="uk-width-1-6"><button type="button" className="uk-button uk-button-green" onClick={this.onAddWidgetClick}>{this.state.addWidgetText}</button></div>
                        </div>
                        {this.state.showToolbox && <div className="showcase" id="toolbox">
                            {finalToolbox}
                        </div>}
                        <section id="grid" style={{display: "block"}}>
                            <GridLayout className="layout" cols={10} rowHeight={30} width={'1500'} onLayoutChange={this.onLayoutChange}>
                                {cardArrays}
                            </GridLayout>
                        </section>
                    </div>
            </div>
        )
    }
}


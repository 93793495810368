import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;
const urlBene = window.REACT_APP_BENE_URL;

export async function searchActiveBeneficiariesBankAccountsByCustomerAndCurrency(customerID, currencyID, searchTerm){
    try {
        let params = {"customerID":customerID,
                    "currencyID":currencyID,
                    "searchTerm":searchTerm,};

        let response = await genericGetWithParameters('Beneficiary/SearchActiveBeneficiariesBankAccountsByCustomerAndCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getActiveBeneficiariesBankAccountsByCustomerAndCurrency(customerID, currencyID){
    try {
        let params = {"customerID":customerID,
                    "currencyID":currencyID,};

        let response = await genericGetWithParameters('Beneficiary/GetActiveBeneficiariesBankAccountsByCustomerAndCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByCustomer(customerID, page){
    try {
        let params = {
            "customerID":customerID,
            "page": page,
            "items":0
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiariesByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByID(id){
    try {
        let params = {"beneficiaryId":id,};

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getRiskScoreBeneDetails(beneID){
    try {
        let params = {
            EntityType: "Beneficiary",
            EntityID: beneID,
            checkBankScore: true
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiaryRiskScore(beneficiaryID){
    try {
        let params = {
            BeneficiaryID: beneficiaryID
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetBeneficiaryRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Beneficiary/SaveBeneficiary
export async function addBeneficiary(ojbBene) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Beneficiary/SaveBeneficiary',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(ojbBene),
        });
        /*if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }*/
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = '';
        switch(response.status)
        {
            case 200:
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

//Beneficiary/DeleteBeneficiary
export async function deleteBeneficiary(putBene) {
    try{
        let params = {
            'beneficiaryID': putBene
        };
        let response = await genericDeleteWithParameters('Beneficiary/DeleteBeneficiary',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getProvinceByCountryID(id){
    try {
        let params = {"CountryID":id,};

        let response = await genericGetWithParameters('States/GetStatesByCountry_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Autocomplete
export async function getBeneficiariesByNameMin(beneficiary){
    try {
        let params = {"beneficiary":beneficiary,};

        let response = await genericGetWithParameters('Beneficiary/SearchBeneficiaryByIdNameMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByCustomerFormatedMin(customerId, currencyId, status) {
    try {
        let params = {
            "CustomerID": customerId,
            "CurrencyID": currencyId,
            "Status": status
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiariesByCustomerFormatedMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function getNewBeneficiaryEmail(customerId, personId, beneficiaryName, beneEmail) {
    try {
        let params = {
            "CustomerID": customerId,
            "PersonID": personId,
            "BeneficiaryName": beneficiaryName,
            "BeneficiaryEmail": beneEmail,
            "BeneficiaryUrl": urlBene
        };

        let response = await genericGetWithParameters('Beneficiary/SendRequestBeneficiaryInformationEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getNewBeneficiaryKey(key) {
    try {
        let params = {
            "BeneficiaryInfoRequestID": key
        };
        let response = await genericGetWithParametersNoEncrypted('Beneficiary/GetCustomerIDByKey', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiaryForDisbursementsScreen(customerID, currencyID){
    try {
        let params = {
            "customerID":customerID,
            "currencyID": currencyID,
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryForDisbursementsScreen',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllBeneficiariesByDeal(dealId){
    try{
        let params = {
            dealId: dealId
        }

        let response = await genericGetWithParameters('Beneficiary/GetAllBeneficiariesByDeal',params);
        return response;
    }catch (error)
    {
        console.error(error);
    }
}

export async function getBeneficiariesCardView(customerID, beneficiaryID, beneficiaryName){
    try {
        let params = {
            CustomerID      : (customerID === '' ? '' : customerID),
            BeneficiaryID   : (beneficiaryID === '' ? '' : beneficiaryID),
            BeneficiaryName : (beneficiaryName === '' ? '' : beneficiaryName)
        }
        let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryCardView', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const saveBeneficiaryInAFEX = async (model) => {
    try{
        const response = await genericCallWithBody('POST','AFEX/SaveBeneficiaryInAFEX',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAFEXBeneficiaryInstantPayments(customerID, fromDate){
    try {
        let params = {
            "CustomerID":customerID,
            "FromDate": fromDate,
        };

        let response = await genericGetWithParameters('Beneficiary/GetAFEXBeneficiaryInstantPayments',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateCountryRiskScore(entityType, entityId){
    try {
        let params = {
            'EntityType': entityType,
            'EntityId': entityId
        }

        let response = await genericGetWithParametersNoEncrypted('Beneficiary/ValidateCountryRiskScore',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendBeneficiaryToInterface(ojbBene){
    var method = 'POST';
    var apiName = 'Beneficiary/SendBeneficiaryToInterface'
    let response = await genericCallWithBody(method, apiName, ojbBene)
    return response;
}

export const saveBeneficiaryInOpenPayd = async (model) => {
    try{
        const response = await genericCallWithBody('POST','Beneficiary/SaveBeneficiaryInOpenPayd',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
import {genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted} from './Networking';

export async function getReferredDeals(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('CustomerLimitProfile/GetReferredDeals',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getReferredOutgoingFunds(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('CustomerLimitProfile/GetReferredOutgoingFunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveReferredDealComment(model){

    try{
        let response = await genericCallWithBody('POST','CustomerLimitProfile/SetComment',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealsInquiries(page, items, fromDate, toDate){
    try {
        let params = {
            'Page': page,
            'Items': items,
            'fromDate': fromDate,
            'toDate': toDate
        }
        let response = await genericGetWithParameters('CustomerLimitProfile/GetDealsInquiries',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoingFundsInquiries(page, items, fromDate, toDate){
    try {
        let params = {
            'Page': page,
            'Items': items,
            'fromDate': fromDate,
            'toDate': toDate
        }
        let response = await genericGetWithParameters('CustomerLimitProfile/GetOutgoingFundsInquiries',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
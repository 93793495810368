import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import {getLogoURL} from '../../../../helpers/PackageJsonHelper';
import { formatToCurrencyWithScale } from '../../../../helpers/FormatHelper';
import RobotoRegular from '../../../../fonts/Roboto/Roboto-Regular.ttf';
import RobotoBold from '../../../../fonts/Roboto/Roboto-Bold.ttf';



import AppContext from '../../../../AppContext';
let globalFunctions = {};

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
        format: 'truetype'
      },
      {
        src: RobotoBold,
        format: 'truetype',
        fontWeight: 'bold'
      }
    ]
  })

const styles = StyleSheet.create({
    page: {display: 'flex', flexDirection: 'column'},
    header: {display: 'flex', flexDirection: 'column', padding: '10px'},
    subHeader: {display: 'flex', flexDirection: 'row'},
    logo: {width: '20%', maxHeight: '100%'},
    headerChild: {flex: 1, display: 'flex', flexDirection: 'column', padding: '10px', alignSelf: 'center'},
    vostroSection: {display: 'flex', flexDirection: 'column', padding: '10px'},
    vostroSectionHeader: {display: 'flex', flexDirection: 'row', width: '100%'},
    vostroTable: {display: 'flex', flexDirection: 'column', paddingTop: '10px'},
    vostroTableRow: {display: 'flex', flexDirection: 'row'},
    vostroTableRowEven: {display: 'flex', flexDirection: 'row', backgroundColor: '#D3D3D3'},
    text: {fontFamily: 'Roboto', fontSize: 8},
})

const formatDates = (inputDate) => {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

const getCurrentDate = () => {
    var d = new Date();
    if (!isNaN(d.getTime())) {
        return [d.getMonth()+1,
            d.getDate(),
            d.getFullYear()].join('/')+' '+
           [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');
    } 
}


export default class VostroStatementPDF extends React.Component {
    render = () => {
        let data = this.props.vostroStatementData;
        let vostroStatement = data.VostroStatement;
        let heldFundsBalance = data.HeldFundsBalance;
        let outstandingForwards = data.OutstandingForwards;
        //VOSTRO STATEMENT
        let vostroStatementRender = [];
        if(Array.isArray(vostroStatement) && vostroStatement.length>0){
            
            let finalVostroStatement = {};

            vostroStatement.forEach(element => {
                if(finalVostroStatement[element.currencyid]){
                    finalVostroStatement[element.currencyid].push(element);
                } else {
                    finalVostroStatement[element.currencyid] = [];
                    finalVostroStatement[element.currencyid].push(element);
                }
            });
            let vostroStatementKeys = Object.keys(finalVostroStatement);

            vostroStatementKeys.forEach(key => {
                let header = <View style={styles.vostroSectionHeader}>
                    <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '50%'}}>Currency: {key}</Text>
                    {/*<Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '25%'}}>{key}</Text>*/}
                    <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '40%', textAlign: 'right'}}>Previous Balance: </Text>
                    <Text style={{fontFamily: 'Roboto', textAlign: 'right', fontSize: 10, width: '10%'}}>{formatToCurrencyWithScale(finalVostroStatement[key][0].previousbalance, key)} {key}</Text>
                </View>;
                
                let currencyRows = [];
                currencyRows.push(
                    <View style={styles.vostroTableRow} fixed>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%'}}>Date</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%'}}>Deal Number</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '40%'}}>Description</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '15%'}}>Beneficiary</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%', textAlign: 'right'}}>Amount</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '15%', textAlign: 'right'}}>Running Balance</Text>
                    </View>
                );

                finalVostroStatement[key].forEach((row,index) => {
                    let styleRow = styles.vostroTableRowEven;
                    if(index%2 === 0){
                        styleRow = styles.vostroTableRow;
                    }
                    
                    currencyRows.push(
                        <View style={styleRow}>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%'}}>{formatDates(row.balancedatetime)}</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%'}}>{row.DealNumber === 0 ? '' : row.DealNumber}</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '40%'}}>{row.description}</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '15%'}}>{row.BeneficiaryName ? row.BeneficiaryName : ''}</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%', textAlign: 'right'}}>{formatToCurrencyWithScale(row.amount, key)}</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '15%', textAlign: 'right'}}>{formatToCurrencyWithScale(row.currentbalance, key)}</Text>
                        </View>
                    );

                    if (index === finalVostroStatement[key].length - 1) {
                        currencyRows.push(
                            <View style={styles.vostroTableRow}>
                                <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '35%', textAlign: 'right'}}></Text>
                                <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '40%', textAlign: 'right'}}>Ending Balance</Text>
                                <Text style={{fontFamily: 'Roboto', textAlign: 'right', fontSize: 10, width: '10%'}}>{formatToCurrencyWithScale(row.currentbalance, key)}</Text>
                                <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '1%', textAlign: 'left'}}></Text>
                                <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '14%', textAlign: 'left'}}>{`   ${key}`}</Text>
                            </View>
                        );
                    }
                })
                let currencyPack = (
                    <View style={styles.vostroSection}>
                        {header}
                        <View style={styles.vostroTable}>
                            {currencyRows}
                        </View>
                    </View>
                );
                vostroStatementRender.push(currencyPack);

            })
        }
        
        //--------------

        //HELD FUNDS BALANCE

        let fundsRender = [];

        if(Array.isArray(heldFundsBalance) && heldFundsBalance.length>0){
            heldFundsBalance.forEach((row,index) => {
                let styleRow = styles.vostroTableRow;
                if(index%2 === 0){
                    styleRow = styles.vostroTableRowEven;
                }
                fundsRender.push(
                    <View style={styleRow}>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '40%'}}>{row.currencyid}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '60%', textAlign: 'right'}}>{formatToCurrencyWithScale(row.Heldfundbalance, row.currencyid)}</Text>
                    </View>
                );
            })
        }

        //------------

        //OUTSTANDING FORWARDS
        let outstandingRender = [];

        if(Array.isArray(outstandingForwards) && outstandingForwards.length>0){
            outstandingForwards.forEach((row,index) => {
                let styleRow = styles.vostroTableRow;
                if(index%2 === 0){
                    styleRow = styles.vostroTableRowEven;
                }
                outstandingRender.push(
                    <View style={styleRow}>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '5%'}}>{row.Trade}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%'}}>{formatDates(row['Trade Date'])}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%'}}>{formatDates(row.ValueDate)}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%'}}>{formatDates(row.MaturityDate)}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '20%', textAlign: 'center'}}>{formatToCurrencyWithScale(row['Customer Buys'], row.BuyCurrency)} {row.BuyCurrency}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '20%', textAlign: 'center'}}>{formatToCurrencyWithScale(row['Customer Sells'], row.SellCurrency)} {row.SellCurrency}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '10%', textAlign: 'center'}}>{row.Rate}</Text>
                        <Text style={{fontFamily: 'Roboto', fontSize: 10, width: '15%', textAlign: 'center'}}>{formatToCurrencyWithScale(row['Remaining Balance'], row.SellCurrency)} {row.SellCurrency}</Text>
                    </View>
                );
            })
        }
        //------------
        let customerName = '';
        let traderName = '';
        if(data.VostroStatement[0]){
            customerName = data.VostroStatement[0].CustomerName;
            traderName = data.VostroStatement[0].TraderName;
        }

        console.log(data);
        console.log(getLogoURL());
        return(
            <Document title={`${customerName}-VostroStatement-${formatDates(this.props.fromTradeDate)}-${formatDates(this.props.toTradeDate)}`}>
                <Page style={styles.page} orientation="landscape" size="A4" wrap>
                    <View style={styles.header} fixed={true}>
                        <Text fixed={true} style={[styles.text, {width: '100%', textAlign: 'right'}]} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                            )}
                        />
                        <View style={styles.subHeader}>
                            <Image src={getLogoURL()} style={styles.logo}/>
                            <View style={styles.headerChild}>
                                <Text style={styles.text}>
                                    Customer: {customerName}
                                </Text>
                                <Text style={styles.text}>
                                    Statement Date: From {formatDates(this.props.fromTradeDate)} to {formatDates(this.props.toTradeDate)}
                                </Text>
                                <Text style={styles.text}>
                                    Salesperson: {traderName}
                                </Text>
                                <Text style={styles.text}>
                                    Run Date: {getCurrentDate()}
                                </Text>
                            </View>
                            <View style={styles.headerChild}>
                                <Text style={[styles.text, {textAlign: 'right'}]}>
                                    {this.props.companyAddress}
                                </Text>
                                <Text style={[styles.text, {textAlign: 'right'}]}>
                                    Toll Free: {this.props.companyTollFree}
                                </Text>
                            </View>
                        </View>
                    </View>
                    {vostroStatementRender}
                    {Array.isArray(heldFundsBalance) && <View style={[styles.vostroSection, {width: '25%'}]} break>
                        <View style={styles.vostroSectionHeader}>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '100%'}}>Funds on Account Balance</Text>
                        </View>
                        <View style={[styles.vostroSectionHeader, {paddingTop: '10px'}]}>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '40%'}}>Currency</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '60%'}}>Balance</Text>
                        </View>
                        <View style={styles.vostroTable}>
                            {fundsRender}
                        </View>
                    </View>}
                    {Array.isArray(outstandingForwards) &&<View style={[styles.vostroSection, {width: '100%'}]} break>
                        <View style={styles.vostroSectionHeader}>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '100%'}}>Outstanding Forwards  (forwards with balance not yet drawn down)</Text>
                        </View>
                        <View style={[styles.vostroSectionHeader, {paddingTop: '10px'}]}>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '5%'}}>Trade</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%'}}>Trade date</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%'}}>Value Date</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%'}}>Maturity Date</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '20%', textAlign: 'center'}}>Customer Buys</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '20%', textAlign: 'center'}}>Customer Sells</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '10%', textAlign: 'center'}}>Rate</Text>
                            <Text style={{fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '15%', textAlign: 'center'}}>Remaining Balance</Text>
                        </View>
                        <View style={styles.vostroTable}>
                            {outstandingRender}
                        </View>
                    </View>}
                    <Text style={{padding: '10px', fontFamily: 'Roboto', fontSize: 10, fontWeight: 'bold', width: '100%', color: '#CE0000'}}>Please check this statement and report any errors or omissions within 30 days of delivery</Text>
                </Page> 
            </Document>
        );
    }
}
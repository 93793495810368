import React from 'react';
import { getLookUpTable } from '../../../networking/Networking';
import { updateIntegrations, getGUID } from '../../../networking/NetworkingGlobalSystemParameters';
import {getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../../styles/styles';
import LoadingBox from '../../shared/LoadingBox';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';

const style = getStyle();

export default class IntegrationsMaintenanceScreen extends React.Component{
    state = {
        alertTitle : '',
        alertMessage : '',
        dropdowns: {
            "Integrations": [],
        },
        lookUpTables: [
            "Integrations"
        ],
        integration_parameter: '',
        integration: '',
        loading : false,
        showAlert : false,
        showAlertError : false
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    }

    componentDidMount(){
        this.loadIntegration();
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        });
    }

    componentDidUpdate(){}

    handleUpdateIntegrations = () => {
        var objParameter = { 'IntegrationName' : this.state.integration }
        updateIntegrations(objParameter).then((json) => {
            debugger;
            if (json == undefined || (json.httpStatusCode !== undefined && json.httpStatusCode !== 200)) {
                this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
            } else {
                this.loadIntegration();
                this.setState({ showAlertError: true, alertMessage: 'Integration Saved!', alertTitle: 'Success', loading: false
                });
            }
        });
    }

    loadIntegration(){
        getGUID('AutoCoverPartner').then((json) =>{
            this.setState({ integration_parameter: json.ParameterValue});
        });
    }

    hangleGetValueIntegration = (value) => {
        if (value !== undefined && value !== "select") {
            this.setState({ integration: value.value});
        }
    }
    
    render(){
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Integration Maintenance</h3>
                <div className="">
                    <form className="uk-form-horizontal">
                        <div className="uk-grid">
                            <div className="uk-width-1-2 uk-form-stacked">
                                <label className="uk-form-label" >Integration</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect objValue={{value: this.state.integration}} getValue={this.hangleGetValueIntegration} listHasPlaceholder={true} data={this.state.dropdowns["Integrations"]} id="form-integrations" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" >Current active integration: {this.state.integration_parameter}</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleUpdateIntegrations}>Save</button>
                </div>
                <LoadingBox loading={this.state.loading} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} />
            </div>
        );
    }
}
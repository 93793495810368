import React from 'react';
import { getStyle } from '../../../../../styles/styles';
import { getScreen } from '../../../../shared/ScreenHelper';
import CardBoard from './CardBoard';
const style = getStyle();

export default class CustomersCardViewScreen extends React.Component {
    state = {
        screen: 'board',
        activeTab: '',
        elementID: 0,
        customerName: '',
        showTitle: true
    };

    handleBackClick = () => {
        this.setState({ showTitle: true, screen: 'board' });
    };

    onClickElementBoard = (element) => {
        this.setState({ showTitle: false, screen: 'ShowCustomerInfo', elementID: element['Customer ID{No line}'], customerName: element['Customer{No label}'] });
    };

    onChangeScreen = (screen, id, tab) => {
        this.setState({ screen: screen, elementID: id, activeTab: tab });
    };

    loadScreen() {
        if (this.state.screen === 'board') {
            return <CardBoard onClickElementBoard={this.onClickElementBoard} />;
        } else {
            return getScreen(this.state.screen, this.state.elementID, this.handleBackClick, null, null, this.state.customerName, 'Customers Card View');
        }
    };

    render() {
        return (
            <div>
                {this.state.showTitle && (
                    <h3 className="uk-heading-divider" style={style.titleStyle}>
                        Customers Card View
                    </h3>
                )}
                {this.loadScreen()}
            </div>
        );
    }
}

import React from 'react';

import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox'
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NumberInput from '../../shared/NumberInput';


import CurrencyPairTable from './CurrencyPairTable';
import { getCurrencyType } from '../../../networking/NetworkingCurrencies';
import { getCurrencyPairDefs, getCurrencyPairInfo, modifyCurrencyPair } from '../../../networking/NetworkingCurrencyPairs';
import { getLookUpTable } from '../../../networking/Networking';
import {getScaleForCurrencyPair} from '../../../helpers/FormatHelper'


import { getStyle, regularSelectClass } from '../../../styles/styles'
var style = getStyle();
let globalFunctions = {};


const CurrencyPairEdit = ({ currencyPair, reversedCurrencyPair, refreshCurrencyPair, onBackClick, buyCurrency, sellCurrency, rateTermsList, updateCurrencyEdited }) => {

    let sameCurrencyPair = buyCurrency === sellCurrency

    let view =
        <div className="uk-form-stacked">
            <h3 className="uk-heading-divider" style={style.titleStyle}>
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={onBackClick}>
                    <i onClick={onBackClick} className="fa fa-arrow-left back-arrow"></i></button>
                    Modify Currency Pair
            </h3>
            <div className="uk-width-1-1">
                <h5 className="uk-heading-divider"
                    style={{ ...getStyle().titleStyle, cursor: 'pointer', width: '100%' }}>Selected Currency Pair Information
                </h5>

                <form className="uk-form-horizontal">
                    <div className="uk-grid">

                        {/* row 1 */}
                        <div className="uk-width-1-2 uk-form-horizontal uk-margin-small-bottom">
                            <label className="uk-form-label" >Buy Currency</label>
                            <div >{buyCurrency}
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-horizontal uk-margin-small-bottom">
                            <label className="uk-form-label" >Sell Currency</label>
                            <div >{sellCurrency}
                            </div>
                        </div>

                        {/* row 2 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Feed Terms</label>
                            <div>
                                <DynamicSelect id="scp-rate-feeds" className="uk-select" objValue={{ value: currencyPair.RateFeedTerms, name: currencyPair.RateFeedTerms }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            currencyPair.RateFeedTerms = value.value
                                            reversedCurrencyPair.RateFeedTerms = rateTermsList.filter(x => x.value !== value.value && !x.value.includes('Select'))[0].value
                                            refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                        }
                                    }}
                                    data={rateTermsList} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Terms</label>
                            <div>
                                <DynamicSelect id="scp-rate-feeds" className="uk-select" objValue={{ value: currencyPair.RateTerms, name: currencyPair.RateTerms }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            currencyPair.RateTerms = value.value
                                            reversedCurrencyPair.RateTerms = rateTermsList.filter(x => x.value !== value.value && !x.value.includes('Select'))[0].value
                                            refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                        }
                                    }}
                                    data={rateTermsList} />
                            </div>
                        </div>

                        {/* row 3 */}

                        <div className="uk-width-1-2 uk-form-stacked  uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Precision</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0" id="scp-rate-precision" type="Integer" scale={2} value={currencyPair.RatePrecision}
                                    onChange={(event) => { currencyPair.RatePrecision = event.target.value }} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Margin error</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0" id="scp-margin-error" type="Integer" scale={2} value={currencyPair.MarginError}
                                    onChange={(event) => { currencyPair.MarginError = event.target.value }} />
                            </div>
                        </div>

                        {/* row 4 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Lower Limit</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="scp-lower-limit" type="Rate" 
                                scale={getScaleForCurrencyPair(buyCurrency, sellCurrency)}
                                value={currencyPair.LowerLimit}
                                    onChange={(event) => { currencyPair.LowerLimit = event.target.value }} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Upper Limit</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="scp-upper-limit" type="Rate" 
                                scale={getScaleForCurrencyPair(buyCurrency, sellCurrency)}
                                value={currencyPair.UpperLimit}
                                    onChange={(event) => { currencyPair.UpperLimit = event.target.value }} />
                            </div>
                        </div>

                        {/* row 5 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Spot Days</label>
                            <div>
                                <NumberInput className="uk-input" id="scp-spot-days" placeholder="0" type="Integer" scale={2} value={currencyPair.SpotDays}
                                    onChange={(event) => { currencyPair.SpotDays = event.target.value }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label"><input className="uk-checkbox" id="scp-from-rate-feed" checked={currencyPair.FromRateFeed}
                                onChange={(event) => {
                                    currencyPair.FromRateFeed = event.target.checked
                                    refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                }} type="checkbox" /> From Rate Feed</label>
                        </div>

                        {/* row 6 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Online Fix Variance</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="scp-online-fix-variance" type="Rate" scale={2} value={currencyPair.OnlineFixVariance}
                                    onChange={(event) => { currencyPair.OnlineFixVariance = event.target.value }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label"><input className="uk-checkbox" id="scp-allow-online-fwd" checked={currencyPair.AllowOnlineFwd}
                                onChange={(event) => {
                                    currencyPair.AllowOnlineFwd = event.target.checked
                                    refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                }} type="checkbox" /> Allow Online Fwd</label>
                        </div>

                        <div className="uk-width-1-1 uk-margin-small-bottom">
                            <h3 className="uk-text-meta">Instrument Convention</h3>
                        </div>

                        {/* row 7 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Primary Currency</label>
                            <div>
                                <DynamicSelect id="scp-primary-currency" className="uk-select" objValue={{ value: currencyPair.PrimaryCurrency, name: currencyPair.PrimaryCurrency }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            if(value.value === currencyPair.SecondaryCurrency){
                                                currencyPair.SecondaryCurrency = currencyPair.PrimaryCurrency;
                                            }
                                            currencyPair.PrimaryCurrency = value.value
                                            refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                        }
                                    }}
                                    data={[{ name: buyCurrency, value: buyCurrency }, { name: sellCurrency, value: sellCurrency }]} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Secondary Currency</label>
                            <div>
                                <DynamicSelect id="scp-secondary-currency" className="uk-select" objValue={{ value: currencyPair.SecondaryCurrency, name: currencyPair.SecondaryCurrency }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            if(value.value === currencyPair.PrimaryCurrency){
                                                currencyPair.PrimaryCurrency = currencyPair.SecondaryCurrency;
                                            }
                                            currencyPair.SecondaryCurrency = value.value
                                            refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                        }
                                    }}
                                    data={[{ name: buyCurrency, value: buyCurrency }, { name: sellCurrency, value: sellCurrency }]} />
                            </div>
                        </div>
                    </div>
                </form>                

                <h6 className="uk-heading-divider"
                    style={{ ...getStyle().titleStyle, cursor: 'pointer', width: '100%' }} hidden={sameCurrencyPair}>Reverse Currency Pair Information
                </h6>

                <form className="uk-form-horizontal" hidden={sameCurrencyPair}>
                    <div className="uk-grid">

                        {/* row 1 */}
                        <div className="uk-width-1-2 uk-form-horizontal uk-margin-small-bottom">
                            <label className="uk-form-label" >Buy Currency</label>
                            <div >{sellCurrency}
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-horizontal uk-margin-small-bottom">
                            <label className="uk-form-label" >Sell Currency</label>
                            <div >{buyCurrency}
                            </div>
                        </div>

                        {/* row 2 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Feed Terms</label>
                            <div>
                                <DynamicSelect id="rscp-rate-feeds" className="uk-select" objValue={{ value: reversedCurrencyPair.RateFeedTerms, name: reversedCurrencyPair.RateFeedTerms }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            reversedCurrencyPair.RateFeedTerms = value.value
                                        }
                                    }}
                                    data={rateTermsList} disabled={true}/>
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Terms</label>
                            <div>
                                <DynamicSelect id="rscp-rate-feeds" className="uk-select" objValue={{ value: reversedCurrencyPair.RateTerms, name: reversedCurrencyPair.RateTerms }}
                                    getValue={(value) => {
                                        if (value !== undefined && value != null && value.value !== undefined) {
                                            reversedCurrencyPair.RateTerms = value.value
                                        }
                                    }}
                                    data={rateTermsList} disabled={true}/>
                            </div>
                        </div>

                        {/* row 3 */}

                        <div className="uk-width-1-2 uk-form-stacked  uk-margin-small-bottom">
                            <label className="uk-form-label">Rate Precision</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0" id="rscp-rate-precision" type="Integer" scale={2} value={reversedCurrencyPair.RatePrecision}
                                    onChange={(event) => { reversedCurrencyPair.RatePrecision = event.target.value }} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Margin error</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0" id="rscp-margin-error" type="Integer" scale={2} value={reversedCurrencyPair.MarginError}
                                    onChange={(event) => { reversedCurrencyPair.MarginError = event.target.value }} />
                            </div>
                        </div>

                        {/* row 4 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Lower Limit</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="rscp-lower-limit" type="Rate" 
                                scale={getScaleForCurrencyPair(buyCurrency, sellCurrency)}
                                value={reversedCurrencyPair.LowerLimit}
                                    onChange={(event) => { reversedCurrencyPair.LowerLimit = event.target.value }} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Upper Limit</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="rscp-upper-limit" type="Rate" 
                                scale={getScaleForCurrencyPair(buyCurrency, sellCurrency)}
                                value={reversedCurrencyPair.UpperLimit}
                                    onChange={(event) => { reversedCurrencyPair.UpperLimit = event.target.value }} />
                            </div>
                        </div>

                        {/* row 5 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Spot Days</label>
                            <div>
                                <NumberInput className="uk-input" id="rscp-spot-days" placeholder="0" type="Integer" scale={2} value={reversedCurrencyPair.SpotDays}
                                    onChange={(event) => { reversedCurrencyPair.SpotDays = event.target.value }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label"><input className="uk-checkbox" id="rscp-from-rate-feed" checked={reversedCurrencyPair.FromRateFeed}
                                onChange={(event) => {
                                    reversedCurrencyPair.FromRateFeed = event.target.checked
                                    refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                }} type="checkbox" /> From Rate Feed</label>
                        </div>

                        {/* row 6 */}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label">Online Fix Variance</label>
                            <div>
                                <NumberInput className="uk-input" placeholder="0.00" id="rscp-online-fix-variance" type="Rate" scale={2} value={reversedCurrencyPair.OnlineFixVariance}
                                    onChange={(event) => { reversedCurrencyPair.OnlineFixVariance = event.target.value }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin-small-bottom">
                            <label className="uk-form-label"><input className="uk-checkbox" id="rscp-allow-online-fwd" checked={reversedCurrencyPair.AllowOnlineFwd}
                                onChange={(event) => {
                                    reversedCurrencyPair.AllowOnlineFwd = event.target.checked
                                    refreshCurrencyPair(currencyPair, reversedCurrencyPair)
                                }} type="checkbox" /> Allow Online Fwd</label>
                        </div>

                    </div>
                </form>

                <div className="uk-width-1-2 uk-form-stacked uk-margin">
                    <button name="btnSaveCurrencyPairDef" className="uk-button uk-button-default uk-button-green" onClick={() => updateCurrencyEdited(currencyPair, reversedCurrencyPair)}>Save</button>
                </div>

                </div>
        </div>
    return view
}



export default class CurrencyPairMaintenanceScreen extends React.Component {
    static contextType = AppContext;
    state = {
        currencyDefs: [],
        currencyType: '',
        listCurrencyType: [],
        editCurrencyPair: { edit: false, buyCurrency: '', sellCurrency: '' },
        lookUpTables: [
            "Currency Pair Terms"],
        rateTermsList: [],
        currencyPairForEdition: {},
        currencyPairReversedForEdition: {},
        loading: false,
        showAlert: false,
        alertMessage: '',
        alertTitle: ''
    }

    componentDidMount() {
        globalFunctions = this.context;
        globalFunctions.startLoading();
        getCurrencyType().then(
            (json) => {
                if (typeof json !== "undefined") {
                    let currencies = json;
                    currencies.forEach(element => {
                        element.value = element.Description;
                        element.name = element.Description;
                    });
                    this.setState({
                        listCurrencyType: currencies, currencyType: 'Major'
                    });
                    globalFunctions.finishLoading();
                }
            }
        )
        this.getCurrencyPairDefsInfo('Major')
        this.loadLookupTables()
    }

    getCurrencyPairDefsInfo(filter) {
        //this.setState({ loading: true });
        globalFunctions.startLoading();
        getCurrencyPairDefs(filter).then(
            (json) => {
                if (typeof json !== "undefined" && json.data) {
                    this.setState({ currencyDefs: json.data });
                    globalFunctions.finishLoading();
                }
            }
        );
    }

    loadLookupTables() {
        globalFunctions.startLoading();
        getLookUpTable(this.state.lookUpTables.toString()).then((response) => {
            console.log(response)
            //Currency Pair Terms
            let lookUpTables = response.lookUpTables || []
            let currencyPairTerms = lookUpTables.filter(x => x.LookUpTableDescription === 'Currency Pair Terms') || []
            currencyPairTerms[0].lookUpTableDetails.forEach(x => {
                x.value = x.LookUpTableDetail;
                x.name = x.LookUpTableDetail;
            })
            this.setState({ rateTermsList: currencyPairTerms[0].lookUpTableDetails });
            globalFunctions.finishLoading();
        })
    }
    
    updateCurrencyEdited = (currencyPair, reversedCurrencyPair) => {
        globalFunctions.startLoading();
        console.log(currencyPair, reversedCurrencyPair)
        this.setState({ loading: true }, () => {
            modifyCurrencyPair(currencyPair, reversedCurrencyPair)
                .then((response) => {
                    console.log(response)
                    switch (Number(response.httpStatusCode)) {
                        case 200:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: 'Modified Succesfully.',
                                /*editCurrencyPair: { edit: false }, 
                                currencyPairForEdition: {}*/
                            })
                            break
                        default:
                            console.log(response.errorMessage)
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Error updating the currency pairs.'
                            })
                            break
                    }
                })
                .finally(() => {
                    globalFunctions.finishLoading();
                })
        })
    }

    //events
    onRefreshCurrencyPair = (currencyPair, reversedCurrencyPair) => {
        this.setState({ currencyPairForEdition: currencyPair, currencyPairReversedForEdition: reversedCurrencyPair })
    }

    handleCurrencyTypeSelection = (value) => {
        this.setState({ currencyType: value.value || 'Major' });
        this.getCurrencyPairDefsInfo(value.value || 'Major');
    }

    handleRowClick(obj) { }

    defaultValues(obj, rateTerms){
        if(obj.RateTerms!=="D" && obj.RateTerms!=="I"){
            var objDefault={
                PrimaryCurrency:null,
                SecondaryCurrency:null,
                AllowOnlineFwd: false,
                CurrencyCode: obj.CurrencyCode,
                FromRateFeed: false,
                LowerLimit: 500,
                MarginError: 20,
                MarketSidePricing: null,
                OnlineFixVariance: 0,
                OutCurrencyCode: obj.OutCurrencyCode,
                RateFeedTerms: rateTerms,
                RatePrecision: 7,
                RateTerms: rateTerms,
                SpotDays: 2,
                UpperLimit: 10000000,
            }
            return objDefault;
        }else{
            return obj;
        }        
    }

    handleModifyCurrencyPair = (buyCurrency, sellCurrency) => {
        getCurrencyPairInfo(buyCurrency, sellCurrency).then(response => {
            console.log(response)
            return this.defaultValues(response,"D") || {}
        }).then(currencyPair => {
            this.setState({
                editCurrencyPair: { edit: true, buyCurrency: buyCurrency, sellCurrency: sellCurrency },
                currencyPairForEdition: {
                    ...currencyPair,
                    PrimaryCurrency: (currencyPair.PrimaryCurrency || buyCurrency),
                    SecondaryCurrency: (currencyPair.SecondaryCurrency || sellCurrency)
                }
            })
        })
        if (buyCurrency !== sellCurrency) {
            getCurrencyPairInfo(sellCurrency, buyCurrency).then(response => {
                console.log(response)
                return this.defaultValues(response,"I") || {}
            }).then(currencyPair => {
                this.setState({
                    currencyPairReversedForEdition: {
                        ...currencyPair,
                        PrimaryCurrency: (currencyPair.PrimaryCurrency || buyCurrency),
                        SecondaryCurrency: (currencyPair.SecondaryCurrency || sellCurrency)
                    }
                })
            })
        }
    }

    handleEditCurrencyPairBackClick = () => {        
        this.setState({ editCurrencyPair: { edit: false }, currencyPairForEdition: {} });
    }

    closeAlert = () => {
        this.setState({ showAlert: false}) 
    }

    render() {       
        console.log(this.state)
        let currentView = []
        let maintenanceView = <div>
            <div className="uk-width-1-2" style={{ marginBottom: 10 }}>
                <label className="uk-form-label" htmlFor="form-currency-type">Currency type(*)</label>
                <div className="uk-form-controls">
                    <DynamicSelect className={regularSelectClass} getValue={this.handleCurrencyTypeSelection} placeholder="Select an Item" objValue={{ value: this.state.currencyType }} data={this.state.listCurrencyType} id="form-currency-status" />
                </div>
            </div>
            <div className="uk-grid">
                {/*<div className="uk-width-1-1" style={{textAlign: 'left'}}>Buying Currencies</div>*/}
                {<div className="uk-width-1-12" style={{width: '1px', wordWrap: 'break-word', paddingTop: '10px'}}>Selling_Currencies</div>}
                <div>
                    <CurrencyPairTable data={this.state.currencyDefs} handleModifyCurrencyPair={this.handleModifyCurrencyPair} />
                </div>
                
            </div>
        </div>
        currentView = maintenanceView
        if (this.state.editCurrencyPair.edit) {
            currentView = <CurrencyPairEdit
                currencyPair={this.state.currencyPairForEdition}
                reversedCurrencyPair={this.state.currencyPairReversedForEdition}
                refreshCurrencyPair={this.onRefreshCurrencyPair}
                onBackClick={this.handleEditCurrencyPairBackClick}
                buyCurrency={this.state.editCurrencyPair.buyCurrency}
                sellCurrency={this.state.editCurrencyPair.sellCurrency}
                rateTermsList={this.state.rateTermsList}
                updateCurrencyEdited={this.updateCurrencyEdited}
            />
        }
        return (
            <div>
                <div className="uk-width-1-1 uk-margin">
                    <div className="">
                        <h3 className="uk-heading-divider" style={style.titleStyle}>
                            Currency Pair Maintenance
                            </h3>
                    </div>
                </div>
                {currentView}
                <AlertBox id="rollApplyFundsrAlert" type="Ok" open={this.state.showAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                    okClick={this.closeAlert} onClose={this.closeAlert} />
            </div>
        );
    }
}
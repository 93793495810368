import React, { useState, useRef } from 'react';
import BusinessHoursForm from './BusinessHoursForm';
import { getStyle } from '../../../styles/styles';
import PlansForm from './PlansForm';
import usePlans from './hooks/usePlans';
import PlanDetailsForm from './PlanDetailsForm';
import NewBusinessHoursForm from './NewBusinessHoursForm';
var style = getStyle();

const AfterHoursScreen = () => {
    const [plans, totalPages, plansNeedReloading] = usePlans();
    const [showPlanDetails, setShowPlanDetails] = useState(false);
    const [selectedPlanID, setSelectedPlanID] = useState(0);
    const [activeTab, setActiveTab] = useState('business-hours');

    const getPlans = () => {
        plansNeedReloading(true);
    }

    const getSelectedPlanID = (planID) => {
        setSelectedPlanID(planID);
        setShowPlanDetails(true);
        setActiveTab('plan-details');
    }

    const handleActiveTab = (tab) => {
        if (tab === 'plan-details' && activeTab !== 'plan-details') {
            return 'uk-disabled';
        }
        return activeTab === tab ? 'uk-active': '';
    }

    const changeTab = (tab) => {
        if (tab !== 'plan-details') {
            setShowPlanDetails(false);
        }
        setActiveTab(tab);
    }

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >
                After Hours
            </h3>
            <ul uk-tab="">
                <li className={handleActiveTab('business-hours')}><a href="#" onClick={() => changeTab("business-hours")}>Business Hours</a></li>
                <li className={handleActiveTab('plans')}><a href="#" onClick={() => changeTab("plans")}>Plans</a></li>
                <li className={handleActiveTab('plan-details')}><a onClick={() => changeTab("plan-details")}>Plan Details</a></li>
            </ul>
            <ul className="uk-switcher uk-margin">
                <li className={handleActiveTab('business-hours')}>
                    <NewBusinessHoursForm plans={plans} plansNeedReloading={getPlans}/>
                </li>
                <li className={handleActiveTab('plans')}>
                    <PlansForm plans={plans} totalPages={totalPages} plansNeedReloading={getPlans} getSelectedPlanID={getSelectedPlanID}/>
                </li>
                <li className={handleActiveTab('plan-details')}>
                    {
                        showPlanDetails &&
                        <PlanDetailsForm selectedPlanID={selectedPlanID.AfterHoursPlan_ID} selectedPlanName={selectedPlanID.PlanName}/>
                    }
                </li>
            </ul>
        </div>
    );
}

export default AfterHoursScreen;
export const SourceTableNames = {
    Payment : "P",
    Deposit : "D",
    Quote : "Q",
    Option : "O",
    RateWatch : "R",
    OutgoingFunds : "U",
    IncomingFunds : "I",
    Customer : "C",
    Beneficiary : "B",
    DealFees : "F",
    RegularPayments : "G",
    PaymentString : "Payment",
    FundOnHoldString : "Funds on Hold",
    FundOnHold : "H",
}

import { Dialog, DialogContent } from '@material-ui/core';
import preval from 'preval.macro';
import React from 'react';
import AppContext from '../../AppContext';
import { getCurrentVersionNumber } from '../../helpers/PackageJsonHelper';
import { getServerVersion, login, refreshVersionLogin } from '../../networking/Networking';
import { sendForgotPasswordEmail } from '../../networking/NetworkingUsers';
import AlertBox from '../shared/AlertBox';
import AdditionalValidation from './AdditionalValidation';


let globalFunctions = {};


export default class LoginForm extends React.Component {
    static contextType = AppContext;

    state = {
        username: '',
        password: '',
        loading: false,
        forgotPassword: false,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        isPasswordShown: false,
        additionalValidation: false
    }

    componentDidMount = () => {
        globalFunctions = this.context;
    }

    handleLoginClick = () => {
        this.setState({
            loading: true
        });
        globalFunctions.startLoading();

        //getServerVersion().then((response) => {
        let version = getCurrentVersionNumber();
        console.log("config version:" + version)

        //if (response.version !== version) {
        //    window.location.reload();
        //}

        login(this.state.username, this.state.password).then(
            (value) => {
                if (value != null) {
                    if (value.SessionID != null) {
                        if (value.AdditionalValidation) {
                            this.setState({
                                additionalValidation: true
                            });
                        } else {
                            this.props.loginSuccess();
                        }
                    } else if (value.status !== undefined) {
                        if (value.status === 401) {
                            console.log("value.status === 401: " + JSON.stringify(value));
                            if (value.IsLocked === 1) {
                                this.setState({
                                    showAlert: true,
                                    alertMessage: 'Your account is locked. Please contact your supervisor.',
                                    alertTitle: 'Error',
                                    username: '',
                                    password: ''
                                });
                            } else {
                                this.setState({
                                    showAlert: true,
                                    alertMessage: 'Incorrect Username or Password',
                                    alertTitle: 'Error',
                                    username: '',
                                    password: ''
                                });
                            }

                        } else if (value.status === 406) {
                            this.setState({
                                showAlert: true,
                                alertMessage: 'Could not login with windows credentials',
                                alertTitle: 'Error',
                                username: '',
                                password: ''
                            });
                        } else if (value.status === 412) {
                            this.setState({
                                showAlert: true,
                                alertMessage: 'Sorry, at this moment the system is not available, please try again later.',
                                alertTitle: 'Error',
                                username: '',
                                password: ''
                            });
                        }
                    } else {
                        this.setState({
                            showAlertError: true,
                            alertMessage: 'Check your Internet Connection',
                            alertTitle: 'Error'
                        });
                    }
                } else {
                    this.setState({
                        showAlertError: true,
                        alertMessage: 'Check your Internet Connection',
                        alertTitle: 'Error'
                    });
                }
            }
        );
        //})
    }

    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '', forgotPassword: false, username: '', password: '' });
        globalFunctions.finishLoading()
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '' });
        globalFunctions.finishLoading()
    }

    closeAdditionalValidation = () => {
        this.setState({ additionalValidation: false});
        globalFunctions.finishLoading()
    }

    handleResetPasswordClick = (event) => {
        event.preventDefault();
        if (this.state.username !== "") {
            sendForgotPasswordEmail("ForeignExchange", this.state.username).then(
                (json) => {
                    if (json !== undefined) {
                        if (json.httpStatusCode !== 200) {
                            this.setState({ showAlertError: true, alertMessage: 'An error ocurred while sending the email', alertTitle: 'Error' });
                        } else {
                            this.setState({ showAlert: true, alertTitle: 'Email sent ', alertMessage: 'Please check your inbox', username: '', password: '' });
                        }
                    }
                }
            );
        }
    }

    handleForgotPasswordClick = (event) => {
        event.preventDefault();
        this.setState({ forgotPassword: !this.state.forgotPassword });
    }

    handleUpdateUsername = (event) => {
        this.setState({ username: event.target.value });
    }

    handleUpdatePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleLoginClick();
        }
    }

    handlePasswordVisiblity = () => {
        const { isPasswordShown } = this.state;
        this.setState({ isPasswordShown: !isPasswordShown });
    };

    render() {
        const { isPasswordShown } = this.state;
        var form = (
            <form>
                <div className="uk-margin">
                    <input className="uk-input" type="text" placeholder="User Name" autoFocus={true} onChange={this.handleUpdateUsername} value={this.state.username} onKeyPress={this.handleKeyPress} />
                </div>
                <div className="uk-margin">
                    <div className="uk-inline uk-width-1-1">
                        <span className={`fa ${isPasswordShown ? "fa-eye" : "fa-eye-slash"} password-icon`} onClick={this.handlePasswordVisiblity} />
                        <input className="uk-input" type={isPasswordShown ? "text" : "password"} placeholder="Password" onChange={this.handleUpdatePassword} value={this.state.password} onKeyPress={this.handleKeyPress} />
                    </div>
                </div>
                <div className="uk-margin-top">
                    <button type="button" className="uk-button uk-button-link" onClick={this.handleForgotPasswordClick}>Forgot your password?</button>
                </div>
                <div className="uk-margin">
                    <button type="button" className="uk-button uk-button-green uk-width-1-1" onClick={this.handleLoginClick}>Login</button>
                </div>
            </form>
        );

        if (this.state.loading) {
            //form = (<div className="uk-position-center" uk-spinner="ratio: 3"></div>);
        }

        if (this.state.forgotPassword) {
            form = (
                <form>
                    <div className="uk-margin">
                        <input className="uk-input" type="text" placeholder="Username" onChange={this.handleUpdateUsername} value={this.state.username} />
                    </div>
                    <div className="uk-margin">
                        <button type="button" className="uk-button uk-button-link" onClick={this.handleForgotPasswordClick}>Return to Login page</button>
                    </div>
                    <button className="uk-button uk-button-green uk-width-1-1" onClick={this.handleResetPasswordClick}>Reset password</button>
                </form>
            );
        }

        return (
            <div>
                <div className="uk-position-center uk-box-shadow-large">
                    <div className="uk-card uk-card-default uk-card-large uk-width-large">
                        <div className="uk-card-badge uk-label">{getCurrentVersionNumber()}</div>
                        <div className="uk-card-header">
                            <h3 className="uk-card-title">e2e Foreign Exchange</h3>
                            <h6> Build Date: {preval`module.exports = new Date().toLocaleString();`}</h6>
                        </div>
                        <div className="uk-card-body">
                            {form}
                        </div>
                    </div>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} />
                <Dialog
                fullWidth={true}
                maxWidth='xs'
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.additionalValidation} onClose={this.closeAdditionalValidation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <AdditionalValidation loginSuccess={this.props.loginSuccess} onClose = {this.closeAdditionalValidation}/>
                </DialogContent>
            </Dialog>
            </div>
        );
    }
}
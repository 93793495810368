import { Table } from '@material-ui/core';
import { Breadcrumb } from 'antd';
import React from 'react';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../networking/Networking';
//import DealUse from './DealUse'
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';
import { getDepositByID } from '../../../networking/NetworkingDeposits';
import { getStyle } from '../../../styles/styles';
import IncomingPaymentForm from '../../deal/IncomingPaymentForm';
import LoadingBox from '../../shared/LoadingBox';
import WizardSearchCustomerForm from '../../trade/wizard/WizardSearchCustomerForm';
import DepositInfoScreen from './DepositInfoScreen';

var style = getStyle();

export default class DepositsScreen extends React.Component {
   state={
        currencyID : '',
        customerID : 0,
        depositID: 0,
        listActiveCurrencies : [],
        listIncomingInstruments : [],
        objCustomer: {},
        objDeposit: {},
        step: 1,
        typeRecordsApply : 'Deposit',
        customerName: '',
   }

   componentDidMount  = async () => {    
        await this.loadCurrencyList();        
        if(this.props.data !== undefined && Number(this.props.data) > 0){
            this.setState({
                depositID: this.props.data,
                step: 2,
            });
            this.getDeposit(this.props.data);
        }        
        this.loadIncomingInstruments();
   }

   getDeposit = async (depositID) =>
   {       
        var objDeposit;
        if(depositID !== undefined && Number(depositID) > 0){
            await getDepositByID(depositID).then(
                (json) => {
                    if(json !== undefined){
                        this.setState({
                            depositID: depositID,
                            objDeposit: json,
                        });
                        objDeposit = json;
                    }
                }
            );
        }
        return objDeposit;
   }

   onClickStepTab = (value) => {
        if(this.state.step === 1){
            //Nothing at all
        }
        else{
            if(value === 4)
            { 
                if(this.state.objDeposit !== undefined && this.state.objDeposit["Deposit ID"] !== undefined)
                {
                    this.setState({step: value});
                }
            }
            else
            {
                this.setState({step: value});
            }
        }
    }

    handleBackLink = () => {
        this.props.backLink();
    }

    ///Customer screen
    handleCustomerSelection = (obj, nextStep) => {
        if(nextStep === 2){            
            this.setState({step: nextStep, 
                objCustomer: obj,
                customerID: obj["Customer ID"]
            });            
        }  
    }

    setCustomer = (customerID) =>{
        if(customerID !== undefined){
            this.setState({customerID: customerID}); 
        }
    }

    setDepositCurrency = (currencyID) => {
        //alert('setDepositCurrency:' + currencyID);
        this.setState({currencyID: currencyID}); 
    }

    setCustomerName = (customerName) => {
        this.setState({customerName: customerName}); 
    }

    ///Deposit screen
    handleDepositSelection(obj, nextStep){
        if(nextStep === 4){            
            this.setState({
                depositID: obj.DepositID,
                objDeposit: obj,
            });            
        }  
    }

    loadCurrencyList = async () => {
        await getCurrencyList().then(
            (response) => {
                var currencies = [];
                if (response !== undefined) {
                    currencies = response.currencies;
                    currencies.forEach(element => {
                        element.value = element.CurrencyID;
                        element.name = element.CurrencyIDName;
                    });
                }
                this.setState({listActiveCurrencies: currencies});
            }
        );
    }

    loadIncomingInstruments() {
        getLookUpTable('Incoming Instruments').then(
            (response) => {
                if (typeof response !== "undefined" && response.lookUpTables !== "undefined") {
                    let instruments = response.lookUpTables[0];
                    instruments.lookUpTableDetails.forEach(element => {
                        element.value = element.LookUpTableDetail;
                        element.name = element.LookUpTableDetail;
                    });
                    var listInstruments = [];
                    listInstruments.push(instruments);
                    this.setState({
                        listIncomingInstruments: listInstruments
                    });                    
                }
            });
    }

    render()
    {
        var stepScreen = [];

        var classNameStep1 = '', classNameStep2 = '', classNameStep3 = '';//, classNameStep4 = '';

        switch(this.state.step){
            case 1:
            default:
                classNameStep1 = 'uk-active';
                stepScreen.push(<WizardSearchCustomerForm key="customer-form"
                    onCustomerSelected={this.handleCustomerSelection}/>);
                break;
            case 2:
                classNameStep2 = 'uk-active';
                stepScreen.push(<DepositInfoScreen key="deposit-form"
                    objCustomer={this.state.objCustomer} 
                    currencies ={this.state.listActiveCurrencies}
                    onDepositSelected={this.handleDepositSelection.bind(this)}
                    data={this.state.depositID}
                    setCustomer = {this.setCustomer}
                    setDepositCurrency = {this.setDepositCurrency}
                    customerID={this.state.customerID}
                    reloadDepositInfo={this.getDeposit}
                    setCustomerName={this.setCustomerName}
                    />);
                break;
            case 3:
                classNameStep3 = 'uk-active';
                stepScreen.push(<IncomingPaymentForm key="payment-form"
                customerID={this.state.customerID}//{this.state.objCustomer["Customer ID"]}
                activeCurrencies={this.state.listActiveCurrencies}
                data={this.state.objDeposit.DefaultPaymentID}
                incomingInstruments={this.state.listIncomingInstruments}
                typeRecordsApply = {this.state.typeRecordsApply}
                paymentOpenFromBoard={false}
                buyCurrency = {this.state.currencyID}
                objDeposit={this.state.objDeposit}
                reloadDepositInfo = {this.getDeposit}/>);
                break;
          /*  case 4:
                classNameStep4 = 'uk-active';
                stepScreen.push(<DealUse key="deposit-form"
                        objDeposit={this.state.objDeposit}
                        customerID={this.state.objCustomer["Customer ID"]}/>);
                break;*/
        }

        var button = "";
        var tabs = [];

        if(this.props.data !== undefined)
        { 
            button = <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left back-arrow"></i>
                </button>;
        }
        
        if(this.props.data !== undefined && this.props.data > 0)
        { 
            if(this.state.objDeposit !== undefined 
                && this.state.objDeposit.ReceivedStatus !== undefined 
                && this.state.objDeposit.ReceivedStatus !== 'Fully paid' 
                && this.state.objDeposit.ReceivedStatus !== 'Voided')
            {
                tabs.push(<li className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Margin</a></li>)
                tabs.push(<li className={classNameStep3}><a onClick={() => this.onClickStepTab(3)}>Incoming Payments</a></li>)
            }
        }
        else
        {
            
            tabs.push(<li className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>)  
            tabs.push(<li className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Margin</a></li>)  
            tabs.push(<li className={classNameStep3}><a onClick={() => this.onClickStepTab(3)}>Incoming Payments</a></li>)        
        }
        var depositTitle = (
            <div className="uk-width-1-2 uk-heading-divider" style={style.titleStyleMargin}>
                {button}
                <h3
                    style={{
                        color: 'white',
                        margin: 'auto auto auto 0',
                    }}
                >Margin</h3>
            </div>)
        if (this.props.mainTitle != null) {
            depositTitle = (<div></div>);
        }
        var depositScreen = (
            <div>
                <div>
                    <div className="uk-flex">
                        {depositTitle}
                        <div className="uk-width-1-2"><h4 className="text-align-left">Customer: {this.state.customerID} - {this.state.customerName}</h4></div>
                        {this.state.depositID > 0 && <div className="uk-width-1-2 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div
                                style={{
                                    textAlign: "right",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    paddingRight: '10px',
                                }}
                            >
                                {/*<label className="uk-form-label" style={{ fontSize: '1.75em', }} >Deposit number: {this.state.depositID}</label>*/}
                            </div>                            
                            <Table border="1" bordercolor="#E5DBD8" style={{ width: '30%' }}>
                                <tr >
                                    <th>Deposit Status</th>
                                    <th>Balance</th>
                                </tr>
                                <tr>
                                    <td align="middle">{this.state.objDeposit.ReceivedStatus}</td>
                                    <td align="middle">{formatToCurrencyWithScale(Number(this.state.objDeposit.Amount) - Number(this.state.objDeposit.AmountReceived),this.state.currencyID)}</td>
                                </tr>
                            </Table>                     
                        </div>}
                    </div>

                    <ul className="uk-tab" style={{ marginTop: 5 }}>
                        {tabs}
                    </ul>
                    {stepScreen}
                </div>
                <LoadingBox loading={this.state.loading} />
            </div>)
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Margin - Deposit number: {this.state.depositID}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {depositScreen}
            </div>
        );
    }
}